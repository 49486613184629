import { padLeft } from '@stellacontrol/utilities'
import { DeviceFamily } from './device-family'
import { AttenuationGroup } from './attenuation-group'
import { getDeviceFamily } from './device-family'
import { isMultiDevice, isConnectedDevice } from './device-type'

/**
 * Device bands
 */
export const DeviceBandCode = {
  V: 'V',
  L: 'L',
  G: 'G',
  D: 'D',
  W: 'W',
  H: 'H'
}

/**
 * Device bands
 */
export const DeviceBandIdentifier = {
  Band07: '07',
  Band08: '08',
  Band09: '09',
  Band18: '18',
  Band21: '21',
  Band26: '26'
}

export const DeviceBandIndex = {
  [DeviceBandIdentifier.Band07]: 0,
  [DeviceBandIdentifier.Band08]: 1,
  [DeviceBandIdentifier.Band09]: 2,
  [DeviceBandIdentifier.Band18]: 3,
  [DeviceBandIdentifier.Band21]: 4,
  [DeviceBandIdentifier.Band26]: 5
}

export const DeviceBandIdentifiers = [
  DeviceBandIdentifier.Band07,
  DeviceBandIdentifier.Band08,
  DeviceBandIdentifier.Band09,
  DeviceBandIdentifier.Band18,
  DeviceBandIdentifier.Band21,
  DeviceBandIdentifier.Band26
]

/**
 * Band names and other details
 */
export const DeviceBandDetails = {
  [DeviceBandIdentifier.Band07]: {
    id: DeviceBandIdentifier.Band07,
    code: DeviceBandCode.V,
    parameters: {
      [DeviceFamily.EU]: {
        label: 'B28',
        frequency: 700,
        frequencyLabel: '700MHz'
      },
      // Slot 07 is not applicable for US devices
      [DeviceFamily.US]: {
        label: 'N/A',
        frequency: undefined,
        frequencyLabel: 'N/A'
      }
    }
  },

  [DeviceBandIdentifier.Band08]: {
    id: DeviceBandIdentifier.Band08,
    code: DeviceBandCode.L,
    parameters: {
      [DeviceFamily.EU]: {
        label: 'B20',
        frequency: 800,
        frequencyLabel: '800MHz'
      },
      [DeviceFamily.US]: {
        label: 'B28',
        frequency: 700,
        frequencyLabel: 'APT-700'
      }
    }
  },

  [DeviceBandIdentifier.Band09]: {
    id: DeviceBandIdentifier.Band09,
    code: DeviceBandCode.G,
    parameters: {
      [DeviceFamily.EU]: {
        label: 'B8',
        frequency: 900,
        frequencyLabel: '900MHz'
      },
      [DeviceFamily.US]: {
        label: 'B5',
        frequency: 850,
        frequencyLabel: '850MHz'
      }
    }
  },

  [DeviceBandIdentifier.Band18]: {
    id: DeviceBandIdentifier.Band18,
    code: DeviceBandCode.D,
    parameters: {
      [DeviceFamily.EU]: {
        label: 'B3',
        frequency: 1800,
        frequencyLabel: '1800MHz'
      },
      [DeviceFamily.US]: {
        label: 'B4',
        frequency: 1750,
        frequencyLabel: 'AWS-1750'
      }
    }
  },

  [DeviceBandIdentifier.Band21]: {
    id: DeviceBandIdentifier.Band21,
    code: DeviceBandCode.W,
    parameters: {
      [DeviceFamily.EU]: {
        label: 'B1',
        frequency: 2100,
        frequencyLabel: '2100MHz'
      },
      [DeviceFamily.US]: {
        label: 'B25',
        frequency: 1900,
        frequencyLabel: 'PCS-1900'
      }
    }
  },

  [DeviceBandIdentifier.Band26]: {
    id: DeviceBandIdentifier.Band26,
    code: DeviceBandCode.H,
    frequency: 2600,
    parameters: {
      [DeviceFamily.EU]: {
        label: 'B7',
        frequency: 2600,
        frequencyLabel: '2600MHz'
      },
      [DeviceFamily.US]: {
        label: 'B7',
        frequency: 2600,
        frequencyLabel: '2600MHz'
      }
    }
  }
}

/**
 * Typical band sets
 */
export const StandardDeviceBands = {
  // Five bands
  FiveBands: 'LGWDH',
  // Six bands
  SixBands: 'VLGWDH',
  // Four bands,
  FourBands: 'VLGD'
}

/**
 * Typical band set names
 */
export const StandardDeviceBandsNames = {
  [StandardDeviceBands.FiveBands]: 'Five',
  [StandardDeviceBands.SixBands]: 'Six',
  [StandardDeviceBands.FourBands]: 'Four'
}

/**
 * Band count to band set mapping
 */
export const DeviceBandsSets = {
  5: StandardDeviceBands.FiveBands,
  6: StandardDeviceBands.SixBands,
  4: StandardDeviceBands.FourBands
}

/**
 * Bands grouped by attenuation group
 */
export const AttenuationGroupBands = {
  [AttenuationGroup.LowerBands]: [
    DeviceBandIdentifier.Band07,
    DeviceBandIdentifier.Band08,
    DeviceBandIdentifier.Band09
  ],
  [AttenuationGroup.UpperBands]: [
    DeviceBandIdentifier.Band18,
    DeviceBandIdentifier.Band21,
    DeviceBandIdentifier.Band26
  ]
}

/**
 * Checks if specified band identifier is valud
 * @param {BandIdentifier} identifier Band identifier
 * @returns {Boolean} True if the specified band identifier is valid
 */
export function isBandIdentifier (identifier) {
  return identifier && DeviceBandIdentifiers.includes(identifier.toString())
}

/**
 * Converts band number such as `7`, `8`, `21` etc. to band identifier string
 * @param {Number} number Band number
 * @returns {BandIdentifier} identifier Band identifier
 */
export function bandNumberToIdentifier (number) {
  if (number != null) {
    return padLeft(number, 2, '0')
  }
}

/**
 * Converts band label such as `B28` to band identifier
 * @param {String} label Band label
 * @returns {BandIdentifier} identifier Band identifier
 */
export function bandLabelToIdentifier (label) {
  if (label != null) {
    const details = Object
      .values(DeviceBandDetails)
      .find(d => d.parameters[DeviceFamily.EU].label === label || d.parameters[DeviceFamily.US].label === label)
    return details?.id
  }
}

/**
 * Converts a set of bands specified by letter-codes to a list of band identifiers
 * @param {String} bands Bands, specified as string of band letter codes, for example `LGWDH`
 * @returns {Array[DeviceBandIdentifier]} Band identifiers
 */
export function bandsToIdentifiers (bands = '') {
  if (!bands) return []

  const details = Object
    .entries(DeviceBandDetails)
    .map(([identifier, { code }]) => ({ identifier, code }))

  const identifiers = Array
    .from(bands)
    .map(code => ((details.find(i => i.code === code) || {}).identifier || '').toString())
    .filter(id => id)
  identifiers.sort()

  return identifiers
}

/**
 * Converts a list of band identifiers to a list of band codes
 * @param {Array[DeviceBandIdentifier]} identifiers Band identifiers
 * @returns {Array[String]} List of band codes such as `V`, `G` etc.
 */
export function identifiersToBands (identifiers = []) {
  const details = Object
    .entries(DeviceBandDetails)
    .map(([identifier, { code }]) => ({ identifier, code }))

  return identifiers
    .map(identifier => (details.find(i => i.identifier === identifier) || {}).code)
}

/**
 * Converts a list of band identifiers to a band codes string
 * @param {Array[DeviceBandIdentifier]} identifiers Band identifiers
 * @returns {String} Band codes string such as `LGWDH`
 */
export function identifiersToBandsString (identifiers = []) {
  return (identifiersToBands(identifiers) || []).join('')
}

/**
 * Returns a list of identifiers of bands applicable to the specified device
 * @param {Device} device Device
 * @returns {Array[DeviceBandIdentifier]} List of identifiers of bands applicable to the specified device
 */
export function getDeviceBandIdentifiers (device) {
  if (device && isConnectedDevice(device.type) && !isMultiDevice(device.type)) {
    return bandsToIdentifiers(device.bands)
  } else {
    return []
  }
}

/**
 * Returns a list of bands applicable to the specified device
 * @param {Device} device Device
 * @returns {Array[DeviceBandIdentifier]} List of identifiers of bands applicable to the specified device
 */
export function getDeviceBands (device) {
  if (device) {
    const family = getDeviceFamily(device)
    const identifiers = getDeviceBandIdentifiers(device)
    if (identifiers) {
      return identifiers.map(identifier => {
        const details = DeviceBandDetails[identifier]
        const parameters = details.parameters[family]
        return {
          ...details,
          parameters: {
            ...parameters
          }
        }
      })
    }
  }
}

/**
 * Returns parameters of the specified band
 * @param {BandIdentifier} identifier Band identifier
 * @param {DeviceFamily} family Device family
 * @returns {Object} Band parameters for the specified band
 */
export function getBandMegaParameters (identifier, family) {
  const details = DeviceBandDetails[identifier]
  if (!details) throw new Error(`Unknown band identifier ${identifier}`)
  const parameters = details.parameters[family || DeviceFamily.EU]
  if (!parameters) throw new Error(`Unknown device family ${family}`)
  return parameters
}

/**
 * Returns parameters of the specified band
 * @param {String} code Band letter code
 * @param {DeviceFamily} family Device family
 * @returns {Object} Band parameters for the specified band
 */
export function getBandMegaParametersByCode (code, family) {
  const details = Object
    .values(DeviceBandDetails)
    .find(band => band.code === code)
  if (!details) throw new Error(`Unknown band code ${code}`)
  const parameters = details.parameters[family || DeviceFamily.EU]
  if (!parameters) throw new Error(`Unknown device family ${family}`)
  return parameters
}

/**
 * Returns frequency of the specified band
 * @param {BandIdentifier} identifier Band identifier
 * @param {DeviceFamily} family Device family
 * @returns {Number} Band frequency
 */
export function getBandFrequency (identifier, family) {
  const parameters = getBandMegaParameters(identifier, family) || {}
  return parameters.frequency
}

/**
 * Returns frequency of the specified band
 * @param {String} code Band letter code
 * @param {DeviceFamily} family Device family
 * @returns {Number} Band frequency
 */
export function getBandFrequencyByCode (code, family) {
  const parameters = getBandMegaParametersByCode(code, family) || {}
  return parameters.frequency
}

/**
 * Returns human-friendly label of the specified band
 * @param {BandIdentifier} identifier Band identifier
 * @param {DeviceFamily} family Device family
 * @returns {String} Band label
 */
export function getBandLabel (identifier, family) {
  const parameters = getBandMegaParameters(identifier, family) || {}
  return parameters.label
}

/**
 * Returns human-friendly of the specified band's frequency
 * @param {BandIdentifier} identifier Band identifier
 * @param {DeviceFamily} family Device family
 * @returns {String} Band frequency label
 */
export function getBandFrequencyLabel (identifier, family) {
  const parameters = getBandMegaParameters(identifier, family) || {}
  return parameters.frequencyLabel
}

/**
 * Returns human-friendly label of the specified band
 * @param {String} code Band letter code
 * @param {DeviceFamily} family Device family
 * @returns {String} Band label
 */
export function getBandLabelByCode (code, family) {
  const parameters = getBandMegaParametersByCode(code, family)
  return parameters.label
}

/**
 * Checks if the specified band is in the specified attenuation group
 * @param {DeviceBandIdentifier} identifier Band identifier
 * @param {AttenuationGroup} group Attenuation group
 * @returns {Boolean} Returns true if the specified band is the specified attenuation group
 */
export function inAttenuationGroup (identifier, group) {
  return AttenuationGroupBands[group]?.includes(identifier)
}
