import Konva from 'konva'
import { PlanItemType } from '@stellacontrol/planner'
import { Shape } from './shape'

/**
 * Rectangle
 * @param {PlanRectangle} item Plan item details
 */
export class RectangleShape extends Shape {
  constructor (item, dataCallback) {
    super(item, dataCallback)
    this.createShapes()
  }

  static get type () {
    return PlanItemType.Rectangle
  }

  /**
   * Rectangle shape
   * @type {Konva.Rect}
   */
  rect

  createShapes () {
    super.createShapes()

    this.rect = new Konva.Rect()
    this.content.add(this.rect)
  }

  async render (renderer) {
    await super.render(renderer)
    const { rect, item } = this
    if (rect && item) {
      const { lineStyle, backgroundStyle } = item
      rect.width(item.width)
      rect.height(item.height)
      rect.fill(backgroundStyle.color)
      rect.stroke(lineStyle.color)
      rect.strokeWidth(lineStyle.width)
      rect.dash(lineStyle.dash)
      rect.cornerRadius(lineStyle.radius)
      this.applyFilters()
    }
  }

  /**
   * Triggered when shape has been transformed.
   * @param {PlanRenderer} renderer Plan renderer
   * @param {PlanScale} scale Scale of the shape
   * @param {Number} rotation Shape rotation
   */
  async transformed ({ renderer, scale, rotation }) {
    super.transformed({ renderer, scale, rotation })

    // Change the sizes
    const { item } = this
    item.width = Math.round(item.width * scale.x)
    item.height = Math.round(item.height * scale.y)

    // Re-render the shape
    await this.render(renderer)
  }
}
