/**
 * User levels
 */
export const UserLevel = {
  /**
   * Regular user
   */
  User: 'user',
  /**
   * Administrator user
   */
  Administrator: 'administrator',
  /**
   * Guest user
   */
  Guest: 'guest',
  /**
   * Integration user, for app-to-app communications
   */
  Integration: 'integration'
}

/**
 * Human-friendly user level names
 */
export const UserLevelNames = {
  [UserLevel.User]: 'Regular user',
  [UserLevel.Guest]: 'Guest user',
  [UserLevel.Administrator]: 'Administrator',
  [UserLevel.Integration]: 'Systems Integration'
}

/**
 * User level weights, for sorting etc.
 */
export const UserLevelWeight = {
  [UserLevel.Guest]: 0,
  [UserLevel.User]: 1,
  [UserLevel.Administrator]: 2,
  [UserLevel.Integration]: 3
}

// User icons, representing its level
export const UserIcons = {
  [UserLevel.Administrator]: 'local_police',
  [UserLevel.User]: 'person',
  [UserLevel.Guest]: 'person_outline',
  [UserLevel.Integration]: 'miscellaneous_services'
}

// User colors, representing its level
export const UserColors = {
  [UserLevel.Administrator]: 'indigo-5',
  [UserLevel.User]: 'indigo-5',
  [UserLevel.Guest]: 'blue-grey-4'
}

/**
 * Returns icon representing user level
 * @param user User
 * @param defaultIcon Default icon to return, if user is not specified
 */
export function getUserIcon (user, defaultIcon = 'help') {
  return user ? UserIcons[user.level] : defaultIcon
}

/**
 * Returns color representing user level
 * @param user User
 * @param defaultColor Default color to return, if user is not specified
 */
export function getUserColor (user, defaultColor = 'grey-5') {
  return user.isEnabled
    ? (user.isPrimaryAdministrator ? 'green-7' : UserColors[user.level])
    : defaultColor
}

/**
 * Returns a user friendly description of user level
 * @param user User
 * @param defaultName Default text to return, if user is not specified
 */
export function getUserLevelName (user, defaultName = '') {
  return user ? UserLevelNames[user.level] : defaultName
}
