<script>
import helpContent from './help.md'

export default {
  props: {
    // Help to show
    help: {
    }
  },

  data () {
    return {
      helpContent
    }
  },

  computed: {
    // Indicates whether the help is visible
    isVisible () {
      return Boolean(this.help)
    }
  },

  emits: [
    'close'
  ],

  methods: {
    // Signals that the user wants to execute the specified action on the item
    close () {
      this.$emit('close')
    }
  }
}

</script>

<template>
  <div class="overlay" v-if="isVisible" @click="close()">
    <div class="help bg-grey-1">
      <q-banner class="banner bg-indigo-6">
        <div class="row items-center">
          <span class="text-subtitle1 text-white text-bold">
            StellaPlanner Help
          </span>
          <q-space>
          </q-space>
          <q-btn flat dense label="Close" @click="close()" class="primary">
          </q-btn>
        </div>
      </q-banner>
      <div class="content q-pa-md">
        <sc-document-viewer type="markdown" :content="helpContent">
        </sc-document-viewer>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .help {
    width: 90%;
    height: 90%;
    max-width: 1200px;
    max-height: 1024px;
    box-shadow: 0 0 8px #b0b0b0;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .content {
      flex: 1;
      overflow: auto;
    }
  }
}
</style>

