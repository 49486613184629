<script>
import { mapState } from 'vuex'
import { DeviceRegion, DeviceRegionDescription, isDeviceRegionValid } from '@stellacontrol/model'
import { Viewport } from '@stellacontrol/client-utilities'
import DeviceWidget from './device-widget'

export default {
  mixins: [
    DeviceWidget
  ],

  props: {
    // Icon size
    iconSize: {
      type: String
    }
  },

  computed: {
    ...mapState({
      // Indicates whether device region is currently being changed
      isChangingRegion: state => state.deviceSettings.changingRegionOfDevices.length > 0,
      changingRegionTo: state => state.deviceSettings.changingRegionTo
    }),

    isSmallScreen () {
      return Viewport.isSmallScreen
    },

    // Current device region
    region () {
      return this.status?.mega?._rf_region
    },

    isEMEA () {
      return this.region === DeviceRegion.EMEA
    },

    isUSA () {
      return this.region === DeviceRegion.USA
    },

    isOff () {
      return this.region === DeviceRegion.Off
    },

    isUnknown () {
      return !isDeviceRegionValid(this.region)
    },

    regionDescription () {
      const { status, region, isChangingRegion, changingRegionTo } = this
      if (status) {
        return isChangingRegion && (region !== changingRegionTo)
          ? `${DeviceRegionDescription[region] || 'Unknown'} > ${DeviceRegionDescription[changingRegionTo]}`
          : `RF: ${DeviceRegionDescription[region] || 'Unknown'}`
      } else {
        return ''
      }
    }
  }
}
</script>

<template>
  <sc-widget-parameter v-bind="$props">

    <template #icon>
      <div class="icon-container" :class="{ [region]: true }" v-if="hasStatus">
        <sc-icon-flag-eu v-if="!isChangingRegion && isEMEA" class="icon-emea"></sc-icon-flag-eu>
        <sc-icon-flag-usa v-if="!isChangingRegion && isUSA" class="icon-us"></sc-icon-flag-usa>
        <q-icon v-if="!isChangingRegion && isOff" name="stop_circle" color="grey-6" size="60px"></q-icon>
        <q-icon v-if="!isChangingRegion && isUnknown" name="help_outline" color="grey-6" size="60px"></q-icon>
        <q-icon v-if="isChangingRegion" name="change_circle" class="rotate-reverse" color="orange-4" size="60px"></q-icon>
      </div>
    </template>

  </sc-widget-parameter>
</template>

<style lang="scss" scoped>
.icon-container {
  overflow: hidden;
  height: 64px;
  width: 60px;
  position: relative;

  svg {
    position: absolute;

    &.icon-emea,
    &.icon-us {
      left: 6px;
      top: 6px;
    }
  }
}

.toolbar {
}
</style>
