import { defaultListState } from './list.state'

export const actions = {
  /**
   * Sets initial states of lists
   * @param listState One or more initial list states
   */
  async initializeList ({ commit }, listState) {
    const items = Array.isArray(listState)
      ? listState.map(item => ({ ...defaultListState, ...item }))
      : [listState]
    commit('setListState', { items })
  },

  /**
   * Changes the state of the specified list
   * @param name List identifier
   * @param viewMode List view mode
   * @param sortBy List sort expression
   * @param sortDescending List sort direction
   * @param filter List filter expression
   */
  async setListState ({ commit }, { name, viewMode, sortBy, sortDescending, filter } = {}) {
    if (!name) {
      return
    }

    const items = [{ name, viewMode, sortBy, sortDescending, filter }]
    commit('setListState', { items })
  }
}
