import { PlanAction, PlanActions } from './plan-action'

/**
 * Rotates items
 */
export class RotateItemsAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.RotateItems
  }

  /**
   * Action label
   * @type {String}
   */
  get label () {
    return super.label || 'Rotate'
  }

  /**
   * Action icon
   * @type {String}
   */
  get icon () {
    return 'refresh'
  }

  /**
   * Indicates that the action requires items to act on
   * @type {Boolean}
   */
  get requiresItems () {
    return true
  }

  /**
   * Indicates whether action requires refresh
   * @type {Boolean}
   */
  get requiresRefresh () {
    return true
  }

  /**
   * If true, the current selection will be preserved
   * after the action has been executed
   * @type {Boolean}
   */
  get preserveSelection () {
    return true
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   * @param {Array[PlanItem]} items Plan items to apply the action to
   * @param {Number} angle Angle to rotate by
   */
  execute ({ renderer, items, angle = 45 } = {}) {
    if (renderer && items) {
      for (const item of items) {
        item.rotateBy(angle, renderer.isCrossSection)
        // Notify all layers about the modified item.
        // They might want to render something related to it,
        // for example rotate the radiation pattern around antenna.
        renderer.notifyLayers(layer => layer.itemChanged(item))
        renderer.changed({ action: this })
      }
    }
  }
}
