import { getStore } from '../get-store'
import { DialogResult } from '../../dialogs/dialog-result'

export const actions = {
  /**
   * Opens the specified popup dialog. Returns a promise which resolves
   * with dialog result and data, when dialog closes.
   * @param {String} dialog Dialog name
   * @param {Object} data Optional data to pass to the dialog
   * @param {Boolean} noClone If true, data is passed to the dialog in original shape, without creating a local clone
   */
  async showDialog ({ commit, getters }, { dialog, data, noClone } = {}) {
    if (!dialog) throw new Error('Dialog identifier required')

    if (getters.isDialogVisible(dialog)) {
      commit('hideDialog', { dialog })
    }

    return new Promise(resolve => {
      // Watch when the dialog has been closed...
      const store = getStore()
      const unsubscribe = store.subscribeAction({
        after: action => {
          if (action.type === 'hideDialog' && (!action.payload || action.payload.dialog === dialog)) {
            // Resolve with dialog result
            const { result, data } = action.payload || {}
            const isOk = result === DialogResult.Ok
            const isCancel = result === DialogResult.Cancel
            unsubscribe()
            resolve({ dialog, result, isOk, isCancel, data, noClone })
          }
        }
      })

      // Show the dialog
      commit('showDialog', { dialog, data })
    })
  },

  /**
   * Hides the specified dialog or any currently shown one
   * @param {String} dialog Optional dialog identifier. If not specified, just any currently shown dialog will be hidden.
   * @param {DialogResult} result Result to report, applicable only when dialog identifier is specified
   * @param {Object} data Data to report, applicable only when dialog identifier is specified
   */
  async hideDialog ({ commit, getters }, { dialog, result, data } = {}) {
    if (dialog) {
      if (getters.isDialogVisible(dialog)) {
        commit('hideDialog', { dialog, result, data })
      }
    } else {
      commit('hideDialog')
    }
  },

  /**
   * Saves dialog data in state
   * @param dialog Dialog identifier
   * @param data Data to save
   */
  async setDialogData ({ commit }, { dialog, data } = {}) {
    if (!dialog) throw new Error('Dialog identifier required')

    commit('setDialogData', { dialog, data })
  },

  /**
   * Hides the specified dialog with `ok` result and optional data
   * @param dialog Dialog identifier
   * @param data Optional data to report. If not specified, data from dialog state is returned instead.
   */
  async dialogOk ({ dispatch, getters }, { dialog, data } = {}) {
    if (!dialog) throw new Error('Dialog identifier required')

    // Return
    data = data || getters.getDialogData(dialog)
    await dispatch('hideDialog', { dialog, result: DialogResult.Ok, data })
  },

  /**
   * Hides the specified dialog with `cancel` result and optional data
   * @param dialog Dialog identifier
   */
  async dialogCancel ({ dispatch }, { dialog } = {}) {
    if (!dialog) throw new Error('Dialog identifier required')

    await dispatch('hideDialog', { dialog, result: DialogResult.Cancel })
  }
}
