import IsSelectedHeader from './is-selected.header.vue'
import TagsHeader from './tags.header.vue'
import TypeHeader from './type.header.vue'
import PlainTextHeader from './plain-text.header.vue'
import SelectHeader from './select.header.vue'
import IconHeader from './icon.header.vue'

export default {
  'sc-is-selected-header': IsSelectedHeader,
  'sc-tags-header': TagsHeader,
  'sc-type-header': TypeHeader,
  'sc-plain-text-header': PlainTextHeader,
  'sc-select-header': SelectHeader,
  'sc-icon-header': IconHeader
}
