<script>

// Default colors from Quasar palette
const DefaultColors = [
  'red',
  'pink',
  'purple',
  'deep-purple',
  'indigo',
  'blue',
  'cyan',
  'teal',
  'green',
  'lime',
  'yellow',
  'orange',
  'deep-orange',
  'brown',
  'grey',
  'blue-grey'
]

export default {
  props: {
    modelValue: {
      required: true
    },

    // Button label
    label: {
    },

    // Custom width of the button
    width: {
    },

    // Custom height of the button
    height: {
    },

    // If true, full color palette, otherwise just a simple list of basic colors
    full: {
      type: Boolean,
      default: false
    },

    // If true, allow clearing the color
    clear: {
      type: Boolean,
      default: false
    },

    // Palette of colors to select from, list of Quasar color classes
    // https://quasar.dev/style/color-palette
    colors: {
      default: () => [...DefaultColors]
    },

    // Rows to split the input into
    rows: {
      type: Number,
      default: 2
    }
  },

  data () {
    return {
      selectedColor: null
    }
  },

  computed: {
    // Color groups, to render table rows
    groups () {
      const { colors, rows } = this
      const chunk = Math.ceil(colors.length / (rows || 2))
      const groups = []
      const allColors = [...colors]
      while (allColors.length > 0) {
        const group = allColors.splice(0, chunk)
        while (group.length < chunk) {
          group.push(null)
        }
        groups.push(group)
      }
      return groups
    },

    // Returns CSS class for the specified color
    colorClass () {
      return color => {
        const selected = color != null && this.selectedColor === color
        return {
          disabled: color == null,
          enabled: color != null,
          selected,
          [`bg-${color}`]: true
        }
      }
    },

    // When in simple mode, assign button class from the selected color
    buttonClass () {
      const { full, selectedColor } = this
      return {
        full,
        [`bg-${selectedColor}`]: !full
      }
    },

    // When in full mode and selecting HTML colors, return background color for the button
    buttonStyle () {
      const { full, selectedColor, width, height } = this
      if (full) {
        const style = {
          'background-color': `${selectedColor || 'transparent'} !important`,
          'border': 'solid #0000001f 1px'
        }
        if (width) {
          style.width = width
        }
        if (height) {
          style.height = height
        }
        return style

      } else {
        const style = {
          'border': 'solid #0000001f 1px'
        }
        return style
      }
    },

    // Returns false if no color or transparent color is selected
    hasColor () {
      return this.selectedColor && this.selectedColor !== 'transparent'
    }
  },

  emits: [
    'update:model-value'
  ],

  methods: {
    toggleColor (color) {
      if (this.selectedColor === color) {
        if (this.clear) {
          this.selectedColor = null
        }
      } else {
        this.selectedColor = color || 'transparent'
      }
      this.$emit('update:model-value', this.selectedColor)
    }
  },

  watch: {
    modelValue () {
      this.selectedColor = this.modelValue
    }
  },

  created () {
    this.selectedColor = this.modelValue
  }
}
</script>

<template>
  <div class="color-button" :class="buttonClass" :style="buttonStyle" menu-self="top left">
    <span>
      {{ label }}
    </span>
    <q-popup-proxy>
      <template v-if="full">
        <q-color v-close-popup flat square :model-value="selectedColor"
          @update:model-value="value => toggleColor(value)" no-header no-footer
          default-view="palette" class="color-popup">
        </q-color>

        <div class="buttons">
          <q-btn v-if="clear" class="q-ma-sm" dense unelevated flat label="Clear" icon="close"
            v-close-popup @click="toggleColor()">
          </q-btn>
        </div>
      </template>

      <template v-else>
        <div>
          <table class="colors">
            <tbody>
              <tr v-for="group in groups" :key="`group-${group}`">
                <td v-for="color in group" :key="`color-${color}`" @click="toggleColor(color)"
                  v-close-popup>
                  <div class="color" :class="colorClass(color)">
                    <q-icon v-if="selectedColor === color" name="fiber_manual_record" size="24px"
                      color="white"></q-icon>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
    </q-popup-proxy>
  </div>
</template>

<style scoped lang="scss">
.buttons {
  border-top: solid #d0d0d0 1px;
}

.color-button {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;

  &.full {
    width: 64px;
    height: 32px;
  }
}

.color-popup {
  max-width: 250px;
}

table.colors {
  border-collapse: collapse;
  border: solid silver 1px;
  border-spacing: 1px;

  tr {
    td {
      padding: 0;
      width: 40px;
      height: 40px;

      .color {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        transition: all 0.1s ease-out;

        &.enabled {
          cursor: pointer;

          &:hover {
            opacity: 0.8;
          }
        }

        &.disabled {
          background-color: #ebebeb;
        }
      }
    }
  }
}
</style>
