<script>
import { mapState, mapActions } from 'vuex'

export default {
  data () {
    return {
    }
  },

  computed: {
    ...mapState({
      announcements: state => state.announcements.newAnnouncements
    }),

    // Unacknowledged announcements
    newAnnouncements () {
      return this.announcements?.filter(a => !a.isAcknowledged) || []
    },

    // Indicates whether there are any unacknowledged announcements
    hasNewAnnouncements () {
      return this.newAnnouncements.length > 0
    },

    // Tooltip indicating the new announcements
    tooltip () {
      const { length } = this.newAnnouncements
      return length === 0
        ? ''
        : (length === 1 ? 'There is a new announcement!' : `There are ${length} new announcements`)
    }
  },

  methods: {
    ...mapActions([
      'showAnnouncementsPopup'
    ])
  }
}
</script>

<template>
  <main v-if="hasNewAnnouncements">
    <q-btn round dense unelevated icon="notifications" color="green-8" size="lg"
      @click.stop="showAnnouncementsPopup()">
      <sc-tooltip :text="tooltip"></sc-tooltip>
    </q-btn>
  </main>
</template>

<style lang='scss' scoped>

</style>
