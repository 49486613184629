<script>
import { mapState, mapGetters } from 'vuex'
import { getDeviceLabel, getPlaceIcon } from '@stellacontrol/model'
import { Secure } from '@stellacontrol/security-ui'
import DeviceWidget from './device-widget'

export default {
  mixins: [
    DeviceWidget,
    Secure
  ],

  computed: {
    ...mapState({
      user: state => state.security.currentUser
    }),

    ...mapGetters([
      'currentOrganization',
      'isSmallScreen'
    ]),

    // Device label to display
    deviceLabel () {
      const { device } = this
      return getDeviceLabel(device)
    },

    place () {
      return this.device.place || {}
    },

    hasPlace () {
      return Boolean(this.device.place)
    },

    hasLocation () {
      return this.device.customLocation || this.device.location
    },

    items () {
      const { device, deviceLabel, isSmallScreen } = this
      const { location, customLocation, firmwareVersionLong, model, place } = device
      const placeText = place?.name
      const locationText = (customLocation || location) ? (customLocation || location) : ''
      const versionText = firmwareVersionLong

      return [
        isSmallScreen ? null : { text: deviceLabel, class: { 'label': true } },
        isSmallScreen ? { text: model, class: { 'label': true } } : null,
        { text: versionText ? `v.${versionText}` : undefined, class: { 'small': true } },
        isSmallScreen ? { text: placeText, class: { 'small': true } } : null,
        { text: locationText, class: { 'small': true } }
      ].filter(item => item?.text)
    }
  },

  methods: {
    getPlaceIcon
  }
}
</script>

<template>
  <sc-widget-text :icon="hasPlace ? getPlaceIcon(device.place.placeType) : null"
    :items="items" min-width="180px">
  </sc-widget-text>
</template>

