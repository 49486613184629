<script>
import { mapGetters } from 'vuex'
import { UserLevelNames } from '@stellacontrol/model'
import { FormMixin } from '@stellacontrol/client-utilities'
import { Secure } from '../../components'

export default {
  mixins: [
    FormMixin,
    Secure
  ],

  props: {
    data: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters([
      'users'
    ]),

    /**
     * User-friendly level name
     */
    userLevel () {
      return UserLevelNames[this.data.level]
    },

    /**
     * Grantor of permissions
     */
    permissionsGrantedBy () {
      const permission = (this.data.permissions || [])[0]
      if (permission) {
        return this.users.find(user => user.id === permission.createdBy)
      }
    },

    /**
     * Date when permissions were granted
     */
    permissionsGrantedOn () {
      return ((this.data.permissions || [])[0] || {}).createdAt
    }
  }
}

</script>

<template>
  <q-form ref="form" autofocus>
    <div class="row">
      <div class="col-12 col-md-9 col-lg-6 q-pa-md q-gutter-sm">
        <q-input v-model="data.name" dense square outlined disable class="q-mb-lg" maxlength="255">
          <template v-slot:before> <label>User Name</label> </template>
        </q-input>

        <q-input v-model="userLevel" dense square outlined disable class="q-mb-lg">
          <template v-slot:before> <label>User Level</label> </template>
        </q-input>

        <q-input v-model="data.firstName" dense square outlined lazy-rules :rules="[rules.required('First name is required')]" maxlength="45">
          <template v-slot:before> <label>First Name</label> </template>
        </q-input>

        <q-input v-model="data.lastName" dense square outlined lazy-rules :rules="[rules.required('Last name is required')]" maxlength="45">
          <template v-slot:before> <label>Last Name</label> </template>
        </q-input>

        <q-input v-model="data.phone" dense square outlined maxlength="45">
          <template v-slot:before> <label>Phone Number</label> </template>
        </q-input>

        <q-field dense borderless v-if="!data.isNew" class="q-pt-xs">
          <template v-slot:before> <label>Created</label> </template>
          <div class="row items-center">
            {{ data.createdText }}
          </div>
        </q-field>

        <q-field dense borderless v-if="!data.isNew">
          <template v-slot:before> <label>Updated</label> </template>
          <div class="row items-center">
             {{ data.updatedText }}
          </div>
        </q-field>

        <q-field dense borderless v-if="!data.isNew && permissionsGrantedBy">
          <template v-slot:before> <label>Permissions granted</label> </template>
          <div class="row items-center">
             {{ datetime(permissionsGrantedOn) }}, {{ permissionsGrantedBy.fullName }}
          </div>
        </q-field>
      </div>
    </div>
  </q-form>
</template>

<style lang='scss' scoped>
label {
  font-size: 14px;
  min-width: 180px;
}
</style>
