<script>
import { getPlaceIcon } from '@stellacontrol/model'
import { CellMixin } from './cell-mixin'

export default {
  mixins: [
    CellMixin
  ],

  props: {
    item: {
      type: Object,
      required: true,
    }
  },

  computed: {
    getPlaceIcon () {
      return placeType => getPlaceIcon(placeType)
    }
  },
}
</script>

<template>
  <div :style="columnStyle">
    <q-icon v-if="item.device.place" :name="getPlaceIcon(item.device.place.placeType)" color="indigo-5" size="20px" />
  </div>
</template>

<style>
</style>