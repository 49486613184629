export function createState () {
  return {
    // Full hierarchy of monitored devices, grouped by organizations and places
    monitoredDevicesHierarchy: null,

    // List of groups of monitored devices, derived from hierarchy, useful for easier traversals
    monitoredDeviceGroups: [],

    // List of monitored devices, derived from hierarchy, useful for easier traversals
    monitoredDevices: [],

    // System-wide default alert configurations, defined in configuration files
    defaultConfigurations: {},

    // Dictionary of alert configurations for the currently logged in organization
    myConfigurations: {},

    // Dictionary of alert notification subscriptions for the current organization
    alertSubscriptions: {}
  }
}

export const state = createState()

export const getters = {
  // System-wide default alert configurations
  defaultAlertConfigurations: state => state.defaultConfigurations,
  hasDefaultAlertConfigurations: state => state.defaultConfigurations != null && Object.keys(state.defaultConfigurations).length > 0
}
