import { PreferenceType } from './preference-type'
import { format } from 'date-fns'

/**
 * Preference
 */
export class Preference {
  constructor (data = {}) {
    this.principalId = data.principalId
    this.isGlobal = data.isGlobal || !this.principalId
    this.name = data.name
    this.type = data.type || PreferenceType.String
    this.value = this.parse(data.value)
  }

  /**
   * Principal owning the preference
   * @type {String}
   */
  principalId

  /**
   * Indicates that this is a global preference.
   * Principal is then the super organization.
   * @type {Boolean}
   */
  isGlobal

  /**
   * Preference name
   * @type {String}
   */
  name

  /**
   * Preference type
   * @type {PreferenceType}
   */
  type

  /**
   * Preference value
   * @type {String|Number|Boolean|Date|Array}
   */
  value

  /**
   * Parses preference value according to its type
   */
  parse (value) {
    if (value == null) {
      return value
    }

    switch (this.type) {
      // Booleans are stored as '0' or '1' or 'true' or 'false' string
      case PreferenceType.Boolean:
        if (typeof value !== 'boolean') {
          value = parseInt(value.toString()) === 1 || value.toString() === 'true'
        }
        break

      // Numbers are stored as serialized to string
      case PreferenceType.Number:
        if (typeof value !== 'number') {
          value = parseInt(value.toString())
          if (isNaN(value)) {
            value = undefined
          }
        }
        break

      // Dates are stored as serialized to ISO datetime string
      case PreferenceType.Date:
        if (!(value instanceof Date)) {
          value = Date.parse(value.toString())
          if (isNaN(value)) {
            value = undefined
          } else {
            value = new Date(value)
          }
        }
        break

      // Lists are stored as comma-separated string
      case PreferenceType.List:
        if (!Array.isArray(value)) {
          value = (value || '').toString().split(',').filter(item => item.trim())
        }
        break

      default:
        value = value.toString()
    }
    return value
  }

  /**
   * Returns a preference value serialized to string
   * @type {String}
   */
  get serializedValue () {
    const { value } = this
    switch (this.type) {
      // Booleans are stored as '0' or '1' string
      case PreferenceType.Boolean:
        return value ? '1' : '0'

      // Dates are stored as ISO datetime string
      case PreferenceType.Date:
        return format(value, 'yyyy-MM-dd')

      // Lists are stored as comma-separated string
      case PreferenceType.List:
        return (value || []).filter(item => item.toString().trim()).join(',')

      // Numbers are stored as-is
      case PreferenceType.Number:
        return value.toString()

      default:
        return (value || '').toString()
    }
  }

  /**
   * Returns true if preference is a boolean
   * @type {Boolean}
   */
  get isBoolean () {
    return this.type === PreferenceType.Boolean
  }

  /**
   * Returns true if preference is a number
   * @type {Boolean}
   */
  get isNumber () {
    return this.type === PreferenceType.Number
  }

  /**
   * Returns true if preference is a string
   * @type {Boolean}
   */
  get isString () {
    return this.type === PreferenceType.String
  }

  /**
   * Returns true if preference is a list
   * @type {Boolean}
   */
  get isList () {
    return this.type === PreferenceType.List
  }

  /**
   * Returns true if preference is a date
   * @type {Boolean}
   */
  get isDate () {
    return this.type === PreferenceType.Date
  }

  /**
   * Creates a string-type preference
   * @param {String} name Preference name
   * @param {String} value Preference value
   * @param {Principal} principal Preference owner, optional
   * @returns {Preference}
   */
  static fromString (name, value, principal) {
    return new Preference({
      name,
      value,
      type: PreferenceType.String,
      principalId: (principal || {}).id
    })
  }

  /**
   * Creates a number-type preference
   * @param {String} name Preference name
   * @param {Number} value Preference value
   * @param {Principal} principal Preference owner, optional
   * @returns {Preference}
   */
  static fromNumber (name, value, principal) {
    return new Preference({
      name,
      value,
      type: PreferenceType.Number,
      principalId: (principal || {}).id
    })
  }

  /**
   * Creates a boolean-type preference
   * @param {String} name Preference name
   * @param {Boolean} value Preference value
   * @param {Principal} principal Preference owner, optional
   * @returns {Preference}
   */
  static fromBoolean (name, value, principal) {
    return new Preference({
      name,
      value,
      type: PreferenceType.Boolean,
      principalId: (principal || {}).id
    })
  }

  /**
   * Creates a date-type preference
   * @param {String} name Preference name
   * @param {Date} value Preference value
   * @param {Principal} principal Preference owner, optional
   * @returns {Preference}
   */
  static fromDate (name, value, principal) {
    return new Preference({
      name,
      value,
      type: PreferenceType.Date,
      principalId: (principal || {}).id
    })
  }

  /**
   * Creates a list-type preference
   * @param name Preference name
   * @param values Preference values
   * @param principal Preference owner, optional
   */
  static fromArray (name, value = [], principal) {
    return new Preference({
      name,
      value,
      type: PreferenceType.List,
      principalId: (principal || {}).id
    })
  }

  /**
   * Creates a types preference from the specified value
   * @param {String} name Preference name
   * @param {String|Number|Boolean|Date|Array} value Preference value
   * @param {Principal} principal Preference owner, optional
   * @returns {Preference}
   */
  static from (name, value, principal) {
    if (Array.isArray(value)) {
      return Preference.fromArray(name, value, principal)
    }
    if (typeof value === 'boolean') {
      return Preference.fromBoolean(name, value, principal)
    }
    if (typeof value === 'number') {
      return Preference.fromNumber(name, value, principal)
    }
    if (value instanceof Date) {
      return Preference.fromDate(name, value, principal)
    }
    return Preference.fromString(name, (value || '').toString(), principal)
  }
}
