<script>
import { getDateFormat, formatDate } from '@stellacontrol/utilities'

export default {
  props: {
    /**
     * Initial value
     */
    modelValue: {
      required: true
    },

    /**
     * Label displayed in the text input
     */
    label: {
      type: String
    },

    /**
     * Label displayed before the text input
     */
    labelBefore: {
      type: String
    },

    /**
     * Hint displayed after the text input
     */
    hint: {
      type: String
    },

    /**
     * Date format
     */
    format: {
      type: String,
      default: getDateFormat().toUpperCase()
    },

    /**
     * First day of week, Monday by default
     */
    firstDayOfWeek: {
      type: Number,
      default: 1
    },

    /**
     * If true, today will be inserted as default value
     */
    defaultToday: {
      type: Boolean,
      default: true
    },

    /**
     * If true, field is quickly clearable with handy cross icon
     */
    clearable: {
      type: Boolean,
      default: false
    },

    /**
     * Quasar q-date properties
     */
    minimal: {
      type: Boolean,
      default: true
    },

    todayButton: {
      type: Boolean,
      default: true
    },

    /**
     * Quasar q-input properties
     */
    square: {
      type: Boolean,
      default: true
    },

    outlined: {
      type: Boolean,
      default: true
    },

    dense: {
      type: Boolean,
      default: true
    },

    readonly: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    rules: {
      type: Array
    },

    lazyRules: {
      type: Boolean,
      default: false
    },

    bgColor: {
      type: String
    },

    inputStyle: {
      type: Object,
      default: () => { }
    }
  },

  data () {
    return {
      date: null,
      text: ''
    }
  },

  computed: {
    mask () {
      return this.format.replace(/[A-z]/g, '#')
    },

    dateString () {
      const { date } = this
      return date ? formatDate(date, 'yyyy/MM/dd') : undefined
    }
  },

  methods: {
    /**
     * Called when date selected in date picker,
     * stores the selected date in the model
     * and updates the text field
     */
    setDate ({ year, month, day, emit = true }) {
      this.date = Quasar.date.buildDate({ year, month, date: day })
      this.text = Quasar.date.formatDate(this.date, this.format)
      if (emit) {
        this.$emit('update:model-value', this.date)
      }
    },

    /**
     * Called when text field is edited,
     * stores the entered date in the model
     */
    setText ({ value = '', emit = true }) {
      if (!value && this.defaultToday) {
        this.date = new Date()
        this.text = Quasar.date.formatDate(this.date, this.format)
      }
      else if (value && this.isDate(value)) {
        this.date = Quasar.date.extractDate(value, this.format)
        this.text = Quasar.date.formatDate(this.date, this.format)
      } else {
        this.clear()
      }
      if (emit) {
        this.$emit('update:model-value', this.date)
      }
    },

    /**
     * Checks if the specified value is a valid date.
     * Empty input is treaded as valid - if you want to enforce
     * the input, use an additional `required` rule
     */
    isDate (value = '') {
      if (value) {
        const invalidDate = Quasar.date.extractDate('', this.format)
        const date = Quasar.date.extractDate(value, this.format)
        return date && date.toString() !== invalidDate.toString()
      } else {
        return true
      }
    },

    // Clears the input
    clear () {
      this.date = undefined
      this.text = ''
    }
  },

  watch: {
    modelValue (date) {
      if (date) {
        this.setDate({
          year: date.getFullYear(),
          month: date.getMonth() + 1,
          day: date.getDate(),
          emit: false
        })
      }
    }
  },

  mounted () {
    const { modelValue, defaultToday } = this
    const date = modelValue || (defaultToday ? new Date() : null)
    if (date) {
      this.setDate({
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate(),
        emit: false
      })
    }
  }
}
</script>

<template>
  <q-input
    v-model="text"
    @update:model-value="value => setText({ value })"
    :square="square"
    :outlined="outlined"
    :dense="dense"
    :readonly="readonly"
    :disabled="disabled"
    :label="label"
    :mask="mask"
    :clearable="clearable"
    :rules="[ value => isDate(value) || 'Invalid date', ...rules || [] ]"
    :lazy-rules="lazyRules"
    debounce="500"
    hide-bottom-space
    :bg-color="bgColor"
    :inputStyle="{ ...inputStyle, 'padding-right': '4px' }">

    <template v-slot:append>
      <q-icon name="event" class="cursor-pointer">
        <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
          <q-date :model-value="dateString" :minimal="minimal" :first-day-of-week="firstDayOfWeek"
            :today-btn="todayButton"
            @update:model-value="(value, reason, details) => { $refs.qDateProxy.hide(); setDate(details) }">
          </q-date>
        </q-popup-proxy>
      </q-icon>
    </template>
    <template v-slot:before v-if="labelBefore">
      <label>{{ labelBefore }}</label>
    </template>
    <template v-slot:after v-if="hint">
      <sc-hint :text="hint"></sc-hint>
    </template>
  </q-input>
</template>
