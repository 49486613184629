import Konva from 'konva'
import { PlanItemType } from '@stellacontrol/planner'
import { Shape } from './shape'

/**
 * Text
 */
export class TextShape extends Shape {
  constructor (item, dataCallback) {
    super(item, dataCallback)
    this.createShapes()
  }

  static get type () {
    return PlanItemType.Text
  }

  // Used to detect a change in line count,
  // when item height needs to be adjusted
  __lineCount

  /**
   * Text shape
   * @type {Konva.Shape}
   */
  textShape

  /**
   * Text background shape
   * @type {Konva.Shape}
   */
  backgroundShape

  /**
   * Determines whether text has any background or border
   * @type {Boolean}
   */
  get hasBackground () {
    const { item } = this
    return item && !(item.backgroundStyle.isEmpty && item.lineStyle.isEmpty)
  }

  createShapes () {
    super.createShapes()

    const { item } = this

    this.backgroundShape = new Konva.Rect()
    this.content.add(this.backgroundShape)
    this.textShape = new Konva.Text()
    this.content.add(this.textShape)

    this.__lineCount = item.lineCount
  }

  render (renderer) {
    super.render(renderer)
    const { item, textShape, hasBackground, backgroundShape } = this
    const { lineStyle, backgroundStyle, textStyle, label, padding, width, height } = item

    backgroundShape.width(width)
    backgroundShape.height(height)

    if (hasBackground) {
      backgroundShape.fill(backgroundStyle.color)
      backgroundShape.stroke(lineStyle.color)
      backgroundShape.strokeWidth(lineStyle.width)
      backgroundShape.cornerRadius(lineStyle.radius)
      backgroundShape.dash(lineStyle.dash)
    }

    textShape.x(hasBackground ? padding.left : 2)
    textShape.y(hasBackground ? padding.top : 2)
    textShape.width(hasBackground ? (width - padding.left - padding.right) : width)

    textShape.wrap('none')
    textShape.text(label)
    textShape.fill(textStyle.color)
    textShape.fontFamily(textStyle.font)
    textShape.fontSize(textStyle.size)
    textShape.lineHeight(textStyle.lineHeight)
    textShape.align(item.textStyle.alignment)
    textShape.verticalAlign(textStyle.verticalAlignment)
    textShape.textDecoration(textStyle.underline ? 'underline' : undefined)

    // Adjust item height if line count changed
    if (this.__lineCount != item.lineCount) {
      this.__lineCount = item.lineCount
      item.height = textShape.height() + padding.vertical + (hasBackground ? lineStyle.width * 2 : 0)
      backgroundShape.height(item.height)
    }
  }

  /**
   * Wires up event handlers
   * @param {PlanRenderer} renderer Plan renderer
   */
  bindShapeEvents (renderer) {
    super.bindShapeEvents(renderer)
    // Force the shape to capture events - by default it does not
    this.textShape.listening(true)
  }

  /**
   * Triggered when shape has been transformed.
   * @param {PlanRenderer} renderer Plan renderer
   * @param {PlanScale} scale Scale of the shape
   * @param {Number} rotation Shape rotation
   */
  transformed ({ renderer, scale, rotation }) {
    super.transformed({ renderer, scale, rotation })

    // Change the sizes
    const { item } = this
    item.width = Math.round(item.width * scale.x)
    item.height = Math.round(item.height * scale.y)

    // Adjust font size so that the text fills the box
    if (item.autoAdjustFontSize) {
      const height = (item.lineCount * item.textStyle.size * item.textStyle.lineHeight) + item.padding.vertical
      const ratio = height / item.height
      item.textStyle.size = Math.max(7, Math.ceil(item.textStyle.size / ratio))
    }

    // Re-render the shape
    this.render(renderer)
  }

}
