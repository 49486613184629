<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    // Show Back-to-Login button if user is currently logged in
    ifLoggedIn: {
      type: Boolean
    },

    // Show Back-to-Login button if user is currently logged out
    ifLoggedOut: {
      type: Boolean
    },

    // Button label
    label: {
      type: String,
      value: 'Go back to login page'
    }
  },

  computed: {
    ...mapGetters([
      'isLoggedIn'
    ]),

    isVisible () {
      if (this.isLoggedIn) {
        return this.ifLoggedIn
      } else {
        return this.ifLoggedOut
      }
    }
  },

  methods: {
    ...mapActions([
      'gotoLogin'
    ])
  }
}
</script>

<template>
  <q-btn unelevated :label="label"
    v-if="isVisible"
    @click="gotoLogin()">
  </q-btn>
</template>

<style>

</style>