import { createState } from './common.state'

export const mutations = {
  /**
   * Stores about data in the store
   * @param about About... data
   */
  storeAboutData (state, { about = {} } = {}) {
    state.about = about
  },

  /**
   * Resets the state to original shape.
   * @description
   * Presence of this mutation is obligatory on stores,
   * to ensure purging of any user-bound data when
   * user logs in and out and in. Otherwise expect
   * unexpected side effects with data of the previously
   * logged in user appearing at places.
   */
  reset (state) {
    Object.assign(state, createState())
  }
}
