import { subDays, startOfDay, endOfDay } from 'date-fns'
import { AlertStatisticsPartKey } from '@stellacontrol/model'

export function createState () {
  return {
    // Alerts statistics per organization
    statistics: [],
    // Merged alert statistics for all organizations
    mergedStatistics: null,
    // Statistics part viewed in details
    part: null,
    // Indicates whether part data has been loaded,
    // with a slight delay for the scroller to settle down with rendering
    partLoaded: false,
    // Indicates that loading failed, i.e. due to timeout because of too long of a period
    error: null,
    // Status of refreshing the displayed details
    refresh: {
      // Refresh clock
      clock: null,
      // Refresh frequency, in seconds
      frequency: 60,
      // Countdown to the next refresh, in seconds
      countdown: 0,
      // Indicates that we're currently refreshing the statistics details
      isRefreshing: false
    },
    // Alert statistics filter
    statisticsFilter: {
      // Organization looked at
      organizationId: null,
      // Statistics period
      from: startOfDay(subDays(new Date(), 1)),
      until: endOfDay(new Date()),
      // Maximal allowed period length, in days
      maximalPeriod: 31,
      // Include all child organizations in the statistics
      all: true,
      // Free-text filters in statistics details, separate per each statistics part
      detailsFilter: {},
      // Condition validators for statistics details, per statistics part
      detailsConditions: {}
    }
  }
}

export const state = createState()

export const getters = {
  // Items loaded in the details view
  alertStatisticsDetails: state =>
    statistics => {
      const { part, partLoaded } = state
      if (part && partLoaded) {
        const key = AlertStatisticsPartKey[part]
        const items = statistics?.details[key] || []
        return items
      }
    },

  // Items visible in details view after applying filters
  visibleAlertStatisticsDetails: state =>
    statistics => {
      const { part, partLoaded, statisticsFilter: { detailsConditions, detailsFilter } } = state
      if (part && partLoaded) {
        const key = AlertStatisticsPartKey[part]
        const filter = detailsFilter[part]
        const conditions = detailsConditions[part]
        const permittedItems = (statistics?.details[key] || []).filter(item => item.isPermitted !== false)
        if (filter && conditions) {
          const itemMatches = item => conditions.match(Object.values(item).join(''))
          return permittedItems.filter(item => itemMatches(item))
        } else {
          return permittedItems
        }
      }
    }
}
