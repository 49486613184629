/**
 * Premium service status
 */
export const PremiumServiceStatus = {
  // No premium service is associated with device / organization
  None: 'none',
  // Premium service is associated with device / organization but subscription not started yet
  NotStarted: 'not-started',
  // Premium service is associated with device / organization and subscription is started
  // but not yet active, because start date is still in the future!
  Inactive: 'inactive',
  // Premium service is associated with device / organization and subscription is active
  Active: 'active',
  // Premium service is associated with device / organization but subscription has expired
  Expired: 'expired'
}