import PremiumServicesView from './premium-services.vue'
import { resolve } from './premium-services.resolve'

export const Routes = [
  {
    name: 'premium-services',
    path: '/premium-services/pricelists',
    component: PremiumServicesView,

    async beforeEnter (to, from, next) {
      if (await resolve({ to, from })) {
        next()
      }
    }
  }
]
