import { dispatch, dispatchParallel, isRouteDataChanged } from '@stellacontrol/client-utilities'

/**
 * Prepares data for users route
 */
export async function resolveUsers () {
  return await dispatch('getUsers')
}

/**
 * Prepares data for user route
 */
export async function resolveUser ({ from, to }) {
  if (!isRouteDataChanged(from, to)) {
    return true
  }

  await dispatchParallel([
    'requireOrganizationProfiles',
    'requireOrganizations',
    'requireUsers'
  ])

  const { params: { id } } = to
  const { query: { organization: organizationId } } = to
  const organization = organizationId
    ? await dispatch('getOrganization', { id: organizationId })
    : await dispatch('getCurrentOrganization')
  const data = id === 'new'
    ? await dispatch('newUser', { user: { organization } })
    : await dispatch('getUser', { id })

  if (data) {
    await dispatch('setRouteData', { from, to, data, organizationId })
    return data
  }
}
