import Konva from 'konva'
import { Rectangle, subtractPolygon } from '@stellacontrol/utilities'
import { Layer } from './layer'
import { moveToTop, moveToBottom } from '../utilities'

/**
 * Radiation map layer
 */
export class RadiationLayer extends Layer {
  constructor (data) {
    super(data)
  }

  /**
   * Rendering parameters
   */
  parameters = {
    blurRadius: 12,
    opacity: 0.9,
    blendMode: 'multiply',
    gradient: [0, 'red', 0.4, 'yellow', 0.6, 'greenyellow', 1.0, 'white']
  }

  /**
   * Heatmap layer
   * @type {Konva.Group}
   */
  heatmap

  /**
   * Plan items reflected on the heatmap
   * @type {Dictionary<String, Konva.Shape>}
   */
  items = {}

  /**
   * Heatmap shapes representing plan items
   * @type {Dictionary<String, Konva.Shape>}
   */
  points = {}

  /**
   * Returns all antenna items on the plan
   * @type {Array[PlanAntenna]}
   */
  get antennae () {
    return this.floor.items.filter(i => i.isAntenna)
  }

  /**
   * Mask covering everything outside the building perimeter
   * defined as the first of the {@link walls}
   * @type {Konva.Group}
   */
  outside

  /**
   * Returns all shapes representing building confines
   * within which mobile signal is present
   * @type {Array[Konva.Shape]}
   */
  get walls () {
    return this.shapes.filter(s => s.item?.isWall)
  }

  /**
   * Returns true if radiation layer has any walls
   * @type {Boolean}
   */
  get hasWalls () {
    // Any walls must be completed!
    return this.walls.length > 0 &&
      this.walls.every(wall => !wall.item.inProgress)
  }

  /**
   * Returns all shapes representing masks used to delineate
   * inner yards where signal doesn't reach
   * @type {Array[Konva.Shape]}
   */
  get yards () {
    return this.shapes.filter(s => s.item?.isYard)
  }

  /**
   * Returns all shapes representing beams
   * @type {Array[Konva.Shape]}
   */
  get beams () {
    return this.shapes.filter(s => s.item?.key === 'beam')
  }

  /**
   * Creates own shapes of the layer
   * @return {Promise<Array[Shape|Konva.Shape]>}
   */
  async createOwnShapes () {
    const { floor, antennae } = this
    if (!floor.hasRadiation) {
      return []
    }

    // Remove previous elements
    this.destroy(this.heatmap, this.outside)

    // Heatmap layer
    this.heatmap = new Konva.Group({
      draggable: false,
      listening: false
    })

    // Outside perimeter
    this.outside = new Konva.Group({
      listening: false,
      x: 0,
      y: 0
    })
    const contour = new Konva.Line({
      fill: 'white',
      stroke: null,
      strokeWidth: 0,
      closed: true
    })
    this.outside.add(contour)

    // Add antenna items
    for (const antenna of antennae) {
      this.itemAdded(antenna)
    }

    // Mask around the entire building shape
    this.renderOutsideMask()

    // Lock walls and yards
    this.lockWalls(floor.radiation.lockWalls)

    return [this.heatmap, this.outside]
  }

  /**
   * Creates a masking shape over the outside of the building
   */
  renderOutsideMask () {
    const { floor, outside, walls, hasWalls } = this
    if (!floor.hasRadiation) return

    if (hasWalls && floor.radiation.maskOutside) {
      const wall = walls[0]
      const item = wall.item
      const bounds = Rectangle
        .dimensions(floor.dimensions)
        .growBy(floor.margin)
      const contour = this.outside.getChildren()[0]
      const contourPoints = subtractPolygon(bounds, item.points).flatMap(p => ([p.x, p.y]))
      contour.points(contourPoints)
      outside.show()

    } else {
      outside.hide()
    }

    this.reorder()
  }

  /**
   * Locks/unlocks walls and yards for modifications by the user
   * @param {Boolean} status Lock status
   */
  lockWalls (status) {
    const { floor, walls, yards } = this
    if (!floor.hasRadiation) return

    const isLocked = status == null ? floor.radiation.lockWalls : Boolean(status)
    floor.radiation.lockWalls = isLocked

    const wallsAndYards = floor.items.filter(i => i.isWall || i.isYard)
    for (const item of wallsAndYards) {
      item.isLocked = isLocked
    }

    for (const wall of walls) {
      wall.content.listening(!isLocked)
    }

    for (const yard of yards) {
      yard.content.listening(!isLocked)
    }
  }

  /**
   * Refreshes the layer
   */
  refresh () {
    const { floor } = this
    super.refresh()
    if (floor.hasRadiation) {
      this.renderOutsideMask()
      this.lockWalls(floor.radiation.lockWalls)
      this.reorder()
    }
  }

  /**
   * Assigns correct z-order to shapes on the layer
   * @returns {Layer} Self-reference if reordering was performed
   */
  reorder () {
    if (super.reorder()) {
      const { content, floor, heatmap, beams, outside, walls, yards } = this
      if (!floor.hasRadiation) return

      // Reordering is not possible when layer is still not at the stage
      if (!content.getParent()) return

      // Walls Masks must be on top of the heatmap
      for (const wall of walls) {
        moveToBottom(wall)
      }

      // Heatmap beams must be on top of the walls
      moveToTop(heatmap)
      for (const beam of beams) {
        moveToTop(beam)
      }

      // If outside masking is turned on
      // yard masks must be on top of all shapes,
      if (floor.radiation.maskOutside) {
        moveToTop(outside)
        for (const yard of yards) {
          moveToTop(yard)
        }
      } else {
        // If outside masking is turned off,
        // bring all the heatmap elements to the very top
        for (const beam of beams) {
          moveToTop(beam)
          moveToTop(heatmap)
        }
      }
    }
  }

  /**
   * Clears the layer
  */
  clear () {
    super.clear()
  }

  /**
   * Creates radiation shape for the specified plan item
   * @param {PlanItem} item Plan item generating radiation, such as antenna
   */
  createRadiationBlob (item) {
    const { layout, floor } = this

    if (!floor.hasRadiation) return
    if (layout.isExternalAntenna(item)) return

    // Render radiation pattern of antenna
    let blob

    // Omnidirectional antennae
    if (item.isOmnidirectionalAntenna) {
      blob = new Konva.Circle({
        draggable: false,
        listening: false
      })
    }

    if (blob) {
      const shape = new Konva.Group({
        id: item.id,
        draggable: false,
        listening: false
      })

      // Remember the antenna type. If changed later, we will have to re-create the element
      shape.setAttr('antennaType', item.antennaType)
      shape.add(blob)
      this.placeRadiationBlob(item, shape)
      this.refreshRadiationBlob(item, shape)
      this.items[item.id] = item
      this.points[item.id] = shape
      this.heatmap.add(shape)
    }
  }

  /**
   * Deletes radiation shape for the specified plan item
   * @param {PlanItem} item Plan item generating radiation, such as antenna
   */
  deleteRadiationBlob (item) {
    if (!this.floor.hasRadiation) return

    const shape = this.points[item.id]
    if (shape) {
      shape.destroy()
      delete this.points[item.id]
      delete this.items[item.id]
    }
  }

  /**
   * Positions the element representing radiation pattern around the specified plan item
   * @param {PlanItem} item Item whose radiation pattern to position
   * @param {Konva.Shape} shape Shape representing the item
   */
  placeRadiationBlob (item, shape) {
    if (!this.floor.hasRadiation) return

    if (item && shape) {
      const { x, y } = item
      shape.x(x)
      shape.y(y)

      if (item.isPanel) {
        shape.x(x + item.width / 2)
        shape.y(y + item.height / 2)
      }
    }
  }

  /**
   * Refreshes the element representing radiation pattern around the specified plan item
   * @param {PlanItem} item Item whose radiation pattern to refresh
   * @param {Konva.Shape} shape Shape representing the item
   */
  refreshRadiationBlob (item, shape) {
    if (!this.floor.hasRadiation) return

    if (item && shape) {
      const blob = shape.getChildren()[0]
      if (!blob) return

      const { renderer: { layout, equipmentHierarchy }, parameters: { blurRadius, gradient, blendMode }, floor, floor: { radiation } } = this
      const cableStats = layout.getCableStats(item, equipmentHierarchy)

      // Omni-directional antenna
      shape.hide()
      if (item.isOmnidirectionalAntenna) {
        // Determine radiation radius, taking into consideration signal gain/loss on the way from the repeater.
        // Then, apply the global scaling factor for antennae on the floor
        const pixelRadius = cableStats
          ? Math.round(floor.metersToPixels(cableStats.radiation.radius) * (radiation.strength / 100) * (item.radiationStrength / 100))
          : Math.round(floor.metersToPixels(item.parameters.radius) * (radiation.strength / 100) * (item.radiationStrength / 100))
        if (pixelRadius > 0 && (pixelRadius - blurRadius) > 0) {
          blob.radius(pixelRadius)
          blob.fillRadialGradientStartRadius(0)
          blob.fillRadialGradientEndRadius(pixelRadius - blurRadius)
          blob.fillRadialGradientColorStops(gradient)
          shape.show()
        }
      }

      // Blur the radiation pattern
      if (shape.visible() && shape.hasChildren()) {
        moveToTop(shape)
        // Omnidirectional antennae
        if (item.isOmnidirectionalAntenna) {
          shape.opacity(this.parameters.opacity)
          shape.cache()
          shape.filters([Konva.Filters.Blur])
          shape.blurRadius(blurRadius)
          shape.globalCompositeOperation(blendMode)
          shape.globalCompositeOperation('multiply')
        }
      }
    }
  }

  /**
   * Notifies that the specified item has been added to the plan
   * @param {PlanItem} item Added item
   */
  itemAdded (item) {
    if (!item) return
    if (!this.floor.hasRadiation) return

    // Render radiation blob for antennae
    if (item.isAntenna) {
      this.createRadiationBlob(item)
      this.renderOutsideMask()
    }

    // Auto-create outside mask when building wall has been added
    if (item.isWall) {
      this.renderOutsideMask()
    }

    // Lock wall and mask items
    if (item.isWall || item.isYard) {
      item.isLocked = this.floor.radiation.lockWalls
    }
  }

  /**
   * Notifies that the specified item has been removed from the plan
   * @param {PlanItem} item Removed item
   */
  itemRemoved (item) {
    if (!item) return
    if (!this.floor.hasRadiation) return

    // Remove the item shape
    super.itemRemoved(item)

    // If antenna removed, delete the accompanying radiation blob
    if (item.isAntenna) {
      this.deleteRadiationBlob(item)
    }

    if (item.isWall) {
      this.renderOutsideMask()
    }
  }

  /**
   * Notifies that the specified item has been moved
   * @param {PlanItem} item Moved item
   * @param {Boolean} completed If true, the movement has completed (usually when user releases the mouse button)
  */
  // eslint-disable-next-line no-unused-vars
  itemMoved (item, completed) {
    if (!item) return
    if (!this.floor.hasRadiation) return

    const { renderer, points } = this

    // If antenna moved, move its radiation blob
    if (item.isAntenna) {
      const shape = points[item.id]
      this.placeRadiationBlob(item, shape)
      this.refreshRadiationBlob(item, shape)

    } else {
      // For other items, check whether they're connected to antennae
      // and update the respective radiation blobs
      const antennae = item.isPlug
        ? renderer.floor.items.filter(i => i.isAntenna)
        : renderer.findConnectedEquipment(item, item => item.antennaType)
      for (const antenna of antennae) {
        const shape = points[antenna.id]
        this.refreshRadiationBlob(antenna, shape)
      }
    }

    // If wall moved, update the outer mask
    if (item.isWall) {
      this.renderOutsideMask()
    }
  }

  /**
   * Notifies that the specified item properties have changed
   * @param {PlanItem} item Changed item
   */
  itemChanged (item) {
    if (!item) return
    if (!this.floor.hasRadiation) return

    // If antenna properties changed, its radiation blob may need to be re-created or just redrawn
    if (item.isAntenna) {
      const shape = this.points[item.id]
      if (shape) {
        // If antenna type changed, re-create its radiation blob
        if (item.isAntenna) {
          if (shape.getAttr('antennaType') !== item.antennaType) {
            this.itemRemoved(item)
            this.itemAdded(item)
          } else {
            this.refreshRadiationBlob(item, shape)
          }
        }
      }
    }

    // If wall changed, re-create the outside mask
    if (item.isWall) {
      this.renderOutsideMask()
    }
  }
}
