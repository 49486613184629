import { SecurityAPI } from '@stellacontrol/client-api'

export const actions = {
  /**
   * Retrieves features
   * @description This is needed only once, when the application starts
   */
  async getFeatures ({ commit }) {
    const features = await SecurityAPI.getFeatures()
    commit('storeFeatures', { features })
  }
}
