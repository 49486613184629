export function createState () {
  return {
  }
}

export const state = createState()


export const getters = {
}
