/**
 * Client actions
 */
export const actions = {
  /**
   * Triggered when session ends
   */
  endSession ({ commit }) {
    // Disconnect all active push listeners
    commit('removePushListeners')
  }
}
