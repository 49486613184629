import { state, getters } from './attachments.state'
import { mutations } from './attachments.mutations'
import { actions } from './attachments.actions'

export default {
  state,
  getters,
  mutations,
  actions
}
