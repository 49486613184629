<script>
import { DaySchedule } from '@stellacontrol/utilities'
import AlertConfigurationComponent from './alert-configuration-component'

// Schedule selector, allowing to select multiple time ranges within day.
// For simple schedules with only one time range better use alert-configuration-schedule-simple.vue
export default {
  mixins: [
    AlertConfigurationComponent
  ],

  data () {
    return {
      schedule: new DaySchedule()
    }
  },

  computed: {
    scheduleIsModified () {
      return this.isModified('schedule')
    },

    scheduleIsNotDefault () {
      return this.isNotDefault('schedule', value => `<br>Default schedule is ${value}. `)
    },

    scheduleText () {
      return this.schedule.toString({ readable: true, hours: true })
    }
  },

  watch: {
    configuration () {
      const { configuration } = this
      if (configuration) {
        this.schedule = DaySchedule.fromString(this.configuration.schedule)
      } else {
        this.schedule = new DaySchedule()
      }
    },

    schedule (value) {
      if (value) {
        this.configuration.schedule = value.toString()
      } else {
        this.configuration.schedule = ''
      }
    }
  },

  created () {
    this.schedule = DaySchedule.fromString(this.configuration.schedule)
  }
}
</script>

<template>
  <article>
    <section>
        <label :class="{ modified: scheduleIsModified }">
          Check alert at
          <sc-hint text="The data will be monitored and collected only during the selected hours. The alert will be checked and reported at the end of each monitored period." size="20px">
          </sc-hint>
        </label>
        <div v-if="isEditable">
          <sc-time-schedule-input v-model="schedule" :rows="2">
          </sc-time-schedule-input>
        </div>
        <div v-else>
          {{ scheduleText }}
        </div>
    </section>
  </article>
</template>

<style scoped lang="scss">

</style>