<script>
import { mapState, mapActions } from 'vuex'
import { DialogMixin } from '@stellacontrol/client-utilities'
import { FormMixin } from '@stellacontrol/client-utilities'
import { PasswordPolicy } from '@stellacontrol/security'
import { Secure } from '../../components/secure-component'

const dialog = 'change-password'

export default {
  mixins: [
    DialogMixin,
    FormMixin,
    Secure
  ],

  data () {
    return {
      dialog,
      newPassword: '',
      repeatPassword: '',
      revealPassword: false,
      passwordPolicy: null,
      passwordErrors: null,
      passwordPolicyChecked: false
    }
  },

  computed: {
    ...mapState({
      // Password security policy
      passwordPolicySettings: state => state.client.configuration.security.passwordPolicy
    }),

    // Indicates whether password is ready to be checked
    canBeChecked () {
      const { newPassword, repeatPassword } = this
      return (newPassword && repeatPassword && newPassword.trim() && newPassword === repeatPassword)
    },

    // Indicates whether password can be changed -
    // inputs are OK and it meets policy requirements
    canBeChanged () {
      return this.canBeChecked && !this.passwordErrors
    },

    // Returns true if password has violated the specified policy requirement
    hasError () {
      return error => (this.passwordErrors || []).some(item => item.error === error)
    }
  },

  methods: {
    ...mapActions([
      'dialogOk',
      'dialogCancel'
    ]),

    initialize () {
      this.newPassword = ''
      this.repeatPassword = ''
    },

    // Checks the password against the password policy
    checkPasswordPolicy () {
      const { newPassword, canBeChecked } = this
      if (canBeChecked) {
        const { ok, errors } = this.passwordPolicy.check(newPassword)
        this.passwordErrors = errors && errors.length > 0 ? errors : null
        this.passwordPolicyChecked = true
        return ok
      } else {
        this.passwordPolicyChecked = false
        this.passwordErrors = null
        return true
      }
    },

    // Changes the password
    async ok () {
      if (await this.$refs.form.validate() && this.checkPasswordPolicy()) {
        const { newPassword: password } = this
        this.dialogOk({ dialog, data: { password } })
      }
    },

    // Cancels the dialog
    cancel () {
      this.dialogCancel({ dialog })
    }
  },

  created () {
    this.passwordPolicy = new PasswordPolicy(this.passwordPolicySettings)
  }
}

</script>

<template>

  <sc-dialog dialog="change-password" persistent v-if="isVisible" @dialogShown="initialize()">
    <!-- sc-dialog component fetches dialog data when shown,
         and stores in its `data` property. We can access this
         data here in the template through slot binding. -->
    <template #default="{ data }">
      <q-form autofocus ref="form" class="q-pa-md q-mt-md">

        <div class="text-h6 q-pb-lg">
          {{ currentUser.id === data.user.id ? 'Change my password' : `${data.user.fullName} (${data.user.name})` }}
        </div>

        <div class="column q-pt-sm q-pl-lg q-pr-lg q-gutter-md">

          <q-input square outlined
            label="New password"
            v-model="newPassword"
            autocomplete="current-password"
            :type="revealPassword ? 'text' : 'password'"
            debounce="500"
            maxlength="45"
            @update:model-value="() => checkPasswordPolicy()"
            lazy-rules
            :rules="[
              rules.required('New password is required')
            ]">
            <template v-slot:append>
              <q-icon name="visibility" class="cursor-pointer"
                :color="revealPassword ? 'green-7' : 'gray-6'"
                @click="revealPassword = !revealPassword" />
            </template>
          </q-input>

          <q-input square outlined
            label="Repeat the new password"
            v-model="repeatPassword"
            autocomplete="new-password"
            :type="revealPassword ? 'text' : 'password'"
            debounce="500"
            maxlength="45"
            @update:model-value="() => checkPasswordPolicy()"
            lazy-rules
            :rules="[
              rules.required('New password must be repeated required'),
              value => value === newPassword || 'Both passwords must be identical'
            ]">
            <template v-slot:append>
              <q-icon name="visibility" class="cursor-pointer"
                :color="revealPassword ? 'green-7' : 'gray-6'"
                @click="revealPassword = !revealPassword" />
            </template>
          </q-input>

          <div class="errors" v-if="passwordPolicy.checks.length > 0">
            <div :class="{ 'text-bold': true, 'text-red-6': passwordErrors }">
              {{ passwordErrors ? 'The new password is not strong enough:' : 'Password must meet the following requirements:' }}
            </div>

            <div class="row items-center q-mt-md" v-for="check in passwordPolicy.checks" :key="check.error">
              <q-icon size="24px"
                :name="hasError(check.error) ? 'error' : 'check_circle'"
                :color="passwordPolicyChecked ? (hasError(check.error) ? 'red-7' : 'green-8') : 'indigo-5'" />
              <span class="q-ml-sm"
                :class="{ 'text-red-6': hasError(check.error), 'text-green-8': passwordPolicyChecked && !hasError(check.error) }">
                {{ check.description }}
              </span>
            </div>
          </div>

          <div class="row q-mb-lg q-gutter-md justify-end">
            <q-btn label="Close" unelevated @click="cancel()" />
            <q-btn label="Change Password" unelevated class="primary" @click="ok()" :disabled="!canBeChanged" />
          </div>
        </div>

      </q-form>
    </template>
  </sc-dialog>

</template>

<style lang="scss" scoped>
.q-form {
  min-width: 550px;
}
</style>
