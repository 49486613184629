<script>
import { FormValidationRules as rules } from '@stellacontrol/client-utilities'
import AlertConfigurationComponent from './alert-configuration-component'

export default {
  mixins: [
    AlertConfigurationComponent
  ],

  data () {
    return {
      rules
    }
  },

  computed: {
    min () {
      return this.configuration.parameters.minTemperature
    },

    max () {
      return this.configuration.parameters.maxTemperature
    },

    temperatureIsModified () {
      return this.isModified(
        configuration => configuration.parameters.temperature
      )
    },

    temperatureIsNotDefault () {
      return this.isNotDefault(
        configuration => configuration.parameters.temperature,
        value => `Default maximal temperature is ${value} °C.`
      )
    }
  }
}
</script>

<template>
  <article>
    <section v-if="isEditable && advancedConfiguration">
      <label :class="{ modified: temperatureIsModified }">
        Maximal Temperature
        <sc-hint
          :text="`The alert will be triggered when temperature on PCB exceeds ${configuration.parameters.temperature} °C. ${temperatureIsNotDefault}`"
          size="20px" />
      </label>
      <div>
        <q-input v-model.number="configuration.parameters.temperature" type="number" dense outlined
          square
          :rules="[rules.isNumber(min, max, `The value has to be between ${min}°C and ${max}°C`)]"
          debounce="500" :min="min" :max="max" :input-style="{ 'max-width': '130px' }">
          <template v-slot:prepend>
            <span class="text-body2 text-grey-7">°C</span>
          </template>
        </q-input>
      </div>
    </section>
  </article>
</template>

<style scoped lang="scss">
</style>