import { Point } from '@stellacontrol/utilities'
import { PlanAction, PlanActions } from './plan-action'

/**
 * Moves items on the plan
 */
export class MoveItemsAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.MoveItems
  }

  /**
   * Indicates that the action requires items to act on
   * @type {Boolean}
   */
  get requiresItems () {
    return true
  }

  /**
   * Indicates whether action requires refresh
   * @type {Boolean}
   */
  get requiresRefresh () {
    return true
  }

  /**
   * If true, the current selection will be preserved
   * after the action has been executed
   * @type {Boolean}
   */
  get preserveSelection () {
    return true
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   * @param {Array[PlanItem]} items Plan items to apply the action to
   * @param {Point} position Position at which the action should be executed
   * @param {Number} x Item movement on X axis
   * @param {Number} y Item movement on Y axis
   * @param {Boolean} snapToGrid If true, the new position should be snapped to grid lines
   */
  execute ({ renderer, items, x = 0, y = 0, snapToGrid } = {}) {
    if (renderer && items) {
      try {
        const { layout, isCrossSection } = renderer
        renderer.isMovingItems = true
        for (const item of items) {
          // Get the current position of the item
          const position = item.getCoordinates(isCrossSection) || item.getFirstPoint(isCrossSection)
          if (position) {
            // Calculate the new position
            let newPosition = new Point(position).moveBy({ x, y })

            // Snap to grid if required
            if (snapToGrid && Math.abs(x) > 1 || Math.abs(y) > 1) {
              // Determine on which axis to snap
              const onX = x != 0
              const onY = y != 0
              // Determine the direction of snapping
              const upper = x > 0 || y > 0
              const lower = x < 0 || y < 0
              // Snap the new position
              newPosition = layout.snap(newPosition, { onX, onY, upper, lower })
            }

            // Move the item
            const delta = newPosition.delta(position)
            item.moveTo(newPosition, isCrossSection, delta)
          }
        }

      } finally {
        renderer.isMovingItems = false
        renderer.changed({ action: this })
      }
    }
  }

  /**
   * Undoes the executed action
   * @param {PlanRenderer} renderer Plan renderer
   * @param {RecordedPlanAction} data Data recorded before executing the action
  */
  async undo ({ renderer, data }) {
    await super.undo({ renderer, data })

    // Move the items back to their original positions
    const { layout, isCrossSection } = renderer
    for (const { id } of data.items) {
      const item = layout.getItem(id)
      if (!item) continue
      if (!item.canMove) continue

      const before = data.getItemSnapshot(id)

      if (before) {
        if (item.isConnector) {
          item.setTurns(before.turns, isCrossSection)

        } else if (item.isPointBased) {
          item.setPoints(before.points, isCrossSection)

        } else {
          const coordinates = isCrossSection ? before.crossSection?.coordinates : before.coordinates
          item.setCoordinates(coordinates, isCrossSection)
        }

        renderer.refreshItem(item)
        renderer.updateLinkedItems(item)
        renderer.notifyLayers(layer => layer.itemMoved(item))
      }
    }

    renderer.changed({ action: this })
  }
}
