<script>
import { countAndPluralize } from '@stellacontrol/utilities'
import { PlanActions, createPlanAction, PlanClipboard } from '../../renderer'
import Menu from './menu'

export default {
  mixins: [
    Menu
  ],

  computed: {
    // Change to enable pasting from clipboard
    allowPaste () {
      return false
    },

    // Menu items
    actions () {
      const itemsInClipboard = PlanClipboard.count
      const pasteAction = this.allowPaste && itemsInClipboard > 0
        ? {
          action: PlanActions.PasteItems,
          label: itemsInClipboard > 1 ? `Paste ${countAndPluralize(itemsInClipboard, 'item')}` : null
        }
        : null
      const actions = [
        // Paste items
        pasteAction,
        // Clear plan
        { action: PlanActions.ClearFloor },
        // Show help
        { action: PlanActions.ShowHelp }
      ]

      return actions
        .filter(item => item)
        .map(({ action, separator, label, icon, color = 'grey-2' }) => ({
          separator,
          action: action ? createPlanAction({ action, label, icon, color }) : null
        }))
    }
  },
}

</script>

<template>
  <div ref="menu" class="menu bg-grey-9 shadow-2 rounded-borders q-pa-sm" :class="cssClass"
    :style="cssStyle">
    <div class="row items-center no-wrap">
      <template v-for="{ separator, action } in actions">
        <q-btn :icon="action.icon" :color="action.color" flat unelevated dense size="md"
          :class="buttonClass(action, separator)" @click="execute({ action })">
          <sc-tooltip>
            {{ action.label }}
          </sc-tooltip>
        </q-btn>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.menu {
  position: absolute;
  z-index: 100;
  visibility: hidden;

  &.visible {
    visibility: visible;
  }
}
</style>
