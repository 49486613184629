import ViewComponent from './view.vue'

/**
 * Installs view components into the application
 * @param application Vue application instance
 */
export function useViews (application) {
  const plugin = {
    install (application) {
      const items = [
        {
          tag: 'sc-view', component: ViewComponent
        }
      ]

      for (const { tag, component } of items) {
        application.component(tag, component)
      }
    }
  }

  application.use(plugin)
}

/**
 * Registers application views into state store
 * @param views Views to register
 * @param store State store
 */
export function registerViews (views, store) {
  if (views && store) {
    store.commit('initializeViews', { views })
  }
}