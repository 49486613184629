import { Routes as unsubscribeRoutes } from '../views/unsubscribe'

/**
 * Routes defined in this applet
 */
export const AppletRoutes = [
  ...unsubscribeRoutes
]

/**
 * Enumeration of route names
 */
export const AppletRoute = {
  Unsubscribe: 'unsubscribe'
}
