<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  data () {
    return {
      // All help videos
      videos: [],
      // Currently played video
      selectedVideo: null
    }
  },

  computed: {
    ...mapGetters([
      'application'
    ]),

    // Available video categories
    categories () {
      const { videos } = this
      const categories = new Set(videos
        .filter(v => v.category)
        .map(v => v.category))
      return [undefined, ...Array.from(categories)]
    },

    // Videos in the specified category
    categoryVideos () {
      return category => this.videos.filter(v => v.category == category)
    },

    // Returns true if specified category has videos
    hasVideos () {
      return category => this.videos.some(v => v.category == category)
    },

    // List of non-empty categories
    visibleCategories () {
      const { categories, hasVideos } = this
      return categories.filter(category => hasVideos(category))
    }
  },

  methods: {
    ...mapActions([
      'getVideos'
    ]),

    // Opens the specified video for playing
    openVideo (video) {
      this.selectedVideo = video
    }
  },

  async created () {
    this.videos = await this.getVideos()
  }
}
</script>

<template>
  <div class="help-videos" v-if="videos.length > 0">
    <header class="text-body2 q-mb-lg">
      Get familiar with the new {{application.name}} application by watching these introduction videos:
    </header>
    <section class="list">
      <!-- list of videos -->
      <div class="scroller">
        <section class="category" v-for="category in visibleCategories" :key="category">
          <header>
            {{ category || 'Help' }}
          </header>
          <article class="q-pa-md" v-for="(video, index) in categoryVideos(category)" :key="index"
            @click="openVideo(video)">
            <div>
              <div class="title">
                {{ video.title }}
              </div>
            </div>
            <div class="thumbnail">
              <img :src="video.thumbnail" v-if="video.thumbnail" />
            </div>
            <sc-tooltip :text="video.description" />
          </article>
        </section>
      </div>
    </section>

    <!-- embedded video player -->
    <div class="backdrop" v-if="selectedVideo">
    </div>
    <div class="video row items-center justify-center" v-if="selectedVideo">
      <header class="text-grey-7 text-h5">
        Loading "{{ selectedVideo.title }}" video, please wait ...
      </header>
      <iframe v-if="selectedVideo" :src="selectedVideo.url"
        frameborder="0"
        allowfullscreen
        webkitallowfullscreen
        mozallowfullscreen>
      </iframe>
    </div>
    <q-btn class="video-close-button" v-if="selectedVideo"
      round
      size="md"
      icon="close"
      color="grey-9"
      @click="openVideo(null)" />
  </div>
</template>

<style scoped lang="scss">
.help-videos {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  > header {
    flex: 0;
  }

  > .list {
    flex: 1;
    overflow: hidden;

    .scroller {
      height: 100%;
      overflow: auto;
      display: flex;
      flex-direction: column;

      > section {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 8px;

        > header {
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 8px;
          flex-basis: 100%;
        }

        > article {
          border: 1px solid #0000001f;
          border-radius: 8px;
          background-color: #dce9fa1f;
          margin-bottom: 16px;
          margin-right: 16px;
          max-width: 250px;
          height: 150px;
          cursor: pointer;

          .title {
            font-size: 13px;
          }

          .thumbnail {
            position: relative;
            margin-top: 8px;

            img {
              max-height: 80px;
            }
          }

          &:hover {
            background-color: #cddaeb31;
          }
        }
      }
    }
  }

  > .backdrop {
    position: absolute;
    z-index: 999998;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    opacity: 0.5;
  }

  > .video {
    position: fixed;
    z-index: 999999;
    left: 24px;
    top: 24px;
    right: 24px;
    bottom: 24px;
    background-color: white;
    border: solid #0f0f0f 1px;
    opacity: 1.0;

    iframe {
      position: absolute;
      z-index: 999999;
      width: 100%;
      height: 100%;
    }
  }

  > .video-close-button {
    position: fixed;
    z-index: 999999;
    right: 8px;
    top: 8px;
    opacity: 1.0;
  }
}

</style>