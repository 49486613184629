import HelpView from './help.vue'

const name = 'help'

export const Routes = [
  {
    name,
    path: '/help',
    component: HelpView
  }
]
