import { state, getters } from './inventory-view.state'
import { mutations } from './inventory-view.mutations'
import { actions } from './inventory-view.actions'

export default {
  state,
  getters,
  mutations,
  actions
}
