/**
 * Moves the shape to top of the parent
 * @param {Shape|Konva.Shape} instance Shape to move
 * @description This function first checks whether the shape has a parent.
 * Direct call to `shape.moveToTop` would result in warning, if no parent is specified.
 */
export function moveToTop (instance) {
  const shape = instance?.content || instance
  if (shape?.getParent()) {
    shape.moveToTop()
  }
}

/**
 * Moves the shape to the bottom of the parent
 * @param {Shape|Konva.Shape} instance Shape to move
 * @description This function first checks whether the shape has a parent.
 * Direct call to `shape.moveToBottom` would result in warning, if no parent is specified.
 */
export function moveToBottom (instance) {
  const shape = instance?.content || instance
  if (shape?.getParent()) {
    shape.moveToBottom()
  }
}

/**
 * Changes z-index of the shape inside its parent
 * @param {Shape|Konva.Shape} instance Shape to move
 * @description This function first checks whether the shape has a parent.
 * Direct call to `shape.zIndex` would result in warning, if no parent is specified.
 */
export function setZIndex (instance, zIndex) {
  const shape = instance?.content || instance
  if (shape?.getParent() && zIndex != null) {
    shape.zIndex(zIndex)
  }
}
