import { safeParseInt, parseNumberList } from '@stellacontrol/utilities'
import { StandardDeviceBands, DeviceBandStatus, DeviceBandDetails, DeviceBandColor, bandsToIdentifiers, DeviceRegion, DeviceType, AttenuationGroup, productTypeToDeviceType, DeviceBandIndex, DeviceBandIdentifiers } from '@stellacontrol/model'
import { DeviceStatusFeatures } from './device-status-features'

/**
 * Details of device bands and their current status
 */
export class DeviceStatusBands {
  /**
   * Initializes the instance
   * @param {String} serialNumber Device serial number
   * @param {Object} mega Live device data retrieved from Shadow API
   * @param {Object} data Initial values of the properties
   */
  constructor (serialNumber, mega, data = {}) {
    this.serialNumber = serialNumber
    Object.assign(this, data)
    if (mega) {
      this.parse(mega)
    }
  }

  /**
   * Creates am instance populated with deserialized data
   */
  static from (data = {}) {
    const status = new DeviceStatusBands()
    Object.assign(status, data)
    return status
  }

  /**
   * MEGA message version
   * @type {String}
   */
  megaVersion

  /**
   * Device serial number
   * @type {String}
   */
  serialNumber

  /**
   * Indicates that bands work in SHIP mode
   * @type {Boolean}
   */
  isShip

  /**
   * Indicates that bands can be automatically switched off due to LPAS
   * @type {Boolean}
   */
  isAutoSwitchOff

  /**
   * String representing device bands, such as LGWDH
   * @type {String}
   */
  bands

  /**
   * List of band codes
   * @type {Array[String]}
   */
  codes = []

  /**
   * Slot identifiers
   * @type {Array[String]}
   */
  identifiers = []

  /**
   * Band group attenuations
   * @type {Dictionary<AttenuationGroup, Number>}
   */
  attenuationGroups

  /**
   * Summary status of bands,
   * dictionary where key is band slot identifier
   */
  status = {}

  /**
   * Detailed status of bands,
   * dictionary where key is band slot identifier
   */
  details = {}

  /**
   * Parses raw MEGA record received from the device
   * @param mega MEGA record
   */
  parse (mega = {}) {
    this.megaVersion = mega['@version']
    if (!this.megaVersion) return

    this.bands = mega['product_bands'] || StandardDeviceBands.SixBands
    this.identifiers = bandsToIdentifiers(this.bands)
    this.codes = Array.from(this.bands)

    // Check if is SHIP mode
    this.isShip = Boolean(mega['ship'] || mega['_ship_setaws'])
    // Check if auto-switch off due to LPAS
    this.isAutoSwitchOff = mega['_ship_setaws'] && mega['_ship_auto_switchoff']

    // Parse group attenuations
    if (mega['_dl_atten_group']) {
      const values = mega['_dl_atten_group']
        .split(',')
        .map(value => safeParseInt(value))
      this.attenuationGroups = {
        [AttenuationGroup.LowerBands]: values[0],
        [AttenuationGroup.UpperBands]: values[1]
      }
    }

    // Check which slots are currently active, using `_rf_slots_enable` flag
    // (available only on FW >= 7.2.2.0)
    const slotEnable = parseNumberList(mega['_rf_slot_enable'], null, ',')
    const slots = DeviceBandIdentifiers
      .map(band => ({
        band,
        isEnabled: slotEnable?.length > 0 ? slotEnable.includes(DeviceBandIndex[band]) : true
      }))
      .reduce((all, item) => ({ ...all, [item.band]: item.isEnabled }), {})

    // Determine status of individual bands
    this.status = this.identifiers
      .reduce((all, i) => {
        all[i.toString()] = this.parseBandStatus(mega, i, slots)
        return all
      }, {})

    // Determine details of individual bands
    this.details = this.identifiers
      .reduce((all, i) => {
        all[i.toString()] = this.parseBandDetails(mega, i)
        return all
      }, {})
  }

  /**
   * Determines the status of the specified band
   * @param {Object} mega MEGA content
   * @param {BandIdentifier} identifier Band slot identifier
   * @param {Dictionary<BandIdentifier, Boolean>} slots Status of band slots: `true` if slot is enabled, `false` if disabled
   */
  parseBandStatus (mega = {}, identifier = '', slots = []) {
    // Check if band is enabled at all
    const isEnabled = slots[identifier]

    // Check if band is currently online
    const status = this.getBandValue(mega, identifier, 'on') ? DeviceBandStatus.Online : DeviceBandStatus.Offline
    const deviceType = productTypeToDeviceType(mega['product_type'])
    let isOnline = isEnabled && Boolean(this.getBandValue(mega, identifier, 'on'))
    let index = DeviceBandIndex[identifier]
    let ledCount = 0
    let isAGC = false
    let isOscillating = false
    let isReducedGain = 0
    let isOverPower = false
    let isShutdownManually = false
    let isShutdownAutomatically
    let isShutdownImminent = false
    let isUplink = false

    // Parse feature flags
    const flags = DeviceStatusFeatures.parseFlags(mega)

    // SOME PARAMETERS ARE AVAILABLE REGARDLESS OF CURRENT STATUS OF THE BAND
    // Check if is SHIP mode
    let isShip = Boolean(mega['ship'] || mega['_ship_setaws'])

    // Check if auto-switch off due to LPAS
    let isAutoSwitchOff = mega['_ship_setaws'] && mega['_ship_auto_switchoff']

    // Check if auto-switch due to region OFF
    let isRFOff = mega['_rf_region'] === DeviceRegion.Off

    // SOME PARAMETERS ARE DETERMINED ONLY WHEN BAND IS ONLINE
    if (isOnline) {
      // Check the number of leds which should be lit, indicating downlink power.
      // On older firmware we look at legacy `signal_leds`
      ledCount = safeParseInt(this.getBandValue(mega, identifier, 'signal_leds'), 0)

      // On newer firmware check `LedCount4` feature flag,
      // which indicates LCD/LED-ONLY models with respectively 6 and 4 LEDs
      if (flags[DeviceStatusFeatures.Fields.LedCount4] !== null) {
        // If LINK_POWER parameter is present, use this in combination with led count,
        // otherwise re-scale the legacy `signal_leds`
        const linkPower = safeParseInt(this.getBandValue(mega, identifier, 'link_power'))
        if (linkPower == null) {
          ledCount = flags[DeviceStatusFeatures.Fields.LedCount4]
            ? Math.round((ledCount * 4) / 6)
            : ledCount
        } else {
          const divider = flags[DeviceStatusFeatures.Fields.LedCount4] ? 60 : 40
          ledCount = Math.round(linkPower / divider)
        }
      }

      // Check if AGC is ON on the band
      isAGC = safeParseInt(this.getBandValue(mega, identifier, 'neartower_dw'), 0) > 0

      // Check if band is oscillating
      isOscillating = Boolean(this.getBandValue(mega, identifier, 'oscillation'))

      // Check if reduced gain has been reported
      isReducedGain = Boolean(this.getBandValue(mega, identifier, 'reduced_gain'))

      // Check if band is overpowered, ignore for combiner amps
      isOverPower = deviceType != DeviceType.Combiner && Boolean(this.getBandValue(mega, identifier, 'overpower'))

      // Check if band shutdown is imminent, for example due to overpower, ignore for combiner amps
      isShutdownImminent = deviceType != DeviceType.Combiner && Boolean(this.getBandValue(mega, identifier, 'eminent'))

      // Check if band is currently being used by some devices nearby
      isUplink = Boolean(this.getBandValue(mega, identifier, 'swc_up'))
    }

    // WHEN BAND IS OFFLINE BUT NOT DISABLED, WE NEED TO AT LEAST DETERMINE WHY IT'S OFFLINE
    if (!isOnline && isEnabled) {
      // Check if band has been shut down automatically (if the flag is present)
      isShutdownAutomatically = Boolean(this.getBandValue(mega, identifier, '_shutdown'))
      // Check if band has been shut down manually
      isShutdownManually = Boolean(this.getBandValue(mega, identifier, '_shutdown'))
    }

    // Collect MEGA values applicable to the band
    const keys = [
      'mean_up',
      'mean_dw',
      'peak_up',
      'peak_dw',
      'peak_agc_up',
      'peak_agc_dw',
      'neartower_up',
      'neartower_dw',
      'vsc',
      'osc_up',
      'osc_dw',
      'osc_max',
      '_mgn_dw',
      'sliding',
      '_skew',
      'extra_perm'
    ]

    const values = keys
      .map(key => ({ key, value: this.getBandValue(mega, identifier, key) }))
      .reduce((all, { key, value }) => ({ ...all, [key]: safeParseInt(value, 0) }), {})

    // Calculated values
    values['total_attenuation'] = safeParseInt(values['extra_perm'], 0) +
      safeParseInt(values['_mgn_dw'], 0) +
      safeParseInt(values['osc_max'], 0) +
      safeParseInt(values['neartower_dw'], 0)

    return {
      identifier,
      index,
      band: DeviceBandDetails[identifier],
      status,
      isEnabled,
      isOnline,
      isAGC,
      isOscillating,
      isReducedGain,
      isOverPower,
      isShutdownImminent,
      isShutdownManually,
      isShutdownAutomatically,
      isShutDown: isShutdownManually || isShutdownAutomatically,
      isRFOff,
      isUplink,
      isShip,
      isAutoSwitchOff,
      ledCount,
      values
    }
  }

  /**
   * Determines the detailed description of status of the specified band
   * @param {Object} mega MEGA content
   * @param {BandIdentifier} identifier Band slot identifier
   */
  parseBandDetails (mega = {}, identifier = '') {
    const status = this.status[identifier]

    if (mega && status) {
      const {
        isEnabled,
        isOnline,
        isAGC,
        isReducedGain,
        isOscillating,
        isOverPower,
        isShutdownManually,
        isShutdownImminent,
        isShip,
        isAutoSwitchOff,
        isRFOff
      } = this.status[identifier]

      let label = ''
      let bandLabel = ''
      let description = ''
      let details = ''
      let isAnimated = false
      let isError = false
      let isWarning = false
      let color = DeviceBandColor.None
      let borderColor = DeviceBandColor.None

      if (isOnline) {
        // DEVICE IS ONLINE
        label = isAGC ? 'On AGC' : 'On'
        description = isAGC ? 'On AGC' : 'On'
        details = isAGC ? 'No issues, AGC is > 0' : 'No issues'
        color = DeviceBandColor.Ok

        if (isOscillating) {
          // Oscillation warning detected on band
          label = 'Feedback'
          description = 'Feedback detected on band'
          details = 'Feedback detected. This happens when the indoor and outdoor antenna are too close to each other.'
          isError = true
          color = DeviceBandColor.Error

        } else if (isReducedGain) {
          // Reduced Gain warning detected on band
          label = 'Reduced Gain'
          description = 'Reduced gain detected'
          details = 'Reduced gain detected.This is due to a strong downlink signal. Action: Use manual attenuators to control power on this band.'
          isWarning = true
          color = DeviceBandColor.ReducedGain

        } else if (isShutdownImminent) {
          // Band is about to shutdown due to problems
          label = 'Shutdown imminent'
          description = 'Very high power on band'
          details = isShip
            ? 'Very high power on downlink. Band is about to shutdown soon'
            : 'Band is experiencing very high power and will shut down soon if high power persists. Action: Install an external attenuator.'
          isAnimated = true
          isError = true
          color = DeviceBandColor.Error

        } else if (isOverPower) {
          label = 'Near Shutdown'
          // Overload detected on band
          description = 'High downlink power'
          details = isShip
            ? 'Very high downlink power detected, automatic shutdown is imminent'
            : 'Very high downlink power detected, automatic shutdown is imminent, add attenuation'
          isWarning = true
          color = DeviceBandColor.OverPower

        } else if (isShutdownManually) {
          // Band reports online, but there's also indication that it's been shutdown manually.
          // This is an inconsistency and potentially a signal of an error situation or a bug.
          label = 'Manual Shutdown'
          isError = true
          description = 'Device data disagrees on shutdown values'
          details = 'Device data disagrees on shutdown values. Possible device error, or update was made by simulated device.'
          color = DeviceBandColor.Error
          bandLabel = 'M'

        }

      } else {
        // BAND IS DISABLED
        if (!isEnabled) {
          // DEVICE IS OFFLINE
          label = 'Deactivated'
          description = 'Band is deactivated'
          details = 'Band has been deactivated. Please contact your reseller to activate this band.'
          details = ''
          color = DeviceBandColor.NotAvailable
          bandLabel = 'S'
          isError = false

        } else if (isRFOff) {
          // DEVICE IS OFFLINE
          label = 'GEO Off'
          description = 'Band is shutdown because device region is set to OFF'
          details = ''
          color = DeviceBandColor.NotAvailable
          bandLabel = 'R'
          isError = false

        } else {
          // BAND IS SHUT DOWN
          label = 'Automatic Shutdown'
          description = 'Band was shutdown automatically by device'
          details = 'Band automatically shut down due to very strong downlink power. Add manual attenuation to this band.'
          color = DeviceBandColor.Error
          bandLabel = 'A'
          isError = true

          if (isShutdownManually) {
            // MANUAL SHUTDOWN BY THE USER - THUS NOT AN ERROR
            label = 'Manual Shutdown'
            description = 'Band was shut down manually'
            details = 'Band has been shut down manually by the user'
            color = DeviceBandColor.NotAvailable
            bandLabel = 'M'
            isError = false

          } else if (isShip && isAutoSwitchOff) {
            // AUTOMATIC SHUTDOWN ON SHIP
            label = 'LPAS Shutdown'
            bandLabel = 'L'
            description = 'LPAS (Low Power Auto Shutdown)'
            details = 'Band was shutdown automatically by device due to LPAS (Low Power Auto Shutdown)'
          }
        }
      }

      return {
        label,
        bandLabel,
        description,
        details,
        isAnimated,
        isError,
        isWarning,
        color,
        borderColor
      }
    }
  }

  /**
   * Returns a specified MEGA value for the given band
   * @param mega MEGA content
   * @param identifier Band slot identifier
   */
  getBandValue (mega = {}, identifier, name, defaultValue) {
    return mega[`${name}_${identifier}`] || defaultValue
  }

  /**
   * Returns true if specified MEGA value for the given band exists
   * @param mega MEGA content
   * @param identifier Band slot identifier
   */
  hasBandValue (mega = {}, identifier, name) {
    return mega[`${name}_${identifier}`] != null
  }
}
