export const actions = {
  /**
   * Initialize the applet
   */
  async initializeApplet () {
  },

  /**
   * Retrieves data for About view
   */
  async getAboutData ({ commit }) {
    const about = {
      appVersion: '1.0.0'
    }

    commit('storeAboutData', { about })
  }
}
