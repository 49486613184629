<script>
import { FormValidationRules as rules } from '@stellacontrol/client-utilities'
import AlertConfigurationComponent from './alert-configuration-component'

export default {
  mixins: [
    AlertConfigurationComponent
  ],

  data () {
    return {
      rules
    }
  },

  computed: {
    min () {
      return this.configuration.parameters.minErrorCount
    },

    max () {
      return this.configuration.parameters.maxErrorCount
    },

    errorCountIsModified () {
      return this.isModified(
        configuration => configuration.parameters.errorCount
      )
    },
    errorCountIsNotDefault () {
      return this.isNotDefault(configuration => configuration.parameters.errorCount)
    }
  }
}
</script>

<template>
  <article>
    <section v-if="isEditable && advancedConfiguration">
      <label :class="{ modified: errorCountIsModified }">
        Network error count
        <sc-hint
          :text="`The alert will be triggered, when ${configuration.parameters.errorCount} new errors are registered over the period of ${duration(configuration.trend)}. ${errorCountIsNotDefault}`"
          size="20px">
        </sc-hint>
      </label>
      <div>
        <q-input :input-style="{ 'max-width': '150px' }"
          v-model.number="configuration.parameters.errorCount" type="number" dense outlined square
          :rules="[rules.isNumber(min, max, `The value has to be at least ${min}`)]" debounce="500"
          :min="min" :max="max">
        </q-input>
      </div>
    </section>
  </article>
</template>

<style scoped lang="scss">
</style>