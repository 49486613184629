export function createState () {
  return {
    /**
     * Currently edited premium service
     */
    selectedPremiumService: null
  }
}

export const state = createState()

export const getters = {
}
