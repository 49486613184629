import { Place } from '@stellacontrol/model'
import { AppletRoute } from '../../router'

export const actions = {
  /**
   * Initializes the Device Dashboard view
   * @param {Device} device Device to view
   * @param {Device} devicePart Multi-device part to view
   */
  async initializeDeviceDashboard ({ commit, dispatch, getters }, { device, devicePart } = {}) {
    const { currentOrganization } = getters

    // Get the owner of the device
    const organization = getters.organizations.find(o => device.ownerId
      ? o.id === device.ownerId
      : currentOrganization.isSuperOrganization && o.id === currentOrganization.id)

    // Get the place where the device is located
    const place = device.placeId ? getters.allPlaces.find(p => p.id === device.placeId) : Place.NoPlace

    // Get guardian for the specified organization
    const organizationGuardian = await dispatch('getGuardian', { principal: organization, force: true })

    // Get the pending premium service associated with device, if any
    const pendingPremiumService = device?.isPremiumServiceNotStarted
      ? await dispatch('getPremiumService', { id: device.premiumServiceId })
      : undefined

    commit('initializeDeviceDashboard', {
      organization,
      organizationGuardian,
      place,
      device,
      devicePart,
      pendingPremiumService
    })
  },

  /**
    * Navigates to device dashboard
    * @param {Device} device Device to show
    */
  async gotoDeviceDashboard ({ dispatch }, { device: { serialNumber } = {} } = {}) {
    if (!(serialNumber)) throw new Error('Device serial number is required')

    await dispatch('gotoRoute', {
      name: AppletRoute.DeviceDashboard,
      params: { serialNumber }
    })
  },

  /**
   * Navigates to multi-device board on the current dashboard
   * @param {Device} device Device to show
   * @param {String} part Serial number of the device board to show
   */
  async gotoDevicePartDashboard ({ dispatch }, { device: { serialNumber } = {}, part } = {}) {
    if (!serialNumber) throw new Error('Device identifier or serial number is required')
    if (!part) throw new Error('Device part serial number is required')

    await dispatch('gotoRoute', {
      name: AppletRoute.DeviceDashboard,
      params: { serialNumber },
      query: { part }
    })
  }
}
