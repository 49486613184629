import { mapActions } from 'vuex'
import { AttachmentType } from '@stellacontrol/model'
import { DeviceAPI, CommonAPI } from '@stellacontrol/client-api'
import { Download, Notification } from '@stellacontrol/client-utilities'

// Base component for creating file lists and views
export const FilesMixin = {
  methods: {
    ...mapActions([
      'busy',
      'done',
      'openUrl',
      'deleteAttachments',
      'getAttachmentUrl',
      'previewAttachment'
    ]),

    // Removes the selected files
    removeFiles ({ files }) {
      this.deleteAttachments({ attachments: files, confirm: true })
    },

    // Downloads the specified file
    async downloadFile ({ file }) {
      if (file?.canDownload) {
        const url = await this.getAttachmentUrl({ attachment: file })
        this.openUrl({ url })
        Notification.success({ message: 'The file has been downloaded' })
      }
    },

    // Prints the specified files
    async printFiles ({ files }) {
      const printableFiles = files.filter(file => file.canPrint && file.isScanResults)
      if (printableFiles.length > 0) {
        // Print scan results
        const identifiers = printableFiles.map(f => f.id)
        await this.busy({ message: 'Printing ... ', silent: identifiers.length < 5 })
        const filename = 'scans.zip'
        const result = await DeviceAPI.printScanReports({
          identifiers,
          format: AttachmentType.HTML,
          bundle: filename
        })

        // Download the bundle
        if (result) {
          const url = CommonAPI.getAttachmentUrl({ attachment: result, remove: true })
          Download.saveUrlToFile(url, filename)
          await this.done()
          Notification.success({
            message: printableFiles.length === 1
              ? 'The report has been printed and downloaded'
              : 'The reports have been printed and downloaded'
          })
        }
      }
    },

    // Previews the specified file
    async previewFile ({ file }) {
      if (file?.canPreview || file?.canPreviewInFrame) {
        this.previewAttachment({ attachment: file })
      }
    }
  }
}