import {
  capitalize,
  capitalizeWords,
  parseDate,
  formatDateTime,
  formatDate,
  formatTime,
  clip,
  getDurationString,
  getAgeString,
  countString,
  pluralize,
  htmlize
} from '@stellacontrol/utilities'

/**
 * Installs UI filters into the application
 * @param application Vue application instance
 */
export function useFilters (application) {
  const plugin = {
    install (application) {
      Object.assign(
        application.config.globalProperties,
        {
          capitalize: text => capitalize(text),
          capitalizeWords: text => capitalizeWords(text),
          clip: (text, length) => clip(text, length),
          uppercase: text => (text || '').toString().toUpperCase(),
          lowercase: text => (text || '').toString().toLowerCase(),
          date: (dt, format) => formatDate(parseDate(dt), format),
          time: (dt, format) => formatTime(parseDate(dt), format),
          datetime: (dt, format) => formatDateTime(parseDate(dt), format),
          dateString: (dt, format) => formatDate(parseDate(dt), format),
          timeString: (dt, format) => formatTime(parseDate(dt), format),
          dateTimeString: (dt, format) => formatDateTime(parseDate(dt), format),
          duration: duration => getDurationString(duration),
          age: duration => getAgeString(duration),
          count: (items = [], label, noneText) => countString(items, label, noneText),
          pluralize: (items = [], singleLabel, multipleLabel, noneLabel) => pluralize(items, singleLabel, multipleLabel, noneLabel),
          html: text => htmlize(text)
        }
      )
    }
  }
  application.use(plugin)
}
