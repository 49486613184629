/**
 * Document types
 */
export const DocumentType = {
  Text: 'text',
  Markdown: 'markdown',
  HTML: 'html',
  JSON: 'json',
  PDF: 'pdf',
  JPG: 'jpg',
  PNG: 'png',
  DOC: 'doc'
}
