/**
 * Replaces a string at specified index with the given replacement
 * @param {String} s Text to modify
 * @param {Number} index Index to replace the text at
 * @param {String} replacement Replacement text
 * @param {Number} length Number of characters to replace, if different than the replacement text
 * @returns Modified string
 */
export function replaceAt (s, index, replacement, length) {
  if (s != null && index >= 0 && replacement != null) {
    return s.toString().substring(0, index) +
      replacement.toString() +
      s.toString().substring(index + (length || replacement.toString().length))
  } else {
    return s
  }
}
/**
 * Capitalizes text
 * @param {String} s Text to capitalize
 * @returns {String} Capitalized text
 */
export function capitalize (s) {
  if (s) {
    s = s.toString().trim()
    return s.substring(0, 1).toUpperCase() + s.substring(1).toLowerCase()
  } else {
    return s
  }
}

/**
 * Capitalizes every word in the text
 * @param {String} s Text to capitalize
 * @param {Boolean} leaveExistingCaps If true, existing capitals will be left intact,
 * otherwise everything after the first capital letter of the word will be lower-cased
 * @returns {String} Text with words capitalized
 */
export function capitalizeWords (s, leaveExistingCaps) {
  if (s) {
    const normalized = leaveExistingCaps
      ? s.toString().trim()
      : s.toString().trim().toLowerCase()
    return normalized.replace(/(?:^|\s)\S/g, ch => ch.toUpperCase())
  } else {
    return s
  }
}

/**
 * Pascalizes an identifier by removing separators and capitalizing all words
 * @param {String} s Identifier to pascalize
 * @param {String} separator Separator to add between words
 * @returns {String} Pascalized identifier
 */
export function pascalize (s, separator = '') {
  if (s) {
    return s
      .toString()
      .toLowerCase()
      .replace(/(?:^|[\s-_.:])\S/g, ch => ch.toUpperCase())
      .replace(/[\s-_:.]/g, separator)
  } else {
    return s
  }
}

/**
 * Camelizes an identifier by removing separators and capitalizing all words except first one
 * @param {String} s Identifier to camelize
 * @returns {String} Camelized identifier
 */
export function camelize (s) {
  if (s) {
    s = pascalize(s)
    return s[0].toLowerCase() + s.substring(1)
  } else {
    return s
  }
}

/**
 * Normalizes text by trimming and converting all whitespace and breaks to single spaces
 * @param {String} s Text to normalize
 * @param {String} replacement Replacement string for extended characters
 * @returns {String} Normalized identifier
 */
export function plainText (s, replacement = ' ') {
  if (s) {
    return s
      .toString()
      .replace(/[\n\r\t]/g, replacement)
      .replace(/ +/g, replacement)
      .trim()
  } else {
    return s
  }
}

/**
 * Clips the specified text at given length.
 * If length is exceeded, the specified suffix is added.
 * @param {String} s Text to clip
 * @param {Number} length Maximal length of the text to return
 * @param {String} suffix Suffix to add after the clipped text
 * @returns {String} Clipped text
 */
export function clip (s, length = 0, suffix = ' ...') {
  if (s != null && s.toString().length > length) {
    return s.toString().substring(0, length) + (suffix || '')
  } else {
    return s
  }
}

/**
 * Pads the specified text from left, to specified width
 * @param {any} value Value to pad
 * @param {Number} length Length of the padded text
 * @param {String} ch Padding character
 * @returns {String} Padded text
 */
export function padLeft (value, length = 0, ch = ' ') {
  if (value == null) {
    return value
  }
  const s = value.toString()
  if (s.length < length) {
    return ch.repeat(length - s.length) + s
  } else {
    return s
  }
}

/**
 * Pads the specified text from right, to specified width
 * @param {any} value Value to pad
 * @param {Number} length Length of the padded text
 * @param {String} ch Padding character
 * @returns {String} Padded text
 */
export function padRight (value, length = 0, ch = ' ') {
  if (value == null) {
    return value
  }
  const s = value.toString()
  if (s.length < length) {
    return s + ch.repeat(length - s.length)
  } else {
    return s
  }
}

/**
 * Returns a singular or plural form noun
 * depending on the number of items in the list or the given number of items
 * @param {Array|Number} itemsOrNumber Items to count or number of items
 * @param {String} singleLabel Label describing a single item
 * @param {String} multipleLabel Label describing multiple items
 * @param {String} noneLabel Label describing zero items
 * @returns {String} Plural or singular noun
 */
export function pluralize (itemsOrNumber, singleLabel, multipleLabel, noneLabel) {
  if (itemsOrNumber == null) {
    return
  }

  const count = Array.isArray(itemsOrNumber)
    ? itemsOrNumber?.length
    : parseInt(itemsOrNumber)

  if (isNaN(count)) {
    return
  }

  if (!singleLabel) {
    return parseInt(count).toString()
  }

  if (count === 0) {
    return noneLabel
      ? noneLabel
      : (multipleLabel ? `no ${multipleLabel}` : `no ${singleLabel}s`)

  } else {
    return count === 1
      ? singleLabel
      : (multipleLabel || `${singleLabel}s`)
  }
}

/**
 * Returns a text representing the number of items in the list
 * @param {Array|Number} items Items to describe or number of items
 * @param {String} label Label describing a single item
 * @param {String} noneText Text to return if there are no ites in the list
 * @returns {String} Count string
 */
export function countString (items, label, noneText) {
  const count = Array.isArray(items) ? items.length : parseInt(items)
  if (!label && !isNaN(count)) {
    return count.toString()
  }

  if (!isNaN(count)) {
    return count === 0
      ? (noneText == null ? `no ${label}s` : noneText)
      : `${count} ${label}${count === 1 ? '' : 's'}`
  }
}

/**
 * Returns a text representing the number of items in the list with pluralized label
 * @param {Array} items Items to describe
 * @param {String} singleLabel Label describing a single item
 * @param {String} multipleLabel Label describing multiple items
 * @param {String} noneLabel Label describing zero items
 * @returns {String} Number of items with plural or singular noun
 */
export function countAndPluralize (items, singleLabel, multipleLabel, noneLabel) {
  const count = Array.isArray(items) ? items.length : parseInt(items)
  if (isNaN(count)) {
    return
  }
  if (count === 0) {
    return pluralize(items, singleLabel, multipleLabel, noneLabel)
  } else {
    if (singleLabel || multipleLabel || noneLabel) {
      return `${count} ${pluralize(items, singleLabel, multipleLabel, noneLabel)}`
    } else {
      return count.toString()
    }
  }
}

/**
 * Returns true if text contain only numbers
 * @param {String} text
 */
export function hasOnlyDigits (text) {
  if (text == null) return false
  return /^[0-9]+$/.test(text)
}

// /<title[^>]*>([sS]*?)<\/title>/gm.exec("<title>aaa</title>")

/**
 * Returns true if text contain only ASCII characters
 * @param {String} text
 */
export function hasOnlyCharacters (text) {
  if (text == null) return false
  return /^[A-z]+$/.test(text)
}

/**
 * Returns true if text contain only ASCII characters or digits
 * @param {String} text
 */
export function isAlphanumeric (text) {
  if (text == null) return false
  return /^[A-z0-9]+$/.test(text)
}

/**
 * Outputs the values as a line of aligned columns
 * @param {Array} values Values to output
 * @param {Array[String|Number]} columns Column definitions
 * @param {String} separator Column separator
 * The number specifies column width. If number is negative,
 * the column will be right-aligned.
 */
export function columns (values, columns = [], separator = ' ') {
  if (values == null) {
    return ''
  }
  const result = []
  for (let i = 0; i < values.length; i++) {
    let value = values[i] == null ? '' : values[i].toString()
    const width = columns[i]
    const right = columns[i] < 0
    if (width) {
      result.push(right ? padLeft(value, Math.abs(width), ' ') : padRight(value, width, ' '))
    } else {
      result.push(value)
    }
  }
  return result.join(separator)
}

/**
 * Check whether string contains another string
 * @param {String} a String to search in
 * @param {String} b String to find
 * @param {Boolean} caseSensitive If true, check is case-sensitive
 * @returns {Boolean}
 */
export function stringContains (a, b, caseSensitive = true) {
  if (a != null && b != null) {
    const aa = caseSensitive ? a.toString().toLocaleLowerCase() : a.toString()
    const bb = caseSensitive ? b.toString().toLocaleLowerCase() : b.toString()
    return aa.includes(bb)
  } else {
    return false
  }
}

/**
 * Removes all characters except the allowed ones from the specified text
 * @param {String} s Text to process
 * @param {String} allowed Allowed characters
 * @returns {String}
 */
export function filterCharacters (s, allowed) {
  if (s != null) {
    return Array
      .from(s)
      .filter(ch => allowed.includes(ch))
      .join('')
  }
}

/**
 * Removes all characters except the alpha ones from the specified text
 * @param {String} s Text to process
 * @param {String} extra Additional allowed characters
 * @returns {String}
 */
export function filterAlpha (s, extra = []) {
  if (s != null) {
    return Array
      .from(s)
      .filter(ch => (ch >= 'A' && ch <= 'z') || extra.includes(ch))
      .join('')
  }
}

/**
 * Removes all characters except the alphanumeric ones from the specified text
 * @param {String} s Text to process
 * @param {String} extra Additional allowed characters
 * @returns {String}
 */
export function filterAlphanumeric (s, extra = '') {
  if (s != null) {
    return Array
      .from(s)
      .filter(ch => (ch >= '0' && ch <= '9') || (ch >= 'A' && ch <= 'z') || extra.includes(ch))
      .join('')
  }
}