/**
 * API Client Configuration
 */
export class APIClientConfiguration {
  constructor (data = {}) {
    Object.assign(this, data)
  }

  /**
   * URL of the endpoint to which the client connects
   * @type {String}
   */
  url

  /**
   * API client identifier
   * @type {String}
   */
  client

  /**
   * API key
   * @type {String}
   */
  key

  /**
   * Request timeout, in milliseconds.
   * If response doesn't arrive in the specified time, an error is raised
   * @type {Number}
   */
  timeout

  /**
   * Artificial delay before sending requests, in milliseconds,
   * can be useful during debugging
   * @type {Number}
   */
  requestDelay
}
