<script>
export default {
  props: {
    /**
     * Devices to execute the action on
     */
    devices: {
      required: true
    },

    /**
     * Action
     */
    action: {
      required: true
    },

    /**
     * Action details
     */
    details: {
    },

    /**
     * Indicates whether the action UI is populating
     */
    isLoading: {
      default: false,
    },

    /*
     * Loading message
     */
    loadingMessage: {
    },

    /**
     * Indicates whether the action can be executed
     * under current circumstances. If not, SAVE
     * button won't be visible and action content
     * slot will be hidden
     */
    isAllowed: {
      default: true,
    },

    /**
     * Indicates whether action should be allowed when devices
     * from different owners have been selected
     */
    allowWhenDifferentOwners: {
      default: true
    },

    /**
     * Text displayed to the user when action
     * is not allowed
     */
    warningLabel: {
      default: 'Action not available for the selected devices'
    },

    /**
     * Text displayed on CLOSE button
     */
    closeLabel: {
    },

    /**
     * Label for EXECUTE button
     */
    executeLabel: {
      default: 'Save'
    },

    /**
     * Tooltip for EXECUTE button
     */
    executeTooltip: {
    },

    /**
     * Label for RESET button
     */
    resetLabel: {
      default: 'Reset'
    },

    /**
     * Tooltip for RESET button
     */
    resetTooltip: {
    },

    /**
     * Indicates whether the execute action is ready to be executed
     * If not, SAVE button will be disabled.
     * Used in combination with input form validations etc.
     */
    canExecute: {
      default: true,
    },

    /**
     * Indicates whether the reset action is ready to be executed
     * If not, RESET button will be hidden.
     * Used in combination with input form validations etc.
     */
    canReset: {
      default: false,
    },

    /**
     * Indicates whether SAVE button is visible at all.
     */
    executeVisible: {
      default: true,
    }
  },

  computed: {
    // Indicates that selected devices have different owners
    haveDifferentOwners () {
      const owners = new Set(this.devices.map(d => d.owner ? d.owner.id : null))
      return owners.size > 1
    },

    // Checks if action is allowed
    isActionAllowed () {
      return this.isAllowed &&
        this.allowWhenDifferentOwners || !this.haveDifferentOwners
    }
  },

  emits: [
    'execute',
    'reset',
    'closing',
    'close'
  ],

  methods: {
    async execute () {
      await this.$emit('execute')
    },

    async reset () {
      await this.$emit('reset')
    },

    async closing () {
      this.$emit('closing')
    },

    async close () {
      await this.$emit('close')
    }
  }
}
</script>

<template>
  <div>
    <div class="q-pa-sm bg-indigo-1">
      <div class="row no-wrap">
        <div class="q-mr-xs">
          <q-icon
            size="50px"
            :name="details?.icon || action.icon"
            :color="isActionAllowed ? (action.color || 'indigo-5') : 'red-8'">
          </q-icon>
        </div>
        <div class="column">
          <label class="text-subtitle1 q-ml-sm text-grey-10">
            {{ details?.label || action.label }}
          </label>
          <label v-if="isActionAllowed" class="q-pl-sm text-grey-8">
            {{ details?.description || action.details }}
          </label>
          <label v-else class="q-pl-sm text-grey-8">
            {{ !allowWhenDifferentOwners && haveDifferentOwners
              ? 'Action not available when devices from different owners are selected'
              : warningLabel }}
          </label>
        </div>
      </div>
    </div>

    <slot v-if="isActionAllowed && !isLoading">
    </slot>

    <slot name="loading" v-if="isLoading">
      <div class="q-pa-md">
        {{ loadingMessage || 'Loading ...' }}
      </div>
    </slot>

    <div class="q-mt-md row reverse q-gutter-md" v-if="!isLoading">
      <q-btn unelevated v-if="isActionAllowed && executeVisible" :disable="!canExecute" :label="executeLabel" class="primary" @click="execute()">
        <sc-tooltip :text="executeTooltip"></sc-tooltip>
      </q-btn>
      <q-btn unelevated :label="closeLabel || ((isActionAllowed && executeVisible) ? 'Cancel' : 'Close')" @click="close()" @mousedown="closing()"></q-btn>
      <q-space></q-space>
      <slot name="buttons">
      </slot>
      <q-btn unelevated v-if="isActionAllowed && canReset" :label="resetLabel" class="warning" @click="reset()">
        <sc-tooltip :text="resetTooltip"></sc-tooltip>
      </q-btn>
    </div>

  </div>
</template>

<style lang="scss" scoped>
</style>
