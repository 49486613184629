<script>
import { mapState } from 'vuex'
import { DeviceConnectionStatusColor, DeviceConnectionStatusName, DeviceConnectionStatus } from '@stellacontrol/model'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    }
  },

  computed: {
    ...mapState({
      ticks: state => state.deviceStatus.ticks
    }),

    // Recently received live status
    status () {
      return this.item.status
    },

    // Recently recorded device snapshot.
    // This will be used to display the icon while waiting for the status to arrive.
    snapshot () {
      return this.item.device?.snapshot
    },

    // Icon representing the connection status
    icon () {
      return this.item.device.partOf ? 'memory' : 'fiber_manual_record'
    },

    // Color representing the connection status
    color () {
      const { connection } = this.item
      const color = DeviceConnectionStatusColor[connection] || DeviceConnectionStatusColor[DeviceConnectionStatus.NeverConnected]
      return color
    },

    // Icon tooltip
    tooltip () {
      const { item: { connection }, status, ticks } = this
      if (ticks && connection) {
        const age = status?.connection?.isConnected || status?.connection?.isLost
          ? status.timings?.statusAgeString
          : ''
        return `${DeviceConnectionStatusName[connection]} ${age || ''}`
      } else {
        return ''
      }
    }
  }
}
</script>

<template>
  <div class="row justify-center" v-if="item.device.isConnectedDevice">
    <q-icon :name="icon" size="sm" :style="{ color }"></q-icon>
    <sc-tooltip :text="tooltip">
    </sc-tooltip>
  </div>
</template>

<style></style>