/**
 * Device tree view modes
 */
export const DeviceTreeViewMode = {
  /**
   * Show asset tree, a hierarchy of organizations, places and devices
   */
  Tree: 'tree',
  /**
   * Show a flat list of organizations
   */
  Organizations: 'organizations',
  /**
   * Show a flat list of places
   */
  Places: 'places',
  /**
   * Show a flat list of devices
   */
  Devices: 'devices'
}