import { formatDate } from '@stellacontrol/utilities'
import { UserLevelNames, getUserIcon, getUserColor } from '@stellacontrol/model'

/**
 * Users list columns
 */
const userColumns = [
  { name: 'icon', label: 'Level', field: 'level', format: (_, item) => ({ icon: getUserIcon(item), color: getUserColor(item), sortable: true, align: 'left' }) },
  { name: 'fullName', label: 'User', field: 'fullName', sortable: true, align: 'left', format: (fullName, row) => row.isCurrentUser ? `${fullName} (logged in)` : fullName },
  { name: 'email', label: 'Login', field: 'name', sortable: true, align: 'left' },
  { name: 'level', label: 'Level', field: 'level', sortable: true, align: 'left', format: (level, item) => item.isPrimaryAdministrator ? 'Primary Administrator' : UserLevelNames[level] },
  { name: 'description', label: 'Description', field: 'description', sortable: true, align: 'left', desktopOnly: true },
  { name: 'phone', label: 'Phone', field: 'phone', sortable: true, align: 'left', desktopOnly: true },
  { name: 'isEnabled', label: 'Status', field: 'isEnabled', align: 'left', format: isEnabled => `${isEnabled ? 'Active' : 'Inactive'}` },
  { name: 'created', label: 'Created On', field: 'createdAt', sortable: true, align: 'left', format: value => formatDate(value), desktopOnly: true },
  { name: 'updated', label: 'Updated On', field: 'updatedAt', sortable: true, align: 'left', format: value => formatDate(value), desktopOnly: true },
  { name: 'actions', label: 'Actions', align: 'right', sortable: false }
]

export function createState () {
  return {
    /**
     * Users
     */
    items: null,
    /**
     * List columns
     */
    userColumns,
    /**
     * Last created or updated user
     */
    lastUpdated: null
  }
}

export const state = createState()

export const getters = {
  /**
   * Users of the current organization
   */
  users: (state) =>
    state.items || [],

  /**
   * Dictionary of users, by identifier
   */
  usersDictionary: state =>
    (state.items || []).reduce((all, u) => ({ ...all, [u.id]: u })),

  /**
    * Indicates that there are users available
    */
  hasUsers: state =>
    state.items && state.items.length > 0,

  /**
   * Finds a user
   * @param id User identifier
   */
  getUser: state =>
    id => (state.items || []).find(u => u.id === id),

  /**
   * User list columns
   */
  userColumns: (state) =>
    state.userColumns
}
