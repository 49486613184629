/**
 * Notification types
 */
export const NotificationType = {
  Information: 'information',
  Warning: 'warning',
  Error: 'error',
  Alert: 'alert',
  PremiumSubscriptionStarted: 'ps-started',
  PremiumSubscriptionWillExpire: 'ps-will-expire',
  PremiumSubscriptionExpired: 'ps-expired'
}

/**
 * Notification type descriptions
 */
export const NotificationTypeDescription = {
  [NotificationType.Information]: 'Information',
  [NotificationType.Warning]: 'Warning',
  [NotificationType.Error]: 'Error',
  [NotificationType.Alert]: 'Alert',
  [NotificationType.PremiumSubscriptionStarted]: 'Premium service subscription has started',
  [NotificationType.PremiumSubscriptionWillExpire]: 'Premium service subscription will expire',
  [NotificationType.PremiumSubscriptionExpired]: 'Premium service subscription has expired'
}
