import { Routes as aboutRoutes } from '../views/about'
import { Routes as helpRoutes } from '../views/help'
import { Routes as bugReportRoutes } from '../views/bug-report'
import { Routes as announcementsRoutes } from '../views/announcements'
import { Routes as fileRoutes } from '../views/files'

/**
 * Routes defined in this applet
 */
export const AppletRoutes = [
  ...aboutRoutes,
  ...helpRoutes,
  ...bugReportRoutes,
  ...announcementsRoutes,
  ...fileRoutes
]

/**
 * Enumeration of route names in this applet
 */
export const AppletRoute = {
  About: 'about',
  Help: 'help',
  BugReport: 'bug-report',
  Announcements: 'announcements',
  Announcement: 'announcement',
  Files: 'files'
}
