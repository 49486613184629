/**
 * List view modes
 */
export const ListViewMode = {
  /**
   * Normal tabular view
   */
  Normal: 'normal',
  /**
   * Dense tabular view
   */
  Dense: 'dense',
  /**
   * Mini-card view
   */
  MiniCards: 'mini-cards',
  /**
   * Card view
   */
  Cards: 'cards'
}
