/**
 * Announcement category
 */
export const AnnouncementCategory = {
  Information: 'information',
  SoftwareUpdate: 'software-update',
  PlatformMaintenance: 'platform-maintenance',
  Commercial: 'commercial'
}

/**
 * Announcement category definitions
 */
export const AnnouncementCategories = {
  [AnnouncementCategory.Information]: {
    name: 'Information',
    description: 'General announcements and messages to customers',
    icon: 'info'
  },
  [AnnouncementCategory.SoftwareUpdate]: {
    name: 'Software Update',
    description: 'Information about the upcoming or deployed software updates',
    icon: 'arrow_circle_up'
  },
  [AnnouncementCategory.PlatformMaintenance]: {
    name: 'Platform Maintenance',
    description: 'Notifications about planned platform maintenance, possible disruptions of service, down time etc.',
    icon: 'settings'
  },
  [AnnouncementCategory.Commercial]: {
    name: 'Commercial Information',
    description: 'Announcements about new products, services, pricing changes etc.',
    icon: 'payments'
  }
}
