import { Log, delay } from '@stellacontrol/utilities'
import { DeviceAPI } from '@stellacontrol/client-api'
import { DeviceStatus } from '../model/device-status'

/**
 * Devices API wrapper for retrieving live status of devices.
 */
export class DeviceStatusClient {
  /**
   * Retrieves live and parsed status of devices
   * @param {Array[Device]} devices Devices to fetch
   * @param {Boolean} raw If true, raw status as received from Shadow API is returned, otherwise a parsed `DeviceStatus` instance
   * @param {Boolean} isFastSampling Indicates that we're in fast mode, so skip some checks as we only need the status now
   * @param {String} part Part of the status to return: connection|bands|timings|identity|health
   * @returns {Promise<Array[DeviceStatus]>} Device status
   */
  async getDeviceStatus (devices, { raw, isFastSampling, part } = {}) {
    if (!devices) throw new Error('Devices are required')
    if (devices.length === 0) return

    try {
      const results = await DeviceAPI.getDeviceStatus({ devices, raw, isFastSampling, part }) || []
      const success = results.filter(result => !result.error)
      const errors = results.filter(result => result.error)

      if (errors.length > 0) {
        Log.error(`Error fetching status:\n${errors.map(error => `${error.device?.serialNumber} ${error.message || 'Unknown error'}`).join('\n')}`)
      }
      if (raw) {
        return success
      } else {
        return success.map(({ status }) => DeviceStatus.from(status))
      }

    } catch (error) {
      Log.error(`Error fetching status of ${devices.map(d => d.serialNumber).join(',')}`)
      Log.exception(error)
      return []
    }
  }

  /**
   * Probes for device status, until fresh status has arrived.
   * @param {Device} device Device to probe
   * @param {Number} timeout Timeout in milliseconds, after which probing should be stopped
   * @param {Number} interval Interval in milliseconds, between probing attempts
   * @param {Function<Boolean>} predicate Optional predicate to run on the returned status.
   * If returned status matches the predicate, the function returns the status immediately.
   * @param {String} message Message to log if predicate is found to be true
   * @returns {Promise<DeviceStatus>} Most recent known status of the device
   */
  async waitForLiveStatus (device, { timeout = 10000, interval = 1000, predicate, message } = {}) {
    let counter = timeout
    let status

    do {
      const states = await this.getDeviceStatus([device], { isFastSampling: true, raw: false })
      status = (states || [])[0]

      if (status?.connection?.isOnline || status?.timings?.isHeartbeat) {
        if (!predicate) {
          return status
        }

        if (predicate(status)) {
          if (message) {
            Log.debug(message)
          }
          return status
        }
      }

      counter = counter - interval
      await delay(interval)

    } while (counter > 0)

    return status
  }
}
