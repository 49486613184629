<script>
import { mapActions } from 'vuex'
import { Secure } from '@stellacontrol/security-ui'
import DeviceWidget from './device-widget'

export default {
  mixins: [
    DeviceWidget,
    Secure
  ],

  computed: {
    // Status of the update of the viewed device
    updateStatus () {
      const { updateStatus } = this.device || {}
      if (updateStatus) {
        const { firmwareVersion, updatedAt, progress, inProgress, isScheduled } = updateStatus
        return {
          inProgress,
          isScheduled,
          version: firmwareVersion,
          progress: progress,
          updatedAt
        }
      } else {
        return {}
      }
    },

    // Version of the firmware being sent to device
    firmwareVersion () {
      return this.updateStatus?.version || ''
    },

    // Items to display in the widget
    items () {
      const { updateStatus } = this
      return [
        { text: 'Firmware Update', class: { 'label': true } },
        updateStatus.inProgress
          ? { text: `Updating to v.${updateStatus.version}`, class: { 'small': true } }
          : { text: `Update to v.${updateStatus.version} is scheduled`, class: { 'small': true } },
        updateStatus.inProgress
          ? { text: `${updateStatus.progress}% done`, class: { 'small': true }  }
          : null
      ]
    }
  },

  methods: {
    ...mapActions([
      'watchUploadStatus',
      'unwatchUploadStatus'
    ]),

    // Starts polling for firmware update progress,
    // if pending update
    startPolling () {
      const { updateStatus } = this
      if (updateStatus.inProgress) {
        this.watchUploadStatus({
          hasUploadsInProgress: () => {
            if (updateStatus.inProgress) {
              return true
            } else {
              this.stopPolling()
              return false
            }
          }
        })
      }
    },

    // Stops polling for firmware update progress
    stopPolling () {
      this.unwatchUploadStatus()
    }
  },

  created () {
    this.startPolling()
  },

  beforeUnmount () {
    this.stopPolling()
  }
}
</script>

<template>
  <sc-widget-text
    iconColor="light-green-7"
    :icon="updateStatus.inProgress ? 'motion_photos_on' : 'update'"
    :iconClass="updateStatus.inProgress ? 'rotate' : ''"
    :items="items"  >
    <template #toolbar>
      <div class="toolbar row q-pb-sm items-center justify-center">
        <q-btn no-caps no-wrap unelevated dense
          :ripple="false" label="Go to upload jobs" icon-right="chevron_right"
          color="indigo-1" text-color="indigo-7" :to="{ name: 'device-updates' }">
        </q-btn>
      </div>
    </template>
  </sc-widget-text>
</template>
