import Permissions from './permissions/permissions.vue'
import OrganizationSelector from './organization-selector/organization-selector.vue'
import MultiOrganizationSelector from './organization-selector/multi-organization-selector.vue'
import MultiOrganizationProfileSelector from './organization-profile-selector/multi-organization-profile-selector.vue'

/**
 * Installs Security UI components into the application
 * @param application Vue application instance
 */
export function useSecurityComponents (application) {
  const plugin = {
    install (application) {
      const items = [
        {
          tag: 'sc-permissions', component: Permissions
        },
        {
          tag: 'sc-organization-selector', component: OrganizationSelector
        },
        {
          tag: 'sc-multi-organization-selector', component: MultiOrganizationSelector
        },
        {
          tag: 'sc-multi-organization-profile-selector', component: MultiOrganizationProfileSelector
        }
      ]

      for (const { tag, component } of items) {
        application.component(tag, component)
      }
    }
  }

  application.use(plugin)
}
