/**
 * Chart data series, representing a single line on a chart
 */
export class ChartSeries {
  constructor (data = {}) {
    Object.assign(this, data)
    this.data = this.data || []
    this.parsing = this.data.parsing == null ? true : Boolean(this.data.parsing)
  }

  /**
   * Series name
   * @type {String}
   */
  name

  /**
   * Series type
   * @type {String}
   */
  type

  /**
   * Series data type
   * @type {DeviceAuditEventType}
   */
  dataType

  /**
   * Indicates that chart series represents time points
   * @type {Boolean}
   */
  isTimeSeries

  /**
   * Indicates whether chart series data requires parsing.
   * Use `false` when chart data is correctly sorted and typed,
   * to speed up chart rendering.
   * @type {Boolean}
   */
  parsing

  /**
   * Series description
   * @type {String}
   */
  description

  /**
   * Series label
   * @type {String}
   */
  label

  /**
   * Series line and label background color
   * @type {String}
   */
  backgroundColor

  /**
   * Series label border color
   * @type {String}
   */
  borderColor

  /**
   * Series label border with
   * @type {Number}
   */
  borderWidth

  /**
   * Series data unit
   * @type {String}
   */
  unit

  /**
   * Series options
   * @type {Object}
   */
  options

  /**
   * Values to show
   * @type {Array[Number]}
   */
  data

  /**
   * Checks whether series does not have any data
   * @type {Boolean}
   */
  get isEmpty () {
    return (this.data || []).length === 0
  }

  /**
   * Checks whether series has any data
   * @type {Boolean}
   */
  get hasData () {
    return !this.isEmpty
  }

  /**
   * Adds data point to series
   * @param {Number} x X coordinate
   * @param {Number} x X coordinate
   */
  add (data) {
    if (data?.x != null && data?.y != null) {
      this.data.push(data)
    }
  }

  /**
   * Clears the series
   */
  clear () {
    this.data = []
  }
}
