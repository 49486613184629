/**
 * Document categories
 */
export const DocumentCategory = {
  FAQ: 'FAQ',
  ChangeLog: 'changelog',
  Pricing: 'pricing',
  FirmwareHistory: 'firmware-history'
}

/**
 * Document subcategories
 */
export const DocumentSubcategory = {
  Current: 'current',
  Legacy: 'legacy',
  TestTool: 'tt'
}
