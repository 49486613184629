<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    // Path displayed in the breadcrumbs, an array of { name, title, params, query } pairs
    path: {
      type: Array,
      required: true
    },
    // Custom CSS class assigned to the breadcrumbs component
    cssClass: String,
    // Custom title of the last item. Normally this will be taken
    // from default item title, but in some situations
    // we might want to override that. Example: a view
    // where we're editing a record such as user or device,
    // and we'd like to show the user ID or device serial as title.
    title: String
  },

  computed: {
    ...mapGetters([
      'getView',
      'getRouteByView'
    ]),

    visiblePath () {
      return (this.path || []).filter(p => p && !p.isHidden)
    }
  },

  methods: {
    // Returns route for the specified breadcrumbs item
    getRoute (item) {
      if (item.route) {
        return item.route
      }
      const view = this.getView(item.name)
      if (view) {
        let route = view.route || this.getRouteByView(item.name)
        if (route) {
          return route.name
        }
      }
    }
  }
}
</script>

<template>
  <ul :class="{ breadcrumbs: true, ['css-class']: cssClass ? true : false }">
    <li v-for="(item, index) in visiblePath" :key="item.name">
      <span>{{ index === 0 ? '' : ' > ' }}</span>
      <router-link
        class="item-link"
        v-if="getRoute(item) && (index < visiblePath.length - 1)"
        :to="{
          name: getRoute(item),
          params: item.params,
          query: item.query
        }"
      >{{ item.title }}</router-link>
      <span v-else>{{ item.title }}</span>
    </li>
  </ul>
</template>

<style scoped lang="scss">
ul.breadcrumbs {
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
    margin-left: 4px;
    font-size: 13px;
    color: #7a7a7a;

    &:last-child {
      font-weight: bold;
      color: #707070;
    }
  }
}
</style>