import { Point } from './point'

/**
* Determines whether the specified point belongs to a given two-point vector
* @param {Point} point Point to check
* @param {Point} start Vector start
* @param {Point} end Vector end
* @param {Number} fuzzy If greater than zero, we also search in vicinity of the line
* @returns {Boolean}
 */
export function pointBelongsToVector (point, start, end, fuzzy = 0) {
  const ds = distance(point, start)
  const de = distance(point, end)
  const d = distance(start, end)
  const diff = Math.floor(Math.abs((ds + de) - d))

  return diff <= fuzzy
}

/**
 * Determines length of the specified vector
 * @param {Point} start Vector start
 * @param {Point} end Vector end
 * @returns {Number}
 */
export function distance (start, end) {
  if (start && end) {
    return Math.sqrt(Math.pow(start.x - end.x, 2) + Math.pow(start.y - end.y, 2))
  }
}

/**
 * Determines the length of the specified multi-point line
 * @param {Array[Point]} points Line
 * @returns {Number}
 */
export function lineLength (points) {
  if (points?.length > 1) {
    return points.reduce((length, point, index, points) => {
      if (index < 1) return length
      return length + distance(point, points[index - 1])
    }, 0)
  }
}

/**
 * Determines a point at some distance from the specified point
 * @param {Point} point Point
 * @param {Point} direction Point determining direction toward which the resulting point should be moved
 * @param {Number} distance Distance in pixels
 * @returns {Point}
 */
export function getNearbyPoint (point, direction, distance) {
  if (point && direction && distance != null) {
    const dx = point.x - direction.x
    const dy = point.y - direction.y
    const sx = Math.sign(dx)
    const sy = Math.sign(dy)

    // If distance is zero or points are the same, just return the point
    if (!(distance > 0) || (dx === 0 && dy === 0)) {
      return new Point({ x: point.x, y: point.y })
    }

    if (dx === 0) {
      const ratio = distance / dy
      const x = point.x
      const y = point.y - sy * (ratio * dy)
      return new Point({ x, y })
    } else {
      const d = Math.sqrt(dx * dx, dy * dy)
      const ratio = distance / d
      const x = point.x - sx * (ratio * d)
      const y = point.y - sy * (ratio * d)
      return new Point({ x, y })
    }
  }
}

/**
 * Determines whether point is inside the specified rectangle
 * @param {Point|Rectangle} item Point or rectangle to check
 * @param {Rect} rect Rectangle
 * @returns {Boolean}
 */
export function isInsideRectangle (item, rect) {
  if (item && rect) {
    if (item.leftTop != null && item.rightBottom != null) {
      // We have a rectangle to check
      return isInsideRectangle(item.leftTop, rect) && isInsideRectangle(item.rightBottom, rect)
    } else {
      // We have a point to check
      const { x, y } = item
      return x >= rect.left &&
        x <= rect.right &&
        y >= rect.top &&
        y <= rect.bottom
    }
  }
}

/**
 * Determines whether rectangle overlaps with another rectangle
 * @param {Rect} a Rectangle
 * @param {Rect} b Rectangle
 * @returns {Boolean}
 */
export function rectanglesOverlap (a, b) {
  if (a && b) {
    return isInsideRectangle(a.leftTop, b) ||
      isInsideRectangle(a.rightTop, b) ||
      isInsideRectangle(a.leftBottom, b) ||
      isInsideRectangle(a.rightBottom, b) ||
      isInsideRectangle(b.leftTop, a) ||
      isInsideRectangle(b.rightTop, a) ||
      isInsideRectangle(b.leftBottom, a) ||
      isInsideRectangle(b.rightBottom, a)
  }
}

/**
 * Snaps the point to a grid at specified granularity
 * @param {Point} point Point to snap to
 * @param {Point} granularity Grid granularity at each axis
 * @param {Object} options Snap options. If not specified, snap to nearest grid line.
 * Otherwise when:
 *   `lower` - then snap to grid line to the left/top of the point
 *   `upper` - then snap to grid line to the right/bottom of the point
 * @returns {Point} New point snapped to the grid
 */
export function snapPoint (point, granularity, { lower, upper } = {}) {
  if (!(point && granularity)) return
  if (granularity.x <= 0 && granularity.y <= 0) return point

  const sx = point.x / granularity.x
  const sy = point.y / granularity.y

  let x, y
  if (lower) {
    x = Math.floor(sx) * granularity.x
    y = Math.floor(sy) * granularity.y
  } else if (upper) {
    x = Math.ceil(sx) * granularity.x
    y = Math.ceil(sy) * granularity.y
  } else {
    x = Math.round(sx) * granularity.x
    y = Math.round(sy) * granularity.y
  }

  return new Point({ x, y })
}

/**
 * Find the intersection point between two vectors
 * @param {Point} p1 Start of the first vector
 * @param {Point} p2 End of the first vector
 * @param {Point} q1 Start of the second vector
 * @param {Point} q2 End of the second vector
 * @returns {Point} Intersection point
 */
export function intersection (p1, p2, q1, q2) {
  const dx1 = p2.x - p1.x
  const dy1 = p2.y - p1.y
  const dx2 = q2.x - q1.x
  const dy2 = q2.y - q1.y

  const denominator = dx1 * dy2 - dy1 * dx2
  if (denominator === 0) {
    // Parallel lines, no intersection
    return null
  }

  const t = ((q1.x - p1.x) * dy2 - (q1.y - p1.y) * dx2) / denominator

  return new Point({
    x: p1.x + t * dx1,
    y: p1.y + t * dy1
  })
}

/**
 * Clips a polygon against the specified rectangle
 * @param {Array[Point]} polygon Polygon to clip
 * @param {Rectangle} rectangle Rectangle to clip against
 * @returns {Array[Point]}
 */
export function clipPolygon (polygon, rectangle) {
  const { leftTop, rightBottom } = rectangle
  const clippedPolygon = []

  for (let i = 0; i < polygon.length; i++) {
    const currentPoint = polygon[i]
    const nextPoint = polygon[(i + 1) % polygon.length]

    const currentInside = isInsideRectangle(currentPoint, rectangle)
    const nextInside = isInsideRectangle(nextPoint, rectangle)

    if (currentInside && nextInside) {
      clippedPolygon.push(nextPoint)
    } else if (currentInside && !nextInside) {
      clippedPolygon.push(intersection(currentPoint, nextPoint, leftTop, rightBottom))
    } else if (!currentInside && nextInside) {
      clippedPolygon.push(intersection(currentPoint, nextPoint, leftTop, rightBottom))
      clippedPolygon.push(nextPoint)
    }
  }

  return clippedPolygon
}

/**
 * Sorts an array of points in clockwise order
 * @param {Array[Point]} points Points to sort
 * @returns {Array[Point]}
 */
export function sortPoints (points) {
  if (points?.length > 0) {
    // Calculate the center point
    const center = points.reduce(
      (acc, point) => {
        acc.x += point.x
        acc.y += point.y
        return acc
      },
      { x: 0, y: 0 }
    )
    center.x /= points.length
    center.y /= points.length

    // Sort the points in clockwise order, using the point angle towards the center
    return points.sort((a, b) => {
      const angleA = Math.atan2(a.y - center.y, a.x - center.x)
      const angleB = Math.atan2(b.y - center.y, b.x - center.x)
      return angleA - angleB ? 1 : -1
    })

  } else {
    return points
  }
}

/**
 * Creates a new polygon by subtracting another polygon from a rectangle
 * @param {Rectangle} rectangle Rectangle to subtract from
 * @param {Array[Point]} polygon Polygon to subtract
 * @returns {Array[Point]}
 */
export function subtractPolygon (rectangle, polygon) {
  if (!rectangle) return
  if (!(polygon?.length > 0)) return rectangle.points

  const sorted = sortPoints(polygon)
  const result = [
    rectangle.leftTop,
    rectangle.leftBottom,
    rectangle.rightBottom,
    rectangle.rightTop,
    sorted[sorted.length - 1],
    ...sorted,
    rectangle.rightTop,
  ]
  return result
}

