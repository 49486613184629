<script>
import { mapActions } from 'vuex'
import { Secure } from '@stellacontrol/security-ui'
import { sortProperties } from '@stellacontrol/utilities'
import { Notification, Clipboard } from '@stellacontrol/client-utilities'
import AnyParameterEditor from './editors/any-parameter.vue'

export default {
  mixins: [
    Secure
  ],

  props: {
    device: {
      required: true
    },
    status: {
      required: true
    }
  },

  components: {
    'sc-any-parameter-editor': AnyParameterEditor
  },

  computed: {
    // Returns true if any settings on the device have been customized
    hasCustomSettings () {
      return this.status?.isCustomized()
    },

    // Raw device MEGA string
    megaString () {
      const { reported, custom } = this.status
      if (reported) {
        // Combine reported and custom values
        // Move `extra` node to the end
        const extra = reported.extra
        const mega = JSON.parse(JSON.stringify({ custom, reported }))
        const sorted = sortProperties(mega)
        delete sorted.reported.extra
        sorted.reported.extra = sortProperties(extra)
        return JSON.stringify(sorted, null, 2)
      } else {
        return ''
      }
    }
  },

  methods: {
    ...mapActions([
      'clearCustomDeviceSettings',
      'updateDeviceSettings',
      'showDialog'
    ]),

    // Clears the entire desired shadow from MEGA
    async clear () {
      const { device, status: { custom } = {} } = this
      if (custom) {
        const parameters = Object.keys(custom)
        await this.clearCustomDeviceSettings({ device, parameters, peekStatus: false })
      }
    },

    // Copies complete MEGA string to clipboard
    async copyMEGAToClipboard () {
      const { megaString } = this
      if (megaString) {
        await Clipboard.write(megaString)
        Notification.success({ message: 'MEGA has been copied to clipboard' })
      }
    },

    // Edits arbitrary parameter of the device shadow
    async editParameter () {
      const { device, status } = this

      const { isOk, data } = await this.showDialog({
        dialog: 'edit-device-parameter',
        data: {
          device,
          status
        }
      })

      if (isOk && data) {
        const { name, value } = data
        await this.updateDeviceSettings({
          device,
          parameters: { [name]: value },
          confirmation: `Set [${name}] to [${value}]?`
        })
      }
    }
  }
}
</script>

<template>
  <div class="shadow-container column">
    <section class="shadow">
      <header class="row q-gutter-sm q-mt-sm">
        <span>
          Device Shadow
        </span>
        <q-space></q-space>
        <q-btn no-caps unelevated dense icon="content_copy" label="Copy to Clipboard"
          @click="copyMEGAToClipboard()">
        </q-btn>
        <q-btn v-if="isSuperAdministrator" no-caps unelevated dense icon="tune" label="Set Parameter" @click="editParameter()">
          <sc-tooltip>
            Assign a new value to an arbitrary parameter
          </sc-tooltip>
        </q-btn>
        <q-btn no-caps unelevated dense icon="restart_alt" label="Return Control to Device"
          @click="clear()" v-if="hasCustomSettings">
          <sc-tooltip>
            Returns control of customized settings from platform back to the device
          </sc-tooltip>
        </q-btn>
      </header>
      <main>
        <textarea class="input-shadow" readonly type="textarea" :value="megaString"></textarea>
      </main>
    </section>

    <sc-any-parameter-editor></sc-any-parameter-editor>
  </div>

</template>

<style scoped lang="scss">
.shadow-container {
  flex: 1;
  width: 100%;
  height: 100%;
  padding: 16px;
  padding-top: 0;

  .shadow {
    flex: 1;
    display: flex;
    flex-direction: column;

    header {
      flex: 0;
      font-size: 17px;
      margin-bottom: 8px;
    }

    main {
      flex: 1;
      display: flex;
      flex-direction: column;

      .input-shadow {
        flex: 1;
        border: solid silver 1px;
        padding: 4px;
        outline: 0;
        resize: none;
        font-size: 15px;
        font-family: 'Courier New', Courier, monospace;
      }
    }
  }
}

/* Layout adjustments for screen below HD resolution */
@media screen and (max-width: 1365px) {
  .shadow-container {
    padding: 8px;
    padding-top: 0;
  }
}
</style>