<script>
import { mapState, mapActions } from 'vuex'

export default {
  props: {
    column: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapState({
      gridFilters: state => state.inventoryView.gridFilters
    })
  },

  methods: {
    ...mapActions([
      'filterInventory'
    ])
  }
}
</script>

<template>
  <q-input dense clearable clear-icon="close" bg-color="#e8e8e8" :model-value="gridFilters[column.name]"
    :label="column.label" debounce="1000"
    @update:model-value="value => filterInventory({ column, value })" />
</template>

<style>

</style>