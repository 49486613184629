<script>
import { mapState } from 'vuex'
import { CellMixin } from './cell-mixin'
import { Secure } from '@stellacontrol/security-ui'

export default {
  mixins: [
    CellMixin,
    Secure
  ],

  props: {
    item: {
      type: Object,
      required: true,
    }
  },

  computed: {
    ...mapState({
      allDevices: state => state.devices.devices || []
    }),

    // Parameters of a link leading to device dashboard
    dashboardRoute () {
      const { item: { serialNumber } } = this
      return {
        name: 'device-dashboard',
        params: { serialNumber }
      }
    },

    // Master device of the current board,
    // if board makes a part of a multi-device
    masterDevice () {
      const { item: { device: { partOf } } } = this
      return partOf ? this.allDevices.find(d => d.id === partOf) : undefined
    },

    // Parts of a multi-device
    deviceParts () {
      const { item: { device: { id, isMultiDevice } } } = this
      return isMultiDevice ? this.allDevices.filter(d => d.partOf === id) : []
    }
  }
}
</script>

<template>
  <div :style="columnStyle" class="row items-center text clip">
    <router-link class="item-link" @click.stop
      v-if="item.device.isConnectedDevice && canUse('device-dashboard') && item.device.owner"
      :to="dashboardRoute">
      {{ item.device.serialNumber }}
      <sc-tooltip :text="`Go to ${item.device.serialNumber} dashboard`"></sc-tooltip>
    </router-link>

    <span v-else>
      {{ item.device.serialNumber }}
    </span>

    <router-link class="item-link" @click.stop
      v-if="masterDevice"
      :to="{ name: 'inventory', query: { selection: masterDevice?.serialNumber }} ">
      <q-icon size="sm" color="indigo-5" name="arrow_right" class="q-ml-xs"></q-icon>
      <sc-tooltip :text="`Select master device ${masterDevice?.serialNumber}`"></sc-tooltip>
    </router-link>

    <q-icon v-if="deviceParts.length > 0" size="xs" color="indigo-5" name="memory" class="q-ml-xs">
      <sc-tooltip :text="`Device boards:<br><ul>${deviceParts.map(p => `<li>${p.acronym} ${p.serialNumber}</li>`).join('\n')}</ul>`">
      </sc-tooltip>
    </q-icon>

  </div>
</template>

<style>
</style>