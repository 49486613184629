/**
 * Geographic regions where devices operate,
 * which determine applicable frequencies etc.
 */
export const DeviceRegion = {
  Off: 'OFF',
  EMEA: 'EMEA',
  USA: 'USA'
}

/**
 * Geographic region control modes
 */
export const DeviceRegionControl = {
  Manual: 'MANUAL',
  GPS: 'GPS',
  SingleBoard: 'SINGLEBOARD-ON'
}

/**
 * Descriptions of geographic regions where devices operate,
 * which determine applicable frequencies etc.
 */
export const DeviceRegionDescription = {
  [DeviceRegion.Off]: 'Off',
  [DeviceRegion.EMEA]: 'EMEA',
  [DeviceRegion.USA]: 'USA'
}

/**
 * List of known regions
 */
export const DeviceRegions = [
  DeviceRegion.Off,
  DeviceRegion.EMEA,
  DeviceRegion.USA
]

/**
 * Checks whether device region is supported
 */
export function isDeviceRegionValid (region) {
  return region && DeviceRegions.includes(region)
}
