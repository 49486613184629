import Konva from 'konva'
import { PlanItemType } from '@stellacontrol/planner'
import { Shape } from './shape'

/**
 * Radiation beam
 */
export class BeamShape extends Shape {
  constructor (item, data) {
    super(item, data)
    this.createShapes()
  }

  static get type () {
    return PlanItemType.Beam
  }

  /**
   * Circle shape making up the beam
   * @type {Konva.Circle}
   */
  circle

  /**
   * Rectangle shape making up the beam
   * @type {Konva.Rect}
   */
  rectangle

  /**
   * Wedge shape making up the beam
   * @type {Konva.Wedge}
   */
  wedge

  createShapes () {
    super.createShapes()

    this.circle = new Konva.Circle()
    this.rectangle = new Konva.Rect()
    this.wedge = new Konva.Wedge()
    this.content.add(
      this.circle,
      this.rectangle,
      this.wedge
    )
  }

  render (renderer) {
    super.render(renderer)
    const { circle, rectangle, item } = this
    let shape

    if (item) {
      if (item.isOmnidirectional) {
        shape = circle
        circle.radius(item.radius)
        circle.fillRadialGradientStartRadius(0)
        circle.fillRadialGradientEndRadius(item.radius - item.radius * 0.1)
        circle.fillRadialGradientColorStops(item.gradient)
      } else {
        circle.hide()
      }

      if (item.isStraight) {
        shape = rectangle
        rectangle.width(item.radius)
        rectangle.height(Math.round(Math.min(10, item.radius * 0.1)))
        rectangle.fillRadialGradientStartRadius(0)
        rectangle.fillRadialGradientEndRadius(item.radius - item.radius * 0.1)
        rectangle.fillRadialGradientColorStops(item.gradient)
      } else {
        rectangle.hide()
      }

      if (shape) {
        shape.cache()
        shape.filters([Konva.Filters.Blur])
        shape.blurRadius(item.blurRadius)
        shape.globalCompositeOperation(item.blendMode)
        shape.show()
      }

      this.applyFilters()
    }
  }

  /**
   * Triggered when shape has been transformed.
   * @param {PlanRenderer} renderer Plan renderer
   * @param {PlanScale} scale Scale of the shape
   * @param {Number} rotation Shape rotation
   */
  transformed ({ renderer, scale, rotation }) {
    super.transformed({ renderer, scale, rotation })

    const { item } = this
    item.radius = Math.round(item.radius * scale.x)
    this.render(renderer)
  }
}
