<script>

export default {
  props: {
    // Selector of the container element where the tooltip is positioned
    container: {
      type: String,
      required: true
    },
    // Tooltip to show
    tooltip: {
    }
  },

  computed: {
    // Tooltip CSS class
    cssClass () {
      return {
        visible: this.tooltip?.isValid
      }
    },

    // Tooltip CSS style
    cssStyle () {
      const { tooltip, container } = this
      const containerElement = document.querySelector(container)
      const tooltipElement = this.$refs.tooltip
      if (tooltip && tooltip.isValid && containerElement && tooltipElement) {
        const { position } = this.tooltip
        // Make sure the tooltip is entirely visible within the container
        const { width, height } = containerElement.getBoundingClientRect()
        const { offsetWidth: menuWidth, offsetHeight: menuHeight } = tooltipElement
        const dx = width - (position.x + menuWidth) - 5
        const dy = height - (position.y + menuHeight) - 5
        if (dx < 0) position.moveBy({ x: dx })
        if (dy < 0) position.moveBy({ y: dy })

        return {
          'left': `${position.x}px`,
          'top': `${position.y}px`,
        }
      }
    },
  }
}

</script>

<template>
  <div ref="tooltip" class="tooltip bg-grey-9 shadow-2 rounded-borders q-pa-sm" :class="cssClass"
    :style="cssStyle">
    <div class="row items-center no-wrap text-white" v-if="tooltip" v-html="tooltip.text">
    </div>
  </div>
</template>

<style lang="scss" scoped>
.tooltip {
  position: absolute;
  z-index: 100;
  visibility: hidden;

  &.visible {
    visibility: visible;
  }
}
</style>
