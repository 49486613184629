import { Routes as plannerRoutes } from '../views/planner'
import { Routes as playgroundRoutes } from '../views/playground'

/**
 * Routes defined in this applet
 */
export const AppletRoutes = [
  ...plannerRoutes,
  ...playgroundRoutes
]

/**
 * Enumeration of route names in this applet
 */
export const AppletRoute = {
  Plan: 'plan',
  BuildingPlan: 'building-plan'
}
