/**
 * Returns true if specified route has been left
 * @param {String} name Route name
 * @param {Route} from Source route
 * @param {Route} to Target route
 */
export function isRouteLeft (name, from, to) {
  return name && from && to && from.name === name && to.name !== from.name
}

/**
 * Returns true if any of the specified routes has been left
 * @param {String} names Route names
 * @param {Route} from Source route
 * @param {Route} to Target route
 */
export function anyRouteLeft (names = [], from, to) {
  return names.some(name => isRouteLeft(name, from, to))
}
