<script>
import { mapActions } from 'vuex'
import { DaySchedule } from '@stellacontrol/utilities'
import { FormValidationRules as rules } from '@stellacontrol/client-utilities'
import { DeviceAlertStatistics, getDeviceBands } from '@stellacontrol/model'
import AlertConfigurationComponent from './alert-configuration-component'

export default {
  mixins: [
    AlertConfigurationComponent
  ],

  data () {
    return {
      rules,
      // Band usage statistics, used to determine the alert
      bandUsageStatistics: null
    }
  },

  computed: {
    // Currently selected schedule
    schedule () {
      return DaySchedule.fromString(this.configuration.schedule)
    },

    // Shows whether band usage statistics should be visible
    showStatistics () {
      return this.schedule && !this.schedule.isEmpty
    },

    // Device bands
    deviceBands () {
      const { device } = this
      const bands = getDeviceBands(device)
      return bands || []
    }
  },

  methods: {
    ...mapActions([
      'getDeviceAlertStatistics'
    ]),

    async populate () {
      const { device } = this
      if (device) {
        this.bandUsageStatistics = await this.getDeviceAlertStatistics({ device, name: DeviceAlertStatistics.BandUsage })
      } else {
        this.bandUsageStatistics = null
      }
    }
  },

  watch: {
    async device () {
      await this.populate()
    }
  },

  async created () {
    await this.populate()
  }
}
</script>

<template>
  <div v-if="showStatistics" class="q-mt-md">
    <div v-if="isSuperAdministrator && bandUsageStatistics?.hasValues" class="q-mt-md">
      <div class="q-mb-sm">
        Registered average band usage:
      </div>
      <div class="row items-start">
        <q-markup-table flat dense bordered separator="cell" class="usage-statistics">
          <thead>
            <tr>
              <th>
                Data points
              </th>
              <th v-for="band in deviceBands">
                {{ band.parameters.label }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {{ bandUsageStatistics.values?.length || 0 }}
              </td>
              <td v-for="band in deviceBands">
                {{ Math.round(bandUsageStatistics.getAverageValue(band.id) || 0) }} %
              </td>
            </tr>
          </tbody>
        </q-markup-table>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.usage-statistics {
  th {
    background-color: #f0f0f0;
  }

  th,
  td {
    text-align: left;
    min-width: 60px;
  }
}</style>