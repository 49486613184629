import { state, getters } from './device-dashboard.state'
import { mutations } from './device-dashboard.mutations'
import { actions } from './device-dashboard.actions'

export default {
  state,
  getters,
  mutations,
  actions
}
