<script>
import AlertConfigurationComponent from './alert-configuration-component'

export default {
  mixins: [
    AlertConfigurationComponent
  ],

  computed: {
    faultDurationIsModified () {
      return this.isModified(configuration => configuration.parameters.faultDuration)
    }
  }
}
</script>

<template>
  <article v-if="isEditable && advancedConfiguration">
    <section>
      <span class="text-indigo-9">
        Alert will be triggered when port fault persists for longer than:
      </span>
    </section>

    <section>
      <label :class="{ modified: faultDurationIsModified }">
        Fault duration
      </label>
      <div>
        <sc-duration-input v-model="configuration.parameters.faultDuration"
          :min="configuration.parameters.minFaultDuration"
          :max="configuration.parameters.maxFaultDuration">
        </sc-duration-input>
      </div>

    </section>
  </article>
</template>

<style scoped lang="scss">
</style>