import { stringCompare } from '@stellacontrol/utilities'
import { isMultiDevice } from './device-type'

/**
 * Device models.
 * Initialized at runtime, by querying devices table
 */
export const DeviceModels = []

/**
 * Obsolete device models
 * Initialized at runtime, by querying configuration where list of obsolete models is found
 */
export const ObsoleteDeviceModels = []

/**
 * Device models which cannot be remotely updated.
 * Initialized at runtime, by querying configuration where list of obsolete models is found
 */
export const NonUpdateableDeviceModels = []

/**
 * Checks whether for the specified device we should display
 * reseller's own model name rather than the generic model name
 * @param {Device} device Device to check
 * @returns {Boolean}
 */
export function useResellerModel (device) {
  if (device) {
    // TODO Bad, bad monkey. '69' is a hard-coded prefix for Allcom distributor devices.
    if ((device.serialNumber || '').startsWith('69')) {
      return true
    }
  }
}

/**
 * Checks whether the specified model is obsolete
 * @param {DeviceModel} model Device model to check
 * @returns {Boolean}
 */
export function isObsoleteDeviceModel (model) {
  return model && ObsoleteDeviceModels.some(m => stringCompare(m, model, false) === 0)
}

/**
 * Checks whether the specified model is not updateable
 * @param {DeviceModel} model Device model to check
 * @returns {Boolean}
 */
export function isNotUpdateableDeviceModel (model) {
  if (!model) return true
  if (isMultiDevice(model)) return true
  return ObsoleteDeviceModels.some(m => stringCompare(m, model, false) === 0)
}
