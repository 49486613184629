// GSM service types
export const GSMServiceType = {
  GSM: 0,
  UMTS: 1,
  LTE: 2
}

// GSM service names
export const GSMServiceName = {
  [GSMServiceType.GSM]: 'GSM',
  [GSMServiceType.UMTS]: 'UMTS',
  [GSMServiceType.LTE]: 'LTE'
}

