<script>
import { mapActions, mapGetters } from 'vuex'
import { Log, wait } from '@stellacontrol/utilities'
import { ViewMixin, FormMixin, Notification } from '@stellacontrol/client-utilities'
import { sortOrganizations, OrganizationSortOrder } from '@stellacontrol/model'
import { Secure } from '@stellacontrol/security-ui'
import FloorPlanDesigner from './floor-plan-designer.vue'
import { resolveFloorPlan } from './floor-plans.resolve'

const name = 'floor-plan'

export default {
  mixins: [
    ViewMixin,
    FormMixin,
    Secure
  ],

  components: {
    'sc-floor-plan-designer': FloorPlanDesigner
  },

  data () {
    return {
      name,
      organizationPlaces: [],
      tab: 'general',
      // Indicates whether floor plan designer has been fully initialized
      isInitialized: false,
      // Open the designer in full-screen mode if editing an existing record
      isMaximizedView: false,
      // Indicates whether floor plan is currently being saved
      isSavingFloorPlan: false,
      savingFloorPlanFailed: false,
      // Designer IFRAME
      designerFrame: null,
      // Designer API available to the host application
      designerAPI: null
    }
  },

  computed: {
    ...mapGetters([
      'currentOrganization',
      'organizations'
    ]),

    title () {
      const { data, organizations, inMyOrganization } = this
      const organization = organizations.find(o => o.id === data.organizationId)
      const planName = data.isNew
        ? 'New floor plan'
        : `Floor plan ${data.name}`
      const organizationName = inMyOrganization
        ? 'in my organization'
        : organization ? `in ${organization.name}` : ''
      return `${planName} ${organizationName}`
    },

    // Returns true if floor plan belongs to current organization
    inMyOrganization () {
      return this.data.organizationId === this.currentOrganization.id
    },

    // Selectable organizations
    visibleOrganizations () {
      const { organizations, currentOrganization, isSuperAdministrator } = this
      const items = sortOrganizations(organizations, OrganizationSortOrder.Rank, { currentOrganization })
      return items.filter(item => isSuperAdministrator || item.canUse('floor-plans'))
    },

    // Can design the floor plan only if it has been saved,
    // as designer saves automatically so it needs an existing record.
    canDesign () {
      return !this.data.isNew
    }
  },

  methods: {
    ...mapActions([
      'toggleSidebar',
      'getPlaces',
      'floorPlanExists',
      'saveFloorPlan',
      'editFloorPlan',
      'goBack',
      'gotoRoute'
    ]),

    // Validation rule which checks whether the specified floor plan name is already in use
    async floorPlanNameIsUnique (name) {
      const { organizationId } = this.data
      const { id, exists } = await this.floorPlanExists({ name, organizationId })
      const isUnique = !exists || id === this.data.id
      return isUnique || 'Floor plan with this name already exists'
    },

    // Prepare a list of places to select from
    async refreshPlaces() {
      this.organizationPlaces = await this.getPlaces({ organization: this.data.organization, withChildren: false })  || []
    },

    // Another organization was selected, re-populate places
    organizationSelected (id) {
      // Unassign from the place if organization has changed, as this is organization-specific!
      if (this.data.organization.id !== id) {
        this.data.placeId = undefined
        this.data.place = undefined
      }
      // Assign new organization
      this.data.organization = this.organizations.find(o => o.id === id)
      this.refreshPlaces()
    },

    // Saves the floor plan and navigates back to where the user came from
    async save (silent) {
      if (await this.validate()) {
        const { data, data: { isNew } } = this
        const floorPlan = await this.saveFloorPlan({ floorPlan: data, silent })
        if (isNew) {
          await this.editFloorPlan({ floorPlan })
        } else {
          return floorPlan
        }
      }
    },

    // Finishes editing
    async close () {
      if (await this.validate()) {
        await this.save(true)
      }

      if (this.viewName === 'place-floor-plan') {
        await this.goBack()
      } else {
        await this.gotoRoute({ name: 'floor-plans' })
      }
    },

    // Switch designer to maximized mode
    maximizedView() {
      this.isMaximizedView = true
      this.toggleSidebar({ isCollapsed: true })
      this.designerAPI.maximizeView()
    },

    // Leaves maximized mode
    normalView() {
      this.isMaximizedView = false
      this.toggleSidebar({ isCollapsed: false })
      this.designerAPI.normalView(this.isMaximizedView)
    },

    // Toggles designer tool palette
    togglePalette () {
      this.designerAPI.togglePalette()
    },

    // Called by floor plan designer when it's done initializing
    // Hands over a function for saving the model, which we can call on save
    designerInitialized (designerFrame, designerAPI) {
      Log.debug('Floor Plan Designer has been initialized', { designerFrame, designerAPI })
      this.isInitialized = true
      this.designerFrame = designerFrame
      this.designerAPI = designerAPI
      designerAPI.addEventListener('saving', (floorPlan) => this.floorPlanSaving(floorPlan))
      designerAPI.addEventListener('saved', (floorPlan) => this.floorPlanSaved(floorPlan))
      designerAPI.addEventListener('saveFailed', (error) => this.floorPlanSaveFailed(error))
    },

    // Event triggered when floor plan is about to be saved
    floorPlanSaving () {
      this.isSavingFloorPlan = true
      this.savingFloorPlanFailed = false
    },

    // Event triggered when floor plan has been saved
    floorPlanSaved (floorPlan) {
      if (floorPlan) {
        Log.debug('The floor plan has been saved')
      }
      wait(800, () => this.isSavingFloorPlan = false)
    },

    // Event triggered when there was an error while saving the floor plan
    floorPlanSaveFailed (error) {
      Log.error('The floor plan could not be saved')
      Log.exception(error)
      Notification.error({ message: 'Changes to floor plan could not be saved', details: 'Please check your network connection' })
      wait(800, () => {
        this.isSavingFloorPlan = false
        this.savingFloorPlanFailed = true
      })
    }
  },

  async created () {
    this.refreshPlaces()
  },

  // Load data on navigation to another floor plan
  async beforeRouteUpdate (to, from, next) {
    if (await resolveFloorPlan({ from, to })) {
      this.refreshPlaces()
      next()
    }
  }
}
</script>

<template>
  <sc-view :name="name" :title="title" :noHeader="isMaximizedView">
    <template #toolbar>
      <div class="row items-center q-gutter-sm">
        <q-badge v-if="isSavingFloorPlan" class="q-mr-md" color="green-6">
          <q-icon size="20px" class="rotate-reverse q-pa-xs q-mr-sm" name="change_circle" color="white"></q-icon>
          Saving ...
        </q-badge>
        <q-badge v-if="!isSavingFloorPlan && savingFloorPlanFailed" class="q-pa-xs q-mr-md" color="red-7">
          <span class="q-pa-sm">
            Save failed
          </span>
        </q-badge>
        <q-btn v-if="isInitialized && !data.isNew" label="Toggle Palette" dense unelevated @click="togglePalette()"></q-btn>
        <q-btn v-if="isInitialized && !data.isNew" label="Maximized view" dense unelevated @click="maximizedView()"></q-btn>
        <q-btn v-if="false && isInitialized && canUse('edit-floor-plans')" label="Save" dense unelevated @click="save()"></q-btn>
        <q-btn label="Close" class="primary" dense unelevated @click="close()"></q-btn>
      </div>
    </template>

    <q-form class="form" ref="form" autofocus v-if="!isMaximizedView && canUse('edit-floor-plans')">
      <div class="editor row items-center q-pl-md q-pt-lg q-pr-md q-pb-sm">
        <q-input v-model="data.name" dense square outlined debounce="1000" maxlength="255" class="q-pr-md col-3" bg-color="white"
          lazy-rules :rules="[ rules.required('Floor plan name is required'), name => floorPlanNameIsUnique(name) ]">
          <template v-slot:before>
            <label class="q-mr-sm">Name</label>
          </template>
        </q-input>

        <sc-organization-selector class="col-3 q-pr-md" bg-color="white" dense square style="margin-bottom: 20px;"
          v-model="data.organizationId"
          :items="visibleOrganizations" @update:model-value="value => organizationSelected(value)">
          <template v-slot:before>
            <label class="q-mr-sm">Organization</label>
          </template>
        </sc-organization-selector>

        <sc-place-selector class="col-3 q-pr-md" dense square style="margin-bottom: 20px;" bg-color="white"
          v-model="data.placeId"
          :items="organizationPlaces"
          no-place
          no-place-label="- No place assigned -">
          <template v-slot:before>
            <label class="q-mr-sm">Place</label>
          </template>
        </sc-place-selector>

        <q-input v-model="data.description" dense square outlined maxlength="255" class="col-3" bg-color="white" style="margin-bottom: 20px;">
          <template v-slot:before>
            <label class="q-mr-sm">Description</label>
          </template>
        </q-input>
      </div>
    </q-form>

    <div class="designer" :class="{ 'full-screen': isMaximizedView }">
      <sc-floor-plan-designer
        v-if="canDesign"
        :data="data"
        :isMaximizedView="isMaximizedView"
        @initialize="(designerFrame, designerAPI) => designerInitialized(designerFrame, designerAPI)"
        @normal-view="normalView()"
        @toggle-palette="togglePalette()">
      </sc-floor-plan-designer>
      <div v-else>
        <div class="q-ma-md text-body1">
          The new floor plan must be saved, before you can start designing.
          Click below to save and start the designer.
        </div>
        <q-btn class="q-ml-md" color="deep-purple-8" icon-right="chevron_right" label="Save and Start Designing" @click="save()"/>
      </div>

      <div class="designer-background" v-if="cannotUse('edit-floor-plans')" @click.stop>
      </div>
    </div>

  </sc-view>
</template>

<style lang='scss' scoped>
.editor {
  flex: 0;
  display: flex;
  flex-direction: row;
}

.form {
  background-color: #f8f8f8;
}

.designer {
  flex: 1;
  display: flex;
  flex-direction: column;
  border: solid silver 1px;
  padding: 8px 16px 16px 16px;
  position: relative;

  &.full-screen {
    padding: 1px;
    border: none;
  }

  .designer-background {
    position: absolute;
    z-index: 9999;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
  }
}

label {
  font-size: 14px;
}

</style>
