import { state, getters } from './device-transmission.state'
import * as mutations from './device-transmission.mutations'
import * as actions from './device-transmission.actions'

export default {
  state,
  getters,
  mutations,
  actions
}
