<script>
import { mapActions, mapGetters } from 'vuex'
import { pluralize } from '@stellacontrol/utilities'
import DeviceAction from './device-action.vue'
import { DeviceActionMixin } from './device-action-mixin'

export default {
  mixins: [DeviceActionMixin],

  components: {
    'sc-device-action': DeviceAction
  },

  data() {
    return {
      // Customer to sell to
      newOwnerId: null,
      // Customer guardian
      newOwnerGuardian: null,
      // Date and time of the sale
      soldAt: new Date(),
      // Additional notes
      notes: null,
      // Premium services assigned to all selected devices
      currentPremiumServices: [],
      // Premium services available to the new owner.
      // The new owner might have his own premium services assigned!
      availableServices: [],
      // Premium service associated with device, which owner can activate
      premiumServiceId: null,
      // Date and time of starting the premium service
      premiumServiceStartsAt: new Date()
    }
  },

  computed: {
    ...mapGetters([
      'organizationPreferences',
      'organizations',
      'fullHierarchy',
      'pricelist',
    ]),

    // All organizations which the devices can be sold to.
    // We include:
    // - child organizations
    // We ignore:
    // - current owner
    // - guest organizations
    // - shipping organizations
    customers() {
      const { organizations, ownerId } = this
      const customers = [...organizations]
      return customers
        .filter((o) => o && o.id !== ownerId)
        .filter((o) => !o.isGuestOrganization)
        .filter((o) => !o.isShippingCompany)
    },

    // New device owner
    newOwner() {
      const owner = this.customers.find((o) => o.id === this.newOwnerId)
      return owner
    },

    // Premium service currently assigned to the single selected device
    currentPremiumService() {
      const { getValue, currentPremiumServices } = this
      const premiumServiceId = getValue('premiumServiceId')
      return premiumServiceId
        ? currentPremiumServices.find((p) => p.id === premiumServiceId)
        : undefined
    },

    // Checks if there are free-of-charge premium services attached to the device,
    // which should be immediately activated when device is sold to the new owner.
    hasPendingPremiumServices() {
      const { devices, newOwner } = this
      const hasPremiumServicesToActivate = devices.some(
        (d) => d.isPremiumServiceNotStarted
      )
      return newOwner && newOwner.isPremiumCustomer && hasPremiumServicesToActivate
    },

    // New premium service selected to assign/activate on the device
    newPremiumService() {
      const { availableServices, premiumServiceId } = this
      return premiumServiceId
        ? availableServices.find((p) => p.id === premiumServiceId)
        : undefined
    },

    // Indicates whether free-of-charge premium service can be assigned to devices under current circumstances.
    // This can be done when:
    // - Current organization is a bank
    // - Selected owner is obliged to purchase premium services
    // - Device being sold doesn't already have a premium service assigned
    // - Device is connected
    canAssignPremiumService() {
      const { isBank, newOwnerGuardian, currentPremiumService, isConnected } = this
      return isBank &&
        newOwnerGuardian?.isPremiumCustomer &&
        !currentPremiumService &&
        isConnected
    },

    // Checks whether current user can select date of activating the premium service
    canSelectServiceActivationDate() {
      return (
        this.canAssignPremiumService &&
        this.newPremiumService &&
        this.activatePremiumServicesNow
      )
    },

    // Checks if there are free-of-charge premium services attached to the device,
    // which should be immediately activated when device is sold to the new owner
    activatePremiumServicesNow() {
      const { devices, newOwnerId, newOwner } = this
      if (newOwnerId && newOwner) {
        const ownerIsEndCustomer =
          newOwner.isRegularOrganization || newOwner.isGuestOrganization
        const hasPremiumServicesToActivate = devices.some(
          (d) => d.isPremiumServiceNotStarted
        )
        return ownerIsEndCustomer && hasPremiumServicesToActivate
      }
    },

    // Indicates whether sell action can be executed
    canSell() {
      return this.newOwnerId && this.newOwnerId !== this.ownerId
    },

    // Indicates whether user can change the sell date
    canChangeSellDate() {
      return this.canSell && false
    }
  },

  emits: ['execute'],

  methods: {
    ...mapActions(['getPremiumService', 'sellDevices', 'startDeviceSubscriptions']),

    // Loads available premium services
    async loadPremiumServices() {
      const { pricelist, newOwner, currentOrganization, fullHierarchy, getValues } = this

      // Load premium services available for sale
      const availableServices = pricelist.hasOwnPremiumServices(newOwner, fullHierarchy)
        ? pricelist.resellerPremiumServices(newOwner, fullHierarchy)
        : pricelist.publicPremiumServices(currentOrganization)
      this.availableServices = availableServices.filter((s) => s.isPerDevice)

      // Deselect previously selected free service, if it's not present in the loaded pricelist
      if (!availableServices.find((s) => s.id === this.premiumServiceId)) {
        this.premiumServiceId = null
      }

      // Load premium services currently associated with devices
      this.currentPremiumServices = []
      for (const id of getValues('premiumServiceId')) {
        if (id) {
          const premiumService =
            this.availableServices.find((s) => s.id === id) ||
            (await this.getPremiumService({ id }))
          if (premiumService) {
            this.currentPremiumServices.push(premiumService)
          }
        }
      }
    },

    // Selects the premium service currently specified in devices
    // If none is specified yet, select default premium service.
    selectCurrentPremiumService() {
      const { canAssignPremiumService, availableServices } = this
      this.premiumServiceId = null
      if (canAssignPremiumService) {
        const premiumServiceId = this.getValue('premiumServiceId')
        // Prevent selecting service which is no longer available,
        // select default one if none is specified
        this.premiumServiceId =
          (availableServices.find((s) => s.id === premiumServiceId) || {}).id ||
          (availableServices.find((s) => s.isDefault) || {}).id
      }
    },

    // Sells to current organization if allowed
    selectCurrentOrganization() {
      const { devices, customers, currentOrganization } = this
      if (customers.find((o) => o.id === currentOrganization.id)) {
        const alreadyOwns = devices.every((device) =>
          device.isOwnedBy(currentOrganization)
        )
        if (!alreadyOwns) {
          this.newOwnerId = currentOrganization.id
        }
      }
    },

    // Sells the selected devices to the new owner
    async sell() {
      if (await this.validate()) {
        await this.busy()
        const {
          devices,
          newOwner,
          soldAt,
          notes,
          premiumServiceId,
          activatePremiumServicesNow,
          currentPremiumService,
          premiumServiceStartsAt,
        } = this
        await this.sellDevices({
          devices,
          organization: newOwner,
          soldAt,
          notes,
          premiumServiceId,
        })

        if (activatePremiumServicesNow) {
          const devicesLabel = devices.map((d) => d.serialNumber)
          await this.startDeviceSubscriptions({
            devices,
            startsAt: premiumServiceStartsAt,
            details: `Premium service ${
              currentPremiumService.label
            } has been activated on ${pluralize(
              devices,
              'device',
              'devices'
            )} ${devicesLabel} when ${pluralize(
              devices,
              'it was',
              'they were'
            )} sold to ${newOwner.fullName}`,
            silent: true,
          })
        }
        await this.done()
        await this.executed()
      }
    }
  },

  watch: {
    // Triggered when owner has been selected
    async newOwnerId() {
      const { newOwner } = this
      this.newOwnerGuardian = null
      if (newOwner) {
        // Refresh the list of premium services available to the new owner
        this.newOwnerGuardian = await this.getGuardian({ principal: newOwner, force: true })
        await this.loadPremiumServices()
      }

      // Show currently assigned premium service if any
      if (this.canAssignPremiumService) {
        this.selectCurrentPremiumService()
      } else {
        this.premiumServiceId = null
      }
    },

    // Triggered when devices have changed
    async devices() {
      await this.loadPremiumServices()
    }
  },

  async created() {
    await this.loadPremiumServices()
    await this.selectCurrentPremiumService()
  }
}
</script>

<template>
  <sc-device-action
    :action="action"
    :devices="devices"
    execute-label="Sell"
    @closing="closing"
    @close="close"
    @execute="sell"
    :canExecute="canSell">
    <q-form ref="form" autofocus class="q-mt-md q-gutter-sm" @submit.prevent>
      <div>
        <label class="text-body2 text-grey-9" v-if="owner">
          {{ capitalize(devicesAreLabel) }}
          currently owned by
          <b>{{ owner.name }}</b>.
        </label>

        <label v-else> Nobody owns {{ devicesLabel }} yet. </label>

        <!-- Show currently assigned free-of-charge premium service -->
        <label
          v-if="currentPremiumService &&
            newOwnerGuardian?.isPremiumCustomer &&
            hasPendingPremiumServices">
          <div class="text-orange-8 q-mt-sm">
            <span v-if="currentPremiumService.neverExpires">
              <b>{{ currentPremiumService.label }}</b>
            </span>
            <span v-else>
              {{ capitalize(currentPremiumService.periodDescription) }} of
              <b>{{ currentPremiumService.label }}</b>
            </span>
            <span v-if="activatePremiumServicesNow">
              service is included and will be activated now.
            </span>
            <span v-else> service is included. </span>
          </div>
        </label>

        <label
          v-else-if="currentPremiumServices.length > 1 &&
            newOwnerGuardian?.isPremiumCustomer &&
            activatePremiumServicesNow">
          <div class="text-orange q-mt-sm">
            <span>
              There are premium services included with {{ selectionLabel }}
            </span>
            <span v-if="activatePremiumServicesNow">
              and will be activated now.
            </span>
            <sc-tooltip :text="currentPremiumServices.map((s) => s.label).join(', ')" />
          </div>
        </label>
      </div>

      <sc-organization-selector
        class="q-mt-sm"
        v-model="newOwnerId"
        label="Select the new owner"
        :items="customers"
        :rules="[rules.required('Owner is required')]">
      </sc-organization-selector>

      <sc-date-input
        v-if="canChangeSellDate"
        :dense="false"
        label="Sold on"
        v-model="soldAt"
        :rules="[rules.required('Date is required')]">
      </sc-date-input>

      <!-- Allow assigning premium service free of charge, unless one is already assigned -->
      <sc-premium-service-selector
        v-if="canSell && canAssignPremiumService"
        v-model="premiumServiceId"
        :items="availableServices"
        label="Add premium service free of charge">
      </sc-premium-service-selector>

      <div
        v-if="canSell && canAssignPremiumService && newPremiumService"
        class="q-pl-sm q-pr-sm">
        <div class="text-body2 text-orange-8">
          <span v-if="newPremiumService.neverExpires">
            Never-expiring subscription to
            <b>{{ newPremiumService.label }}</b> will be
            {{ activatePremiumServicesNow ? "activated" : "added" }}
            <b>free of charge</b>.
          </span>
          <span v-else>
            {{ capitalize(newPremiumService.periodDescription) }}
            of <b>{{ newPremiumService.label }}</b> will be
            {{ activatePremiumServicesNow ? "activated" : "added" }}
            free of <b>charge</b>.
          </span>
        </div>
        <div v-if="isBank" class="q-mt-sm">
          To sell a paid premium service, clear the free service above and proceed with
          the sale of {{ devicesLabel }}. Once completed, go to Premium Services action,
          where you can sell services to the new owner of {{ devicesLabel }}
        </div>
      </div>

      <sc-date-input
        v-if="canSell && canSelectServiceActivationDate"
        :dense="false"
        label="Activate premium service on"
        v-model="premiumServiceStartsAt"
        :rules="[rules.required('Date is required')]">
      </sc-date-input>

      <q-input
        v-if="canSell"
        square
        outlined
        label="Notes"
        v-model="notes"
        type="textarea"
        autogrow>
      </q-input>
    </q-form>
  </sc-device-action>
</template>

<style lang="scss" scoped></style>
