/**
 * Current live status of a device band
 */
export const DeviceBandStatus = {
  Offline: 'offline',
  Online: 'online',
  Adjusting: 'adjusting',
  Oscillating: 'oscillating',
  ShutdownImminent: 'shutdown-imminent'
}

/**
 * User-friendly names of live states
 */
export const DeviceBandStatusDescription = {
  [DeviceBandStatus.Offline]: 'Not Connected',
  [DeviceBandStatus.Online]: 'Connected',
  [DeviceBandStatus.Adjusting]: 'Adjusting'
}

/**
 * Colors representing various states of device bands
 */
export const DeviceBandColor = {
  None: 'transparent',
  Ok: '#2ecd6f',
  Warning: '#ff851b',
  Alert: '#ab47bc',
  Error: '#e20400',
  Uplink: '#08adff',
  ReducedGain: '#923db4',
  Oscillation: '#e20400',
  OverPower: '#ff851b',
  NotAvailable: '#666666'
}
