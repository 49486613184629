<script>
import { mapActions } from 'vuex'
import { distinctValues } from '@stellacontrol/utilities'
import ScanSection from './ttscan.section.vue'
import ScanSectionSummary from './ttscan.section.summary.vue'
import ScanSectionSpectrum from './ttscan.section.spectrum.vue'
import ScanSectionNeighbours from './ttscan.section.neighbours.vue'
import ScanSectionSurvey from './ttscan.section.survey.vue'
import ScanSectionRaw from './ttscan.section.raw.vue'

export default {
  props: {
    // File with TT Scan results
    file: {
    }
  },

  components: {
    'sc-ttscan-section': ScanSection,
    'sc-ttscan-section-summary': ScanSectionSummary,
    'sc-ttscan-section-spectrum': ScanSectionSpectrum,
    'sc-ttscan-section-neighbours': ScanSectionNeighbours,
    'sc-ttscan-section-survey': ScanSectionSurvey,
    'sc-ttscan-section-raw': ScanSectionRaw
  },

  data () {
    return {
      // Status of preview sections
      collapsed: {
        summary: false,
        neighbours: false,
        spectrum: false,
        survey: false,
        data: true
      }
    }
  },

  computed: {
    // Scan results
    results () {
      return this.file?.data?.results
    },

    // Indicates whether any scan results are available
    hasResults () {
      return this.results != null
    },

    // Scanned operators
    operators () {
      return this.results?.operatorNames
    },

    operatorNames () {
      return Object
        .entries(this.results?.operatorNames)
        .map(([id, name]) => ({ id, name }))
    },

    // Scanned bands
    scannedBands () {
      const neighbour = this.results.neighbourResults || []
      return distinctValues(neighbour.flatMap(item => item.bandNumbersScanned))
    },

    // Indicates whether to show the summary
    hasSummary () {
      return true
    },

    // Spectrum scan results
    spectrumResults () {
      return this.results?.spectrumResults || []
    },

    hasSpectrumResults () {
      return this.spectrumResults.length > 0
    },

    // Cell scan results
    neighbourResults () {
      return this.results?.neighbourResults || []
    },

    hasNeighbourResults () {
      return this.neighbourResults.length > 0
    },

    // Survey results
    surveyResults () {
      return this.results?.surveyResults || []
    },

    hasSurveyResults () {
      return this.surveyResults.length > 0
    }
  },

  methods: {
    ...mapActions([
    ]),

    // Populates the view
    populate () {
      // expand raw data if not other section is visible
      const { hasSpectrumResults, hasNeighbourResults, hasSurveyResults } = this
      if (!(hasSpectrumResults || hasNeighbourResults || hasSurveyResults)) {
        this.collapsed.data = false
      }
    },

    // Toggles a section
    toggle ({ section }) {
      this.collapsed[section] = !this.collapsed[section]
    }
  },

  created () {
    this.populate()
  }
}

</script>

<template>
  <div class="ttscan" v-if="hasResults">
    <!-- Summary -->
    <sc-ttscan-section name="summary" title="Summary" :collapsed="collapsed" @toggle="toggle"
      v-if="hasSummary">

      <sc-ttscan-section-summary :collapsed="collapsed" @toggle="toggle" :file="file"
        :operators="operators">
      </sc-ttscan-section-summary>

    </sc-ttscan-section>

    <!-- Neighbour results -->
    <sc-ttscan-section v-if="hasNeighbourResults" name="neighbours" title="Live Scan"
      :collapsed="collapsed" @toggle="toggle">

      <sc-ttscan-section-neighbours :collapsed="collapsed" @toggle="toggle" :file="file"
        :items="neighbourResults" :operators="operators">
      </sc-ttscan-section-neighbours>

    </sc-ttscan-section>

    <!-- Spectrum results -->
    <sc-ttscan-section v-if="hasSpectrumResults" name="spectrum" title="Spectrum Scan"
      :collapsed="collapsed" @toggle="toggle">

      <sc-ttscan-section-spectrum :collapsed="collapsed" @toggle="toggle" :file="file" :items="spectrumResults"
        :operators="operators">
      </sc-ttscan-section-spectrum>

    </sc-ttscan-section>

    <!-- Survey results -->
    <sc-ttscan-section v-if="hasSurveyResults" name="survey" title="Cell ID Scan"
      :collapsed="collapsed" @toggle="toggle">

      <sc-ttscan-section-survey :collapsed="collapsed" @toggle="toggle" :file="file" :items="surveyResults"
        :operators="operators">
      </sc-ttscan-section-survey>

    </sc-ttscan-section>

    <!-- Raw data -->
    <sc-ttscan-section name="data" title="Raw Data" :collapsed="collapsed" @toggle="toggle">
      <sc-ttscan-section-raw :collapsed="collapsed" @toggle="toggle" :file="file">
      </sc-ttscan-section-raw>
    </sc-ttscan-section>
  </div>
</template>

<style lang="scss" scoped>
.ttscan {
  overflow: auto;

  .data {
    padding: 8px;
  }
}
</style>
