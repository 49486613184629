// Creates new clean state for the inventory view,
// preserving certain state between navigations
export function createState (previousState) {
  return {
    ...(previousState || {}),
    // Indicates whether action panel is visible
    isVisible: false,
    // Indicates whether action panel is collapsed
    isCollapsed: false,
    // Currently opened tab
    tab: null,
    // Owner of the selected devices.
    // Populated when all devices have the same owner!
    owner: null,
    // Security guardian for the owner of the selected devices.
    // Populated when all devices have the same owner!
    ownerGuardian: null,
    // List of selected inventory items
    items: [],
    // List of selected devices
    devices: [],
    // Current action
    action: {
      action: null,
      icon: null,
      label: null,
      description: null
    }
  }
}

export const state = createState()

export const getters = {
}
