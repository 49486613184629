<script>
import { mapGetters, mapActions } from 'vuex'
import { ViewMixin, ListAction } from '@stellacontrol/client-utilities'
import { Guardian } from '@stellacontrol/security'
import { OrganizationSortOrder, OrganizationIcons, OrganizationColors, getOrganizationIcon, OrganizationLevelNames, sortOrganizations } from '@stellacontrol/model'
import { Secure } from '../../components'

const name = 'organization-profiles'

export default {
  mixins: [
    ViewMixin,
    Secure
  ],

  data () {
    return {
      name,
      OrganizationIcons,
      OrganizationColors,
      OrganizationLevelNames,
      // Security guardian for the profile assigned to the current organization
      currentOrganizationProfileGuardian: null
    }
  },

  computed: {
    ...mapGetters([
      'features',
      'environment',
      'pricelist',
      'currentOrganizationProfile',
      'organizationProfiles',
      'myOrganizationProfiles',
      'otherOrganizationProfiles',
      'availableOrganizationProfiles',
      'organizationProfileColumns',
      'myDefaultOrganizationProfile',
      'isMyOrganizationProfile'
    ]),

    // Available profiles available
    profiles () {
      const { isSuperOrganization } = this

      // Super-organization can see all profiles
      if (isSuperOrganization) {
        const { organizationProfiles } = this
        return sortOrganizations(organizationProfiles, OrganizationSortOrder.Rank, { byFullName: true })

      } else {
        // Resellers can edit
        const { myOrganizationProfiles, otherOrganizationProfiles } = this
        return [
          ...sortOrganizations(myOrganizationProfiles, OrganizationSortOrder.Rank, { byFullName: true }),
          ...sortOrganizations(otherOrganizationProfiles, OrganizationSortOrder.Rank, { byFullName: true }),
        ]
      }
    },

    hasProfiles () {
      return (this.profiles || []).length > 0
    },

    // Determines the color of profile icon
    profileIconColor () {
      return profile => this.isMyOrganizationProfile(profile)
        ? (OrganizationColors[profile.level] || 'indigo-5')
        : 'grey-5'
    },

    // Indicates that specified profile is a default one for this organization.
    // If none of the profiles owned by current organization is marked as default,
    // pick the one from shared profiles.
    isDefaultProfile () {
      return profile => {
        const { myDefaultOrganizationProfile } = this
        return myDefaultOrganizationProfile && myDefaultOrganizationProfile.id === profile.id
      }
    },

    // List actions
    actions () {
      const { isMyOrganizationProfile } = this

      return [
        {
          ...ListAction.Edit,
          // Only allow editing of own organization profiles
          isVisible: item => isMyOrganizationProfile(item)
        },
        {
          name: 'copy',
          icon: 'content_copy',
          label: 'Copy',
          color: 'green-9',
          // Only allow cloning of own organization profiles
          isVisible: item => isMyOrganizationProfile(item)
        },
        {
          ...ListAction.Delete,
          // Only allow deleting of own organization profiles
          isVisible: item => isMyOrganizationProfile(item)
        }
      ]
    }
  },

  methods: {
    ...mapActions([
      'createOrganizationProfile',
      'editOrganizationProfile',
      'copyOrganizationProfile',
      'removeOrganizationProfile'
    ]),

    getOrganizationIcon,

    executeAction (organizationProfile, action) {
      switch (action.name) {
        case 'edit':
          return this.editOrganizationProfile({ organizationProfile })
        case 'delete':
          return this.removeOrganizationProfile({ organizationProfile, confirm: true })
        case 'copy':
          return this.copyOrganizationProfile({ organizationProfile, confirm: true })
        default:
          throw new Error(`Unhandled action ${action.name}`)
      }
    }
  },

  created () {
    // Get guardian for current organization profile.
    // This is needed to determine which types of organization
    // the current organization is allowed to create
    const { features, currentOrganizationProfile, pricelist, environment } = this
    this.currentOrganizationProfileGuardian = new Guardian({
      features,
      principal: currentOrganizationProfile,
      pricelist,
      environment
    })
  }
}
</script>

<template>
  <sc-view :name="name" v-if="isLoggedIn">

    <template #toolbar>
      <q-btn label="Add Organization Profile" icon="add" class="primary" unelevated
        @click="createOrganizationProfile()"></q-btn>
    </template>

    <div class="q-ma-md" v-if="!hasProfiles">
      <div class="q-mb-md">
        You can add here organization profiles. These are types of organizations
        which your resellers can create, their permissions and other settings.
      </div>
      <q-btn color="deep-purple-8" icon="add" label="Add Organization Profile"
        @click="createOrganizationProfile()"></q-btn>
    </div>

    <template #header>
      <sc-list-filter :name="name" v-if="hasProfiles"></sc-list-filter>
    </template>

    <sc-list v-if="hasProfiles" :name="name" :columns="organizationProfileColumns" :items="profiles"
      row-key="id" :actions="actions" @action="executeAction">

      <template v-slot:body-cell-level="props">
        <q-td :props="props" style="width: 24px;">
          <q-icon size="22px" :name="getOrganizationIcon(props.row)"
            :color="profileIconColor(props.row)" />
        </q-td>
      </template>

      <template v-slot:body-cell-fullName="props">
        <q-td :props="props">
          <router-link v-if="isMyOrganizationProfile(props.row)" class="item-link"
            :to="{ name: 'organization-profile', params: { id: props.row.id } }">
            {{ props.row.fullName }}
            <sc-tooltip :text="`Edit profile ${props.row.fullName}`" />
          </router-link>
          <span v-else class="text-grey-7">
            {{ props.row.fullName }}
          </span>
        </q-td>
      </template>

      <template v-slot:body-cell-profileOwner="props">
        <q-td :props="props">
          <span :class="{ 'text-grey-7': !isMyOrganizationProfile(props.row) }">
            {{ props.row.owner ? props.row.owner.name : 'Shared profile' }}
          </span>
        </q-td>
      </template>

      <template v-slot:body-cell-levelDescription="props">
        <q-td :props="props">
          <span :class="{ 'text-grey-7': !isMyOrganizationProfile(props.row) }">
            {{ OrganizationLevelNames[props.row.level] }}
          </span>
        </q-td>
      </template>

      <template v-slot:body-cell-isEnabled="props">
        <q-td :props="props">
          <span :class="{ 'text-grey-7': !isMyOrganizationProfile(props.row) }">
            {{ props.row.isEnabled ? 'Active' : 'Inactive' }}
          </span>
        </q-td>
      </template>

      <template v-slot:body-cell-isDefault="props">
        <q-td :props="props">
          <span :class="{ 'text-grey-7': !isMyOrganizationProfile(props.row) }">
            {{ isDefaultProfile(props.row) ? 'Default Profile' : '' }}
          </span>
        </q-td>
      </template>

    </sc-list>

  </sc-view>
</template>

<style lang='scss' scoped>

</style>
