import { PrincipalType, PrincipalTypeDescription } from '../security/principal/principal-type'
import { UserLevelNames } from '../organization/user-level'
import { Assignable } from '../common/assignable'

/**
 * Announcement recipient
 */
export class AnnouncementRecipient extends Assignable {
  constructor (data = {}) {
    super()
    this.assign({
      ...data,
      type: data.type || PrincipalType.Organization
    })
  }

  /**
   * Recipient type
   * @type {PrincipalType}
  */
  type

  /**
   * Recipient is organization
   * @type {Boolean}
  */
  get isOrganization () {
    return this.type === PrincipalType.Organization
  }

  /**
   * Recipient is organization profile
   * @type {Boolean}
  */
  get isOrganizationProfile () {
    return this.type === PrincipalType.OrganizationProfile
  }

  /**
   * Recipient identifier
    * @type {String}
    */
  id

  /**
   * Recipient name
    * @type {String}
    */
  name

  /**
   * User level.
   * Used to limit visibility of announcement only to specific
   * categories of users such as admins.
   * @type {UserLevel}
   */
  userLevel

  /**
   * Checks whether the recipient is valid
   * @type {Boolean}
   */
  get isValid () {
    return (this.id?.trim()) && (this.name?.trim())
  }

  /**
   * Description of the recipient
   * @type {String}
   */
  get label () {
    const { type, name, userLevel } = this
    if (type && name) {
      return [
        type !== PrincipalType.Organization ? PrincipalTypeDescription[type] : null,
        name,
        userLevel ? `(only ${UserLevelNames[userLevel]})` : null
      ]
        .filter(s => s?.trim())
        .join(' ')
    }
  }

  /**
   * Checks whether the recipient matches the specified user
   * @param {Organization} organization Organization to check
   * @param {User} user User to check
   * @returns {Boolean} True if recipient matches the specified user
   */
  matches (organization, user) {
    if (organization && user) {
      const organizationMatches = this.isOrganization
        ? organization.id === this.id
        : (this.isOrganizationProfile
          ? organization.profileId === this.id
          : false)

      const userMatches = this.userLevel
        ? user.level === this.userLevel
        : true

      return organizationMatches && userMatches

    } else {
      return false
    }
  }

  /**
   * Creates announcement recipient from the specified organization profile
   * @param {OrganizationProfile} profile Recipient data
   * @param {UserLevel} userLevel Additional user level
   * @returns {AnnouncementRecipient}
   */
  static fromOrganizationProfile (profile, userLevel) {
    if (profile?.id && profile?.name) {
      return new AnnouncementRecipient({
        type: PrincipalType.OrganizationProfile,
        id: profile.id,
        name: profile.name,
        userLevel
      })
    }
  }

  /**
   * Creates announcement recipient from the specified organization
   * @param {Organization} organization Recipient data
   * @param {UserLevel} userLevel Additional user level
   * @returns {AnnouncementRecipient}
   */
  static fromOrganization (organization, userLevel) {
    if (organization?.id && organization?.name) {
      return new AnnouncementRecipient({
        type: PrincipalType.Organization,
        id: organization.id,
        name: organization.name,
        userLevel
      })
    }
  }
}
