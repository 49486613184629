<script>
import ParameterEditor from './parameter-editor'

export default {
  mixins: [
    ParameterEditor
  ],

  props: {
    // Edited band
    band: {
      type: Object,
      required: true
    }
  },

  computed: {
    // Colors representing uplink level
    colors () {
      return (this.values || []).map((_, index) => `green-${3 + index}`)
    },

    // Items for value selector
    items () {
      const { values = [], colors } = this
      return values.map((value, index) => ({
        label: `Level ${value}`,
        value: value,
        icon: 'wifi',
        color: colors[index]
      }))
    }
  }
}
</script>

<template>
  <div class="parameter-editor" v-if="hasValue">

    <sc-parameter-value
      :label="`Uplink On Level of ${band.label} band`"
      :reported="reported"
      :custom="custom">
    </sc-parameter-value>

    <sc-parameter-selector
      :items="items"
      :label="`Uplink On Level of ${band.label} band`"
      :allow-clear="isCustomized"
      @select="value => change(value)"
      @clear="clear()">
    </sc-parameter-selector>

  </div>
</template>
