<script>
/**
 * Empty component used to ensure proper alignment of controls
 * in forms where sc-hint is used on some fields only.
 */
export default {
  props: {
    // Icon size
    size: {
      type: String,
      default: '24px'
    }
  },

  data () {
    return {
      style: {
        width: this.size,
        height: '1px',
        'margin-left': '2px',
        'margin-right': '2px'
      }
    }
  }
}
</script>

<template>
  <div :style="style">
  </div>
</template>
