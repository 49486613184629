import { createState } from './alerts.state'

/**
 * Stores the hierarchy of monitored devices in state
 * @param monitoredDevicesHierarchy Hierarchy
 */
export function storeMonitoredDevicesHierarchy (state, { monitoredDevicesHierarchy = {} } = {}) {
  state.monitoredDevicesHierarchy = monitoredDevicesHierarchy
  state.monitoredDeviceGroups = []
  state.monitoredDevices = []

  function addToList (group, list) {
    list.push(group)
    for (const organization of group.organizations || []) {
      addToList(organization, list)
    }
    for (const place of group.places || []) {
      addToList(place, list)
    }
    for (const device of group.devices || []) {
      state.monitoredDevices.push(device)
    }
  }

  addToList(state.monitoredDevicesHierarchy, state.monitoredDeviceGroups)
}

/**
 * Stores the list of alert notification subscriptions in state
 * @param {Array[AlertSubscription]} subscriptions Alert subscriptions
 */
export function storeAlertSubscriptions (state, { subscriptions = [] } = {}) {
  state.alertSubscriptions = subscriptions.reduce((all, s) => {
    all[s.deviceId] = s.serialNumber
    return all
  }, {})
}

/**
 * Adds alert notification subscriptions for the specified devices
 * @param {Array[Device]} devices Devices to subscribe to
 */
export function addAlertSubscriptions (state, { devices = [] } = {}) {
  for (const { id, serialNumber } of devices) {
    state.alertSubscriptions[id] = serialNumber
  }
}

/**
 * Removes alert notification subscriptions for the specified devices
 * @param {Array[Device]} devices Devices to subscribe to
 */
export function removeAlertSubscriptions (state, { devices = [] } = {}) {
  for (const { id } of devices) {
    delete state.alertSubscriptions[id]
  }
}

/**
 * Stores system-wide default alert configurations
 * @param configurations Alert configurations to store
 */
export function storeDefaultAlertConfigurations (state, { configurations } = {}) {
  if (configurations) {
    state.defaultConfigurations = configurations
  } else {
    state.defaultConfigurations = null
  }
}

/**
 * Stores default alert configurations
 * for the currently logged in organization
 * @param configurations Alert configurations to store
 */
export function storeMyAlertConfigurations (state, { configurations } = {}) {
  if (configurations) {
    state.myConfigurations = configurations.reduce((all, c) => ({ ...all, [c.alertType]: c }), {})
  } else {
    state.myConfigurations = null
  }
}

/**
 * Stores the list of alert configurations associated with the specified entity
 * @param entity Entity whose alert configurations to store
 * @param configurations Alert configurations to store
 */
export function storeAlertConfigurations (state, { entity: { id }, configurations = [] } = {}) {
  // Determine the entity whose configuration this is
  let entity = state.monitoredDeviceGroups.find(g => g.id === id) ||
    state.monitoredDevices.find(d => d.id === id) ||
    state.monitoredDevicesHierarchy

  if (entity) {
    entity.configurations = configurations
  }
}

/**
 * Resets configurations of a specified entity to defaults
 * @param id Entity identifier
 */
export function resetAlertConfigurations (state, { id } = {}) {
  const entity = state.monitoredDeviceGroups.find(g => g.id === id) ||
    state.monitoredDevices.find(d => d.id === id) ||
    state.monitoredDevicesHierarchy

  entity.configurations = []
}

/**
 * Triggered when device has been swapped.
 * Invalidate the hierarchy, it needs to be reloaded.
 */
export function swapDevice (state) {
  state.monitoredDevicesHierarchy = null
}

/**
 * Triggered after alerts have been deleted
 * @param {Array[AlertOccurrence]} alerts Deleted alerts
 */
// eslint-disable-next-line no-unused-vars
export function alertsDeleted (state, { alerts } = {}) {
}

/**
 * Resets the state to original shape.
 * @description
 * Presence of this mutation is obligatory on stores,
 * to ensure purging of any user-bound data when
 * user logs in and out and in. Otherwise expect
 * unexpected side effects with data of the previously
 * logged in user appearing at places.
 */
export function reset (state) {
  Object.assign(state, createState())
}

export function resetAlerts (state) {
  Object.assign(state, createState())
}
