<script>
import { mapActions, mapGetters } from 'vuex'
import { DialogMixin } from '@stellacontrol/client-utilities'
import { PrincipalType } from '@stellacontrol/model'
import { Secure } from '@stellacontrol/security-ui'

const dialog = 'link-attachments'

export default {
  mixins: [
    Secure,
    DialogMixin
  ],

  data () {
    return {
      dialog,
      // Indicates that the dialog is initializing
      isInitializing: false,
      // File attachments to be attached to a principal
      attachments: null,
      // Organization to assign to
      organizationId: null,
      // Place to assign to
      placeId: null
    }
  },

  computed: {
    ...mapGetters([
      'organizations',
      'allPlaces'
    ]),

    // The number of attachments to link
    count () {
      return this.attachments?.length || 0
    },

    // Single attachment to link
    attachment () {
      return this.count === 1 ? this.attachments[0] : null
    },

    // Places of the selected organization
    organizationPlaces () {
      const { organizationId, allPlaces } = this
      if (organizationId) {
        return allPlaces.filter(p => p.organizationId === organizationId)
      } else {
        return []
      }
    },

    // Verifies whether organization and place have been selected
    canLink () {
      return this.organizationId && this.placeId
    }
  },

  methods: {
    ...mapActions([
      'dialogOk',
      'linkAttachment'
    ]),

    // Called when dialog is shown
    dialogShown () {
      this.isInitializing = true
      this.attachments = this.data.attachments
      this.organizationId = null
      this.placeId = null

      // Select the place which is currently assigned to the attachments
      const attachment = this.attachments[0]
      if (attachment?.hasLinks) {
        const link = attachment.links.find(l => l.principal?.type === PrincipalType.Place)
        if (link?.organization) {
          this.placeId = link.principalId
          this.organizationId = link.organization.id
        }
      }
      this.isInitializing = false
    },

    // Closes the dialog
    async close () {
      this.dialogOk({ dialog })
    },

    // Links the attachment to the selected place
    async link () {
      const { organizationId, placeId } = this
      this.dialogOk({ dialog, data: { organizationId, placeId } })
    },

    // Organization has been selected
    organizationChanged () {
      this.placeId = null
    }
  }
}
</script>

<template>
  <sc-dialog :dialog="dialog" @dialogShown="dialogShown()">
    <q-form ref="form" class="form" v-if="count > 0">
      <q-banner class="bg-indigo-6">
        <div class="row items-center">
          <span class="text-white title">
            Assign
            {{ count === 1
    ? `${attachment.dataTypeLabel} ${attachment.name}`
    : `${count} attachments`
            }}
            to a place
          </span>
        </div>
      </q-banner>

      <div class="column q-pa-md">
        <sc-organization-selector :items="organizations" dense square v-model="organizationId"
        @update:model-value="organizationChanged"
          label="Organization">
        </sc-organization-selector>

        <sc-place-selector class="q-mt-md" dense square bg-color="white" v-model="placeId"
          label="Place" :items="organizationPlaces">
        </sc-place-selector>
      </div>

      <div class="row items-center q-pa-md">
        <q-space></q-space>
        <q-btn unelevated dense label="Close" no-caps class="q-mr-md" @click="close()">
        </q-btn>
        <q-btn unelevated dense class="primary" label="Assign" no-caps @click="link()"
          :disabled="!canLink">
        </q-btn>
      </div>
    </q-form>
  </sc-dialog>
</template>

<style scoped lang="scss">
.form {
  width: 550px;
  height: 260px;
}
</style>