/**
 * Device link types
 */
export const DeviceLinkType = {
  /**
   * Organization which is currently a legal owner of a device
   */
  Owner: 'owner',
  /**
   * Delegate organization which is not an owner but has access
   * to the device, for example a guest organization which is testing the app
   */
  Delegate: 'delegate'
}
