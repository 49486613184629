import { parseDate, isBrowser } from '@stellacontrol/utilities'
import { Assignable } from './assignable'
import { User } from '../organization/organization-entities'

/**
 * Bug types
 */
export const BugType = {
  Exception: 'exception',
  RequestException: 'request-exception',
  Error: 'error',
  Warning: 'warning'
}

/**
 * Bug location within application architecture
 */
export const BugLocation = {
  FrontEnd: 'frontend',
  BackEnd: 'backend'
}

/**
 * Bug report
 */
export class Bug extends Assignable {
  constructor (data = {}) {
    super()
    this.assign(
      {
        ...data,
        createdAt: data.createdAt || new Date(),
        isUnhandled: Boolean(data.isUnhandled),
        isNetworkError: Boolean(data.isNetworkError)
      },
      {
        createdAt: parseDate
      })
  }

  normalize () {
    super.normalize()
    this.creator = this.cast(this.creator, User)
    this.linkedBug = this.cast(this.linkedBug, Bug)
  }

  /**
   * Creates a bug report from the specified error
   * @param {BugLocation} location Location in application architecture where bug has happened
   * @param {BugType} type Bug type
   * @param {Error} error Error
   * @param {User} user User reporting the exception
   * @param {Object} platform Platform details
   * @param {String} url Web page URL, for client-side errors or service name, for server-side errors
   * @param {Environment} environment Environment where error has happened
   * @param {String} instance Server instance where error has happened
   * @param {String} service Service or application where error has happened
   * @param {Boolean} isUnhandled Indicates an unhandled exception
   * @returns {Bug}
   */
  static fromError ({ location, type = BugType.Exception, error, user, platform, url, environment, instance, service, isUnhandled } = {}) {
    if (!location) throw new Error('Location is required')
    if (!error) throw new Error('Exception is required')

    // Determine the wherebouts of the error
    if (isBrowser) {
      const { Configuration } = window
      environment = environment || Configuration?.environment || ''
      instance = instance || Configuration?.origin?.domain || ''
      service = service || Configuration?.application?.name || ''
    } else {
      const { hostname } = platform || {}
      environment = environment || global.Configuration.environment
      instance = instance || hostname
      service = (service || global.Configuration.pkg.name || '').replace('@stellacontrol/', '')
    }

    const { message, lineNumber, columnNumber, fileName, stack, details: { source } = {}, request, response, isNetworkError } = error

    if (platform) {
      platform.text = platform.ua
        ? getClientPlatformString(platform)
        : getServerPlatformString(platform)
    }

    let bug
    if (source) {
      const { fileName, line, column, chunk } = source
      bug = new Bug({
        linkedBugId: error.bugReportId,
        type,
        isUnhandled,
        isNetworkError,
        environment,
        instance,
        service,
        location,
        url,
        message,
        createdBy: user.id,
        creator: user,
        line,
        column,
        fileName,
        source: chunk ? chunk.join('\n') : undefined,
        stack,
        platform
      })

    } else {
      bug = new Bug({
        linkedBugId: error.bugReportId,
        type,
        isUnhandled,
        isNetworkError,
        environment,
        instance,
        service,
        location,
        url,
        message,
        createdBy: user?.id,
        creator: user,
        line: lineNumber,
        column: columnNumber,
        fileName,
        stack,
        platform
      })
    }

    // Additional data from network errors
    if (request) {
      bug.type = BugType.RequestException
      if (!response) {
        bug.message = `${bug.message}: no response`
      }
      const { url, method, headers, params, data } = request
      bug.request = { url, method, headers, params, data }
    }

    if (response) {
      if (response.status) {
        const { status, statusText, data } = response
        bug.message = `HTTP ${status} ${statusText}: ${bug.message}`
        bug.response = { status, statusText, data }
      }
    }

    return bug
  }

  /**
   * Creates a bug report from the specified server error
   * @param {BugType} type Bug type
   * @param {Error} error Error
   * @param {User} user User in whose context the exception has happened
   * @param {Object} platform Platform details
   * @param {Request} request HTTP request under which the error has happened
   * @param {Environment} environment Environment where error has happened
   * @param {String} instance Server instance where error has happened
   * @param {String} service Service or application where error has happened
   * @param {Boolean} isUnhandled Indicates an unhandled exception
   * @returns {Bug}
  */
  static fromServerError ({ type = BugType.Exception, error, user, platform, request, environment, instance, service, isUnhandled } = {}) {
    if (!error) throw new Error('Exception is required')
    if (typeof error === 'string') error = new Error(error.toString())

    let { message, lineNumber, columnNumber, fileName, stack = {}, isNetworkError } = error

    if (platform) {
      platform.text = getServerPlatformString(platform)
    }

    // Determine the wherebouts of the error
    const { hostname } = platform || {}
    environment = environment || global.Configuration.environment
    instance = instance || hostname
    service = (service || global.Configuration.pkg.name || '').replace('@stellacontrol/', '')

    const url = platform && platform.process ? platform.process.file : 'NodeJS'

    if (!fileName && stack?.match) {
      fileName = ((stack.match(/(?=\().*?(?=\))/gm) || [])[0] || '').substr(1)
      if (fileName) {
        const parts = fileName.split(':')
        fileName = parts[0]
        lineNumber = parts[1] ? parseInt(parts[1]) : undefined
        columnNumber = parts[2] ? parseInt(parts[2]) : undefined
      }
    }

    const bug = new Bug({
      linkedBugId: error.bugReportId,
      type,
      isUnhandled,
      isNetworkError,
      environment,
      instance,
      service,
      location: BugLocation.BackEnd,
      url,
      message,
      createdBy: user?.id,
      creator: user,
      line: lineNumber,
      column: columnNumber,
      fileName,
      stack,
      platform
    })

    // Additional data from network errors
    if (request) {
      bug.type = BugType.RequestException
      const { requestId: id, hostname, url, method, headers, raw: { query: params } = {}, body: data } = request
      bug.request = {
        id,
        url: `${hostname}${url}`,
        method,
        headers,
        params,
        data
      }
    }

    return bug
  }

  /**
   * Database identifier
   * @type {String}
   */
  id

  /**
   * Creation time
   * @type {Date}
   */
  createdAt

  /**
   * Creator id
   * @type {String}
   */
  createdBy

  /**
   * Creator
   * @type {User}
   */
  creator

  /**
   * Bug type
   * @type {BugType}
   */
  type

  /**
   * Indicates whether this is an unhandled exception
   * @type {Boolean}
   */
  isUnhandled

  /**
   * Indicates whether this is a network error
   * @type {Boolean}
   */
  isNetworkError

  /**
   * Bug location
   * @type {BugLocation}
   */
  location

  /**
   * Server instance on which the bug was registered, such as `monitoring`
   * @type {String}
   */
  instance

  /**
   * Service where the bug has happened, such as `devices-api`
   * @type {String}
   */
  service

  /**
   * Error message
   * @type {String}
   */
  message

  /**
   * Platform details
   * @description
   * On the client it's evaluated from browser agent using ua-parser-js library.
   * On the server it's determined from NodeJS runtime.
   * @type {String}
   */
  platform

  /**
   * URL at which the bug has happened
   * @type {String}
   */
  url

  /**
   * Details of API request which returned error
   * @type {Object}
   */
  request

  /**
   * Details of API error response
   * @type {Object}
   */
  response

  /**
   * Source file where bug has happened
   * @type {String}
   */
  fileName

  /**
   * Line number where bug has happened
   * @type {Number}
   */
  line

  /**
   * Column number where bug has happened
   * @type {Number}
   */
  column

  /**
   * Chunk of source code where bug has happened
   * @type {String}
   */
  source

  /**
   * Raw stack trace
   * @type {String}
   */
  stack

  /**
   * Identifier of a linked bug,
   * i.e. server error which has been followed by a client error
   * @type {String}
   */
  linkedBugId

  /**
   * Linked bug details
   * @type {Bug}
   */
  linkedBug
}

/**
 * Returns user-friendly description of client platform
 * @param {String} platform Platform details deducted from browser's user agent string
 * @description For parsing user agent strings we use https://www.npmjs.com/package/ua-parser-js
 * @returns {String}
 */
function getClientPlatformString (platform) {
  if (platform && platform.ua) {
    const { browser = {}, engine = {}, os = {}, device = {}, cpu = {}, ua } = platform
    const result = [
      browser.name,
      browser.version,
      engine.name ? `(${engine.name} ${engine.version})` : '',
      os.name ? `on ${os.name} ${os.version || ''}` : '',
      device.model,
      device.type,
      device.vendor,
      (cpu.architecture || '').toUpperCase()
    ]
      .map(s => (s || '').trim())
      .filter(s => s)
      .join(' ')

    return result || ua
  }
}

/**
 * Returns user-friendly description of server platform
 * @param {String} platform Platform details deducted from NodeJS runtime
 * @returns {String}
 */
function getServerPlatformString (platform) {
  if (platform) {
    const { runtime = {}, os = {}, cpu = {}, hostname } = platform
    const result = [
      hostname,
      `${runtime.name || ''} ${runtime.version || ''}`,
      `${os.name} ${os.version || ''}`,
      (cpu.model || '').toUpperCase(),
    ]
      .map(s => (s || '').trim())
      .filter(s => s)
      .join(', ')

    return result || 'NodeJS'
  }
}
