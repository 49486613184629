import { capitalize } from '@stellacontrol/utilities'
import { Confirmation, Notification } from '@stellacontrol/client-utilities'
import { DeviceAPI, ServiceManagementAPI, CommonAPI } from '@stellacontrol/client-api'
import { Place, PlaceNames, Note } from '@stellacontrol/model'
import { SecurityRules } from '@stellacontrol/security'

export const actions = {
  /**
   * Retrieves places of the current or specified organization and its child organizations
   * @param {Organization} organization Optional organization whose places to retrieve.
   * If not specified, places of the current organization are retrieved.
   * @returns {Promise<Array[Place]>}
   */
  async getPlaces ({ commit, dispatch, getters }, { organization, withDetails = false, withChildren = true } = {}) {
    await dispatch('loading')
    const places = await DeviceAPI.getPlaces({ organization, withDetails, withChildren })
    if (!organization || organization.id === getters.currentOrganization.id) {
      commit('storePlaces', { places })
    }
    await dispatch('done')
    return places
  },

  /**
   * Retrieves places of the current or specified organization if not retrieved yet
   * @param {Organization} organization Optional organization
   * @returns {Promise<Array[Place]>}
   */
  async requirePlaces ({ state, dispatch }, { organization, withDetails = false, withChildren = true } = {}) {
    if (!(state.items?.length > 0)) {
      return dispatch('getPlaces', { organization, withDetails, withChildren })
    } else {
      return state.items || []
    }
  },

  /**
   * Retrieves the specified place with all details such as creator, updater, devices at the place etc.
   * @param {String} id Place identifier
   * @param {String} organizationId Place owner identifier
   * @param {Boolean} withAttachments If true, attachments linked to the place are returned too
   * @returns {Place}
   * @description Place identifier can be one of the virtual places:
   *  - none: for place representing company stock devices, not assigned to any place yet
   *  - shared: for place containing devices shared with organization
   */
  async getPlace ({ state, dispatch, getters }, { id, organizationId, withAttachments } = {}) {
    if (!id) throw new Error('Place identifier is required')

    let place
    const { currentOrganization } = getters

    await dispatch('loading')
    if (id === 'none') {
      place = new Place({ ...state.noPlace, organizationId: organizationId || currentOrganization.id, organization: currentOrganization })
    } else if (id === 'shared') {
      place = new Place({ ...state.sharedPlace, organizationId: organizationId || currentOrganization.id, organization: currentOrganization })
    } else {
      place = await DeviceAPI.getPlace({ id, withDetails: true, withDevices: true })
      if (withAttachments) {
        const { attachments: ownAttachments } = await CommonAPI.getAttachments({ ownerId: id }) || []
        const { attachments: linkedAttachments } = await CommonAPI.getAttachments({ principalId: id }) || []
        place.addAttachments(ownAttachments)
        place.addAttachments(linkedAttachments)
      }
    }
    await dispatch('done')

    return place
  },

  /**
   * Returns a new place
   * @param place Initial properties of the place
   * @param organizationId Owner of the place
   */
  async newPlace ({ dispatch, getters }, { place = {}, organizationId } = {}) {
    await dispatch('loading')

    // Currently logged in organization is default owner
    const organization = organizationId
      ? await dispatch('getOrganization', { id: organizationId })
      : getters.currentOrganization

    // Create new place
    const data = new Place({
      ...place,
      organization,
      organizationId: organization.id
    })
    await dispatch('done')
    return data
  },

  /**
   * Checks whether the specified place exists
   * @param name Place name
   * @param organization Organization owning the place, current organization assumed if not specified
   */
  async placeExists ({ getters }, { name, organization } = {}) {
    if (name) {
      const { id, exists } = await DeviceAPI.placeExists({
        name,
        organization: organization || getters.currentOrganization
      })
      return { id, exists }
    } else {
      return { exists: false }
    }
  },

  /**
   * Returns a place with a given name under organization
   * @param name Place name
   * @param organization Organization owning the place, current organization assumed if not specified
   */
  async getPlaceByName ({ getters }, { name, organization } = {}) {
    if (name) {
      const place = await DeviceAPI.getPlaceByName({
        name,
        organization: organization || getters.currentOrganization
      })
      return place
    }
  },

  /**
   * Edits a new place
   * @param organization Optional organization under which the place is to be created.
   * If not specified, the place will be created under the current organization
   */
  async createPlace ({ dispatch }, { organization } = {}) {
    await dispatch('gotoRoute', {
      name: 'place',
      params: { id: 'new' },
      query: organization ? { organization: organization.id } : undefined
    })
  },

  /**
   * Edits an existing place
   * @param place Place to edit
   */
  async editPlace ({ dispatch }, { place } = {}) {
    if (!place) throw new Error('Place is required')

    await dispatch('gotoRoute', {
      name: 'place',
      params: { id: place.id }
    })
  },

  /**
   * Saves an place
   * @param {Place} place Place to save
   * @param {Boolean} silent If true, no notifications will be shown
   * @param {Function} onError Handler to call if error happens during saving the place, optional. If not specified, exception will be thrown.
   * @param {Boolean} movingToAnotherOrganization Indicates that we're moving the place between organization.
   * In such case some post-processing of devices belonging to the place might be needed,
   * i.e. transfer of subscriptions associated with devices to their new owner.
   * @returns {Promise<Place>} Saved place
   */
  async savePlace ({ commit, dispatch }, { place, movingToAnotherOrganization, silent, onError } = {}) {
    if (!place) throw new Error('Place is required')

    await dispatch('busy', { message: `Saving ${place.name} ...`, data: place, silent })
    try {
      place = await DeviceAPI.savePlace({ place })
    } catch (error) {
      if (onError) {
        onError(place)
        return
      } else {
        throw error
      }
    }

    // If place has been moved to another organization
    // and it had devices, make sure that premium subscriptions associated with these devices
    // have too been migrated to the new owner of these devices
    if (movingToAnotherOrganization) {
      place = await DeviceAPI.getPlace({ id: place.id, withDetails: true })
      if (place && place.devices && place.devices.length > 0) {
        await ServiceManagementAPI.migrateDeviceSubscriptions({ devices: place.devices })
      }
    }

    commit('storePlace', { place })
    await dispatch('done', { message: `Place ${place.name} has been saved`, silent })
    return place
  },

  /**
   * Removes an place
   * @param {Place} place Place to remove
   * @param {Boolean} confirm If true, user has to confirm
   * @param {Boolean} silent If true, no notifications will be showing
   * @returns {Place} True when place has been removed
   */
  async removePlace ({ commit, dispatch, getters }, { confirm = true, place: { id } = {}, silent } = {}) {
    if (!id) throw new Error('Place is required')
    const place = await dispatch('getPlace', { id, withDetails: true })
    if (!place) throw new Error('Place not found')

    const { currentUser } = getters
    const { canDeletePlace, reason } = SecurityRules.canDeletePlace(currentUser, place)
    if (!canDeletePlace) {
      Notification.error({ message: reason, silent })
      return
    }

    const placeType = PlaceNames[place.placeType]
    const placeDescription = `${PlaceNames[place.placeType]} ${place.fullText}`
    const deviceCount = (place.devices || []).length
    const deviceInfo = deviceCount > 0
      ? `${(deviceCount === 1 ? '\nThere is one device ' : `\nThere are ${deviceCount} devices`)} in this building. ${deviceCount === 1 ? 'It' : 'They'} will be moved to stock.`
      : ''
    const yes = await Confirmation.ask({
      title: `Delete ${PlaceNames[placeType]}?`,
      message: `Do you want to permanently delete ${placeDescription}? ${deviceInfo}`,
      confirm
    })

    if (yes) {
      await dispatch('busy', { message: `Deleting ${placeDescription} ...`, data: place, silent })
      await DeviceAPI.deletePlace({ place })
      await dispatch('done', { message: `${capitalize(placeDescription)} has been deleted`, silent })
      commit('removePlace', { place })
      return true
    }
  },

  /**
   * Saves a place
   * @param {Place} place Place to save
   * @returns {Promise<Place>}
   */
  async storePlace ({ commit }, { place } = {}) {
    if (!place) throw new Error('Place is required')
    commit('storePlace', { place })
    return place
  },

  /**
   * Saves place notes
   * @param {Place} place Place to save
   * @returns {Promise<Place>}
   */
  async savePlaceNotes ({ commit }, { place } = {}) {
    if (!place) throw new Error('Place is required')

    const note = await CommonAPI.saveNote({ note: Note.for(place) })
    place.notes = note ? [note] : []
    commit('storePlace', { place })

    return place
  },

}
