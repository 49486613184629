import Konva from 'konva'
import { PlanItemType } from '@stellacontrol/planner'
import { Shape } from './shape'

/**
 * Ellipse
 */
export class EllipseShape extends Shape {
  constructor (item, dataCallback) {
    super(item, dataCallback)
    this.content = new Konva.Ellipse()
  }

  static get type () {
    return PlanItemType.Ellipse
  }

  render (renderer) {
    super.render(renderer)
    const { content: shape, item } = this
    if (shape && item) {
      const { lineStyle, backgroundStyle } = item
      // Konva reverts the radii somehow ...
      shape.radiusY(item.radius.x)
      shape.radiusX(item.radius.y)
      shape.stroke(item.lineStyle.color)
      shape.strokeWidth(lineStyle.width)
      shape.fill(backgroundStyle.color)
      this.applyFilters()
    }
  }

  /**
   * Triggered when shape has been transformed.
   * @param {PlanRenderer} renderer Plan renderer
   * @param {PlanScale} scale Scale of the shape
   * @param {Number} rotation Shape rotation
   */
  transformed ({ renderer, scale, rotation }) {
    super.transformed({ renderer, scale, rotation })

    // Scale the radius
    const { item } = this
    item.radius.x = Math.round(item.radius.x * scale.x)
    item.radius.y = Math.round(item.radius.y * scale.y)

    // Re-render the shape
    this.render(renderer)
  }
}
