<script>
import { mapGetters } from 'vuex'
import { fill } from '@stellacontrol/utilities'

/**
 * Default background color for the view
 */
const defaultBgColor = '#f2f2f2'

/**
 * Container for a typical StellaControl view,
 * with header slot, breadcrumbs, content slot and a footer slot.
 */
export default {
  props: {
    // View name
    name: {
      type: String,
      required: true
    },
    // View name aliases. Dictionary of { route: view } names
    // which specify how the view is named, when being opened
    // coming from the specified route.
    // Useful when the same view is being approached from
    // different routes. In this case we should define aliases
    // for the view and place these under their respective
    // places in the view hierarchy, to show proper content
    // in the breadcrumbs.
    alias: {
      type: Object
    },
    // Custom view title. Normally this will be taken from
    // globally defined view title, but for some views
    // we might want to override that. Example: a view
    // where we're editing a record such as user or device,
    // and we'd like to show the user ID or device serial as title.
    title: String,
    // View subtitle
    subtitle: String,
    // Custom view permissions.
    // Normally defined in views.json but can be overridden.
    permissions: {
      default: () => []
    },
    // Used to hide the header area
    noHeader: {
      type: Boolean,
      value: false
    },
    // Background color
    bgColor: {
      type: String,
      value: defaultBgColor
    },
    // Header background color
    headerColor: {
      type: String
    },
    // Subheader background color
    subheaderColor: {
      type: String
    },
    // Footer background color
    footerColor: {
      type: String
    },
    // Custom class for the header area
    headerClass: {
      type: String
    },
    // Custom class for the title area
    titleClass: {
      type: String
    },
    // Custom class for the subheader area
    subheaderClass: {
      type: String
    },
    // Custom class for the toolbar area
    toolbarClass: {
      type: String
    },
    // Custom class for the content area
    contentClass: {
      type: String
    },
    // Custom class for the footer area
    footerClass: {
      type: String
    },
    // Custom style for the header area
    headerStyle: {
      type: Object,
      default: () => { }
    },
    // Custom style for the title area
    titleStyle: {
      type: Object,
      default: () => { }
    },
    // Custom class for the subheader area
    subheaderStyle: {
      type: Object,
      default: () => { }
    },
    // Custom class for the toolbar area
    toolbarStyle: {
      type: Object,
      default: () => { }
    },
    // Custom class for the content area
    contentStyle: {
      type: Object,
      default: () => { }
    },
    // Custom class for the footer area
    footerStyle: {
      type: Object,
      default: () => { }
    },
    // Custom breadcrumbs to show
    breadcrumbs: {
      type: Array
    },
  },

  data () {
    return {
      defaultBgColor
    }
  },

  computed: {
    ...mapGetters([
      'configuration',
      'getView',
      'getRouteByView',
      'getRouteData',
      'getViewTitle',
      'isLoggedIn',
      'guardian',
      'isSmallScreen'
    ]),

    /**
     * Data associated with the route
     */
    data () {
      const { data } = this.getRouteData(this.name) || {}
      return data
    },

    /**
     * Indicates that view has associated data
     */
    hasData () {
      return Boolean(this.data)
    },

    /**
     * View name, derived from the route that is associated with the view
     * or optionally set explicitly as `name` attribute
     */
    viewName () {
      const { view } = this.getRouteData(this.name) || {}
      return view ? view.name : this.name
    },

    // View definition
    view () {
      return this.getView(this.viewName)
    },

    /**
     * View permissions
     */
    viewPermissions () {
      const { view } = this
      return (view ? view.permissions : this.permissions) || []
    },

    // Indicates whether the view is permitted for the current user
    isPermitted () {
      return this.guardian ? this.guardian.canUseAll(this.viewPermissions) : true
    },

    // Determine whether the view header should be visible
    isHeaderVisible () {
      return !(this.noHeader || this.isSmallScreen)
    },

    // User-friendly view title
    viewTitle () {
      const routeData = this.getRouteData(this.name) || {}
      const { title, view, viewName, configuration: { application, branding } } = this
      const result = routeData.title || title || (view ? view.title : `View ${viewName} is undefined`)
      return fill(result, { application, branding })
    },

    // View subtitle
    viewSubtitle () {
      const routeData = this.getRouteData(this.name) || {}
      const { subtitle, configuration: { application, branding } } = this
      const result = routeData.subtitle || subtitle
      return fill(result, { application, branding })
    },

    // Breadcrumbs to show in the view header
    visibleBreadcrumbs () {
      const { breadcrumbs } = this

      if (breadcrumbs) return breadcrumbs

      return this.view.path.map(name => {
        const { configuration: { application, branding } } = this
        const title = name === this.name
          ? this.viewTitle
          : fill(this.getViewTitle(name), { application, branding })
        return { name, title }
      })
    }
  }
}
</script>

<template>
  <div v-if="isPermitted" :class="{ view: true, [`view-${viewName}`]: true }"
    :style="{ 'background-color': bgColor || defaultBgColor }">

    <header v-if="isHeaderVisible" class="header"
      :class="headerClass ? { [headerClass]: true } : {}"
      :style="{ 'background-color': headerColor || undefined, ...headerStyle }">

      <div class="title" :class="titleClass ? { [titleClass]: true } : {}"
        :style="{ ...titleStyle }">
        <h1>
          {{ viewTitle }}
        </h1>
        <h3 v-if="viewSubtitle">
          {{ viewSubtitle }}
        </h3>

        <sc-breadcrumbs :path="visibleBreadcrumbs" :title="viewTitle"
          v-if="visibleBreadcrumbs.length > 0">
        </sc-breadcrumbs>
      </div>

      <!-- the content of a page toolbar -->
      <div class="toolbar row items-center justify-end no-wrap"
        :class="toolbarClass ? { [toolbarClass]: true } : {}" :style="{ ...toolbarStyle }">
        <slot name="toolbar" v-bind:data="data">
        </slot>
      </div>
    </header>

    <header class="subheader" :class="subheaderClass ? { [subheaderClass]: true } : {}"
      :style="{ 'background-color': subheaderColor || undefined, ...subheaderStyle }">
      <slot name="header" v-bind:data="data">
      </slot>
    </header>

    <main class="column no-wrap" :class="contentClass ? { [contentClass]: true } : {}"
      :style="{ 'background-color': bgColor, ...contentStyle }">
      <slot v-bind:data="data">
      </slot>
    </main>

    <footer class="footer" :class="footerClass ? { [footerClass]: true } : {}"
      :style="{ 'background-color': footerColor || undefined, ...contentStyle }">
      <slot name="footer" v-bind:data="data">
      </slot>
    </footer>
  </div>

  <sc-not-authorized v-else title="You are not authorized to access this page"
    details="Please contact your administrator to obtain the necessary permissions.">
  </sc-not-authorized>
</template>

<style scoped lang="scss">
.view {
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;

  >header.header {
    flex: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 20px 20px 20px 15px;
    background-color: #ECEBEF;
    border-bottom: 1px solid rgb(206, 209, 216);

    >.title {
      flex: unset;
      overflow: hidden;

      h1 {
        margin: 0;
        padding: 0;
        font-size: 19px;
        font-weight: 500;
        line-height: normal;
        color: #272727;
        margin-bottom: 10px;
        padding-left: 2px;
      }

      h3 {
        margin-top: 0;
        padding: 0;
        font-size: 15px;
        font-weight: normal;
        line-height: normal;
        color: #272727;
        margin-bottom: 10px;
        padding-left: 2px;
        max-width: 90%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    >.toolbar {
      flex: 1;
      padding-top: 4px;
    }
  }

  >header.subheader {
    flex: 0;
    display: flex;
    flex-direction: column;
  }

  >main {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: white;
    overflow: hidden;
  }

  >footer {
    flex: 0;
  }
}

/* Layout adjustments for screen below HD resolution */
@media screen and (max-width: 1365px) {
  .view {
    header.header {
      /* Reduce padding, but add more on the left of the header area,
         so it isn't covered by the hamburger menu */
      padding: 8px;

      /* Allow wrapping of toolbar underneath, if too wide */
      flex-wrap: wrap;
    }
  }
}

/* Layout adjustments for small screens */
@media screen and (max-width: 1024px) {
  .view {
    >header.header {
      background-color: #263472;

      >.title {
        h1 {
          color: white;
          margin-bottom: 0;
          padding: 8px;
        }
      }
    }
  }
}
</style>