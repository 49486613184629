import { dispatch } from '@stellacontrol/client-utilities'

/**
 * Resolves the data required by the `building-dashboard` route
 */
export async function resolve ({ from, to }) {
  await dispatch('requireOrganizations')
  await dispatch('requirePlaces')
  await dispatch('requireDevices')
  await dispatch('requireUsers')

  const { id, organizationId } = to.params
  const { place, organization } = await dispatch('initializeBuildingDashboard', { id, organizationId }) || {}

  if (place && organization) {
    const title = `${organization.name} | ${place.name}`
    await dispatch('setRouteData', { from, to, title })

  } else {
    return {
      redirectTo: {
        name: 'installations'
      }
    }
  }
}
