/**
 * Device log storage formats
 */
export const LogFormat = {
  // Plain text format
  Text: 'text',
  // Data encoded to Base64 format
  Base64: 'base64',
  // Data stored as raw binary
  Binary: 'binary'
}
