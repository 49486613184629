import { state, getters } from './application.state'
import { mutations } from './application.mutations'
import { actions } from './application.actions'

/**
 * Global state
 */
export default {
  state,
  getters,
  mutations,
  actions
}
