import { Assignable } from '../common/assignable'
import { parseDate } from '@stellacontrol/utilities'
import { UploadStatus } from '../device-transmission/upload-status'

/**
 * Status of recently scheduled firmware update for the device
 */
export class DeviceUpdateStatus extends Assignable {
  static IMMEDIATELY = 0
  static WHEN_CONNECTED = -1
  static ON_USER_REQUEST = -2

  constructor (data = {}) {
    super()
    this.assign(data, {
      createdAt: parseDate,
      updatedAt: parseDate
    })
  }

  normalize () {
    super.normalize()
    this.createdAt = this.createdAt || new Date()
    this.updatedAt = this.updatedAt || new Date()
  }

  /**
   * Device identifier
   * @type {String}
   */
  deviceId

  /**
   * Job identifier
   * @type {String}
   */
  jobId

  /**
  * Creation time
  * @type {Date}
  */
  createdAt

  /**
   * Last modification time
   * @type {Date}
   */
  updatedAt

  /**
   * Creator id
   * @type {String}
   */
  createdBy

  /**
   * Creator name
   * @type {String}
   */
  creator

  /**
   * Version of the firmware
   * @type {String}
   */
  firmwareVersion

  /**
   * Update status
   * @type {UploadStatus}
   */
  status

  /**
   * Update progress, percentage
   * @type {Number}
   */
  progress

  /**
   * Interval to wait, before the upload is attempted, in seconds.
   * Used to schedule uploads at less busy times.
   * There are two 'magic' values for this property:
   * -1 for jobs which should wait until the device comes online
   * -2 for jobs which should wait until the device comes online AND the user requests the update by pressing a button on the device
   * @type {Number}
   */
  defer

  /**
   * If true, upload will start immediately when device comes online.
   * Useful to schedule updates for devices which intermittently go offline and it's hard to catch the moment when they do.
   * @type {Boolean}
   */
  get whenConnected () {
    return this.defer === DeviceUpdateStatus.WHEN_CONNECTED
  }
  set whenConnected (value) {
    this.defer === value ? DeviceUpdateStatus.WHEN_CONNECTED : DeviceUpdateStatus.IMMEDIATELY
  }

  /**
   * If true, upload will start when the user requests it from the device.
   * @type {Boolean}
  */
  get onUserRequest () {
    return this.defer === DeviceUpdateStatus.ON_USER_REQUEST
  }
  set onUserRequest (value) {
    this.defer === value ? DeviceUpdateStatus.ON_USER_REQUEST : DeviceUpdateStatus.IMMEDIATELY
  }

  /**
   * Indicates that upload is now in progress
   * @type {Boolean}
   */
  get inProgress () {
    return this.status === UploadStatus.Sending
  }

  /**
   * Indicates that upload is scheduled and waiting
   * @type {Boolean}
   */
  get isScheduled () {
    return !this.inProgress && Boolean(this.whenConnected || this.onUserRequest)
  }
}
