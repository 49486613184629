import { Layer } from './layer'
import { moveToBottom } from '../utilities'

/**
 * Item layer renderer
 */
export class ItemLayer extends Layer {
  constructor (data) {
    super(data)
  }

  /**
   * Assigns correct z-order to shapes on the layer
   * @returns {Layer} Self-reference if reordering was performed
   */
  reorder () {
    if (super.reorder()) {
      // Connectors must go under every other item
      for (const shape of this.shapes) {
        if (shape.item.isConnector) {
          moveToBottom(shape.content)
        }
      }

      return this
    }
  }

}
