<script>
import { mapGetters, mapActions } from 'vuex'
import { PremiumServiceStatus } from '@stellacontrol/model'
import { CellMixin } from './cell-mixin'

const TOOLTIP_LOADING = 'Loading ...'

export default {
  mixins: [
    CellMixin
  ],

  props: {
    item: {
      type: Object,
      required: true,
    }
  },

  data () {
    return {
      tooltip: TOOLTIP_LOADING,

      icons: {
        [PremiumServiceStatus.None]: null,
        [PremiumServiceStatus.NotStarted]: 'pause_circle',
        [PremiumServiceStatus.Inactive]: 'play_circle',
        [PremiumServiceStatus.Active]: 'play_circle',
        [PremiumServiceStatus.Expired]: 'error',
      },
      colors: {
        [PremiumServiceStatus.None]: null,
        [PremiumServiceStatus.NotStarted]: 'grey-5',
        [PremiumServiceStatus.Inactive]: 'green-5',
        [PremiumServiceStatus.Active]: 'green-6',
        [PremiumServiceStatus.Expired]: 'orange-5',
      }
    }
  },

  computed: {
    ...mapGetters([
      'organizations'
    ]),

    device () {
      return this.item.device
    },

    // Label representing the premium service associated with device
    premiumServiceLabel () {
      const { device: { premiumService } } = this
      return premiumService
        ? (premiumService.code || premiumService.name || '').toUpperCase()
        : ''
    },

    // Icon representing service status
    icon () {
      const { owner, icons, device: { premiumServiceStatus } } = this
      return (owner && !owner.isBank && (owner.canUse('premium-services-buy') || owner.canUse('premium-services-sell')))
        ? icons[premiumServiceStatus]
        : undefined
    },

    // Icon color
    iconColor () {
      const { colors, device: { premiumService, premiumServiceStatus } } = this
      return premiumServiceStatus === PremiumServiceStatus.Active
        ? premiumService?.color || colors[premiumServiceStatus]
        : colors[premiumServiceStatus]
    },

    // Device owner
    owner () {
      const id = this.device?.owner?.id
      if (id) {
        return this.organizations.find(organization => organization.id === id)
      }
    }
  },

  methods: {
    ...mapActions([
      'getWallet'
    ]),

    // Populates the premium subscriptions tooltip
    async loadTooltip () {
      const { device, owner, premiumServiceLabel } = this
      if (device && owner && !owner.isBank) {
        this.tooltip = TOOLTIP_LOADING
        const wallet = await this.getWallet({ organization: owner })
        const subscriptions = (wallet.subscriptions || []).filter(s => s.forDevice(device))
        const activeSubscriptions = subscriptions.filter(s => s.forDevice(device) && s.isStarted && s.isActive)
        const notStartedSubscriptions = subscriptions.filter(s => s.forDevice(device) && (s.isNotStarted || s.startsInFuture))
        const expiredSubscriptions = subscriptions.filter(s => s.forDevice(device) && s.isExpired)

        const lines = []
        if (device.isPremiumServiceNotStarted) {
          lines.push(`Premium service ${premiumServiceLabel} has not been activated yet`)
        }

        if (subscriptions.length > 0 && !device.isPremiumServiceNotStarted) {
          if (lines.length > 0) lines.push('<br>')
          if (activeSubscriptions.length > 0) {
            lines.push('<b>Active subscriptions:</b>')
            lines.push('<ul>')
            lines.push(...activeSubscriptions.map(s => `<li>${s.service.label}<br>${s.statusLabel}</li>`))
            lines.push('</ul>')
          }
          if (notStartedSubscriptions.length > 0) {
            lines.push('<b>Upcoming subscriptions:</b>')
            lines.push('<ul>')
            lines.push(...notStartedSubscriptions.map(s => `<li> ${s.service.label}<br>${s.statusLabel}</li>`))
            lines.push('</ul>')
          }
          if (expiredSubscriptions.length > 0 && activeSubscriptions.length === 0 && notStartedSubscriptions.length === 0) {
            lines.push('<b>All premium services on this device have expired</b>')
            lines.push('<ul>')
            lines.push(...expiredSubscriptions.map(s => `<li>${s.service.label}<br>${s.statusLabel}</li>`))
            lines.push('</ul>')
          }
        }

        if (lines.length > 0) {
          this.tooltip = lines.join('')
        } else {
          this.tooltip = 'The device doesn\'t have any premium services yet'
        }
      }
    }
  }
}
</script>

<template>
  <div :style="columnStyle">
    <q-icon v-if="icon" :name="icon" :color="iconColor" size="20px">
      <sc-tooltip v-if="device.hasPremiumService" @show="loadTooltip()" :text="tooltip">
      </sc-tooltip>
    </q-icon>
  </div>
</template>
