<script>
import { mapActions, mapGetters } from 'vuex'
import { ViewMixin } from '@stellacontrol/client-utilities'

const name = 'terms-and-conditions'

export default {
  mixins: [
    ViewMixin
  ],

  data () {
    return {
      name,
      terms: null,
      text: null
    }
  },

  computed: {
    ...mapGetters([
      'myTermsAndConditions',
      'application'
    ])
  },

  methods: {
    ...mapActions([
      'getTermsAndConditions',
      'goBack'
    ])
  },

  async created () {
    const { id } = this.myTermsAndConditions || {}
    const { terms, html } = await this.getTermsAndConditions({ id }) || {}
    if (html) {
      this.terms = terms
      this.text = html
    } else {
      this.text = 'Could not load Terms and Conditions. Please contact administrator.'
    }
  }
}

</script>

<template>
  <sc-view :name="name">
    <template #toolbar>
      <q-btn label="Close" unelevated class="primary" @click="goBack()" />
    </template>

    <article v-if="text" class="terms-and-conditions markdown q-pa-md" v-html="text">
    </article>
  </sc-view>
</template>

<style lang="scss" scoped>
article.terms-and-conditions {
  flex: 1;
  overflow: auto;
}
</style>
