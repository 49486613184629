/**
 * Device families, which define groups of settings
 * shared by various device models, such as
 * EU- or US-specific technical parameters
 */
export const DeviceFamily = {
  EU: 'eu',
  US: 'us'
}

/**
 * Attempts to determine device family using
 * the data received from the device
 * @param serial Serial number of a device
 * @param model Device model
 * @param mega Mega version
 */
export function getDeviceFamily (device) {
  const { serialNumber, model, megaVersion } = device || {}
  if (serialNumber && model) {
    if (model.toLowerCase().includes('us')) {
      return DeviceFamily.US
    }
    if (megaVersion) {
      // lint-disable-line no-empty
    }
  }
  return DeviceFamily.EU
}
