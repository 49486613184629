import { Log, countAndPluralize } from '@stellacontrol/utilities'
import { PlanLayer, PlanLayers } from '@stellacontrol/planner'
import { createShape } from '../shapes/create-shape'
import { BaseLayer } from './base-layer'
import { RadiationLayer as RadiationLayerOld } from './radiation-layer-old'
import { RadiationLayer } from './radiation-layer'
import { BackgroundLayer } from './background-layer'
import { SelectionLayer } from './selection-layer'
import { ItemLayer } from './item-layer'
import { CrossSectionLayer } from './cross-section-layer'

/**
 * Creates a plan layer
 * @param {PlanRenderer} renderer Plan renderer
 * @param {String} id Layer identifier
 * @param {PlanLayout} layout Plan layout containing the items
 * @param {PlanFloor} floor Floor to show, optional
 * @param {Function<PlanItem, any>} onData Callback for fetching additional data for the specified plan item
 * @return {Promise<Layer> }
 */
export async function createLayer (id, renderer, layout, floor, onData) {
  if (!renderer) throw new Error('Renderer is required')
  if (!id) throw new Error('Layer identifier is required')
  if (!layout) throw new Error('Layout is required')
  if (!onData) throw new Error('Data callback is required')

  // Get items to show on the floor
  const items = floor
    .getItemsOn(id)
    .filter(item => renderer.canRenderOnTheFloor(item))

  if (renderer.isDebugging) {
    Log.debug(`Layer [${id}]`, countAndPluralize(items, 'item'))
  }

  // Create item shapes.
  // Do not render items which don't belong on the current view.
  // For example, if rendering a floor plan, don't show items which belong on the cross-section only.
  const shapes = items.map(item => {
    if (renderer.isDebugging) {
      Log.debug(`- ${item.toString()}`)
    }
    return createShape(item, onData)
  })
    .filter(shape => shape != null && !shape.isEmpty)

  const item = floor.layers.find(l => l.id === id) || new PlanLayer({ id })
  let layer
  switch (id) {
    case PlanLayers.Base:
      layer = new BaseLayer({ renderer, item, shapes, layout, floor })
      break
    case PlanLayers.Background:
      layer = new BackgroundLayer({ renderer, item, shapes, layout, floor })
      break
    case PlanLayers.Radiation:
      layer = new RadiationLayer({ renderer, item, shapes, layout, floor })
      // layer = new RadiationLayerOld({ renderer, item, shapes, layout, floor })
      break
    case PlanLayers.Selection:
      layer = new SelectionLayer({ renderer, item, shapes, layout, floor })
      break
    default:
      if (renderer.isCrossSection) {
        // Cross-section view has a dedicated item layer with special functionality
        layer = new CrossSectionLayer({ renderer, item, shapes, layout, floor: layout.crossSection })
      } else {
        // Floor views have a default item layer
        layer = new ItemLayer({ renderer, item, shapes, layout, floor })
      }
      break
  }

  return layer
}
