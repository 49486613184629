<script>
import ItemPropertiesMixin from './item-properties.js'
import { PlanActions } from '../../../renderer/actions'

export default {
  mixins: [
    ItemPropertiesMixin
  ],

  data () {
    return {
      // When changed, the riser will merge with the selected one
      withId: null
    }
  },

  computed: {
    // Riser associated with the plug
    riser () {
      return this.layout.risers.find(r => r.id === this.item.riser)
    },

    // The number of risers on the plan
    riserCount () {
      return this.layout.risers.length
    },

    // Risers to select from
    risers () {
      const { layout, riser } = this
      return layout
        .risers
        .filter(r => r.id !== riser.id)
        .map(r => ({
          value: r.id,
          label: `riser ${r.tag || r.index}`
        }))
    }
  },

  methods: {
    // Merges the edited riser with another riser
    mergeWithRiser (id) {
      const { items, riser, layout } = this
      const mergeWith = layout.getRiser(id)
      this.executeAction({
        action: PlanActions.MergeRisers,
        items,
        riser,
        mergeWith
      })
    },

    // Splits the riser
    splitRiser () {
      const { items, riser } = this
      this.executeAction({
        action: PlanActions.SplitRiser,
        items,
        riser
      })
    },
  }
}

</script>

<template>
  <main class="plan-item-properties">
    <section v-if="riser && riserCount > 1">
      <label style="text-wrap: nowrap;">
        Merge riser {{ riser.tag }} with
      </label>
      <q-select outlined square dense emit-value map-options :options="risers"
        :style="styles.select.medium" :model-value="withId"
        @update:model-value="id => mergeWithRiser(id)">
      </q-select>
    </section>
    <section v-if="riser && riser.isJointRiser">
      <label>
        Split riser {{ riser.tag }}
      </label>
      <q-btn class="button" unelevated no-wrap outline square icon="call_split" label="Split" no-caps
        @click="splitRiser()">
        <sc-tooltip text="Splits the riser into separate risers, one riser for each repeater group"></sc-tooltip>
      </q-btn>
    </section>
  </main>

</template>

<style lang="scss" scoped></style>
