import axios from 'axios'
import { Log, wait, getId, countString } from '@stellacontrol/utilities'
import { APISession } from '../api-session'
import { APIDebugger } from './api-debugger'
import { APIClientConfiguration } from './api-client-configuration'

/**
 * Cross-platform API client.
 * Use it as a base class to implement domain-specific API clients.
 * @description Implemented using `axios` library
 * @link https://github.com/axios/axios
 */
export class APIClient {
  /**
   * Returns API name served by this client
   * @type {String}
   */
  get name () {
    return 'API'
  }

  /**
   * Access to API session
   * @type {APISession}
   */
  get session () {
    return APISession
  }

  /**
   * Indicates whether the client has been initialized
   * @type {Boolean}
   */
  isInitialized = false

  /**
   * Indicates whether the API is online,
   * as determined with `getStatus` call
   * @type {Boolean}
   */
  isOnline = false

  /**
   * Service which uses this instance of API client, usually application name
   * @type {String}
   */
  service

  /**
   * API client configuration
   * @type {APIClientConfiguration}
   */
  configuration

  /**
   * API status, obtained with `getStatus` method
   * @type {Object}
   */
  status

  /**
   * Indicates whether the API client is required to run the application
   * @type {Boolean}
   */
  isRequired = false

  /**
   * Initializes the API Client
   * @param {Object} configuration API client configuration.
   */
  initialize (configuration) {
    if (!configuration) throw new Error('Configuration is required to initialize the API client')
    this.service = configuration.service
    this.configuration = new APIClientConfiguration(
      configuration.api
        ? { ...configuration.api }
        : { ...configuration })
    this.isRequired = configuration.isRequired
    this.isInitialized = true
    this.validate()

    return this
  }

  /**
   * Verifies whether the API client is configured and ready to work.
   * Throws if API client not initialized yet.
   */
  validate () {
    if (!this.isInitialized) throw new Error('API client has not been initialized')
    const { url } = this.configuration
    if (!url) throw new Error('API URL is not specified')
  }

  /**
   * Returns request path, combined with optional parameters,
   * using a specified base URL
   * @param {String} baseUrl Base URL
   * @param {String} name Endpoint name
   * @param {Dictionary<String, any>} params Endpoint parameters
   * @returns {String}
   */
  absoluteEndpoint (baseUrl, name, ...params) {
    return [baseUrl, name, ...params]
      .map(part => (part || '').toString())
      .map(part => part.trim())
      .map(part => part.replace(/^\/|\/$/g, ''))
      .filter(part => Boolean(part))
      .map((part, index) => index > 1 ? encodeURIComponent(part) : part)
      .join('/')
  }

  /**
   * Returns request path, combined with optional parameters
   * @param {String} name Endpoint name
   * @param {Object} params Endpoint parameters
   * @returns {String}
   */
  endpoint (name, ...params) {
    this.validate()
    return this.absoluteEndpoint(this.configuration.url, name, ...params)
  }

  /**
   * Endpoint for checking service status, can be overridden in custom API clients
   * @returns {String}
   */
  getStatusEndpoint () {
    return this.endpoint('status')
  }

  /**
   * Retrieves API status
   * @param {Boolean} suppressExceptions If true, exceptions are suppressed when checking the status
   * @returns {Promise<Object>}
   */
  async getStatus ({ suppressExceptions } = {}) {
    const { name } = this
    const url = this.configuration.url
    const statusUrl = this.getStatusEndpoint()

    try {
      const { status } = await this.request({ url: statusUrl })

      this.status = status
      this.isOnline = true

      return {
        ...(status || {}),
        url,
        name,
        isOnline: true
      }
    } catch (error) {
      if (this.isRequired && !suppressExceptions) {
        throw error
      } else {
        return {
          name,
          url,
          error: error.message,
          isOnline: false
        }
      }
    }
  }

  /**
   * Reports API client status
   * @param {Object} data Status details to report
   * @returns {Promise<Object>} Reported status
   */
  async reportStatus (data) {
    const url = this.endpoint()
    try {
      const { status } = await this.request({
        method: 'put',
        url,
        data: {
          time: new Date(),
          ...data
        }
      })
      return {
        ...status
      }
    } catch (error) {
      return {
        error
      }
    }
  }

  /**
   * Prepares and sends the specified request
   * @param {Object} options Request options
   * @returns {Promise<Object>} Returned data
   */
  request ({
    // Request method
    method = 'get',
    // Full request URL
    url,
    // Custom headers to include
    headers: customHeaders,
    // Query parameters
    params,
    // Request body
    data,
    // API Client
    client: customClient,
    // API Key
    key: customKey,
    // If true, Quasar will display a progress bar indicating a pending request
    progress = true,
    // Indicates cross-domain request with CORS handshake
    crossDomain = true,
    // Send JWT authorization token with the request
    authorization = true,
    // Custom JWT session token to use. If not specified, the recent token from APISession is used
    jwtToken,
    // Set to false to prevent retrying on errors.
    // Some API requests don't need it because they end up with errors by design,
    // i.e. login request
    retry,
    // Custom request timeout
    timeout = 0
  }) {
    this.validate()
    const { requestDelay = 0 } = this.configuration
    const paramsString = Object
      .entries(params || {})
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    return new Promise((resolve, reject) => {
      wait(requestDelay)
        .then(() => {
          // Obtain authorization headers if required.
          // This is asynchronous operation, as some applications might log in at this point.
          if (authorization) {
            return APISession.getAuthorizationHeaders(jwtToken)
          } else {
            return {}
          }
        })
        .then((authHeaders = {}) => {
          const { client, key, maxContentLength = Number.MAX_SAFE_INTEGER } = this.configuration
          const clientHeaders = (customClient !== false && (customClient || client))
            ? { 'sc-api-client': customClient || client, 'sc-api-key': customKey || key }
            : {}
          const headers = {
            'sc-request-id': getId(),
            ...authHeaders,
            ...clientHeaders,
            ...customHeaders
          }
          if ((method === 'put' || method === 'post') && (!data)) {
            data = {}
          }

          const options = {
            method,
            url,
            headers,
            params,
            data,
            timeout,
            maxContentLength,
            credentials: 'omit',
            crossDomain,
            progress,
            retry
          }

          return options
        })
        .then((options) => {
          // Debug the request
          APIDebugger.request(this, options)

          // Executes the request, optionally repeating it
          // in case of failure, if configured to do so
          const attemptRequest = async () => {
            let attempts = 0
            let remainingAttempts = 1
            let onError

            do {
              attempts++

              try {
                const response = await axios(options)

                // Debug the response
                if (response) {
                  APIDebugger.response(this, response)
                }

                return response || {}

              } catch (error) {
                onError = onError || this.shouldRetry(error, options)
                remainingAttempts = onError.retry - attempts
                if (remainingAttempts > 0) {
                  Log.warn(`${method} ${url}${paramsString ? '?' : ''}${paramsString} failed, trying again in ${onError.retryInterval} ms ... (${countString(remainingAttempts, 'attempt')} left)`)
                  Log.warn(`Trying again in ${onError.retryInterval} ms ... (${countString(remainingAttempts, 'attempt')} left)`)
                  await wait(onError.retryInterval)
                } else {
                  throw error
                }
              }
            } while (remainingAttempts > 0)
          }

          return attemptRequest()
            // Refresh JWT token if response returned it
            .then(({ data }) => {
              if (data) {
                APISession.setToken(data.sessionToken)
              }
              return data
            })
            // Handle the returned data
            .then(data => resolve(data))
            .catch(error => reject(error))
        })
    })
  }

  /**
   * Sends raw request with specified options
   * @param {Object} options Request options
   * @returns {Promise<Object>} Returned data
   */
  rawRequest (options) {
    if (!options) throw new Error('Request options are required')

    const requestOptions = {
      timeout: 0,
      maxContentLength: Number.MAX_SAFE_INTEGER,
      credentials: 'omit',
      crossDomain: true,
      ...options
    }

    // Debug the request
    APIDebugger.request(this, requestOptions)

    return axios(requestOptions)
      .then(response => {
        if (response) {
          APIDebugger.response(this, response)
          return response.data
        }
      })
  }

  /**
   * Handles errors, by unwrapping errors from HTTP responses
   * and behaving nicely if it's just a plain 404
   * @param {Error} error Error caught during execution of a request
   */
  handleError (error) {
    if (!error) return
    if (this.shouldIgnoreError(error)) return

    // Debug the request
    APIDebugger.error(this, error)

    // If no request configuration nor response in the error,
    // this must be a regular internal exception and not a request exception,
    // so simply re-throw it
    const { response, config } = error

    if (response) {
      // HTTP error returned from the API might contain a response object
      // with more details about the error, such as schema validation errors.
      // Get these details and create a more user-friendly error.
      const { status, statusText, data } = response
      const message = data
        ? [
          typeof data.error === 'boolean' ? null : data.error,
          data.message
        ]
          .filter(e => e)
          .join(': ')
        : `HTTP ${status} ${statusText || ''}`
      const bugReportId = data ? data.bugReportId : undefined
      const e = new Error((message || statusText || 'Unknown API error'))
      e.response = response
      e.bugReportId = bugReportId
      e.isNetworkError = this.isNetworkError(error)
      e.request = config

      throw e

    } else if (config) {
      // HTTP error without response, likely a lost network connection or timeout
      const e = new Error(error.message || 'Unknown API error')
      e.isNetworkError = this.isNetworkError(error)
      e.request = config

      throw e

    } else {
      throw error
    }
  }

  /**
   * Special handling of authentication errors.
   * Client application might have provided custom handling for such errors,
   * to perform actions such as redirecting back to login page etc.
   * @param {Error} error Error thrown during request
   * @returns {Boolean} True if error should be ignored
   */
  handleAuthenticationError (error) {
    if (error) {
      const { response } = error
      if (response && this.isAuthenticationError(error) && APISession.errorHandler) {
        // Ignore entirely if error happened during logout,
        // most probably just an expired session
        if (response.config && response.config.url.includes('/logout')) {
          return true
        } else {
          // If APISession has handled the error, suppress it
          if (APISession.errorHandler(error, response)) {
            return true
          }
        }
      }
    }
  }

  /**
   * Checks whether to ignore the specified error
   * @param {Error} error Error thrown during request
   * @returns {Boolean} True if error should be ignored
   */
  shouldIgnoreError (error) {
    const { networkError = {}, notFoundError = {} } = (this.configuration || {}).errors || {}

    if (this.isNotFoundError(error) && notFoundError.ignore) {
      return true
    }

    if (this.isNetworkError(error) && networkError.ignore) {
      return true
    }

    if (this.isRequestAbortedError(error)) {
      return true
    }

    // If 401, let the API session handle the error
    return this.handleAuthenticationError(error)
  }

  /**
   * Checks whether to retry request after the specified error
   * @param {Error} error Error thrown during request
   * @param {Object} options Request details
   * @returns {Object} If request should be retried, returns
   * options object defining details of how to retry, otherwise false
   */
  shouldRetry (error, options) {
    const { networkError = {}, notFoundError = {}, authenticationError = {}, authorizationError = {} } = this.configuration.errors || {}

    // Do not retry POST requests,
    // as exception might have happened AFTER the data was saved
    // and we would get multiple records saved as a result.
    if (options?.method.toLowerCase() === 'post') return { retry: 0 }

    // Some requests have retry banned explicitly
    if (options?.retry === false) return { retry: 0 }

    if (this.isAuthenticationError(error)) {
      if (authenticationError.retry) return authenticationError
    } else if (this.isAuthorizationError(error)) {
      if (authorizationError.retry) return authorizationError
    } else if (this.isNotFoundError(error)) {
      if (notFoundError.retry) return notFoundError
    } else if (this.isNetworkError(error)) {
      if (networkError.retry) return networkError
    }

    return { retry: 0 }
  }

  /**
   * Returns error response, if returned with the error
   */
  getErrorResponse (error) {
    return error && error.response && error.response.data
      ? error.response.data
      : { error: true, message: error.message }
  }

  /**
   * Returns true if bad request error
   */
  isBadRequestError (error) {
    return error && error.response && error.response.status === 400
  }

  /**
   * Returns true if authentication error
   */
  isAuthenticationError (error) {
    return error && error.response && error.response.status === 401
  }

  /**
   * Returns true if authorization error
   */
  isAuthorizationError (error) {
    return error && error.response && error.response.status === 403
  }

  /**
   * Returns true if not-found error
   */
  isNotFoundError (error) {
    return error && error.response && error.response.status === 404
  }

  /**
   * Returns true if entity-too-large error
   */
  isEntityTooLargeError (error) {
    return error && error.response && error.response.status === 413
  }

  /**
   * Returns true if network error.
   * Also IoT gateway errors HTTP 502 are technically network errors.
   */
  isNetworkError (error = {}) {
    const message = (error.message || '').toString().toLowerCase()
    const bits = [
      'network error',
      'no response',
      'econnrefused',
      'socket hang up',
      '504'
    ]
    return error.isAxiosError || bits.some(bit => message.includes(bit))
  }

  /**
   * Returns true if request has failed because aborted by the user
   */
  isRequestAbortedError (error) {
    const message = ((error || {}).message || '').toString().toLowerCase()
    return message.includes('request aborted')
  }

  /**
   * Returns error message from the returned data
   * @param defaultMessage Default message to return if no message in the returned data
   */
  getErrorMessage (error, defaultMessage = 'Unknown API error') {
    let message
    if (error) {
      if (error.response && error.response.data) {
        message = error.response.data.message
      }
      return message || defaultMessage
    }
  }

  /**
   * Returns response data from the returned error
   */
  getErrorData (error) {
    return (error && error.response)
      ? error.response.data
      : undefined
  }
}
