/**
 * Antenna types
 */
export const AntennaType = {
  Unknown: 0,
  Omnidirectional: 1,
  Yagi: 2,
  Laser: 3,
  Panel: 4
}

/**
 * Antenna names
 */
export const AntennaName = {
  [AntennaType.Unknown]: 'Unknown',
  [AntennaType.Omnidirectional]: 'Omnidirectional',
  [AntennaType.Yagi]: 'Yagi',
  [AntennaType.Laser]: 'Laser',
  [AntennaType.Panel]: 'Panel'
}

/**
 * Antenna orientations
 */
export const AntennaOrientation = {
  Unknown: 0x00,
  // 0x10 to 0x1f are 22.5 degree increments from 0.0 to 337.5.
  Angle_000_0_North: 0x10,
  Angle_022_5_NorthNortheast: 0x11,
  Angle_045_0_Northeast: 0x12,
  Angle_067_5_EastNortheast: 0x13,
  Angle_090_0_East: 0x14,
  Angle_112_5_EastSouthEast: 0x15,
  Angle_135_0_Southeast: 0x16,
  Angle_157_5_SouthSouthEast: 0x17,
  Angle_180_0_South: 0x18,
  Angle_202_5_SouthSouthWest: 0x19,
  Angle_225_0_SouthWest: 0x1a,
  Angle_247_5_WestSouthWest: 0x1b,
  Angle_270_0_West: 0x1c,
  Angle_292_5_WestNorthWest: 0x1d,
  Angle_315_0_NorthWest: 0x1e,
  Angle_337_5_NorthNorthWest: 0x1f,
  Angle_Omnidirectional: 0x20,
}

/**
 * Antenna direction names
 */
export const AntennaOrientationName = {
  [AntennaOrientation.Unknown]: 'Unknown',
  // 0x10 to 0x1f are 22.5 degree increments from 0.0 to 337.5.
  [AntennaOrientation.Angle_000_0_North]: 'N',
  [AntennaOrientation.Angle_022_5_NorthNortheast]: 'NNE',
  [AntennaOrientation.Angle_045_0_Northeast]: 'NE',
  [AntennaOrientation.Angle_067_5_EastNortheast]: 'ENE',
  [AntennaOrientation.Angle_090_0_East]: 'E',
  [AntennaOrientation.Angle_112_5_EastSouthEast]: 'ESE',
  [AntennaOrientation.Angle_135_0_Southeast]: 'SE',
  [AntennaOrientation.Angle_157_5_SouthSouthEast]: 'SSE',
  [AntennaOrientation.Angle_180_0_South]: 'S',
  [AntennaOrientation.Angle_202_5_SouthSouthWest]: 'SSW',
  [AntennaOrientation.Angle_225_0_SouthWest]: 'SW',
  [AntennaOrientation.Angle_247_5_WestSouthWest]: 'WSW',
  [AntennaOrientation.Angle_270_0_West]: 'W',
  [AntennaOrientation.Angle_292_5_WestNorthWest]: 'WNW',
  [AntennaOrientation.Angle_315_0_NorthWest]: 'NW',
  [AntennaOrientation.Angle_337_5_NorthNorthWest]: 'NNW',
  [AntennaOrientation.Angle_Omnidirectional]: 'Omnidirectional',
}
