import { padLeft } from './string'

/**
 * Checks if the specified value is a valid number
 * @param {any} value Value to check
 * @param {Boolean} strict If true, the check is strict: no implicit conversions are performed.
 * @param {Number} min Minimal value that the number must not exceed, optional
 * @param {Number} max Maximal value that the number must not exceed, optional
 * @returns {Boolean} True if value is a valid number
 * @description NaN won't qualify as number.
 */
export function isNumber (value, strict = true, min, max) {
  if (value == null) return false
  if (strict && typeof value !== 'number') return false
  const number = parseFloat(value)
  if (isNaN(number)) return false
  if (min != null && number < min) return false
  if (max != null && number > max) return false

  return true
}

/**
 * Returns true if specified value can be parsed as byte
 * and is truly a byte. Eg, 'a123' will still parse as
 * number with {@link parseInt} without a glitch, so it's not enough.
 * @param {any} value Value to check
 * @returns {Boolean} True if value is a valid byte
 * @description The check is strict: no implicit conversions are performed, also NaN won't qualify.
 */
export function isByte (value) {
  if (value != null && value >= 0 && value <= 255) {
    const s = value.toString()
    const i = parseInt(s)
    if (!isNaN(i)) {
      return Array.from(s).every(ch => '0123456789-'.includes(ch))
    }
  }
  return false
}

/**
 * Returns true if specified value can be parsed as integer number
 * and is truly an integer. Eg, 'a123' will still parse as
 * number with {@link parseInt} without a glitch, so it's not enough.
 * @param {any} value Value to check
 * @returns {Boolean} True if value is a valid integer number
 * @description The check is strict: no implicit conversions are performed, also NaN won't qualify.
 */
export function isInteger (value) {
  if (value != null) {
    const s = value.toString()
    const i = parseInt(s)
    if (!isNaN(i)) {
      return Array.from(s).every(ch => '0123456789-'.includes(ch))
    }
  }
  return false
}

/**
 * Returns true if specified value can be parsed as natural number
 * and is truly a natural number. Eg, 'a123' will still parse as
 * number with {@link parseInt} without a glitch, so it's not enough.
 * @param value Value to check
 */
export function isNatural (value) {
  if (value != null) {
    const s = value.toString()
    const i = parseInt(s)
    if (!isNaN(i) && i >= 0) {
      return Array.from(s).every(ch => '0123456789'.includes(ch))
    }
  }
  return false
}

/**
 * Returns true if specified value can be parsed as number
 * and is truly a number. Eg, 'a123' will still parse as
 * number with {@link parseFloat} without a glitch, so it's not enough.
 * @param {any} value Value to check
 * @returns {Boolean} True if value is a valid float number
 * @description The check is strict: no implicit conversions are performed, also NaN won't qualify.
 */
export function isFloat (value) {
  if (value != null) {
    const s = value.toString()
    const f = parseFloat(s)
    if (!isNaN(f)) {
      return Array.from(s).every(ch => '0123456789.-'.includes(ch))
    }
  }
  return false
}

/**
 * Returns a random integer within the specified range
 * @param {Number} min Lower range
 * @param {Number} max Upper range (non inclusive)
 * @returns {Number}
 */
export function randomInt (min = 0, max = Number.MAX_SAFE_INTEGER) {
  const number = Math.random()
  return min + Math.floor(number * (max - min))
}

/**
 * Safely parses the specified value as integer.
 * Returns the provided default value if result is NaN.
 * @param {String} value Value to parse
 * @param {Number} defaultValue Value to return if parse fails
 * @param {Boolean} allowFloat If true, float values are allowed on input, and will be rounded to the nearest integer
 * @returns {Number}
 * @description Checks whether the string has been parsed
 * to number and the result really represents the input.
 * Thus quirks like parseInt('123abc') parsing to 123
 * and not returning any errors are prevented.
 */
export function safeParseInt (value, defaultValue, allowFloat = true) {
  if (value == null) {
    return defaultValue
  } else {
    const number = allowFloat ? parseFloat(value) : parseInt(value)
    return isNaN(number) || (number.toString() !== value.toString())
      ? defaultValue
      : allowFloat ? Math.round(number) : number
  }
}

/**
 * Safely parses the specified value as float.
 * Returns the provided default value if result is NaN
 * @param {String} value Value to parse
 * @param {Number} defaultValue Value to return if parse fails
 * @returns {Number}
 * @description Checks whether the string has been parsed
 * to number and the result really represents the input.
 * Thus quirks like parseInt('123abc') parsing to 123
 * and not returning any errors are prevented.
 */
export function safeParseFloat (value, defaultValue) {
  if (value == null) {
    return defaultValue
  } else {
    const number = Number(value)
    return isNaN(number)
      ? defaultValue
      : number
  }
}

/**
 * Enumerates a sequence of numbers
 * @param {Number} start Number to start
 * @param {Number} length Length of the sequence
 * @param {Number} step Distance between values
 * @returns {Generator} Enumeration over the numbers in the sequence
 */
export function* sequence (start, length, step = 1) {
  start = parseInt(start)
  length = parseInt(length)
  step = parseInt(step)

  if (isNaN(start)) throw new Error('Sequence start must be a number')
  if (isNaN(length)) throw new Error('Sequence length must be a number')
  if (isNaN(step)) throw new Error('Sequence step must be a number')

  let value = start
  let count = 0
  while (count < length) {
    yield value
    value = value + step
    count++
  }
}

/**
 * Enumerates a sequence of numbers within the specified range
 * @param {Number} start Range start
 * @param {Number} end Range end, not inclusive. If range end is smaller
 * than the range start, the returned range will be in reverse order.
 * @param {Number} step Distance between the values
 * @returns {Generator} Enumeration over the numbers in the range
 */
export function* range (start, end, step = 1) {
  start = parseInt(start)
  end = parseInt(end)
  step = parseInt(step)

  if (isNaN(start)) throw new Error('Range start must be a number')
  if (isNaN(end)) throw new Error('Range end must be a number')
  if (isNaN(step)) throw new Error('Range step must be a number')
  if (step < 1) throw new Error('Range step must be a positive number')

  let value = start
  if (end >= start) {
    while (value < end) {
      yield value
      value = value + step
    }
  } else {
    while (value > end) {
      yield value
      value = value - step
    }
  }
}

/**
 * Returns an array of bits making up the specified number
 * @param {Number} number Number to parse
 * @param {Number} count Number of bits to return
 * @param {Boolean} reverse Bits are returned in order from least-significant to most-significant.
 * I.e. value `1` will be returned as [1, 0, 0, 0, 0, 0, 0, 0]
 * Set this parameter to `true` to return in reverse order.
 * @returns {Array[Number]} Array of bits
 */
export function bits (number, count = 8, reverse) {
  if (number != null && count >= 0) {
    const result = []
    const bits = padLeft((number >>> 0).toString(2), count, '0')
    while (count > 0) {
      result.push(parseInt(bits[count - 1] || 0))
      count--
    }
    return reverse ? result.reverse() : result
  }
}

/**
 * Rounds a number with the specified precision
 * @param {Number} number Number to round
 * @param {Number} precision Rounding precision, number of decimal places to preserve
 * @returns {Number} Rounded number
 */
export function round (number, precision = 0) {
  if (number != null) {
    if (!(precision >= 0)) throw new Error('Precision must be a natural number')
    return Math.round(number * Math.pow(10, precision)) / Math.pow(10, precision)
  } else {
    return number
  }
}
