<script>
import ChartMixin from './chart-mixin'

export default {
  name: 'LineChart',

  mixins: [
    ChartMixin
  ],

  data () {
    return {
    }
  },

  computed: {
    // Chart type
    type () {
      return 'line'
    }
  }
}

</script>

<template>
  <main>
    <canvas :class="chartClass" ref="chart"></canvas>
  </main>
</template>

<style scoped lang="scss">
main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  canvas {
    width: 100%;
    height: 100%;
    flex: 1;
  }
}
</style>
