/**
 * Built-in features, for whose permissions are created programmatically when user logs in
 */
export const BuiltInFeatures = {
  IsSuperAdministrator: 'super-administrator',
  IsResellerAdministrator: 'reseller-administrator',
  IsAdministrator: 'administrator',
  IsGuestUser: 'guest-user',
  IsRegularUser: 'regular-user',
  IsSuperOrganization: 'super-organization',
  IsResellerOrganization: 'reseller-organization',
  IsRegularOrganization: 'regular-organization',
  IsGuestOrganization: 'guest-organization',
  IsShippingCompany: 'shipping-company'
}

/**
 * Returns true if specified feature is a built-in one
 * @param name Feature name
 */
export function isBuiltInFeature (name) {
  return Object.values(BuiltInFeatures).includes(name)
}
