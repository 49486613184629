<script>
/**
 * Empty view
 */
export default {
}

</script>

<template>
  <q-layout>
    <q-page-container>
      <q-page class="column items-center justify-center">
        <sc-back-to-login if-logged-out label="Go back to login page">
        </sc-back-to-login>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<style lang="scss" scoped></style>
