import AnnouncementsView from './announcements.vue'
import AnnouncementView from './announcement.vue'
import { resolveAnnouncements, resolveAnnouncement } from './announcements.resolve'

export const Routes = [
  {
    name: 'announcements',
    path: '/announcements',
    component: AnnouncementsView,

    async beforeEnter (to, from, next) {
      const data = await resolveAnnouncements({ to, from })
      if (data) {
        next()
      }
    }
  },
  {
    name: 'announcement',
    path: '/announcements/:id',
    component: AnnouncementView,

    async beforeEnter (to, from, next) {
      const data = await resolveAnnouncement({ to, from })
      if (data) {
        next()
      }
    }
  }
]
