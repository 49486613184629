<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}

</script>

<template>
  <div class="row">
    <div class="col-sm-12 col-md-6 q-pa-md q-gutter-sm">
      Attachments ...
    </div>
  </div>
</template>

<style lang='scss' scoped>
</style>
