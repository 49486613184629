import { clone } from '@stellacontrol/utilities'
import { applyDeviceRules } from '@stellacontrol/mega'

/**
 * Checks whether the specified alert type is supported on the given device
 * @param {AlertType} alertType Alert type
 * @param {AlertConfiguration} configuration Alert configuration
 * @param {Device} device Device to check
 * @param {DeviceStatus} status Last known status of the device, optional
 * @returns {Boolean}
 */
export function isAlertSupported (alertType, configuration, device, status) {
  if (alertType && configuration && device) {
    // Check WHEN rules in alert configuration
    const appliedConfiguration = configuration.when
      ? applyDeviceRules(device, clone(configuration), configuration.when, status)
      : configuration
    if (!appliedConfiguration.isSupported) {
      return false
    }
    return true
  }
}
