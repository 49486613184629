import { formatDateTime } from '@stellacontrol/utilities'
import { FormValidationRules as rules } from './validation-rules'
import { validateForm, resetForm } from './validate-form'

/**
 * Vue form mixin, providing functionality
 * for validation etc.
 */
export const FormMixin = {
  data () {
    return {
      /**
       * Collection of standard validation rules
       */
      rules
    }
  },

  computed: {
    // Default form name
    formName () {
      return 'form'
    },

    // Indicates that form mixin has been introduced
    hasFormMixin () {
      return true
    },

    /**
     * Shorthand access to entity new indicator
     */
    isNew () {
      return 'data' in this ? this.data.isNew : undefined
    },

    /**
     * Shorthand access to entity creation date
     */
    createdAt () {
      return 'data' in this ? formatDateTime(this.data.createdAt) : undefined
    },

    /**
     * Shorthand access to entity modification date
     */
    updatedAt () {
      return 'data' in this ? formatDateTime(this.data.updatedAt) : undefined
    }
  },

  methods: {
    /**
     * Validates the form
     */
    async validate () {
      const isValid = await validateForm(this, this.formName)
      return isValid
    },

    /**
     * Resets the form after validation
     */
    reset () {
      return resetForm(this)
    }
  }
}
