<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { clip, plainText, toggleItem, formatDateTime, dehtmlize } from '@stellacontrol/utilities'
import { ViewMixin, FormMixin, Clipboard, Notification } from '@stellacontrol/client-utilities'
import { AuditSubject, AuditActionDescription, AuditSubjectDescription } from '@stellacontrol/model'
import { Secure } from '@stellacontrol/security-ui'
import { resolve } from './premium-services-audit.resolve'

const name = 'premium-services-audit'

export default {
  mixins: [
    ViewMixin,
    FormMixin,
    Secure
  ],

  components: {
  },

  data () {
    return {
      name,
      AuditSubject,
      AuditSubjectDescription,
      AuditActionDescription,
      filter: '',
      pagination: {
        page: 0,
        rowsPerPage: 100
      }
    }
  },

  computed: {
    ...mapState({
      // Grid columns
      columns: state => state.serviceManagement.auditView.columns,
      // Audit items
      items: state => state.serviceManagement.auditView.items,
      // Available audit subjects
      defaultAuditSubjects: state => state.serviceManagement.auditView.defaultAuditSubjects,
      // Selected audit subjects
      subjects: state => state.serviceManagement.auditView.filter.subjects,
      // Selected time range
      newerThan: state => state.serviceManagement.auditView.filter.newerThan,
      olderThan: state => state.serviceManagement.auditView.filter.olderThan,
      // Rows per page
      rowsPerPage: state => state.serviceManagement.auditView.filter.rowsPerPage
    }),

    ...mapGetters([
      'isLoading'
    ]),

    // View title
    title () {
      return 'Premium Services Audit'
    },

    // Indicates whether there are any audit items to show
    hasItems () {
      return this.items.length > 0
    },

    // Audit subject items, for toggle control.
    // Only bank can see audit trail of events related to editing the pricelists.
    subjectItems () {
      return this.defaultAuditSubjects
        .filter(subject => subject === AuditSubject.PremiumService ? this.isBank : true)
        .map(subject => ({ label: AuditSubjectDescription[subject], value: subject }))
    },

    // Checks whether specified subject is included in the filter
    isSubjectSelected () {
      return subject => subject === AuditSubject.PremiumService
        ? this.isBank && this.subjects.includes(subject)
        : this.subjects.includes(subject)
    }
  },

  methods: {
    ...mapActions([
      'gotoRoute',
      'getPremiumServicesAudit',
      'filterPremiumServicesAudit'
    ]),

    clip,
    plainText,
    formatDateTime,

    // Refreshes the data
    refresh () {
      this.getPremiumServicesAudit()
    },

    // Toggles the specified subject in the filter
    async toggleSubject (subject) {
      const { subjects, filterPremiumServicesAudit } = this
      await filterPremiumServicesAudit({
        subjects: toggleItem(subjects, subject)
      })
    },

    // Changes filter period
    async filterNewerThan (value) {
      const { filterPremiumServicesAudit } = this
      const newerThan = value || new Date()
      await filterPremiumServicesAudit({ newerThan })
    },

    async filterOlderThan (value) {
      const { filterPremiumServicesAudit } = this
      const olderThan = value || new Date()
      await filterPremiumServicesAudit({ olderThan })
    },

    // Pagination changed - store on state
    async paginationChanged ({ rowsPerPage }) {
      if (this.rowsPerPage != rowsPerPage) {
        const { filterPremiumServicesAudit } = this
        await filterPremiumServicesAudit({ rowsPerPage, reload: false })
      }
    },

    // Copies tooltip text to clipboard
    copyTooltipToClipboard (item) {
      const lines = [
        `Action: ${AuditSubjectDescription[item.subjectType]} ${AuditActionDescription[item.action]}`,
        `Date: ${formatDateTime(item.createdAt)}`,
        `User: ${item.actor.email}`,
        `Organization: ${item.actor.organization}`,
        '',
        dehtmlize(item.tooltip)
      ]
      const text = lines.map(line => line.trim()).join('\n')
      Clipboard.write(text)
      Notification.success({
        message: `The details of ${formatDateTime(item.createdAt)}: ${AuditActionDescription[item.action]} have copied to clipboard.`
      })
    }
  },

  // Reload data on navigation to another organization or subscription
  async beforeRouteUpdate (to, from, next) {
    if (await resolve({ from, to })) {
      await next()
    }
  },

  created () {
    this.pagination.rowsPerPage = this.rowsPerPage || 100
  }
}

</script>

<template>
  <sc-view :name="name" :title="title">

    <template #toolbar>
      <div class="q-gutter-sm">
        <q-btn label="Refresh" icon="refresh" unelevated @click="refresh()"></q-btn>
      </div>
    </template>

    <template #header>
      <sc-list-filter :name="name">
        <div class="row item-center q-pl-lg q-pt-xs">
          <q-btn-group push flat unelevated>
            <q-btn v-for="item in subjectItems" :key="item.value" :label="item.label" no-caps
              :ripple="false" :color="isSubjectSelected(item.value) ? 'primary' : 'white'"
              :text-color="isSubjectSelected(item.value) ? 'white' : 'gray-8'"
              style="border-right: solid silver 1px" @click="toggleSubject(item.value)">
            </q-btn>
          </q-btn-group>

          <sc-date-input class="q-ml-md" dense label="From" bg-color="white" default-today
            :input-style="{ width: '110px' }" :model-value="newerThan"
            @update:model-value="value => filterNewerThan(value)">
          </sc-date-input>

          <sc-date-input class="q-ml-md" dense label="Until" bg-color="white" default-today
            :input-style="{ width: '110px' }" :model-value="olderThan"
            @update:model-value="value => filterOlderThan(value)">
          </sc-date-input>
        </div>
      </sc-list-filter>
    </template>

    <div class="loading q-ma-md" v-if="isLoading">
      <span class="text-black title q-mb-md">
        Loading audit trail, please wait ...
      </span>
      <q-spinner-gears size="48px" color="grey-8" />
    </div>

    <div class="q-ma-md" v-else-if="!hasItems">
      <div class="q-mb-md">
        No audit events found.
      </div>
    </div>

    <sc-list v-else :name="name" :columns="columns" :items="items" row-key="id"
      :initial-pagination="pagination" @paginate="pagination => paginationChanged(pagination)">

      <template v-slot:body-cell-actor="props">
        <q-td :props="props">
          {{ props.row.actor.lastName ? `${props.row.actor.firstName} ${props.row.actor.lastName}` :
              props.row.actor.email
          }}
          <sc-tooltip :text="props.row.email">
          </sc-tooltip>
        </q-td>
      </template>

      <template v-slot:body-cell-subject="props">
        <q-td :props="props">
          <router-link v-if="props.row.subjectType === AuditSubject.Device" class="item-link"
            :to="{ name: 'inventory', query: { selection: props.row.subject } }">
            {{ props.row.subject }}
          </router-link>
          <router-link v-else-if="props.row.subjectType === AuditSubject.Wallet" class="item-link"
            :to="{ name: 'premium-subscribers', query: { organization: props.row.subjectId } }">
            {{ props.row.subject }}
          </router-link>
          <router-link v-else-if="isBank && props.row.subjectType === AuditSubject.PremiumService"
            class="item-link"
            :to="{ name: 'premium-services', query: { service: props.row.subjectId } }">
            {{ props.row.subject }}
          </router-link>
          <span v-else>
            {{ props.row.subject }}
          </span>
        </q-td>
      </template>

      <template v-slot:body-cell-description="props">
        <q-td :props="props" class="desc">
          {{ clip(plainText(props.row.details), 130) }}
          <q-popup-edit anchor="top middle" class="ps-audit-popup bg-grey-2"
            :model-value="props.row.tooltip"
            :title="`${formatDateTime(props.row.createdAt)}: ${AuditActionDescription[props.row.action]}`">
            <div v-html="props.row.tooltip">
            </div>
            <div class="q-mt-md q-mb-sm row items-center">
              <q-btn v-close-popup class="primary" label="Copy to clipboard"
                @click="copyTooltipToClipboard(props.row)"></q-btn>
            </div>
          </q-popup-edit>
        </q-td>
      </template>

    </sc-list>

  </sc-view>
</template>

<style lang='scss' scoped>
.loading {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sc-list {
  table {
    vertical-align: top;

    &.desc {
      max-width: 300px;
      white-space: wrap;
    }
  }
}

.subject-toggle {
  border: 1px solid #027be3
}
</style>

<style>
.q-menu.scroll.q-popup-edit.ps-audit-popup {
  min-width: 600px !important;
  max-width: 600px !important;
}
</style>
