/**
 * Audited subjects
 */
export const AuditSubject = {
  User: 'user',
  Organization: 'organization',
  OrganizationProfile: 'organization-profile',
  Device: 'device',
  Place: 'place',
  // TODO Obsolete, remove
  FloorPlan: 'floor-plan',
  Plan: 'plan',
  AlertConfiguration: 'alert-configuration',
  Alert: 'alert',
  Notification: 'notification',
  PremiumService: 'premium-service',
  PremiumServiceSubscription: 'premium-service-subscription',
  Wallet: 'wallet',
  TermsAndConditions: 'terms-and-conditions',
  DeviceFirmware: 'device-firmware',
  UploadJob: 'upload-job',
  EMail: 'email',
  Page: 'page'
}

export const AuditSubjectDescription = {
  [AuditSubject.User]: 'User',
  [AuditSubject.Organization]: 'Organization',
  [AuditSubject.OrganizationProfile]: 'Organization Profile',
  [AuditSubject.Device]: 'Device',
  [AuditSubject.Place]: 'Place',
  // TODO Obsolete, remove
  [AuditSubject.FloorPlan]: 'Floor Plan',
  [AuditSubject.Plan]: 'Plan',
  [AuditSubject.AlertConfiguration]: 'Alert Configuration',
  [AuditSubject.Alert]: 'Alert',
  [AuditSubject.Notification]: 'Notification',
  [AuditSubject.PremiumService]: 'Premium Service',
  [AuditSubject.PremiumServiceSubscription]: 'Premium Subscription',
  [AuditSubject.Wallet]: 'Transactions',
  [AuditSubject.TermsAndConditions]: 'Terms and Conditions',
  [AuditSubject.DeviceFirmware]: 'Device Firmware',
  [AuditSubject.UploadJob]: 'Upload',
  [AuditSubject.EMail]: 'E-Mail Address',
  [AuditSubject.Page]: 'Page'
}
