<script>
import { CellMixin } from './cell-mixin'

export default {
  mixins: [
    CellMixin
  ],

  props: {
    item: {
      type: Object,
      required: true,
    }
  }
}
</script>

<template>
  <div :style="columnStyle">
    {{ item.device.premiumServiceLabel }}
  </div>
</template>