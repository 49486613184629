<script>
import { mapGetters } from 'vuex'
import { FormMixin } from '@stellacontrol/client-utilities'
import { Secure } from '@stellacontrol/security-ui'
import { PeriodNames } from '@stellacontrol/utilities'
import { PremiumService } from '@stellacontrol/model'

export default {
  mixins: [
    FormMixin,
    Secure
  ],

  props: {
    // Currently selected premium service
    service: {
      required: true
    },
    // List of premium features to select from
    premiumFeatures: {
      required: true
    }
  },

  computed: {
    ...mapGetters([
      'organizationHierarchy'
    ]),

    // Period units allowed for premium services
    periodUnits () {
      const names = this.service.period < 2 ? PeriodNames.Singular : PeriodNames.Plural
      return PremiumService.allowedPeriodUnits.map(value => ({ label: names[value], value }))
    },

    // Period duration cannot be changed for existing premium services
    canChangePeriodDuration () {
      return this.service.isNew
    },

    // We no longer allow selecting other period units than day.
    canChangePeriodType () {
      return false
    },

    // Checks whether current user can change reseller assigned to the premium service
    canEditReseller () {
      return this.isSuperAdministrator
    },

    // All resellers under the current organization
    // who have permission to sell premium services
    resellers () {
      return this.organizationHierarchy
        .toList()
        .filter(o =>
          o.id !== this.currentOrganization.id &&
          (o.isPremiumReseller || o.id === this.service.resellerId))
    },

    // Reseller currently assigned to the premium service
    reseller () {
      return this.resellers.find(r => r.id === this.service.resellerId)
    }
  },

  emits: [
    'cancel',
    'save',
    'remove'
  ],

  methods: {
    // Cancels editing
    cancel () {
      this.$emit('cancel')
    },

    // Saves changes
    save (service) {
      this.$emit('save', service)
    },

    // Removes the premium service from pricelist
    remove (service) {
      this.$emit('remove', service)
    }
  }
}

</script>

<template>
  <q-form ref="form" class="form">
    <div class="fields">
      <div class="row">
        <div class="q-mb-sm">
          <div class="text-h6">
            {{ (service.name || '').trim() || '[Specify premium service name]' }}
          </div>
          <div class="text-grey-7">
            {{ capitalize(service.priceDescription) }}
          </div>
        </div>

        <q-space></q-space>
        <div class="buttons q-gutter-sm">
          <q-btn flat dense unelevated label="Delete" icon="delete" class="light-danger" @click="remove(service)" />
          <q-btn flat dense unelevated label="Save" class="primary" @click="save(service)" />
          <q-btn flat dense unelevated label="Close" class="default" @click="cancel()" />
        </div>
      </div>

      <div class="col q-mt-md q-gutter-md">
        <q-input v-model="service.name" dense square outlined debounce="1000" :style="{ 'max-width': '500px' }"
          maxlength="255" hide-bottom-space lazy-rules
          :rules="[rules.required('Name is required')]">
          <template v-slot:before> <label>Service name</label> </template>
          <template v-slot:after> <sc-hint-placeholder /> </template>
        </q-input>

        <q-input v-model="service.code" dense square outlined debounce="1000" :style="{ 'max-width': '500px' }"
          maxlength="255" hide-bottom-space>
          <template v-slot:before> <label>Product code</label> </template>
          <template v-slot:after> <sc-hint text="Short code used to refer to the service" /> </template>
        </q-input>

        <q-input v-model="service.description" dense square outlined maxlength="1000">
          <template v-slot:before> <label>Description</label> </template>
          <template v-slot:after> <sc-hint-placeholder /> </template>
        </q-input>

        <sc-organization-selector class="q-mt-md" dense square outlined bg-color="white" clearable
          v-if="canEditReseller"
          v-model="service.resellerId"
          :items="resellers">
          <template v-slot:before>
            <label>Distributor</label>
          </template>
          <template v-slot:after>
            <sc-hint text="Distributor which can sell this premium service.<br>Also his resellers will be able to sell it,<br>unless it is marked as private."></sc-hint>
          </template>
        </sc-organization-selector>

        <div class="row items-center q-mb-md">
          <label class="text-grey-7">Duration</label>
          <div class="row items-center" style="padding-left: 6px;">
            <q-input v-model.number="service.period" type="number" min="0" max="1000" square dense outlined maxlength="1000"
              :style="{ 'max-width': '266px' }" :readonly="!canChangePeriodDuration" hide-bottom-space>
              <template v-slot:after>
                <label v-if="service.isPerDay">
                  days
                </label>
              </template>
            </q-input>
            <q-select v-if="!service.isPerDay" v-model="service.periodUnit" square dense outlined label="Period" class="q-ml-md"
              :style="{ 'max-width': '210px' }"
              :options="periodUnits"
              :readonly="!canChangePeriodType"
              emit-value
              map-options
              option-value="value"
              option-label="label">
              <template v-slot:after>
                <sc-hint>
                  <span>
                  Length of subscription. Set duration to zero to create service which never expires, and can be purchased with a one-off payment.
                  </span>
                  <span v-if="!canChangePeriodType">
                    <br><br>
                    Period length can't be changed.
                  </span>
                </sc-hint>
              </template>
            </q-select>
          </div>
        </div>

        <q-input v-model.number="service.price" type="number" min="0" max="1000" dense square outlined maxlength="1000" label="Tokens"
          :style="{ 'max-width': '300px' }">
          <template v-slot:before> <label>Service Price</label> </template>
          <template v-slot:after>
            <sc-hint v-if="service.price === 0" :text="`The service is free of charge ${service.period  > 0 ? `for ${service.periodDescription}` : ''}`"></sc-hint>
            <sc-hint v-else :text="`Customer will pay ${service.priceLabel} for each ${service.period === 0 ? 'the service' : `${service.periodDescription} of use.`}`"></sc-hint>
          </template>
        </q-input>

        <q-field dense borderless>
          <div class="row items-center">
            <q-toggle v-model="service.isPrivate" color="green" />
            <sc-hint v-if="service.isPrivate">
              The service is available only to
              {{ reseller ? 'the distributor.' : 'you.' }}
              Nobody else will able to sell it.
            </sc-hint>
            <sc-hint v-else>
              The service is also available to
              {{ reseller ? `resellers of ${reseller.name}.` : 'your distributors and resellers.' }}
              They will be able to sell it to their customers.
            </sc-hint>
          </div>
          <template v-slot:before> <label>Service is Private</label> </template>
        </q-field>

        <q-field dense borderless>
          <div class="row items-center">
            <q-toggle v-model="service.isDefault" color="green" />
            <sc-hint>
              If service is marked as default, it will be selected
              automatically when
              {{ reseller ? `${reseller.name} sells` : 'you sell' }}
              devices to customers.
            </sc-hint>
          </div>
          <template v-slot:before> <label>Service is Default</label> </template>
        </q-field>

        <q-field dense borderless>
          <div class="row items-center q-pl-sm">
            <sc-color-picker v-model="service.color" class="q-mr-xs"></sc-color-picker>
            <sc-hint>
              Custom color to use when displaying premium service icon
            </sc-hint>
          </div>
          <template v-slot:before> <label>Custom Color</label> </template>
        </q-field>
      </div>
    </div>

    <div class="features-header q-mt-lg q-mb-md">
      <span class="text-subtitle1">
        Functionality included in the service:
      </span>
    </div>

    <div class="features-container">
      <div class="features">
        <div clickable v-close-popup v-for="feature in premiumFeatures" :key="feature.name">
          <q-checkbox size="sm" color="indigo-5"
            v-model="service.features"
            :val="feature.name"
            :label="feature.description"
          />
        </div>
      </div>
    </div>
  </q-form>
</template>

<style lang='scss' scoped>
.form {
  display: flex;
  flex-direction: column;

  .fields {
    flex: 0;
    display: flex;
    flex-direction: column;

    .buttons {
      .q-btn {
        min-width: 90px;
      }
    }

    label {
      font-size: 14px;
      min-width: 150px;
    }
  }

  .features-header {
    flex: 0;
  }

  .features-container {
    flex: 1;
    overflow: auto;

    .features {
      max-height: 300px;
      flex-direction: column;
      border: 1px solid #0000001f;
      overflow: auto;
    }
  }
}
</style>
