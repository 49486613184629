import { PlaceType } from './place-type'

// Organization place icons, representing its type
export const PlaceIcons = {
  [PlaceType.Building]: 'home',
  [PlaceType.Ship]: 'directions_boat',
  [PlaceType.Vehicle]: 'directions_bus',
  [PlaceType.Other]: 'place',
  [PlaceType.NoPlace]: 'storage',
  [PlaceType.SharedPlace]: 'share'
}

/**
 * Determines an icon representing a place
 * @param placeType Place type
 * @param defaultIcon Default icon to return, if icon cannot be determined
 */
export function getPlaceIcon (placeType, defaultIcon = 'place') {
  return (placeType ? PlaceIcons[placeType] : '') || defaultIcon
}
