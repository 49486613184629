import { safeParseInt, merge } from '@stellacontrol/utilities'
import { PlanItem, PlanItemType } from './plan-item'
import { PlanLineStyle } from '../styles'

/**
 * Circle
 */
export class PlanCircle extends PlanItem {
  constructor (data = {}) {
    super(data)
    this.assign(data)
  }

  /**
   * Item type
   * @type {PlanItemType}
   */
  static get type () {
    return PlanItemType.Circle
  }

  /**
   * Creates a new rectangle line
   * @param {Object} data Initial data
   * @returns {PlanRectangle}
   */
  static create (data = {}) {
    return new PlanCircle({
      ...data,
      lineStyle: merge({ width: 2 }, data.lineStyle),
      backgroundStyle: merge({ color: 'blue', opacity: 0.5 }, data.backgroundStyle),
    })
  }

  /**
   * Item defaults
   */
  get defaults () {
    return {
      ...super.defaults,
      radius: 100,
      innerRadius: 0,
      lineStyle: PlanLineStyle.Default
    }
  }

  normalize () {
    super.normalize()
    const { defaults } = this
    this.innerRadius = Math.max(0, safeParseInt(this.innerRadius, defaults.innerRadius))
  }

  get persistentProperties () {
    return ['radius', 'innerRadius']
  }

  /**
   * Inner radius, optional.
   * If specified, the circle is rendered as hollow ring
   * @type {Number}
   */
  innerRadius

  /**
   * Resizes the circle
   * @param {Number} radius New radius
   */
  resize (radius) {
    if (this.canResize) {
      const ratio = this.innerRadius
        ? this.radius / this.innerRadius
        : undefined
      this.radius = radius == null ? this.radius : radius
      // Keep the ratio of the inner radius intact
      if (ratio) {
        this.innerRadius = this.radius / ratio
      }
    }
  }

  /**
   * Resizes the circle by the specified delta
   * @param {Number} rx Radius delta on X axis. Radius on Y axis will change by the same amount.
   * @param {Number} ry Alternatively, radius delta on Y axis. Radius on X axis will change by the same amount.
   */
  resizeBy (rx, ry) {
    if (this.canResize) {
      if (rx != null) {
        this.resize(this.radius + rx)
      }
      if (ry != null) {
        this.resize(this.radius + ry)
      }
    }
  }
}
