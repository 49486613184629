<script>
import ParameterEditor from './parameter-editor'

export default {
  mixins: [
    ParameterEditor
  ],

  data () {
    return {
      fanTemp: 0
    }
  },

  methods: {
    save (value) {
      const { min, max } = this
      const fanTemp = parseInt((value || '').trim())
      if (!isNaN(fanTemp) && fanTemp >= min && fanTemp <= max) {
        this.change(fanTemp)
      }
    }
  },

  created () {
    const { custom, reported } = this
    this.fanTemp = (Math.round(10 * (custom == null ? reported : custom) || 0)) / 10
  }
}
</script>

<template>
  <div class="parameter-editor" v-if="hasValue">

    <sc-parameter-value
      :label="mega.label"
      :reported="reported"
      :custom="custom"
      :format="value => value == null ? '-' : `${(Math.round(value * 10) / 10).toString()} ${mega.unit}`">
    </sc-parameter-value>

    <q-popup-edit square :style="{ padding: 0 }" :cover="false" v-model="fanTemp" buttons v-slot="scope" @save="value => save(value)">
      <q-banner class="bg-indigo-6">
        <span class="text-white title">
          {{ mega.label }}
        </span>
      </q-banner>
      <div class="q-pa-md">
        <span class="q-mb-sm">
          {{ mega.description }}
        </span>
        <q-input v-model="scope.value" dense autofocus @keyup.enter="scope.set" type="number" :min="min" :max="max">
          <template v-slot:append>
            <label>{{ mega.unit }}</label>
          </template>
        </q-input>
      </div>
    </q-popup-edit>

  </div>
</template>

<style lang="scss" scoped>
label {
  font-size: 14px;
}
</style>