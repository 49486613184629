import LineChart from './line-chart.vue'

/**
 * Installs chart components into the application
 * @param application Vue application instance
 */
export function useCharts (application) {
  const plugin = {
    install (application) {
      const items = [
        {
          tag: 'sc-line-chart', component: LineChart
        }
      ]

      for (const { tag, component } of items) {
        application.component(tag, component)
      }
    }
  }

  application.use(plugin)
}
