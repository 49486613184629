/**
 * Simple event listener
 */
export class EventListener {
  /**
   * Event handlers
   */
  #eventHandlers = {}

  /**
   * Returns a list of supported events
   * @returns {Array[String]}
   */
  get events () {
    return []
  }

  /**
   * Returns a dictionary of event handlers
   * @returns {Dictionary<String, Function>}
   */
  get handlers () {
    return this.#eventHandlers
  }

  /**
   * Checks if the specified event is one of the supported {@link events}
   * @param {String} name
   */
  isValidEvent (name) {
    return name && (this.events.length === 0 || this.events.includes(name))
  }

  /**
   * Checks if the specified event has been wired up
   * @param {String} name
   */
  hasEventListener (name) {
    return name && (this.#eventHandlers[name]?.length > 0)
  }

  /**
 * Adds the specified event listener
 * @param {String} name Event name
 * @param {Function} handler Event handler
 */
  addEventListener (name, handler) {
    if (!name) throw new Error('Event name is required')
    if (!handler) throw new Error('Event handler is required')
    if (typeof handler !== 'function') throw new Error('Event handler must be a function')
    if (!this.isValidEvent(name)) throw new Error(`Event [${name}] is not supported`)

    let handlers = this.#eventHandlers[name]
    if (!handlers) {
      handlers = []
      this.#eventHandlers[name] = handlers
    }
    if (!handlers.includes(handler)) {
      handlers.push(handler)
    }
  }

  /**
   * Removes the specified event listener.
   * If handler is specified, only this specific handler is removed,
   * otherwise all handlers for the event are removed.
   * @param {String} name Event name
   * @param {Function} handler Event handler
   */
  removeEventListener (name, handler) {
    if (!name) throw new Error('Event name is required')
    if (handler && typeof handler !== 'function') throw new Error('Event handler must be a function')
    if (!this.isValidEvent(name)) throw new Error(`Event [${name}] is not supported`)

    const handlers = this.#eventHandlers[name]
    if (handlers && handler) {
      const index = handlers.indexOf(handler)
      if (index > -1) {
        handlers.splice(index, 1)
      }
    } else {
      handlers.splice(0)
    }
  }

  /**
   * Emits an event
   * @param {String} name Event name
   * @param {Array} args Event arguments
   * @returns Handler result
   */
  async emit (name, ...args) {
    if (!name) throw new Error('Event name is required')
    if (!this.isValidEvent(name)) throw new Error(`Event [${name}] is not supported`)

    const handlers = this.#eventHandlers[name]
    if (handlers) {
      let results = []
      for (const handler of handlers) {
        if (typeof handler === 'function') {
          const result = await handler(...args)
          results.push(result)
        }
      }
      // Return accumulated results of event handlers
      return results.length === 1
        ? results[0]
        : results
    }
  }
}
