<script>
import { PlanBackgroundStyle } from '@stellacontrol/planner'
import ItemPropertiesMixin from './item-properties.js'

export default {
  mixins: [
    ItemPropertiesMixin
  ],

  data () {
    return {
      defaults: new PlanBackgroundStyle().defaults
    }
  }
}

</script>

<template>
  <main class="plan-item-properties">
    <section>
      <label>
        Background
      </label>
      <sc-color-picker :model-value="getValue('backgroundStyle.color', defaults.color)"
        @update:model-value="value => setProperties({ 'backgroundStyle.color': value })" full clear
        :width="styles.color.width" :height="styles.color.height">
      </sc-color-picker>
      <q-input type="number" :min="0.1" :max="1" :step="0.1" label="Opacity" class="q-ml-xs" dense
        outlined square debounce="500" :input-style="styles.number"
        :model-value="getValue('backgroundStyle.opacity', defaults.opacity)"
        @update:model-value="value => setProperties({ 'backgroundStyle.opacity': parseFloat(value) })">
      </q-input>
    </section>

    <section v-if="hasProperty('blurRadius')">
      <label>
        Blur
      </label>
      <q-input type="number" :min="0" :max="100" dense outlined square label="Blur"
        debounce="500" :input-style="styles.number" :model-value="getValue('blurRadius')"
        @update:model-value="value => setProperties({ blurRadius: parseInt(value) })">
      </q-input>
    </section>
  </main>
</template>

<style lang="scss" scoped></style>
