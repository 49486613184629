import { parseDate } from '@stellacontrol/utilities'
import { Assignable } from '../common/assignable'

/**
 * Upload payload details
 */
export class UploadPayload extends Assignable {
  constructor (data = {}) {
    super()
    this.assign({
      ...data,
      file: {
        ...(data.file || {}),
        date: parseDate((data.file || {}).date)
      }
    })
  }

  /**
   * Identifier
   */
  id

  /**
   * Name
   */
  name

  /**
   * Description
   */
  description

  /**
   * File details
   */
  file
}
