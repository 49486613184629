import { state, getters } from './premium-subscribers-view.state'
import { mutations } from './premium-subscribers-view.mutations'
import { actions } from './premium-subscribers-view.actions'

export default {
  state,
  getters,
  mutations,
  actions
}
