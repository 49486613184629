<script>

/**
 * Loading... view
 */
export default {
  props: [
    'title'
  ],

  data () {
    return {
      defaultTitle: 'Loading, please wait ...'
    }
  },

  created () {
  }
}

</script>

<template>
  <q-layout>
    <q-page-container>
      <q-page class="column items-center justify-center">
        <q-inner-loading :showing="true">
          <q-spinner-gears size="64px" color="grey-7" />
        </q-inner-loading>
        <label class="loading text-h5 text-weight-light text-black">
          {{ title || defaultTitle }}
        </label>
      </q-page>
    </q-page-container>
  </q-layout>

</template>

<style lang="scss" scoped>
label.loading {
  margin-top: 140px;
  padding-left: 20px;
}
</style>
