import { isEnum } from '@stellacontrol/utilities'
import { Entity } from '../../common/entity'
import { AuditSubject } from './audit-subject'
import { AuditAction } from './audit-action'
import { PrincipalType } from '../../security/principal/principal-type'
import { User } from '../../organization/organization-entities'

/**
 * Audit entry recording someone performing an action
 * on a specified subjectType
 */
export class AuditItem extends Entity {
  constructor (data = {}) {
    super()

    this.assign(data)

    this.actorId = data?.actor?.id || this.actorId
    if (!isEnum(AuditAction, this.action)) throw new Error(`Invalid audit action ${this.action}`)
    if (!isEnum(AuditSubject, this.subjectType)) throw new Error(`Invalid action subjectType ${this.subjectType}`)
    if (this.targetType && !isEnum(AuditSubject, this.targetType)) throw new Error(`Invalid action target ${this.targetType}`)

    // Reduce user data to a bare minimum
    if (data?.actor) {
      const { id, name, firstName, lastName, level, organizationId } = data.actor
      this.actor = new User({ id, name, firstName, lastName, level, organizationId })
    }
  }

  /**
   * Identifier of the environment on which the action has been recorded
   * @type {Environment}
   */
  environment

  /**
   * Domain from which the action has been triggered
   * @type {String}
   */
  domain

  /**
   * Identifier of user who performed the action
   * @type {String}
   */
  actorId

  /**
   * Details of user who performed the action
   * @type {User}
   */
  actor

  /**
   * Performed action
   * @type {AuditAction}
   */
  action

  /**
   * Identifier of the subjectType impacted by the action, such as user identifier, organization identifier etc.
   * @type {String}
   */
  subjectId

  /**
   * Type of the subject, such as 'user' or 'organization'
   * @type {AuditSubject}
   */
  subjectType

  /**
   * Subject details
   * @type {Object}
   */
  subject

  /**
   * Identifier of the targetType impacted by the action, such as user identifier, organization identifier etc.
   * if action is directed from one entity to another, such as selling device from one organization to another
   * or reseller selling tokens to a customer
   * @type {String}
   */
  targetId

  /**
   * Type of the target, such as 'user' or 'organization'
   * @type {AuditSubject}
   */
  targetType

  /**
   * Target details
   * @type {Object}
   */
  target

  /**
   * Other details regarding the action
   * @type {String}
   */
  details

  /**
   * Detailed description of changes in the audited instance
   * @type {String}
   */
  changes

  /**
   * Overrides serialization to prevent serializing of certain
   * runtime-only properties
   */
  toJSON () {
    const result = {
      ...this
    }
    return result
  }

  /**
   * Creates an instance of audit event
   * @returns {AuditItem}
   */
  static create ({ actor, action, subjectType, subjectId, targetType, targetId, details, changes }) {
    if (!action) throw new Error('Audit action is required')
    if (!subjectType) throw new Error('Action subject is required')
    return new AuditItem({ actor, action, subjectType, subjectId, targetType, targetId, details, changes })
  }

  /**
   * Creates an instance of principal-related audit event
   */
  static forPrincipal ({ actor, principal, action, targetId, targetType, details, changes }) {
    if (!principal) throw new Error('Principal impacted by the action is required')
    const subjectTypes = {
      [PrincipalType.User]: AuditSubject.User,
      [PrincipalType.Organization]: AuditSubject.Organization,
      [PrincipalType.OrganizationProfile]: AuditSubject.OrganizationProfile,
      [PrincipalType.Place]: AuditSubject.Place
    }
    const subjectType = subjectTypes[principal.type]
    if (subjectType) {
      return new AuditItem({
        actor,
        action,
        subjectType: AuditSubject.User,
        subjectId: principal.id,
        targetId,
        targetType,
        details: details || principal.name,
        changes
      })
    } else {
      throw new Error('Unknown principal type')
    }
  }

  /**
   * Creates an instance of device-related audit event
   */
  static forDevice ({ actor, device, action, targetId, targetType, details, changes }) {
    if (!device) throw new Error('Device impacted by the action is required')
    return new AuditItem({
      actor,
      action,
      subjectType: AuditSubject.Device,
      subjectId: device.id,
      targetId,
      targetType,
      details: details || device.serialNumber,
      changes
    })
  }

  /**
   * Creates an instance of user-related audit event
   */
  static forUser ({ actor, user, action, targetId, targetType, details, changes }) {
    if (!user) throw new Error('User impacted by the action is required')
    return new AuditItem({
      actor,
      action,
      subjectType: AuditSubject.User,
      subjectId: user.id,
      targetId,
      targetType,
      details: details || user.name,
      changes
    })
  }

  /**
   * Creates an instance of organization-related audit event
   */
  static forOrganization ({ actor, organization, action, targetId, targetType, details, changes }) {
    if (!organization) throw new Error('Organization impacted by the action is required')
    return new AuditItem({
      actor,
      action,
      subjectType: AuditSubject.Organization,
      subjectId: organization.id,
      targetId,
      targetType,
      details: details || organization.name,
      changes
    })
  }

  /**
   * Creates an instance of organization-profile-related audit event
   */
  static forOrganizationProfile ({ actor, organizationProfile, action, targetId, targetType, details, changes }) {
    if (!organizationProfile) throw new Error('Organization profile impacted by the action is required')
    return new AuditItem({
      actor,
      action,
      subjectType: AuditSubject.OrganizationProfile,
      subjectId: organizationProfile.id,
      targetId,
      targetType,
      details: details || organizationProfile.name,
      changes
    })
  }

  /**
   * Creates an instance of place-related audit event
   */
  static forPlace ({ actor, place, action, targetId, targetType, details, changes }) {
    if (!place) throw new Error('Place impacted by the action is required')
    return new AuditItem({
      actor,
      action,
      subjectType: AuditSubject.Place,
      subjectId: place.id,
      targetId,
      targetType,
      details: details || place.name,
      changes
    })
  }

  /**
   * Creates an instance of floor-plan-related audit event
   */
  // TODO Obsolete, remove
  static forFloorPlan ({ actor, floorPlan, action, targetId, targetType, details, changes }) {
    if (!floorPlan) throw new Error('Floor plan impacted by the action is required')
    return new AuditItem({
      actor,
      action,
      subjectType: AuditSubject.FloorPlan,
      subjectId: floorPlan.id,
      targetId,
      targetType,
      details: details || floorPlan.name,
      changes
    })
  }

  /**
   * Creates an instance of alert-configuration-related audit event
   */
  static forAlertConfiguration ({ actor, alertConfiguration, action, targetId, targetType, details, changes }) {
    if (!alertConfiguration) throw new Error('Alert configuration impacted by the action is required')
    return new AuditItem({
      actor,
      action,
      subjectType: AuditSubject.AlertConfiguration,
      subjectId: alertConfiguration.deviceId,
      targetId,
      targetType,
      details: details || alertConfiguration.alertType,
      changes
    })
  }

  /**
   * Creates an instance of premium-service-related audit event
   */
  static forPremiumService ({ actor, premiumService, action, targetId, targetType, details, changes }) {
    if (!premiumService) throw new Error('Premium service impacted by the action is required')
    return new AuditItem({
      actor,
      action,
      subjectType: AuditSubject.PremiumService,
      subjectId: premiumService.id,
      targetId,
      targetType,
      details: details || premiumService.label,
      changes
    })
  }

  /**
   * Creates an instance of premium-service-subscription-related audit event
   */
  static forPremiumServiceSubscription ({ actor, subscription, action, targetId, targetType, details, changes }) {
    if (!subscription) throw new Error('Premium service subscription impacted by the action is required')
    return new AuditItem({
      actor,
      action,
      subjectType: AuditSubject.PremiumServiceSubscription,
      subjectId: subscription.id,
      targetType: targetType || AuditSubject.Organization,
      targetId: targetId || subscription.walletId,
      details: details || subscription.label,
      changes
    })
  }

  /**
   * Creates an instance of wallet-related audit event
   */
  static forWallet ({ actor, wallet, action, targetId, targetType, details, changes }) {
    if (!wallet) throw new Error('Wallet impacted by the action is required')
    return new AuditItem({
      actor,
      action,
      subjectType: AuditSubject.Wallet,
      subjectId: wallet.id,
      targetId,
      targetType,
      details,
      changes
    })
  }

  /**
   * Creates an instance of device-firmware-related audit event
   */
  static forDeviceFirmware ({ actor, deviceFirmware, action, targetId, targetType, details, changes }) {
    if (!deviceFirmware) throw new Error('Device firmware impacted by the action is required')
    return new AuditItem({
      actor,
      action,
      subjectType: AuditSubject.DeviceFirmware,
      subjectId: deviceFirmware.id,
      targetId,
      targetType,
      details: details || deviceFirmware.label,
      changes
    })
  }

  /**
   * Creates an instance of upload-job-related audit event
   */
  static forUploadJob ({ actor, uploadJob, action, targetId, targetType, details, changes }) {
    if (!uploadJob) throw new Error('Upload job impacted by the action is required')
    return new AuditItem({
      actor,
      action,
      subjectType: AuditSubject.UploadJob,
      subjectId: uploadJob.id,
      targetId,
      targetType,
      details: details || uploadJob.type,
      changes
    })
  }

  /**
   * Creates an instance of notification-related audit event
   */
  static forNotification ({ actor, notification, action, targetId, targetType, details, changes }) {
    if (!notification) throw new Error('Notification impacted by the action is required')
    return new AuditItem({
      actor,
      action,
      subjectType: AuditSubject.Notification,
      subjectId: notification.id,
      targetId: targetId || notification.subjectId,
      targetType: targetType || notification.subjectType,
      details: details || notification.source,
      changes
    })
  }

  /**
   * Creates an instance of email-related audit event
   */
  static forMailMessage ({ actor, message, targetId, targetType, details }) {
    if (!message) throw new Error('E-mail message involved in the action is required')
    return new AuditItem({
      actor,
      action: AuditAction.SendMail,
      subjectType: AuditSubject.EMail,
      subjectId: message.from,
      targetType: targetType || AuditSubject.EMail,
      targetId: targetId || message.getRecipients().join(','),
      details: details || message.subject
    })
  }

  /**
   * Creates an instance of page-view audit event
   */
  static forPageView ({ actor, page, url, details }) {
    if (!url) throw new Error('Page URL involved in the action is required')
    return new AuditItem({
      actor,
      action: AuditAction.PageViewed,
      subjectType: AuditSubject.Page,
      subjectId: page || 'home',
      details: details || url
    })
  }
}
