/**
 * Navigation failures
 */
export const NavigationFailureType = {
  notfound: 1,
  redirected: 2,
  aborted: 4,
  cancelled: 8,
  duplicated: 16
}

export const NavigationFailureName = {
  [NavigationFailureType.notfound]: 'notfound',
  [NavigationFailureType.redirected]: 'redirected',
  [NavigationFailureType.aborted]: 'aborted',
  [NavigationFailureType.cancelled]: 'cancelled',
  [NavigationFailureType.duplicated]: 'duplicated'
}

/**
 * Navigation failures deemed as non-critical
 */
export const NonCriticalNavigationFailures = [
  NavigationFailureType.notfound,
  NavigationFailureType.duplicated,
  NavigationFailureType.cancelled,
  NavigationFailureType.redirected
]

export const NonCriticalNavigationErrorMessages = [
  'route not found',
  'redundant navigation',
  'navigation cancelled',
  'navigation duplicated',
  'navigation redirected'
]

/**
 * Checks whether the specified exception represents a router navigation failure
 * @param {Error} error Router exception
 * @param {NavigationFailureType} type Failure type to check, optional
 * @returns True, if specified error is router navigation failure, optionally of the specified type
 */
export function isNavigationFailure (error, type) {
  return error && error.from && error.to && (type ? error.type === type : true)
}

/**
 * Checks whether the specified exception represents
 * a route-not-found router navigation failure
 * @param {Error} error Router exception
 * @returns True, if specified error is a non-critical router navigation failure
 */
export function isRouteNotFoundNavigationFailure (error) {
  return error.type === NavigationFailureType.notfound
}

/**
 * Checks whether the specified exception represents
 * a non-critical router navigation failure
 * @param {Error} error Router exception
 * @returns True, if specified error is a non-critical router navigation failure
 */
export function isNonCriticalNavigationFailure (error) {
  if (isNavigationFailure(error)) {
    const isNonCriticalFailure = NonCriticalNavigationFailures.some(type => type === error.type) ||
      NonCriticalNavigationErrorMessages.some(m => error.message.toLowerCase().includes(m))
    return isNonCriticalFailure
  } else {
    return false
  }
}

/**
 * Checks whether the specified exception represents a router navigation failure
 * and returns its details
 * @param {Error} error Router exception
 * @param {NavigationFailureType} type Failure type to check, optional
 * @returns Details of navigation failure, if specified error is router navigation failure, optionally of the specified type
 */
export function getNavigationFailure (error, type) {
  if (isNavigationFailure(error, type)) {
    return {
      type: error.type,
      name: NavigationFailureName[error.type],
      message: error.message,
      from: error.from,
      to: error.to
    }
  }
}
