import { safeParseInt, parseBoolean } from '@stellacontrol/utilities'
import { Assignable } from '@stellacontrol/model'

/**
 * Properties of arrows at the end of a line
 */
export class PlanArrowStyle extends Assignable {
  constructor (data = {}) {
    super(data)
    this.assign(data)
  }

  /**
   * Creates style representing no arrows
   * @type {PlanArrowStyle}
   */
  static get None () {
    return new PlanArrowStyle()
  }

  /**
   * Object defaults
   */
  get defaults () {
    return {
      width: 0,
      height: 0,
      start: false,
      end: false
    }
  }

  normalize () {
    super.normalize()
    const { defaults } = this
    this.width = Math.max(0, safeParseInt(this.width, defaults.width))
    this.height = Math.max(0, safeParseInt(this.height, defaults.height))
    this.start = parseBoolean(this.start, defaults.start)
    this.end = parseBoolean(this.end, defaults.end)
  }

  /**
   * Serializes the item to JSON
   * @returns {Object}
   */
  toJSON () {
    const result = { ...this }
    return this.clearDefaults(result)
  }

  /**
   * Checks if style is the same as the specified one
   * @param {PlanArrowStyle} style
   * @returns {Boolean}
   */
  sameAs (style) {
    if (style) {
      return this.width === style.width &&
        this.height === style.height &&
        this.start === style.start &&
        this.end === style.end
    }
  }

  /**
   * Pointer width, in pixels
   * @type {Number}
   */
  width

  /**
   * Pointer height, in pixels
   * @type {Number}
   */
  height

  /**
   * Indicates whether the arrow should be rendered at the start of the line
   * @type {Boolean}
   */
  start

  /**
   * Indicates whether the arrow should be rendered at the end of the line
   * @type {Boolean}
   */
  end

  /**
   * Returns true if style is not defined
   * @type {Boolean}
   */
  get isEmpty () {
    return !(this.start || this.end)
  }
}
