import { PlanAction, PlanActions } from './plan-action'

/**
 * Empty action, useful for testing etc.
 */
export class EmptyAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.Empty
  }

  /**
   * Action label
   * @type {String}
   */
  get label () {
    return 'Empty action'
  }

  /**
   * Indicates that the action requires items to act on
   * @type {Boolean}
   */
  get requiresItems () {
    return false
  }

  /**
   * Indicates whether action requires refresh
   * @type {Boolean}
   */
  get requiresRefresh () {
    return false
  }

  /**
   * If true, the current selection will be preserved
   * after the action has been executed
   * @type {Boolean}
   */
  get preserveSelection () {
    return true
  }

  /**
   * Executes the action
   */
  execute () {
  }

  /**
   * Undoes the action
   */
  async undo () {
  }

  /**
   * Redoes the action
   */
  async redo () {
  }
}
