import { MessageType } from '../messaging/message-type'

/**
 * Device flags, determining its behavior on the platform
 */
export class DeviceFlags {
  constructor (data = {}) {
    Object.assign(this, data)
    this.debug = data.debug == null
      ? undefined
      : data.debug instanceof Date ? data.debug : new Date(Date.parse(data.debug))
    this.profile = data.profile == null
      ? undefined
      : data.profile instanceof Date ? data.profile : new Date(Date.parse(data.profile))
  }

  /**
   * List of available flags
   */
  static FLAGS = [
    { name: 'blockStatus', isBoolean: true },
    { name: 'blockSettings', isBoolean: true },
    { name: 'blockCommands', isBoolean: true },
    { name: 'debug', isBoolean: false },
    { name: 'profile', isBoolean: false }
  ]

  /**
   * Device identifier
   * @type {String}
   */
  id

  /**
   * Device serial number
   * @type {String}
   */
  serialNumber

  /**
   * If true, incoming device status messages will be ignored.
   * @type {Boolean}
   */
  blockStatus

  /**
   * If true, processing of outgoing device commands will be disabled.
   * @type {Boolean}
   */
  blockCommands

  /**
   * If true, processing of device settings changes will be disabled.
   * @type {Boolean}
   */
  blockSettings

  /**
   * If specified, deep logging of incoming traffic is turned on until the specified time
   * @type {Date}
   */
  debug

  /**
   * If specified, profiling of message processing is turned on until the specified time
   * @type {Date}
   */
  profile

  /**
   * Checks whether flags are empty
   */
  get isEmpty () {
    return DeviceFlags.FLAGS.every(({ name }) => this[name] == null)
  }

  /**
   * Clears all flags
   */
  clear () {
    for (const { name } of DeviceFlags.FLAGS) {
      delete this[name]
    }
  }

  /**
   * Toggles the specified flags
   * @param {String} name Flag name
   */
  toggle (name) {
    const flag = DeviceFlags.FLAGS.find(f => f.name === name)
    if (flag && flag.isBoolean) {
      this[name] = !this[name]
    }
  }

  /**
   * Sets the specified flag to a given value
   * @param {String} name Flag name
   */
  set (name, value) {
    const flag = DeviceFlags.FLAGS.find(f => f.name === name)
    if (flag) {
      if (flag.isBoolean) {
        this[name] = Boolean(value)
      } else {
        this[name] = value
      }
    }
  }

  /**
   * Returns string representation of the flags
   * @returns {String}
   */
  toString () {
    const lines = []
    const { blockStatus, blockSettings, blockCommands, isDebugging, isProfiling } = this

    const add = (name, value) => {
      if (value != null) {
        if (typeof value === 'boolean') {
          lines.push(`${name}: ${value ? 'YES' : 'NO'}`)
        } else {
          lines.push(`${name}: ${value.toString()}`)

        }
      }
    }

    add('Receive Status', !blockStatus)
    add('Receive Settings', !blockSettings)
    add('Receive Commands', !blockCommands)
    add('Debugging', isDebugging)
    add('Profiling', isProfiling)

    return lines.join(', ')
  }

  /**
   * Checks whether the specified message type is accepted for the device,
   * as per currently set flags
   * @param {MessageType} messageType
   * @returns {Boolean}
   */
  isMessageAccepted (messageType) {
    switch (messageType) {
      case MessageType.DeviceStatus:
        return this.blockStatus !== true
      case MessageType.DeviceSettings:
        return this.blockSettings !== true
      case MessageType.DeviceCommand:
        return this.blockCommands !== true
    }
    return true
  }

  /**
   * Checks whether logging of message traffic should be active for the device
   * @returns {Boolean}
   */
  get isDebugging () {
    const { debug } = this
    return debug != null && debug >= new Date()
  }

  /**
   * Checks whether profiling of message processing should be active for the device
   * @returns {Boolean}
   */
  get isProfiling () {
    const { profile } = this
    return profile != null && profile >= new Date()
  }
}
