import { getStore } from '../get-store'
import { Log } from '@stellacontrol/utilities'

/**
 * Dispatches the specified action
 * @param name Action to dispatch
 * @param parameters Action parameters
 * @returns Results of the dispatched action
 */
export async function dispatch (name, parameters) {
  const store = getStore()
  return await store.dispatch(name, parameters)
}

/**
 * Dispatches the specified action
 * and intercepts errors
 * @description
 * On exception it will calls the error handler if provided,
 * otherwise it will log the exception and return gracefully
 * @param name Action to dispatch
 * @param parameters Action parameters
 * @returns Object containing either the result of the dispatched action
 * or eventual error captured while executing the action
 */
export async function tryDispatch (name, parameters, errorHandler) {
  try {
    const store = getStore()
    const result = await store.dispatch(name, parameters)
    return { result }
  } catch (error) {
    if (errorHandler) {
      errorHandler(error)
    } else {
      Log.exception(error)
    }
    return { error }
  }
}
