import { Routes as loginRoutes } from '../views/login'
import { Routes as myAccountRoutes } from '../views/my-account'
import { Routes as resetPasswordRoutes } from '../views/reset-password'
import { Routes as activateAccountRoutes } from '../views/activate-account'
import { Routes as organizationProfileRoutes } from '../views/organization-profile'
import { Routes as organizationRoutes } from '../views/organization'
import { Routes as userRoutes } from '../views/user'
import { Routes as preferencesRoutes } from '../views/preferences'
import { Routes as organizationPlaceRoutes } from '../views/organization-place'

/**
 * Routes defined in this applet
 */
export const AppletRoutes = [
  ...loginRoutes,
  ...myAccountRoutes,
  ...resetPasswordRoutes,
  ...activateAccountRoutes,
  ...organizationProfileRoutes,
  ...organizationRoutes,
  ...userRoutes,
  ...preferencesRoutes,
  ...organizationPlaceRoutes
]

/**
 * Enumeration of route names
 */
export const AppletRoute = {
  Login: 'login',
  MyAccount: 'my-account',
  ResetPassword: 'reset-password',
  ActivateAccount: 'activate-account',
  Organizations: 'organizations',
  Organization: 'organization',
  OrganizationProfiles: 'organization-profiles',
  OrganizationProfile: 'organization-profile',
  Users: 'users',
  User: 'user',
  OrganizationPlace: 'organization-place'
}
