import { PushClient, DevicesPushClient } from './push-client'

/**
 * Generic Push Client instance
 */
export const Push = new PushClient()

/**
 * Devices Push Client instance
 */
export const DevicesPush = new DevicesPushClient()
