import { safeParseFloat, round } from '@stellacontrol/utilities'
import { Assignable } from '@stellacontrol/model'

/**
 * Plan scale
 */
export class PlanScale extends Assignable {
  constructor (data = {}) {
    super(data)
    if (data.x != null || data.y != null || data.z != null) {
      this.assign(data)
    } else {
      this.assign({ x: data, y: data, z: data })
    }
  }

  /**
   * Returns a 1:1 scale
   * @returns {PlanScale}
   */
  static get Normal () {
    return new PlanScale({ x: 1, y: 1, z: 1 })
  }

  /**
   * Creates a scale from the specified one
   * @param {PlanScale|Number} value Initial value
   * @returns {PlanScale}
   */
  static from (value) {
    return new PlanScale(value)
  }

  /**
   * Object defaults
   */
  get defaults () {
    return {
      x: 1,
      y: 1,
      z: 1
    }
  }

  normalize () {
    super.normalize()
    const { defaults } = this
    this.x = round(safeParseFloat(this.x, defaults.x), 3)
    this.y = round(safeParseFloat(this.y, defaults.y), 3)
    this.z = round(safeParseFloat(this.z, defaults.z), 3)
  }

  /**
   * Serializes the item to JSON
   * @returns {Object}
   */
  toJSON () {
    const result = { ...this }
    return this.clearDefaults(result)
  }

  /**
   * String representation of the scale
   * @returns {String}
   */
  toString () {
    return this.value?.toString()
  }

  /**
   * X scale
   * @type {Number}
   */
  x

  /**
   * Y scale
   * @type {Number}
   */
  y

  /**
   * Z scale
   * @type {Number}
   */
  z

  /**
   * Unified scale on all axes
   * @type {Number}
   */
  get value () {
    const { x } = this
    return x
  }
  set value (value) {
    this.x = value
    this.y = value
    this.z = value
  }

  /**
   * Returns `true` when plan scale is 'normal'
   * @type {Boolean}
   */
  get isNormal () {
    return this.value === 1
  }

  /**
   * Returns `true` when plan scale has changed from `1`
   * @type {Boolean}
   */
  get isChanged () {
    return this.value !== 1
  }

  /**
   * Unified ratio on all axes, in percent
   * @type {Number}
   */
  get ratio () {
    const { value } = this
    return Math.round(100 * (value / 1))
  }

  /**
   * Returns the inverse of the scale
   * @returns {PlanScale}
   */
  inverted () {
    return new PlanScale({
      x: this.x ? 1 / this.x : 1,
      y: this.y ? 1 / this.y : 1,
      z: this.z ? 1 / this.z : 1
    })
  }

  /**
   * Resets the scale to 1
   */
  reset () {
    this.x = 1
    this.y = 1
    this.z = 1
  }

  /**
   * Checks whether the scale is the same as the specified one
   * @param {PlanScale} value Plan scale to compare with
   * @returns {Boolean}
   */
  sameAs (value) {
    const { x, y, z } = value || {}

    return this.x === x &&
      this.y === y &&
      this.z === z
  }

  /**
   * Rounds the scale to the specified precision
   * @param {Number} digits Number of decimal digits to round to
   * @returns {PlanScale}
   */
  round (digits = 3) {
    this.x = this.x == null ? null : round(this.x, digits)
    this.y = this.y == null ? null : round(this.y, digits)
    this.z = this.z == null ? null : round(this.z, digits)
    return this
  }

  /**
   * Adds the specified scale to the existing scale
   * (multiplication operation)
   * @param {PlanScale|Number} scale Scale to add
   * @returns {PlanScale}
   */
  add (scale) {
    scale = PlanScale.from(scale)
    this.x = this.x == null ? null : this.x * scale.x
    this.y = this.y == null ? null : this.y * scale.y
    this.z = this.z == null ? null : this.z * scale.z
    return this
  }

  /**
   * Subtracts the specified scale from the existing scale
   * (division operation)
   * @param {PlanScale|Number} scale Scale to subtract
   * @returns {PlanScale}
   */
  subtract (scale) {
    scale = PlanScale.from(scale)
    this.x = this.x == null ? null : this.x / scale.x
    this.y = this.y == null ? null : this.y / scale.y
    this.z = this.z == null ? null : this.z / scale.z
    return this
  }

  /**
   * Returns the scale which does not exceed the specified one
   * @param {PlanScale} scale Scale to limit
   * @param {PlanScale|Number} limit Scale limit
   * @returns {PlanScale}
   */
  static min (scale, limit) {
    limit = PlanScale.from(limit)
    const x = Math.min(limit.x, scale.x)
    const y = Math.min(limit.y, scale.y)
    const z = Math.min(limit.z, scale.z)
    return new PlanScale({ x, y, z })
  }

  /**
   * Returns the scale which is at least as big as the specified one
   * @param {PlanScale} scale Scale to limit
   * @param {PlanScale|Number} limit Scale limit
   * @returns {PlanScale}
   */
  static max (scale, limit) {
    limit = PlanScale.from(limit)
    const x = Math.max(limit.x, scale.x)
    const y = Math.max(limit.y, scale.y)
    const z = Math.max(limit.z, scale.z)
    return new PlanScale({ x, y, z })
  }

  /**
  * Checks whether the unified scale has the specified value
  * @param {Number} value Scale to compare to
  * @returns {Boolean}
  */
  equals (value) {
    return this.value === value
  }
}
