<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { sameArrays } from '@stellacontrol/utilities'
import { Exporter } from '@stellacontrol/client-utilities'
import { SortOrder } from '@stellacontrol/model'
import { ViewMixin } from '@stellacontrol/client-utilities'
import { Secure } from '@stellacontrol/security-ui'
import DevicePanelComponents from './device-panel'
import PopupComponents from './popups'
import HeaderComponents from './headers'
import CellComponents from './cells'

const name = 'inventory'

export default {
  mixins: [
    ViewMixin,
    Secure
  ],

  components: {
    ...DevicePanelComponents,
    ...PopupComponents,
    ...HeaderComponents,
    ...CellComponents
  },

  data () {
    return {
      // View name
      name,
      // List of serials of devices currently watched for status
      watchedDevices: [],
      // Grid pagination settings
      pagination: {
        sortBy: 'serialNumber',
        descending: false,
        page: 1,
        rowsPerPage: 50
      }
    }
  },

  computed: {
    ...mapGetters([
      'configuration',
      'currentRoute',
      'organizationGuardian',
      'myWallet',
      'devices',
      'visibleInventoryColumns',
      'getStatusWatchSettings',
      'isRecentlyAddedDevice'
    ]),

    ...mapState({
      // Inventory grid is reloading
      isReloading: state => state.inventoryView.isReloading,
      // Grid options
      options: state => state.inventoryView.gridOptions,
      // Grid columns
      columns: state => state.inventoryView.gridColumns,
      columnsList: state => state.inventoryView.gridColumnsList,
      // All tag definitions
      deviceTags: state => state.inventoryView.deviceTags,
      deviceTagsList: state => state.inventoryView.deviceTagsList,
      // Device items
      items: state => state.inventoryView.items || [],
      // Indicates whether the action panel is currently visible.
      // Note: defined as function to allow access to 'this' (but Vue compiler would warn about this anyway ;-)
      isDevicePanelVisible (state) {
        return this.hasSelectedItems && state.devicePanel.isVisible
      },
      // Indicates whether the action panel is currently collapsed
      isDevicePanelCollapsed: state => state.devicePanel.isCollapsed
    }),

    // Dictionary of visible grid columns
    visibleColumns () {
      return this.columnsList.reduce((all, column) => {
        all[column.name] = column.isAllowed && column.isVisible
        return all
      }, {})
    },

    // Grid column definitions for q-table component
    columnDefinitions () {
      return Object.entries(this.columns)
        .map(([name, column]) => ({
          name,
          label: column.label,
          sortable: true,
          field: 'device',
          format: (device, item) => column.text ? column.text(item) : device[name]
        }))
    },

    // Indicates whether any devices are available
    hasItems () {
      return this.items.length > 0
    },

    // Returns true if some devices have been selected
    hasSelectedItems () {
      return this.items.some(item => item.isSelected)
    },

    // Returns true if just one device have been selected
    isOneItemSelected () {
      return this.items.filter(item => item.isSelected).length === 1
    },

    // Items remaining visible after applying the filters
    visibleItems () {
      return this.items.filter(item => item.isVisible)
    },

    // Items on the current page
    currentPageItems () {
      const { $refs, pagination, visibleItems } = this
      const { spreadsheet } = $refs

      if (spreadsheet) {
        const { page, rowsPerPage } = pagination
        const startIndex = (page - 1) * rowsPerPage
        const endIndex = startIndex + rowsPerPage
        const rows = spreadsheet.filteredSortedRows || visibleItems || []
        return rows.slice(startIndex, endIndex)
      } else {
        return []
      }
    },

    // Selected items
    selectedItems () {
      return this.visibleItems
        .filter(item => item.isSelected)
    },

    // Devices associated with the selected items
    selectedDevices () {
      return this.selectedItems
        .map(item => item.device)
    },

    // Devices visible on the current page (after eventual filters have been applied)
    visibleDevices () {
      return this.currentPageItems.map(item => item.device)
    },

    visibleSerials () {
      return this.currentPageItems.map(item => item.serialNumber)
    },

    // Checks whether any devices are currently being updated with a new firmware
    hasUploadsInProgress () {
      return this.visibleItems.some(item => item.device.firmwareUpdateInProgress)
    },

    // Current sort column
    sortBy () {
      return this.options.sortBy
    },

    // Current sort order
    sortOrder () {
      return this.options.sortOrder
    },

    // Available page size values for pagination
    pageSizes () {
      const { options: { rowsPerPageItems } } = this
      const pageSizeLimit = 100
      return rowsPerPageItems.filter(value => (value === 0 || value > pageSizeLimit) ? this.isSuperOrganization : true)
    },

    // Current page
    currentPageNumber () {
      return this.pagination.page
    },

    // Current page row count
    currentRowCount () {
      return this.pagination.rowsPerPage
    },

    // Indication of what's in the wallet of the current organization
    walletLabel () {
      const { myWallet, requiresPremiumSubscriptions } = this
      if (myWallet && requiresPremiumSubscriptions) {
        return myWallet.balanceDescription
      }
    },

    // Color indication of wallet state
    walletColor () {
      const { myWallet, requiresPremiumSubscriptions } = this
      if (myWallet && requiresPremiumSubscriptions) {
        return myWallet.balance > 0
          ? 'success'
          : 'warning'
      }
    },

    // Indicates whether current user can purchase tokens
    canPurchaseTokens () {
      const { isSuperAdministrator, requiresPremiumSubscriptions } = this
      return isSuperAdministrator && requiresPremiumSubscriptions
    }
  },

  watch: {
    // When sort order or direction has changed, re-sort the table.
    // Unfortunately this cannot be done on state store alone,
    // as sorting is performed by calling a method on q-table component.
    sortBy () {
      this.sort()
    },

    sortOrder () {
      this.sort()
    },

    // Triggered when pagination changed.
    // If page size or number was changed,
    // we want to refresh device status,
    // as new devices might have come in sight
    pagination ({ page, rowsPerPage }, oldValue) {
      const needsStatusRefresh = oldValue.rowsPerPage != rowsPerPage || oldValue.page != page
      if (needsStatusRefresh) {
        this.paginateInventory({ rowsPerPage })
      }
    },

    // Selected items
    async selectedItems (current, previous) {
      if (!sameArrays(current, previous, 'serialNumber')) {
        const { selectedItems: items, selectedDevices: devices } = this
        await this.inventorySelectionChanged({ items, devices })
      }
    },

    // Currently visible devices on page have changed
    visibleSerials () {
      // Reinitialize status watcher to only watch the currently visible devices
      this.watchStatus()
    }
  },

  methods: {
    ...mapMutations([
      'openActionPanelTab',
      'selectWholeInventory',
      'deselectInventoryItems',
      'selectInventoryItem',
      'selectInventoryItems',
      'selectInventoryRange',
      'invalidateDevices'
    ]),
    ...mapActions([
      'goBack',
      'updateRoute',
      'setRouteData',
      'showDialog',
      'initializeInventoryView',
      'restoreInventoryLayout',
      'inventorySelectionChanged',
      'addDevicesToInventory',
      'importDevicesToInventory',
      'getLiveStatus',
      'watchDeviceStatus',
      'unwatchDeviceStatus',
      'watchUploadStatus',
      'unwatchUploadStatus',
      'paginateInventory',
      'selectInventory',
      'inventoryActionExecuted',
      'exportDeviceStatus'
    ]),

    // Goes back to the previous view
    async close () {
      await this.goBack()
    },

    // Starts watchihg status updates and firmware upload updates
    watchStatus () {
      const { visibleDevices: devices } = this
      const devicesToWatch = devices.map(d => d.serialNumber)
      const devicesChanged = !sameArrays(this.watchedDevices, devicesToWatch)
      if (devicesChanged && devicesToWatch.length > 0) {
        this.watchedDevices = devicesToWatch
        // Start watching device status updates
        this.watchDeviceStatus({ name, devices })
        // Start monitoring firmware update status periodically
        this.watchUploadStatus({ interval: 10, hasUploadsInProgress: () => this.hasUploadsInProgress })
      }
    },

    // Stops watching status updates and firmware upload updates
    async unwatchStatus () {
      if (this.watchedDevices.length > 0) {
        await this.unwatchDeviceStatus({ name })
        await this.unwatchUploadStatus()
        this.watchedDevices = []
      }
    },

    // Triggered when device has been toggled as current user's favorite.
    // We need to force-sort the grid now, as favorites go to the top of the grid.
    toggleFavorite () {
      this.sort()
    },

    // Selects an item, deselecting others.
    selectItem (item, deselectOthers) {
      if (deselectOthers) {
        this.deselectInventoryItems()
      }
      this.selectInventoryItem({ item })
      this.updateRoute({ query: { selection: item ? item.serialNumber : undefined } })
    },

    // Returns style for a column header
    getHeaderStyle (column) {
      const style = {
        'min-width': column.width,
        'max-width': column.width,
        'width': column.width
      }
      return style
    },

    // Returns style for a column
    getColumnStyle (column) {
      return {
        'min-width': column.width,
        'max-width': column.width
      }
    },

    // Forces re-sorting the table by the currently selected sort column
    async sort () {
      const { spreadsheet } = this.$refs
      if (spreadsheet && this.hasItems) {
        const { sortBy } = this
        await spreadsheet.sort(sortBy)
      }
    },

    // Handler for sorting table rows
    sortHandler (rows) {
      const data = [...rows]
      const { columns, sortOrder, sortBy } = this
      if (sortBy) {
        const descending = sortOrder === SortOrder.Descending
        data.sort((a, b) => {
          if (a.isFavorite && !b.isFavorite) {
            return -1
          } else if (b.isFavorite && !a.isFavorite) {
            return 1
          } else {
            const x = descending ? b : a
            const y = descending ? a : b
            return x.compareTo(y, columns[sortBy])
          }
        })
      }

      return data
    },

    // Export the current grid content to CSV
    exportGrid () {
      const { columns, visibleColumns, visibleItems } = this

      // get currently visible columns
      const exportableColumns = Object
        .entries(columns)
        .filter(([name, column]) => visibleColumns[name] && column.isExportable !== false)
        .map(([name, column]) => ({ name, ...column }))

      // Construct a header
      const separator = ','
      const header = exportableColumns
        .map(column => column.label)
        .join(separator)

      // Get currently visible items, pick data from the visible columns
      const lines = visibleItems.map(item => {
        return exportableColumns
          .map(column => column.text ? column.text(item) : item.device[column.name])
          .map(value => !value || value === '-' ? '' : value)
          .map(value => value.replace(/\n/g, ' '))
          .join(separator)
      })

      // Export to a CSV file
      const text = [header, ...lines].join('\n')
      const fileName = 'inventory.csv'
      Exporter.toFile(text, fileName)
    },

    // Export live status of all devices to CSV
    async exportDevices () {
      const lines = await this.exportDeviceStatus()
      const text = lines.join('\n')
      const fileName = 'device-status.csv'
      Exporter.toFile(text, fileName)
    },

    /**
     * Refreshes the devices in the grid
     * @param preserveSelection If true, currently selected devices will be kept selected
     */
    async refreshDevices ({ preserveSelection } = {}) {
      await this.unwatchStatus()

      const items = preserveSelection ? [...this.selectedDevices] : []
      await this.invalidateDevices()
      await this.initializeInventoryView()
      await this.selectInventoryItems({ items })
      const selection = items.length > 0 ? items.map(i => i.serialNumber).join(',') : undefined
      await this.updateRoute({ query: { selection } })

      this.$nextTick(async () => {
        await this.sort()
        this.watchStatus()
      })
    },

    // Closes device panel
    async closeDevicePanel ({ refresh } = {}) {
      await this.deselectInventoryItems()
      await this.clearInitialSelection()
      if (refresh) {
        await this.refreshDevices()
      }
    },

    // Triggered when action has been executed in device panel
    async actionExecuted ({ action, devices, refresh } = {}) {
      if (refresh) {
        await this.refreshDevices({ preserveSelection: true })
      }
      await this.inventoryActionExecuted({ devices, action })
    },

    // Triggered when item has been selected or deselected
    async itemSelected ({ item, isSelected, multiSelect } = {}) {
      // Select range
      if (multiSelect) {
        await this.selectInventoryRange({
          items: this.currentPageItems,
          endItem: item,
          isSelected
        })
      }

      // Update the selection in URL
      await this.setInitialSelection(this.selectedItems.map(i => i.serialNumber))
    },

    // Clears initial selection from route query
    async clearInitialSelection () {
      const initialSelection = this.currentRoute.query.selection || []
      if (initialSelection.length > 0) {
        await this.updateRoute({ query: { selection: undefined } })
      }
    },

    // Assigns initial selection to route query
    async setInitialSelection (selection = []) {
      const currentSelection = this.currentRoute.query.selection || []
      if (!sameArrays(selection, currentSelection)) {
        if (selection.length > 0) {
          await this.updateRoute({ query: { selection: selection.join(',') } })
        } else {
          await this.updateRoute({ query: { selection: undefined } })
        }
      }
    },

    // Opens dialog for purchasing tokens
    purchaseTokens () {
      const { currentOrganization: customer } = this
      this.showDialog({ dialog: 'purchase-tokens', data: { customer } })
    }
  },

  async created () {
    // Prepare the grid
    await this.restoreInventoryLayout()
    this.pagination.rowsPerPage = this.options.rowsPerPage

    // When loading, pass on eventual selection specified in route parameters
    await this.initializeInventoryView({
      selection: this.data.selection,
      organizationId: this.data.organizationId,
      placeId: this.data.placeId,
      preserveSelection: !this.data.selection?.length > 0,
      filterBySelection: this.data.filterBySelection
    })

    // Load device status for devices visible on the current page...
    // Refs are available only after render while they're needed for sorting the grid,
    // so we need to push it ahead a bit...
    this.$nextTick(async () => {
      await this.sort()
    })
  },

  // Stop watching status when leaving the view
  async beforeUnmount () {
    await this.unwatchStatus()
  },

  // Reload data on navigation
  async beforeRouteUpdate (to, from) {
    this.watchedDevices = []
    const selection = (to.query.selection || '').split(',')
    const organizationId = to.query.organization
    const placeId = to.query.place

    if (selection.length > 0 || organizationId || placeId) {
      const data = { selection, organizationId, placeId }
      await this.setRouteData({ from, to, data })
      await this.selectInventory({ selection, organizationId, placeId })
    }
  }
}

</script>

<template>
  <sc-view :name="name">
    <template #toolbar>
      <div class="q-gutter-sm">
        <q-btn v-if="canPurchaseTokens" class="q-mr-lg" :class="walletColor"
          :label="walletLabel" icon="payments" unelevated @click="purchaseTokens()">
          <sc-tooltip>Tokens are required to activate premium services on your devices. Click to
            purchase more tokens.</sc-tooltip>
        </q-btn>
        <q-btn label="Refresh" icon="refresh" unelevated @click="refreshDevices()"></q-btn>
        <q-btn-dropdown v-if="isSuperAdministrator" unelevated label="Export" :ripple="false">
          <q-list>
            <q-item clickable v-close-popup @click="exportGrid()">
              <q-item-section side>
                <q-icon name="grid_on" color="indigo-7" size="24px"></q-icon>
              </q-item-section>
              <q-item-section>
                <q-item-label>
                  Export Spreadsheet
                </q-item-label>
              </q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="exportDevices()">
              <q-item-section side>
                <q-icon name="sensors" color="green-7" size="24px"></q-icon>
              </q-item-section>
              <q-item-section>
                <q-item-label>
                  Export Device Status
                </q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
        <q-btn v-else label="Export" icon="get_app" unelevated @click="exportGrid()">
        </q-btn>
        <q-btn label="Add Devices" v-if="canUse('add-devices')" icon="add" unelevated class="success"
          @click="addDevicesToInventory()"></q-btn>
      </div>
    </template>

    <template #header></template>

    <div class="table-container" v-if="hasItems">
      <sc-columns-menu></sc-columns-menu>

      <q-table class="spreadsheet" ref="spreadsheet" flat dense separator="cell" row-key="id"
        binary-state-sort v-model:pagination="pagination" :rows="visibleItems"
        :columns="columnDefinitions" :sort-method="sortHandler" :rows-per-page-options="pageSizes"
        :loading="isReloading">
        <template v-slot:header>
          <q-tr>
            <q-th :style="getHeaderStyle(columns.isSelected)" v-if="visibleColumns.isSelected"
              style="padding: 2px; text-align: center;">
              <sc-is-selected-header :column="columns.isSelected"></sc-is-selected-header>
            </q-th>

            <q-th :style="getHeaderStyle(columns.tags)" v-if="visibleColumns.tags"
              style="padding: 2px; text-align: center;">
              <sc-tags-header :column="columns.tags"></sc-tags-header>
            </q-th>

            <q-th :style="getHeaderStyle(columns.isOnline)" v-if="visibleColumns.isOnline"
              style="padding: 2px; text-align: center;">
              <sc-icon-header :column="columns.isOnline" :iconStyle="{ 'margin-top': '4px', 'margin-left': '7px' }">
              </sc-icon-header>
              <sc-column-menu :column="columns.isOnline"></sc-column-menu>
            </q-th>

            <q-th :style="getHeaderStyle(columns.type)" v-if="visibleColumns.type">
              <sc-plain-text-header :column="columns.type"></sc-plain-text-header>
              <sc-column-menu :column="columns.type"></sc-column-menu>
            </q-th>

            <q-th :style="getHeaderStyle(columns.serialNumber)" v-if="visibleColumns.serialNumber">
              <sc-plain-text-header :column="columns.serialNumber"></sc-plain-text-header>
              <sc-column-menu :column="columns.serialNumber"></sc-column-menu>
            </q-th>

            <q-th :style="getHeaderStyle(columns.name)" v-if="visibleColumns.name">
              <sc-plain-text-header :column="columns.name"></sc-plain-text-header>
              <sc-column-menu :column="columns.name"></sc-column-menu>
            </q-th>

            <q-th :style="getHeaderStyle(columns.alerts)" v-if="visibleColumns.alerts">
              <sc-icon-header :column="columns.alerts"></sc-icon-header>
              <sc-column-menu :column="columns.alerts"></sc-column-menu>
            </q-th>

            <q-th :style="getHeaderStyle(columns.megaVersion)" v-if="visibleColumns.megaVersion">
              <sc-plain-text-header :column="columns.megaVersion"></sc-plain-text-header>
              <sc-column-menu :column="columns.megaVersion"></sc-column-menu>
            </q-th>

            <q-th :style="getHeaderStyle(columns.firmwareVersion)"
              v-if="visibleColumns.firmwareVersion">
              <sc-plain-text-header :column="columns.firmwareVersion"></sc-plain-text-header>
              <sc-column-menu :column="columns.firmwareVersion"></sc-column-menu>
            </q-th>

            <q-th :style="getHeaderStyle(columns.hardwareVersion)"
              v-if="visibleColumns.hardwareVersion">
              <sc-plain-text-header :column="columns.hardwareVersion"></sc-plain-text-header>
              <sc-column-menu :column="columns.hardwareVersion"></sc-column-menu>
            </q-th>

            <q-th :style="getHeaderStyle(columns.eepromVersion)" v-if="visibleColumns.eepromVersion">
              <sc-plain-text-header :column="columns.eepromVersion"></sc-plain-text-header>
              <sc-column-menu :column="columns.eepromVersion"></sc-column-menu>
            </q-th>

            <q-th :style="getHeaderStyle(columns.model)" v-if="visibleColumns.model">
              <sc-plain-text-header :column="columns.model"></sc-plain-text-header>
              <sc-column-menu :column="columns.model"></sc-column-menu>
            </q-th>

            <q-th :style="getHeaderStyle(columns.manufacturedAt)"
              v-if="visibleColumns.manufacturedAt">
              <sc-plain-text-header :column="columns.manufacturedAt"></sc-plain-text-header>
              <sc-column-menu :column="columns.manufacturedAt"></sc-column-menu>
            </q-th>

            <q-th :style="getHeaderStyle(columns.ownership)" v-if="visibleColumns.ownership">
              <sc-plain-text-header :column="columns.ownership"></sc-plain-text-header>
              <sc-column-menu :column="columns.ownership"></sc-column-menu>
            </q-th>

            <q-th :style="getHeaderStyle(columns.soldAt)" v-if="visibleColumns.soldAt">
              <sc-plain-text-header :column="columns.soldAt"></sc-plain-text-header>
              <sc-column-menu :column="columns.soldAt"></sc-column-menu>
            </q-th>

            <q-th :style="getHeaderStyle(columns.premiumServiceStatus)"
              v-if="visibleColumns.premiumServiceStatus">
              <sc-icon-header :column="columns.premiumServiceStatus"></sc-icon-header>
              <sc-column-menu :column="columns.premiumServiceStatus"></sc-column-menu>
            </q-th>

            <q-th :style="getHeaderStyle(columns.premiumService)"
              v-if="visibleColumns.premiumService">
              <sc-plain-text-header :column="columns.premiumService"></sc-plain-text-header>
              <sc-column-menu :column="columns.premiumService"></sc-column-menu>
            </q-th>

            <q-th :style="getHeaderStyle(columns.placeType)" v-if="visibleColumns.placeType">
              <sc-icon-header :column="columns.placeType"></sc-icon-header>
              <sc-column-menu :column="columns.placeType"></sc-column-menu>
            </q-th>

            <q-th :style="getHeaderStyle(columns.place)" v-if="columns.place.isVisible">
              <sc-plain-text-header :column="columns.place"></sc-plain-text-header>
              <sc-column-menu :column="columns.place"></sc-column-menu>
            </q-th>

            <q-th :style="getHeaderStyle(columns.location)" v-if="visibleColumns.location">
              <sc-plain-text-header :column="columns.location"></sc-plain-text-header>
              <sc-column-menu :column="columns.location"></sc-column-menu>
            </q-th>

            <q-th :style="getHeaderStyle(columns.notes)" v-if="visibleColumns.notes">
              <sc-plain-text-header :column="columns.notes"></sc-plain-text-header>
              <sc-column-menu :column="columns.notes"></sc-column-menu>
            </q-th>
          </q-tr>
        </template>

        <template v-slot:body="props">
          <q-tr :props="props" :key="props.row.id" :class="{
            favorite: props.row.isFavorite,
            selected: props.row.isSelected,
            recent: isRecentlyAddedDevice(props.row.device),
            decommissioned: props.row.device.isDecommissioned,
            'non-connected': !props.row.device.isConnectedDevice
          }" @click="() => { if (isOneItemSelected) selectItem(props.row, true) }"
            @dblclick="selectItem(props.row, true)">

            <q-td v-if="visibleColumns.isSelected" style="padding: 2px; text-align: center;">
              <sc-is-selected-cell :item="props.row"
                @itemSelected="itemSelected"></sc-is-selected-cell>
            </q-td>

            <q-td v-if="visibleColumns.tags" style="padding: 2px; width: 20px;">
              <sc-tags-cell :item="props.row" @toggleFavorite="toggleFavorite"></sc-tags-cell>
            </q-td>

            <q-td class="cell-online-indicator" v-if="visibleColumns.isOnline"
              style="padding: 2px; text-align: center;">
              <sc-is-online-cell :item="props.row"></sc-is-online-cell>
            </q-td>

            <q-td v-if="visibleColumns.type">
              <sc-plain-text-cell :text="props.row.device.acronym"
                :column="columns.type"></sc-plain-text-cell>
            </q-td>

            <q-td v-if="visibleColumns.serialNumber">
              <sc-serial-number-cell :item="props.row"
                :column="columns.serialNumber"></sc-serial-number-cell>
            </q-td>

            <q-td v-if="visibleColumns.name">
              <sc-name-cell :item="props.row" :column="columns.name"></sc-name-cell>
            </q-td>

            <q-td v-if="visibleColumns.alerts">
              <sc-alerts-cell :item="props.row" :column="columns.alerts"></sc-alerts-cell>
            </q-td>

            <q-td v-if="visibleColumns.megaVersion">
              <sc-plain-text-cell :text="props.row.device.megaVersion"
                :column="columns.megaVersion"></sc-plain-text-cell>
            </q-td>

            <q-td v-if="visibleColumns.firmwareVersion" style="padding: 0;">
              <sc-firmware-version-cell :item="props.row"
                :column="columns.firmwareVersion"></sc-firmware-version-cell>
            </q-td>

            <q-td v-if="visibleColumns.hardwareVersion">
              <sc-plain-text-cell :text="props.row.device.hardwareVersion"
                :column="columns.hardwareVersion"></sc-plain-text-cell>
            </q-td>

            <q-td v-if="visibleColumns.eepromVersion">
              <sc-plain-text-cell :text="props.row.device.eepromVersion"
                :column="columns.eepromVersion"></sc-plain-text-cell>
            </q-td>

            <q-td v-if="visibleColumns.model">
              <sc-plain-text-cell :text="props.row.model"
                :column="columns.model"></sc-plain-text-cell>
            </q-td>

            <q-td v-if="visibleColumns.manufacturedAt">
              <sc-date-cell :date="props.row.device.manufacturedAt"
                :column="columns.manufacturedAt"></sc-date-cell>
            </q-td>

            <q-td v-if="visibleColumns.ownership"
              :class="{ popup: Boolean(props.row.device.ownership) }">
              <sc-ownership-cell :item="props.row" :column="columns.ownership"></sc-ownership-cell>
            </q-td>

            <q-td v-if="visibleColumns.soldAt">
              <sc-date-cell :date="props.row.soldAt" :column="columns.soldAt"></sc-date-cell>
            </q-td>

            <q-td v-if="visibleColumns.premiumServiceStatus">
              <sc-premium-service-status-cell :item="props.row"
                :column="columns.premiumServiceStatus"></sc-premium-service-status-cell>
            </q-td>

            <q-td v-if="visibleColumns.premiumService">
              <sc-premium-service-cell :item="props.row"
                :column="columns.premiumService"></sc-premium-service-cell>
            </q-td>

            <q-td v-if="visibleColumns.placeType">
              <sc-place-type-cell :item="props.row" :column="columns.placeType"></sc-place-type-cell>
            </q-td>

            <q-td v-if="visibleColumns.place" :class="{ popup: Boolean(props.row.device.place) }">
              <sc-place-cell :item="props.row" :column="columns.place"></sc-place-cell>
            </q-td>

            <q-td v-if="visibleColumns.location"
              :class="{ popup: Boolean(props.row.device.location) }">
              <sc-location-cell :item="props.row" :column="columns.location"></sc-location-cell>
            </q-td>

            <q-td v-if="visibleColumns.notes" class="popup">
              <sc-notes-cell :item="props.row" :column="columns.location"></sc-notes-cell>
            </q-td>
          </q-tr>
        </template>

        <template v-slot:no-data>
          <div class="full-width row flex-center text-accent q-gutter-sm q-mt-xl">
            <q-icon size="2em" name="sentiment_dissatisfied"></q-icon>
            <span>
              {{
                items.length === 0
                ? 'No devices yet'
                : 'No devices match the selected criteria'
              }}
            </span>
          </div>
        </template>
      </q-table>
    </div>

    <div v-if="!hasItems && isReloading" class="row items-center justify-center" style="flex: 1;">
      <sc-icon-progress class="text-indigo-4" size="32px"></sc-icon-progress>
      <label class="text-subtitle1 text-indigo-6 q-ml-sm">Loading the inventory, please wait
        ...</label>
    </div>

    <div v-if="!hasItems && !isReloading" class="row items-center justify-center" style="flex: 1;">
      <q-icon name="mood" size="sm"></q-icon>
      <label class="text-subtitle1 q-ml-md">There are no devices yet.</label>
    </div>

    <div class="device-panel" :class="{ collapsed: isDevicePanelCollapsed }"
      v-if="isDevicePanelVisible">
      <sc-device-panel :items="selectedItems" :devices="selectedDevices"
        @executeAction="actionExecuted" @close="closeDevicePanel">
      </sc-device-panel>
    </div>

    <sc-sell-tokens-dialog></sc-sell-tokens-dialog>
    <sc-purchase-tokens-dialog></sc-purchase-tokens-dialog>

  </sc-view>
</template>

<style lang='scss' scoped>
.table-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: auto;
}

.spreadsheet {
  flex: 1;
}

th {
  text-align: left;
  background-color: #e8e8e8;
  position: relative;
}

tr {
  &.tags {
    background-color: #fffade;
  }

  &.recent {
    td {
      font-weight: bold;
    }
  }

  &.decommissioned {
    td {
      color: #c0c0c0;
    }
  }

  &.selected {
    background-color: #e4eaff;
  }

  &.non-connected {
    background-color: #f0f0f0;
  }

  &.favorite {
    background-color: #eef5eb;
  }
}

td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.popup {
    cursor: pointer;
  }
}

.select-option {
  cursor: pointer;

  &:hover {
    background-color: #e8e8e8;
  }
}

.view-devices {
  position: relative;
}

.device-panel {
  position: absolute;
  z-index: 1000;
  right: 0;
  top: 222px;
  bottom: 0;
  background-color: #ffffff;
  margin: 8px;
  box-shadow: 0 0 8px gray;

  &.collapsed {
    position: absolute;
    top: unset;
    height: 42px;
    margin: 0;
    cursor: pointer;
  }
}
</style>
