import { getStore } from '../get-store'
import { runBatch } from '@stellacontrol/utilities'

/**
 * Dispatches a batch of store actions in parallel.
 * Internally it uses `runBatch` helper from `@stellacontrol/utilities` which
 * runs a number of promises in parallel, optionally in batches
 * @param actions Actions to dispatch, list of action names or { name, parameters } entities if action requires parameters
 * @param batchSize Maximal batch size
 * @param pause Pause in milliseconds between subsequent batches
 * @param onBatchStarted Notification before starting a batch of actions
 * @param onBatchCompleted Notification on completion of a batch of actions
 */
export function dispatchParallel (actions = [], { batchSize = Number.MAX_VALUE, pause = 0, onBatchStarted = null, onBatchCompleted = null } = {}) {
  const store = getStore()
  const functions = actions
    .map(action => {
      const actionName = (typeof action === 'string') ? action : action.name
      const actionParameters = (typeof action === 'string') ? undefined : action.parameters
      return () => store.dispatch(actionName, actionParameters)
    })

  return runBatch(functions, {
    batchSize,
    pause,
    onBatchStarted,
    onBatchCompleted
  })
}
