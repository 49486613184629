<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import { sortItems } from '@stellacontrol/utilities'
import { ViewMixin } from '@stellacontrol/client-utilities'
import { getPlaceIcon, DeviceType, getPlaceDescription } from '@stellacontrol/model'
import { Secure } from '@stellacontrol/security-ui'
import { DeviceCommands, DefaultDeviceCommands } from '@stellacontrol/devices'
import { resolve } from './building-dashboard.resolve'
import { DashboardWidgets } from '../device-dashboard/widgets'

const name = 'building-dashboard'

export default {
  mixins: [
    ViewMixin,
    Secure
  ],

  components: {
    ...DashboardWidgets
  },

  data () {
    return {
      name,
      // Indicates whether the dashboard is initialized
      isInitialized: false,
      // Indicates device status initialization in progress
      startingStatusWatch: false,
      // Available device commands
      DeviceCommands,
      DefaultDeviceCommands,
    }
  },

  computed: {
    ...mapGetters([
      'availableFastSamplingSlots',
      'isFastSamplingDevice',
      'getStatusWatchSettings',
      'isSmallScreen'
    ]),

    ...mapState({
      // Organization to which the building belongs
      organization: state => state.buildingDashboard.organization,
      // Viewed organization's guardian
      organizationGuardian: state => state.buildingDashboard.organizationGuardian,
      // Building displayed on the dashboard
      place: state => state.buildingDashboard.place,
      // Devices which belong to the building
      devices: state => state.buildingDashboard.devices,
      // All devices
      allDevices: state => state.devices.devices,
      // All places
      allPlaces: state => state.places.items,
      // Status of all currently watched devices
      deviceStatus: state => state.deviceStatus.devices || {},
      // Devices which belong to the building
      showParts: state => state.buildingDashboard.showParts,
      // Selected device ids
      selectedDeviceIds: state => state.buildingDashboard.selectedDevices,
      fastSamplingCountdown: state => state.buildingDashboard.fastSamplingCountdown,
      fastSamplingActive: state => state.buildingDashboard.countdownInterval != null
    }),

    // Indicates whether the current place represents stock
    isStock () {
      return this.place.id === 'none'
    },

    // Devices to show on the dashboard.
    // By default do not show individual device boards which are parts of multi-board devices.
    visibleDevices () {
      return this.devices
        .filter(device => this.showParts
          ? !device.isMultiDevice
          : !device.partOf)
    },

    // Returns a list of serial numbers of all devices on the current dashboard
    allSerialNumbers () {
      return this.visibleDevices.map(d => d.serialNumber).filter(s => s)
    },

    // Devices sorted by serial number, repeaters first
    sortedDevices () {
      const { visibleDevices } = this
      return [
        ...sortItems(visibleDevices.filter(d => d.type === DeviceType.Repeater, 'serialNumber')),
        ...sortItems(visibleDevices.filter(d => d.type !== DeviceType.Repeater, 'serialNumber'))
      ]
    },

    // Returns a list of devices which are permitted to be monitored for live status.
    // If customer is on a premium plan, this requires the `live-status` subscription.
    monitoredDevices () {
      const { organizationGuardian, visibleDevices, currentOrganizationGuardian } = this
      let devices
      if (currentOrganizationGuardian) {
        devices = currentOrganizationGuardian.requiresPremiumSubscription('live-status')
          ? visibleDevices.filter(({ serialNumber }) => organizationGuardian.canDeviceUse('live-status', serialNumber, currentOrganizationGuardian))
          : (currentOrganizationGuardian.canUse('live-status') ? visibleDevices : [])
      } else {
        devices = []
      }

      // Return only actual communicating boards
      return devices.filter(d => d.isConnectedDevice && !d.isMultiDevice)
    },

    // Indicates whether any devices can be monitored live
    hasMonitoredDevices () {
      return this.monitoredDevices.length > 0
    },

    // Devices which aren't monitored but can be still asked for status
    notMonitoredDevices () {
      return this.visibleDevices
        .filter(d => !this.monitoredDevices.some(ld => ld.id === d.id))
        .filter(d => d.isConnectedDevice)
    },

    // Devices currently selected in the place
    selectedDevices () {
      return this.monitoredDevices.filter(d =>
        this.selectedDeviceIds.some(s => s.id === d.id))
    },

    // Indicates whether all devices are selected in the place
    allDevicesSelected () {
      return this.monitoredDevices.length &&
        this.monitoredDevices.every(d => this.isDeviceSelected(d))
    },

    // Indicates whether more than one device has been selected
    isBatchSelected () {
      return this.selectedDevices.length > 0
    },

    // Checks whether the specified device is currently selected
    isDeviceSelected () {
      return device => this.selectedDevices.some(d => d.id === device.id)
    },

    // Indicates whether the device should be selectable
    isSelectable () {
      return device => this.canSeeLiveStatus(device) && this.canSendCommands
    },

    // Indicates whether the device can be edited
    isEditable () {
      return device => device != null && this.isAdministrator
    },

    // Commands available for the device
    commands () {
      return DefaultDeviceCommands
    },

    // Indicates whether the user can navigate to the inventory
    // and see the selected devices there
    canGoToInventory () {
      return this.canUse('inventory') && this.monitoredDevices.length
    },

    // Checks whether the user can edit the building plan
    canEditPlan () {
      const { place, currentOrganization } = this
      return !this.isStock && this.canUse('planner') &&
        (place.organizationId === currentOrganization.id || this.canUse('child-floor-plans'))
    },

    // Checks whether the user can edit the building notes
    canEditNotes () {
      const { place, currentOrganization } = this
      return !this.isStock && (place.organizationId === currentOrganization.id || this.canUse('child-places'))
    },

    // Indicates whether the user can send commands to devices
    canSendCommands () {
      return this.canUse('device-management')
    },

    // Indicates whether live status can be monitored for the specified device
    canSeeLiveStatus () {
      return device => {
        return !this.liveStatusDetails(device)
      }
    },

    // Explanation why device live status cannot be displayed
    liveStatusDetails () {
      return device => {
        const { currentOrganizationGuardian, organizationGuardian, deviceStatus } = this
        if (currentOrganizationGuardian && device) {
          if (device.isMultiDevice && !device.hasParts) {
            return 'Status not available'
          }
          if (device.isNonConnectedDevice && !device.isMultiDevice) {
            return 'Non-connected device'
          }
          if (deviceStatus[device.serialNumber]?.hasNeverConnected) {
            return 'Device has never connected'
          }
          if (currentOrganizationGuardian?.requiresPremiumSubscription('live-status')) {
            return !organizationGuardian.canDeviceUse('live-status', device.serialNumber, currentOrganizationGuardian) &&
              'No premium services active'
          } else {
            return !currentOrganizationGuardian?.canUse('live-status') &&
              'Not authorized to see live status'
          }
        }
      }
    },

    // Icon for place notes, indicating whether there are file attachments as well
    notesIcon () {
      return this.place.attachmentCount
        ? 'attachment'
        : this.place.notes.length ? 'comment' : 'mode_comment'
    },

    // Maximum number of bands for any live device
    // Passed to device cards to unify the layout if devices have varying numbers of bands
    maxBands () {
      return Math.max(...this.visibleDevices.map(d => d.isConnectedDevice ? d.bandCount : 1), 1)
    },

    // Devices which can be potentially added to this place
    devicesToAdd () {
      const { place, allDevices, isStock } = this
      // If moving to place, find stock devices which belong to the owner of the place.
      // If moving to stock, find devices which belong to the owner of the place but are not assigned to any place yet.
      const devices = isStock
        ? allDevices.filter(d => d.ownerId === place.organizationId && d.placeId != null)
        : allDevices.filter(d => d.ownerId === place.organizationId && d.placeId == null)
      return devices
    },

    // Indicates whether user can add devices to a building
    canAddDevice () {
      return this.canUse('set-device-place') && this.devicesToAdd.length > 0
    },

    // Indicates whether fast-sampling is allowed for any of the devices in the building
    canFastSample () {
      const devices = this.sortedDevices.filter(device => this.canSeeLiveStatus(device))
      return devices.length > 0 && this.statusWatchSettings.fastSamplingSpeed !== 'off'
    },

    // Devices which are currently eligible for fast-sampling,
    // either from the current selection or from all devices in the building
    devicesEligibleForFastSampling () {
      const { canSendCommands, sortedDevices, selectedDevices, availableFastSamplingSlots } = this

      if (!canSendCommands) return []

      const devices = (selectedDevices.length > 0 ? selectedDevices : sortedDevices)
        .filter(device => this.canSeeLiveStatus(device))
        .filter(device => !this.isFastSamplingDevice(device))

      if (devices.length >= availableFastSamplingSlots) {
        return selectedDevices.length > 0 ? [] : devices.slice(0, availableFastSamplingSlots)
      }

      return devices
    },

    // Label to show on the fast-sampling button
    fastSamplingLabel () {
      return this.fastSamplingCountdown || this.statusWatchSettings.fastSamplingDuration
    },

    // Indicates whether we're now fast-sampling some devices
    isFastSampling () {
      return this.canFastSample && this.fastSamplingCountdown > 0
    },

    // Status watch settings for the current view
    statusWatchSettings () {
      return this.getStatusWatchSettings(name)
    },

    // View breadcrumbs
    breadcrumbs () {
      const { organization, place, getViewTitle } = this

      const breadcrumbs = [
        {
          name: 'home',
          title: getViewTitle('home')
        },
        {
          name: 'installations',
          title: getViewTitle('installations'),
          route: 'installations'
        },
        {
          name: 'installations',
          title: organization.name,
          route: 'installations',
          query: {
            filter: organization.name
          }
        },
        {
          title: getPlaceDescription(place)
        }
      ]

      return breadcrumbs
    }
  },

  methods: {
    ...mapActions([
      'initializeBuildingDashboard',
      'getLiveStatus',
      'watchDeviceStatus',
      'unwatchDeviceStatus',
      'suspendWatchingDeviceStatus',
      'resumeWatchingDeviceStatus',
      'watchUploadStatus',
      'unwatchUploadStatus',
      'gotoInventory',
      'showPlaceNotes',
      'goBack',
      'gotoRoute',
      'openInventoryAction',
      'buildingSelectDevice',
      'buildingStartFastSampling',
      'startFastSampling',
      'showDialog',
      'setDevicePlace',
      'removeDevicesFromPlace'
    ]),

    getPlaceIcon,

    // Populates the dashboard
    async populate () {
      this.isInitialized = false
      const { organization, place } = this
      if (organization && place) {
        this.watchStatus()
      }
      this.isInitialized = true
    },

    // Starts watching for device status
    async watchStatus () {
      if (!this.startingStatusWatch) {
        this.startingStatusWatch = true
        try {
          await this.unwatchStatus()

          // Start watching live status of permitted devices
          if (this.hasMonitoredDevices) {
            await this.watchDeviceStatus({ name, devices: this.monitoredDevices })
            await this.watchUploadStatus({ interval: this.statusWatchSettings.fastSamplingDuration })
          }

          // Fetch most-recent status of remaining devices
          await this.getLiveStatus({
            devices: this.notMonitoredDevices
          })

        } finally {
          this.startingStatusWatch = false
        }
      }
    },

    // Stops watching the device status
    async unwatchStatus () {
      await this.unwatchDeviceStatus({ name })
      await this.unwatchUploadStatus()
    },

    // Suspends watching the device status
    async suspendWatchStatus () {
      if (!this.hasMonitoredDevices) return
      this.suspendWatchingDeviceStatus({ name })
      this.unwatchUploadStatus({ name })
    },

    // Resumes watching the device status
    async resumeWatchStatus () {
      if (!this.hasMonitoredDevices) return
      this.resumeWatchingDeviceStatus({ name })
      this.watchUploadStatus({ name, interval: this.statusWatchSettings.fastSamplingDuration })
    },

    // Opens organization devices (or current selection) in inventory
    showDevicesInInventory () {
      this.gotoInventory({
        selection: this.allSerialNumbers,
        filterBySelection: true
      })
    },

    // Toggle device selection
    toggleDevice (device, isSelected) {
      if (this.isSelectable(device)) {
        this.buildingSelectDevice({ device, isSelected })
      }
    },

    // Starts fast sampling of all or selected devices
    async startDeviceFastSampling () {
      const {
        devicesEligibleForFastSampling: devices,
        statusWatchSettings: { fastSamplingDuration }
      } = this

      if (devices.length > 0) {
        this.buildingStartFastSampling({ devices, fastSamplingDuration })
      }
    },

    // Goes back to installations dashboard
    close () {
      this.gotoRoute({ name: 'installations' })
    },

    // Adds stock devices to a building.
    // If building is stock, we're removing devices from their current places.
    async addDevice () {
      const { isStock, place, allPlaces, devicesToAdd: devices } = this

      const title = isStock ? 'Move device back to stock' : `Move device from stock to ${place.name}`
      const { isOk, data } = await this.showDialog({
        dialog: 'device-picker',
        data: { title, devices }
      })

      if (isOk) {
        const { device } = data

        if (isStock) {
          // Move the device from its current place back to stock
          const place = allPlaces.find(p => p.id === device.placeId)
          await this.removeDevicesFromPlace({ devices: [device], place })

        } else {
          // Move the device to place
          this.setDevicePlace({ device, place })
          // Activate any pending premium services present on the device
          const { premiumServiceId, isPremiumServiceNotStarted } = device
          if (premiumServiceId && isPremiumServiceNotStarted) {
            await this.startDeviceSubscriptions({
              devices: [device],
              startsAt: new Date(),
              details: `Premium service has been activated because the device has been assigned to ${getPlaceDescription(this.place)}`,
              silent: true,
            })
          }
        }

        // Reload the dashboard and reinitialize status watch
        await this.initializeBuildingDashboard({ id: this.place.id, organizationId: this.organization.id })
        await this.unwatchStatus()
        await this.watchStatus()
      }
    }
  },

  async created () {
    await this.populate()
  },

  // Reload the dashboard on navigation to another place
  async beforeRouteUpdate (to, from, next) {
    // Stop any running status subscriptions
    await this.unwatchDeviceStatus()

    // Load the data of the new organization and place
    const { redirectTo } = await resolve({ from, to }) || {}

    // Re-initialize the dashboard
    await next(redirectTo)
    if (!redirectTo) {
      await this.populate()
    }
  }
}
</script>

<template>
  <sc-view :name="name" :breadcrumbs="breadcrumbs">

    <!-- Desktop mode toolbar -->
    <template #toolbar>
      <q-btn v-if="canEditPlan" dense unelevated icon="category"
        :to="{ name: 'building-plan', params: { id: place.id } }">
        <sc-tooltip>
          Edit building plan
        </sc-tooltip>
      </q-btn>

      <q-btn v-if="!isStock" dense unelevated :icon="notesIcon" textColor="indigo-5"
        @click.stop="showPlaceNotes({ place })">
      </q-btn>

      <q-btn v-if="canFastSample" unelevated :label="`Live ${fastSamplingLabel}`"
        :disable="isFastSampling" icon="play_arrow" :ripple="false"
        @click="startDeviceFastSampling()">
      </q-btn>

      <q-btn-dropdown v-if="canSendCommands" label="Commands" unelevated icon="wifi_tethering"
        :ripple="false" :disable="selectedDevices.length === 0">
        <sc-device-commands :show-header="false" :commands="commands" :devices="selectedDevices">
        </sc-device-commands>
      </q-btn-dropdown>

    </template>

    <!-- When in mobile mode, show buttons inside the topbar -->
    <teleport v-if="isSmallScreen" to="#topbar-items">
      <span class="place-label q-mr-sm text-white">
        {{ place.name }}
      </span>

      <q-space>
      </q-space>

      <div class="row items-center no-wrap q-gutter-sm">
        <q-btn v-if="canEditNotes" outline style="color: white" :icon="notesIcon"
          @click.stop="showPlaceNotes({ place })">
        </q-btn>

        <q-btn v-if="canFastSample" outline style="color: white; width: 55px;"
          @click="startDeviceFastSampling()">
          <div class="row items-center no-wrap">
            <q-icon name="play_arrow" :color="fastSamplingActive ? 'orange' : 'white'" />
            <div class="text-center q-ml-xs"
              :class="{ 'text-orange': fastSamplingActive, 'text-white': !fastSamplingActive }">
              {{ fastSamplingLabel }}
            </div>
          </div>
        </q-btn>

        <q-btn v-if="canAddDevice" outline style="color: white" icon="add"
          @click="addDevice()"></q-btn>

        <q-btn outline style="color: white" icon="arrow_back" @click="close()">
        </q-btn>
      </div>
    </teleport>

    <q-banner v-if="isStock" class="bg-orange-6 row items-center">
      <q-icon color="white" name="new_releases" class="q-mr-sm" size="sm" />
      <span class="text-subtitle2">
        Assign to building to unlock all functionalities
      </span>
    </q-banner>

    <div class="devices" v-if="sortedDevices.length > 0 || canAddDevice">
      <template v-for="device in sortedDevices">
        <sc-widget-device-card dense :device="device" :place="place" :organization="organization"
          :compact="false" :is-editable="isEditable(device)" :is-selectable="isSelectable(device)"
          :is-selected="isDeviceSelected(device)" :is-live-status-allowed="canSeeLiveStatus(device)"
          :live-status-details="liveStatusDetails(device)" :max-bands="maxBands"
          @select="({ device, isSelected }) => toggleDevice(device, isSelected)">
        </sc-widget-device-card>
      </template>
      <div class="button-add-to-place row items-center" v-if="canAddDevice && !isSmallScreen">
        <q-btn icon="add" flat @click="addDevice()" size="lg" :ripple="false">
        </q-btn>
        <sc-tooltip>
          {{ isStock ? 'Move devices back to stock' : `Move devices from stock to ${place.name}`
          }}
        </sc-tooltip>
      </div>
    </div>

    <div class="no-devices q-pa-md" v-if="sortedDevices.length === 0 && !canAddDevice">
      {{ organization.name }} does not have any devices.
    </div>

    <template v-if="isSmallScreen" #footer>
      <div class="context-menu" v-if="selectedDevices.length > 0">
        <sc-device-commands :show-header="false" :commands="DefaultDeviceCommands"
          :devices="selectedDevices" horizontal>
          <template #prefix-commands>
            <q-item v-if="selectedDevices.length === 1" clickable v-close-popup
              :to="{ name: 'device-dashboard', params: { serialNumber: selectedDevices[0].serialNumber } }">
              <q-item-section side>
                <q-icon name="link" color="indigo-6" size="24px"></q-icon>
              </q-item-section>
              <q-item-section>
                <q-item-label class="command-label no-wrap">
                  Info
                </q-item-label>
              </q-item-section>
            </q-item>
          </template>
        </sc-device-commands>
      </div>
    </template>

    <sc-band-selector-dialog></sc-band-selector-dialog>
    <sc-device-picker-dialog></sc-device-picker-dialog>
    <sc-document-upload-dialog></sc-document-upload-dialog>
  </sc-view>

</template>

<style lang="scss" scoped>
.devices {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  gap: 16px;
  padding: 16px;
  overflow-y: auto;
  align-content: flex-start;
}

.context-menu {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.place-label {
  margin: 0;
  padding: 0 0 0 2px;
  font-size: 19px;
  font-weight: 500;
  line-height: normal;
  color: #272727;
  margin-bottom: 10px;
}

.button-add-to-place {
  width: 190px;
  min-height: 200px;
  border: dashed #d5d5d5 2px;
  border-radius: 4px;

  .q-btn {
    width: 100%;
    height: 100%;
  }
}

/* Layout adjustments for small screens */
@media screen and (width <=1024px) {
  .devices {
    display: grid;
    gap: 10px;
    padding: 10px;
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .place-label {
    font-size: 16px;
    text-wrap: wrap;
    margin-bottom: unset;
    margin-left: 8px;
    margin-right: 8px;
  }
}

@media screen and (width <=920px) {
  .devices {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media screen and (width < 640px) {
  .devices {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 8px;
  }
}
</style>
