<script>
import { mapGetters } from 'vuex'
import { Secure } from '../../secure-component'
import { ContextEditor } from './context-editor'

/**
 * Permission context editor for allowing organization to use some of the premium features
 * without need to have paid subscriptions for them
 */
export default {
  mixins: [
    ContextEditor,
    Secure
  ],

  computed: {
    ...mapGetters([
    ]),

    // List of all premium features
    premiumFeatures () {
      return (this.guardian.premiumFeatures || [])
        .map(({ name, description }) => ({
          name,
          description,
          isSelected: this.isFeatureSelected({ name })
        }))
    }
  },

  methods: {
    // Returns true if feature has been selected
    isFeatureSelected (feature) {
      return (this.permission.context || []).includes(feature.name)
    },

    // Selects/unselects a feature
    toggleFeature (feature, value) {
      if (!this.permission.context) {
        this.permission.context = []
      }

      const index = this.permission.context.indexOf(feature.name)
      const isSelected = index > -1

      if (value && !isSelected) {
        this.permission.context.push(feature.name)
      }

      if (!value && isSelected) {
        this.permission.context.splice(index, 1)
      }

      this.$emit('context', this.permission)
    }
  },

  created () {
    // Cleanup features which no longer exist
    const { premiumFeatures, permission: { context = [] } = {} } = this
    this.permission.context = context.filter(featureName => premiumFeatures.find(p => p.name === featureName))
  }
}
</script>

<template>
  <div v-if="permissions.canUse('child-organizations')">
    <div class="bg-indigo-7 text-white q-pa-md">
      If you want to allow {{ principal.fullName || 'organization' }}
      to use some premium features without having subscriptions,
      indicate them below:
    </div>

    <div class="q-pa-sm">
      <q-list dense class="q-mt-sm">
        <q-item v-for="feature in premiumFeatures" :key="feature.name">
          <q-item-section side>
            <q-checkbox dense
              :model-value="isFeatureSelected(feature)"
              @update:model-value="value => toggleFeature(feature, value)">
            </q-checkbox>
          </q-item-section>
          <q-item-section>
            <q-item-label>
              {{ feature.description }}
            </q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </div>
  </div>
</template>

<style lang="scss" scoped>
</style>
