<script>
import { sortItems, groupItems, sortProperties } from '@stellacontrol/utilities'

export default {
  props: {
    // Organizations selected initially
    modelValue: {
      required: true
    },

    // List of organization profiles to select from
    organizationProfiles: {
    },

    // List is bordered
    bordered: {
      default: true
    },

    // Items are separated
    separator: {
      default: true
    },

    // List is dense
    dense: {
      default: false
    },

    // Also shows profile owner.
    // If so, profiles are sorted by owner.
    showProfileOwner: {
      default: false
    }
  },

  data () {
    return {
      // Currently selected organization profiles
      selection: [],

      // Organization profile filter
      filter: ''
    }
  },

  computed: {
    // Visible profiles
    visibleProfiles () {
      let profiles
      const { filter } = this
      if (filter.trim()) {
        profiles = this.organizationProfiles.filter(profile => profile.fullName.toLowerCase().includes(filter.trim().toLowerCase()))
      } else {
        profiles = this.organizationProfiles
      }

      return sortItems(profiles, profile => this.showProfileOwner
        ? `${profile.owner?.name || ''} ${profile.fullName}`
        : profile.fullName)
    },

    // Profile groups
    profileGroups () {
      const { showProfileOwner, visibleProfiles } = this

      let groups
      if (showProfileOwner) {
        groups = groupItems(visibleProfiles, profile => profile.owner?.name || '-')
      } else {
        groups = { '-': visibleProfiles }
      }

      return sortProperties(groups)
    },

    // Indicates whether the specified organization profile is selected
    isSelected () {
      return profile => this.selection.includes(profile.id)
    },

    // Indicates whether all organization profiles have been selected
    allSelected () {
      return this.organizationProfiles.length === this.selection.length
    }
  },

  watch: {
    modelValue (value) {
      this.selection = value
    },

    // Report model change on selection
    selection (value) {
      this.$emit('update:model-value', value)
    }
  },

  methods: {
    // Selects/unselects organization profile
    select (profile, status) {
      const isSelected = this.selection.includes(profile.id)
      if (status && !isSelected) {
        this.selection = [...this.selection, profile.id]
      }
      if (!status && isSelected) {
        this.selection = this.selection.filter(id => id !== profile.id)
      }
    },

    // Selects/deselects all organization profiles
    toggleAll (status) {
      if (status) {
        this.selection = this.organizationProfiles.map(profile => profile.id)
      } else {
        this.selection = []
      }
    }
  },

  created () {
    this.selection = this.modelValue
  }
}

</script>

<template>
  <main>
    <header class="row items-center q-pt-sm q-pb-sm q-pl-md q-pr-md">
      <q-input borderless dense v-model="filter" label="Filter organization profiles"></q-input>
      <q-space></q-space>
      <q-checkbox :model-value="allSelected" :label="allSelected ? 'Deselect All' : 'Select All'"
        @update:model-value="status => toggleAll(status)">
      </q-checkbox>
    </header>

    <section class="q-pa-sm">
      <div class="group" v-for="[name, profiles] in Object.entries(profileGroups)">
        <div class="group-title">
          {{  name === '-' ? 'General Profiles' : `${name} Profiles` }}
        </div>
        <q-list :dense="dense" :bordered="bordered" :separator="separator" class="q-mt-sm">
          <q-item v-for="profile in profiles" :key="profile.id">
            <q-item-section side>
              <q-checkbox dense :model-value="isSelected(profile)" color="indigo-6" keep-color
                @update:model-value="value => select(profile, value)"></q-checkbox>
            </q-item-section>
            <q-item-section>
              <q-item-label>
                {{ profile.fullName }}
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </div>
    </section>
  </main>
</template>

<style scoped lang="scss">
main {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  header {
    flex: 0;
    border-bottom: solid #0000001f 1px;
  }

  section {
    flex: 1;
    overflow: auto;

    .group {
      margin-bottom: 10px;

      .group-title {
        font-weight: bold;
        padding: 4px;
        border-bottom: solid #0000001f 1px;
      }
    }
  }
}
</style>