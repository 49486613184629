<script>
import ParameterEditor from './parameter-editor'

export default {
  mixins: [
    ParameterEditor
  ],

  computed: {
    // Items for value selector
    items () {
      // We allow users only to select some selected values
      const allowed = [0, 3]
      return this.values
        .map((value, index) => ({ label: this.labels[index], value }))
        .filter(item => allowed.includes(item.value))
    }
  }
}
</script>

<template>
  <div class="parameter-editor" v-if="hasValue">

    <sc-parameter-value :label="mega.label" :reported="reported" :custom="custom"
      :format="value => labelOf(value)">
    </sc-parameter-value>

    <sc-parameter-selector :items="items" :label="mega.label" :allow-clear="isCustomized"
      @select="value => change(value)" @clear="clear()">
    </sc-parameter-selector>

  </div>
</template>
