/**
 * Possible states of an upload job
 */
export const UploadStatus = {
  /**
   * Upload job just created, never attempted yet
   */
  New: 'new',
  /**
   * Upload requested by the user
   */
  Requested: 'requested',
  /**
   * Upload failed and needs to be retried
   */
  Retry: 'retry',
  /**
   * Upload is in progress
   */
  Sending: 'sending',
  /**
   * Upload has been completed
   */
  Completed: 'completed',
  /**
   * All attempts to deliver the upload have failes
   */
  Failed: 'failed'
}

/**
 * Descriptions of upload states
 */
export const UploadStatusDescription = {
  [UploadStatus.New]: 'upload scheduled',
  [UploadStatus.Requested]: 'upload requested by the user',
  [UploadStatus.Retry]: 'upload failed, trying again',
  [UploadStatus.Sending]: 'upload in progress',
  [UploadStatus.Completed]: 'upload completed',
  [UploadStatus.Failed]: 'upload failed',
}

/**
 * Icons representing upload states
 */
export const UploadStatusIcon = {
  [UploadStatus.New]: 'radio_button_unchecked',
  [UploadStatus.Requested]: 'record_voice_over',
  [UploadStatus.Retry]: 'next_plan',
  [UploadStatus.Sending]: 'swap_vertical_circle',
  [UploadStatus.Completed]: 'check_circle',
  [UploadStatus.Failed]: 'error',
}

/**
 * Colors representing upload states
 */
export const UploadStatusColor = {
  [UploadStatus.New]: 'green-5',
  [UploadStatus.Requested]: 'green-5',
  [UploadStatus.Retry]: 'orange-6',
  [UploadStatus.Sending]: 'blue-6',
  [UploadStatus.Completed]: 'green-8',
  [UploadStatus.Failed]: 'red-8',
}