<script>
import { mapState, mapGetters } from 'vuex'
import { Secure } from '@stellacontrol/security-ui'
import { FormMixin } from '@stellacontrol/client-utilities'

export default {
  mixins: [
    FormMixin,
    Secure
  ],

  props: {
    // Indicates whether editing is enabled
    isEnabled: {
      type: Boolean,
      default: true
    },
    // Selected organization
    organization: {
    },
    // Selected files
    selectedFiles: {
    },
    // File sort order
    sortOrder: {
    },
    // File filter
    filter: {
    },
    // File age
    age: {
    }
  },

  data () {
    return {
      // File sort order
      sortOrders: [
        { value: 'date-asc', label: 'Date' },
        { value: 'date-desc', label: 'Date, descending' },
        { value: 'name-asc', label: 'Name' },
        { value: 'name-desc', label: 'Name, descending' }
      ],
      // File age
      ages: [
        { value: 1, label: 'One day' },
        { value: 7, label: 'One week' },
        { value: 31, label: 'One month' },
        { value: 0, label: 'All' }
      ]
    }
  },

  computed: {
    ...mapState({
      owners: state => state.organizations.items || []
    }),

    ...mapGetters([
      'organizations'
    ]),

    // Indicates that some files in the folder are selected
    hasSelectedFiles () {
      return this.selectedFiles.length > 0
    },

    // Indicates that some files in the folder can be deleted
    canDeleteFiles () {
      return this.selectedFiles.some(f => f.canDelete)
    },

    // Indicates that some files in the folder can be printed
    canPrintFiles () {
      return this.selectedFiles.some(f => f.canPrint)
    },

    // Indicates that some files in the folder can be printed
    canLinkFiles () {
      return this.selectedFiles.some(f => f.canLinkToPrincipal)
    }
  },

  emits: [
    'filter',
    'remove-files',
    'print-files',
    'sort-files',
    'filter-files',
    'link-files'
  ],

  methods: {
    // Selects the specified organization
    selectOrganization (organizationId) {
      const organization = this.organizations.find(o => o.id === organizationId)
      this.$emit('filter', { organization })
    },

    // Removes the selected files
    removeFiles () {
      const files = this.selectedFiles.filter(f => f.canDelete)
      this.$emit('remove-files', { files })
    },

    // Prints the selected files
    printFiles () {
      const files = this.selectedFiles.filter(f => f.canPrint)
      this.$emit('print-files', { files })
    },

    // Sorts the files
    sortFiles (sortOrder) {
      this.$emit('sort-files', { sortOrder })
    },

    // Filters the files
    filterFiles ({ filter, age }) {
      this.$emit('filter-files', { filter, age })
    },

    // Links the specified files to another organization and place
    linkFiles () {
      const files = this.selectedFiles.filter(f => f.canLinkToPrincipal)
      this.$emit('link-files', { files })
    }
  }
}
</script>

<template>
  <sc-organization-selector class="q-mr-sm" :items="owners" dense :disable="!isEnabled"
    :model-value="organization?.id" @update:model-value="selectOrganization" label="Organization">
  </sc-organization-selector>
  <q-select dense outlined emit-value label="Sort files by"
    :style="{ width: '170px' }" :model-value="sortOrder" :options="sortOrders" map-options
    @update:model-value="sortFiles">
  </q-select>
  <q-select dense outlined emit-value label="File age" :style="{ width: '100px' }"
    :model-value="age" :options="ages" map-options
    @update:model-value="age => filterFiles({ age })">
  </q-select>
  <q-input outlined clearable clear-icon="close" dense label="Filter" icon="search"
   :style="{ width: '170px' }"
    bg-color="white" :debounce="500" :model-value="filter"
    @update:model-value="filter => filterFiles({ filter })">
    <template v-slot:prepend>
      <q-icon name="search"></q-icon>
    </template>
  </q-input>
  <q-space>
  </q-space>
  <div class="buttons">
    <q-btn unelevated no-caps icon="print" class="q-mr-sm" v-if="isSuperAdministrator"
      :color="canLinkFiles ? 'indigo-6' : 'grey-7'" :disabled="!canLinkFiles" label="Assign to place"
      @click="linkFiles">
      <sc-tooltip v-if="!canLinkFiles" nowrap>
        Select the files to assign to a place
      </sc-tooltip>
    </q-btn>
    <q-btn unelevated no-caps icon="print" class="q-mr-sm"
      :color="canPrintFiles ? 'indigo-6' : 'grey-7'" :disabled="!canPrintFiles" label="Print"
      @click="printFiles">
      <sc-tooltip v-if="!canPrintFiles" nowrap>
        Select the files to print
      </sc-tooltip>
    </q-btn>
    <q-btn unelevated no-caps icon="delete" :color="canDeleteFiles ? 'red-6' : 'grey-7'"
      :disabled="!canDeleteFiles" label="Delete" @click="removeFiles">
      <sc-tooltip v-if="!hasSelectedFiles" nowrap>
        Select the files to delete
      </sc-tooltip>
    </q-btn>
  </div>
</template>

<style scoped lang="scss"></style>