<script>
import { mapState, mapGetters } from 'vuex'
import { formatDateTime, pluralize, getConciseDurationString } from '@stellacontrol/utilities'
import DeviceWidget from './device-widget'

export default {
  mixins: [
    DeviceWidget
  ],

  computed: {
    ...mapState({
      // Age of the alerts to display
      age: state => state.deviceDashboard.lastAlertsAge,
      // Recently retrieved alerts of monitored devices
      lastAlerts: state => state.deviceStatus.alerts
    }),

    ...mapGetters([
      'toLocalDateTime'
    ]),

    items () {
      const { lastAlert, alerts, age } = this
      if (lastAlert) {
        const localTime = this.toLocalDateTime(lastAlert.time)
        const timestamp = formatDateTime(localTime)
        const otherAlerts = alerts.filter(alert => alert.id !== lastAlert.id)
        const tooltip = otherAlerts.length === 0
          ? `
            <i>${formatDateTime(this.toLocalDateTime(lastAlert.time))}</i>
            <br>
            ${lastAlert.message}
          `
          : `
            <ul>
              ${[lastAlert, ...otherAlerts].map(alert => `
              <li>
                <i>${formatDateTime(this.toLocalDateTime(alert.time))}</i>
                <br>
                ${alert.message}
              </li>
            `).join('\n')}
            </ul>
        `
        const items = [
          { text: timestamp, class: { 'label': true } },
          { text: lastAlert.description, class: { small: true }, tooltip }
        ]
        if (otherAlerts.length > 0) {
          items.push({
            text: `${otherAlerts.length} more ${pluralize(otherAlerts.length, 'alert')} ...`,
            class: { small: true, muted: true },
            tooltip
          })
        }
        return items

      } else {
        return [{ text: `No alerts during the last ${getConciseDurationString(age)}`, class: { label: true } }]
      }
    },

    // Recently logged alerts
    alerts () {
      return this.lastAlerts[this.device.serialNumber] || []
    },

    // Most recent alert
    lastAlert () {
      return this.alerts[0]
    },

    // Widget icon
    icon () {
      const { device } = this
      return device.canTriggerAlerts ? 'notifications_active' : 'notifications_off'
    },

    // Color of the widget icon
    color () {
      const { device, lastAlert } = this
      return device.canTriggerAlerts
        ? (lastAlert ? 'deep-orange-6' : 'light-green-7')
        : 'grey-5'
    }
  }
}
</script>

<template>
  <sc-widget-text v-if="device?.canTriggerAlerts" iconMargin="0" :icon="icon" :iconColor="color"
    :items="items" min-width="195px" max-width="300px">
  </sc-widget-text>
</template>

<style lang="scss" scoped></style>
