import { Assignable } from '../common/assignable'
import { isEnum, getAge, parseDate } from '@stellacontrol/utilities'
import { UploadStatus } from './upload-status'
import { UploadType } from './upload-type'

/**
 * Status of a device upload job
 */
export class UploadJobStatus extends Assignable {
  constructor (data = {}) {
    super()
    this.assign({
      ...data
    },
    {
      updatedAt: parseDate
    })
    if (!isEnum(UploadStatus, this.status)) throw new Error(`Invalid upload status ${this.status}`)
  }

  /**
   * Upload job identifier
   * @type {String}
   */
  id

  /**
   * Device identifier
   * @type {String}
   */
  deviceId

  /**
   * Last status update time
   * @type {Date}
   */
  updatedAt

  /**
   * Upload status
   * @type {UploadStatus}
   */
  status

  /**
   * Upload type
   * @type {UploadType}
   */
  type

  /**
   * Upload % progress
   * @type {Number}
   */
  progress

  /**
   * Remaining number of retry attempts
   * @type {Number}
   */
  retryAttempts

  /**
   * Time when the job should be retried again [ms]
   * @type {Number}
   */
  retryInterval

  /**
   * Determines whether the specified file is a firmware file
   * @param name File name
   */
  get isFirmwareUpload () {
    return this.type === UploadType.Firmware
  }

  /**
   * Determines whether the specified file is a boot logo file
   * @param name File name
   */
  get isBootLogoUpload () {
    return this.type === UploadType.BootLogo
  }

  /**
   * Returns true if job has not yet been started
   * @type {Boolean}
   */
  get isNew () {
    return this.status === UploadStatus.New
  }

  /**
   * Returns true if job has been completed
   * @type {Boolean}
   */
  get isCompleted () {
    return this.status === UploadStatus.Completed
  }

  /**
   * Returns true if job will be retried
   * @type {Boolean}
   */
  get isRetry () {
    return this.status === UploadStatus.Retry
  }

  /**
   * Returns true if job has failed
   * @type {Boolean}
   */
  get isFailed () {
    return this.status === UploadStatus.Failed
  }

  /**
   * Indicates that the job is currently in progress
   * @type {Boolean}
   */
  get inProgress () {
    return !(this.isCompleted || this.isFailed)
  }

  /**
   * Returns time since last job update, in seconds
   * @type {Number}
   */
  get timeSinceLastUpdate () {
    if (this.updatedAt) {
      return getAge(this.updatedAt)
    } else {
      return 0
    }
  }

  /**
   * Returns time (in ms) in which the next attempt will happen
   * @type {Number}
   */
  get nextTryIn () {
    if (this.retryAttempts > 0) {
      return Math.max(0, this.retryInterval - this.timeSinceLastUpdate)
    }
  }

  /**
   * Returns true if job can be retried now,
   * which means there are retry attempts left
   * and enough time has passed since last retry
   * @type {Boolean}
   */
  get canRetryNow () {
    return this.status === UploadStatus.Retry &&
      this.retryAttempts > 0 &&
      this.timeSinceLastUpdate >= this.retryInterval
  }
}
