import { Assignable } from '@stellacontrol/model'
import { parseBoolean } from '@stellacontrol/utilities'
import { PlanLayerLabels, PlanLayerOrder, PlanLayers } from './plan-layers'

/**
 * Plan layer
 */
export class PlanLayer extends Assignable {
  constructor (data) {
    super()
    this.assign(data)
    this.label = PlanLayerLabels[this.id]
    this.order = PlanLayerOrder[this.id]
  }

  /**
   * Object defaults
   */
  get defaults () {
    return {
      isVisible: true,
      isBackgroundVisible: false
    }
  }

  /**
   * Permanently locked layers
   * @type {Array[PlanLayers]}
   */
  get lockedLayers () {
    return [
      PlanLayers.Base,
      PlanLayers.Background,
      PlanLayers.Selection
    ]
  }

  normalize () {
    super.normalize()
    const { id, defaults } = this
    this.isVisible = parseBoolean(this.isVisible, defaults.isVisible)
    this.isBackgroundVisible = parseBoolean(this.isBackgroundVisible, defaults.isBackgroundVisible)
    // Some layers must be locked permanently
    this.isLocked = this.lockedLayers.includes(id)
  }

  /**
   * Serializes the item to JSON
   * @returns {Object}
   */
  toJSON () {
    const result = { ...this }
    // Remove defaults
    this.clearDefaults(result)
    delete result.order
    delete result.label
    delete result.isLocked
    return result
  }

  /**
   * Layer identifier
   * @type {String}
   */
  id

  /**
   * Layer label
   * @type {String}
   */
  label

  /**
   * Layer order
   * @type {Number}
   */
  order

  /**
   * Indicates whether the layer is visible
   * @type {Boolean}
   */
  isVisible

  /**
   * Indicates whether the background image on the layer is visible
   * @type {Boolean}
   */
  isBackgroundVisible

  /**
   * Indicates whether user can add any new items to the layer
   * or modify items existing on the layer
   * @type {Boolean}
   */
  isLocked

  /**
   * Indicates whether the layer is internal, and should not be made available
   * to the user to hide it or manipulate.
   * Used for layer such as automatically rendered coordinate lines, selection boxes etc.
   * @type {Boolean}
   */
  get isInternal () {
    return this.id === PlanLayers.Base || this.id === PlanLayers.Selection
  }
}
