import { safeParseInt, Point } from '@stellacontrol/utilities'
import { PlanItem, PlanItemType } from './plan-item'

/**
 * Ellipse
 */
export class PlanEllipse extends PlanItem {
  constructor (data = {}) {
    super(data)
    this.assign(data)
  }

  /**
   * Item type
   * @type {PlanItemType}
   */
  static get type () {
    return PlanItemType.Ellipse
  }

  /**
   * Item defaults
   */
  get defaults () {
    return {
      ...super.defaults,
      radius: {
        x: 100,
        y: 100
      }
    }
  }

  normalize () {
    super.normalize()
    const { defaults } = this
    this.radius = new Point({
      x: Math.max(1, safeParseInt(this.radius?.x, defaults.radius.x)),
      y: Math.max(1, safeParseInt(this.radius?.y, defaults.radius.y))
    })
  }

  /**
   * Radius on both axes
   * @type {Point}
   */
  radius

  /**
   * Radius on the X axis.
   * Also implemented in {@link Ellipse} shape, makes handling circular shapes easier
   * @returns {Number}
   */
  get radiusX () {
    return this.radius?.x
  }

  /**
   * Radius on the Y axis.
   * Also implemented in {@link Ellipse} shape, makes handling circular shapes easier
   * @returns {Number}
   */
  get radiusY () {
    return this.radius?.y
  }

  /**
   * Resizes the circle
   * @param {Number} x New radius on X axis
   * @param {Number} y New radius on Y axis
   */
  resize (x, y) {
    if (this.canResize) {
      this.radius = new Point({ x, y })
    }
  }

  /**
   * Resizes the circle by the specified delta
   * @param {Number} x New radius on X axis
   * @param {Number} y New radius on Y axis
   */
  resizeBy (x, y) {
    if (this.canResize) {
      this.resize(
        x == null ? this.radius.x : this.radius.x + x,
        y == null ? this.radius.y : this.radius.y + y
      )
    }
  }
}
