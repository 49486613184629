import { state, getters } from './alerts.state'
import * as mutations from './alerts.mutations'
import * as actions from './alerts.actions'

export default {
  state,
  getters,
  mutations,
  actions
}
