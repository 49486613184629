import Konva from 'konva'
import { getId } from '@stellacontrol/utilities'
import { PlanLayer } from '@stellacontrol/planner'
import { Shape } from '../shapes/shape'
import { PlanEventEmitter } from '../events'
import { moveToTop } from '../utilities'

/**
 * Virtual layer renderer, implemented using {@link Konva.Group} for better performance
 */
export class Layer extends PlanEventEmitter {
  constructor (data) {
    super()
    Object.assign(this, data)
    this.__uuid = getId()
    const { item, id } = this
    this.item = item || new PlanLayer({ id })
    this.shapes = this.shapes || []
    if (data.isTrueLayer) {
      // True Konva.Layer
      this.content = new Konva.Layer({ name: id })
    } else {
      // Layer simulated with Konva.Group for better rendering performance
      this.content = new Konva.Group({ name: id })
    }
  }

  __stage
  __uuid

  /**
   * Instance identifier
   * @type {String}
   */
  get uuid () {
    return this.__uuid
  }


  /**
   * Plan layout
   * @type {PlanLayout}
   */
  layout

  /**
   * Floor on which the layer belongs
   * @type {PlanFloor}
   */
  floor

  /**
   * Layer details
   * @type {PlanLayer}
   */
  item

  /**
   * Element containing layer shapes
   * @type {Konva.Group}
   */
  content

  /**
   * Own shapes always present on the layer
   * @type {Array[Shape]}
   */
  ownShapes = []

  /**
   * Custom shapes added to the layer by the user
   * @type {Array[Shape]}
   */
  shapes

  /**
   * Layer identifier
   * @type {String}
   */
  get id () {
    return this.item?.id
  }

  /**
   * Checks whether the layer is visible
   * @type {Boolean}
   */
  get isVisible () {
    return Boolean(this.item?.isVisible)
  }

  /**
   * Checks whether the layer is locked
   * @type {Boolean}
   */
  get isLocked () {
    return Boolean(this.item?.isLocked)
  }

  /**
   * Checks whether the layer is listening to events
   * @type {Boolean}
   */
  get isListening () {
    return this.content.listening()
  }
  set isListening (value) {
    this.content.listening(Boolean(value))
  }

  /**
   * Indicates whether the layer is internal, and should not be made available
   * to the user to hide it or manipulate.
   * @type {Boolean}
   */
  get isInternal () {
    return Boolean(this.item?.isInternal)
  }

  /**
   * Returns the stage
   * @returns {Konva.Stage}
   */
  get stage () {
    if (this.__stage) {
      return this.__stage
    }
    let element = this.content
    while (element) {
      if (element.nodeType === 'Stage') {
        this.__stage = element
        return element
      } else {
        element = element.parent
      }
    }
    return undefined
  }

  /**
   * Sets visibility of the layer
   * @param {Boolean} value
   */
  setVisibility (value) {
    this.item.isVisible = Boolean(value)
    this.content.visible(Boolean(value))
  }

  /**
   * Sets visibility of the layer's background image
   * @param {Boolean} value
   */
  setBackgroundVisibility (value) {
    this.item.isBackgroundVisible = Boolean(value)
  }

  /**
   * Sets label of the layer
   * @param {String} value
   * @param {String} details
   */
  setLabel (value, details) {
    this.item.label = value
    this.item.details = details == undefined ? this.item.details : details
  }

  /**
   * Indicates that specified custom shape belongs to this layer
   * @param {Shape} shape Shape to check
   * @returns {Boolean}
   */
  hasShape (shape) {
    if (shape) {
      return (this.shapes || []).some(s => s.item.id === shape.item.id)
    } else {
      return false
    }
  }

  /**
   * Finds shape associated with the specified item
   * @param {PlanItem} item Plan item
   * @returns {Shape}
   */
  getItemShape (item) {
    if (item) {
      if (item.shape) {
        return item.shape
      } else {
        const { shapes } = this
        item.shape = shapes.find(shape => shape.item?.id === item.id)
        return item.shape
      }
    }
  }

  /**
   * Creates own shapes of the layer.
   * Override in specific layer to create fixed elements which are rendered
   * on the layer before rendering any custom elements added by the user
   * @return {Promise<Array[Shape|Konva.Shape]>}
   */
  async createOwnShapes () {
    return []
  }

  /**
   * Renders the layer
   * @returns {Promise}
   */
  async render () {
    // Destroy any shapes in the layer
    this.content.destroyChildren()

    // Destroy any previously created own shapes
    this.destroy(...this.ownShapes)

    // Add layer's own shapes
    const { isVisible, shapes } = this
    const ownShapes = await this.createOwnShapes()
    for (const shape of ownShapes) {
      this.renderShape(shape, true)
    }

    // Add custom shapes that belong to the layer
    for (const shape of shapes) {
      this.renderShape(shape, false)
    }

    // Set elements order
    this.reorder()

    // Set initial visibility of the layer
    this.ownShapes = ownShapes
    this.setVisibility(isVisible)
  }

  /**
   * Assigns correct z-order to shapes on the layer
   * @returns {Layer} Self-reference if reordering was performed
   */
  reorder () {
    const { content, shapes } = this

    // Reordering is not possible when layer is still not at the stage
    if (!content.getParent()) return

    // Move all custom elements to top
    for (const shape of shapes) {
      moveToTop(shape.content)
    }

    return this
  }

  /**
   * Renders a shape on the layer
   * @param {Shape|Konva.Shape} shape
   */
  renderShape (shape, isOwn) {
    if (!shape) return

    const { content, isLocked } = this

    if (shape instanceof Shape) {
      // Adding custom shape
      shape.content.setAttr('isOwn', isOwn)
      content.add(shape.content)
      // Determine if shape can be interacted with
      if (isLocked || shape.item.isLocked) {
        shape.lock()
      } else {
        shape.unlock()
      }
    } else {
      // Adding Konva primitive
      shape.setAttr('isOwn', isOwn)
      content.add(shape)
    }
  }

  /**
   * Adds a custom shape to the layer
   * @param {Shape} shape
   */
  add (shape) {
    if (shape) {
      this.shapes.push(shape)
      this.renderShape(shape)
    }
  }

  /**
   * Removes a custom shape from the layer
   * @param {Shape} shape
   * @returns {Layer} If layer owned the shape, it is returned
   */
  remove (shape) {
    if (shape && shape instanceof Shape && this.shapes.find(s => s.id === shape.id)) {
      this.shapes = this.shapes.filter(s => s.id !== shape.id)
      shape.item = null
      shape.destroy()
      return this
    }
  }

  /**
   * Destroys the specified shapes if they're defined
   * @param  {...Konva.Shape} shapes Shapes to destroy
   */
  destroy (...shapes) {
    for (const shape of shapes) {
      if (shape) {
        shape.destroy()
      }
    }
  }

  /**
   * Clears the layer
   * @param {Boolean} includeOwn If true, also layer's own elements are cleared
   */
  clear (includeOwn) {
    // Destroy custom shapes
    for (const shape of this.shapes) {
      shape.destroy()
    }
    this.shapes = []

    // Destroy own shapes
    if (includeOwn) {
      for (const shape of this.ownShapes) {
        shape.destroy()
      }
      this.ownShapes = []
    }
  }

  /**
   * Redraws the layer
   * @returns {Promise}
   */
  async refresh () {
    // Re-create own elements
    for (const shape of this.ownShapes) {
      shape.destroy()
    }
    this.ownShapes = await this.createOwnShapes()
    for (const shape of this.ownShapes) {
      this.renderShape(shape)
    }

    // Redraw all elements
    this.content?.draw()

    this.reorder()
  }

  /**
   * Notifies that the specified item has been added to the plan
   * @param {PlanItem} item Added item
   */
  // eslint-disable-next-line no-unused-vars
  itemAdded (item) {
  }

  /**
   * Notifies that the specified item has been removed from the plan
   * @param {PlanItem} item Removed item
   */
  itemRemoved (item) {
    // Remove the shape representing the removed item
    const shape = this.shapes.find(s => s.id === item.id)
    this.remove(shape)
  }

  /**
   * Notifies that the specified item has been moved
   * @param {PlanItem} item Moved item
   * @param {Boolean} completed If true, the movement has completed (usually when user releases the mouse button)
   */
  // eslint-disable-next-line no-unused-vars
  itemMoved (item, completed) {
  }

  /**
   * Notifies that the specified item properties have changed
   * @param {PlanItem} item Changed item
   */
  // eslint-disable-next-line no-unused-vars
  itemChanged (item, delta) {
  }

  /**
   * Notifies that the specified item has been selected
   * @param {PlanItem} item Selected item
   */
  // eslint-disable-next-line no-unused-vars
  itemSelected (item) {
  }
}
