import pkg from '../package.json'
export * from './store'
export * from './components'
export * from './dialogs'
export * from './views'
export * from './router'

import { AppletRoutes as routes } from './router'
import { AppletStores as stores } from './store'
import { AppletViews as views } from './views'
import { useServiceManagementComponents } from './components'
import { useServiceManagementDialogs } from './dialogs'

/**
 * Installs Service Management applet into the application
 * @param application Vue application instance
 */
export function useServiceManagementApplet (application) {
  useServiceManagementComponents(application)
  useServiceManagementDialogs(application)

  const { name, version, description } = pkg
  return { name, version, description, routes, views, stores }
}
