import { dispatch, isRouteDataChanged } from '@stellacontrol/client-utilities'

/**
 * Actions to be executed when resolving organization place route
 * @param from Source route
 * @param to Target route
 */
export async function resolveOrganizationPlace ({ from, to }) {
  if (!isRouteDataChanged(from, to)) {
    return true
  }

  await dispatch('requireOrganizations')

  const { params: { id, organizationId } } = to
  const organization = await dispatch('getOrganization', { id: organizationId })
  if (organization) {
    const data = id === 'new'
      ? await dispatch('newOrganizationPlace', { organization })
      : await dispatch('getOrganizationPlace', { id, organization })

    if (data) {
      await dispatch('setRouteData', { from, to, data })
      return true
    }
  }
}
