<script>
import { Viewport } from '../../responsive'

/**
 * Icon with tooltip, for things like input field hints etc.
 */
export default {
  props: {
    // Icon
    icon: {
      type: String,
      default: 'info_outline'
    },

    // Color of the icon
    color: {
      type: String,
      default: 'grey-6'
    },

    // Icon size
    size: {
      type: String
    },

    // Tooltip text
    text: {
      type: String
    },

    // Tooltip width
    width: {
      type: String,
      default: '350px'
    },

    // Tooltip class
    tooltipClass: {
      type: Object
    },

    /**
     * CSS class applied to tooltip content
     */
    contentClass: {
      type: Object
    }
  },

  computed: {
    iconSize () {
      return this.size || (Viewport.isSmallScreen ? '28px' : '24px')
    },

    tooltipContainerClass () {
      const result = {
        ...(this.tooltipClass || {})
      }
      return result
    },

    tooltipContentClass () {
      const result = {
        'bg-grey-8': true,
        'text-white': true,
        'text-body2': true,
        ...(this.contentClass || {})
      }
      return result
    }
  }
}
</script>

<template>
  <q-icon class="hint" :name="icon" :color="color" :size="iconSize">
    <q-tooltip
      transition-show="fade"
      transition-hide="fade"
      :maxWidth="width"
      :class="tooltipContainerClass"
      :contentClass="tooltipContentClass">
      <slot>
        <span v-html="text"></span>
      </slot>
    </q-tooltip>
  </q-icon>
</template>

<style scoped lang="scss">
.hint {
  cursor: pointer;
  margin-left: 2px;
  margin-right: 2px;
}

/* Tooltip styles are found in tooltip.vue */
</style>