/**
 * Hard-coded security rules for sanitization of requests
 */
export const SecurityRules = {
  /**
   * Checks whether the initiator can create or edit the specified user
   * @param initiatorGuardian Security guardian with details of user who initiated the request
   * @param user User to be edited or created
   * @returns Structure containing `canEditUser` property and `reason` property indicating a reason for denial
   * @description User can be edited only:
   * - by super administrator
   * - by administrator in the same organization
   * - by administrator in the parent organization if it has permission to manage child organizations
   */
  canEditUser (initiatorGuardian, user) {
    if (!initiatorGuardian) {
      return { canEditUser: false, reason: 'Unknown initiator' }
    }

    if (!user) {
      return { canEditUser: false, reason: 'User is not specified' }
    }

    const initiator = initiatorGuardian.principal
    if (!initiator.isEnabled) {
      return { canEditUser: false, reason: 'Initiator is disabled' }
    }

    if (!initiator.isAdministrator) {
      return { canEditUser: false, reason: 'Initiator is not an administator' }
    }

    if (initiator.isSuperAdministrator) {
      return { canEditUser: true }
    }

    if (initiator.organizationId === user.organizationId) {
      if (initiator.canUse('child-users')) {
        return { canEditUser: true }
      }
      return { canEditUser: false, reason: 'Not allowed to manage users in this organization' }
    }

    return { canEditUser: false, reason: 'Not allowed to edit this user' }
  },

  /**
   * Checks whether the initiator can delete the specified user
   * @param initiator User who initiated the request
   * @param user User to be deleted
   * @returns Structure containing `canDeleteUser` property and `reason` property indicating a reason for denial
   * @description User can be deleted only:
   * - by super administrator
   * - by administrator in the same organization
   * but not if:
   * - initiator is the same as the user to be deleted
   * - user is a super-administrator
   */
  canDeleteUser (initiator, user) {
    if (!initiator) {
      return { canDeleteUser: false, reason: 'Unknown initiator' }
    }

    if (!user) {
      return { canDeleteUser: false, reason: 'User is not specified' }
    }

    if (!initiator.isEnabled) {
      return { canDeleteUser: false, reason: 'Initiator is disabled' }
    }

    if (initiator.sameAs(user)) {
      return { canDeleteUser: false, reason: 'You cannot delete yourself' }
    }

    if (user.isSuperAdministrator) {
      return { canDeleteUser: false, reason: 'You cannot delete a super administrator.\nHis level needs to be downgraded to a regular user first.' }
    }

    if (initiator.isAdministrator) {
      return { canDeleteUser: true }
    }

    if (initiator.isSuperAdministrator) {
      return { canDeleteUser: true }
    }

    return { canDeleteUser: false, reason: 'Not allowed to delete this user' }
  },

  /**
   * Checks whether the initiator can delete the specified organization profile
   * @param initiator User who initiated the request
   * @param organizationProfile Organization profile to be deleted
   * @returns Structure containing `canDeleteOrganizationProfile` property and `reason` property indicating a reason for denial
   * @description Organization profile can be deleted only:
   * - by super administrator
   * - by profile owner
   * but not if:
   * - Profile represents a super organization
   * - Profile has organizations assigned to it
   */
  canDeleteOrganizationProfile (initiator, organizationProfile) {
    if (!initiator) {
      return { canDeleteOrganizationProfile: false, reason: 'Unknown initiator' }
    }

    if (!initiator.isEnabled) {
      return { canDeleteOrganizationProfile: false, reason: 'Initiator is disabled' }
    }

    if (!organizationProfile) {
      return { canDeleteOrganizationProfile: false, reason: 'Organization profile is not specified' }
    }

    if (!initiator.isAdministrator) {
      return { canDeleteOrganizationProfile: false, reason: 'Only administrator can delete organization profiles' }
    }

    if (!initiator.isSuperAdministrator && initiator.organization.id !== organizationProfile.ownerId) {
      return { canDeleteOrganizationProfile: false, reason: 'Only super administrator can delete this profile' }
    }

    if (initiator.organization && initiator.organization.profileId === organizationProfile.id) {
      return { canDeleteOrganizationProfile: false, reason: 'You cannot delete a profile to which your own organization belongs' }
    }

    if (organizationProfile.isSuperOrganization) {
      return { canDeleteOrganizationProfile: false, reason: 'Super-organization profile cannot be deleted.\nYou must downgrade it first.' }
    }

    if (organizationProfile.hasOrganizations) {
      return { canDeleteOrganizationProfile: false, reason: 'The profile cannot be deleted because there are organizations assigned to it.\nAssign the organizations to some other profile first.' }
    }

    return { canDeleteOrganizationProfile: true }
  },

  /**
   * Checks whether the initiator can delete the specified organization
   * @param initiator User who initiated the request
   * @param organization Organization to be deleted
   * @returns Structure containing `canDeleteOrganization` property and `reason` property indicating a reason for denial
   * @description Organization can be deleted only:
   * - by super administrator
   * - by administrator in a parent organization
   * but not if:
   * - organization is super organization
   * - organization is not empty (has users and/or child organizations)
   */
  canDeleteOrganization (initiator, organization) {
    if (!initiator) {
      return { canDeleteOrganization: false, reason: 'Unknown initiator' }
    }

    if (!initiator.isEnabled) {
      return { canDeleteOrganization: false, reason: 'Initiator is disabled' }
    }

    if (!organization) {
      return { canDeleteOrganization: false, reason: 'Organization is not specified' }
    }

    if (organization.isSuperOrganization) {
      return { canDeleteOrganization: false, reason: 'Super organization cannot be deleted' }
    }

    if (organization.hasChildOrganizations) {
      return { canDeleteOrganization: false, reason: 'Organization cannot be deleted because it has child organizations.\nDelete child organizations first or assign them to another reseller organization.' }
    }

    if (organization.hasUsers) {
      return { canDeleteOrganization: false, reason: 'Organization cannot be deleted because it has users.\nDelete all the users first.' }
    }

    return { canDeleteOrganization: true }
  },

  /**
   * Checks whether the initiator can change the password of the specified user
   * @param initiator User who initiated the request
   * @param user User whose password is about to be reset
   * @returns Structure containing `canChangePassword` property and `reason` property indicating a reason for denial
   * @description Reset passwords of other users is only allowed if:
   * - initiator is the user himself
   * - initiator is administrator in the same organization
   * - initiator is super administrator
   */
  canChangePassword (initiator, user) {
    if (!initiator) {
      return { canChangePassword: false, reason: 'Unknown initiator' }
    }

    if (!initiator.isEnabled) {
      return { canChangePassword: false, reason: 'Initiator is disabled' }
    }

    if (!user) {
      return { canChangePassword: false, reason: 'User is not specified' }
    }

    if (initiator.sameAs(user)) {
      return { canChangePassword: true }
    }

    if (initiator.isAdministrator && initiator.sameOrganizationAs(user)) {
      return { canChangePassword: true }
    }

    if (initiator.isAdministrator && initiator.organization.isParentOf(user.organization)) {
      return { canChangePassword: true }
    }

    if (initiator.isSuperAdministrator) {
      return { canChangePassword: true }
    }

    if (initiator.isAdministrator) {
      return { canChangePassword: true }
    }

    return { canChangePassword: false, reason: 'Not allowed to change password of this user' }
  },

  /**
   * Checks whether the initiator can edit permissions of the specified principal
   * @param initiator User who initiated the request
   * @param principal Principal whose permissions are to be edited
   * @returns Structure containing `canEditPermissions` property and `reason` property indicating a reason for denial
   * @description Editing permissions depends on the principal type.
   * For users it is allowed if:
   * - initiator is super administrator
   * - initiator is administrator in the same organization
   * - initiator is NOT the user himself
   */
  canEditPermissions (initiator, principal) {
    if (!initiator) {
      return { canEditPermissions: false, reason: 'Unknown initiator' }
    }

    if (!initiator.isEnabled) {
      return { canEditPermissions: false, reason: 'Initiator is disabled' }
    }

    if (!principal) {
      return { canEditPermissions: false, reason: 'Principal is not specified' }
    }

    if (principal.isUser) {
      if (initiator.sameAs(principal)) {
        return { canEditPermissions: false, reason: 'You cannot edit your own permissions' }
      }
      if (initiator.isSuperAdministrator) {
        return { canEditPermissions: true }
      }
      if (initiator.isAdministrator && initiator.sameOrganizationAs(principal)) {
        return { canEditPermissions: true }
      }
    }

    return { canEditPermissions: true }
  },

  /**
   * Checks whether the user can access devices
   * of all other organizations
   * @param initiator User initiating a request
   * @description This is allowed if user's organization is a super organization
   */
  canAccessAllDevices (initiator) {
    if (!initiator) {
      return { canAccessAllDevices: false, reason: 'Unknown initiator' }
    }

    if (!initiator.isEnabled) {
      return { canAccessAllDevices: false, reason: 'Initiator is disabled' }
    }

    if (!initiator.organization) {
      return { canAccessAllDevices: false, reason: 'Unknown organization of the initiator' }
    }

    if (!initiator.organization.isSuperOrganization) {
      return { canAccessAllDevices: false, reason: 'Initiator is not a super organization' }
    }

    return { canAccessAllDevices: true }
  },

  /**
   * Checks whether the user can access devices of a specified organization
   * @param initiator User initiating a request
   * @param organization Organization whose devices are to be accessed
   * @description This is allowed if user's organization is a reseller organization of the specified child organization
   */
  canAccessDevicesOf (initiator, organization) {
    if (!initiator) {
      return { canAccessDevicesOf: false, reason: 'Unknown initiator' }
    }

    if (!initiator.isEnabled) {
      return { canAccessDevicesOf: false, reason: 'Initiator is disabled' }
    }

    if (!initiator.organization) {
      return { canAccessDevicesOf: false, reason: 'Unknown organization of the initiator' }
    }

    if (!organization) {
      return { canAccessDevicesOf: false, reason: 'Unknown organization' }
    }

    if (initiator.organization.isSuperOrganization) {
      return { canAccessDevicesOf: true }
    }

    // TODO: enable again, but with check up the hierarchy, as now parent of a parent would be rejected here
    // if (organization.id !== initiator.organizationId && organization.parentOrganizationId !== initiator.organizationId) {
    //   return { canAccessDevicesOf: false, reason: 'Initiator is not a parent organization' }
    // }

    return { canAccessDevicesOf: true }
  },

  /**
   * Checks whether the user can decommission the specified device
   * @param initiator User initiating a request
   * @param device Device to decommission
   * @description This is allowed if user is an admin in a super organization only
   */
  canDecommissionDevice (initiator, device) {
    if (!initiator) {
      return { canDecommissionDevice: false, reason: 'Unknown initiator' }
    }

    if (!initiator.isEnabled) {
      return { canDecommissionDevice: false, reason: 'Initiator is disabled' }
    }

    if (!initiator.organization) {
      return { canDecommissionDevice: false, reason: 'Unknown organization of the initiator' }
    }

    if (!initiator.isSuperAdministrator) {
      return { canDecommissionDevice: false, reason: 'Initiator is not an administrator in super organization' }
    }

    if (!device) {
      return { canDecommissionDevice: false, reason: 'Unknown device' }
    }

    if (device.isDecommissioned) {
      return { canDecommissionDevice: false, reason: 'Device has already been removed from inventory' }
    }

    return { canDecommissionDevice: true }
  },

  /**
   * Checks whether the user can reset the specified device
   * @param initiator User initiating a request
   * @param device Device to reset
   * @description This is allowed if user is an admin in a super organization or reseller organization with access to the device
   */
  canResetDevice (initiator, device) {
    if (!initiator) {
      return { canResetDevice: false, reason: 'Unknown initiator' }
    }

    if (!initiator.isEnabled) {
      return { canResetDevice: false, reason: 'Initiator is disabled' }
    }

    if (!initiator.organization) {
      return { canResetDevice: false, reason: 'Unknown organization of the initiator' }
    }

    if (!(initiator.isSuperAdministrator || initiator.isResellerAdministrator)) {
      return { canResetDevice: false, reason: 'Initiator is not an administrator in super organization or a reseller organization' }
    }

    if (!device) {
      return { canResetDevice: false, reason: 'Unknown device' }
    }

    return { canResetDevice: true }
  },

  /**
   * Checks whether the user can delete the specified place
   * @param initiator User initiating a request
   * @param place Place to delete
   * @description This is allowed if user is admin in organization or parent organization with access to places of child organizations
   */
  canDeletePlace (initiator, place) {
    if (!initiator) {
      return { canDeletePlace: false, reason: 'Unknown initiator' }
    }

    if (!place) {
      return { canDeletePlace: false, reason: 'Unknown place' }
    }

    if (!initiator.isEnabled) {
      return { canDeletePlace: false, reason: 'Initiator is disabled' }
    }

    if (!initiator.organization) {
      return { canDeletePlace: false, reason: 'Unknown organization of the initiator' }
    }

    if (initiator.isSuperAdministrator) {
      return { canDeletePlace: true }
    }

    if (initiator.isAdministrator) {
      return { canDeletePlace: true }
    }

    return { canDeletePlace: false, reason: 'The user has no permissions to remove this place' }
  },

  /**
   * Checks whether the user can delete the specified floor plan
   * @param initiator User initiating a request
   * @param floorPlan Floor plan to delete
   * @description This is allowed if user is super admin
   */
  canDeleteFloorPlan (initiator, floorPlan) {
    if (!initiator) {
      return { canDeleteFloorPlan: false, reason: 'Unknown initiator' }
    }

    if (!floorPlan) {
      return { canDeleteFloorPlan: false, reason: 'Unknown floor plan' }
    }

    if (!initiator.isEnabled) {
      return { canDeleteFloorPlan: false, reason: 'Initiator is disabled' }
    }

    if (!initiator.organization) {
      return { canDeleteFloorPlan: false, reason: 'Unknown organization of the initiator' }
    }

    if (initiator.isSuperAdministrator) {
      return { canDeleteFloorPlan: true }
    }

    if (initiator.organization.id === floorPlan.organizationId) {
      return { canDeleteFloorPlan: true }
    }

    return { canDeleteFloorPlan: false, reason: 'User not allowed to delete floor plans' }
  }
}
