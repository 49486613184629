import { Routes as alertsRoutes } from '../views/alerts'

/**
 * Routes defined in this applet
 */
export const AppletRoutes = [
  ...alertsRoutes
]

/**
 * Enumeration of route names in this applet
 */
export const AppletRoute = {
  Alerts: 'alerts'
}
