import { clone, delay, Point, Rectangle, countString } from '@stellacontrol/utilities'
import { Notification } from '@stellacontrol/client-utilities'
import { createPlanItem } from '@stellacontrol/planner'
import { PlanAction, PlanActions } from './plan-action'
import { AddItemAction } from './add-item'
import { PlanClipboard } from '../utilities/clipboard'

/**
 * Duplicates the specified items
 */
export class DuplicateItemsAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.DuplicateItems
  }

  /**
   * Action label
   * @type {String}
   */
  get label () {
    const { isBatch, count } = this
    return isBatch ? `Duplicate ${count} items` : 'Duplicate'
  }

  /**
   * Action icon
   * @type {String}
   */
  get icon () {
    return 'content_copy'
  }

  /**
   * Indicates that the action requires items to act on
   * @type {Boolean}
   */
  get requiresItems () {
    return true
  }

  /**
   * Indicates whether action requires refresh
   * @type {Boolean}
   */
  get requiresRefresh () {
    return true
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   * @param {Array[PlanItem]} items Plan items to apply the action to
   */
  async execute ({ renderer, items } = {}) {
    if (renderer && items) {
      // Paste at the location where action was executed
      const newItems = []
      for (const source of items) {
        // Clone the item next to the source item
        const data = clone(source.toJSON())
        const item = createPlanItem({ ...data, id: undefined, tag: undefined, tagIndex: undefined })

        // Add the item to the layout
        const action = new AddItemAction({ items: [item] })
        await action.execute({ renderer, items: [item] })
        newItems.push(item)
      }

      // Move the items so that they don't overlap.
      if (items.length === 1) {
        // Single item is moved next to the source item.
        const item = newItems[0]
        const scale = renderer.getEquipmentScale(item)
        const bounds = item.getScaledBounds(scale, renderer.isCrossSection)
        const delta = Point.from({ x: bounds.width + 20 })
        item.moveBy(delta, renderer.isCrossSection)

      } else {
        // Multiple items are moved below the source group.
        const rectangles = items.map(item => item.getBounds(renderer.isCrossSection))
        const bounds = Rectangle.fromRectangles(rectangles)
        const delta = Point.from({ y: bounds.height + 20 })
        for (const item of newItems) {
          item.moveBy(delta, renderer.isCrossSection)
        }
      }

      // Mark newly added items as selected
      await delay(200)
      renderer.refreshItems({ items: newItems })
      renderer.selectItems({ items: newItems })
      renderer.changed()
    }
  }
}

/**
 * Copies the specified items to the clipboard
 */
export class CopyItemsAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.CopyItems
  }

  /**
   * Action label
   * @type {String}
   */
  get label () {
    const { isBatch, count } = this
    return isBatch ? `Copy ${count} items` : 'Copy'
  }

  /**
   * Action icon
   * @type {String}
   */
  get icon () {
    return 'content_copy'
  }

  /**
   * Indicates that the action requires items to act on
   * @type {Boolean}
   */
  get requiresItems () {
    return true
  }

  /**
   * Indicates whether action requires refresh
   * @type {Boolean}
   */
  get requiresRefresh () {
    return false
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   * @param {Array[PlanItem]} items Plan items to apply the action to
   */
  execute ({ renderer, items } = {}) {
    if (renderer && items?.length > 0) {
      const sourcePosition = renderer.currentPosition
      PlanClipboard.write(items, sourcePosition)
      Notification.success({
        message: `${countString(items, 'shape')} copied`,
        details: 'Press CTRL+V to paste them, also onto another floor'
      })
    }
  }
}

/**
 * Pastes specified items from the clipboard
 */
export class PasteItemsAction extends PlanAction {
  constructor (data = {}) {
    super(data)
  }

  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.PasteItems
  }

  /**
  * Action label
  * @type {String}
  */
  get label () {
    return super.label || 'Paste'
  }

  /**
   * Action icon
   * @type {String}
   */
  get icon () {
    return 'content_paste'
  }

  /**
   * Indicates whether action requires refresh
   * @type {Boolean}
   */
  get requiresRefresh () {
    return true
  }

  /**
   * If true, the current selection will be preserved
   * after the action has been executed
   * @type {Boolean}
   */
  get preserveSelection () {
    return true
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   * @param {Point} position Position at which the action should be executed
   */
  async execute ({ renderer, position } = {}) {
    if (renderer) {
      // Get clipboard content
      const { items, position: sourcePosition } = PlanClipboard.read() || {}
      if (!(items && position)) return

      // Get connectors - they can be added only after all other elements have been pasted
      const connectors = items.filter(item => item.isConnector)
      const equipment = items.filter(item => !item.isConnector)

      // Paste the equipment at the location where action was executed,
      // in the right sequence - first the equipment, then the connectors
      const { isCrossSection } = renderer
      for (const group of [equipment, connectors]) {
        for (const item of group) {
          item.clearTag()
          const action = new AddItemAction({ items: [item] })
          // Move the item to where the action was executed, and add a bit of a shift
          const coordinates = item.getCoordinates(isCrossSection)
          const delta = position.delta(sourcePosition || coordinates)
          item.moveBy(delta, isCrossSection)

          // Add the item
          action.execute({ renderer, items: [item] })
        }
      }

      // Mark newly added items as selected
      await delay(200)
      renderer.refreshItems({ items })
      renderer.selectItems({ items, showMenu: false })
      renderer.changed()
    }
  }
}
