import { createState } from './organization-profiles.state'

export const mutations = {
  /**
   * Stores organization profiles in the state
   * @param organizationProfiles Organization profiles to store
   */
  storeOrganizationProfiles (state, { organizationProfiles = [] } = {}) {
    state.items = organizationProfiles
  },

  /**
   * Stores an organization profile in the state
   * @param organizationProfile Organization profile to store
   */
  storeOrganizationProfile (state, { organizationProfile } = {}) {
    if (organizationProfile) {
      if (!state.items) {
        state.items = []
      }
      const { id } = organizationProfile
      const index = id ? state.items.findIndex(i => i.id === id) : -1
      if (index === -1) {
        state.items.push(organizationProfile)
      } else {
        state.items[index] = organizationProfile
      }
      state.lastUpdated = organizationProfile
    }
  },

  /**
   * Removes an organization profile from the state
   * @param organizationProfile Organization profile to remove
   */
  removeOrganizationProfile (state, { organizationProfile = {} } = {}) {
    const { id } = organizationProfile
    if (id) {
      const index = (state.items || []).findIndex(i => i.id === id)
      if (index !== -1) {
        state.items.splice(index, 1)
      }
    }
  },

  /**
   * Reset the state
   */
  reset (state) {
    Object.assign(state, createState())
  }
}
