<script>
import { formatTimePart, DaySchedule } from '@stellacontrol/utilities'

export default {
  props: {
    /**
     * Initial schedule
     */
    modelValue: {
      type: DaySchedule,
      required: true
    },

    /**
     * Enabled hours.
     * If specified, only these hours will be selectable.
     * Use to enforce schedules only within certain times of the day
     */
    enabledHours: {
      type: Array,
      default: () => []
    },

    /**
     * Disabled hours.
     * If specified, these hours will not be selectable.
     * Use to enforce schedules only within certain times of the day
     */
    disabledHours: {
      type: Array,
      default: () => []
    },

    // Control is read-only
    readonly: {
      type: Boolean,
      default: false
    },

    // Control is disabled
    disabled: {
      type: Boolean,
      default: false
    },

    // Rows to split the input into
    rows: {
      type: Number,
      default: 2
    }
  },

  data () {
    return {
      // Selected hours
      selected: []
    }
  },

  computed: {
    // All hours
    hours () {
      return DaySchedule.AllHours
    },

    // Hours to select from
    selectableHours () {
      const { hours, enabledHours, disabledHours } = this
      return hours
        .filter(hour => disabledHours.length === 0 || !disabledHours.includes(hour))
        .filter(hour => enabledHours.length === 0 || enabledHours.includes(hour))
    },

    // Hour groups, to render table rows
    groups () {
      const { rows, hours } = this
      const chunk = Math.ceil(hours.length / (rows || 2))
      const groups = []
      const allHours = [...hours]
      while (allHours.length > 0) {
        const group = allHours.splice(0, chunk)
        while (group.length < chunk) {
          group.push(null)
        }
        groups.push(group)
      }
      return groups
    },

    // Entered schedule
    schedule () {
      const schedule = DaySchedule.fromHours(this.selected)
      return schedule
    },

    // Returns CSS class for the specified hour
    hourClass () {
      return hour => ({
        selectable: hour != null && this.selectableHours.includes(hour),
        selected: hour != null && this.selected.includes(hour)
      })
    }
  },

  methods: {
    formatTimePart,

    // Assigns the specified schedule to the editor
    assign (schedule) {
      if (schedule && !schedule.isEmpty) {
        this.selected = schedule.toHours()
      } else {
        this.selected = []
      }
    },

    // Selects or deselects the specified hour
    toggleHour (hour) {
      const index = this.selected.indexOf(hour)
      if (index === -1) {
        this.selected.push(hour)
      } else {
        this.selected.splice(index, 1)
      }
      this.$emit('update:model-value', this.schedule)
    }
  },

  watch: {
    modelValue (schedule) {
      this.assign(schedule)
    }
  },

  created () {
    this.assign(this.modelValue)
  }
}
</script>

<template>
  <div>
    <table class="hours">
      <tbody>
        <tr v-for="group in groups" :key="`group-${group}`">
          <td v-for="hour in group" :key="`hour-${hour}`"
            :class="hourClass(hour)"
            @click="toggleHour(hour)">
            <div :class="hourClass(hour)">
              {{ hour == null ? "" : formatTimePart(hour) }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style lang="scss" scoped>
table.hours {
  border-collapse: collapse;
  border: solid silver 1px;

  tr {
    td {
      padding: 0;
      width: 44px;
      height: 40px;
      font-weight: normal;
      background-color: #ebebeb;

      &.selectable {
        background-color: #fafafa;
        cursor: pointer;
      }

      > div {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        transition: all 0.1s ease-out;
        border-right: solid silver 1px;
        border-bottom: solid silver 1px;

        &.selectable {
          &:hover {
            background-color: #f0f0f0;
          }
        }

        &.selectable.selected {
          &:hover {
            background-color: #b8c8f5;
          }
        }

        &.selected {
          background-color: #c7d6ff;
        }
      }

      &:last-child {
        > div {
          border-right: none;
        }
      }
    }

    &:last-child {
     td {
       > div {
        border-bottom: none;
       }
     }
    }
  }
}
</style>