/**
 * Horizontal alignments
 */
export const PlanHorizontalAlignment = {
  Left: 'left',
  Center: 'center',
  Right: 'right'
}

/**
 * Vertical alignments
 */
export const PlanVerticalAlignment = {
  Top: 'left',
  Middle: 'middle',
  Bottom: 'bottom'
}
