import { isEnum } from '@stellacontrol/utilities'
import { Assignable } from '../common/assignable'
import { DeviceType } from './device-type'

/**
 * Product part
 */
export class ProductPart extends Assignable {
  constructor (data = {}) {
    super()
    this.assign(data, {})
    if (!isEnum(DeviceType, this.type)) throw new Error(`Invalid part type [${this.type}]`)
    if (!this.id) throw new Error('Part identifier is required')
    if (!this.models || this.models.length === 0) throw new Error('Part models are required')
    if (!this.name) throw new Error('Part name is required')
  }

  /**
   * Unique identifier
   * @type {String}
   */
  id

  /**
   * Part name, indicating its function within the product,
   * eg. 'Main Amplifier'
   * @type {String}
   */
  name

  /**
   * Component description
   * @type {String}
   */
  description

  /**
   * Component type
   * @type {DeviceType}
   */
  type

  /**
   * Nodels of the component allowed as this product part
   * @type {String}
   */
  models
}
