import { Log } from '@stellacontrol/utilities'
import * as PlanItems from '../items'

/**
 * Returns the class of the specified plan item
 * @param {Object} data Item data
 * @returns {Function} Item constructor
 */
export function getPlanItemClass (data) {
  if (!data) {
    throw new Error('Item data is required')
  }

  if (!data.type) {
    Log.error('Unknown item type. The item will be discarded.', data)
    return
  }

  const itemClass = Object.values(PlanItems).find(c => c.type === data.type)
  if (!itemClass) {
    Log.error(`[${data.type}] Unsupported plan item. The item will be discarded.`, data)
    return
  }

  if (!itemClass.type) {
    Log.error(`[${data.type}] Item class requires static 'type' property. The item will be discarded.`, data)
    return
  }

  return itemClass
}

/**
 * Creates a plan item initialized with the specified data
 * @param {Object} data Item data
 * @returns {PlanItem} Plan item
 */
export function createPlanItem (data) {
  const itemClass = getPlanItemClass(data)
  if (itemClass) {
    const item = new itemClass(data)
    return item
  }
}
