/**
 * Returns true if route has changed - namely from.name and to.name are different
 * @param {Route} from Source route
 * @param {Route} to Target route
 */
export function isRouteChanged (from, to) {
  return from && to && from.name !== to.name
}

/**
 * Returns true if identifier of edited object changed on the route,
 * so new data needs to be retrieved.
 * @param {Route} from Source route
 * @param {Route} to Target route
 */
export function isRouteDataChanged (from, to) {
  return from && to && (from.name !== to.name || from.params.id !== to.params.id)
}
