import { DeviceLinkType, OrganizationLevel, AuditAction, AuditSubject, Assignable, User, Organization } from '@stellacontrol/model'

/**
 * Device audit event level
 */
export const DeviceAuditEventLevel = {
  Information: 'information',
  Warning: 'warning',
  Error: 'error'
}

/**
 * Device audit event type
 */
export const DeviceAuditEventType = {
  Event: 'event',
  Status: 'status',
  Alert: 'alert',
  CommandSent: 'command',
  Manufactured: 'manufactured',
  Decommissioned: 'decommissioned',
  Added: 'added',
  Removed: 'removed',
  OwnerChanged: 'owner',
  Shared: 'shared',
  Updated: 'updated',
  Reset: 'reset',
  FirmwareUpdated: 'firmware',
  SettingsChanged: 'settings',
  PremiumServiceAssigned: 'ps-assigned',
  PremiumServiceActivated: 'ps-activated',
  PremiumServiceExpired: 'ps-expired'
}

/**
 * Device audit event description
 */
export const DeviceAuditEventDescription = {
  [DeviceAuditEventType.Event]: 'Event',
  [DeviceAuditEventType.Status]: 'Status',
  [DeviceAuditEventType.Alert]: 'Alert',
  [DeviceAuditEventType.CommandSent]: 'Command sent',
  [DeviceAuditEventType.Manufactured]: 'Device manufactured',
  [DeviceAuditEventType.Decommissioned]: 'Device decommissioned',
  [DeviceAuditEventType.Added]: 'Device added to inventory',
  [DeviceAuditEventType.Removed]: 'Device removed from inventory',
  [DeviceAuditEventType.OwnerChanged]: 'Device owner changed',
  [DeviceAuditEventType.Shared]: 'Device shared',
  [DeviceAuditEventType.Updated]: 'Device updated',
  [DeviceAuditEventType.Reset]: 'Device reset',
  [DeviceAuditEventType.FirmwareUpdated]: 'Firmware updated',
  [DeviceAuditEventType.SettingsChanged]: 'Settings changed',
  [DeviceAuditEventType.PremiumServiceAssigned]: 'Premium service assigned',
  [DeviceAuditEventType.PremiumServiceActivated]: 'Premium service activated',
  [DeviceAuditEventType.PremiumServiceExpired]: 'Premium service expired'
}

/**
 * Device audit
 */
export class DeviceAudit {
  /**
   * Parses device details and history, returns a list of device audit items
   * @param {Device} device Device details, including links, versions, creator, updater etc.
   * @param {User} user User viewing the audit
   * @param {Organization} organization Organization where the user belongs
   * @param {Boolean} descending If true, the audit is sorted in descending order
   * @param {Array[AuditItem]} audit Audit trail of the device
   * @param {Array[UploadJob]} firmwareUpdates List of completed firmware updates of the device
   * @returns {Array[DeviceAudit]} Device audit
   */
  static create ({ device, user, organization, descending, audit, firmwareUpdates } = {}) {
    if (!device) throw new Error('Device is required')
    if (!user) throw new Error('User is required')
    if (!organization) throw new Error('Organization is required')
    const { versions, links } = device
    const items = []

    // Clears details message from unnecessary information
    const clearDetails = details => {
      return details.replace(/\[.*?\]/g, '')
    }

    // Get the initial and the most recent device version
    let firstVersion
    if (versions) {
      versions.sort((a, b) => a.createdAt > b.createdAt ? 1 : -1)
      firstVersion = versions[0]
    }

    // EVENT: Manufacture date, with initial versions
    if (device.manufacturedAt) {
      items.push(new DeviceAuditItem({
        organizationId: device.creator.organizationId,
        date: device.manufacturedAt,
        details: firstVersion ? firstVersion.getVersionString() : '',
        type: DeviceAuditEventType.Manufactured
      }))
    }

    // EVENT: Added to inventory
    items.push(new DeviceAuditItem({
      organizationId: device.creator.organizationId,
      date: device.createdAt,
      user: device.creator,
      type: DeviceAuditEventType.Added
    }))

    // EVENTS: Ownership changes and sharing
    if (links) {
      links.sort((a, b) => a.createdAt > b.createdAt ? 1 : -1)
      for (const link of links) {
        const organization = link.principal
        const isSuperOrganization = link.principal.level === OrganizationLevel.SuperOrganization
        const isGuest = link.principal.level === OrganizationLevel.GuestOrganization

        if (link.type === DeviceLinkType.Owner) {
          // Ignore if first ownership is super-organization itself,
          // no need to bother the user with the obvious.
          const isFirstOwnershipSuperOrg = links.filter(l => l.type === DeviceLinkType.Owner)[0].id === link.id && isSuperOrganization
          if (!isFirstOwnershipSuperOrg) {
            items.push(new DeviceAuditItem({
              organization: link.creator.organization,
              targetOrganizationId: link.principalId,
              date: link.createdAt,
              description: isSuperOrganization ? `Owner set to ${organization.name}` : `Sold to ${organization.name}`,
              details: isSuperOrganization ? 'Owner changed' : `Device sold to ${organization.name}`,
              principalId: organization.id,
              user: link.creator,
              type: DeviceAuditEventType.OwnerChanged
            }))
          }
        }

        if (link.type === DeviceLinkType.Delegate) {
          if (link.isCurrent) {
            // Report sharing of the device with another organization
            items.push(new DeviceAuditItem({
              organization: link.creator.organization,
              targetOrganizationId: link.principalId,
              date: link.createdAt,
              description: `Shared with ${organization.name}`,
              details: `Shared with ${isGuest ? 'guest organization ' : ''}${organization.name}`,
              user: link.creator,
              principalId: organization.id,
              type: DeviceAuditEventType.Shared
            }))
          } else if (link.deactivatedAt) {
            // Report unsharing
            items.push(new DeviceAuditItem({
              organization: link.deactivator.organization,
              targetOrganizationId: link.principalId,
              date: link.deactivatedAt,
              description: `No longer shared with ${organization.name}`,
              details: `Unshared with ${organization.name}`,
              user: link.deactivator,
              type: DeviceAuditEventType.Shared,
              level: DeviceAuditEventLevel.Warning
            }))
          }
        }
      }
    }

    // Firmware updates
    if (firmwareUpdates) {
      for (const firmwareUpdate of firmwareUpdates) {
        items.push(new DeviceAuditItem({
          organizationId: firmwareUpdate.creator.organizationId,
          organization: firmwareUpdate.creator.organization,
          user: firmwareUpdate.creator,
          date: firmwareUpdate.createdAt,
          details: `Firmware updated to v.${firmwareUpdate.payload?.versionString}`,
          type: DeviceAuditEventType.FirmwareUpdated
        }))
      }
    }

    // Decomissioning
    if (device.decommissionedAt) {
      items.push(new DeviceAuditItem({
        organizationId: device.decommissioner.organizationId,
        date: device.decommissionedAt,
        user: device.decommissioner,
        type: DeviceAuditEventType.Decommissioned
      }))
    }

    // EVENT: Reset
    if (device.resetAt) {
      items.push(new DeviceAuditItem({
        organizationId: device.resetter.organizationId,
        date: device.resetAt,
        user: device.resetter,
        type: DeviceAuditEventType.Reset
      }))
    }

    // Audit trail
    for (const item of audit || []) {
      // Configuration changes
      if (item.action === AuditAction.ConfigureDevice) {
        items.push(new DeviceAuditItem({
          organizationId: device.creator.organizationId,
          date: item.createdAt,
          type: DeviceAuditEventType.SettingsChanged,
          description: clearDetails(item.details),
          user: item.user,
          organization: item.organization
        }))
      }

      // Premium service subscriptions
      if (item.targetType === AuditSubject.PremiumServiceSubscription) {
        let type, description
        if (item.action === AuditAction.Update) {
          type = DeviceAuditEventType.PremiumServiceActivated
        } else if (item.action === AuditAction.Delete) {
          type = DeviceAuditEventType.PremiumServiceExpired
        }

        if (description) {
          items.push(new DeviceAuditItem({
            organizationId: device.creator.organizationId,
            date: item.createdAt,
            type,
            description,
            details: item.details,
            user: item.user,
            organization: item.organization
          }))
        }
      }
    }

    // Premium service, in case the subscription is not registered in the audit trail
    // Concerns subscriptions dated pre-07/2022.
    if (device.hasPremiumService) {
      const isSubscriptionInAudit = audit?.some(item => item.targetType === AuditSubject.PremiumServiceSubscription && item.targetId === device.premiumSubscriptionId)
      if (!isSubscriptionInAudit) {
        if (device.isPremiumServiceStarted) {
          items.push(new DeviceAuditItem({
            organizationId: device.creator.organizationId,
            date: device.premiumServiceStartedAt,
            details: device.premiumServiceLabel,
            type: DeviceAuditEventType.PremiumServiceActivated
          }))
        }
        if (device.hasPremiumServiceExpired) {
          items.push(new DeviceAuditItem({
            organizationId: device.creator.organizationId,
            date: device.premiumServiceExpiredAt,
            details: device.premiumServiceLabel,
            type: DeviceAuditEventType.PremiumServiceExpired
          }))
        }
      }
    }

    // Fill in descriptions
    for (const item of items) {
      if (!item.description) {
        item.description = DeviceAuditEventDescription[item.type]
      }
    }

    // Sort chronologically
    items.sort((a, b) => (descending ? -1 : 1) * (a.date > b.date ? 1 : -1))

    return items
  }
}

/**
 * A single event in device audit record
 */
export class DeviceAuditItem extends Assignable {
  constructor (data = {}) {
    super()
    this.assign(data)
  }

  normalize () {
    super.normalize()
    this.level = this.level || DeviceAuditEventLevel.Information
    this.type = this.type || DeviceAuditEventType.Event
    this.user = this.cast(this.user, User)
    this.organization = this.cast(this.organization, Organization)
  }

  /**
   * Organization which initiated the event
   */
  __organization
  get organization () {
    return this.user?.organization || this.__organization
  }
  set organization (value) {
    this.__organization = value
    this.organizationId = value ? value.id : undefined
  }

  /**
   * Identifier of organization which initiated the event
   * @type {String}
   */
  organizationId

  /**
   * Identifier of target organization of the event, i.e. organization to which device was sold
   * @type {String}
   */
  targetOrganizationId

  /**
   * Date of the event
   * @type {Date}
   */
  date

  /**
   * Brief event description
   * @type {String}
   */
  description

  /**
   * Event details
   * @type {String}
   */
  details

  /**
   * Further details to show in a tooltip
   * @type {String}
   */
  tooltip

  /**
   * Event data
   * @type {Object}
   */
  data

  /**
   * User who initiated the event
   * @type {User}
   */
  user

  /**
   * Event type
   * @type {DeviceAuditEventType}
   */
  type

  /**
   * Event level
   * @type {DeviceAuditEventLevel}
   */
  level

  toJSON () {
    const result = { ...this }
    delete result.__organization
    return result
  }
}
