import { Entity } from '../common/entity'
import { NotificationType } from './notification-type'
import { NotificationPriority } from './notification-priority'
import { NotificationRoute } from './notification-route'
import { NotificationStatus } from './notification-status'
import { NotificationRecipient } from './notification-recipient'

/**
 * Application notification
 */
export class Notification extends Entity {
  constructor (data = {}) {
    super()

    this.assign({
      ...data,
      type: data.type || NotificationType.Information,
      priority: data.priority || NotificationPriority.Normal,
      routes: data.routes || [NotificationRoute.Website, NotificationRoute.Email],
      status: data.status || []
    })
  }

  normalize () {
    super.normalize()
    this.status = this.castArray(this.status, NotificationStatus)
    this.recipient = this.cast(this.recipient, NotificationRecipient)
    if (this.recipient && !this.recipientId) {
      this.recipientId = this.recipient.id
    }
  }

  /**
   * Identifier of the environment on which the action has been recorded
   * @type {Environment}
   */
  environment

  /**
  * Notification source, usually the name of the application or module where the notification originated
  * @type {String}
  */
  source

  /**
   * Notification sender (principal identifier)
   * @type {String}
   */
  senderId

  /**
   * Date and time when notification has been sent
   * @type {Date}
   */
  sentAt

  /**
   * Notification type
   * @type {NotificationType}
   */
  type

  /**
   * Notification subtype
   * @type {String}
   */
  subtype

  /**
   * If true, the notification can be bundled with other pending notifications
   * and sent in a batch. By default, the notification will be sent immediately.
   * @type {Boolean}
   */
  bundle

  /**
   * Identificator of an object which has triggered the notification
   * such as alert, user action etc.
   * @type {String}
   */
  referenceId

  /**
   * Date and time when notification has been acknowledged
   * @type {Date}
   */
  acknowledgedAt

  /**
   * Identifier of user who acknowledged the notification
   * @type {String}
   */
  acknowledgedBy

  /**
   * Notification priority. Use to indicates that notification is urgent
   * and should be delivered / shown on top of other notifications
   * @type {NotificationPriority}
   */
  priority

  /**
   * Identifier of the recipient
   * @type {String}
   */
  recipientId

  /**
   * Recipient details
   * @type {NotificationRecipient}
   */
  recipient

  /**
   * Human-friendly description of the recipient
   * @type {Array[String]}
   */
  get recipientName () {
    const { recipient } = this
    return recipient?.name || ''
  }

  /**
   * Recipient e-mail address
   * @type {String}
   */
  get recipientEmail () {
    const { recipient } = this
    return recipient?.email || ''
  }

  /**
   * Recipient name and e-mail address
   * @type {String}
   */
  get recipientDetails () {
    const { recipient } = this
    return recipient
      ? `${recipient.name} ${recipient.email}`
      : ''
  }

  /**
   * Returns true if specified recipient is a target of this notification
   * @param {String} id
   * @returns {Boolean}
   */
  hasRecipient (id) {
    return this.recipientId === id
  }

  /**
   * Routes for sending the notification - e-mail, push etc.
   * @type {Array[NotificationRoute]}
   */
  routes

  /**
   * Identificator of organization within which the notification has happened
   * @type {String}
   */
  organizationId

  /**
   * Name of organization within which the notification has happened
   * @type {String}
   */
  organizationName

  /**
   * Identificator of notification subject, such as device, user etc.
   * @type {String}
   */
  subjectId

  /**
   * Identifies the type of the notification subject,
   * One of {@link EntityType} values
   */
  subjectType

  /**
   * Template used to render the notification body
   * @type {String}
   */
  template

  /**
   * Template used to render the notification body if sending a bundle of notifications in one message
   * @type {String}
   */
  bundleTemplate

  /**
   * Notification title, user-friendly text which can serve as message title
   * @type {String}
   */
  title

  /**
   * Notification details, user-friendly text which can serve as message details
   * @type {String}
   */
  details

  /**
   * Additional data associated with notification
   * @type {Object}
   */
  data

  /**
   * Identifies the type of additional data associated with notification,
   * {@type EntityType}
   */
  dataType

  /**
   * Notification status
   * @type {NotificationStatus}
   */
  status

  /**
   * Determines whether the notification can be unsubscribed
   * @type {Boolean}
   */
  get canUnsubscribe () {
    const { type } = this
    return [
      NotificationType.Alert,
      NotificationType.PremiumSubscriptionWillExpire,
      NotificationType.PremiumSubscriptionStarted,
      NotificationType.PremiumSubscriptionExpired
    ].includes(type)
  }
}
