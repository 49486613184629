import { startOfDay, endOfDay, subDays } from 'date-fns'
import { formatDateTime, dateCompare } from '@stellacontrol/utilities'
import { AuditSubject, AuditActionDescription, AuditSubjectDescription } from '@stellacontrol/model'

/**
 * Audit events list columns
 */
const columns = [
  { name: 'time', label: 'Time', field: 'createdAt', sortable: true, align: 'left', format: value => formatDateTime(value), sort: dateCompare },
  { name: 'actor', label: 'User', field: 'actor', sortable: true, align: 'left', format: actor => actor.email },
  { name: 'organization', label: 'Organization', field: 'actor', sortable: true, align: 'left', format: actor => actor.organization },
  { name: 'subjectType', label: 'Subject', field: 'subjectType', sortable: true, align: 'left', format: subjectType => AuditSubjectDescription[subjectType] || subjectType },
  { name: 'action', label: 'Action', field: 'action', sortable: true, align: 'left', format: action => AuditActionDescription[action] || action },
  { name: 'subject', label: 'Details', field: 'subject', sortable: true, align: 'left' },
  { name: 'description', label: 'Description', field: 'details', align: 'left' }
]

// Audit subjects available in the view
const defaultAuditSubjects = [
  AuditSubject.Wallet,
  AuditSubject.Device,
  AuditSubject.PremiumService,
  AuditSubject.PremiumServiceSubscription
]

export function createState (data = {}) {
  return {
    // Grid columns
    columns,

    // Audit items
    items: [],

    // All audit subjects available in the view
    defaultAuditSubjects,

    // Grid filter
    filter: {
      subjects: [...defaultAuditSubjects],
      newerThan: startOfDay(subDays(new Date(), 7)),
      olderThan: endOfDay(new Date()),
      rowsPerPage: 100
    },

    // Override with initial data if any
    ...data
  }
}

export const state = createState()

export const getters = {
}
