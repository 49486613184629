export function createState () {
  return {
    /**
     * Floor plans available to the currently logged in organization
     */
    items: null,
    /**
     * Currently expanded customers in the floor plans view
     */
    expandedOrganizations: []
  }
}

export const state = createState()

export const getters = {
}
