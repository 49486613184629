/**
 * Reasons why permission cannot be used by the principal
 */
export const PermissionDeniedReason = {
  FeatureDisabled: 'feature-disabled',
  FeatureNotAllowed: 'not-allowed',
  ParentGuardianDenied: 'parent-guardian-denied',
  ParentPermissionDenied: 'parent-permission-denied',
  PremiumSubscriptionRequired: 'premium-subscription-requited'
}
