import { formatDateTime, parseDate, stringCompare } from '@stellacontrol/utilities'
import { Version, isValidVersion } from '../version/version'
import { Entity } from '../common/entity'
import { User } from '../organization/organization-entities'
import { versionCompare } from '../version/version'

/**
 * Firmware version before which the firmware
 * is treated as legacy, no longer developed and supported
 */
export const LEGACY_FIRMWARE_VERSION = '7.2.0.0'

/**
 * Checks whether the specified device has legacy firmware
 * @param {Device} device Device to check
 * @type {Boolean} True if specified device has legacy firmware
 */
export function hasLegacyFirmware (device) {
  if (device) {
    const result = versionCompare(LEGACY_FIRMWARE_VERSION, device.firmwareVersionLong || device.firmwareVersion) >= 0
    return result
  }
}

/**
 * Device firmware
 */
export class DeviceFirmware extends Entity {
  constructor (data = {}) {
    super(data)
    this.assign(
      {
        ...data,
        isObsolete: data.isObsolete || false,
        version: {
          major: (data.version || {}).major || 0,
          minor: (data.version || {}).minor || 0,
          build: (data.version || {}).build || 0,
          patch: (data.version || {}).patch || 0
        },
        file: {
          name: (data.file || {}).name,
          date: parseDate((data.file || {}).date),
          size: (data.file || {}).size,
          content: (data.file || {}).content
        },
        organizations: data.organizations || []
      },
      {
        isObsolete: Boolean
      }
    )
  }

  normalize () {
    super.normalize()
    this.creator = this.cast(this.creator, User)
    this.updater = this.cast(this.updater, User)
    this.version = new Version({ ...this.version, length: 4, numeric: true })
  }

  /**
   * Firmware version: { major, minor, build, patch }
   * @type {Version}
   */
  version

  /**
   * Firmware file details: { name, date, size, content }
   */
  file

  /**
   * Firmware name
   * @type {String}
   */
  name

  /**
   * Firmware description
   * @type {String}
   */
  description

  /**
   * Indicates obsolete firmware, which should no longer be available for uploads
   * @type {Boolean}
   */
  isObsolete

  /**
   * Indicates active firmware, available for uploads
   * @type {Boolean}
   */
  get isActive () {
    return !this.isObsolete
  }

  /**
   * Identifiers of organizations which were granted access to this firmware
   * @type {Array[String]}
   */
  organizations

  /**
   * Models of devices to which the firmware applies
   * @type {Array[String]}
   */
  models

  /**
   * String representation of device models to which the firmware applies
   * @type {String}
   */
  get modelsString () {
    const models = this.models || []
    if (models.length > 2) {
      return `${models.length} models`
    } else {
      return models.join(', ')
    }
  }

  /**
   * String representation of device models to which the firmware applies
   * @type {String}
   */
  get modelsLongString () {
    const models = this.models || []
    return models.join(', ')
  }

  /**
   * Returns a clone of the instance stripped of binary content
   * @returns {Version} Clone of the instance stripped of binary content
   */
  get withoutContent () {
    return new DeviceFirmware({
      ...this,
      file: {
        ...this.file,
        content: undefined
      }
    })
  }

  /**
   * Returns true if new record
   * @type {Boolean}
   */
  get isNew () {
    return !this.id
  }

  /**
   * Creation date and creator details
   * @type {String}
   */
  get createdText () {
    const { creator } = this
    return [
      formatDateTime(this.createdAt),
      creator ? (creator.fullName || creator.name) : undefined
    ]
      .filter(s => s)
      .join(', ')
  }

  /**
   * Modification date and creator details
   * @type {String}
   */
  get updatedText () {
    const { updater } = this
    return [
      formatDateTime(this.updatedAt || this.createdAt),
      updater ? (updater.fullName || updater.name) : undefined
    ]
      .filter(s => s)
      .join(', ')
  }

  /**
   * Returns true if record contains valid file
   * @type {Boolean}
   */
  get hasFile () {
    const { file: { name, size, date } = {} } = this
    return Boolean(name && size > 0 && date)
  }

  /**
   * Returns true if record contains valid file content
   * @type {Boolean}
   */
  get hasFileContent () {
    const { hasFile, file: { content } = {} } = this
    return Boolean(hasFile && content)
  }

  /**
   * Returns true if file content is Base64-encoded
   * @type {Boolean}
   */
  get isFileContentEncoded () {
    const { hasFile, file: { content } = {} } = this
    if (hasFile && content) {
      return typeof content === 'string'
    }
  }

  /**
   * Returns true if record contains a valid non-zero version
   * @type {Boolean}
   */
  get hasVersion () {
    const { version: { major, minor } = {} } = this
    return major > 0 || minor > 0
  }

  /**
   * Returns true if firmware version matches the specified one
   * @param {String} value Version string to compare to
   * @type {Boolean}
   */
  isVersion (value) {
    return value === this.versionString
  }

  /**
   * Returns true if firmware has been granted to any organizations
   * @type {Boolean}
   */
  get hasOrganizations () {
    return (this.organizations || []).length > 0
  }

  /**
   * Version string
   * @returns {String} Firmware version string
   */
  get versionString () {
    return this.version?.toFullString() || ''
  }

  /**
   * File details string
   * @returns {String} File details string
   */
  get fileString () {
    const { file: { name, size } = {} } = this
    return name
      ? `${name} (${size}b)`
      : ''
  }

  /**
   * User-friendly label
   * @returns {String} Firmware label
   */
  get label () {
    return [
      this.modelsString,
      this.versionString,
      (this.name || '').trim()
    ].filter(s => s).join(' ')
  }

  /**
   * Checks if organization is granted access to this firmware
   * @param {Organization} organization Organization to check
   * @returns {Boolean} True if organization is granted access to the firmware
   */
  isOrganizationGranted (organization) {
    return (this.organizations || []).includes(organization.id)
  }

  /**
   * Grants the organization with access to this firmware
   * @param {Organization} organization Organization to grant
   */
  grantOrganization (organization) {
    if (organization) {
      if (!this.organizations) {
        this.organizations = []
      }
      const i = this.organizations.indexOf(organization.id)
      if (i === -1) {
        this.organizations.push(organization.id)
      }
    }
  }

  /**
   * Revokes access to the firmware from the specified organization
   * @param {Organization} organization Organization to revoke access from
   */
  revokeOrganization (organization) {
    if (organization) {
      if (!this.organizations) {
        this.organizations = []
      }
      const i = this.organizations.indexOf(organization.id)
      if (i > -1) {
        this.organizations.splice(i, 1)
      }
    }
  }

  /**
   * Checks if firmware is allowed for the specified device model
   * @param {String} model Device model
   * @returns {Boolean} True if firmware is allowed for the specified device model
   */
  isAllowedForDevice (model) {
    return Boolean((this.models || []).some(value => stringCompare(value, model, false) === 0))
  }

  /**
   * Checks if firmware is allowed for all specified device models
   * @param {Array[String]} type Device models
   * @returns {Boolean} True if firmware is allowed for all specified device models
   */
  isAllowedForDevices (models) {
    return (models || []).every(model => this.isAllowedForDevice(model))
  }

  /**
   * Assigns version number either from { major, minor, build, patch } tuple
   * @param {Number} major Major version number
   * @param {Number} minor Minor version number
   * @param {Number} build Build number
   * @param {Number} patch Patch number
   */
  setVersion ({ major, minor, build, patch } = {}) {
    if (major != null && minor != null && build != null) {
      const { version } = this
      version.major = major
      version.minor = minor
      version.build = build
      version.patch = patch == null ? 0 : patch
    }
  }

  /**
   * Extracts firmware version and model from firmware file name
   * @param {String} fileName
   * @returns {Object} Firmware data: { model, version }
   * @description Possible firmware name formats:
   * iC5_USA_DNL_V1_v7.0.8.1.firmware
   * iC5_EU_DNL_V1_v7.0.8.1.firmware
   * iC5_EU_DNL_V1_7.0.8.1.firmware
   * uni_v7.0.8.1.firmware
   * uni_7.0.8.1.firmware
   */
  static parseFirmwareFileName (fileName) {
    if (fileName) {
      const parts = fileName
        .replace('.firmware', '')
        .split('_')

      const versionPart = parts[parts.length - 1]
      const versionString = versionPart.startsWith('v') ? versionPart.substring(1) : versionPart
      const version = isValidVersion(versionString, 4, true)
      const model = parts[0] === 'uni' ? undefined : parts.slice(0, parts.length - 1).join('_')
      return { version, versionString, model }
    }
  }
}
