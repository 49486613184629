import { PlanItem, PlanItemType } from './plan-item'
import { PlanLineStyle } from '../styles'

/**
 * Free curve defined by vector path
 */
export class PlanCurve extends PlanItem {
  constructor (data = {}) {
    super(data)
    this.assign(data)
  }

  /**
   * Item type
   * @type {PlanItemType}
   */
  static get type () {
    return PlanItemType.Curve
  }

  /**
   * Item defaults
   */
  get defaults () {
    return {
      ...super.defaults,
      lineStyle: PlanLineStyle.Default
    }
  }

  normalize () {
    super.normalize()
    this.path = this.path || ''
  }

  /**
   * Curve path
   * @type {String}
   */
  path
}
