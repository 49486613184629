import { PremiumService } from '@stellacontrol/model'
import { createState } from './premium-services-view.state'

export const mutations = {
  /**
   * Starts editing the specified premium service
   * @param premiumService Premium service to edit
   */
  editPremiumService (state, { premiumService } = {}) {
    if (premiumService) {
      state.selectedPremiumService = new PremiumService(premiumService)
    }
  },

  /**
   * Stops editing the premium service
   */
  stopEditingPremiumService (state) {
    state.selectedPremiumService = null
  },

  /**
   * Stores a premium service in the currently edited pricelist
   * @param {Pricelist} pricelist Pricelist to store the premium service in
   * @param {PremiumService} premiumService Premium service to store
   */
  storePremiumService (_, { premiumService, pricelist } = {}) {
    if (pricelist && premiumService) {
      // Assign service to current price list
      premiumService.pricelistId = pricelist.id

      // Add or replace the modified service
      const index = pricelist.services.findIndex(s => s.id === premiumService.id)
      if (index > -1) {
        pricelist.services.splice(index, 1, premiumService)
      } else {
        pricelist.services.push(premiumService)
      }
    }
  },

  /**
   * Removes a premium service from the currently edited pricelist
   * @param {Pricelist} pricelist Pricelist to remove the premium service from
   * @param {PremiumService} premiumService Premium service to remove
   */
  removePremiumService (state, { premiumService, pricelist } = {}) {
    if (pricelist && premiumService) {
      const index = pricelist.services.findIndex(s => s.id === premiumService.id)
      if (index > -1) {
        pricelist.services.splice(index, 1)
      }
    }
  },

  /**
   * Resets the store to defaults
   */
  reset (state) {
    Object.assign(state, createState())
  },

  resetPremiumServicesView (state) {
    Object.assign(state, createState())
  }
}
