import { dispatch } from '@stellacontrol/client-utilities'
import AboutView from './about.vue'

const name = 'about'

export const Routes = [
  {
    name,
    path: '/about',
    component: AboutView,

    async beforeEnter (to, from, next) {
      const data = await dispatch('getAboutData')
      if (data) {
        await dispatch('setRouteData', { from, to, data })
      }
      next()
    }
  }
]
