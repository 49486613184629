import { createState } from './places.state'

export const mutations = {
  /**
   * Stores places of current organization
   * @param places Places
   */
  storePlaces (state, { places = [] } = {}) {
    state.items = [...places]
  },

  /**
   * Stores an place in the state
   * @param place Place to store
   */
  storePlace (state, { place } = {}) {
    if (!place) return

    const { id } = place
    const index = state.items.findIndex(i => i.id === id)
    if (index === -1) {
      state.items.push(place)
    } else {
      state.items[index] = place
    }
  },

  /**
   * Removes an place from the state
   * @param place Place to remove
   */
  removePlace (state, { place } = {}) {
    if (!place) return

    const { id } = place
    const index = (state.items || []).findIndex(i => i.id === id)
    if (index !== -1) {
      state.items.splice(index, 1)
    }
  },

  /**
   * Reset the state
   */
  reset (state) {
    Object.assign(state, createState())
  }
}
