<script>
import ParameterEditor from './parameter-editor'

export default {
  mixins: [
    ParameterEditor
  ],

  props: {
    // Edited band
    band: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      // Colors representing attenuation value
      colors: ['grey-2', 'green-3', 'green-4', 'green-5', 'green-6', 'green-7', 'green-8', 'green-9', 'green-9']
    }
  },

  computed: {
    // Items for value selector
    items () {
      const { values, colors } = this
      return values.map((value, index) => ({
        label: `${value} dB`,
        value: value,
        icon: 'signal_cellular_alt',
        color: colors[index]
      }))
    }
  }
}
</script>

<template>
  <div class="parameter-editor" v-if="hasValue">

    <sc-parameter-value
      :label="`Manual attenuation of ${band.label} band`"
      :reported="reported"
      :custom="custom"
      :unit="unit">
    </sc-parameter-value>

    <sc-parameter-selector
      :items="items"
      :label="`Manual attenuation of ${band.label} band`"
      :allow-clear="false"
      @select="value => change(value)">
    </sc-parameter-selector>

  </div>
</template>
