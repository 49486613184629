<script>
import { mapActions, mapGetters } from 'vuex'
import { sortItems, distinctValues, distinctItemCount } from '@stellacontrol/utilities'
import { DialogMixin } from '@stellacontrol/client-utilities'
import { Secure } from '@stellacontrol/security-ui'

const dialog = 'batch-edit-firmwares'
const VALUE_VARIOUS_NAMES = '<various names>'
const VALUE_VARIOUS_DESCRIPTIONS = '<various descriptions>'

export default {
  mixins: [
    DialogMixin,
    Secure
  ],

  data () {
    return {
      dialog,
      // Dialog title
      title: '',
      // Firmwares to which we grant access
      firmwares: null,
      // Firmwares' name
      firmwareName: '',
      // Firmwares' description
      firmwareDescription: '',
      // Organizations allowed to use the firmware
      selectedOrganizations: []
    }
  },

  computed: {
    ...mapGetters([
      'organizations'
    ]),

    // Organizations allowed to upload firmwares
    allOrganizations () {
      const { organizations } = this
      const items = organizations.filter(o => o.canUse('device-management-firmware'))
      return sortItems(items, o => o.fullName)
    },

    // Organizations currently granted with access to the firmware
    grantedOrganizations () {
      return this.allOrganizations.filter(o => this.selectedOrganizations.includes(o.id))
    },

    // Organizations currently denied access to the firmware
    revokedOrganizations () {
      return this.allOrganizations.filter(o => !this.selectedOrganizations.includes(o.id))
    },

    // Indicates that edited firmwares have currently different names
    haveDifferentNames () {
      return distinctItemCount(this.firmwares.map(f => f.name)) >= 2
    },

    // Indicates that edited firmwares have currently different descriptions
    haveDifferentDescriptions () {
      return distinctItemCount(this.firmwares.map(f => f.description)) >= 2
    }
  },

  methods: {
    ...mapActions([
      'dialogOk',
      'dialogCancel'
    ]),

    // Called when dialog is shown
    dialogShown () {
      // Pass on initial data
      this.title = this.data.title || 'Edit firmwares'
      this.firmwares = this.data.firmwares
      const names = distinctValues(this.firmwares.map(f => f.name))
      const descriptions = distinctValues(this.firmwares.map(f => f.description))
      const grantedOrganizations = distinctValues(this.firmwares.flatMap(f => f.organizations))
      this.firmwareName = names.length > 1 ? VALUE_VARIOUS_NAMES : (names[0] || '')
      this.firmwareDescription = descriptions.length > 1 ? VALUE_VARIOUS_DESCRIPTIONS : (descriptions[0] || '')
      this.selectedOrganizations = [...grantedOrganizations]
    },

    // Validates and OKs the dialog
    async ok () {
      const {
        firmwares,
        firmwareName,
        firmwareDescription,
        haveDifferentDescriptions,
        haveDifferentNames,
        selectedOrganizations,
        grantedOrganizations,
        revokedOrganizations
      } = this
      this.dialogOk({
        dialog, data: {
          firmwares,
          name: haveDifferentNames ? undefined : firmwareName,
          description: haveDifferentDescriptions ? undefined : firmwareDescription,
          selectedOrganizations,
          grantedOrganizations,
          revokedOrganizations
        }
      })
    },

    // Cancels the dialog
    cancel () {
      this.dialogCancel({ dialog })
    }
  }
}
</script>

<template>
  <sc-dialog :dialog="dialog" persistent @dialogShown="dialogShown()">
    <q-form class="form" ref="form">
      <q-banner class="header bg-indigo-6">
        <span class="text-white title">{{ title }}</span>
      </q-banner>

      <div class="content q-pa-md">
        <q-input label="Firmware name" v-model="firmwareName" dense square outlined hide-bottom-space clearable class="q-mb-md" maxlength="255" :filled="haveDifferentNames"></q-input>

        <q-input label="Firmware description" class="notes q-mb-md" v-model="firmwareDescription" clearable type="textarea" dense square outlined :filled="haveDifferentDescriptions"></q-input>

        <div class="q-mb-sm">Organizations allowed to use the firmware:</div>
        <div class="organizations">
          <sc-multi-organization-selector
            v-model="selectedOrganizations"
            :organizations="allOrganizations"
            :bordered="false"
            :separator="true"
            :dense="false"
            iconSelected="check_circle"
            iconSelectedColor="green-7"
          ></sc-multi-organization-selector>
        </div>

        <div class="footer row q-mt-xl">
          <q-space></q-space>
          <q-btn label="Cancel" unelevated class="q-mr-md" @click="cancel()"></q-btn>
          <q-btn label="Apply" unelevated class="primary" @click="ok()"></q-btn>
        </div>
      </div>
    </q-form>
  </sc-dialog>
</template>

<style scoped lang="scss">
.title {
  font-size: 18px;
}

.form {
  width: 600px;

  .header {
  }

  .content {
    .organizations {
      height: 300px;
      overflow: auto;
      border: 1px solid #0000001f;
    }
  }

  .footer {
  }
}
</style>