<script>
import { mapState } from 'vuex'

/**
 * Reloading... view
 */
export default {
  props: [
    'title'
  ],

  computed: {
    ...mapState({
      reloading: state => state.client.reloading
    }),

    message () {
      return this.reloading.message || 'Reloading, please wait ...'
    }
  }
}

</script>

<template>
  <q-layout>
    <q-page-container>
      <q-page class="column items-center justify-center">
        <q-inner-loading :showing="true">
          <q-spinner-gears size="64px" color="indigo-6" />
        </q-inner-loading>
        <label class="reloading text-h5 text-weight-light text-black">
          {{ message }}
        </label>
      </q-page>
    </q-page-container>
  </q-layout>

</template>

<style lang="scss" scoped>
label.reloading {
  margin-top: 140px;
  padding-left: 20px;
}
</style>
