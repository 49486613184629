import { formatDate, countString } from '@stellacontrol/utilities'

/**
 * Function for styling grid cells
 */
const announcementColumnClasses = announcement => `q-td ${announcement.isNoLongerApplicableOn() ? 'not-applicable' : ''}`
const announcementColumnClass = announcement => ({
  'q-td': true,
  'not-applicable': announcement.isNoLongerApplicableOn()
})

/**
 * Grid columns for announcement administration view
 */
const announcementsAdministrationColumns = [
  { name: 'category', label: 'Category', field: 'category', sortable: true, align: 'left', style: 'width: 48px', classes: announcementColumnClasses },
  { name: 'title', label: 'Title', field: 'title', sortable: true, align: 'left', classes: announcementColumnClasses },
  { name: 'createdAt', label: 'Created', field: 'createdAt', sortable: true, format: value => value ? formatDate(value) : '', align: 'left', classes: announcementColumnClasses },
  { name: 'channels', label: 'Channels', field: 'channels', format: value => (value || []).join(','), sortable: true, align: 'left', classes: announcementColumnClasses },
  { name: 'recipients', label: 'Recipients', field: 'recipients', format: value => value ? countString(value, 'recipient') : 'No recipients', sortable: true, align: 'left', classes: announcementColumnClasses },
  { name: 'publishedAt', label: 'Published', field: 'publishedAt', sortable: true, format: value => value ? formatDate(value) : '', align: 'left', classes: announcementColumnClasses },
  { name: 'shown', label: 'Shown', field: 'validFrom', sortable: true, format: (value, item) => item.validPeriodString || 'Until dismissed', align: 'left', classes: announcementColumnClasses },
  { name: 'actions', label: 'Actions', align: 'right', sortable: false }
]

/**
 * Grid columns for announcement view
 */
const announcementsColumns = [
  { name: 'category', label: 'Category', field: 'category', sortable: true, align: 'left' },
  { name: 'priority', label: 'Priority', field: 'priority', sortable: true, align: 'left' },
  { name: 'title', label: 'Title', field: 'title', sortable: true, align: 'left' }
]

export function createState () {
  return {
    /**
     * Grid columns for announcement administration view
     */
    announcementsAdministrationColumns,

    /**
     * Grid columns for announcement view
     */
    announcementsColumns,
    announcementColumnClass,

    /**
     * All announcements available to the current user
     * @type {Array[Announcement]}
     */
    announcements: [],

    /**
     * All announcements available to the current user
     * @type {Array[Announcement]}
     */
    myAnnouncements: [],

    /**
     * New announcements to show to the current user
     * @type {Array[Announcement]}
     */
    newAnnouncements: [],

    /**
     * Indicates whether the new announcements popup is now visible
     */
    isAnnouncementPopupVisible: false
  }
}

export const state = createState()

export const getters = {
}
