import { Confirmation, ListViewMode } from '@stellacontrol/client-utilities'
import { CommonAPI } from '@stellacontrol/client-api'
import { AppletRoute } from '../../router/common.routes'

export const actions = {
  /**
   * Initialize the organization profiles store
   */
  async initializeApplet ({ dispatch }) {
    // Initialize lists
    await dispatch('initializeList', [
      { name: 'announcements', viewMode: ListViewMode.Normal, sortBy: 'createdAt', sortDescending: true }
    ])
  },

  /**
   * Triggered when new user session has started
   * @param {Session} session Session details
   */
  async sessionStarted ({ dispatch, getters }, { session } = {}) {
    if (session) {
      // Fetch new announcements
      dispatch('getNewAnnouncements')

      // Start polling for new announcements every few minutes
      const announcementInterval = getters.isDevelopmentEnvironment ? 60000 : 5 * 60000
      const announcementTimer = window.setInterval(() => {
        if (getters.isLoggedIn) {
          dispatch('getNewAnnouncements')
        } else {
          window.clearInterval(announcementTimer)
        }
      }, announcementInterval)
    }
  },

  /**
   * When session ends, hide announcement popup
   */
  async endSession ({ commit }) {
    commit('hideAnnouncementsPopup')
  },

  /**
   * On route changes hide announcement popup
   */
  async navigationStarted ({ commit }) {
    commit('hideAnnouncementsPopup')
  },

  /**
   * Retrieves all announcements visible to the currently logged in user
   * @param {Boolean} expired If true, also expired announcements are returned, useful for the list of past announcements etc.
   * @returns {Promise<Array[Announcement]>}
   */
  async getMyAnnouncements ({ getters, commit } = {}, { expired = true } = {}) {
    const { currentOrganization, currentUser, isLoggedIn } = getters
    if (currentOrganization && currentUser && isLoggedIn) {
      const announcements = await CommonAPI.getMyAnnouncements({ expired })
      // Filter out announcements applicable to the current user.
      // The API should already return only the applicable ones, but better check again in case something changed in permissions etc.
      const applicableAnnouncements = (announcements || [])
        .filter(announcement => announcement.isApplicableFor(currentOrganization, currentUser))
      commit('storeMyAnnouncements', { announcements: applicableAnnouncements })
      return applicableAnnouncements
    }
  },

  /**
   * Retrieves all announcements visible to the currently logged in user
   * @returns {Promise<Array[Announcement]>}
   */
  async getNewAnnouncements ({ getters, commit } = {}) {
    const { currentOrganization, currentUser, isLoggedIn } = getters
    if (currentOrganization && currentUser && isLoggedIn) {
      const announcements = await CommonAPI.getMyAnnouncements({ unacknowledged: true })
      // Filter out announcements applicable to the current user.
      // The API should already return only the applicable ones, but better check again in case something changed in permissions etc.
      const applicableAnnouncements = (announcements || [])
        .filter(announcement => announcement.isApplicableFor(currentOrganization, currentUser, new Date()))
      commit('storeNewAnnouncements', { announcements: applicableAnnouncements })
      return applicableAnnouncements
    }
  },

  /**
   * Retrieves all announcements created by current organization
   * @returns {Promise<Array[Announcement]>}
   */
  async getAnnouncements ({ commit, getters } = {}) {
    const { currentOrganization: organization } = getters
    if (organization) {
      const announcements = await CommonAPI.getAnnouncements()
      commit('storeAnnouncements', { announcements })
      return announcements
    }
  },

  /**
   * Retrieves details of the specified announcement
   * @param {String} id Announcement identifier
   * @returns {Promise<Announcement>} Announcement details
   */
  async getAnnouncement ({ commit } = {}, { id } = {}) {
    if (id) {
      const announcement = await CommonAPI.getAnnouncement({ id })
      if (announcement) {
        commit('storeAnnouncement', { announcement })
        return announcement
      } else {
        commit('removeAnnouncement', { id })
      }
    }
  },

  /**
   * Retrieves details of the specified announcement
   * @param {String} id Announcement identifier
   */
  async createAnnouncement ({ dispatch } = {}) {
    await dispatch('gotoRoute', {
      name: AppletRoute.Announcement,
      params: { id: 'new' }
    })
  },

  /**
   * Retrieves details of the specified announcement
   * @param {String} id Announcement identifier
   */
  async editAnnouncement ({ dispatch } = {}, { announcement } = {}) {
    if (announcement) {
      await dispatch('gotoRoute', {
        name: AppletRoute.Announcement,
        params: { id: announcement.id }
      })
    }
  },

  /**
   * Creates a copy of the specified announcement
   * @param {String} id Announcement identifier
   */
  async copyAnnouncement ({ dispatch } = {}, { announcement } = {}) {
    if (announcement) {
      await dispatch('gotoRoute', {
        name: AppletRoute.Announcement,
        params: { id: announcement.id },
        query: { copy: true }
      })
    }
  },

  /**
   * Navigates to announcements
   */
  async gotoAnnouncements ({ dispatch } = {}) {
    await dispatch('gotoRoute', {
      name: AppletRoute.Announcements
    })
  },

  /**
   * Deletes the announcement
   * @param {Announcement} announcement Announcement to delete
   * @param {Boolean} confirm If true, user has to confirm
   * @param {Boolean} silent If true, no notifications will be showing
   * @returns {Promise<Announcement>} Details of the deleted announcement
   */
  async deleteAnnouncement ({ commit, dispatch } = {}, { announcement, confirm, silent } = {}) {
    if (announcement) {
      const yes = await Confirmation.ask({
        title: 'Delete?',
        message: `Do you want to delete the announcement [${announcement.title}]?`,
        confirm
      })
      if (yes) {
        await dispatch('busy', { message: 'Deleting the announcement ...', data: announcement, silent })
        const deletedAnnouncement = await CommonAPI.deleteAnnouncement({ announcement })
        if (deletedAnnouncement) {
          commit('removeAnnouncement', { announcement: deletedAnnouncement })
          await dispatch('done', { message: 'The announcement has been deleted', silent })
        } else {
          await dispatch('done', { silent })
        }
        return deletedAnnouncement
      }
    }
  },

  /**
   * Saves the announcement
   * @param {Announcement} announcement Announcement to save
   * @param {Boolean} publish If true, announcement will be published after saving
   * @param {Boolean} silent If true, no notifications will be showing
   * @returns {Promise<Announcement>} Details of the saved announcement
   */
  async saveAnnouncement ({ commit, dispatch } = {}, { announcement, publish, silent } = {}) {
    if (announcement) {
      await dispatch('busy', { message: 'Saving the announcement ...', data: announcement, silent })
      let savedAnnouncement = await CommonAPI.saveAnnouncement({ announcement })
      if (savedAnnouncement) {
        if (publish && announcement.canPublish) {
          savedAnnouncement = await dispatch('publishAnnouncement', { announcement: savedAnnouncement, confirm: false, silent: true })
        }
        commit('storeAnnouncement', { announcement: savedAnnouncement })
        const published = publish && savedAnnouncement.canPublish && savedAnnouncement.wasPublished
        await dispatch('done', { message: `The announcement has been saved${published ? ' and published' : ''}`, silent })
      } else {
        await dispatch('done', { silent })
      }

      return savedAnnouncement
    }
  },

  /**
   * Publishes the announcement
   * @param {Announcement} announcement Announcement to publish
   * @param {Boolean} confirm If true, user has to confirm
   * @param {Boolean} silent If true, no notifications will be showing
   * @returns {Promise<Announcement>} Details of the published announcement
   */
  async publishAnnouncement ({ commit, dispatch } = {}, { announcement, confirm, silent } = {}) {
    if (announcement) {
      const yes = await Confirmation.ask({
        title: 'Publish the announcement?',
        message: `Do you want to publish the announcement [${announcement.title}]${announcement.wasPublished ? ' again' : ''}? It will become visible to recipients and e-mails will be sent.`,
        confirm
      })
      if (yes) {
        await dispatch('busy', { message: 'Publishing the announcement ...', data: announcement, silent })
        const publishedAnnouncement = await CommonAPI.publishAnnouncement({ announcement })
        if (publishedAnnouncement) {
          commit('storeAnnouncement', { announcement: publishedAnnouncement })
          await dispatch('done', { message: 'The announcement has been published', silent })
        } else {
          await dispatch('done', { silent })
        }

        return publishedAnnouncement
      }
    }
  },

  /**
   * Acknowledges the announcement
   * @param {Announcement} announcement Announcement to acknowledge
   * @returns {Promise<Announcement>} Details of the acknowledged announcement
   */
  async acknowledgeAnnouncement ({ commit } = {}, { announcement } = {}) {
    if (announcement) {
      const status = await CommonAPI.acknowledgeAnnouncement({ announcement })
      if (status) {
        commit('acknowledgeAnnouncement', { status })
        return status
      }
    }
  },

  /**
   * Acknowledges multiple announcements
   * @param {Array[Announcement]} announcements Announcements to acknowledge
   * @returns {Promise<Array[Announcement]>} Details of the acknowledged announcements
   */
  async acknowledgeAnnouncements ({ commit } = {}, { announcements } = {}) {
    if (announcements) {
      const result = []
      for (const announcement of announcements) {
        const status = await CommonAPI.acknowledgeAnnouncement({ announcement })
        if (status) {
          result.push(status)
          commit('acknowledgeAnnouncement', { status })
        }
      }
      return result
    }
  },

  /**
   * Shows the popup with new announcements
   */
  async showAnnouncementsPopup ({ commit }) {
    commit('showAnnouncementsPopup')
  },

  /**
   * Hides the popup with new announcements
   */
  async hideAnnouncementsPopup ({ commit }) {
    commit('hideAnnouncementsPopup')
  },

  /**
   * Dismiss the announcements popup on click outside
   */
  async click ({ state, dispatch }, { event }) {
    if (state.isAnnouncementPopupVisible) {
      const popup = document.getElementById('announcement-popup')
      let target = event.target
      do {
        if (target == popup) return
        target = target.parentNode
      } while (target)
      dispatch('hideAnnouncementsPopup')
    }
  }
}