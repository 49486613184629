import { assign } from '@stellacontrol/utilities'
import { EntityType } from './entity-type'

/**
 * Tag
 */
export class Tag {
  constructor (data = {}) {
    assign(this, {
      ...data,
      createdAt: data.createdAt || new Date()
    })
  }

  /**
   * Database identifier
   */
  id

  /**
   * Tag category, typically an identifier of entity associated with tag such as `device` or `user`
   */
  category

  /**
   * Tag value
   */
  name

  /**
   * Tagged entity
   */
  entityId

  /**
   * Identifier of optional user who owns the tag.
   * If not specified, all users will see the tag.
   * This allows multiple users to mark the same
   * entity with the tag. For example, `favorite` tag
   * on device can be attached by multiple users.
   */
  userId

  /**
   * Tag is private if user identifier is assigned
   */
  get isPrivate () {
    return Boolean(this.userId)
  }

  /**
   * Tag is empty if entity if and name are missing
   */
  get isEmpty () {
    return this.entityId == null && this.name == null
  }

  /**
   * Tag is valid if entity if and name are specified
   */
  get isValid () {
    return this.entityId != null && this.name != null
  }

  /**
   * Returns the entity type with which the tag is associated
   * @returns {EntityType}
   */
  get entityType () {
    return this.category
  }
}

/**
 * Tag categories.
 */
export const TagCategory = {
  User: EntityType.User,
  Organization: EntityType.Organization,
  OrganizationProfile: EntityType.OrganizationProfile,
  Device: EntityType.Device,
  Place: EntityType.Place
}

/**
 * Predefined tags.
 * Additional tags can be defined in configuration file.
 */
export const Tags = {
  /**
   * Used to mark entities as user's favorites
   */
  Favorite: 'favorite'
}
