<script>
import { mapActions } from 'vuex'
import { Secure } from '@stellacontrol/security-ui'
import { DocumentCategory } from '@stellacontrol/model'

export default {
  mixins: [
    Secure
  ],

  data () {
    return {
      // All available documents
      documents: [],
      // Selected document
      document: null
    }
  },

  computed: {
    // Checks whether the specified document is currently selected
    isSelected () {
      return document => this.document?.url === document?.url
    }
  },

  methods: {
    ...mapActions([
      'getDocuments'
    ]),

    // Show the specified firmware change log
    showDocument (document) {
      this.document = document
    }
  },

  async created () {
    this.documents = await this.getDocuments({ category: DocumentCategory.FirmwareHistory }) || []
    this.document = this.documents[0]
  }
}
</script>

<template>
  <div class="container q-ma-md">
    <div class="documents q-pr-md">
      <q-btn v-for="document in documents" @click="showDocument(document)" :label="document.title"
        class="q-mb-sm" no-caps unelevated :class="{ primary: isSelected(document) }">
      </q-btn>
    </div>

    <div class="document q-pa-sm">
      <sc-document-viewer v-if="document" :url="document.url" :content="document.content"
        :type="document.type" :isCollapsible="false" :isCollapsed="false">
      </sc-document-viewer>
    </div>
  </div>
</template>

<style scoped lang="scss">
.container {
  flex: 1;
  display: flex;
  flex-direction: row;
  overflow: hidden;

  .documents {
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  .document {
    flex: 1;
    display: flex;
    flex-direction: column;
    border: solid #0000001f 1px;
    overflow: auto;
  }
}
</style>