import Notes from './notes/notes.vue'
import Note from './notes/note.vue'
import AnnouncementList from './announcements/announcement-list.vue'
import AnnouncementIndicator from './announcements/announcement-indicator.vue'
import AnnouncementPopup from './announcements/announcement-popup.vue'
import File from '../views/files/components/file.vue'
import FilePreview from '../views/files/components/preview.vue'
import FileNotes from '../views/files/components/file-notes.vue'

export { FilesMixin } from '../views/files/files-mixin'

/**
 * Installs Common UI components into the application
 * @param application Vue application instance
 */
export function useCommonComponents (application) {
  const plugin = {
    install (application) {
      const items = [
        {
          tag: 'sc-notes', component: Notes
        },
        {
          tag: 'sc-note', component: Note
        },
        {
          tag: 'sc-announcement-list', component: AnnouncementList
        },
        {
          tag: 'sc-announcement-popup', component: AnnouncementPopup
        },
        {
          tag: 'sc-announcement-indicator', component: AnnouncementIndicator
        },
        {
          tag: 'sc-file', component: File
        },
        {
          tag: 'sc-file-preview', component: FilePreview
        },
        {
          tag: 'sc-file-notes', component: FileNotes
        }
      ]

      for (const { tag, component } of items) {
        application.component(tag, component)
      }
    }
  }

  application.use(plugin)
}
