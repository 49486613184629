import { Size, Rectangle, Margin, getId, parseBoolean, notNull, safeParseInt } from '@stellacontrol/utilities'
import { Assignable } from '@stellacontrol/model'
import { PlanLayer } from './plan-layer'
import { PlanLayers } from './plan-layers'
import { PlanScale } from './plan-scale'
import { PlanBackground } from './plan-background'
import { PlanRadiation } from './plan-radiation'
import { getPlanItemClass } from './create-item'
import { PlanPlug } from '../items/plan-plug'
import { PlanCable } from '../items/plan-cable'

/**
 * Identifiers of predefined plan floors
 */
export const PlanFloors = {
  // Not really a floor, but rather a cross-view of all floors
  CrossSection: 'cross-section',
  // The plan of the main/first floor. Other floors may be added by the user subsequently.
  Main: '0',
}

/**
 * Plan floor
 */
export class PlanFloor extends Assignable {
  constructor (data) {
    super()
    this.assign(data)
  }

  __items

  /**
   * Object defaults
   * @return {Object}
  */
  get defaults () {
    return {
      size: new Size({ width: 2000, height: 2000 }),
      minSize: new Size({ width: 500, height: 500 }),
      maxSize: new Size({ width: 5000, height: 5000 }),
      margin: new Margin({ top: 100, right: 500, bottom: 500, left: 100 }),
      zoom: PlanScale.Normal,
      background: new PlanBackground(),
      radiation: new PlanRadiation(),
      crossSection: new PlanFloorCrossSectionProperties(),
      mapScale: 20,
      roomHeight: 3,
      layers: []
    }
  }

  normalize () {
    super.normalize()
    const { defaults } = this

    const { defaults: { size, maxSize, margin } } = this

    this.id = this.id || getId('floor')

    // Parse dimensions, items scale and zoom
    this.dimensions = this
      .cast(this.dimensions, Size, Size.from(size))
      .setSize(this.dimensions, Size.from(maxSize))
      .round()
    this.zoom = this.cast(this.zoom, PlanScale, defaults.zoom)
    this.margin = this.cast(this.margin, Margin, margin)
    this.roomHeight = safeParseInt(this.roomHeight, defaults.roomHeight)

    // Parse background layout
    this.background = this.cast(this.background, PlanBackground, defaults.background)

    // Parse radiation layout
    this.radiation = this.cast(this.radiation, PlanRadiation, defaults.radiation)

    // Parse map scale
    this.mapScale = safeParseInt(this.mapScale, defaults.mapScale)

    // Parse custom layers
    this.layers = this.castArray(this.layers, PlanLayer, defaults.layers)

    // Parse to specific item classes
    this.items = (this.items || [])
      .map(data => {
        const itemClass = getPlanItemClass(data)
        const item = itemClass ? this.cast(data, itemClass) : null
        if (item) {
          // Store the floor to which the item belongs, for lookups etc.
          item.floorId = this.id
          // Ensure item index
          item.index = item.index || this.itemIndex++
        }
        return item
      })
      .filter(item => item != null)

    // Eliminate any obsolete items
    const legend = this.items.find(i => i.isLegend)
    this.items = this.items
      .filter(item => {
        // Remove duplicate legends, now that Power and Equipment legend has been merged into one
        if (item.isLegend && item.id !== legend.id) {
          return false
        }
        return true
      })

    // Parse cross-section properties
    this.crossSection = this.cast(this.crossSection, PlanFloorCrossSectionProperties, defaults.crossSection)

    // Reset runtime properties
    this.isDeleted = false
    this.isNew = false
    this.isLoadingImage = false
  }

  /**
   * Serializes the item to JSON
   * @returns {Object}
   */
  toJSON () {
    const result = {
      ...this,
      items: this.items
    }

    this.clearDefaults(result)
    if (result.layers?.length === 0) {
      delete result.layers
    }

    // Delete runtime properties
    delete result.__items
    delete result.isDeleted
    delete result.isNew
    delete result.isSelected
    delete result.isLoadingImage
    delete result.index

    return result
  }

  /**
   * Resets the floor layout to defaults
   */
  reset () {
    const { defaults } = this

    // Parse dimensions, items scale and plan zoom
    this.dimensions = Size.from(defaults.size)
    this.zoom = PlanScale.Normal
    this.margin = Margin.from(defaults.margin)
    this.background?.reset()
    this.radiation?.reset()
  }

  /**
   * Floor identifier
   * @type {String}
   */
  id

  /**
   * Indicates that the floor has just been deleted.
   * This flag is used to quickly hide the floor from sight,
   * while it is still being removed, image purged from S3 storage
   * and all other cleanup actions which can take substantial time
   * @type {Boolean}
   * @description RUNTIME
   */
  isDeleted

  /**
   * Indicates that the floor has just been added
   * @type {Boolean}
   * @description RUNTIME
   */
  isNew

  /**
   * Indicates that the floor has been selected,
   * while on cross-section view
   * @type {Boolean}
   * @description RUNTIME
  */
  isSelected

  /**
   * Checks whether the floor is the main floor
   * @type {Boolean}
   */
  get isMain () {
    return this.id === PlanFloors.Main
  }

  /**
   * Checks whether the floor is the cross-section view
   * @type {Boolean}
   */
  get isCrossSection () {
    return this.id === PlanFloors.CrossSection
  }

  /**
   * Checks whether the floor is just a regular floor
   * @type {Boolean}
   */
  get isFloor () {
    return this.id !== PlanFloors.CrossSection
  }

  /**
   * Indicates whether the floor supports displaying of radiation patterns
   * @type {Boolean}
   */
  get hasRadiation () {
    return !this.isCrossSection
  }

  /**
   * Floor label
   * @type {String}
   */
  label

  /**
   * More details
   * @type {String}
   */
  details

  /**
   * Floor plan dimensions
   * @type {Size}
   */
  dimensions

  /**
   * Physical height of the floor space, in meters.
   * Used for cable length calculations of cross-floor cables
   * @type {Number}
   */
  roomHeight

  /**
   * Properties of the floor on the cross-section view
   * @type {PlanFloorCrossSectionProperties}
   */
  crossSection

  /**
   * Zoom level
   * @type {PlanScale}
   */
  zoom

  /**
   * Image margin
   * @type {Margin}
   */
  margin

  /**
   * Map scale, expressed as `pixels per meter` value
   * @type {Number}
   */
  mapScale

  /**
   * Indicates that map scale is specified
   * @type {Boolean}
   */
  get hasMapScale () {
    return this.mapScale > 0
  }

  /**
   * Converts pixels to meters using the current {@link mapScale}
   * @param {Number} value Pixels
   * @param {Boolean} round If true, the result will be rounded
   * @returns {Number}
   */
  pixelsToMeters (value, round = true) {
    if (value >= 0 && this.hasMapScale) {
      return round
        ? Math.round(value / this.mapScale)
        : value / this.mapScale
    }
  }

  /**
   * Converts meters to pixels using the current {@link mapScale}
   * @param {Number} value Meters
   * @returns {Number}
   */
  metersToPixels (value) {
    if (value >= 0 && this.hasMapScale) {
      return Math.round(value * this.mapScale)
    }
  }

  /**
   * Checks whether the floor can be deleted
   * @type {Boolean}
   */
  get canDelete () {
    return this.id !== PlanFloors.CrossSection &&
      this.id !== PlanFloors.Main
  }

  /**
   * Canvas dimensions required to fit the plan, derived from {@link dimensions},
   * @link
   *  and the current {@link zoom} level
   */
  get canvasDimensions () {
    const { dimensions, zoom, margin } = this
    return Size
      .from(dimensions)
      .growBy(margin)
      .scale(zoom)
      .round()
  }

  /**
   * Plan bounds
   * @type {Rectangle}
   */
  get bounds () {
    const { dimensions: { width, height } } = this
    return new Rectangle({ x: 0, y: 0, width, height })
  }

  /**
   * Plan background
   * @type {PlanBackground}
   */
  background

  /**
   * Indicates that background image has been specified
   * @type {Boolean}
   */
  get hasImage () {
    return this.background?.hasImage
  }

  /**
   * Removes the image from the floor
   */
  clearImage () {
    this.background?.clearImage()
  }

  /**
   * Indicates that background image is now being loaded
   * from the external storage
   * @type {Boolean}
   * @description RUNTIME
   */
  isLoadingImage

  /**
   * Plan radiation map
   * @type {PlanRadiation}
   */
  radiation

  /**
   * Plan layers
   * @type {Array[PlanLayer]}
   */
  layers

  /**
   * Finds the specified layer
   * @param {String} id Layer identifier
   * @returns {PlanLayer}
   */
  getLayer (id) {
    return (this.layers || []).find(layer => layer.id === id)
  }

  /**
   * Items on the floor
   * @type {Array[PlanItem]}
   */
  get items () {
    return this.__items
  }

  set items (value) {
    this.__items = value
  }

  /**
   * Items on the floor which should be displayed on the cross-section view
   * @type {Array[PlanItem]}
   */
  get crossSectionItems () {
    return this.items.filter(i => i.showOnCrossSection)
  }

  /**
   * Checks whether the floor contains the specified item
   * @param {PlanItem} item Plan item
   * @returns {Boolean}
   */
  hasItem (item) {
    return item
      ? (this.items || []).some(i => i.id === item.id)
      : null
  }

  /**
   * Returns items which belong to the specified layer of the floor
   * @param {Number} layerId Layer identifier
   * @returns {Array[PlanItem]}
   */
  getItemsOn (layerId) {
    if (this.id === PlanFloors.CrossSection && layerId === PlanLayers.Items) {
      return (this.items || []).filter(i => i.showOnCrossSection)
    } else {
      return (this.items || []).filter(i => i.layer === layerId)
    }
  }

  /**
   * Returns item with the specified id
   * @param {String} id Item identifier
   * @returns {PlanItem}
   */
  getItem (id) {
    return this.items.find(item => item.id === id)
  }

  /**
   * Ensures that plug for the specified connector exists
   * @param {PlanConnector} connector Connector
   * @param {PlanRiser} riser Riser through which the connector passes
   * @returns Returns the added items in form of `{ added: Array }` object
   */
  ensurePlug (connector, riser) {
    const added = []

    if (connector && riser && connector.toFloor(this)) {
      // Ensure that there's a plug representing connector entering the riser
      let plug = this.items.find(item => item.isPlug && item.riser === riser.id)
      if (!plug) {
        plug = new PlanPlug({
          floorId: this.id,
          riser: riser.id,
        })
        plug.moveTo({
          x: plug.width,
          y: plug.width
        })
        this.addItem(plug)
        added.push(plug)
      }

      // Ensure that there's a virtual cable
      // representing the part of the connector leading from the item to the plug
      const { start, end } = connector
      const from = start.item.floorId === this.id ? start : end
      const hasPlugConnector = this.items.some(i => i.isConnector && i.partOf === connector.id && i.goesIntoRiser)

      if (!hasPlugConnector) {
        const plugConnector = new PlanCable({
          partOf: connector.id,
          goesIntoRiser: riser.id,
          start: {
            id: from.id,
            type: from.type,
            itemId: from.itemId,
            item: from.item,
          },
          end: {
            id: plug.port.id,
            type: plug.port.type,
            itemId: plug.id,
            item: plug
          }
        })
        this.addItem(plugConnector)
        added.push(plugConnector)
      }

      return { added }
    }
  }

  /**
   * Returns wall items on the radiation layer
   * @type {Array[PlanItem]}
   */
  get walls () {
    return this.items.filter(i => i.isWall)
  }

  /**
   * Returns yard items on the radiation layer
   * @type {Array[PlanItem]}
   */
  get yards () {
    return this.items.filter(i => i.isYard)
  }

  /**
   * Checks whether the floor has any walls defined
   * @type {Boolean}
   */
  get hasWalls () {
    return this.items.some(i => i.isWall)
  }

  /**
   * Adds the specified plan item to the floor
   * @param {PlanItem} item Item to add
   * @returns {PlanItem} Added item
   */
  addItem (item) {
    if (!item) throw new Error('Item is required')
    item.floorId = this.id
    item.index = this.itemIndex++
    this.items.push(item)
    return item
  }

  /**
   * Removes the specified plan item from the floor
   * @param {PlanItem} item Item to remove
   */
  removeItem (item) {
    if (item) {
      delete item.shape
      this.items = this.items.filter(i => i.id !== item.id)
    }
  }

  /**
   * Removes all items from the floor
   */
  clear () {
    this.items = []
  }

  /**
   * Checks whether there are no items on the floor
   * @type {Boolean}
   */
  get isEmpty () {
    return !(this.items?.length > 0)
  }

  /**
   * Returns all connectors on the floor
   * @returns {Array[PlanConnector]}
   */
  getConnectors () {
    return (this.items || []).filter(i => i.isConnector)
  }

  /**
   * Returns all connectors connected to the specified item
   * @param {PlanItem} item Plan item
   * @returns {Array[PlanConnector]}
   */
  getConnectorsOf (item) {
    return this.items.filter(i => i.isConnector && i.isConnectedTo(item))
  }

  /**
   * Returns all antennae on the floor
   * @returns {Array[PlanAntenna]}
   */
  getAntennae () {
    return (this.items || []).filter(i => i.isAntenna)
  }

  /**
   * Moves the item on z axis to the top
   * @param {PlanItem} item Item to move to the top
   * @returns {Number} New index of the item
   */
  moveToTop (item) {
    const index = this.items.findIndex(i => i.id === item.id)
    if (index > -1) {
      const items = this.items.splice(index, 1)
      this.items = [...this.items, ...items]
      return this.items.length - items.length
    }
  }

  /**
   * Moves the item on z axis to the bottom
   * @param {PlanItem} item Item to move to the bottom
   * @returns {Number} New index of the item
  */
  moveToBottom (item) {
    const index = this.items.findIndex(i => i.id === item.id)
    if (index > -1) {
      const items = this.items.splice(index, 1)
      this.items = [...items, ...this.items]
      return 0
    }
  }

  /**
   * Changes the dimensions of the floor plan
   * @param {Point} value Floor plan size
   */
  setSize (value) {
    if (value) {
      const { dimensions, defaults: { minSize } } = this
      const width = Math.max(minSize.x, notNull(value.width, dimensions.width))
      const height = Math.max(minSize.y, notNull(value.height, dimensions.height))
      dimensions.setSize({ width, height })
    }
  }

  /**
   * Changes the margin around the floor plan image
   * @param {Margin} value Margin to set
   */
  setMargin (value) {
    if (value) {
      const { margin, background } = this
      margin.setMargin(value)
      if (background?.imagePosition) {
        background.imagePosition.moveTo({
          x: value.left,
          y: value.top
        })
      }
    }
  }

  /**
   * Changes the floor view zoom
   * @param {Number} value Zoom factor
   * @returns {Boolean} `true` if zoom has been changed
   */
  setZoom (value) {
    if (value != null) {
      const zoom = Math.min(10, Math.max(0.1, value))
      if (this.zoom.value !== zoom) {
        this.zoom.value = zoom
        return true
      }
    }
  }

  /**
   * Changes the floor view zoom
   * @param {Number} value Zoom change, negative if zooming out
   * @returns {Boolean} `true` if zoom has been changed
   */
  zoomBy (value) {
    if (value != null) {
      return this.setZoom(this.zoom.value + value)
    }
  }

  /**
   * Clears all walls and yards on the floor plan
   */
  clearWalls () {
    this.items = this.items.filter(i => !(i.isWall || i.isYard))
  }

  /**
   * Locks/unlocks all walls and yards on the floor plan
   * @param {Boolean} isLocked Lock status
   */
  lockWalls (isLocked) {
    this.radiation.lockWalls = isLocked
    for (const item of [...this.walls, ...this.yards]) {
      item.isLocked = isLocked
    }
  }

  /**
   * Indicates that floor plan image is now being loaded / has been loaded from an external source
   * @param {Boolean} status
   */
  loadImage (status = true) {
    this.isLoadingImage = Boolean(status)
  }

  /**
   * Finds items overlapping with this item
   * @param {PlanItem} item Item to check
   * @returns {Array[PlanItem]} Items on the floor which overlap with the specified item
   */
  findOverlappingItems (item, margin) {
    const { items, isCrossSection } = this
    const bounds = item
      .getBounds(isCrossSection)
      .growBy({
        left: margin,
        top: margin,
        right: margin,
        bottom: margin
      })
    return items.filter(i => i.id !== item.id && i.getBounds(isCrossSection).overlapsWith(bounds))
  }

  /**
   * Checks whether the item is not sticking out of the floor view
   * @param {PlanItem} item Item to check
   * @returns {Boolean}
  */
  isItemFullyVisible (item) {
    const { bounds: floorBounds, isCrossSection } = this
    const bounds = item.getBounds(isCrossSection)
    return floorBounds.contains(bounds)
  }
}

/**
 * Cross-section properties of a plan floor
 */
export class PlanFloorCrossSectionProperties extends Assignable {
  constructor (data) {
    super()
    this.assign(data)
  }

  /**
   * Object defaults
   * @return {Object}
   */
  get defaults () {
    return {
      isExpanded: true,
      height: 500
    }
  }

  normalize () {
    super.normalize()
    const { defaults } = this

    this.isExpanded = parseBoolean(this.isExpanded, defaults.isExpanded)
    this.bounds = this.cast(this.bounds, Rectangle, Rectangle.Zero)
  }

  /**
   * Serializes the plan item to JSON
   * @returns {Object}
   */
  toJSON () {
    const result = { ...this }

    // Delete default values
    this.clearDefaults(result)

    return result
  }

  /**
   * Indicates whether the floor is expanded
   * @type {Boolean}
   * @description RUNTIME
   */
  isExpanded

  /**
   * Floor index
   * @type {Number}
   * @description RUNTIME
   */
  index

  /**
   * Item index, increasing monotonically as items are being added
   * @type {Number}
   * @description RUNTIME
   */
  itemIndex = 1

  /**
   * Floor bounds, specified using absolute coordinates on the cross-section view
   * @type {Rectangle}
   */
  bounds

  /**
   * Floor height
   * @type {Number}
   */
  get height () {
    return this.bounds?.height || this.defaults.height
  }

  /**
   * Sets the floor height
   * @param {Number} value New floor height
   */
  set height (value) {
    if (this.bounds) {
      this.bounds.height = value
    }
  }

  /**
   * Checks whether the specified point belongs on the specified floor
   * on the cross-section view
   * @param {Point} point Point to check
   * @returns {Boolean}
   */
  contains (point) {
    return point && this.bounds && this.bounds.contains(point)
  }
}
