import { NotificationsAPI } from '@stellacontrol/client-api'
import { dispatch } from '@stellacontrol/client-utilities'
import UnsubscribeView from './unsubscribe.vue'

const name = 'unsubscribe-notifications'

export const Routes = [
  {
    name,
    path: '/notifications/unsubscribe/:token',
    component: UnsubscribeView,

    async beforeEnter (to, from, next) {
      // Fetch the unsubscribe request specified in the route
      const { params: { token } } = to
      const request = await NotificationsAPI.getUnsubscribeRequest({ token })
      if (request && request.canUse) {
        await dispatch('setRouteData', { from, to, data: { token, request } })
        next()
      } else {
        next({ name: 'login' })
      }
    }
  }
]
