import { Log, assign } from '@stellacontrol/utilities'

/**
 * Assignable instance
 */
export class Assignable {
  constructor (data = {}) {
    this.assign({ ...data })
  }

  /**
   * Default values for the object
   */
  get defaults () {
    return {}
  }

  /**
   * Checks whether all properties listed in {@link defaults}
   * have their specified default values
   * @param {Boolean} strict If true, the object must not have any values which are not listed under {@link defaults}
   * @param {Object} defaults Defaults to check. If not specified, built-in {@link defaults} are used.
   */
  isDefault (strict = true, defaults) {
    const hasDefaults = Object
      .entries(defaults || this.defaults || {})
      .every(([key, value]) => this[key] === value)

    if (hasDefaults) {
      return strict
        ? Object.keys(this).every(key => key in this.defaults)
        : true
    }

    return false
  }

  /**
   * Removes properties from the object which are equal to the specified defaults.
   * Useful when optimizing for serialization
   * @param {Object} data Data object to clear. If not specified, this object itself is used.
   * @param {Object} defaults Defaults to clear
   * @param {Array[String]} keep List of properties which should be always stored, regardless whether thay have default value or not
   */
  clearDefaults (data, defaults, keep = []) {
    data = data == null ? this : data
    for (const [key, value] of Object.entries(defaults || this.defaults || {})) {
      if (data[key] === value && !keep.includes(key)) {
        delete data[key]
      }
    }
    return data
  }

  /**
   * Normalizes the data after assignment, for example converting
   * the properties to their correct types
   */
  normalize () {
  }

  /**
   * Assigns data to the instance
   * @param {Object} data Data to assign to the instance
   * @param {Dictionary<string, Function>} map Dictionary of property value mappers
   * @returns {Object} Modified instance
   */
  assign (data, map) {
    if (data) {
      assign(this, data, map)
    }
    this.normalize()
    return this
  }

  /**
   * Clones the instance.
   * @returns {Object} Instance of the same type containing all data of this instance
   */
  clone () {
    const data = JSON.parse(JSON.stringify(this))
    const clone = new this.constructor(data)
    clone.normalize()
    return clone
  }

  /**
   * Casts the specified instance into a given type.
   * Ignores and returns as-is if it's already a correct type
   * @param {Object} instance Instance to type-cast
   * @param {Function} constructor Constructor of the result class
   * @param {any} defaultValue Value to assign if instance is not specified
   * @returns {Object} Instance casted to the specified class
   */
  cast (instance, constructor, defaultValue) {
    if (instance != null && constructor) {
      if (instance.constructor == constructor) {
        return instance
      } else {
        return new constructor(instance)
      }
    } else {
      return defaultValue == null ? instance : defaultValue
    }
  }

  /**
   * Casts all instances in the specified array into a given type.
   * Ignores and returns as-is instances which are already a correct type.
   * @param {Array[Object]} instances Instance to type-cast
   * @param {Function} constructor Constructor of the result class
   * @param {any} defaultValue Value to assign if instance is not specified
   * @returns {Array[Object]} Instances casted to the specified class
   */
  castArray (instances, constructor, defaultValue) {
    if (instances != null && constructor) {
      if (Array.isArray(instances)) {
        return instances.map(instance =>
          instance == null
            ? instance
            : (instance.constructor === constructor ? instance : new constructor(instance))
        )
      } else {
        Log.warn(`Attempt to perform castArray of a non-array ${constructor.name} instance`, instances)
        // eslint-disable-next-line no-console
        console.trace()
      }
    } else {
      return defaultValue == null ? instances : defaultValue
    }
  }

  /**
   * Casts the specified value to a given type and
   * overrides default values with any custom ones
   * @param {Function} constructor Property class constructor
   * @param {Object} defaults Default values to assign
   * @param {Object} custom Custom values to assign
   */
  customize (custom, constructor, defaults) {
    // If any defaults specified, start from these, otherwise start from the current value
    const result = this.cast(defaults || custom, constructor)
    if (result && custom) {
      // Get any customizations, parse them to the same class
      const customValues = this.cast(custom, constructor)
      // Assign customizations to the defaults
      for (const key of Object.keys(custom)) {
        result[key] = customValues[key]
      }
    }
    return result
  }
}
