/**
 * Application state
 */
export const state = {
  // Application flags
  flags: null,

  // Application update poller
  applicationUpdate: {
    // Watcher for application updates
    clock: null,
    // New available application version
    updatedTo: null
  }
}

export const getters = {
  // Indicates that there's a new version of the application available
  isNewVersionAvailable: state =>
    Boolean(state.applicationUpdate.updatedTo)
}
