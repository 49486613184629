import { Assignable } from '@stellacontrol/model'

/**
 * Describes a change in definition of MEGA parameter
 * applicable for some specific version, device type etc.
 */
export class MegaParameterChange extends Assignable {
  constructor (data = {}) {
    super()
    this.assign({ ...data })
  }

  /**
   * Firmware version to which the change of the definition applies.
   * Optionally preceded by operator = < > >= <= != then space, i.e. != 6.6.4 or >= 6.5.0,
   * @type {String}
   */
  firmware

  /**
   * Hardware version to which the change of the definition applies.
   * Optionally preceded by operator, like with {@link firmware} version.
   */
  hardware

  /**
   * EEPROM version to which the change of the definition applies.
   * Optionally preceded by operator, like with {@link firmware} version.
   */
  eeprom

  /**
   * MEGA version to which the change of the definition applies.
   * Optionally preceded by operator, like with {@link firmware} version.
   */
  mega

  /**
   * Device type to which the change of the definition applies.
   * Optionally preceded by operator = or != followed by space, i.e. repeater, != combiner
   * @type {DeviceType}
   */
  type

  /**
   * Dictionary of parameter values, which are used instead of default parameter values
   * when parameter change is applicable
   * @type {Object}
   */
  then
}
