import { PlanItemType, PlanItemState, PlanLineStyle } from '@stellacontrol/planner'
import { LineShape } from './line'

/**
 * Connector
 */
export class ConnectorShape extends LineShape {
  constructor (item, dataCallback) {
    super(item, dataCallback)
  }

  static get type () {
    return PlanItemType.Connector
  }

  /**
   * Last-evaluated connector points
   * @type {Array[Point]}
   */
  __points

  /**
   * Starting point of the connector
   * @type {Point}
   */
  startPoint

  /**
   * Ending point of the connector
   * @type {Point}
   */
  endPoint

  /**
   * Returns an array of connector points
   * @param {PlanRenderer} renderer Plan renderer
   * @returns {Array[Point]}
   */
  getShapePoints ({ renderer }) {
    // Renderer is required, as to determine connector point locations, we need to know
    // the whereabouts of other shapes to which the connector is linked
    if (!renderer) throw new Error('Renderer is required')

    // Get points, correct coordinates so that start and end are at correct port positions
    const { shapes, isCrossSection } = renderer
    const { item } = this
    const startShape = shapes.find(s => s.item.id === item.start.itemId)
    const endShape = shapes.find(s => s.item.id === item.end.itemId)
    const scale = renderer.getEquipmentScale(item)

    this.startPoint = startShape?.getConnectionPoint({
      renderer,
      port: item.start,
      scale,
      expanded: startShape.portsExpanded
    })

    this.endPoint = endShape?.getConnectionPoint({
      renderer,
      port: item.end,
      scale,
      expanded: endShape.portsExpanded
    })

    const hasBothEnds = this.startPoint && this.endPoint

    let points = [
      this.startPoint,
      ...item.getTurns(isCrossSection),
      this.endPoint
    ].filter(c => c != null)

    if (hasBothEnds) {
      // Align points, to maintain straight angles
      points = this.alignPoints(renderer, points)
    }

    // Store back the adjusted points in the item definition.
    // Just remove the start and end point (still missing if drawing a new connector),
    // as they're not stored in `turns` collection but rather calculated from the items
    // to which the connector is attached
    const turns = points.slice(this.startPoint ? 1 : 0, points.length - (this.endPoint ? 1 : 0))
    this.item.setTurns(turns, isCrossSection)

    // Store the position of the connector start and end point
    // in the properties of the connected item
    this.item.start.point = this.startPoint
    this.item.end.point = this.endPoint

    this.__points = points
    return points
  }

  /**
   * Aligns newly calculated connector points if smart layout is enabled
   * @param {PlanRenderer} renderer Plan renderer
   * @returns {Array[Point]}
   */
  alignPoints (renderer, points) {
    const canAlign = renderer.layout.smartLayout && points.length > 2

    if (canAlign) {
      // Adjust points adjacent to moving ends of the connector,
      // to retain right angles
      const { __points: previous } = this
      if (previous?.length === points.length) {
        this.alignPoint(points, previous, 1, 0)
        this.alignPoint(points, previous, points.length - 2, points.length - 1)
      }

      // Remove identical points, but only if user is not interacting,
      // otherwise we might remove points which accidentally came close
      // as user was moving connector points
      if (!renderer.isUserActive) {
        points = points.filter((p, i) => i === 0 || i === points.length - 1 || !p.sameAs(points[i - 1]))
      }
    }

    return points
  }

  /**
   * Adjusts newly calculated connector points if smart layout is enabled:
   * - Keep the neighbors of the end and start point aligned
   * @param {Array[Point]} current Coordinates of points to adjust
   * @param {Array[Point]} previous Previous coordinates of the points to adjust
   * @param {Number} index Index of a point to adjust
   * @param {Number} reference Index of a point to adjust to
   */
  alignPoint (current, previous, index, reference) {
    const margin = 5
    const point = previous[index]
    const neighbour = previous[reference]
    if (neighbour) {
      const delta = neighbour.delta(point)
      if (Math.abs(delta.x) <= margin) {
        current[index].x = current[reference].x
      } else if (Math.abs(delta.y) <= margin) {
        current[index].y = current[reference].y
      }
    }
    return point
  }

  /**
   * Returns an array of coordinates of moveable joints
   * @param {PlanRenderer} renderer Plan renderer
   * @returns {Array[Point]}
   */
  // eslint-disable-next-line no-unused-vars
  getShapeJoints (renderer) {
    const points = this.getShapePoints({ renderer })
    return points.slice(1, points.length - 1)
  }

  /**
   * Returns shape line style, appropriate for the specified state.
   * @param {PlanItem} item Item for which to determine the line style
   * @param {PlanLineStyle} style Optional style to tap into. If not specified, we're using `lineStyle` of the item
   * @param {PlanItemState} state Optional shape state
   * @param {PlanRenderer} renderer Plan renderer
   */
  getLineStyle (item, style, state, renderer) {
    if (item && renderer) {
      style = style || item.lineStyle
      if (state === PlanItemState.Hover) {
        const width = Math.round(7 / renderer.zoom)
        style = new PlanLineStyle({ ...style, width })
      } else {
        style = new PlanLineStyle(style)

        // Riser cables have their distinct style
        if (item.goesIntoRiser) {
          style.color = '#404040'
        }
      }
      return style
    }
  }

  /**
   * Renders the shape
   * @param {PlanRenderer} renderer
   */
  render (renderer) {
    super.render(renderer)
  }
}
