<script>
import { mapGetters } from 'vuex'
import { OrganizationSortOrder, sortOrganizations, getOrganizationIcon, getOrganizationColor } from '@stellacontrol/model'

export default {
  props: {
    // Organization selected initially
    modelValue: {
      required: true
    },

    // List of organizations to select from
    items: {
      type: Array,
      required: true
    },

    // Dense rendering
    dense: {
      type: Boolean,
      default: false
    },

    // Sort order
    order: {
      type: String,
      default: OrganizationSortOrder.Rank
    }
  },

  data () {
    return {
      selection: this.modelValue,
      matchingItems: []
    }
  },

  computed: {
    ...mapGetters([
      'currentOrganization'
    ]),

    allOrganizations () {
      const { items, order, currentOrganization } = this
      return sortOrganizations(items, order, { currentOrganization })
    },

    selectedOrganization () {
      return this.allOrganizations.find(item => item.id === this.selection)
    }
  },

  watch: {
    modelValue () {
      this.selection = this.modelValue
    },

    items () {
      this.matchingItems = [...this.allOrganizations]
    }
  },

  methods: {
    getOrganizationIcon,
    getOrganizationColor,

    // Notify about model change
    organizationSelected (value) {
      this.$emit('update:model-value', value)
    },

    // Filter function for free-text filtering of companies by name
    filterOrganizations (value = '', update) {
      update(() => {
        if (value === '') {
          this.matchingItems = [...this.allOrganizations]
        } else {
          const text = value.toLowerCase()
          this.matchingItems = this.allOrganizations.filter(o => o.name.toLowerCase().includes(text))
        }
      })
    }
  },

  created () {
    this.selection = this.modelValue
    this.matchingItems = [...this.allOrganizations]
  }
}
</script>

<template>
  <q-select
    v-model="selection"
    :options="matchingItems"
    outlined
    emit-value
    map-options
    option-value="id"
    option-label="name"
    use-input
    debounce="500"
    fill-input
    hide-selected
    hide-bottom-space
    :dense="dense"
    @filter="filterOrganizations"
    @update:model-value="value => organizationSelected(value)"
  >
    <template v-slot:option="scope">
      <q-item v-bind="scope.itemProps">
        <q-item-section side>
          <q-icon
            :name="getOrganizationIcon(scope.opt)"
            :color="getOrganizationColor(scope.opt)"
            size="sm"
          />
        </q-item-section>
        <q-item-section>
          <q-item-label v-html="scope.opt.name" />
        </q-item-section>
      </q-item>
    </template>

    <!--
      Pass all other custom slots from the parent
    -->
    <template v-for="(_, name) in $slots" v-slot:[name]="slotData">
      <slot :name="name" v-bind="slotData" :props="slotData"></slot>
    </template>

  </q-select>
</template>

<style>
</style>