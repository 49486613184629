import { dispatch } from '@stellacontrol/client-utilities'

/**
 * Resolves the data required by the All Places route
 */
// eslint-disable-next-line no-unused-vars
export async function resolve ({ from, to }) {
  await dispatch('requireOrganizations')
  await dispatch('requirePlaces')
  await dispatch('requireDevices')
  await dispatch('requireUsers')
  await dispatch('setRouteData', { from, to })
  await dispatch('initializeInstallationsView')
}
