<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { DialogMixin } from '@stellacontrol/client-utilities'
import { Secure } from '@stellacontrol/security-ui'
import { WalletTransactionType } from '@stellacontrol/model'

const dialog = 'sell-tokens'

export default {
  mixins: [
    DialogMixin,
    Secure
  ],

  data () {
    return {
      dialog,
      // Customer to sell tokens to
      customer: null,
      // Customer wallet
      customerWallet: null,
      // Amount of tokens to sell
      amount: 0,
      // Notes
      notes: ''
    }
  },

  computed: {
    ...mapState({
      myWallet: state => state.serviceManagement.wallet
    }),

    ...mapGetters([
      'isSmallScreen'
    ]),

    // Current organization's available tokens.
    balance () {
      return this.myWallet.balance
    },

    // Indicates whether current organization has enough tokens to sell
    hasSufficientBalance () {
      return this.isBank || this.balance >= this.amount
    },

    // New balance of customer's wallet after adding the tokens
    newBalance () {
      const { balance, amount } = this
      return balance - Math.max(0, amount || 0)
    },

    // Indicates whether current organization's wallet will become empty after transaction
    walletWillBeEmpty () {
      return !this.isBank && this.newBalance <= 0
    },

    // Current organization's available tokens.
    customerBalance () {
      return this.customerWallet?.balance || 0
    },

    // New balance of customer's wallet after adding the tokens
    newCustomerBalance () {
      const { customerBalance, amount } = this
      return customerBalance + Math.max(0, amount || 0)
    }
  },

  emit: [
    'show',
    'hide',
    'transfer'
  ],

  methods: {
    ...mapActions([
      'dialogOk',
      'dialogCancel',
      'creditTokens',
      'transferTokensTo'
    ]),

    // Validates and OKs the dialog
    async ok (data) {
      this.dialogOk({ dialog, data })
      this.$emit('close')
    },

    // Cancels the dialog
    cancel () {
      this.dialogCancel({ dialog })
      this.$emit('close')
    },

    // Triggered when dialog is shown
    dialogShown () {
      this.customer = this.data.customer
      this.customerWallet = this.data.customerWallet
      this.$emit('show')
    },

    // Triggered when dialog is hidden
    dialogHidden () {
      this.$emit('hide')
    },

    // Credits the token to customer's wallet
    async credit () {
      const { notes, isBank, customer, amount, myWallet, customerWallet, newCustomerBalance } = this

      if (isBank) {
        await this.creditTokens({ customer, amount, notes })
      } else {
        await this.transferTokensTo({ customer, amount, notes })
      }

      this.amount = 0
      this.notes = ''
      customerWallet.balance = newCustomerBalance
      this.ok({ myWallet, customerWallet, amount, notes })
      this.$emit('transfer', { customer, transactionType: WalletTransactionType.Credit, amount, notes })
    }
  },

  mounted () {
  }
}

</script>

<template>
  <sc-dialog persistent :dialog="dialog" :maximized="isSmallScreen" @dialogShown="dialogShown()"
    @dialogHidden="dialogHidden()">

    <q-form class="form" ref="form">
      <div class="content">

        <q-banner class="banner bg-indigo-6">
          <div class="row items-center">
            <span class="text-white title">
              Add tokens to the wallet of {{ customer.name }}
            </span>
            <q-space></q-space>
            <q-btn label="Close" unelevated class="primary" @click="ok()"></q-btn>
          </div>
        </q-banner>

        <div class="q-mt-md q-ml-lg q-mr-lg q-mb-lg inside">
          <div class="text-body2 column q-mb-md" v-if="!isBank">
            <span>
              There {{ balance === 1 ? 'is' : 'are' }} currently <b>
                {{ balance === 0 ? 'no' : balance }}</b>
              {{ balance === 1 ? 'token' : 'tokens' }}
              in your wallet.
            </span>
            <span v-if="balance === 0">
              Please contact your reseller to purchase more tokens!
            </span>
          </div>

          <div v-if="hasSufficientBalance" class="transaction">
            <div class="q-pr-md" v-if="isBank">
              Enter the amount of tokens to add to the customer's wallet.
            </div>
            <div v-else>
              Enter the amount of tokens to move from your wallet to the customer's wallet.
            </div>

            <div class="row items-center no-wrap q-mt-md">
              <div class="from q-pa-md q-mr-md"
                :class="{ 'bg-green-1': !walletWillBeEmpty, 'bg-red-1': walletWillBeEmpty }">
                <header>
                  From: {{ currentOrganization.name }}
                </header>
                <main v-if="isBank" class="text-h6 q-mt-md text-green-8">
                  Bank
                </main>
                <main v-else class="text-h6 q-mt-md"
                  :class="{ 'text-green-8': !walletWillBeEmpty, 'text-red-8': walletWillBeEmpty }">
                  {{ newBalance }} token{{ newBalance === 1 ? '' : 's' }}
                </main>
              </div>

              <div class="amount row items-center no-wrap q-gutter-md">
                <q-icon class="icon" size="lg" name="double_arrow"
                  :color="amount >= 0 ? 'green-3' : 'red-3'">
                </q-icon>
                <q-input class="input" v-model.number="amount" type="number"
                  :min="0"
                  :max="isBank ? Number.MAX_SAFE_INTEGER : balance"
                  :style="{ width: '70px' }" square dense outlined />
                <q-icon class="icon" size="lg" name="double_arrow"
                  :color="amount >= 0 ? 'green-3' : 'red-3'">
                </q-icon>
              </div>

              <div class="to q-pa-md q-ml-md"
                :class="{ 'bg-green-1': newCustomerBalance > 0, 'bg-red-1': newCustomerBalance === 0 }">
                <header>
                  To: {{ customer.name }}
                </header>
                <main class="text-h6 q-mt-md"
                  :class="{ 'text-green-8': newCustomerBalance > 0, 'text-red-8': newCustomerBalance === 0 }">
                  {{ newCustomerBalance }} token{{ newCustomerBalance === 1 ? '' : 's' }}
                </main>
              </div>

            </div>

            <div class="notes q-mt-md">
              <q-input v-model="notes" type="textarea" outlined label="Notes" bg-color="white"
                :input-style="{ height: '120px' }">
              </q-input>
            </div>

            <div class="row justify-end q-mt-lg">
              <q-space></q-space>
              <q-btn v-if="amount !== 0" flat unelevated class="q-ml-md primary" label="Add tokens"
                @click="credit()">
              </q-btn>
              <q-btn label="Close" unelevated class="q-ml-md" @click="ok()"></q-btn>
            </div>
          </div>

        </div>
      </div>

    </q-form>

  </sc-dialog>
</template>

<style lang='scss' scoped>
.form {
  width: 660px;
  min-height: 450px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .banner {
      flex: 0;
    }

    .inside {
      flex: 1;
      overflow: auto;
    }

    .title {
      font-size: 18px;
    }

    .transaction {
      .from {
        flex: 1;
      }

      .to {
        flex: 1;
      }
    }
  }
}

/* Layout adjustments for screen below HD resolution */
@media screen and (max-width: 1365px) {
  .q-dialog.fullscreen {
    top: 40px;
  }

  .form {
    width: 100%;
    min-height: 100%;
  }
}
</style>
