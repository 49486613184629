/**
 * v-focus directive making elements moveable.
 * The element must be absolutely positioned using CSS.
 * @description
 * HANDLE ELEMENT
 *    By default, the entire moveable element serves as grab handle.
 *    Specify CSS selector of alternative handle element as directive value,
 *    for example `v-moveable="'header'"`. The handle element will be sought first
 *    inside the element, then in the entire document.
 *
 * ANCHOR
 *    By default the moved element is anchored to `left` `bottom` position.
 *    You can change this by using modifiers `left` or `right` for `X` coordinate
 *    and `top` or `bottom` for `Y` coordinate. For example, `v-moveable.right.bottom`
 *    keeps the moved element attached to the right-bottom corner.
 */
export const MoveableDirective = {
  attribute: 'moveable',
  directive: {
    mounted: (element, binding) => {
      const handle = binding.value
        ? element.querySelector(binding.value) || document.querySelector(binding.value) || element
        : element

      let elementMoved = false
      let previousCursor, previousUserSelect

      const onMove = ({ movementX, movementY }) => {
        // Use `move` cursor and prevent marking text as selected
        document.body.style.cursor = 'move'
        element.style['user-select'] = 'none'

        const style = window.getComputedStyle(element)
        const left = parseInt(style.left)
        const right = parseInt(style.right)
        const top = parseInt(style.top)
        const bottom = parseInt(style.bottom)

        const toRight = binding.modifiers.right
        const toBottom = binding.modifiers.bottom

        if (Math.abs(movementX) > 0) {
          if (toRight) {
            elementMoved = true
            element.style.right = `${right - movementX}px`
          } else {
            element.style.left = `${left + movementX}px`
            elementMoved = true
          }
        }

        if (Math.abs(movementY) > 0) {
          if (toBottom) {
            element.style.bottom = `${bottom - movementY}px`
            elementMoved = true
          } else {
            element.style.top = `${top + movementY}px`
            elementMoved = true
          }
        }
      }

      const onMoved = (e) => {
        // Restore pointer style and user selection style
        document.body.style.cursor = previousCursor
        element.style['user-select'] = previousUserSelect

        window.removeEventListener('mousemove', onMove)
        window.removeEventListener('mouseup', onMoved)
        window.removeEventListener('click', onMoved)

        if (elementMoved) {
          e.stopPropagation()
          e.preventDefault()
          elementMoved = false
        }
      }

      handle.addEventListener('mousedown', () => {
        // Preserve pointer style and user selection style
        previousCursor = document.body.style.cursor
        previousUserSelect = element.style['user-select']

        // Wait for dragging to finish
        window.addEventListener('mousemove', onMove)
        window.addEventListener('mouseup', onMoved)
        window.addEventListener('click', onMoved)
      })

    }
  }
}
