/**
 * Check if the specified value exists in the given instance
 * @param {Object} items Object with enumeration values specified as fields
 * @param {any} value Value to verify
 * @param {Boolean} allowNullish If true, `null` and `undefined` are also seen as valid enumeration values
 * @returns {Boolean} True if specified value is a valid enumeration value
 */
export function isEnum (items, value, allowNullish = true) {
  if (!items) {
    return undefined
  }

  if (value == null) {
    return allowNullish
  }

  return Object.getOwnPropertyNames(items)
    .filter(property => !(property.value && typeof property.value === 'function'))
    .map(property => items[property])
    .includes(value)
}

/**
 * Parses the specified value as enum
 * @param {Object} items Object with enumeration values specified as fields
 * @param {any} value Value to parse
 * @param {any} defaultValue Value to return if {@link value} is not a valid value
 * @returns {any} Enumeration value
 */
export function parseEnum (items, value, defaultValue) {
  if (isEnum(items, value, false)) {
    return value
  } else {
    return defaultValue
  }
}
