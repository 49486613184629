<script>
import { isEnum, decodeBase64, toJSON } from '@stellacontrol/utilities'
import { DocumentType } from '@stellacontrol/model'
import { Converter as MarkdownConverter } from 'showdown'

/**
 * Document viewer component for viewing text, HTML and Markdown documents:
 *
 * - passed with `content` attribute
 * - specified as slot content
 * - specified as external URL
 *
 * The content can be optionally Base64-encoded. This is handy when importing
 * external documents using `rollup-url` plugin which embeds them as
 * Base64-encoded string variables.
 *
 * Warning! When passing markdown as slot content, remember to remove any left padding
 * from the text. The text must be aligned to the very left margin of the document,
 * otherwise it will be intepreted the way Markdown works - as CODE section.
 */

export default {
  props: {
    // Document URL
    url: {
      type: String
    },
    // Or alternatively, document content
    content: {
      type: String
    },
    // Document type, such as DocumentType.Markdown
    type: {
      type: String,
      required: true
    },
    // Indicates whether it's a hierarchic document whose sections can be expanded and collapsed
    isCollapsible: {
      type: Boolean,
      default: false
    },
    // Indicates whether hierarchic document such as markdown
    // should be initially collapsed to chapters
    isCollapsed: {
      type: Boolean,
      default: false
    },
    // Indicates whether Collapse All button is available
    allowCollapseAll: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      // Text of the document to display
      text: null,
      // Indicates whether the document has been rendered
      isReady: false,
      // Indicates whether all elements are now collapsed
      allCollapsed: false,
      // Document types
      DocumentType
    }
  },

  computed: {
    // Markdown-to-HTML converter
    markdownConverter () {
      return new MarkdownConverter({
        noHeaderId: true,
        simplifiedAutoLink: true,
        simpleLineBreaks: true,
        tables: true,
        tasklists: true,
        emoji: true,
        underline: true
      })
    },

    // Inner text to display, alternative to loading it from a document.
    // Can be specified as `content` property or simply inside the default slot.
    innerContent () {
      const inline = this.$refs.innerContent.querySelector('pre')?.innerHTML
      return inline || this.content
    }
  },

  methods: {
    // Loads document content
    async load () {
      let { url, innerContent, type, isCollapsible, isCollapsed } = this
      if (!(url || innerContent)) {
        throw new Error('Document URL or content is required')
      }
      if (!isEnum(DocumentType, type)) {
        throw new Error(`Document type ${type} is invalid. Supported types: ${Object.values(DocumentType).join(', ')}`)
      }

      this.isReady = false
      let content
      if (url) {
        const response = await fetch(url, { cache: 'no-store' })
        if (response.ok) {
          content = await response.text()
        }
      } else {
        content = this.innerContent
      }

      this.text = await this.prepare(content || '', type)

      if (isCollapsible) {
        this.$nextTick(() => {
          this.prepareHierarchy(isCollapsed)
          this.isReady = true
        })
      } else {
        this.isReady = true
      }
    },

    // Strips frontmatter tags from the MD document
    stripFrontMatter (content) {
      return content.replace(/---\n.*?\n---/s, '')
    },

    // Prepares document content for display
    async prepare (content, type) {
      // If content is Base64-encoded, decode it
      if (content.match(/data:.*;base64,/)) {
        const data = content.match(/[^,]*$/)
        if (data && data.length > 0) {
          content = decodeBase64(data[0])
        }
      }

      // Parse the content according to its type
      switch (type) {
        case DocumentType.Text:
        case DocumentType.HTML:
          return content

        case DocumentType.JSON:
          return toJSON(content, 2, content)

        case DocumentType.Markdown:
          content = this.stripFrontMatter(content)
          return this.markdownConverter.makeHtml(content)

        default:
          throw new Error(`Unsupported document type: ${type}`)
      }
    },

    // Prepares collapsible document sections, wires up expansion by click
    prepareHierarchy (isCollapsed) {
      const { type, $refs: { viewer } } = this
      if (!viewer) return

      if (type === DocumentType.Markdown) {
        const container = viewer.querySelector('.markdown')
        const headers = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']
        let chapter = 0
        const iconHTML = '<i class="icon-expand material-icons">chevron_right</i>'

        for (const child of container.childNodes) {
          const tag = (child.tagName || '').toLowerCase()
          if (tag) {
            child.classList.add(isCollapsed ? 'collapsed' : 'expanded')
            const isHeader = headers.includes(tag)
            // const isSubHeader = isHeader && tag !== 'h1'

            if (isHeader) {
              chapter++
              child.classList.add('chapter')
              child.setAttribute('chapter', chapter)
              child.addEventListener('click', () => this.toggleChapter(child))
              child.innerHTML = child.innerHTML + iconHTML
            }

            if (!isHeader) {
              if (chapter) {
                child.classList.add('chapter-element')
                child.setAttribute('chapter-element', chapter)
              }
            }
          }
        }

        this.allCollapsed = isCollapsed
      }
    },

    // Toggles the specified chapter
    toggleChapter (chapterElement) {
      const chapter = chapterElement.getAttribute('chapter')
      const { type, $refs: { viewer } } = this
      if (!chapter) return
      if (!viewer) return

      if (type === DocumentType.Markdown) {
        chapterElement.classList.toggle('collapsed')
        chapterElement.classList.toggle('expanded')

        const container = viewer.querySelector('.markdown')
        const elements = container.querySelectorAll(`*[chapter-element="${chapter}"]`)
        for (const element of Array.from(elements)) {
          element.classList.toggle('collapsed')
          element.classList.toggle('expanded')
        }

        const expandedElements = Array.from(container.querySelectorAll('*[chapter-element].expanded'))
        this.allCollapsed = expandedElements.length === 0
      }
    },

    // Toggles visibility of all chapters
    toggleAllChapters (expand) {
      const { type, $refs: { viewer } } = this
      if (!viewer) return

      if (type === DocumentType.Markdown) {
        const container = viewer.querySelector('.markdown')
        const elements = Array.from(container.querySelectorAll('*[chapter], *[chapter-element]'))
        for (const element of elements) {
          if (expand) {
            element.classList.remove('collapsed')
            element.classList.add('expanded')
          } else {
            element.classList.add('collapsed')
            element.classList.remove('expanded')
          }
        }
      }

      this.allCollapsed = !expand
    }
  },

  watch: {
    url (value) {
      if (value) {
        this.load()
      }
    },

    content (value) {
      if (value) {
        this.load()
      }
    }
  },

  async mounted () {
    await this.load()
  }
}
</script>

<template>
  <div ref="viewer" class="document-viewer" :class="{ ready: isReady }">
    <div class="text" v-if="type === DocumentType.Text">
      {{ text }}
    </div>
    <div class="html" v-html="text" v-if="type === DocumentType.HTML">
    </div>
    <div class="markdown" v-html="text" v-if="type === DocumentType.Markdown">
    </div>
    <div class="json" v-if="type === DocumentType.JSON" v-html="text">
    </div>
    <q-btn class="button-toggle-all primary" v-if="isCollapsible && allowCollapseAll"
      :label="allCollapsed ? 'Expand All' : 'Collapse All'"
      @click="toggleAllChapters(allCollapsed)">
    </q-btn>
    <div class="inner-content" ref="innerContent">
      <slot>
      </slot>
    </div>
  </div>
</template>

<style scoped lang="scss">
.document-viewer {
  width: 100%;
  height: 100%;
  flex: 1;
  flex-direction: column;
  overflow: auto;
  display: none;
  position: relative;

  .button-toggle-all {
    position: absolute;
    right: 4px;
    top: 4px;
    z-index: 1;
  }

  &.ready {
    display: flex;
  }

  .inner-content {
    display: none;
  }

  .markdown {
    padding-right: 150px;
    max-width: 1400px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .json {
    display: block;
    white-space: pre;
    unicode-bidi: embed;
    font-family: 'Courier New', Courier, monospace;
    font-size: 15px;
  }
}
</style>