import { state, getters } from './push-client.state'
import { mutations } from './push-client.mutations'
import { actions } from './push-client.actions'

export default {
  state,
  getters,
  mutations,
  actions
}
