<script>
import { safeParseInt, safeParseFloat } from '@stellacontrol/utilities'
import { CableLengthMode } from '@stellacontrol/planner'
import { PlanActions } from '../../../renderer/actions'
import LayerProperties from './layer-properties'

// Plan properties, shown when editing cross-section view.
// When editing floors, more extensive `background-layer-properties` component is used instead.
export default {
  mixins: [
    LayerProperties
  ],

  data () {
    return {
      // Selected files, array of `File` objects
      files: null,

      CableLengthMode
    }
  },

  computed: {
    // Currently selected floor
    floor () {
      return this.renderer.selectedFloor
    },

    // Cross-section properties
    crossSection () {
      return this.renderer.layout.crossSection
    },

    // Indicates whether cable lengths are visible at all
    cableLengthsVisible () {
      return this.layout.cableLengths !== CableLengthMode.Off
    }
  },

  methods: {
    safeParseInt,
    safeParseFloat,

    // Changes the floor dimensions
    async setFloorSize (size) {
      this.$emit('action', {
        action: PlanActions.SetFloorSize,
        floor: this.crossSection,
        size
      })
    },

    // Changes the margin around the floor
    async setFloorMargin (margin) {
      this.$emit('action', {
        action: PlanActions.SetFloorMargin,
        floor: this.crossSection,
        margin
      })
    },

    // Toggles cable lengths visibility
    toggleCableLengths (mode) {
      if (mode === false) {
        this.$emit('action', { action: PlanActions.ToggleCableLengths, mode: CableLengthMode.Off })
      } else {
        this.$emit('action', { action: PlanActions.ToggleCableLengths, mode: CableLengthMode.Actual })
      }
    },

    // Toggles equipment tag visibility
    toggleTags (isVisible) {
      this.$emit('action', { action: PlanActions.ToggleTags, isVisible })
    },
  }
}
</script>

<template>
  <main>
    <div class="column">
      <section>
        <div class="property row items-center q-mt-sm" v-if="isAdvancedMode">
          <label class="text-bold">
            Margins
          </label>
          <q-input type="number" :min="0" :max="1000" dense outlined square label="Left"
            debounce="500" :input-style="styles.number" :model-value="crossSection.margin.left"
            @update:model-value="value => setFloorMargin({ left: safeParseInt(value, 0) })">
          </q-input>
          <q-input type="number" :min="0" :max="1000" dense outlined square label="Right"
            class="q-ml-xs" debounce="500" :input-style="styles.number"
            :model-value="crossSection.margin.right"
            @update:model-value="value => setFloorMargin({ right: safeParseInt(value, 0) })">
          </q-input>
        </div>
        <div class="property row items-center q-mt-sm" v-if="isAdvancedMode">
          <label>
          </label>
          <q-input type="number" :min="0" :max="1000" dense outlined square label="Top"
            debounce="500" :input-style="styles.number" :model-value="crossSection.margin.top"
            @update:model-value="value => setFloorMargin({ top: safeParseInt(value, 0) })">
          </q-input>
          <q-input type="number" :min="0" :max="1000" dense outlined square label="Bottom"
            class="q-ml-xs" debounce="500" :input-style="styles.number"
            :model-value="crossSection.margin.bottom"
            @update:model-value="value => setFloorMargin({ bottom: safeParseInt(value, 0) })">
          </q-input>
        </div>
      </section>

      <section class="q-mt-md">
        <div class="text-bold q-mb-xs">
          Show
        </div>
        <div class="property row items-center">
          <label class="wider">
            <q-checkbox dense :model-value="layout.showTags" label="Annotations"
              @update:model-value="value => toggleTags(value)">
            </q-checkbox>
          </label>
        </div>
        <div class="property row items-center q-mt-sm">
          <label class="wider row items-center">
            <q-checkbox dense :model-value="cableLengthsVisible" label="Cable Lengths"
              @update:model-value="value => toggleCableLengths(value)">
            </q-checkbox>
          </label>
        </div>
      </section>
    </div>
  </main>
</template>

<style lang="scss" scoped>
main {
  padding: 10px 10px 20px 10px;

  .property>label:not(.q-field) {
    min-width: 93px;
    margin-right: 8px;

    &.wider {
      min-width: 125px;
    }
  }

  .button {
    height: 40px;

    &.selected {
      color: white;
      background-color: #3949ab;
    }
  }
}
</style>
