<script>
import { mapGetters } from 'vuex'
import { sortItems } from '@stellacontrol/utilities'
import { Secure } from '@stellacontrol/security-ui'
import { AlertStatisticsPart } from '@stellacontrol/model'
import DetailsMixin from './details-mixin'

export default {
  mixins: [
    Secure,
    DetailsMixin
  ],

  data () {
    return {
      bundles: []
    }
  },

  computed: {
    ...mapGetters([
      'toLocalDateTime'
    ]),

    sent () {
      return this.part === AlertStatisticsPart.SentNotifications
    },

    failed () {
      return this.part === AlertStatisticsPart.FailedNotifications
    },
  },

  watch: {
    items () {
      this.populate()
    }
  },

  methods: {
    populate () {
      const { items, sent } = this
      const bundles = {}

      for (const item of items) {
        const { bundleId: id } = item
        const bundle = bundles[id] || {
          id,
          notifications: [],
          isExpanded: false,
          count: 0,
          sentTo: null,
          time: null
        }
        bundle.notifications.push(item)
        bundles[id] = bundle
      }

      for (const bundle of Object.values(bundles)) {
        const first = bundle.notifications[0]
        bundle.notifications = sortItems(bundle.notifications, sent ? 'sent' : 'failed', true)
        bundle.count = bundle.notifications.length
        bundle.time = sent ? first.sent : first.failed
        bundle.sentTo = first.sentTo
      }

      this.bundles = Object.values(bundles)
    }
  },

  created () {
    this.populate()
  }
}
</script>

<template>
  <div class="scroller">
    <div class="header">
      <div class="date">
        Sent
      </div>
      <div class="serial">
        Alerts
      </div>
      <div class="details">
        Details
      </div>
    </div>

    <div v-for="bundle in bundles" :key="bundle.id" class="bundle"
      :class="{ expanded: bundle.isExpanded }">
      <div class="title" @click="bundle.isExpanded = !bundle.isExpanded"
        :class="{ expanded: bundle.isExpanded }">
        <div class="date">
          {{ datetime(toLocalDateTime(bundle.time)) }}
        </div>
        <div class="serial">
          {{ capitalize(count(bundle.count, 'alert')) }}
        </div>
        <div class="details">
          {{ bundle.sentTo }}
        </div>
        <sc-tooltip v-if="!bundle.isExpanded">
          Click to show detailed list of alerts
        </sc-tooltip>
      </div>

      <div class="items" v-if="bundle.isExpanded">
        <div v-for="item in bundle.notifications" :key="item.id" class="item">
          <div class="date">
          </div>
          <div class="serial">
            <router-link class="item-link"
              :to="{ name: 'device-dashboard', params: { serialNumber: item.serialNumber } }">
              {{ item.serialNumber }}
              <sc-tooltip :text="`Go to ${item.serialNumber} dashboard`"></sc-tooltip>
            </router-link>
          </div>
          <div class="details">
            {{ item.details }}
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<style scoped lang="scss">
.scroller {
  box-shadow: none;
  max-height: 90vh;

  .header,
  .title,
  .item {
    display: flex;
    flex-direction: row;

    div {
      border-top: solid #0000001f 1px;
      padding: 7px 16px 7px 16px;
      font-size: 13px;

      &.date {
        flex-basis: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &.serial {
        flex-basis: 160px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &.details {
        flex: 1;
        white-space: normal;
      }
    }
  }

  .header {
    div {
      font-weight: bold;
    }
  }

  .title {
    div {
      cursor: pointer;
    }

    &.expanded {
      div {
        border-top: solid grey 1px;
      }
    }
  }

  .bundle {
    &.expanded {
      border-bottom: solid grey 1px;
    }
  }
}
</style>
