import factorySettings from './factory-settings.json'

/**
 * Returns factory settings for the specified parameters of a device
 * @param device Device
 * @param parameters Parameters whose factory settings are to be fetched
 */
export function getFactorySettings (device, parameters) {
  if (!device) throw new Error('Device is required')

  // Determine default settings applicable
  // to the specified device
  const settings = factorySettings.default

  // Return a requested subset of factory settings
  if (parameters) {
    return parameters.reduce((all, key) => ({
      ...all,
      [key]: settings[key]
    }), {})
  } else {
    return settings
  }
}
