<script>
import { mapActions } from 'vuex'
import { DialogMixin } from '@stellacontrol/client-utilities'
import { bandsToIdentifiers, identifiersToBands, getBandMegaParameters, StandardDeviceBands, getDeviceFamily, DeviceFamily } from '@stellacontrol/model'

const dialog = 'band-selector'

export default {
  mixins: [
    DialogMixin
  ],

  data () {
    return {
      dialog,
      // Devices for which bands are to be selected
      devices: [],
      // Initially selected bands
      selectedIdentifiers: [],
      // Color representing a selected band
      selectedBandColor: 'green-9',
      // Dialog title
      title: 'Select bands',
      // OK button label
      okLabel: 'OK',
      // Cancel button label
      cancelLabel: 'Cancel'
    }
  },

  computed: {
    // Device family, from first of the specified devices
    deviceFamily () {
      if (this.devices) {
        const device = this.devices[0]
        return device ? getDeviceFamily(device) : DeviceFamily.EU
      }
    },

    // Available bands, deducted from the devices passed to the dialog.
    // If no devices passed, all bands will be presented.
    bands () {
      const device = (this.devices || [])[0]
      if (device) {
        return device.bands
      } else {
        return StandardDeviceBands.SixBands
      }
    },

    // Identifiers of the available bands
    identifiers () {
      return bandsToIdentifiers(this.bands)
    },

    // Band details
    details () {
      const { identifiers, deviceFamily } = this
      return identifiers.map(identifier => {
        const { label } = getBandMegaParameters(identifier, deviceFamily)
        return { identifier, label }
      })
    },

    // Checks whether the specified band is selected
    isBandSelected () {
      return identifier => this.selectedIdentifiers.includes(identifier)
    },

    // Returns true if some bands have been selected
    someBandsSelected () {
      return this.selectedIdentifiers.length > 0
    },

    // Returns true if all bands have been selected
    allBandsSelected () {
      return this.selectedIdentifiers.length === this.bands.length
    }
  },

  methods: {
    ...mapActions([
      'dialogOk',
      'dialogCancel'
    ]),

    // Toggles a band
    toggleBand (identifier) {
      const index = this.selectedIdentifiers.indexOf(identifier)
      if (index === -1) {
        this.selectedIdentifiers.push(identifier)
      } else {
        this.selectedIdentifiers.splice(index, 1)
      }
    },

    // Toggles all bands
    toggleAllBands () {
      if (this.allBandsSelected) {
        this.selectedIdentifiers = []
      } else {
        this.selectedIdentifiers = bandsToIdentifiers(this.bands)
      }
    },

    // Called when dialog is shown
    dialogShown () {
      // Pass on initial data
      this.devices = this.data.devices
      this.selectedIdentifiers = bandsToIdentifiers(this.data.selectedIdentifiers)
      this.selectedBandColor = this.data.selectedBandColor || this.selectedBandColor
      this.title = this.data.title || this.title
      this.okLabel = this.data.okLabel || this.okLabel
      this.cancelLabel = this.data.cancelLabel || this.cancelLabel
    },

    // Validates and OKs the dialog
    async ok () {
      const data = {
        identifiers: this.selectedIdentifiers,
        bands: identifiersToBands(this.selectedIdentifiers)
      }
      this.dialogOk({ dialog, data })
    },

    // Cancels the dialog
    cancel () {
      this.dialogCancel({ dialog })
    }
  }
}
</script>

<template>
  <sc-dialog :dialog="dialog" persistent @dialogShown="dialogShown()">
    <q-form ref="form">
      <q-banner class="bg-indigo-6">
        <span class="text-white title">
          {{ title }}
        </span>
      </q-banner>

      <div class="q-pa-xl">
        <div>
          <table>
            <tbody>
              <tr>
                <td class="q-pr-sm" v-for="band of details" :key="band.identifier">
                  <q-btn unelevated class="band-button" :label="band.identifier" :ripple="false"
                    :outline="!isBandSelected(band.identifier)"
                    :color="isBandSelected(band.identifier) ? selectedBandColor : 'grey-7'"
                    @click="toggleBand(band.identifier)" />
                </td>
                <td>
                  <q-btn flat class="button-select-all" color="indigo-6"
                    :label="allBandsSelected ? 'Deselect All' : 'Select All'"
                    @click="toggleAllBands()" />
                </td>
              </tr>
              <tr>
                <td class="band-label q-pr-sm q-pt-sm" v-for="band of details"
                  :key="band.identifier">
                  {{ band.label }}
                </td>
                <td>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="row q-mt-xl">
          <q-space></q-space>
          <q-btn :label="cancelLabel" unelevated class="q-mr-md" @click="cancel()" />
          <q-btn :label="okLabel" unelevated class="primary" :disabled="!someBandsSelected"
            @click="ok()" />
        </div>
      </div>

    </q-form>
  </sc-dialog>
</template>

<style scoped lang="scss">
.title {
  font-size: 18px;
}

.button-select-all {
  min-width: 140px;
}

.band-button {
  min-width: 70px;
  width: 70px;
}

.band-label {
  font-size: 10px;
  text-align: center;
}
</style>