<script>
import { mapGetters } from 'vuex'
import { Secure } from '@stellacontrol/security-ui'
import DetailsMixin from './details-mixin'

export default {
  mixins: [
    Secure,
    DetailsMixin
  ],

  computed: {
    ...mapGetters([
      'toLocalDateTime'
    ])
  }
}
</script>

<template>
  <q-virtual-scroll type="table" class="scroller" :virtual-scroll-item-size="30" :items="items">
    <template v-slot:before>
      <thead>
        <tr class="header">
          <th>
            Created
          </th>
          <th>
            Recipients
          </th>
          <th>
            Device
          </th>
          <th>
            Details
          </th>
        </tr>
      </thead>
    </template>

    <template v-slot="{ item, index }">
      <tr :key="index" class="item">
        <td class="date">
          {{ datetime(toLocalDateTime(item.created)) }}
        </td>
        <td class="recipients">
          <span>
            {{ item.sentTo }}
            <sc-tooltip :text="item.sentTo">
            </sc-tooltip>
          </span>
        </td>
        <td class="serial">
          <router-link class="item-link"
            :to="{ name: 'device-dashboard', params: { serialNumber: item.serialNumber } }">
            {{ item.serialNumber }}
            <sc-tooltip :text="`Go to ${item.serialNumber} dashboard`"></sc-tooltip>
          </router-link>
        </td>
        <td class="details">
          {{ item.details }}
        </td>
      </tr>
    </template>

  </q-virtual-scroll>
</template>

<style scoped lang="scss">
.scroller {
  box-shadow: none;
  max-height: 90vh;

  table {
    border-collapse: collapse;
  }

  .header {
    height: auto;

    th {
      text-align: left;
    }
  }

  .item {
    td {
      height: auto;
      vertical-align: top;
    }

    .date {
      width: 140px;
    }

    .serial {
      width: 140px;
    }

    .recipients {
      width: 200px;
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
    }

    .details {
      white-space: normal;
    }
  }
}
</style>
