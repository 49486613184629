/**
 * Clipboard wrapper
 */
export class ClipboardService {
  /**
   * Copies the specified text to clipboard
   * @param text Text to copy
   * @returns Promise resolved when text has been successfully placed in the clipboard
   */
  async write (text) {
    if (text != null) {
      const { copyToClipboard } = Quasar
      await copyToClipboard(text)
    }
  }
}
