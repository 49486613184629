import { Guardian } from '@stellacontrol/security'
import { getUTCDateTime, getLocalDateTime, getLocalTime, Timezones } from '@stellacontrol/utilities'

export function createState () {
  return {
    /**
     * Current user name
     */
    userName: null,
    /**
     * Current user session
     */
    session: null,
    /**
     * Permission guardian initialized with the logged in user
     */
    guardian: null,
    /**
     * Permission guardian initialized with the logged in organization
     */
    organizationGuardian: null,
    /**
     * Dictionary of guardians, for frequent use
     */
    guardians: {},
    /**
     * Login error
     */
    loginError: null,
    /**
     * Indicates an ongoing login progress
     */
    isLoggingIn: false,
    /**
     * Indicates an ongoing logout progress
     */
    isLoggingOut: false,
    /**
     * Indicates that user has logged out
     */
    isLoggedOut: false
  }
}

export const state = createState()


export const getters = {
  // Shorthand access to state values
  loginError: state =>
    state.loginError,

  isLoggingIn: state =>
    state.isLoggingIn,

  isLoggingOut: state =>
    state.isLoggingOut,

  userName: state =>
    state.userName,

  session: state =>
    state.session,

  sessionToken: state =>
    state.session ? state.session.token : undefined,

  masterUser: state =>
    state.session ? state.session.masterUser : undefined,

  masterOrganization: state =>
    state.session ? state.session.masterOrganization : undefined,

  guardian: state =>
    state.guardian,

  organizationGuardian: state =>
    state.organizationGuardian,

  currentOrganizationGuardian: state =>
    state.organizationGuardian,

  guardianOf: (state, getters) =>
    principal => state.guardians[principal.id] ||
      new Guardian({
        features: getters.features,
        principal,
        pricelist: getters.pricelist,
        environment: getters.environment
      }),

  currentUser: state =>
    state.session ? state.session.user : undefined,

  currentUserId: (_, getters) =>
    (getters.currentUser || {}).id,

  currentOrganization: state =>
    state.session ? state.session.organization : undefined,

  currentOrganizationId: (_, getters) =>
    (getters.currentOrganization || {}).id,

  currentOrganizationProfile: state =>
    state.session ? state.session.organizationProfile : undefined,

  preferences: (state, getters) => {
    const preferences = (getters.currentUser || {}).preferences || {}
    return {
      // Email preferences
      email: {
        // Receive emails with technical updates
        receiveTechnicalNews: !preferences['privacy-email-technical-opt-out'],
        // Receive emails with announcements about new products and services
        receiveCommercialNews: !preferences['privacy-email-commercial-opt-out']
      },

      ...preferences
    }
  },

  // Structured user preferences
  userPreferences: (state, getters) => {
    const preferences = getters.preferences
    return {
      // UI preferences
      ui: {
        // Application start page
        startPage: preferences['ui-start-page']
      }
    }
  },

  // Structured organization preferences
  organizationPreferences: (state, getters) => {
    const preferences = (getters.currentOrganization || {}).preferences || {}
    return {
      // UI preferences
      ui: {
        // Application start page
        startPage: preferences['ui-start-page']
      },
      // Premium services preferences
      premiumServices: {
        // Activate premium services on devices sold to end customers.
        // Changed from preference to always true, 2022/11/17
        activateSoldDevices: true
      }
    }
  },

  // Structured global preferences
  globalPreferences: state =>
    state.session ? state.session.preferences : {},

  // List of terms and conditions documents applicable to current organization
  termsAndConditions: (state, getters) =>
    ((getters.configuration || {}).security || {}).termsAndConditions,

  // Returns true if approval of terms and conditions is required
  // and there are terms-and-conditions documents applicable to current organization
  termsAndConditionsApply: (state, getters) => {
    const { currentOrganizationProfile, termsAndConditions, isLoggedIn, loggedInAsMaster } = getters
    return isLoggedIn &&
      !loggedInAsMaster &&
      currentOrganizationProfile &&
      currentOrganizationProfile.termsAndConditionsId &&
      termsAndConditions &&
      termsAndConditions.requireApproval &&
      termsAndConditions.items.some(t => t.id === currentOrganizationProfile.termsAndConditionsId)
  },

  // Returns terms and conditions applicable to the current organization
  myTermsAndConditions: (state, getters) => {
    const { termsAndConditions, currentOrganizationProfile } = getters
    return termsAndConditions.items.find(t => t.id === currentOrganizationProfile.termsAndConditionsId) ||
      termsAndConditions.items.find(t => t.isDefault)
  },

  // Checks whether user has approved terms and conditions, if it is required.
  // Used to prevent any application actions until user does approve.
  termsAndConditionsAccepted: (state, getters) => {
    const { myTermsAndConditions, termsAndConditionsApply, preferences, isLoggedIn } = getters
    let accepted = true
    if (isLoggedIn && termsAndConditionsApply) {
      const key = `terms-and-conditions-approved-${myTermsAndConditions.id}`
      accepted = preferences[key] === true
    }
    return accepted
  },

  /**
   * Indicates that user is currently logged in
   */
  isLoggedIn: state =>
    state.session != null && !state.session.hasExpired,

  /**
   * Indicates that currently logged in user
   * has logged in as administrator from parent organization
   */
  loggedInAsMaster: state =>
    state.session != null && !state.session.hasExpired && Boolean(state.session.masterOrganization) && Boolean(state.session.masterUser),

  /**
   * Indicates that user has logged out
   */
  isLoggedOut: state =>
    state.isLoggedOut,

  /**
   * Checks whether current organization is a super-organization
   */
  isSuperOrganization: (state, getters) =>
    getters?.currentOrganization?.isSuperOrganization,

  /**
   * Checks whether current user is a super-administrator
   */
  isSuperAdministrator: (state, getters) =>
    getters?.currentUser?.isSuperAdministrator,

  /**
   * Checks whether user can use the specified feature
   */
  canUse: state =>
    featureName =>
      state.guardian && state.guardian.canUse(featureName),

  /**
   * Checks whether user can use all the specified features
   */
  canUseAll: state =>
    features =>
      state.guardian && state.guardian.canUseAll(features),

  /**
   * Checks whether user can use any of the specified features
   */
  canUseAny: state =>
    features =>
      state.guardian && state.guardian.canUseAny(features),

  /**
   * Checks whether user cannot use the specified feature
   */
  cannotUse: state =>
    featureName =>
      !state.guardian || !state.guardian.canUse(featureName),

  /**
   * Checks whether user cannot use some or all the specified features
   */
  cannotUseAll: state =>
    features =>
      !(state.guardian && state.guardian.canUseAll(features)),

  /**
   * Checks whether user cannot use any of the specified features
   */
  cannotUseAny: state =>
    features =>
      !(state.guardian && state.guardian.canUseAny(features)),

  /**
   * Returns context of a granted permission
   * @param name Feature name
   */
  getContext: state =>
    featureName =>
      state.guardian ? state.guardian.getContext(featureName) : undefined,

  /**
   * Alert types permitted for the current organization
   */
  permittedAlertTypes: (state, getters) => {
    const { guardian } = getters
    return guardian
      ? guardian.permittedAlertTypes
      : []
  },

  /**
   * Checks whether the specified alert type is permitted for the principal
   * @param {AlertType} alertType Alert type to check
   * @returns {Boolean}
   */
  isAlertPermitted: (state, getters) =>
    alertType => getters.permittedAlertTypes.includes(alertType),

  // Returns true if alert monitoring is enabled for the current organization
  hasAnyAlertsPermitted: (state, getters) => {
    const { guardian } = getters
    return guardian
      ? guardian.hasAnyAlertsPermitted
      : []
  },

  // Returns details of timezone of the current organization
  currentTimezone: (state) => {
    const { organization: { timezone, countryCode } = {} } = state.session || {}
    const timezoneDetails = (timezone && countryCode)
      ? Timezones.find(t => t.value === timezone || (t.utc || []).includes(timezone))
      : null
    return timezoneDetails
      ? { ...timezoneDetails, country: countryCode }
      : null
  },

  // Converts the specified local datetime to current organization's local datetime
  toLocalDateTime: (state) =>
    datetime => {
      const { organization: { timezone, countryCode } = {} } = state.session || {}
      if (timezone && countryCode) {
        // Convert time to UTC
        const utcDateTime = getUTCDateTime(datetime)
        // Convert from UTC to local date/time of the viewer
        return getLocalDateTime(countryCode, timezone, utcDateTime)
      } else {
        return datetime
      }
    },

  // Converts the specified UTC datetime to current organization's local datetime
  utcToLocalDateTime: (state) =>
    utcDateTime => {
      const { organization: { timezone, countryCode } = {} } = state.session || {}
      if (timezone && countryCode) {
        // Convert from UTC to local date/time of the viewer
        return getLocalDateTime(countryCode, timezone, utcDateTime)
      } else {
        return utcDateTime
      }
    },

  // Converts the specified UTC time to current organization's local time
  getLocalTime: (state) =>
    time => {
      const { organization: { timezone, countryCode } = {} } = state.session || {}
      return (timezone && countryCode)
        ? getLocalTime(countryCode, timezone, time)
        : time
    }
}
