/**
 * A service for enforcing password policies
 */
export class PasswordPolicy {
  constructor (data = {}) {
    Object.assign(this, data)
  }

  /**
   * Minimal password length
   */
  length
  /**
   * Minimal amount of digits in the password
   */

  digits
  /**
   * Minimal amount of capital letters in the password
   */
  capitals

  /**
   * Minimal amount of special characters in the password
   */
  specials

  /**
   * Allowed special characters
   */
  allowedSpecials

  /**
   * Maximal amount of repeated characters
   */
  maxRepeats

  /**
   * Maximal length of sequential characters
   */
  maxSequence

  /**
   * Returns a list of all performed checks
   */
  get checks () {
    const { length, digits, capitals, specials, maxRepeats, maxSequence } = this

    const errors = [
      (maxRepeats > 0 || maxSequence > 0) ? PasswordPolicyCheckError.PasswordTooSimple : undefined,
      length > 0 ? PasswordPolicyCheckError.PasswordTooShort : undefined,
      digits ? PasswordPolicyCheckError.NotEnoughDigits : undefined,
      capitals ? PasswordPolicyCheckError.NotEnoughCapitals : undefined,
      specials ? PasswordPolicyCheckError.NotEnoughSpecials : undefined
    ].filter(e => e)

    return errors.map(error => ({
      error,
      description: this.getResultDescription(error)
    }))
  }

  /**
   * Checks the password against the policy.
   * @returns Results of the check - an entity with the following properties:
   *
   *    ok: true | false
   *    errors: [ { error, description } ]
   *
   */
  check (password) {
    const result = { ok: false, errors: [] }
    password = password ? password.toString().trim() : ''

    const verify = (predicate, error) => {
      if (predicate(password)) {
        result.errors.push({ error })
      }
    }

    verify(password => this.passwordNotSpecified(password), PasswordPolicyCheckError.PasswordNotSpecified)
    verify(password => this.passwordTooSimple(password), PasswordPolicyCheckError.PasswordTooSimple)
    verify(password => this.passwordTooShort(password), PasswordPolicyCheckError.PasswordTooShort)
    verify(password => this.notEnoughDigits(password), PasswordPolicyCheckError.NotEnoughDigits)
    verify(password => this.notEnoughCapitals(password), PasswordPolicyCheckError.NotEnoughCapitals)
    verify(password => this.notEnoughSpecials(password), PasswordPolicyCheckError.NotEnoughSpecials)

    result.ok = result.errors.length === 0
    for (const item of result.errors) {
      item.description = this.getResultDescription(item.error)
    }

    return result
  }

  /**
   * Checks if password is not specified
   * @param password Password to check
   * @returns True if password does not meet the criteria
   */
  passwordNotSpecified (password) {
    password = password ? password.toString().trim() : ''
    return !password
  }

  /**
   * Checks if password is too simple
   * @param password Password to check
   * @returns True if password does not meet the criteria
   */
  passwordTooSimple (password) {
    password = password ? password.toString().trim() : ''
    const { maxRepeats, maxSequence } = this

    if (password) {
      const distinctCharacters = new Set(Array.from(password))

      // Check for repeating characters
      if (maxRepeats > 0) {
        for (const ch of distinctCharacters) {
          const repeated = ch.repeat(maxRepeats + 1)
          if (password.includes(repeated)) {
            return true
          }
        }
      }

      // Check for sequences
      if (maxSequence > 0) {
        for (let i = 0; i < password.length - 1; i++) {
          const ch = password[i]

          // Check forward sequences: 123, abc
          let sequence = ch
          for (let j = i + 1; i < password.length; j++) {
            const nextCh = String.fromCharCode(ch.charCodeAt(0) + (j - i))
            sequence = sequence + nextCh
            if (password[j] !== nextCh) {
              break
            } else if ((j - i) >= maxSequence) {
              return true
            }
          }

          // Check reverse sequences: 321, cba
          sequence = ch
          for (let j = i + 1; i < password.length; j++) {
            const prevCh = String.fromCharCode(ch.charCodeAt(0) - (j - i))
            sequence = sequence + prevCh
            if (password[j] !== prevCh) {
              break
            } else if ((j - i) >= maxSequence) {
              return true
            }
          }
        }
      }
    }

    return false
  }

  /**
   * Checks if password is too short
   * @param password Password to check
   * @returns True if password does not meet the criteria
   */
  passwordTooShort (password) {
    password = password ? password.toString().trim() : ''
    const { length } = this
    if (password && length > 0) {
      return password.toString().trim().length < length
    }
  }

  /**
   * Checks if password has the required amount of digits
   * @param password Password to check
   * @returns True if password does not meet the criteria
   */
  notEnoughDigits (password) {
    password = password ? password.toString().trim() : ''
    const { digits } = this
    if (password && digits > 0) {
      return Array
        .from(password)
        .filter(ch => '0123456789'.includes(ch))
        .length < digits
    }
  }

  /**
   * Checks if password has the required amount of capital letters
   * @param password Password to check
   * @returns True if password does not meet the criteria
   */
  notEnoughCapitals (password) {
    password = password ? password.toString().trim() : ''
    const { capitals } = this
    if (password && capitals > 0) {
      return Array
        .from(password)
        .filter(ch => {
          const lowerCh = ch.toLowerCase()
          const upperCh = ch.toUpperCase()
          return (ch === upperCh && upperCh !== lowerCh)
        })
        .length < capitals
    }
  }

  /**
   * Checks if password has the required amount of special characters
   * @param password Password to check
   * @returns True if password does not meet the criteria
   */
  notEnoughSpecials (password) {
    password = password ? password.toString().trim() : ''
    const { specials, allowedSpecials } = this
    if (password && specials > 0 && allowedSpecials && allowedSpecials.length > 0) {
      return Array
        .from(password)
        .filter(ch => allowedSpecials.includes(ch))
        .length < specials
    }
  }

  /**
   * Returns a human-friendly descriptions of results of checking the password policy
   */
  getResultDescription (result) {
    if (result) {
      switch (result) {
        case PasswordPolicyCheckError.PasswordNotSpecified:
          return 'The password must not be empty'
        case PasswordPolicyCheckError.PasswordTooSimple:
          return 'Repeated characters or sequences are not allowed'
        case PasswordPolicyCheckError.PasswordTooShort:
          return `At least ${this.length} characters are required`
        case PasswordPolicyCheckError.NotEnoughDigits:
          return `At least ${this.digits} digit${this.digits === 1 ? ' is required' : 's are required'}`
        case PasswordPolicyCheckError.NotEnoughCapitals:
          return `At least ${this.capitals} uppercase character${this.capitals === 1 ? ' is required' : 's are required'}`
        case PasswordPolicyCheckError.NotEnoughSpecials:
          return `At least ${this.specials} special character${this.specials === 1 ? ' is required' : 's are required'}: ${this.allowedSpecials}`
        default:
          return result.toString()
      }
    }
  }
}

/**
 * Error results of checking the password policy
 */
export const PasswordPolicyCheckError = {
  PasswordNotSpecified: 1,
  PasswordTooSimple: 2,
  PasswordTooShort: 3,
  NotEnoughDigits: 4,
  NotEnoughCapitals: 5,
  NotEnoughSpecials: 6
}
