<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { isToday } from 'date-fns'
import { getUTCDateTime, formatTime, formatDateTime, capitalize } from '@stellacontrol/utilities'
import { ListAction } from '@stellacontrol/client-utilities'
import { UploadStatus, UploadStatusDescription, UploadStatusIcon, UploadStatusColor } from '@stellacontrol/model'
import { Secure } from '@stellacontrol/security-ui'
import { jobRequiresUserRequest, getJobSchedule } from '@stellacontrol/device-transmission'

export default {
  mixins: [
    Secure
  ],

  data () {
    return {
      UploadStatus,
      UploadStatusDescription,
      UploadStatusIcon,
      UploadStatusColor,
      gotJobDetails: false,
      // Initial pagination
      pagination: {
        page: 0,
        rowsPerPage: 50
      }
    }
  },

  computed: {
    ...mapState({
      // Jobs initiated by the currently logged in organization
      jobs: state => state.deviceTransmission.jobs,
      // List columns
      jobColumns: state => state.deviceTransmission.jobColumns
    }),

    ...mapGetters([
      'organizations',
      'utcToLocalDateTime'
    ]),

    // Returns true if current user is allowed to manage firmware repository
    canManageFirmware () {
      return this.canUse('can-manage-firmware')
    },

    // Returns true if there any upload jobs
    hasJobs () {
      return (this.jobs || []).length > 0
    },

    // Returns user-friendly description of the payload
    getPayloadDescription () {
      return job => {
        if (job.payload) {
          if (job.isFirmwareUpload) {
            return `Update to v.${job.payload.versionString}`
          }
          if (job.isBootLogoUpload) {
            return job.payload.name
          }
        }
        return '-'
      }
    },

    // List actions
    actions () {
      return [
        ListAction.create({
          name: 'retry',
          label: 'Upload again',
          icon: 'next_plan',
          color: 'green-8',
          isVisible: job => !job.isNew
        }),
        {
          ...ListAction.Delete,
          label: 'Delete upload'
        }
      ]
    }
  },

  methods: {
    ...mapActions([
      'removeUploadJob',
      'retryUploadJob',
      'getUploadJob'
    ]),

    isToday,
    formatTime,
    formatDateTime,
    getUTCDateTime,
    jobRequiresUserRequest,
    getJobSchedule,

    // Determine column visibility
    isColumnVisible (column) {
      const adminColumns = [
        'fileName'
      ]
      if (adminColumns.includes(column.name)) {
        return this.canManageFirmware
      }
      return true
    },

    // Returns textual description of job upload step
    getStepStatus (job, step) {
      const lines = [
        capitalize(UploadStatusDescription[step.status]),
        step.failed && step.progress != null ? ` at ${step.progress}%.` : '.',
        step.failed && (job.whenConnected || job.onUserRequest) ? ' The user has to initiate it again.' : '',
        step.failed && !(job.whenConnected || job.onUserRequest) ? ' It will resume when device comes back online.' : ''
      ].join('')
      return lines
    },

    // Executes action on a specified job
    executeAction (job, action) {
      switch (action.name) {
        case 'delete':
          return this.removeUploadJob({ job, confirm: true })

        case 'retry':
          return this.retryUploadJob({ job, reset: true, confirm: true })

        default:
          throw new Error(`Unhandled action ${action.name}`)
      }
    },

    // Refreshes details of the specified job,
    // for displaying the popup
    async fetchJobDetails ({ id }) {
      this.gotJobDetails = false
      await this.getUploadJob({ id })
      this.gotJobDetails = true
    }
  }
}
</script>

<template>
  <sc-list class="jobs" v-if="hasJobs" name="upload-jobs" :columns="jobColumns" :items="jobs"
    row-key="id" :actions="actions" :isColumnVisible="isColumnVisible"
    :initial-pagination="pagination" @action="executeAction">

    <template v-slot:body-cell-status="props">
      <q-td class="status" :props="props" style="width: 32px; max-width: 32px;">
        <q-icon size="sm" :name="UploadStatusIcon[props.row.status]"
          :color="UploadStatusColor[props.row.status]">
          <sc-tooltip>
            Click to see the job details ...
          </sc-tooltip>
          <q-popup-proxy @before-show="fetchJobDetails(props.row)">
            <div class="job-status-popup" v-if="gotJobDetails">
              <!-- Steps of a job in progress -->
              <div class="steps" v-if="props.row.hasSteps">
                <div class="step" v-for="step in props.row.steps" :key="step.id"
                  :class="{ failed: step.failed, completed: step.status === UploadStatus.Completed }">
                  <div class="icon q-mr-md">
                    <q-icon :name="UploadStatusIcon[step.status]"
                      :color="step.failed ? UploadStatusColor[UploadStatus.Failed] : UploadStatusColor[UploadStatus.New]"
                      size="sm">
                    </q-icon>
                  </div>
                  <div class="text">
                    <i>
                      {{ isToday(step.time) ? formatTime(step.time) : formatDateTime(step.time) }}
                    </i>
                    <span class="status">
                      {{ getStepStatus(props.row, step) }}
                    </span>
                    <span class="details q-mt-sm"
                      v-if="step.details && (!step.failed || isSuperAdministrator)">
                      {{ step.details }}
                    </span>
                  </div>
                </div>
              </div>
              <!-- Status of a job which hasn't been started yet -->
              <div v-else>
                <div class="steps">
                  <div class="step">
                    <div class="icon q-mr-md">
                      <q-icon :name="UploadStatusIcon[props.row.status]"
                        :color="UploadStatusColor[props.row.status]" size="sm">
                      </q-icon>
                    </div>
                    <div class="text">
                      <i>
                        {{ formatDateTime(props.row.updatedAt) }}
                      </i>
                      <span>
                        {{ capitalize(UploadStatusDescription[props.row.status]) }}
                      </span>
                      <span v-if="props.row.isNew || props.row.isRetry">
                        Will start {{ getJobSchedule(props.row) }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </q-popup-proxy>
        </q-icon>
      </q-td>
    </template>


    <template v-slot:body-cell-description="props">
      <q-td :props="props">
        <div class="description">
          {{ getPayloadDescription(props.row) }}
          <sc-tooltip :text="getPayloadDescription(props.row)" />
        </div>
      </q-td>
    </template>

    <template v-slot:body-cell-currentVersion="props">
      <q-td :props="props">
        {{ props.row.device.getFirmwareVersion() }}
      </q-td>
    </template>

    <template v-slot:body-cell-progress="props">
      <q-td class="progress" :props="props">
        <div v-if="props.row.inProgress && props.row.progress > 0" class="text-orange-9">
          <div class="bar">
            <div class="inner" :style="{ width: `${props.row.progress}%` }">
            </div>
          </div>
          <div class="value">
            {{ props.row.progress }} %
          </div>
        </div>
        <div v-else class="value">
          {{ props.row.progress }} %
        </div>
      </q-td>
    </template>

    <template v-slot:body-cell-updatedAt="props">
      <q-td>
        {{ datetime(utcToLocalDateTime(props.row.updatedAt)) }}
      </q-td>
    </template>

  </sc-list>

  <div v-else class="q-pa-md">
    There are no upload jobs yet.
  </div>
</template>

<style scoped lang="scss">
.jobs {
  .description {
    max-width: 200px;
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .progress {
    position: relative;
    height: 100%;

    .bar {
      position: absolute;
      left: 10px;
      right: 10px;
      height: 20px;
      border: solid silver 1px;

      .inner {
        height: 100%;
        opacity: 0.3;
        background-color: green;
        transition: 0.3s all ease-in-out;
      }
    }

    .value {
      padding-left: 4px;
    }
  }
}

.job-status-popup {
  display: flex;
  flex-direction: column;
  max-height: 500px;
  max-width: 800px;
  background-color: #fafafa;
  color: #040404;
  box-shadow: 2px 2px 4px #a0a0a0;
  padding: 16px;

  .title {
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 8px;
    flex: 0;
  }

  .steps {
    width: 100%;
    flex: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;

    .step {
      display: flex;
      flex-direction: row;
      border-bottom: dotted #c0c0c0 1px;
      padding: 6px;

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        background-color: #f0f0f0;
      }

      &.failed {
        color: orangered;
      }

      &.completed {
        color: green;
      }

      .icon {
        flex: 0;
      }

      .text {
        flex: 1;
        display: flex;
        flex-direction: column;
      }
    }
  }
}
</style>