<script>
import { getStore } from '@stellacontrol/client-utilities'
import { Secure } from '@stellacontrol/security-ui'
import { computed } from 'vue'

export default {
  mixins: [
    Secure
  ],

  props: {
    // Customized style for the menu button
    buttonStyle: {
      type: Object
    }
  },

  setup () {
    const { dispatch, state } = getStore()

    // Get grid options
    const options = computed(() =>
      state.inventoryView.gridOptions)
    // Determine columns which can be hidden by the user
    const switchableColumns = computed(() =>
      state.inventoryView.gridColumnsList.filter(c => !c.cannotBeHidden && c.isAllowed && !c.isDisabled))

    // Toggles the specified column
    async function toggleColumn ({ name }) {
      await dispatch('toggleInventoryColumn', { name })
    }

    // Toggles visibility of decommissioned devices
    async function toggleDecommisionedDevices () {
      await dispatch('toggleDecommisionedDevices')
    }

    // Toggles visibility of decommissioned devices
    async function toggleNonConnectedDevices () {
      await dispatch('toggleNonConnectedDevices')
    }

    // Toggles visibility of simulated devices
    async function toggleSimulatedDevices () {
      await dispatch('toggleSimulatedDevices')
    }

    // Toggles visibility of multi-device parts
    async function toggleDeviceParts () {
      await dispatch('toggleDeviceParts')
    }

    // Clears all the filters
    async function clearFilters () {
      await dispatch('clearInventoryFilters')
    }

    return {
      options,
      switchableColumns,
      toggleColumn,
      toggleDecommisionedDevices,
      toggleNonConnectedDevices,
      toggleSimulatedDevices,
      toggleDeviceParts,
      clearFilters
    }
  }
}
</script>

<template>
  <q-btn-dropdown class="columns-menu-dropdown" dropdown-icon="more_vert"
    flat dense unelevated rounded round stack size="md" padding="2px" :ripple="false" menu-self="top left" :style="buttonStyle">

    <div class="row justify-center q-ma-sm">
      <q-btn label="Clear Filters" unelevated dense @click="clearFilters()"></q-btn>
    </div>

    <q-list dense class="columns-menu">
      <q-item v-if="canUse('decommission-devices')">
        <q-item-section side>
          <q-checkbox :model-value="options.showDecommissionedDevices" dense @update:model-value="() => toggleDecommisionedDevices()"/>
        </q-item-section>
        <q-item-section>
          <q-item-label caption class="column-label text-grey-10" @click="toggleDecommisionedDevices()">
            <span>Show decommissioned devices</span>
          </q-item-label>
        </q-item-section>
      </q-item>

      <q-item>
        <q-item-section side>
          <q-checkbox :model-value="options.showNonConnectedDevices" dense @update:model-value="() => toggleNonConnectedDevices()" />
        </q-item-section>
        <q-item-section>
          <q-item-label caption class="column-label text-grey-10" @click="toggleNonConnectedDevices()">
            <span>Show non-connected devices</span>
          </q-item-label>
        </q-item-section>
      </q-item>

      <q-item>
        <q-item-section side>
          <q-checkbox :model-value="options.showDeviceParts" dense @update:model-value="() => toggleDeviceParts()" />
        </q-item-section>
        <q-item-section>
          <q-item-label caption class="column-label text-grey-10" @click="toggleDeviceParts()">
            <span>Show multi-device parts</span>
          </q-item-label>
        </q-item-section>
      </q-item>

      <q-item>
        <q-item-section side>
          <q-checkbox :model-value="options.showSimulatedDevices" dense @update:model-value="() => toggleSimulatedDevices()" />
        </q-item-section>
        <q-item-section>
          <q-item-label caption class="column-label text-grey-10" @click="toggleSimulatedDevices()">
            <span>Show simulated devices</span>
          </q-item-label>
        </q-item-section>
      </q-item>

      <q-separator class="q-mt-sm q-mb-sm" />

      <q-item v-for="(column, index) in switchableColumns" :key="index">
        <q-item-section side>
          <q-checkbox :model-value="column.isVisible" dense @update:model-value="() => toggleColumn(column)" />
        </q-item-section>
        <q-item-section>
          <q-item-label caption class="column-label text-grey-10" @click="toggleColumn(column)">
            {{ column.label }}
          </q-item-label>
        </q-item-section>
      </q-item>
    </q-list>
  </q-btn-dropdown>
</template>

<style scoped lang="scss">
.columns-menu-dropdown {
  position: absolute;
  right: 2px;
  top: 2px;
  z-index: 100;

  .q-btn-dropdown__arrow {
    margin-left: 0 !important;
  }
}

.columns-menu {
  .column-label {
    cursor: pointer;

    span {
       white-space: nowrap;
    }
  }
}

</style>
