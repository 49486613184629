import { addMilliseconds, startOfDay } from 'date-fns'
import { parseTime, formatTime, formatTimeShort } from './time'
import { isNumber } from './number'
import { findLast } from './array'

/**
 * Defines a selection of time ranges within a day
 */
export class DaySchedule {
  constructor (items = []) {
    Object.assign(this, { items })
    this.normalize()
  }

  /**
   * Normalizes the data after assignment
   */
  normalize () {
    this.items = (this.items || [])
      .map(({ from, to }) => ({
        from: isNumber(from) ? from : parseTime(from),
        to: isNumber(to) ? to : parseTime(to)
      }))
  }

  /**
   * All hours of the day
   * @type {Array[Number]}
   */
  static get AllHours () {
    const hours = []
    for (var hour = 0; hour < 24; hour++) {
      hours.push(hour)
    }
    return hours
  }

  /**
   * Time ranges included in the schedule,
   * list of `{ from, to }` tuples
   * @type {Array}
   */
  items

  /**
   * Returns true if schedule is empty
   * @type {Boolean}
   */
  get isEmpty () {
    return (this.items || []).length === 0
  }

  /**
   * Returns the number of time ranges in the schedule
   * @type {Number}
   */
  get length () {
    return (this.items || []).length
  }

  /**
   * Converts time schedule to string format, eg.
   * 00:00:00-01:00:00,02:00:00-03:12:34
   * @param {Boolean} readable If true, more readable human-friendly format is used
   * @param {Boolean} hours If true, seconds are ignored in the output as time ranges cover full hours
   * @returns {String}
   */
  toString ({ readable, hours } = {}) {
    return this.items
      .map(item => this.slotToString(item, { readable, hours }))
      .join(readable ? ', ' : ',')
  }

  /**
   * Converts the specified time slot to string format
   * i.e. 00:00:00-01:00:00
   * @param {Boolean} readable If true, more readable human-friendly format is used
   * @param {Boolean} hours If true, seconds are ignored in the output as time ranges cover full hours
   * @returns {String}
   */
  slotToString (slot, { readable, hours } = {}) {
    if (!slot) return
    const { from, to } = slot
    if (readable) {
      return (from === to)
        ? `${hours ? formatTimeShort(from) : formatTime(from)}`
        : `${hours ? formatTimeShort(from) : formatTime(from)} - ${hours ? formatTimeShort(to) : formatTime(to)}`
    } else {
      return (from === to)
        ? `${formatTime(from)}`
        : `${formatTime(from)}-${formatTime(to)}`
    }
  }

  /**
   * Converts human-readable comma-separated list of times, eg.
   * 00:00:00-01:00:00,02:00:00-03:12:34
   * to time schedule
   * @param {String} value Schedule to parse
   * @returns {DaySchedule} Parsed schedule
   */
  static fromString (value) {
    if (value != null) {
      const items = value
        .toString()
        .split(',')
        .map(range => range.trim())
        .map(range => range.split('-'))
        .map(([from, to]) => ({
          from: parseTime(from),
          to: to ? parseTime(to) : parseTime(from)
        }))
        .filter(item => item.from != null && item.to != null)
      const schedule = new DaySchedule(items)
      return schedule
    }
  }

  /**
   * Converts time schedule to list of hours
   * Useful for time schedules where selectable blocks are hours
   * @returns {Array[Number]}
   */
  toHours () {
    return DaySchedule
      .AllHours
      .filter(hour =>
        this.includes(hour * 3600))
  }

  /**
   * Creates time schedule from list of hours
   * Useful for time schedules where selectable blocks are hours
   * @param {Array[Number]} hours Hours to convert to time schedule
   * @returns {DaySchedule}
   */
  static fromHours (hours) {
    if (hours) {
      let items = []

      const addItem = (from, to) => {
        if (from != null) {
          if (to == null) to = from + 1
          if (to === 24) to = 0
          items.push({
            from: from * 3600 * 1000,
            to: to * 3600 * 1000
          })
        }
      }

      let from
      let to
      for (const hour of DaySchedule.AllHours) {
        if (hours.includes(hour)) {
          if (from != null) {
            to = hour + 1
          } else {
            from = hour
            to = null
          }
        } else {
          addItem(from, to)
          from = null
          to = null
        }
      }

      addItem(from, to)

      return new DaySchedule(items)
    }
  }

  /**
   * Generates a day schedule using the specified period and starting point
   * @param {Number} period Period duration
   * @param {Number} start Start time, in seconds from midnight
   * @param {Number} count Number of entries to generate.
   * @param {Number} duration Period duration. If not specified, generated is a series of moments rather than periods.
   * If not specified, the day will be filled counting from the {@link start}.
   */
  static periodic ({ period, start = 0, count, duration } = {}) {
    if (period > 0 && start >= 0 && !(count < 0)) {
      const schedule = new DaySchedule()
      const end = 24 * 3600
      let time = start
      while (time < end) {
        schedule.add(time, duration ? (time + duration) : time)
        time = time + period
        if (count > 0 && schedule.length === count) break
      }
      return schedule
    }
  }

  /**
   * Clears the schedule
   * @returns {Array} Current time ranges
   */
  clear () {
    this.items = []
    return this.items
  }

  /**
   * Adds another time range to schedule
   * @param {Date|Number|String} from Start of the time range to add.
   * @param {Date|Number|String} to End of the time range to add.
   * @description If value type is `Number`, the value is expected to be seconds.
   * @returns {Array} Current time ranges
   */
  add (from, to) {
    const item = {
      from: parseTime(isNumber(from) ? from * 1000 : from),
      to: parseTime(isNumber(to) ? to * 1000 : to)
    }
    this.items.push(item)
    return this.items
  }

  /**
   * Remove time range from schedule
   * @param {Date|Number|String} from Start of the time range to remove
   * @param {Date|Number|String} to End of the time range to remove
   * @description If value type is `Number`, the value is expected to be seconds.
   * @returns {Array} Current time ranges
   */
  remove (from, to) {
    const item = {
      from: parseTime(isNumber(from) ? from * 1000 : from),
      to: parseTime(isNumber(to) ? to * 1000 : to)
    }
    const index = this.items.findIndex(({ from, to }) => item.from === from && item.to === to)
    if (index > -1) {
      this.items.splice(index, 1)
    }
    return this.items
  }

  /**
   * Checks whether the specified time matches the specified schedule item
   * @param {Object} item Schedule item
   * @param {Date|Number|String} value Time to check
   * @param {Boolean} inclusive If true, the end of the range is included in the check
   * @description
   * Right boundary of time range is non-inclusive.
   * If value type is `Number`, the value is expected to be seconds.
   * @returns {Boolean}
   */
  isIncludedIn (item, value, inclusive) {
    const time = isNumber(value) ? value * 1000 : parseTime(value)
    const { from, to } = item
    // Check if hour is not before the slot
    if (time < from) return false
    // Check if right boundary is midnight, indicated by 0
    if (from > 0 && to === 0) return true
    // If time spot, not slot, hour must be on spot
    if (to === from) return time === from

    // Otherwise time must be before the end of the slot
    return inclusive
      ? time <= to
      : time < to
  }

  /**
   * Checks whether the specified time is included in the schedule.
   * @param {Date|Number|String} value
   * @description
   * Right boundary of time range is non-inclusive.
   * If value type is `Number`, the value is expected to be seconds.
   * @returns {Object} Matching time slot
   */
  includes (value) {
    return this.items.find(item => this.isIncludedIn(item, value))
  }

  /**
   * Checks whether the current or specified time is included in the schedule.
   * @param {Date} time Time to check
   * @returns {Boolean}
   */
  itIsTime (time = new Date()) {
    return this.includes(time) != null
  }

  /**
   * Returns the scheduled slot which matches the current or specified time.
   * @param {Date|Number|String} value Time to check
   * @returns {Object} Time slot matching the time
   */
  currentSlot (value = new Date()) {
    return this.includes(value)
  }

  /**
   * If time matches any scheduled slot, returns that slot.
   * Otherwise returns the next scheduled slot after the time.
   * @param {Date|Number|String} value Time to check
   * @returns {Object} Next time slot matching the time
   */
  nextSlot (value = new Date()) {
    const time = isNumber(value) ? value * 1000 : parseTime(value)
    const now = this.includes(value)

    if (now) {
      return now
    } else {
      const after = this.items.find(item => item.from >= time)
      if (after) {
        return after
      } else {
        const before = this.items.filter(item => item.to < time)[0]
        return before
      }
    }
  }

  /**
   * If time matches any scheduled slot, returns that slot.
   * Otherwise returns the last scheduled slot before the time.
   * @param {Date|Number|String} value Time to check
   * @returns {Object} Previous time slot matching the time
   */
  previousSlot (value = new Date()) {
    const time = isNumber(value) ? value * 1000 : parseTime(value)
    const now = this.includes(value)

    if (now) {
      return now
    } else {
      const before = findLast(this.items, item => item.to <= time)
      if (before) {
        return before
      } else {
        const before = this.items.filter(item => item.from > time)[0]
        return before
      }
    }
  }

  /**
   * If time matches any scheduled slot, returns that time.
   * Otherwise returns the start time of the next scheduled slot after the time.
   * @param {Date|Number|String} value Time to check
   * @returns {Date} Time of the upcoming schedule slot
   */
  nextTime (value = new Date()) {
    const time = isNumber(value) ? value * 1000 : parseTime(value)
    if (this.includes(time)) {
      return addMilliseconds(startOfDay(new Date()), time)
    }
    else {
      const nextSlot = this.nextSlot(value)
      if (nextSlot) {
        return addMilliseconds(startOfDay(value), nextSlot.from)
      }
    }
  }
}
