<script>
import { UploadStatus } from '@stellacontrol/model'
import { formatDateTime } from '@stellacontrol/utilities'
import { Secure } from '@stellacontrol/security-ui'
import { CellMixin } from './cell-mixin'

export default {
  mixins: [
    CellMixin,
    Secure
  ],

  props: {
    item: {
      type: Object,
      required: true,
    }
  },

  computed: {
    // Current status of recent firmware update on the device
    updateStatus () {
      const { device: { updateStatus } } = this.item
      if (updateStatus) {
        const { firmwareVersion, status, updatedAt, progress } = updateStatus
        const inProgress = status === UploadStatus.Sending
        const tooltip = inProgress
          ? `Updating to v.${firmwareVersion}, ${progress} % done.<br>Last update at ${formatDateTime(updatedAt)}.`
          : `The device will be updated to v.${firmwareVersion}<br>when it comes online`

        return {
          inProgress,
          progress,
          firmwareVersion,
          tooltip
        }
      }
    }
  }
}
</script>

<template>
  <div class="firmware" :style="columnStyle">
    <div v-if="updateStatus" class="update text-orange-9">
      <sc-tooltip :text="updateStatus.tooltip">
      </sc-tooltip>
      <div class="progress" :style="{ width: `${updateStatus.progress}%` }">
      </div>
      <div class="version">
        {{ item.firmwareVersion }}
      </div>
    </div>
    <div v-else class="version">{{ item.firmwareVersion }}</div>
  </div>
</template>

<style scoped lang="scss">
.firmware {
  height: "28px";

  .update {
    position: relative;
    height: 100%;

    .progress {
      position: absolute;
      left: 1px;
      opacity: 0.3;
      background-color: green;
      height: 100%;
      transition: all 0.3s ease-in-out;
    }
  }

  .version {
    padding: 4px 8px;
    margin-left: 1px;
  }
}
</style>