import { Confirmation } from '@stellacontrol/client-utilities'
import { PlanAction, PlanActions } from './plan-action'
import { RemoveItemsAction } from './remove-items'

/**
 * Toggles masking of radiation outside the building
 */
export class MaskRadiationOutsideAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.MaskRadiationOutside
  }

  /**
  * Action label
  * @type {String}
  */
  get label () {
    return 'Toggle radiation outside the building'
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   * @param {Boolean} isMasked If true, radiation outside the defined building perimeter will not be visible
   */
  async execute ({ renderer, isMasked } = {}) {
    if (renderer) {
      const { radiationLayer, floor } = renderer
      floor.radiation.maskOutside = isMasked
      radiationLayer.refresh()
      renderer.changed({ action: this })
    }
  }
}

/**
 * Toggles walls and yards lock
 */
export class LockWallsAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.LockWalls
  }

  /**
  * Action label
  * @type {String}
  */
  get label () {
    return 'Toggles walls and yards lock'
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   * @param {Boolean} isLocked If true, walls and yards on radiation layer are locked for any modifications
   */
  async execute ({ renderer, isLocked } = {}) {
    if (renderer) {
      const { radiationLayer, floor } = renderer
      floor.lockWalls(isLocked)
      radiationLayer.refresh()
      renderer.reset()
      renderer.changed({ action: this })
    }
  }
}

/**
 * Clears walls and yards
 */
export class ClearWallsAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.ClearWalls
  }

  /**
  * Action label
  * @type {String}
  */
  get label () {
    return 'Clears walls and yards '
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   */
  async execute ({ renderer } = {}) {
    if (renderer) {
      const { radiationLayer, floor } = renderer
      renderer.stopAddingItem()
      const remove = new RemoveItemsAction()
      const items = [...floor.walls, ...floor.yards]
      await remove.execute({ renderer, items })
      radiationLayer.refresh()
      renderer.changed({ action: this })
    }
  }
}

/**
 * Sets the global radiation strength for all antennae
 */
export class SetRadiationStrengthAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.SetRadiationStrength
  }

  /**
  * Action label
  * @type {String}
  */
  get label () {
    return 'Sets the global radiation strength for all antennae'
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   * @param {Number} strength Radiation strength for all antennae on the current floor
   * @param {Boolean} allFloors If true, the new radiation strength will be applied to other floors as well
   */
  async execute ({ renderer, strength, allFloors } = {}) {
    if (renderer) {
      const { radiationLayer, floor } = renderer
      floor.radiation.strength = strength

      if (renderer.layout.floorCount > 1 && allFloors) {
        const yes = await Confirmation.ask(({ title: 'Confirmation', message: 'Apply the new antenna strength to the other floors as well?' }))
        if (yes) {
          for (const floor of renderer.layout.floors) {
            floor.radiation.strength = strength
          }
        }
      }

      radiationLayer.refresh()
      renderer.changed({ action: this })
    }
  }
}


/**
 * Clears the transparent colors for the background image
 */
export class ClearBackgroundTransparencyAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.ClearBackgroundTransparency
  }

  /**
   * Action label
   * @type {String}
   */
  get label () {
    return 'Clear transparency'
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   */
  execute ({ renderer } = {}) {
    if (renderer) {
      renderer.floor.background.clearTransparentColors()
      renderer.backgroundLayer.refresh()
      renderer.changed()
      renderer.cancelEditing()
    }
  }
}

/**
 * Starts/ends selection of transparent colors for the background image
 */
export class SetBackgroundTransparencyAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.SetBackgroundTransparency
  }

  /**
   * Action label
   * @type {String}
   */
  get label () {
    return 'Set transparency'
  }

  /**
   * Use this to indicate that action requires refresh
   * of the entire plan stage after completion
   * @type {Boolean}
   */
  get requiresRefresh () {
    return false
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   * @param {String} color Color to add to transparent colors of the background image.
   * If not specifed, we just toggle setting mode on/off
   */
  async execute ({ renderer, color } = {}) {
    if (renderer) {
      if (color) {
        if (renderer.floor.background.addTransparentColors(color)) {
          await renderer.backgroundLayer.refreshImage()
        }
      } else {
        if (!renderer.isSettingTransparentColors) {
          renderer.cancelEditing()
        }
        renderer.floor.background.clearTransparentColors()
        await renderer.setTransparentColors(!renderer.isSettingTransparentColors)
      }
    }
  }
}
