<script>
import { mapGetters } from 'vuex'
import { Secure } from '@stellacontrol/security-ui'
import DetailsMixin from './details-mixin'

export default {
  mixins: [
    Secure,
    DetailsMixin
  ],

  computed: {
    ...mapGetters([
      'toLocalDateTime'
    ])
  }
}
</script>

<template>
  <q-virtual-scroll type="table" class="scroller"
    :virtual-scroll-item-size="30"
    :items="items">

    <template v-slot:before>
      <thead>
        <tr class="header">
          <th>
            Device
          </th>
          <th>
            Changed At
          </th>
          <th>
            Changed By
          </th>
          <th>
            Organization
          </th>
          <th>
            Alert Configuration
          </th>
          <th>
            Details
          </th>
        </tr>
      </thead>
    </template>

    <template v-slot="{ item, index }">
      <tr :key="index" class="item">
        <td class="serial">
          <router-link class="item-link"
            :to="{ name: 'device-dashboard', params: { serialNumber: item.serialNumber } }">
            {{ item.serialNumber }}
            <sc-tooltip :text="`Go to ${item.serialNumber} dashboard`"></sc-tooltip>
          </router-link>
        </td>
        <td class="date">
          {{ datetime(toLocalDateTime(item.created)) }}
        </td>
        <td class="user">
          {{ item.user }}
        </td>
        <td class="organization">
          {{ item.organization }}
        </td>
        <td class="type">
          {{ AlertLabel[item.alertType] }}
        </td>
        <td class="details">
          {{ item.details }}
          <sc-tooltip :text="html(item.changes)">
          </sc-tooltip>
        </td>
      </tr>
    </template>

  </q-virtual-scroll>
</template>

<style scoped lang="scss">
.scroller {
  box-shadow: none;
  max-height: 90vh;

  .header {
    height: auto;

    th {
      text-align: left;
    }
  }

  .item {
    td {
      height: auto;
    }

    .serial {
      width: 140px;
    }

    .user {
      width: 160px;
    }

    .organization {
      width: 160px;
    }

    .date {
      width: 140px;
    }

    .type {
      width: 140px;
    }

    .details {
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
    }
  }
}
</style>
