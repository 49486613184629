<script>
import { mapGetters, mapActions } from 'vuex'
import { sortItemsBy } from '@stellacontrol/utilities'
import { ViewMixin, FormMixin } from '@stellacontrol/client-utilities'
import { Secure } from '@stellacontrol/security-ui'
import PremiumServiceList from './components/premium-service-list.vue'
import PremiumServiceEditor from './components/premium-service-edit.vue'
import { resolve } from './premium-services.resolve'

const name = 'premium-services'

export default {
  mixins: [
    ViewMixin,
    FormMixin,
    Secure
  ],

  components: {
    'sc-premium-service-list': PremiumServiceList,
    'sc-premium-service-editor': PremiumServiceEditor
  },

  data () {
    return {
      name
    }
  },

  computed: {
    ...mapGetters([
      'pricelist',
      'organizationGuardian',
      'organizationHierarchy'
    ]),

    // Currently edited premium service,
    premiumService () {
      return this.data
    },

    // Returns true if currently editing a premium service
    isEditing () {
      return this.premiumService != null
    },

    // Returns true if specified premium service is currently selected
    isSelected () {
      return service => {
        const { premiumService } = this
        return service && premiumService && premiumService.id === service.id
      }
    },

    // Premium services in the pricelist,
    // available for editing by current organization
    premiumServices () {
      const { pricelist, currentOrganization, isSuperOrganization, organizationHierarchy } = this
      const services = isSuperOrganization
        ? pricelist.services
        : pricelist.resellerPremiumServices(currentOrganization, organizationHierarchy)
      return sortItemsBy(services, item => item.code || item.label)
    },

    // List of premium features to select from
    premiumFeatures () {
      return this.organizationGuardian.premiumFeatures || []
    }
  },

  methods: {
    ...mapActions([
      'savePricelist',
      'stopEditingPremiumService',
      'editPremiumService',
      'savePremiumService',
      'removePremiumService',
      'gotoRoute'
    ])
  },

  // Reload data on navigation to another organization
  async beforeRouteUpdate (to, from) {
    await resolve({ to, from })
  }
}

</script>

<template>
  <sc-view
    :name="name"
    :headerStyle="{ padding: 0 }"
    :titleStyle="{ padding: '20px 20px 20px 15px' }"
    :toolbarStyle="{ padding: '20px 20px 20px 20px', 'background-color': '#e8eaf6', 'border-left': 'solid #ced1d8 1px' }">

    <template #toolbar>
      <div class="info text-grey-9">
        You can define Premium Services, available to customers at extra cost.
        <br />Premium Service is made of features such as Alerts or Live Status.
        <br />Feature can have fixed or monthly price and can be charged per device.
      </div>
    </template>

    <header class="q-pt-sm q-pb-sm q-pl-md row">
      <q-btn flat dense unelevated no-caps class="default" label="Add Premium Service" icon="add" style="width: 190px"
        :disabled="isEditing"
        @click="editPremiumService()">
      </q-btn>
    </header>

    <main class="q-pl-md q-pr-md q-pb-md row" v-if="pricelist">
      <div class="col-5 col pricelist-container">
        <div class="pricelist">
          <sc-premium-service-list
            v-if="pricelist.hasServices"
            :items="premiumServices"
            :service="premiumService"
            @selected="premiumService => editPremiumService(premiumService)">
          </sc-premium-service-list>
          <div v-else class="text-grey-9 q-pa-md">Pricelist is empty. Why not add some Premium Services to it?</div>
        </div>
      </div>

      <div class="feature-editor-container col-7 col q-pl-lg">
        <div class="feature-editor q-pa-md" v-if="premiumService">
          <sc-premium-service-editor
            :service="premiumService"
            :premiumFeatures="premiumFeatures"
            @cancel="stopEditingPremiumService()"
            @save="premiumService => savePremiumService({ premiumService })"
            @remove="premiumService => removePremiumService({ premiumService, confirm: true })"
          ></sc-premium-service-editor>
        </div>
      </div>
    </main>

    <main class="q-pa-md row" v-else>
      <q-btn label="Create pricelist" icon="add" color="primary" />
    </main>
  </sc-view>
</template>

<style lang='scss' scoped>
header {
  flex: 0;
  display: flex;
  flex-direction: row;
}

main {
  flex: 1;
  display: flex;
  flex-direction: row;
  overflow: hidden;

  .pricelist-container {
    position: relative;
    height: 100%;

    .pricelist {
      height: 100%;
      overflow: auto;
      border: 1px solid #0000001f;
    }
  }

  .feature-editor-container {
    height: 100%;
    overflow: hidden;

    .feature-editor {
      overflow: auto;
      height: 100%;
      border: 1px solid #0000001f;

      .buttons {
        .q-btn {
          min-width: 90px;
        }
      }
    }
  }
}
</style>
