import { Point } from './point'
import { Size } from './size'
import { Rectangle } from './rectangle'
import { isNumber } from '../number'

/**
 * Circle
 */
export class Circle {
  constructor (data) {
    if (data) {
      Object.assign(this, data)
    } else {
      return
    }

    if (data.radius != null) {
      // Explicit radius
      this.radius = data.radius
    } else if (data.width != null || data.height != null) {
      // Radius deducted from square of a specified width
      const size = (data.width < data.height || data.height == null) ? data.width : data.height
      this.radius = size / 2
    }

    if (data.x != null && data.y != null) {
      // Explicit coordinates
      this.x = data.x
      this.y = data.y
    } else {
      if (data.width == null && data.height == null && data.radius != null) {
        // Circle with specified radius is at (0,0)
        this.x = 0
        this.y = 0
      } else {
        // Circle at the centre of a square
        this.x = this.radius
        this.y = this.radius
      }
    }
  }

  /**
   * Creates a circle at position `(0,0)`
   * @returns {Circle}
   */
  static get Zero () {
    return new Circle({ x: 0, y: 0, radius: 0 })
  }

  /**
   * Creates a circle
   * @param {Circle|Number} circle Source circle or circle radius
   * @returns {Circle}
   */
  static from (circle) {
    return isNumber(circle)
      ? new Circle({ radius: circle })
      : new Circle(circle)
  }

  /**
   * Creates a deep copy of the circle
   * @type {Circle}
   */
  copy () {
    return Circle.from(this)
  }

  /**
   * Left coordinate of a rectangle containing the circle
   * @type {Number}
   */
  get left () {
    return this.x - this.radius
  }
  set left (value) {
    this.x = value + this.radius
  }

  /**
   * Top coordinate of a rectangle containing the circle
   * @type {Number}
   */
  get top () {
    return this.y - this.radius
  }
  set top (value) {
    this.y = value + this.radius
  }

  /**
   * Right coordinate of a rectangle containing the circle
   * @type {Number}
   */
  get right () {
    return this.left + this.width
  }

  /**
   * Bottom coordinate of a rectangle containing the circle
   * @type {Number}
   */
  get bottom () {
    return this.top + this.height
  }

  /**
   * Left-top corner of the rectangle containing the circle
   * @type {Point}
   */
  get leftTop () {
    const { left, top } = this
    if (left != null && top != null) {
      return new Point({ x: left, y: top })
    }
  }

  /**
   * Left-bottom corner of the rectangle containing the circle
   * @type {Point}
   */
  get leftBottom () {
    const { left, bottom } = this
    if (left != null && bottom != null) {
      return new Point({ x: left, y: bottom })
    }
  }

  /**
   * Right-top corner of the rectangle containing the circle
   * @type {Point}
   */
  get rightTop () {
    const { right, top } = this
    if (right != null && top != null) {
      return new Point({ x: right, y: top })
    }
  }

  /**
   * Right-bottom corner of the rectangle containing the circle
   * @type {Point}
   */
  get rightBottom () {
    const { right, bottom } = this
    if (right != null && bottom != null) {
      return new Point({ x: right, y: bottom })
    }
  }

  /**
   * Width of a rectangle containing the circle
   * @type {Number}
   */
  get width () {
    return this.radius * 2
  }
  set width (value) {
    this.radius = value / 2
  }

  /**
   * Height of a rectangle containing the circle
   * @type {Number}
   */
  get height () {
    return this.radius * 2
  }
  set height (value) {
    this.radius = value / 2
  }

  /**
   * Size of a rectangle containing the circle
   * @type {Size}
   */
  get size () {
    const { width, height } = this
    return new Size({ width, height })
  }
  set size (value) {
    const { width, height } = value
    const size = Math.min(width, height)
    this.width = size
  }

  /**
   * Rectangle containing the circle
   * @type {Rectangle}
   */
  get bounds () {
    const { x, y, radius, width, height } = this
    return new Rectangle({ x: x - radius, y: y - radius, width, height })
  }
  set bounds (value) {
    if (value) {
      const { x, y, width, height } = value
      const size = Math.min(width, height)
      this.radius = size / 2
      this.x = x
      this.y = y
    }
  }

  /**
   * Serializes the item to JSON
   * @returns {Object}
   */
  toJSON () {
    const result = { ...this }
    if (result.x == null) delete result.x
    if (result.y == null) delete result.y
    if (result.radius == null) delete result.radius
    return result
  }

  /**
   * String representation of the circle
   * @returns {String}
   */
  toString () {
    const { x, y, radius } = this
    return `(${[x, y, radius].filter(c => c != null).join(',')})`
  }

  /**
   * String representation of the circle, using coordinates the rectangle containing the circle
   * @returns {String}
   */
  toCoordinateString () {
    const { left, top, right, bottom } = this
    return `(${[left, top, right, bottom].filter(c => c != null).join(',')})`
  }

  /**
   * Determines whether circle coordinates and sizes are specified
   * @type {Boolean}
   */
  get isDefined () {
    return this.x != null && this.y != null && this.radius != null
  }

  /**
   * Coordinates of circle's center
   * @type {Point}
   */
  get center () {
    const { x, y } = this
    if (x != null && y != null) {
      return new Point({ x, y })
    }
  }

  /**
   * Checks whether the circle has the same coordinates and dimensions as the specified one
   * @param {Circle} circle Circle to compare to
   * @returns {Boolean}
   */
  sameAs (circle) {
    if (circle) {
      const { x, y, radius } = circle
      return this.x === x &&
        this.y === y &&
        this.radius === radius
    }
  }

  /**
   * Changes the circle position to relative, by moving `(x,y)` point to `(0,0)`
   * @type {Circle}
   */
  relative () {
    this.x = 0
    this.y = 0
    return this
  }

  /**
   * Rounds the coordinates and radius to the nearest integers
   * @type {Circle}
   */
  round () {
    this.x = this.x == null ? this.x : Math.round(this.x)
    this.y = this.y == null ? this.y : Math.round(this.y)
    this.radius = this.radius == null ? this.radius : Math.round(this.radius)
    return this
  }

  /**
   * Returns all points of the rectangle containing the circle in clockwise order starting with top-left point
   * @type {Array[Point]}
   */
  get points () {
    return [
      this.leftTop,
      this.rightTop,
      this.rightBottom,
      this.leftBottom
    ]
  }

  /**
   * Sets the coordinates of the circle
   * @param {Point} point New coordinates of the circle
   * @returns {Circle} Modified instance
   */
  moveTo (point) {
    if (!point) throw new Error('Point is required')
    const { x, y } = point
    this.x = x == null ? this.x : x
    this.y = y == null ? this.y : y
    return this
  }

  /**
   * Moves the coordinates of the circle
   * @param {Point} delta New coordinates of the circle
   * @returns {Rectangle} Modified instance
   */
  moveBy (delta) {
    if (!delta) throw new Error('Point is required')
    const { x, y } = delta
    this.x = x == null ? this.x : this.x + x
    this.y = y == null ? this.y : this.y + y
    return this
  }

  /**
   * Sets the coordinates of the circle
   * so that it's centered at the specified point
   * @param {Point|Size} value Coordinates of the centre of the circle or size of a rectangle in which to center
   * @returns {Circle} Modified instance
   * @description Examples of use
   *    centerAt({ x: 100, y: 200 }) will place the circle center at `(100,200)`
   *    centerAt({ x: 100 }) will place the circle center at `(100)` and leave the `y` coordinate intact
   *    centerAt({ width: 100 }) will place the circle center at `(50)` and leave the `y` coordinate intact
   *    centerAt({ height: 200 }, 25) will place the circle center's `y` coordinate at `25%` of the height `200`, thus `50`
   */
  centerAt (value, percent = 50) {
    if (!value) throw new Error('Point is required')
    const { x, y, width, height } = value

    if (x != null) {
      this.x = x
    } else if (width != null && percent != null) {
      this.x = width * (percent / 100)
    }

    if (y != null) {
      this.y = y
    } else if (height != null && percent != null) {
      this.y = height * (percent / 100)
    }

    return this
  }

  /**
   * Sets the coordinates of the circle
   * so that it's positioned above the specified `y` coordinate
   * @param {Number} y Coordinate above which to place the circle
   * @returns {Circle} Modified instance
   */
  moveAbove (y) {
    if (y == null) throw new Error('Y coordinate is required')
    const { radius } = this
    this.y = y - radius
    return this
  }

  /**
   * Sets the coordinates of the circle
   * so that it's positioned below the specified `y` coordinate
   * @param {Number} y Coordinate below which to place the circle
   * @returns {Circle} Modified instance
  */
  moveBelow (y) {
    if (y == null) throw new Error('Y coordinate is required')
    const { radius } = this
    this.y = y + radius
    return this
  }

  /**
   * Sets the coordinates of the circle
   * so that it's positioned to the left of the specified `x` coordinate
   * @param {Number} x Coordinate to the left of which to place the circle
   * @returns {Circle} Modified instance
   */
  moveBefore (x) {
    if (x == null) throw new Error('Y coordinate is required')
    const { radius } = this
    this.x = x - radius
    return this
  }

  /**
   * Sets the coordinates of the circle
   * so that it's positioned to the right of the specified `x` coordinate
   * @param {Number} x Coordinate to the right of which to place the circle
   * @returns {Rectangle} Modified instance
  */
  moveAfter (x) {
    if (x == null) throw new Error('Y coordinate is required')
    const { radius } = this
    this.x = x + radius
    return this
  }

  /**
   * Rotates the circle by the given angle on a flat plane
   * @param {Number} angle Rotation angle in degrees, from `0` to `360`
   * @param {Point} origin Optional origin of the rotation. If not specified, rotation is performed against `(0,0)`
   * @param {Boolean} round If true, result coordinates are rounded up to the nearest integers
   * @returns {Circle} Modified instance
   */
  rotate (angle, origin, round = true) {
    const { x, y } = Point.from(this.center).rotate(angle, origin, round)

    this.x = x
    this.y = y

    return this
  }

  /**
   * Scales the circle by the specified factor
   * @param {Point|Number} scale Scale factor on `x` and `y` axis, where `1` means no change
   * @param {Boolean} coordinates If true, also coordinates are scaled
   * @returns {Circle} Modified instance
   */
  scale (scale, coordinates) {
    scale = isNumber(scale) ? { x: scale, y: scale, z: scale } : scale
    const { x, y } = scale || {}
    this.radius = this.radius * scale.x
    if (coordinates) {
      this.x = (x != null && this.x != null) ? this.x * scale.x : this.x
      this.y = (y != null && this.y != null) ? this.y * scale.y : this.y
    }
    return this
  }

  /**
   * Scales the coordinates by the reverse of the specified factor
   * @param {Point|Number} scale Scale factor, `1` means no change.
   * Use a number to specify the same scale factor for all axes, or {@link Point} to specify a scale for each axis individually.
   * @param {Boolean} coordinates If true, also coordinates are scaled
   * @returns {Point} Modified instance
   */
  scaleReverse (scale, coordinates) {
    scale = isNumber(scale) ? { x: scale, y: scale, z: scale } : scale
    const { x, y, z } = scale || {}
    const reverse = {
      x: x === 0 ? x : 1 / x,
      y: y === 0 ? y : 1 / y,
      z: z === 0 ? z : 1 / z
    }
    return this.scale(reverse, coordinates)
  }

  /**
   * Grows the circle by the specified size or margin
   * @param {Number} value Size or margin to grow by
   * @returns {Circle} Modified instance
   */
  growBy (value) {
    if (value) {
      this.radius = this.radius + value
    }

    return this
  }
}
