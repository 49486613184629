import { formatDate } from '@stellacontrol/utilities'
import { Guardian } from '@stellacontrol/security'
import { OrganizationLevelNames, OrganizationLevel, getOrganizationRank, OrganizationIcons, OrganizationColors } from '@stellacontrol/model'

/**
 * Organization profiles list columns
 */
const organizationProfileColumns = [
  { name: 'level', label: 'Level', format: (value, item) => getOrganizationRank(item), sortable: true, align: 'left' },
  { name: 'fullName', label: 'Name', field: 'fullName', sortable: true, align: 'left', required: true },
  { name: 'profileOwner', label: 'Owner', field: 'ownerId', format: (value, item) => item.owner ? item.owner.name : 'Shared profile', sortable: true, align: 'left' },
  { name: 'levelDescription', label: 'Level', field: 'level', sortable: true, align: 'left', format: level => OrganizationLevelNames[level] },
  { name: 'isEnabled', label: 'Status', field: 'isEnabled', align: 'left', format: isEnabled => `${isEnabled ? 'Active' : 'Inactive'}` },
  { name: 'isDefault', label: 'Default', field: 'isDefault', align: 'left', format: isDefault => `${isDefault ? 'Default Profile' : ''}` },
  { name: 'created', label: 'Created On', field: 'createdAt', sortable: true, align: 'left', format: value => formatDate(value), desktopOnly: true },
  { name: 'updated', label: 'Updated On', field: 'updatedAt', sortable: true, align: 'left', format: value => formatDate(value), desktopOnly: true },
  { name: 'actions', label: 'Actions', align: 'right', sortable: false }
]

const organizationsInProfileColumns = [
  { name: 'icon', label: 'Level', field: 'level', format: value => ({ icon: OrganizationIcons[value], color: OrganizationColors[value] }), sortable: true, align: 'left' },
  { name: 'name', label: 'Name', field: 'name', sortable: true, align: 'left', required: true },
  { name: 'description', label: 'Description', field: 'description', sortable: true, align: 'left' },
  { name: 'level', label: 'Level', field: 'level', sortable: true, align: 'left', format: level => OrganizationLevelNames[level] },
  { name: 'isEnabled', label: 'Status', field: 'isEnabled', align: 'left', format: isEnabled => `${isEnabled ? 'Active' : 'Inactive'}` },
  { name: 'created', label: 'Created On', field: 'createdAt', sortable: true, align: 'left', format: value => formatDate(value), desktopOnly: true },
  { name: 'updated', label: 'Updated On', field: 'updatedAt', sortable: true, align: 'left', format: value => formatDate(value), desktopOnly: true },
  { name: 'actions', label: 'Actions', align: 'right', sortable: false }
]

export function createState () {
  return {
    /**
     * Organization profiles
     */
    items: null,
    /**
     * List columns
     */
    organizationProfileColumns,
    organizationsInProfileColumns,
    /**
     * Last created or updated organization profile
     */
    lastUpdated: null
  }
}

export const state = createState()


export const getters = {
  /**
   * All organization profiles
   */
  organizationProfiles: state =>
    state.items || [],

  /**
   * Dictionary of organization profiles, by identifier
   */
  organizationProfilesDictionary: state =>
    (state.items || []).reduce((all, o) => ({ ...all, [o.id]: o })),

  /**
    * Global organization profiles, without explicitly assigned owner
    */
  globalOrganizationProfiles: state =>
    (state.items || []).filter(p => !p.ownerId),

  /**
   * Organization profiles directly owned by the current organization.
   */
  myOrganizationProfiles: (state, getters) => {
    const { currentOrganization, guardian } = getters
    if (!(currentOrganization && guardian)) {
      return []
    }

    if (currentOrganization.isSuperOrganization) {
      // Super organization owns everything
      return state.items || []
    } else {
      // Others don't own any profiles, they can only use
      // profiles granted to them in permissions
      return []
    }
  },

  // Other organization profiles available to the current organization
  // but not directly owned by it
  otherOrganizationProfiles: (state, getters) => {
    const { currentOrganization, features, currentOrganizationProfile, organizationProfiles, isMyOrganizationProfile, pricelist, environment } = getters
    if (!currentOrganization) {
      return []
    }
    const profileGuardian = new Guardian({
      features,
      principal: currentOrganizationProfile,
      pricelist, environment
    })
    const grantedProfiles = profileGuardian.getContext('child-organization-profiles') || []

    return organizationProfiles
      .filter(p => !isMyOrganizationProfile(p))
      .filter(p => grantedProfiles.includes(p.id))
  },

  // All profiles available profiles to the current organization
  availableOrganizationProfiles: (state, getters) => {
    const { currentOrganization } = getters
    if (!currentOrganization) return []

    // Super-organization can see all profiles
    if (currentOrganization.isSuperOrganization) {
      const { organizationProfiles } = getters
      return organizationProfiles

    } else {
      const { myOrganizationProfiles, otherOrganizationProfiles } = getters
      return [
        ...myOrganizationProfiles,
        ...otherOrganizationProfiles
      ]
    }
  },

  // Checks whether the specified profile is owned by my organization
  isMyOrganizationProfile: (state, getters) =>
    profile =>
      getters.myOrganizationProfiles.some(p => p.id === profile.id),

  // Returns default organization profile to use, when creating new organizations
  myDefaultOrganizationProfile: (state, getters) => {
    const { myOrganizationProfiles, otherOrganizationProfiles } = getters
    return myOrganizationProfiles.find(p => p.isDefault) ||
      otherOrganizationProfiles.find(p => p.isDefault) ||
      otherOrganizationProfiles.find(p => p.isRegularOrganization) ||
      myOrganizationProfiles[0] ||
      otherOrganizationProfiles[0]
  },

  /**
   * Indicates that there are organization profiles available
   */
  hasOrganizationProfiles: state =>
    state.items && state.items.length > 0,

  /**
   * Finds an organization profile
   * @param id Organization profile identifier
   */
  getOrganizationProfile: state =>
    id => (state.items || []).find(p => p.id === id),

  /**
   * Organization profiles allowed for the current reseller organization,
   * when it creates new child organizations
   */
  childOrganizationProfiles: state =>
    state.items || [],

  /**
   * Default organization profile to use when creating new child organizations
   */
  defaultChildOrganizationProfile: (state, getters) =>
    getters.childOrganizationProfiles.find(p => p.level === OrganizationLevel.Organization) || getters.childOrganizationProfiles[0],

  /**
   * Organization profile list columns
   */
  organizationProfileColumns: state =>
    state.organizationProfileColumns,

  /**
   * Organizations-in-the-profile list columns
   */
  organizationsInProfileColumns: state =>
    state.organizationsInProfileColumns
}
