import { stringCompare, numberCompare } from '@stellacontrol/utilities'
import { getPlaceDescription } from './place-names'

/**
 * Sort order of places
 */
export const PlaceSortOrder = {
  // Simple alphabetical order by name
  Name: 'name',
  // Simple alphabetical order by name prefixed with place type
  QualifiedName: 'qualified-name',
  // Order by address
  Address: 'address',
  // Custom order, as ordered by user
  Custom: 'custom'
}

/**
 * Sorts the specified places
 * @param places Places to sort
 * @param order Sorting order
 */
export function sortPlaces (places = [], order = PlaceSortOrder.Name) {
  // Comparer responsible for pushing STOCK and SHARED places
  // always on top
  const compareSpecial = (a, b) => {
    if (a.id === 'none' && b.id !== 'none') {
      return -10
    }
    if (b.id === 'none' && a.id !== 'none') {
      return 10
    }
    if (a.id === 'shared' && b.id !== 'shared') {
      return -5
    }
    if (b.id === 'shared' && a.id !== 'shared') {
      return 5
    }
  }

  const compare = {
    // Sorting by name
    // Make sure that NO PLACE and SHARED DEVICES are on top
    [PlaceSortOrder.Name]: (a, b) => {
      return stringCompare(a.name, b.name)
    },
    // Sorting by qualified name
    [PlaceSortOrder.QualifiedName]: (a, b) => {
      return stringCompare(getPlaceDescription(a), getPlaceDescription(b))
    },
    // Sorting by address
    [PlaceSortOrder.Address]: (a, b) => {
      return stringCompare(a.fullAddress, b.fullAddress)
    },
    // Custom order
    [PlaceSortOrder.Custom]: (a, b) => {
      return numberCompare(a.sortOrder, b.sortOrder) || stringCompare(a.name, b.name)
    }
  }[order]

  if (!compare) {
    throw new Error(`Unknown place sort order ${order}`)
  }

  const result = [...places]
  result.sort((a, b) => compareSpecial(a, b) || compare(a, b))
  return result
}
