<script>
import { mapGetters, mapActions } from 'vuex'
import { Secure } from '@stellacontrol/security-ui'

export default {
  mixins: [
    Secure
  ],

  props: {
    item: {
      type: Object,
      required: true,
    }
  },

  computed: {
    ...mapGetters([
      'configuration'
    ]),

    // All tag definitions
    availableTags () {
      return this.configuration.entities.tag.items
    }
  },

  methods: {
    ...mapActions([
      'toggleFavoriteDevice',
      'removeDeviceTag'
    ]),

    // Toggles the device as current user's favorite
    async toggleFavorite (device) {
      await this.toggleFavoriteDevice({ device, user: this.currentUser })
      this.$emit('toggleFavorite')
    }
  }
}
</script>

<template>
  <div>
    <q-icon
      dense size="20px"
      :color="item.isFavorite ? availableTags.favorite.colorOn : availableTags.favorite.colorOff"
      :name="item.isFavorite ? availableTags.favorite.iconOn : availableTags.favorite.iconOff"
      @click="() => toggleFavorite(item.device)">
    </q-icon>
    <q-icon
      dense size="20px"
      v-for="tag of (item.device.tags || []).filter(t => t.name !== 'favorite')"
      :key="tag.name"
      :color="availableTags[tag.name].colorOn"
      :name="availableTags[tag.name].iconOn"
      @click="() => removeDeviceTag({ device: item.device, tag })">
    </q-icon>
  </div>
</template>

<style>

</style>