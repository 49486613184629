import { createState } from './device-settings.state'

export const mutations = {
  /**
   * Indicates that we're in process of changing region of the specified devices
   * @param {Array[Device]} devices Devices whose region is currently being changed
   * @param {DeviceRegion} region Region to assign devices to
   */
  startChangingRegionOfDevices (state, { devices = [], region } = {}) {
    state.changingRegionOfDevices = devices || []
    state.changingRegionTo = region
    state.changingRegionErrors = []
  },

  /**
   * Indicates that we've finished the process of changing region of devices
   */
  finishChangingRegionOfDevices (state, { failed } = {}) {
    state.changingRegionOfDevices = []
    state.changingRegionErrors = failed
  },

  /* Presence of this mutation is obligatory on stores,
   * to ensure purging of any user-bound data when
   * user logs in and out and in. Otherwise expect
   * unexpected side effects with data of the previously
   * logged in user appearing at places.
   */
  reset (state) {
    Object.assign(state, createState())
  }
}
