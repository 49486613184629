<script>
import { mapActions, mapGetters } from 'vuex'
import { formatDate } from '@stellacontrol/utilities'
import { DeviceAuditEventLevel, DeviceAuditEventType } from '@stellacontrol/devices'
import { Secure } from '@stellacontrol/security-ui'
import { DevicePanelTabMixin } from '../device-panel-tab-mixin'

export default {
  mixins: [
    DevicePanelTabMixin,
    Secure
  ],

  data () {
    return {
      audit: [],
      // Permissions required to see specific types of events
      permissions: {
        [DeviceAuditEventType.Manufactured]: ['add-devices'],
        [DeviceAuditEventType.Added]: ['add-devices'],
        [DeviceAuditEventType.Reset]: ['reset-devices'],
        [DeviceAuditEventType.SettingsChanged]: ['device-configuration'],
        [DeviceAuditEventType.Decommissioned]: ['decommission-devices'],
        [DeviceAuditEventType.PremiumServiceAssigned]: ['premium-services-sell'],
        [DeviceAuditEventType.PremiumServiceActivated]: ['premium-services-sell'],
        [DeviceAuditEventType.PremiumServiceExpired]: ['premium-services-sell'],
        // Firmware updates can only be seen if done by current organization
        // or their child organizations
        [DeviceAuditEventType.FirmwareUpdated]: ['device-management-firmware'],
        // Show ownership change event only if reseller who can sell,
        // or when viewer is the owner specified in the event
        [DeviceAuditEventType.OwnershipChanged]: event => {
          if (this.canUse('sell-devices')) {
            return true
          } else {
            return this.currentOrganization.id === event.principalId
          }
        },
        // Show share event only if reseller who can share,
        // or when viewer is the owner specified in the event
        [DeviceAuditEventType.Shared]: event => {
          if (this.canUse('share-devices')) {
            return true
          } else {
            return this.currentOrganization.id === event.principalId
          }
        },
      }
    }
  },

  computed: {
    ...mapGetters([
      'organizations'
    ]),

    // Returns true if specified event was initiated by my organization
    // or one of my child organizations
    isMyEvent () {
      return event => {
        return this.isSuperOrganization ||
          this.currentOrganization.id === event.organizationId ||
          this.organizations.some(o => o.id === event.organizationId) ||
          this.organizations.some(o => o.id ===event.targetOrganizationId)
      }
    },

    // Audit items visible to the current user
    // under his permissions
    visibleItems () {
      return this.audit.filter(event => {
        if (!this.isMyEvent(event)) return false
        const permissions = this.permissions[event.type]
        if (typeof permissions === 'function') {
          return permissions(event)
        } else {
          return this.canUseAll(permissions)
        }
      })
    }
  },

  methods: {
    ...mapActions([
      'getDeviceAudit'
    ]),

    // Populates the audit
    async loadAudit () {
      const { device, currentUser: user, currentOrganization: organization } = this
      if (device) {
        this.audit = await this.getDeviceAudit({ device, user, organization, descending: true })
      } else {
        this.audit = []
      }
    },

    // Returns color representing the event
    getColor (item) {
      if (item.level === DeviceAuditEventLevel.Error) return 'red'
      if (item.level === DeviceAuditEventLevel.Warning) return 'orange'
      if (item.type === DeviceAuditEventType.PremiumServiceActivated) return 'green'
      if (item.type === DeviceAuditEventType.PremiumServiceExpired) return 'orange'

      return {
        [DeviceAuditEventType.Manufactured]: 'green',
        [DeviceAuditEventType.Removed]: 'red',
        [DeviceAuditEventType.Reset]: 'orange',
        [DeviceAuditEventType.Decommissioned]: 'red'
      }[item.type] || 'blue'
    },

    // Returns icon representing the event
    getIcon (item) {
      return {
        [DeviceAuditEventType.Manufactured]: 'star',
        [DeviceAuditEventType.Decommissioned]: 'delete',
        [DeviceAuditEventType.Added]: 'add',
        [DeviceAuditEventType.OwnerChanged]: 'euro',
        [DeviceAuditEventType.Shared]: 'share',
        [DeviceAuditEventType.FirmwareUpdated]: 'memory',
        [DeviceAuditEventType.SettingsChanged]: 'settings',
        [DeviceAuditEventType.Reset]: 'loop',
        [DeviceAuditEventType.PremiumServiceAssigned]: 'euro',
        [DeviceAuditEventType.PremiumServiceActivated]: 'euro',
        [DeviceAuditEventType.PremiumServiceExpired]: 'euro'
      }[item.type] || ''
    },

    formatDate
  },

  watch: {
    // Reload audit when device changes
    device () {
      this.loadAudit()
    }
  },

  async created () {
    this.loadAudit()
  }
}
</script>

<template>
  <div class="audit q-pl-sm">
    <q-timeline color="secondary" dense>
      <q-timeline-entry
        v-for="(item, index) in visibleItems"
        :key="index"
        :subtitle="`${formatDate(item.date)}, ${item.description}`"
        :icon="getIcon(item)"
        :color="getColor(item)"
      >
        <span v-if="item.user">
          {{ item.user.fullName }}
          <span v-if="item.organization"> ({{ item.organization.name }}) </span>
        </span>
        <span v-if="item.user && item.details">
          <br />
        </span>
        <span v-if="item.details">
          {{ item.details }}
        </span>
        <sc-tooltip v-if="item.tooltip" :text="item.tooltip.replace('\n', '<br>')">
        </sc-tooltip>
      </q-timeline-entry>
    </q-timeline>
  </div>
</template>

<style lang="scss" scoped>
.audit {
  a {
    color: #b71c1c;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
