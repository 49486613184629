/**
 * Creates a separated list of elements
 * @return {Array} items List elements
 * @param {String} separator Element separator
 * @param {Function<String, any>} converter Optional function converting items to list elements
 * @returns {String} String containing a separated list of elements
 */
export function createList (items, separator = ',', converter) {
  if (items) {
    return items
      .map(item => converter ? converter(item) : item)
      .map(item => item == null ? '' : item.toString())
      .join(separator)
  }
}

/**
 * Parses the specified separated list
 * @param {String} list Separated list of elements
 * @param {String|Array[String]} separator Element separator or list of alternative separators
 * @param {Function<String, any>} converter Optional function converting list elements to their final format
 * @return {Array} List elements
 */
export function parseList (list, separator = ',', converter) {
  if (list) {
    const items = Array.isArray(separator)
      ? list.split(separator.find(s => list.includes(s)) || separator[0])
      : list.split(separator.toString())
    return items
      .map(item => item.trim())
      .map(item => converter ? converter(item) : item)
  }
}

/**
 * Parses the specified separated list of numbers
 * @param {String} list Separated list of numbers
 * @param {Number} defaultValue Optional value to use if element cannot be converted to a number
 * @param {String} separator Element separator
 * @return {Array} List elements
 */
export function parseNumberList (list, defaultValue, separator = ',') {
  return parseList(
    list,
    separator,
    item => {
      const value = parseFloat(item)
      return isNaN(value) ? defaultValue : value
    })
}

/**
 * Parses the specified separated list of integer numbers
 * @param {String} list Separated list of integer numbers
 * @param {Number} defaultValue Optional value to use if element cannot be converted to an integer
 * @param {String} separator Element separator
 * @return {Array} List elements
 */
export function parseIntList (list, defaultValue, separator = ',') {
  return parseList(
    list,
    separator,
    item => {
      const value = parseInt(item)
      return isNaN(value) ? defaultValue : value
    })
}

/**
 * Retrieves the value at a specified index in a separated list of values
 * @param {String} list Separated list of values
 * @param {Number} index Index of a value to get
 * @param {String} defaultValue Default value to return if item is missing
 * @param {String} separator Element separator
 * @returns {any} List item or default value. If index is invalid, undefined is returned.
 */
export function getListItem (list, index, defaultValue = '', separator = ',') {
  if (list && index >= 0) {
    const items = parseList(list, separator)
    if (index < items.length) {
      const value = items[index]
      return value == null || value === '' ? defaultValue : value
    }
  }
}

/**
 * Retrieves the value at a specified index in a separated list of numbers
 * @param {String} list Separated list of numbers
 * @param {Number} index Index of a value to get
 * @param {Number} defaultValue Default value to return if item is missing
 * @param {String} separator Element separator
 * @returns {any} List value
 */
export function getNumberListItem (list, index, defaultValue, separator = ',') {
  if (list && index >= 0) {
    const items = parseNumberList(list, defaultValue, separator)
    if (index < items.length) {
      const value = items[index]
      return value == null ? defaultValue : value
    }
  }
}

/**
 * Changes the specified value in a separated list of values
 * @param {String} list Separated list of values
 * @param {Number} index Index of a value to set
 * @param {String} value Value to set
 * @param {String} separator Element separator
 * @returns {String} Modified list
 */
export function setListItem (list, index, value, separator = ',') {
  if (list != null && index >= 0) {
    const items = parseList(list, separator) || []
    items[index] = value == null ? '' : value.toString()
    return items.join(separator)
  }
}
