import { safeParseInt } from '@stellacontrol/utilities'
import { Assignable } from '@stellacontrol/model'

/**
 * Plan element padding
 */
export class PlanPadding extends Assignable {
  constructor (data = {}) {
    super(data)
    this.assign(data)
  }

  /**
   * Default padding
   * @type {PlanPadding}
   */
  static get Default () {
    return new PlanPadding()
  }

  /**
   * Padding of 4px
   * @type {PlanPadding}
   */
  static get P4 () {
    return new PlanPadding({ top: 4, right: 4, bottom: 4, left: 4 })
  }

  /**
   * Padding of 5px
   * @type {PlanPadding}
   */
  static get P5 () {
    return new PlanPadding({ top: 5, right: 5, bottom: 5, left: 5 })
  }

  /**
   * Padding of 8px
   * @type {PlanPadding}
   */
  static get P8 () {
    return new PlanPadding({ top: 8, right: 8, bottom: 8, left: 8 })
  }

  /**
   * Padding of 10px
   * @type {PlanPadding}
   */
  static get P10 () {
    return new PlanPadding({ top: 10, right: 10, bottom: 10, left: 10 })
  }

  /**
   * Object defaults
   */
  get defaults () {
    return {
      top: 4,
      right: 4,
      bottom: 4,
      left: 4
    }
  }

  normalize () {
    super.normalize()
    const { defaults } = this
    this.top = safeParseInt(this.top, defaults.top)
    this.right = safeParseInt(this.right, defaults.right)
    this.bottom = safeParseInt(this.bottom, defaults.bottom)
    this.left = safeParseInt(this.left, defaults.left)
  }

  /**
   * Serializes the item to JSON
   * @returns {Object}
   */
  toJSON () {
    const result = { ...this }
    return this.clearDefaults(result)
  }

  /**
   * Top padding, in pixels
   * @type {Number}
   */
  top

  /**
   * Right padding, in pixels
   * @type {Number}
   */
  right

  /**
   * Bottom padding, in pixels
   * @type {Number}
   */
  bottom

  /**
   * Left padding, in pixels
   * @type {Number}
   */
  left

  /**
   * Total horizontal padding
   * @type {Number}
   */
  get horizontal () {
    return this.left + this.right
  }

  /**
   * Total vertical padding
   * @type {Number}
   */
  get vertical () {
    return this.top + this.bottom
  }

  /**
   * Sets padding to the same value on all sides
   * @param {Number} value Padding value
   */
  set (value) {
    this.top = value
    this.right = value
    this.bottom = value
    this.left = value
    this.normalize()
  }

  /**
   * Returns true if style is not defined
   * @type {Boolean}
   */
  get isEmpty () {
    return !(this.top > 0 || this.right > 0 || this.bottom > 0 || this.left > 0)
  }
}
