import { clone } from '@stellacontrol/utilities'
import { Assignable } from '@stellacontrol/model'
import { createPlanItem } from '@stellacontrol/planner'
import { createPlanAction } from '../../actions/execute-action'
import { PlanActions } from '../../actions/plan-action'

/**
 * Record of a plan state on execution of a plan action
 */
export class RecordedPlanAction extends Assignable {
  /**
   * Creates a record of an executed action
   * @param {PlanAction} action Executed action
   * @param {PlanLayout} layout Plan layout
   * @param {PlanFloor} floor Floor impacted by the action
   * @param {Array[PlanItem]} items Items impacted by the action
   * @param {Object} parameters Action parameters
   */
  constructor ({ action, layout, floor, items, parameters } = {}) {
    if (!action) throw new Error('Plan action is required')
    if (!layout) throw new Error('Plan layout is required')

    super()
    this.time = new Date()
    if (typeof action === 'string') {
      action = createPlanAction({ action, layout, floor })
    }
    this.action = action.name
    this.label = action.label
    this.items = this.createItems(items)
    this.parameters = this.createParameters(parameters)
    this.snapshot = this.createSnapshot({
      action,
      layout,
      floor,
      items
    })
  }

  /**
   * Determines whether the action can be stored in the history.
   * Some actions such as selecting item don't change anything
   * so there's no point of saving them in the history
   * @param {PlanAction} action
   * @returns {Boolean}
   */
  static canStore (action) {
    return action &&
      ![
        PlanActions.SelectItems,
        PlanActions.SelectAllItems,
        PlanActions.CopyItems,
        PlanActions.ShowHelp,
        PlanActions.DrawMapScale,
        PlanActions.SelectFloor,
        PlanActions.DownloadFloorImage,
        PlanActions.ShowItemDetails,
        PlanActions.SetBackgroundImage,
        PlanActions.ClearBackgroundImage,
      ].includes(action.name)
  }

  /**
   * Time when action was executed
   * @type {Date}
   */
  time

  /**
   * Executed action
   * @type {PlanActions}
   */
  action

  /**
   * Action label
   * @type {String}
   */
  label

  /**
   * Items impacted by the action
   * @type {Array[{id, floorId}]}
   */
  items

  /**
   * Action parameters
   * @type {Object}
   */
  parameters

  /**
   * Snapshot of the data impacted by the action, such as items or floor.
   * To limit memory use, we only save snapshots for destructive actions
   * such as removing an item, so we can re-create these items on undo
   * @type {Object}
   */
  snapshot

  /**
   * Creates an extract of data about the items passed to the action
   * @param {Array[PlanItem]} items Items passed to the action
   */
  createItems (items) {
    return items?.map(({ id, type, floorId }) => ({ id, type, floorId })) || []
  }

  /**
   * Creates an extract of data about the parameters passed to the action
   * @param {Object} parameters Action parameters
   */
  createParameters (parameters) {
    if (parameters) {
      const result = { ...parameters }
      // Return any deeply nested properties and hierarchies,
      // these should be taken care of by `getSnapshot` if needed.
      // Here we only store simple parameters
      delete result.floor
      delete result.layout
      delete result.renderer
      delete result.items
      delete result.item
      return clone(result)
    }
  }

  /**
   * Determines whether the action is destructive and thus
   * saving it in the history needs creating a snapshot of the destroyed items
   * @param {PlanAction} action
   * @returns {Boolean}
   */
  requiresSnapshot (action) {
    return action &&
      [
        PlanActions.Empty,
        PlanActions.ClearFloor,
        PlanActions.RemoveItems,
        PlanActions.MoveItems,
        PlanActions.RemovePoint,
        PlanActions.RemoveFloor,
        PlanActions.SetItemProperties,
        PlanActions.ClearWalls
      ].includes(action.name)
  }

  /**
   * Creates a deep snapshot of data required to safely store the action in the history
   * @param {PlanAction} action Executed action
   * @param {PlanFloor} floor Floor impacted by the action
   * @param {Array[PlanItem]} items Items impacted by the action
   * @returns {Object} Data snapshot
   */
  createSnapshot ({ action, floor, items }) {
    if (!this.requiresSnapshot(action)) return

    const snapshot = {}

    // Store a snapshot of items involved in the action
    if (items && action.requiresItems) {
      snapshot.items = clone(items)
    }

    // For some actions we need to store extra data
    if ([
      PlanActions.ClearFloor,
      PlanActions.RemoveFloor,
      PlanActions.ClearWalls
    ].includes(action.name) && floor) {
      snapshot.floor = clone(floor)
    }

    return snapshot
  }

  /**
   * Returns a snapshot of the specified item, if present
   * @param {String} id Item identifier
   * @returns {PlanItem}
   */
  getItemSnapshot (id) {
    const data = this.snapshot?.items?.find(i => i.id === id)
    if (data) {
      return createPlanItem(data)
    }
  }

}
