import { stringCompare, numberCompare } from '@stellacontrol/utilities'
import { OrganizationLevel } from './organization-level'

/**
 * Sort order of organizations
 */
export const OrganizationSortOrder = {
  // Simple alphabetical order by name
  Alphabetical: 'alphabetical',
  // Rank order with my organization first, then resellers,
  // regular organizations and finally guest organizations
  Rank: 'rank',
  // Hierarchical order with children listed under their parents
  Hierarchy: 'hierarchy'
}

/**
 * Returns a number representing company rank
 * @param organization Organization to rank
 * @param currentOrganization Currently logged in organization, which if specified, has the highest rank
 */
export function getOrganizationRank (organization, currentOrganization) {
  if (currentOrganization && organization.id === currentOrganization.id) return 100
  if (organization.level === OrganizationLevel.SuperOrganization) return 90
  if (organization.level === OrganizationLevel.ResellerOrganization) return 80
  if (organization.level === OrganizationLevel.RegularOrganization) return 50
  if (organization.level === OrganizationLevel.ShippingCompany) return 40
  return 0
}

/**
 * Sorts the specified organizations
 * @param {Array[Organization|OrganizationHierarchy]}organizations Organizations to sort
 * @param {SortOrder} order Sorting order - alphabetical, rank, hierarchy etc.
 * @param {Organization|OrganizationHierarchy} currentOrganization Organization within whose context the organizations are sorted. Needed when sorting by rank.
 * @param {OrganizationHierarchy} hierarchy Hierarchy of organizations. Needed when sorting by hierarchy.
 */
export function sortOrganizations (organizations = [], order = OrganizationSortOrder.Alphabetical, { currentOrganization, hierarchy, byFullName } = {}) {
  if (order === OrganizationSortOrder.Hierarchy && !hierarchy) return organizations
  if (!currentOrganization) return organizations

  // Sorting by hierarchy
  if (order === OrganizationSortOrder.Hierarchy) {
    if (!hierarchy) return organizations
    const dictionary = {}
    for (const organization of organizations) {
      dictionary[organization.id] = organization
    }
    const hierarchyOrganizations = hierarchy.toList().map(item => {
      const organization = dictionary[item.id]
      return organization
    })
    return hierarchyOrganizations.filter(o => o)
  }

  // Plain sorting
  const compare = {
    // Alphabetical sorting
    [OrganizationSortOrder.Alphabetical]: (a, b) =>
      stringCompare(
        byFullName ? a.fullName : a.name,
        byFullName ? b.fullName : b.name
      ),
    // Rank sorting
    [OrganizationSortOrder.Rank]: (a, b) => {
      let result = -numberCompare(
        getOrganizationRank(a, currentOrganization),
        getOrganizationRank(b, currentOrganization)
      )

      return result === 0
        ? stringCompare(
          byFullName ? a.fullName : a.name,
          byFullName ? b.fullName : b.name
        )
        : result
    }
  }[order]

  if (!compare) {
    throw new Error(`Unknown organization sort order ${order}`)
  }

  const result = ((organizations && Array.isArray(organizations)) ? [...organizations] : []).filter(o => o)
  result.sort((a, b) => compare(a, b))
  return result
}
