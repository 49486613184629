import { state, getters } from './dialog.state'
import { mutations } from './dialog.mutations'
import { actions } from './dialog.actions'

export default {
  state,
  getters,
  mutations,
  actions
}
