import './widgets'
import DeviceDashboard from './device-dashboard.vue'
import { dispatch } from '@stellacontrol/client-utilities'
import { resolve } from './device-dashboard.resolve'

export const Routes = [
  {
    name: 'device-dashboard',
    path: '/device/:serialNumber',
    component: DeviceDashboard,

    async beforeEnter (to, from, next) {
      const { redirectTo } = await resolve({ to, from })
      if (redirectTo) {
        next(redirectTo)
      } else {
        dispatch('storeBuildingsRoute', { route: to })
        next()
      }
    }
  }
]
