<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { getDeviceLabel, DateRange } from '@stellacontrol/model'
import { DeviceAuditEventType } from '@stellacontrol/devices'
import { DialogMixin } from '@stellacontrol/client-utilities'
import { Secure } from '@stellacontrol/security-ui'
import DeviceHistoryPanel from '../../views/device-history/device-history-panel.vue'

const dialog = 'device-history'


export default {
  mixins: [
    DialogMixin,
    Secure
  ],

  components: {
    'sc-device-history': DeviceHistoryPanel
  },

  data () {
    return {
      dialog,
      // Indicates whether dialog finished initializing
      isInitialized: false,
      // Currently viewed device
      device: null,
      // Currently viewed organization or organization of the viewed place/device
      viewedOrganization: null,
      // Currently viewed organization's guardian
      viewedOrganizationGuardian: null,
      // Selected period
      period: DateRange.today(),
      // Parameters to show
      parameters: ['temperature'],
      // Extras to show, such as alerts, firmware updates etc.
      extras: [
        DeviceAuditEventType.Alert,
        DeviceAuditEventType.SettingsChanged,
        DeviceAuditEventType.CommandSent,
        DeviceAuditEventType.FirmwareUpdated
      ],
      // Chart options
      options: {
        plugins: {
          legend: {
            position: 'bottom'
          }
        }
      }
    }
  },

  computed: {
    ...mapState({
      maxDays: state => state.deviceHistoryView.filter.maxDays
    }),

    ...mapGetters([
      'isSmallScreen'
    ])
  },

  methods: {
    ...mapActions([
      'getOrganization',
      'getGuardian',
      'dialogOk',
      'gotoRoute',
      'getUserPreference',
      'storeUserPreferences'
    ]),

    getDeviceLabel,

    // Checks if specified device has premium subscription allowing to see its history
    async isDeviceAllowed (device) {
      if (device) {
        const ownerId = device.ownerId || this.currentOrganization.id
        if (!this.viewedOrganization) {
          this.viewedOrganization = await this.getOrganization({ id: ownerId })
          this.viewedOrganizationGuardian = await this.getGuardian({ principal: this.viewedOrganization })
        }
        const { viewedOrganizationGuardian, currentOrganizationGuardian } = this
        if (viewedOrganizationGuardian && currentOrganizationGuardian) {
          const isDeviceAllowed = currentOrganizationGuardian.requiresPremiumSubscription('history-graph')
            ? viewedOrganizationGuardian.canDeviceUse('history-graph', device.serialNumber, currentOrganizationGuardian)
            : currentOrganizationGuardian.canUse('history-graph')
          return isDeviceAllowed
        }
      }
    },

    // Populates the dialog
    async populate () {
      this.isInitialized = false

      const { device, period, parameters, extras } = this.data
      if (!await this.isDeviceAllowed(device)) {
        this.close()
        return
      }

      this.period = period || await this.getUserPreference({ name: 'device-history-dialog-period', defaultValue: this.period, converter: DateRange.fromString })
      this.parameters = parameters || await this.getUserPreference({ name: 'device-history-dialog-parameters', defaultValue: this.parameters })
      this.extras = extras || await this.getUserPreference({ name: 'device-history-dialog-extras', defaultValue: this.extras })
      this.device = device

      this.$nextTick(async () => {
        if (this.$refs.history) {
          await this.$refs.history.populate()
        }
        this.isInitialized = true
      })
    },

    // Closes the dialog
    async close () {
      await this.dialogOk({ dialog })
      this.$emit('close')
    },

    // Triggered when dialog is shown
    dialogShown () {
      this.populate()
    },

    // Triggered when filter has changed
    filterChanged ({ period, parameters, extras } = {}) {
      if (this.isInitialized && this.$refs.history) {
        this.period = period || this.period
        this.parameters = parameters || this.parameters
        this.extras = extras || this.extras

        this.storeUserPreferences({
          items: [
            { name: 'device-history-dialog-period', value: this.period.toString() },
            { name: 'device-history-dialog-parameters', value: this.parameters },
            { name: 'device-history-dialog-extras', value: this.extras }
          ]
        })
      }
    }
  }
}

</script>

<template>
  <sc-dialog :dialog="dialog" :maximized="isSmallScreen" @dialogShown="dialogShown()">

    <q-form class="form" ref="form">
      <main class="content">

        <q-banner class="banner bg-indigo-6">
          <div class="row items-center">
            <span class="text-white title">
              {{ getDeviceLabel(device) }} History
            </span>
            <q-space></q-space>
            <q-btn label="Close" unelevated class="primary" @click="close()"></q-btn>
          </div>
        </q-banner>

        <section class="inside">
          <sc-device-history ref="history" :device="device" :period="period" :maxDays="maxDays"
            max-height="750px" :parameters="parameters" :extras="extras" :options="options"
            @filter="values => filterChanged(values)">
          </sc-device-history>
        </section>

      </main>
    </q-form>
  </sc-dialog>
</template>

<style lang='scss' scoped>
.form {
  min-width: 1400px;
  max-width: 1400px;
  min-height: 650px;
  max-height: 900px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .banner {
      flex: 0;
    }

    .inside {
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    .title {
      font-size: 18px;
    }

  }
}

/* Layout adjustments for screen below HD resolution */
@media screen and (max-width: 1365px) {
  .q-dialog.fullscreen {
    top: 40px;
  }

  .form {
    width: 100%;
    min-height: 100%;
  }
}
</style>
