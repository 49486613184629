<script>
import { computed } from 'vue'
import { SortOrder } from '@stellacontrol/model'
import { getStore } from '@stellacontrol/client-utilities'

export default {
  props: {
    // Grid column controlled by the menu
    column: {
      type: Object,
      required: true
    },
    // Customized style for the menu button
    buttonStyle: {
      type: Object
    }
  },

  setup ({ column }) {
    const { dispatch, state } = getStore()
    const { name } = column

    // Get grid options
    const options = computed(() => state.inventoryView.gridOptions)
    // Determine columns which can be hidden by the user
    const switchableColumns = computed(() => state.inventoryView.gridColumnsList.filter(c => !c.cannotBeHidden && c.isAllowed))

    // Checks if column is last visible column
    const visibleColumns = computed(() => switchableColumns.value.filter(c => c.isVisible))
    const lastVisibleColumn = computed(() => visibleColumns.value[visibleColumns.value.length - 1])
    const isLastVisible = computed(() => column.isVisible && lastVisibleColumn && lastVisibleColumn.value.name === name)

    // Current sort status
    const isSorting = computed(() => options.value.sortBy === name)
    const isSortingAscending = computed(() => isSorting.value && options.value.sortOrder === SortOrder.Ascending)
    const isSortingDescending = computed(() => isSorting.value && options.value.sortOrder === SortOrder.Descending)

    // Current filter status
    const isColumnFiltered = computed(() => {
      const selection = state.inventoryView.gridColumns[name].selection
      return selection != null && selection !== '' && selection !== 'all'
    })

    // Enables sorting by the current column in ascending order
    async function sortAscending () {
      await dispatch('sortInventory', { sortBy: name, sortOrder: SortOrder.Ascending })
    }

    // Enables sorting by the current column in descending order
    async function sortDescending () {
      await dispatch('sortInventory', { sortBy: name, sortOrder: SortOrder.Descending })
    }

    // Disables sorting by the current column
    async function noSorting () {
      await dispatch('sortInventory', { sortBy: null, sortOrder: SortOrder.None })
    }

    // Hides the current column
    async function hideColumn () {
      await dispatch('toggleInventoryColumn', { name, isVisible: false })
    }

    return {
      sortAscending,
      sortDescending,
      noSorting,
      hideColumn,
      isSorting,
      isSortingAscending,
      isSortingDescending,
      isLastVisible,
      isColumnFiltered
    }
  }
}
</script>

<template>
  <q-btn-dropdown
    flat dense unelevated rounded stack size="12px"
    :dropdown-icon="isSortingDescending ? 'arrow_drop_down' : (isSortingAscending ? 'arrow_drop_up' : 'arrow_right')"
    class="column-menu-dropdown"
    menu-self="top right"
    padding="0"
    v-if="!isColumnFiltered"
    :class="{ 'last-visible': isLastVisible }"
    :color="isSorting ? 'blue-8' : 'grey-6'"
    :ripple="false"
    :style="buttonStyle">

    <div class="row justify-center q-ma-sm">
      <span class="text-indigo-8">{{ column.label }}</span>
    </div>

    <q-list dense>
      <q-item clickable v-close-popup @click="sortAscending()">
        <q-item-section side>
          <q-icon name="arrow_upward" size="xs" :color="isSortingAscending ? 'indigo-9' : 'grey-5'"/>
        </q-item-section>
        <q-item-section>
          <q-item-label caption class="text-grey-10">Sort Ascending</q-item-label>
        </q-item-section>
      </q-item>

      <q-item clickable v-close-popup @click="sortDescending()">
        <q-item-section side>
          <q-icon name="arrow_downward" size="xs" :color="isSortingDescending ? 'indigo-9' : 'grey-5'"/>
        </q-item-section>
        <q-item-section>
          <q-item-label caption class="text-grey-10">Sort Descending</q-item-label>
        </q-item-section>
      </q-item>

      <q-item clickable v-close-popup @click="hideColumn()">
        <q-item-section side>
          <q-icon name="close" size="xs"/>
        </q-item-section>
        <q-item-section>
          <q-item-label caption class="text-grey-10">Hide Column</q-item-label>
        </q-item-section>
      </q-item>
    </q-list>
  </q-btn-dropdown>
</template>

<style scoped lang="scss">
.column-menu-dropdown {
  position: absolute;
  right: 2px;
  top: 2px;

  &.last-visible {
    right: 26px;
  }

  .q-btn-dropdown__arrow {
    margin-left: 0 !important;
  }
}
</style>
