import { state, getters } from './service-management.state'
import { mutations } from './service-management.mutations'
import { actions } from './service-management.actions'
import premiumServicesView from './premium-services-view'
import premiumSubscribersView from './premium-subscribers-view'
import auditView from './premium-services-audit-view'

export default {
  state,
  getters,
  mutations,
  actions,
  modules: {
    premiumServicesView,
    premiumSubscribersView,
    auditView
  }
}
