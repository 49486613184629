import { PlanRectangle } from './plan-rectangle'
import { PlanItemType } from './plan-item'

/**
 * Legend
 */
export class PlanLegend extends PlanRectangle {
  constructor (data = {}) {
    super(data)
    this.assign(data)
  }

  /**
   * Item type
   * @type {PlanItemType}
   */
  static get type () {
    return PlanItemType.Legend
  }

  /**
   * Creates a legend
   * @param {Object} data Item data
   * @returns {PlanLegend}
   */
  static createLegend (data = {}) {
    return new PlanLegend({
      ...data
    })
  }

  /**
   * Serializes the plan item to JSON
   * @returns {Object}
   */
  toJSON () {
    const result = super.toJSON()

    // Remove runtime data
    delete result.__legendType

    // Remove fixed styles
    delete result.backgroundStyle
    delete result.lineStyle
    delete result.textStyle

    return result
  }
}
