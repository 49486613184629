import { Log, isBrowser, isNodeJS } from '@stellacontrol/utilities'

/**
 * Debugged API items
 */
export const APIDebugItem = {
  // Request
  Request: 'request',
  // Response
  Response: 'response',
  // Error
  Error: 'error',
  // Other
  Other: 'other'
}

/**
 * API debug levels
 */
export const APIDebugLevel = {
  // Request and response logging is OFF
  OFF: 'off',
  // Request and response logging is ON, data is ignored
  ON: 'on',
  // Request and response logging is ON, headers are included
  HEADERS: 'headers',
  // Request and response logging is ON, data is included
  DATA: 'data',
  // Request and response logging is ON, data is logged as well
  FULL: 'full'
}

/**
 * Log file formats
 */
const APIDebugFileFormat = {
  Text: 'text',
  CSV: 'csv',
  JSON: 'json'
}

/**
 * API debugger
 */
export class APIDebuggerService {
  /**
   * Service name
   * @type {String}
   */
  service = 'debugger'

  /**
   * API loggers
   * @type {Object}
   */
  loggers = {
    /**
     * Logging enabled/disabled globally
     * @type {Boolean}
     */
    enabled: true,
    /**
     * Services allowed to log API requests and responses
     * @type {Array[String]}
     */
    services: [],
    /**
     * Request logging
     * @type {APIRequestLogger}
     */
    request: new APIRequestLogger(),
    /**
     * Response logging
     * @type {APIResponseLogger}
     */
    response: new APIResponseLogger(),
    /**
     * Error logging
     * @type {APIErrorLogger}
     */
    error: new APIErrorLogger(),
    /**
     * Other items logging
     * @type {APIOtherLogger}
     */
    other: new APIOtherLogger()
  }

  /**
   * Checks whether we're currently debugging anything
   * @type {Boolean}
   */
  get isEnabled () {
    const { loggers: { enabled, request, response, error } } = this
    return enabled && (request.enabled || response.enabled || error.enabled)
  }

  /**
   * Checks whether the specified service is allowed to log.
   * @param {String} service Service name
   * @type {Boolean}
   */
  isServiceAllowed (service) {
    const { loggers: { services = [] } } = this
    if (services.length === 0) return true
    return services.includes(service)
  }

  /**
   * Checks whether the specified API client is allowed to log.
   * We check such things as service name etc.
   * @param {APIClient} apiClient API client to check
   * @type {Boolean}
   */
  isClientAllowed (client) {
    return client === this || this.isServiceAllowed(client?.service)
  }

  /**
   * Returns description of API debugger status
   */
  toString () {
    const { loggers: { request, response, error } } = this
    const lines = []

    if (request.enabled) {
      lines.push('Requests')
      lines.push(`[${[
        'INFO',
        request.headers ? 'HEADERS' : '',
        request.data ? 'DATA' : ''
      ].filter(f => f).join(' ')}]`)
    }

    if (response.enabled) {
      lines.push('Responses')
      lines.push(`[${[
        'INFO',
        response.headers ? 'HEADERS' : '',
        response.data ? 'DATA' : ''
      ].filter(f => f).join(' ')}]`)
    }

    if (error.enabled) {
      lines.push('Errors')
      lines.push(`[${[
        'INFO',
        error.headers ? 'HEADERS' : '',
        error.data ? 'DATA' : ''
      ].filter(f => f).join(' ')}]`)
    }

    if (lines.length > 0) {
      return lines.join(' ')
    } else {
      return 'OFF'
    }
  }

  /**
   * Sets options for debugging of API calls
   * @param {Boolean|APIDebugLevel|APILogger} options Debug level or debug options
   * @description
   * If `false` or `off`, all debugging is stopped
   * If `true` or `on`, debugging of request and response details is started.
   * If `full`, debugging of request and response details and data is started.
   * For other permutations, specify the detailed options.
   */
  initialize (options) {
    if (typeof options === 'object') {
      this.loggers.enabled = options.enabled == null ? this.loggers.enabled : options.enabled
      this.loggers.services = options.services == null ? this.loggers.services : options.services

      for (const [name, logger] of Object.entries(this.loggers)) {
        if (logger instanceof APILogger) {
          logger.initialize(options[name])
        }
      }

    } else {
      if (!options || options === APIDebugLevel.OFF) {
        this.loggers.enabled = false
      } else if (options === true || options === APIDebugLevel.ON || options === APIDebugLevel.FULL) {
        this.loggers.enabled = true
      }

      for (const logger of Object.values(this.loggers)) {
        if (logger instanceof APILogger) {
          logger.initialize(options)
        }
      }
    }

    return this.loggers
  }

  /**
   * Logs the specified item related to API call
   * @param {APIClient} client API client executing the request
   * @param {APIDebugItem} type Item type
   * @param {Object} item Item details
   */
  async debug (client, type, item) {
    try {
      if (!this.isEnabled) return

      if (!item) return
      if (!client) throw new Error('API client is required')
      if (!type) throw new Error('API item type is required')

      if (!this.isClientAllowed(client)) return

      const logger = Object.values(this.loggers).find(o => o.type === type)
      if (!logger) throw new Error(`Logger for [${type}] not found`)
      if (!logger.canLog(item)) return

      // Extract all details to log
      const details = logger.getMessage(client, item)
      const { message, data, dataString, headers, params } = details

      // Collect values to log in file, including custom values
      const values = logger.values
        ? logger.values(this, item, details.values)
        : details.values

      // In browser, take advantage of console display of data
      // and dump data in native form
      if (isBrowser) {
        if (logger.console) {
          if (logger.data && (data || params || headers)) {
            Log.debug(message, { data, params, headers })
          } else {
            Log.debug(message)
          }
        }
      }

      // In server, write data under log entry
      if (isNodeJS) {
        if (logger.console) {
          Log.debug(message)
          if (logger.headers && headers) {
            Log.debug(`HEADERS:\n  ${dictionaryToString(headers, '\n  ', ': ')}`)
          }
          if (logger.data && dataString) {
            Log.debug(`  DATA:\n    ${dataString}`)
          }
        }

        if (logger.file) {
          try {
            let lines = []

            if (logger.fileFormat === APIDebugFileFormat.CSV) {
              lines = [values.join(';')]
              if (logger.headers && headers) {
                lines.push([
                  'HEADERS',
                  ...Object.entries(headers || {}).flatMap(entry => [...entry])
                ].join(';'))
              }
              if (logger.data && dataString) {
                lines.push([
                  'DATA',
                  dataString
                ].join(';'))
              }
              lines.push('')
            }

            if (logger.fileFormat === APIDebugFileFormat.Text) {
              lines = [message]
              if (logger.headers) {
                lines.push('HEADERS:')
                lines.push(dictionaryToString(headers, '\n', ': '))
              }

              if (logger.data && dataString) {
                lines.push('DATA:')
                lines.push(dataString)
              }
              lines.push('\n')
            }

            const fs = await import('node:fs')
            await fs.promises.appendFile(logger.file, lines.join('\n'))

          } catch (error) {
            Log.warn(`Logging API ${type} to file ${logger.file} failed`, error.message)
          }
        }
      }

    } catch (error) {
      Log.warn(`Logging API ${type} failed`, error.toString())
      throw error
    }
  }

  /**
   * Logs custom entry related to ongoing API call
   * @param {Object} item Item to log. It can be a string, a data object or a dictionary `{ message, data, values }`
   */
  async log (item) {
    return this.debug(this, APIDebugItem.Other, item)
  }


  /**
   * Logs the specified request as per instructions in {@link loggers}
   * @param {APIClient} client API client executing the request
   * @param {Object} request Request details
   */
  request (client, request) {
    return this.debug(client, APIDebugItem.Request, request)
  }

  /**
   * Logs the specified response as per instructions in {@link loggers}
   * @param {APIClient} client API client receiving the response
   * @param {Object} response Response details
   */
  response (client, response) {
    return this.debug(client, APIDebugItem.Response, response)
  }

  /**
   * Logs the specified error as per instructions in {@link loggers}
   * @param {APIClient} client API client receiving the response
   * @param {Object} response Response details
   */
  error (client, error) {
    return this.debug(client, APIDebugItem.Error, error)
  }
}

/**
 * Component for preparing data to log for various API-related events
 */
class APILogger {
  /**
   * Debugged item to which this logger apply
   * @type {APIDebugItem}
   */
  get type () {
    throw new Error('Not implemented')
  }

  /**
   * Basic logging of request/response/error details is enabled
   * @type {Boolean}
   */
  enabled = false

  /**
   * Log HTTP headers
   * @type {Boolean}
   */
  headers = false

  /**
   * Log data payloads
   * @type {Boolean}
   */
  data = false

  /**
   * Log debug statements to console
   * @type {Boolean}
   */
  console = true

  /**
   * Log debug statements to a specified file (NodeJS only)
   * @type {String}
   */
  file = null

  /**
   * Output file format: `pretty` text or raw `csv`
   * @type {APIDebugFileFormat}
   */
  fileFormat = APIDebugFileFormat.Text

  /**
   * Extractor for additional values to be logged when writing to files.
   * Function taking request details and default values on entry,
   * returning array of values which should be logged.
   * @type {Function<Object, Array, Array}
   */
  values = null

  /**
   * Request filter for including only selected requests in the log.
   * Function taking request details on entry and returning true for requests which should be logged.
   * @type {Function<Object, Boolean>}
   */
  filter = null

  /**
   * Enables logging by this logger
   */
  enable () {
    this.enabled = true
  }

  /**
   * Disables all logging by this logger
   */
  disable () {
    this.enabled = false
  }

  /**
   * Enables logging by this logger
   * @param {Boolean|APIDebugLevel|APILogger} options Logging options
   */
  initialize (options) {
    if (!options || options === APIDebugLevel.OFF) {
      this.enabled = false

    } else if (options === true || options === APIDebugLevel.ON) {
      this.enabled = true
      this.headers = false
      this.data = false

    } else if (options === APIDebugLevel.FULL) {
      this.enabled = true
      this.headers = true
      this.data = true

    } else if (typeof options === 'object') {
      this.enabled = options.enabled == null ? this.enabled : options.enabled
      this.headers = options.headers == null ? this.headers : options.headers
      this.data = options.data == null ? this.data : options.data
      this.console = options.console == null ? this.console : options.console
      this.file = options.file == null ? this.file : options.file
      this.fileFormat = options.fileFormat == null ? this.fileFormat : options.fileFormat
      this.filter = options.filter == null ? this.filter : options.filter
      this.values = options.values == null ? this.values : options.values
    }
  }

  /**
   * Enables/disables filtering of API calls data to which by this logger applies
   * @param {Function<Object, Boolean>} predicate Predicate to check on request/response/error details.
   * If not specified, filtering will be disabled.
   */
  setFilter (predicate) {
    if (predicate == null || typeof predicate === 'function') {
      this.filter = predicate
    } else {
      this.filter = null
    }
  }

  /**
   * Checks whether the specified request/response/error can be logged by this logger
   * @param {Object} item Item to log
   * @returns {Boolean}
   */
  canLog (item) {
    if (item == null) return false
    if (!this.enabled) return false
    return this.filter
      ? this.filter(item)
      : true
  }

  /**
   * Extract and returns all details about the logged item
   * @param {Dictionary<String, String>} params
   * @param {Dictionary<String, String>} headers
   * @param {Object} data
   * @returns {Object}
   */
  extract ({ params, headers, data } = {}) {
    const result = {}

    result.params = params
    result.headers = headers
    result.data = data

    result.paramsString = params && Object.keys(params).length > 0
      ? dictionaryToString(params, '&', '=')
      : ''
    result.headersString = headers
      ? dictionaryToString(headers, '\n', ':')
      : ''
    result.dataString = data
      ? JSON.stringify(data)
      : ''

    result.paramsLength = params ? result.paramsString.length.toString() : ''
    result.headersLength = headers ? result.headersString.length.toString() : ''
    result.dataLength = data ? result.dataString.length.toString() : ''
    result.contentLength = headers && headers['content-length'] ? parseInt(headers['content-length']).toString() : ''

    return result
  }

  /**
   * Creates message and data fields to log from the specified request/response/error
   * @param {Object} client Client sending the API request
   * @param {Object} item Request/response/error
   * @returns {Object}
   */
  getMessage (client, item) {
    if (!client) throw new Error('Client not specified')
    if (!item) throw new Error('Item to log not specified')

    return {
      type: this.type,
      values: [
        client.service,
        this.type,
        ...getTimestamp()
      ]
    }
  }
}

/**
 * API Request logger
 */
class APIRequestLogger extends APILogger {
  /**
   * Debugged item to which this logger applies
   * @type {APIDebugItem}
   */
  get type () {
    return APIDebugItem.Request
  }

  /**
   * Creates message and data fields to log for the specified request
   * @param {Object} client Client sending the API request
   * @param {Object} request Request details
   * @returns {Object}
   */
  getMessage (client, request) {
    const { type, values } = super.getMessage(client, request)
    const { method, url, params, headers, data } = request
    const {
      paramsString,
      headersString,
      dataString,
      dataLengthString,
      paramsLength,
      headersLength,
      dataLength
    } = this.extract({ params, headers, data })

    const message = `> ${method.toUpperCase()} ${url}${paramsString} >${dataLengthString ? ` ${dataLengthString}b >` : ''}`

    return {
      type,
      message,
      values: [
        ...values,
        paramsLength || '0',
        headersLength || '0',
        dataLength || '0',
        method.toUpperCase(),
        url,
        dictionaryToString(params, ';', ';')
      ],
      params,
      headers,
      data,
      paramsString,
      headersString,
      dataString
    }
  }
}

/**
 * API Response logger
 */
class APIResponseLogger extends APILogger {
  /**
   * Debugged item to which this logger applies
   * @type {APIDebugItem}
   */
  get type () {
    return APIDebugItem.Response
  }

  /**
   * Checks whether the specified response can be logged
   * under the current options
   * @param {Object} response API response to log
   * @returns {Boolean}
   */
  canLog (response) {
    if (!super.canLog(response)) return false
    if (!response.config) return false
    return true
  }

  /**
   * Creates message and data fields to log for the specified response
   * @param {Object} client Client sending the API response
   * @param {Object} response Response details
   * @returns {Object}
   */
  getMessage (client, response) {
    const { type, values } = super.getMessage(client, response)
    const { method, url, params } = response.config
    const { headers, data } = response
    const {
      paramsString,
      headersString,
      dataString,
      dataLengthString,
      paramsLength,
      headersLength,
      dataLength
    } = this.extract({ params, headers, data })

    const message = `< ${method.toUpperCase()} ${url}${paramsString}${dataLengthString ? ` < ${dataLengthString}b` : ''}`

    return {
      type,
      message,
      values: [
        ...values,
        paramsLength,
        headersLength,
        dataLength,
        method.toUpperCase(),
        url,
        dictionaryToString(params, ';', ';')
      ],
      params,
      headers,
      data,
      paramsString,
      headersString,
      dataString
    }
  }
}

/**
 * API Error logger
 */
class APIErrorLogger extends APILogger {
  /**
   * Debugged item to which this logger applies
   * @type {APIDebugItem}
   */
  get type () {
    return APIDebugItem.Error
  }

  /**
   * Checks whether the specified error can be logged
   * under the current options
   * @param {Object} error API error to log
   * @returns {Boolean}
   */
  canLog (error) {
    if (!super.canLog(error)) return false
    if (!error.config) return false
    if (!error.response) return false
    return true
  }

  /**
   * Creates message and data fields to log for the specified error
   * @param {Object} client Client sending the API error
   * @param {Object} error Error details
   * @returns {Object}
   */
  getMessage (client, error) {
    const { type, values } = super.getMessage(client, error)
    const { method, url, params } = error.config
    const { statusText, status, headers, data } = error.response
    const {
      paramsString,
      headersString,
      dataString
    } = this.extract({ params, headers, data })

    const errorMessage = data ? [typeof data.error === 'boolean' ? null : data.error, data.message].filter(e => e).join(': ') : statusText
    const bugReportId = data ? data.bugReportId : undefined
    const message = `! ${method.toUpperCase()} ${url}${paramsString} < HTTP ${status} ${errorMessage} ${bugReportId ? `[Bug# ${bugReportId}]` : ''}`

    return {
      type,
      message,
      values: [
        ...values,
        status,
        statusText,
        errorMessage,
        method.toUpperCase(),
        url,
        dictionaryToString(params, ';', ';')
      ],
      params,
      headers,
      data,
      paramsString,
      headersString,
      dataString
    }
  }
}

/**
 * API other logger
 */
class APIOtherLogger extends APILogger {
  /**
   * Debugged item to which this logger applies
   * @type {APIDebugItem}
   */
  get type () {
    return APIDebugItem.Other
  }

  /**
   * Checks whether the specified item can be logged
   * under the current options
   * @param {Object} item Iten to log
   * @returns {Boolean}
   */
  canLog (item) {
    if (!super.canLog(item)) return false
    return true
  }

  /**
   * Creates message and data fields to log for the specified item
   * @param {Object} client Client performing the API calls
   * @param {Object} item Item to log. It can be a string, a data object or a dictionary `{ message, data, values }`
   * @returns {Object}
   */
  getMessage (client, item) {
    const { type, values } = super.getMessage(client, item)

    const message = item.message == null
      ? JSON.stringify(item, null, 2)
      : item.message

    const data = item.data == null
      ? item
      : item.data

    return {
      type,
      message,
      data,
      values: [
        ...values,
        ...item.values == null ? [JSON.stringify(item, null, 0)] : item.values
      ]
    }
  }
}

/**
 * Prints out dictionary as string, used for debugging
 * @param {Object} dictionary Dictionary to print
 * @param {String} itemSeparator Separator for dictionary items
 * @param {String} valueSeparator Separator for item name and value
 * @returns {String}
 */
function dictionaryToString (dictionary, itemSeparator = ', ', valueSeparator = '=') {
  const items = Object.entries(dictionary || {})
  if (items.length > 0) {
    return items
      .map(([key, value]) => `${key}${valueSeparator}${value == null ? 'null' : value.toString()}`)
      .join(itemSeparator)
  } else {
    return ''
  }
}

/**
 * Returns current timestamp fields (date, time)
 * @returns {Array[String]}
 */
function getTimestamp () {
  const timestamp = (new Date()).toISOString()
  return [
    timestamp.substring(0, 10),
    timestamp.substring(11, 19)
  ]
}

/**
 * Global instance of API Debugger
 */
export const APIDebugger = new APIDebuggerService()

// Attach API debugger to window object, so it can be used interactively in the browser console.
if (isBrowser) {
  window.SCAPIDebugger = APIDebugger
}
