import { Device, DeviceLink, DeviceLinkType, Note, NoteCategory } from '@stellacontrol/model'

/**
 * Returns a list of device entities with the specified serial numbers,
 * initialized with the specified device data
 * @param serialNumbers Serial numbers
 * @param data Data for the created devices
 * @param owner Device owner
 */
export function populateDevices (serialNumbers = [], data = {}, owner = {}) {
  const { type, model, bands, portCount, manufacturedAt, soldAt, notes, simulatedDeviceProfile } = data
  const { id: ownerId } = owner

  if (type && model && bands && portCount && manufacturedAt && soldAt, ownerId) {
    return serialNumbers.map(serialNumber => {
      const device = new Device({
        serialNumber,
        type,
        model,
        bands,
        portCount,
        manufacturedAt,
        simulatedDeviceProfile
      })

      // Create a link with owner
      device.links = [
        new DeviceLink({
          createdAt: soldAt,
          type: DeviceLinkType.Owner,
          principalId: ownerId,
          principal: owner
        })
      ]

      // Pass on notes
      if (notes && notes.trim()) {
        device.notes = [new Note({
          category: NoteCategory.Device,
          text: notes
        })]
      }

      return device
    })
  } else {
    return []
  }
}
