<script>
import { mapActions } from 'vuex'
import { DialogMixin } from '@stellacontrol/client-utilities'
import { Secure } from '@stellacontrol/security-ui'

const dialog = 'attachment-notes'

export default {
  mixins: [
    Secure,
    DialogMixin
  ],

  data () {
    return {
      dialog,
      // File attachment whose notes are being edited
      attachment: null,
      // Indicates that we're editing a first note of an attachment
      isFirst: false
    }
  },

  methods: {
    ...mapActions([
      'dialogOk',
      'saveAttachmentNote',
      'removeAttachmentNote'
    ]),

    // Called when dialog is shown
    dialogShown () {
      this.attachment = this.data.attachment
      this.isFirst = !this.attachment.hasNotes
    },

    // Closes the dialog
    async close () {
      this.dialogOk({ dialog })
    },

    // Saves a note
    async updateNote ({ note }) {
      const { attachment, isFirst } = this
      const { notes } = await this.saveAttachmentNote({ attachment, note })
      if (isFirst) {
        this.close()
      } else {
        this.attachment.notes = notes
      }
    },

    // Removes a note
    async removeNote ({ note }) {
      const { attachment } = this
      const { notes } = await this.removeAttachmentNote({ attachment, note })
      if (notes.length > 0) {
        this.attachment.notes = notes
      } else {
        this.close()
      }
    }
  }
}
</script>

<template>
  <sc-dialog dialog="attachment-notes" @dialogShown="dialogShown()">
    <q-form ref="form" class="form" :class="{ empty: !attachment.hasNotes }" v-if="attachment">
      <q-banner class="bg-indigo-6">
        <div class="row items-center">
          <span class="text-white title">
            {{ attachment.dataTypeLabel }} {{ attachment.name }}
          </span>
          <q-space>
          </q-space>
          <q-btn flat dense label="Close" @click="close()" class="primary">
          </q-btn>
        </div>
      </q-banner>
      <div class="q-pa-sm">
        <sc-notes :notes="attachment.notes" :entity="attachment" :creator="currentUser"
          category="attachment" :edit-mode="isSuperAdministrator ? 'all' : 'none'"
          :create-new-note="!attachment.hasNotes" @update="updateNote" @remove="removeNote">

          <template v-slot:add-button="slotProps">
            <q-btn icon="add" unelevated dense label="Add note" class="q-mr-sm"
              style="position: absolute; bottom: 8px; right: 4px;" @click.stop="slotProps.addNote()">
            </q-btn>
          </template>

        </sc-notes>
      </div>
    </q-form>
  </sc-dialog>
</template>

<style scoped lang="scss">
.form {
  width: 550px;
  min-height: 600px;
  max-height: 90vw;

  &.empty {
    min-height: 280px;
  }
}
</style>