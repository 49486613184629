<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { countString } from '@stellacontrol/utilities'
import { ListAction } from '@stellacontrol/client-utilities'
import { DeviceFirmwareGroupBy } from '@stellacontrol/model'
import { Secure } from '@stellacontrol/security-ui'
import BatchEditDialog from '../batch-edit-firmwares.vue'

export default {
  mixins: [
    Secure
  ],

  components: {
    'sc-batch-edit-firmwares': BatchEditDialog
  },

  props: {
    // If true, also the old obsolete firmwares are shown
    showObsoleteFirmwares: {
      type: Boolean
    }
  },

  data () {
    return {
    }
  },

  computed: {
    ...mapState({
      // Firmwares available to currently logged in organization
      firmwares: state => state.deviceTransmission.firmwares,
      // Firmwares grouping mode
      groupBy: state => state.deviceTransmission.groupFirmwaresBy,
      // Expanded/collapsed status of groups
      expandedGroups: state => state.deviceTransmission.expandedFirmwareGroups,
      // List columns
      firmwareColumns: state => state.deviceTransmission.firmwareColumns
    }),

    ...mapGetters([
      'organizations',
      'firmwaresByVersion',
      'firmwaresByDeviceModel'
    ]),

    // Firmwares, grouped by version or model.
    // We only show active firmwares to regular customers!
    items () {
      let items
      switch (this.groupBy) {
        case DeviceFirmwareGroupBy.Version:
          items = this.firmwaresByVersion
          break
        case DeviceFirmwareGroupBy.DeviceModel:
          items = this.firmwaresByDeviceModel
          break
        default:
          items = this.firmwares
          break
      }
      return items?.filter(item => !item.isObsolete || this.showObsoleteFirmwares)
    },

    // Determines whether specified firmware group is currently expanded
    isGroupExpanded () {
      return group => Boolean(this.expandedGroups[group.id])
    },

    // Determines whether specified firmware is currently visible under expanded group
    isFirmwareVisible () {
      return firmware => firmware &&
        !firmware.isGroup &&
        (!firmware.parentId || Boolean(this.expandedGroups[firmware.parentId]))
    },

    // Returns true if there any firmwares in the repository
    hasFirmwares () {
      return (this.items || []).length > 0
    },

    // Returns true if current user is allowed to manage firmware repository
    canEditFirmware () {
      return this.canUse('can-manage-firmware')
    },

    // Organizations with granted access to the specified firmware
    grantedOrganizations () {
      return firmware => this.organizations.filter(o => firmware.isOrganizationGranted(o))
    },

    // Firmware tooltip text
    firmwareTooltip () {
      return firmware => {
        const lines = [
          `Firmware v.${firmware.versionString}`,
          firmware.name,
          firmware.models?.length > 0 ? `Models: ${firmware.modelsLongString}` : '',
          ...(firmware.description || '').split('\n')
        ]
        return lines
          .filter(line => line)
          .join('<br>')
      }
    },

    // Firmware sharing text
    sharedWithTooltip () {
      return firmware => {
        const { canEditFirmware, grantedOrganizations } = this
        const organizations = grantedOrganizations(firmware)
        if (canEditFirmware && firmware.hasOrganizations) {
          return [
            `${firmware.label} is available to:`,
            '',
            ...organizations.map(o => `• ${o.fullName}`)
          ].join('<br>')
        }
      }
    },

    // Returns firmwares within the group
    getFirmwareGroup () {
      return group => this.items.filter(i => i.parentId === group.id)
    },

    // Checks whether all firmwares within the group are obsolete
    isObsoleteGroup () {
      return group => this.getFirmwareGroup(group).every(firmware => firmware.isObsolete)
    },

    // Checks whether the firmware group is empty
    isGroupEmpty () {
      return group => this.getFirmwareGroup(group).length === 0
    },

    // Determine column visibility
    isColumnVisible () {
      return name => {
        const adminColumns = [
          'isShared',
          'fileName',
          'actions'
        ]
        if (adminColumns.includes(name)) {
          return this.canEditFirmware
        }
        return true
      }
    },

    // Number of visible columns
    visibleColumnsCount () {
      return this.firmwareColumns.filter(({ name }) => this.isColumnVisible(name)).length
    },

    // CSS class for the firmware group
    groupClass () {
      return group => ({
        group: true,
        obsolete: this.isObsoleteGroup(group),
        expanded: this.isGroupExpanded(group)
      })
    },

    // CSS class for the firmware
    firmwareClass () {
      return firmware => ({
        firmware: true,
        obsolete: firmware.isObsolete
      })
    },

    // Firmware actions
    firmwareActions () {
      if (this.canEditFirmware) {
        return [
          {
            ...ListAction.Edit
          },
          {
            ...ListAction.Delete
          }
        ]
      }
    },

    // Firmware group actions
    groupActions () {
      return group => {
        if (!this.canEditFirmware) return
        const firmwares = this.getFirmwareGroup(group)
        const canObsolete = firmwares.some(f => f.isActive)
        const canActivate = firmwares.some(f => f.isObsolete)
        return [
          ListAction.create({
            name: 'edit',
            label: 'Edit batch',
            icon: 'edit',
            color: 'green-7'
          }),
          ListAction.create({
            name: 'activate',
            label: 'Mark as active',
            icon: 'memory',
            color: 'green-7',
            isVisible: canActivate
          }),
          ListAction.create({
            name: 'obsolete',
            label: 'Mark as obsolete',
            icon: 'memory',
            color: 'gray-7',
            isVisible: canObsolete
          }),
          {
            ...ListAction.Delete,
            label: 'Delete firmwares'
          }
        ]
      }
    }
  },

  methods: {
    ...mapActions([
      'editDeviceFirmware',
      'removeDeviceFirmware',
      'removeDeviceFirmwares',
      'setDeviceFirmwaresStatus',
      'toggleFirmwareGroup',
      'toggleFirmwareGroup',
      'editFirmwares'
    ]),

    executeFirmwareAction (firmware, action) {
      switch (action.name) {
        case 'edit':
          return this.editDeviceFirmware({ firmware })
        case 'delete':
          return this.removeDeviceFirmware({ firmware, confirm: true })
        default:
          throw new Error(`Unhandled action ${action.name}`)
      }
    },

    executeGroupAction (group, action) {
      const firmwares = this.getFirmwareGroup(group)
      switch (action.name) {
        case 'edit':
          return this.editFirmwares({
            firmwares,
            title: `Edit ${countString(firmwares, 'firmware')} ${group.version ? 'v.' : 'for device model '} ${group.label}`
          })
        case 'unshare':
          return this.unshareFirmwares({
            firmwares,
            title: `Revoke access to ${countString(firmwares, 'firmware')} ${group.version ? 'v.' : 'for device model '} ${group.label} from:`
          })
        case 'obsolete':
          return this.setDeviceFirmwaresStatus({ firmwares, isObsolete: true, confirm: true })
        case 'activate':
          return this.setDeviceFirmwaresStatus({ firmwares, isObsolete: false, confirm: true })
        case 'delete':
          return this.removeDeviceFirmwares({ firmwares, confirm: true })
        default:
          throw new Error(`Unhandled action ${action.name}`)
      }
    },

    // Filtering function for firmwares, only taking into consideration version number and name
    filterItems (item, filter) {
      filter = (filter || '').toString().trim()
      if (!filter) return true

      const parts = [
        item.version.toFullString() || ''
      ]
      if (item.parentId) {
        parts.push(item.name || '')
      }
      if (item.file) {
        parts.push(item.file.name)
      }
      return parts.some(part => part.toLowerCase().includes(filter))
    }
  }
}
</script>

<template>
  <sc-list class="firmwares" v-if="hasFirmwares" name="firmwares" :columns="firmwareColumns"
    :items="items" row-key="id" hide-pagination :filter-method="filterItems">

    <template v-slot:header="props">
      <q-tr :props="props" style="display:none">
        <q-th :colspan="visibleColumnsCount">
        </q-th>
      </q-tr>
    </template>

    <template v-slot:body="props">
      <q-tr v-if="props.row.isGroup && !isGroupEmpty(props.row)" :props="props"
        :class="groupClass(props.row)" @click="toggleFirmwareGroup({ group: props.row })">
        <q-th class="version" :colspan="isGroupExpanded(props.row) ? 1 : visibleColumnsCount - 1">
          <div class="row items-center no-wrap text-subtitle2">
            <q-icon name="memory" :color="isObsoleteGroup(props.row) ? 'grey-6' : 'green-7'"
              size="24px">
            </q-icon>
            <span class="q-ml-sm">
              {{ props.row.label }}
            </span>
            <span class="q-ml-sm text-weight-regular">
              ({{ count(
    getFirmwareGroup(props.row),
    props.row.isObsolete ? 'obsolete firmware' : 'firmware')
              }})
            </span>
            <q-icon size="22px" class="q-ml-sm" color="indigo-5" style="margin-top: 2px;"
              :name="isGroupExpanded(props.row) ? 'expand_more' : 'chevron_right'" />
          </div>
        </q-th>
        <q-th v-if="isGroupExpanded(props.row) && isColumnVisible('name')">
          Name
        </q-th>
        <q-th v-if="isGroupExpanded(props.row) && isColumnVisible('created')">
          Uploaded
        </q-th>
        <q-th v-if="isGroupExpanded(props.row) && isColumnVisible('isShared')">
          Shared
        </q-th>
        <q-th v-if="isGroupExpanded(props.row) && isColumnVisible('fileName')">
          File Name
        </q-th>
        <q-th v-if="isGroupExpanded(props.row) && isColumnVisible('fileSize')">
          File Size
        </q-th>
        <q-th v-if="isGroupExpanded(props.row) && isColumnVisible('fileDate')">
          File Date
        </q-th>
        <q-th class="actions" v-if="isColumnVisible('actions')">
          <sc-action-dropdown :data="props.row" :actions="groupActions(props.row)"
            @action="action => executeGroupAction(props.row, action)">
          </sc-action-dropdown>
        </q-th>
      </q-tr>

      <q-tr v-else-if="isFirmwareVisible(props.row)" :props="props"
        :class="firmwareClass(props.row)">
        <q-td :style="{ width: '120px' }">
          <div class="q-ml-xl">
            <router-link v-if="canEditFirmware" class="item-link"
              :to="{ name: 'firmware', params: { id: props.row.id } }">{{ props.row.label
              }}</router-link>
            <span v-else>{{ props.row.label }}</span>
            <sc-tooltip :text="firmwareTooltip(props.row)" max-width="500px" max-height="700px" />
          </div>
        </q-td>

        <q-td v-if="isColumnVisible('name')">
          <router-link v-if="canEditFirmware" class="item-link"
            :to="{ name: 'firmware', params: { id: props.row.id } }">{{ props.row.name
            }}</router-link>
          <span v-else>{{ props.row.name }}</span>
          <sc-tooltip :text="firmwareTooltip(props.row)" max-width="500px" max-height="700px" />
        </q-td>

        <q-td v-if="isColumnVisible('createdAt')">
          {{ datetime(props.row.createdAt) }}
        </q-td>

        <q-td v-if="isColumnVisible('isShared')" class="is-shared">
          <q-icon v-if="props.row.hasOrganizations" size="sm" name="share" color="green-6">
            <sc-tooltip :text="sharedWithTooltip(props.row)"></sc-tooltip>
          </q-icon>
        </q-td>

        <q-td v-if="isColumnVisible('fileName')">
          {{ props.row.file.name }}
        </q-td>

        <q-td v-if="isColumnVisible('fileSize')">
          {{ props.row.file.size }}b
        </q-td>

        <q-td v-if="isColumnVisible('fileDate')">
          {{ datetime(props.row.file.date) }}
        </q-td>

        <q-td v-if="isColumnVisible('actions')" class="actions">
          <sc-action-dropdown :data="props.row" :actions="firmwareActions"
            @action="action => executeFirmwareAction(props.row, action)">
          </sc-action-dropdown>
        </q-td>
      </q-tr>
    </template>
  </sc-list>

  <sc-batch-edit-firmwares></sc-batch-edit-firmwares>

</template>

<style scoped lang="scss">
.firmwares {

  .q-tr {
    &.group {
      cursor: pointer;

      th {
        text-align: left;
        border-bottom: solid #0000001f 1px;
        color: #7a7a7a;
        font-size: 12px;
        font-weight: 500;

        &.version {
          width: 220px;
          color: black;
        }

        &.actions {
          text-align: right;
        }
      }

      &.obsolete {
        th.version {
          color: gray;
        }
      }

      &:first-child {
        th {
          padding-top: 12px;
        }
      }

      &:last-child {
        th {
          border-bottom: none;
        }
      }

      &.expanded {
        background-color: #f8f8f8;
      }

    }

    &.firmware {
      &.obsolete {
        color: gray;
      }

      td {
        &.is-shared,

        &.model {
          cursor: pointer;
        }

        &.actions {
          text-align: right;
        }
      }
    }
  }
}
</style>