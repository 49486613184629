<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { sortItems, distinctValues, countString, wait } from '@stellacontrol/utilities'
import { ViewMixin, EditorMixin, FormMixin, Notification } from '@stellacontrol/client-utilities'
import { DeviceFirmware, isDeviceFirmwareFile } from '@stellacontrol/model'
import { Secure } from '@stellacontrol/security-ui'
import { isValidVersion } from '@stellacontrol/model'
import { resolveDeviceFirmware } from './device-updates.resolve'

const name = 'firmware'

/**
 * Firmware editor
 */
export default {
  mixins: [
    ViewMixin,
    EditorMixin,
    FormMixin,
    Secure
  ],

  data () {
    return {
      name,
      // Firmware version
      version: '',
      // Details of the firmware file to upload, File object
      selectedFile: null,
      selectedFiles: null,
      // Firmware download URL
      downloadUrl: null,
      // Organizations filter
      organizationFilter: ''
    }
  },

  computed: {
    ...mapState({
      // Firmwares available to currently logged in organization
      firmwares: state => state.deviceTransmission.firmwares,
      // Data of the created or updated firmware
      lastUpdated: state => state.deviceTransmission.lastUpdated,
      // Available device models
      deviceModels: state => state.deviceTransmission.deviceModels
    }),

    ...mapGetters([
      'organizations',
      'configuration'
    ]),

    // Device model groups
    modelGroups () {
      const { configuration, deviceModels } = this
      const groups = Object
        .values(configuration.entities.device.model.groups)
        .map(group => ({
          name: group.name,
          models: deviceModels.filter(model => group.models.includes(model))
        }))
      const misc = {
        name: 'Misc',
        models: deviceModels.filter(model => !groups.some(group => group.models.includes(model)))
      }
      return [
        ...groups,
        misc
      ]
    },

    // Returns true if current user is allowed to manage firmware repository
    canEditFirmware () {
      return this.canUse('can-manage-firmware')
    },

    // Returns true if SAVE is possible
    canSave () {
      const { selectedFilesValidRule, firmwareVersionsValidRule, fileVersionsIdenticalRule, data } = this
      if (data.isNew) {
        return selectedFilesValidRule() === true &&
          firmwareVersionsValidRule() === true &&
          fileVersionsIdenticalRule() === true
      } else {
        return true
      }
    },

    // Organizations allowed to upload firmwares
    allOrganizations () {
      const { organizations } = this
      const items = organizations.filter(o => o.canUse('device-management-firmware'))
      return sortItems(items, o => o.fullName)
    },

    // Organizations after applying the filter
    visibleOrganizations () {
      const filter = this.organizationFilter.trim().toLowerCase()
      return this.allOrganizations.filter(o => !filter || o.fullName.toLowerCase().includes(filter))
    },

    // Organizations currently granted with access to the firmware
    grantedOrganizations () {
      return this.allOrganizations.filter(o => this.data.isOrganizationGranted(o))
    },

    // Organizations currently denied access to the firmware
    revokedOrganizations () {
      return this.allOrganizations.filter(o => !this.data.isOrganizationGranted(o))
    },

    // Returns true if all organizations were granted access
    allOrganizationsSelected () {
      return this.allOrganizations.every(o => this.data.isOrganizationGranted(o))
    },

    // Returns true if all models have been selected for the firmware
    allModelsSelected () {
      const { deviceModels, data } = this
      return deviceModels.every(model => data.models?.includes(model)) || false
    },

    // Returns true if specified model have been selected for the firmware
    isModelSelected () {
      const { data } = this
      return model => data.models?.includes(model) || false
    },

    // Selected firmware details
    selectedFirmwares () {
      return (this.selectedFiles || []).map(file => {
        const { name } = file
        const isValid = isDeviceFirmwareFile(name)
        const { version, versionString, model } = isValid
          ? DeviceFirmware.parseFirmwareFileName(name)
          : {}
        return {
          file,
          isValid,
          name,
          version,
          versionString,
          model,
        }
      })
    },

    // Version of the selected firmware files
    selectedFirmwareVersions () {
      return distinctValues(this.selectedFirmwares, firmware => firmware.versionString)
    }
  },

  methods: {
    ...mapActions([
      'busy',
      'done',
      'deviceFirmwareExists',
      'getDeviceFirmware',
      'saveDeviceFirmware',
      'saveDeviceFirmwareFile',
      'editDeviceFirmware',
      'getDeviceFirmwareUrl',
      'showDeviceFirmwares',
      'grantAccessToDeviceFirmware',
      'revokeAccessToDeviceFirmware'
    ]),

    // Initializes the editor
    async initialize () {
      const { data: firmware } = this
      this.version = firmware.versionString
      this.downloadUrl = await this.getDeviceFirmwareUrl({ firmware })
    },

    // Saves the edited firmware
    async save () {
      if (await this.validate()) {
        const { data, data: { isNew }, selectedFirmwares: firmwares, grantedOrganizations, revokedOrganizations } = this

        const progress = isNew
          ? await this.busy({ message: `Saving ${countString(firmwares, 'device firmware')}, please wait ...` })
          : await this.busy({ message: 'Saving device firmware, please wait ...' })

        if (isNew) {
          const oneFile = firmwares.length === 1
          for (const { file, name } of firmwares) {
            await progress({ message: `Saving firmware ${name} ...` })
            // Save each uploaded file separately
            await wait(1000)
            const firmware = await this.saveDeviceFirmware({
              firmware: new DeviceFirmware({ ...data })
            })
            await this.saveDeviceFirmwareFile({ firmware, file })
            // Grant/revoke access to the firmware to selected organizations
            await this.revokeAccessToDeviceFirmware({ firmware, organizations: revokedOrganizations })
            await this.grantAccessToDeviceFirmware({ firmware, organizations: grantedOrganizations })
          }

          this.done({
            message: oneFile
              ? `Firmware ${firmwares[0].name} has been saved`
              : `${firmwares.length} firmwares have been saved`
          })

          this.cancel()

        } else {
          const firmware = await this.saveDeviceFirmware({ firmware: data })
          // Grant/revoke access to the firmware to selected organizations
          await this.revokeAccessToDeviceFirmware({ firmware, organizations: revokedOrganizations })
          await this.grantAccessToDeviceFirmware({ firmware, organizations: grantedOrganizations })
          this.done({ message: `Firmware ${firmware.label} has been saved` })
          this.editDeviceFirmware({ firmware })
        }

        return true
      }
    },

    // Cancels editing
    async cancel () {
      await this.showDeviceFirmwares()
    },

    // Form validation rules for firmware version
    // Validates the selected files
    selectedFilesValidRule () {
      const { selectedFirmwares } = this
      const invalidFirmwares = selectedFirmwares.filter(firmware => !firmware.isValid)
      if (selectedFirmwares.length === 0) {
        return 'No firmware selected'
      } else if (invalidFirmwares.length > 0) {
        return `Invalid firmware: ${invalidFirmwares.map(f => f.name).join(', ')}`
      } else {
        return true
      }
    },

    // Validates versions of the selected files
    firmwareVersionsValidRule () {
      const { selectedFirmwares } = this
      const invalidFirmwares = selectedFirmwares.filter(firmware => !firmware.version)
      return invalidFirmwares.length === 0
        ? true
        : `Firmware versions are invalid: ${invalidFirmwares.map(f => f.name).join(', ')}`
    },

    // Form validator: makes sure the selected files have firmware version in the name
    isValidFirmwareVersionRule (version, message) {
      return Boolean(isValidVersion(version, 4, true)) || message
    },

    // Form validator: makes sure that the selected files have all the same version
    fileVersionsIdenticalRule () {
      return this.selectedFirmwareVersions.length < 2
        ? true
        : 'You cannot mix different firmware versions'
    },

    // Grants/revokes access to firmware to the specified organization
    toggleOrganization (organization, isGranted) {
      const { data } = this
      if (isGranted) {
        data.grantOrganization(organization)
      } else {
        data.revokeOrganization(organization)
      }
    },

    // Toggles all organizations
    toggleAllOrganizations (isGranted) {
      for (const organization of this.allOrganizations) {
        this.toggleOrganization(organization, isGranted)
      }
    },

    // Selects/deselects all models
    selectAllModels (selected) {
      const { deviceModels, data } = this
      if (selected) {
        data.models = [...deviceModels]
      } else {
        data.models = []
      }
    },

    // Selects/deselects the specified model
    selectModel (model, value) {
      const { data } = this
      if (!data.models) data.models = []
      if (value) {
        if (!data.models.includes(model)) {
          data.models.push(model)
        }
      } else {
        data.models = data.models.filter(m => m !== model)
      }
    },

    // Parses and stores the entered version if valid
    onVersionChanged (value) {
      const { data } = this
      const version = isValidVersion(value, 4, true)
      if (version) {
        version.assignTo(data.version)
      }
    },

    // Extract firmware data from the selected files
    onFirmwareFilesSelected () {
      const { data, selectedFirmwares } = this
      if (selectedFirmwares.length > 0) {
        const { version, versionString, model } = selectedFirmwares[0]
        if (version) {
          data.models = (model && selectedFirmwares.length === 1) ? [model] : []
          this.version = versionString
          version.assignTo(data.version)
        } else {
          Notification.warning({ message: 'The selected file doesn\'t look like a firmware file', details: 'Firmware file name should contain device model and firmware version, for example iR5_V4_v7.4.0.20' })
        }
      }
    }
  },

  // Reload data on navigation to another firmware
  async beforeRouteUpdate (to, from) {
    if (await resolveDeviceFirmware({ from, to })) {
      await this.initialize()
    }
  },

  async created () {
    await this.initialize()
    if (this.data.isNew) {
      this.toggleAllOrganizations(true)
    }
  }
}

</script>

<template>
  <sc-view :name="name">
    <template #toolbar>
      <q-btn label="Download Firmware" unelevated type="a" color="indigo-2" text-color="indigo-7"
        :href="downloadUrl" v-if="downloadUrl"></q-btn>
      <q-btn label="Save" unelevated class="primary q-ml-lg" @click="save()" :disabled="!canSave">
      </q-btn>
      <q-btn label="Close" unelevated @click="cancel()"></q-btn>
    </template>

    <div class="container">
      <div class="form-container q-ml-md q-mt-md q-mb-md q-mr-sm">
        <q-form class="form" ref="form" autofocus>
          <div class="properties q-mr-sm">
            <q-file v-if="data.isNew" outlined square dense bottom-slots counter use-chips
              class="file-selector" multiple v-model="selectedFiles" :rules="[
                () => selectedFilesValidRule(),
                () => firmwareVersionsValidRule(),
                () => fileVersionsIdenticalRule()
              ]" @update:model-value="onFirmwareFilesSelected()">
              <template v-slot:prepend>
                <q-icon name="attach_file" @click.stop></q-icon>
              </template>
              <template v-slot:append>
                <q-icon name="close" @click.stop="model = null" class="cursor-pointer"></q-icon>
              </template>
              <template v-slot:before>
                <label>Firmware File</label>
              </template>
            </q-file>

            <q-input v-else :model-value="data.fileString" readonly dense square outlined
              hide-bottom-space class="q-mt-sm">
              <template v-slot:before>
                <label>Firmware File</label>
              </template>
            </q-input>

            <q-input v-model="version" dense square outlined hide-bottom-space class="q-mt-sm"
              :label="version ? undefined : '4-segment firmware version number, i.e. 7.0.2.12'"
              maxlength="20" lazy-rules :rules="[
                rules.required('Firmware version is required'),
                version => isValidFirmwareVersionRule(version, 'Firmware version is invalid')
              ]" @update:model-value="value => onVersionChanged(value)">
              <template v-slot:before>
                <label>Version Number</label>
              </template>
            </q-input>

            <q-input v-model="data.name" dense square outlined hide-bottom-space class="q-mt-md"
              maxlength="255" label="User-friendly name of the firmware">
              <template v-slot:before>
                <label>Name</label>
              </template>
            </q-input>

            <q-field dense borderless v-if="!data.isNew || selectedFirmwares.length < 2"
              class="q-mt-md">
              <template v-slot:before>
                <label>Supported Models</label>
              </template>
              <div class="models row q-pt-md q-mb-md">
                <div class="column q-pr-lg" v-for="group in modelGroups">
                  <div class="text-bold q-ml-sm">
                    {{ group.name }}
                  </div>

                  <q-checkbox v-for="model in group.models" :model-value="isModelSelected(model)"
                    @update:model-value="value => selectModel(model, value)" :label="model" size="sm">
                  </q-checkbox>
                </div>
              </div>
            </q-field>

            <q-input class="notes q-mt-md" v-model="data.description" type="textarea" dense square
              outlined>
              <template v-slot:before>
                <label>Details</label>
              </template>
            </q-input>

            <q-field dense borderless class="q-mt-sm q-mb-sm">
              <template v-slot:before>
                <label>{{ data.isObsolete ? 'Obsolete Firmware' : 'Active Firmware' }}</label>
              </template>
              <div class="row items-center">
                <q-toggle color="green-7" :model-value="!data.isObsolete"
                  @update:model-value="value => data.isObsolete = !value"></q-toggle>
                <sc-hint>If firmware becomes obsolete, mark it as inactive. They users will not be
                  able to select it, when updating their devices. </sc-hint>
              </div>
            </q-field>

            <q-field dense borderless v-if="!data.isNew">
              <template v-slot:before>
                <label>Added On</label>
              </template>
              <div class="row items-center">{{ data.createdText }}</div>
            </q-field>

            <q-field dense borderless v-if="!data.isNew && data.hasFile">
              <template v-slot:before>
                <label>File Name</label>
              </template>
              <div class="row items-center">{{ data.file.name }}</div>
            </q-field>

            <q-field dense borderless v-if="!data.isNew && data.hasFile">
              <template v-slot:before>
                <label>File Size</label>
              </template>
              <div class="row items-center">{{ data.file.size }} B</div>
            </q-field>

            <q-field dense borderless v-if="!data.isNew && data.hasFile">
              <template v-slot:before>
                <label>File Time</label>
              </template>
              <div class="row items-center">{{ dateTimeString(data.file.date) }}</div>
            </q-field>
          </div>
        </q-form>
      </div>

      <div class="organizations-container">
        <header class="header q-pt-md q-pl-md bg-grey-2">
          Organizations allowed to use
          <span v-if="data.hasVersion">
            firmware
            <b>{{ data.versionString }}</b>
          </span>
          <span v-else>the firmware</span>
        </header>

        <section class="filter row items-center q-pl-sm q-pb-md q-pt-sm bg-grey-2">
          <q-checkbox :model-value="allOrganizationsSelected"
            :label="allOrganizationsSelected ? 'Deselect all' : 'Select all'"
            @update:model-value="value => toggleAllOrganizations(value)">
          </q-checkbox>
          <q-input class="q-ml-lg bg-white" v-model="organizationFilter" dense outlined square
            label="Filter">
          </q-input>
        </section>

        <section class="organizations">
          <main>
            <q-list dense class="q-mt-sm">
              <q-item v-for="organization in visibleOrganizations" :key="organization.id">
                <q-item-section side>
                  <q-checkbox dense :label="organization.fullName"
                    :model-value="data.isOrganizationGranted(organization)"
                    @update:model-value="value => toggleOrganization(organization, value)">
                  </q-checkbox>
                </q-item-section>
              </q-item>
            </q-list>
          </main>
        </section>
      </div>
    </div>
  </sc-view>
</template>

<style lang='scss' scoped>
.container {
  flex: 1;
  display: flex;
  flex-direction: row;
  overflow: hidden;

  .form-container {
    flex: 3;
    display: flex;
    overflow: hidden;

    .form {
      flex: 1;
      display: flex;
      overflow: auto;
      flex-direction: column;
      align-items: stretch;
      justify-content: stretch;

      label {
        font-size: 14px;
        min-width: 150px;
      }

      .models {
        flex: 1;
        overflow: auto;
        border: 1px solid #0000001f;
      }

      .notes {
        :deep(textarea) {
          display: flex;
          flex-direction: column;
          flex: 1;
          height: 200px;
        }

        :deep(.q-field__control) {
          height: 100%;
          flex: 1;
        }
      }

      .file-selector {
        :deep(.q-field__before) {
          padding-right: 6px;
        }
      }
    }
  }

  .organizations-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, 0.24);
    border-top: none;

    .header {
      flex: 0;
    }

    .filter {
      flex: 0;
    }

    .organizations {
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      height: 100%;
      border-top: 1px solid rgba(0, 0, 0, 0.24);

      >main {
        flex: 1;
        overflow: auto;
      }
    }
  }
}
</style>
