<script>
import ItemPropertiesMixin from './item-properties.js'
import { PlanLineStyle, PlanLineType } from '@stellacontrol/planner'

export default {
  mixins: [
    ItemPropertiesMixin
  ],

  props: {
    // Element labels
    lineLabel: {
      default: 'Line'
    },
    widthLabel: {
      default: 'Width'
    },
    typeLabel: {
      default: 'Type'
    },
    radiusLabel: {
      default: 'Radius'
    },
    blurLabel: {
      default: 'Blur'
    },
    arrowsLabel: {
      default: 'Arrows'
    },
    // Allows editing line type
    lineType: {
      type: Boolean,
      default: true
    },
    // Allows editing radius
    radius: {
      type: Boolean,
      default: false
    },
    // Allows editing arrows
    arrows: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      PlanLineType,
      defaults: new PlanLineStyle().defaults
    }
  },

  computed: {
    // Available line types
    lineTypes () {
      return [
        { value: PlanLineType.Single, label: '───' },
        { value: PlanLineType.Dotted, label: '···········' },
        { value: PlanLineType.Dashed, label: '- - - - - -' }
      ]
    }
  }
}

</script>

<template>
  <main class="plan-item-properties">
    <section>
      <label>
        {{ lineLabel }}
      </label>
      <sc-color-picker :model-value="getValue('lineStyle.color', defaults.color)"
        @update:model-value="value => setProperties({ 'lineStyle.color': value })" full clear
        :width="styles.color.width" :height="styles.color.height">
      </sc-color-picker>
      <q-input type="number" :min="0" :max="20" dense outlined square debounce="500" class="q-ml-xs"
        :label="widthLabel" :input-style="styles.number"
        :model-value="getValue('lineStyle.width', defaults.width)"
        @update:model-value="value => setProperties({ 'lineStyle.width': parseInt(value) })">
      </q-input>
    </section>

    <section v-if="lineType">
      <label>
      </label>
      <q-select outlined square dense emit-value map-options :options="lineTypes" :label="typeLabel"
        :style="styles.select.small" :model-value="getValue('lineStyle.type', defaults.type)"
        @update:model-value="value => setProperties({ 'lineStyle.type': value })">
      </q-select>
      <q-input v-if="radius" type="number" :min="0" :max="50" dense outlined square debounce="500"
        :label="radiusLabel" :input-style="styles.number"  class="q-ml-xs"
        :model-value="getValue('lineStyle.radius', defaults.radius)"
        @update:model-value="value => setProperties({ 'lineStyle.radius': parseInt(value) })">
      </q-input>
    </section>

    <section v-if="hasProperty('blurRadius') && !hasProperty('backgroundStyle')">
      <label>
        {{ blurLabel }}
      </label>
      <q-input type="number" :min="0" :max="100" dense outlined square debounce="500"
        :input-style="styles.number" :model-value="getValue('blurRadius')"
        @update:model-value="value => setProperties({ blurRadius: parseInt(value) })">
      </q-input>
    </section>

    <section v-if="arrows">
      <label>
        {{ arrowsLabel }}
      </label>
      <div class="column">
        <q-checkbox dense label="Start" class="q-mt-sm"
          :model-value="getValue('arrowStyle.start', false)"
          @update:model-value="value => setProperties({ 'arrowStyle.start': Boolean(value) })">
        </q-checkbox>
        <q-checkbox dense label="End" class="q-mt-sm"
          :model-value="getValue('arrowStyle.end', false)"
          @update:model-value="value => setProperties({ 'arrowStyle.end': Boolean(value) })">
        </q-checkbox>
      </div>
    </section>

  </main>
</template>

<style lang="scss" scoped></style>
