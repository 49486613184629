export function createState (initialState = {}) {
  return {
    // Indicates that we're in process of changing region of the specified devices
    changingRegionOfDevices: [],
    // Region we're switching to
    changingRegionTo: '',
    // Details of devices which failed during changing region
    changingRegionErrors: [],

    ...initialState
  }
}

export const state = createState()

export const getters = {
}
