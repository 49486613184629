/**
 * Notification routes
 */
export const NotificationRoute = {
  /**
   * Show notification in the website, when user logs in
   */
  Website: 'website',
  /**
   * Send notification by e-mail
   */
  Email: 'email',
  /**
   * Send push notification to recipients' devices
   */
  Push: 'push',
  /**
   * Send notification by text message
   */
  SMS: 'push'
}
