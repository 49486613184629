/**
 * Security request types
 */
export const SecurityRequestType = {
  /**
   * Activate user account
   */
  ActivateAccount: 'activate-account',
  /**
   * New password
   */
  NewPassword: 'new-password',
  /**
   * Unsubscribe from emails
   */
  UnsubscribeNotifications: 'unsubscribe-notifications'
}
