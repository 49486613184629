import { PlannerAPI, CommonAPI } from '@stellacontrol/client-api'
import { Plan, EntityType, Attachment, AttachmentType } from '@stellacontrol/model'
import { PlanLayout, PlannerMode } from '@stellacontrol/planner'

/**
 * Initialize the applet
 */
export async function initializeApplet () {
}

/**
 * Retrieves plans of the current organization
 * @returns {Promise<Array[Plan]>}
 */
export async function getPlans ({ commit, getters }) {
  const { currentOrganization: organization } = getters
  const plans = await PlannerAPI.getPlans({ organization })
  commit('storePlans', { plans })
  return plans
}

/**
 * Retrieves the specified plan
 * @param {String} id Plan identifier
 * @returns {Promise<Plan>}
 */
export async function getPlan ({ commit }, { id } = {}) {
  if (id) {
    const plan = await PlannerAPI.getPlan({ id })
    if (plan) {
      // Parse plan layout
      plan.layout = new PlanLayout(plan.layout, plan.name)
      // Determine URLs for floor images
      for (const floor of plan.layout.floors) {
        const { image } = floor.background
        if (image) {
          image.downloadUrl = CommonAPI.getAttachmentUrl({
            attachment: image,
            decode: true
          })
        }
      }
      commit('storePlan', { plan })
      return plan
    }
  }
}

/**
 * Retrieves a plan for he specified place
 * @param {String} id Place identifier
 * @param {Boolean} create If true and plan of a place does not exist yet, it will be now created
 * @returns {Promise<Plan>}
 */
export async function getPlacePlan ({ commit, getters }, { id, create = true } = {}) {
  if (id) {
    const place = getters.allPlaces.find(p => p.id === id)
    if (place) {
      const plan = await PlannerAPI.getPlacePlan({ id: place.id, create })
      if (plan) {
        plan.layout = new PlanLayout(plan.layout, plan.name)
        commit('storePlan', { plan })
        return plan
      }
    }
  }
}

/**
 * Creates a new plan belonging to the current organization
 * @returns {Promise<Plan>}
 */
export async function newPlan ({ getters }) {
  const { currentOrganization } = getters

  const plan = new Plan({
    organizationId: currentOrganization.id
  })

  return plan
}

/**
 * Selects the plan for editing
 * @param {Plan} plan Plan to edit
 * @param {String} floorId Floor to edit
 */
export async function editPlan ({ commit }, { plan, floorId } = {}) {
  commit('editPlan', { plan })
  commit('editPlanFloor', { floorId })
}

/**
 * Selects the plan floor for editing
 * @param {String} floorId Floor to edit
 */
export async function editPlanFloor ({ commit }, { floorId } = {}) {
  commit('editPlanFloor', { floorId })
}


/**
 * Saves a plan
 * @param {Plan} plan Plan to save
 * @returns {Promise<Plan>}
 */
export async function savePlan ({ commit }, { plan } = {}) {
  if (!plan) throw new Error('Plan is required')
  if (!plan.organization) throw new Error('Plan owner is required')
  if (!plan.place) throw new Error('Plan place is required')

  // Save the plan.
  // Floor images will be removed during serialization.
  // They are saved separately as attachments.
  const planToSave = new Plan(plan)
  planToSave.layout = new PlanLayout(plan.layout, plan.name)
  for (const floor of planToSave.layout.floors) {
    if (floor.hasImage) {
      floor.background.image.content = null
    }
  }
  plan = await PlannerAPI.savePlan({ plan: planToSave })

  if (plan) {
    // Parse the saved layout
    plan.layout = new PlanLayout(plan.layout, plan.name)

    // Store the updated plan in the state
    commit('storePlan', { plan })
  }

  return plan
}

/**
 * Saves a floor image
 * @param {Plan} plan Plan
 * @param {PlanFloor} floor Plan floor whose image to save
 * @param {Attachment} file File data, `null` if image has been cleared
 * @returns {Promise<Plan>}
 */
export async function saveFloorImage ({ commit, getters }, { plan, floor, file } = {}) {
  if (!plan) throw new Error('Plan is required')
  if (!plan.id) throw new Error('Plan must be saved first')
  if (!floor) throw new Error('Floor is required')

  try {
    // Delete the previous floor image when image has been cleared,
    // or when new image has been uploaded,
    const imageCleared = floor.background && !file
    const imageAssigned = floor.background && !!file
    const imageChanged = floor.background && floor.background.wasImageChanged

    // Delete previous image if image cleared or new image uploaded
    if (imageCleared) {
      floor.background.clearImage()
    }
    if (imageCleared || imageChanged) {
      await CommonAPI.deleteAttachments({
        ownerId: plan.organizationId,
        entityId: plan.id,
        folder: floor.id
      })
    }

    if (imageAssigned) {
      // Save the new floor image
      let attachment = new Attachment({
        ...file,
        type: AttachmentType.Image,
        name: file.name || floor.label,
        description: plan.name,
        ownerId: plan.organizationId,
        entityId: plan.id,
        entityType: EntityType.Plan,
        folder: floor.id,

        // The image is passed as Base64-encoded data URL
        dataUrl: file.content,
        mimeType: file.mimeType,
        file: null,

        // The attachment is stored in the external data storage
        external: true
      })

      attachment = await CommonAPI.saveAttachment({ attachment, decode: true })
      if (attachment) {
        // From now on, the attachment can be downloaded via URL
        attachment.downloadUrl = CommonAPI.getAttachmentUrl({ attachment })
        attachment.mimeType = file.mimeType
        attachment.content = undefined
        floor.background.image = attachment
        // Remove transparent colors from the floor, the image is now stored with transparency
        floor.background.clearTransparentColors()
      }

    }

    // Save the plan
    plan = await PlannerAPI.savePlan({ plan })
    plan.layout = new PlanLayout(plan.layout, plan.name)
    commit('storePlan', { plan })

    return plan

  } catch (error) {
    if (CommonAPI.isEntityTooLargeError(error) || CommonAPI.isNetworkError(error)) {
      const limits = getters.configuration.upload.limits
      const details = `The uploaded file is too large. Maximal allowed size is ${Math.round(limits.fileSize / (1024 * 1024))} MB`
      throw new Error(details)
    } else {
      throw error
    }
  }
}

/**
 * Sets the planner editing mode
 * @param {PlannerMode} mode Planner editing mode
 */
export async function setPlannerMode ({ commit, getters }, { mode } = {}) {
  if (getters.guardian.canUse('planner-advanced')) {
    commit('setPlannerMode', { mode })
  } else {
    commit('setPlannerMode', { mode: PlannerMode.Regular })
  }
}

/**
 * Maximizes the plan editor / restores to normal view
 * @param {Boolean} isMaximized Planner view size
 */
export function setPlannerView ({ commit }, { isMaximized } = {}) {
  commit('setPlannerView', { isMaximized })
}
