<script>
import { mapActions } from 'vuex'
import { notNull } from '@stellacontrol/utilities'
import { isMegaParameterApplicable } from '@stellacontrol/mega'
import { DialogMixin } from '@stellacontrol/client-utilities'

const dialog = 'edit-device-parameter'

export default {
  mixins: [
    DialogMixin
  ],

  data () {
    return {
      // Edited device
      device: null,
      // Most recent device status
      status: null,
      // Parameter name
      name: null,
      // Parameter value
      value: null,
      // Dialog identifier
      dialog
    }
  },

  computed: {
    // Checks whether the value have been entered and is correct
    isValidName () {
      const { name, device, status } = this
      return isMegaParameterApplicable(name, device, status)
    },

    // Checks whether name and value have been entered and are correct
    isValidValue () {
      return this.value != null
    },

    // Checks whether the entered data is correct
    isValid () {
      return this.device?.isConnectedDevice &&
        this.isValidName &&
        this.isValidValue
    }
  },

  methods: {
    ...mapActions([
      'dialogOk',
      'dialogCancel'
    ]),

    // Called when dialog is shown
    dialogShown () {
      // Pass on initial data
      this.name = notNull(this.data.name, '')
      this.value = notNull(this.data.value, '')
      this.device = this.data.device
    },

    // Accepts the dialog
    ok () {
      const { name, value } = this
      this.dialogOk({ dialog, data: { name, value } })
    },

    // Cancels the dialog
    cancel () {
      this.dialogCancel({ dialog })
    }
  }
}
</script>

<template>
  <sc-dialog :dialog="dialog" persistent @dialogShown="dialogShown()">
    <q-form ref="form" class="form" v-if="device">
      <q-banner class="bg-indigo-6">
        <span class="text-white title">
          {{ device.acronym }} {{ device.serialNumber }}
        </span>
      </q-banner>

      <main class="q-pa-md">
        <header>
          Edit device parameter
        </header>

        <section class="column q-mt-md">
          <q-input v-model="name" label="Name" max-length="50" outlined square debounce="500"
            :rules="[() => isValidName ? null : 'Invalid parameter name']">
          </q-input>

          <q-input v-model="value" label="Value" max-length="50" class="q-mt-xs" outlined square
            debounce="500" :rules="[() => isValidValue ? null : 'Invalid parameter value']">
          </q-input>
        </section>

        <footer class="q-mt-lg text-orange-8 q-pl-sm q-pr-sm">
          Caution! An invalid parameter can severely disrupt device operations!
        </footer>

        <footer class="buttons row q-mt-lg align-center">
          <q-space></q-space>
          <q-btn label="Save" unelevated class="q-mr-md" @click="ok()" :disabled="!isValid"></q-btn>
          <q-btn label="Cancel" unelevated @click="cancel()"></q-btn>
        </footer>

      </main>

    </q-form>
  </sc-dialog>
</template>

<style scoped lang="scss">
.form {
  min-width: 290px;
  max-width: 550px;

  .title {
    font-size: 18px;
  }
}
</style>
