import { dispatch } from '@stellacontrol/client-utilities'
import { PlanAction, PlanActions } from './plan-action'

/**
 * Shows the details of the specified plan item
 */
export class ShowItemDetailsAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.ShowItemDetails
  }

  /**
   * Action label
   * @type {String}
   */
  get label () {
    return 'Show details'
  }

  /**
   * Action icon
   * @type {String}
   */
  get icon () {
    return 'info'
  }

  /**
   * Action icon color
   * @type {String}
   */
  get color () {
    return 'green-3'
  }

  /**
   * Indicates that the action requires items to act on
   * @type {Boolean}
   */
  get requiresItems () {
    return true
  }

  /**
   * Indicates that the action can be executed on a batch of items
   * @type {Boolean}
   */
  get allowBatch () {
    return false
  }

  /**
   * Indicates whether action requires refresh
   * @type {Boolean}
   */
  get requiresRefresh () {
    return false
  }

  /**
   * If true, the current selection will be preserved
   * after the action has been executed
   * @type {Boolean}
   */
  get preserveSelection () {
    return true
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   * @param {Array[PlanItem]} items Plan items to apply the action to
   */
  async execute ({ renderer, items } = {}) {
    if (renderer && items) {
      const item = items[0]
      if (item) {
        const details = item.getDetails(renderer.layout, renderer.equipmentHierarchy)
        if (details?.length > 0) {
          await dispatch('showDialog', {
            dialog: 'plan-item-details',
            data: { item, details }
          })
        }
      }
    }
  }
}
