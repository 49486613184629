import { parseDate } from '@stellacontrol/utilities'
import { PremiumServiceSubscription } from './premium-service-subscription'
import { Entity } from '../common/entity'

/**
 * Represents a customer wallet
 * used to pay for premium services
 */
export class Wallet extends Entity {
  constructor (data = {}) {
    super()

    this.assign(
      {
        ...data,
        subscriptions: data.subscriptions || [],
        createdAt: data.createdAt || new Date(),
        balance: data.balance || 0
      },
      {
        createdAt: parseDate
      }
    )
  }

  normalize () {
    super.normalize()
    this.subscriptions = this.castArray(this.subscriptions, PremiumServiceSubscription)
  }

  /**
   * Wallet identifier is the same as identifier of organization
   * to which the wallet belongs
   * @type {String}
   */
  get organizationId () {
    return this.id
  }
  set organizationId (value) {
    this.id = value
  }

  /**
   * Organization to which the wallet belongs
   * @type {Organization}
   */
  organization

  /**
   * Current balance of the customer wallet,
   * the number of unused tokens
   * @type {Number}
   */
  balance

  /**
   * Human-friendly description of the balance
   */
  get balanceDescription () {
    const { balance } = this
    if (balance > 0) {
      return `${balance} token${balance === 1 ? '' : 's'}`
    } else if (balance === 0) {
      return 'Wallet is empty'
    } else {
      return ''
    }
  }

  /**
   * Indicates that organization is a bank and has unlimited wallet
   */
  isBank

  /**
   * Indicates whether organization is allowed at all to subscribe
   * to premium services
   */
  canSubscribe

  /**
   * Current subscriptions to premium services
   * @type {Array[PremiumServiceSubscription]}
   */
  subscriptions

  /**
   * Returns true if wallet has any subscriptions
   */
  get hasSubscriptions () {
    return (this.subscriptions || []).length > 0
  }

  /**
   * Currently active subscriptions to premium services
   * @type {Array[PremiumServiceSubscription]}
   */
  get activeSubscriptions () {
    return this.subscriptions.filter(s => s.isActive)
  }

  /**
   * Expired subscriptions
   * @type {Array[PremiumServiceSubscription]}
   */
  get expiredSubscriptions () {
    return this.subscriptions.filter(s => s.isActive === false)
  }

  /**
   * Total value of tokens allocated to currently active subscriptions
   * @type {Number}
   */
  get activeTokens () {
    return this.activeSubscriptions
      .map(s => s.total)
      .reduce((all, total) => all + total, 0)
  }

  /**
   * Total value of tokens spent on already expired subscriptions
   * @type {Number}
   */
  get usedTokens () {
    return this.expiredSubscriptions
      .map(s => s.total)
      .reduce((all, total) => all + total, 0)
  }
}
