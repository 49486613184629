<script>
import { mapGetters, mapActions } from 'vuex'
import { DialogMixin } from '@stellacontrol/client-utilities'
import { getDeviceLabel } from '@stellacontrol/model'
import { Secure } from '@stellacontrol/security-ui'

const dialog = 'purchase-premium-service'

export default {
  mixins: [
    DialogMixin,
    Secure
  ],

  data () {
    return {
      dialog,
      // Amount of tokens to buy
      amount: 0,
      // Identifier of the selected premium service
      premiumServiceId: null
    }
  },

  computed: {
    ...mapGetters([
      'isSmallScreen',
      'myWallet',
      'pricelist',
      'fullHierarchy',
      'organizationHierarchy'
    ]),

    // Device to purchase services for
    device () {
      return this.data.device
    },

    // Customer to purchase services for
    customer () {
      return this.currentOrganization
    },

    // Current organization's available tokens.
    balance () {
      return this.myWallet.balance
    },

    // Premium services which the current organization is allowed to purchase
    premiumServices () {
      const { pricelist, fullHierarchy, currentOrganization } = this
      const services = pricelist.resellerPremiumServices(currentOrganization, fullHierarchy)
      return services.filter(s => s.isPerDevice && !s.isFree)
    },

    // True if customer can afford purchasing the selected service
    canAffordPurchase () {
      return this.premiumService && this.premiumService.price <= this.balance
    },

    // Premium service selected by the customer
    premiumService () {
      return this.premiumServices.find(item => item.id === this.premiumServiceId)
    }
  },

  emit: [
    'show',
    'hide',
    'purchase'
  ],

  methods: {
    ...mapActions([
      'dialogOk',
      'dialogCancel',
      'sellPremiumService'
    ]),

    getDeviceLabel,

    // Validates and OKs the dialog
    async ok () {
      this.dialogOk({ dialog })
      this.$emit('close')
    },

    // Cancels the dialog
    cancel () {
      this.dialogCancel({ dialog })
      this.$emit('close')
    },

    // Triggered when dialog is shown
    dialogShown () {
      this.$emit('show')
    },

    // Triggered when dialog is hidden
    dialogHidden () {
      this.$emit('hide')
    },

    // Purchases new service for the device
    async purchaseService () {
      const { premiumService: service, device, currentOrganization: customer, myWallet: wallet, canAffordPurchase } = this
      if (canAffordPurchase) {
        await this.sellPremiumService({
          devices: [device],
          customer,
          service,
          wallet,
          startsAt: new Date()
        })

        this.$emit('purchase', { customer, service, wallet, device })
        this.ok()
      }
    }
  }
}

</script>

<template>
  <sc-dialog persistent :dialog="dialog" :maximized="isSmallScreen" @dialogShown="dialogShown()"
    @dialogHidden="dialogHidden()">

    <q-form class="form" ref="form">
      <div class="content">

        <q-banner class="banner bg-indigo-6">
          <div class="row items-center">
            <span class="text-white title">
              Buy premium services for {{ getDeviceLabel(device) }}
            </span>
          </div>
        </q-banner>

        <div class="q-mt-md q-ml-lg q-mr-lg q-mb-lg inside">
          <div class="text-body2 column">
            <span v-if="balance === 0" class="text-orange-7">
              Your wallet is empty.
            </span>
            <span v-else>
              You have
              <b>
                {{ balance }} {{ balance === 1 ? 'token' : 'tokens' }}
              </b>
              in your wallet.
            </span>

            <!-- If wallet is not empty, proceed with purchase -->
            <div v-if="myWallet.balance > 0" class="q-mt-md">
              <span>
                Select premium service to activate on the device:
              </span>

              <sc-premium-service-selector class="q-mt-md" v-model="premiumServiceId"
                :items="premiumServices" label="Select a premium service">
              </sc-premium-service-selector>

              <div v-if="premiumService" class="q-mt-md">
                <div v-if="canAffordPurchase">
                  You're buying {{ capitalize(premiumService.periodDescription) }}
                  of <b>{{ premiumService.label }}</b>.
                  The price is <b>{{ premiumService.priceLabel }}</b>.
                </div>
                <div v-else class="text-orange-7">
                  Unfortunately you cannot afford the selected premium service.
                  Please contact your reseller to purchase more tokens!
                </div>
              </div>
            </div>

            <!-- Otherwise encourage to buy tokens -->
            <div v-else class="q-mt-md">
              Unfortunately you cannot buy any premium services now.
              Please contact your reseller to purchase more tokens!
            </div>
          </div>
        </div>

        <div class="footer row justify-end q-ma-lg">
          <q-btn :label="canAffordPurchase ? 'Cancel' : 'Close'" flat unelevated @click="cancel()">
          </q-btn>
          <q-btn label="Buy services" v-if="canAffordPurchase" flat unelevated
            class="q-ml-md primary" @click="purchaseService()">
          </q-btn>
        </div>
      </div>

    </q-form>

  </sc-dialog>
</template>

<style lang='scss' scoped>
.form {
  width: 650px;
  min-height: 400px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .banner {
      flex: 0;
    }

    .inside {
      flex: 1;
      overflow: auto;
    }

    .footer {
      flex: 0;
    }

    .title {
      font-size: 18px;
    }
  }
}

/* Layout adjustments for screen below HD resolution */
@media screen and (max-width: 1365px) {
  .q-dialog.fullscreen {
    top: 40px;
  }

  .form {
    width: 100%;
    min-height: 100%;
  }
}
</style>
