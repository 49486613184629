/**
 * Removes query part from the specified URL
 * @param {String} s URL to parse
 * @returns {String} URL without query part
 */
export function removeQuery (s) {
  if (s != null) {
    const url = s.toString().trim()
    const index = url.indexOf('?')
    return index === -1
      ? url
      : url.substring(0, index)
  }
}

/**
 * Extracts query parameters from a string
 * @param {String} url URL to parse
 * @returns {Object} Dictionary of query parameters, if any are present
 */
export function getQueryParameters (url) {
  if (url) {
    const query = /(?<=\?).*/.exec(url)
    if (query && query[0]) {
      const parameters = query[0]
        .split('&')
        .map(param => param.split('='))
        .filter(([name]) => name.trim())
        .reduce((all, [name, value]) => ({ ...all, [name.trim()]: (value || '').trim() }), {})

      return Object.keys(parameters).length > 0 ? parameters : undefined
    }
  }
}

/**
 * Returns true if specified link is valid
 */
export function isValidUrl (url) {
  // eslint-disable-next-line
  const validator = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
  return url
    ? validator.test(url.toString())
    : false
}