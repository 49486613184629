import { safeParseFloat, sameArrays } from '@stellacontrol/utilities'
import { PlanItemType, PlanItem } from './plan-item'

/**
 * Radiation beam
 */
export class PlanBeam extends PlanItem {
  constructor (data = {}) {
    super(data)
    this.assign(data)
  }

  /**
   * Item type
   * @type {PlanItemType}
   */
  static get type () {
    return PlanItemType.Beam
  }

  /**
   * Creates an antenna
   * @param {Object} data Item data
   * @returns {PlanBeam}
   */
  static create (data = {}) {
    return new PlanBeam({ ...data })
  }

  /**
   * Object defaults
   */
  get defaults () {
    // General defaults
    const defaults = {
      ...super.defaults,
      blurRadius: 12,
      opacity: 0.8,
      radius: 100,
      beam: 360,
      blendMode: 'multiply',
      gradient:[0, 'red', 0.4, 'yellow', 0.6, 'greenyellow', 1.0, 'white']
      // gradient: [0, 'red', 0.4, 'yellow', 0.6, 'greenyellow', 0.8, '#d6fca4', 1.0, 'transparent']
    }

    return defaults
  }

  normalize () {
    super.normalize()
    const { defaults } = this
    this.opacity = Math.min(1, Math.max(0, safeParseFloat(this.opacity, defaults.opacity)))
    this.beam = Math.min(360, Math.max(0, safeParseFloat(this.beam, defaults.beam)))
    this.blendMode = this.blendMode || defaults.blendMode
    this.gradient = (this.gradient || defaults.gradient)
  }

  /**
   * Serializes the plan item to JSON
   * @returns {Object}
   */
  toJSON () {
    const { defaults } = this
    const result = super.toJSON()
    if (sameArrays(this.gradient, defaults.gradient)) {
      delete result.gradient
    }
    return result
  }

  /**
   * Beam opacity
   * @type {Number}
   */
  opacity

  /**
   * Beam in degrees
   * @type {Number}
   */
  beam

  /**
   * Beam radius
   * @type {Number}
   */
  radius

  /**
   * Indicates an omnidirectional beam
   * @type {Boolean}
   */
  get isOmnidirectional () {
    return this.beam === 360
  }

  /**
   * Indicates a straight beam
   * @type {Boolean}
   */
  get isStraight () {
    return this.beam <= 1
  }

  /**
   * Resizes the beam
   * @param {Number} width New width
   */
  resize (width) {
    if (this.canResize) {
      this.radius = width == null ? this.radius : width / 2
    }
  }

  /**
   * Resizes the beam by the specified delta
   * @param {Number} width New width
   */
  resizeBy (width) {
    if (this.canResize) {
      if (width != null) {
        this.resize((this.radius * 2) + width)
      }
    }
  }
}
