/**
 * Message types handled by the parser
 */
export const MessageType = {
  /**
   * Generic message
   */
  Generic: 'generic',
  /**
   * Message carrying status of a connected device
   */
  DeviceStatus: 'status',
  /**
   * Message carrying changes to device settings
   */
  DeviceSettings: 'settings',
  /**
   * Message carrying device command
   */
  DeviceCommand: 'command',
  /**
   * Message carrying device settings request.
   * The platform will send device settings in response.
   */
  DeviceSettingsRequest: 'settings-request',
  /**
   * Message carrying scan results
   */
  ScanResults: 'ttscan',
  /**
   * Message carrying a binary file using HERMES protocol
   */
  HermesFile: 'hermes',
  /**
   * Message carrying HERMES file acknowledgement
   */
  HermesAcknowledgement: 'hermes-ack',
  /**
   * Message carrying information about alert configuration changes
   */
  AlertConfigurationChanged: 'alert-configuration-changed',
  /**
   * Message telling that device started being actively used by the customer
   */
  DeviceActivated: 'device-activated',
  /**
   * Device alert message
   */
  DeviceAlert: 'device-alert',
  /**
   * Notification message
   */
  Notification: 'notification',
  /**
   * Device Flags message
   */
  DeviceFlags: 'device-flags',
  /**
   * Device Logs message
   */
  DeviceLog: 'device-log'
}

/**
 * Message type descriptions
 */
export const MessageTypeDescription = {
  [MessageType.DeviceStatus]: 'Device Status',
  [MessageType.DeviceSettings]: 'Device Settings',
  [MessageType.DeviceCommand]: 'Device Command',
  [MessageType.DeviceSettingsRequest]: 'Device Settings Request',
  [MessageType.ScanResults]: 'Scan Results',
  [MessageType.HermesFile]: 'Hermes File',
  [MessageType.HermesAcknowledgement]: 'Hermes File Acknowledgement',
  [MessageType.AlertConfigurationChanged]: 'Alert Configuration Changed',
  [MessageType.DeviceActivated]: 'Device Activated',
  [MessageType.DeviceAlert]: 'Device Alert',
  [MessageType.Notification]: 'Notification',
  [MessageType.DeviceFlags]: 'Device Flags',
  [MessageType.DeviceLog]: 'Device Log'
}
