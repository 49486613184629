import { mapActions } from 'vuex'

/**
 * Vue mixin for editor views which contain one or more input forms.
 * Requires that all these forms are decorated with `form-mixin`.
 * Provides functionality for global validation etc.
 * Assumes that component has `data` property, which might be introduced
 * with ViewMixin as well, if editor is a route view.
 */
export const EditorMixin = {
  computed: {
    // Indicates that editor mixin has been introduced
    hasEditorMixin () {
      return true
    },

    // List of all forms in the component.
    // Override in your own forms component
    // if there are more forms on it.
    forms () {
      return ['form']
    }
  },

  methods: {
    ...mapActions([
      'goBack'
    ]),

    /**
     * Returns a form with the specified reference
     * @param ref Form reference
     */
    form (ref) {
      return this.$refs[ref]
    },

    /**
     * Validates all forms.
     * Presumes presence of computed property `forms`
     * returning a list of refs of all forms in the component
     */
    validate () {
      const validators = this.forms
        .map(ref => this.form(ref))
        .map(form => form ? form.validate() : true)
      return Promise
        .all(validators)
        .then(values => values.every(value => Boolean(value)))
    },

    /**
     * Resets all forms after validation
     */
    reset () {
      const resetters = this.forms
        .map(ref => this.form(ref))
        .map(form => form ? form.reset() : true)
      return Promise.all(resetters)
    },

    /**
     * Navigates to the previous view
     */
    cancel () {
      this.goBack()
    }
  }
}
