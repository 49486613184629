import { isEnum } from '@stellacontrol/utilities'

/**
 * Location of an element in relation to another element
 */
export const PlanLocation = {
  Top: 'top',
  Right: 'right',
  Bottom: 'bottom',
  Left: 'left',
  Center: 'center'
}

/**
 * Parses locations from space-separated string to list of {@link PlanLocation} values
 * @param {String} data Space-separated list of locations
 * @returns {Array[PlanLocation]}
 */
export function deserializePlanLocations (data) {
  if (data) {
    const items = data
      .split(' ')
      .map(i => i.trim())
      .filter(i => isEnum(PlanLocation, i))
    return items
  }
}

/**
 * Converts locations to space-separated string
 * @param {Array[PlanLocation]} data
 * @returns {String}
 */
export function serializePlanLocations (data) {
  if (Array.isArray(data)) {
    return data.filter(i => isEnum(PlanLocation, i)).join(' ')
  }
}
