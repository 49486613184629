import { serialize } from './object'
import { extractTagContent, replaceTag } from './html'

/**
 * Simple template fill utility, using JS string interpolation
 * @param {String} text Template text
 * @param {Object} data Data referred to in the template
 * @returns {String} Filled template
 * @example
 * const mailText = template(
 *   '<script>const msg = 'Welcome'</script><p>${msg}, ${user}!</p> <p>Your ${application} account has been created.</p>',
 *  {
 *    user: 'Tomasz',
 *    application: 'StellaControl'
 *  })
 */
export function fill (text, data) {
  if (text == null) return text
  if (data == null) return text

  // Extract script part from template
  const script = extractTagContent(text, 'script') || ''
  const template = replaceTag(text, 'script', '')

  // We create a function evaluating the template.
  // Variables need to be in function's local context.
  const variables = Object
    .entries(data)
    .map(([key, value]) => `let ${key} = ${value == null ? 'null' : serialize(value, true, true)};`)
    .join('\n')

  // A bunch of useful functions for use within templates
  const functions = `
      const cssHide = condition => Boolean(condition) ? 'display: none' : '';
      const cssShow = condition => Boolean(condition) ? '' : 'display: none';
      const parseDate = s => s ? new Date(Date.parse(s.toString())) : null;
      const string = (s) => s || '';
      const stringToDateTime = (s = '', locale) => s ? parseDate(s).toLocaleString(locale) : '';
      const stringToFullDateTime = (s = '', locale) => s ? parseDate(s).toLocaleString(locale, { dateStyle: 'medium', timeStyle: 'medium' }) : '';
      const stringToDate = (s = '', locale) => s ? parseDate(s).toLocaleDateString(locale) : '';
      const stringToFullDate = (s = '', locale) => s ? parseDate(s).toLocaleDateString(locale, { dateStyle: 'medium' }) : '';
      const stringToTime = (s = '', locale) => s ? parseDate(s).toLocaleTimeString(locale) : '';
      const now = () => new Date().toLocaleString();
      const date = () => new Date().toDateString();
      const time = () => new Date().toTimeString();
      const capitalize = (s = '') => s.substring(0, 1).toUpperCase() + s.substring(1).toLowerCase();
    `

  const body = variables + '\n\n' + functions + '\n\n' + script + '\n\nreturn `' + template + '`'

  // Tell eslint to buzz off, we know what we're doing here.
  // eslint-disable-next-line no-new-func
  const filler = new Function(body)
  return filler.call(data)
}

