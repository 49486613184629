import { parseDate } from '@stellacontrol/utilities'
import { DeviceAlertStatistics } from './device-alert-statistics'

/**
 * Statistics of band power values kept for each device,
 * used as baseline for detecting sustained reduced power alert
 */
export class BandPowerStatistics extends DeviceAlertStatistics {
  constructor (data = {}) {
    super(data)
    this.assign(data)
    this.count = this.count || 0
    this.sums = this.sums || {}
    this.normal = this.normal || {}
    this.first = parseDate(this.first)
    this.last = parseDate(this.last)
    this.parameter = 'mean_dw'
  }

  /**
   * Number of datapoints collected to establish
   * the normal power level
   * @type {Number}
   */
  count

  /**
   * Timestamp of the first datapoint used to establish the normal power level
   * @type {Date}
   */
  first

  /**
   * Timestamp of the last datapoint used to establish the normal power level
   * @type {Date}
   */
  last

  /**
   * Collected sums of values of signal, per band
   * @type {Dictionary<BandIdentifier, Number>}
   */
  sums

  /**
   * Calculated normal values of signal, per band,
   * acquired during the initial learning phase
   * @type {Dictionary<BandIdentifier, Number>}
   */
  normal

  /**
   * Adds a data point, the value of {@param parameter} for each band,
   * extracted from device status
   */
  addBandValues (status, requiredCount) {
    if (requiredCount) {
      this.requiredCount = requiredCount
    } else {
      requiredCount = this.requiredCount
    }

    const { bands, hasDeviceConnected } = status || {}
    if (!hasDeviceConnected) return
    if (!bands) return
    const bandStatus = Object.values(bands.status)
    if (bandStatus.length === 0) return

    // Store timestamp of the first collected status
    if (!this.first) {
      this.first = status.receivedAt
    }

    // If normal not ready yet, accumulate.
    // Calculate sums and normal averages of the power parameter per band.
    if (this.count < requiredCount) {
      this.last = status.receivedAt
      this.count++
      for (const { identifier, values } of bandStatus) {
        const value = values[this.parameter] || 0
        this.sums[identifier] = (this.sums[identifier] || 0) + value
        this.normal[identifier] = Math.round(this.sums[identifier] / this.count)
      }
    } else {
      super.addBandValues(status, requiredCount)
    }

    // Make sure the counter stays limited.
    // If we increase the limit, we will start accumulating additional values.
    this.count = Math.min(this.count, requiredCount)
  }

  /**
   * Checks whether statistics is ready to be used
   * for checking alerts and other purposes
   * @param {Number} count Minimal required number of datapoints
   * @returns {Boolean}
   */
  isReady (count) {
    count = count || this.requiredCount
    return super.isReady(count) &&
      this.count >= count
  }

  /**
   * Clears the data points
   */
  clear () {
    super.clear()
    this.count = 0
    this.sums = {}
    this.normal = {}
    this.first = undefined
    this.last = undefined
  }
}
