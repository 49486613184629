<script>
import { mapState, mapActions } from 'vuex'

export default {
  props: {
    // Grid column
    column: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapState({
      // All tag definitions
      deviceTags: state => state.inventoryView.deviceTags,
      // Grid column filters
      gridFilters: state => state.inventoryView.gridFilters
    })
  },

  methods: {
    ...mapActions([
      'filterInventory'
    ])
  }
}
</script>

<template>
  <q-select
    dense
    borderless
    hide-dropdown-icon
    :model-value="gridFilters[column.name]"
    :multiple="column.multiple"
    :options="column.options"
    @update:model-value="value => filterInventory({ column, value })"
  >
    <template v-slot:selected>
      <q-icon
        size="19px"
        style="margin-left: 2px; margin-top: 2px;"
        :name="(deviceTags[gridFilters[column.name]] || {}).iconOn || 'local_offer'"
        :color="(deviceTags[gridFilters[column.name]] || {}).colorOn || 'grey-6'"
      />
    </template>
    <template v-slot:option="scope">
        <q-item clickable v-close-popup dense class="select-option items-center" v-bind="scope.itemProps">
          <q-icon
            :name="(deviceTags[scope.opt] || {}).iconOn || 'label'"
            :color="(deviceTags[scope.opt] || {}).colorOn || 'grey-6'"
            size="20px"
          />
          <span class="q-ml-sm">
            {{scope.opt}}
          </span>
        </q-item>
    </template>
  </q-select>
</template>

<style>
</style>