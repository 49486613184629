import { resolveDeviceFirmwares, resolveDeviceFirmware, resolveJobUploads } from './device-updates.resolve'
import DeviceUpdates from './device-updates.vue'
import Firmware from './firmware.vue'

export const Routes = [
  {
    name: 'device-updates',
    path: '/administration/device-updates',
    component: DeviceUpdates,

    async beforeEnter (to, from, next) {
      const { tab } = to.query || {}
      if (tab === 'firmware') {
        await resolveDeviceFirmwares({ from, to })
      } else {
        await resolveJobUploads({ to, from })
      }
      next()
    }
  },

  {
    name: 'firmware',
    path: '/administration/device-updates/firmware/:id',
    component: Firmware,

    async beforeEnter (to, from, next) {
      if (await resolveDeviceFirmware({ from, to })) {
        next()
      }
    }
  }
]
