<script>
import { mapActions } from 'vuex'
import { DialogMixin, FormMixin } from '@stellacontrol/client-utilities'
import { StandardDeviceBands, StandardDeviceBandsNames, NonConnectedDeviceTypes, DeviceName } from '@stellacontrol/model'

const dialog = 'non-connected-device'

export default {
  mixins: [
    DialogMixin,
    FormMixin,
  ],

  data () {
    return {
      dialog
    }
  },

  computed: {
    // Options for device type select
    deviceTypes: function () {
      return NonConnectedDeviceTypes.map(type => ({
        label: DeviceName[type],
        value: type
      }))
    },

    // Options for device band select
    deviceBands: function () {
      return Object
        .values(StandardDeviceBands)
        .map((value) => ({ label: StandardDeviceBandsNames[value], value }))
    }
  },

  methods: {
    ...mapActions([
      'dialogOk',
      'dialogCancel',
      'getDeviceBySerialNumber'
    ]),

    // Validation rule which checks whether the specified serial number is already in use
    async serialNumberIsUnique (serialNumber) {
      const device = await this.getDeviceBySerialNumber({ serialNumber })
      return !device || `Device ${serialNumber} already exists`
    },

    /**
     * Validates and OKs the dialog
     */
    async ok () {
      if (await this.$refs.form.validate()) {
        this.dialogOk({ dialog })
      }
    },

    /**
     * Cancels the dialog
     */
    cancel () {
      this.dialogCancel({ dialog })
    }
  }
}
</script>

<template>
  <sc-dialog dialog="non-connected-device" persistent v-if="isVisible">
    <template #default="{ data }">
      <q-form autofocus ref="form" class="non-connected-device q-pa-md q-mt-md">
        <div class="text-h6 q-pb-md">
          Add new device
        </div>

        <div class="q-mb-md">
          Add a <b>NON-CONNECTED</b> device
          {{ data.place ? 'to ' + data.place.placeType : '' }}
          <b>{{ data.place ? data.place.name : '' }}</b>
          in <b>{{ data.organization.name }}</b>
        </div>

        <div class="column q-gutter-md">
          <q-input square outlined dense hide-bottom-space autofocus
            label="Unique name"
            v-model="data.device.serialNumber"
            debounce="1000"
            maxlength="45"
            lazy-rules
            :rules="[
              rules.required('Unique name or serial number is required'),
              value => serialNumberIsUnique(value)
            ]">
          </q-input>

          <q-select
            square outlined dense
            bg-color="white"
            label="Type"
            v-model="data.device.type"
            :options="deviceTypes"
            emit-value
            map-options
          />

          <q-select
            square outlined dense
            bg-color="white"
            label="Bands"
            v-model="data.device.bands"
            :options="deviceBands"
            emit-value
            map-options
          />

          <q-input square outlined dense
            type="textarea"
            clearable
            clear-icon="close"
            bg-color="white"
            label="Notes"
            v-model="data.device.notes" />

          <div class="row q-mb-lg q-gutter-md justify-end">
            <q-btn flat color="indigo-6" label="Close" @click="cancel()"></q-btn>
            <q-btn size="md" color="primary" label="Save" @click="ok()"></q-btn>
          </div>
        </div>
      </q-form>
    </template>
  </sc-dialog>
</template>

<style scoped lang="scss">
.non-connected-device {
  min-width: 550px;
}
</style>