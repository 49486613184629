<script>
import { mapActions } from 'vuex'
import { formatDateTime, capitalize } from '@stellacontrol/utilities'
import { ViewMixin } from '@stellacontrol/client-utilities'
import { resolve } from './bug-report.resolve'

const name = 'bug-report'

export default {
  mixins: [
    ViewMixin
  ],

  data () {
    return {
      name,
      formatDateTime,
      capitalize,
      tab: 'general'
    }
  },

  computed: {
    // Bug details
    bug () {
      return this.data
    },

    // URL of a request, if bug is an API request error
    requestUrl () {
      const { bug } = this
      if (bug && bug.request) {
        const params = Object
          .entries(bug.request.params || {})
          .map(([key, value]) => `${key}=${value}`)
          .join('&')
        return [
          bug.request.method.toUpperCase(),
          ' ',
          bug.request.url,
          params ? '?' : null,
          params
        ].filter(s => s)
          .join('')
      }
    },

    // View title
    title () {
      const { createdAt, creator, type, location } = this.bug
      return `${capitalize(location)} ${type} reported by ${creator.name} on ${formatDateTime(createdAt)}`
    }
  },

  methods: {
    ...mapActions([
      'busy',
      'done',
      'sendBugReport'
    ]),

    // Sends bug report to system administrator
    async send () {
      const { bug } = this
      this.busy({ message: 'Sending bug report ...' })
      await this.sendBugReport({ bug })
      this.done({ message: 'Bug report has been sent', details: 'Thank you!' })
    }
  },

  // Reload data on navigation to another entity
  async beforeRouteUpdate (to, from, next) {
    if (await resolve(to, from)) {
      next()
    }
  }
}

</script>

<template>
  <sc-view :name="name" :title="title">

    <template #toolbar>
      <q-btn label="Send Bug Report" icon="outgoing_mail" class="primary" unelevated
        @click="send()">
      </q-btn>
    </template>

    <template #header>
    </template>

    <div class="q-ma-lg details">
      <q-tabs v-model="tab" align="left" inline-label no-caps class="bg-indigo-1 text-grey-9">
        <q-tab icon="bug_report" name="general" label="Details">
        </q-tab>
        <q-tab icon="chevron_right" name="request" label="Request" v-if="bug.request">
        </q-tab>
        <q-tab icon="chevron_left" name="response" label="Response" v-if="bug.response">
        </q-tab>
      </q-tabs>

      <q-tab-panels v-model="tab">
        <q-tab-panel name="general" label="Details">
          <q-markup-table flat borderless dense>
            <tbody>
              <tr>
                <td>
                  ID
                </td>
                <td>
                  {{ bug.id }}
                </td>
              </tr>

              <tr v-if="bug.linkedBugId">
                <td>
                  Related to
                </td>
                <td>
                  <span v-if="bug.linkedBug" class="q-mr-xs">
                    {{ capitalize(bug.linkedBug.location) }} {{ bug.linkedBug.type }}
                  </span>
                  <router-link :to="{ name: 'bug-report', params: { id: bug.linkedBugId } }">
                    {{ bug.linkedBugId }}
                  </router-link>
                </td>
              </tr>

              <tr>
                <td>
                  Time
                </td>
                <td>
                  {{ dateTimeString(bug.createdAt) }}
                </td>
              </tr>

              <tr>
                <td>
                  Message
                </td>
                <td>
                  {{ bug.message }}
                </td>
              </tr>

              <tr>
                <td>
                  Type
                </td>
                <td>
                  {{ capitalize(bug.type) }}
                </td>
              </tr>

              <tr v-if="bug.creator & bug.creator.organization">
                <td>
                  Organization
                </td>
                <td>
                  {{ bug.creator.organization.fullName }}
                </td>
              </tr>

              <tr v-if="bug.creator">
                <td>
                  User
                </td>
                <td>
                  {{ bug.creator.fullName }} ({{ bug.creator.email }})
                </td>
              </tr>

              <tr v-if="bug.creator">
                <td>
                  Organization ID
                </td>
                <td>
                  {{ bug.creator.organizationId }}
                </td>
              </tr>

              <tr>
                <td>
                  User ID
                </td>
                <td>
                  {{ bug.createdBy }}
                </td>
              </tr>

              <tr>
                <td>
                  Origin
                </td>
                <td>
                  {{ capitalize(bug.location) }}
                </td>
              </tr>

              <tr v-if="bug.url">
                <td>
                  URL
                </td>
                <td>
                  <a :href="bug.url">
                    {{ bug.url }}
                  </a>
                </td>
              </tr>

              <tr v-if="bug.platform">
                <td>
                  Platform
                </td>
                <td>
                  {{ bug.platform.text }}
                </td>
              </tr>

              <tr v-if="bug.fileName">
                <td>
                  File
                </td>
                <td>
                  {{ bug.fileName }}
                </td>
              </tr>

              <tr v-if="bug.line">
                <td>
                  Location
                </td>
                <td>
                  {{ bug.line }}:{{ bug.column }}
                </td>
              </tr>

              <tr v-if="bug.source">
                <td>
                  Source
                </td>
                <td>
                  <pre v-html="bug.source.replace(/\n/g, '<br>')"></pre>
                </td>
              </tr>

              <tr v-if="bug.stack">
                <td>
                  Stack
                </td>
                <td>
                  <pre v-html="bug.stack.replace(/\n/g, '<br>')"></pre>
                </td>
              </tr>

            </tbody>
          </q-markup-table>
        </q-tab-panel>

        <q-tab-panel name="request" label="Request" v-if="bug.request">
          <q-markup-table flat borderless dense>
            <tbody>
              <tr>
                <td>
                  Request URL
                </td>
                <td colspan="2">
                  {{ requestUrl }}
                </td>
              </tr>
              <tr v-for="entry, index in Object.entries(bug.request.headers || {})">
                <td>
                  <span v-if="(index == 0)">
                    Request Headers
                  </span>
                </td>
                <td>
                  {{ entry[0] }}
                </td>
                <td class="value">
                  {{ entry[1] }}
                </td>
              </tr>
              <tr v-if="bug.request.data">
                <td>
                  Request Data
                </td>
                <td colspan="2">
                  <textarea readonly
                    class="code">{{ JSON.stringify(bug.request.data, null, 2) }}</textarea>
                </td>
              </tr>
            </tbody>
          </q-markup-table>
        </q-tab-panel>

        <q-tab-panel name="response" label="Response" v-if="bug.response">
          <q-markup-table flat borderless dense>
            <tbody>
              <tr>
                <td>
                  Response Status
                </td>
                <td>
                  {{ bug.response.status }}
                </td>
              </tr>
              <tr>
                <td>
                  Status Text
                </td>
                <td>
                  {{ bug.response.statusText }}
                </td>
              </tr>
              <tr v-if="bug.response.data">
                <td>
                  Response Data
                </td>
                <td colspan="2">
                  <textarea readonly
                    class="code">{{ JSON.stringify(bug.response.data, null, 2) }}</textarea>
                </td>
              </tr>
            </tbody>
          </q-markup-table>
        </q-tab-panel>

      </q-tab-panels>
    </div>

  </sc-view>
</template>

<style lang='scss' scoped>
.details {
  flex: 1;
  overflow: auto;
  border: solid #0000001f 1px;

  td {
    vertical-align: top;

    &:first-child {
      width: 150px;
    }

    max-width: 600px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  textarea.code {
    border: solid #0000001f 1px;
    padding: 8px;
    width: 100%;
    font-family: 'Courier New', Courier, monospace;
    min-height: 500px;
    outline: 0;
  }
}
</style>
