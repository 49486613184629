import { ListViewMode, Confirmation, Notification } from '@stellacontrol/client-utilities'
import { UserLevel } from '@stellacontrol/model'
import { SecurityAPI } from '@stellacontrol/client-api'
import { User } from '@stellacontrol/model'
import { SecurityRules } from '@stellacontrol/security'
import { AppletRoute } from '../../router'

export const actions = {
  /**
   * Initialize the users store
   */
  async initializeApplet ({ dispatch }) {
    // Initialize lists
    await dispatch('initializeList', [
      { name: 'users', viewMode: ListViewMode.Normal }
    ])
  },

  /**
   * Retrieves users of the currently logged in organization
   */
  async getUsers ({ commit, dispatch, getters }) {
    const organizationId = getters.currentOrganizationId
    if (organizationId) {
      await dispatch('loading')
      const items = await SecurityAPI.getUsers({ organizationId })
      const currentUser = items.find(user => user.id === getters.currentUser.id)
      if (currentUser) {
        currentUser.isCurrentUser = true
      }
      commit('storeUsers', { items })
      await dispatch('done')
      return items
    } else {
      return []
    }
  },

  /**
   * Retrieves users if not retrieved yet
   */
  async requireUsers ({ state, dispatch }) {
    if (!state.items) {
      return await dispatch('getUsers')
    }
  },

  /**
   * Retrieves the specified user, with all details such as permissions, creator etc.
   * @param id User identifier
   */
  async getUser ({ commit, dispatch }, { id } = {}) {
    if (!id) throw new Error('User identifier is required')

    await dispatch('loading')
    const user = await SecurityAPI.getUser({ id, withDetails: true, withParents: true })
    commit('storeUser', { user })
    await dispatch('done')
    return user
  },

  /**
   * Returns a new user
   * @param user Initial properties of the user
   */
  async newUser ({ dispatch, getters }, { user } = {}) {
    await dispatch('loading')
    // Currently logged in organization is default parent
    const organization = (user ? user.organization : undefined) || getters.currentOrganization
    if (!organization) {
      throw new Error('User must have an organization assigned')
    }

    // Create new user, assign to organization
    const data = new User({
      ...user,
      organization,
      organizationId: organization.id,
      level: UserLevel.Administrator
    })
    await dispatch('done')
    return data
  },

  /**
   * Checks whether the specified user exists
   * @param name User name
   */
  async userExists (_, { name } = {}) {
    if (name) {
      const { id, exists } = await SecurityAPI.userExists({ name })
      return { id, exists }
    } else {
      return { exists: false }
    }
  },

  /**
   * Edits a new user
   * @param organization Optional parent organization,
   * if it's to be different than the current one
   */
  async createUser ({ dispatch }, { organization } = {}) {
    const query = organization ? { organization: organization.id } : {}
    await dispatch('gotoRoute', { name: AppletRoute.User, params: { id: 'new' }, query })
  },

  /**
   * Shows a list of users where users can be added and edited
   */
  async editUsers ({ dispatch }) {
    await dispatch('gotoRoute', { name: AppletRoute.Users })
  },

  /**
   * Edits an existing user
   * @param id User to edit
   */
  async editUser ({ dispatch }, { user: { id }, tab } = {}) {
    if (!id) throw new Error('User identifier is required')
    await dispatch('gotoRoute', { name: AppletRoute.User, params: { id }, query: { tab } })
  },

  /**
   * Saves an user
   * @param user User to add
   * @param silent If true, no notifications will be shown
   */
  async saveUser ({ commit, dispatch }, { user, silent } = {}) {
    if (!user) throw new Error('User is required')

    await dispatch('busy', { message: `Saving user ${user.fullName} ...`, data: user, silent })
    const { isNew } = user
    user = await SecurityAPI.saveUser({ user })
    if (user) {
      const { error } = user
      if (error) {
        await dispatch('done')
        const { message } = user
        Notification.error({ message, details: 'Error saving the user', silent })

      } else {
        const message = isNew ? `User ${user.fullName} has been created` : `User ${user.fullName} has been saved`
        const details = isNew ? `Invitation message has been sent to ${user.email}.` : undefined

        commit('storeUser', { user })
        await dispatch('done', { message, details, data: user, silent })
        return user
      }
    }
  },

  /**
   * Removes an user
   * @param confirm If true, user has to confirm
   * @param user User to remove
   * @param silent If true, no notifications will be shown
   */
  async removeUser ({ commit, dispatch, getters }, { confirm = true, user: { id }, silent } = {}) {
    if (!id) throw new Error('User is required')

    const { currentUser } = getters
    const user = await SecurityAPI.getUser({ id, withDetails: true })
    if (!user) throw new Error(`User ${id} no longer exists`)

    // Check if allowed to reset the password of the user
    const { canDeleteUser, reason } = SecurityRules.canDeleteUser(currentUser, user)
    if (!canDeleteUser) {
      Notification.error({ message: reason, silent })
      return
    }

    const yes = await Confirmation.ask({
      title: 'Delete',
      message: `Delete user ${user.name}?`,
      confirm
    })

    if (yes) {
      await dispatch('busy', { message: `Deleting user ${user.fullName} ...`, data: user, silent })
      const result = await SecurityAPI.deleteUser({ user })
      await dispatch('done', { message: `User ${user.fullName} has been deleted`, data: user, silent })
      commit('removeUser', { user })
      return result
    }
  },

  /**
   * Sends a reset password e-mail to the user
   * @param user User whose password is to be reset
   * @param confirm If true, confirmation is required
   * @param silent If true, no notifications will be shown
   */
  async resetPassword ({ dispatch, getters }, { user, confirm = true, silent } = {}) {
    if (!user) throw new Error('User is required')

    // Check if allowed to reset the password of the user
    const { currentUser } = getters
    const { canChangePassword, reason } = SecurityRules.canChangePassword(currentUser, user)
    if (!canChangePassword) {
      Notification.error({ message: reason, silent })
      return
    }

    // Vary the message depending on whether resetting own or someone else's password
    const resettingOwnPassword = user.sameAs(currentUser)
    const message = resettingOwnPassword
      ? 'Do you want to reset your password?'
      : `Reset password for user ${user.name}?`
    const yes = await Confirmation.ask({ title: 'Reset password', message, confirm })

    if (yes) {
      await dispatch('busy', { message: `Resetting password of ${user.fullName} ...`, data: user, silent })
      const { mailSent, pendingRequest, timeToExpire } = await SecurityAPI.resetPassword({ idOrName: user.id, sendEmail: true })
      const timeToExpireText = timeToExpire < 2
        ? 'another minute'
        : `another ${timeToExpire} minutes`
      if (pendingRequest) {
        const warning = 'You have already requested a password reset'
        const details = `Check your mailbox for further instructions <br> or wait ${timeToExpireText} and try again`
        await dispatch('done', { warning, details, silent })
      } else {
        const message = resettingOwnPassword
          ? 'Your password has been reset'
          : 'User password has been reset'
        const details = resettingOwnPassword
          ? (mailSent ? `Look for an e-mail with further instructions in your ${user.email} mailbox.` : '')
          : (mailSent ? `An e-mail with further instructions was sent to ${user.email}.` : '')
        await dispatch('done', { message, details, silent })
      }
    }
  },

  /**
   * Sends an invitation email to the user, to activate his account
   * @param user User to invite
   * @param confirm If true, confirmation is required
   * @param silent If true, no notifications will be shown
   */
  async inviteUser ({ dispatch, getters }, { user, confirm = true, silent } = {}) {
    if (!user) throw new Error('User is required')

    // Check if allowed to reset the password of the user
    const { currentUser } = getters
    const { canChangePassword, reason } = SecurityRules.canChangePassword(currentUser, user)
    if (!canChangePassword) {
      Notification.error({ message: reason, silent })
      return
    }

    // Vary the message depending on whether resetting own or someone else's password
    const message = `Send account activation e-mail to user ${user.name}?`
    const yes = await Confirmation.ask({ title: 'Invite user', message, confirm })

    if (yes) {
      await dispatch('busy', { message: `Inviting ${user.fullName} ...`, data: user, silent })
      if (await SecurityAPI.inviteUser({ id: user.id })) {
        const message = 'User invited!'
        const details = `An e-mail with further instructions was sent to ${user.email}.`
        await dispatch('done', { message, details, silent })
      }
    }
  },

  /**
   * Sends a reset password e-mail to a user trying to log in
   * @param name Name of the user whose password is to be reset
   * @param reCaptchaToken reCaptcha token obtained from Google reCaptcha service during interactive session
   * @param confirm If true, confirmation is required
   * @param silent If true, no notifications will be shown
   */
  async anonymousResetPassword ({ dispatch }, { name, reCaptchaToken, silent } = {}) {
    if (!name) throw new Error('User name is required')

    await dispatch('busy', { message: `Resetting password of ${name} ...`, data: name, silent })
    const { success, mailSent, pendingRequest, timeToExpire } = await SecurityAPI.resetPassword({ idOrName: name, sendEmail: true, reCaptchaToken })
    const timeToExpireText = timeToExpire < 2
      ? 'another minute'
      : `another ${timeToExpire} minutes`
    if (success) {
      const message = 'Your password has been reset'
      const details = mailSent ? 'Look for an e-mail with further instructions in your mailbox.' : ''
      await dispatch('done', { message, details, silent })
    } else if (pendingRequest) {
      const warning = 'You have recently requested password reset'
      const details = `Check your mailbox for further instructions <br> or wait ${timeToExpireText} and try again`
      await dispatch('done', { warning, details, silent })
    } else {
      const warning = 'Password could not be reset'
      await dispatch('done', { warning, silent })
    }
  },

  /**
   * Changes user password
   * @param user User whose password is to be changed
   * @param ask If true, asks for a new password for the user
   * @param password If `ask` is false, this specifies the new password to set
   * @param silent If true, no notifications will be shown
   */
  async changePassword ({ commit, dispatch, getters }, { user, ask = true, password, silent } = {}) {
    if (!user) throw new Error('User is required')

    const { currentUser } = getters
    const changingOwnPassword = user.sameAs(currentUser)
    const { canChangePassword, reason } = SecurityRules.canChangePassword(currentUser, user)
    if (!canChangePassword) {
      Notification.error({ message: reason, silent })
      return
    }

    let yes = false
    if (ask) {
      const { isOk, data } = await dispatch('showDialog', { dialog: 'change-password', data: { user, password } })
      if (isOk) {
        yes = true
        password = data.password
      }
    } else {
      yes = true
    }

    if (yes && password && password.trim()) {
      const message = changingOwnPassword
        ? 'Changing your password, please wait ...'
        : `Changing password of ${user.fullName} ...`
      await dispatch('busy', { message, data: user, silent })
      const { token } = await SecurityAPI.resetPassword({ idOrName: user.id, sendEmail: false }) || {}
      if (token) {
        user.isEnabled = true
        const { mailSent } = await SecurityAPI.changePassword({ user, token, password })
        const message = changingOwnPassword
          ? 'Your password has been changed'
          : 'User password has been changed'
        const details = mailSent ? `Notification has been sent to ${user.email}.` : ''
        commit('storeUser', { user })
        await dispatch('done', { message, details, silent })
      } else {
        await dispatch('done')
      }
    }
  },

  /**
   * Sets user password in response to a reset password request
   * @param user User changing the password
   * @param token Request token
   * @param password If `ask` is false, this specifies the new password to set
   * @param reCaptchaToken reCaptcha token obtained from Google reCaptcha service during interactive session
   * @param silent If true, no notifications will be shown
   */
  async setPassword ({ commit, dispatch }, { user, token, password, reCaptchaToken, silent } = {}) {
    if (!token) throw new Error('Request token is required')
    if (!user) throw new Error('User is required')
    if (!password) throw new Error('Password is required')

    if (password.trim()) {
      let message = 'Changing your password, please wait ...'
      await dispatch('busy', { message, data: token, silent })
      const { mailSent } = await SecurityAPI.changePassword({ user, token, password, reCaptchaToken })

      message = 'Your password has been changed'
      const details = mailSent ? `Notification has been sent to ${user.email}.` : ''
      commit('storeUser', { user })
      await dispatch('done', { message, details, silent })
      return true
    }
  },

  /**
   * Activates a user
   * @param token Activation request token
   * @param user User to activate
   * @param silent If true, no notifications will be shown
   */
  async activateUser ({ commit, dispatch }, { user, token, silent } = {}) {
    if (!user) throw new Error('User is required')
    if (!token) throw new Error('Request token is required')

    await dispatch('busy', { message: `Activating user ${user.fullName} ...`, data: user, silent })
    user = await SecurityAPI.activateUser({ user, token, sendEmail: true })

    if (user) {
      const message = `User ${user.fullName} has been activated`
      const details = `Invitation message has been sent to ${user.email}.`
      await dispatch('done', { message, details, data: user, silent })
      commit('storeUser', { user })
      return user
    } else {
      await dispatch('done')
    }
  }
}
