/**
 * Browser storage wrapper
 */
export class StorageService {
  /**
   * @param {Boolean} session If true, data is put in session storage, otherwise in application storage
   * @returns {Storage}
   */
  getStorage (session) {
    const storage = session ? window?.sessionStorage : window?.localStorage
    return storage
  }

  /**
   * Stores data in browser local storage
   * @param {String} key Unique key
   * @param {any} data Data to store
   * @param {Boolean} session If true, data is put in session storage, otherwise in application storage
   */
  put (key, data, session) {
    if (key != null && data != null) {
      const storage = this.getStorage(session)
      if (storage) {
        const serialized = JSON.stringify(data)
        storage.setItem(key.toString(), serialized)
      }
    }
  }

  /**
   * Retrieves data from browser local storage
   * @param {String} key Unique key
   * @param {any} defaultValue Value returned when storage does not contain the specified item
   * @param {Boolean} session If true, data is put in session storage, otherwise in application storage
   * @returns {any}
   */
  get (key, defaultValue, session) {
    if (key != null) {
      const storage = this.getStorage(session)
      if (storage) {
        const serialized = storage.getItem(key.toString())
        if (serialized) {
          try {
            return JSON.parse(serialized)
          } catch {
            return defaultValue
          }
        } else {
          return defaultValue
        }
      }
    }
  }

  /**
   * Clears data from browser local storage
   * @param {String} key Unique key
   * @param {Boolean} session If true, data is put in session storage, otherwise in application storage
   * @returns {any}
   */
  clear (key, session) {
    if (key != null) {
      const storage = this.getStorage(session)
      if (storage) {
        storage.removeItem(key.toString())
      }
    }
  }
}
