import { PlanItemType } from '@stellacontrol/planner'
import { PlanAction, PlanActions } from './plan-action'
import { RemoveItemsAction } from './remove-items'

/**
 * Removes a point from the specified item
 */
export class RemovePointAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.RemovePoint
  }

  /**
   * Indicates that the action can be executed on a batch of items
   * @type {Boolean}
   */
  get allowBatch () {
    return false
  }

  /**
  * Action label
  * @type {String}
  */
  get label () {
    return 'Delete point'
  }

  /**
   * Action icon
   * @type {String}
   */
  get icon () {
    return 'fiber_manual_record'
  }

  /**
   * Action icon color
   * @type {String}
   */
  get color () {
    return 'red-8'
  }

  /**
   * Indicates that the action requires items to act on
   * @type {Boolean}
   */
  get requiresItems () {
    return true
  }

  /**
   * Use this to indicate that action requires refresh
   * of the plan stage area after completion
   * @type {Boolean}
   */
  get requiresRefresh () {
    return true
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   * @param {Array[PlanItem]} items Plan items to apply the action to
   * @param {Number} point Item point to act on
   */
  async execute ({ renderer, items, point } = {}) {
    if (renderer && items) {
      const item = items[0]
      if (item && point != null) {
        renderer.selectPoint()
        item.removePoint(point, renderer.isCrossSection)

        // Check if shape is now incomplete and should be removed
        const isIncomplete = (item.is(PlanItemType.Line) && item.points.length < 2) ||
          (item.is(PlanItemType.Polygon) && item.points.length < 3)

        if (isIncomplete) {
          const remove = new RemoveItemsAction()
          await remove.execute({ renderer, items: [item] })

        } else {
          await renderer.refreshItem(item)
          renderer.changed({ action: this })
        }

        // Update legends after moving points or items,
        // cable lengths might need an update
        renderer.refreshCables()
        renderer.refreshLegends()
      }
    }
  }
}
