import { fill } from '@stellacontrol/utilities'

export const actions = {
  /**
   * Initializes the menu,
   * checks user permissions to see menu items
   */
  async initializeMenu ({ commit, state, getters }) {
    const { guardian, configuration } = getters

    function populateMenu (items) {
      if (items) {
        return items
          .map(item => ({ ...item }))
          .filter(item => !item.isHidden)
          .filter(item => {
            // Check if required properties are specified
            if (!item.name) throw new Error('Menu item name is required', item)
            if (!item.title) throw new Error('Menu item title is required', item)

            // Apply branding to menu title
            const { application, branding } = configuration
            item.title = fill(item.title, { application, branding })

            // Menu items are collapsed by default unless
            // explicitly marked as expanded
            if (item.isCollapsed == null) {
              item.isCollapsed = true
            }

            // Mark as not selected initially
            item.isSelected = false

            // Check permissions on the menu item
            // Check permissions on the associated view
            const permissions = [
              ...item.permissions || [],
              ...(item.view ? getters.getViewPermissions(item.view) : [])
            ]
            let canUse = guardian.canUseAll(permissions)

            // If item has an associated view, navigate to it
            item.route = null
            if (item.view) {
              const route = getters.getRouteByView(item.view)
              if (route) {
                item.route = route.name
              }
            }

            // Process child items
            if (canUse) {
              item.items = populateMenu(item.items)
              return item
            }
          })
      }
    }

    const menu = populateMenu(state.items)
    commit('initializeMenu', { menu })
  },

  /**
   * Clears the menu
   */
  clearMenu ({ commit }) {
    commit('clearMenu')
  },

  /**
   * Intercept navigation to a view,
   * to ensure that menu item corresponding
   * with that view has been marked as selected
   * @param view View to navigate to
   */
  async showView ({ commit, getters }, { view } = {}) {
    const item = getters.getMenuItemByView(view.name)
    commit('selectMenuItem', { item })
  },

  /**
   * Toggles the specified menu item
   * @param item Menu item to select
   */
  async toggleMenuItem ({ commit }, { item } = {}) {
    // If item has child items, toggle it
    if (item && item.items) {
      commit('toggleMenuItem', { item })
    }
  }
}
