import { dispatch, isRoute } from '@stellacontrol/client-utilities'

/**
 * Prepares the data required by premium-subscribers view
 */
export async function resolve ({ from, to }) {
  // Make sure all organizations and devices available to current organization are fetched
  await dispatch('requireDevices')
  await dispatch('requireOrganizations')

  // Load current organization's pricelists and wallet,
  // if not already on the premium subscriptions page
  if (!isRoute(from, 'premium-subscribers')) {
    await dispatch('initializeServiceManagement')
  }

  // Check if specific customer is to be selected
  const { organization: customerId } = to.query || {}
  const wallet = await dispatch('initializePremiumSubscribersView', { customerId })
  return wallet
}
