import { Log } from '@stellacontrol/utilities'
import { NotificationsAPI } from '@stellacontrol/client-api'

export const actions = {
  /**
   * Initialize the notifications applet
   */
  async initializeApplet () {
  },

  /**
   * Unsubscribes from the notifications
   * @param {SecurityRequest} request Security request used to initiate the action
   * @returns {Promise<Boolean>} True if succeeded
   */
  async unsubscribeNotifications (_, { request }) {
    if (!request) return false
    if (!request.canUse) return false
    if (!request.token) return false

    try {
      const { token } = request
      const { success } = await NotificationsAPI.unsubscribe({ token })
      return success
    } catch (error) {
      Log.exception(error)
    }
  }
}
