import { createState } from './security-applet.state'

export const mutations = {
  /**
   * Signals login attempt
   */
  tryLogin (state) {
    state.isLoggingIn = true
  },

  /**
   * Signals logout attempt
   */
  loggingOut (state) {
    state.isLoggingOut = true
  },

  /**
   * Signals logout completed
   */
  loggedOut (state) {
    state.isLoggingOut = false
  },

  /**
   * Signals session started
   */
  // eslint-disable-next-line no-unused-vars
  sessionStarted ({ currentUser, currentOrganization, session } = {}) {
  },

  /**
   * Signals session ended
   */
  sessionEnded () {
  },

  /**
   * Initiates user session with the specified data
   */
  loginSuccess (state, { session, guardian, organizationGuardian }) {
    state.session = session
    state.guardian = guardian
    state.organizationGuardian = organizationGuardian
    state.loginError = null
    state.isLoggingIn = false
    state.isLoggedOut = false
  },

  /**
   * Signals login error
   */
  loginError (state, { message }) {
    state.session = null
    state.guardian = null
    state.loginError = message
    state.isLoggingIn = false
  },

  /**
   * Reset the state, called during logout
   */
  reset (state) {
    const { isLoggingOut } = state
    Object.assign(state, createState(), {
      isLoggingOut,
      isLoggedOut: true
    })
  },

  /**
   * Clear all pending operations in case of application failure
   */
  fail (state) {
    state.isLoggingIn = false
    state.isLoggingOut = false
  },

  /**
   * Updates the current session user,
   * i.e. after the user has edited his profile
   */
  updateSessionUser (state, { user }) {
    const { session: { user: sessionUser } = {} } = state
    if (sessionUser && user && user.id === sessionUser.id) {
      const { name, description, firstName, lastName, phone, level } = user
      Object.assign(sessionUser, { name, description, firstName, lastName, phone, level })
    }
  },

  /**
   * Updates the guardian with other security-related information
   * @param wallet Organization's wallet, containing list of currently active premium subscriptions
   */
  updateGuardian (state, { wallet } = {}) {
    if (wallet) {
      state.guardian.setWallet(wallet)
      state.organizationGuardian.setWallet(wallet)
    }
  },

  /**
   * Stores principal's guardian in the state
   * @param {Principal} principal Principal whose guardian to store
   * @param {Guardian} guardian Security guardian for the principal
   */
  storeGuardianOf (state, { principal, guardian } = {}) {
    state.guardians[principal.id] = guardian
  },

  /**
   * Stores the specified user preference
   * in current user's preferences
   * @param name Preference to store
   * @param value Preference value
   */
  storeUserPreference (state, { name, value } = {}) {
    const { user } = state.session
    if (user && name) {
      user.preferences[name] = value
    }
  },

  /**
   * Stores the specified user preferences
   * in current user's preferences
   * @param items List of preferences specified as { name, value }
   */
  storeUserPreferences (state, { items = [] } = {}) {
    const { user } = state.session
    if (user) {
      for (const { name, value } of items) {
        user.preferences[name] = value
      }
    }
  },

  /**
   * Stores the specified user preference
   * in current organization's preferences
   * @param name Preference to store
   * @param value Preference value
   */
  storeOrganizationPreference (state, { name, value } = {}) {
    const { session: { organization } = {} } = state
    if (organization && name) {
      organization.preferences[name] = value
    }
  },

  /**
   * Stores the specified user preferences
   * in current organization's preferences
   * @param items List of preferences specified as { name, value }
   */
  storeOrganizationPreferences (state, { items = [] } = {}) {
    const { session: { organization } = {} } = state
    if (organization) {
      for (const { name, value } of items) {
        organization.preferences[name] = value
      }
    }
  },

  /**
   * Stores the specified global preference
   * @param name Preference to store
   * @param value Preference value
   */
  storeGlobalPreference (state, { name, value } = {}) {
    const { session = {} } = state
    if (session.preferences && name) {
      session.preferences[name] = value
    }
  },

  /**
   * Stores the specified global preferences
   * @param items List of preferences specified as { name, value }
   */
  storeGlobalPreferences (state, { items = [] } = {}) {
    const { session = {} } = state
    if (session.preferences) {
      for (const { name, value } of items) {
        session.preferences[name] = value
      }
    }
  },

  /**
   * Sets organization language and/or country
   * @param {Organization} organization Organization to update
   * @param {String} countryCode ISO 3166 Alpha-2 country code
   * @param {String} languageCode ISO 639-2 language code
   * @param {String} timezone Name of country timezone, as per https://www.iana.org/time-zones
   */
  setLocale (state, { organization, countryCode, languageCode, timezone } = {}) {
    const { session = {} } = state
    if (organization && organization.id === session?.organization?.id) {
      session.organization.countryCode = countryCode || session.organization.countryCode
      session.organization.languageCode = languageCode || session.organization.languageCode
      session.organization.timezone = timezone || session.organization.timezone
    }
  }
}
