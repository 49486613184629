/**
 * Available fast-sampling speeds for retrieving live device status
 */
export const FastSamplingSpeed = {
  Off: 'off',
  QuarterSecond: '250ms',
  OneSecond: '1s',
  TenSeconds: '10s',
  OneMinute: '1m'
}
