/**
 * Checks if the specified value is a boolean
 * @param {any} value Value to check
 * @returns {Boolean}
 */
export function isBoolean (value) {
  return value != null && typeof value === 'boolean'
}

/**
 * Parses the specified value to boolean
 * @param {any} value Value to parse
 * @param {Boolean} defaultValue Default value to return if {@link value} is not specified
 * @returns {Boolean}
 */
export function parseBoolean (value, defaultValue) {
  if (value == null) {
    return defaultValue
  } else {
    return Boolean(value)
  }
}
