import { Point } from '@stellacontrol/utilities'

/**
 * Utility class for handling events on the plan
 */
export class PlanEvent {
  /**
   * Checks whether the button in the specified mouse event is the primary one
   * @param {Konva.Event|Event} e Event details
   * @returns {Boolean}
   */
  static isLeftButton (e) {
    if (!e) return false
    return ((e.evt || e).buttons & 1) === 1
  }

  /**
   * Checks whether the button in the specified mouse event is the middle one
   * @param {Konva.Event} e Event details
   * @returns {Boolean}
   */
  static isMiddleButton (e) {
    if (!e) return false
    return ((e.evt || e).buttons & 4) === 4
  }

  /**
   * Checks whether the button in the specified mouse event is the secondary one
   * @param {Konva.Event} e Event details
   * @returns {Boolean}
   */
  static isRightButton (e) {
    if (!e) return false
    return ((e.evt || e).buttons & 2) === 2
  }

  /**
   * Checks whether CTRL key was pressed when event happened
   * @param {Konva.Event} e Event details
   * @returns {Boolean}
   */
  static isCtrlKey (e) {
    if (!e) return false
    return (e.evt || e).ctrlKey
  }

  /**
   * Checks whether SHIFT key was pressed when event happened
   * @param {Konva.Event} e Event details
   * @returns {Boolean}
   */
  static isShiftKey (e) {
    if (!e) return false
    return (e.evt || e).shiftKey
  }

  /**
   * Checks whether ALT key was pressed when event happened
   * @param {Konva.Event} e Event details
   * @returns {Boolean}
   */
  static isAltKey (e) {
    if (!e) return false
    return (e.evt || e).altKey
  }

  /**
   * Checks whether any control key was pressed when event happened (CTRL, SHIFT, ALT, META)
   * @param {Konva.Event} e Event details
   * @returns {Boolean}
   */
  static anyControlKeys (e) {
    if (!e) return false
    const event = e.evt || e
    return event.altKey || event.ctrlKey || event.shiftKey || event.metaKey
  }

  /**
   * Checks whether no control key was pressed when event happened (CTRL, SHIFT, ALT, META)
   * @param {Konva.Event} e Event details
   * @returns {Boolean}
   */
  static noControlKeys (e) {
    if (!e) return true
    const event = e.evt || e
    return !(event.altKey || event.ctrlKey || event.shiftKey || event.metaKey)
  }

  /**
   * Checks if event has already been handled elsewhere
   * @param {Konva.Event|Event} e Event to check
   * @returns {Boolean}
   */
  static isEventHandled (e) {
    if (!e) return false
    const event = e.evt || e
    return Boolean(event.defaultPrevented)
  }

  /**
   * Prevents propagation of the specified shape event
   * @param {Konva.Event} e Event to cancel
   */
  static cancelEvent (e) {
    if (!e) return
    const event = e.evt || e
    event.cancelBubble = true
    if (event.stopImmediatePropagation) event.stopImmediatePropagation()
    if (event.stopPropagation) event.stopPropagation()
    if (event.preventDefault) event.preventDefault()
    if (e.evt) {
      e.cancelBubble = true
    }
  }

  /**
   * Returns coordinates where the specified event was triggered
   * @param {Event|Konva.Event} e Event details
   */
  static getEventPosition (e) {
    const { clientX: x, clientY: y } = (e.evt || e || {})
    if (x != null && y != null) {
      return Point.from({ x, y })
    }
  }
}