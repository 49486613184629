<script>
import { mapState, mapActions } from 'vuex'
import { isConnectedDevice, DeviceAcronym } from '@stellacontrol/model'

export default {
  props: {
    // Grid column
    column: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      DeviceAcronym
    }
  },

  computed: {
    ...mapState({
      gridFilters: state => state.inventoryView.gridFilters
    })
  },

  methods: {
    ...mapActions([
      'filterInventory'
    ]),

    isConnectedDevice
  }
}
</script>

<template>
  <q-select
    dense
    clearable
    clear-icon="close"
    hide-dropdown-icon
    :model-value="gridFilters[column.name]"
    :label="column.label"
    :multiple="column.multiple"
    :options="column.options"
    option-value="value"
    option-label="label"
    map-options
    emit-value
    @update:model-value="value => filterInventory({ column: column, value })"
  >
    <template v-slot:selected>
      {{ DeviceAcronym[gridFilters[column.name]] || '' }}
    </template>
    <template v-slot:option="scope">
      <q-item class="select-option items-center q-pa-xs" clickable v-close-popup dense v-bind="scope.itemProps">
        <q-icon name="brightness_1" :color="isConnectedDevice(scope.opt.value) ? 'light-green-9' : 'grey-4'" size="16px" />
        <span class="q-ml-sm">{{ scope.opt.label }}</span>
      </q-item>
    </template>
  </q-select>
</template>

<style>
</style>