import { ActionItem } from '../action-dropdown'

/**
 * Default list actions for record lists such as organizations, users etc.
 */
export const ListAction = {
  // Represents a separator item
  Separator: new ActionItem({
    separator: true
  }),

  // Represents a default edit item
  Edit: new ActionItem({
    // Unique name of action in the menu
    name: 'edit',
    // Action label
    label: 'Edit',
    // Action icon
    icon: 'edit',
    // Icon color
    color: 'indigo-5',
    // If true, action will be triggered on double-clicking the item
    isDefault: true
  }),

  // Represents a default delete item
  Delete: new ActionItem({
    name: 'delete',
    icon: 'close',
    label: 'Delete',
    color: 'red-9'
  }),

  /**
   * Creates a list action with specified parameters
   * @param {Object} parameters Action parameters
   * @returns {ActionItem} List action item
   */
  create ({
    name,
    icon,
    label,
    color,
    isDefault,
    isVisible,
    separator,
    confirmation,
    handler
  }) {
    return new ActionItem({
      name,
      icon,
      label,
      color,
      isDefault,
      isVisible,
      separator,
      confirmation,
      handler
    })
  }
}

/**
 * Default list actions for record lists such as organizations, users etc.
 */
export const DefaultListActions = [
  ListAction.Edit,
  ListAction.Delete
]
