/**
 * Place types
 */
export const PlaceType = {
  Building: 'building',
  Ship: 'ship',
  Vehicle: 'vehicle',
  Other: 'other',
  NoPlace: 'no-place',
  SharedPlace: 'shared-devices',
}
