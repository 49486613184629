import { PlanRiser } from '@stellacontrol/planner'
import { PlanAction, PlanActions } from './plan-action'
import { AddItemAction } from './add-item'
import { RemoveItemsAction } from './remove-items'

/**
 * Toggles riser plug and cable visibility
 */
export class ToggleRisersAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.ToggleRisers
  }

  /**
  * Action label
  * @type {String}
  */
  get label () {
    return 'Toggle risers'
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   * @param {Boolean} isVisible Indicates whether riser plugs and cables leading into them should be visible
   */
  execute ({ renderer, isVisible } = {}) {
    if (renderer) {
      renderer.layout.showRisers = isVisible == null ? !renderer.layout.showRisers : Boolean(isVisible)
      renderer.refresh()
      renderer.changed()
    }
  }
}

/**
 * Merges risers
 */
export class MergeRisersAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.MergeRisers
  }

  /**
   * Indicates that the action requires items to act on
   * @type {Boolean}
   */
  get requiresItems () {
    return true
  }

  /**
   * Indicates whether action requires refresh
   * @type {Boolean}
   */
  get requiresRefresh () {
    return true
  }

  /**
   * If true, the current selection will be preserved
   * after the action has been executed
   * @type {Boolean}
   */
  get preserveSelection () {
    return true
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   * @param {PlanRiser} riser Riser to merge
   * @param {PlanRiser} mergeWith Riser to merge with
   */
  async execute ({ renderer, riser, mergeWith } = {}) {
    if (renderer && riser && mergeWith && riser.id !== mergeWith.id) {
      const { layout } = renderer

      renderer.deselect()

      // Create the new joint riser
      const jointRiser = new PlanRiser({
        isJointRiser: true,
        tag: layout.crossSection.getRiserTag(),
        connectors: [...mergeWith.connectors, ...riser.connectors]
      })
      layout.crossSection.risers.push(jointRiser)

      riser.connectors = []
      mergeWith.connectors = []

      // Get the newly added items representing the plugs and cables leading into the merged riser
      const { added, deleted } = renderer.layout.refreshRisers()

      // Render the newly added items on the canvas
      const plugCoordinates = {}
      const addAction = new AddItemAction()
      for (const item of added) {
        // Position the newly created plugs next to each other
        if (item.isPlug) {
          let plugX = plugCoordinates[item.floorId]
          plugX = (plugX == null)
            ? item.coordinates.x
            : plugX + item.width * 2
          item.coordinates.x = plugX
          plugCoordinates[item.floorId] = plugX
        }

        // Render the item
        addAction.render({ renderer, item })
      }

      // Remove the discarded items from the canvas
      await renderer.cleanup()
      const removeAction = new RemoveItemsAction()
      for (const item of deleted) {
        removeAction.render({ renderer, item })
      }

      // Update legends etc.
      await renderer.refreshLegends()
      await renderer.refreshEquipmentHierarchy()
      renderer.changed({ action: this })
    }
  }

  /**
   * Undoes the executed action
   * @param {PlanRenderer} renderer Plan renderer
   * @param {RecordedPlanAction} data Data recorded before executing the action
  */
  async undo ({ renderer, data }) {
    await super.undo({ renderer, data })
    renderer.refresh()
    renderer.changed({ action: this })
  }
}

/**
 * Splits risers
 */
export class SplitRiserAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.SplitRiser
  }

  /**
   * Indicates that the action requires items to act on
   * @type {Boolean}
   */
  get requiresItems () {
    return true
  }

  /**
   * Indicates whether action requires refresh
   * @type {Boolean}
   */
  get requiresRefresh () {
    return true
  }

  /**
   * If true, the current selection will be preserved
   * after the action has been executed
   * @type {Boolean}
   */
  get preserveSelection () {
    return true
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   * @param {PlanRiser} riser Riser to split
   */
  async execute ({ renderer, riser } = {}) {
    if (renderer && riser) {
      const { layout } = renderer

      renderer.deselect()

      // Group the connectors going through the riser
      // by root repeater
      const connectors = riser.connectors.map(id => layout.getItem(id))
      const groups = { [null]: [] }
      for (const connector of connectors) {
        const root = layout.getRootDeviceOf(connector)
        if (root) {
          if (!groups[root.id]) groups[root.id] = []
          groups[root.id].push(connector)
        } else {
          groups[null].push(connector)
        }
      }

      // Remove the joint riser
      riser.connectors = []

      // For each group, create a new riser
      for (const [id, connectors] of Object.entries(groups)) {
        if (connectors.length > 0) {
          const root = layout.getItem(id)
          const groupRiser = new PlanRiser({
            tag: root.tag,
            connectors: connectors.map(c => c.id)
          })
          layout.crossSection.risers.push(groupRiser)
        }
      }

      // Refresh the risers
      const { added, deleted } = renderer.layout.refreshRisers()

      // Render the newly added items on the canvas
      const plugCoordinates = {}
      const addAction = new AddItemAction()
      for (const item of added) {
        // Position the newly created plugs next to each other
        if (item.isPlug) {
          let plugX = plugCoordinates[item.floorId]
          plugX = (plugX == null)
            ? item.coordinates.x
            : plugX + item.width * 2
          item.coordinates.x = plugX
          plugCoordinates[item.floorId] = plugX
        }

        // Render the item
        addAction.render({ renderer, item })
      }

      // Remove the discarded items from the canvas
      await renderer.cleanup()
      const removeAction = new RemoveItemsAction()
      for (const item of deleted) {
        removeAction.render({ renderer, item })
      }

      // Update legends etc.
      await renderer.refreshLegends()
      await renderer.refreshEquipmentHierarchy()
      renderer.changed({ action: this })
    }
  }

  /**
   * Undoes the executed action
   * @param {PlanRenderer} renderer Plan renderer
   * @param {RecordedPlanAction} data Data recorded before executing the action
  */
  async undo ({ renderer, data }) {
    await super.undo({ renderer, data })
    renderer.refresh()
    renderer.changed({ action: this })
  }
}
