import { assign } from '@stellacontrol/utilities'
import CountryList from './countries.json'

/**
 * Countries where the platform is officially supported
 */
export const SupportedCountries = ['ie', 'be', 'nl', 'gb', 'es', 'it', 'de', 'fr']

/**
 * Country groups
 */
export const CountryGroup = {
  EU: 'EU',
  EMEA: 'EMEA',
  APAC: 'APAC'
}

/**
 * Country
 */
export class Country {
  constructor (data = {}) {
    assign(
      this,
      {
        ...data
      })
  }

  /**
   * ISO 3166 Alpha-2 country code
   * @type {String}
   */
  code

  /**
   * ISO 3166 Alpha-3 country code
   * @type {String}
   */
  code3

  /**
   * Country names, per language code
   * @type {Dictionary<String, String>}
   */
  names

  /**
   * Default English country name or just the first name
   * @type {String}
   */
  get name () {
    const { names } = this
    if (names) {
      return names['en'] || Object.values(names)[0]
    }
  }

  /**
   * Country languages, specified as list of ISO 639-2 language codes
   * @type {Array[String]}
   */
  languages

  /**
   * Default country language, specified as ISO 639-2 language code
   * @type {String}
   */
  get language () {
    const { languages } = this
    if (languages) {
      return languages[0]
    }
  }

  /**
   * Country groups where the country belongs
   * @type {Array[CountryGroup]}
   */
  groups

  /**
   * Country timezones
   * @type {Array[String]}
   */
  timezones

  /**
   * Default country timezone, useful when there are multiple timezones in the country
   * @type {String}
   */
  timezone

  /**
   * Default country timezone, useful when there are multiple timezones in the country
   * @type {String}
   */
  getDefaultTimezone () {
    return this.timezone || (this.timezones || [])[0]
  }

  /**
   * Checks whether the specified timezone is listed in the country
   * @param {String} timezone Timezone to check
   * @returns {Boolean}
   */
  isValidTimezone (timezone) {
    return timezone && this.timezones?.includes(timezone)
  }

  /**
  * If false, the country is no longer active, eg. gone from the map
  * @type {Boolean}
  */
  isActive

  /**
   * Indicates whether the country belongs to the specified country group
   * @type {Boolean}
   */
  isMemberOf (group) {
    return this.groups?.includes(group)
  }

  /**
   * Indicates whether the country belongs to EU
   * @type {Boolean}
   */
  isEU () {
    return this.groups?.includes(CountryGroup.EU)
  }

  /**
   * Indicates whether the country belongs to EMEA
   * @type {Boolean}
   */
  isEMEA () {
    return this.groups?.includes(CountryGroup.EMEA)
  }

  /**
   * Indicates whether the country belongs to APAC
   * @type {Boolean}
   */
  isAPAC () {
    return this.groups?.includes(CountryGroup.APAC)
  }

  /**
   * True indicates that the country supported by the platform
   * @type {Boolean}
   */
  get isSupported () {
    return isSupportedCountry(this.code)
  }
}

/**
 * List of countries
 */
export const Countries = {
  /**
   * All countries
   */
  All: CountryList.items.map(item => new Country(item)),
  /**
   * Countries where the platform is officially supported
   */
  Supported: SupportedCountries
    .map(code => CountryList.items.find(country => country.code === code))
    .map(item => new Country(item))
}

/**
 * Gets country data by code
 * @param {String} code ISO 3166 Alpha-2 or Alpha-3 country code
 * @returns {Country} Country data
 */
export function getCountry (code) {
  if (code) {
    if (code.length === 2) {
      return Countries.All.find(country => country.code === code)
    }
    if (code.length === 3) {
      return Countries.All.find(country => country.code3 === code)
    }
  }
}

/**
 * Checks whether country is valid
 * @param {String} code ISO 3166 Alpha-2 or Alpha-3 country code
 * @returns {Boolean} True if country is valid
 */
export function isValidCountry (code) {
  const country = getCountry(code)
  return Boolean(country)
}

/**
 * Checks whether country is supported by the platform
 * @param {String} code ISO 3166 Alpha-2 or Alpha-3 country code
 * @returns {Boolean} True if country is supported by the platform
 */
export function isSupportedCountry (code) {
  const country = getCountry(code)
  return country && Countries.Supported.some(c => c.code === country.code)
}
