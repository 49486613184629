<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { ViewMixin, FormMixin, Clipboard, Notification, Exporter } from '@stellacontrol/client-utilities'
import { DeviceType, DeviceName, DevicePortCount, StandardDeviceBands, StandardDeviceBandsNames, isConnectedDevice, sortOrganizations, OrganizationSortOrder, AvailableProducts, isMultiDevice } from '@stellacontrol/model'
import { Secure } from '@stellacontrol/security-ui'
import { SimulatedDeviceProfiles } from '@stellacontrol/devices'
import { generateSerialNumbers } from './generate-serial-numbers'
import { exportSerialNumbers } from './export-serial-numbers'
import { populateDevices } from './populate-devices'

const name = 'inventory-add'

export default {
  mixins: [
    ViewMixin,
    FormMixin,
    Secure
  ],

  data () {
    return {
      // Device type names
      DeviceName,
      // View name
      name,
      // Entered serial numbers
      serialNumbers: {
        all: '',
        prefix: '',
        start: 1,
        count: 1,
        digits: 2,
        suffix: ''
      },
      // Details of the added devices
      device: {
        // Owner organization for the entered devices
        ownerId: null,
        // Device type, model etc.
        type: '',
        model: '',
        bands: '',
        portCount: 1,
        // Manufacture date
        manufacturedAt: new Date(),
        // Sale date
        soldAt: new Date(),
        // additional notes
        notes: '',
        // Simulated device profile
        simulatedDeviceProfile: ''
      },
      // Devices which could not be created because they already existed in the database
      existingDevices: [],
      // Devices which could not be created due to unexpected errors
      failedDevices: [],
      // Visibility of advanced properties such as model, type etc.
      // Normally there's no need to enter these, as they're populated
      // automatically the first time the device status is retrieved
      editingAdvancedProperties: false
    }
  },

  computed: {
    ...mapState({
      // Available device models
      models: state => state.devices.deviceTypes.models
    }),

    ...mapGetters([
      'currentOrganization',
      'organizations',
      'configuration',
      'recentlyAddedDevices'
    ]),

    // Options for select inputs
    deviceTypes () {
      return Object
        .values(DeviceType)
        .map(value => ({ label: DeviceName[value], value }))
    },

    // Selectable device models
    deviceModels () {
      // Get all currently used, and all multi-unit products,
      // filter out obsolete ones
      const models = [
        ...this.models.map(model => ({ label: model, value: model })),
        ...AvailableProducts.map(({ name: label, model: value }) => ({ label, value }))
      ]
      return models
    },

    devicePortCounts () {
      return Object
        .entries(DevicePortCount)
        .map(([label, value]) => ({ label, value }))
    },

    deviceBands () {
      return Object
        .values(StandardDeviceBands)
        .map((value) => ({ label: StandardDeviceBandsNames[value], value }))
    },

    // Simulated device profiles
    simulatedDeviceProfiles () {
      return [
        { name: '', description: '-', icon: 'do_not_disturb', color: 'grey-5' },
        ...SimulatedDeviceProfiles
      ].filter(p => p.name !== 'default')
    },

    // Normalized list of entered serial numbers,
    // without duplicates or empties
    serialNumbersList () {
      const numbers = (this.serialNumbers.all || '')
        .trim()
        .split('\n')
        .filter(line => line.trim())
      return Array.from(new Set(numbers))
    },

    // All organizations
    allOrganizations () {
      const { organizations, currentOrganization } = this
      return sortOrganizations(organizations, OrganizationSortOrder.Rank, { currentOrganization })
    },

    // Possible owners of devices
    owners () {
      return this.allOrganizations.filter(o => !(o.isGuestOrganization || o.isShippingCompany))
    },

    // Selected owner of the devices
    owner () {
      const { ownerId } = this.device
      return ownerId ? this.owners.find(o => o.id === ownerId) : undefined
    },

    // Selected type of the devices
    type () {
      const { type } = this.device
      return type
    },

    // Returns true if selected device type is a multi-board unit
    isMultiDevice () {
      return isMultiDevice(this.type)
    },

    // Devices to create from the entered serial numbers
    devices () {
      return populateDevices(this.serialNumbersList, this.device, this.owner)
    },

    // Indicates that import results are ready
    hasImportResults () {
      return this.recentlyAddedDevices.length > 0 || this.existingDevices.length > 0 || this.failedDevices.length > 0
    }
  },

  methods: {
    ...mapActions([
      'gotoInventory',
      'saveDevices',
      'peekLiveStatus'
    ]),

    // Check whether the specified device type is an internet-connected one
    isConnectedDevice,

    // Saves changes
    async addDevices () {
      const devices = this.devices || []
      if (devices.length > 0 && await this.validate()) {
        const { existingDevices, failedDevices, savedDevices } = await this.saveDevices({ devices })
        this.processSaveResults({ existingDevices, failedDevices, savedDevices })
      }
    },

    // Interprets and processes the results of saving devices
    processSaveResults ({ existingDevices, failedDevices, savedDevices }) {
      // Take notice of devices which already existed in the database,
      // so they could not created
      for (const existingDevice of existingDevices) {
        if (!this.existingDevices.find(d => d.serialNumber === existingDevice.serialNumber)) {
          this.existingDevices.push(existingDevice)
        }
      }

      // Take notice of devices which could not created due to errors
      for (const failedDevice of failedDevices) {
        if (!this.failedDevices.find(d => d.serialNumber === failedDevice.serialNumber)) {
          this.failedDevices.push(failedDevice)
        }
      }

      // Remove device from failed if succeeded at another attempt
      for (const savedDevice of savedDevices) {
        let index = this.existingDevices.findIndex(d => d.serialNumber === savedDevice.serialNumber)
        if (index > -1) {
          this.existingDevices.splice(index, 1)
        }
        index = this.failedDevices.findIndex(d => d.serialNumber === savedDevice.serialNumber)
        if (index > -1) {
          this.failedDevices.splice(index, 1)
        }
      }

      // Fetch recent status of the saved devices, without waiting
      this.peekLiveStatus({ devices: savedDevices })

      // Remove processed numbers from the list
      this.serialNumbers.all = ''
    },

    // Cancels changes and goes back to the previous view
    async close () {
      await this.gotoInventory()
    },

    // Validates the entered serial numbers
    validateSerialNumbers (value = '') {
      if (value) {
        const numbers = value.split('\n')
        const validator = /^[a-zA-Z0-9-]*/
        return numbers.every((number = '') =>
          number.trim().match(validator)[0] === number.trim()) || 'Invalid characters used in serial numbers'
      }
    },

    // Generates serial numbers
    generateSerialNumbers () {
      const { prefix = '', suffix = '' } = this.serialNumbers
      const serialNumbers = [
        ...this.serialNumbersList,
        ...generateSerialNumbers({
          prefix,
          suffix,
          start: parseInt(this.serialNumbers.start),
          count: parseInt(this.serialNumbers.count),
          digits: parseInt(this.serialNumbers.digits)
        })
      ]
      this.serialNumbers.all = serialNumbers
        .map(line => line.trim())
        .filter(line => line)
        .join('\n')
    },

    /**
     * Copies serial numbers to clipboard
     * @param method Export method: list / text / csv
     * @param onlyCreated If true, only devices succesfully created will be copied
     */
    async copySerialNumbers (method = 'text', onlyCreated = true) {
      const { owner } = this
      const devices = this.recentlyAddedDevices
        .filter(device => onlyCreated ? !device.error : true)
        .map(device => ({ ...device, owner }))
      const text = exportSerialNumbers(method, devices)
      if (text) {
        await Clipboard.write(text)
        Notification.success({ message: 'Serial numbers have been copied to clipboard' })
      } else {
        Notification.warning({ message: 'No devices were created yet' })
      }
    },

    /**
     * Saves serial numbers to a text file
     * @param method Export method: list / text /| csv
     * @param onlyCreated If true, only devices succesfully created will be saved
     */
    saveSerialNumbers (method = 'list', onlyCreated = true) {
      const { owner } = this
      const devices = this.recentlyAddedDevices
        .filter(device => onlyCreated ? !device.error : true)
        .map(device => ({ ...device, owner }))
      const text = exportSerialNumbers(method, devices)
      if (text) {
        // const fileName = `devices-${formatDate(new Date())}.txt`
        const fileName = 's.txt'
        Exporter.toFile(text, fileName)
      } else {
        Notification.warning({ message: 'No devices were created yet' })
      }
    },

    /**
     * Returns human-friendly error message
     * associated with a device which could not be created
     */
    getDeviceError (device) {
      let message = ''
      if (device && device.error) {
        message = (device.error.message || '').replace('Internal Server Error: ', '')
      }
      return message
    }
  },

  watch: {
    type () {
      // If multi-unit product selected, pick the correct model automatically
      if (this.isMultiDevice) {
        const products = AvailableProducts.filter(p => p.type === this.device.type)
        if (products.length === 1) {
          this.device.model = products[0].model
        }
      }
    }
  },

  created () {
    // Input defaults
    this.device.manufacturedAt = new Date()
    this.device.soldAt = new Date()
    this.device.ownerId = this.currentOrganization.id
    this.device.type = this.deviceTypes[0].value
    this.device.model = this.deviceModels[0].value
    this.device.portCount = this.devicePortCounts[0].value
    this.device.bands = this.deviceBands[0].value
  }
}

</script>

<template>
  <sc-view :name="name">

    <template #toolbar>
      <div class="q-gutter-sm">
        <q-btn unelevated class="primary" label="Close" @click="close()"></q-btn>
      </div>
    </template>

    <q-form ref="form" autofocus class="add-devices-form q-ma-lg">
      <div class="header">
        <div class="columns">
          <div class="serial-numbers row q-gutter-md">
            <label class="text-subtitle1">
              Serial numbers
            </label>
            <q-space></q-space>
            <q-btn unelevated dense label="Copy" title="Copy serial numbers to clipboard"
              @click="copySerialNumbers()" />
            <q-btn unelevated dense label="Download" title="Save serial numbers to a file"
              @click="saveSerialNumbers()" />
          </div>
          <div class="details q-pl-md">
            <label class="text-subtitle1 q-ml-sm">
              Device details
            </label>
          </div>
        </div>
      </div>

      <div class="data q-mt-sm">
        <div class="serial-numbers">
          <q-input class="serial-numbers-input" autofocus type="textarea" hide-bottom-space
            clearable clear-icon="close" bg-color="white" square outlined dense
            v-model="serialNumbers.all" lazy-rules
            :rules="[value => validateSerialNumbers(value)]" />
        </div>

        <div class="details q-pl-md">
          <div class="inner">
            <div class="row q-mb-md" v-if="canUse('add-devices')">
              <div class="col-4 q-pr-md">
                <sc-organization-selector label="Owner" bg-color="white" dense square
                  v-model="device.ownerId" :items="owners" />
              </div>
              <div class="col-2 q-pr-md">
                <sc-date-input bg-color="white" label="Manufactured on"
                  v-model="device.manufacturedAt" />
              </div>
              <div class="col-2 q-pr-md">
                <sc-date-input bg-color="white" label="Sold on" v-model="device.soldAt" />
              </div>
              <div class="col-4 q-pt-xs">
                <q-btn unelevated dense icon-right="more_horiz"
                  :label="editingAdvancedProperties ? 'Hide advanced properties' : 'Advanced properties'"
                  @click="editingAdvancedProperties = !editingAdvancedProperties" />
              </div>
            </div>

            <div v-else class="row q-mb-md">
              <div class="col-6">
                <sc-organization-selector label="Owner" bg-color="white" dense square
                  v-model="device.ownerId" :items="owners" />
              </div>
              <div class="col-6">
              </div>
            </div>

            <div class="row q-mb-md no-wrap" v-if="editingAdvancedProperties">
              <span class="text-orange-8 q-pl-xs">
                Normally there's no need to enter these properties. When device status is retrieved,
                they will be automatically discovered and saved.
              </span>
            </div>

            <div class="row q-mb-md no-wrap" v-if="editingAdvancedProperties">
              <div class="col-2">
                <q-select square outlined dense bg-color="white" label="Type" v-model="device.type"
                  :options="deviceTypes" emit-value map-options>
                  <template v-slot:option="scope">
                    <q-item clickable v-close-popup v-bind="scope.itemProps">
                      <div class="row items-center">
                        <q-icon name="brightness_1"
                          :color="isConnectedDevice(scope.opt.value) ? 'light-green-9' : 'grey-4'"
                          size="20px" />
                        <span class="q-ml-sm">{{ DeviceName[scope.opt.value] }}</span>
                      </div>
                    </q-item>
                  </template>
                </q-select>
              </div>
              <div class="q-pl-md col-2" v-if="!isMultiDevice">
                <q-select square outlined dense bg-color="white" label="Bands"
                  v-model="device.bands" :options="deviceBands" emit-value map-options>
                </q-select>
              </div>
              <div class="col-2 q-pl-md" v-if="!isMultiDevice">
                <q-select square outlined dense bg-color="white" label="Port count"
                  v-model="device.portCount" :options="devicePortCounts" emit-value map-options>
                </q-select>
              </div>
              <div class="col-3 q-pl-md">
                <q-select square outlined dense bg-color="white" label="Model"
                  v-model="device.model" :options="deviceModels" emit-value map-options>
                </q-select>
              </div>
              <div class="col-3 q-pl-md" v-if="canUse('add-simulated-devices') && !isMultiDevice">
                <q-select square outlined dense bg-color="white" label="Simulated device"
                  v-model="device.simulatedDeviceProfile" :options="simulatedDeviceProfiles"
                  emit-value map-options option-value="name" option-label="description">

                  <template v-slot:option="scope">
                    <q-item clickable v-close-popup v-bind="scope.itemProps">
                      <div class="row items-center no-wrap">
                        <q-icon class="q-mr-sm" :name="scope.opt.icon" :color="scope.opt.color"
                          size="sm" />
                        <span class="no-wrap-text">
                          {{ scope.opt.description }}
                        </span>
                      </div>
                    </q-item>
                  </template>

                </q-select>
              </div>
            </div>

            <div class="notes">
              <q-input square outlined dense class="notes-input" type="textarea" hide-bottom-space
                clearable clear-icon="close" bg-color="white" label="Notes"
                v-model="device.notes" />
            </div>

            <div class="results q-mt-md q-pa-sm" v-if="hasImportResults">
              <div>
                <label class="q-mb-sm text-grey-7">
                  Results
                </label>
              </div>
              <div>
                <!-- Show red badge indicating devices which failed during import -->
                <q-badge v-for="device in failedDevices" :key="`failed-${device.serialNumber}`"
                  class="q-pa-sm q-ma-xs" color="red-5" :title="getDeviceError(device)">
                  <q-icon size="20px" color="white" class="q-mr-xs" name="close" />
                  {{ device.serialNumber }}
                </q-badge>

                <!-- Show orange badge for devices already existing in the database -->
                <q-badge v-for="device in existingDevices" :key="`existing-${device.serialNumber}`"
                  class="q-pa-sm q-ma-xs" color="orange-5"
                  :title="`Device ${device.serialNumber} already exists`">
                  <q-icon name="priority_high" size="18px" color="white" class="q-mr-xs" />
                  {{ device.serialNumber }}
                </q-badge>

                <!-- show green badge indicating devices succesfully added -->
                <q-badge v-for="device in recentlyAddedDevices"
                  :key="`added-${device.serialNumber}`" class="q-pa-sm q-ma-xs" color="green">
                  <q-icon size="20px" color="white" class="q-mr-xs" name="check" />
                  {{ device.serialNumber }}
                </q-badge>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="bottom q-mt-md q-mb-md">
        <div class="serial-numbers">
          <div class="generator q-gutter-sm" v-if="canUse('add-devices')">
            <q-input square filled dense label="Prefix" v-model="serialNumbers.prefix" />
            <q-input square filled dense label="Start From" type="number"
              v-model="serialNumbers.start" />
            <q-input square filled dense label="Count" type="number"
              v-model="serialNumbers.count" />
            <q-input square filled dense label="Digits" type="number"
              v-model="serialNumbers.digits" />
            <q-input square filled dense label="Suffix" v-model="serialNumbers.suffix" />

            <q-btn unelevated dense label="Generate" @click="generateSerialNumbers()" />
          </div>
        </div>
        <div class="details">
          <div class="actions row items-center q-gutter-sm justify-end">
            <q-btn unelevated label="Add New Devices" icon="add" class="success"
              @click="addDevices()"></q-btn>
          </div>
        </div>
      </div>


    </q-form>

  </sc-view>
</template>

<style lang='scss'>
.add-devices-form {
  flex: 1;
  display: flex;
  flex-direction: column;

  .header {
    flex: 0;
    display: flex;

    .columns {
      flex: 1;
      display: flex;
      flex-direction: row;

      .serial-numbers {
        flex: 2;
      }

      .details {
        flex: 3;
      }
    }
  }

  .data {
    flex: 1;
    display: flex;
    flex-direction: row;

    .serial-numbers {
      flex: 2;
      display: flex;
      flex-direction: column;

      .serial-numbers-input {
        flex: 1;
        display: flex;
        flex-direction: column;

        .q-field__inner {
          flex: 1;
          display: flex;
          flex-direction: column;

          .q-field__control {
            flex: 1;
          }
        }
      }
    }

    .details {
      flex: 3;
      display: flex;
      flex-direction: column;

      .inner {
        flex: 1;
        display: flex;
        flex-direction: column;

        >div {
          flex: 0;

          &.results {
            flex: 0;
            border: solid silver 1px;
          }

          &.notes {
            flex: 1;
            display: flex;
            flex-direction: column;

            .notes-input {
              flex: 1;
              display: flex;
              flex-direction: column;

              .q-field__inner {
                flex: 1;
                display: flex;
                flex-direction: column;

                .q-field__control {
                  flex: 1;
                }
              }
            }
          }
        }
      }
    }
  }

  .bottom {
    flex: 0;
    display: flex;
    flex-direction: row;

    .serial-numbers {
      flex: 2;

      .generator {
        display: flex;
        flex-direction: row;

        .q-input {
          max-width: 110px;
        }
      }
    }

    .details {
      flex: 3;
    }

  }
}
</style>
