import { Place, getPlaceIcon, PlaceIcons } from '@stellacontrol/model'
import { formatDate } from '@stellacontrol/utilities'

/**
 * Places columns
 */
const placesColumns = [
  { name: 'icon', label: 'Type', field: 'placeType', format: value => ({ icon: getPlaceIcon(value, 'place') }), sortable: true, align: 'left' },
  { name: 'name', label: 'Name', field: 'name', sortable: true, align: 'left' },
  { name: 'deviceCount', label: 'Devices', field: 'deviceCount', format: value => value || '0', sortable: true, align: 'left', style: 'width: 120px' },
  { name: 'city', label: 'City', field: 'city', sortable: true, align: 'left', style: 'width: 150px' },
  { name: 'address', label: 'Address', field: 'address', sortable: true, align: 'left' },
  { name: 'created', label: 'Created On', field: 'createdAt', sortable: true, align: 'left', format: value => formatDate(value), style: 'width: 120px' },
  { name: 'updated', label: 'Updated On', field: 'updatedAt', sortable: true, align: 'left', format: value => formatDate(value), style: 'width: 120px' },
  { name: 'actions', label: 'Actions', align: 'right', sortable: false }
]

/**
 * Devices assigned to the place columns
 */
const placeDevicesColumns = [
  { name: 'serialNumber', label: 'Serial Number', field: 'serialNumber', sortable: true, align: 'left', style: 'width: 150px' },
  { name: 'name', label: 'Name', field: 'name', sortable: true, align: 'left', style: 'width: 150px' },
  { name: 'acronym', label: 'Type', field: 'acronym', sortable: true, align: 'left', style: 'width: 100px' },
  { name: 'model', label: 'Model', field: 'model', sortable: true, align: 'left' },
  { name: 'firmwareVersion', label: 'Firmware', field: 'firmwareVersion', sortable: true, align: 'left' },
  { name: 'location', label: 'Device Location', field: 'location', sortable: true, align: 'left', format: (value, row) => row.customLocation || row.location || '-' },
  { name: 'actions', label: 'Actions', align: 'right', sortable: false }
]

/**
 * Floor plans assigned to the place
 */
const placeFloorPlanColumns = [
  { name: 'name', label: 'Name', field: 'name', sortable: true, align: 'left', style: 'width: 150px' },
  { name: 'description', label: 'Description', field: 'description', sortable: true, align: 'left' },
  { name: 'created', label: 'Created On', field: 'createdAt', sortable: true, align: 'left', format: value => formatDate(value), style: 'width: 120px' },
  { name: 'updated', label: 'Updated On', field: 'updatedAt', sortable: true, align: 'left', format: value => formatDate(value), style: 'width: 120px' },
  { name: 'actions', label: 'Actions', align: 'right', sortable: false }
]

export function createState () {
  return {
    /**
     * Places columns
     */
    placesColumns,
    /**
     * Place devices columns
     */
    placeDevicesColumns,
    /**
     * Floor plans assigned to the place
     */
    placeFloorPlanColumns,
    /**
     * Organization place icons, representing its type
     */
    placeIcons: PlaceIcons,
    /**
     * Places available to the current organization
     */
    items: [],
    /**
     * Special record representing empty place, for displaying devices not assigned to any place
     */
    noPlace: new Place(Place.NoPlace),
    /**
     * Special record representing virtual place, for displaying devices shared with organization
     */
    sharedPlace: new Place(Place.SharedPlace)
  }
}

export const state = createState()

export const getters = {
  /**
   * Places of the current organization
   */
  places: (state, getters) => (state.items || []).filter(p => p.organizationId === getters.currentOrganization?.id),
  /**
   * Dictionary of places of the current organization, by identifier
   */
  placesDictionary: (state, getters) =>
    getters.places.reduce((all, p) => ({ ...all, [p.id]: p }), [])
}
