import { ColorService } from '@stellacontrol/utilities'
import { colors } from 'quasar'

/**
 * Color service
 */
export class ClientColorService extends ColorService {
  /**
   * Returns hex color representing the specified color name
   * @param {String} name Palette color name, eg. 'primary' or 'orange-4'
   * @returns {String}
   */
  getColor (name) {
    if (name) {
      return name.startsWith('#')
        ? name
        : colors.getPaletteColor(name) || this.toHex(name)
    }
  }

  /**
   * Returns hex colors representing the specified color names.
   * If names are not specified, all colors are returned.
   * @param {Array[String]} names Palette color names, eg. 'primary' or 'orange-4'
   * @returns {Dictionary<String, String>} Dictionary of color names and their hex colors
   */
  getColors (...names) {
    return names
      .reduce((all, name) =>
        ({ ...all, [name]: this.getColor(name) }), {})
  }
}

