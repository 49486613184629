import { AutoFocusDirective } from './auto-focus'
import { MoveableDirective } from './moveable'

/**
 * Installs UI directives into the application
 * @param application Vue application instance
 */
export function useDirectives (application) {
  const plugin = {
    install (application) {

      const items = [
        AutoFocusDirective,
        MoveableDirective,
      ]
      for (const { attribute, directive } of items) {
        application.directive(attribute, directive)
      }
    }
  }

  application.use(plugin)
}
