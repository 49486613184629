<script>
import { Clipboard, Notification } from '@stellacontrol/client-utilities'
import DeviceWidget from './device-widget'

export default {
  mixins: [
    DeviceWidget
  ],

  data () {
    return {
      actions: [
        { name: 'copy', label: 'Copy to clipboard', icon: 'content_copy', color: 'indigo-6', permissions: [] },
      ]
    }
  },

  computed: {
    // Parameters available for display on the graph
    parameters () {
    },

    // Parameters currently displayed on the graph
    visibleParameters () {
    }
  },

  methods: {
    // Executes the action
    async executeAction (action) {
      switch (action.name) {
        case 'copy':
          await this.copyToClipboard()
          break
      }
    },

    // Copies the table content to clipboard
    async copyToClipboard () {
      const lines = []
      await Clipboard.write(lines + '\n')
      Notification.success({ message: 'Signal details have been copied to clipboard' })
    }
  }
}
</script>

<template>
  <sc-widget v-bind="{ ...$props, ...$attrs }" :actions="actions" @action="executeAction">
  </sc-widget>
</template>

<style scoped lang="scss">
</style>
