import { state, getters } from './device-status.state'
import { mutations } from './device-status.mutations'
import { actions } from './device-status.actions'

export default {
  state,
  getters,
  mutations,
  actions
}
