import security from './security-applet'
import features from './features'
import organizations from './organizations'
import organizationProfiles from './organization-profiles'
import users from './users'
import organizationPlaces from './organization-places'

/**
 * Stores introduced by Security applet
 */
export const AppletStores = {
  security,
  features,
  organizations,
  organizationProfiles,
  organizationPlaces,
  users
}
