import { PlannerMode } from '@stellacontrol/planner'

// Plan list columns
const columns = [
]

export function createState () {
  return {
    // Plan list columns
    columns,

    // Plans of the current organization
    plans: [],

    // Edited plan
    plan: null,

    // Edited plan floor
    floorId: null,

    // Planner usage mode - normal or advanced
    mode: PlannerMode.Regular,

    // Indicates that plan editor is maximized
    isMaximized: false
  }
}

export const state = createState()

export const getters = {
}
