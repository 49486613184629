import { parseDate } from '@stellacontrol/utilities'

/**
 * Announcement status for a specific user
 */
export class AnnouncementStatus {
  constructor (data = {}) {
    Object.assign(this, data)
    this.isAcknowledged = Boolean(this.isAcknowledged)
    this.createdAt = parseDate(this.createdAt)
  }

  /**
  * Identifier of the acknowledgement
  * @type {String}
  */
  id

  /**
 * Status date
 * @type {Date}
 */
  createdAt

  /**
   * Identifier of the user who updated the status
   * @type {String}
   */
  userId

  /**
   * Identifier of the organization to which the user belongs
   * @type {String}
   */
  organizationId

  /**
   * Indicates that recipient has acknowledged the announcement
   * @type {Date}
   */
  isAcknowledged
}
