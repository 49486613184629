<script>
import { mapGetters, mapActions } from 'vuex'
import { User } from '@stellacontrol/model'
import { ViewMixin, FormMixin } from '@stellacontrol/client-utilities'

const name = 'activate-account'

export default {
  mixins: [
    ViewMixin,
    FormMixin
  ],

  data () {
    return {
      name,
      user: null,
      repeatPassword: ''
    }
  },

  computed: {
    ...mapGetters([
      'isInitialized',
      'application',
      'company',
      'branding',
      'configuration'
    ]),

    token () {
      return this.data.token
    }
  },

  methods: {
    ...mapActions([
      'endSession',
      'activateUser',
      'gotoLogin',
      'gotoUrl'
    ]),

    async activate () {
      if (await this.validate()) {
        const { token, user } = this
        if (await this.activateUser({ user, token })) {
          this.gotoLogin()
        }
      }
    },

    async cancel () {
      this.gotoUrl({ url: this.company.website || 'about:blank' })
    }
  },

  created () {
    this.user = new User(this.data.user)
  }
}
</script>

<template>
  <sc-view :name="name" no-header>
    <q-layout v-cloak v-if="isInitialized && user">
      <q-page-container>
        <q-page class="column items-center justify-center">
          <q-card bordered class="form-activate-user">
            <q-form ref="form" class="q-gutter-sm q-pa-md">

              <div class="row">
                <span class="text-h5 q-pb-lg header-activate-user">
                  {{ application.name }}
                </span>
                <q-space></q-space>
                <div class="logo-image"
                  v-if="branding.logo"
                  :style="{ 'background-image': `url('${branding.logo}')` }">
                </div>
              </div>

              <div class="text-subtitle1 title-activate-user">
                Activation of user account <b>{{user.name}}</b>
              </div>

              <q-input square outlined label="First name" v-model="user.firstName" maxlength="45"
                class="input-first-name"
                lazy-rules
                :rules="[
                  rules.required('First name is required')
                ]">
              </q-input>

              <q-input square outlined label="Last name" v-model="user.lastName" maxlength="45"
                class="input-last-name"
                lazy-rules
                :rules="[
                  rules.required('Last name is required')
                ]">
              </q-input>

              <q-input square outlined label="Phone number" v-model="user.phone" maxlength="45" class="input-phone">
              </q-input>

              <q-input square outlined label="Password" type="password" v-model="user.password" maxlength="45"
                class="input-password"
                lazy-rules
                :rules="[
                  rules.required('Password is required')
                ]">
              </q-input>

              <q-input square outlined label="Repeat password" type="password" v-model="repeatPassword" maxlength="45"
                class="input-repeat-password"
                lazy-rules
                :rules="[
                  rules.required('Password is required'),
                  value => value === user.password || 'Passwords must be identical'
                ]">
              </q-input>

              <div class="row q-mt-xs q-gutter-lg">
                <q-space></q-space>
                <q-btn unelevated label="Close" @click="cancel()" class="button-close"></q-btn>
                <q-btn unelevated class="button-activate primary" size="md" label="Activate my account" @click="activate()"></q-btn>
              </div>
            </q-form>
          </q-card>
        </q-page>
      </q-page-container>
    </q-layout>
  </sc-view>
</template>

<style lang="scss" scoped>
.form-activate-user {
  width: 50%;
  max-width: 600px;

  .logo-image {
    width: 200px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: right;
  }

  .button-activate {
    min-width: 180px;
  }
}

/* Layout adjustments for screen below HD resolution */
@media screen and (max-width: 1365px) {
  .form-activate-user {
    width: 80%;
    max-width: 80%;
  }
}

/* Layout adjustments for small screens */
@media screen and (max-width: 1024px) {
  .form-activate-user {
    width: 100%;
    max-width: 100%;
  }
}
</style>