import { createState } from './device-commands.state'

export const mutations = {
  /**
   * Command has been executed on devices
   * @param {DeviceCommand} command Executed command
   * @param {Array[Device]} devices Devices on which the command has been executed
   * @param {Array} results Command results
   */
  deviceCommandSent (state, { command, devices, results } = {}) {
    if (state && devices && command) {
      if (results) {
        // lint-disable-line no-empty
      }
    }
  },

  /**
   * Command has failed on devices
   * @param {DeviceCommand} command Executed command
   * @param {Array[Device]} devices Devices on which the command has been executed
   * @param {Array} results Command results
   */
  deviceCommandFailed (state, { command, devices, results } = {}) {
    if (state && devices && command) {
      if (results) {
        // lint-disable-line no-empty
      }
    }
  },

  /**
   * Resets the state to original shape.
   * @description
   * Presence of this mutation is obligatory on stores,
   * to ensure purging of any user-bound data when
   * user logs in and out and in. Otherwise expect
   * unexpected side effects with data of the previously
   * logged in user appearing at places.
   */
  reset (state) {
    Object.assign(state, createState())
  },

  startFastSamplingCountdown (state, { devices, fastSamplingDuration }) {
    state.fastSamplingDevices = [
      ...state.fastSamplingDevices,
      ...devices.map(device => device.serialNumber)
    ]

    setTimeout(() => {
      state.fastSamplingDevices = state.fastSamplingDevices.filter(device => !devices.find(d => d.serialNumber === device))
    }, fastSamplingDuration*1000)
  },

}

