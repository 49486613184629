import Konva from 'konva'
import { PlanItemType, PlanScale } from '@stellacontrol/planner'
import { Shape } from './shape'

/**
 * Curve
 */
export class CurveShape extends Shape {
  constructor (item, dataCallback) {
    super(item, dataCallback)
    this.content = new Konva.Path()
  }

  static get type () {
    return PlanItemType.Curve
  }

  render (renderer) {
    super.render(renderer)
    const { content: shape, item } = this
    if (shape && item) {
      const { backgroundStyle, lineStyle } = item
      shape.data(item.path)
      shape.stroke(lineStyle.color)
      shape.strokeWidth(lineStyle.width)
      shape.dash(lineStyle.dash)
      shape.fill(backgroundStyle.color)
      this.applyFilters()
    }
  }

  /**
   * Triggered when shape has been transformed.
   * @param {PlanRenderer} renderer Plan renderer
   * @param {PlanScale} scale Scale of the shape
   * @param {Number} rotation Shape rotation
   */
  transformed ({ renderer, scale, rotation }) {
    super.transformed({ renderer, scale, rotation })

    // Store the scale
    const { item } = this
    item.scale = new PlanScale(scale)

    // Re-render the shape
    this.render(renderer)
  }
}
