<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import { Place } from '@stellacontrol/model'
import { ListAction, ViewMixin } from '@stellacontrol/client-utilities'
import { Secure } from '@stellacontrol/security-ui'

const name = 'installations'

export default {
  mixins: [
    ViewMixin,
    Secure
  ],

  data () {
    return {
      name,
      // Indicates whether the user has filtered the list by device serial number.
      filteredDevice: null,

      // Table columns
      columns: [
        { field: 'pinned', label: '', sortable: false },
        { field: 'name', label: 'Name', sortable: true },
        { field: 'organizationPath', label: 'Organization', sortable: true },
        { field: 'deviceCount', label: 'Devices', sortable: true },
        { field: 'hasPlan', label: 'Plan', sortable: false, permissions: ['planner'] },
        { field: 'hasNotes', label: 'Notes', sortable: false },
        { field: 'updatedAt', label: 'Last Modified', sortable: true },
        { field: 'updatedBy', label: 'Modified By', sortable: true },
        { field: 'commands', label: '', sortable: false }
      ],
      // Tooltips for various states of the building
      tooltips: {
        plan: {
          on: 'Edit building plan',
          off: 'Create building plan'
        },
        notes: {
          on: 'Edit notes',
          off: 'Add notes'
        },
        pinned: {
          on: 'Remove from favourite',
          off: 'Add to favourites'
        }
      }
    }
  },

  computed: {
    ...mapGetters([
      'organizationProfiles',
      'isSmallScreen',
      'toLocalDateTime'
    ]),

    ...mapState({
      // Buildings view state
      installationsView: state => state.installationsView,
      // Used to force the update of the installations view
      installationsRefresh: state => state.installationsView.installationsRefresh,
      // All devices
      devices: state => state.devices.devices,
      // Buildings to display
      buildings: state => state.installationsView.places,
      // Current filter
      filter: state => state.installationsView.filter,
      exactFilter: state => state.installationsView.exactFilter,
      // Current sorting
      sortBy: state => state.installationsView.sortBy,
      sortDescending: state => state.installationsView.sortDescending,
      // User's favourite buildings
      pinnedBuildings: state => state.installationsView.pinnedBuildings || []
    }),

    // Checks whether the table is sorted by the specified column
    isSortedBy () {
      return column => this.sortBy === column.field
    },

    // CSS class for the specified column
    getColumnClass () {
      return column => {
        const sorted = this.isSortedBy(column)
        const descending = sorted && this.sortDescending
        return {
          column: true,
          sortable: column.sortable,
          sorted,
          descending
        }
      }
    },

    // List of buildings matching eventual filter
    filteredBuildings () {
      let result
      const { buildings, exactFilter } = this
      const filter = this.filter?.toLowerCase() || ''
      const filters = filter.split(' ').filter(word => word.trim()) || []
      this.filteredDevice = null

      if (filters.length) {
        result = buildings.filter(building => {
          // Check whether text fields match
          const values = [
            building.name.toLowerCase(),
            building.organizationName.toLowerCase(),
            building.deviceCount.toString(),
            building.updatedAt?.toString() || '',
            building.updatedBy?.name || ''
          ]

          if (exactFilter) {
            if (values.some(v => v === filter)) {
              return true
            }
          } else {
            if (filters.some(f => values.some(v => v.includes(f)))) {
              return true
            }
          }

          // Check whether device serial numbers in the place match
          if (filters.some(f => {
            const serialNumber = building.devices.find(s => s.toLowerCase() === f)
            if (serialNumber) {
              this.filteredDevice = serialNumber
              return true
            }
          })) {
            return true
          }

          return false
        })

      } else {
        result = buildings
      }

      // Prevent Vue from observing the list - after all, we don't have live data changes here
      return Object.freeze(result)
    },

    // Checks whether the building is user's favourite
    isBuildingPinned () {
      return building => this.pinnedBuildings.includes(building.id)
    },

    // Determines the icon for the place
    buildingIcon () {
      return building => this.pinnedBuildings.includes(building.id) ? 'star' : 'star_border'
    },

    // Determines the color of the building icon
    buildingIconColor () {
      return building => this.pinnedBuildings.includes(building.id) ? 'amber-9' : 'grey-6'
    },

    // Determines the actions available for the building
    buildingActions () {
      return building => {
        const actions = []
        if (!building.isStock) {
          if (building.isMyBuilding || this.canUse('child-places')) {
            actions.push(ListAction.Edit)
            actions.push(ListAction.Delete)
          }
        }
        return actions
      }
    },

    // View title
    title () {
      return `Buildings (${this.buildings.length})`
    },

    // Determines a route for the building
    getBuildingRoute () {
      return building => ({
        name: 'building-dashboard',
        params: {
          id: building.isStock ? Place.ID_NOPLACE : building.id,
          organizationId: building.organizationId
        }
      })
    },

    // Determines a route for the building plan
    getBuildingPlanRoute () {
      return building => building.isStock
        ? null
        : {
          name: 'building-plan',
          params: {
            id: building.id
          }
        }
    },

    // Checks whether the user can edit the building plan
    canEditPlan () {
      return building => !building.isStock &&
        this.canUse('planner') &&
        (building.isMyBuilding || this.canUse('child-floor-plans'))
    },

    // Checks whether the user can edit the building notes
    canEditNotes () {
      return building => !building.isStock && (building.isMyBuilding || this.canUse('child-places'))
    },

    // Indicates whether the user has access to device dashboard
    canSeeDeviceDashboard () {
      return this.canUse('device-dashboard')
    },

    // Route to device dashboard
    getDeviceDashboardRoute () {
      return serialNumber => serialNumber
        ? {
          name: 'device-dashboard',
          params: { serialNumber }
        }
        : null
    }
  },

  methods: {
    ...mapActions([
      'showDialog',
      'createPlace',
      'filterBuildings',
      'sortBuildings',
      'pinFavouriteBuilding',
      'showPlaceNotes',
      'getPlace',
      'editPlace',
      'removePlace'
    ]),

    // Changes the sort order of the table
    sort (column) {
      const { installationsView } = this
      const { field: sortBy, sortable } = column
      if (sortable) {
        const sortDescending = installationsView.sortBy === sortBy && !installationsView.sortDescending
        this.sortBuildings({ sortBy, sortDescending })
      }
    },

    // Edit building notes
    async editBuildingNotes (id) {
      const building = await this.getPlace({ id, withAttachments: true })
      if (building) {
        this.showPlaceNotes({ place: building })
      }
    },

    // Executes a context menu action for the specified building
    executeAction (building, action) {
      switch (action.name) {
        case 'edit':
          return this.editPlace({ place: building })
        case 'delete':
          return this.removePlace({ place: building, confirm: true })
      }
    },

    // Returns tooltips for table icons
    tooltip (type, value) {
      return this.tooltips[type][value ? 'on' : 'off']
    }
  }
}

</script>

<template>
  <sc-view :name="name" subheader-color="#fff" :title="title">
    <!-- Desktop mode toolbar -->
    <template #toolbar>
      <div class="buildings-toolbar row items-center no-wrap">
        <div class="buildings-filter row items-center no-wrap">
          <q-input clearable square label="Filter" :model-value="filter" class="input-filter"
            bg-color="white" :outlined="true"
            @update:model-value="filter => filterBuildings({ filter })" dense debounce="500">
            <template v-slot:append>
              <sc-hint class="hint" text="Enter filters separated by spaces" size="20px" />
            </template>
          </q-input>
        </div>

        <q-space></q-space>

        <div class="row items-center no-wrap justify-end">
          <q-btn flat class="primary" icon="add" label="Add Building" color="indigo-6"
            @click="createPlace()"></q-btn>
        </div>
      </div>
    </template>

    <!-- When in mobile mode, show buttons inside the topbar -->
    <teleport v-if="isSmallScreen" to="#topbar-items">
      <div class="buildings-filter row items-center no-wrap q-pl-md">
        <q-input clearable square label="Filter" class="input-filter" bg-color="white" outlined
          dense :model-value="filter" @update:model-value="filter => filterBuildings({ filter })"
          debounce="500">
          <template v-slot:append>
            <sc-hint class="hint" text="Enter filters separated by spaces" size="20px" />
          </template>
        </q-input>
      </div>
    </teleport>

    <div class="buildings column items-stretch"
      v-memo="[sortBy, sortDescending, filter, pinnedBuildings, installationsRefresh]">
      <div class="list">

        <!-- Header row -->
        <div class="header">
          <span v-for="column of columns" @click="sort(column)" :class="getColumnClass(column)">
            <template v-if="canUseAll(column.permissions)">
              {{ column.label }}
              <q-icon v-if="isSortedBy(column)"
                :name="sortDescending ? 'keyboard_arrow_down' : 'keyboard_arrow_up'"
                color="indigo-7" size="xs" class="q-ml-xs">
              </q-icon>
            </template>
            <template v-else>
              &nbsp;
            </template>
          </span>
        </div>

        <!-- Buildings -->
        <div class="building" v-for="building of filteredBuildings">
          <!-- 1 -->
          <span>
            <q-btn dense unelevated no-caps class="clear" :icon="buildingIcon(building)"
              :textColor="buildingIconColor(building)" @click.stop="pinFavouriteBuilding(building)">
              <sc-tooltip :text="tooltip('pinned', isBuildingPinned(building))" />
            </q-btn>
          </span>

          <!-- 2 -->
          <span>
            <router-link class="item-link" :to="getBuildingRoute(building)">
              {{ building.name }}
              <label class="stock-owner" v-if="building.isStock">
                / {{ building.organizationName }}
              </label>
              <label class="owner" v-else>
                / {{ building.organizationName }}
              </label>
            </router-link>
          </span>

          <!-- 3 -->
          <span>
            {{ building.organizationPath }}<br>
          </span>

          <!-- 4 -->
          <span class="4">
            <template v-if="filteredDevice && canSeeDeviceDashboard">
              <q-btn dense unelevated no-caps icon="dashboard" textColor="indigo-5" class="clear"
                :to="getDeviceDashboardRoute(filteredDevice)">
                <sc-tooltip>
                  Open the {{ filteredDevice }} dashboard
                </sc-tooltip>
              </q-btn>
            </template>
            <template v-else>
              {{ building.deviceCount || 0 }}
            </template>
          </span>

          <!-- 5 -->
          <span>
            <q-btn v-if="canEditPlan(building)" dense unelevated no-caps class="clear"
              icon="category" :textColor="building.hasPlan ? 'indigo-5' : 'indigo-2'"
              :to="getBuildingPlanRoute(building)">
              <sc-tooltip :text="tooltip('plan', building.hasPlan)" />
            </q-btn>
            <span v-else>
              &nbsp;
            </span>
          </span>

          <!-- 6 -->
          <span>
            <q-btn v-if="canEditNotes(building)" dense unelevated no-caps class="clear"
              icon="comment" :textColor="building.hasNotes ? 'indigo-5' : 'indigo-2'"
              @click.stop="editBuildingNotes(building.id)">
              <sc-tooltip :text="tooltip('notes', building.hasNotes)" />
            </q-btn>
            <span v-else>
              &nbsp;
            </span>
          </span>

          <!-- 7 -->
          <span>
            {{ building.updatedAt ? datetime(toLocalDateTime(building.updatedAt)) : '' }}
          </span>

          <!-- 8 -->
          <span>
            {{ building.updatedBy }}
          </span>

          <!-- 9 -->
          <span>
            <sc-action-dropdown v-if="!building.isStock" :data="building"
              :actions="buildingActions(building)"
              @action="action => executeAction(building, action)">
            </sc-action-dropdown>
          </span>
        </div>

      </div>
    </div>

    <sc-document-upload-dialog></sc-document-upload-dialog>
  </sc-view>
</template>

<style lang="scss" scoped>
.buildings-toolbar {
  flex: 1;
}

.buildings-filter {
  flex: 1;
  padding-left: 100px;
  padding-right: 100px;

  .q-input {
    width: 100%;
  }
}

.buildings {
  flex: 1;
  position: relative;
  overflow: auto;
  background-color: white;
  padding: 16px;

  .list {
    max-width: 1400px;
    background-color: transparent;
    display: grid;
    grid-template-columns: 50px auto auto 85px 55px 55px 170px auto 50px;

    /* Header */
    .header {
      .column {
        color: #6772a2;

        &.sortable {
          cursor: pointer;
        }

        &.sorted {
          color: #332a92;
          font-weight: bold;
        }
      }
    }

    /* Rows */
    .header,
    .building {
      display: contents;

      span {
        display: flex;
        height: 40px;
        padding-right: 8px;
        flex-direction: row;
        align-items: center;
        text-wrap: nowrap;
        flex-wrap: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        border-bottom: solid #0000001f 1px;

        /* Columns */
        /* Hide building owner, only needed on very small screens */
        &:nth-child(2) {
          .owner {
            display: none;
          }
        }

        /* Right-align device count */
        &:nth-child(4) {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding-right: 12px;
        }
      }
    }
  }
}

/* Layout adjustments for small screens */
@media screen and (width <=1024px) {
  .buildings-filter {
    padding-left: 10px;
    padding-right: unset;

    .input-filter {
      width: 100%;
    }
  }

  .buildings {
    overflow: auto;

    .list {
      grid-template-columns: 40px auto auto 85px 0 0 auto auto 0;

      /* Rows */
      .header,
      .building {
        span {

          /* Hide action columns */
          &:nth-child(5),
          &:nth-child(6),
          &:nth-child(9) {
            width: 0;
            visibility: hidden;
          }
        }
      }
    }
  }
}

@media screen and (width <=800px) {
  .buildings {

    .list {
      grid-template-columns: 40px auto auto 85px 0 0 auto 0 0;

      /* Rows */
      .header,
      .building {
        span {

          /* Also hide the updater */
          &:nth-child(8) {
            width: 0;
            visibility: hidden;
          }
        }
      }
    }
  }
}

@media screen and (width <=680px) {
  .buildings {

    .list {
      grid-template-columns: 40px auto 0 64px 0 0 0 0 0;

      /* Hide the header */
      .header {
        display: none;
      }

      /* Rows */
      .header,
      .building {
        span {

          /* Hide path and everything after device count */
          &:nth-child(3),
          &:nth-child(7),
          &:nth-child(8),
          &:nth-child(7),
          &:nth-child(8) {
            width: 0;
            visibility: hidden;
          }

          /* Show building owner */
          &:nth-child(2) {
            .owner {
              display: unset;
            }
          }

          /* Right-align device count */
          &:nth-child(4) {
            padding-right: 0;
          }
        }
      }
    }
  }
}
</style>
