import { Assignable } from '../common/assignable'
import { parseDate } from '@stellacontrol/utilities'

/**
 * Upload processing step
 */
export class UploadStep extends Assignable {
  constructor (data = {}) {
    super()
    this.assign(
      {
        ...data,
        time: data.time || new Date()
      },
      {
        time: parseDate,
        failed: Boolean
      }
    )
  }

  /**
   * Identifier
   */
  id

  /**
   * Job identifier
   */
  jobId

  /**
   * Time when the step has been taken
   */
  time

  /**
   * Job processing status after executing the step
   */
  status

  /**
   * Progress at the moment of reporting the upload
   */
  progress

  /**
   * Other details
   */
  details

  /**
   * Indicates that the step has failed.
   * This is different than {@link UploadJob} failed status.
   * It indicates that job has failed while executing
   * the specified processing step, such as `Sending`.
   */
  failed

  /**
   * Returns true if step has succeeded
   */
  get succeeded () {
    return !this.failed
  }
}
