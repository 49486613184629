import PlainTextCell from './plain-text.cell.vue'
import DateCell from './date.cell.vue'
import IsSelectedCell from './is-selected.cell.vue'
import IsOnlineCell from './is-online.cell.vue'
import SerialNumberCell from './serial-number.cell.vue'
import NameCell from './name.cell.vue'
import AlertsCell from './alerts.cell.vue'
import OwnershipCell from './ownership.cell.vue'
import TagsCell from './tags.cell.vue'
import PlaceCell from './place.cell.vue'
import PlaceTypeCell from './place-type.cell.vue'
import LocationCell from './location.cell.vue'
import NotesCell from './notes.cell.vue'
import PremiumServiceCell from './premium-service.cell.vue'
import PremiumServiceStatusCell from './premium-service-status.cell.vue'
import FirmwareVersionCell from './firmware-version.cell.vue'

export default {
  'sc-plain-text-cell': PlainTextCell,
  'sc-date-cell': DateCell,
  'sc-is-selected-cell': IsSelectedCell,
  'sc-is-online-cell': IsOnlineCell,
  'sc-serial-number-cell': SerialNumberCell,
  'sc-name-cell': NameCell,
  'sc-alerts-cell': AlertsCell,
  'sc-ownership-cell': OwnershipCell,
  'sc-tags-cell': TagsCell,
  'sc-place-cell': PlaceCell,
  'sc-place-type-cell': PlaceTypeCell,
  'sc-location-cell': LocationCell,
  'sc-notes-cell': NotesCell,
  'sc-premium-service-cell': PremiumServiceCell,
  'sc-premium-service-status-cell': PremiumServiceStatusCell,
  'sc-firmware-version-cell': FirmwareVersionCell
}
