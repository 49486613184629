<script>
import { mapActions, mapGetters } from 'vuex'
import { countString } from '@stellacontrol/utilities'
import { Secure } from '@stellacontrol/security-ui'
import { CellMixin } from './cell-mixin'

const TOOLTIP_LOADING = 'Loading ...'

export default {
  mixins: [
    CellMixin,
    Secure
  ],

  props: {
    item: {
      type: Object,
      required: true,
    }
  },

  data () {
    return {
      // Tooltips for each organization in ownership hierarchy
      tooltip: {}
    }
  },

  computed: {
    ...mapGetters([
      'organizationProfiles'
    ]),

    // Returns the ownership path for the device
    ownership () {
      return this.item.ownership
    },

    // Returns the owner of the device
    owner () {
      return this.item.device.owner
    },

    // Returns true if organization can access dashboards of child organizations
    canAccessDashboards () {
      return this.canUse('child-places')
    },

    // Ownership items to display
    items () {
      const { isAdministrator, owner } = this
      const items = this.item.ownership
        .filter((o, index) => index > 1 || (o.id === owner.id || !o.isSuperOrganization))
        .map(({ id, name, profileId }) => {
          const tooltip = isAdministrator
            ? `Go to settings of ${name}`
            : name
          return { id, name, profileId, tooltip }
        })

      return items
    },

    // Organization tooltip
    tooltipOf () {
      return organization => organization ? (this.tooltip[organization.id] || TOOLTIP_LOADING) : undefined
    }
  },

  methods: {
    ...mapActions([
      'gotoOrganizationDashboard',
      'getWallet'
    ]),


    // Loads tooltip for the specified organization
    async loadTooltipOf (organization) {
      if (organization) {
        const profile = this.organizationProfiles.find(p => p.id === organization.profileId)
        const lines = []
        lines.push(`${organization.name}`)
        if (profile) lines.push(`${profile.fullName}`)
        if (this.canUseAny(['premium-services-sell', 'premium-services-tokens'])) {
          const wallet = await this.getWallet({ organization })
          if (wallet) {
            lines.push(`${countString(wallet.balance, 'token', 'No tokens in the wallet')}`)
          }
        }
        lines.push('')
        lines.push('Click to go to organization settings')
        this.tooltip[organization.id] = lines.join('<br>')
      }
    }
  }
}
</script>

<template>
  <div :style="columnStyle" class="text">
    <template v-for="item in items">

      <router-link class="item-link" v-if="isAdministrator && item.id != currentOrganization.id"
        :to="{ name: 'organization', params: { id: item.id } }">
        {{ item.name }}
        <sc-tooltip @show="loadTooltipOf(item)" :text="tooltipOf(item)">
        </sc-tooltip>
      </router-link>

      <span v-else>
        {{ item.name }}
      </span>

      <q-icon name="chevron_right" size="xs" color="grey-7" v-if="item.id !== owner.id"
        :key="`i-${item.id}`">
      </q-icon>

    </template>
  </div>
</template>