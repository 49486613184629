import { DateRange, DeviceHierarchy } from '@stellacontrol/model'
import { AppletRoute } from '../../router'

export const actions = {
  /**
   * Initializes the DEVICE HISTORY view
   * @param {DeviceHierarchy} hierarchy Hierarchy of organizations, places and devices available to the currently logged in organization and user
   * @param {Organization} organization Organization to view or organization associated with the entity
   * @param {OrganizationGuardian} organizationGuardian Organization's guardian
   * @param {Device} device Device to view
   * @param {Boolean} isTreeMinimized Indicates whether to collapse the assets tree initially
   */
  async initializeDeviceHistoryView ({ commit, dispatch, getters, state }, { hierarchy, organization, organizationGuardian, device, isTreeMinimized, isTreeHidden } = {}) {
    const { cannotUse } = getters

    // If organization does not have permission to see child organizations,
    // remove child organizations from hierarchy
    if (cannotUse('child-organizations')) {
      hierarchy.organizations = []
    }

    // Retrieve previously stored tree settings
    const viewMode = await dispatch('getUserPreference', { name: 'device-history-tree-view-mode' })
    const isMinimized = isTreeMinimized || await dispatch('getUserPreference', { name: 'device-history-tree-minimized' })
    const treeFilter = await dispatch('getUserPreference', { name: 'device-history-tree-filter' })
    let expanded = await dispatch('getUserPreference', { name: 'device-history-expanded-items' })
    // If nothing has been marked as expanded yet, expand the top level only
    if (!expanded) {
      expanded = [hierarchy.id]
    }
    const filterPeriod = await dispatch('getUserPreference', { name: 'device-history-period', defaultValue: state.filter.period.toString() })
    const filterParameters = await dispatch('getUserPreference', { name: 'device-history-parameters', defaultValue: state.filter.parameters })
    const filterExtras = await dispatch('getUserPreference', { name: 'device-history-extras', defaultValue: state.filter.extras })


    commit('initializeDeviceHistoryView', {
      hierarchy,
      organization,
      organizationGuardian,
      device,
      tree: {
        viewMode,
        isMinimized,
        isHidden: isTreeHidden,
        filter: treeFilter,
        expanded,
        selected: null
      },
      filter: {
        period: filterPeriod ? DateRange.fromString(filterPeriod) : state.filter.period,
        parameters: filterParameters || state.filter.parameters,
        extras: filterExtras || state.filter.extras
      }
    })
  },

  /**
   * Returns devices and organizations hierarchy for the dashboard view.
   * Re-uses the previously fetched data if already available.
   */
  async getDeviceHistoryViewHierarchy ({ state, getters, commit }) {
    const { organizations, currentOrganization: organization, devices, places } = getters
    const hierarchy = state.hierarchy || DeviceHierarchy.fromOrganizations({ organizations, organization, places, devices })
    commit('updateDeviceHistoryView', { hierarchy })
    return hierarchy
  },

  /**
   * Stores currently expanded tree items
   * @param expanded List of identifiers of items currently expanded in the asset tree
   */
  async storeExpandedDeviceHistoryItems ({ commit, dispatch }, { expanded = [] } = {}) {
    const value = Array.from(new Set(expanded))
    await dispatch('storeUserPreference', { name: 'device-history-expanded-items', value })
    commit('storeExpandedDeviceHistoryItems', { expanded: value })
  },

  /**
   * Stores currently visibility of the tree
   * @param isMinimized Visibility of the tree
   * @param filter Filter applied to the tree
   * @param viewMode Tree view mode
   */
  async storeDeviceHistoryTreeState ({ commit, dispatch }, { isMinimized, filter, viewMode } = {}) {
    if (isMinimized !== undefined) {
      await dispatch('storeUserPreference', { name: 'device-history-tree-minimized', value: Boolean(isMinimized) })
    }
    if (filter !== undefined) {
      await dispatch('storeUserPreference', { name: 'device-history-tree-filter', value: filter })
    }
    if (viewMode !== undefined) {
      await dispatch('storeUserPreference', { name: 'device-history-tree-view-mode', value: viewMode })
    }
    commit('storeDeviceHistoryTreeState', { isMinimized, filter, viewMode })
  },

  /**
   * Stores device history filter
   * @param {DateRange} period Filter period to store
   * @param {Array[String]} parameters Filter parameters to store
   * @param {Array[String]} extras Filter extras to store
   */
  async storeDeviceHistoryFilter ({ dispatch, commit }, { device, period, parameters, extras } = {}) {
    commit('storeDeviceHistoryFilter', { period, parameters, extras })
    if (device) {
      await dispatch('storeUserPreference', { name: 'device-history-filter-device', value: device.serialNumber })
    }
    if (period) {
      await dispatch('storeUserPreference', { name: 'device-history-period', value: (period || DateRange.today()).toString() })
    }
    if (parameters) {
      await dispatch('storeUserPreference', { name: 'device-history-parameters', value: parameters })
    }
    if (extras) {
      await dispatch('storeUserPreference', { name: 'device-history-extras', value: extras })
    }
  },

  /**
   * Navigates to device history view
   * @param {Device} device Device to show
   */
  async gotoDeviceDeviceHistory ({ dispatch }, { device: { id, serialNumber } = {} } = {}) {
    if (!(id || serialNumber)) throw new Error('Device identifier or serial number is required')

    await dispatch('gotoRoute', {
      name: AppletRoute.DeviceHistory,
      params: {
        device: id || serialNumber
      }
    })
  }
}
