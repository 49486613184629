import { LoadingBar } from 'quasar'

/**
 * Wrapper around the Quasar loading bar
 * which indicates ongoing web requests
 */
export class LoadingBarService {
  /**
   * Loading bar element
   */
  element

  /**
   * Loading bar options
   */
  options

  /**
   * Indicates that the bar has been temporarily disabled
   */
  isDisabled

  /**
   * Indicates the party which has disabled the loading bar
   */
  disabledBy

  /**
   * Initializes the loading bar
   * @param color Bar color
   * @param size Bar size in CSS units
   * @param position Bar position (top, bottom, left, right)
   */
  initialize ({ color = 'purple', size = '8px', position = 'bottom' } = {}) {
    if (LoadingBar) {
      this.element = document.querySelector('.q-loading-bar')
      this.options = { color, size, position }
      LoadingBar.setDefaults(this.options)
    }
  }

  /**
   * Disables the loading bar.
   * Useful if for some requests you don't want to display it.
   * @param disabledBy Indicates the party which has disabled the loading bar
   */
  disable (disabledBy) {
    if (!this.isDisabled && this.element) {
      this.element.setAttribute('hidden', '')
      this.isDisabled = true
      this.disabledBy = disabledBy
    }
  }

  /**
   * Re-enabled the previously disabled loading bar.
   * @param disabledBy Indicates the party which has disabled the loading bar
   * @description
   * Prevents re-enabling the loading bar by a party another than the one which has
   * disabled the loading bar
   */
  enable (disabledBy) {
    if (this.isDisabled && this.element && (!this.disabledBy || disabledBy === this.disabledBy)) {
      window.setTimeout(() => {
        this.element.removeAttribute('hidden')
        this.isDisabled = false
      }, 1000)
    }
  }
}
