/**
 * Service for exporting data to files
 */
export class ExporterService {
  /**
   * Exports the specified text to a file
   * @param {String} text Text to copy
   * @param {String} fileName File name
   * @param {String} mimeType File type
   * @returns {Promise} Promise resolved if browser has permitted the file to be downloaded
   */
  toFile (text, fileName, mimeType = 'text/plain') {
    if (!text) throw new Error('Text to export is required')
    if (!fileName) throw new Error('File name is required')
    if (!mimeType) throw new Error('MIME type is required')

    const { exportFile } = Quasar
    const result = exportFile(fileName, text, mimeType)
    if (result) {
      return Promise.resolve(result)
    } else {
      return Promise.reject()
    }
  }
}
