import {
  CommonAPIClient,
  SecurityAPIClient,
  DeviceAPIClient,
  DeviceTransmissionAPIClient,
  AlertsAPIClient,
  NotificationsAPIClient,
  ServiceManagementAPIClient,
  PlannerAPIClient,
  LegacyAPIClient
} from './api-client'

/**
 * Common API Client instance
 * @description The instance has to be initialized before use with `initialize(configuration)` method.
 * Configuration should contain all the required API back-end details.
 */
export const CommonAPI = new CommonAPIClient()

/**
 * Security API Client instance
 */
export const SecurityAPI = new SecurityAPIClient()

/**
 * Device API Client instance
 */
export const DeviceAPI = new DeviceAPIClient()

/**
 * Legacy API Client instance
 */
export const LegacyAPI = new LegacyAPIClient()

/**
 * Device Transmission API Client instance
 */
export const DeviceTransmissionAPI = new DeviceTransmissionAPIClient()

/**
 * Alerts API Client instance
 */
export const AlertsAPI = new AlertsAPIClient()

/**
 * Notifications API Client instance
 */
export const NotificationsAPI = new NotificationsAPIClient()

/**
 * Service Management API Client instance
 */
export const ServiceManagementAPI = new ServiceManagementAPIClient()

/**
 * StellaPlanner API Client instance
 */
export const PlannerAPI = new PlannerAPIClient()

/**
 * API identifiers
 */
export const APIs = {
  Common: 'common',
  Security: 'security',
  Devices: 'devices',
  Alerts: 'alerts',
  Notifications: 'notifications',
  DeviceTransmission: 'deviceTransmission',
  ServiceManagement: 'serviceManagement',
  Planner: 'planner',
  Legacy: 'legacy'
}

/**
 * API directory
 */
export const API = {
  // Service keys, as specified in client's configuration.services
  [APIs.Common]: CommonAPI,
  [APIs.Security]: SecurityAPI,
  [APIs.Devices]: DeviceAPI,
  [APIs.DeviceTransmission]: DeviceTransmissionAPI,
  [APIs.Alerts]: AlertsAPI,
  [APIs.Notifications]: NotificationsAPI,
  [APIs.ServiceManagement]: ServiceManagementAPI,
  [APIs.Planner]: PlannerAPI,
  [APIs.Legacy]: LegacyAPI
}
