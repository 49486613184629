import PremiumServicesAuditView from './premium-services-audit.vue'
import { resolve } from './premium-services-audit.resolve'

export const Routes = [
  {
    name: 'premium-services-audit',
    path: '/premium-services/audit',
    component: PremiumServicesAuditView,

    async beforeEnter (to, from, next) {
      if (await resolve({ to, from })) {
        next()
      }
    }
  }
]
