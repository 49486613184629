import DeviceHistoryView from './device-history.vue'
import { resolve } from './device-history.resolve'

export const Routes = [
  {
    name: 'device-history',
    view: 'device-history',
    path: '/device-history/:device?',
    query: { tree: 'minimized' },
    component: DeviceHistoryView,

    async beforeEnter (to, from, next) {
      const result = await resolve({ to, from })
      if (result) {
        next(result.redirectTo)
      }
    }
  }
]
