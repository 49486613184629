import { safeParseInt, safeParseFloat, parseEnum } from '@stellacontrol/utilities'
import { Assignable } from '@stellacontrol/model'

/**
 * Line types
 */
export const PlanLineType = {
  Single: 'single',
  Dotted: 'dotted',
  Dashed: 'dashed'
}

/**
 * Properties of a line
 */
export class PlanLineStyle extends Assignable {
  constructor (data = {}) {
    super(data)
    this.assign(data)
  }

  /**
   * Creates style representing a single black line
   * @type {PlanLineStyle}
   */
  static get Default () {
    return new PlanLineStyle({ width: 2 })
  }

  /**
   * Creates style representing no line
   * @type {PlanLineStyle}
   */
  static get None () {
    return new PlanLineStyle({ width: 0 })
  }

  /**
   * Creates style representing a line one-pixel wide
   * @type {PlanLineStyle}
   */
  static get Single () {
    return new PlanLineStyle({ width: 1 })
  }

  /**
   * Creates style representing a line two-pixels wide
   * @type {PlanLineStyle}
   */
  static get Double () {
    return new PlanLineStyle({ width: 2 })
  }

  /**
   * Object defaults
   */
  get defaults () {
    return {
      width: 1,
      type: PlanLineType.Single,
      color: 'black',
      radius: 0,
      tension: 0,
      lineCap: 'round',
      lineJoin: 'round'
    }
  }

  normalize () {
    super.normalize()
    const { defaults } = this
    this.width = Math.max(0, safeParseInt(this.width, defaults.width))
    this.type = parseEnum(PlanLineType, this.type, defaults.type)
    this.color = this.color || defaults.color
    this.radius = safeParseInt(this.radius, defaults.radius)
    this.tension = Math.max(0, safeParseFloat(this.tension, defaults.tension))
    this.lineCap = this.lineCap || defaults.lineCap
    this.lineJoin = this.lineJoin || defaults.lineJoin
  }

  /**
   * Checks if style is the same as the specified one
   * @param {PlanLineStyle} style
   * @returns {Boolean}
   */
  sameAs (style) {
    if (style) {
      return this.width === style.width &&
        this.type === style.type &&
        this.color === style.color &&
        this.radius === style.radius &&
        this.tension === style.tension &&
        this.lineCap === style.lineCap &&
        this.lineJoin === style.lineJoin
    }
  }

  /**
   * Indicates that the style represents a line
   * @type {Boolean}
   */
  get isLineStyle () {
    return true
  }

  /**
   * Line width, in pixels
   * @type {Number}
   */
  width

  /**
   * Line color
   * @type {String}
   */
  color

  /**
   * Line type
   * @type {PlanLineType}
   */
  type

  /**
   * Corner radius for line bends, in pixels
   * @type {Number}
   */
  radius

  /**
   * Line tension, determining the level of curve interpolation on line joints
   * @type {Number}
   */
  tension

  /**
   * Line end style, can be `butt`, `round`, or `square`
   * @type {String}
   */
  lineCap

  /**
   * Line join style, can be `miter`, `round`, or `bevel`
   * @type {String}
   */
  lineJoin

  /**
   * Returns true if style is not defined
   * @type {Boolean}
   */
  get isEmpty () {
    return !(this.color && this.width > 0)
  }

  /**
   * Calculates dash pattern for the specified line {@link type}
   * @returns {Array[Number]}
   */
  get dash () {
    const { type, width } = this
    return PlanLineStyle.getLineDash(type, width)
  }

  /**
   * Calculates dash pattern for the specified line {@link type}
   * @param {PlanLineType} type Dash type
   * @param {Number} width Line width
   * @returns {Array[Number]}
   */
  static getLineDash (type, width = 1) {
    const segment = Math.min(width, 5)
    const dash = type === PlanLineType.Dashed
      ? [segment * 6, segment * 2]
      : type === PlanLineType.Dotted
        ? [width, width]
        : undefined
    return dash
  }
}
