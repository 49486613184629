<script>
import { PlanItemType, PlanPaletteItem, PlanRuler, PlanLayers } from '@stellacontrol/planner'
import { PlanActions, executePlanAction } from '../../renderer/actions'

export default {
  props: {
    // Plan renderer
    renderer: {
    }
  },

  computed: {
    floor () {
      return this.renderer?.floor
    },

    // Radiation properties
    radiation () {
      return this.floor?.radiation
    },

    radiationStrength () {
      return this.radiation?.strength
    },

    radiationStrengthLabel () {
      return `${this.radiation?.strength}%`
    },

    isCrossSection () {
      return this.renderer?.isCrossSection
    },

    canDrawRuler () {
      return !this.isCrossSection && this.floor.hasMapScale
    },

    canSetAntennaRange () {
      return !this.isCrossSection && this.floor.hasMapScale
    },

    isAddingRuler () {
      const { renderer } = this
      if (renderer) {
        return renderer.isAddingItem && renderer.itemToAdd.type === PlanItemType.Ruler
      }
    }
  },

  methods: {
    // Adds a ruler to the plan
    async addRuler () {
      const { renderer } = this
      if (this.isAddingRuler) {
        renderer.stopAddingItem()
      } else {
        const item = new PlanPaletteItem({
          type: PlanItemType.Ruler,
          factory: () => PlanRuler.create()
        })
        renderer.startAddingItem(item, PlanLayers.Items)
      }
    },

    // Sets the global radiation strength
    async setRadiationStrength (strength) {
      const { renderer } = this
      await executePlanAction({ renderer, action: PlanActions.SetRadiationStrength, strength })
    },

    // Hides the radiation strength popup,
    // ask the user whether to apply to other floors as well
    async hideRadiationStrengthPopup () {
      this.$refs.radiationStrengthPopup.hide()

      const { renderer } = this
      const { strength } = renderer.floor.radiation
      await executePlanAction({ renderer, action: PlanActions.SetRadiationStrength, strength, allFloors: true })
    }
  }
}
</script>

<template>
  <div class="tools row items-center" v-if="renderer">
    <!-- Antenna range slider -->
    <q-btn v-if="canSetAntennaRange" class="q-mr-sm" icon="wifi_tethering" flat unelevated dense
      round size="13px" color="grey-1" @click="$refs.antennaRangeTooltip.hide()">
      <sc-tooltip ref="antennaRangeTooltip">
        Antenna strength: {{ radiationStrength }}%
      </sc-tooltip>
      <q-popup-proxy ref="radiationStrengthPopup">
        <div class="radiation-strength-popup q-pa-md bg-grey-3">
          <div class="q-mb-sm">
            Antenna Strength: {{ radiationStrength }}%
          </div>
          <q-slider :model-value="radiationStrength"
            @update:model-value="value => setRadiationStrength(value)" :min="20" :max="500"
            :step="10" @change="hideRadiationStrengthPopup()" label label-always switch-label-side
            :label-value="radiationStrengthLabel" />
        </div>
      </q-popup-proxy>
    </q-btn>

    <!-- Button for addding rulers -->
    <q-btn v-if="canDrawRuler" icon="straighten" flat unelevated dense round size="13px"
      :color="isAddingRuler ? 'orange-8' : 'grey-1'" @click="addRuler()">
      <sc-tooltip>
        Add a ruler to the plan
      </sc-tooltip>
    </q-btn>
  </div>
</template>

<style lang="scss" scoped>
.tools {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

.radiation-strength-popup {
  width: 200px;
  height: 110px;
}
</style>
