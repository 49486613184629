import Vue from 'vue'
import Application from './application.vue'
import { useClient, useErrorHandler } from '@stellacontrol/client-utilities'

/**
 * Initializes the application
 * @param pkg Applet package definition
 * @param views Applet views
 * @param routes Applet routes
 * @param stores Applet state stores
 * @param use Dictionary of plugins to install with Vue,
 * used to register applet-specific components and services.
 * These can be either objects or functions:
 * - if object, it's assumed to have `install` function as required by `Vue.use()`
 * - if function, it is called with Vue application instance as argument
 */
export function runApplication ({ pkg, routes, views, stores, use = {} }) {
  if (!pkg) throw new Error('Application package.json required')
  if (!routes) throw new Error('Application routes required')
  if (!views) throw new Error('Application views required')
  if (!stores) throw new Error('Application stores required')

  // Mount the application once the page is loaded
  window.addEventListener('load', async () => {
    const application = Vue.createApp(Application)

    useErrorHandler(application)

    await useClient({
      isApplet: false,
      pkg,
      application,
      stores,
      routes,
      views,
      use
    })

    application.mount('#stellacontrol')

    return application
  })
}
