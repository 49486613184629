<script>
export default {
  props: {
    // Section collapse status, dictionary
    collapsed: {
      type: Object,
      default: () => { }
    },
    // Data file
    file: {
    }
  },

  data () {
    return {
      // Tree nodes
      nodes: []
    }
  },

  computed: {
    // Parsed and formatted content
    content () {
      const data = this.file?.data?.results
      return data
        ? JSON.stringify(data, null, 2)
        : ''
    }
  },

  emits: [
    'toggle'
  ],

  methods: {
    // Toggles a section
    toggle ({ section }) {
      this.$emit('toggle', { section })
    },

    // Populates the view
    populate () {
    }
  },

  created () {
    this.populate()
  }
}

</script>

<template>
  <div class="data">
    <textarea class="content" readonly>
      {{ content }}
    </textarea>
    <!-- <sc-document-viewer type="json" :content="file.content">
    </sc-document-viewer> -->
  </div>
</template>

<style lang="scss" scoped>
.data {
  padding-top: 8px;
  padding-bottom: 8px;

  .content {
    width: 100%;
    height: 50vh;
    border: none;
    padding: 4px;
    font-family: 'Courier New', Courier, monospace;
    font-size: 15px;
  }
}
</style>
