/**
 * Modes of viewing the cable lengths on the plan
 */
export const CableLengthMode = {
  // Cable labels and lengths not shown at all
  Off: 'off',
  // Real cable lengths shown (only available when map scale has been defined)
  Actual: 'actual'
}

/**
 * Modes of viewing the cable lengths on the plan
 */
export const CableLengthModeName = {
  // Cable labels and lengths not shown at all
  [CableLengthMode.Off]: 'Don\'t show',
  // Real cable lengths shown (only available when map scale has been defined)
  [CableLengthMode.Actual]: 'Real lengths'
}
