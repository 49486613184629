/**
 * Calculates the signal level for the specified value, within the given range
 * @param {Number} value Signal value
 * @param {Number} min Minimal allowed value
 * @param {Number} bad Value below which the signal is seen as 'bad'
 * @param {Number} good Value above which the signal is seen as 'good'
 * @param {Number} max Maximal allowed value
 * @returns {Object} Object with the following properties:
 *  `level`: signal level within range of `0..100`
 *  `range`: qualification of the signal level: `good`, `normal` or `bad`
 */
export function getSignalLevel (value, min, bad, good, max) {
  const normalized = Math.min(max, Math.max(min, value))
  const level = Math.min(100, Math.round(100 * (normalized - min) / (max - min)))
  const range = normalized < bad
    ? 'bad'
    : (normalized > good ? 'good' : 'normal')
  return { level, range }
}
