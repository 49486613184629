import { distinctValues } from '@stellacontrol/utilities'

export const actions = {
  /**
   * Triggered when inventory selection has changed
   * @param {Array[Device]} devices Currently selected devices
   * @param {Array[DevicePresenter]} items Currently selected inventory items
   */
  async inventorySelectionChanged ({ dispatch, commit, state }, { devices = [], items = [] } = {}) {
    const owners = distinctValues(devices, 'ownerId')
    const owner = owners.length === 1 ? devices[0].owner : null
    let ownerGuardian
    let ownerWallet
    if (owner) {
      if (owner.id === state.ownerGuardian?.principal.id) {
        ownerGuardian = state.ownerGuardian
      } else {
        ownerGuardian = await dispatch('getGuardian', { principal: owner, force: true })
      }
      if (owner.id === state.ownerWallet?.id) {
        ownerWallet = state.ownerWallet
      } else {
        ownerWallet = await dispatch('getWallet', { organization: owner })
      }
    }
    commit('updateActionPanel', { owner, ownerGuardian, ownerWallet, devices, items })

    if (items.length > 0) {
      await dispatch('showActionPanel')
    } else {
      await dispatch('hideActionPanel')
    }
  },

  /**
   * Shows the action panel
   * @param {String } tab Initial tab to open, if different than default
   * @param {Boolean } isCollapsed Open the panel in collapsed state
   */
  async showActionPanel ({ commit }, { tab, isCollapsed = false } = {}) {
    commit('showActionPanel', { tab, isCollapsed })
  },

  /**
   * Hides the action panel
   */
  async hideActionPanel ({ commit }) {
    commit('hideActionPanel')
  },

  /**
   * Indicates user opening an action in the inventory action panel
   * @param {Device} device Selected device
   * @param {Array[Device]} devices Selected devices
   * @param {Object} action Action to start
   * @param {String} icon Custom action icon
   * @param {String} label Custom action label
   * @param {String} description Custom action description
   */
  async openInventoryAction ({ commit }, { device, devices, action, icon, label, description } = {}) {
    if ((device || devices) && action) {
      commit('openInventoryAction', { device, devices, action, icon, label, description })
    }
  },

  /**
   * Indicates user closing an action in the inventory action panel
   * @param {Object} action Action to close
   */
  async closeInventoryAction ({ commit }, { action } = {}) {
    if (action) {
      commit('closeInventoryAction', { action })
    }
  },

  /**
   * Updates inventory action label and/or details
   * @param {String} icon Custom action icon
   * @param {String} label Custom action label
   * @param {String} description Custom action description
   */
  async setInventoryActionLabel ({ commit }, { icon, label, description } = {}) {
    commit('setInventoryActionLabel', { icon, label, description })
  },

  /**
   * Resets inventory action label and/or details to defaults
   */
  async resetInventoryActionLabel ({ commit }) {
    commit('resetInventoryActionLabel')
  }
}
