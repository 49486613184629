import Konva from 'konva'
import { Layer } from './layer'

/**
 * Base layer renderer
 */
export class BaseLayer extends Layer {
  constructor (data) {
    super({ ...data, isTrueLayer: true })
  }

  /**
   * White background
   * @type {Konva.Rectangle}
   */
  white

  /**
   * Creates own shapes of the layer
   * @return {Promise<Array[Shape|Konva.Shape]>}
   */
  async createOwnShapes () {
    this.destroy(this.white)

    const { floor: { canvasDimensions } } = this

    this.white = new Konva.Rect({
      draggable: false,
      listening: false,
      fill: 'white',
      x: 0,
      y: 0,
      width: canvasDimensions.width,
      height: canvasDimensions.height
    })

    return [this.white]
  }

}
