<script>
import { mapState, mapMutations } from 'vuex'

export default {
  props: {
    // Grid column
    column: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapState({
      // Items displayed in the grid
      items: state => state.inventoryView.items,
    }),

    // Returns true if all devices have been selected
    allItemsSelected () {
      return this.items && this.items.every(item => item.isSelected)
    }
  },

  methods: {
    ...mapMutations([
      'selectWholeInventory'
    ])
  }
}
</script>

<template>
  <q-checkbox
    dense
    color="indigo-5"
    :model-value="allItemsSelected"
    @update:model-value="value => selectWholeInventory({ isSelected: value })">
  </q-checkbox>
</template>

<style>

</style>