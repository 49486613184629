import { PlanItem, PlanItemType } from './plan-item'

/**
 * Image
 */
export class PlanImage extends PlanItem {
  constructor (data = {}) {
    super(data)
    this.assign(data)
  }

  /**
   * Item type
   * @type {PlanItemType}
   */
  static get type () {
    return PlanItemType.Image
  }

  /**
   * Item defaults
   */
  get defaults () {
    return {
      ...super.defaults
    }
  }

  normalize () {
    super.normalize()
  }

  toJSON () {
    const result = super.toJSON()
    return result
  }
  /**
   * Image URL
   * @type {String}
   */
  url
}
