export function createState () {
  return {
    /**
     * Places to show
     * @type {Array}
     */
    places: [],

    /**
     * Places filter
     * @type {String}
     */
    filter: '',

    /**
     * If true, `equals` string comparisons are performed when applying the {@link filter}, otherwise `includes`
     * @type {Boolean}
     */
    exactFilter: false,

    /**
     * Sort order
     * @type {String}
     */
    sortBy: 'name',
    sortDescending: false,

    /**
     * List of pinned places IDs
     * @type {Array[String]}
    */
    pinnedBuildings: [],

    /**
     * Information about the most recently viewed route under BUILDINGS,
     * which is restored when we select BUILDINGS menu item.
     * This allows going in and out a building or device without losing work context.
     * @type {Route}
     */
    lastRoute: null,

    // Ticker used to force the update of the installations view.
    // The view is heavy so we use bind-once and `v-memo` to update it only when strictly necessary.
    // To trigger an update in any other situations, simply increase this counter.
    installationsRefresh: 0
  }
}

export const state = createState()

export const getters = {
}
