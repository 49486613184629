import { Routes as premiumServicesRoutes } from '../views/premium-services'
import { Routes as premiumSubscribersRoutes } from '../views/premium-subscribers'
import { Routes as premiumServicesAuditRoutes } from '../views/premium-services-audit'

/**
 * Routes defined in this applet
 */
export const AppletRoutes = [
  ...premiumServicesRoutes,
  ...premiumSubscribersRoutes,
  ...premiumServicesAuditRoutes
]

/**
 * Enumeration of route names in this applet
 */
export const AppletRoute = {
  PremiumServices: 'premium-services',
  PremiumSubscribers: 'premium-subscribers',
  PremiumServicesAudit: 'premium-services-audit'
}
