/**
 * Utilities for downloading binary data
 */
export class DownloadService {
  /**
   * Converts Base64 string to byte array
   * @param {String} s Base64 string
   * @returns {Array[Number]} Byte array
   */
  base64ToByteArray (s) {
    if (s != null) {
      const decoded = window.atob(s)
      const bytes = new Uint8Array(decoded.length)
      return bytes.map((b, i) => decoded.charCodeAt(i))
    }
  }

  /**
   * Converts string to byte array
   * @param {String} s String
   * @returns {Array[Number]} Byte array
   */
  stringToByteArray (s) {
    if (s != null) {
      const encoder = new TextEncoder()
      const bytes = encoder.encode(s)
      return bytes
    }
  }

  /**
   * Returns data encoded as URL
   * @param {Array[Number]} data
   * @returns {String}
   */
  getObjectUrl (data) {
    if (data != null) {
      const url = URL.createObjectURL(data)
      return url
    }
  }

  /**
   * Saves the specified binary data to a file
   * @param {Array[Number]} data Binary data to save
   * @param {String} filename Name of the file
   */
  saveByteArrayToFile (data, filename) {
    if (!filename) throw new Error('File name is required')
    if (data != null) {
      const blob = new Blob(data)
      const link = document.createElement('a')
      const url = this.getObjectUrl(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', filename)
      link.style.display = 'none'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      return link
    }
  }

  /**
   * Downloads the specified URL to a file
   * @param {String} url URL to download
   * @param {String} filename Name of the file, optional.
   * If specified, it will override the returned file name.
   */
  saveUrlToFile (url, filename) {
    if (url && filename) {
      const link = document.createElement('a')
      link.setAttribute('href', url)
      if (filename) {
        link.setAttribute('download', filename)
      }
      link.style.display = 'none'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      return link
    }
  }

  /**
   * Saves the specified Base64-encoded data to a file
   * @param {String} data Base64-encoded data to save
   * @param {String} filename Name of the file
   */
  saveBase64ToFile (data, filename) {
    return this.saveByteArrayToFile(this.base64ToByteArray(data), filename)
  }

  /**
   * Saves the specified string to a file
   * @param {String} data String to save
   * @param {String} filename Name of the file
   */
  saveStringToFile (data, filename) {
    return this.saveByteArrayToFile(this.stringToByteArray(data), filename)
  }
}
