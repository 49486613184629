<script>
import ItemPropertiesMixin from './item-properties.js'
import ItemProperties from './item-properties.vue'
import BackgroundProperties from './background-style-properties.vue'
import TextStyleProperties from './text-style-properties.vue'
import LineProperties from './line-style-properties.vue'

export default {
  mixins: [
    ItemPropertiesMixin
  ],

  components: {
    'sc-item-properties': ItemProperties,
    'sc-text-style-properties': TextStyleProperties,
    'sc-background-properties': BackgroundProperties,
    'sc-line-properties': LineProperties
  }
}

</script>

<template>
  <sc-item-properties :renderer="renderer" :items="items" @action="executeAction" labelText="Text">
  </sc-item-properties>

  <sc-text-style-properties :renderer="renderer" :items="items" @action="executeAction">
  </sc-text-style-properties>

  <sc-background-properties :renderer="renderer" :items="items" @action="executeAction">
  </sc-background-properties>

  <sc-line-properties :renderer="renderer" :items="items" @action="executeAction" line-label="Border"
    widthLabel="Border Width" colorLabel="Border Color" typeLabel="Border Type" radiusLabel="Border Radius" 
    :radius="isAdvancedMode" :line-type="isAdvancedMode">
  </sc-line-properties>
</template>

<style lang="scss" scoped>
</style>
