<script>
import { mapState, mapGetters } from 'vuex'
import { Secure } from '@stellacontrol/security-ui'
import DeviceWidget from './device-widget'

export default {
  mixins: [
    Secure,
    DeviceWidget
  ],

  data () {
    return {
      // Indicates that we're showing raw MEGA values, not just the user-friendly extract
      showRawMega: false
    }
  },

  computed: {
    ...mapState({
      // Status watcher clock ticks, used to ensure refreshing of labels on each status arrival
      ticks: state => state.deviceStatus.ticks
    }),

    ...mapGetters([
      'guardian'
    ]),

    // MEGA
    mega () {
      return this.status?.mega
    },

    // TT Usage Counters
    ttUsageCounters () {
      const { device, status } = this
      if (device?.isTestTool && status?.health?.ttUsage) {
        const {
          deviceOn,
          spectrum,
          cellSurvey,
          neighbourCells,
          beacon,
          cableTest,
          agcTest,
          diagnostics,
          fullSurvey,
          help,
          settings,
          fullSurveySetup,
          fwUpdatePrompt
        } = status.health.ttUsage

        const items = [
          { label: 'Power On Events', value: deviceOn },
          { label: 'Spectrum Analyser', value: spectrum },
          { label: 'Live Scan', value: neighbourCells },
          { label: 'Cell ID Scan', value: cellSurvey },
          { label: 'Beacon Test', value: beacon },
          { label: 'Cable Test', value: cableTest },
          { label: 'AGC Test', value: agcTest },
          { label: 'Full Survey', value: fullSurvey },
          { label: 'Diagnostics Page', value: diagnostics },
          { label: 'Help Page', value: help },
          { label: 'Menus/Settings Pages', value: settings },
          { label: 'Full Survey Setup', value: fullSurveySetup },
          { label: 'FW Update Prompt', value: fwUpdatePrompt }
        ]
        return items
      } else {
        return []
      }
    }
  }
}
</script>

<template>
  <sc-widget v-bind="{ ...$props, ...$attrs }" v-if="hasStatus" width="230px">
    <div class="parameters">
      <div class="parameters-header">
        Usage Counters
      </div>

      <div class="content">
        <div class="product-features">
          <div class="item" v-for="(item, index) in ttUsageCounters" :key="index">
            <span class="name" :title="item.label">
              {{ item.label }}
            </span>
            <span class="value">
              {{ item.value }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </sc-widget>
</template>

<style lang="scss" scoped>
.parameters {
  padding: 8px 0px 8px 12px;
  background-color: white;
  overflow: hidden;

  .parameters-header {
    font-size: 13px;
    font-weight: bold;
  }

  .content {
    height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 8px;
  }

  .item {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 6px 0 6px 0;
    border-bottom: solid #dddddd 1px;

    &:hover {
      background-color: #fafafa;
    }

    &:last-child {
      border-bottom: none;
    }

    .name {
      max-width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 13px;
      color: #4a4a4a;
      padding-left: 2px;
    }

    .value {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 13px;
      color: #242424;
      padding-right: 2px;
    }
  }
}
</style>
