<script>
import { Viewport } from '@stellacontrol/client-utilities'
import Widget from './widget.vue'

export default {
  props: {
    // Icon to show in the widget
    icon: {
      type: String
    },

    // Icon size
    iconSize: {
      type: String
    },

    // Icon color
    iconColor: {
      type: String,
      default: 'indigo-4'
    },

    // Icon margin
    iconMargin: {
      type: String,
      default: ''
    },

    // Icon class
    iconClass: {
      type: String,
      default: ''
    },

    // Icon tooltip
    tooltip: {
      type: String,
      default: ''
    },

    // Icon style
    iconStyle: {
      type: Object
    }
  },

  components: {
    'sc-widget': Widget
  },

  computed: {
    isSmallScreen () {
      return Viewport.isSmallScreen
    },

    adjustedIconSize () {
      return this.iconSize || (this.isSmallScreen ? '32px' : '56px')
    }
  },

  methods: {
    // Executes the action
    executeAction (action) {
      this.$emit('action', action)
    }
  }
}
</script>

<template>
  <sc-widget v-bind="{...$props, ...$attrs}" v-if="icon" class="icon-widget">

    <div class="widget-content">
      <div class="icon">
        <q-icon
          :size="adjustedIconSize"
          :color="iconColor"
          :name="icon"
          :class="iconClass ? { [iconClass]: true } : undefined"
          :style="{ margin: iconMargin, ...iconStyle || {} }">

          <sc-tooltip :text="tooltip">
          </sc-tooltip>

        </q-icon>
      </div>
    </div>

    <slot name="toolbar">
    </slot>

  </sc-widget>
</template>

<style lang="scss" scoped>
@import './widget.scss';
</style>
