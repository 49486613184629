/**
 * API Session object.
 * Takes care of session token handling and
 * making it accessible to all API clients
 * within the application.
 */
export const APISession = {
  /**
   * Session token key for storing in browser local storage
   */
  tokenKey: 'c5bff997-ef82-441e-ab50-39571f968c22',

  /**
   * Session token
   * @type {String}
   */
  token: null,

  /**
   * Current user
   * @type {User}
   */
  user: null,

  /**
   * Handler to be called when session is being asked for,
   * but we're not logged in yet.
   * Useful in non-interactive processes as a place to implement re-login.
   * @type {Function}
   */
  loginHandler: null,

  /**
   * Error handler to be called when API calls
   * fail with authorization errors
   * @type {Function<Error>}
   */
  errorHandler: null,

  /**
   * Checks whether the session has been initialized.
   * This doesn't means though that the session token hasn't expired in the meantime!
   * This can only be determined by the server.
   * @returns {Boolean}
   */
  get hasSession () {
    return Boolean(this.token && this.user)
  },

  /**
   * Stores the current session token
   * @param {String} value Session token to store
   */
  setToken (value) {
    if (value) {
      return this.token = value
    }
  },

  /**
   * Stores the current session user
   * @param {User} value Session user to store
   */
  setUser (value) {
    if (value) {
      this.user = value
    }
  },

  /**
   * Sets handler to be called when session is being asked for
   * but we're not logged in yet.
   * Useful in non-interactive processes as a place to implement re-login.
   * @param {Function} value Login handler to call before obtaining the session
   */
  setLoginHandler (value) {
    if (value) {
      this.loginHandler = value
    }
  },

  /**
   * Sets error handler to be called when API calls fail with authorization errors
   * @param {Function<Error>} value Session error handler to call
   */
  setErrorHandler (value) {
    if (value) {
      this.errorHandler = value
    }
  },

  /**
   * Prepares authorization headers for a web request
   * using the specified token or the current session token
   * @param {String} token Session token
   * @returns {Dictionary<String, String>} Authorization headers
   */
  async getAuthorizationHeaders (token) {
    const createHeaders = token => ({ 'Authorization': `${token.startsWith('Bearer ') ? '' : 'Bearer '}${token}` })
    token = token || this.token

    if (token) {
      return createHeaders(token)
    } else {
      if (this.loginHandler) {
        await this.loginHandler()
        if (this.token) {
          return createHeaders(this.token)
        }
      }
    }

    return {}
  },

  /**
   * Clears the current session user
   */
  clear () {
    this.user = undefined
    this.token = undefined
    this.errorHandler = undefined
  }
}

/**
 * Impersonates the APIs with the specified user session.
 * @param {Session} session Session details
 * @param {Function<Error>} authErrorHandler Error handler to be called when API calls
 * fail with authorization errors. Can be used to capture expired
 * sessions and redirect users back to login page
 * @description
 * The credentials are required to perform secure calls to APIs.
 * Without them only a limited subset of APIs is available,
 * such as API status and login.
 */
export async function startAPISession (session = {}, authErrorHandler) {
  if (!session.token) throw new Error('Session token is required')

  APISession.setToken(session.token)
  if (session.user) {
    APISession.setUser(session.user)
  }
  APISession.setErrorHandler(authErrorHandler)

  return APISession
}

/**
 * Clears the API session
 */
export async function clearAPISession () {
  APISession.clear()
  return APISession
}
