import PlaygroundView from './playground.vue'
import { resolve } from './playground.resolve'

export const Routes = [
  {
    name: 'stellaplanner-playground',
    path: '/stellaplanner-playground',
    component: PlaygroundView,

    async beforeEnter (to, from, next) {
      if (await resolve({ from, to })) {
        next()
      }
    }
  }
]
