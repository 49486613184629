/**
 * Preference types
 */
export const PreferenceType = {
  Boolean: 'b',
  String: 's',
  Number: 'n',
  List: 'l',
  Date: 'd'
}
