/**
 * Various states of plan items, with regard
 * to user activities
 */
export const PlanItemState = {
  Normal: 'normal',
  Hover: 'hover',
  Selected: 'selected',
  Disabled: 'disabled',
  Hidden: 'hidden'
}
