<script>
// TODO "No video source" => icon
/**
 * Allow taking a picture
 * @emits cancel - When the user cancels taking a picture
 * @emits image - When the user confirms a taken picture - is called with the taken image local URL ("image/jpeg" + base64)
 */
export default {
  data () {
    return {
      // Indicates there is no video source
      noSource: false,
      // Contains the taken image before confirmation
      image: null,
      // used internally to free resources
      stream: null,
      // actual video stream size
      videoWidth: 0,
      videoHeight: 0
    }
  },
  
  methods: {
    // Cancel the whole photo taking process
    cancel () {
      this.$emit('cancel')
    },

    // Take the photo
    click () {
      const { video, canvas } = this.$refs
      canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height)
      this.image = canvas.toDataURL('image/jpeg')
      this.stopStreaming()
    },

    // Stop the video stream
    stopStreaming () {
      for(const track of this.stream.getVideoTracks()) {
        track.stop()
      }
      this.stream = null
    },

    // Get into showing the video mode
    startStreaming () {
      navigator.mediaDevices.getUserMedia({ video: true, audio: false })
        .then(
          (stream) => {
            this.stream = stream
            const { width, height } = stream.getVideoTracks()[0].getSettings()
            this.videoWidth = width
            this.videoHeight = height
          },
          () => this.noSource = true
        )
    }
  },


  mounted () {
    this.startStreaming()
  },

  unmounted () {
    if (this.stream) this.stopStreaming()
  }
}
</script>

<template>
  <div class="photo-shot">
    <div class="photo">
      <canvas ref="canvas" :width="videoWidth" :height="videoHeight" style="display: none;"></canvas>
      <div v-if="noSource">
        No video source
      </div>
      <video v-else-if="stream" ref="video" :srcObject.prop="stream" width="100%" height="100%" autoplay></video>
      <img v-else-if="image" :src="image" style="width: 100%;"/>
    </div>
    <div class="controls">
      <q-btn label="Cancel" unelevated class="q-mr-md" @click="cancel()"></q-btn>
      <q-btn v-if="stream" label="Click!" unelevated class="q-mr-md primary" @click="click()"></q-btn>
      <template v-else-if="image">
        <q-btn label="Retake" unelevated class="q-mr-md" @click="startStreaming()"></q-btn>
        <q-btn label="Confirm" unelevated class="primary" @click="$emit('image', image)"></q-btn>
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">
.photo-shot {
  width: 100%;
  height: 100%;
  .photo {
    width: 100%;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>