/**
 * Principal types
 */
export const PrincipalType = {
  User: 'user',
  Organization: 'organization',
  OrganizationProfile: 'organization-profile',
  Place: 'place'
}

/**
 * Human-friendly descriptions of principal types
 */
export const PrincipalTypeDescription = {
  [PrincipalType.User]: 'User',
  [PrincipalType.Organization]: 'Organization',
  [PrincipalType.OrganizationProfile]: 'Organization Profile',
  [PrincipalType.Place]: 'Place'
}
