import { DeviceConnectionStatus } from '@stellacontrol/model'
import { safeParseInt } from '@stellacontrol/utilities'
import Profiles from '../../assets/simulated-devices.json'
import { DeviceStatus } from './device-status'

/**
 * List of simulated device profiles
 */
export const SimulatedDeviceProfiles = Object.values(Profiles)

/**
 * Simulated device profile
 */
export class SimulatedDeviceProfile {
  constructor (data = {}) {
    this.name = data.name || 'default'
    this.description = data.description
    this.details = data.details
    this.icon = data.icon || 'router'
    this.color = data.color || 'grey-8'
    this.borrow = data.borrow
    // Assign data from the profile
    const emptyProfile = { mega: {}, randomize: {}, connectionStatus: DeviceConnectionStatus.Online }
    const defaultProfile = Profiles['default'] || {}
    this.profile = Profiles[this.name] || {}
    Object.assign(this, emptyProfile, defaultProfile, this.profile)
    // Assign direct customizations
    Object.assign(this.mega, data.mega || {})
    Object.assign(this.randomize, data.randomize || {})
    this.randomize.frequency = this.randomize.frequency || 5000
  }

  /**
   * Unique name of the profile
   * @type {String}
   */
  name

  /**
   * User-friendly description of the profile
   * @type {String}
   */
  description

  /**
   * Detailed description of what kind of data to expect
   * from a device with this simulation profile
   * @type {String}
   */
  details

  /**
   * Icon representing the profile
   * @type {String}
   */
  icon

  /**
   * Color representing the profile
   * @type {String}
   */
  color

  /**
   * Profile to use to generate fake data
   * @type {Object}
   */
  profile

  /**
   * Borrow mega settings from another profile of the specified name.
   * If specified, {@link mega} and {@randomize} only need to contain deviations from that profile.
   * This allows setting up one extensive profile for a healthy device
   * and minimize the amount of settings in profiles representing problematic devices
   * @type {String}
   */
  borrow

  /**
   * Specifies of connection status to report. These values
   * will be used to generate timestamp of the returned status message.
   *    online - for device in fast mode
   *    heartbeat- for device in heartbeat mode
   *    offline - for device which stopped communicating long ago
   * @type {DeviceConnectionStatus}
   */
  connectionStatus

  /**
   * Frequency in seconds at which status should be reported for the device
   * @type {Number}
   */
  messageFrequency

  /**
   * Fixed status data to return for this device
   * @type {Object}
   */
  mega

  /**
   * Specification of signal variations.
   * For each key in MEGA you can define the type of the value
   * to generate and signal variation range used to randomize the values.
   * For numeric properties you need to specify the variation range { min, max }
   * For boolean properties just specify `true`
   *
   * I.e.
   *
   * {
   *    osc_dw_21: { min: 0, max: 30 },
   *    adjusting_09: true,
   *    _shutdown_18: true,
   *    _shutdown_21: true,
   * }
   *
   *
   * @type {Object}
   */
  randomize

  /**
   * Returns MEGA message with data as specified in the profile
   * @param {String} serialNumber Serial number of a device for which we're creating sample MEGA
   * @returns {Object}
   */
  getMega (serialNumber) {
    if (!serialNumber) throw new Error('Serial number is required')

    const { profile, mega = {}, connectionStatus = DeviceConnectionStatus.Online } = this
    const now = new Date().getTime()

    // Determine data to borrow from another profile
    const defaultProfile = Profiles['default'] || {}
    const borrowProfile = Profiles[this.borrow] || { mega: {}, randomize: {} }

    // Assign data from borrowed profile, override with own data
    let data = { ...defaultProfile.mega, ...borrowProfile.mega, ...mega }

    // Get previously generated data for this device
    const previousData = (profile.lastMega || {})[serialNumber] || {}

    // Randomize specified values at certain intervals.
    // If interval hasn't passed yet, use last randomized values
    const randomize = { ...borrowProfile.randomize, ...(this.randomize || {}) }
    if (Object.values(randomize).length > 0) {
      const timeToRandomize = (now - (profile.lastRandomizedAt || 0)) >= randomize.frequency
      if (timeToRandomize) {
        profile.lastRandomizedAt = now

        for (const [key, variation] of Object.entries(randomize)) {
          if (['frequency'].includes(key)) continue

          let value = data[key]

          if (variation === true) {
            // Random boolean
            value = Boolean(Math.random() > 0.5)

          } else if (variation.delta && variation.min != null && variation.max != null) {
            // Increasing or decreasing value within specified range,
            // until reaching the range end
            const previousValue = previousData[key]
            if (previousValue == null) {
              value = variation.delta > 0
                ? variation.min
                : variation.max

            } else {
              value = previousValue + variation.delta
              // On reaching the end of trend, go back to the beginning of the range
              if (variation.delta > 0) {
                if (value > variation.max) {
                  value = variation.min
                }
              } else {
                if (value < variation.min) {
                  value = variation.max
                }
              }
            }

          } else if (variation.max != null) {
            // Random number within specified range
            variation.min = variation.min || 0
            const delta = variation.max - variation.min + 1
            value = parseFloat(value) - variation.min + Math.floor(Math.random() * delta)
          }

          data[key] = value
        }
      } else {
        if (profile.lastMega) {
          data = {
            ...data,
            ...(profile.lastMega[serialNumber] || {})
          }
        }
      }
    }

    // Set dates, according to the connection status
    let timestamp, lag
    switch (connectionStatus) {
      case 'heartbeat':
        // For heartbeat, keep last message date within 1 and 5 minutes before now
        lag = 60 * (1 + Math.floor(Math.random() * 5))
        timestamp = now - (1000 * lag)
        break

      case 'offline':
        // For offline devices, keep the generated time in the profile,
        // so the device remains offline with the same time of last contact
        // not jumping randomly back and forth
        if (profile.timestamp) {
          timestamp = profile.timestamp
        } else {
          lag = 60 * (15 + Math.floor(Math.random() * 1000))
          timestamp = now - (1000 * lag)
          profile.timestamp = timestamp
        }
        break

      default:
        // For live devices, make the last communication 1 second old
        timestamp = now - 1000
    }

    const datetime = new Date(timestamp)
    const extra = {
      timestamp: timestamp.toString(),
      datetime: datetime.toISOString()
    }

    // Update message counters
    profile['hourly_message_count'] = safeParseInt(profile['hourly_message_count'], 0) + 1
    profile['daily_message_count'] = safeParseInt(profile['daily_message_count'], 0) + 1
    data['hourly_message_count'] = profile['hourly_message_count']
    data['daily_message_count'] = profile['daily_message_count']

    // Preserve generated values for the device
    profile.lastMega = {
      ...(profile.lastMega || {}),
      [serialNumber]: { ...data, extra }
    }

    return profile.lastMega[serialNumber]
  }
}

/**
 * Retrieves simulated device profile with a given name
 * @param {String} name Name of the simulated profile
 * @returns {SimulatedDeviceProfile}
 */
export function getSimulatedDeviceProfile (name) {
  if (!name) throw new Error('Profile name is required')
  return new SimulatedDeviceProfile({ name })
}

/**
 * Retrieves simulated status of a single device
 * @param {Device} device Device whose status to fetch
 * @param {Boolean} raw If true, unparsed status is returned, otherwise a parsed `DeviceStatus` instance
 * @param {String} name Name of the simulated profile to use
 * @returns {DeviceStatus}
 */
export function getSimulatedDeviceStatus (device, raw = false, name) {
  if (!device) throw new Error('Device is required')
  const { serialNumber } = device
  const profile = new SimulatedDeviceProfile({ name })
  const mega = profile.getMega(serialNumber)
  const status = raw ? mega : new DeviceStatus(serialNumber, mega)
  return status
}

