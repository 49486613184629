import { DeviceName } from './device-name'
import { DeviceAcronym } from './device-type'

/**
 * Returns a human-friendly but concise device identification string,
 * composed of device type and serial number
 * @param device Devices
 * @param noDeviceLabel Text to return if no device is specified
 */
export function getDeviceLabel (device, noDeviceLabel = '') {
  if (device) {
    const type = (device.isMultiDevice ? device.product.model : DeviceAcronym[device.type]) || ''
    const result = `${(type)} ${device.name || device.serialNumber}`
    return result.trim()
  } else {
    return noDeviceLabel
  }
}

/**
 * Returns a human-friendly but concise description
 * of a devices, to use in UI messages
 * @param device Devices
 * @param noDeviceDescription Text to return if no device is specified
 */
export function getDeviceDescription (device, noDeviceDescription = '') {
  if (device) {
    const result = `${device.type ? DeviceName[device.type] : ''} ${device.name || device.serialNumber}`
    return result.trim()
  } else {
    return noDeviceDescription
  }
}

/**
 * Returns a human-friendly but concise description
 * of a batch of devices, to use in UI messages
 * @param devices Batch of devices
 * @param noDevicesDescription Text to return if no devices are specified
 */
export function getDevicesDescription (devices = [], noDevicesDescription = '') {
  devices = devices.filter(d => d)
  if (devices.length > 0) {
    const singleDevice = devices.length === 1
    if (singleDevice) {
      const device = devices[0]
      return getDeviceDescription(device)
    } else {
      const smallBatch = devices.length <= 5
      if (smallBatch) {
        const labels = devices.map(d => d.name || d.serialNumber || 'unknown').join(', ')
        return labels
      } else {
        return `${devices.length} devices`
      }
    }
  } else {
    return noDevicesDescription
  }
}

/**
 * Returns a human-friendly but concise label
 * of a batch of devices, to use in UI messages
 * @param devices Batch of devices
 * @param noDevicesDescription Text to return if no devices are specified
 */
export function getDevicesLabel (devices = [], noDevicesDescription = '') {
  devices = devices.filter(d => d)
  if (devices.length > 0) {
    const singleDevice = devices.length === 1
    if (singleDevice) {
      const device = devices[0]
      return getDeviceLabel(device)
    } else {
      const smallBatch = devices.length <= 5
      if (smallBatch) {
        const labels = devices.map(d => getDeviceLabel(d)).join(', ')
        return labels
      } else {
        return `${devices.length} devices`
      }
    }
  } else {
    return noDevicesDescription
  }
}
