import { isEnum, parseDate } from '@stellacontrol/utilities'
import { Entity } from '../common/entity'
import { DeviceLinkType } from './device-link-type'
import { Organization } from '../organization'

export class DeviceLink extends Entity {
  constructor (data = {}) {
    super()
    this.assign(data)
    this.type = this.type == null ? DeviceLinkType.Delegate : this.type
    this.isCurrent = this.isCurrent == null ? true : this.isCurrent
    this.deactivatedAt = parseDate(this.deactivatedAt)

    if (!isEnum(DeviceLinkType, this.type)) throw new Error(`Invalid link type ${this.type}`)
  }

  /**
   * Normalizes the data after assignment
   */
  normalize () {
    super.normalize()
    if (this.principal) {
      if (this.principal.type === 'organization') {
        this.principal = this.cast(this.principal, Organization)
      }
    }
  }

  /**
   * Identifier of a user who created the link is stored in createdBy.
   * Date and time when link has been created is stored in createdAt.
   */

  /**
   * Device link type
   * @type {DeviceLinkType}
   */
  type

  /**
   * Device identifier
   * @type {String}
   */
  deviceId

  /**
   * Device details
   * @type {Device}
   */
  device

  /**
   * Identifier of organization which created the link
   * @type {String}
   */
  creatorOrganizationId

  /**
   * Identifier of a principal linked to device
   * @type {String}
   */
  principalId

  /**
   * Principal details
   * @type {Principal}
   */
  principal

  /**
   * Defines whether the link is the most current one.
   * We maintain the history of previous links for the record,
   * so this property helps us figure out which links
   * currently apply to the device and which are the historical ones.
   * @type {Boolean}
   */
  isCurrent

  /**
   * Date and time when link has been deactivated
   * @type {Date}
   */
  deactivatedAt

  /**
   * Identifier of a user who deactivated the link
   * @type {String}
   */
  deactivatedBy

  /**
   * User who deactivated the link
   * @type {User}
   */
  deactivator

  /**
   * Other notes related to the device link
   * @type {String}
   */
  notes

  /**
   * Remove runtime properties before serialization
   * @returns {Object}
   */
  toJSON () {
    const result = { ...this }
    delete result.device
    return result
  }
}
