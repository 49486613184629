import { DeviceCommandDefinitions } from '@stellacontrol/devices'
import { getDeviceCommand } from '@stellacontrol/mega'

export function createState () {
  return {
    // List of all available device commands
    commands: Object.values(DeviceCommandDefinitions),
    // List of all fast sampling devices serial numbers
    fastSamplingDevices: []
  }
}

export const state = createState()

export const getters = {
  /**
   * Returns a definition of the specified command
   * @param name Command name
   */
  getDeviceCommandDefinition: state =>
    name => state.commands.find(command => command.name === name),

  /**
   * Checks whether the specified command is applicable in context of the specified devices
   * @param command Command to execute
   * @param devices Devices on which the command would be possibly executed
   */
  isDeviceCommandAvailable: (state, getters) =>
    (command, devices = [], status = {}) => {
      if (!(command && devices.length > 0)) {
        return false
      }

      if (!(getters.guardian)) {
        return false
      }

      const { isBatchCommand, isConnectedDevice, permissions, permissionsOf } = command.conditions || {}

      // Check if allowed in batch
      if (devices.length > 1 && !isBatchCommand) {
        return false
      }

      // Check permissions
      if (permissions && !getters.guardian.canUseAll(permissions)) {
        return false
      }

      if (permissionsOf && !getters.guardian.canUseChildrenOf(permissionsOf)) {
        return false
      }

      // Check connected/non-connected status
      if (isConnectedDevice != null) {
        if (!devices.filter(d => d).every(device => device.isConnectedDevice === isConnectedDevice)) {
          return false
        }
      }

      // Check other conditions defined in MEGA.json
      if (!devices.filter(d => d).every(device => {
        const mega = getDeviceCommand(command.name, device, status[device.serialNumber])
        if (mega) {
          if (mega.notApplicable) return false
          if (mega.permissions && !getters.guardian.canUseAll(mega.permissions)) return false
        }
        return true
      })) {
        return false
      }

      return true
    },

  /**
   * Checks whether any of the specified commands are available for the specified devices
   * @param devices Devices to execute commands
   * @param commands List of default commands to check
   * @param customCommands List of custom commands to check
   * @returns List of available commands or undefined, if none are available for the specified set of devices
   */
  availableDeviceCommands: (state, getters) =>
    (devices, commands = [], customCommands = []) => {
      const allCommands = [
        ...commands,
        ...customCommands
      ]
      const availableCommands = allCommands.filter(command =>
        command && (command.separator || getters.isDeviceCommandAvailable(command, devices)))
      return availableCommands.length === 0
        ? undefined
        : availableCommands
    },

  // Return the amount of devices that can now be set in fast-sampling mode
  availableFastSamplingSlots: (state, getters) => {
    // Until this setting is moved (centralized), we use `building-dashboard`' one
    const maximumFastSamplingDevices = getters.getStatusWatchSettings('building-dashboard').fastSamplingMaxCount
    return maximumFastSamplingDevices - state.fastSamplingDevices.length
  },

  // Check whether the specified device is in fast-sampling mode
  isFastSamplingDevice: (state) => (device) => state.fastSamplingDevices.includes(device.serialNumber),

}
