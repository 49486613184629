import { isEnum } from '@stellacontrol/utilities'
import { Entity } from '../../common/entity'
import { PrincipalType } from './principal-type'
import { Permission } from '../permission/permission'
import { formatDateTime } from '@stellacontrol/utilities'

/**
 * An entity which requires permissions
 */
export class Principal extends Entity {
  constructor (data = {}) {
    super()

    this.assign({
      ...data,
      isEnabled: data.isEnabled == null ? true : data.isEnabled
    })

    if (!isEnum(PrincipalType, this.type)) throw new Error(`Invalid principal type ${this.type}`)
  }

  /**
   * Normalizes the data after assignment
   */
  normalize () {
    super.normalize()
    if (this.permissions) {
      this.permissions = this.castArray(this.permissions, Permission)
    }
    if (this.creator) {
      this.creator = this.cast(this.creator, Principal)
    }
    if (this.updater) {
      this.updater = this.cast(this.updater, Principal)
    }
    if (this.disabler) {
      this.disabler = this.cast(this.disabler, Principal)
    }
  }

  /**
   * Principal name
   * @type {String}
   */
  name

  /**
   * Principal full name, often overridden in descendants
   * to include things such as organization profile name, place type etc.
   * @type {String}
   */
  get fullName () {
    return this.name
  }

  /**
   * Description
   * @type {String}
   */
  description

  /**
   * Identifier of user which has recently disabled this principal
   * @type {String}
   */
  disabledBy

  /**
   * User which disabled the principal
   * @type {User}
   */
  disabler

  /**
   * Principal's token wallet
   * @type {Wallet}
   */
  wallet

  /**
   * Creation date and creator details
   * @type {String}
   */
  get createdText () {
    const { creator } = this
    return [
      formatDateTime(this.createdAt),
      creator ? (creator.fullName || creator.name) : undefined
    ]
      .filter(s => s)
      .join(', ')
  }

  /**
   * Modification date and creator details
   * @type {String}
   */
  get updatedText () {
    const { updater } = this
    return [
      formatDateTime(this.updatedAt || this.createdAt),
      updater ? (updater.fullName || updater.name) : undefined
    ]
      .filter(s => s)
      .join(', ')
  }

  /**
   * Principal type
   * @type {PrincipalType}
   */
  type

  /**
   * True if principal represents an organization profile
   * @type {Boolean}
   */
  get isOrganizationProfile () {
    return this.type === PrincipalType.OrganizationProfile
  }

  /**
   * True if principal represents an organization
   * @type {Boolean}
   */
  get isOrganization () {
    return this.type === PrincipalType.Organization
  }

  /**
   * True if principal represents a user
   * @type {Boolean}
   */
  get isUser () {
    return this.type === PrincipalType.User
  }

  /**
   * True if principal represents a place
   * @type {Boolean}
   */
  get isPlace () {
    return this.type === PrincipalType.Place
  }

  /**
   * Text, either description or name
   * @type {String}
   */
  get text () {
    return this.description || this.name
  }

  /**
   * Indicates whether the principal is currently enabled
   * @type {Boolean}
   */
  isEnabled

  /**
   * Principal level
   * @type {OrganizationLevel|UserLevel}
   */
  level

  /**
   * Permissions assigned to the principal
   * @type {Array[Permission]}
   */
  permissions

  /**
   * Gets a permission by feature name
   * @param {String} name Feature name
   * @returns {Permission}
   */
  getPermission (name) {
    if (!name) throw new Error('Feature name is required')
    return (this.permissions || []).find(permission => permission.featureName === name)
  }

  /**
   * Checks if there is a permission for the specified feature
   * @param {String} name Feature name
   * @returns {Boolean}
   */
  hasPermission (name) {
    if (!name) throw new Error('Feature name is required')
    return (this.permissions || []).some(permission => permission.featureName === name)
  }

  /**
   * Gets a clone of permission by feature name
   * @param {String} name Feature name
   * @returns {Permission}
   */
  getPermissionClone (name) {
    const permission = this.getPermission(name)
    return permission ? new Permission(permission) : undefined
  }

  /**
   * Returns a dictionary of principal's explicitly granted permissions
   * @returns {Dictionary<string, Permission>}
   */
  getPermissions () {
    return (this.permissions || []).reduce((all, permission) => {
      all[permission.featureName] = permission
      return all
    }, {})
  }

  /**
   * Returns a clone of principal's permissions.
   * We assign them with temporary unique identifiers,
   * which will be discarded when permissions are stored.
   * @param {Boolean} useDefaults If true, some cloned permissions will be
   * immediately granted, if their default values were set to true on the principal.
   * Some features are marked as not granted by default, so they will be available
   * in the permissions editor but not checked yet.
   * @returns {Array[Permission]}
   */
  clonePermissions ({ useDefaults } = {}) {
    let id = 1
    return (this.permissions || [])
      .map(p => {
        const permission = new Permission({
          ...p,
          id: id++
        })

        if (useDefaults) {
          permission.canUse = Boolean(permission.defaultValue)
        }

        return permission
      })
  }

  /**
   * Determines default permission for the specified feature,
   * by looking up the parent principal.
   * This works only when editing permissions of organizations,
   * as only organization profiles can have defaults defined.
   * @param {Feature} feature Feature which has just become available to the principal
   * @returns {Permission}
   */
  getDefaultPermission (feature) {
    const { parentPrincipal } = this
    if (!this.parentPrincipal) {
      return false
    }

    if (parentPrincipal.type !== PrincipalType.OrganizationProfile) {
      return false
    }

    const parentPermission = parentPrincipal.getPermission(feature.name)
    if (parentPermission) {
      return Boolean(parentPermission.defaultValue)
    }

    return false
  }

  /**
   * Checks if principal has a granted permission for a specified feature
   * @param {String} name Feature name
   * @returns {Permission}
   */
  canUse (name) {
    const { canUse } = this.getPermission(name) || {}
    return canUse
  }

  /**
   * Returns context associated with permission granted for a specified feature
   * @param {String} name Feature name
   * @returns {Object}
   */
  getContext (name) {
    const { context } = this.getPermission(name) || {}
    return context
  }

  // RUNTIME PROPERTIES --------------------------------------------------
  /**
   * Principal parents, whose permissions might limit permissions
   * available to this principal. Used to create hierarchies such as
   * organization profile -> organization -> user or
   * parent organization -> organization
   * @type {Array[Principal]}
   */
  get parentPrincipals () {
    return undefined
  }

  /**
   * Returns the first of principal parents
   * @type {Principal}
   */
  get parentPrincipal () {
    return (this.parentPrincipals || [])[0]
  }

  /**
   * Indicates that principal has parent principals
   * @type {Boolean}
   */
  get hasParentPrincipals () {
    const { parentPrincipals } = this
    return (parentPrincipals || []).length > 0
  }

  /**
   * Overrides serialization to prevent serializing of certain
   * runtime-only properties
   * @returns {Object}
   */
  toJSON () {
    const result = { ...this }
    return result
  }
}
