/**
 * Animation service
 */
export class AnimationService {
  /**
   * Applies animation to the specified UI element
   * @param {String|Element} container Element container or selector
   * @param {String|Element} element Element or selector
   * @param {String} animation CSS animation to apply
   * @param {String} cssClass Alternative, CSS class to apply
   * @param {Number} duration Duration in milliseconds
   * @returns Promise resolved when pulse has ended
   */
  run ({ container, element, animation, cssClass, duration = 1000 } = {}) {
    return new Promise((resolve, reject) => {
      if (!element) return reject(new Error('Element is required'))
      if (!(animation || cssClass)) return reject(new Error('Animation name or CSS class is required'))

      container = container ? (typeof container === 'string' ? document.querySelector(container) : container) : document
      if (!container) return reject(new Error('Container is required'))

      element = typeof element === 'string' ? container.querySelector(element) : element
      if (!element) return reject(new Error(`Element [${element}] not found`))

      if (cssClass) {
        element.classList.add(cssClass)
      } else {
        element.style.animation = animation
      }

      setTimeout(() => {
        if (cssClass) {
          element.classList.remove(cssClass)
        } else {
          element.style.animation = null
        }
        resolve(true)
      }, duration)
    })
  }
}
