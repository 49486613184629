import { Dialog } from 'quasar'

/**
 * Confirmation service for displaying confirmation dialogs,
 * using Quasar Dialog plugin.
 */
export class ConfirmationService {
  /**
   * Shows a confirmation of a specified action
   * @param title Dialog title
   * @param message Confirmation message
   * @param html If true, message can be HTML. Potentially unsafe!
   * @param lineBreaks If true, \n characters are automatically converted into linebreaks
   * @param persistent If true, dialog cannot be dismissed with clicking outside
   * @param ok OK button text
   * @param cancel Cancel button text
   * @param confirm If false, confirmation is suppressed, call just resolves with true
   * @returns Promise resolved with TRUE, if user confirmed the action
   */
  ask ({ title, message, persistent, html, lineBreaks = true, ok = 'Yes', cancel = 'No', confirm = true } = {}) {
    if (confirm) {
      message = (message || '').trim()
      if (message.includes('<script')) {
        throw new Error('Unsafe notification message')
      }
      if (message.includes('\n') && lineBreaks) {
        html = true
        message = message.replace('\n', '<br>')
      }

      return new Promise((resolve) => {
        const options = this.getDialogOptions({ title, message, persistent, ok, cancel, html })
        Dialog
          .create(options)
          .onOk(() => resolve(true))
          .onCancel(() => resolve(false))
      })
    } else {
      return Promise.resolve(true)
    }
  }

  /**
   * Shows a message popup with just one DISMISS button
   * @param {String} title Dialog title
   * @param {String} message Information message
   * @param {Boolean} persistent If true, dialog cannot be dismissed with clicking outside
   * @param {String} ok OK button text
   * @returns {Promise<Boolean>} True, if user confirmed the action
   */
  message ({ title, message, persistent, ok = 'Dismiss' } = {}) {
    return new Promise((resolve) => {
      const options = this.getDialogOptions({ title, message, persistent, ok, cancel: null })
      Dialog
        .create(options)
        .onOk(() => resolve(true))
        .onCancel(() => resolve(false))
    })
  }

  /**
   * Asks user for manual input
   * @param {String} title Dialog title
   * @param {String} message Information message
   * @param {Boolean} persistent If true, dialog cannot be dismissed with clicking outside
   * @param {Boolean} number If true, input is a number
   * @param {Number} min Minimal allowed value
   * @param {Number} max Maximal allowed value
   * @param {Number} minLength Minimal allowed text length
   * @param {Number} maxLength Maximal allowed text length
   * @param {Number} step Value step, for increments with up-down buttons
   * @param {String} ok OK button text
   * @returns {Promise<String|Number>} Value entered and confirmed by the user
   */
  prompt ({ title, message, initial = '', persistent = true, number, minLength = 0, maxLength, min, max, step } = {}) {
    return new Promise(resolve => {

      const options = this.getDialogOptions({ title, message, persistent })
      const prompt = number
        ? {
          model: initial,
          type: 'number',
          min,
          max,
          step,
          isValid: s => Boolean(s.trim().length)
        }
        : {
          model: initial,
          type: 'text',
          isValid: s => s.trim().length >= minLength && (!maxLength || s.trim().length <= maxLength)
        }

      Dialog
        .create({ ...options, prompt })
        .onOk((data) => resolve(data))
        .onCancel(() => resolve(false))
    })
  }

  /**
   * Creates options object for a dialog
   */
  getDialogOptions ({ title, message, html, persistent = false, ok = 'OK', cancel = 'Cancel' } = {}) {
    const options = {
      title,
      message,
      html,
      persistent,
      ok: ok
        ? { label: ok, color: 'blue-grey-9' }
        : undefined,
      cancel: cancel
        ? { label: cancel, color: 'blue-grey-5' }
        : undefined,
      'transition-show': 'none',
      'transition-hide': 'none'
    }
    return options
  }
}
