<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { formatTime } from '@stellacontrol/utilities'
import { DeviceFlags } from '@stellacontrol/model'
import DeviceWidget from './device-widget'

export default {
  mixins: [
    DeviceWidget
  ],

  props: {
  },

  data () {
    return {
      logBundle: null
    }
  },

  computed: {
    ...mapState({
      // Status watcher clock ticks, used to enforce refreshing of labels such as status age
      ticks: state => state.deviceStatus.ticks
    }),

    ...mapGetters([
      'isSmallScreen'
    ]),

    // Buttons to display in the widget
    buttons () {
      const { ticks, device: { flags }, logBundle } = this
      if (ticks >= 0) {
        const buttons = [
          {
            name: 'blockStatus',
            icon: 'podcasts',
            color: flags.blockStatus ? 'red-7' : 'green-7',
            tooltip: flags.blockStatus ? 'Not receiving status messages from the device<br>Click to resume.' : 'Receiving status messages from the device.<br>Click to suspend.'
          },
          {
            name: 'blockSettings',
            icon: 'settings',
            color: flags.blockSettings ? 'red-7' : 'green-7',
            tooltip: flags.blockSettings ? 'Settings changes will be discarded<br>Click to resume.' : 'Receiving settings from the platform.<br>Click to suspend.'
          },
          {
            name: 'blockCommands',
            icon: 'bolt',
            color: flags.blockCommands ? 'red-7' : 'green-7',
            tooltip: flags.blockCommands ? 'Commands will be discarded<br>Click to resume.' : 'Receiving commands from the platform.<br>Click to suspend.',
            break: true
          },
          {
            name: 'debug',
            icon: 'search',
            color: flags.isDebugging ? 'blue-7' : 'grey-5',
            tooltip: flags.isDebugging ? `Device messages are logged until ${formatTime(flags.debug)}<br>Click to stop logging.` : 'Device messages are not logged.<br>Click to start logging.'
          },
          {
            name: 'profile',
            icon: 'schedule',
            color: flags.isDebugging ? 'blue-7' : 'grey-5',
            tooltip: flags.isDebugging ? `Message processing speed is logged until ${formatTime(flags.profile)}<br>Click to stop logging.` : 'Message processing speed is not logged.<br>Click to start logging.'
          },
          {
            name: 'download',
            isHidden: !logBundle?.url,
            href: logBundle?.url,
            icon: 'download',
            color: 'blue-7',
            tooltip: logBundle ? `Click to download device logs <br>collected at ${ formatTime(logBundle.createdAt) }` : ''
          }
        ]
        return buttons
      } else {
        return []
      }
    }
  },

  methods: {
    ...mapActions([
      'setDeviceFlags',
      'startDeviceLogging',
      'stopDeviceLogging',
      'getDeviceLogBundle'
    ]),

    // Loads the most recent log bundle
    async populate () {
      const { device } = this
      if (device) {
        this.logBundle = await this.getDeviceLogBundle({ device })
      } else {
        this.logBundle = null
      }
    },

    // Toggles the specified flag
    async toggleFlag (name) {
      const { device } = this
      const flags = new DeviceFlags(device.flags)

      // Enable debugging, optionally with profiling
      if (name === 'debug' || name === 'profile') {
        if (flags.isDebugging) {
          this.logBundle = await this.stopDeviceLogging({ device })
        } else {
          this.logBundle = null
          await this.startDeviceLogging({ device, profile: name === 'profile' })
        }
      } else {
        // Other flags
        flags.toggle(name)
        await this.setDeviceFlags({ device, flags })
      }
    }
  },

  watch: {
    device () {
      this.populate()
    }
  },

  async created () {
    await this.populate()
  }
}
</script>

<template>
  <sc-widget class="icon-widget">
    <div class="widget-content">
      <div class="lines">
        <span class="label">
          Device Flags
        </span>
        <div class="flags row wrap q-mt-sm">
          <template v-for="button in buttons">
            <q-btn :icon="button.icon" :color="button.color" round unelevated dense
              size="12px" class="q-mr-md q-mb-md"
              @click="button.href ? () => {} : toggleFlag(button.name)"
              :href="button.href"
              v-if="!button.isHidden">
              <sc-tooltip :text="button.tooltip">
              </sc-tooltip>
            </q-btn>
            <br v-if="button.break && !isSmallScreen">
          </template>
        </div>
      </div>
    </div>
  </sc-widget>
</template>

<style lang="scss" scoped>
.flags {
  display: inline-block;
}
</style>
