import { assign } from '@stellacontrol/utilities'
import { DeviceAcronym } from '../device/device-type'
import { isTrendAlert } from './alert-type'

/**
 * Definition of an alert watched for a specified device
 */
export class AlertDefinition {
  constructor (data = {}) {
    assign(this, {
      ...data
    })
  }

  /**
   * Alert type
   * @type {AlertType}
   */
  alertType

  /**
   * Identifier of the monitored device
   * @type {String}
   */
  deviceId

  /**
   * Serial number of the monitored device
   * @type {String}
   */
  serialNumber

  /**
   * Device type
   * @type {DeviceType}
   */
  type

  /**
   * Device model
   * @type {DeviceModel}
   */
  model
  resellerModel

  /**
   * Device version
   * @type {String}
   */
  firmwareVersion
  firmwareVersionLong
  hardwareVersion
  eepromVersion

  /**
   * Port count
   * @type {Number}
   */
  portCount

  /**
   * Device bands
   * @type {String}
   */
  bands

  /**
   * Device acronym, showing its type and bands
   * @type {String}
   */
  get acronym () {
    const type = DeviceAcronym[this.type] || '?'
    const bands = (this.bands || '').length || ''
    return type + bands
  }

  /**
   * Identifier of a place where the device is assigned to
   * @type {String}
   */
  placeId

  /**
   * Name of a place where device is assigned to
   * @type {String}
   */
  placeName

  /**
   * Detailed location within the place
   * @type {String}
   */
  location

  /**
   * Custom device location
   * @type {String}
   */
  customLocation

  /**
   * Identifier of the owner of the monitored device
   * @type {String}
   */
  ownerId

  /**
   * Name of the owner of the monitored device
   * @type {String}
   */
  ownerName

  /**
   * Code of the country of the device owner
   * ISO 3166 Alpha-2 country code
   * @type {String}
   */
  ownerCountry

  /**
   * Code of the preferred language used by the device owner
   * ISO 639-2 language code
   * @type {String}
   */
  ownerLanguage

  /**
   * Timezone of the device owner, as per https://www.iana.org/time-zones
   * @type {String}
   */
  ownerTimezone

  /**
  * Identifier of the parent of the owner of the monitored device
  * @type {String}
  */
  parentId

  /**
   * Name of the parent of the owner of the monitored device
   * @type {String}
   */
  parentName

  /**
   * Name of the current premium service on the device
   * @type {String}
   */
  premiumServiceName

  /**
   * Code of the current premium service on the device
   * @type {String}
   */
  premiumServiceCode

  /**
   * Custom alert configuration
   * @type {AlertConfiguration}
   */
  configuration

  /**
   * Simulated device profile
   * @type {String}
   */
  simulatedDeviceProfile

  /**
   * Indicates that it's a simulated device
   * for which fake status data will be generated and returned.
   * @type {Boolean}
   */
  get isSimulatedDevice () {
    return Boolean(this.simulatedDeviceProfile)
  }

  /**
   * Indicates a trend-based alert, which requires a block of data collected
   * over time to determine whether alert situation has arisen
   * @type {Boolean}
   */
  get isTrendAlert () {
    return isTrendAlert(this.alertType)
  }
}
