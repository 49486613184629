import { DeviceStatus } from '@stellacontrol/devices'
import { createState } from './device-status.state'

export const mutations = {
  /**
   * Starts listening to device status updates
   * @param {String} name Name of a view or process which has initiated listening
   * @param {PushClient} listener Device status listener
   * @param {Clock} clock Process clock
   */
  subscribeDeviceStatus (state, { name, listener, clock }) {
    state.statusListeners[name] = { name, listener, clock }
    state.ticks = 0
  },

  /**
   * Stops listening to device status updates
   * @param {String} name Name of a view or process which has initiated listening
   * If not specified, all status listeners are stopped.
   */
  unsubscribeDeviceStatus (state, { name } = {}) {
    state.ticks = 0
    if (name) {
      delete state.statusListeners[name]
    } else {
      for (const name of Object.keys(state.statusListeners)) {
        delete state.statusListeners[name]
      }
    }
  },

  /**
   * Registers a clock ticking while waiting for device status
   */
  tickDeviceStatus (state, { ticks = 0 } = {}) {
    state.ticks = ticks
  },

  /**
   * Triggered when live status of a device has been received.
   * If one of our devices, update its status so it's reflected in the view.
   * Stores the status of a device in `devices` dictionary.
   * Implement this action on other stores to consume the received status.
   * @param {DeviceStatus} status Live status of a single device
   * @param {Device} device Device whose status has been received
   * @param {Device} masterDevice Master device, if {@link device} is a part of multi-board unit
   */
  deviceStatusReceived (state, { device, masterDevice, status } = {}) {
    if (status) {
      const { serialNumber } = status
      state.devices[serialNumber] = status
      // If device part status has been received,
      // Also store it the master device status.
      if (device && masterDevice && device.partOf === masterDevice.id) {
        const masterStatus = state.devices[masterDevice.serialNumber] || new DeviceStatus(masterDevice.serialNumber, {})
        masterStatus.parts[device.serialNumber] = status
        state.devices[masterDevice.serialNumber] = masterStatus
      }
    }
  },

  /**
   * Triggered when recent device alerts have been received
   * @param {Device} device Device whose status has been received
   * @param {Array[AlertOccurrence]} alerts Recent device alerts
   */
  deviceAlertsReceived (state, { device, alerts }) {
    if (device && alerts) {
      state.alerts[device.serialNumber] = alerts
    }
  },

  /**
   * Finishes retrieving the status for a batch of devices
   * @param {String} name Name of a view or process which has initiated the retrieval
   * @param {Array[Device]} devices Devices whose status has just been retrieved
   */
  finishRetrievingDeviceStatus (_, { name, devices } = {}) {
    if (name && devices) {
      // lint-disable-line no-empty
    }
  },

  /**
   * Starts listening to device data updates
   * @param {String} name Name of a view or process which has initiated listening
   * @param {PushClient} listener Device data listener
   */
  subscribeDeviceUpdates (state, { name, listener }) {
    state.deviceListeners[name] = { name, listener }
  },

  /**
   * Stops listening to device data updates
   * @param {String} name Name of a view or process which has initiated listening
   * If not specified, all status listeners are stopped.
   */
  unsubscribeDeviceUpdates (state, { name } = {}) {
    if (name) {
      delete state.deviceListeners[name]
    } else {
      for (const name of Object.keys(state.deviceListeners)) {
        delete state.deviceListeners[name]
      }
    }
  },

  /**
   * Triggered when update of device data has been received.
   * Implement this action on other stores to consume the received update.
   * @param {Device} device Device whose data has been received
   * @param {Device} masterDevice Master device, if {@link device} is a part of multi-board unit
   * @param {Device} update Received data of the device
   * @param {DeviceEntity} part Specified if update contains only partial data of the device.
   */
  // eslint-disable-next-line no-unused-vars
  deviceUpdateReceived (state, { device, masterDevice, update, part } = {}) {
  },

  /**
   * Resets the state to original shape.
   * @description
   * Presence of this mutation is obligatory on stores,
   * to ensure purging of any user-bound data when
   * user logs in and out and in. Otherwise expect
   * unexpected side effects with data of the previously
   * logged in user appearing at places.
   */
  reset (state) {
    Object.assign(state, createState())
  }
}
