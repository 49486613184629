<script>
import { mapGetters, mapActions } from 'vuex'
import { ListViewMode } from './list-view-mode'

export default {
  props: {
    // Name of the list controlled by this component
    name: {
      type: String,
      required: true
    },
    // Label to display inside the search box
    label: {
      type: String,
      default: 'Search'
    },
    // If true (default), changing list view type is possible enabled
    allowChangingViewType: {
      type: Boolean,
      default: true
    },
    // If true, the container will be transparent and assume parent's background color
    transparent: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      filter: ''
    }
  },

  computed: {
    ...mapGetters([
      'listFilter',
      'isNormalListView',
      'isDenseListView',
      'isCardView',
      'list',
    ]),

    listState () {
      return this.list(this.name)
    }
  },

  emits: [
    'filter'
  ],

  methods: {
    ...mapActions([
      'setListState'
    ]),

    normalView () {
      const { name } = this
      this.setListState({ name, viewMode: ListViewMode.Normal } )
    },

    denseView () {
      const { name } = this
      this.setListState({ name, viewMode: ListViewMode.Dense } )
    },

    cardView () {
      const { name } = this
      this.setListState({ name, viewMode: ListViewMode.Cards } )
    },

    applyFilter () {
      const { name } = this
      const filter = this.filter ? this.filter.trim() : ''
      this.setListState({ name, filter } )
      this.$emit('filter', { filter })
    }
  },

  created () {
    // Get the last filter from the grid state
    this.filter = this.listFilter(this.name) || ''
  }
}

</script>

<template>
  <div class="list-filter row items-center" :class="{ transparent }">
    <slot name="filter" v-bind:data="listState">
      <q-input
        class="input-filter"
        outlined
        clearable
        clear-icon="close"
        dense
        :label="label"
        icon="search"
        bg-color="white"
        :debounce="500"
        v-model="filter"
        @update:model-value="() => applyFilter()"
      >
      <template v-slot:prepend>
        <q-icon name="search"></q-icon>
      </template>
      </q-input>
    </slot>

    <div class="inner row items-center">
      <slot>
      </slot>
    </div>

    <div class="row items-center">
      <slot name="buttons" v-bind:data="listState">
        <template v-if="allowChangingViewType">
          <q-btn flat round dense unelevated icon="menu" size="12px" :ripple="false" :color="isNormalListView(name) ? 'primary' : 'grey-6'"
            @click="normalView()">
          </q-btn>
          <q-btn flat round dense unelevated icon="reorder" size="12px" :ripple="false" :color="isDenseListView(name) ? 'primary' : 'grey-6'"
            @click="denseView()">
          </q-btn>
        </template>
      </slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.list-filter {
  padding: 15px 15px 15px 15px;
  background-color: #F2F2F2;
  border-bottom: 1px solid #DFE3EA;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &.transparent {
    background-color: transparent;
  }

  .input-filter {
    min-width: 30%;
  }

  .inner {
    flex: 1;
  }
}
</style>
