import { dispatch, dispatchParallel, isRouteDataChanged } from '@stellacontrol/client-utilities'

/**
 * Prepares data for organization-profiles route
 */
export async function resolveOrganizationProfiles () {
  await dispatchParallel([
    'requireOrganizationProfiles',
    'requireOrganizations'
  ])
}

/**
 * Prepares data for organization-profile route
 */
export async function resolveOrganizationProfile ({ from, to }) {
  if (!isRouteDataChanged(from, to)) {
    return true
  }

  await dispatchParallel([
    'requireOrganizationProfiles',
    'requireOrganizations'
  ])

  const { params: { id }, query: { copy } } = to
  let isNew = id === 'new'
  const data = isNew
    ? await dispatch('newOrganizationProfile')
    : await dispatch('getOrganizationProfile', { id })

  if (data) {
    if (!isNew) {
      // Edit a profile - make sure that profile is owned by the current organization.
      // Do not allow editing other profiles!
      const isMyProfile = await dispatch('isMyOrganizationProfile', { organizationProfile: data })
      if (!isMyProfile) {
        return {
          redirectTo: {
            name: 'organization-profiles'
          }
        }
      }
    }

    // Clone the existing profile
    if (id && copy) {
      data.id = undefined
      data.name = undefined
      isNew = true
    }

    const title = isNew ? 'New Organization Profile' : `Organization Profile: ${data.name}`
    await dispatch('setRouteData', { from, to, data, title })
    return true
  }
}
