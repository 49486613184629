import { dispatch } from '@stellacontrol/client-utilities'
import PreferencesView from './preferences.vue'

const name = 'preferences'

export const Routes = [
  {
    name,
    path: '/administration/preferences',
    component: PreferencesView,

    async beforeEnter (to, from, next) {
      const { id } = await dispatch('getCurrentOrganization')
      const data = await dispatch('getOrganization', { id })
      if (data) {
        await dispatch('setRouteData', { from, to, data })
        next()
      }
    }
  }
]
