import pkg from '../package.json'
export * from './store'
export * from './components'
export * from './dialogs'
export * from './views'
export * from './router'

import { AppletRoutes as routes } from './router'
import { AppletStores as stores } from './store'
import { AppletViews as views } from './views'
import { useDeviceTransmissionComponents } from './components'
import { useDeviceTransmissionDialogs } from './dialogs'

/**
 * Installs Device Transmission applet into the application
 * @param application Vue application instance
 */
export function useDeviceTransmissionApplet (application) {
  useDeviceTransmissionComponents(application)
  useDeviceTransmissionDialogs(application)

  const { name, version, description } = pkg
  return { name, version, description, routes, views, stores }
}
