<script>
import { mapGetters } from 'vuex'
import { Secure } from '@stellacontrol/security-ui'
import { AlertDescription } from '@stellacontrol/model'
import DetailsMixin from './details-mixin'

export default {
  mixins: [
    Secure,
    DetailsMixin
  ],

  data () {
    return {
      AlertDescription
    }
  },

  computed: {
    ...mapGetters([
      'toLocalDateTime'
    ])
  }
}
</script>

<template>
  <q-virtual-scroll class="scroller" type="table"
    :virtual-scroll-item-size="30"
    :items="items">

    <template v-slot:before>
      <thead>
        <tr class="header">
          <th>
            Device
          </th>
          <th>
            Owner
          </th>
          <th>
            Place
          </th>
          <th>
            Time
          </th>
          <th>
            Alert
          </th>
        </tr>
      </thead>
    </template>

    <template v-slot="{ item, index }">
      <tr :key="index" class="item">
        <td class="serial">
          <router-link class="item-link"
            :to="{ name: 'device-dashboard', params: { serialNumber: item.serialNumber } }">
            {{ item.serialNumber }}
            <sc-tooltip :text="`Go to ${item.serialNumber} dashboard`"></sc-tooltip>
          </router-link>
        </td>
        <td class="organization">
          {{ item.organization }}
        </td>
        <td class="place">
          {{ item.place }}
        </td>
        <td class="date">
          {{ datetime(toLocalDateTime(item.time)) }}
        </td>
        <td class="type">
          {{ AlertDescription[item.alertType] }}
          <sc-tooltip :text="item.message">
          </sc-tooltip>
        </td>
      </tr>
    </template>

  </q-virtual-scroll>
</template>

<style scoped lang="scss">
.scroller {
  box-shadow: none;
  max-height: 90vh;

  .header {
    height: auto;

    th {
      text-align: left;
    }
  }

  .item {
    td {
      height: auto;
    }

    .serial {
      width: 120px;
    }

    .organization {
      width: 140px;
    }

    .place {
      width: 140px;
    }

    .date {
      width: 120px;
    }

    .type {
      white-space: normal;
    }
  }
}
</style>
