import { PlanItem, PlanItemType } from './plan-item'

/**
 * Icon
 */
export class PlanIcon extends PlanItem {
  constructor (data = {}) {
    super(data)
    this.assign(data)
  }

  /**
   * Item defaults
   */
  get defaults () {
    return {
      ...super.defaults
    }
  }

  /**
   * Item type
   * @type {PlanItemType}
   */
  static get type () {
    return PlanItemType.Icon
  }

  normalize () {
    super.normalize()
  }

  /**
   * Icon name
   * @type {String}
   */
  icon

  /**
   * Icon color
   * @type {String}
   */
  color
}
