import { createState } from './premium-subscribers-view.state'

export const mutations = {
  /**
   * Stores wallet of the specified customer
   * @param wallet Wallet to store
   * @param customer Customer owning the wallet
   * @param customerGuardian Security guardian of the customer
   * @param devices Devices owned by the customer
   */
  storeCustomerWallet (state, { customer, wallet, guardian, devices } = {}) {
    state.customer = customer || state.customer
    state.guardian = guardian || state.guardian
    state.devices = devices || state.devices
    state.wallet = wallet || state.wallet
  },

  /**
   * Resets the store to defaults
   */
  resetPremiumSubscribersView (state) {
    Object.assign(state, createState())
  },

  reset (state) {
    Object.assign(state, createState())
  }
}
