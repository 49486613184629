import Konva from 'konva'
import { Layer } from './layer'
import { Log, Rectangle, countString } from '@stellacontrol/utilities'
import { PlanLineStyle, PlanTextStyle, PlanBackgroundStyle } from '@stellacontrol/planner'

/**
 * Cross-section layer renderer
 */
export class CrossSectionLayer extends Layer {
  constructor (data) {
    super(data)
  }


  /**
   * Cross-section layer cannot be hidden
   * @type {Boolean}
   */
  get isVisible () {
    return true
  }

  /**
   * Cross-section layer cannot be hidden
   * @param {Boolean} value
   */
  // eslint-disable-next-line no-unused-vars
  setVisibility (value) {
    super.setVisibility(true)
  }

  /**
   * Rendering defaults
   */
  defaults = {
    border: {
      normal: new PlanLineStyle({
        color: '#808080',
        width: 2
      }),
      selected: new PlanLineStyle({
        color: '#202020',
      })
    },
    background: {
      normal: new PlanBackgroundStyle({
        color: '#ffffff',
        width: 2
      }),
      selected: new PlanBackgroundStyle({
        color: '#eef1fd',
      })
    },
    labelStyle: new PlanTextStyle({
      color: 'black',
      size: 17
    })
  }

  /**
   * Shapes representing plan floors
   * @type {Dictionary<String, Konva.Group>}
   */
  floors = []

  /**
   * The number of the floors
   * @type {Number}
   */
  get floorCount () {
    return this.floors.length
  }

  /**
   * Returns the specified plan floor
   * @param {String} id Floor identifier
   * @returns {PlanFloor}
   */
  getFloor (id) {
    return this.layout.getFloor(id)
  }

  /**
   * Returns the shape representing a plan floor
   * @param {String} id Floor identifier
   * @returns {Konva.Group}
   */
  getFloorShape (id) {
    return this.floors.find(f => f.id() === id)
  }

  /**
   * Creates own shapes of the layer
   * @return {Promise<Array[Shape|Konva.Shape]>}
   */
  async createOwnShapes () {
    // Remove previous elements
    this.destroy(...this.floors)
    this.floors = []

    for (const floor of this.layout.floors) {
      const floorShape = this.createFloorShape(floor)
      this.floors.push(floorShape)
    }

    for (const floor of this.layout.floors) {
      this.showFloor(floor)
    }

    return this.floors
  }

  /**
   * Creates a shape representing a plan floor
   * @param {PlanFloor} floor Plan floor
   * @return {Promise<Konva.Group>}
   */
  createFloorShape (floor) {
    const { defaults, layout: { crossSection: { dimensions } } } = this

    const floorShape = new Konva.Group({
      id: floor.id
    })

    const width = dimensions.width
    const height = floor.crossSection.height

    const background = new Konva.Rect({
      id: 'background',
      x: 0,
      y: 0,
      width,
      height
    })

    const border = new Konva.Rect({
      id: 'border',
      x: 0,
      y: 0,
      width,
      height,
      strokeWidth: defaults.border.normal.width,
    })

    const bottom = new Konva.Rect({
      id: 'bottom',
      x: 0,
      y: height - defaults.border.normal.width * 2,
      width,
      height: defaults.border.normal.width * 2,
    })

    const antennaCount = floor.getAntennae().length
    const label = new Konva.Text({
      id: 'label',
      x: 8,
      y: 8,
      text: antennaCount === 0 ? floor.label : `${floor.label} (${countString(antennaCount, 'antenna')})`,
      fill: defaults.labelStyle.color,
      fontFamily: defaults.labelStyle.font,
      fontSize: defaults.labelStyle.size
    })

    floorShape.add(background, border, bottom, label)

    // Select the floor on mouse click
    floorShape.on('mousedown', () => {
      this.selectFloor(floor)
    })

    return floorShape
  }

  /**
   * Shows the shape representing the specified plan floor
   * at its correct position, taking into consideration collapsed/expanded status
   * @param {PlanFloor} floor Plan floor
   */
  showFloor (floor) {
    const { defaults, floors } = this
    const floorIndex = floors.findIndex(f => f.id() === floor.id)
    if (floorIndex > -1) {
      const { x, y, width, height } = floor.crossSection.bounds

      const floorShape = floors[floorIndex]
      floorShape.x(x)
      floorShape.y(y)
      floorShape.width(width)
      floorShape.height(height)

      // Mark the floor as selected
      const backgroundStyle = floor.isSelected ? defaults.background.selected : defaults.background.normal
      const borderStyle = floor.isSelected ? defaults.border.selected : defaults.border.normal
      const backgroundShape = floorShape.findOne('#background')
      const borderShape = floorShape.findOne('#border')
      const bottomShape = floorShape.findOne('#bottom')
      backgroundShape.fill(backgroundStyle.color)
      borderShape.stroke(borderStyle.color)
      bottomShape.fill(borderStyle.color)

      // Make sure that items are correctly positioned on the floor
      this.showFloorItems(floor)
    }
  }

  /**
   * Shows the items which belong to the specified plan floor
   * @param {PlanFloor} floor Plan floor
   */
  showFloorItems (floor) {
    const { layout, layout: { crossSection } } = this

    for (const item of floor.crossSectionItems) {
      // If item does not have coordinates on the cross-section yet,
      // place it automatically
      if (!item.isPointBased && item.getCoordinates(true).isZero) {
        layout.placeItem(item, crossSection)
      }
    }
  }

  /**
   * Returns the floor at which the specified point is
   * @param {Point} coordinates Coordinates to check
   * @returns {PlanFloor}
   */
  getFloorAt (coordinates) {
    if (coordinates) {
      const { floors } = this

      for (const floorShape of floors) {
        const bounds = Rectangle.from({
          ...floorShape.position(),
          ...floorShape.size()
        })
        if (bounds.contains(coordinates)) {
          return this.getFloor(floorShape.id())
        }
      }
    }
  }

  /**
   * Marks the floor as selected
   * @param {PlanFloor} floor Floor to select
   */
  selectFloor ({ id } = {}) {
    const { layout } = this
    layout.selectFloor({ id })
    for (const floor of layout.floors) {
      this.showFloor(floor)
    }
  }

  /**
   * Notifies that the specified item has been moved
   * @param {PlanItem} item Moved item
   * @param {Boolean} completed If true, the movement has completed (usually when user releases the mouse button)
  */
  itemMoved (item, completed) {
    super.itemMoved(item, completed)
    if (item && completed) {
      const floor = this.getFloorAt(item.crossSection.coordinates)
      // Move the item to the floor on which it was dropped.
      // Do not do that if item belongs to cross-section only.
      if (floor && floor.id !== item.floorId && !item.showOnlyOnCrossSection) {
        const { layout } = this
        const currentFloor = layout.getFloor(item.floorId)
        this.selectFloor(floor)
        layout.moveToFloor(item, floor)
        Log.debug(`[${item.type}] moved from [${currentFloor.label}] to [${floor.label}]`)
      }
      // Update floor labels to show eventual changes to antenna counts
      this.updateFloorLabels()
    }
  }

  /**
   * Notifies that the specified item has been removed from the plan
   * @param {PlanItem} item Removed item
   */
  itemRemoved (item) {
    super.itemRemoved(item)
    this.updateFloorLabels()
  }

  /**
   * Notifies that the specified item has been added to the plan
   * @param {PlanItem} item Added item
  */
  itemAdded (item) {
    super.itemAdded(item)
    this.updateFloorLabels()
  }

  /**
   * When item has been selected, activate the floor it is on
   * @param {PlanItem} item Selected item
   */
  itemSelected (item) {
    super.itemSelected(item)
    const floor = this.layout.getFloorOf(item)
    if (floor?.isFloor) {
      this.selectFloor(floor)
    }
  }

  /**
   * Renders a shape on the layer.
   * We override this inherited method to ensure that shape is rendered using its cross-section coordinates,
   * and not the default floor coordinates.
   * @param {Shape|Konva.Shape} shape
   */
  renderShape (shape, isOwn) {
    if (!shape) return
    super.renderShape(shape, isOwn)

    if (!isOwn && shape.item && shape.item.showOnCrossSection && !shape.item.isPointBased) {
      // Cross-section view uses its own set of coordinates
      const { x, y } = shape.item.crossSection.coordinates
      shape.content.x(x)
      shape.content.y(y)
    }
  }

  /**
   * Updates floor labels after changes to the plan
   * @param {PlanFloor} floor Floor to update, optional. If not specified, all floor labels are updated
   */
  updateFloorLabels (floor) {
    const { layout, floors } = this
    const floorShapes = floor
      ? [this.getFloorShape(floor.id)]
      : floors

    for (const floorShape of floorShapes) {
      const id = floorShape.id()
      const floor = layout.getFloor(id)
      const antennaCount = floor.getAntennae().length
      const label = floorShape.findOne('#label')
      const text = antennaCount === 0 ? floor.label : `${floor.label} (${countString(antennaCount, 'antenna')})`
      label.text(text)
    }
  }
}
