import { dispatch, getters } from '@stellacontrol/client-utilities'
import MyAccountView from './my-account.vue'

const name = 'my-account'

export const Routes = [
  {
    name,
    path: '/administration/my-account',
    component: MyAccountView,

    async beforeEnter (to, from, next) {
      await dispatch('requireUsers')

      const { currentUserId: id } = getters()
      const data = await dispatch('getUser', { id })
      if (data) {
        await dispatch('setRouteData', { from, to, data })
        next()
      }
    }
  }
]
