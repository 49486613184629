/**
 * Global search
 */
export function createState () {
  return {
    // Search condition
    condition: null,

    // Indicates a search in progress
    isSearching: false,

    // Found groups of entities, per entity type
    groups: {}
  }
}

export const state = createState()

export const getters = {
}

