import Konva from 'konva'
import { PlanItemType, PlanItemState } from '@stellacontrol/planner'
import { Shape } from './shape'

/**
 * Polygon
 */
export class PolygonShape extends Shape {
  constructor (item, dataCallback) {
    super(item, dataCallback)
    this.createShapes()
  }

  static get type () {
    return PlanItemType.Polygon
  }

  /**
   * Line shape
   * @type {Konva.Line}
   */
  lineShape

  /**
   * Rectangle showing the polygon boundaries
   * @type {Konva.Rect}
   */
  shapeBoundaries

  /**
   * Returns an array of coordinates of shape points
   * @param {PlanRenderer} renderer Plan renderer
   * @returns {Array[Point]}
   */
  // eslint-disable-next-line no-unused-vars
  getShapePoints ({ renderer }) {
    return this.item.points
  }

  /**
   * Indicates that label should be rendered with a little delay.
   * @type {Boolean}
   */
  deferLabel () {
    return true
  }

  createShapes () {
    super.createShapes()
    this.lineShape = new Konva.Line()
    this.content.add(this.lineShape)
  }

  render (renderer) {
    super.render(renderer)
    const { lineShape, item, isPointedAt } = this
    const isHover = isPointedAt || renderer.isItemSelected(item)

    const { inProgress, backgroundStyleInProgress, lineStyleInProgress } = item
    const backgroundStyle = (inProgress ? backgroundStyleInProgress : null) || item.backgroundStyle
    const lineStyle = (inProgress ? lineStyleInProgress : null) || this.getLineStyle(item, item.lineStyle, isHover ? PlanItemState.Hover : PlanItemState.Normal, renderer)

    const { dash, width, color, lineCap, lineJoin } = lineStyle
    const points = this.toCoordinates(this.getShapePoints({ renderer }))

    lineShape.points(points)
    lineShape.dash(dash)
    lineShape.lineJoin(lineJoin)
    lineShape.lineCap(lineCap)
    lineShape.closed(true)
    lineShape.fill(backgroundStyle.color)
    lineShape.opacity(backgroundStyle.opacity || 1)

    if (item.inProgress) {
      lineShape.strokeWidth(width || 2)
      lineShape.stroke(color || 'black')
    } else {
      lineShape.strokeWidth(width)
      lineShape.stroke(color)
    }

    this.applyFilters()
  }

  /**
   * Moves shape to the specified new coordinates.
   * @param {Point} position Position to which the shape was moved
   * @param {Point} delta Movement delta
   */
  moveTo (position, delta) {
    if (position && delta) {
      // Since polygon is rendered using absolute point coordinates,
      // move the group shape back to 0,0
      this.content.x(0)
      this.content.y(0)
    }
  }

  /**
   * Triggered when shape has been transformed.
   * @param {PlanRenderer} renderer Plan renderer
   * @param {PlanScale} scale Scale of the shape
   * @param {Number} rotation Shape rotation
   */
  transformed ({ renderer, scale, rotation }) {
    super.transformed({ renderer, scale, rotation })

    // Change the coordinates of all points
    const { item } = this
    for (const point of item.points) {
      point.x = Math.round(point.x * scale.x)
      point.y = Math.round(point.y * scale.y)
    }

    // Re-render the shape
    this.render(renderer)
  }
}
