/**
 * Client state mutations
 */
export const mutations = {
  /**
   * Adds new active PUSH listener
   * @param {String} name Unique name of the listener
   * @param {PushClient} listener Push listener to add
   */
  addPushListener (state, { name, listener } = {}) {
    if (listener && name) {
      const existingListener = state.pushListeners[name]
      if (existingListener) {
        existingListener.close()
      }
      state.pushListeners[name] = listener
    }
  },

  /**
   * Disconnects and removes active PUSH listener
   * @param {PushClient} listener Push listener to remove
   * @param {String} name Alternatively, the name of the push listener to remove
   */
  removePushListener (state, { listener, name } = {}) {
    if (listener) {
      const entry = Object.entries(state.pushListeners).find(entry => entry[1].id === listener.id)
      if (entry) {
        const [name, listener] = entry
        delete state.pushListeners[name]
        listener.close()
      }
    } else {
      const listener = state.pushListeners[name]
      if (listener) {
        delete state.pushListeners[name]
        listener.close()
      }
    }
  },

  /**
   * Disconnects and removes all active PUSH listeners
   */
  removePushListeners (state) {
    const listeners = Object.values(state.pushListeners)
    state.pushListeners = {}
    for (const listener of listeners) {
      listener.close()
    }
  }
}
