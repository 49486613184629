import OrganizationProfilesView from './organization-profiles.vue'
import OrganizationProfileView from './organization-profile.vue'
import { resolveOrganizationProfiles, resolveOrganizationProfile } from './organization-profile.resolve'

export const Routes = [
  // List of organization profiles
  {
    name: 'organization-profiles',
    path: '/administration/organization-profiles',
    component: OrganizationProfilesView,

    async beforeEnter (to, from, next) {
      await resolveOrganizationProfiles()
      next()
    }
  },
  // Edit organization profile
  {
    name: 'organization-profile',
    path: '/administration/organization-profile/:id',
    component: OrganizationProfileView,

    async beforeEnter (to, from, next) {
      const result = await resolveOrganizationProfile({ to, from })
      if (result) {
        next(result.redirectTo)
      }
    }
  }
]
