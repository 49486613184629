import { Log } from '@stellacontrol/utilities'
import { DeviceAPI } from '@stellacontrol/client-api'
import { FastSamplingSpeed } from '../model/fast-sampling-speed'
import { DeviceCommand } from '../model/device-command'

/**
 * Device API wrapper for sending commands to devices.
 */
export class DeviceCommandClient {
  /**
   * Sends a command to a device
   * @param devices Devices to send the command to
   * @param command Device command
   */
  async sendCommand (devices = [], command = {}) {
    if (!devices) throw new Error('Devices are required')
    if (devices.length === 0) return
    if (!command) throw new Error('Command is required')

    try {
      const results = await DeviceAPI.sendCommand({ devices, command })
      return results

    } catch (error) {
      Log.error(`Error sending command ${command.name} to ${devices.map(d => d.serialNumber).join(',')}`)
      Log.exception(error)
    }
  }

  /**
   * Switches the specified device to fast mode
   * @param {Device} device Device to switch to fast mode
   * @param {Number} speed Fast sampling speed
   * @param {Number} duration Duration, in seconds, of fast sampling mode
   */
  async switchToFastMode (device, { speed = FastSamplingSpeed.OneSecond, duration = 10 }) {
    const command = DeviceCommand.startFastSampling(speed, duration)
    const results = await this.sendCommand([device], command)
    return (results || [])[0]
  }
}
