import { groupItems } from '@stellacontrol/utilities'
import { OrganizationLevel } from '../organization/organization-level'
import { EntityType } from '../common/entity-type'
import { Place } from './device-entities'
import { PlaceType } from './place-type'

/**
 * Hierarchy of devices, grouped per organization and place
 */
export class DeviceHierarchy {
  constructor (data = {}) {
    Object.assign(this, data)

    this.items = data.items || []
    this.organizations = []

    const { organizations } = data
    if (organizations) {
      this.organizations = organizations
        .filter(o => o.parentOrganizationId === this.id)
        .map(({ organization }) => {
          return new DeviceHierarchy({
            ...organization,
            type: EntityType.Organization,
            parentOrganizationId: this.id,
            parentOrganization: this,
            hierarchyLevel: this.hierarchyLevel + 1,
            organizations
          })
        })
    }
  }
  /**
   * Creates a single device hierarchy item from the specified organization
   * @param {Organization} organization Organization
   * @param {Array[Device]} devices Devices to include in the hierarchy
   * @param {Array[Place]} places Places to include in the hierarchy
   * @param {Number} level Hierarchy level
   * @returns {DeviceHierarchy}
   */
  static fromOrganization ({ organization, devices, places, level = 0 } = {}) {
    const hierarchy = new DeviceHierarchy({
      id: organization.id,
      type: EntityType.Organization,
      name: organization.name,
      level: organization.level,
      parentOrganizationId: organization.parentOrganizationId,
      profile: organization.profile,
      organizations: [],
      places: [],
      hierarchyLevel: level,
      administrators: organization.administrators
    })

    const organizationId = organization.id
    const organizationDevices = devices?.filter(device => device.ownerId === organization.id) || []
    const stockDevices = organizationDevices.filter(device => !device.placeId) || []
    if (places) {
      organization.places = places.filter(p => p.organizationId === organization.id)
    }
    hierarchy.places = (organization.places || [])
      .map(place => {
        const placeDevices = organizationDevices
          .filter(device => device.placeId === place.id)
          .map(device => ({
            ...device,
            type: EntityType.Device,
            acronym: device.acronym,
            isConnectedDevice: device.isConnectedDevice,
            place: undefined,
            hierarchyLevel: level + 2
          }))

        return {
          id: place.id,
          type: EntityType.Place,
          organizationId,
          name: place.name,
          placeType: place.placeType,
          devices: placeDevices,
          deviceCount: placeDevices.count,
          sortOrder: place.sortOrder,
          hierarchyLevel: level + 1
        }
      })

    if (stockDevices.length > 0) {
      const placeDevices = stockDevices.map(device => {
        return {
          ...device,
          type: EntityType.Device,
          acronym: device.acronym,
          isConnectedDevice: device.isConnectedDevice,
          place: undefined,
          hierarchyLevel: level + 2
        }
      })

      hierarchy.places.push({
        name: Place.NAME_NOPLACE,
        id: Place.ID_NOPLACE,
        type: EntityType.Place,
        organizationId,
        placeType: PlaceType.NoPlace,
        devices: placeDevices,
        deviceCount: placeDevices.count,
        hierarchyLevel: level + 1
      })
    }

    return hierarchy
  }

  /**
   * Creates device hierarchy from the specified organizations and their devices
   * @param {Organization} organization Root organization to start from
   * @param {Array[Organization]} organizations Organizations to include in the hierarchy. They're supposed to come with places!
   * @param {Array[Device]} devices Devices to include in the hierarchy
   * @param {Array[Place]} places Places to include in the hierarchy
   * @returns {DeviceHierarchy}
   */
  static fromOrganizations ({ organization, organizations, devices, places }) {
    if (!organization) throw new Error('Root organization is required')

    // Build the hierarchy root
    const rootId = organization.id
    const hierarchy = DeviceHierarchy.fromOrganization({ organization, devices, places, level: 0 })

    // Bucket the devices and places by organization, for faster lookups
    const deviceDictionary = devices ? groupItems(devices, device => device.ownerId) : {}
    const placeDictionary = places ? groupItems(places, place => place.organizationId) : {}

    // Build the hierarchy items
    const itemDictionary = { [organization.id]: hierarchy }
    const items = (organizations || [])
      .map(organization => {
        // Make sure the root organization isn't listed twice, if it's also in the list of all organizations!
        if (organization.id === rootId) return
        // Find devices and places of the organization
        const devices = deviceDictionary[organization.id]
        const places = placeDictionary[organization.id]
        // Create hierarchy item
        const item = DeviceHierarchy.fromOrganization({ organization: organization, devices, places })
        itemDictionary[organization.id] = item
        return item
      }).filter(i => i)

    // Assign children to parent organizations
    for (const item of items) {
      const parent = itemDictionary[item.parentOrganizationId]
      if (parent) {
        item.parentOrganization = parent
        parent.organizations.push(item)
      }
    }

    // Fix hierarchy level for all items
    hierarchy.traverse((item, hierarchyLevel) => {
      item.hierarchyLevel = hierarchyLevel
      hierarchy.items.push({ id: item.id, type: item.type })
    })

    return hierarchy
  }

  /**
   * Flat list of all items in the hierarchy - organizations, places and devices,
   * useful for quick lookups. Present only on top-level node in the hierarchy.
   * @type {Array}
   */
  items

  /**
   * Item identifier
   * @type {String}
   */
  id

  /**
   * Item entity type
   * @type {entityType}
   */
  type

  /**
  * Places in the organization
  * @type {Array[DeviceHierarchy]}
  */
  places

  /**
   * Level in the hierarchy
   * @type {Number}
   */
  hierarchyLevel

  /**
   * Parent organization identifier
   * @type {String}
   */
  parentOrganizationId

  /**
   * Parent organization
   * @type {DeviceHierarchy}
   */
  parentOrganization

  /**
   * Organization name
   * @type {String}
   */
  name

  /**
   * Organization level
   * @type {OrganizationLevel}
   */
  level

  /**
   * Organization profile identifier
   * @type {String}
   */
  profileId

  /**
   * Organization profile details
   * @type {OrganizationProfile}
   */
  profile

  /**
   * A collection of child organizations
   * @type {Array[DeviceHierarchy]}
   */
  organizations

  /**
   * Admin users of the organization
   * @type {Array[User]}
   */
  administrators

  /**
   * Default admin user of the organization.
   * @type {User}
   */
  get administrator () {
    return (this.administrators || []).find(a => a.isEnabled)
  }

  /**
   * Identifier of the administrator
   * @type {String}
   */
  get administratorId () {
    return (this.administrator || {}).id
  }

  /**
   * Indicates that the organization has child organizations
   * @type {Boolean}
   */
  get hasChildOrganizations () {
    return this.organizations?.length > 0
  }

  /**
   * The number of child organizations
   * @type {Number}
   */
  get childOrganizationCount () {
    return this.organizations?.length > 0
  }

  /**
   * Indicates that the organization has parent organization
   * @type {Boolean}
   */
  get hasParent () {
    return Boolean(this.parentOrganizationId)
  }

  /**
   * Indicates whether profile represents a super organization
   * @type {Boolean}
   */
  get isSuperOrganization () {
    return this.level === OrganizationLevel.SuperOrganization
  }

  /**
   * Indicates whether profile represents a reseller organization which can have child organizations
   * @type {Boolean}
   */
  get isResellerOrganization () {
    return this.level === OrganizationLevel.ResellerOrganization
  }

  /**
   * Indicates whether profile represents a regular organization
   * @type {Boolean}
   */
  get isRegularOrganization () {
    return this.level === OrganizationLevel.Organization
  }

  /**
   * Indicates whether profile represents a guest organization with very limited functionality
   * @type {Boolean}
   */
  get isGuestOrganization () {
    return this.level === OrganizationLevel.GuestOrganization
  }

  /**
   * Indicates whether profile represents a shipping company with no access to system or devices,
   * present only to capture the realities of shipping devices between organizations
   * @type {Boolean}
   */
  get isShippingCompany () {
    return this.level === OrganizationLevel.ShipppingCompany
  }

  /**
   * Traverses the hierarchy calling the specified callback on each item.
   * If callback returns something, traversing is stopped and the result is returned.
   * @param {Function<DeviceHierarchy, Number, Boolean>} callback Callback to call on each traversed item.
   * If callback returns `true`, traversal ends returning the matching item
   * @param {EntityType} entityType Entity types to execute the callback upon. If not specified, all are checked: organizations, places and devices.
   * @param {Object} root Root to start the search from. If not specified, we start with this hierarchy.
   */
  traverse (callback, entityType, root) {
    if (!callback) throw new Error('Callback is required')

    const entityMatches = entity => !entityType || entity.type === entityType

    const item = (root || this)
    const hierarchyLevel = root ? root.hierarchyLevel + 1 : 0
    if (entityMatches(item) && callback(item, hierarchyLevel)) {
      return item
    }

    // Loop over places of organization
    if (item.type === EntityType.Organization) {
      for (const place of item.places || []) {
        if (entityMatches(place) && callback(place, hierarchyLevel + 1)) {
          return place
        }

        // Loop over devices in the place
        for (const device of place.devices || []) {
          if (entityMatches(device) && callback(device, hierarchyLevel + 2)) {
            return device
          }
        }
      }

      // Recurse into child organizations
      for (const child of item.organizations || []) {
        const result = this.traverse(callback, entityType, child)
        if (result) {
          return result
        }
      }
    }
  }

  /**
   * Traverses the hierarchy calling the specified async callback on each item.
   * If callback returns something, traversing is stopped and the result is returned.
   * @param {Function<DeviceHierarchy, Number, Boolean>} callback Callback to call on each traversed item, can be an async function.
   * If callback returns `true`, traversal ends returning the matching item
   * @param {EntityType} entityType Entity types to execute the callback upon. If not specified, all are checked: organizations, places and devices.
   * @param {Object} root Root to start the search from. If not specified, we start with this hierarchy.
   */
  async traverseAsync (callback, entityType, root) {
    if (!callback) throw new Error('Callback is required')

    const entityMatches = entity => !entityType || entity.type === entityType

    const item = (root || this)
    const hierarchyLevel = root ? root.hierarchyLevel + 1 : 0
    if (entityMatches(item) && await callback(item, hierarchyLevel)) {
      return item
    }

    if (item.type === EntityType.Organization) {
      // Loop over places of organization
      for (const place of item.places || []) {
        if (entityMatches(place) && await callback(place, hierarchyLevel + 1)) {
          return place
        }

        // Loop over devices in the place
        for (const device of place.devices || []) {
          if (entityMatches(device) && await callback(device, hierarchyLevel + 2)) {
            return device
          }
        }
      }

      // Recurse into child organizations
      for (const child of item.organizations || []) {
        const result = await this.traverseAsync(callback, entityType, child)
        if (result) {
          return result
        }
      }
    }
  }

  /**
   * Finds an item in the hierarchy using the specified predicate
   * @param {Function<DeviceHierarchy, Boolean>} predicate Predicate to check
   * @param {EntityType} entityType Entity types to check the predicate upon. If not specified, all are checked: organizations, places and devices.
   * @returns {DeviceHierarchy}
   */
  findBy (predicate, entityType) {
    if (!predicate) throw new Error('Predicate is required')
    return this.traverse(item => predicate(item), entityType)
  }

  /**
   * Finds an item in the hierarchy
   * @param {String} id Entity identifier
   * @param {EntityType} entityType Entity types to scan. If not specified, all are checked: organizations, places and devices.
   * @description If {@link entityType} is device, we also check whether serial number matches the specified {@link id}
   * @returns {DeviceHierarchy}
   */
  find (id, entityType) {
    if (id != null) {
      return this.findBy(item => item.id === id || (entityType === EntityType.Device && item.serialNumber === id))
    }
  }

  /**
   * Finds a parent organization of the specified item
   * matching the specified predicate.
   * @param {String} id Item identifier
   * @returns {DeviceHierarchy}
   */
  findParentOf (id, predicate) {
    let organization = (this.find(id, EntityType.Organization) || {}).parentOrganization
    while (organization) {
      if (predicate(organization)) {
        return organization
      } else {
        organization = organization.parentOrganization
      }
    }
  }

  /**
   * Finds a reseller organization for the specified organization.
   * If no reseller found, super organization will be seen as reseller.
   * @param {String} id Organization identifier
   * @returns {DeviceHierarchy}
   */
  findResellerOf (id) {
    return this.findParentOf(id, parent =>
      parent.level === OrganizationLevel.ResellerOrganization || parent.level === OrganizationLevel.SuperOrganization)
  }

  /**
   * Returns the entire chain of parents above the specified organization
   * @param {String} id Organization identifier
   * @returns {Array[DeviceHierarchy]}
   */
  getParentsOf (id) {
    const result = []
    let parent = (this.find(id) || {}).parentOrganization
    while (parent) {
      result.push(parent)
      parent = parent.parentOrganization
    }
    return result
  }

  /**
   * Returns true if specified organization is a direct child of the specified organization
   * @param {Organization} parent Parent organization to check
   * @param {Organization} child Child organization to check
   * @returns {Boolean}
   */
  isChildOf ({ id: parentId }, { id: childId }) {
    const child = this.find(childId, EntityType.Organization)
    const parent = this.find(parentId, EntityType.Organization) || {}
    return child && child.parentOrganizationId === parent.id
  }

  /**
   * Returns true if organization is a direct parent of the specified organization
   * @param {Organization} parent Parent organization to check
   * @param {Organization} child Child organization to check
   * @returns {Boolean}
   */
  isParentOf ({ id: parentId }, { id: childId }) {
    const child = this.find(childId, EntityType.Organization)
    const parent = this.find(parentId, EntityType.Organization)
    return parent && child && parent.id === child.parentOrganizationId
  }

  /**
   * Returns true if organization is a child of the specified organization,
   * either directly or indirectly
   * @param {Organization} parent Parent organization to check
   * @param {Organization} child Child organization to check
   * @returns {Boolean}
   */
  isDescendantOf ({ id: childId }, { id: parentId }) {
    let child = this.find(childId, EntityType.Organization) || {}
    const parent = this.find(parentId, EntityType.Organization)
    while (parent && child.parentOrganization) {
      if (child.parentOrganizationId === parent.id) {
        return true
      } else {
        child = child.parentOrganization
      }
    }
  }

  /**
   * Returns true if organization is an ancestor of the specified organization,
   * either directly or indirectly
   * @param {Organization} parent Parent organization to check
   * @param {Organization} child Child organization to check
   * @returns {Boolean}
   */
  isAncestorOf ({ id: parentId }, { id: childId }) {
    return this.isDescendantOf({ id: childId }, { id: parentId })
  }

  /**
   * Re-creates hierarchy by assigning `parentOrganization` properties of children.
   * Useful after deserializing the instance from JSON.
   */
  recreateHierarchy () {
    for (const organization of this.organizations || []) {
      organization.parentOrganization = this
      organization.recreateHierarchy()
    }
  }

  /**
   * Overrides serialization to prevent circular JSON
   * with parentOrganization property
   * @returns {Object}
   */
  toJSON () {
    const result = { ...this, parentOrganization: undefined }
    return result
  }
}
