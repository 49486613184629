import { state, getters } from './device-settings.state'
import { mutations } from './device-settings.mutations'
import { actions } from './device-settings.actions'

export default {
  state,
  getters,
  mutations,
  actions
}
