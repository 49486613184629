import { sortItems } from '@stellacontrol/utilities'
import { createState } from './service-management.state'

export const mutations = {
  /**
   * Initializes service management data applicable to current organization
   * @param {OrganizationHierarchy} customers Customer hierarchy of the current organization, if reseller
   * @param {Wallet} wallet Wallet of the current organization
   * @param {Boolean} isBank Indicates that current organization is a bank,
   * with ability to freely create and distribute tokens to its customers
   * @param {Boolean} isCustomer Indicates that current organization is a customer
   * able to subscribe to premium services
   * @param {Boolean} isReseller Indicates that current organization is a reseller
   * able to sell premium service subscription to its customers
   * @param {Array[Pricelist]} pricelists Pricelists
   * @param {Array[Device]} devices Devices available to the current organizations
   */
  initializeServiceManagement (state, { customers, wallet, isBank, isCustomer, isReseller, pricelists, devices } = {}) {
    state.customers = customers
    state.wallet = wallet
    state.isBank = isBank
    state.isCustomer = isCustomer
    state.isReseller = isReseller
    state.pricelists = pricelists
    state.devices = devices.reduce((all, device) => {
      if (!all[device.ownerId]) {
        all[device.ownerId] = []
      }
      all[device.ownerId].push(device)
      return all
    }, {})

    state.isInitialized = true
  },

  /**
   * Updates data in service management applet
   * @param customers Customer hierarchy of the current organization, if reseller
   */
  updateServiceManagement (state, { customers } = {}) {
    if (customers !== undefined) {
      state.customers = customers
    }
  },

  /**
   * Stores pricelists of the current organization
   * @param pricelists Pricelists to store
   */
  storePricelists (state, { pricelists } = {}) {
    // Sort by name, bring the main one to the top though
    sortItems(pricelists, pricelist => pricelist.isMain ? '' : pricelist.name)
    state.pricelists = pricelists
  },

  /**
   * Stores a pricelist
   * @param pricelist Pricelist to store
   */
  storePricelist (state, { pricelist } = {}) {
    const { pricelists } = state
    const { id } = pricelist

    // If pricelist is marked as main, unmark others
    if (pricelist.isMain) {
      for (const p of pricelists) {
        p.isMain = false
      }
    }

    // Add or update
    const index = pricelists.findIndex(p => p.id === id)
    if (index > -1) {
      pricelists.splice(index, 1)
    }
    pricelists.push(pricelist)

    // Sort by name, bring the main one to the top though
    sortItems(pricelists, pricelist => pricelist.isMain ? '' : pricelist.name)
  },

  /**
   * Removes a pricelist
   * @param pricelist Pricelist to remove
   */
  removePricelist (state, { pricelist } = {}) {
    const { pricelists } = state
    const { id } = pricelist

    const index = pricelists.findIndex(p => p.id === id)
    if (index > -1) {
      state.pricelists.splice(index, 1)
    }
  },

  /**
   * Stores changes to my wallet
   * @param wallet Wallet of the current organization
   */
  storeMyWallet (state, { wallet } = {}) {
    state.wallet = wallet
  },

  /**
   * Stores wallet of the specified customer
   * @param wallet Wallet to store
   * @param customer Customer owning the wallet
   * @param customerGuardian Security guardian of the customer
   * @param devices Devices owned by the customer
   */
  storeCustomerWallet (state, { customer, wallet } = {}) {
    if (customer && wallet) {
      const c = state.customers?.toList().find(c => c.id === customer.id)
      if (c) {
        c.balance = wallet.balance
        if (c.wallet) {
          c.wallet.balance = wallet.balance
        }
      }
    }
  },

  /**
   * Resets the state to original shape.
   */
  resetServiceManagement (state) {
    Object.assign(state, createState())
  },

  /**
   * Removes devices from specified premium subscription
   * @param {PremiumServiceSubscription} subscription Premium subscription
   * @param {Array[Devices]} devices Devices to remove from premium subscription
   * @returns {PremiumServiceSubscription} Modified subscription
   */
  unsubscribeDevices (state, { subscription: { id } = {}, devices = [] } = {}) {
    if (id && devices && devices.length > 0 && state.wallet) {
      const subscription = state.wallet.subscriptions.find(s => s.id === id)
      if (subscription) {
        for (const device of devices) {
          subscription.removeDevice(device)
        }
      }
    }
  },

  /**
   * Triggered when premium service has been updated
   * @param premiumService Premium service to store
   */
  storePremiumService (state, { premiumService } = {}) {
    const { pricelist } = state
    if (pricelist && premiumService) {
      // Assign service to current price list
      premiumService.pricelistId = pricelist.id

      // Add or replace the modified service
      const index = pricelist.services.findIndex(s => s.id === premiumService.id)
      if (index > -1) {
        pricelist.services.splice(index, 1, premiumService)
      } else {
        pricelist.services.push(premiumService)
      }
    }
  },

  /**
   * Clears free-of-charge premium services currently assigned to specified devices
   * @param {Array[Device]} devices Devices to clear the service from
   */
  clearPremiumServicesOnDevices (state, { devices } = {}) {
    if (devices && devices.length > 0 && state.devices) {
      for (const devices of Object.values(state.devices)) {
        for (const device of devices) {
          if (devices.find(d => d.id === device.id)) {
            device.clearPremiumService()
          }
        }
      }
    }
  },

  /**
   * Resets the state to original shape.
   * @description
   * Presence of this mutation is obligatory on stores,
   * to ensure purging of any user-bound data when
   * user logs in and out and in. Otherwise expect
   * unexpected side effects with data of the previously
   * logged in user appearing at places.
   */
  reset (state) {
    Object.assign(state, createState())
  }
}
