import { parseDate, formatDate, sortItemsBy, Period, getPeriod, addPeriod } from '@stellacontrol/utilities'
import { Assignable } from '../common/assignable'
import { differenceInDays, startOfDay, endOfDay, addDays } from 'date-fns'
import { PremiumService } from './premium-service'

/**
 * Represents a subscription to a premium service,
 * using tokens from customer's wallet
 */
export class PremiumServiceSubscription extends Assignable {
  constructor (data = {}) {
    super()

    this.assign(
      {
        ...data,
        price: data.price || 0,
        createdAt: data.createdAt || new Date(),
        updatedAt: data.updatedAt || new Date(),
        startsAt: data.startsAt === undefined ? new Date() : data.startsAt,
        devices: data.devices || [],
        description: data.description || '',
        freeOfCharge: data.freeOfCharge || false
      },
      {
        createdAt: parseDate,
        updatedAt: parseDate,
        startsAt: date => date ? startOfDay(parseDate(date)) : undefined,
        expiresAt: date => date ? endOfDay(parseDate(date)) : undefined,
        expirationConfirmedAt: parseDate,
        startNotifiedAt: parseDate,
        expirationNotifiedAt: parseDate,
        comingExpirationNotifiedAt: parseDate,
        freeOfCharge: Boolean
      }
    )

    const { duration, durationUnit, startsAt, expiresAt, service } = this
    if (service && service.period > 0 && startsAt) {
      if (duration) {
        // Calculate expiry date if not specified, but duration is known
        this.expiresAt = addPeriod(startsAt, durationUnit, duration * service.period)
      } else if (expiresAt) {
        // Calculate duration as multiply of service period units,
        // if not specified explicitly, but expiry date is known
        const period = getPeriod(startsAt, expiresAt, durationUnit)
        this.duration = Math.ceil(period / service.period)
      }
    } else {
      this.duration = 0
    }

    // If subscription is not yet expired, clear eventual expiration flag
    if (!this.isExpired) {
      this.expirationConfirmedAt = undefined
    }
  }


  normalize () {
    super.normalize()
    this.service = this.cast(this.service, PremiumService)
  }

  /**
   * Subscription identifier
   * @type {String}
   */
  id

  /**
   * Identifier of the wallet from which the subscribed service has been paid
   * @type {String}
   */
  walletId

  /**
   * Identifier of the parent organization (installer or reseller)
   * @type {String}
   */
  resellerId

  /**
   * Identifier of the subscribed service
   * @type {String}
   */
  serviceId

  /**
   * Subscribed service
   * @type {PremiumService}
   */
  service

  /**
   * Human-friendly description of the subscription
   */
  description

  /**
   * Additional notes
   */
  notes

  /**
   * Subscription label - either custom description or default service name
   */
  get label () {
    const { description, service: { code, label = '' } = {} } = this
    return description
      ? `${code ? `${code} / ${description}` : description}`
      : label
  }

  /**
   * Subscription short label
   */
  get shortLabel () {
    const { service: { code, name = '' } = {} } = this
    return code || name
  }

  /**
   * Identifiers of devices to which the subscription is attached
   * @type {String}
   */
  devices

  /**
   * Serial number of the device covered by subscription.
   * Returned when subscription is for one device only.
   * @type {String}
   */
  get serialNumber () {
    const { devices } = this
    return devices.length === 1 ? devices[0] : null
  }

  /**
   * Returns true, if per-device subscription
   */
  get isPerDevice () {
    const { service } = this
    return service && service.isPerDevice
  }

  /**
   * Returns true, if per-device subscription and some devices are assigned to it
   */
  get hasDevices () {
    const { devices, service } = this
    return service && service.isPerDevice && devices && devices.length > 0
  }

  /**
   * Returns true, if per-device subscription and assigned to just one device
   */
  get hasOneDevice () {
    const { devices, service } = this
    return service && service.isPerDevice && devices && devices.length === 1
  }

  /**
   * Returns true, if subscription is per-device and the specified
   * device is included in it
   * @param serialNumber Device serial number
   */
  forDevice ({ serialNumber }) {
    const { devices, hasDevices } = this
    return hasDevices ? devices.includes(serialNumber) : false
  }

  /**
   * Adds device to subscription
   * @param {Device} device Device to add
   * @returns {Array[String]} Subscribed devices
   */
  addDevice ({ serialNumber } = {}) {
    if (serialNumber) {
      const { devices, service } = this
      if (service.isPerDevice && !devices.includes(serialNumber)) {
        devices.push(serialNumber.trim())
      }
    }
    return this.devices
  }

  /**
   * Adds devices to subscription
   * @param {Array[Device]} devices Devices to add
   * @returns {Array[String]} Subscribed devices
   */
  addDevices (devices = []) {
    if (devices) {
      for (const device of devices) {
        this.addDevice(device)
      }
    }
    return this.devices
  }

  /**
   * Removes device from subscription
   * @param {Device} device Device to remove
   * @returns {Array[String]} Subscribed devices
   */
  removeDevice ({ serialNumber } = {}) {
    if (serialNumber) {
      const { devices, service } = this
      if (service.isPerDevice) {
        const index = devices.indexOf(serialNumber)
        if (index > -1) {
          devices.splice(index, 1)
        }
      }
    }
    return this.devices
  }

  /**
   * Removes devices from subscription
   * @param {Array[Device]} devices Devices to remove
   * @returns {Array[String]} Subscribed devices
   */
  removeDevices (devices = []) {
    if (devices) {
      for (const device of devices) {
        this.removeDevice(device)
      }
    }
    return this.devices
  }

  /**
   * Removes all devices from subscription
   * @returns {Array[String]} Subscribed devices
   */
  removeAllDevices () {
    this.devices = []
    return this.devices
  }

  /**
   * Date and time when subscription has been created
   * @type {Date}
   */
  createdAt

  /**
   * Identifier of a user which subscribed to the service
   * @type {String}
   */
  createdBy

  /**
   * Date and time when subscription has been updated
   * @type {Date}
   */
  updatedAt

  /**
   * Identifier of a user which updated the subscription
   * @type {String}
   */
  updatedBy

  /**
   * Subscription start date.
   * @type {Date}
   */
  startsAt

  /**
   * Subscription duration, measured in service periods.
   * For example, if service is sold per-month, this will be
   * a number of subscribed months. If service is sold per-year,
   * this will be the number of years etc.
   */
  duration

  /**
 * Indicates a time unit for the {@link duration} property.
 * @type {Period}
 */
  get durationUnit () {
    const { service } = this
    return service ? service.periodUnit : undefined
  }

  /**
   * Returns true if service is billed per day
   * @type {Boolean}
   */
  get isPerDay () {
    return this.durationUnit === Period.Day
  }

  /**
   * Returns true if service is billed per month
   * @type {Boolean}
   */
  get isPerMonth () {
    return this.durationUnit === Period.Month
  }

  /**
   * Returns true if service is billed per year
   * @type {Boolean}
   */
  get isPerYear () {
    return this.durationUnit === Period.Year
  }

  /**
   * Assigned date when subscription will expire.
   * If not specified, this is a permanent subscription which never expires.
   * @type {Date}
   */
  expiresAt

  /**
   * Date when subscription expiration has been confirmed.
   * @type {Date}
   */
  expirationConfirmedAt

  /**
   * Date when notification about subscription start
   * has been sent to subscribers
   * @type {Date}
   */
  startNotifiedAt

  /**
   * Date when notification about imminent subscription expiration
   * has been sent to subscribers
   * @type {Date}
   */
  comingExpirationNotifiedAt

  /**
   * Date when notification about subscription expired
   * has been sent to subscribers
   * @type {Date}
   */
  expirationNotifiedAt

  /**
   * Returns true if subscription is charged periodically
   * @type {Boolean}
   */
  get isChargedPeriodically () {
    return Boolean(this.duration && this.durationUnit)
  }

  /**
   * Indicates that this premium subscription was charged only once and
   * it remains enabled forever, as opposed to subscriptions charged periodically,
   * which require renewal at expiration date.
   * @type {Boolean}
   */
  get neverExpires () {
    return !(this.expiresAt || this.duration > 0)
  }

  /**
   * Number of days which have passed since the subscription has expired
   * @type {Number}
   */
  get expiredDays () {
    const { isExpired, expiresAt } = this
    if (isExpired) {
      return differenceInDays(startOfDay(new Date()), expiresAt)
    }
  }

  /**
   * Number of days remaining until the subscription expires
   * @type {Number}
   */
  get expiresIn () {
    const { isExpired, expiresAt } = this
    const now = endOfDay(new Date())
    if (isExpired && expiresAt >= now) {
      return differenceInDays(expiresAt, now)
    }
  }

  /**
   * Indicates that premium subscription will become expired by the time the specified date comes
   * @param {Date} date Date to check for subscription expiration
   * @type {Boolean}
   */
  willExpireBy (date) {
    const { isExpired, expiresAt } = this
    return date && !isExpired && expiresAt && expiresAt <= endOfDay(date)
  }

  /**
   * Indicates that premium subscription will expire within the specified number of days
   * @param {Number} days Number of days to check whether the subscription will expire after
   * @type {Boolean}
   */
  willExpireWithin (days) {
    if (days >= 0) {
      const date = addDays(new Date(), days)
      return this.willExpireBy(date)
    }
  }

  /**
   * Returns true if premium service is free
   * @type {Boolean}
   */
  get isFree () {
    return this.price === 0
  }

  /**
   * Indicates whether subscription is currently active.
   * It is, if today is past the start date, while expiration date is in future -
   * or subscription is permanent and never expires
   * @type {Boolean}
   * @description This is a three-state property. When start date isn't specified yet,
   * if will return undefined rather than true
   */
  get isActive () {
    const { startsAt, expiresAt } = this
    const now = new Date()
    if (startsAt && startsAt <= now) {
      return expiresAt ? (expiresAt > now) : true
    }
  }

  /**
   * Indicates whether subscription is past expiration date
   */
  get isExpired () {
    const { expiresAt } = this
    const now = new Date()
    return expiresAt ? (expiresAt <= now) : false
  }

  /**
   * Indicates whether subscription is not just expired but also noticed as expired
   */
  get isExpirationConfirmed () {
    const { isExpired, expirationConfirmedAt } = this
    return isExpired && expirationConfirmedAt != null
  }

  /**
   * Returns true if subscription is not yet active
   * @type {Boolean}
   * @description This is a three-state property. When start date isn't specified yet,
   * if will return undefined rather than false
   */
  get isNotActive () {
    const { startsAt, expiresAt } = this
    const now = new Date()
    if (startsAt) {
      if (startsAt > now) return true
      return expiresAt ? (expiresAt < now) : false
    }
  }

  /**
   * Indicates whether subscription has been started.
   * Notice that this is not equivalent of subscription being active!
   * If start date is in future, the subscription will still be seen as inactive.
   * @type {Boolean}
   */
  get isStarted () {
    return Boolean(this.startsAt)
  }

  /**
   * Indicates whether subscription does not have start time specified.
   * @type {Boolean}
   */
  get isNotStarted () {
    return !this.startsAt
  }

  /**
   * Indicates whether subscription is not yet active,
   * because the time has not come yet.
   * @type {Boolean}
   */
  get startsInFuture () {
    return this.startsAt && this.startsAt > (new Date())
  }

  /**
   * Starts the subscription from the specified date
   * @param date Start date
   */
  start (startsAt = new Date()) {
    const { service, duration, durationUnit } = this
    if (service) {
      this.startsAt = startsAt
      if (startsAt && service.isChargedPeriodically && duration > 0) {
        this.expiresAt = addPeriod(startsAt, durationUnit, duration * service.period)
      }
    }
  }

  /**
   * Stops the subscription
   */
  stop () {
    this.startsAt = undefined
    this.expiresAt = undefined
  }

  /**
   * Returns label describing subscription activation and duration status
   */
  get statusLabel () {
    const now = new Date()
    const { isActive, isNotActive, isStarted, isNotStarted, isExpired, neverExpires, startsAt, expiresAt, duration, periodDescription } = this

    if (isActive) {
      return neverExpires
        ? `Started on ${formatDate(startsAt)}, never expires`
        : `Started on ${formatDate(startsAt)}, expires on ${formatDate(expiresAt)}`
    } else if (isExpired) {
      return `Started on ${formatDate(startsAt)}, expired on ${formatDate(expiresAt)}`
    } else if (isNotActive && isStarted) {
      if (startsAt > now) {
        return neverExpires
          ? `Will start on ${formatDate(startsAt)}`
          : `Will start on ${formatDate(startsAt)}, expires on ${formatDate(expiresAt)}`
      } else {
        return neverExpires
          ? `Started on ${formatDate(startsAt)}`
          : `Started on ${formatDate(startsAt)}, expires on ${formatDate(expiresAt)}`
      }
    } else if (isNotStarted) {
      return duration > 0
        ? `Not started yet, ${periodDescription}`
        : 'Not started yet'
    } else {
      return ''
    }
  }

  /**
   * Total price paid for the subscription for its entire duration, in tokens
   * @type {Number}
   */
  price

  /**
   * Indicates that this particular subscription has been given free of charge,
   * even if the service is a paid service
   */
  freeOfCharge

  /**
   * Calculates total subscription price,
   * optionally for the specified devices
   * @param {PremiumService} service Service to subscribe to
   * @param {Number} duration Subscription duration, number of service subscription periods (days, months, years etc.)
   * @param {Number} deviceCount Number of devices included in the subscription
   */
  static calculatePrice (service, duration = 0, deviceCount = 0) {
    if (service) {
      const multiplier = service.isPerDevice ? deviceCount : 1
      const price = service.neverExpires
        ? service.price * multiplier
        : service.price * duration * multiplier
      return price
    }
    else {
      return 0
    }
  }

  /**
   * User-friendly price description
   * @type {Number}
   */
  get priceDescription () {
    const { price } = this
    return price === 0
      ? 'free of charge'
      : `${price} token${price === 0 ? '' : 's'}`
  }

  /**
   * Human-friendly description subscription duration
   */
  get periodDescription () {
    const { service, duration } = this
    return service
      ? service.getPeriodDescription(service.period * (duration || 0), service.periodUnit)
      : ''
  }

  /**
   * Spent time for the subscription, in period units
   * @type {Number}
   */
  get spent () {
    const { startsAt, expiresAt, durationUnit } = this
    const now = new Date()
    if (startsAt && expiresAt && startsAt <= now) {
      return now <= expiresAt
        ? getPeriod(startsAt, now, durationUnit)
        : getPeriod(startsAt, expiresAt, durationUnit)
    }
  }

  /**
   * Remaining time for the subscription, in period units
   * @type {Number}
   */
  get remaining () {
    const { duration, spent, neverExpires } = this
    if (!neverExpires) {
      return (duration != null && spent != null) ? (duration - spent) : undefined
    }
  }

  /**
   * Checks if specified premium feature
   * is included in this subscription
   * @param name Feature name
   * @param serialNumber Optional device serial number. If subscription is for per-device service,
   * the device must be listed in the list of subscription's devices.
   */
  isSubscribedTo (name, serialNumber) {
    const { devices = [], service: { isPerDevice, features = [] } = {} } = this
    if (features.includes(name)) {
      return (isPerDevice && serialNumber) ? devices.includes(serialNumber) : true
    }
    return false
  }

  /**
   * Checks whether subscription has the same parameters
   * as the specified one
   * @param {PremiumServiceSubscription} subscription Subscription to compare to
   */
  isIdentical (subscription) {
    if (subscription) {
      const { serviceId, startsAt, expiresAt } = subscription
      return this.serviceId === serviceId &&
        differenceInDays(this.startsAt, startsAt) === 0 &&
        (expiresAt
          ? (this.expiresAt && differenceInDays(this.expiresAt, expiresAt) === 0)
          : !this.expiresAt
        )
    }
  }

  /**
   * Finds a subscription in the specified list, with the same parameters
   * as the specified one
   * @param {Array[PremiumServiceSubscription]} subscriptions List of subscriptions to search
   * @param {PremiumServiceSubscription} subscription Subscription to match
   */
  static findIdentical (subscriptions = [], subscription) {
    if (subscription) {
      return subscriptions.find(s => s.isIdentical(subscription))
    }
  }

  /**
   * Overrides serialization to properly serialize empty dates
   */
  toJSON () {
    const result = {
      ...this
    }

    if (!this.startsAt) delete result.startsAt
    if (!this.expiresAt) delete result.expiresAt

    return result
  }

  /**
   * Finds latest active subscription in the specified list
   * @param {Array[PremiumServiceSubscription]} items Subscriptions to search through
   * @param {Boolean} activeOnly If true, only active subscriptions are considered while searching for the most recent subscription
   */
  static findRecent (items = [], activeOnly) {
    const sorted = sortItemsBy(
      activeOnly ? items.filter(s => !s.isExpired) : items,
      'createdAt',
      true
    )
    return sorted[0]
  }
}
