/**
 * Status of MEGA parameter in context of a specific MEGA version
 */
export const MegaParameterStatus = {
  /**
   * Parameter is present and valid in MEGA message of the specific version
   */
  Present: 'present',
  /**
   * Parameter is not present in MEGA message of the specific version
   */
  Absent: 'absent',
  /**
   * Parameter is present in MEGA message of the specific version
   * but should be deemed as obsolete. Look for `replacedBy` field
   * in the result of `getMegaParameter` to discover whether the parameter
   * has been replaced by another, newer MEGA parameter
   */
  Obsolete: 'obsolete',
  /**
   * Parameter is unknown
   */
  Unknown: 'unknown'
}

