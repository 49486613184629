import { ListViewMode, Confirmation, Notification } from '@stellacontrol/client-utilities'
import { SecurityAPI } from '@stellacontrol/client-api'
import { OrganizationProfile } from '@stellacontrol/model'
import { SecurityRules } from '@stellacontrol/security'
import { AppletRoute } from '../../router'

export const actions = {
  /**
   * Initialize the organization profiles store
   */
  async initializeApplet ({ dispatch }) {
    // Initialize lists
    await dispatch('initializeList', [
      { name: 'organization-profiles', viewMode: ListViewMode.Normal },
      { name: 'organizations-in-profile', sortBy: 'name', viewMode: ListViewMode.Normal }
    ])
  },

  /**
   * Retrieves organization profiles
   */
  async getOrganizationProfiles ({ commit, dispatch }) {
    await dispatch('loading')
    const organizationProfiles = await SecurityAPI.getOrganizationProfiles({ withPermissions: true })
    if (organizationProfiles) {
      commit('storeOrganizationProfiles', { organizationProfiles })
    }
    await dispatch('done')
  },

  /**
   * Retrieves organization profiles if not retrieved yet
   */
  async requireOrganizationProfiles ({ state, dispatch }) {
    if (!state.items) {
      await dispatch('getOrganizationProfiles')
    }
  },

  /**
   * Retrieves the specified organization profile with all details such as
   * organizations assigned to it, creator, updater etc.
   * @param id Profile identifier
   */
  async getOrganizationProfile ({ commit, dispatch }, { id } = {}) {
    if (!id) throw new Error('Organization profile identifier is required')

    await dispatch('loading')
    const organizationProfile = await SecurityAPI.getOrganizationProfile({
      id,
      withDetails: true,
      withParents: true
    })
    if (organizationProfile) {
      commit('storeOrganizationProfile', { organizationProfile })
    }
    await dispatch('done')
    return organizationProfile
  },


  /**
   * Checks whether the specified profile is owned by my organization
   * @param {OrganizationProfile} organizationProfile Organization profile to check
   */
  async isMyOrganizationProfile ({ getters }, { organizationProfile } = {}) {
    return organizationProfile && getters.isMyOrganizationProfile(organizationProfile)
  },

  /**
   * Creates a new organization profile
   * @param organizationProfile Initial properties of the organization profile
   */
  async newOrganizationProfile ({ dispatch, getters }, { organizationProfile } = {}) {
    await dispatch('loading')

    // Determine profile owner, if current organization is not a super organization
    const { currentOrganization } = getters
    const ownerId = currentOrganization.isSuperOrganization
      ? undefined
      : currentOrganization.id

    // Put owner's name in profile description, to reduce naming conflicts
    // when multiple resellers name their profiles the same way (Customer, Installer etc.)
    const description = currentOrganization.isSuperOrganization
      ? undefined
      : currentOrganization.name

    // Determine terms and conditions to use
    let termsAndConditionsId
    if (getters.termsAndConditionsApply) {
      const defaultTermsAndConditions = getters.termsAndConditions.items.find(terms => terms.isDefault) ||
        getters.termsAndConditions.items[0]
      termsAndConditionsId = (defaultTermsAndConditions || {}).id
    }

    const data = new OrganizationProfile({
      ...organizationProfile,
      ownerId,
      description,
      termsAndConditionsId
    })
    await dispatch('done')
    return data
  },

  /**
   * Checks whether the specified organization profile exists
   * @param name Organization profile name
   */
  async organizationProfileExists (_, { name } = {}) {
    if (name) {
      const { id, exists } = await SecurityAPI.organizationProfileExists({ name })
      return { id, exists }
    } else {
      return { exists: false }
    }
  },

  /**
 * Returns organizations assigned to the specified organization profile
 * @param {String} id Organization profile identifier
 * @param {Boolean} withPermissions If true, permissions of these organizations are retrieved
 * @returns {Promise<Array[Organization]>}
 */
  async getOrganizationProfileMembers (_, { id, withPermissions } = {}) {
    if (id) {
      const organizations = await SecurityAPI.getOrganizationProfileMembers({ id, withPermissions })
      return organizations
    }
  },

  /**
   * Shows a list of organization profiles where profiles can be added and edited
   */
  async editOrganizationProfiles ({ dispatch }) {
    await dispatch('gotoRoute', { name: AppletRoute.OrganizationProfiles })
  },

  /**
   * Edits a new organization profile
   */
  async createOrganizationProfile ({ dispatch }) {
    await dispatch('gotoRoute', { name: AppletRoute.OrganizationProfile, params: { id: 'new' } })
  },

  /**
   * Edits an existing organization profile
   * @param id Organization profile to edit
   */
  async editOrganizationProfile ({ dispatch }, { organizationProfile: { id }, tab } = {}) {
    if (!id) throw new Error('Organization profile identifier is required')

    await dispatch('gotoRoute', { name: AppletRoute.OrganizationProfile, params: { id }, query: { tab } })
  },

  /**
   * Edits a copy of an existing organization profile
   * @param {String} id Organization profile to copy
   */
  async copyOrganizationProfile ({ dispatch }, { organizationProfile: { id } } = {}) {
    if (!id) throw new Error('Organization profile identifier is required')

    await dispatch('gotoRoute', { name: AppletRoute.OrganizationProfile, params: { id }, query: { copy: true } })
  },

  /**
   * Saves an organization profile
   * @param organizationProfile Profile to save
   * @param silent If true, no notifications will be shown
   */
  async saveOrganizationProfile ({ commit, dispatch }, { organizationProfile, silent } = {}) {
    if (!organizationProfile) throw new Error('Organization profile is required')

    await dispatch('busy', { message: `Saving organization profile ${organizationProfile.fullName} ...`, data: organizationProfile, silent })
    organizationProfile = await SecurityAPI.saveOrganizationProfile({ organizationProfile })

    if (organizationProfile) {
      const { error, message } = organizationProfile
      if (error) {
        await dispatch('done')
        Notification.error({ message, details: 'Error saving organization profile', silent })

      } else {
        commit('storeOrganizationProfile', { organizationProfile })
        await dispatch('done', { message: `Organization profile ${organizationProfile.fullName} has been saved`, silent })
        return organizationProfile
      }
    }
  },

  /**
   * Removes an organization profile
   * @param confirm If true, user has to confirm
   * @param id Profile to remove
   * @param silent If true, no notifications will be shown
   */
  async removeOrganizationProfile ({ commit, dispatch, getters }, { confirm = true, organizationProfile: { id }, silent } = {}) {
    if (!id) throw new Error('Organization profile is required')

    const organizationProfile = await SecurityAPI.getOrganizationProfile({ id, withDetails: true })
    if (!organizationProfile) throw new Error(`Organization profile ${id} no longer exists`)

    const { currentUser } = getters
    const { fullName } = organizationProfile
    const { canDeleteOrganizationProfile, reason } = SecurityRules.canDeleteOrganizationProfile(currentUser, organizationProfile)
    if (!canDeleteOrganizationProfile) {
      Notification.error({ message: reason, silent })
      return
    }

    const yes = await Confirmation.ask({
      title: 'Delete',
      message: `Delete organization profile ${fullName}?`,
      confirm
    })

    if (yes) {
      await dispatch('busy', { message: `Deleting organization profile ${fullName} ...`, data: organizationProfile, silent })
      const result = await SecurityAPI.deleteOrganizationProfile({ organizationProfile })
      await dispatch('done', { message: `Organization profile ${fullName} has been removed`, silent })
      commit('removeOrganizationProfile', { organizationProfile })
      return result
    }
  }
}
