/**
 * Resolves a promise after the specified delay
 * @param {Number} time Time to hold yer horses, in milliseconds
 * @param {Function} callback Optional callback to call, once the time has passed. It can be an async function.
 * @returns {any} Result of the callback, if specified
 */
export function wait (time, callback) {
  if (time > 0) {
    return new Promise(resolve => {
      setTimeout(async () => {
        const result = callback
          ? await callback()
          : undefined
        resolve(result)
      }, time)
    })
  } else {
    return Promise.resolve()
  }
}

/**
 * Alias for {@link wait} function.
 * @param {Number} time Time to hold yer horses, in milliseconds
 * @param {Function} callback Optional callback to call, once the time has passed. It can be an async function.
 * @returns {any} Result of the callback, if specified
 */
export function delay (time, callback) {
  return wait(time, callback)
}
