<script>
import { delay } from '@stellacontrol/utilities'
import { DeviceAPI } from '@stellacontrol/client-api'

export default {
  props: {
    // Edited floor plan
    data: {
      type: Object,
      required: true
    },

    // Indicates that designer is now in full-screen mode
    isMaximizedView: {
      type: Boolean
    }
  },

  computed: {
    appletUrl () {
      const { url, client, key, pages } = DeviceAPI.configuration
      const { id } = this.data
      const floorPlanAPIUrl = `${url}/floor-plan`
      const params = new URLSearchParams()
      params.append('id', id)
      params.append('api-url', floorPlanAPIUrl)
      params.append('api-client', client)
      params.append('api-key', key)
      return `${pages.floorPlanDesigner}/index.html?${params.toString()}`
    }
  },

  methods: {
    normalView () {
      this.$emit('normal-view')
    },

    togglePalette () {
      this.$emit('toggle-palette')
    }
  },

  async created () {
    let initialized = false
    // Wait until the designer iframe is loaded, we need a few things from the inside ...
    while (!initialized) {
      const { designer } = this.$refs
      if (designer?.contentWindow?.API) {
        this.$emit('initialize', designer, designer.contentWindow.API)
        initialized = true
      } else {
        await delay(1000)
      }
    }
  }
}

</script>

<template>
  <div class="floor-plan-designer" :class="{ 'full-screen': isMaximizedView }">
    <div class="buttons" v-if="isMaximizedView">
      <q-btn label="Toggle Palette" dense unelevated class="q-mr-sm" @click="togglePalette()">
      </q-btn>
      <q-btn label="Normal view" dense unelevated @click="normalView()">
      </q-btn>
    </div>
    <iframe ref="designer" :src="appletUrl" scroll="auto">
    </iframe>
  </div>
</template>

<style lang='scss' scoped>
.floor-plan-designer {
  flex: 1;
  width: 100%;
  height: 100%;
  background-color: #fafafa;

  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }

  .buttons {
    position: absolute;
    right: 16px;
    top: 16px;
  }
}
</style>
