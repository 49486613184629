/**
 * Application sidebar state
 */
export const state = {
  // Application sidebar
  sidebar: {
    // If true, the sidebar is collapsed
    isCollapsed: false
  }
}

export const getters = {
  /**
   * Returns true if sidebar is collapsed
   */
  isSidebarCollapsed: state => state.sidebar.isCollapsed
}
