/**
 * Conditions evaluator
   * @description
   * The following operators are available in expression:
   *    or, alternatively ||
   *    and, alternatively &&
   *    not, alternatively ! or -
   *    only, to enforce exact check instead of the default `contains`
 */
export class Conditions {
  /**
   * Initializes the conditions evaluator
   * @param {String} expression Expression with condition to check against
   * @param {Boolean} caseInsensitive If true, checks are case insensitive
   * @returns {Array[ConditionValue]}
   */
  constructor (expression, caseInsensitive = true) {
    if (expression) {
      this.expression = expression
      this.items = this.parse(expression, caseInsensitive)
    }
  }

  /**
   * Expression
   * @type {String}
   */
  expression

  /**
   * Expression parsed into conditions and operators
   * @type {Array[Condition]}
   */
  items

  /**
   * Parses the specified expression
   * @param {String} expression Expression with condition to check against
   * @param {Boolean} caseInsensitive If true, checks are case insensitive
   * @returns {Array[ConditionValue]}
   */
  parse (expression, caseInsensitive = true) {
    expression = (expression || '').toString().trim()
    if (expression === '') return []

    // Extract all conditions in the expression, determine operator
    let items
    let operator
    if (expression.includes(' or ')) {
      items = expression.split(' or ').flatMap(condition => condition.split(' '))
      operator = 'or'
    } else if (expression.includes(' || ')) {
      items = expression.split(' || ').flatMap(condition => condition.split(' '))
      operator = 'or'
    } else if (expression.includes(' and ')) {
      items = expression.split(' and ').flatMap(condition => condition.split(' '))
      operator = 'and'
    } else if (expression.includes(' && ')) {
      items = expression.split(' && ').flatMap(condition => condition.split(' '))
      operator = 'and'
    } else {
      // Assume OR if values are separated with space
      items = expression.split(' ')
      operator = 'or'
    }

    // Determine which checks to use and which to negate
    let negate
    let check
    items = items
      .map(c => c.trim())
      .filter(c => c)
      .map(value => {
        value = value.trim()
        value = caseInsensitive ? value.toLowerCase() : value

        if (!check) {
          check = value === 'only' ? ConditionCheck.Equals : ConditionCheck.Contains
          if (value === 'only') return
        }

        if (!negate) {
          negate = value === '-' || value === '!' || value === 'not'
          if (negate) {
            return
          }
          negate = value[0] === '-' || value[0] === '!'
          if (negate) {
            value = value.substring(1).trim()
          }
        }

        const condition = { value, operator, check, negate }
        negate = false
        check = null
        return condition
      })
      .filter(c => c)

    return items
  }

  /**
   * Checks whether the specified text matches the given condition
   * @param {String} text Text to check
   * @param {String} expression Expression with condition to check against
   * @param {Boolean} caseInsensitive If true, checks are case insensitive
   * @returns {Boolean}
   * @description
   * The following operators are available in expression:
   *    or, alternatively ||
   *    and, alternatively &&
   *    not, alternatively ! -
   *    only
   */
  match (text, expression, caseInsensitive = true) {
    if (text == null) return false
    if (expression == null && !this.items) return false

    // If expression identical to checked text, just assume true
    if (expression == text) return true

    // If expression provided and is different, re-parse
    if (expression != null && this.expression !== expression) {
      this.items = this.parse(expression, caseInsensitive)
    }

    let matches = true
    text = text.toString().trim()
    text = caseInsensitive ? text.toLowerCase() : text

    for (const { value, operator, check, negate } of this.items) {
      switch (check) {
        case ConditionCheck.Equals:
          matches = text === value
          break
        case ConditionCheck.Contains:
          matches = text.includes(value)
          break
        default:
          throw new Error(`Unknown condition check [${check}]`)
      }

      if (negate) {
        matches = !matches
      }

      // If OR condition, just one match is enough
      if (matches && operator === ConditionOperator.Or) {
        break
      }

      // If AND conditions, just one mismatch is enough
      if (!matches && operator === ConditionOperator.And) {
        break
      }
    }

    return matches
  }
}

/**
 * Single condition
 */
export class Condition {
  constructor (data = {}) {
    Object.assign(this, data)
  }

  /**
   * Condition value
   * @type {String}
   */
  value

  /**
   * Condition operator
   * @type {ConditionOperator}
   */
  operator

  /**
   * If true, negate the result of the check
   * @type {Boolean}
   */
  negate
}

/**
 * Condition check types
 */
export const ConditionCheck = {
  Contains: 'contains',
  Equals: 'equals'
}

/**
 * Operators allowed in conditions
 */
export const ConditionOperator = {
  And: 'and',
  Or: 'or',
  Not: 'not',
  Only: 'only'
}
