/**
 * Generates a sequence of serial numbers
 * @param prefix Number prefix
 * @param start Start index
 * @param count Number of serials to generate
 * @param digits Number of digits in the numeric part of the serial number
 * @param suffix Number suffix
 */
export function generateSerialNumbers ({ prefix = '', start = 1, count = 1, digits = 1, suffix = '' } = {}) {
  const serialNumbers = []
  if (start >= 0 && count > 0 && digits > 0 && digits <= 10) {
    for (let i = start; i < start + count; i++) {
      const number = `${prefix}${i.toString().padStart(digits, '0')}${suffix}`
      serialNumbers.push(number)
    }
  }
  return serialNumbers
}
