<script>
import AlertConfigurationComponent from './alert-configuration-component'

export default {
  mixins: [
    AlertConfigurationComponent
  ],

  data () {
    return {
    }
  },

  computed: {
    maxMessagesPerDayIsModified () {
      return this.isModified(
        configuration => configuration.parameters.maxMessagesPerDay
      )
    },

    maxMessagesPerDayIsNotDefault () {
      return this.isNotDefault(
        configuration => configuration.parameters.maxMessagesPerDay,
        value => `Default value is ${value} status messages.`
      )
    },

    maxMessagesPerHourIsModified () {
      return this.isModified(
        configuration => configuration.parameters.maxMessagesPerHour
      )
    },

    maxMessagesPerHourIsNotDefault () {
      return this.isNotDefault(
        configuration => configuration.parameters.maxMessagesPerHour,
        value => `Default value is ${value} status messages.`
      )
    },

    maxMessagesPerMinuteIsModified () {
      return this.isModified(
        configuration => configuration.parameters.maxMessagesPerMinute
      )
    },

    maxMessagesPerMinuteIsNotDefault () {
      return this.isNotDefault(
        configuration => configuration.parameters.maxMessagesPerMinute,
        value => `Default value is ${value} status messages.`
      )
    }
  },

  created () {
  }
}
</script>

<template>
  <article>
    <section v-if="isEditable && advancedConfiguration" class="column items-start">
      <div>
        Specify the number of messages which, when exceeded, will trigger the alert.
        You can specify separate limits for the recent minute,
        recent hour and recent day. Each can be used to detect a different kinds of
        problems. For example, monitoring last minute can be used to detect
        the ping-pong anomaly. Monitoring last hour or day can be used to detect
        faulty devices which occasionally sends bursts of messages, or
        platform abuse by customers.
      </div>
      <div class="q-mt-md">
        Set limit to zero to ignore monitoring of alert for the specified period.
      </div>
    </section>

    <section v-if="isEditable && advancedConfiguration">
      <label
        :class="{ modified: maxMessagesPerDayIsModified, disabled: configuration.parameters.maxMessagesPerDay === 0 }">
        Messages Per Day
        <sc-hint size="20px">
          The alert will be raised when device has sent more than
          {{ configuration.parameters.maxMessagesPerDay }}
          status messages during the recent day.
          {{ maxMessagesPerDayIsNotDefault }}
        </sc-hint>
      </label>
      <div class="row items-center">
        <q-input :input-style="{ 'max-width': '150px' }"
          v-model.number="configuration.parameters.maxMessagesPerDay" type="number" dense outlined
          square debounce="500" :min="0" :max="1000000">
        </q-input>
      </div>
    </section>

    <section v-if="isEditable && advancedConfiguration">
      <label
        :class="{ modified: maxMessagesPerHourIsModified, disabled: configuration.parameters.maxMessagesPerHour === 0 }">
        Messages Per Hour
        <sc-hint size="20px">
          The alert will be raised when device has sent more than
          {{ configuration.parameters.maxMessagesPerHour }}
          status messages during the recent hour.
          {{ maxMessagesPerHourIsNotDefault }}
        </sc-hint>
      </label>
      <div class="row items-center">
        <q-input :input-style="{ 'max-width': '150px' }"
          v-model.number="configuration.parameters.maxMessagesPerHour" type="number" dense outlined
          square debounce="500" :min="0" :max="1000000">
        </q-input>
      </div>
    </section>

    <section v-if="isEditable && advancedConfiguration">
      <label
        :class="{ modified: maxMessagesPerMinuteIsModified, disabled: configuration.parameters.maxMessagesPerMinute === 0 }">
        Messages Per Minute
        <sc-hint size="20px">
          The alert will be raised when device sends more than
          {{ configuration.parameters.maxMessagesPerMinute }}
          messages per minute.
          {{ maxMessagesPerMinuteIsNotDefault }}
        </sc-hint>
      </label>
      <div class="row items-center">
        <q-input :input-style="{ 'max-width': '150px' }"
          v-model.number="configuration.parameters.maxMessagesPerMinute" type="number" dense outlined
          square debounce="500" :min="0" :max="1000000">
        </q-input>
      </div>
    </section>
  </article>
</template>

<style scoped lang="scss">
</style>