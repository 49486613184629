/**
 * Client state factory
 */
export function createState () {
  return {
    // Push message listeners
    pushListeners: {}
  }
}

/**
 * Client state
 */
export const state = createState()

export const getters = {
  // Returns push listener with the specified name
  getPushListener: state => name => state.pushListeners[name]
}
