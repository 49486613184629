import { Device } from '@stellacontrol/model'
import { APIClient } from './api-client'

/**
 * Legacy database API client
 */
export class LegacyAPIClient extends APIClient {
  /**
   * Returns API name served by this client
   */
  get name () {
    return 'Legacy'
  }

  /**
   * API is configured with the same settings as Devices API
   */
  get configurationName () {
    return 'devices'
  }

  /**
   * Retrieves all legacy organizations
   */
  async getOrganizations () {
    try {
      const method = 'get'
      const url = this.endpoint('legacy/organization')
      const { organizations } = await this.request({ method, url })
      return organizations
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Retrieves all customers of a legacy organization
   * @param {Number} id Legacy organization identifier
   * @param {Boolean} withDevices If true, also devices under the customers are returned
   */
  async getCustomersOf ({ id, withDevices }) {
    try {
      const method = 'get'
      const url = this.endpoint('legacy/organization', id, 'customer')
      const params = { withDevices }
      const { customers } = await this.request({ method, url, params })
      return customers
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Retrieves all buildings of a legacy organization
   * @param {Number} id Legacy organization identifier
   * @param {Boolean} withDevices If true, also devices within the buildings are returned
   */
  async getBuildingsOf ({ id, withDevices }) {
    try {
      const method = 'get'
      const url = this.endpoint('legacy/organization', id, 'building')
      const params = { withDevices }
      const { buildings } = await this.request({ method, url, params })
      return buildings
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Retrieves all devices of a legacy organization
   * @param {Number} id Legacy organization identifier
   */
  async getDevicesOf ({ id }) {
    try {
      const method = 'get'
      const url = this.endpoint('legacy/organization', id, 'device')
      const { devices } = await this.request({ method, url })
      return devices
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Imports a legacy device
   * @param device Device to import
   * @param organization Organization owning the devices
   */
  async importLegacyDevice ({ device, organization }) {
    try {
      const method = 'post'
      const url = this.endpoint('legacy/device/import')
      const data = { device, organization }
      const result = await this.request({ method, url, data })
      if (result && result.device) {
        result.device = this.asDevice(result.device)
      }
      return result
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Converts the specified data item
   * received from API to Device instance
   * @param item Data item
   * @returns Device instance initialized with the content of the data item
   */
  asDevice (item) {
    if (item) {
      return new Device(item)
    }
  }
}
