/**
 * Color sequences
 */
const Color = {
  black: '\x1b[30m',
  gray: '\x1b[37m',
  red: '\x1b[91m',
  green: '\x1b[92m',
  yellow: '\x1b[93m',
  blue: '\x1b[94m',
  magenta: '\x1b[95m',
  cyan: '\x1b[96m',
  white: '\x1b[97m'
}

/**
 * Special sequences
 */
const Special = {
  reset: '\x1b[0m'
}

/**
 * Replaces color tags in the specified text with {@link Color} sequences
 */
export function colorize (s) {
  if (s != null) {
    return Object
      .entries(Color)
      .reduce((result, [key, value]) => {
        const open = new RegExp(`<${key}>`, 'gmi')
        const close = new RegExp(`</${key}>`, 'gmi')
        return result != null
          ? result.toString()
            .replace(open, value)
            .replace(close, Special.reset)
          : ''
      }, s)
  }
}

/**
 * Removes color tags in the specified text
 */
export function decolorize (s) {
  if (s != null) {
    return Object
      .entries(Color)
      .reduce((result, [key]) => {
        const open = new RegExp(`<${key}>`, 'gmi')
        const close = new RegExp(`</${key}>`, 'gmi')
        return result != null
          ? result.toString()
            .replace(open, '')
            .replace(close, '')
          : ''
      }, s)
  }
}
