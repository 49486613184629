<script>
export default {
  props: {
    // Text label
    label: {
      type: String,
      required: true
    },
    // Items to select from: array of { label, icon, color, value }.
    // Alternatively, you can specify custom content using default slot.
    items: {
      type: Array
    },
    // Indicates whether an option for clearing the custom value should be displayed
    allowClear: {
      default: false
    }
  },

  methods: {
    // Notifies about user's selection
    async select (value) {
      this.$emit('select', value)
    },

    // Notifies about user clearing the current custom value
    clear () {
      this.$emit('clear')
    }
  }
}
</script>

<template>
  <q-popup-edit square :style="{ padding: 0 }" :cover="false" :model-value="items">

    <q-list dense class="items q-ma-sm">
      <q-item>
        <q-item-section>
          <q-item-label>
            <div class="row items-center text-bold q-pa-sm" v-html="label">
            </div>
          </q-item-label>
        </q-item-section>
      </q-item>

      <q-separator></q-separator>

      <slot>
      </slot>

      <q-item clickable v-close-popup
        v-for="(item, index) in items" :key="`parameter-value-${index}`" @click.stop="select(item.value)">
        <q-item-section side v-if="item.icon">
          <q-icon :name="item.icon" :color="item.color || 'indigo-5'" size="24px" />
        </q-item-section>
        <q-item-section>
          <q-item-label class="action-label q-pt-sm q-pb-sm">
            {{ item.label }}
          </q-item-label>
        </q-item-section>
      </q-item>

      <slot name="clear" v-if="allowClear">
        <q-separator></q-separator>

        <q-item clickable v-close-popup @click.stop="clear()">
          <q-item-section side>
            <q-icon name="replay" color="indigo-5" size="24px" />
          </q-item-section>
          <q-item-section>
            <q-item-label class="action-label q-pt-sm q-pb-sm">
              Clear custom value
            </q-item-label>
          </q-item-section>
        </q-item>
      </slot>

    </q-list>
  </q-popup-edit>
</template>

<style lang="scss" scoped>
.items {
  max-width: 270px;
}

.action-label {
  white-space: nowrap;
}
</style>
