<script>
import { mapGetters } from 'vuex'
import { OrganizationSortOrder, sortOrganizations } from '@stellacontrol/model'

export default {
  props: {
    // Organizations selected initially
    modelValue: {
      required: true
    },

    // List of organizations to select from
    organizations: {
    },

    // Hierarchy of organizations to select from, alternative to `organizations`.
    // If specified, organizations are displayed as hierarchy instead of flat list
    hierarchy: {
    },

    // If true, current organization will be included in the selector
    includeSelf: {
      default: false
    },

    // Sort order
    order: {
      default: OrganizationSortOrder.Name
    },

    // List is bordered
    bordered: {
      default: true
    },

    // Items are separated
    separator: {
      default: true
    },

    // List is dense
    dense: {
      default: false
    },

    // Tick strategy for the organization hierarchy
    // https://quasar.dev/vue-components/tree#tick-strategy
    tickStrategy: {
      default: 'leaf'
    },

    // Icon representing non-selected organization
    icon: {
      default: 'radio_button_unchecked'
    },

    // Icon representing selected organization
    iconSelected: {
      default: 'check_circle'
    },

    // Color representing non-selected organization
    iconColor: {
      default: 'gray-4'
    },

    // Color representing selected organization
    iconSelectedColor: {
      default: 'green-7'
    }
  },

  data () {
    return {
      // Current organization
      current: null,
      // Selected organizations
      selection: [],
      // Tree nodes
      nodes: [],
      // Organization filter
      filter: ''
    }
  },

  computed: {
    ...mapGetters([
      'currentOrganization'
    ]),

    // Indicates whether the specified organization is selected
    isOrganizationSelected () {
      return organization => this.selection.includes(organization.id)
    },

    // Organization icon
    organizationIcon () {
      return organization => this.isOrganizationSelected(organization)
        ? this.iconSelected
        : this.icon
    },

    // Organization icon color
    organizationIconColor () {
      return organization => this.isOrganizationSelected(organization)
        ? this.iconSelectedColor
        : this.iconColor
    },

    // List items
    items () {
      const { organizations, order } = this
      return sortOrganizations(organizations, order)
    },

    // Indicates whether all organizations have been selected
    allSelected () {
      if (this.hierarchy) {
        return this.hierarchy.toList().length === this.selection.length
      } else {
        return this.items.length === this.selection.length
      }
    }
  },

  methods: {
    // Populates the selector
    populate () {
      const { hierarchy, currentOrganization, includeSelf } = this

      if (hierarchy) {
        const createNode = ({ id, name, organizations }) => ({
          id,
          name,
          children: createNodes(organizations)
        })

        const createNodes = organizations =>
          sortOrganizations(organizations, OrganizationSortOrder.Rank, { currentOrganization })
            .map(o => createNode(o))

        const children = createNodes(hierarchy.organizations)
        this.nodes = includeSelf
          ? [createNode(currentOrganization), ...children]
          : children
      }
    },

    // Selects/deselects organization
    toggleOrganization (item) {
      if (this.isOrganizationSelected(item)) {
        this.selection = this.selection.filter(id => id !== item.id)
      } else {
        this.selection = [...this.selection, item.id]
      }
    },

    // Selects/deselects all organizations
    toggleAll (status) {
      if (status) {
        if (this.hierarchy) {
          this.selection = this.hierarchy.toList().map(item => item.id)
        } else {
          this.selection = this.items.map(item => item.id)
        }
      } else {
        this.selection = []
      }
    },

    // Filter function for free-text filtering of companies by name
    filterOrganizations (value = '', update) {
      update(() => {
        if (value === '') {
          this.matchingItems = [...this.allOrganizations]
        } else {
          const text = value.toLowerCase()
          this.matchingItems = this.allOrganizations.filter(o => o.name.toLowerCase().includes(text))
        }
      })
    }
  },

  watch: {
    // Report model change on selection
    selection () {
      this.$emit('update:model-value', this.selection)
    },

    // Expand the tree when filtering
    filter (value) {
      if (this.hierarchy && value?.trim()) {
        this.$refs.tree?.expandAll()
      }
    }
  },

  created () {
    this.selection = this.modelValue
    this.populate()
  }
}
</script>

<template>
  <main v-if="hierarchy" class="hierarchy">
    <header class="header row items-center q-pt-sm q-pb-sm q-pl-md q-pr-md">
      <div class="filter">
        <q-input borderless dense v-model="filter" label="Filter organizations"></q-input>
      </div>
      <div class="selector">
        <q-checkbox :model-value="allSelected" :label="allSelected ? 'Deselect All' : 'Select All'"
          @update:model-value="status => toggleAll(status)">
        </q-checkbox>
      </div>
    </header>
    <section class="q-pa-sm">
      <q-tree ref="tree" color="indigo-6" control-color="indigo-6" :nodes="nodes" node-key="id"
        label-key="name" children-key="children" no-connectors no-nodes-label=" " no-results-label=" "
        :duration="0" :default-expand-all="false" :tick-strategy="tickStrategy"
        v-model:selected="current" v-model:ticked="selection" :filter="filter">
      </q-tree>
    </section>
  </main>

  <main v-else>
    <q-list flat :bordered="bordered" :separator="separator" :dense="dense">
      <q-item v-for="item of items" clickable :active="isOrganizationSelected(item)"
        active-class="bg-indigo-1" @click="toggleOrganization(item)">
        <q-item-section side>
          <q-icon :name="organizationIcon(item)" :color="organizationIconColor(item)" size="sm">
          </q-icon>
        </q-item-section>
        <q-item-section>
          <q-item-label>
            {{ item.name }} {{ item.profile ? `(${item.profile.fullName})` : '' }}
          </q-item-label>
        </q-item-section>
      </q-item>
    </q-list>
  </main>
</template>

<style scoped lang="scss">
main {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  header {
    flex: 0;
    border-bottom: solid #0000001f 1px;
    display: flex;
    flex-direction: row;

    .filter {
      flex: 1;
    }

    .selector {
      flex: 0;
      white-space: nowrap;
    }
  }

  section {
    flex: 1;
    overflow: auto;
  }
}
</style>