<script>
import { distinctValues } from '@stellacontrol/utilities'
import { PlanItemType } from '@stellacontrol/planner'
import ItemProperties from './item-properties/item-properties.vue'
import RectangleProperties from './item-properties/rectangle-properties.vue'
import CircleProperties from './item-properties/circle-properties.vue'
import EllipseProperties from './item-properties/ellipse-properties.vue'
import CurveProperties from './item-properties/curve-properties.vue'
import PolygonProperties from './item-properties/polygon-properties.vue'
import LineProperties from './item-properties/line-properties.vue'
import TextProperties from './item-properties/text-properties.vue'
import DeviceProperties from './item-properties/device-properties.vue'
import CableProperties from './item-properties/cable-properties.vue'
import SplitterProperties from './item-properties/splitter-properties.vue'
import AntennaProperties from './item-properties/antenna-properties.vue'
import BeamProperties from './item-properties/beam-properties.vue'
import PlugProperties from './item-properties/plug-properties.vue'

export default {
  components: {
    'scp-item-properties': ItemProperties,
    'scp-rectangle-properties': RectangleProperties,
    'scp-circle-properties': CircleProperties,
    'scp-ellipse-properties': EllipseProperties,
    'scp-curve-properties': CurveProperties,
    'scp-polygon-properties': PolygonProperties,
    'scp-line-properties': LineProperties,
    'scp-text-properties': TextProperties,
    'scp-device-properties': DeviceProperties,
    'scp-cable-properties': CableProperties,
    'scp-splitter-properties': SplitterProperties,
    'scp-antenna-properties': AntennaProperties,
    'scp-beam-properties': BeamProperties,
    'scp-plug-properties': PlugProperties
  },

  props: {
    // Plan renderer
    renderer: {
      required: true
    },
    // Items to edit
    items: {
    }
  },

  computed: {
    // Determines the editor for the edited items
    editor () {
      const { items } = this
      if (items) {
        const defaultEditor = 'scp-item-properties'
        const editors = {
          [PlanItemType.Rectangle]: 'scp-rectangle-properties',
          [PlanItemType.Circle]: 'scp-circle-properties',
          [PlanItemType.Ellipse]: 'scp-ellipse-properties',
          [PlanItemType.Curve]: 'scp-curve-properties',
          [PlanItemType.Polygon]: 'scp-polygon-properties',
          [PlanItemType.Line]: 'scp-line-properties',
          [PlanItemType.Connector]: 'scp-line-properties',
          [PlanItemType.Cable]: 'scp-line-properties',
          [PlanItemType.Text]: 'scp-text-properties',
          [PlanItemType.Device]: 'scp-device-properties',
          [PlanItemType.Cable]: 'scp-cable-properties',
          [PlanItemType.Splitter]: 'scp-splitter-properties',
          [PlanItemType.Antenna]: 'scp-antenna-properties',
          [PlanItemType.Beam]: 'scp-beam-properties',
          [PlanItemType.Plug]: 'scp-plug-properties'
        }
        const types = distinctValues(items, 'type')
        if (types.length === 1) {
          return editors[types[0]] || defaultEditor
        } else {
          return defaultEditor
        }
      }
    },

    // Indicates whether properties can be edited for the edited items
    canEditProperties () {
      const { items, editor } = this
      return items?.length > 0 &&
        items.every(item => item.canEditProperties) &&
        editor != null
    }
  },

  emits: [
    'action'
  ],

  methods: {
    // Passes the action requested in the properties
    executeAction (details) {
      this.$emit('action', details)
    }
  }
}

</script>

<template>
  <div class="item-properties" v-if="canEditProperties">
    <component :is="editor" :renderer="renderer" :items="items" @action="executeAction">
    </component>
  </div>
</template>

<style lang="scss" scoped>
.item-properties {
  padding: 15px;
  font-size: 13px;
}
</style>
