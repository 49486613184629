<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { getDeviceLabel, getPlaceDescription } from '@stellacontrol/model'
import { ViewMixin } from '@stellacontrol/client-utilities'
import { Secure } from '@stellacontrol/security-ui'
import { DeviceCommands, DefaultDeviceCommands } from '@stellacontrol/devices'
import { isMegaParameterApplicable } from '@stellacontrol/mega'
import { DashboardWidgets } from './widgets'
import { resolve } from './device-dashboard.resolve'

const name = 'device-dashboard'

export default {
  mixins: [
    ViewMixin,
    Secure
  ],

  components: {
    ...DashboardWidgets
  },

  data () {
    return {
      name,
      // Indicates whether the dashboard is initialized
      isInitialized: false,
      // Loading message
      loadingMessage: '',
      // Indicates whether fast sampling has been turned on by the user
      fastSamplingActive: false,
      fastSamplingCounter: 30,
      // Most recent message counters of the selected device
      counters: null,
      // Available device commands
      DeviceCommands,
      DefaultDeviceCommands,
      // Push client
      pushClient: null
    }
  },

  computed: {
    ...mapState({
      // Current user
      user: state => state.security.currentUser,
      // Currently viewed organization
      organization: state => state.deviceDashboard.organization,
      // Viewed organization's guardian
      organizationGuardian: state => state.deviceDashboard.organizationGuardian,
      // Viewed place
      place: state => state.deviceDashboard.place,
      // Currently viewed device
      device: state => state.deviceDashboard.device,
      // Status of the currently viewed device
      status: state => state.deviceStatus.devices[state.deviceDashboard.device?.serialNumber],
      // Currently viewed device part
      devicePart: state => state.deviceDashboard.devicePart,
      // Pending premium service associated with device
      pendingPremiumService: state => state.deviceDashboard.pendingPremiumService,
    }),

    ...mapGetters([
      'isSmallScreen',
      'environment',
      'availableDeviceCommands',
      'getDeviceStatus',
      'getStatusWatchSettings',
    ]),

    // Indicates that we're adding a place in another organization, not ours
    inAnotherOrganization () {
      return this.organization.id !== this.currentOrganization.id
    },

    // Dashboard title
    title () {
      return getDeviceLabel(this.device)
    },

    // View breadcrumbs
    breadcrumbs () {
      const { organization: viewedOrganization, place, device, getViewTitle } = this

      if (!(viewedOrganization && place && device)) return []

      const breadcrumbs = [
        {
          name: 'home',
          title: getViewTitle('home')
        },
        {
          name: 'installations',
          title: getViewTitle('installations'),
          route: 'installations'
        },
        {
          name: 'installations',
          title: viewedOrganization.name,
          route: 'installations',
          query: {
            filter: viewedOrganization.name
          }
        },
        {
          name: 'building-dashboard',
          title: getPlaceDescription(place),
          route: 'building-dashboard',
          params: {
            organizationId: viewedOrganization.id,
            id: place.id
          }
        },
        {
          name: 'device-dashboard',
          title: getDeviceLabel(device)
        }
      ]

      return breadcrumbs
    },

    // Checks whether viewed device's live status can be monitored.
    // If user is on paid plan, this required an active subscription for alerts.
    isLiveStatusAllowed () {
      const { currentOrganizationGuardian, organizationGuardian, device, isMultiDevice, hasParts } = this
      if (currentOrganizationGuardian && device) {
        if (isMultiDevice && !hasParts) {
          return false
        }
        if (currentOrganizationGuardian.requiresPremiumSubscription('live-status')) {
          return organizationGuardian.canDeviceUse('live-status', device.serialNumber, currentOrganizationGuardian)
        } else {
          return currentOrganizationGuardian.canUse('live-status')
        }
      }
    },

    // Indicates that we have to do with a multi-board device
    isMultiDevice () {
      const { device } = this
      return device?.isMultiDevice
    },

    // Indicates multi-board device has any parts
    hasParts () {
      const { device, deviceParts } = this
      return device?.isMultiDevice && deviceParts.length > 0
    },

    // Indicates whether the currently selected device is the main multi-device
    isMultiDeviceSelected () {
      return this.isMultiDevice && this.selectedDevice?.serialNumber === this.device?.serialNumber
    },

    // Indicates whether the currently selected device is a part of a multi-device
    isMultiDevicePartSelected () {
      return this.isMultiDevice && Boolean(this.selectedDevice?.partOf)
    },

    // Physical components of the displayed device
    deviceParts () {
      const { device } = this
      if (device) {
        return device.isMultiDevice
          ? device.parts
          : [device]
      } else {
        return []
      }
    },

    // Currently selected device or device part
    selectedDevice () {
      return this.devicePart || this.device
    },

    // Indicates whether the device or any of its parts has ever connected to report the status
    hasAnyPartConnected () {
      const status = this.getDeviceStatus(this.selectedDevice)
      return status?.hasAnyPartConnected
    },

    // Returns true if device status is available
    hasStatus () {
      return Boolean(this.status?.connection?.status)
    },

    // Indicates whether the selected device part has ever connected to report the status
    hasSelectedDeviceConnected () {
      const status = this.getDeviceStatus(this.selectedDevice)
      return status?.hasDeviceConnected
    },

    // Status watch settings for the current view
    statusWatchSettings () {
      return this.getStatusWatchSettings(name)
    },

    // Indicates that device is editable for the current user, commands can be sent to it etc.
    // Device is not editable when:
    // - it's only shared with user's organization
    // - organization is a guest organization
    // - user is a guest user
    isEditable () {
      const { device, currentOrganization, currentUser } = this
      return !(device?.isShared || currentOrganization.isGuestOrganization || currentUser.isGuestUser)
    },

    // Indicates that viewed device is test tool
    isTestTool () {
      return this.device?.isTestTool
    },

    // Indicates whether user can send commands
    // to the selected device
    canSendCommands () {
      if (this.device) {
        if (this.isEditable && this.hasAnyPartConnected && this.isLiveStatusAllowed) {
          if (this.isMultiDevicePartSelected) {
            return this.availableDeviceCommands([this.selectedDevice], DefaultDeviceCommands)
          } else {
            return this.availableDeviceCommands(this.deviceParts, DefaultDeviceCommands)
          }
        }
      }
    },

    // Indicates whether user can configure the currently selected device
    canConfigure () {
      const { device, isEditable, currentOrganizationGuardian, organizationGuardian } = this
      if (device && currentOrganizationGuardian) {
        const yes = device &&
          isEditable &&
          this.canUseChildrenOf('device-configuration')
        if (yes) {
          return currentOrganizationGuardian.requiresPremiumSubscription('live-status')
            ? organizationGuardian.canDeviceUse('live-status', device.serialNumber, currentOrganizationGuardian)
            : currentOrganizationGuardian.canUse('live-status')
        }
      }
    },

    // Indicates whether temperature widget should be displayed
    canSeeTemperature () {
      const { isLiveStatusAllowed, hasStatus, canUse } = this
      return isLiveStatusAllowed && hasStatus && canUse('device-temperature')
    },

    // Indicates whether message counters widget should be displayed
    canSeeCounters () {
      const { isLiveStatusAllowed, hasStatus, canUse, counters } = this
      return isLiveStatusAllowed &&
        hasStatus &&
        canUse('device-counters') &&
        (counters?.day != null ||
          counters?.hour != null ||
          counters?.minute != null)
    },

    // Indicates whether battery widget should be displayed
    canSeeBattery () {
      const { isLiveStatusAllowed, hasStatus, canUse } = this
      return isLiveStatusAllowed && hasStatus && canUse('device-battery')
    },

    // Indicates whether premium subscriptions widget should be displayed
    canSeePremiumSubscriptions () {
      const { hasStatus, isMultiDevicePartSelected, organizationGuardian } = this
      return hasStatus && !isMultiDevicePartSelected && organizationGuardian?.mustUse('premium-services-buy')
    },

    // Indicates whether firmware update widget should be displayed
    canSeeFirmwareUpdates () {
      const { hasStatus, hasFirmwareUpdate, canUse } = this
      return hasStatus && canUse('device-management-firmware') && hasFirmwareUpdate
    },

    // Indicates whether PortSense widget should be displayed
    canSeePortSense () {
      const { hasStatus, device, status } = this
      return hasStatus && isMegaParameterApplicable('status_portsense', device, status)
    },

    // Indicates whether last alert widget should be displayed.
    // This is when:
    // - user is allowed to see live status of devices
    // - device is able to trigger alerts
    // - device owner has alert subscription on this device
    // - viewer organization has alerts permission
    canSeeLastAlert () {
      const { isLiveStatusAllowed, hasStatus, selectedDevice, organizationGuardian, currentOrganizationGuardian } = this
      if (selectedDevice && currentOrganizationGuardian) {
        return isLiveStatusAllowed &&
          hasStatus &&
          selectedDevice &&
          selectedDevice.canTriggerAlerts &&
          organizationGuardian.canDeviceUse('alerts', selectedDevice.serialNumber, currentOrganizationGuardian)
      }
    },

    // Indicates whether flags widget should be displayed.
    // Normally it's only visible on DEV and BETA, and only for super administrators.
    canSeeFlags () {
      const { device, isSuperAdministrator, environment, viewConfiguration: { showDeviceFlagsOn }, hasStatus } = this
      if (device) {
        const canSeeFlags = isSuperAdministrator &&
          device.isConnectedDevice &&
          device.flags &&
          showDeviceFlagsOn.includes(environment) &&
          hasStatus
        return canSeeFlags
      }
    },

    // Returns true if device parameters widget is available
    canSeeDeviceParameters () {
      return this.hasStatus && this.canUse('device-parameters')
    },

    // Returns true if TT usage widget is available
    canSeeTTUsage () {
      return this.hasStatus && this.device?.isTestTool && this.status?.health?.ttUsage != null
    },

    // Returns true if device bands widget is available
    canSeeBands () {
      return this.hasStatus && this.canUse('device-bands') && !this.isTestTool
    },

    // Returns true if device band details widget is available
    canSeeBandDetails () {
      return this.hasStatus && this.canUse('device-db-table') && !this.isTestTool
    },

    // Returns true if notes widget is available
    canSeeNotes () {
      return this.hasStatus &&
        (this.canUse('edit-device-notes') || this.device?.hasNotesOf(this.currentUser))
    },

    // Indicates whether the device has a firmware update to show
    hasFirmwareUpdate () {
      const { selectedDevice: { updateStatus } } = this
      return updateStatus?.inProgress || updateStatus?.isScheduled
    },

    // Indicates whether current user can change device region on this device.
    canSeeRegion () {
      if (this.isInitialized) {
        const { canUse, device } = this
        return canUse('device-management-region-change') &&
          device?.place?.hasRegion
      }
    },

    // Indicates whether user can navigate to inventory and see the selected device there
    canViewInventory () {
      return this.isInitialized && this.canUse('inventory')
    },

    // Indicates whether history is available for this device
    canViewHistory () {
      if (this.isInitialized) {
        const { currentOrganizationGuardian, organizationGuardian, selectedDevice: device } = this
        if (device && device.isConnectedDevice && !device.isMultiDevice) {
          return currentOrganizationGuardian.requiresPremiumSubscription('history-graph')
            ? organizationGuardian.canDeviceUse('history-graph', device.serialNumber, currentOrganizationGuardian)
            : currentOrganizationGuardian.canUse('history-graph')
        }
      }
    },

    // Whether the device can enter fast-sampling mode
    canFastSample () {
      return this.canSendCommands && this.isLiveStatusAllowed
    },
  },

  methods: {
    ...mapActions([
      'gotoRoute',
      'populateDeviceDashboard',
      'showDialog',
      'hideDialog',
      'gotoDeviceDashboard',
      'gotoDevicePartDashboard',
      'peekLiveStatus',
      'watchDeviceStatus',
      'unwatchDeviceStatus'
    ]),

    getDeviceLabel,

    // Populates the dashboard
    async populate () {
      await this.watchStatus()
      this.loadingMessage = ''
      this.isInitialized = true
    },

    // Starts watching device status
    async watchStatus () {
      if (!this.isLiveStatusAllowed) {
        return
      }

      const devices = this.deviceParts
      const fastSampling = this.fastSamplingActive
      const peekStatus = !this.fastSamplingActive
      await this.watchDeviceStatus({ name, devices, fastSampling, peekStatus })
    },

    // Stops watching device status
    async unwatchStatus () {
      await this.unwatchDeviceStatus({ name })
    },

    // Launches dialog for configuring devices
    async showConfigurationDialog () {
      const { selectedDevice: { serialNumber } = {} } = this
      if (serialNumber) {
        await this.showDialog({ dialog: 'device-configuration', data: { serialNumber } })
      }
    },

    async hideConfigurationDialog () {
      this.hideDialog({ dialog: 'device-configuration' })
    },

    // Shows history of the current device
    async showHistoryDialog () {
      await this.showDialog({ dialog: 'device-history', data: { device: this.selectedDevice } })
    },

    // Toggles fast sampling mode
    async toggleFastSamplingMode () {
      this.fastSamplingActive = !this.fastSamplingActive
      await this.watchStatus()
    },

    // Stops fast sampling mode
    async stopFastSamplingMode () {
      this.fastSamplingActive = false
    },

    // Navigates to the specified device part or to the main device
    async selectDevice (serialNumber) {
      const { device } = this
      if (device.serialNumber === serialNumber) {
        this.gotoDeviceDashboard({ device })
      } else {
        this.gotoDevicePartDashboard({ device, part: serialNumber })
      }
    },

    // Closes the dashboard
    close () {
      this.gotoRoute({
        name: 'building-dashboard',
        params: {
          organizationId: this.organization.id,
          id: this.place.id
        }
      })
    }
  },

  watch: {
    // When status is received, update the counters,
    // but keep the recent ones if the new status doesn't have any
    status () {
      this.counters = this.status?.counters || this.counters
    }
  },

  // First visit
  async beforeRouteEnter (to, from, next) {
    next(async vm => {
      vm.populate()
    })
  },

  // Reload data on navigation to another device
  async beforeRouteUpdate (to, from, next) {
    this.isInitialized = false
    this.loadingMessage = 'Loading ...'

    await this.hideConfigurationDialog()
    await this.stopFastSamplingMode()
    await this.unwatchStatus()

    const { redirectTo } = await resolve({ to, from })
    if (redirectTo) {
      next(redirectTo)

    } else {
      await this.populate()
    }
  },

  // Stop all status subscriptions before leaving the route
  async beforeUnmount () {
    this.hideConfigurationDialog()
    this.stopFastSamplingMode()
    this.unwatchStatus()
  }
}

</script>

<template>
  <sc-view :name="name" :title="title" :breadcrumbs="breadcrumbs">

    <!-- Desktop mode toolbar -->
    <template #toolbar>
      <div v-if="isInitialized" class="toolbar q-gutter-sm">
        <q-btn unelevated :label="fastSamplingActive ? 'Stop Fast Sampling' : 'Start Fast Sampling'"
          :icon="fastSamplingActive ? 'stop' : 'play_arrow'" :ripple="false"
          :class="fastSamplingActive ? 'success' : undefined" @click="toggleFastSamplingMode()"
          v-if="hasAnyPartConnected && isLiveStatusAllowed">
        </q-btn>

        <q-btn-dropdown label="Commands" unelevated icon="wifi_tethering" :ripple="false"
          v-if="canSendCommands">
          <sc-device-commands :show-header="false" :commands="DefaultDeviceCommands"
            :devices="isMultiDevicePartSelected ? [selectedDevice] : deviceParts">
          </sc-device-commands>
        </q-btn-dropdown>

        <q-btn label="Configure" unelevated icon="settings" @click="showConfigurationDialog()"
          :ripple="false" v-if="canConfigure"></q-btn>

        <q-btn label="Open in Inventory" unelevated icon="list_alt" :ripple="false"
          v-if="canViewInventory"
          :to="{ name: 'inventory', query: { selection: selectedDevice.serialNumber, filterBySelection: true } }">
          <sc-tooltip>
            Open {{ getDeviceLabel(selectedDevice) }} in the inventory
          </sc-tooltip>
        </q-btn>

        <q-btn label="Show History" unelevated icon="timeline" :ripple="false" v-if="canViewHistory"
          @click="showHistoryDialog()">
          <sc-tooltip>
            Show history of {{ getDeviceLabel(selectedDevice) }}
          </sc-tooltip>
        </q-btn>
      </div>
    </template>

    <!-- Small screen toolbar -->
    <teleport v-if="isInitialized && isSmallScreen" to="#topbar-items">
      <span class="device-label q-mr-sm text-white">
        {{ getDeviceLabel(device) }}
      </span>

      <q-space>
      </q-space>

      <div class="row items-center no-wrap q-gutter-sm">
        <q-btn outline style="color: white" icon="arrow_back" @click="close()">
        </q-btn>
      </div>
    </teleport>

    <!-- Dashboard content -->
    <main class="device-dashboard">
      <main v-if="isInitialized && selectedDevice" class="content">
        <sc-tabs v-if="isMultiDevice" :model-value="selectedDevice?.serialNumber"
          @update:model-value="serialNumber => selectDevice(serialNumber)">
          <q-tab :name="device.serialNumber" icon="router" :label="getDeviceLabel(device)"
            :ripple="false"></q-tab>
          <q-tab v-for="part in deviceParts" :name="part.serialNumber" icon="memory"
            :label="`${part.model}: ${part.serialNumber} (${part.modelRegion})`" :ripple="false">
          </q-tab>
        </sc-tabs>

        <div class="rows">
          <template :device="device" v-if="isMultiDevice">
            <!-- Multi-device details -->
            <div class="multi-device q-gutter-md">
              <div class="box">
                <div class="header">
                  <sc-widget-device-info :device="device" :isEditable="isEditable"
                    :hasActions="false">
                  </sc-widget-device-info>
                </div>
                <div class="boards">
                  <sc-widget-device-card v-for="part in device.parts" dense :masterDevice="device"
                    :device="part" :place="place" :organization="organization"
                    :isEditable="isEditable" :isSelectable="false" :canComment="false"
                    :cardLink="getPartLink(part)">
                  </sc-widget-device-card>
                </div>
                <q-icon v-if="canEditComments || device.comments" class="device-comments"
                  size="22px" :name="device.comments ? 'comment' : 'mode_comment'"
                  :color="device.comments ? 'indigo-6' : 'grey-5'" @click.stop>

                  <sc-tooltip :text="breakLines(device.comments) || 'Click to add notes ...'">
                  </sc-tooltip>

                  <q-popup-edit v-if="canEditComments" buttons self="bottom left" label-set="Save"
                    :model-value="device.comments" v-slot="scope"
                    :title="`Enter comments for ${device.acronym} ${device.serialNumber}`"
                    @save="comments => setDeviceComments({ device, comments })">
                    <q-input type="textarea" autofocus max-length="1000" v-model="scope.value"
                      @keydown.enter.stop @keypress.enter.stop @keyup.enter.stop>
                    </q-input>
                  </q-popup-edit>
                </q-icon>
              </div>

              <sc-widget-device-subscriptions :device="device"
                :pendingPremiumService="pendingPremiumService" :organization="organization"
                :organizationGuardian="organizationGuardian" :isEditable="isEditable"
                v-if="organizationGuardian.mustUse('premium-services-buy')">
              </sc-widget-device-subscriptions>

            </div>
          </template>

          <!-- Device widgets -->
          <div class="row" v-if="!isMultiDevice || isMultiDevicePartSelected">
            <sc-widget-device-info :device="selectedDevice" :isEditable="isEditable">
            </sc-widget-device-info>
            <sc-widget-device-status :device="selectedDevice" :isEditable="isEditable" :name="name"
              :isLiveStatusAllowed="isLiveStatusAllowed">
            </sc-widget-device-status>
            <sc-widget-device-portsense :device="selectedDevice" :isEditable="isEditable"
              v-if="canSeePortSense">
            </sc-widget-device-portsense>
            <sc-widget-device-region :device="selectedDevice" :isEditable="isEditable"
              v-if="canSeeRegion">
            </sc-widget-device-region>
            <sc-widget-device-temperature :device="selectedDevice" :isEditable="isEditable"
              v-if="canSeeTemperature">
            </sc-widget-device-temperature>
            <sc-widget-device-battery :device="selectedDevice" :isEditable="isEditable"
              v-if="canSeeBattery">
            </sc-widget-device-battery>
            <sc-widget-device-alerts :device="selectedDevice" :isEditable="isEditable"
              v-if="canSeeLastAlert">
            </sc-widget-device-alerts>
            <sc-widget-firmware-update :device="selectedDevice" v-if="canSeeFirmwareUpdates">
            </sc-widget-firmware-update>
            <sc-widget-device-subscriptions :device="selectedDevice"
              :pendingPremiumService="pendingPremiumService" :organization="organization"
              :organizationGuardian="organizationGuardian" :isEditable="isEditable"
              v-if="canSeePremiumSubscriptions">
            </sc-widget-device-subscriptions>
            <sc-widget-device-flags :device="selectedDevice" :isEditable="isEditable" :name="name"
              :isLiveStatusAllowed="isLiveStatusAllowed" v-if="canSeeFlags">
            </sc-widget-device-flags>
            <sc-widget-device-counters :device="selectedDevice" :counters="counters"
              :isEditable="isEditable" v-if="canSeeCounters">
            </sc-widget-device-counters>
          </div>

          <div class="row" v-if="hasSelectedDeviceConnected && isLiveStatusAllowed">
            <sc-widget-device-bands :device="selectedDevice" :isEditable="isEditable"
              v-if="canSeeBands">
            </sc-widget-device-bands>
            <sc-widget-device-band-details :device="selectedDevice" :isEditable="isEditable"
              v-if="canSeeBandDetails">
            </sc-widget-device-band-details>
            <sc-widget-tt-usage :device="selectedDevice" :isEditable="isEditable"
              v-if="canSeeTTUsage">
            </sc-widget-tt-usage>
            <sc-widget-device-mega :device="selectedDevice" :isEditable="isEditable"
              v-if="canSeeDeviceParameters">
            </sc-widget-device-mega>
            <sc-widget-device-notes :device="selectedDevice" :isEditable="isEditable"
              v-if="canSeeNotes">
            </sc-widget-device-notes>
          </div>
        </div>

        <sc-band-selector-dialog></sc-band-selector-dialog>
        <sc-purchase-premium-service-dialog></sc-purchase-premium-service-dialog>
      </main>

      <main v-if="!isInitialized">
        <sc-busy :title="loadingMessage"></sc-busy>
      </main>
    </main>
  </sc-view>
</template>

<style lang="scss" scoped>
.device-dashboard {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  >.content {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0;
    overflow: hidden;
    background-color: #efefef;

    >.rows {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 16px;
      overflow: auto;
      background-color: #efefef;

      >.row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        >.widget {
          margin-right: 16px;
          margin-bottom: 16px;
        }
      }
    }

    .multi-device {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      >.box {
        background-color: white;
        padding: 8px 0 0 16px;
        position: relative;

        >.header {}

        >.device-comments {
          position: absolute;
          right: 4px;
          top: 4px;
          cursor: pointer;
        }

        >.boards {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          justify-content: flex-start;

          >.widget,
          >.device-card {
            margin-right: 16px;
            margin-bottom: 16px;
          }
        }
      }
    }
  }

}

/* Layout adjustments for screen below HD resolution */
@media screen and (max-width: 1365px) {
  .device-dashboard {
    .content {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 0;

      >.rows {
        padding: 4px;

        >.row {
          >.widget {
            margin-right: 4px;
            margin-bottom: 4px;
          }
        }
      }
    }
  }
}

/* Layout adjustments for small screens */
@media screen and (max-width: 1024px) {
  .device-label {
    font-size: 16px;
    text-wrap: wrap;
    margin-bottom: unset;
    margin-left: 8px;
    margin-right: 8px;
  }

  .device-dashboard {
    .content {
      >.rows {

        >.row {
          display: flex;
          flex-direction: column;
          margin: 4px;
          gap: 8px;

          >.widget {
            margin: 0;
          }
        }
      }
    }
  }
}
</style>
