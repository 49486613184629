<script>
import { getFullDurationString } from '@stellacontrol/utilities'
import AlertConfigurationComponent from './alert-configuration-component'

export default {
  mixins: [
    AlertConfigurationComponent
  ],

  computed: {
    noHeartbeatForIsModified () {
      return this.isModified(
        configuration => configuration.parameters.noHeartbeatFor
      )
    },

    noHeartbeatForMin () {
      return this.configuration.parameters.noHeartbeatForMin
    },

    noHeartbeatForMax () {
      return this.configuration.parameters.noHeartbeatForMax
    },

    noHeartbeatForIsNotDefault () {
      return this.isNotDefault(
        configuration => configuration.parameters.noHeartbeatFor,
        value => `Default value is ${this.duration(value)}.`
      )
    },

    ignoreOlderThanIsModified () {
      return this.isModified(
        configuration => configuration.parameters.ignoreOlderThan
      )
    },

    ignoreOlderThanIsNotDefault () {
      return this.isNotDefault(
        configuration => configuration.parameters.ignoreOlderThan,
        value => `Default value is ${this.duration(value)}.`
      )
    },

    // Intervals to select from
    intervals () {
      return this.configuration?.parameters?.noHeartbeatForIntervals?.map(f => ({
        value: f,
        label: getFullDurationString(f).replace('about', '')
      })) || []
    }
  },

  created () {
  }
}
</script>

<template>
  <article>
    <section :class="{ vertical: isEditable }">
      <label :class="{ modified: noHeartbeatForIsModified }">
        <span>
          Must be offline at least
        </span>
        <sc-hint
          :text="`The alert will be raised only when device has been offline for more than ${duration(configuration.parameters.noHeartbeatFor)}. ${noHeartbeatForIsNotDefault}`"
          size="20px">
        </sc-hint>
      </label>
      <div v-if="isEditable" class="q-mt-sm">
        <q-option-group dense inline v-model="configuration.parameters.noHeartbeatFor"
          :options="intervals" color="indigo-6">
        </q-option-group>
      </div>
      <div v-else>
        {{ duration(configuration.frequency) }}
      </div>
    </section>

    <section>
      <div>
        When device has been offline for longer than
        {{ duration(configuration.parameters.ignoreOlderThan) }},
        the alert will be ignored.
      </div>
    </section>

  </article>
</template>

<style scoped lang="scss"></style>