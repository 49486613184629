import PlaceEditor from './place-editor/place-editor.vue'
import PlaceSelector from './place-selector/place-selector.vue'
import DeviceCommands from './device-commands/device-commands.vue'

/**
 * Installs Device UI components into the application
 * @param application Vue application instance
 */
export function useDevicesComponents (application) {
  const plugin = {
    install (application) {
      const items = [
        {
          tag: 'sc-place-editor', component: PlaceEditor
        },
        {
          tag: 'sc-place-selector', component: PlaceSelector
        },
        {
          tag: 'sc-device-commands', component: DeviceCommands
        }
      ]

      for (const { tag, component } of items) {
        application.component(tag, component)
      }
    }
  }

  application.use(plugin)
}
