import { dispatch, dispatchParallel } from '@stellacontrol/client-utilities'

/**
 * Prepares the data required by premium-services view
 */
export async function resolve ({ from, to }) {
  // Get current organization's wallet and pricelists
  await dispatch('initializeServiceManagement', { force: false })

  await dispatchParallel([
    'requireOrganizations',
    'requirePricelists'
  ])

  const { service: id } = to.query

  const data = id
    ? (id === 'new'
      ? await dispatch('newPremiumService')
      : await dispatch('getPremiumService', { id, store: false })
    )
    : undefined

  if (!id || data) {
    await dispatch('setRouteData', { from, to, data })
    return true
  }
}
