<script>
import { mapActions, mapGetters } from 'vuex'
import { UserLevel, UserLevelNames, getUserIcon, getUserColor } from '@stellacontrol/model'
import { SecurityRules } from '@stellacontrol/security'
import { FormMixin } from '@stellacontrol/client-utilities'
import { Secure } from '../../components'

export default {
  mixins: [
    FormMixin,
    Secure
  ],

  props: {
    data: {
      type: Object,
      required: true
    },

    permissions: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters([
      'users'
    ]),

    // User levels to choose from
    levels () {
      return [
        this.level(UserLevel.Administrator),
        this.level(UserLevel.User),
        this.level(UserLevel.Guest),
        this.canUse('can-create-integration-accounts') ? this.level(UserLevel.Integration) : undefined
      ].filter(p => p)
    },

    // Can change level only if also allowed to edit user's permission.
    // This for example to prevent admin from downgrading himself to non-admin level
    canChangeLevel () {
      const { canEditPermissions } = SecurityRules.canEditPermissions(this.currentUser, this.data)
      return canEditPermissions
    },

    /**
     * Grantor of permissions
     */
    permissionsGrantedBy () {
      const permission = (this.data.permissions || [])[0]
      if (permission) {
        return this.users.find(user => user.id === permission.createdBy)
      }
    },

    /**
     * Date when permissions were granted
     */
    permissionsGrantedOn () {
      return ((this.data.permissions || [])[0] || {}).createdAt
    },

    /**
     * Determines whether user data is read-only for the viewer.
     * Cannot edit yourself, for example.
     */
    isReadOnly () {
      const { id: currentId, isAdministrator, isSuperAdministrator } = this.currentUser
      const { id, isIntegration } = this.data

      if (id === currentId) return true

      if (isIntegration) {
        return !isSuperAdministrator
      } else {
        return !isAdministrator
      }
    },

    // Determines whether user account can be enabled.
    // If it was disabled by another organization, only that organization
    // can re-enable the user again
    canEnableDisable () {
      const { currentOrganization, data: { isEnabled, disabledBy, disabler } } = this
      if (!isEnabled && disabledBy && disabler) {
        return currentOrganization.isSuperAdministrator ||
          disabler.organizationId == currentOrganization.id
      } else {
        return true
      }
    }
  },

  methods: {
    ...mapActions([
      'userExists'
    ]),

    getUserIcon,
    getUserColor,

    // Returns an option for level select input
    level (value, options) {
      return { value, label: UserLevelNames[value], ...options }
    },

    // Validation rule which checks whether the specified user name is already in use
    async userNameIsUnique (name) {
      if (this.data.isNew) {
        const { id, exists } = await this.userExists({ name })
        const isUnique = !exists || id === this.data.id
        return isUnique || 'User with this name already exists'
      } else {
        return true
      }
    },

    // Profile level has changed.
    // Reinitialize the permissions, because
    // some features might have just become available or unavailable.
    levelChanged (level) {
      this.data.level = level
      this.permissions.initialize()
      // Automatically grant all permissions to admin users
      if (level === UserLevel.Administrator) {
        this.permissions.setCanUseAll(true)
      }
      if (this.data.isNew && level === UserLevel.Integration) {
        this.data.isEnabled = true
      }
    }
  },

  created () {
  }
}

</script>

<template>
  <q-form class="user" ref="form" autofocus>
    <q-banner v-if="!data.isEnabled && !canEnableDisable" class="banner-account-locked bg-orange-5">
      User account has been locked. To unlock the account, please contact your reseller.
    </q-banner>
    <div class="row">
      <div class="col-12 col-md-9 col-lg-6 q-pa-md q-gutter-sm">
        <q-input class="input-name" v-model="data.name" dense square outlined debounce="1000"
          maxlength="255" hide-bottom-space
          lazy-rules
          :readonly="isReadOnly || !data.isNew"
          :rules="[
            rules.required('User e-mail is required'),
            rules.isEmail('User name must be a valid e-mail address'),
            name => userNameIsUnique(name)
          ]">
          <template v-slot:before> <label>User E-Mail</label> </template>
          <template v-slot:after>
            <sc-hint text="User e-mail will be used to log in to the application." />
          </template>
        </q-input>

        <q-input class="input-description" v-model="data.description" dense square outlined maxlength="255" hide-bottom-space
          :readonly="isReadOnly">
          <template v-slot:before> <label>Description</label> </template>
          <template v-slot:after>
            <sc-hint text="Detailed user description." />
          </template>
        </q-input>

        <q-select dense square outlined class="q-pb-none select-level" hide-bottom-space
          v-model="data.level"
          :readonly="isReadOnly"
          :options="levels"
          emit-value
          lazy-rules
          map-options
          :disable="!canChangeLevel"
          :rules="[
            rules.required('User level is required')
          ]"
          @update:model-value="value => levelChanged(value)">
          <template v-slot:before>
            <label>User Level</label>
          </template>
          <template v-slot:option="scope">
            <q-item v-bind="scope.itemProps">
              <q-item-section side>
                <q-icon :name="getUserIcon({ level: scope.opt.value })" :color="getUserColor({ level: scope.opt.value })" size="sm"></q-icon>
              </q-item-section>
              <q-item-section>
                <q-item-label v-html="scope.opt.label" />
              </q-item-section>
            </q-item>
          </template>
          <template v-slot:after>
            <sc-hint width="450px" text="Level determines which permissions can be granted to the user. For example Guest User can have less permissions than Regular User." />
          </template>
        </q-select>

        <q-field dense borderless class="label-level">
          <template v-slot:before>
            <label></label>
          </template>
          <label v-if="data.isAdministrator" class="text-grey-8">
            Administrator has full access to all application features available to the customer.
          </label>
          <label v-if="data.isRegularUser" class="text-grey-8">
            Regular users can use a selected set of application features permitted by Administrators.
          </label>
          <label v-if="data.isGuestUser" class="text-grey-8">
            Guest users can only look at devices. Gues users cannot change anything or send commands to devices.
          </label>
          <label v-if="data.isIntegration" class="text-grey-8">
            Integration accounts are used for system-to-system communications
            such as third-party integration or internal calls between modules.
            They are not permitted to log in to the user interface.
          </label>
        </q-field>

        <q-field dense borderless v-if="data.isAdministrator && (currentUser.isSuperAdministrator || currentUser.isPrimaryAdministrator)" class="toggle-enabled">
          <q-toggle v-model="data.isPrimary" color="green" :disable="isReadOnly"></q-toggle>
          <template v-slot:before> <label>Primary Administrator?</label> </template>
          <template v-slot:after>
            <sc-hint text="If there are more administrators under this customer, one must be marked as primary"></sc-hint>
          </template>
        </q-field>

        <q-input v-model="data.firstName" dense square outlined class="input-firstname q-mb-md" maxlength="45" hide-bottom-space
          :readonly="isReadOnly" v-if="!data.isIntegration">
          <template v-slot:before> <label>First Name</label> </template>
          <template v-slot:after> <sc-hint-placeholder /> </template>
        </q-input>

        <q-input v-model="data.lastName" dense square outlined class="input-lastname q-mb-md" maxlength="45" hide-bottom-space
          :readonly="isReadOnly" v-if="!data.isIntegration">
          <template v-slot:before> <label>Last Name</label> </template>
          <template v-slot:after> <sc-hint-placeholder /> </template>
        </q-input>

        <q-input v-model="data.phone" dense square outlined class="input-phone q-mb-md" maxlength="45" hide-bottom-space
          :readonly="isReadOnly" v-if="!data.isIntegration">
          <template v-slot:before> <label>Phone Number</label> </template>
          <template v-slot:after> <sc-hint-placeholder /> </template>
        </q-input>

        <q-field dense borderless class="toggle-enabled">
          <q-toggle v-model="data.isEnabled" color="green" :disable="isReadOnly || !canEnableDisable"></q-toggle>
          <template v-slot:before> <label>Is Active?</label> </template>
          <template v-slot:after>
            <sc-hint text="Deactivated users are not allowed to log into the system." />
          </template>
        </q-field>

        <q-field dense borderless v-if="!data.isNew" class="label-created">
          <template v-slot:before> <label>Created</label> </template>
          <div class="row items-center">
            {{ data.createdText }}
          </div>
        </q-field>

        <q-field dense borderless v-if="!data.isNew" class="label-updated">
          <template v-slot:before> <label>Updated</label> </template>
          <div class="row items-center">
             {{ data.updatedText }}
          </div>
        </q-field>

        <q-field dense borderless v-if="!data.isNew && permissionsGrantedBy" class="label-permissions">
          <template v-slot:before> <label>Permissions granted</label> </template>
          <div class="row items-center">
             {{ datetime(permissionsGrantedOn) }}, {{ permissionsGrantedBy.fullName }}
          </div>
        </q-field>
      </div>
    </div>
  </q-form>
</template>

<style lang='scss' scoped>
.user {
  max-width: 1200px;
}

label {
  font-size: 14px;
  min-width: 180px;
}
</style>
