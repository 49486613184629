import { differenceInDays, startOfDay, endOfDay, subDays } from 'date-fns'
import { createState } from './alerts-view.state'
import { Conditions } from '@stellacontrol/utilities'
import { AlertStatistics, AlertStatisticsPart, AlertStatisticsPartKey } from '@stellacontrol/model'

/**
 * Stores the alert statistics filter in state
 * @param {String} organizationId Identifier of organization whose alert statistics to return
 * @param {Date} from Period start
 * @param {Date} until Period end
 * @param {Boolean} all Include all child organizations in the statistics
 * @param {AlertStatisticsPart} part Alert statistics part to show
 */
export function filterAlertStatistics (state, { organizationId, from, until, all, part } = {}) {
  const filter = state.statisticsFilter

  filter.organizationId = organizationId || filter.organizationId
  filter.from = startOfDay(from || filter.from)
  filter.until = endOfDay(until || filter.until)

  if (filter.until < filter.from) {
    filter.until = filter.from
  }

  if (differenceInDays(filter.until, filter.from) > state.statisticsFilter.maximalPeriod) {
    filter.from = startOfDay(subDays(filter.until, state.statisticsFilter.maximalPeriod))
  }

  filter.all = all == undefined ? filter.all : Boolean(all)

  if (part != null && part !== state.part) {
    state.part = part
    state.partLoaded = false
  }
}

/**
 * Filters the details of alert statistics
 * @param {AlertStatisticsPart} part Alert statistics part
 * @param {String} filter Filter to apply
 */
export function filterAlertStatisticsDetails (state, { part, filter } = {}) {
  if (part) {
    if (state.part !== part) {
      state.part = part
      state.partLoaded = false
    }
    const normalizedFilter = (filter || '').trim().toLowerCase()
    state.statisticsFilter.detailsFilter[part] = normalizedFilter
    state.statisticsFilter.detailsConditions[part] = new Conditions(normalizedFilter)
  }
}


/**
 * Stores the alert statistics in state
 * @param {Array[AlertStatistics]} statistics Alert statistics
 * @param {Boolean} isRefreshing Indicates that the call was due to autorefresh,
 * thus keep the details intact while they're being refreshed soon
 */
export function storeAlertStatistics (state, { statistics, isRefreshing } = {}) {
  const existingDetails = state.mergedStatistics?.details
  state.error = null
  state.statistics = statistics
  state.mergedStatistics = AlertStatistics.merge(statistics)
  if (isRefreshing) {
    state.mergedStatistics.details = existingDetails
  }
  state.refresh.clock?.reset()
}

/**
 * Stores the details of alert statistics in state
 * @param {Array[AlertStatistics]} statistics Alert statistics
 * @param {AlertStatisticsPart} part Alert statistics part
 */
export function storeAlertStatisticsDetails (state, { statistics, part } = {}) {
  if (part && statistics && state.statistics) {
    state.part = part

    for (const item of statistics) {
      const update = state.statistics.find(i => i.organization.id === item.organization.id)
      if (update) {
        const key = AlertStatisticsPartKey[part]
        update.counters[key] = item.counters[key]
        update.details[key] = item.details[key]

        // If alert details were retrieved, update device details accordingly,
        // as they're just a derivation of alerts
        if (part === AlertStatisticsPart.Alerts) {
          const devices = item.details.alerts.reduce((all, alert) => {
            if (!all[alert.serialNumber] || all[alert.serialNumber].time < alert.time) {
              all[alert.serialNumber] = { ...alert, id: undefined }
            }
            return all
          }, {})
          update.details.devices = Object.values(devices)
          update.counters.devices = update.details.devices.length
        }
      }
    }

    state.error = null
    state.mergedStatistics = AlertStatistics.merge(state.statistics)

    if (!state.partLoaded) {
      setTimeout(() => {
        state.partLoaded = true
      }, 1500)
    }

    state.refresh.clock?.reset()
  }
}

/**
 * Stores the error caught during loading the alert statistics
 * @param {Error} error Error
 */
export function storeAlertStatisticsError (state, { error } = {}) {
  state.error = error
}

/**
 * Clears the last error caught during loading the alert statistics
 * @param {Error} error Error
 */
export function clearAlertStatisticsError (state) {
  state.error = null
}

/**
 * Stores the currently viewed part of alert statistics
 * @param {AlertStatisticsPart} part Alert statistics part
 */
export function storeAlertStatisticsPart (state, { part } = {}) {
  if (state.part !== part) {
    state.part = part
  }
}

/**
 * Starts/stops automatic refreshing of the currently viewed alert statistics
 * @param {Clock} clock Running clock. Don't specify to clear the existing clock.
 * @param {Number} frequency Refresh frequency, in seconds
 */
export function refreshAlertStatistics (state, { clock, frequency } = {}) {
  if (state.refresh.clock) {
    state.refresh.clock.stop()
  }
  if (clock && frequency) {
    state.refresh.clock = clock
    state.refresh.frequency = frequency
    state.refresh.countdown = frequency
    // keep the countdown of the 'refresh' alert
    clock.addEventListener('tick', () => {
      state.refresh.countdown = clock.getAlert('refresh').countdown
      state.refresh.isRefreshing = clock.getAlert('refresh').isBusy
    })
  } else {
    state.refresh.clock = null
    state.refresh.countdown = 0
    state.refresh.isRefreshing = false
  }
}

/**
 * Pauses automatic refreshing of the currently viewed alert statistics
 */
export function suspendRefreshingAlertStatistics (state) {
  state.refresh.clock?.suspend()
  state.refresh.clock?.reset()
}

/**
 * Resumes automatic refreshing of the currently viewed alert statistics
 */
export function resumeRefreshingAlertStatistics (state) {
  state.refresh.clock?.resume()
}

/**
 * Resets the filters
 * @description
 * Presence of this mutation is obligatory on stores,
 * to ensure purging of any user-bound data when
 * user logs in and out and in or on any other occasions.
 * Otherwise expect unexpected side effects with data of the previously
 * logged in user appearing at places.
 */
export function reset (state) {
  Object.assign(
    state,
    createState(),
    // Keep the filter between navigations
    {
      statisticsFilter: {
        ...state.statisticsFilter
      }
    }
  )
}
