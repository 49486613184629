<script>
import { FormValidationRules as rules } from '@stellacontrol/client-utilities'
import AlertConfigurationComponent from './alert-configuration-component'

export default {
  mixins: [
    AlertConfigurationComponent
  ],

  data () {
    return {
      rules
    }
  },

  computed: {
    min () {
      return this.configuration.parameters.minRebootCount
    },

    max () {
      return this.configuration.parameters.maxRebootCount
    },

    hardwareRebootCountIsModified () {
      return this.isModified(
        configuration => configuration.parameters.hardwareRebootCount
      )
    },

    softwareRebootCountIsModified () {
      return this.isModified(
        configuration => configuration.parameters.softwareRebootCount
      )
    },

    hardwareRebootCountIsNotDefault () {
      return this.isNotDefault(configuration => configuration.parameters.hardwareRebootCount)
    },

    softwareRebootCountIsNotDefault () {
      return this.isNotDefault(configuration => configuration.parameters.softwareRebootCount)
    }
  }
}
</script>

<template>
  <article>
    <section v-if="advancedConfiguration">
        <label :class="{ modified: hardwareRebootCountIsModified }">
          Hardware reboot count
          <sc-hint :text="`The alert will be triggered, when device hardware reboots more than ${configuration.parameters.hardwareRebootCount} times over the period of ${duration(configuration.trend)}. ${hardwareRebootCountIsNotDefault}`" size="20px" />
        </label>
        <div>
          <q-input :input-style="{ 'max-width': '150px' }" v-model.number="configuration.parameters.hardwareRebootCount"
            type="number" dense outlined square debounce="500"
            :rules="[ rules.isNumber(min, max, `The value has to be at least ${min}`) ]"
            :min="min"
            :max="max">
          </q-input>
        </div>
    </section>
    <section v-if="advancedConfiguration">
        <label :class="{ modified: softwareRebootCountIsModified }">
          Software reboot count
          <sc-hint :text="`The alert will be triggered, when device software reboots more than ${configuration.parameters.softwareRebootCount} times over the period of ${duration(configuration.trend)}. ${softwareRebootCountIsNotDefault}`" size="20px" />
        </label>
        <div>
          <q-input :input-style="{ 'max-width': '150px' }" v-model.number="configuration.parameters.softwareRebootCount" type="number" dense outlined square
            :rules="[ rules.isNumber(min, max, `The value has to be at least ${min}`) ]"
            debounce="500"
            :min="min"
            :max="max">
          </q-input>
        </div>
    </section>
  </article>
</template>

<style scoped lang="scss">

</style>