export const mutations = {
  /**
   * Toggles sidebar visibility
   * @param state UI state
   * @param isCollapsed If specified, visibility is set to this value otherwise it's toggled
   */
  toggleSidebar (state, { isCollapsed } = {}) {
    if (isCollapsed == null) {
      state.sidebar.isCollapsed = !state.sidebar.isCollapsed
    } else {
      state.sidebar.isCollapsed = isCollapsed
    }
  }
}
