import { Assignable } from '../common/assignable'


/**
 * Generic object for keeping statistics of status values
 * for each device, used to detect alerts
 */
export class DeviceAlertStatistics extends Assignable {
  // Identifiers of available device alert statistics
  static BandPower = 'band-power'
  static BandUsage = 'band-usage'

  constructor (data = {}) {
    super()
    this.assign(data)
    this.values = this.values || []
    this.requiredCount = this.requiredCount || 144
  }

  /**
   * Default number of data points required for these
   * statistics to be reliable
   * @type {Number}
   */
  requiredCount

  /**
  * Parameter stored in the statistics
  */
  parameter

  /**
   * Device serial number
   * @type {String}
   */
  serialNumber

  /**
   * Collection of recent values of the {@link parameter}
   * @type {Array[DeviceAlertStatisticsItem]}
   */
  values

  /**
   * Checks whether statistics has collected any values
   * @returns {Boolean}
   */
  get hasValues () {
    return this.values?.length > 0
  }

  /**
   * Returns average value of a {@link parameter}
   * @param {BandIdentifier} band Band identifier, optional.
   * Must specify if band statistics are collected.
   * @returns {Number}
   */
  getAverageValue (band) {
    const { values } = this
    if (values?.length > 0) {
      const sum = values
        .filter(item => band ? item.values[band] != null : item.value != null)
        .reduce((sum, { values, value }) =>
          sum + (band ? values[band] : value), 0)
      return sum / values.length
    }
  }

  /**
   * Adds a data point, the value of {@param parameter}
   * extracted from the status
   * @param {DeviceStatus} status Received device status
   * @param {Number} requiredCount Maximal number of recent values to keep, one day by default
   */
  addValue (status, requiredCount) {
    const { hasDeviceConnected } = status || {}
    if (!hasDeviceConnected) return

    // Overwrite the required count if specified
    if (requiredCount) {
      this.requiredCount = requiredCount
    } else {
      requiredCount = this.requiredCount
    }

    // If normal is ready, maintain a buffer of recent values
    // Store in recent values, if normal already saturated
    const { values, last, parameter } = this
    values.push(new DeviceAlertStatisticsItem({
      time: last,
      value: status.mega[parameter]
    }))

    // Remove old values
    const excess = values.length - requiredCount
    if (excess > 0) {
      this.values = values.slice(excess)
    }
  }

  /**
   * Adds a data point, the value of {@param parameter} for each band,
   * extracted from device status
   * @param {DeviceStatus} status Received device status
   * @param {Number} requiredCount Maximal number of recent values to keep, one day by default
   */
  addBandValues (status, requiredCount) {
    const { bands, hasDeviceConnected } = status || {}
    if (!hasDeviceConnected) return
    if (!bands) return
    const bandStatus = Object.values(bands.status)
    if (bandStatus.length === 0) return

    // Overwrite the required count if specified
    if (requiredCount) {
      this.requiredCount = requiredCount
    } else {
      requiredCount = this.requiredCount
    }

    // If normal is ready, maintain a buffer of recent values
    // Store in recent values, if normal already saturated
    this.values.push(new DeviceAlertStatisticsItem({
      time: this.last,
      values: Object
        .values(bands.status)
        .reduce((all, s) => ({
          ...all,
          [s.identifier]: s.values[this.parameter]
        }), {})
    }))

    // Remove old values
    const excess = this.values.length - requiredCount
    if (excess > 0) {
      this.values = this.values.slice(excess)
    }
  }

  /**
   * Checks whether statistics is ready to be used
   * for checking alerts and other purposes
   * @param {Number} count Minimal required number of datapoints
   * @returns {Boolean}
   */
  isReady (count) {
    count = count || this.requiredCount
    return count > 0 &&
      this.values?.length >= count
  }

  /**
   * Clears the data points
   */
  clear () {
    this.values = []
  }
}

/**
 * Collected data point for {@link DeviceAlertStatistics}
 */
export class DeviceAlertStatisticsItem {
  constructor (data) {
    Object.assign(this, data)
  }

  /**
   * Date and time of the data point
   * @type {Date}
   */
  time

  /**
   * Single value
   * @type {Number}
   */
  value

  /**
   * Dictionary of values per band
   * @type {Dictionary<BandIdentifier, Number>}
   */
  values
}
