import { safeParseInt, range } from '@stellacontrol/utilities'
import { Device, DeviceType, DeviceName, StandardDeviceBands, bandsToIdentifiers } from '@stellacontrol/model'
import { PlanRectangle } from './plan-rectangle'
import { PlanItemType } from './plan-item'
import { PlanPort, PlanPortType } from './plan-port'
import { PlanBackgroundStyle, PlanLineStyle } from '../styles'

/**
 * Device-specific defaults
 */
const DeviceDefaults = {
  [DeviceType.Repeater]: {
    backgroundStyle: new PlanBackgroundStyle({ color: '#377dff' }),
    portCounts: [1, 4],
    portCount: 4
  },
  [DeviceType.LineAmp]: {
    backgroundStyle: new PlanBackgroundStyle({ color: '#ff9f3f' }),
    portCounts: [4],
    portCount: 4
  }
}

/**
 * Device-specific operational parameters
 */
const DeviceParameters = {
  [DeviceType.Repeater]: { gain: 70 },
  [DeviceType.LineAmp]: { gain: 70 }
}

/**
 * Device
 */
export class PlanDevice extends PlanRectangle {
  constructor (data = {}) {
    super(data)
    this.assign(data)
  }

  /**
   * Item type
   * @type {PlanItemType}
   */
  static get type () {
    return PlanItemType.Device
  }

  /**
   * Creates a repeater device
   * @param {Object} data Item data
   * @returns {PlanDevice}
   */
  static createRepeater (data = {}) {
    return new PlanDevice({
      ...data,
      device: {
        ...(data.device || {}),
        type: DeviceType.Repeater
      }
    })
  }

  /**
   * Creates a lineamp device
   * @param {Object} data Item data
   * @returns {PlanDevice}
   */
  static createLineAmp (data = {}) {
    return new PlanDevice({
      ...data,
      device: {
        ...(data.device || {}),
        type: DeviceType.LineAmp
      }
    })
  }

  /**
   * Object defaults
   */
  get defaults () {
    const { deviceType } = this
    const deviceDefaults = DeviceDefaults[deviceType]

    return {
      ...super.defaults,
      ...deviceDefaults,
      width: 100,
      height: 50,
      lineStyle: PlanLineStyle.Double,
      externalPort: {
        id: PlanPortType.External
      }
    }
  }

  normalize () {
    super.normalize()
    const { defaults } = this
    this.device = this.customize(this.device, Device)
    if (this.device) {
      this.device.bands = bandsToIdentifiers(StandardDeviceBands.SixBands)

      // Set port count, fall back to default if not listed
      this.device.portCount = Math.max(1, safeParseInt(this.device.portCount, defaults.portCount))
      if (!this.defaults.portCounts.includes(this.device.portCount)) {
        this.device.portCount = defaults.portCount
      }
    }
    this.createPorts()
  }

  /**
   * Serializes the plan item to JSON
   * @returns {Object}
   */
  toJSON () {
    const result = super.toJSON()

    // Remove runtime data
    delete result.ports
    delete result.bands

    // Store device data only if not provided from outside
    if (result.device) {
      if (this.data) {
        delete result.device
      } else {
        const { serialNumber, type, bands, portCount } = result.device
        result.device = { serialNumber, type, bands, portCount }
      }
    }
    return result
  }

  /**
   * Human-friendly representation of the item, useful for the UI
   * @param {Boolean} details If true, detailed description is returned
   * @returns {String}
   */
  // eslint-disable-next-line no-unused-vars
  toHumanString (details) {
    const label = `${this.autoLabel}`.trim()
    return label
  }

  /**
   * Device-specific parameters
   * @type {Object}
   */
  get parameters () {
    return DeviceParameters[this.deviceType]
  }

  /**
  * Data of a device associated with this item.
  * Specified ONLY if data is not provided externally
  * from list of devices of the customer.
  * @type {Device}
  */
  device

  /**
   * Device type
   * @type {DeviceType}
   */
  get deviceType () {
    return this.device?.type || DeviceType.Repeater
  }

  /**
   * Device serial number
   * @type {String}
   */
  get serialNumber () {
    return this.device?.serialNumber
  }

  /**
   * Automatically generated label
   * @type {String}
   */
  get autoLabel () {
    return `${DeviceName[this.deviceType]} ${this.serialNumber || ''}`.trim()
  }

  /**
   * Internal port count
   * @type {Number}
   */
  get portCount () {
    return this.device?.portCount || this.defaults.portCount
  }

  /**
   * Device always has a label
   * @type {Boolean}
   */
  get hasLabel () {
    return true
  }

  /**
   * If true, the label is fixed in place and user cannot move it elsewhere
   */
  get isLabelFixed () {
    return true
  }

  /**
   * Assigns custom data to the item
   * @param {any} data
   * @returns {PlanItem}
   */
  setData (data) {
    super.setData(data)

    // If external data provided, override any hard-coded device data from layout
    if (data?.device && data.device.serialNumber !== this.device?.serialNumber) {
      this.device = new Device(data.device)
    }

    if (data?.status) {
      this.status = data.status
    }

    // Configure device ports
    this.createPorts()
  }

  /**
   * Changes device properties.
   * Detects change in port count and other properties which require refresh
   * @param {Object} properties Properties to set
   */
  setProperties (properties = {}) {
    const { portCount } = this
    super.setProperties(properties)

    if (portCount !== this.portCount) {
      this.refresh()
    }
  }

  /**
   * Creates device ports
   */
  createPorts () {
    const { portCount, defaults, id: itemId } = this
    const externalPort = new PlanPort({ ...defaults.externalPort, itemId, type: PlanPortType.External })
    const internalPorts = Array.from(range(1, portCount + 1)).map(i => {
      const id = i.toString()
      const label = id.toString()
      return new PlanPort({ id, label, itemId, type: PlanPortType.Internal })
    })
    this.ports = [
      externalPort,
      ...internalPorts
    ]
  }

  /**
   * Refreshes the device after changes
   */
  refresh () {
    this.createPorts()
  }

  /**
   * Determines input signal strength on the repeater
   * @param {PlanLayout} layout Plan layout
   * @returns {Number}
   */
  getInputSignal (layout) {
    if (layout) {
      return -50
    }
  }

  /**
   * Determine signal gain/loss at the item
   * @param {PlanLayout} layout Plan layout
   * @returns {Number}
   */
  getGain (layout) {
    const { parameters } = this
    if (layout && parameters) {
      return parameters.gain || 0
    }
  }

  /**
   * Caps signal going out of the device
   * @param {Number} signal Outoing signal on device output, in `dB`
   * @returns {Number} Capped signal on device output, in `dB`
   */
  capSignal (signal) {
    if (signal != null) {
      // 13dB for 1-port devices
      if (this.portCount === 1) return Math.min(13, signal)
      // 10dB for others
      return Math.min(10, signal)
    }
  }
}
