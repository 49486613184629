<script>
import { mapActions } from 'vuex'
import { ViewMixin, TabsMixin, EditorMixin } from '@stellacontrol/client-utilities'
import { Secure } from '../../components'
import OrganizationPreferences from './organization-preferences.vue'
import UserPreferences from './user-preferences.vue'

const name = 'preferences'

export default {
  mixins: [
    ViewMixin,
    TabsMixin,
    EditorMixin,
    Secure
  ],

  components: {
    'sc-organization-preferences': OrganizationPreferences,
    'sc-user-preferences': UserPreferences
  },

  data () {
    return {
      name
    }
  },

  computed: {
    // Indicates whether current user can edit organization preferences
    canEditOrganizationPreferences () {
      return this.isAdministrator
    }
  },

  methods: {
    ...mapActions([
      'goBack'
    ]),

    // Closes and goes back to the previous view
    async close () {
      await this.goBack()
    }
  },

  created () {
    // Switch to default tab if one specified in URL has become invisible
    this.ensureTab()
  }
}
</script>

<template>
  <sc-view :name="name" v-if="isLoggedIn">
    <template #toolbar>
      <q-btn label="Close" unelevated @click="close()"></q-btn>
    </template>

    <sc-tabs :model-value="viewTab" @update:model-value="tab => selectTab(tab)" :tabs="viewTabs">
      <q-tab name="user-preferences" icon="person" label="User Preferences" :ripple="false"></q-tab>
      <q-tab v-if="canEditOrganizationPreferences" name="system-preferences" icon="people" label="System Preferences" :ripple="false"></q-tab>
    </sc-tabs>

    <sc-tab-panels :model-value="viewTab" :tabs="viewTabs">
      <q-tab-panel name="user-preferences">
        <sc-user-preferences></sc-user-preferences>
      </q-tab-panel>
      <q-tab-panel name="system-preferences" v-if="canEditOrganizationPreferences">
        <sc-organization-preferences></sc-organization-preferences>
      </q-tab-panel>
    </sc-tab-panels>

  </sc-view>
</template>

<style lang="scss" scoped>
</style>
