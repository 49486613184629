import { state, getters } from './activity.state'
import { mutations } from './activity.mutations'
import { actions } from './activity.actions'

export default {
  state,
  getters,
  mutations,
  actions
}
