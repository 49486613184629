import { mapState, mapGetters } from 'vuex'
import { getOrganizationIcon, getOrganizationColor, sortOrganizations, OrganizationSortOrder } from '@stellacontrol/model'
import { FormMixin } from '@stellacontrol/client-utilities'
import { Secure } from '@stellacontrol/security-ui'

/**
 * Shared code for device action components
 */
export const DeviceActionMixin = {
  mixins: [
    FormMixin,
    Secure
  ],

  props: {
    /**
     * Devices
     */
    devices: {
      type: Array,
      required: true
    },

    /**
     * Action
     */
    action: {
      type: Object,
      required: true
    },

    /**
     * Action details
     */
    details: {
    }
  },

  data: {
    // Indicator that can be used to signal start/end of loading of the editor
    isLoading: false
  },

  computed: {
    ...mapState({
      allDevices: state => state.devices.devices || [],
      owner: state => state.devicePanel.owner,
      ownerGuardian: state => state.devicePanel.ownerGuardian,
      ownerWallet: state => state.devicePanel.ownerWallet
    }),

    ...mapGetters([
      'currentOrganization',
      'organizations'
    ]),

    // Indicates that we're processing a single device
    isSingle () {
      return this.devices.length === 1
    },

    // Indicates that we're processing a batch of devices
    isBatch () {
      return this.devices.length > 1
    },

    // Indicates that we're processing a multi-device
    isMultiDevice () {
      return this.isSingle && this.device.isMultiDevice
    },

    // Indicates that we're processing a part of a multi-device
    isMultiDevicePart () {
      return this.isSingle && Boolean(this.device.partOf)
    },

    // First of the processed devices
    device () {
      return this.devices[0]
    },

    // Master device, available if processing a multi-device part
    masterDevice () {
      return this.isMultiDevicePart ? this.allDevices.find(d => d.id === this.device.partOf) : null
    },

    // Owner organization identifier
    ownerId () {
      const id = this.getValue(device => device.owner ? device.owner.id : undefined)
      return id
    },

    // Indicates that device has an owner selected
    hasOwner () {
      return Boolean(this.owner)
    },

    // Indicates that devices belong to the current organization
    allDevicesAreMine () {
      return this.devices.every(d => d.ownerId === this.currentOrganization.id)
    },

    // Indicates that no devices belong to the current organization
    allDevicesAreNotMine () {
      return this.devices.every(d => d.ownerId !== this.currentOrganization.id)
    },

    // Indicates that selected devices have different owners
    haveDifferentOwners () {
      return this.getValues(device => device.owner ? device.owner.id : undefined).length > 1
    },

    // Indicates that selected devices have the same owner
    haveSameOwner () {
      return this.owner && this.getValues(device => device.owner ? device.owner.id : undefined).length === 1
    },

    // Device place
    place () {
      return this.getValue('place')
    },

    // Indicates that device has a place assigned
    hasPlace () {
      return Boolean(this.place)
    },

    // Organizations sorted hierarchically
    // super/ reseller/ regular
    organizations () {
      const { organizations, currentOrganization } = this
      return sortOrganizations(organizations, OrganizationSortOrder.Rank, { currentOrganization })
    },

    // Devices label, singular or plural depending on current selection
    devicesLabel () {
      return this.devices.length === 1 ? 'the device' : 'the devices'
    },

    // Selection label, singular or plural depending on current selection
    selectionLabel () {
      return this.devices.length === 1 ? 'the selected device' : 'the selected devices'
    },

    // Devices pronoun, singular or plural depending on current selection
    devicesPronoun () {
      return this.devices.length === 1 ? 'it' : 'they'
    },

    // Devices verb, singular or plural depending on current selection
    devicesVerb () {
      return verb => this.devices.length === 1 ? verb : (verb + 's')
    },

    // Devices-are label: device is / devices are, depending on current selection
    devicesAreLabel () {
      return this.devices.length === 1 ? 'the device is' : 'devices are'
    },

    // Devices-have label: device has / devices have, depending on current selection
    devicesHaveLabel () {
      return this.devices.length === 1 ? 'the device has' : 'devices have'
    },

    // Devices text, a comma-separated list of serial numbers
    // of the selected devices
    devicesText () {
      return this.devices.map(d => d.serialNumber).join(', ')
    },

    // Returns true if devices are all decommissioned
    isDecommissioned () {
      return this.getValue('isDecommissioned') === true
    },

    // Returns true if devices are all connected
    isConnected () {
      return this.getValue('isConnectedDevice') === true
    }
  },

  emits: [
    'execute',
    'closing',
    'close'
  ],

  methods: {
    getOrganizationIcon,
    getOrganizationColor,

    // Toggles the loading indicator
    busy () {
      this.isLoading = true
    },

    done () {
      this.isLoading = false
    },

    // Gets all values of a specified property from the edited devices
    getValues (property) {
      return Array.from(new Set(this.devices.map(device =>
        typeof property === 'function' ? property(device) : device[property]
      )))
    },

    // Gets all distinct of a specified property from the edited devices
    getDistinctValues (property) {
      const values = this.getValues(property)
      return Array.from(new Set(values))
    },

    // Gets a specified property of the edited devices.
    // If they have differing values for the property,
    // the specified default value is returned.
    getValue (property, defaultValue) {
      const values = this.getValues(property)
      const value = values.length === 1 ? values[0] : defaultValue
      return value == null ? defaultValue : value
    },

    closing () {
      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
      this.$emit('closing')
    },

    // Signals execution of action
    // and requirement to refresh the inventory grid
    executed ({ refresh = true } = {}) {
      const { action } = this
      this.$emit('execute', { action, refresh })
    },

    close () {
      const { action } = this
      this.$emit('close', { action })
    }
  }
}
