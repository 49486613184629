import { differenceInSeconds, addSeconds } from 'date-fns'
import { Assignable } from '../common/assignable'
import { getLocalDateTime, parseDate, isEnum, sameDate } from '@stellacontrol/utilities'
import { UploadType, UploadDescription } from './upload-type'
import { UploadStatus } from './upload-status'
import { UploadStep } from './upload-step'
import { UploadPayload } from './upload-payload'
import { UploadJobPriority } from './upload-job-priority'
import { DeviceFirmware } from './device-firmware'
import { Device } from '../device/device-entities'
import { User } from '../organization/organization-entities'

/**
 * Device upload job
 */
export class UploadJob extends Assignable {
  // Magic values for `defer` property
  static IMMEDIATELY = 0
  static WHEN_CONNECTED = -1
  static ON_USER_REQUEST = -2

  constructor (data = {}) {
    super()

    if (data.creatorName && !data.creator) {
      data.creator = {
        id: data.createdBy,
        name: data.creatorName,
        firstName: data.creatorFirstName,
        lastName: data.creatorLastName,
        organizationId: data.creatorOrganizationId,
        organization: {
          id: data.creatorOrganizationId,
          name: data.creatorOrganizationName
        }
      }
    }

    this.assign(
      {
        status: data.status || UploadStatus.New,
        createdAt: data.createdAt || new Date(),
        updatedAt: data.updatedAt || data.createdAt || new Date(),
        retryAttempts: data.retryAttempts == null ? 5 : data.retryAttempts,
        retryInterval: data.retryInterval == null ? 50 : data.retryInterval,
        progress: data.progress == null ? 0 : data.progress,
        steps: data.steps || [],
        priority: data.priority || UploadJobPriority.Normal,
        ...data
      },
      {
        createdAt: parseDate,
        updatedAt: parseDate
      })

    if (!isEnum(UploadType, this.type)) throw new Error(`Invalid upload type ${this.type}`)
    if (!isEnum(UploadStatus, this.status)) throw new Error(`Invalid upload status ${this.status}`)
  }

  normalize () {
    super.normalize()
    this.steps = this.castArray(this.steps, UploadStep)
    if (this.payload) {
      if (this.isFirmwareUpload) {
        this.payload = this.cast(this.payload, DeviceFirmware)
      } else {
        this.payload = this.cast(this.payload, UploadPayload)
      }
    }
    this.device = this.cast(this.device, Device)
    this.creator = this.cast(this.creator, User)
  }

  /**
   * Upload bob identifier
   * @type {String}
   */
  id

  /**
   * Organization to which the job belongs
   * @type {String}
   */
  organizationId

  /**
   * Identifier of user who created the job
   * @type {String}
   */
  createdBy

  /**
   * User who created the job
   * @type {User}
   */
  creator

  /**
   * Name of the user who created the job
   * @type {String}
   */
  get creatorName () {
    return this.creator?.name
  }

  /**
   * Job creation time
   * @type {Date}
   */
  createdAt

  /**
   * Last status update time
   * @type {Date}
   */
  updatedAt


  /**
   * Upload type
   * @type {UploadType}
   */
  type

  /**
   * Identifier of recipient device
   * @type {String}
   */
  deviceId

  /**
   * Details of recipient device
   * @type {Device}
   */
  device

  /**
   * Current firmware version on the device
   * @type {String}
   */
  firmwareVersion
  firmwareVersionLong

  /**
   * Identifier of the payload to upload,
   * entry in `firmware` or `bootlogo` table
   * @type {String}
   */
  payloadId

  /**
   * Details of the payload
   * @type {UploadPayload|DeviceFirmware}
   */
  payload

  /**
   * Upload status
   * @type {UploadStatus}
   */
  status

  /**
   * Upload % progress
   * @type {Number}
   */
  progress

  /**
   * Remaining number of retry attempts
   * @type {Number}
   */
  retryAttempts

  /**
   * Time when the job should be retried again [ms]
   * @type {Number}
   */
  retryInterval

  /**
   * Interval to wait, before the upload is attempted, in seconds.
   * Used to schedule uploads at less busy times.
   * There are two 'magic' values for this property:
   * -1 for jobs which should wait until the device comes online
   * -2 for jobs which should wait until the device comes online AND the user requests the update by pressing a button on the device
   * @type {Number}
   */
  defer

  /**
   * If true, upload will start immediately when device comes online.
   * Useful to schedule updates for devices which intermittently go offline and it's hard to catch the moment when they do.
   * @type {Boolean}
   */
  get whenConnected () {
    return this.defer === UploadJob.WHEN_CONNECTED
  }
  set whenConnected (value) {
    this.defer === value ? UploadJob.WHEN_CONNECTED : UploadJob.IMMEDIATELY
  }

  /**
   * If true, upload will start when the user requests it from the device.
   * @type {Boolean}
  */
  get onUserRequest () {
    return this.defer === UploadJob.ON_USER_REQUEST
  }
  set onUserRequest (value) {
    this.defer === value ? UploadJob.ON_USER_REQUEST : UploadJob.IMMEDIATELY
  }

  /**
   * Indicates that device came online and is ready to receive pending uploads
   * @type {Boolean}
   */
  deviceReadyToReceive

  /**
   * Use to indicate that job should be prioritised.
   * The higher the number, the higher the priority.
   * @type {UploadJobPriority}
   */
  priority

  /**
   * Upload processing steps, chronological history
   * @type {Array[UploadStep]}
   */
  steps = []

  /**
   * Brief label for the UI
   * @type {String}
   */
  get label () {
    const { type, payload } = this
    const parts = [
      UploadDescription[type],
      payload ? payload.name : undefined
    ]
    return parts
      .filter(p => p)
      .join(' ')
  }

  /**
   * Returns true if job has not yet been started
   * @type {Boolean}
   */
  get isNew () {
    return this.status === UploadStatus.New
  }

  /**
   * Returns true if job has been completed
   * @type {Boolean}
   */
  get isCompleted () {
    return this.status === UploadStatus.Completed
  }

  /**
   * Returns true if job will be retried
   * @type {Boolean}
   */
  get isRetry () {
    return this.status === UploadStatus.Retry
  }

  /**
   * Returns true if job has failed
   * @type {Boolean}
   */
  get isFailed () {
    return this.status === UploadStatus.Failed
  }

  /**
   * Indicates that the job is currently in progress
   * @type {Boolean}
   */
  get inProgress () {
    return !(this.isCompleted || this.isFailed)
  }

  /**
   * Indicates that the job is currently deferred and will be attempted later
   * @type {Boolean}
   */
  get isDeferred () {
    return this.isNew && this.defer > UploadJob.IMMEDIATELY
  }

  /**
   * Indicates that there are processing steps
   * registered
   * @type {Boolean}
   */
  get hasSteps () {
    return this.steps && this.steps.length > 0
  }

  /**
   * Returns job age, in seconds
   * @type {Number}
   */
  get age () {
    const age = differenceInSeconds(Date.now(), this.createdAt)
    return isNaN(age) ? undefined : age
  }

  /**
   * Returns time since last job update, in seconds
   * @type {Number}
   */
  get timeSinceLastUpdate () {
    const time = differenceInSeconds(Date.now(), this.updatedAt)
    return isNaN(time) ? undefined : time
  }

  /**
   * Returns the time remaining for the job to be executed, in seconds.
   * @type {Number}
   * @description Calculation is done according to device owner's timezone!
   */
  get uploadIn () {
    const { createdAt, isDeferred, defer, device } = this
    const { country, timezone } = device?.owner || {}

    if (isDeferred) {
      // If upload is deferred, check whether the time has come
      if (country && timezone) {
        // ... using device owner's local time
        const localUpdateAt = getLocalDateTime(country, timezone, addSeconds(createdAt, defer))
        const localTime = getLocalDateTime(country, timezone)
        if (localTime <= localUpdateAt) {
          return differenceInSeconds(localUpdateAt, localTime)
        } else {
          return 0
        }
      } else {
        // ... using server time
        const updateAt = addSeconds(createdAt, defer)
        const time = new Date()
        if (time <= updateAt) {
          return differenceInSeconds(updateAt, time)
        } else {
          return 0
        }
      }
    } else {
      // If upload is not deferred, it can be executed:
      // - immediately
      // - when device comes online
      // - on user request
      return 0
    }
  }

  /**
   * Returns true if job can be uploaded now.
   * This is true, when job is new.
   * If job is deferred, we check whether enough time has passed since job creation.
   * @type {Boolean}
   * @description Calculation is done according to device owner's time,
   * thus `now` is seen as `now` in their timezone, not UTC timezone of the server.
   */
  get canUploadNow () {
    return this.uploadIn === 0
  }

  /**
   * Returns time in which the next upload attempt will happen, in seconds
   * @type {Number}
   */
  get nextTryIn () {
    const { isRetry, retryAttempts, retryInterval, timeSinceLastUpdate } = this
    if (isRetry && retryAttempts > 0 && retryInterval > 0 && timeSinceLastUpdate >= 0) {
      return Math.max(0, retryInterval - timeSinceLastUpdate)
    }
  }

  /**
   * Returns true if job can be retried now,
   * which means there are retry attempts left
   * and enough time has passed since last retry
   * @type {Boolean}
   */
  get canRetryNow () {
    const { retryAttempts, nextTryIn } = this
    return retryAttempts > 0 &&
      nextTryIn <= 0
  }

  /**
   * Returns true if job can be retried but later
   * @type {Boolean}
   */
  get canRetryLater () {
    const { retryAttempts, nextTryIn } = this
    return retryAttempts > 0 &&
      nextTryIn > 0
  }

  /**
   * Determines whether the specified file is a firmware file
   * @param name File name
   * @type {Boolean}
   */
  get isFirmwareUpload () {
    return this.type === UploadType.Firmware
  }

  /**
   * Determines whether the specified file is a boot logo file
   * @param name File name
   * @type {Boolean}
   */
  get isBootLogoUpload () {
    return this.type === UploadType.BootLogo
  }

  /**
   * Checks whether the job has associated payload
   * @type {Boolean}
   */
  get hasPayload () {
    return Boolean(this.payload)
  }

  /**
   * Checks whether the job has associated payload including binary content
   * @type {Boolean}
   */
  get hasPayloadContent () {
    const { payload } = this
    return Boolean(payload && payload.file && payload.file.name && payload.file.size && payload.file.content)
  }

  /**
   * Checks whether the job has been changed,
   * as compared to the specified previous status
   * @param id Job identifier
   * @param status Last known job status
   * @param updatedAt Time of the last update
   * @returns {Boolean} True if upload job status has changed
   */
  hasChanged ({ id, status, updatedAt } = {}) {
    if (id && id === this.id && status && updatedAt) {
      return status !== this.status || !sameDate(updatedAt, this.updatedAt)
    }
  }

  /**
   * Records a new step when processing a job
   * @param status Job status
   * @param details Other details
   * @param failed Indicates that the step has failed
   * @returns {UploadStep} Upload step
   */
  addStep (status, details, failed = false) {
    if (status && isEnum(UploadStatus, status)) {
      const step = new UploadStep({ status, details, failed })
      this.steps.push(step)
      return step
    } else {
      throw new Error('Invalid step data')
    }
  }

  /**
   * Updates the job with specified status.
   * Used to propagate changes returned from API calls such
   * as retry, set status etc.
   * @param {UploadJobStatus} status Job status to apply
   * @returns {UploadJob} Updated job
   */
  updateStatus ({ status, progress, retryAttempts, retryInterval, updatedAt, steps } = {}) {
    this.status = status == null ? this.status : status
    this.progress = progress == null ? this.progress : progress
    this.retryAttempts = retryAttempts == null ? this.retryAttempts : retryAttempts
    this.retryInterval = retryInterval == null ? this.retryInterval : retryInterval
    this.updatedAt = updatedAt == null ? this.updatedAt : updatedAt
    this.steps = steps == null ? this.steps : steps

    return this
  }

  toJSON () {
    const result = { ...this }
    delete result.creatorName
    delete result.creatorFirstName
    delete result.creatorLastName
    delete result.creatorOrganizationId
    delete result.creatorOrganizationName
    return result
  }
}
