<script>
import { mapGetters } from 'vuex'
import { getMegaParameter, isMegaParameterApplicable } from '@stellacontrol/mega'
import { megaVersionMatches, getBandLabel, AttenuationGroupDescription, inAttenuationGroup, AttenuationGroups } from '@stellacontrol/model'
import { Clipboard, Notification } from '@stellacontrol/client-utilities'
import DeviceWidget from './device-widget'

export default {
  mixins: [
    DeviceWidget
  ],

  data () {
    return {
      // List of parameters to display.
      // Their details, such as labels, tooltips or availability
      // on specific firmware versions are all defined in @stellacontrol/mega
      allParameters: [
        { name: 'mean_up' },
        { name: 'mean_dw' },
        { name: 'neartower_up' },
        { name: 'neartower_dw' },
        { name: 'vsc' },
        { name: 'osc_up' },
        { name: 'osc_dw' },
        { name: 'osc_max' },
        { name: 'extra_perm' },
        { name: '_dl_atten_group' },
        { name: '_mgn_dw' },
        { name: '_skew', permissions: ['super-administrator'] },
        { name: 'sliding' }
      ],

      // Parameters which are displays in complementary pairs
      pairedParameters: [
        ['mean_up', 'peak_up'],
        ['mean_dw', 'peak_dw'],
        ['vsc', 'peak_agc_up'],
        ['neartower_dw', 'peak_agc_dw']
      ],

      // Parameters layout in the table
      groups: [
        { parameters: ['mean_up', 'mean_dw', 'peak_up', 'peak_dw'] },
        { parameters: ['neartower_up', 'neartower_dw', 'vsc'] },
        { parameters: ['osc_up', 'osc_dw', 'osc_max', 'extra_perm'] },
        { parameters: ['_dl_atten_group', '_mgn_dw', '_skew'] },
        { parameters: ['sliding'] },
      ],

      // Attenuation groups
      AttenuationGroups
    }
  },

  computed: {
    ...mapGetters([
      'isSmallScreen'
    ]),

    // MEGA version
    megaVersion () {
      return this.status?.version
    },

    // All parameters to display under current permissions
    parameters () {
      const { device, status, allParameters } = this

      const parameters = allParameters
        // Find MEGA definition for the displayed parameters
        .map(parameter => ({
          parameter,
          definition: getMegaParameter(parameter.name, device, status)
        }))
        // Filter out those not applicable under current device firmware version
        .filter(({ definition }) => definition.status === 'unknown' || isMegaParameterApplicable(definition.name, device, status))
        // Create items to display
        .map(({ parameter, definition }) => {
          const data = definition.status === 'unknown' ? parameter : definition
          const item = {
            ...parameter,
            ...data,
            label: data.label || data.name,
            description: data.details || data.description
          }
          return item
        })

      return parameters
    },

    // All parameter groups which are visible under current permissions
    visibleGroups () {
      return this.groups
        .map((group, index, all) => ({
          ...group,
          parameters: this.groupParameters(group),
          index,
          isLast: index === all.length - 1
        }))
        .filter(group => group.parameters.length > 0)
    },

    // All parameters of the specified group to display under current permissions
    groupParameters () {
      return group => group.parameters
        .map(name => this.parameters.find(p => p.name === name))
        .filter(p => p)
    },

    // Bands status parsed from MEGA
    bandStatus () {
      return this.status?.bands
    },

    // Band identifiers
    bandIdentifiers () {
      return this.bandStatus ? this.bandStatus.identifiers : []
    },

    // All available device bands and their current status
    bands () {
      return this.bandStatus ? this.bandStatus.status : {}
    },

    // Bands belonging to the specified attenuation group
    attenuationGroupBands () {
      return group => this.bandIdentifiers.filter(identifier => inAttenuationGroup(identifier, group))
    },

    // Other details about states of device bands
    details () {
      return this.bandStatus ? this.bandStatus.details : {}
    },

    // User-friendly and device-family-specific label of a band
    bandLabel () {
      const family = this.device.family
      return identifier => getBandLabel(identifier, family)
    },

    // Returns true if specified parameter is applicable
    // for the current device and MEGA version
    isParameterApplicable () {
      return parameter => {
        const { megaVersion } = this
        const { requires } = parameter
        if (megaVersion && requires && requires.version) {
          return megaVersionMatches(megaVersion, `mega-v${requires.version}`, requires.operator)
        } else {
          return true
        }
      }
    },

    // General device parameters
    generalParameters () {
      return this.parameters
        .filter(parameter => !parameter.group)
        .filter(parameter => this.isParameterApplicable(parameter))
    },

    // Parameters in OTHER group
    otherParameters () {
      return this.parameters
        .filter(parameter => parameter.group === 'other')
        .filter(parameter => this.isParameterApplicable(parameter))
    },

    // Returns true if band is online
    isBandOnline () {
      return identifier => {
        const { bands } = this
        if (bands) {
          const value = bands[identifier].isOnline
          return value
        }
      }
    },

    // Returns true if band has been shut down manually
    isBandShutDown () {
      return identifier => {
        const { bands } = this
        if (bands) {
          const value = bands[identifier].isShutdownManually
          return value
        }
      }
    },

    // Returns the tooltip for the parameter
    parameterTooltip () {
      return (parameter) => {
        const { device, status } = this
        let tooltip = parameter.description
        for (const [param, pairedParam] of this.pairedParameters) {
          if (parameter.name === param && isMegaParameterApplicable(pairedParam, device, status)) {
            tooltip = `${tooltip}<br><br>The orange values are peak values since the last heartbeat.`
          }
        }
        return tooltip
      }
    },

    // Returns a value of a parameter for the specified band
    parameterValue () {
      return (identifier, name) => {
        const { bands, device, status } = this
        if (bands) {
          const values = bands[identifier].values
          const value = values[name]
          let text = value == null ? '-' : value

          // Add paired values
          for (const [param, pairedParam] of this.pairedParameters) {
            if (name === param && isMegaParameterApplicable(pairedParam, device, status)) {
              text = `${text} <span class="text-orange-9">${values[pairedParam]}</span>`
            }
          }

          return text
        }
      }
    },

    // Returns group attenuation value for the specified group of bands
    groupAttenuation () {
      return which => ({
        label: AttenuationGroupDescription[which],
        value: (this.bandStatus.attenuationGroups || {})[which]
      })
    },

    // Available widget actions
    actions () {
      const { isSmallScreen } = this
      return isSmallScreen
        ? []
        : [
          {
            name: 'copy',
            label: 'Copy to clipboard',
            icon: 'content_copy',
            color: 'indigo-6',
            permissions: []
          },
        ]
    }
  },

  methods: {
    // Executes the action
    async executeAction (action) {
      switch (action.name) {
        case 'copy':
          await this.copyToClipboard()
          break
      }
    },

    // Copies the table content to clipboard
    async copyToClipboard () {
      const { bandIdentifiers, parameters } = this
      const title = 'Band Details'
      const subtitle = ['Parameter', ...bandIdentifiers.map(identifier => this.bandLabel(identifier))].join('\t')
      const values = parameters
        .map(signal =>
          [signal.label, ...bandIdentifiers.map(identifier => this.parameterValue(identifier, signal.name))].join('\t'))
      const lines = [
        title,
        subtitle,
        ...values
      ].join('\n')
      await Clipboard.write(lines + '\n')
      Notification.success({ message: 'Signal details have been copied to clipboard' })
    }
  }
}
</script>

<template>
  <sc-widget v-bind="{ ...$props, ...$attrs }" :actions="actions" @action="executeAction"
    v-if="hasStatus" width="591px" :padding="isSmallScreen ? '0' : undefined">
    <div class="band-details">
      <q-markup-table dense flat square class="parameters">
        <thead>
          <tr>
            <th class="parameter text-left" v-if="!isSmallScreen">
              Parameter
            </th>
            <th v-for="identifier in bandIdentifiers" :key="identifier" class="value"
              :class="{ offline: !isBandOnline(identifier) }">
              {{ bandLabel(identifier) }}
            </th>
          </tr>
        </thead>

        <tbody>
          <template v-for="group in visibleGroups">

            <template v-for="parameter in group.parameters" :key="parameter.name">
              <tr :class="{ bold: parameter.bold }" v-if="isSmallScreen">
                <td class="parameter text-left" :colspan="bandIdentifiers.length">
                  <span class="q-ml-xs">
                    {{ parameter.label }}
                    {{ parameter.unit ? `(${parameter.unit})` : '' }}
                  </span>
                </td>
              </tr>

              <tr :class="{ bold: parameter.bold }">
                <td class="parameter text-left" v-if="!isSmallScreen">
                  <div class="row items-center">
                    <sc-hint size="20px" v-if="parameter.description"
                      :text="parameterTooltip(parameter)" />
                    <span class="q-ml-xs">
                      {{ parameter.label }}
                      {{ parameter.unit ? `(${parameter.unit})` : '' }}
                    </span>
                  </div>
                </td>

                <template v-if="parameter.name !== '_dl_atten_group'">
                  <td v-for="identifier in bandIdentifiers" :key="identifier" class="value text-right"
                    :class="{ offline: !isBandOnline(identifier) }">
                    <span v-html="parameterValue(identifier, parameter.name)">
                    </span>
                  </td>
                </template>
                <template v-else v-for="ag in AttenuationGroups">
                  <td :colspan="attenuationGroupBands(ag).length" class="parameter text-right">
                    {{ groupAttenuation(ag).label }}: {{ groupAttenuation(ag).value }} dB
                  </td>
                </template>
              </tr>

            </template>

            <!-- group spacer -->
            <tr v-if="!group.isLast && !isSmallScreen">
              <th :colspan="1 + bandIdentifiers.length" class="group-spacer">
              </th>
            </tr>

          </template>

        </tbody>
      </q-markup-table>

    </div>
  </sc-widget>
</template>

<style scoped lang="scss">
.band-details {
  padding: 4px 8px 8px 8px;
  background-color: white;

  :deep(.q-table) {
    border-collapse: collapse !important;
  }

  tr {
    th {
      padding: 4px 8px 4px 8px;
      font-size: 16px;
      color: #2a2a2a;
      text-align: right;

      &.group-spacer {
        height: 24px;
        border-bottom: solid #0000001f 1px;
      }

      &.offline {
        background-color: #e8e8e8;
        color: #b0b0b0;
      }

      &.parameter {
        text-align: left;
      }

      &.value {
        min-width: 64px;
      }
    }

    td {
      padding: 6px;
      font-size: 13px;
      color: #4a4a4a;
      border-top: solid #0000001f 1px;
      border-bottom: solid #0000001f 1px;

      &.parameter {
        cursor: pointer;
      }

      &.offline {
        background-color: #e8e8e8;
        color: #b0b0b0;
      }
    }

    &.bold {
      td {
        font-weight: bold;

        &:first-child {
          font-weight: normal;
        }
      }
    }
  }
}

/* Layout adjustments for screen below HD resolution */
@media screen and (max-width: 1365px) {
  .widget {
    width: 400px !important;
  }
}

/* Layout adjustments for small screens */
@media screen and (max-width: 1024px) {
  :deep(.q-table) {
    border-collapse: collapse !important;
  }

  .widget {
    width: 100% !important;

    .band-details {
      padding: 0;

      tr {
        th {
          text-align: center;

          &.value {
            min-width: auto;
            border: solid #0000001f 1px;
          }
        }

        td {
          border-top: none;
          border: solid #0000001f 1px;

          .q-icon.hint {
            display: none;
          }

          &.parameter {}
        }
      }
    }
  }
}
</style>
