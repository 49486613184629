import Konva from 'konva'
import { PlanItemType } from '@stellacontrol/planner'
import { Shape } from './shape'

/**
 * Image
 */
export class ImageShape extends Shape {
  constructor (item, dataCallback) {
    super(item, dataCallback)
    this.content = new Konva.Group()
  }

  static get type () {
    return PlanItemType.Image
  }

  /**
   * Image shape
   * @type {Konva.Image}
   */
  imageShape

  render (renderer) {
    return new Promise(resolve => {
      super.render(renderer)
      const { content: shape, item } = this
      const image = new Image()
      image.onload = () => {
        this.imageShape = new Konva.Image({ image })
        shape.add(this.imageShape)
        this.applyFilters()
        resolve()
      }
      image.src = item.url
    })
  }
}
