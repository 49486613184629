export const mutations = {
  /**
   * Stores features in the state
   * @param features Features to store
   */
  storeFeatures (state, { features = [] } = {}) {
    state.items = features
    state.feature = createFeatureDictionary([features])

  }
}

/**
 * Recursively traverses the feature tree and populates
 * the dictionary. Throws if duplicates found.
 * @param features Feature tree
 * @param dictionary Dictionary to populate
 */
function createFeatureDictionary (features, dictionary = {}) {
  for (const feature of features) {
    const { name } = feature
    if (name in dictionary) throw new Error(`Feature [${name}] is not unique`)
    dictionary[name] = feature
    if (feature.features) {
      createFeatureDictionary(feature.features, dictionary)
    }
  }
  return dictionary
}
