import { isNumber } from './number'
import { padLeft } from './string'
import { isBrowser } from './platform'

/**
 * Checks whether the specified data is Base64 string
 * @param {String} data
 * @returns {Boolean}
 */
export function isBase64 (data) {
  if (data) {
    try {
      if (Array.isArray(data) || Buffer.isBuffer(data)) {
        data = Buffer.from(data).toString('utf8')
      }
      return encodeBase64(decodeBase64(data)) === data
    } catch {
      return false
    }
  } else {
    return false
  }
}

/**
 * Converts array buffer to Base64 without costly string conversion required by `window.btoa`
 * @param {ArrayBuffer} buffer Buffer to convert
 * @returns {String}
 * @description Source: https://gist.github.com/jonleighton/958841
 */
export function arrayBufferToBase64 (buffer) {
  let base64 = ''
  const encodings = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'
  const bytes = new Uint8Array(buffer)
  const byteLength = bytes.byteLength
  const byteRemainder = byteLength % 3
  const mainLength = byteLength - byteRemainder
  let a, b, c, d
  let chunk

  // Main loop deals with bytes in chunks of 3
  for (let i = 0; i < mainLength; i = i + 3) {
    // Combine the three bytes into a single integer
    chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2]
    // Use bitmasks to extract 6-bit segments from the triplet
    a = (chunk & 16515072) >> 18 // 16515072 = (2^6 - 1) << 18
    b = (chunk & 258048) >> 12 // 258048   = (2^6 - 1) << 12
    c = (chunk & 4032) >> 6 // 4032     = (2^6 - 1) << 6
    d = chunk & 63               // 63       = 2^6 - 1
    // Convert the raw binary segments to the appropriate ASCII encoding
    base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d]
  }

  // Deal with the remaining bytes and padding
  if (byteRemainder == 1) {
    chunk = bytes[mainLength]
    a = (chunk & 252) >> 2 // 252 = (2^6 - 1) << 2
    // Set the 4 least significant bits to zero
    b = (chunk & 3) << 4 // 3   = 2^2 - 1
    base64 += encodings[a] + encodings[b] + '=='
  } else if (byteRemainder == 2) {
    chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1]
    a = (chunk & 64512) >> 10 // 64512 = (2^6 - 1) << 10
    b = (chunk & 1008) >> 4 // 1008  = (2^6 - 1) << 4
    // Set the 2 least significant bits to zero
    c = (chunk & 15) << 2 // 15    = 2^4 - 1
    base64 += encodings[a] + encodings[b] + encodings[c] + '='
  }

  return base64
}

/**
 * Encodes data into a Base64 string
 * @param {String|ArrayBuffer|Buffer|Array} data Buffer or array with binary data to encode
 * @returns {String} Encoded data
 */
export function encodeBase64 (data) {
  if (data == null) return

  if (isBrowser) {
    // Input must be either ArrayBuffer or String
    if (data.byteLength > 0) {
      return arrayBufferToBase64(data)
    } else {
      return window.btoa(data.toString())
    }
  } else {
    // In NodeJS, only arrays and buffers are permitted on input
    const isBuffer = Buffer.isBuffer(data)
    const isArray = Array.isArray(data)
    if (!(isBuffer || isArray)) throw new Error('encodeBase64() accepts only byte Array or Buffer as input')

    const buffer = isArray
      ? Buffer.from(data)
      : data

    return buffer.toString('base64')
  }
}

/**
 * Decodes data encoded as Base64 string into a binary buffer
 * @param {String} data Base64-encoded data to decode
 * @param {Boolean} verify If true, the decoded data is encoded again, to check whether the input was indeed a valid Base64
 * @returns {Buffer} Decoded data
 */
export function decodeBase64 (data, verify = true) {
  if (data == null) return
  // If data is in array or buffer, turn it to a string
  if (!isBrowser) {
    if (Array.isArray(data) || Buffer.isBuffer(data)) {
      data = Buffer.from(data).toString('utf8')
    } else {
      if (typeof data !== 'string') throw new Error('Input data has to be a Base64 string')
    }
  }

  // Decode, check if input was indeed Base64
  const decoded = isBrowser
    ? window.atob(data)
    : Buffer.from(data, 'base64')

  if (verify) {
    const encoded = encodeBase64(decoded)
    if (encoded === data) {
      return decoded
    } else {
      throw new Error('Input data is not a Base64 string')
    }
  } else {
    return decoded
  }
}

/**
 * Converts a byte to hex representation
 * @param {Number} value Byte to encode
 * @returns {String} Hex representation of the byte
 */
export function byteToHex (value) {
  if (value == null) return
  if (!isNumber(value, true, 0, 255)) throw new Error(`Value [${value}] is not a byte`)
  if (value < 16) return '0' + value.toString(16)
  return value.toString(16)
}

/**
 * Converts an array of bytes to hex representation
 * @param {Array[Number]|Buffer} values Bytes to encode as hex
 * @param {String} separator Optional separator between bytes if passed as string
 * @returns {String} Hex representation of the byte array
 */
export function bytesToHex (values, separator = '') {
  if (values == null) return
  values = Array.from(values)
  if (!values.every(b => isNumber(b, true, 0, 255))) throw new Error('Input value must be an array of only bytes')

  return values
    .map(b => byteToHex(b))
    .join(separator)
}

/**
 * Converts an array of bytes to string representation
 * @param {Array[Number]|Buffer} values Bytes to encode
 * @param {String|Number} separatorOrWidth Optional separator between bytes or a fixed length of a byte string.
 * @returns {String} String representation of the byte array
 */
export function bytesToString (values, separatorOrWidth = '') {
  if (values == null) return
  values = Array.from(values)
  if (!values.every(b => isNumber(b, true, 0, 255))) throw new Error('Input value must be an array of only bytes')

  if (typeof separatorOrWidth == 'number') {
    return values
      .map(b => padLeft(b.toString(), separatorOrWidth, '0'))
      .join('')
  } else if (typeof separatorOrWidth == 'string') {
    return values
      .map(b => b.toString())
      .join(separatorOrWidth)
  } else {
    throw new Error(`Invalid separator [${separatorOrWidth}], the data [${values}] cannot be parsed to bytes`)
  }
}

/**
 * Converts an array of signed bytes to string representation
 * @param {Array[Number]|Buffer} values Bytes to encode
 * @param {String|Number} separatorOrWidth Optional separator between bytes or a fixed length of a byte string.
 * @returns {String} String representation of the byte array
 */
export function signedBytesToString (values, separatorOrWidth = '') {
  if (values == null) return
  values = Array.from(values)
  if (!values.every(b => isNumber(b, true, -255, 255))) throw new Error('Input value must be an array of only bytes')

  if (typeof separatorOrWidth == 'number') {
    return values
      .map(b => padLeft(b.toString(), separatorOrWidth, '0'))
      .join('')
  } else if (typeof separatorOrWidth == 'string') {
    return values
      .map(b => b.toString())
      .join(separatorOrWidth)
  } else {
    throw new Error(`Invalid separator [${separatorOrWidth}], the data [${values}] cannot be parsed to bytes`)
  }
}

/**
 * Converts a string of numbers to array of bytes
 * @param {String} values String of numbers
 * @param {String|Number} separatorOrWidth Optional separator between numbers or fixed length to cut the input into numbers
 * @param {Number} defaultValue Default value to return if item cannot be parsed as byte
 * @returns {Array[Number]} Byte array
 */
export function stringToBytes (values, separatorOrWidth = ',', defaultValue = 0) {
  if (values == null) return
  if (typeof values !== 'string') throw new Error('Input value must be a string')

  if (typeof separatorOrWidth == 'number') {
    const result = []
    let i = 0
    while (i < values.length) {
      const byte = parseInt(values.substring(i, i + separatorOrWidth))
      result.push(isNaN(byte) ? defaultValue : byte)
      i = i + separatorOrWidth
    }
    return result

  } else if (typeof separatorOrWidth == 'string') {
    return values
      .split(separatorOrWidth)
      .map(byte => parseInt(byte))
      .map(byte => isNaN(byte) ? defaultValue : byte)

  } else {
    throw new Error(`Invalid separator [${separatorOrWidth}], the data [${values}] cannot be parsed to bytes`)
  }
}

/**
 * Parses data URL into its constituents: `mimeType`, `encoding` and `data`
 * @param {String} dataUrl Data URL
 * @returns {Object}
 */
export function parseDataUrl (dataUrl) {
  if (dataUrl) {
    const regex = /data:(?<mimeType>[\w/\-.]+);(?<encoding>\w+),(?<data>.*)/
    const result = regex.exec(dataUrl)
    return result?.groups
  }
}

/**
 * Encodes a number to 26-character based ASCII letter encoding
 * where A=0, B=1, ..., Z=25
 * @param {Number} value Number to encode
 * @returns {String} Encoded number
 * @description Source: https://github.com/patrik-csak/BB26
 */
export function toBase26 (value) {
  let string = ''
  let number = value

  const toChar = (number) => String.fromCodePoint('A'.codePointAt(0) - 1 + number)
  while (number > 0) {
    string = toChar(number % 26 || 26) + string
    number = Math.floor((number - 1) / 26)
  }

  return string
}

/**
 * Decodes a Base26-encoded number
 * @param {String} value Value to decode
 * @returns {Number} Decoded number
 * @description Source: https://github.com/patrik-csak/BB26
 */
export function fromBase26 (value) {
  if (!/[A-Z]/.test(value)) {
    return
  }

  const charToDecimal = letter => letter.codePointAt(0) - 'A'.codePointAt(0) + 1
  let number = 0
  for (let i = 0; i < value.length; i++) {
    const char = value[value.length - i - 1]
    number += 26 ** i * charToDecimal(char)
  }

  return number
}
