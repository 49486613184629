import { Assignable } from '../../common/assignable'
import { Organization, User } from '../../organization'

export class Session extends Assignable {
  constructor (data) {
    super()
    this.assign({
      ...data,
      createdAt: data.createdAt || new Date(),
      updatedAt: data.updatedAt || new Date()
    })
  }

  normalize () {
    super.normalize()
    this.user = this.cast(this.user, User)
    if (this.user && this.user.organization) {
      this.user.organization = this.cast(this.user.organization, Organization)
    }
  }

  /**
   * Session identifier
   * @type {String}
   */
  id

  /**
   * API Client identifier
   * @type {String}
   */
  apiClient

  /**
   * Session creation date and time
   * @type {Date}
   */
  createdAt

  /**
   * Session token
   * @type {String}
   */
  token

  /**
   * Session duration in seconds
   * @type {Number}
   */
  expiresIn

  /**
   * Date and time when session expires
   */
  get expiresAt () {
    const { createdAt, expiresIn } = this
    if (createdAt && expiresIn) {
      return new Date(createdAt.getTime() + expiresIn * 1000)
    }
  }

  /**
   * Indicates that session has expired
   * @type {Boolean}
   */
  hasExpired

  /**
   * User with all related principals and their permissions
   * @type {User}
   */
  user

  /**
   * Administrator who logged in on behalf of the user from parent organization
   * @type {User}
   */
  masterUser

  /**
   * Organization from which administrator has logged in on behalf of the user
   * @type {Organization}
   */
  masterOrganization

  /**
   * Global application preferences
   * @type {Object}
   */
  preferences

  /**
   * Origin IP
   * @type {String}
   */
  ip

  /**
   * Returns true if {@link ip} is a localhost
   * @type {Boolean}
   */
  get isLocalhost () {
    return this.ip === '127.0.0.1' || this.ip === '0.0.0.0'
  }

  /**
   * Origin country
   * @type {String}
   */
  country

  /**
   * Origin city
   * @type {String}
   */
  city

  /**
   * User language
   * @type {String}
   */
  language


  /**
   * Browser (from agent string)
   * @type {String}
   */
  browser

  /**
   * OS (from agent string)
   * @type {String}
   */
  os

  /**
   * Device (from agent string)
   * @type {String}
   */
  device

  /**
   * User's organization
   * @type {Organization}
   */
  get organization () {
    return this.user ? this.user.organization : undefined
  }

  /**
   * User's organization profile
   * @type {OrganizationProfile}
   */
  get organizationProfile () {
    return this.organization ? this.organization.profile : undefined
  }

  /**
   * Indicates a valid session with authenticated user
   * @type {Boolean}
   */
  get isValid () {
    return this.token && this.userId && this.user && this.organization && !this.hasExpired
  }

  toJSON () {
    const result = { ...this }
    const { user } = result
    if (user?.organization) {
      delete user.organization.creator
      delete user.organization.updater
      delete user.organization.deletedBy
      delete user.organization.deletedAt
      delete user.organization.disabledBy
      delete user.organization.isEnabled
      delete user.organization.legacyId
      delete user.organization.isPrimary
    }
    if (user?.organization?.permissions) {
      for (const permission of user.organization.permissions)
        delete permission.id
    }
    if (user?.organization?.profile) {
      delete user.organization.profile.deletedBy
      delete user.organization.profile.deletedAt
      delete user.organization.profile.disabledBy
      delete user.organization.profile.isEnabled
      delete user.organization.profile.isDefault
      delete user.organization.profile.termsAndConditionsId
      delete user.organization.profile.ownerId
    }
    if (user?.organization?.profile?.permissions) {
      for (const permission of user.organization.profile.permissions) {
        delete permission.id
        delete permission.createdAt
        delete permission.updatedAt
        delete permission.createdBy
        delete permission.updatedBy
        delete permission.__feature
        delete permission.principalId
        delete permission.defaultValue
      }
    }
    return result
  }
}
