<script>
import { mapActions, mapState } from 'vuex'
import { Place, isPlaceLockedFor } from '@stellacontrol/model'
import { FormMixin, ListAction, ActionItem } from '@stellacontrol/client-utilities'
import { Secure } from '../../components/secure-component'

export default {
  mixins: [
    FormMixin,
    Secure
  ],

  props: [
    'data'
  ],

  data () {
    return {
      isPlaceLockedFor
    }
  },

  computed: {
    ...mapState({
      organizationPlacesColumns: state => state.organizationPlaces.organizationPlacesColumns
    }),

    places () {
      return (this.data.places || []).map(item => new Place(item))
    },

    hasPlaces () {
      return this.places.length > 0
    },

    placeActions () {
      return [
        new ActionItem({
          ...ListAction.Edit,
          isVisible: place => !isPlaceLockedFor(place, this.currentUser)
        }),
        new ActionItem({
          ...ListAction.Delete,
          isVisible: place => !isPlaceLockedFor(place, this.currentUser)
        })
      ]
    }
  },

  methods: {
    ...mapActions([
      'editOrganizationPlace',
      'createOrganizationPlace',
      'removeOrganizationPlace'
    ]),

    // Adds place to organization
    addPlaceToOrganization () {
      this.$emit('addPlaceToOrganization')
    },

    async executeAction (place, action) {
      const { data: organization } = this
      const { id } = place
      switch (action.name) {
        case 'edit':
          await this.editOrganizationPlace({ id, organization, drilldown: true })
          break
        case 'delete':
          await this.removeOrganizationPlace({ id, organization, confirm: true })
          break
        default:
          throw new Error(`Unhandled action ${action.name}`)
      }
    }
  }
}

</script>

<template>
  <div class="container">
    <div class="header">
      <div class="row items-center">
        <q-btn label="Add Building" icon="add" unelevated @click="addPlaceToOrganization()"/>
        <q-space></q-space>
        <span v-if="!hasPlaces">
          You can add here places where devices will be installed, such as buildings, ships or vehicles.
        </span>
      </div>
    </div>

    <div class="table q-mt-sm" v-if="hasPlaces">

      <sc-list
        name="organization-places"
        row-key="id"
        :columns="organizationPlacesColumns"
        :items="places"
        :actions="placeActions"
        @action="executeAction">

        <template v-slot:body-cell-name="props">
          <q-td :props="props">
            <span v-if="isPlaceLockedFor(props.row, currentUser)">
              {{ props.row.name }}
            </span>
            <router-link v-else class="item-link" :to="{ name: 'organization-place', params: { organizationId: data.id, id: props.row.id } }">
              {{ props.row.name }}
              <sc-tooltip :text="`Edit ${props.row.name}`" />
            </router-link>
          </q-td>
        </template>

      </sc-list>

    </div>
  </div>
</template>

<style lang='scss' scoped>
.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    flex: 0;
  }

  .table {
    flex: 1;
    overflow: auto;
    border-top: solid silver 1px;
  }
}
</style>
