import { mapGetters, mapActions } from 'vuex'

/**
 * Vue secure component mixin, providing quick way to check permissions
 */
export const Secure = {
  computed: {
    ...mapGetters([
      'isLoggedIn',
      'guardian',
      'session',
      'currentUser',
      'currentOrganization',
      'currentOrganizationGuardian',
      'currentOrganizationProfile',
      'getViewPermissions'
    ]),

    /**
     * Returns true if currently logged in is a super organization
     * @returns {Boolean}
     */
    isSuperOrganization () {
      if (!this.currentOrganization) return false
      return this.currentOrganization.isSuperOrganization
    },

    /**
     * Returns true if currently logged in is a reseller organization
     * @returns {Boolean}
     */
    isResellerOrganization () {
      if (!this.currentOrganization) return false
      return this.currentOrganization.isResellerOrganization
    },

    /**
     * Returns true if currently logged in is a guest organization
     * @returns {Boolean}
     */
    isGuestOrganization () {
      if (!this.currentOrganization) return false
      return this.currentOrganization.isGuestOrganization
    },

    /**
     * Returns true if currently logged in is an admin user of a super organization
     * @returns {Boolean}
     */
    isSuperAdministrator () {
      if (!this.currentUser) return false
      return this.currentUser.isSuperAdministrator
    },

    /**
     * Returns true if currently logged in is an admin user of a reseller organization
     * @returns {Boolean}
     */
    isResellerAdministrator () {
      if (!this.currentUser) return false
      return this.currentUser.isResellerAdministrator
    },

    /**
     * Returns true if currently logged in is an admin user
     * @returns {Boolean}
     */
    isAdministrator () {
      if (!this.currentUser) return false
      return this.currentUser.isAdministrator
    },

    /**
     * Returns true if currently logged in is a guest user
     * @returns {Boolean}
     */
    isGuestUser () {
      if (!this.currentUser) return false
      return this.currentUser.isGuestUser
    },

    /**
     * Returns true if current organization requires premium subscriptions
     * @returns {Boolean}
     */
    requiresPremiumSubscriptions () {
      if (this.guardian) {
        return !this.isSuperAdministrator && this.guardian.mustUse('premium-services-buy')
      }
    },

    /**
     * Returns true if current organization is a bank - is allowed to generate premium tokens
     * @returns {Boolean}
     */
    isBank () {
      return this.guardian?.isBank
    },

    /**
     * Parent organization
     * @returns {Organization}
     */
    parentOrganization () {
      if (!this.currentOrganization) return false
      return this.currentOrganization.parentOrganization
    },

    /**
     * Parent organization guardian
     * @returns {Guardian}
     */
    parentOrganizationGuardian () {
      if (this.guardian) {
        return this.guardian.parentOrganizationGuardian
      }
    }
  },

  methods: {
    ...mapActions([
      'getGuardian'
    ]),

    /**
     * Returns true if the specified feature is allowed
     * @param {String} feature Name of the feature to check
     * @returns {Boolean}
     */
    canUse (feature) {
      if (this.guardian) {
        return this.guardian.canUse(feature)
      }
    },

    /**
     * Returns true if the specified feature is required for the principal
     * @param {String} feature Name of the feature to check
     * @returns {Boolean}
     */
    mustUse (feature) {
      if (this.guardian) {
        return this.guardian.mustUse(feature)
      }
    },

    /**
     * Returns true if principal is allowed to use all the specified features
     * @param {Array[String]} names Features to check
     * @returns {Boolean}
     */
    canUseAll (names) {
      if (this.guardian) {
        return this.guardian.canUseAll(names)
      }
    },

    /**
     * Returns true if principal is allowed to use any the specified features
     * @param {Array[String]} names Features to check
     * @returns {Boolean}
     */
    canUseAny (names) {
      if (this.guardian) {
        return this.guardian.canUseAny(names)
      }
    },

    /**
     * Returns true if principal is allowed to use any of the children
     * of the specified feature
     * @param {String} feature Name of the feature to check
     * @returns {Boolean}
     */
    canUseChildrenOf (name) {
      if (this.guardian) {
        return this.guardian.canUseChildrenOf(name)
      }
    },

    /**
     * Returns true if the specified feature is allowed for the specified device
     * @param {String} feature Name of the feature to check
     * @param {String} serialNumber Device serial number
     * @param {Guardian} viewer Guardian of the viewer which acts on behalf of the current principal.
     * Will be specified for example when reseller views his customer's devices.
     * @returns {Boolean}
     */
    canDeviceUse (feature, serialNumber, viewer) {
      if (this.guardian && serialNumber) {
        return this.guardian.canDeviceUse(feature, serialNumber, viewer)
      }
    },

    /**
     * Returns true if the specified feature is not allowed
     * @param {String} feature Name of the feature to check
     * @returns {Boolean}
     */
    cannotUse (feature) {
      if (this.guardian) {
        return this.guardian.cannotUse(feature)
      }
    },

    /**
     * Returns true if principal is not allowed to use all of the specified features
     * @param {Array[String]} names Features to check
     * @returns {Boolean}
     */
    cannotUseAll (names) {
      if (this.guardian) {
        return this.guardian.cannotUseAll(names)
      }
    },

    /**
     * Returns true if principal is not allowed to use some of the specified features
     * @param {Array[String]} names Features to check
     * @returns {Boolean}
     */
    cannotUseAny (names) {
      if (this.guardian) {
        return this.guardian.cannotUseAny(names)
      }
    },

    /**
     * Returns additional context of the specified feature
     * @param {String} feature Name of the feature
     * @returns {Object}
     */
    getFeatureContext (feature) {
      if (this.guardian) {
        return this.guardian.getContext(feature)
      }
    },

    /**
     * Returns true if current organization requires premium subscription
     * for the specified feature
     * @returns {String}
     */
    requiresPremiumSubscription (featureName) {
      return this.guardian
        ? !this.isSuperAdministrator && this.guardian.requiresPremiumSubscription(featureName)
        : false
    },

    /**
     * Returns true if specified view is allowed
     * @param {String} name View name
     * @returns {Boolean}
     */
    canSeeView (name) {
      if (this.guardian && name) {
        const permissions = this.getViewPermissions(name) || []
        return this.guardian.canUseAll(permissions)
      }
    }
  }
}
