import { EntityType } from '../common/entity-type'
import { Assignable } from '../common/assignable'

/**
 * Notification recipient
 */
export class NotificationRecipient extends Assignable {
  constructor (data = {}) {
    super()
    this.assign({ ...data })
  }

  normalize () {
    super.normalize()
    this.frequency = this.frequency || 86400
  }


  /**
   * Recipient identifier
   * @type {String}
   */
  id

  /**
   * Recipient type - that would be either organization or user
   * @type {EntityType}
   */
  type

  /**
   * Recipient name
   * @type {String}
   */
  name

  /**
   * Identifier of organization where recipient belongs
   * @type {String}
   */
  organizationId

  /**
   * Name of organization where recipient belongs
   * @type {String}
   */
  organizationName

  /**
   * Code of the country of the recipient
   * ISO 3166 Alpha-2 country code
   * @type {String}
   */
  countryCode

  /**
   * Code of the preferred language used by the recipient
   * ISO 639-2 language code
   * @type {String}
   */
  languageCode

  /**
   * Name of country timezone, as per https://www.iana.org/time-zones
   * @type {String}
   */
  timezone

  /**
   * Recipient name including the organization
   * @type {String}
   */
  get fullName () {
    return [
      this.name,
      this.organizationName
    ]
      .filter((s = '') => s.trim())
      .join(' - ')
  }

  /**
   * Indicates that recipient has chosen to receive
   * alert notifications in his preferences
   * @type {Boolean}
   */
  receive

  /**
   * Frequency at which the recipient prefers
   * to receive his emails, in seconds
   * @type {Number}
   */
  frequency

  /**
   * Description of the time slot when notifications
   * to this recipient are to be delivered
   * @type {String}
   */
  nextDelivery

  /**
   * E-mail address of the recipient.
   * It can also be multiple email addresses separated with semicolon.
   * @type {String}
   */
  email

  /**
   * More details about whose e-mail this really is.
   * Subscribers can provide custom email addresses
   * depending on place, where device is assigned,
   * as well as custom email on owner or reseller level.
   * @type {String}
   */
  emailOwner

  /**
   * Phone number of the recipient
   * @type {String}
   */
  phone

  /**
   * More details about whose phone number this really is.
   * Subscribers can provide custom phone numbers
   * depending on place, where device is assigned,
   * as well as custom number on owner or reseller level.
   * @type {String}
   */
  phoneOwner

  /**
   * Timestamp when recipient's notifications were sent the last time
   * @type {Date}
   */
  lastSent

  /**
   * Creates a recipient from user
   * @param {User} user
   * @returns {NotificationRecipient}
   */
  static fromUser (user) {
    if (user) {
      const { organization: { countryCode, languageCode, timezone } = {} } = user
      return new NotificationRecipient({
        id: user.id,
        organizationId: user.organizationId,
        type: EntityType.User,
        name: user.name,
        email: user.email,
        emailOwner: user.fullName,
        phone: user.phone,
        phoneOwner: user.fullName,
        countryCode,
        languageCode,
        timezone
      })
    }
  }

  /**
   * Creates a recipient from organization
   * @param {Organization} organization
   * @returns {NotificationRecipient}
   */
  static fromOrganization (organization) {
    if (organization) {
      const { countryCode, languageCode, timezone } = organization
      return new NotificationRecipient({
        id: organization.id,
        organizationId: organization.id,
        type: EntityType.Organization,
        name: organization.name,
        email: organization.email,
        emailOwner: organization.name,
        phone: organization.phone,
        phoneOwner: organization.name,
        countryCode,
        languageCode,
        timezone
      })
    }
  }
}
