<script>
import { mapActions, mapGetters } from 'vuex'
import { DeviceLinkType, getOrganizationIcon, getOrganizationColor, OrganizationLevel } from '@stellacontrol/model'
import { Secure } from '@stellacontrol/security-ui'
import { Confirmation } from '@stellacontrol/client-utilities'
import { DevicePanelTabMixin } from '../device-panel-tab-mixin'

export default {
  mixins: [
    DevicePanelTabMixin,
    Secure
  ],

  computed: {
    ...mapGetters([
      'organizationHierarchy'
    ]),

    // Device owner
    owner () {
      return this.device.owner
    },

    // Device ownership path
    ownership () {
      const { device, organizationHierarchy } = this
      return device.getOwnershipPath(organizationHierarchy)
    },

    // Tree nodes showing the ownership hierarchy
    ownershipTree () {
      const { owner, ownership } = this
      let rootNode
      let parentNode

      for (const organization of ownership) {
        let label
        if (organization.level === OrganizationLevel.Organization) {
          label = organization.name
        } else {
          label = organization.name
        }
        if (owner && organization.id === owner.id) {
          label = label + ', device owner'
        }

        const node = {
          id: organization.id,
          label,
          icon: getOrganizationIcon(organization),
          iconColor: getOrganizationColor(organization),
          children: [],
          selectable: false,
          tickable: false
        }

        if (parentNode) {
          parentNode.children.push(node)
        } else {
          rootNode = node
        }
        parentNode = node
      }

      return [rootNode]
    },

    // Other organizations with which the device is shared
    shares () {
      return (this.device.links || [])
        .filter(link => link.isCurrent)
        .filter(link => link.type === DeviceLinkType.Delegate)
    }
  },

  methods: {
    ...mapActions([
      'unlinkDevices'
    ]),

    getOrganizationIcon,
    getOrganizationColor,

    // Link icon
    getLinkIcon (link) {
      if (link.type === DeviceLinkType.Owner) return 'home'
      return 'share'
    },

    // Determines whether it's possible to unlink the device from the principal.
    // Must not be allowed to unlink from yourself :-)
    canUnlink (link) {
      if (link.principal.id === this.currentOrganization.id) return false
      return true
    },

    // Unlinks the devices from organization
    async unlink (link) {
      const yes = await Confirmation.ask({
        title: 'Revoke access',
        message: `Stop sharing ${this.devicesText} with ${link.principal.name}?`
      })

      if (yes) {
        const { device } = this
        const { principal: organization, type } = link
        await this.unlinkDevices({ devices: [device], organization, type })
      }
    }
  }
}
</script>

<template>
   <div class="column access">

      <div class="q-mb-md">
        <div class="row items-center no-wrap">
          <label class="text-subtitle1 text-grey-10">
            {{ capitalize(device.type) }} <b>{{ device.serialNumber }}</b> is available to the following organizations:
          </label>
        </div>
      </div>

     <q-tree
      :nodes="ownershipTree"
      default-expand-all
      no-connectors
      node-key="id">
     </q-tree>

     <div class="q-mt-md q-mb-sm" v-if="shares.length > 0">
       <div class="row items-center no-wrap">
         <label class="text-subtitle1 text-grey-10">
           It is also shared with the following organizations:
         </label>
       </div>
     </div>

     <q-list class="q-ml-sm" v-if="shares.length > 0" dense>
       <q-item v-for="link in shares" :key="link.id" clickable>
         <q-item-section side>
           <q-icon :name="getLinkIcon(link)" color="green-5" size="sm" />
         </q-item-section>
         <q-item-section>
           <q-item-label>
             {{ link.principal.name }}, shared on {{ dateString(link.createdAt) }}
           </q-item-label>
           <q-item-label class="q-mt-xs text-grey-8" v-if="link.notes">
             {{ link.notes }}
           </q-item-label>
         </q-item-section>

         <q-item-section side v-if="canUnlink(link)">
           <q-btn flat round icon="close" color="red-8" size="sm" @click="unlink(link)">
             <sc-tooltip :text="`Stop sharing the device with ${link.principal.name}`" />
           </q-btn>
         </q-item-section>
       </q-item>
     </q-list>
   </div>

</template>

<style lang="scss" scoped>
.access {
  flex: 1;
  display: flex;

  .content {
    flex: 1;
    overflow: hidden;
  }
}
</style>
