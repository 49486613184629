<script>
import { mapActions } from 'vuex'
import { DialogMixin } from '@stellacontrol/client-utilities'
import { Secure } from '@stellacontrol/security-ui'

const dialog = 'device-picker'

export default {
  mixins: [
    DialogMixin,
    Secure
  ],

  data () {
    return {
      dialog,
      // Dialog title
      title: 'Select a device',
      // OK button label
      okLabel: 'OK',
      // Cancel button label
      cancelLabel: 'Cancel',
      // Devices to select from
      devices: [],
      // Selected device
      device: null,
      // List of devices filtered by user's entry
      filteredList: []
    }
  },

  methods: {
    ...mapActions([
      'dialogOk',
      'dialogCancel'
    ]),

    // Called when dialog is shown
    dialogShown () {
      this.device = null

      // Pass on initial data
      this.filteredList = this.devices = this.data.devices
      this.title = this.data.title || this.title
      this.okLabel = this.data.okLabel || this.okLabel
      this.cancelLabel = this.data.cancelLabel || this.cancelLabel
    },

    // Validates and OKs the dialog
    async ok () {
      const { device } = this
      const data = { device }
      this.dialogOk({ dialog, data })
    },

    // Cancels the dialog
    cancel () {
      this.dialogCancel({ dialog })
    },

    // Filter function
    filterFn (val, update) {
      if (val === '') {
        update(() => {
          this.filteredList = this.devices
        })
        return
      }

      update(() => {
        const needle = val.toLowerCase()
        this.filteredList = this.devices.filter(device => device.serialNumber.toLowerCase().includes(needle))
      })
    }
  }
}

</script>

<template>
  <sc-dialog :dialog="dialog" @dialogShown="dialogShown()">
    <q-form class="form">

      <q-banner class="bg-indigo-6">
        <span class="text-white title">
          {{ title }}
        </span>
      </q-banner>

      <div class="q-pa-lg">
        <div>
          <q-select v-model="device" :options="filteredList" label="Enter the serial number"
            option-value="serialNumber" option-label="serialNumber" map-options color="teal"
            outlined square options-selected-class="text-deep-orange" class="device-select"
            use-input @filter="filterFn">
          </q-select>
        </div>

        <footer class="row q-mt-xl">
          <q-space></q-space>
          <q-btn :label="cancelLabel" unelevated class="q-mr-md" @click="cancel()" />
          <q-btn :label="okLabel" unelevated class="primary" :disabled="!device" @click="ok()" />
        </footer>
      </div>

    </q-form>
  </sc-dialog>
</template>

<style scoped lang="scss">
.form {
  .title {
    font-size: 18px;
  }

  .device-select {
    min-width: 350px;
  }

  footer {
    .q-btn {
      min-width: 80px;
    }
  }
}
</style>