import { state, getters } from './installations.state'
import { mutations } from './installations.mutations'
import { actions } from './installations.actions'

export default {
  state,
  getters,
  mutations,
  actions
}
