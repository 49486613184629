import { PlanAction, PlanActions } from './plan-action'

/**
 * Selects all items on the plan
 */
export class SelectAllItemsAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.SelectAllItems
  }

  /**
  * Action label
  * @type {String}
  */
  get label () {
    return 'Select all'
  }

  /**
   * Action icon
   * @type {String}
   */
  get icon () {
    return 'select_all'
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   * @param {PlanLayers} layer If specified, only the items on the specified layer will be selected
   */
  async execute ({ renderer, layer } = {}) {
    if (renderer) {
      const items = renderer
        .selectableItems
        .filter(item => layer ? item.isOnLayer(layer) : true)
      renderer.selectItems({ items })
    }
  }
}
