<script>
export default {
  props: {
    // Premium service selected initially
    modelValue: {
      required: true
    },

    // List of premium services to select from
    items: {
      type: Array,
      required: true
    },

    // List of premium services which appear in the list but should not be selectable
    disabledItems: {
    },

    // Dense rendering
    dense: {
      type: Boolean,
      default: false
    },

    // Selector label
    label: {
      type: String
    }
  },

  data () {
    return {
      selection: this.modelValue
    }
  },

  computed: {
    selectedPremiumService () {
      return this.items.find(item => item.id === this.selection)
    }
  },

  watch: {
    modelValue () {
      this.selection = this.modelValue
    },

    items () {
      this.matchingItems = [...this.items]
    }
  },

  methods: {
    // Checks whether the specified item can be selected
    canSelect (item) {
      return !this.disabledItems?.some(i => i.id === item.id)
    },

    // Notify about model change
    itemSelected (value) {
      this.$emit('update:model-value', value)
    }
  },

  created () {
    this.selection = this.modelValue
  }
}
</script>

<template>
  <q-select v-model="selection" square outlined hide-bottom-space clearable emit-value lazy-rules
    :dense="dense" map-options option-value="id" option-label="label"
    :options="items" :option-disable="item => !canSelect(item)"
    :label="label" @update:model-value="value => itemSelected(value)">
    <template v-slot:option="scope">
      <q-item v-bind="scope.itemProps">
        <q-item-section>
          <q-item-label class="q-mb-xs text-bold">{{ scope.opt.code || scope.opt.name }}
          </q-item-label>
          <q-item-label caption class="q-mb-xs">{{ scope.opt.code ? scope.opt.name :
              scope.opt.description
          }}</q-item-label>
          <q-item-label caption class="text-bold">{{ scope.opt.priceDescription }}
          </q-item-label>
        </q-item-section>
      </q-item>
      <q-separator></q-separator>
    </template>

    <!--
    Pass all other custom slots from the parent
    -->
    <template v-for="(_, name) in $slots" v-slot:[name]="slotData">
      <slot :name="name" v-bind="slotData" :props="slotData"></slot>
    </template>

  </q-select>
</template>

<style>

</style>

