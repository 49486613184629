<script>
import { mapGetters } from 'vuex'
import { countAndPluralize } from '@stellacontrol/utilities'
import FileComponent from './file.vue'

export default {
  props: {
    // Folder to show
    folder: {
    },
    // Folder expansion state
    expandedFolders: {
      type: Object,
      default: {}
    },
    // Group expansion state
    collapsedGroups: {
      type: Object,
      default: {}
    },
    // File sort order
    sortOrder: {
    },
    // File filter
    filter: {
    },
    // File age
    age: {
    }
  },

  components: {
    'sc-file': FileComponent
  },

  computed: {
    ...mapGetters([
      'isMobilePhone'
    ]),

    // Indicates whether the folder is expanded
    isExpanded () {
      return Boolean(this.expandedFolders[this.folder.id])
    },

    // Folder label
    label () {
      const { folder, hasSelectedFiles, selectedFiles } = this
      const label = [
        folder.label,
        ' (',
        countAndPluralize(folder.files, 'file'),
        hasSelectedFiles ? ', ' : '',
        hasSelectedFiles ? `${selectedFiles.length} selected` : '',
        ')'
      ].join('')
      return label
    },

    // Files filtered and sorted in the selected sort order
    visibleFiles () {
      const { folder, filter, age, sortOrder = 'date-desc' } = this
      return folder.visibleFiles({ filter, age, sortOrder })
    },

    // Indicates whether any files are visible
    hasVisibleFiles () {
      return this.visibleFiles.length > 0
    },

    // File groups
    groups () {
      const { folder, filter, age, sortOrder = 'date-desc' } = this
      return folder.visibleGroups({ filter, age, sortOrder })
    },

    // Indicates that files have specified `folder` property
    hasGroups () {
      const { visibleFiles } = this
      return visibleFiles.some(file => Boolean(file.folder))
    },

    // Group label
    groupLabel () {
      return group => {
        const { name, files } = group
        const label = `${name} (${countAndPluralize(files, 'file')})`
        return label
      }
    },

    // Indicates whether the file group is collapsed
    isGroupCollapsed () {
      return group => {
        const { collapsedGroups } = this
        return collapsedGroups[group.name]
      }
    },

    // Indicates whether all file groups are collapsed
    allGroupsCollapsed () {
      const { groups, collapsedGroups } = this
      return groups.every(g => collapsedGroups[g.name])
    },

    // CSS class of the group container
    groupClass () {
      return group => {
        const collapsed = this.isGroupCollapsed(group)
        return {
          collapsed
        }
      }
    },

    // CSS style of the group container
    groupStyle () {
      return group => {
        const fileWidth = 140
        const fileGap = 8
        const collapsed = this.isGroupCollapsed(group)
        const count = collapsed ? 2 : Math.max(2, group.files.length)
        const width = `${(count * fileWidth + (count - 1) * fileGap)}px`
        return { width }
      }
    },

    // Files in the folder which are selected
    selectedFiles () {
      return this.visibleFiles.filter(file => file.isSelected)
    },

    // Indicates that some files in the folder are selected
    hasSelectedFiles () {
      return this.selectedFiles.length > 0
    },

    // Indicates that all files in the folder are currently selected
    allSelected () {
      const { visibleFiles, selectedFiles } = this
      return visibleFiles.length > 0 && selectedFiles.length === visibleFiles.length
    },

    // Indicates that all files in the group are currently selected
    groupSelected () {
      return group => {
        return group.files.every(file => file.isSelected)
      }
    }
  },

  emits: [
    'toggle',
    'toggleGroup',
    'select',
    'remove',
    'preview',
    'print',
    'download'
  ],

  methods: {
    // Populates the folder
    populate () {
      // If more than one file group, collapse initially
      const { groups } = this
      if (groups.length > 1) {
        this.toggleGroups(false)
      }
    },

    // Toggles the specified file folder or sets it to the specified state
    toggle (expanded) {
      const { folder } = this
      this.$emit('toggle', { folder, expanded })
    },

    // Toggles the specified file group or sets it to the specified state
    toggleGroup (group = 'none', expanded) {
      const { folder } = this
      this.$emit('toggle', { folder, group, expanded })
    },

    // Toggles all file groups or sets them to the specified state
    toggleGroups (expanded) {
      const { folder } = this
      this.$emit('toggle', { folder, allGroups: true, expanded })
    },

    // Marks the file as selected/deselected
    // If no file is specified, all files in the folder will be (de)selected
    select (isSelected, file) {
      const { folder, isExpanded, visibleFiles } = this
      const files = file ? [file] : [...visibleFiles]
      this.$emit('select', { folder, files, isSelected })
      if (file && !isExpanded) {
        this.toggle()
      }
    },

    // Marks files in the specified group as selected/deselected
    selectGroup (isSelected, group) {
      const { folder } = this
      const files = group.files
      this.$emit('select', { folder, files, isSelected })
    },

    // Removes the specified files
    remove (file) {
      const { folder } = this
      this.$emit('remove', { folder, files: [file] })
    },

    // Previews the specified file
    preview (file) {
      const { folder } = this
      this.$emit('preview', { folder, file })
    },

    // Prints the specified file
    print (file) {
      const { folder } = this
      this.$emit('print', { folder, files: [file] })
    },

    // Downloads the specified file
    download (file) {
      const { folder } = this
      this.$emit('download', { folder, file })
    }
  },

  watch: {
    groups (current) {
      // After groups have changed, i.e. after filtering, reveal files
      // if only one visible group is left
      if (current.length === 1) {
        this.toggleGroup(current[0].name, true)
      }
    }
  },

  created () {
    this.populate()
  }
}
</script>

<template>
  <div class="folder">
    <div class="title">
      <q-banner dense class="bg-indigo-1 q-pl-md" @click.stop="toggle">
        <div class="row items-center">
          <q-icon color="indigo-6" :name="folder.icon" size="md">
          </q-icon>
          <span class="q-ml-sm">
            {{ label }}
          </span>
          <q-space>
          </q-space>
          <q-btn dense unelevated flat no-caps color="indigo-6" class="q-mr-md"
            v-if="hasVisibleFiles && !isMobilePhone"
            :label="allGroupsCollapsed ? 'Expand Folders' : 'Collapse Folders'"
            @click.stop="toggleGroups(allGroupsCollapsed)">
          </q-btn>
          <q-btn dense unelevated flat no-caps color="indigo-6" class="q-mr-md"
            v-if="hasVisibleFiles"
            :label="isMobilePhone ? '' : (allSelected ? 'Deselect All' : 'Select All')"
            @click.stop="select(!allSelected)">
          </q-btn>
          <q-icon color="indigo-6" :name="isExpanded ? 'expand_more' : 'chevron_right'" size="md">
          </q-icon>
        </div>
      </q-banner>
    </div>

    <div v-if="visibleFiles.length === 0" class="no-files text-grey-8">
      No matching files found
    </div>

    <div v-else class="group" v-if="isExpanded" v-for="group of groups">
      <div v-if="hasGroups" class="header row items-center no-wrap" :class="groupClass(group)"
        :style="groupStyle(group)" @click="toggleGroup(group.name)">
        <q-icon color="indigo-6" :name="isGroupCollapsed(group) ? 'chevron_right' : 'expand_more'"
          size="sm">
        </q-icon>
        <span class="q-ml-sm q-mr-md">
          {{ groupLabel(group) }}
        </span>
        <q-checkbox dense color="indigo-6" size="sm" :model-value="groupSelected(group)"
          @update:model-value="isSelected => selectGroup(isSelected, group)">
        </q-checkbox>
      </div>
      <div class="files" v-if="!isGroupCollapsed(group)">
        <sc-file v-for="file of group.files" :key="file.id" :file="file" can-select can-annotate
          can-delete can-preview can-print can-download @select="select(!file.isSelected, file)"
          @remove="remove" @preview="preview" @download="download" @print="print">
        </sc-file>
      </div>
    </div>

  </div>
</template>

<style scoped lang="scss">
.folder {
  margin-bottom: 1px;

  >.title {
    cursor: pointer;
  }

  >.no-files {
    padding: 8px;
  }

  >.group {
    display: flex;
    flex-direction: column;
    padding: 8px 8px 8px 16px;
    gap: 4px;

    >.header {
      align-self: flex-start;
      padding: 8px;
      max-width: 100%;
      border-bottom: solid #c1c1c1 1px;
      cursor: pointer;
      overflow: hidden;

      &.collapsed {
        width: 140px;
        border-bottom: none;
      }

      span {
        white-space: nowrap;
      }
    }

    >.files {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding-top: 4px;
      gap: 8px;
    }
  }
}
</style>