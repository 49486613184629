<script>
import { DocumentCategory } from '@stellacontrol/model'
import HelpMixin from './help-mixin'

export default {
  mixins: [
    HelpMixin
  ],

  async created () {
    this.document = await this.getDocument({ category: DocumentCategory.FAQ })
  }
}
</script>

<template>
  <main>
    <sc-document-viewer
      v-if="document"
      :url="document.url"
      :content="document.content"
      :type="document.type"
      :isCollapsible="isCollapsible"
      :isCollapsed="isCollapsed">
    </sc-document-viewer>
    <div class="text-body2" v-else>
      Document not found
    </div>
  </main>
</template>

<style scoped lang="scss">
main {
  flex: 1;
  overflow: auto;
}
</style>
