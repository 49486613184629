<script>
import { mapGetters, mapActions } from 'vuex'
import { FormMixin, DefaultListActions } from '@stellacontrol/client-utilities'

export default {
  mixins: [
    FormMixin
  ],

  props: [
    'data'
  ],

  data () {
    return {
      DefaultListActions
    }
  },

  computed: {
    ...mapGetters([
      'childOrganizationColumns'
    ]),

    childOrganizations () {
      return this.data.organizations || []
    },

    hasChildOrganizations () {
      return this.childOrganizations.length > 0
    }
  },

  methods: {
    ...mapActions([
      'editOrganization',
      'removeOrganization'
    ]),

    // Adds child organization
    addChildOrganization () {
      this.$emit('addChildOrganization')
    },

    // Removes child organization
    async removeChildOrganization (organization) {
      if (await this.removeOrganization({ organization, confirm: true })) {
        const { organizations = [] } = this.data
        const index = organizations.findIndex(o => o.id === organization.id)
        if (index > -1) {
          organizations.splice(index, 1)
        }
      }
    },

    async executeAction (organization, action) {
      switch (action.name) {
        case 'edit':
          await this.editOrganization({ organization, drilldown: true })
          break
        case 'delete':
          return this.removeChildOrganization(organization)
        default:
          throw new Error(`Unhandled action ${action.name}`)
      }
    }
  }
}

</script>

<template>
  <div class="container">

    <div class="header">
      <div class="row items-center">
        <q-btn label="Add Child Organization" icon="add" unelevated @click="addChildOrganization()"/>
        <q-space></q-space>
        <span v-if="hasChildOrganizations">
          The list shows organizations directly managed by {{ data.name }} as well as their customers
        </span>
        <span class="q-mb-md" v-if="!hasChildOrganizations">
          You can add here child organizations managed by {{ data.name }}
        </span>
      </div>
    </div>

    <div class="table q-mt-sm" v-if="hasChildOrganizations">
      <sc-list
        name="child-organizations"
        row-key="id"
        :columns="childOrganizationColumns"
        :items="childOrganizations"
        :actions="[ ...DefaultListActions ]"
        @action="executeAction">

        <template v-slot:body-cell-name="props">
          <q-td :props="props" :style="{ 'width': '150px'}">
            <router-link class="item-link" :to="{ name: 'organization', params: { id: props.row.id }, query: { tab: 'general' } }">
              {{ props.row.name }}
              <sc-tooltip :text="`Edit ${props.row.name}`" />
            </router-link>
          </q-td>
        </template>

      </sc-list>
    </div>

  </div>
</template>

<style lang='scss' scoped>
.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    flex: 0;
  }

  .table {
    flex: 1;
    overflow: auto;
    border-top: solid silver 1px;
  }
}
</style>
