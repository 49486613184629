import OrganizationsView from './organizations.vue'
import OrganizationView from './organization.vue'
import { resolveOrganizations, resolveOrganization } from './organization.resolve'

export const Routes = [
  {
    name: 'organizations',
    path: '/administration/organization',
    component: OrganizationsView,

    async beforeEnter (to, from, next) {
      if (await resolveOrganizations()) {
        next()
      }
    }
  },
  {
    name: 'organization',
    path: '/administration/organization/:id',
    component: OrganizationView,

    async beforeEnter (to, from, next) {
      const { params: { id } } = to
      if (await resolveOrganization({ from, to, id })) {
        next()
      }
    }
  }
]
