import { dispatch } from '@stellacontrol/client-utilities'

export async function resolve (to, from) {
  if (to && from) {
    const { id } = to.params
    const data = await dispatch('getBugReport', { id })
    if (data) {
      await dispatch('setRouteData', { from, to, data })
      return data
    }
  }
}
