import { parseDate } from '@stellacontrol/utilities'
import { Entity } from '../common/entity'
import { PremiumService } from './premium-service'

/**
 * Represents a pricelist containing a list of premium services
 * to which a customer can subscribe
 */
export class Pricelist extends Entity {
  constructor (data = {}) {
    super()

    this.assign(
      {
        ...data,
        name: data.name || 'Pricelist',
        services: data.services || [],
        isEnabled: data.isEnabled == null ? true : Boolean(data.isEnabled),
        isMain: data.isMain == null ? false : Boolean(data.isMain)
      },
      {
        createdAt: parseDate,
        updatedAt: parseDate,
        isEnabled: Boolean,
        isMain: Boolean
      }
    )
  }

  normalize () {
    super.normalize()
    this.services = this.castArray(this.services, PremiumService)
  }

  /**
   * Indicates whether pricelist is editable for the requesting party.
   */
  isEditable

  /**
   * Pricelist name
   * @type {String}
   */
  name

  /**
   * Pricelist description
   * @type {String}
   */
  description

  /**
   * Premium services included in the premium service
   * @type {Array[String]}
   */
  services

  /**
   * Returns true if there are some services defined in the pricelist
   * @type {Boolean}
   */
  get hasServices () {
    const { services = [] } = this
    return services.length > 0
  }

  /**
   * Returns true if there are some publicly available services defined in the pricelist
   * @type {Boolean}
   */
  get hasPublicServices () {
    const { services = [] } = this
    return services.some(s => !s.isPrivate)
  }

  /**
   * Returns public services defined in the pricelist
   * @type {Boolean}
   */
  get publicServices () {
    const { services = [] } = this
    return services.filter(s => !s.isPrivate)
  }

  /**
   * Returns true if there are some free services defined in the pricelist
   * @type {Boolean}
   */
  get hasFreeServices () {
    const { services = [] } = this
    return services.some(s => s.isFree)
  }

  /**
   * Returns free services defined in the pricelist
   * @type {Boolean}
   */
  get freeServices () {
    const { services = [] } = this
    return services.filter(s => s.isFree)
  }

  /**
   * Returns true if there are some free paid defined in the pricelist
   * @type {Boolean}
   */
  get hasPaidServices () {
    const { services = [] } = this
    return services.some(s => !s.isFree)
  }

  /**
   * Returns paid services defined in the pricelist
   * @type {Boolean}
   */
  get paidServices () {
    const { services = [] } = this
    return services.filter(s => !s.isFree)
  }

  /**
   * Returns true if per-device services defined in the pricelist
   * @type {Boolean}
   */
  get hasPerDeviceServices () {
    const { services = [] } = this
    return services.some(s => s.isPerDevice)
  }

  /**
   * Returns per-device services defined in the pricelist
   * @type {Boolean}
   */
  get perDeviceServices () {
    const { services = [] } = this
    return services.filter(s => s.isPerDevice)
  }

  /**
   * Returns true if specified feature is included
   * in some premium service of the pricelist
   * @param name Feature name
   */
  hasFeature (name) {
    const { services = [] } = this
    return services.some(s => s.hasFeature(name))
  }

  /**
   * Returns true if specified feature is included and is charged per-device
   * @param name Feature name
   */
  hasPerDeviceFeature (name) {
    const { services = [] } = this
    return services.some(s => s.hasFeature(name) && s.isPerDevice)
  }

  /**
   * Indicates whether the pricelist is enabled and can be purchased.
   * Use this to retire certain pricelists and prevent users from using them.
   * @type {Boolean}
   */
  isEnabled

  /**
   * Indicates a main pricelist
   * @type {Boolean}
   */
  isMain

  /**
   * Returns all premium services which are assigned
   * to the specified reseller or one of his ancestors
   * @param {Organization} reseller Reseller to check
   * @param {OrganizationHierarchy} hierarchy Full organization hierarchy,
   * used when reseller parent organization chain is not populated
   * @param {Boolean} includePrivate If true, even private services are included, even if reseller is not a super-organization
   * @returns {Array[PremiumService]}
   * @description The following rules are applied:
   * - All services explicitly assigned to the reseller are returned
   * - Public services assigned to reseller's ancestors are returned
   * - If reseller is a super organization, ONLY services not assigned to anybody else are returned
   */
  servicesAssignedTo (reseller, hierarchy, includePrivate) {
    if (!reseller) {
      return []
    }

    if (reseller.isSuperOrganization) {
      return []
    }

    const parents = hierarchy?.getParentsOf(reseller.id).map(p => p.id)

    return this.services.filter(service => {
      if (!service.resellerId) {
        return false
      }

      if (service.resellerId === reseller.id) {
        return true
      }

      if (service.isPublic || includePrivate) {
        if (parents) {
          return parents.includes(service.resellerId)

        } else {
          let parent = reseller.parentOrganization
          let parentId = reseller.parentOrganizationId
          while (parentId) {
            if (service.resellerId === parentId) {
              return true
            }
            parent = parent?.parentOrganization
            parentId = parent?.parentOrganizationId
          }
        }
      }
    })
  }

  /**
   * Returns true if reseller or one of his ancestors
   * have their own premium services assigned,
   * and are not using the public pricelist
   * @param {Organization} reseller Reseller to check
   * @param {OrganizationHierarchy} hierarchy Full organization hierarchy,
   * used when reseller parent organization chain is not populated
   * @returns {Boolean}
   */
  hasOwnPremiumServices (reseller, hierarchy) {
    return this.servicesAssignedTo(reseller, hierarchy).length > 0
  }

  /**
   * Returns all premium services which the specified reseller is allowed to sell
   * @param {Organization} reseller Reseller to check. If not specified,
   * services not explicitly assigned to any reseller are returned.
   * @param {OrganizationHierarchy} hierarchy Full organization hierarchy,
   * used when reseller parent organization chain is not populated
   * @param {Boolean} includePrivate If true, even private services are included, even if reseller is not a super-organization
   * @returns {Array[PremiumService]}
   * @description Reseller can sell a {@link PremiumService}, if any of the following is true:
   * - Service is not assigned to any specific reseller and reseller is a super organization
   * - Service is not assigned to any specific reseller and is public
   * - Service is assigned to the reseller
   * - Service is assigned to parent of the reseller and is public
   */
  resellerPremiumServices (reseller, hierarchy, includePrivate) {
    if (reseller) {
      const resellerServices = this.servicesAssignedTo(reseller, hierarchy, includePrivate)
      if (resellerServices.length === 0) {
        return this.services.filter(s => !s.resellerId && (reseller.isSuperOrganization || s.isPublic || includePrivate))
      } else {
        return resellerServices
      }
    } else {
      return []
    }
  }

  /**
   * Returns all premium services which can be sold by every
   * other reseller except resellers with their own services assigned.
   * @returns {Array[PremiumService]}
   * @description Private services are returned only when reseller is a super-organization
   */
  publicPremiumServices (reseller) {
    if (reseller && !this.hasOwnPremiumServices(reseller)) {
      return this.services.filter(s => !s.resellerId && (reseller.isSuperOrganization || s.isPublic))
    } else {
      return []
    }
  }
}
