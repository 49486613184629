import { state, getters } from './users.state'
import { mutations } from './users.mutations'
import { actions } from './users.actions'

export default {
  state,
  getters,
  mutations,
  actions
}
