import { dispatch } from '@stellacontrol/client-utilities'
import InventoryView from './inventory.vue'
import AddToInventoryView from './inventory-add/inventory-add.vue'

export const Routes = [
  {
    name: 'inventory',
    path: '/inventory',
    component: InventoryView,

    async beforeEnter (to, from, next) {
      await dispatch('getDeviceTypes')
      const data = {
        selection: (to.query.selection || '').split(',').filter(item => item.trim()),
        filterBySelection: Boolean(to.query.filterBySelection),
        organizationId: to.query.organization,
        placeId: to.query.place
      }
      await dispatch('setRouteData', { from, to, data })
      next()
    }
  },

  {
    name: 'inventory-add',
    path: '/inventory/add',
    component: AddToInventoryView,

    async beforeEnter (to, from, next) {
      await dispatch('getDeviceTypes')
      next()
    }
  }
]
