import { parseDate } from '@stellacontrol/utilities'
import { Assignable } from '../common/assignable'
import { getDeviceConnectionStatus } from './device-connection-status'

/**
 * Snapshot of current device status
 */
export class DeviceSnapshot extends Assignable {
  constructor (data = {}) {
    super()
    this.assign({ ...data }, {
      updatedAt: parseDate,
      status: parseDate,
      alertTime: parseDate,
      updateDeferred: Boolean
    })
  }

  normalize () {
    super.normalize()
    if (this.status) {
      this.connection = getDeviceConnectionStatus(this.status)
    }
  }

  /**
   * Device identifier
   * @type {String}
   */
  id

  /**
   * Update date and time
   * @type {Date}
   */
  updatedAt

  /**
   * Date and time of the connection status
   * @type {Date}
   */
  status

  /**
   * Current connection status, based on {@link status} time
   * @type {DeviceConnectionStatus}
   */
  connection

  /**
   * Current version of the firmware on the device
   * @type {String}
   */
  firmwareVersion

  /**
   * Current version of device hardware
   * @type {String}
   */
  hardwareVersion

  /**
   * Current version of device EEPROM
   * @type {String}
   */
  eepromVersion

  /**
   * Current version of communication protocol on the device
   * @type {String}
   */
  megaVersion

  /**
   * Last alert triggered by the device
   * @type {AlertType}
   */
  alert

  /**
   * Date and time of the alert
   * @type {Date}
   */
  alertTime

  /**
   * Identifier of the alert record
   * @type {String}
   */
  alertId

  /**
   * Indicates whether some device alert configurations have been customized.
   * The value is a bitmask of {@link AlertTypeFlags}.
   * @type {Number}
   */
  alertsCustomized

  /**
   * Indicates whether some device alerts have been muted
   * The value is a bitmask of {@link AlertTypeFlags}.
   * @type {Number}
   */
  alertsMuted
}
