import { sortItems } from '@stellacontrol/utilities'
import { EntityType } from '@stellacontrol/model'

export const mutations = {
  /**
   * Starts the search for entities
   * @param {String} condition Condition to search for
   */
  startEntitySearch (state, { condition } = {}) {
    state.groups = {}
    state.condition = condition
    state.isSearching = true
  },

  /**
   * Clears the last search results
   */
  clearEntitySearch (state) {
    state.groups = {}
    state.condition = ''
    state.isSearching = false
  },

  /**
   * Stops the running search, leaving intact the results collected so far
   */
  stopEntitySearch (state) {
    state.isSearching = false
  },

  /**
   * Adds search results
   * @param {Array[Entity]} items Found items
   */
  storeEntitySearchResults (state, { items = [] } = {}) {
    for (const item of items) {
      const type = item.getType ? item.getType() : null
      if (type) {
        if (!state.groups[type]) {
          state.groups[type] = []
        }
        state.groups[type].push(item)
      }
    }

    for (const [type, items] of Object.entries(state.groups)) {
      switch (type) {
        case EntityType.Device:
          state.groups[type] = sortItems(items, 'serialNumber')
          break
        case EntityType.Place:
        case EntityType.User:
        case EntityType.Organization:
        case EntityType.OrganizationProfile:
          state.groups[type] = sortItems(items, 'name')
          break
      }
    }
  }
}
