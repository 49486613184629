import { RectangleShape } from './rectangle'
import { CircleShape } from './circle'
import { EllipseShape } from './ellipse'
import { LineShape } from './line'
import { PolygonShape } from './polygon'
import { TextShape } from './text'
import { CurveShape } from './curve'
import { DeviceShape } from './device'
import { AntennaShape } from './antenna'
import { ConnectorShape } from './connector'
import { CableShape } from './cable'
import { ImageShape } from './image'
import { SplitterShape } from './splitter'
import { BeamShape } from './beam'
import { LegendShape } from './legend'
import { PlugShape } from './plug'
import { RulerShape } from './ruler'

/**
 * All available shapes
 */
const Shapes = [
  RectangleShape,
  CircleShape,
  EllipseShape,
  LineShape,
  PolygonShape,
  TextShape,
  CurveShape,
  DeviceShape,
  AntennaShape,
  ConnectorShape,
  CableShape,
  ImageShape,
  SplitterShape,
  BeamShape,
  LegendShape,
  PlugShape,
  RulerShape
]

/**
 * Internal unique counter for created shapes
 */
let shapeUUID = 1

/**
 * Creates a shape representing the specified plan item
 * @param {PlanItem} item Plan item
 * @param {Function<PlanItem, any>} dataCallback Callback for fetching additional data for the specified plan item
 * @returns {Shape} Shape representing the plan item
 */
export function createShape (item, dataCallback) {
  if (!item) throw new Error('Plan item is required')

  const itemClass = item.constructor?.type
  const ShapeClass = Shapes.find(shape => shape.type === itemClass)
  if (!ShapeClass) return
  if (!ShapeClass) throw new Error(`Unsupported item type [{${itemClass || 'unknown class'}}]`)

  const shape = new ShapeClass(item, dataCallback)

  shape.content.setAttr('uuid', shapeUUID++)

  return shape
}
