import { ServiceManagementAPI } from '@stellacontrol/client-api'
import { Notification, isRouteLeft } from '@stellacontrol/client-utilities'
import { AppletRoute } from '../../router'

export const actions = {
  /**
   * Initializes the premium subscribers view
   * @param {String} customerId Identifier of customer to load initially. If not specified, current organization is assumed.
   */
  async initializePremiumSubscribersView ({ dispatch, commit }, { customerId } = {}) {
    commit('storeCustomerWallet', { customer: null, wallet: null, guardian: null, devices: null })

    const customer = customerId
      ? await dispatch('getOrganization', { id: customerId, withDetails: true })
      : undefined

    if (customer) {
      const guardian = await dispatch('getGuardian', { principal: customer, force: true })
      const devices = await dispatch('getOwnDevices', { organization: customer })
      const wallet = await ServiceManagementAPI.getWallet({ organization: customer })
      commit('storeCustomerWallet', { customer, wallet, guardian, devices })
    }
  },

  /**
   * Credits tokens into the customer's wallet
   * @param {Organization} customer Customer to credit tokens to.
   * If not specified, customer from state is assumed.
   * @param {Number} amount Amount of tokens to credit
   * @param {String} notes User's notes related to the transaction
   * @param {Boolean} silent If true, no notification is shown about the result of the operation
   */
  async creditTokens ({ commit, getters, state }, { customer, amount, notes, silent }) {
    const { organizationGuardian } = getters
    if (!customer) {
      customer = state.customer
    }
    if (customer && amount > 0 && organizationGuardian.isBank) {
      const transaction = await ServiceManagementAPI.credit({ organization: customer, amount, notes })
      if (transaction && transaction.after) {
        commit('storeCustomerWallet', { customer, wallet: transaction.after })
        const message = `${amount} token${amount === 1 ? '' : 's'} credited to ${customer.name} wallet`
        Notification.success({ message, silent })
      }
    }
  },

  /**
   * Debits tokens from customer's wallet
   * @param {Organization} customer Customer to debit tokens from.
   * If not specified, customer from state is assumed.
   * @param {Number} amount Amount of tokens to debit
   * @param {String} notes User's notes related to the transaction
   * @param {Boolean} silent If true, no notification is shown about the result of the operation
   */
  async debitTokens ({ commit, state }, { customer, amount, notes, silent }) {
    if (!customer) {
      customer = state.customer
    }
    if (customer && amount > 0 && amount <= state.wallet.balance) {
      const transaction = await ServiceManagementAPI.debit({ organization: customer, amount, notes })
      if (transaction && transaction.after) {
        commit('storeCustomerWallet', { customer, wallet: transaction.after })
        const message = `${amount} token${amount === 1 ? '' : 's'} debited from ${customer.name} wallet`
        Notification.success({ message, silent })
      }
    }
  },

  /**
   * Transfers tokens from current organization's wallet
   * into the viewed organization's wallet
   * @param {Organization} customer Customer to transfer tokens to.
   * If not specified, customer from state is assumed.
   * @param {Number} amount Amount of tokens to transfer
   * @param {String} notes User's notes related to the transaction
   * @param {Boolean} silent If true, no notification is shown about the result of the operation
   */
  async transferTokensTo ({ commit, getters, state }, { customer, amount, notes, silent }) {
    const { currentOrganization: sender, organizationGuardian } = getters
    const receiver = customer || state.customer
    const { myWallet } = getters
    if (receiver && amount > 0 && !organizationGuardian.isBank && amount <= myWallet.balance) {
      const transaction = await ServiceManagementAPI.transfer({ sender, receiver, amount, notes })
      if (transaction && transaction.sender && transaction.receiver) {
        commit('storeMyWallet', { wallet: transaction.sender.after })
        commit('storeCustomerWallet', { customer: receiver, wallet: transaction.receiver.after })

        const message = `${amount} token${amount === 1 ? '' : 's'} transferred from your wallet to ${receiver.name} wallet`
        Notification.success({ message, silent })
      }
    }
  },

  /**
   * Transfers tokens from viewed organization's wallet
   * back into the current organization's wallet
   * @param {Organization} customer Customer to transfer tokens from.
   * If not specified, customer from state is assumed.
   * @param {Number} amount Amount of tokens to transfer
   * @param {String} notes User's notes related to the transaction
   * @param {Boolean} silent If true, no notification is shown about the result of the operation
   */
  async transferTokensFrom ({ commit, getters, state }, { customer, amount, notes, silent }) {
    const { wallet } = state
    const { currentOrganization: receiver } = getters
    const sender = customer || state.customer
    if (sender && amount > 0 && amount <= wallet.balance) {
      const transaction = await ServiceManagementAPI.transfer({ sender, receiver, amount, notes })
      if (transaction && transaction.sender && transaction.receiver) {
        commit('storeMyWallet', { wallet: transaction.receiver.after })
        commit('storeCustomerWallet', { customer: sender, wallet: transaction.sender.after })

        const message = `${amount} token${amount === 1 ? '' : 's'} transferred from ${sender.name} back into your wallet`
        Notification.success({ message, silent })
      }
    }
  },

  /**
   * Reset state if view closed
   */
  async navigationEnded ({ commit }, { from, to } = {}) {
    if (isRouteLeft(AppletRoute.PremiumSubscribers, from, to)) {
      commit('resetPremiumSubscribersView')
    }
  }
}
