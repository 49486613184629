import { mapActions, mapState, mapGetters } from 'vuex'

/**
 * View mixin
 */
export const ViewMixin = {
  computed: {
    ...mapState({
      viewConfigurations: state => state.client.configuration.client.views
    }),

    ...mapGetters([
      'getView',
      'currentRoute',
      'getRouteData',
      'getViewTitle'
    ]),

    // Configuration of this view
    viewConfiguration () {
      return this.viewConfigurations[this.viewName]
    },

    // Indicates that view mixin has been introduced
    hasViewMixin () {
      return true
    },

    /**
     * Data associated with the route
     */
    data () {
      const { data } = this.getRouteData(this.name) || {}
      return data
    },

    /**
     * Route where we came from to here
     */
    fromRoute () {
      const { from } = this.getRouteData(this.name) || {}
      return from
    },

    /**
     * Target route where we just landed
     */
    toRoute () {
      const { to } = this.getRouteData(this.name) || {}
      return to
    },

    /**
     * View name, derived from the route that is associated with the view
     * or optionally set explicitly as the `name` attribute
     */
    viewName () {
      const { view } = this.getRouteData(this.name) || {}
      return view ? view.name : this.name
    },

    /**
     * View definition
     */
    view () {
      const { hasViewMixin, viewName } = this
      if (hasViewMixin) {
        if (!viewName) {
          throw new Error('View name must be specified on the route leading to this view')
        }
        const view = this.getView(viewName)
        if (!view) {
          throw new Error(`View ${viewName} is not registered yet`)
        }
        return view
      }
    },

    /**
     * Indicates that there is data associated with the view
     */
    hasData () {
      return Boolean(this.data)
    },

    /**
     * Returns true if view is visible
     */
    isVisible () {
      return this.view && this.view.isVisible
    },

    /**
     * Returns true if view is not visible
     */
    isHidden () {
      return this.view && !this.view.isVisible
    },

    /**
     * Returns true if view is currently busy,
     * during operations such as save etc.
     */
    isBusy () {
      return this.view && this.view.isBusy
    },

    /**
     * Returns true if view is currently not busy
     */
    isDone () {
      return this.view && !this.view.isBusy
    }
  },

  methods: {
    ...mapActions([
      'viewBusy'
    ]),

    // Marks the view as busy
    busy () {
      const { view } = this
      if (view) {
        this.viewBusy({ view, isBusy: true })
      }
    },

    // Marks the view as not busy
    done () {
      const { view } = this
      if (view) {
        this.viewBusy({ view, isBusy: false })
      }
    }
  }
}
