<script>

/**
 * Not-Authorized component
 */
export default {
  props: [
    'title',
    'icon',
    'iconColor',
    'color',
    'details'
  ],

  data () {
    return {
      defaultTitle: 'Not authorized'
    }
  }
}

</script>

<template>
  <main>
    <table>
      <tbody>
        <tr>
          <td class="icon" rowspan="2">
            <q-icon
              :name="icon || 'cancel'"
              :color="iconColor || 'red-5'"
              size="xl"
            />
          </td>
          <td class="title">
            {{ title || defaultTitle }}
          </td>
        </tr>
        <tr>
          <td class="details">
            {{ details }}
          </td>
        </tr>
      </tbody>
    </table>
  </main>
</template>

<style lang="scss" scoped>
main {
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;

  .icon {
    padding-right: 12px;
  }

  .title {
    font-size: 17px;
  }

  .details {
    padding-top: 8px;
    font-size: 15px;
  }
}
</style>
