<script>
const defaultMessage = `
  You are not authorized to access this page.
`

/**
 * Not-authorized view
 */
export default {
  computed: {
    message () {
      return defaultMessage
    }
  }
}

</script>

<template>
  <q-layout>
    <q-page-container>
      <q-page class="column items-center justify-center">
        <div class="q-mb-lg">
          <label class="message text-h6 text-weight-light">
            {{ message }}
          </label>
        </div>
        <sc-back-to-login if-logged-out label="Go back to login page">
        </sc-back-to-login>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<style lang="scss" scoped>
  .message {
    max-width: 450px;
  }
</style>
