export const actions = {
  /**
   * Initializes application views
   * @param views View hierarchy
   */
  initializeViews ({ commit }, { views }) {
    commit('initializeViews', { views })
  },

  /**
   * Initializes the specified view when it's shown
   * @param route Route that has led to the view
   * @param view View to navigate to
   * @param name Alternative way to specify view, by name
   * @param title Optional, custom view title to display, useful with record editing views
   */
  async showView ({ commit, getters }, { route, view, name, title } = {}) {
    // Leave if view not specified or component associated with the view
    // is not defined  - typically this would be because the view hasn't
    // been developed yet, so we use a placeholder instead
    view = view || getters.getView(name)
    if (view) {
      // Mark the view as current
      commit('showView', { view, route, title })

      // Select tab, if specified in route query
      const { tab } = route.query || {}
      if (tab) {
        commit('selectViewTab', { view, tab })
      } else {
        // ...else the last selected tab, if specified,
        // otherwise the default tab specified in views.json
        if (!view.tab && view.tabs) {
          commit('selectViewTab', { view, tab: getters.getDefaultViewTab(view.name) })
        }
      }

    } else {
      throw new Error(`Invalid view ${name || (view ? view.name : '')}`)
    }
  },

  /**
   * Indicates that user has left the specified view
   * @param route Route that has led to the view
   * @param view View just left
   * @param name Alternative way to specify view, by name
   */
  async leaveView ({ commit, getters }, { route, view, name }) {
    view = view || getters.getView(name)
    if (view) {
      commit('leaveView', { view, route })
    } else {
      throw new Error(`Invalid view ${name || (view ? view.name : '')}`)
    }
  },

  /**
   * Marks the view as busy
   * @param view View to mark as busy
   * @param name Alternative way to specify view, by name
   */
  async viewBusy ({ commit, getters }, { view, name, isBusy = true }) {
    view = view || getters.getView(name)
    if (view) {
      commit('viewBusy', { view, isBusy })
    }
  },

  /**
   * Marks the view as not busy
   * @param {View} view View to mark as not busy
   * @param {String} name Alternative way to specify view, by name
   */
  async viewNotBusy ({ commit, getters }, { view, name }) {
    view = view || getters.getView(name)
    if (view) {
      commit('viewBusy', { view, isBusy: false })
    }
  },

  /**
   * Selects the specified tab on the view
   * @param {View} view View to mark as not busy
   * @param {String} name Alternative way to specify view, by name
   * @param {String} tab Tab to activate
   */
  async selectViewTab ({ commit, dispatch, getters }, { view, tab }) {
    view = view || getters.getView(name)
    if (view) {
      // Store on state
      commit('selectViewTab', { view, tab })
      // Show in the URL
      dispatch('updateRoute', { query: { tab } })
    }
  }
}
