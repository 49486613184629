<script>
import Widget from './widget.vue'

export default {
  props: {
    // Label to show
    label: {
      type: String
    },

    // Value to show
    value: {
      type: String
    },

    // Label class
    labelClass: {
      type: Object
    },

    // Value class
    valueClass: {
      type: Object
    },

    // Value color
    color: {
      type: String,
      default: 'grey-10'
    },

    // Label style
    labelStyle: {
      type: Object
    },

    // Value style
    valueStyle: {
      type: Object
    },

    // Icon to show in the widget
    icon: {
      type: String
    },

    // Icon size
    iconSize: {
      type: String,
      default: '64px'
    },

    // Icon color
    iconColor: {
      type: String,
      default: 'indigo-5'
    },

    // Icon style
    iconStyle: {
      type: Object
    },

    // Icon class
    iconClass: {
      type: String,
      default: ''
    },

    // tooltip
    tooltip: {
      type: String
    }
  },

  components: {
    'sc-widget': Widget
  },

  computed: {
    hasIcon () {
      return this.icon || this.$slots['icon']
    },

    hasParameter () {
      return this.label || this.value || this.$slots['parameter']
    }
  },

  methods: {
    // Executes the action
    executeAction (action) {
      this.$emit('action', action)
    }
  }
}
</script>

<template>
  <sc-widget v-bind="{...$props, ...$attrs}" class="parameter-widget">

    <sc-tooltip v-if="tooltip" :text="tooltip"></sc-tooltip>

    <div class="widget-content">
      <div class="icon row items-center" v-if="hasIcon">
        <slot name="icon">
          <q-icon
            :size="iconSize"
            :color="iconColor"
            :name="icon"
            :style="iconStyle"
            :class="iconClass ? { [iconClass]: true } : undefined">
          </q-icon>
        </slot>
      </div>

      <div class="parameters q-gutter-sm" v-if="hasParameter">
        <slot name="parameter">
          <div v-html="label" class="label" :class="labelClass" :style="labelStyle"></div>
          <div v-html="value" class="value" :class="{ ...valueClass, ['text-'+color]: true }" :style="valueStyle"></div>
        </slot>
      </div>
    </div>

    <slot name="toolbar">
    </slot>

  </sc-widget>
</template>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: row;
  padding: 10px;

  > .icon {
    flex: 0;
    padding-left: 0;
    padding-right: 0;
  }

  > .parameters {
    flex: 1;
    display: flex;
    flex-direction: column;

    .label {
      font-size: 18px;
      font-weight: normal;
      color: #7a7a7a;
      text-align: center;
    }

    .value {
      font-size: 28px;
      font-weight: bold;
      color: #374550;
      text-align: center;
    }
  }
}

/* Layout adjustments for screen below HD resolution */
@media screen and (max-width: 1365px) {
  .content {
    padding: 4px;

    > .parameters {

      .label {
        font-size: 15px;
      }

      .value {
        font-size: 20px;
      }
    }
  }
}

</style>
