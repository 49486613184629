<script>
import { mapMutations, mapActions, mapState, mapGetters } from 'vuex'
import { ViewMixin } from '@stellacontrol/client-utilities'
import { OrganizationSortOrder, sortOrganizations, getPlaceIcon, getOrganizationIcon, getOrganizationColor, getOrganizationLevelName } from '@stellacontrol/model'
import { Secure } from '@stellacontrol/security-ui'
import FloorPlansOfOrganization from './floor-plans-of-organization.vue'

const name = 'floor-plans'

export default {
  mixins: [
    ViewMixin,
    Secure
  ],

  components: {
    'sc-floor-plans-of-organization': FloorPlansOfOrganization
  },

  data () {
    return {
      filter: '',
      name
    }
  },

  computed: {
    ...mapState({
      floorPlans: state => state.floorPlans.items,
      expandedOrganizations: state => state.floorPlans.expandedOrganizations
    }),

    ...mapGetters([
      'organizationHierarchy',
      'organizations'
    ]),

    // Indicates whether there are any floor plans under this organization
    hasFloorPlans () {
      return this.floorPlans.length > 0
    },

    // Returns floor plans under the specified organization
    organizationFloorPlans () {
      const { filter } = this
      return id => this.floorPlans
        .filter(fp => fp.organizationId === id)
        .filter(fp => {
          if (filter) {
            const text = [
              fp.organization.name,
              fp.name,
              fp.place ? fp.place.name : '',
              fp.reseller ? fp.reseller.name : '',
              fp.updater.fullName
            ].join('`').toLowerCase()
            return text.includes(filter)
          } else {
            return true
          }
        })
    },

    // Number of floor plans under the specified organization
    organizationFloorPlanCount () {
      return id => this.organizationFloorPlans(id).length
    },

    // Indicates whether there are any floor plans under the specified organization
    organizationHasFloorPlans () {
      return id => this.organizationFloorPlans(id).length > 0
    },

    // Organizations which have floor plans
    visibleOrganizations () {
      return sortOrganizations(
        this.organizations.filter(o => this.organizationHasFloorPlans(o.id)),
        OrganizationSortOrder.Rank
      )
    },

    // Returns true if specified organization's plans are now visible
    isOrganizationExpanded () {
      return ({ id }) => this.expandedOrganizations.includes(id)
    },

    // Returns reseller associated with the specified organization
    getResellerOf () {
      return ({ id }) => {
        if (this.currentOrganization.isResellerOrganization) {
          return this.currentOrganization
        } else {
          return this.organizationHierarchy.findResellerOf(id)
        }
      }
    }
  },

  methods: {
    ...mapMutations([
      'toggleAllFloorPlans',
      'toggleOrganizationFloorPlans'
    ]),
    ...mapActions([
      'createFloorPlan',
      'editFloorPlan',
      'removeFloorPlan'
    ]),

    getPlaceIcon,
    getOrganizationIcon,
    getOrganizationColor,
    getOrganizationLevelName,

    // Filters the floor plans
    filterFloorPlans (filter) {
      const { organizations } = this
      this.filter = (filter || '').toLowerCase().trim()
      if (this.filter) {
        // Expand all organizations if filter applied, to reveal the search results
        this.toggleAllFloorPlans({ isExpanded: true, organizations })
      }
    },

    executeAction (floorPlan, action) {
      switch (action.name) {
        case 'edit':
          return this.editFloorPlan({ floorPlan })
        case 'delete':
          return this.removeFloorPlan({ floorPlan, confirm: true })
        default:
          throw new Error(`Unhandled action ${action.name}`)
      }
    }
  }
}
</script>

<template>
  <sc-view :name="name">
    <template #toolbar>
      <q-btn unelevated icon="add" label="Add Floor Plan" class="primary" @click="createFloorPlan()" v-if="canUse('edit-floor-plans')" />
    </template>

    <div class="q-ma-md" v-if="!hasFloorPlans">
      <div class="q-mb-md">
        You can add here floor plans for buildings and other places where devices will be installed.
      </div>
      <q-btn color="deep-purple-8" icon="add" label="Add Floor Plan" @click="createFloorPlan()"/>
    </div>

    <template #header>
      <sc-list-filter v-if="hasFloorPlans"
        :name="name"
        :allow-changing-view-type="false"
        @filter="({ filter }) => filterFloorPlans(filter)">
        <template #buttons>
          <q-btn no-caps dense unelevated :ripple="false" class="q-mr-sm"
            :label="expandedOrganizations.length === 0 ? 'Expand all' : 'Collapse all'"
            :icon="expandedOrganizations.length === 0 ? 'chevron_right' : 'expand_more'"
            @click="toggleAllFloorPlans({ organizations })">
          </q-btn>
        </template>
      </sc-list-filter>
    </template>

    <q-markup-table flat borderless v-if="hasFloorPlans" class="table">
      <tbody>
        <template v-for="organization in visibleOrganizations">

          <tr class="organization"
            :class="{ expanded: isOrganizationExpanded(organization) }"
            @click="toggleOrganizationFloorPlans(organization)">
            <td class="name">
              <div class="row items-center">
                <q-icon :name="getOrganizationIcon(organization)" :color="getOrganizationColor(organization)" size="sm" class="q-mr-sm" />
                <span>{{ organization.name }}</span>
              </div>
            </td>
            <td class="count">
              <div class="row items-center no-wrap">
                <div style="width: 80px;">
                  {{ organizationFloorPlanCount(organization.id) }} floor plan{{ organizationFloorPlanCount(organization.id) === 1 ? '' : 's' }}
                </div>
                <q-btn dense flat round class="q-ml-xs"
                  :icon="isOrganizationExpanded(organization) ? 'expand_more' : 'chevron_right'"
                  @click.stop="toggleOrganizationFloorPlans(organization)"/>
              </div>
            </td>
            <td class="buttons">
            </td>
            <td class="fill">
            </td>
          </tr>

          <tr class="floor-plans" :key="`fp-${organization.id}`" v-if="isOrganizationExpanded(organization)">
            <td colspan="4">
              <sc-floor-plans-of-organization
                :organization="organization"
                :reseller="getResellerOf(organization)"
                :floor-plans="organizationFloorPlans(organization.id)">
              </sc-floor-plans-of-organization>
            </td>
          </tr>
        </template>
      </tbody>

    </q-markup-table>
  </sc-view>
</template>

<style lang='scss' scoped>
.table {
  border-bottom: solid #e0e0e0 1px;

  tr.organization {
    td {
      cursor: pointer;
      padding-top:16px;

      &.name {
        width: 250px;
      }
      &.profile {
        width: 250px;
      }
      &.count {
        width: 650px;
      }
      &.buttons {
        width: 200px;
        text-align: right;
      }
    }

    &.expanded {
      background: linear-gradient(180deg, #4242421f 0%, transparent 10%, transparent 100%);

      &:first-child {
        background: transparent;
      }

      td {
        border-bottom-color: transparent;
      }
    }
  }

  tr.floor-plans {
    /* removes hover background introduced by Quasar */
    > td::before {
      background: none;
    }

    > td {
      height: auto;
      padding: 4px 32px 32px 47px;
    }
  }
}
</style>
