<script>
import { getDurationString } from '@stellacontrol/utilities'
import { getMegaValue } from '@stellacontrol/mega'
import ParameterEditor from './parameter-editor'

export default {
  mixins: [
    ParameterEditor
  ],

  data () {
    return {
    }
  },

  computed: {
    // Parameter value to display
    value () {
      const { device, parameter: name, status, status: { mega } } = this
      const { label } = getMegaValue(name, mega, device, status)
      return label
    }
  },

  methods: {
    getDurationString
  }
}
</script>

<template>
  <div class="parameter-editor">

    <sc-parameter-value label="Unattended Scan Interval" :reported="reported" :custom="custom"
      :format="() => label || '-'">
    </sc-parameter-value>

  </div>
</template>
