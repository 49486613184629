import { PlanLayers } from '@stellacontrol/planner'
import { PlanActions, executePlanAction } from '../actions'
import { PlanEvent } from './plan-event'

/**
 * Utility class for handling keyboard events on the plan
 */
export class KeyboardEvents {
  /**
   * Initializes the keyboard events service
   * @param {PlanRenderer} renderer
   */
  constructor (renderer) {
    this.__renderer = renderer
  }

  __keyEventHandler
  __renderer

  /**
   * Binds keyboard event handlers
   * @param {PlanRenderer} renderer Plan renderer
   */
  bindEvents () {
    this.__keyEventHandler = async (e) => await this.keyPressed(e)
    window.addEventListener('keydown', this.__keyEventHandler)
  }

  /**
   * Keyboard event handler
   * @param {KeyboardEvent} e Event details
   */
  async keyPressed (e) {

    // Ignore keystrokes if renderer is not in focus
    const { __renderer: renderer } = this
    if (!renderer.isFocused) {
      return
    }

    // Global keyboard events
    switch (e.key) {
      // Select all items
      case 'a':
        if (PlanEvent.isCtrlKey(e)) {
          PlanEvent.cancelEvent(e)
          await executePlanAction({
            renderer,
            action: PlanActions.SelectAllItems,
            layer: PlanLayers.Items
          })
        }
        break

      // Pastes the copied items
      case 'v':
        if (PlanEvent.isCtrlKey(e)) {
          PlanEvent.cancelEvent(e)
          await executePlanAction({
            renderer,
            action: PlanActions.PasteItems,
            position: renderer.currentPosition || renderer.selectedPosition
          })
        }
        break
    }

    // Events which require some items to be selected
    const isCtrl = PlanEvent.isCtrlKey(e)
    const isShift = PlanEvent.isShiftKey(e)
    const delta = isCtrl ? renderer.layout.gridSize : 1
    const { selectedItems: items, isAddingPoints, isDrawingMapScale, isDrawingRuler, history } = renderer

    // If creating lines, polygons,
    // ENTER should finish and ESC should cancel
    if (isAddingPoints) {
      switch (e.key) {
        case 'Escape':
          PlanEvent.cancelEvent(e)
          renderer.stopAddingItem()
          break

        case 'Enter':
          PlanEvent.cancelEvent(e)
          renderer.selectItem({
            item: renderer.finishAddingItem()
          })
          break
      }
    }

    // If drawing map scales and rulers,
    // ESC should cancel
    if (isDrawingMapScale || isDrawingRuler) {
      switch (e.key) {
        case 'Escape':
          PlanEvent.cancelEvent(e)
          renderer.drawMapScale(false)
          break
      }
    }

    // Undo/Redo
    if (isCtrl) {
      switch (e.key) {
        case 'z':
          history.undo({ renderer })
          PlanEvent.cancelEvent(e)
          break
        case 'y':
          history.redo({ renderer })
          PlanEvent.cancelEvent(e)
          break
      }
    }

    // Actions on selected items
    if (items.length > 0) {
      switch (e.key) {
        // Duplicates the selected items
        case 'd':
          if (PlanEvent.isCtrlKey(e)) {
            PlanEvent.cancelEvent(e)
            await executePlanAction({
              renderer,
              action: PlanActions.DuplicateItems,
              items
            })
          }
          break

        // Copies the selected items to clipboard
        case 'c':
          if (PlanEvent.isCtrlKey(e)) {
            PlanEvent.cancelEvent(e)
            await executePlanAction({
              renderer,
              action: PlanActions.CopyItems,
              items
            })
          }
          break

        // Rotates the selected items
        case 'r':
          if (PlanEvent.isCtrlKey(e)) {
            PlanEvent.cancelEvent(e)
            await executePlanAction({
              renderer,
              action: PlanActions.RotateItems,
              items,
              angle: 45
            })
          }
          break

        // Delete the currently selected items
        case 'Delete':
          PlanEvent.cancelEvent(e)
          await executePlanAction({ renderer, action: PlanActions.RemoveItems, items })
          break

        // Move/Resize selected items
        case 'ArrowLeft':
          PlanEvent.cancelEvent(e)
          await executePlanAction({
            renderer,
            action: isShift ? PlanActions.ResizeItems : PlanActions.MoveItems,
            items,
            x: -delta,
            snapToGrid: isCtrl
          })
          break

        case 'ArrowRight':
          PlanEvent.cancelEvent(e)
          await executePlanAction({
            renderer,
            action: isShift ? PlanActions.ResizeItems : PlanActions.MoveItems,
            items,
            x: delta,
            snapToGrid: isCtrl
          })
          break

        case 'ArrowUp':
          PlanEvent.cancelEvent(e)
          await executePlanAction({
            renderer,
            action: isShift ? PlanActions.ResizeItems : PlanActions.MoveItems,
            items,
            y: -delta,
            snapToGrid: isCtrl
          })
          break

        case 'ArrowDown':
          PlanEvent.cancelEvent(e)
          await executePlanAction({
            renderer,
            action: isShift ? PlanActions.ResizeItems : PlanActions.MoveItems,
            items,
            y: delta,
            snapToGrid: isCtrl
          })
          break
      }
    }
  }

  /**
   * Unbinds any global event handlers.
   * Should be called before the class is destroyed
   */
  unmount () {
    window.removeEventListener('keydown', this.__keyEventHandler)
    this.__keyEventHandler = null
    this.__renderer = null
  }
}
