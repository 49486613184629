import { PlanItemType, PlanRectangle, PlanCircle, PlanPolygon, PlanLine, PlanDevice, PlanAntenna, PlanText, PlanSplitter, PlanBeam, PlanLegend, AntennaType, SplitterType } from '../items'
import { PlanPaletteItem } from './plan-palette-item'

/**
 * Plan palette settings
 */
export const PlanPalette = {
  /**
   * Icons representing plan items
   */
  icons: {
    [PlanItemType.Rectangle]: 'crop_16_9',
    [PlanItemType.Circle]: 'circle',
    [PlanItemType.Polygon]: 'signal_cellular_0_bar',
    [PlanItemType.Line]: 'timeline',
    [PlanItemType.Cable]: 'timeline',
    [PlanItemType.Connector]: 'timeline',
    [PlanItemType.Text]: 'text_fields',
    [PlanItemType.Device]: 'router',
    [PlanItemType.Antenna]: 'wifi_tethering',
    [PlanItemType.Splitter]: 'call_split',
    [PlanItemType.Beam]: 'wifi',
    [PlanItemType.Legend]: 'list_alt'
  },

  /**
   * Item categories
   */
  categories: [
    {
      label: 'Device',
      isAdvancedMode: false,
      items: [
        new PlanPaletteItem({
          type: PlanItemType.Device,
          label: 'Repeater',
          color: 'blue-9',
          factory: data => PlanDevice.createRepeater(data),
          isCrossSection: true
        }),
        new PlanPaletteItem({
          type: PlanItemType.Device,
          label: 'LineAmp',
          color: 'orange-9',
          factory: data => PlanDevice.createLineAmp(data),
          isCrossSection: true
        })
      ]
    },
    {
      label: 'Antenna',
      isAdvancedMode: false,
      items: [
        new PlanPaletteItem({
          type: PlanItemType.Antenna,
          label: 'Omni',
          color: 'green-9',
          factory: data => PlanAntenna.createAntenna({ ...data, antennaType: AntennaType.IndoorOmni }),
          isCrossSection: true
        }),
        new PlanPaletteItem({
          type: PlanItemType.Antenna,
          label: 'Outdoor',
          color: 'green-9',
          icon: 'settings_input_antenna',
          factory: data => PlanAntenna.createAntenna({ ...data, antennaType: AntennaType.OutdoorOmni }),
          isCrossSection: true
        }),
        new PlanPaletteItem({
          type: PlanItemType.Antenna,
          label: 'Yagi',
          color: 'green-9',
          icon: 'play_arrow',
          factory: data => PlanAntenna.createAntenna({ ...data, antennaType: AntennaType.Yagi }),
          isCrossSection: true
        }),
        new PlanPaletteItem({
          type: PlanItemType.Antenna,
          label: 'Wall',
          color: 'green-9',
          icon: 'crop_portrait',
          factory: data => PlanAntenna.createAntenna({ ...data, antennaType: AntennaType.WallPanel }),
          isCrossSection: true
        }),
        new PlanPaletteItem({
          type: PlanItemType.Antenna,
          label: 'Ceiling',
          icon: 'crop_square',
          color: 'green-9',
          factory: data => PlanAntenna.createAntenna({ ...data, antennaType: AntennaType.CeilingPanel }),
          isCrossSection: true
        }),
        new PlanPaletteItem({
          type: PlanItemType.Antenna,
          label: 'Laser',
          color: 'green-9',
          icon: 'linear_scale',
          factory: data => PlanAntenna.createAntenna({ ...data, antennaType: AntennaType.Laser }),
          isCrossSection: true
        }),
      ]
    },
    {
      label: 'Splitter',
      isAdvancedMode: false,
      items: [
        new PlanPaletteItem({
          type: PlanItemType.Splitter,
          label: 'Two-Way',
          color: 'yellow-9',
          icon: 'call_split',
          factory: data => PlanSplitter.createSplitter({ ...data, splitterType: SplitterType.SplitterTwoWay }),
          isCrossSection: true
        }),
        new PlanPaletteItem({
          type: PlanItemType.Splitter,
          label: 'Three-Way',
          color: 'yellow-9',
          icon: 'call_split',
          factory: data => PlanSplitter.createSplitter({ ...data, splitterType: SplitterType.SplitterThreeWay }),
          isCrossSection: true
        }),
        new PlanPaletteItem({
          type: PlanItemType.Splitter,
          label: 'Four-Way',
          color: 'yellow-9',
          icon: 'call_split',
          factory: data => PlanSplitter.createSplitter({ ...data, splitterType: SplitterType.SplitterFourWay }),
          isCrossSection: true
        }),
        new PlanPaletteItem({
          type: PlanItemType.Splitter,
          label: 'Tapper',
          isEnabled: false,
          color: 'yellow-9',
          icon: 'vertical_align_bottom',
          factory: data => PlanSplitter.createSplitter({ ...data, splitterType: SplitterType.TapperTwoWay }),
          isCrossSection: true
        }),
      ]
    },
    {
      label: 'Descriptions',
      isAdvancedMode: false,
      items: [
        new PlanPaletteItem({
          type: PlanItemType.Text,
          label: 'Text',
          color: 'indigo-7',
          factory: data => PlanText.create(data),
          isCrossSection: true
        }),
        new PlanPaletteItem({
          type: PlanItemType.Legend,
          label: 'Legend',
          color: 'blue-9',
          factory: data => PlanLegend.createLegend(data),
          isCrossSection: true
        })
      ]
    },
    {
      label: 'Shapes',
      isAdvancedMode: true,
      items: [
        new PlanPaletteItem({
          type: PlanItemType.Rectangle,
          label: 'Box',
          color: 'indigo-7',
          factory: data => PlanRectangle.create(data),
          isCrossSection: false
        }),
        new PlanPaletteItem({
          type: PlanItemType.Circle,
          label: 'Circle',
          color: 'indigo-7',
          factory: data => PlanCircle.create(data),
          isCrossSection: false
        }),
        new PlanPaletteItem({
          type: PlanItemType.Polygon,
          label: 'Polygon',
          color: 'indigo-7',
          factory: data => PlanPolygon.create(data),
          hasPoints: true,
          isCrossSection: false
        }),
        new PlanPaletteItem({
          type: PlanItemType.Line,
          label: 'Line',
          color: 'indigo-7',
          factory: data => PlanLine.create(data),
          hasPoints: true,
          isCrossSection: false
        }),
      ]
    }
  ],

  /**
   * Items which can be added to radiation layer
   */
  radiationLayerItems: [
    new PlanPaletteItem({
      type: PlanItemType.Polygon,
      label: 'Outer Walls',
      icon: 'check_box_outline_blank',
      key: 'wall',
      factory: data => PlanPolygon.create({
        ...data,
        key: 'wall',
        lineStyle: {
          width: 0
        },
        backgroundStyle: {
          color: '#e0f0ff',
          opacity: 1.0
        }
      }),
      hasPoints: true
    }),
    new PlanPaletteItem({
      type: PlanItemType.Polygon,
      label: 'Courtyards',
      icon: 'highlight_alt',
      key: 'yard',
      factory: data => PlanPolygon.create({
        ...data,
        key: 'yard',
        lineStyle: {
          width: 0
        },
        backgroundStyle: {
          color: '#ffffff',
          opacity: 1.0
        }
      }),
      hasPoints: true
    }),
    new PlanPaletteItem({
      type: PlanItemType.Beam,
      label: 'Beam',
      isEnabled: false,
      key: 'beam',
      factory: data => PlanBeam.create({
        ...data,
        key: 'beam'
      })
    }),
  ]
}
