/**
 * Router state factory
 */
export function createState () {
  return {
    /**
     * UI router
     */
    router: null,
    /**
     * Application routes
     */
    routes: [],
    /**
     * Previous route
     */
    previousRoute: null,
    /**
     * Indicates that we're currently navigating to another route
     */
    isNavigating: false,
    isNavigatingFrom: null,
    isNavigatingTo: null,
    navigationStartedAt: null,
    navigationFinishedAt: null,
    /**
     * Indicates that we're navigating, and to an entirely different route,
     * not the same route with different query or parameters
     */
    isNavigatingToDifferentRoute: false,
    /**
     * Data associated with each route.
     * Dictionary of objects { name, title, data, view }, with route name used as key
     */
    routeData: {}
  }
}

/**
 * Router state
 */
export const state = createState()

export const getters = {
  /**
   * Router instance
   */
  router: state =>
    state.router,

  /**
   * Indicates that we're currently navigating
   */
  isNavigating: state =>
    state.isNavigating,

  /**
   * Indicates that we're currently navigating to an entirely different route
   */
  isNavigatingToDifferentRoute: state =>
    state.isNavigatingToDifferentRoute,

  /**
   * Returns the route currently entered in the browser address bar
   */
  currentRoute: state => {
    const { router } = state
    if (router) {
      return router.currentRoute
    }
  },

  /**
   * Returns the name of the current route
   */
  currentRouteName: (state, getters) =>
    (getters.currentRoute || {}).name,

  /**
   * Returns the path of the current route
   */
  currentRoutePath: (state, getters) =>
    (getters.currentRoute || {}).path,

  /**
   * Returns the parameters of the current route
   */
  currentRouteParams: (state, getters) =>
    (getters.currentRoute || {}).params,

  /**
   * Returns true if current route matches the specified name
   */
  isCurrentRoute: (state, getters) =>
    name =>
      (getters.currentRoute || {}).name === name,

  /**
   * Returns true if specified route is the same
   * as the current one
   */
  isSameRoute: (state, getters) =>
    route =>
      getters.currentRouteName === route.name && getters.currentRouteParams === route.params,

  /**
   * Previous route
   */
  previousRoute: state =>
    state.previousRoute,

  /**
   * Previous route name
   */
  previousRouteName: state =>
    (state.previousRoute || {}).name,

  /**
   * Previous route path
   */
  previousRoutePath: state =>
    (state.previousRoute || {}).path,

  /**
   * Returns route with the specified name
   * @param name Route name
   */
  getRouteByName: state =>
    name =>
      state.routes.find(route => route.name === name),

  /**
   * Returns true if route with the specified name exists
   * @param name Route name
   */
  routeExists: state =>
    name =>
      state.routes.some(route => route.name === name),

  /**
   * Returns route with the specified path
   * @param path Route path
   */
  getRouteByPath: state =>
    path =>
      state.routes.find(route => route.path === path),

  /**
   * Returns route associated with the specified view
   * @param name View name
   */
  getRouteByView: (state, getters) =>
    name => {
      // Find view, check if maybe a placeholder view should be used
      const view = getters.getView(name)
      if (view) {
        name = view.placeholder || view.name
        return state.routes.find(route => route.meta && route.meta.view && route.meta.view.name === name)
      }
    },

  /**
   * Returns true if the specified route requires a session
   * @param name Route name
   */
  isSecureRoute: (state, getters) =>
    name => {
      const route = getters.getRouteByName(name)
      if (route && route.meta) {
        return route.meta.isSecure
      }
    },

  /**
   * Returns data associated with route with the specified name
   * @param name Route name
   * @returns Object { name, title, data, view } containing all data associated with the route
   */
  getRouteData: state =>
    name =>
      state.routeData[name],

  /**
   * Returns data associated with current route
   * @returns Object { name, title, data, view } containing all data associated with the route
   */
  currentRouteData: (state, getters) =>
    state.routeData[getters.currentRouteName]
}
