import { mapActions } from 'vuex'

export default {
  props: {
    // Indicates whether the help document has collapsible sections
    isCollapsible: {
      type: Boolean,
      default: true
    },

    // Indicates whether the collapsible sections are initially collapsed
    isCollapsed: {
      type: Boolean,
      default: false
    },

    // Help document category
    category: {
    },

    // Help document subcategory
    subcategory: {
    }
  },

  data () {
    return {
      // Viewed document
      document: null
    }
  },

  methods: {
    ...mapActions([
      'getDocument'
    ])
  }
}