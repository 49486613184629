<script>
import { mapState } from 'vuex'
import { Log, delay } from '@stellacontrol/utilities'
import { Confirmation } from '@stellacontrol/client-utilities'
import { PlanFloors, PlanState, PlannerMode } from '@stellacontrol/planner'
import { PlanRenderer, executePlanAction, PlanActions } from '../../renderer'
import PlanPalette from '../palette/palette.vue'
import StageMenu from '../menus/stage-menu.vue'
import ItemsMenu from '../menus/items-menu.vue'
import Tooltip from '../tooltips/tooltip.vue'
import Help from '../help/help.vue'
import FloorToolbar from './floor-toolbar.vue'
import PlanItemDetails from './item-details.vue'

export default {
  components: {
    'scp-palette': PlanPalette,
    'scp-stage-menu': StageMenu,
    'scp-items-menu': ItemsMenu,
    'scp-tooltip': Tooltip,
    'scp-plan-state': PlanState,
    'scp-help': Help,
    'scp-floor-toolbar': FloorToolbar,
    'scp-item-details': PlanItemDetails
  },

  props: {
    // Indicates that we're in DEBUG mode
    isDebugging: {
      default: false
    },
    // List of devices to use on the plan
    devices: {
      default: () => []
    },
    // Place to which the plan belongs
    place: {
    },
    // Plan layout to edit
    layout: {
    },
    // Floor to show
    floorId: {
    },
    // Interval at which changes to the plan should be reported.
    // Used for example for autosave.
    changeInterval: {
      default: 5000
    },
    // Indicates that users wants to see maximized plan with all tooling hidden
    isMaximized: {
      type: Boolean,
      default: false
    },
    // Async callback triggered when plan has been initialized
    initialized: {
      type: Function
    },
    // Async callback triggered when shape requests additional data
    dataRequested: {
      type: Function
    },
    // Async callback triggered when renderer state changes
    stateChanged: {
      type: Function
    },
    // Async callback triggered when plan layout changes
    changed: {
      type: Function
    },
    // Async callback triggered when floor is changed
    floorSelected: {
      type: Function
    },
    // Async callback triggered when cross-section is selected
    crossSectionSelected: {
      type: Function
    },
    // Async callback for saving floor images
    saved: {
      type: Function
    },
    // Async callback for saving floor images
    imageSaved: {
      type: Function
    }
  },

  data () {
    return {
      // Indicates that we're now loading the plan
      isLoading: false,
      // Used to re-create the plan container
      hasContainer: true,
      // Plan renderer
      renderer: null,
      // Default plan floors
      PlanFloors
    }
  },

  computed: {
    ...mapState({
      // Ticker, for updating stubborn values
      ticker: state => state.client.ticker,
      // Planner editing mode
      mode: state => state.planner.mode
    }),

    // Determines whether the planner allows full feature set
    isAdvancedMode () {
      return this.mode === PlannerMode.Advanced
    },

    // Determines whether the planner allows only a limited feature set
    isRegularMode () {
      return this.mode === PlannerMode.Regular
    },

    // Determines whether the planner works in readonly mode
    isReadOnly () {
      return this.mode === PlannerMode.ReadOnly
    },

    // Indicates that we're in cross-section view
    isCrossSection () {
      return this.renderer?.isCrossSection
    },

    // Plan state - messages about loading, saving etc.
    planState () {
      return this.ticker > 0 ? this.renderer?.stateLabel : null
    },

    // Plan floors
    floors () {
      return this.layout?.floors || []
    },

    // Floor count
    floorCount () {
      return this.floors.length
    },

    // Plan floors shown to the user
    visibleFloors () {
      return this.floors.filter(f => !f.isDeleted)
    },

    // Floor shown to the user
    floor () {
      return this.floorId === PlanFloors.CrossSection
        ? this.layout?.crossSection
        : this.floors?.find(f => f.id === this.floorId)
    },

    // Floor label
    floorLabel () {
      return this.isCrossSection ? 'cross-section view' : 'floor plan'
    },

    // Action history
    history () {
      return this.renderer?.history
    },

    // Last action which can be undone
    undoableAction () {
      return this.ticker > 0 ? this.history?.undoableAction || {} : {}
    },

    // Previous action which can be redone
    redoableAction () {
      return this.ticker > 0 ? this.history?.redoableAction || {} : {}
    }
  },

  methods: {
    // Initializes the floor plan
    async loadFloor () {
      // Dispose of the previous renderer
      if (this.renderer) {
        this.renderer.unmount()
        this.renderer = null
        this.hasContainer = false
      }

      if (!this.layout) return

      this.isLoading = true
      this.$nextTick(async () => {
        await delay(100)
        this.hasContainer = true

        this.$nextTick(async () => {
          try {
            const { layout, floorId, floor, isDebugging } = this
            const container = this.$refs.container
            if (container && floorId) {
              const { onInitialized, onData, onSave, onSaveImage, onSelectFloor, onSelectCrossSection, onChanged, changeInterval } = this
              container.innerHTML = ''
              this.renderer = new PlanRenderer({
                layout,
                container,
                floor,
                changeInterval,
                onData,
                onSave,
                onSaveImage,
                onSelectFloor,
                onSelectCrossSection,
                onChanged,
                debug: isDebugging
              })
              this.renderer.setConfirmationHandler(message => this.confirmAction(message))
              await this.renderer.render()
              this.renderer.focus()
              onInitialized()
            }

          } catch (error) {
            this.renderer = null
            Log.error('Error rendering the plan')
            Log.exception(error)

          } finally {
            this.isLoading = false
          }
        })
      })
    },

    // Asks user for confirmation of an action
    async confirmAction (message) {
      const yes = await Confirmation.ask({ title: 'Confirmation', message })
      return yes
    },

    // Notifies about the plan having been initialized
    onInitialized () {
      if (this.initialized) {
        const { renderer, layout, floor } = this
        const { equipmentHierarchy: hierarchy } = renderer
        this.initialized({ renderer, layout, hierarchy, floor })
      }
    },

    // Retrieves additional data for layout items such as devices
    onData (item) {
      if (this.dataRequested) {
        const { renderer, layout, floor } = this
        this.dataRequested({ renderer, layout, floor, item })
      }
    },

    // Notifies about changes to the plan layout
    async onChanged ({ action } = {}) {
      if (this.changed) {
        const { renderer } = this
        this.changed({ renderer, action })
      }
    },

    // Requests saving of the layout
    async onSave () {
      const { renderer } = this
      if (this.saved) {
        await this.saved({ renderer })
      }
    },

    // Requests saving of the floor image
    async onSaveImage ({ floor, file } = {}) {
      if (this.imageSaved) {
        const { renderer } = this
        await this.imageSaved({ renderer, floor, file })
      }
    },

    // Requests navigating to another floor or to cross-section view
    async onSelectFloor ({ floor: { id } = {} } = {}) {
      // Skip tabs which are action buttons
      if (id.startsWith('action-')) {
        return
      }

      if (id) {
        if (id === PlanFloors.CrossSection) {
          await this.onSelectCrossSection()

        } else {
          if (this.floorSelected) {
            const { renderer } = this
            const floor = this.layout.getFloor(id) || this.layout.getMainFloor()
            await this.floorSelected({ renderer, floor })
          }
        }
      }
    },

    // Requests navigating to cross section view
    async onSelectCrossSection () {
      if (this.crossSectionSelected) {
        const { renderer } = this
        await this.crossSectionSelected({ renderer })
      }
    },

    // Signals that the user wants to start adding an item to the plan
    startAddingItem (item, layer) {
      const { renderer } = this
      renderer.startAddingItem(item, layer)
    },

    // Signals that the user wants to stop adding an item to the plan
    stopAddingItem () {
      const { renderer } = this
      renderer.stopAddingItem()
    },

    // Adds new floor to the plan
    async addFloor () {
      const { layout } = this

      const label = await Confirmation.prompt({
        title: 'Floor name',
        message: 'Please enter the name of the floor',
        initial: `Floor ${layout.floorCount}`,
      })

      if (label && label.trim()) {
        await this.executeAction({ action: PlanActions.AddFloor, label })
      }
    },

    // Executes an action
    async executeAction (details = {}) {
      const { renderer, floor } = this
      await executePlanAction({ renderer, floor, ...details })
    },

    // Executes an action at the current position
    async executeActionAt (details = {}) {
      const { renderer } = this
      const position = renderer.currentPosition
      await this.executeAction({ position, ...details })
    },

    // Undoes the last action
    async undo () {
      const { history, renderer, undoableAction } = this
      if (undoableAction) {
        await history.undo({ renderer })
      }
    },

    // Redoes the previously undone action
    async redo () {
      const { history, renderer, redoableAction } = this
      if (redoableAction) {
        history.redo({ renderer })
      }
    },

    // Focus/defocus the plan
    focus (state) {
      this.renderer?.focus(state)
    },

    // Displays help
    showHelp () {
      this.executeAction({ action: PlanActions.ShowHelp })
    },

    // Toggles debugging
    debug (status) {
      this.renderer?.debug(status)
    }
  },

  watch: {
    floorId () {
      // Reload the plan when floor changes
      this.loadFloor()
    },

    // Notifies about state change of the renderer.
    // Can be used to display state labels, such as 'Loading...', 'Saving...' etc.
    planState () {
      if (this.stateChanged) {
        const { renderer } = this
        if (renderer) {
          this.stateChanged({
            state: renderer.state,
            label: renderer.stateLabel
          })
        }
      }
    }
  },

  beforeUnmount () {
    this.renderer?.unmount()
  },

  created () {
    this.loadFloor()
  }
}

</script>

<template>
  <q-tabs :model-value="floorId" no-caps inline-label class="bg-indigo-2" active-bg-color="indigo-3"  outside-arrows
    align="left" @update:model-value="id => onSelectFloor({ floor: { id } })">
    <q-tab :name="PlanFloors.CrossSection" label="Cross Section" icon="corporate_fare"
      :ripple="false">
    </q-tab>
    <q-tab v-for="floor in visibleFloors" :name="floor.id" :key="floor.id" :label="floor.label"
      :icon="layout.hasOneFloor ? 'grid_view' : undefined" :ripple="false">
    </q-tab>
    <q-tab name="action-add-floor" icon="add_circle" :ripple="false" @click.stop="addFloor()"
      class="text-indigo-8 tab-right-border">
      <sc-tooltip>Add a new floor</sc-tooltip>
    </q-tab>
    <q-tab name="action-undo" icon="undo" :ripple="false" @click.stop="undo()"
      class="tab-action" :class="undoableAction.action ? 'text-indigo-8' : 'text-grey-6'" :disable="!undoableAction.action">
      <sc-tooltip>Undo</sc-tooltip>
    </q-tab>
    <q-tab name="action-undo" icon="redo" :ripple="false" @click.stop="redo()"
      class="tab-action" :class="redoableAction.action ? 'text-indigo-8' : 'text-grey-6'" :disable="!redoableAction.action">
      <sc-tooltip>Redo</sc-tooltip>
    </q-tab>
    <q-space>
    </q-space>
    <scp-floor-toolbar :renderer="renderer" :layout="layout" :floor="floor">
    </scp-floor-toolbar>
  </q-tabs>

  <div class="plan" tabindex="1" @focus="focus(true)" @blur="focus(false)">
    <div v-if="hasContainer && !floor?.isDeleted" class="plan-container"
      :class="renderer ? { [renderer.state]: true } : null" ref="container" tabindex="0">
    </div>

    <!-- Make focused palette capture all keyboard events, otherwise they'd be passed to canvas! -->
    <div class="plan-palette" v-if="renderer?.isRendered" @keydown.stop
      v-moveable.right="'.palette-header'">
      <scp-palette :renderer="renderer"
        @start-adding-item="({ item, layer }) => startAddingItem(item, layer)"
        @stop-adding-item="stopAddingItem()" @action="executeActionAt">
      </scp-palette>
    </div>

    <scp-items-menu v-if="renderer?.isRendered" container=".plan" :menu="renderer.itemsMenu"
      @execute="executeActionAt">
    </scp-items-menu>

    <scp-tooltip v-if="renderer?.isRendered" :tooltip="renderer.tooltip" container=".plan">
    </scp-tooltip>

    <scp-help v-if="renderer?.isRendered" :help="renderer.helpVisible" @close="renderer.hideHelp()">
    </scp-help>

    <scp-item-details>
    </scp-item-details>

  </div>
</template>

<style lang="scss" scoped>
.toolbar-toggles {
  border-left: solid #ced2ea 1px;
}

.toolbar-floor {
  border-left: solid #abb0d0 1px;
}

.tab-right-border {
  border-right: solid grey 1px;
  margin-right: 8px;
}

.tab-action {
  padding-left: 4px;
  padding-right: 4px;
}

.plan {
  --palette-width: 354px;
  background-color: #fafafa;

  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  position: relative;

  .plan-container {
    position: relative;
    overflow: auto;
    background-color: #ffffff;
    border-right: solid #0000001f 1px;
    border-bottom: solid #0000001f 1px;

    &.initializing {
      cursor: wait;
    }

    &.saving {}

    &.pointing {
      cursor: pointer;
    }

    &.selecting-transparent-color {
      cursor: crosshair;
    }

    &.panning {
      cursor: move;
    }
  }

  .plan-palette {
    position: fixed;
    right: 5px;
    top: 150px;
    width: var(--palette-width);
    z-index: 11;
  }

  .plan-zoom {
    position: absolute;
    right: 5px;
    bottom: 5px;
    z-index: 10;
  }
}
</style>
