<script>
import { getDurationString, formatDateTime, sortItemsReverse } from '@stellacontrol/utilities'
import { Clipboard, Notification } from '@stellacontrol/client-utilities'
import DeviceWidget from './device-widget'

export default {
  mixins: [
    DeviceWidget
  ],

  data () {
    return {
      actions: [
        { name: 'copy', label: 'Copy to clipboard', icon: 'content_copy', color: 'indigo-6', permissions: [] },
      ]
    }
  },

  computed: {
    events () {
      const { events } = this.device
      return sortItemsReverse(events, event => event.time)
    },

    time () {
      return timestamp => formatDateTime(new Date(timestamp))
    },

    age () {
      const now = (new Date()).getTime()
      return timestamp => getDurationString((now - timestamp) / 1000)
    }
  },

  methods: {
    // Executes the action
    async executeAction (action) {
      switch (action.name) {
        case 'copy':
          await this.copyToClipboard()
          break
      }
    },

    // Copies the table content to clipboard
    async copyToClipboard () {
      const { events } = this
      const lines = [
        'EVENTS',
        ...events.map(event => `${this.time(event.time)}\t${this.age(event.time)}\t${event.type}`)
      ].join('\n')
      await Clipboard.write(lines + '\n')
      Notification.success({ message: 'Events have been copied to clipboard' })
    }
  }
}
</script>

<template>
  <sc-widget v-bind="{...$props, ...$attrs}" :actions="actions" @action="executeAction">
    <div class="events">

      <div class="events-header">
        <q-markup-table dense flat square>
          <thead>
            <tr>
              <th class="text-left">
                Timestamp
              </th>
              <th class="text-left">
                Age
              </th>
              <th class="text-left">
                Event
              </th>
            </tr>
          </thead>
        </q-markup-table>
      </div>

      <div class="events-items">
        <q-markup-table dense flat square>
          <tbody>
            <tr v-for="(event, index) in events || []" :key="index">
              <td>
                {{ datetime(event.time) }}
              </td>
              <td>
                {{ duration(event.age) }}
              </td>
              <td>
                {{ event.type }}
              </td>
            </tr>
          </tbody>
        </q-markup-table>
      </div>
    </div>
  </sc-widget>
</template>

<style lang="scss" scoped>
.events {
  padding: 8px;
  background-color: white;
  width: 515px;

  .events-header {
    table {
      th {
        padding: 6px;
        font-size: 13px;
        color: #4a4a4a;

        &:nth-child(1) {
          width: 180px;
        }
        &:nth-child(3) {
          width: 180px;
        }
      }
    }
  }

  .events-items {
    height: 400px;
    overflow: auto;

    table {
      td {
        padding: 6px;
        font-size: 13px;
        color: #4a4a4a;

        &:nth-child(1) {
          width: 180px;
        }
        &:nth-child(3) {
          width: 180px;
        }
      }
    }
  }
}
</style>
