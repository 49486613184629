<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { User } from '@stellacontrol/model'
import { ViewMixin, FormMixin } from '@stellacontrol/client-utilities'
import { PasswordPolicy } from '@stellacontrol/security'

const name = 'reset-password'

export default {
  mixins: [
    ViewMixin,
    FormMixin
  ],

  data () {
    return {
      name,
      user: null,
      password: '',
      revealPassword: false,
      repeatPassword: '',
      passwordPolicy: null,
      passwordErrors: null,
      passwordPolicyChecked: false
    }
  },

  computed: {
    ...mapState({
      ticker: state => state.client.ticker,
      passwordPolicySettings: state => state.client.configuration.security.passwordPolicy
    }),

    ...mapGetters([
      'isInitialized',
      'application',
      'company',
      'branding',
      'configuration'
    ]),

    // Indicates whether the window is ready for login.
    // Sometimes reCaptcha is slow to initialize...
    // Use client.ticker to force re-evaluation
    isReady () {
      const { ticker, reCaptcha, getReCaptchaService } = this
      if (reCaptcha.enabled) {
        return ticker > 0 && getReCaptchaService() != null
      } else {
        return true
      }
    },

    // Google reCaptcha configuration
    reCaptcha () {
      return this.configuration.security.login.reCaptcha
    },

    // Password reset token
    token () {
      return this.data.token
    },

    // Indicates whether password is ready to be checked
    canBeChecked () {
      const { password, repeatPassword } = this
      return (password && repeatPassword && password.trim() && password === repeatPassword)
    },

    // Indicates whether password can be changed -
    // inputs are OK and it meets policy requirements
    canBeChanged () {
      return this.canBeChecked && !this.passwordErrors
    },

    // Returns true if password has violated the specified policy requirement
    hasError () {
      return error => (this.passwordErrors || []).some(item => item.error === error)
    }
  },

  methods: {
    ...mapActions([
      'endSession',
      'setPassword',
      'gotoLogin',
      'gotoUrl'
    ]),

    // Access to reCaptcha service
    getReCaptchaService () {
      return window.grecaptcha
    },

    // Checks the password against the password policy
    checkPasswordPolicy () {
      const { password, canBeChecked } = this
      if (canBeChecked) {
        const { ok, errors } = this.passwordPolicy.check(password)
        this.passwordErrors = errors && errors.length > 0 ? errors : null
        this.passwordPolicyChecked = true
        return ok
      } else {
        this.passwordErrors = null
        this.passwordPolicyChecked = false
        return true
      }
    },

    // Submits new password
    async submit (reCaptchaToken) {
      if (await this.validate() && this.checkPasswordPolicy()) {
        const { user, token, password } = this
        if (await this.setPassword({ user, token, password, reCaptchaToken })) {
          this.gotoLogin()
        }
      }
    },

    // Password change protected with reCaptcha check
    async safeSubmit () {
      const reCaptchaService = this.getReCaptchaService()
      const { reCaptcha, submit } = this
      if (reCaptchaService && reCaptcha.enabled) {
        reCaptchaService.ready(async () => {
          const reCaptchaToken = await reCaptchaService.execute(reCaptcha.key, { action: 'submit' })
          if (reCaptchaToken) {
            submit(reCaptchaToken)
          }
        })

      } else {
        return submit()
      }
    },

    async cancel () {
      this.gotoUrl({ url: this.company.website || 'about:blank' })
    }
  },

  created () {
    this.user = new User(this.data.user),
    this.passwordPolicy = new PasswordPolicy(this.passwordPolicySettings)
  }
}
</script>

<template>
  <sc-view :name="name" no-header>
    <q-layout v-cloak v-if="isInitialized && user">
      <q-page-container>
        <q-page class="column items-center justify-center">
          <q-card bordered class="form-reset-password">
            <q-form ref="form" class="q-gutter-sm q-pa-md">

              <div class="row">
                <span class="text-h5 q-pb-lg header-reset-password">
                  {{ application.name }}
                </span>
                <q-space></q-space>
                <div class="logo-image"
                  v-if="branding.logo"
                  :style="{ 'background-image': `url('${branding.logo}')` }">
                </div>
              </div>

              <div class="text-subtitle1 title-reset-password">
                Change password of <b>{{user.fullName}}</b>
              </div>

              <q-input square outlined label="New password" v-model="password"
                class="input-new-password"
                maxlength="45"
                debounce="500"
                :type="revealPassword ? 'text' : 'password'"
                @update:model-value="() => checkPasswordPolicy()"
                lazy-rules
                :rules="[
                  rules.required('Password is required')
                ]">
                <template v-slot:append>
                  <q-icon name="visibility" class="icon-reveal-new-password cursor-pointer"
                    :color="revealPassword ? 'green-7' : 'gray-6'"
                    @click="revealPassword = !revealPassword" />
                </template>
              </q-input>

              <q-input square outlined label="Repeat password" v-model="repeatPassword"
                class="input-repeat-password"
                maxlength="45"
                debounce="500"
                :type="revealPassword ? 'text' : 'password'"
                @update:model-value="() => checkPasswordPolicy()"
                lazy-rules
                :rules="[
                  rules.required('Password must be repeated'),
                  value => value === password || 'Both passwords must be identical'
                ]">
                <template v-slot:append>
                  <q-icon name="visibility" class="icon-reveal-repeat-password cursor-pointer"
                    :color="revealPassword ? 'green-7' : 'gray-6'"
                    @click="revealPassword = !revealPassword">
                  </q-icon>
                </template>
              </q-input>

              <div class="errors" v-if="passwordPolicy.checks.length > 0">
                <div :class="{ 'text-bold': true, 'text-red-6': passwordErrors }">
                  {{ passwordErrors ? 'The new password is not strong enough:' : 'Password must meet the following requirements:' }}
                </div>

                <div class="row items-center q-mt-md error" v-for="check in passwordPolicy.checks" :key="check.error">
                  <q-icon size="24px"
                    class="error-icon"
                    :name="hasError(check.error) ? 'error' : 'check_circle'"
                    :color="passwordPolicyChecked ? (hasError(check.error) ? 'red-7' : 'green-8') : 'indigo-5'">
                  </q-icon>
                  <span class="error-text q-ml-sm"
                    :class="{ 'text-red-6': hasError(check.error), 'text-green-8': passwordPolicyChecked && !hasError(check.error) }">
                    {{ check.description }}
                  </span>
                </div>
              </div>

              <div class="row q-mt-xs q-gutter-lg">
                <q-space></q-space>
                <q-btn class="button-close" unelevated label="Close" @click="cancel()"></q-btn>
                <q-btn unelevated class="button-change-password primary" size="md" label="Change my password"
                  :disabled="!canBeChanged" @click="safeSubmit()"></q-btn>
              </div>
            </q-form>
          </q-card>
        </q-page>
      </q-page-container>
    </q-layout>
  </sc-view>
</template>

<style lang="scss" scoped>
.form-reset-password {
  width: 50%;
  max-width: 600px;

  .logo-image {
    width: 200px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: right;
  }

  .button-change-password {
    min-width: 180px;
  }
}

/* Layout adjustments for screen below HD resolution */
@media screen and (max-width: 1365px) {
  .form-reset-password {
    width: 80%;
    max-width: 80%;
  }
}

</style>