import application from './application'
import sidebar from './sidebar'
import menu from './menu'
import search from './search'

/**
 * Application state store
 */
export const Store = {
  application,
  sidebar,
  menu,
  search
}
