export * from './list'
export * from './action-dropdown'
export * from './tabs'

import NetworkErrorPopup from './network-error-popup/network-error-popup.vue'
import ActionDropdown from './action-dropdown/action-dropdown.vue'
import List from './list/list.vue'
import ListFilter from './list/list-filter.vue'
import Breadcrumbs from './breadcrumbs/breadcrumbs.vue'
import Busy from './busy/busy.vue'
import BackToLogin from './back-to-login/back-to-login.vue'
import Empty from './empty/empty.vue'
import DateInput from './date-input/date-input.vue'
import DurationInput from './duration-input/duration-input.vue'
import TimeScheduleInput from './time-schedule-input/time-schedule-input.vue'
import Tooltip from './tooltip/tooltip.vue'
import Hint from './hint/hint.vue'
import HintPlaceholder from './hint/hint-placeholder.vue'
import NotAuthorized from './not-authorized/not-authorized.vue'
import DocumentViewer from './document-viewer/document-viewer.vue'
import Tabs from './tabs/tabs.vue'
import TabPanels from './tabs/tab-panels.vue'
import ColorPicker from './color-picker/color-picker.vue'
import PhotoShot from './photo-shot/photo-shot.vue'
import Icons from './icons'

/**
 * Installs UI components into the application
 * @param application Vue application instance
 */
export function useComponents (application) {
  const plugin = {
    install (application) {
      const items = [
        {
          tag: 'sc-network-error-popup', component: NetworkErrorPopup
        },
        {
          tag: 'sc-action-dropdown', component: ActionDropdown
        },
        {
          tag: 'sc-list', component: List
        },
        {
          tag: 'sc-list-filter', component: ListFilter
        },
        {
          tag: 'sc-breadcrumbs', component: Breadcrumbs
        },
        {
          tag: 'sc-busy', component: Busy
        },
        {
          tag: 'sc-back-to-login', component: BackToLogin
        },
        {
          tag: 'sc-empty', component: Empty
        },
        {
          tag: 'sc-date-input', component: DateInput
        },
        {
          tag: 'sc-duration-input', component: DurationInput
        },
        {
          tag: 'sc-time-schedule-input', component: TimeScheduleInput
        },
        {
          tag: 'sc-tooltip', component: Tooltip
        },
        {
          tag: 'sc-hint', component: Hint
        },
        {
          tag: 'sc-hint-placeholder', component: HintPlaceholder
        },
        {
          tag: 'sc-not-authorized', component: NotAuthorized
        },
        {
          tag: 'sc-document-viewer', component: DocumentViewer
        },
        {
          tag: 'sc-tabs', component: Tabs
        },
        {
          tag: 'sc-tab-panels', component: TabPanels
        },
        {
          tag: 'sc-color-picker', component: ColorPicker
        },
        {
          tag: 'sc-photo-shot', component: PhotoShot
        },
        ...Icons
      ]

      for (const { tag, component } of items) {
        application.component(tag, component)
      }
    }
  }

  application.use(plugin)
}
