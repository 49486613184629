import { safeParseFloat } from '@stellacontrol/utilities'
/**
 * Geographic coordinates
 */
export class GeoCoordinates {
  constructor (data = {}) {
    Object.assign(this, data)
  }

  /**
   * Latitude in degrees
   * @type {Number}
   */
  latitude

  /**
   * Longitude in degrees
   * @type {Number}
   */
  longitude

  /**
   * Checks whether the geographic location is valid
   * @type {Boolean}
   */
  get isValid () {
    return this.latitude != null && this.longitude != null
  }

  /**
   * Parses the coordinates from string, as submitted by device
   * eg. "gps": ".000000, .0000",
   * @return {GeoCoordinates}
   */
  static fromString (value) {
    if (value) {
      const parts = value.toString().split(',').map(v => (v || '').trim())
      if (parts.length === 2) {
        const latitude = safeParseFloat(parts[0].startsWith('.') ? `0.${parts[0]}` : parts[0])
        const longitude = safeParseFloat(parts[1].startsWith('.') ? `0.${parts[1]}` : parts[1])
        if (latitude != null && longitude != null)
          return new GeoCoordinates({ latitude, longitude })
      }
    }
  }

  /**
   * Parses the coordinates from specified latitude and longitude
   * @return {GeoCoordinates}
   */
  static fromLatLon (latitude, longitude) {
    if (latitude != null && longitude != null) {
      return new GeoCoordinates({ latitude, longitude })
    }
  }
}
