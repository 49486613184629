/**
 * Context menu controller
 */
export class ContextMenu {
  /**
   * Plan items for which the menu is shown
   * @type {Array[PlanItem]}
   */
  items

  /**
   * Position where the menu is shown
   * @type {Point}
   */
  position

  /**
   * Line point at which the menu is shown
   * @type {Number}
   */
  point

  /**
   * Indicates that there are any items specified
   * @type {Boolean}
   */
  get hasItems () {
    return this.items?.length > 0
  }

  /**
   * Indicates that there more than one item is specified
   * @type {Boolean}
   */
  get isBatch () {
    return this.items?.length > 1
  }

  /**
   * Determines whether the specified item has a context menu
   * @param {PlanRenderer} renderer Plan renderer
   * @param {PlanItem} item
   * @returns  {Boolean}
   */
  hasContextMenu ({ renderer, item }) {
    return item &&
      !renderer.itemLayer?.isLocked &&
      item.hasContextMenu &&
      !item?.isLocked
  }

  /**
   * Shows the context menu
   * @param {Array[PlanItem]} items Items for which to show the menu
   * @param {Number} point Item point for which to show the menu
   * @param {Point} position Absolute position at which to show the menu
   */
  show ({ items, point, position }) {
    this.items = items
    this.point = point
    this.position = position
  }

  /**
   * Moves the context menu to a new position
   * @param {Point} position Absolute position at which to show the menu
   * @returns {Point} New position of the menu
   */
  moveTo ({ position }) {
    this.position = position.round()
    return this.position
  }

  /**
   * Moves the context menu by the specified delta
   * @param {Point} delta Movement delta
   * @returns {Point} New position of the menu
   */
  moveBy ({ delta }) {
    this.position?.moveBy(delta.round())
    return this.position
  }

  /**
   * Hide the context menu
   */
  hide () {
    this.items = null
    this.point = null
    this.position = null
  }
}
