import { parseDate } from '@stellacontrol/utilities'
import { Assignable } from '../common/assignable'

/**
 * A subscription by organization to alert notifications from a specified device
 */
// TODO: OBSOLETE
export class AlertSubscription extends Assignable {
  constructor (data = {}) {
    super()
    this.assign({...data, createdAt: data.createdAt || new Date() }, { createdAt: parseDate })
  }

  /**
   * Date and time when subscription has been created
   */
  createdAt

  /**
   * Identifier of a user who created the subscription
   */
  createdBy

  /**
   * Identifier of subscribing organization
   */
  organizationId

  /**
   * Identifier of a subscribed device
   */
  deviceId

  /**
   * Serial number of a subscribed device
   */
  serialNumber
}
