/**
 * Validates a form inside the specified Vue component
 * @param component Vue component
 * @param ref Form reference, `form` by default
 */
export async function validateForm (component, ref = 'form') {
  if (!component) throw new Error('Component with a form is required')
  const form = component.$refs[ref]
  if (!form) throw new Error(`The component does not have a form with ref equal to ${ref}`)

  const isValid = await form.validate()
  return isValid
}

/**
 * Resets a previously validated form inside the specified Vue component
 * @param component Vue component
 * @param ref Form reference, `form` by default
 */
export function resetForm (component, ref = 'form') {
  if (!component) return Promise.reject(new Error('Component with a form is required'))
  const form = component.$refs[ref]
  if (!form) return Promise.reject(new Error(`The component does not have a form with ref equal to ${ref}`))

  return form.resetValidation()
}
