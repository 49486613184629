export function createState () {
  return {
    // Files view state
    filesView: {
      // Viewed organization
      organization: null,
      // All owners of attachments,
      owners: null,
      // Attachments of the viewed organization
      attachments: [],
      // Sort order
      sortOrder: 'date-desc',
      // File age, in days
      age: 0,
      // Expanded folders
      expandedFolders: { ttscan: true },
      // Expanded file groups
      collapsedGroups: {
      },
      // Filter
      filter: null,
      // Properties of a currently running attachment poll clocks.
      // It's a per-view dictionary where each view adds its own
      // clock with its own configuration.
      polling: {},
    }
  }
}

export const state = createState()

export const getters = {
}
