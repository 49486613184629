<script>
import { mapActions } from 'vuex'
import { DialogMixin } from '@stellacontrol/client-utilities'
import { HelpTopic, HelpTopicDescription, isKnownHelpTopic } from '@stellacontrol/model'
import Videos from './videos.vue'
import FAQ from './faq.vue'
import Pricing from './pricing.vue'
import TermsAndConditions from './terms-and-conditions.vue'
import FirmwareHistory from './firmware-history.vue'

const dialog = 'help'

export default {
  mixins: [
    DialogMixin
  ],

  components: {
    [`sc-help-${HelpTopic.Videos}`]: Videos,
    [`sc-help-${HelpTopic.FAQ}`]: FAQ,
    [`sc-help-${HelpTopic.Pricing}`]: Pricing,
    [`sc-help-${HelpTopic.TermsAndConditions}`]: TermsAndConditions,
    [`sc-help-${HelpTopic.FirmwareHistory}`]: FirmwareHistory
  },

  props: {
  },

  data () {
    return {
      // Dialog title
      title: 'Help',
      // Displayed help topic
      topic: null,
      // Displayed document category
      category: null,
      // Displayed document subcategory
      subcategory: null,
      // Indicates whether the help document has collapsible sections
      isCollapsible: true,
      // Indicates whether the collapsible sections are initially collapsed
      isCollapsed: false,
      // Dialog identifier
      dialog
    }
  },

  computed: {
    // Name of a component displaying the specified help topic
    topicView () {
      const { topic } = this
      if (topic) {
        return `sc-help-${topic}`
      }
    }
  },

  methods: {
    ...mapActions([
      'dialogOk',
      'dialogCancel'
    ]),

    // Called when dialog is shown
    dialogShown () {
      this.topic = isKnownHelpTopic(this.data.topic) ? this.data.topic : null
      this.category = this.data.category
      this.subcategory = this.data.subcategory
      this.title = HelpTopicDescription[this.topic] || this.title
      this.isCollapsible = this.data.isCollapsible == null ? this.isCollapsible : this.data.isCollapsible
      this.isCollapsed = this.data.isCollapsed == null ? this.isCollapsed : this.data.isCollapsed
    },

    // OKs the dialog
    async ok () {
      this.dialogOk({ dialog })
    },

    // Cancels the dialog
    cancel () {
      this.dialogCancel({ dialog })
    }
  }
}
</script>

<template>
  <sc-dialog :dialog="dialog" @dialogShown="dialogShown()">
    <q-form ref="form" class="form">
      <div class="header">
        <q-banner class="bg-indigo-6">
          <div class="row items-center">
            <span class="text-white title">
              {{ title }}
            </span>
            <q-space></q-space>
            <q-btn label="Close" unelevated class="primary" @click="ok()"></q-btn>
          </div>
        </q-banner>
      </div>

      <div class="content q-pa-md">
        <component v-if="topic"
          :is="topicView"
          :isCollapsible="isCollapsible"
          :isCollapsed="isCollapsed"
          :category="category"
          :subcategory="subcategory">
        </component>
        <div v-else class="help-not-available row items-center justify-center">
          Help is not available
        </div>
      </div>

    </q-form>
  </sc-dialog>
</template>

<style scoped lang="scss">
.form {
  min-width: 850px;
  max-width: 1300px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .header {
    overflow: hidden;

    .title {
      font-size: 18px;
    }
  }

  .content {
    flex: 1;
    overflow: auto;
  }
}

.videos,
.faq,
.pricing,
.terms,
.firmware {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.help-not-available {
  flex: 1;
  height: 100%;
}
</style>