import Vuex from 'vuex'
import { Log } from '@stellacontrol/utilities'
import client from './client'
import router from './router'
import view from './view'
import list from './list'
import dialog from './dialog'
import activity from './activity'
import pushClient from './push-client'
import { setStore } from './get-store'

/**
 * Initializes Vuex state store with shared modules
 * and optional additional modules
 * @param application Vue application instance
 * @param modules Dictionary of additional store modules
 */
export function useStore (application, modules = {}) {
  const store = new Vuex.Store({
    modules: {
      /** Client */
      client,
      /** Router store */
      router,
      /** View store */
      view,
      /** List store */
      list,
      /** Dialog store */
      dialog,
      /** User activity store */
      activity,
      /** Push Client store */
      pushClient,
      /** Additional modules */
      ...modules
    }
  })

  // Make store and state globally available
  // via application.$store and application.$state variables
  setStore(store)
  Object.defineProperty(application.config.globalProperties, '$store', {
    get: () => store,
    set: () => {}
  })

  Object.defineProperty(application.config.globalProperties, '$state', {
    get: () => store ? store.state : undefined,
    set: () => { }
  })

  application.use(store)
  return { store }
}

/**
 * Mounts new modules into existing state store
 * @param {Object} modules Dictionary of modules to mount
 * @param {Store} store State store
 */
export function registerStoreModules (modules, store) {
  if (modules && store) {
    for (const [name, module] of Object.entries(modules)) {
      if (name in store) {
        Log.warn(`Store module [${name}] is already registered`)
      } else {
        store.registerModule(name, module)
      }
    }
  }
}
