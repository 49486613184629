import { createState } from './device-panel.state'

export const mutations = {
  /**
   * Shows the action panel
   * @param {String} tab Initial tab to open, if different than default
   * @param {Boolean} isCollapsed Open the panel in collapsed state
   */
  showActionPanel (state, { tab, isCollapsed = false } = {}) {
    state.tab = tab || state.tab || 'actions'
    state.isVisible = true
    state.isCollapsed = isCollapsed
  },

  /**
   * Updates the data in the action panel
   * @param {Organization} owner Owner of the selected devices
   * @param {Guardian} ownerGuardian Security guardian of the owner of the selected devices
   * @param {Wallet} ownerWallet Wallet of the owner of the selected devices
   * @param {Array[DevicePresenter]} items Inventory items selected when panel has opened
   * @param {Array[Device]} devices Devices selected when panel has opened
   */
  updateActionPanel (state, { owner, ownerGuardian, ownerWallet, devices, items } = {}) {
    state.owner = owner
    state.ownerGuardian = ownerGuardian
    state.ownerWallet = ownerWallet
    state.items = items
    state.devices = devices
    state.isVisible = true
  },

  /**
   * Hides the action panel
   */
  hideActionPanel (state) {
    state.isVisible = true
    state.tab = null
  },

  /**
   * Expands the action panel
   */
  expandActionPanel (state) {
    state.isVisible = true
    state.isCollapsed = false
  },

  /**
   * Collapses the action panel
   */
  collapseActionPanel (state) {
    state.isCollapsed = true
  },

  /**
   * Marks the specified action panel tab as current
   * @param tab Tab to open
   */
  openActionPanelTab (state, { tab } = {}) {
    if (tab) {
      state.tab = tab
    }
  },

  /**
   * Indicates user opening an action in the inventory action panel
   * @param {Device} device Selected device
   * @param {Array[Device]} devices Selected devices
   * @param {Object} action Action to start
   * @param {String} icon Custom action icon
   * @param {String} label Custom action label
   * @param {String} description Custom action description
   */
  openInventoryAction (state, { action, icon, label, description } = {}) {
    state.tab = 'actions'
    state.action = {
      action,
      icon,
      label,
      description
    }
  },

  /**
   * Indicates user closing an action in the inventory action panel
   * @param {Object} action Action to close
   */
  async closeInventoryAction (state, { action } = {}) {
    if (action) {
      state.action = {
        action: null,
        icon: null,
        label: null,
        description: null
      }
    }
  },

  /**
   * Updates inventory action label and/or details
   * @param {String} icon Custom action icon
   * @param {String} label Custom action label
   * @param {String} description Custom action description
   */
  setInventoryActionLabel (state, { icon, label, description } = {}) {
    state.action = {
      ...state.action,
      icon: icon == null ? state.action.icon : icon,
      label: label == null ? state.action.label : label,
      description: description == null ? state.action.description : description
    }
  },

  /**
   * Resets inventory action label and/or details to defaults
   */
  resetInventoryActionLabel (state) {
    state.action = {
      ...state.action,
      icon: null,
      label: null,
      description: null
    }
  },

  /**
   * Resets the state to original shape.
   * @description
   * Presence of this mutation is obligatory on stores,
   * to ensure purging of any user-bound data when
   * user logs in and out and in. Otherwise expect
   * unexpected side effects with data of the previously
   * logged in user appearing at places.
   */
  reset (state) {
    Object.assign(state, createState())
  }
}


