<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { DialogMixin } from '@stellacontrol/client-utilities'
import { Secure } from '@stellacontrol/security-ui'
import { WalletTransactionType } from '@stellacontrol/model'

const dialog = 'premium-subscriber'

export default {
  mixins: [
    DialogMixin,
    Secure
  ],

  data () {
    return {
      dialog,
      // Transaction types
      WalletTransactionType,
      // Amount of tokens to credit
      amount: 0,
      // Notes
      notes: ''
    }
  },

  computed: {
    ...mapState({
      myWallet: state => state.serviceManagement.wallet,
      isBank: state => state.security.guardian.isBank,
      customer: state => state.serviceManagement.premiumSubscribersView.customer,
      customerGuardian: state => state.serviceManagement.premiumSubscribersView.guardian,
      customerDevices: state => state.serviceManagement.premiumSubscribersView.devices,
      customerWallet: state => state.serviceManagement.premiumSubscribersView.wallet,
      customerSubscriptions: state => state.serviceManagement.premiumSubscribersView.wallet.subscriptions
    }),

    ...mapGetters([
      'isSmallScreen'
    ]),

    // Checks whether tokens can be transferred to the currently selected customer
    canTransfer () {
      return this.customerGuardian.isPremiumCustomer
    },

    // Current organization's available tokens.
    // Bank can create tokens out of thin air, like a proper central bank does,
    // but resellers can only sell what they have in their own wallets.
    myAvailableTokens () {
      const { myWallet, isBank } = this
      if (isBank) {
        return Number.MAX_VALUE
      } else {
        return myWallet.balance
      }
    },

    // New balance of customer's wallet after adding the tokens
    newCustomerBalance () {
      const { customerWallet, amount } = this
      return customerWallet.balance + (amount || 0)
    },

    // Amount of tokens remaining in seller's wallet after the operation
    myRemainingTokens () {
      const { isBank, myAvailableTokens, amount } = this
      if (isBank) {
        return myAvailableTokens
      }
      return myAvailableTokens - (amount || 0)
    },

    // Subscriptions of the customer
    subscriptions () {
      const { customerWallet } = this
      return customerWallet ? customerWallet.subscriptions : []
    },

    // Returns true if customer has any subscriptions
    hasSubscriptions () {
      const { subscriptions } = this
      return subscriptions && subscriptions.length > 0
    },

    // Currently active premium devices
    activeDevices () {
      const { customerSubscriptions = [], customerDevices = [] } = this
      return customerDevices.filter(d => customerSubscriptions.some(s => s.isActive && s.forDevice(d)))
    },

    // Devices whose subscriptions have all expired
    expiredDevices () {
      const { customerSubscriptions = [], customerDevices = [], activeDevices } = this
      return customerDevices.filter(d => customerSubscriptions.some(s => s.isExpired && s.forDevice(d)) &&
        !activeDevices.some(ad => ad.id === d.id))
    },

    // Devices whose subscriptions have yet to be activated
    pendingDevices () {
      const { customerDevices = [], activeDevices } = this
      return customerDevices.filter(d => d.isPremiumServiceNotStarted &&
        !activeDevices.some(ad => ad.id === d.id))
    },

    // Devices which don't have any active, expired or pending subscriptions
    standardDevices () {
      const { customerDevices = [], activeDevices, expiredDevices } = this
      return customerDevices.filter(d => !d.hasPremiumService &&
        !activeDevices.some(ad => ad.id === d.id) &&
        !expiredDevices.some(ad => ad.id === d.id))
    }
  },

  emit: [
    'show',
    'hide',
    'transfer'
  ],

  methods: {
    ...mapActions([
      'dialogOk',
      'dialogCancel',
      'creditTokens',
      'debitTokens',
      'transferTokensTo',
      'transferTokensFrom'
    ]),

    // Validates and OKs the dialog
    async ok () {
      this.dialogOk({ dialog })
      this.$emit('close')
    },

    // Cancels the dialog
    cancel () {
      this.dialogCancel({ dialog })
      this.$emit('close')
    },

    // Triggered when dialog is shown
    dialogShown () {
      this.$emit('show')
      this.clear()
    },

    // Triggered when dialog is hidden
    dialogHidden () {
      this.$emit('hide')
    },

    // Saves token transfer
    async transfer () {
      const { notes, isBank, customer } = this
      const transactionType = this.amount > 0 ? WalletTransactionType.Credit : WalletTransactionType.Debit
      const amount = Math.abs(this.amount)

      if (transactionType === WalletTransactionType.Credit) {
        // Transfer from our wallet to theirs
        if (isBank) {
          await this.creditTokens({ amount, notes })
        } else {
          await this.transferTokensTo({ amount, notes })
        }

      } else if (transactionType === WalletTransactionType.Debit) {
        // Transfer from their wallet to ours
        if (isBank) {
          await this.debitTokens({ amount, notes })
        } else {
          await this.transferTokensFrom({ amount, notes })
        }

      } else {
        throw new Error(`Unsupported transaction type ${transactionType}`)
      }

      this.$emit('transfer', { customer, transactionType, amount, notes })

      this.amount = 0
      this.notes = ''
    },

    // Clears the inputs
    clear () {
      this.amount = 0
      this.notes = ''
    }
  },

  watch: {
    // Don't allow the amount to exceed tokens available
    // in the creditor's wallet or in customer's balance
    amount (newValue) {
      const { myAvailableTokens, customerWallet } = this
      if (newValue < 0) {
        this.amount = Math.max(newValue, -customerWallet.balance)
      } else {
        this.amount = Math.min(newValue, myAvailableTokens)
      }
    }
  },

  mounted () {
  }
}

</script>

<template>
  <sc-dialog seamless position="right" :dialog="dialog" :maximized="isSmallScreen"
    :cssClass="{ 'dialog-right-bottom': true }" @dialogShown="dialogShown()"
    @dialogHidden="dialogHidden()">

    <q-form class="form" ref="form">
      <div class="content">

        <q-banner class="banner bg-indigo-6">
          <div class="row items-center">
            <span class="text-white title">
              Customer {{ customer.name }}
            </span>
          </div>
        </q-banner>

        <div class="q-mt-md q-ml-lg q-mr-lg q-mb-lg inside">
          <div class="stats">
            <div class="text-h6">
              Summary
            </div>
            <div>
              Summary of devices owned by {{ customer.name }}:
            </div>
            <q-markup-table flat bordered square separator="cell" class="summary q-mt-sm">
              <tbody>
                <tr>
                  <td>
                    <div class="row items-center">
                      <sc-hint size="20px">
                        All devices owned by {{ customer.name }}
                      </sc-hint>
                      <span><b>All Devices</b></span>
                    </div>
                  </td>
                  <td>
                    <b>{{ customerDevices.length }}</b>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="row items-center">
                      <sc-hint size="20px">
                        Devices with active premium subscriptions
                      </sc-hint>
                      <span>Active Premium Devices</span>
                    </div>
                  </td>
                  <td>
                    {{ activeDevices.length }}
                  </td>
                </tr>
                <tr v-if="false">
                  <td>
                    <div class="row items-center">
                      <sc-hint size="20px">
                        Devices with basic functionality, which have premium subscriptions waiting
                        to be activated
                      </sc-hint>
                      <span>Pending Premium Devices</span>
                    </div>
                  </td>
                  <td>
                    {{ pendingDevices.length }}
                  </td>
                </tr>
                <tr v-if="false">
                  <td>
                    <div class="row items-center">
                      <sc-hint size="20px">
                        Devices with basic functionality, without any premium subscriptions
                      </sc-hint>
                      <span>Standard Devices</span>
                    </div>
                  </td>
                  <td>
                    {{ standardDevices.length }}
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="row items-center">
                      <sc-hint size="20px">
                        Devices with only basic functionality, whose premium subscriptions expired
                      </sc-hint>
                      <span>Expired Devices</span>
                    </div>
                  </td>
                  <td>
                    {{ expiredDevices.length }}
                  </td>
                </tr>
              </tbody>
            </q-markup-table>
          </div>

          <div class="transaction q-mt-lg" v-if="canTransfer">
            <div class="q-pr-md text-h6">
              Wallet Top-Up
            </div>
            <div class="q-pr-md" v-if="isBank">
              Enter the amount of tokens to add to the customer's wallet.
              To withdraw tokens from the customer's wallet,
              enter a negative amount.
            </div>
            <div v-else>
              Enter the amount of tokens to move from your wallet to the customer's wallet.
              To withdraw tokens from the customer's wallet back into your wallet,
              enter a negative amount.
            </div>

            <div class="row items-center no-wrap q-mt-sm">
              <div class="from q-pa-md q-mr-md"
                :class="{ 'bg-green-1': myRemainingTokens > 0, 'bg-red-1': myRemainingTokens === 0 }">
                <header>
                  From: {{ currentOrganization.name }}
                </header>
                <main v-if="isBank" class="text-h6 q-mt-md text-green-8">
                  Bank
                </main>
                <main v-else class="text-h6 q-mt-md"
                  :class="{ 'text-green-8': myRemainingTokens > 0, 'text-red-8': myRemainingTokens === 0 }">
                  {{ myRemainingTokens }} token{{ myRemainingTokens === 1 ? '' : 's' }}
                </main>
              </div>

              <div class="amount row items-center no-wrap q-gutter-md">
                <q-icon class="icon" size="lg" name="double_arrow" :class="{ reverse: amount < 0 }"
                  :color="amount >= 0 ? 'green-3' : 'red-3'">
                </q-icon>
                <q-input class="input" v-model.number="amount" type="number"
                  :min="-customerWallet.balance" :max="myAvailableTokens" :style="{ width: '70px' }"
                  square dense outlined />
                <q-icon class="icon" size="lg" name="double_arrow" :class="{ reverse: amount < 0 }"
                  :color="amount >= 0 ? 'green-3' : 'red-3'">
                </q-icon>
              </div>

              <div class="to q-pa-md q-ml-md"
                :class="{ 'bg-green-1': newCustomerBalance > 0, 'bg-red-1': newCustomerBalance === 0 }">
                <header>
                  To: {{ customer.name }}
                </header>
                <main class="text-h6 q-mt-md"
                  :class="{ 'text-green-8': newCustomerBalance > 0, 'text-red-8': newCustomerBalance === 0 }">
                  {{ newCustomerBalance }} token{{ newCustomerBalance === 1 ? '' : 's' }}
                </main>
              </div>

            </div>

            <div class="notes q-mt-md">
              <q-input v-model="notes" type="textarea" outlined label="Add a note for audit" bg-color="white"
                :input-style="{ height: '120px' }">
              </q-input>
            </div>

            <div class="row justify-end q-mt-lg">
              <q-space></q-space>
              <q-btn flat unelevated class="q-ml-md primary"
                :label="amount < 0 ? 'Debit tokens' : 'Credit tokens'"
                :class="amount < 0 ? 'warning' : 'success'"
                v-if="amount !== 0"
                @click="transfer()">
              </q-btn>
              <q-btn label="Close" unelevated class="q-ml-md" @click="ok()"></q-btn>
              </div>
          </div>

        </div>
      </div>
    </q-form>

  </sc-dialog>
</template>

<style lang='scss' scoped>
.form {
  width: 660px;
  min-height: 650px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .banner {
      flex: 0;
    }

    .inside {
      flex: 1;
      overflow: auto;
    }

    .title {
      font-size: 18px;
    }

    .summary {
      width: 100%;

      td:nth-child(1) {
        span {
          margin-left: 4px;
        }
      }

      td:nth-child(2) {
        text-align: right;
        width: 120px;
      }
    }

    .transaction {
      .from {
        flex: 1;
      }

      .to {
        flex: 1;
      }

      .icon.reverse {
        transform: scale(-1, 1);
      }
    }
  }
}

/* Layout adjustments for screen below HD resolution */
@media screen and (max-width: 1365px) {
  .q-dialog.fullscreen {
    top: 40px;
  }

  .form {
    width: 100%;
    min-height: 100%;
  }
}
</style>
