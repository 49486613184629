/**
 * v-focus directive ensuring that input element is focused
 * after being inserted into DOM
 */
export const AutoFocusDirective = {
  attribute: 'focus',
  directive: {
    // When the bound element is inserted into the DOM ...
    created: function (element) {
      // Focus the element, just give it a while so that all mouse events finish.
      // Otherwise sometimes there's a mouse up right after our focus,
      // which takes the focus away.
      window.setTimeout(() => {
        element.focus()
      }, 150)
    }
  }
}