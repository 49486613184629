<script>
import { CellMixin } from './cell-mixin'

export default {
  mixins: [
    CellMixin
  ],

  props: {
    date: {
    },

    emptyDate: {
      default: '-',
    }
  }
}
</script>

<template>
  <div :style="columnStyle" class="text">
    <span v-bind="{...$props, ...$attrs}">
      <template v-if="date">
      {{ dateString(date) }}
      </template>
      <template v-else>
        {{ emptyDate }}
      </template>
    </span>
  </div>
</template>

<style>
</style>