/**
 * Ensures that field is required
 * @param message Error message to show when condition is not met
 */
function required (message) {
  return value => {
    const isValid = value != null && value.toString().length > 0
    return isValid || message || 'This field is required'
  }
}

/**
 * Ensures that another item with the specified name does not exist in the collection
 * @param id Identifier of the currently edited item
 * @param collection Collection to check
 * @param message Error message to show when condition is not met
 * @description
 * It will not fail when entered value is empty.
 * Use a separate `required` rule to enforce this.
 */
function nameIsUnique (id, inCollection = [], message) {
  return value => {
    if (value == null) {
      return true
    }
    const isValid = !inCollection.some(item => item.name === value && item.id !== id)
    return isValid || message || `${value} already exists`
  }
}

/**
 * Ensures that value has the specified minimal length
 * @param {Number} length Required length
 * @param {String} message Error message to show when condition is not met
 * @param {Boolean} allowEmpty If true, empty value is also allowed
 */
function hasLength (length, message, allowEmpty) {
  return value => {
    const isValid = (value && value.length >= length) || (!value && allowEmpty)
    return isValid || message || `At least ${length} character${length > 1 ? 's' : ''} required`
  }
}

/**
 * Ensures that value has the specified exact length
 * @param {Number} length Required length
 * @param {String} message Error message to show when condition is not met
 * @param {Boolean} allowEmpty If true, empty value is also allowed
 */
function hasExactLength (length, message, allowEmpty) {
  return value => {
    const isValid = (value && value.length === length) || (!value && allowEmpty)
    return isValid || message || `${length} character${length > 1 ? 's' : ''} required`
  }
}

/**
 * Ensures that the entered value is a valid e-mail address.
 * @param message Error message to show when condition is not met
 * @description
 * It will not fail when entered value is empty.
 * Use a separate `required` rule to enforce this.
 */
function isEmail (message) {
  const validEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return value => {
    if (value) {
      const isValid = value && value.length > 0 && validEmail.test(value)
      return isValid || message || 'Invalid e-mail address'
    } else {
      return true
    }
  }
}

/**
 * Ensures that the entered value is a number, optionally within the specified range
 * @param min Minimal required value
 * @param max Maximal required value
 * @param message Error message to show when condition is not met
 * @description
 * It will not fail when entered value is empty.
 * Use a separate `required` rule to enforce this.
 */
function isNumber (min, max, message) {
  const checkRange = min != null || max != null
  return value => {
    if (value == null || value === '') {
      return true
    }
    const number = parseInt(value)
    let isValid = value && !isNaN(number)
    isValid = isValid && (min == null || number >= min) && (max == null || number <= max)
    if (!isValid) {
      if (!message) {
        if (checkRange) {
          const rangeMessages = [
            min == null ? '' : `at least ${min}`,
            max == null ? '' : `no more than ${max}`
          ]
          message = `The number must be ${rangeMessages.join(' and ')}`
        } else {
          message = 'Invalid number'
        }
      }
    }
    return isValid || message
  }
}

/**
 * Ensures that the entered value is made of the specified characters only
 * @param allowedCharacters A string containing all allowed characters
 * @param message Error message to show when condition is not met
 */
function hasOnlyCharacters (allowedCharacters = '', message) {
  return value => {
    const isValid = !value || Array.from(value || '').every(ch => allowedCharacters.includes(ch))
    return isValid || (message || 'Only digits are allowed')
  }
}

/**
 * Ensures that the entered value is made of digits only
 * @param message Error message to show when condition is not met
 */
function hasOnlyDigits (message) {
  return hasOnlyCharacters('0123456789', message)
}

/**
 * Collection of form validation rules
 */
export const FormValidationRules = {
  required,
  nameIsUnique,
  hasLength,
  hasExactLength,
  hasOnlyCharacters,
  hasOnlyDigits,
  isEmail,
  isNumber
}
