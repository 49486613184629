import { parseBoolean, safeParseInt } from '@stellacontrol/utilities'
import { Assignable } from '@stellacontrol/model'

/**
 * Plan radiation map layout
 */
export class PlanRadiation extends Assignable {
  constructor (data = {}) {
    super(data)
    this.assign(data)
  }

  /**
   * Object defaults
   */
  get defaults () {
    return {
      maskOutside: true,
      lockWalls: false,
      strength: 100
    }
  }

  normalize () {
    super.normalize()
    const { defaults } = this
    this.maskOutside = parseBoolean(this.maskOutside, defaults.maskOutside)
    this.lockWalls = parseBoolean(this.lockWalls, defaults.lockWalls)
    this.strength = safeParseInt(this.strength, defaults.strength)
  }

  /**
   * Serializes the item to JSON
   * @returns {Object}
   */
  toJSON () {
    const result = {
      ...this
    }
    return this.clearDefaults(result)
  }

  /**
   * Mask the areas outside the defined building perimeter,
   * so that no radiation from the antennas is rendered there
   * @type {Boolean}
   */
  maskOutside

  /**
   * Locks walls and yards in place.
   * To modify or delete them, lock must be disabled.
   * This is to prevent accidental amendments to the radiation layer,
   * which is normally fixed and defined early during design
   * @type {Boolean}
   */
  lockWalls

  /**
   * Resets the background to default values
   */
  reset () {
    const { defaults } = this
    this.maskOutside = defaults.maskOutside
  }
}
