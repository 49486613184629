<script>

/**
 * Work in Progress view
 */
export default {
  data () {
    return {
      messages: [
        'A work in progress. And the possibilities are endless. Stay tuned!',
        'We\'re all a beautiful, wonderful work in progress....Embrace the process!',
        'Happiness is not a finished product, it is a work in progress.',
        'So, what is my story? I don\'t know, but one day I will.',
        'Waiting for something ...',
        'The road to hell is paved with works-in-progress.',
        'Life is a work in progress.',
        'We\'re not perfect. We\'re a work in progress.',
        'I\'m a work in progress. I\'m trying to be better.'
      ],

      message: null
    }
  },

  methods: {
    getRandomMessage () {
      const index = Math.floor(Math.random() * this.messages.length)
      return this.messages[index]
    }
  },

  created () {
    this.message = this.getRandomMessage()
  }
}

</script>

<template>
  <q-layout>
    <q-page-container>
      <q-page class="column items-center justify-center">
          <div class="q-mb-lg">
            <label class="text-h6 text-weight-light">
              {{ message }}
            </label>
          </div>
          <sc-back-to-login if-logged-out label="Go back to login page">
          </sc-back-to-login>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<style lang="scss" scoped>
</style>
