<script>
import { mapState, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapState({
      // Recent error
      error: state => state.alertsView.error
    }),

    // Indicates that there was an error while loading statistics
    hasError () {
      return this.error != null
    }
  },

  methods: {
    ...mapMutations([
      'clearAlertStatisticsError'
    ])
  }
}
</script>

<template>
  <div class="overlay column items-center justify-center">
  </div>
  <div class="container column items-center justify-center">
    <div class="error q-pa-lg column items-center justify-center">
      <span>
        Alert Statistics could not be retrieved.
      </span>
      <span class="q-mt-sm">
        Please select a shorted period and try again.
      </span>
      <q-btn unelevated dense class="primary q-mt-lg" label="Dismiss"
        @click="clearAlertStatisticsError()">
      </q-btn>
    </div>
  </div>
</template>

<style scoped lang="scss">
.overlay {
  flex: 1;
  position: absolute;
  left: 0;
  top: 8px;
  right: 0;
  bottom: 0;
  z-index: 100;
  opacity: 0.6;
  background-color: #808080;
}

.container {
  flex: 1;
  position: absolute;
  left: 0;
  top: 8px;
  right: 0;
  bottom: 0;
  z-index: 101;
}

.error {
  width: 350px;
  border: solid grey 1px;
  background-color: white;

  .error[data-v-53624cfc] {
    width: 450px;
    border: solid grey 1px;
    background-color: white;
    box-shadow: 0 0 12px #a0a0a0;
  }
}
</style>