<script>
import { mapGetters } from 'vuex'
import { sameArrays, getUTCDelta } from '@stellacontrol/utilities'
import { Secure } from '@stellacontrol/security-ui'
import ParameterChart from './components/parameter-chart.vue'
import PeriodSelector from './components/period-selector.vue'
import ParameterSelector from './components/parameter-selector.vue'
import ExtrasSelector from './components/extras-selector.vue'

export default {
  mixins: [
    Secure
  ],

  props: {
    // Chart rendering options
    options: {
      type: Object,
      default: () => { }
    },
    // Currently viewed device
    device: {
    },
    // Selected period
    period: {
    },
    // Maximal period length, in days
    maxDays: {
      default: 1
    },
    // Parameters to show
    parameters: {
      default: () => []
    },
    // Extras to show, such as alerts, firmware updates etc.
    extras: {
      default: () => []
    },
    // Visibility of filter
    showFilter: {
      type: Boolean,
      default: true
    },
    // Maximal height of the chart panel.
    // If not specified, the chart stretches vertically.
    maxHeight: {
    }
  },

  components: {
    'sc-period-selector': PeriodSelector,
    'sc-parameter-selector': ParameterSelector,
    'sc-extras-selector': ExtrasSelector,
    'sc-parameter-chart': ParameterChart
  },

  data () {
    return {
      // Chart component
      chart: null,
      // Indicates whether the component is still initializing
      isInitializing: true,
      // Indicates whether the chart is currently populating
      isPopulating: false
    }
  },

  computed: {
    ...mapGetters([
      'currentTimezone',
      'isProductionEnvironment'
    ]),

    // Delta between current time and UTC time
    utcDelta () {
      return getUTCDelta()
    },

    // User-friendly description of the viewer's timezone
    currentTimezoneLabel () {
      const { currentTimezone } = this
      if (currentTimezone) {
        return `Event times are according to ${currentTimezone.value}`
      }
    },

    // Timezone of the current organization
    // Checks if settings are correct and the chart can be populated
    canPopulate () {
      const { isPopulating, device, period, parameters, extras } = this
      return !isPopulating &&
        device &&
        period?.isValid &&
        (parameters?.length > 0 || extras?.length > 0) &&
        this.$refs.chart
    },

    // Chart container style
    chartContainerStyle () {
      const style = {}
      return style
    }
  },

  emits: [
    'initialized',
    'filter'
  ],

  watch: {
    // Period changed, reload the chart
    period (newValue, currentValue) {
      if (newValue?.sameAs(currentValue)) return
      this.update()
    },

    // Parameters changed, reload the chart
    parameters (newValue, oldValue) {
      if (sameArrays(newValue, oldValue)) return
      this.update()
    },

    // Extras changed, reload the chart
    extras (newValue, oldValue) {
      if (sameArrays(newValue, oldValue)) return
      this.update()
    }
  },

  methods: {
    // Triggered when filter changed
    async filterChanged ({ period, parameters, extras } = {}) {
      this.$emit('filter', { period, parameters, extras })
    },

    // Triggered when chart has been initialized
    chartInitialized () {
      this.$emit('initialized')
    },

    // Loads chart data and populates the chart
    async populate () {
      if (this.canPopulate) {
        this.isPopulating = true
        try {
          await this.$refs.chart.populate()
        } finally {
          if (this.isInitializing) {
            this.isInitializing = false
            this.$emit('initialized')
          }
          this.isPopulating = false
        }
      }
    },

    // Updates the chart after parameters have changed
    update () {
      if (!this.isInitializing) {
        this.$nextTick(async () => {
          await this.populate()
        })
      }
    },

    // Resets chart zoom
    resetZoom () {
      this.$refs.chart?.resetZoom()
    }
  }
}
</script>

<template>
  <main class="device-history-panel">
    <section class="filter-container q-pa-md bg-grey-1" v-if="showFilter">
      <div>
        <sc-period-selector :model-value="period" :maxDays="maxDays"
          @update:model-value="period => filterChanged({ period })"></sc-period-selector>
      </div>
      <div class="q-mt-lg">
        <sc-parameter-selector :model-value="parameters" :device="device"
          @update:model-value="parameters => filterChanged({ parameters })">
        </sc-parameter-selector>
      </div>
      <div class="q-mt-lg">
        <sc-extras-selector :model-value="extras" :device="device"
          @update:model-value="extras => filterChanged({ extras })"></sc-extras-selector>
      </div>
      <div class="row items-center justify-center q-mt-lg" v-if="false">
        <q-btn label="Show device history" class="success" unelevated @click="populate()">
        </q-btn>
      </div>
    </section>

    <section class="chart-container q-pl-md q-mt-md q-pb-md" :style="chartContainerStyle">
      <main class="chart">
        <sc-parameter-chart ref="chart" :device="device" :period="period" :parameters="parameters"
          :extras="extras" :options="options" @initialized="chartInitialized()">
        </sc-parameter-chart>
      </main>
      <footer class="chart-footer">
        <span v-if="currentTimezone && utcDelta !== 0" class="text-body2 text-grey-7">
          {{ currentTimezoneLabel }}
        </span>
        <span v-else-if="!currentTimezone && utcDelta !== 0" class="text-body2 text-grey-7">
          <span>
            The events are shown using Universal Coordinated Time
          </span>
          <span v-if="utcDelta != 0">
            which is {{ duration(utcDelta) }}
            {{ utcDelta > 0 ? 'earlier' : 'later' }}
            than your local time
          </span>
        </span>
        <span v-else>

        </span>
      </footer>
    </section>
  </main>
</template>

<style lang='scss' scoped>
.device-history-panel {
  flex: 1;
  display: flex;
  flex-direction: row;
  overflow: hidden;

  .filter-container {
    flex: 0;
    flex-basis: 250px;
    display: flex;
    flex-direction: column;
    border-right: solid #0000001f 1px;
    overflow: auto;
  }

  .chart-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .chart {
      flex-basis: 500px;
    }

    .chart-footer {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
      padding-right: 20px;
    }
  }
}
</style>
