import { isMegaParameterApplicable } from '@stellacontrol/mega'

/**
 * Checks whether upload job can be started automatically,
 * or it requires explicit user request
 * @param {UploadJob} job Upload job to check
 * @returns {Boolean}
 */
export function jobRequiresUserRequest (job) {
  return job &&
    job.onUserRequest &&
    deviceUpdatedByUserRequest(job.device)
}

/**
 * Checks whether device can be updated automatically,
 * or it requires explicit user request
 * @param {Device} device Device to check
 * @returns {Boolean}
 */
export function deviceUpdatedByUserRequest (device) {
  return device &&
    isMegaParameterApplicable('fw_update_receive', device)
}
