<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { DialogMixin } from '@stellacontrol/client-utilities'
import { Secure } from '@stellacontrol/security-ui'

const dialog = 'purchase-tokens'

export default {
  mixins: [
    DialogMixin,
    Secure
  ],

  data () {
    return {
      dialog,
      // Amount of tokens to buy
      amount: 0

    }
  },

  computed: {
    ...mapState({
      myWallet: state => state.serviceManagement.wallet
    }),

    ...mapGetters([
      'isSmallScreen'
    ]),

    // Current organization's available tokens.
    balance () {
      return this.myWallet.balance
    },

    // New balance of customer's wallet after adding the tokens
    newBalance () {
      const { balance, amount } = this
      return balance + Math.max(0, amount || 0)
    }
  },

  emit: [
    'show',
    'hide',
    'transfer'
  ],

  methods: {
    ...mapActions([
      'dialogOk',
      'dialogCancel',
      'creditTokens'
    ]),

    // Validates and OKs the dialog
    async ok () {
      this.dialogOk({ dialog })
      this.$emit('close')
    },

    // Cancels the dialog
    cancel () {
      this.dialogCancel({ dialog })
      this.$emit('close')
    },

    // Triggered when dialog is shown
    dialogShown () {
      this.$emit('show')
    },

    // Triggered when dialog is hidden
    dialogHidden () {
      this.$emit('hide')
    },

    // Credits the token to current wallet
    async credit () {
    }
  },

  mounted () {
  }
}

</script>

<template>
  <sc-dialog persistent :dialog="dialog" :maximized="isSmallScreen" @dialogShown="dialogShown()"
    @dialogHidden="dialogHidden()">

    <q-form class="form" ref="form">
      <div class="content">

        <q-banner class="banner bg-indigo-6">
          <div class="row items-center">
            <span class="text-white title">
              Purchase tokens
            </span>
            <q-space></q-space>
            <q-btn label="Close" unelevated class="primary" @click="ok()"></q-btn>
          </div>
        </q-banner>

        <div class="q-mt-md q-ml-lg q-mr-lg q-mb-lg inside">
          <div class="text-body2 column">
            <span>
              There {{ balance === 1 ? 'is' : 'are' }} currently <b>
              {{ balance === 0 ? 'no' : balance }}</b>
              {{ balance === 1 ? 'token' : 'tokens'}}
              in your wallet.
              You can use tokens to activate premium services
              on devices, such as live status updates,
              alerts or access to device history.
            </span>
            <span class="q-mt-md">
              Please contact your reseller to purchase more tokens!
            </span>
          </div>
        </div>
      </div>

    </q-form>

  </sc-dialog>
</template>

<style lang='scss' scoped>
.form {
  width: 550px;
  min-height: 250px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .banner {
      flex: 0;
    }

    .inside {
      flex: 1;
      overflow: auto;
    }

    .title {
      font-size: 18px;
    }
  }
}

/* Layout adjustments for screen below HD resolution */
@media screen and (max-width: 1365px) {
  .q-dialog.fullscreen {
    top: 40px;
  }

  .form {
    width: 100%;
    min-height: 100%;
  }
}
</style>
