import { safeParseFloat, round } from '@stellacontrol/utilities'
import { Assignable } from '@stellacontrol/model'

/**
 * Properties of a background
 */
export class PlanBackgroundStyle extends Assignable {
  constructor (data = {}) {
    super(data)
    this.assign(data)
  }

  /**
   * Creates style representing transparent background
   * @type {PlanBackgroundStyle}
   */
  static get None () {
    return new PlanBackgroundStyle()
  }

  /**
   * Object defaults
   */
  get defaults () {
    return {
      opacity: 1
    }
  }

  normalize () {
    super.normalize()
    const { defaults } = this
    this.opacity = round(Math.max(0, Math.min(1, safeParseFloat(this.opacity, defaults.opacity))), 2)
  }

  /**
   * Checks if style is the same as the specified one
   * @param {PlanBackgroundStyle} style
   * @returns {Boolean}
   */
  sameAs (style) {
    if (style) {
      return this.color === style.color &&
        this.image === style.image &&
        this.opacity === style.opacity
    }
  }

  /**
   * Checks whether the plan background style is empty
   * @returns {Boolean}
   */
  get isNone () {
    return this.sameAs(new PlanBackgroundStyle())
  }

  /**
   * Opacity, from 0 to 1
   * @type {Number}
   */
  opacity

  /**
   * Color
   * @type {String}
   */
  color

  /**
   * Image URL
   * @type {String}
   */
  image

  /**
   * Returns true if style is not defined
   * @type {Boolean}
   */
  get isEmpty () {
    return !(this.color || this.image)
  }
}
