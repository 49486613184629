<script>
import { mapActions, mapState } from 'vuex'
import { FormMixin, DefaultListActions } from '@stellacontrol/client-utilities'
import { Secure } from '@stellacontrol/security-ui'

export default {
  mixins: [
    FormMixin,
    Secure
  ],

  props: [
    'data'
  ],

  data () {
    return {
      DefaultListActions
    }
  },

  computed: {
    ...mapState({
      placeFloorPlanColumns: state => state.places.placeFloorPlanColumns
    }),

    floorPlans () {
      return this.data.floorPlans || []
    },

    hasFloorPlans () {
      return this.floorPlans.length > 0
    }
  },

  methods: {
    ...mapActions([
      'editFloorPlan',
      'removeFloorPlan',
      'createFloorPlan'
    ]),

    async executeAction (floorPlan, action) {
      switch (action.name) {
        case 'edit':
          return await this.editFloorPlan({ floorPlan })
        case 'delete':
          return this.removeFloorPlan({ floorPlan, confirm: true })
        default:
          throw new Error(`Unhandled action ${action.name}`)
      }
    }
  }
}

</script>

<template>
  <div class="container">
    <div class="header">
      <div class="row items-center">
        <q-btn icon="add" label="Add Floor Plan" unelevated
          @click="createFloorPlan({ organization: data.organization })"/>
        <q-space></q-space>
        <span class="q-mb-md" v-if="!hasFloorPlans">
          You can add here a new floor plan assigned to {{ data.name }}
        </span>
      </div>
    </div>

    <div class="table q-mt-sm" v-if="hasFloorPlans">
      <sc-list
        name="place-floor-plans"
        row-key="id"
        :columns="placeFloorPlanColumns"
        :items="floorPlans"
        :actions="[ ...DefaultListActions ]" @action="executeAction">

        <template v-slot:body-cell-name="props">
          <q-td :props="props">
            <router-link class="item-link" :to="{ name: 'floor-plan', params: { id: props.row.id } }">
              {{ props.row.name }}
              <sc-tooltip :text="`Edit ${props.row.name}`" />
            </router-link>
          </q-td>
        </template>
      </sc-list>
    </div>
  </div>
</template>

<style lang='scss' scoped>
.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    flex: 0;
  }

  .table {
    flex: 1;
    overflow: auto;
    border-top: solid silver 1px;
  }
}
</style>
