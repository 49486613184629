import { dispatch } from '@stellacontrol/client-utilities'

export async function resolveFloorPlans ({ from, to }) {
  await dispatch('requireOrganizations')
  const data = await dispatch('getFloorPlans')
  if (data) {
    await dispatch('setRouteData', { from, to, data })
    return data
  }
}

export async function resolveFloorPlan ({ from, to }) {
  await dispatch('requireOrganizations')
  await dispatch('requireFloorPlans')

  const { params: { id }, query: { organization: organizationId } = {} } = to
  const data = id === 'new'
    ? await dispatch('newFloorPlan', { organizationId })
    : await dispatch('getFloorPlan', { id })

  if (data) {
    const title = id === 'new' ? 'New Floor Plan' : data.name
    await dispatch('setRouteData', { from, to, data, title })
    return data
  }
}
