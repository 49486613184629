/**
 * Login failure reasons
 */
export const LoginError = {
  UserNotFound: 'not-found',
  UserDisabled: 'disabled',
  TooManyFailedLogins: 'too-many-failed-logins',
  InvalidPassword: 'invalid-password',
  InvalidChallenge: 'invalid-challenge',
  UserNotAuthorized: 'not-authorized',
  Unknown: 'unknown'
}
