/**
 * User activity log
 */
export function createState() {
  return {
    /**
     * User activities
     * @type Array[AuditItem]
     */
    items: []
  }
}

export const state = createState()

export const getters = {
}
