import { PlanAction, PlanActions } from './plan-action'
import { RemoveItemsAction } from './remove-items'

/**
 * Removes all items from the floor
 */
export class ClearFloorAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.ClearFloor
  }

  /**
   * Indicates that the action can be executed on a batch of items
   * @type {Boolean}
   */
  get allowBatch () {
    return true
  }

  /**
  * Action label
  * @type {String}
  */
  get label () {
    return 'Delete everything'
  }

  /**
   * Action icon
   * @type {String}
   */
  get icon () {
    return 'delete'
  }

  /**
   * Action icon color
   * @type {String}
   */
  get color () {
    return 'deep-orange-4'
  }

  /**
   * Use this to indicate that action requires refresh
   * of the plan stage area after completion
   * @type {Boolean}
   */
  get requiresRefresh () {
    return true
  }

  /**
   * User has to confirm the action
   * @type {String}
   */
  get confirmation () {
    const { floor } = this.data
    return floor?.isCrossSection
      ? 'Clear the entire plan?'
      : `Delete all items from ${floor?.label || 'the floor'}?`
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   */
  async execute ({ renderer } = {}) {
    if (renderer) {
      const { floor, floor: { items }, backgroundLayer, radiationLayer } = renderer
      floor.reset()

      const action = new RemoveItemsAction()
      action.execute({
        renderer,
        items
      })

      renderer.setZoom(1)
      radiationLayer.render()
      backgroundLayer.render()
      await renderer.saveFloorImage()
    }
  }
}
