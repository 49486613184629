import { round } from '@stellacontrol/utilities'
import { PlanItemType } from '@stellacontrol/planner'
import { LineShape } from './line'

/**
 * Ruler
 */
export class RulerShape extends LineShape {
  constructor (item, dataCallback) {
    super(item, dataCallback)
    this.createShapes()
  }

  static get type () {
    return PlanItemType.Ruler
  }

  /**
   * Determines the text of the ruler label - distance in meters
   * @param {PlanRenderer} renderer Renderer instance
   * @returns {String}
   */
  getLabelText ({ renderer }) {
    if (!renderer) throw new Error('Renderer is required')

    const { item } = this
    if (item.hasAllPoints) {
      const [start, end] = item.points
      const distance = end.distance(start)
      const scale = renderer.floor.pixelsToMeters(distance, false)
      return `${round(scale, 1)}m`
    }
  }

  /**
   * Renders the ruler shape
   * @param {PlanRenderer} renderer Plan renderer
   */
  render (renderer) {
    const { item } = this

    // Instead of arrows, show short perpendicular lines
    item.arrowStyle.width = 14
    item.arrowStyle.height = 0.1

    // Show the other end only if the point has moved
    item.arrowStyle.end = item.hasAllPoints

    super.render(renderer)
  }
}
