import { DeviceLinkType } from './device-link-type'

/**
 * Defines how many current links of specified type
 * can a device have. For example, at any given moment
 * a device has only one legal owner, while it might
 * have multiple users allowed to access it.
 */
export const DeviceLinkLimit = {
  // Device has only one current owner organization
  [DeviceLinkType.Owner]: 1,
  // Device can have multiple delegates having access to it
  [DeviceLinkType.Delegate]: Number.POSITIVE_INFINITY
}
