import FloorPlansView from './floor-plans.vue'
import FloorPlanView from './floor-plan.vue'
import { resolveFloorPlan, resolveFloorPlans } from './floor-plans.resolve'

export const Routes = [
  {
    name: 'floor-plans',
    path: '/floor-plans',
    component: FloorPlansView,

    async beforeEnter (to, from, next) {
      if (await resolveFloorPlans({ to, from })) {
        next()
      }
    }
  },
  {
    name: 'floor-plan',
    path: '/floor-plan/:id',
    component: FloorPlanView,
    viewAlias: {
      'default': 'floor-plan',
      'place': 'place-floor-plan'
    },

    async beforeEnter (to, from, next) {
      if (await resolveFloorPlan({ to, from })) {
        next()
      }
    }
  }
]
