import { Notification, NotificationRecipient, SecurityRequest } from '@stellacontrol/model'
import { APIClient } from './api-client'

/**
 * Notifications API client
 */
export class NotificationsAPIClient extends APIClient {
  /**
   * Returns API name served by this client
   */
  get name () {
    return 'Notifications'
  }

  /**
   * Saves a notification in database
   * @param {Notification} notification Notification to save
   * @returns {Promise<Notification>} Details of saved notification
   */
  async saveNotification ({ notification } = {}) {
    try {
      const method = 'post'
      const url = this.endpoint('notification')
      const data = notification
      const { notification: savedNotification } = await this.request({ method, url, data })
      return this.asNotification(savedNotification)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Queues a notification for sending
   * @param {Notification} notification Notification to queue
   * @returns {Promise<Notification>} Details of queued notification
   */
  async queueNotification ({ notification } = {}) {
    try {
      const method = 'post'
      const url = this.endpoint('notification', 'queue')
      const data = notification
      const { notification: queuedNotification } = await this.request({ method, url, data })
      return this.asNotification(queuedNotification)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Sends a single notification
   * @param {Notification} notification Notification to send
   * @param {NotificationRecipient} recipient Optional recipient of the notifications. If not specified,
   * notification will be sent to recipient originally specified in the notification data.
   * @param {String} template Optional template to use, if different than default
   * @returns {Promise<Notification>} Details of queued notification
   */
  async sendNotification ({ notification: { id }, recipient, template } = {}) {
    try {
      const method = 'post'
      const url = this.endpoint('notification', 'send')
      const data = {
        identifiers: [id],
        bundle: false,
        template,
        recipient
      }
      // Allow a long 10 minutes timeout as sending multiple emails can be a very time-consuming operation.
      // Do not retry to avoid duplicate emails if request is still busy while the client times out.
      const timeout = 10 * 60 * 1000
      const retry = false
      const { results } = await this.request({ method, url, data, timeout, retry })
      return results
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Sends a batch of notifications
   * @param {Notification} notification Notification to send
   * @param {NotificationRecipient} recipient Optional recipient of the notifications. If not specified,
   * notification will be sent to recipient originally specified in the notification data.
   * @param {Boolean} bundle If true, notifications are sent in one bundle message rather than each one separately
   * @param {String} template Optional template to use, if different than default
   * @returns {Promise<Notification>} Details of queued notification
   */
  async sendNotifications ({ notifications = [], recipient, bundle = true, template } = {}) {
    try {
      const method = 'post'
      const url = this.endpoint('notification', 'send')
      const data = {
        identifiers: notifications.map(n => n.id),
        bundle,
        template,
        recipient
      }

      // Allow a long 10 minutes timeout as sending multiple emails can be a very time-consuming operation.
      // Do not retry to avoid duplicate emails if request is still busy while the client times out.
      const timeout = 10 * 60 * 1000
      const retry = false
      const { results } = await this.request({ method, url, data, timeout, retry })
      return results
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Retrieves all unsent notifications from the database using Notification API
   * @param {String} recipientId Identifier of a recipient
   * @param {String} source Optional source of notifications
   * @param {Number} age Optional maximal age of unsent notifications to retrieve
   * @param {String} environment If specified, only notifications originating from the specified environment will be considered.
   * @param {Boolean} withDetails If true, all data of notifications is returned, otherwise just basic data such as id, creation date etc.
   * @returns {Promise<Array[Notification]>} Unsent notifications
   */
  async getUnsentNotifications ({ recipientId, source, age, environment, withDetails = true } = {}) {
    try {
      const method = 'get'
      const url = this.endpoint('notification')
      const params = { recipientId, source, unsent: true, age, environment, details: withDetails }
      const { notifications } = await this.request({ method, url, params })
      return this.asNotifications(notifications)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Retrieves all recipients who currently have notifications waiting to be sent
   * @param {String} source Optional source of notifications
   * @param {Number} age Optional maximal age of unsent notifications to consider
   * @param {String} environment If true, only notifications originating from the specified environment will be considered.
   * @returns {Promise<Array[Notification]>} Notification recipients
   */
  async getWaitingRecipients ({ source, age, environment } = {}) {
    try {
      const method = 'get'
      const url = this.endpoint('notification', 'recipient')
      const params = { source, unsent: true, age, environment }
      const { recipients } = await this.request({ method, url, params })
      return this.asNotificationRecipients(recipients)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Stores the notification in database using Notification API
   * @param {Array[Notification]} notifications Notifications to flag
   * @param {Boolean} sent Mark notifications as sent
   * @param {Boolean} unsent Mark notifications as unsent
   * @param {Boolean} failed Mark notifications as failed
   * @param {Boolean} acknowledged Mark notifications as acknowledged
   */
  async flagNotifications ({ notifications, sent, unsent, failed, acknowledged } = {}) {
    if (notifications && notifications.length > 0 && (failed || sent || unsent || acknowledged)) {
      try {
        const method = 'put'
        const url = this.endpoint('notification', 'flag')
        const data = {
          identifiers: notifications.map(n => n.id),
          sent,
          unsent,
          failed,
          acknowledged
        }
        await this.request({ method, url, data })
      } catch (error) {
        this.handleError(error)
      }
    }
  }

  /**
   * Returns an unsubscribe request for notifications
   * @param {String} token Request token
   * @returns {Promise<SecurityRequest>}
   */
  async getUnsubscribeRequest ({ token }) {
    try {
      const method = 'get'
      const url = this.endpoint('notification', 'unsubscribe', token)
      const { request } = await this.request({ method, url })
      return this.asSecurityRequest(request)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Unsubscribes from notifications specified by the given security request
   * @param {String} token Request token
   * @returns {Promise<Object>} Result of the operation
   */
  async unsubscribe ({ token }) {
    try {
      const method = 'delete'
      const url = this.endpoint('notification', 'unsubscribe', token)
      const { success } = await this.request({ method, url })
      return { success }
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Converts the specified data item
   * received from API to Notification instance
   * @param {Object} item Data item
   * @returns {Notification} Instance initialized with the content of the data item
   */
  asNotification (item) {
    if (item) {
      return new Notification(item)
    }
  }

  /**
   * Converts the specified data items
   * received from API to Notification instances
   * @param {Array} items Data items
   * @returns {Array[Notification]} Instances initialized with the content of the data items
   */
  asNotifications (items = []) {
    return items.map(item => new Notification(item))
  }

  /**
   * Converts the specified data item
   * received from API to NotificationRecipient instance
   * @param {Object} item Data item
   * @returns {NotificationRecipient} Instance initialized with the content of the data item
   */
  asNotificationRecipient (item) {
    if (item) {
      return new NotificationRecipient(item)
    }
  }

  /**
   * Converts the specified data items
   * received from API to NotificationRecipient instances
   * @param {Array} items Data items
   * @returns {Array[NotificationRecipient]} Instances initialized with the content of the data items
   */
  asNotificationRecipients (items = []) {
    return items.map(item => new NotificationRecipient(item))
  }

  /**
   * Converts the specified data item
   * received from API to SecurityRequest instance
   * @param {Object} item Data item
   * @returns {SecurityRequest} Instance initialized with the content of the data item
   */
  asSecurityRequest (item) {
    if (item) {
      return new SecurityRequest(item)
    }
  }
}
