import { state, getters } from './places.state'
import { mutations } from './places.mutations'
import { actions } from './places.actions'

export default {
  state,
  getters,
  mutations,
  actions
}
