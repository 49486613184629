import { merge, range, Color } from '@stellacontrol/utilities'
import { ChartSeries } from '@stellacontrol/model'

/**
 * Default options for the spectrum chart
 */
const SpectrumChartOptions = {
  plugins: {
    legend: {
      display: false,
      position: 'bottom',
      align: 'center'
    }
  }
}

/**
 * Prepares data for charting the spectrum scans for the specified scan results
 * @param {Object} item Single item in spectrum scan results
 * @param {Dictionary<Number,String>} operators Operators dictionary, mapping their PLNM numbers to user-friendly names
 * @param {Object} options Options for the spectrum chart
 * @returns {Object} Chart configuration as required by `Chart.JS` library
 */
export function getScanSpectrumChart (item, operators, options = SpectrumChartOptions) {
  const series = getSeries(item, operators)
  const labels = getLabels(item)
  const scales = getScales(item)
  const annotations = getAnnotations(item, operators)
  const title = [
    'Band',
    `B${item.bandNumber}`,
    item.wasUplink ? 'UL' : 'DL',
    item.samplingMode === 0 ? 'Peak,' : 'Inst,',
    item.timeTakenMs,
    'ms'
  ].join(' ')

  return {
    title,
    labels,
    series,
    annotations,
    options: merge(options, { scales })
  }
}

// Determines the color of the series
function getSeriesColor (index, random) {
  if (random) {
    const colors = ['#080808', '#673ab7', '#9c27b0', '#c2185b']
    const color = colors[index % 4]
    const colorService = new Color()
    return colorService.lighten(color, 10)
  } else {
    return '#3f51b5'
  }
}

// Creates chart data series for the specified spectrum results item
function getSeries (item, operators) {
  const allocation = item.operatorAllocation || []
  const range = (item.endKHz - item.startKHz) / 1000
  const points = getPoints(item)
  const count = points.length

  const series = allocation.map((operator, index) => {
    const type = 'line'
    const name = `spectrum-${operator.plmn}-${index}`
    const plmn = operator.plmn.toString()
    const label = operators[plmn] || plmn
    const operatorCount = Math.floor((operator.allocationMhz / range) * count)
    const data = points.splice(0, operatorCount + 1)
    const backgroundColor = getSeriesColor(index)
    const borderColor = backgroundColor
    const unit = 'dB'

    return new ChartSeries({
      type,
      name,
      label,
      unit,
      backgroundColor,
      borderColor,
      pointStyle: false,
      parsing: false,
      tension: 0,
      stepped: false,
      cubicInterpolationMode: 'default',
      data
    })
  })

  return series
}

// Creates series data points for the specified spectrum results item
function getPoints (item) {
  return item
    .spectrumPoints
    .map((value, x) => ({ x, y: Math.round((value / 2.6) - 95 + item.preAttenuationDB) }))
}

// Returns chart point labels for the specified spectrum results item
function getLabels (item) {
  const data = [...range(0, item.spectrumPoints.length)]
  return data
}

// Returns chart scales for the specified spectrum results item
function getScales (item) {
  const x = {
    type: 'linear',
    display: false,
    min: 0,
    max: item.spectrumPoints.length
  }

  const y = {
    type: 'linear',
    display: true,
    min: -100,
    max: -30
  }

  return {
    x,
    y
  }
}

// Returns colored blocks covering areas of every operator for the specified spectrum results item
function getAnnotations (item, operators) {
  const annotations = {}

  const scannedOperators = item.operatorAllocation || []
  const range = (item.endKHz - item.startKHz) / 1000
  const points = getPoints(item)
  const count = points.length

  // Operator labels
  let x = 20
  scannedOperators.map((operator, index) => {
    const key = `operator-label-${index}`
    const operatorCount = (operator.allocationMhz / range) * count
    const xValue = x
    const yValue = -95
    const plmn = operator.plmn.toString()
    const content = (operators[plmn] || plmn).trim()
    const annotation = {
      type: 'label',
      xValue,
      yValue,
      content
    }
    annotations[key] = annotation
    x = x + operatorCount
  })

  // Divider lines
  x = 0
  scannedOperators.map((operator, index) => {
    const key = `operator-divider-${index}`
    const operatorCount = (operator.allocationMhz / range) * count
    const xMin = x + operatorCount + index - 1
    const xMax = xMin + 1
    const backgroundColor = '#e4e4e0'
    const annotation = {
      type: 'box',
      xMin,
      xMax,
      yMin: -90,
      yMax: -30,
      borderWidth: 0,
      backgroundColor
    }
    annotations[key] = annotation
    x = x + operatorCount
  })

  // Spectrum frequency range
  const frequencyRange = [
    item.startKHz / 1000,
    '-',
    item.endKHz / 1000,
    'MHz'
  ].join(' ')

  annotations['range'] = {
    type: 'label',
    xValue: 30,
    yValue: -35,
    content: frequencyRange
  }

  return annotations
}
