import { Permission, PermissionDeniedReason, PrincipalType, PrincipalTypeDescription } from '@stellacontrol/model'
import { BuiltInFeatures, isBuiltInFeature } from './built-in-features'

/**
 * Service for determining permissions available to a principal
 */
export class Guardian {
  /**
   * Initializes the guardian
   * @param {Feature} features Feature tree
   * @param {Principal} principal Principal in whose context the permissions will be resolved.
   * @param {Pricelist} pricelist Services pricelist, which determines whether services features require subscriptions or not
   * @param {Environment} environment Execution environment (DEV, PROD etc)
   * @description It is required that the principal is fully populated and it contains
   * details of its owner principals - organization, profile etc., all with
   * their respective permissions ready for inspection.
   */
  constructor ({ features, principal, pricelist, environment } = {}) {
    this.createdAt = new Date()
    this.features = features
    this.principal = principal
    this.pricelist = pricelist
    this.environment = environment
    if (this.features && this.principal) {
      this.initialize()
    }
  }

  /**
   * Creates the guardian using features list from another guardian.
   * @param {Guardian} source Source guardian
   * @param {Principal} principal Principal
   * @returns {Guardian}
   */
  static from (source, principal) {
    const { features, environment, pricelist } = source

    const guardian = new Guardian({
      features,
      principal,
      pricelist,
      environment
    })

    return guardian
  }

  /**
   * Initializes the guardian
   */
  initialize () {
    const { features, principal, pricelist, environment } = this

    if (!features) throw new Error('Features are required to initialize Guardian instance')
    if (!principal) throw new Error('Principal is required to initialize Guardian instance')
    if (!pricelist) throw new Error('Pricelist is required to initialize Guardian instance')
    if (!environment) throw new Error('Environment is required to initialize Guardian instance')

    if (!principal.permissions) principal.permissions = []
    this.name = `Guardian for [${principal.type}:${principal.name}]`.toUpperCase()

    // Create feature list and dictionary for lookups,
    // as trees are notoriously slow to traverse ;-)
    if (features) {
      this.featureList = features.flat()
      this.featureDictionary = this.featureList.reduce((all, feature) => ({ ...all, [feature.name]: feature }), {})
      this.premiumFeatures = (features.premiumFeatures || [])
        .map(name => this.featureDictionary[name])
        .filter(f => f)
      this.premiumFeatureDictionary = this.premiumFeatures.reduce((all, feature) => ({ ...all, [feature.name]: feature }), {})
    }

    // Create parent principal guardian,
    // used to filter out permissions that were
    // disabled in the parent principal, such as organization profile.
    if (principal.hasParentPrincipals) {
      this.parentGuardians = principal
        .parentPrincipals
        .map(p => Guardian.from(this, p))
    }

    // Checks the currently granted permissions
    // and marks the respective features as usable
    this.apply()
    if (principal.wallet) {
      this.setWallet(principal.wallet)
    }
  }

  /**
   * Creation time of the service
   * @type {Date}
   */
  createdAt

  /**
   * Age of the service, in seconds,
   * can be used for cache expiration
   * @type {Number}
   */
  get age () {
    if (this.createdAt) {
      return ((new Date()) - this.createdAt) / 1000
    }
  }

  /**
   * Unique name of the service
   * @type {String}
   */
  name

  /**
   * Parent guardians used to filter out permissions that were
   * disabled in the parent principal, such as organization profile.
   * @type {Array[Guardian]}
   */
  parentGuardians

  /**
   * Parent organization guardian
   * @type {Guardian}
   */
  get parentOrganizationGuardian () {
    const { parentGuardians, principal } = this

    let parentOrganizationGuardian

    if (parentGuardians) {
      // Parent organization of a user
      switch (principal.type) {
        case PrincipalType.User:
          if (principal.organizationId) {
            parentOrganizationGuardian = parentGuardians.find(g => g.principal.id === principal.organizationId)
          }
          break

        // Parent organization of an organization
        case PrincipalType.organization:
          if (principal.parentOrganizationId) {
            parentOrganizationGuardian = parentGuardians.find(g => g.principal.id === principal.parentOrganizationId)
          }
          break
      }
    }

    return parentOrganizationGuardian
  }

  /**
   * Parent organization profile guardian
   * @type {Guardian}
   */
  get parentProfileGuardian () {
    const { parentGuardians, principal } = this

    if (parentGuardians) {
      if (principal.type === PrincipalType.Organization) {
        if (principal.profileId) {
          return parentGuardians.find(g => g.principal.id === principal.profileId)
        }
      } else if (principal.type === PrincipalType.User) {
        return this.parentOrganizationGuardian?.parentProfileGuardian
      }
    }
  }

  /**
   * Current environment
   * @type {Environment}
   */
  environment

  /**
   * Principal in whose context the permissions will be resolved
   * @type {Principal}
   */
  principal

  /**
   * Services pricelist, which determines whether services features require subscriptions or not
   * @type {Pricelist}
   */
  pricelist

  /**
   * Feature tree
   * @type {Feature}
   */
  features

  /**
   * Feature list, for iterations
   * @type {Array[Feature]}
   */
  featureList

  /**
   * Feature dictionary, for lookups by name
   * @type {Dictionary<string, Feature>}
   */
  featureDictionary

  /**
   * List of premium features
   * @type {Array[Feature]}
   */
  premiumFeatures

  /**
   * Dictionary of premium features, for lookups by name
   * @type {Dictionary<string, Feature>}
   */
  premiumFeatureDictionary

  /**
   * Resolved permissions
   * @type {Array[Permission]}
   */
  permissions

  /**
   * Allowed permissions
   * @type {Array[Permission]}
   */
  get allowedPermissions () {
    return (this.permissions || []).filter(p => p.canUse && p.feature.isSecure && p.feature.name !== 'root')
  }

  /**
   * Denied permissions
   * @type {Array[Permission]}
   */
  get deniedPermissions () {
    return (this.permissions || []).filter(p => !p.canUse && p.feature.isSecure && p.feature.name !== 'root')
  }

  /**
   * Resolved permissions dictionary, for quicker lookups
   * @type {Dictionary<string, Permission>}
   */
  permissionDictionary

  /**
   * Assigns a wallet with premium features to the guardian.
   * If specified, features are checked not only against permissions,
   * but also whether they're listed as premium features
   * and whether the organization has an active subscription to use them
   * @type {Wallet}
   */
  wallet

  /**
   * Indicates that principal needs to have purchased premium
   * subscriptions, to be able to use certain application features
   * @type {Boolean}
   */
  get requiresPremiumSubscriptions () {
    return this.wallet &&
      this.isNotSuperOrganization &&
      this.isNotSuperAdministrator &&
      this.mustUse('premium-services-buy') &&
      this.cannotUse('premium-services-bank')
  }

  /**
   * Indicates that principal needs to have purchased premium
   * subscriptions for the specific type of feature.
   * We check here if the feature hasn't been excluded from
   * the requirement by the reseller.
   * @type {Boolean}
   */
  requiresPremiumSubscription (featureName) {
    if (this.requiresPremiumSubscriptions) {
      const context = this.getContext('premium-services-exceptions')
      return context ? !context.includes(featureName) : true
    }
  }

  /**
   * Assigns a wallet with premium features to the guardian.
   * @param {Wallet} wallet Organization's wallet with premium service subscriptions
   */
  setWallet (wallet) {
    this.principal.wallet = wallet
    this.wallet = wallet

    // Distribute the wallet of current user to parent organization's guardian
    if (this.principal.isUser && this.parentOrganizationGuardian) {
      this.parentOrganizationGuardian.setWallet(wallet)
    }

    this.apply()
  }

  /**
   * Checks whether given principal's level matches the level required by the feature
   * @param {Principal} principal Principal
   * @param {Dictionary<PrincipalType, OrganizationLevel>} featureLevels Feature levels per principal type
   * @returns {Boolean}
   */
  principalHasRequiredLevel (principal, featureLevels) {
    const { type: principalType, level: principalLevel } = principal
    const requiredLevel = featureLevels[principalType]
    return !requiredLevel || requiredLevel.includes(principalLevel)
  }

  /**
   * Determines and returns a permission associated with
   * the specified feature. The permission's `canUse` flag will
   * indicate whether the principal is allowed to use the feature.
   * @param {Feature} feature Feature to determine the permission for
   * @returns {Permission}
   * @description
   * To determine whether feature can be used, we only need to check
   * whether there exists a permission for this feature, for the principal
   * as well as all his parent principals. We don't do any other checks,
   * as all they were already done when permissions were edited.
   */
  determinePermission (feature) {
    // Firstly, feature must not be disabled globally
    const { principal, parentGuardians } = this
    let permission
    let checkParentGuardians

    if (feature.isEnabled) {
      // Super organization and its admins can do anything
      if (principal.isSuperOrganization || principal.isSuperAdministrator) {
        permission = new Permission({
          feature,
          canUse: true,
          implicit: true
        })

      } else {
        // Administrator of an organization has all the permissions of his organization
        if (principal.isAdministrator) {
          permission = new Permission({
            canUse: this.parentOrganizationGuardian.canUse(feature.name)
          })
          permission.reason = permission.canUse ? undefined : PermissionDeniedReason.ParentGuardianDenied
          permission.details = `Organization ${this.parentOrganizationGuardian.name}`

        } else {
          // If feature is secure or requires context, it has to be explicitly granted to the principal
          checkParentGuardians = true
          if (feature.isSecure) {
            permission = principal.getPermissionClone(feature.name)
            if (permission) {
              permission.reason = permission.canUse ? undefined : PermissionDeniedReason.FeatureNotAllowed
              permission.details = permission.canUse ? undefined : feature.description
            }

          } else {
            // If feature has context, maybe it has been explicitly edited for the principal?
            if (feature.context) {
              permission = principal.getPermissionClone(feature.name)
            }
            // Otherwise, if feature is not secure, all its parent features
            // must be either granted or not secure
            if (!permission) {
              if (feature.parent) {
                const parentPermission = this.determinePermission(feature.parent)
                permission = new Permission({ feature, canUse: parentPermission.canUse })
                permission.reason = permission.canUse ? undefined : PermissionDeniedReason.ParentPermissionDenied
                permission.details = permission.canUse ? undefined : feature.parent.description
              } else {
                permission = new Permission({ feature, canUse: true })
              }
            }
          }
        }
      }
    }

    // Even if permission is granted, it may be not enough,
    // if principal has a parent. In such case we also need
    // to check whether the parent principal can use this feature.
    if (permission) {
      if (permission.canUse && checkParentGuardians && parentGuardians) {
        // Check whether the specified feature is permitted in the parent principals.
        // If subject specified on the feature, skip this check for parent guardians whose
        // principal type does not match the subject
        const guardiansToCheck = parentGuardians.filter(g =>
          feature.subject
            ? this.principalHasRequiredType(g.principal, feature.subject)
            : true)
        if (guardiansToCheck.length === 0) {
          permission.canUse = true
        } else {
          const deniedFor = guardiansToCheck.find(g => !g.canUse(feature.name))
          permission.canUse = !deniedFor
          permission.reason = permission.canUse ? undefined : PermissionDeniedReason.ParentGuardianDenied
          permission.details = permission.canUse ? undefined : `${PrincipalTypeDescription[deniedFor.type]} ${deniedFor.name}`
        }
      }

    } else {
      permission = new Permission({ canUse: false })
      permission.reason = permission.canUse ? undefined : PermissionDeniedReason.FeatureDisabled
      permission.details = feature.description
    }

    // If feature is not available at the current environment,
    // deny the permission
    permission.isApplicableInEnvironment = true
    if (permission.canUse && feature.environment && this.environment) {
      const allowedEnvironments = Array.isArray(feature.environment) ? feature.environment : [feature.environment]
      permission.isApplicableInEnvironment = allowedEnvironments.some(e => e === this.environment)
      permission.canUse = permission.isApplicableInEnvironment
    }

    // If feature is allowed, and customer is using premium features,
    // check whether this isn't a premium feature.
    // If so, the customer must have a active subscription to use it!
    // Ignore here per-device premium features, as these are checked separately.
    if (permission.canUse &&
      principal.isOrganization &&
      this.isPremiumFeature(feature.name) &&
      !this.isPerDevicePremiumFeature(feature.name)) {
      permission.canUse = this.isSubscribedTo(feature.name)
      permission.reason = permission.canUse ? undefined : PermissionDeniedReason.PremiumSubscriptionRequired
      permission.details = permission.canUse ? undefined : feature.description
    }

    // Make sure the permission is tagged with
    // the queried feature, and not its parent!
    permission.feature = feature

    return permission
  }

  /**
   * Create a complete list of permissions for the principal,
   * taking into consideration the parent principals and their permissions.
   */
  apply () {
    const { featureList, parentGuardians } = this

    if (parentGuardians) {
      parentGuardians.every(g => g.apply())
    }

    this.permissions = [
      ...this.getLevelPermissions(),
      ...featureList.map(feature => this.determinePermission(feature))
    ]

    this.permissionDictionary = this.permissions
      .reduce((all, permission) => ({ ...all, [permission.feature.name]: permission }), {})

    this.addComputedPermissions(this.features.computedPermissions)
  }

  /**
   * Returns a hard-coded list of permissions representing
   * user and organization level of the current principal.
   * Applies only to user and organization principals.
   * @type {Array[Permission]}
   */
  getLevelPermissions () {
    const { principal } = this
    const isSecure = true
    let permissions = []

    if (principal.type === PrincipalType.User) {
      const { isSuperAdministrator, isResellerAdministrator, isAdministrator, isRegularUser, isGuestUser } = principal
      permissions = [
        ...permissions,
        new Permission({ feature: { name: BuiltInFeatures.IsSuperAdministrator, isSecure }, canUse: isSuperAdministrator }),
        new Permission({ feature: { name: BuiltInFeatures.IsResellerAdministrator, isSecure }, canUse: isResellerAdministrator }),
        new Permission({ feature: { name: BuiltInFeatures.IsAdministrator, isSecure }, canUse: isAdministrator }),
        new Permission({ feature: { name: BuiltInFeatures.IsGuestUser, isSecure }, canUse: isGuestUser }),
        new Permission({ feature: { name: BuiltInFeatures.IsRegularUser, isSecure }, canUse: isRegularUser })
      ]
    }

    if (principal.type === PrincipalType.Organization || principal.type === PrincipalType.User) {
      const organization = principal.type === PrincipalType.Organization ? principal : principal.organization
      const { isSuperOrganization, isResellerOrganization, isRegularOrganization, isGuestOrganization, isShippingCompany } = organization || {}
      permissions = [
        ...permissions,
        new Permission({ feature: { name: BuiltInFeatures.IsSuperOrganization, isSecure }, canUse: isSuperOrganization }),
        new Permission({ feature: { name: BuiltInFeatures.IsResellerOrganization, isSecure }, canUse: isResellerOrganization }),
        new Permission({ feature: { name: BuiltInFeatures.IsRegularOrganization, isSecure }, canUse: isRegularOrganization }),
        new Permission({ feature: { name: BuiltInFeatures.IsGuestOrganization, isSecure }, canUse: isGuestOrganization }),
        new Permission({ feature: { name: BuiltInFeatures.IsShippingCompany, isSecure }, canUse: isShippingCompany })
      ]
    }
    return permissions
  }

  /**
   * Adds computed permissions, as specified in
   * features.json / permissions node.
   * @param {Dictionary<string, Permission>} items Dictionary of computed permissions
   * @description Computed permissions are declared as sets of other permissions.
   * If all of the listed permissions are granted, the computed permissions is regarded as granted.
   * These permissions are obviously not available for editing in the permissions editor.
   * They're here to capture certain complex permission sets, without hard-coding them in the code.
   */
  addComputedPermissions (items = {}) {
    for (const group of Object.values(items)) {
      const items = Object
        .entries(group)
        .filter(item => typeof item[1] === 'object')

      for (const [name, { enabled, permissions = [], environment = [] }] of items) {
        if (this.permissionExists(name)) {
          throw new Error(`Cannot evaluate a computed permission [${name}], such permission already exists`)
        }
        const isSecure = permissions.length > 0 || enabled != null
        const isApplicableInEnvironment = environment.length === 0 || environment.includes(this.environment)
        const canUse = isSecure
          ? enabled !== false && isApplicableInEnvironment && this.canUseAll(permissions)
          : true

        const permission = new Permission({ feature: { name, isSecure, isEnabled: enabled !== false }, canUse, isApplicableInEnvironment })
        this.permissions.push(permission)
        this.permissionDictionary[name] = permission
      }
    }
  }

  /**
   * Indicates that principal is an administrator user
   * @type {Boolean}
   */
  get isAdministrator () {
    const { principal } = this
    return principal && principal.type === PrincipalType.User && principal.isAdministrator
  }

  /**
   * Indicates that principal is a super administrator user
   * @type {Boolean}
   */
  get isSuperAdministrator () {
    const { principal } = this
    return principal && principal.type === PrincipalType.User && principal.isSuperAdministrator
  }

  /**
   * Indicates that principal is a super integrator user
   * @type {Boolean}
   */
  get isSuperIntegrator () {
    const { principal } = this
    return principal && principal.type === PrincipalType.User && principal.isSuperIntegrator
  }

  /**
   * Indicates that principal is a super organization
   * @type {Boolean}
   */
  get isSuperOrganization () {
    const { principal } = this
    return principal && principal.type === PrincipalType.Organization && principal.isSuperOrganization
  }

  /**
   * Indicates that principal is not an administrator user
   * @type {Boolean}
   */
  get isNotAdministrator () {
    return !this.isAdministrator
  }

  /**
   * Indicates that principal is not a super administrator user
   * @type {Boolean}
   */
  get isNotSuperAdministrator () {
    return !this.isSuperAdministrator
  }

  /**
   * Indicates that principal is a super organization
   * @type {Boolean}
   */
  get isNotSuperOrganization () {
    return !this.isSuperOrganization
  }

  /**
   * Returns a permission with the specified name.
   * @param {String} name Feature name
   * @type {Permission}
   */
  getPermission (name) {
    const permission = this.permissionDictionary[name]
    if (permission) {
      return permission
    } else {
      throw new Error(`Unknown feature ${name}`)
    }
  }

  /**
   * Returns true if permission with the specified name exists.
   * @param {String} name Feature name
   * @type {Boolean}
   */
  permissionExists (name) {
    return Boolean(this.permissionDictionary[name])
  }

  /**
   * Returns true if principal is allowed to use the specified feature
   * @param {String} name Feature name
   * @param {Boolean} force If true, permission must be granted explicitly.
   * Normally we skip checks for super organization, but for some permissions which
   * are more like facts than permissions, we might require an explicit check.
   * @returns {Boolean} True if principal is allowed to use the feature
   * @description The check can be negated by prefixing feature name with `no ` prefix.
   * This can be used when permissions are specified as text and checked somewhere
   * programmatically by `canUse` calls.
   * @description Normally we only verify principal permission.
   * If principal is using premium features and checked feature is a premium feature,
   * we also require that there is an active subscription to this premium feature.
   *
   * You can specify MUST operator before feature name,
   * which has the same effect as `force` parameter. If present,
   * permission must be granted explicitly, even for super organization.
   */
  canUse (name, force) {
    if (name) {
      const { principal, featureDictionary } = this
      name = name.toString().trim()
      const negate = name.toLowerCase().startsWith('not ')
      if (negate) {
        name = name.substring(4).trim()
      }
      force = force || name.toLowerCase().startsWith('must ')
      if (force) {
        name = name.substring(5).trim()
      }

      // Get the required permission
      const permission = this.permissionExists(name) ? this.getPermission(name) : null

      // Don't allow if feature marked as disabled
      if (permission?.feature?.isEnabled === false) {
        return false
      }

      // Don't allow if invalid environment (applies even to super user)
      if (permission?.isApplicableInEnvironment === false) {
        return false
      }

      // Skip all other checks if super user
      if (!force && (principal.isSuperOrganization || principal.isSuperAdministrator || principal.isSuperIntegrator)) {
        return negate ? false : true
      }

      // Feature must be allowed at principal's level
      const feature = featureDictionary[name]
      if (feature?.level) {
        const hasRequiredLevel = this.principalHasRequiredLevel(principal, feature.level)
        if (!hasRequiredLevel) {
          return negate ? true : false
        }
      }

      // Return permission status, negated if required
      if (permission) {
        const { canUse } = permission
        return Boolean(negate ? !canUse : canUse)
      }

      // Otherwise deny the feature if built-in, or throw because there's no such feature at all
      if (isBuiltInFeature(name)) {
        return false
      } else {
        throw new Error(`Cannot determine permission [${name}], the corresponding feature is not found`)
      }
    }
  }

  /**
   * Returns true if principal is allowed to use all the specified features
   * @param {Array[String]} names Feature names
   * @param {Boolean} force If true, permission must be granted explicitly
   * @returns {Boolean} True if principal is allowed to use the features
   * @description Operator prefixes are allowed before permission names,
   * which can change the default working of this function:
   *
   * NOT - negates the permission. Returns true if permission is NOT explicitly granted
   * OR  - turns `canUseAll` to `canUseAny`
   * MUST  - Same as {@link force} parameter
   */
  canUseAll (names, force) {
    if (names && names.length > 0) {
      if (!Array.isArray(names)) throw new Error('Names should be an array')
      if (names.some(name => name.startsWith('OR '))) {
        return this.canUseAny(names.map(name => name.replace('OR ', '')), force)
      } else {
        return names.every(name => this.canUse(name, force))
      }
    } else {
      return true
    }
  }

  /**
   * Returns true if principal is allowed to use any of the specified features
   * @param {Array[String]} names Feature names
   * @param {Boolean} force If true, permission must be granted explicitly
   * @returns {Boolean} True if principal is allowed to some of the features
   */
  canUseAny (names, force) {
    if (names && names.length > 0) {
      if (!Array.isArray(names)) throw new Error('Names should be an array')
      const allowed = names.filter(name => this.canUse(name, force))
      return allowed.length === 0 ? false : allowed
    } else {
      return true
    }
  }

  /**
   * Checks if principal is allowed to use any of the children
   * of the specified feature.
   * @param {String} name Feature name
   * @param {Boolean} force If true, permission must be granted explicitly
   * @returns {Boolean} true if any of the child features is permitted
   */
  canUseChildrenOf (name, force) {
    if (this.canUse(name)) {
      const { feature } = this.getPermission(name)
      const features = (feature.features || []).map(p => p.name)
      return this.canUseAny(features, force)
    }
  }

  /**
   * Returns true if principal is not allowed to use the specified feature
   * @param {String} name Feature name
   * @param {Boolean} force If true, permission must be granted explicitly
   * @returns {Boolean} True if principal is not allowed to use the feature
   */
  cannotUse (name, force) {
    return !this.canUse(name, force)
  }

  /**
   * Returns true if principal is not allowed to use any of the specified features
   * @param {Array[String]} names Feature names
   * @param {Boolean} force If true, permission must be granted explicitly
   * @returns {Boolean} True if principal is not allowed to use all the features
   */
  cannotUseAll (names, force) {
    return !this.canUseAll(names, force)
  }

  /**
   * Returns true if principal is not allowed to use some of the specified features
   * @param {Array[String]} names Feature names
   * @param {Boolean} force If true, permission must be granted explicitly
   * @returns {Boolean} True if principal is not allowed to use any of the features
   */
  cannotUseAny (names, force) {
    return !this.canUseAny(names, force)
  }

  /**
   * Returns true if principal's device is allowed to use the specified feature
   * @param {String} name Feature name
   * @param {String} serialNumber Device serial number
   * @param {Guardian} viewer Guardian of the viewer which acts on behalf of the current principal.
   * Will be specified for example when reseller views his customer's devices.
   * @returns {Boolean}
   * @description Normally we only verify principal permission.
   * If principal is using premium features and checked feature is a premium feature,
   * we also require that there is an active subscription to this premium feature,
   * optionally associated with this device.
   */
  canDeviceUse (name, serialNumber, viewer) {
    // When administrator is viewing, check his organization's permission
    // as he doesn't have such permission granted himself
    if (this.isAdministrator && this.parentOrganizationGuardian) {
      return this.parentOrganizationGuardian.canDeviceUse(name, serialNumber, viewer)
    }

    if (!(this.canUse(name) || viewer?.canUse(name))) {
      return false
    }

    if (!this.isPremiumFeature(name)) {
      return true
    }

    if (this.isPremiumFeatureExempt(name) || viewer?.isPremiumFeatureExempt(name)) {
      return true
    }

    return this.isSubscribedTo(name, serialNumber)
  }

  /**
   * Returns true if principal's device is not allowed to use the specified feature
   * @param {Array[String]} names Feature names
   * @param {String} serialNumber Device serial number
   * @returns {Boolean}
   */
  canDeviceUseAll (names = [], serialNumber) {
    return names.every(name => this.canDeviceUse(name, serialNumber))
  }

  /**
   * Returns true if principal's device is not allowed to use the specified feature
   * @param {String} name Feature name
   * @param {String} serialNumber Device serial number
   * @returns {Boolean}
   */
  cannotDeviceUse (name, serialNumber) {
    return !this.canDeviceUse(name, serialNumber)
  }

  /**
   * Returns true if principal is required to use the specified 'requirement-like' feature.
   * @param {String} name Requirement feature name
   * @returns {Boolean}
   */
  mustUse (name) {
    if (name) {
      const permission = this.permissionDictionary[name]
      if (permission && permission.feature.isRequirement && !permission.implicit && permission.canUse) {
        return true
      }
    }
    return false
  }

  /**
   * Returns context of a granted permission
   * @param {String} name Feature name
   * @returns {Object}
   */
  getContext (name) {
    const { feature, canUse, context } = this.getPermission(name)
    return (canUse || !feature.isSecure) ? context : undefined
  }

  /**
   * Checks whether the specified principal is of the required type
   * @param {Principal} principal Principal to check
   * @param {Array[String]} subjects Feature subjects
   * @returns {Boolean}
   */
  principalHasRequiredType (principal, subjects) {
    return subjects?.includes(principal.type)
  }

  /**
   * Checks if specified feature is a premium feature
   * which requires active subcription
   * @param {String} name Feature name
   * @description Feature must be listed as premium in features dictionary,
   * and also included in the organization's pricelist as part of one of premium services.
   * @returns {Boolean}
   */
  isPremiumFeature (name) {
    const { requiresPremiumSubscriptions, premiumFeatureDictionary, pricelist } = this
    if (requiresPremiumSubscriptions && pricelist) {
      return Boolean(premiumFeatureDictionary[name]) && pricelist.hasFeature(name)
    } else {
      return false
    }
  }

  /**
   * Checks if specified premium feature is listed as exception,
   * so the principal is not required to have active subcription to use it.
   * @param {String} name Feature name
   * @returns {Boolean}
   */
  isPremiumFeatureExempt (name) {
    if (this.isSuperOrganization) return true
    if (!this.requiresPremiumSubscriptions) return true
    const items = this.getContext('premium-services-exceptions') || []
    return items.includes(name)
  }

  /**
   * Checks if specified feature is a premium feature which is applied to individual devices
   * @param {String} name Feature name
   * @returns {Boolean}
   */
  isPerDevicePremiumFeature (name) {
    const { requiresPremiumSubscriptions, premiumFeatureDictionary, pricelist } = this
    if (requiresPremiumSubscriptions && pricelist) {
      return Boolean(premiumFeatureDictionary[name]) && pricelist.hasPerDeviceFeature(name)
    } else {
      return false
    }
  }

  /**
   * Returns all premium services available to the principal
   * @returns {Array[PremiumService]}
   */
  getPremiumServices () {
    const { pricelist } = this
    if (pricelist) {
      return pricelist.services || []
    } else {
      return []
    }
  }

  /**
   * Checks if principal has active subscription
   * for the specified premium feature in his wallet.
   * @param {String} name Feature name
   * @param {String} serialNumber Optional device serial number. If subscription is for per-device service,
   * the device must be listed in the list of subscription's devices.
   * @returns {Boolean}
   */
  isSubscribedTo (name, serialNumber) {
    const { wallet: { subscriptions = [] } = {} } = this
    return subscriptions.some(s => s.isSubscribedTo(name, serialNumber) && s.isActive)
  }

  /**
   * Returns active subscriptions of the principal
   * @returns {Array[PremiumServiceSubscription]}
   */
  getSubscriptions () {
    const { wallet: { subscriptions = [] } = {} } = this
    return subscriptions
  }

  /**
   * Returns active subscriptions of the principal,
   * associated with the specified device
   * @param {String} serialNumber Device serial number
   * @returns {Array[PremiumServiceSubscription]}
   */
  getDeviceSubscriptions ({ serialNumber }) {
    const { wallet: { subscriptions = [] } = {} } = this
    return subscriptions.filter(s => s.forDevice({ serialNumber }))
  }

  /**
   * Returns alert types permitted for the current principal
   * @returns {Array[AlertType]}
   */
  get permittedAlertTypes () {
    const alertTypes = (this.permissions || [])
      .filter(p => p.featureName.startsWith('alert-type-'))
      .filter(p => this.canUse(p.featureName))
      .map(p => p.featureName.substr(11))

    return alertTypes
  }

  /**
   * Returns true if alert monitoring is enabled for the principal
   * @type {Boolean}
   */
  get hasAnyAlertsPermitted () {
    return this.permittedAlertTypes.length > 0
  }

  /**
   * Checks whether the specified alert type is permitted for the principal
   * @param {AlertType} alertType Alert type to check
   * @returns {Boolean}
   */
  isAlertPermitted (alertType) {
    return this.permittedAlertTypes.includes(alertType)
  }

  /**
   * Checks whether configuration for the specified alert type can be edited by the principal.
   * Such alert types are defined under `edit-alert-type-too-many-messages` permission.
   * @param {AlertType} alertType Alert type to check
   * @returns {Boolean}
   */
  canEditAlert (alertType) {
    if (this.isAlertPermitted(alertType)) {
      const editPermission = `edit-alert-type-${alertType}`
      if (this.permissionExists(editPermission)) {
        return this.canUse(editPermission)
      } else {
        return true
      }
    }
  }

  /**
   * Checks whether organization can receive the specified alert.
   * Some alert types, although monitored for an organization, should not be announced to them.
   * Such alert types are defined under `receive-alert-type-too-many-messages` permission.
   * @param {AlertType} alertType Alert type to check
   * @returns {Boolean}
   */
  canReceiveAlert (alertType) {
    if (this.isAlertPermitted(alertType)) {
      const editPermission = `receive-alert-type-${alertType}`
      if (this.permissionExists(editPermission)) {
        return this.canUse(editPermission)
      } else {
        return true
      }
    }
  }

  /**
   * Indicates that organization is a premium customer,
   * obliged to purchase subscriptions to use premium features
   * @type {Boolean}
   */
  get isPremiumCustomer () {
    return this.mustUse('premium-services-buy')
  }

  /**
   * Indicates that organization is a premium reseller,
   * able to sell subscriptions to his customers
   * @type {Boolean}
   */
  get isPremiumReseller () {
    return this.canUse('premium-services-sell')
  }

  /**
   * Indicates that organization is a token bank
   * @type {Boolean}
   */
  get isBank () {
    return this.isSuperAdministrator || this.canUse('premium-services-bank')
  }
}
