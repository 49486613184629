import { customAlphabet } from 'nanoid'

/**
 * Define characters allowed in unique identifiers
 */
const nanoid = customAlphabet('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz', 21)
const nanoidShort = customAlphabet('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz', 12)

/**
 * Creates a long 21-characters unique identifier
 * @param {String} prefix Optional prefix for the identifier
 * @returns {String} Unique identifier
 */
export function getId (prefix = '') {
  return `${prefix ? prefix + '-' : ''}${nanoid()}`
}

/**
 * Creates a short 12-characters unique identifier
 * @param {String} prefix Optional prefix for the identifier
 * @returns {String} Unique identifier
 */
export function getShortId (prefix = '') {
  return `${prefix ? prefix + '-' : ''}${nanoidShort()}`
}
