// Default styles for input controls in the palette
export default {
  color: {
    width: '98px',
    height: '40px'
  },
  number: {
    'width': '73px',
    'max-width': '73px'
  },
  select: {
    small: {
      'width': '97px',
      'max-width': '97px'
    },
    medium: {
      'width': '198px',
      'max-width': '198px'
    },
    normal: {
      'width': '100%'
    }
  },
  text: {
    'width': '100%',
    small: {
      'width': '75px',
      'max-width': '75px'
    },
    medium: {
      'width': '134px',
      'max-width': '134px'
    },
  },
  label: {
    'width': '73px'
  }
}
