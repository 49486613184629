/**
 * Typical device port counts
 */
export const DevicePortCount = {
  // One port
  One: 1,
  // Two ports
  Two: 1,
  // Four ports
  Four: 4,
  // Eight ports
  Eight: 8
}
