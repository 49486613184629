import { getMegaParameterIfApplicable } from './get-mega-parameter'

/**
 * Returns a value of MEGA parameter with a specified name
 * on a specified device
 * @param {String} name Name of the parameter
 * @param {Object} mega Recent MEGA
 * @param {Device} device Device in whose context the parameter is retrieved
 * @param {DeviceStatus} status Last known status of the device.
 * Some rules take into consideration the last reported status of the device
 * @param {String} defaultLabel Default label to return if value not present
 * @returns {Object} A dictionary containing value, label and index of the value in the list of allowed values
 */
export function getMegaValue (name, mega, device, status, defaultLabel = '') {
  const parameter = getMegaParameterIfApplicable(name, device, status)
  if (parameter) {
    const { unit = '', format, labels = [], values = [] } = parameter
    const value = (mega || {})[name]
    const index = values.findIndex(v => v == value)
    const label = labels[index] || (value == null ? defaultLabel : `${value.toString()} ${unit}`)
    return {
      value,
      label,
      index,
      unit,
      format
    }
  } else {
    return {
      label: defaultLabel
    }
  }
}

/**
 * Returns a value of MEGA band parameter with a specified name
 * on a specified device
 * @param {String} name Name of the parameter
 * @param {String} band Band identifier
 * @param {Object} mega Recent MEGA
 * @param {Device} device Device in whose context the parameter is retrieved
 * @param {DeviceStatus} status Last known status of the device.
 * Some rules take into consideration the last reported status of the device
 * @param {String} defaultLabel Default label to return if value not present
 * @returns {Object} A dictionary containing value, label and index of the value in the list of allowed values
 */
export function getMegaBandValue (name, band, mega, device, status, defaultLabel = '') {
  const parameter = getMegaParameterIfApplicable(name, device, status)
  if (parameter) {
    const { unit = '', format, labels = [], values = [] } = parameter
    const bandParameterName = `${name}_${band}`
    const value = mega[bandParameterName]
    const index = values.findIndex(v => v == value)
    const label = labels[index] || (value == null ? defaultLabel : `${value.toString()} ${unit}`)
    return {
      value,
      label,
      index,
      unit,
      format
    }
  } else {
    return {
      label: defaultLabel
    }
  }
}
