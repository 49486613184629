<script>
import AlertConfigurationComponent from './alert-configuration-component'
import { AlertType } from '@stellacontrol/model'

export default {
  mixins: [
    AlertConfigurationComponent
  ],

  data () {
    return {
      AlertType
    }
  }
}
</script>

<template>
  <article>
    <section class="dense">
      <div class="column q-mt-sm">
        <div class="title text-body2 text-weight-regular text-orange-7">
          The alert is not supported on the selected {{ isBatch ? 'devices' : 'device' }}.
        </div>
        <div class="subtitle text-body2 text-weight-regular text-orange-7">
          <template v-if="alertType === AlertType.ContinuousUplink && device.type === 'repeater'">
            Upgrade the device firmware to enable the alert.
          </template>
        </div>
      </div>
    </section>
  </article>
</template>

<style scoped lang="scss">
</style>
