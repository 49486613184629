import { stringCompare } from '@stellacontrol/utilities'
import { getDeviceLabel } from './get-devices-description'

/**
 * Sort order of devices
 */
export const DeviceSortOrder = {
  // Order by serial number
  SerialNumber: 'serial-number',
  // Order by serial number prefixed with device type
  QualifiedSerialNumber: 'qualified-serial-number'
}

/**
 * Sorts the specified devices
 * @param devices Places to sort
 * @param order Sorting order
 */
export function sortDevices (devices = [], order = DeviceSortOrder.SerialNumber) {
  const compare = {
    // Sorting by serial number
    [DeviceSortOrder.SerialNumber]: (a, b) =>
      stringCompare(a.serialNumber, b.serialNumber),
    // Sorting by qualified serial number
    [DeviceSortOrder.QualifiedSerialNumber]: (a, b) =>
      stringCompare(getDeviceLabel(a), getDeviceLabel(b))
  }[order]

  if (!compare) {
    throw new Error(`Unknown place sort order ${order}`)
  }

  const result = [...devices]
  result.sort((a, b) => compare(a, b))
  return result
}
