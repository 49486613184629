import { merge } from '@stellacontrol/utilities'
import { PlanItem, PlanItemType } from './plan-item'
import { PlanLineStyle } from '../styles'

/**
 * Rectangle
 */
export class PlanRectangle extends PlanItem {
  constructor (data = {}) {
    super(data)
    this.assign(data)
  }

  /**
   * Creates a new rectangle line
   * @param {Object} data Initial data
   * @returns {PlanRectangle}
   */
  static create (data = {}) {
    return new PlanRectangle({
      ...data,
      lineStyle: merge({ width: 2 }, data.lineStyle),
      backgroundStyle: merge({ color: 'blue', opacity: 0.5 }, data.backgroundStyle),
    })
  }

  /**
   * Item type
   * @type {PlanItemType}
   */
  static get type () {
    return PlanItemType.Rectangle
  }

  /**
   * Item defaults
   */
  get defaults () {
    return {
      ...super.defaults,
      lineStyle: PlanLineStyle.Default,
      width: 200,
      height: 100
    }
  }

  get persistentProperties () {
    return ['width', 'height']
  }
}
