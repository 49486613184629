<script>
import { mapGetters, mapActions } from 'vuex'
import { Secure } from '@stellacontrol/security-ui'
import MenuItem from './application-menu-item.vue'

export default {
  name: 'ApplicationMenu',

  mixins: [
    Secure
  ],

  props: {
    // Indicates whether navigation links etc.
    // are enabled
    enabled: {
      type: Boolean,
      default: true
    }
  },

  components: {
    'sc-application-menu-item': MenuItem
  },

  computed: {
    ...mapGetters([
      'menuItemTitle',
      'menu',
      'isSmallScreen'
    ]),

    logoutItem () {
      return {
        name: 'logout',
        title: 'Log out',
        icon: 'power_settings_new'
      }
    },

    // Visible menu items
    items () {
      return this.menu.filter(item => {
        // Skip items not intended for the current screen size
        return !(this.isSmallScreen && item.desktopOnly)
      })
    }
  },

  methods: {
    ...mapActions([
      'endSession'
    ]),

    itemSelected (item) {
      this.$emit('selected', item)
    },

    async logout () {
      await this.endSession()
    }
  }
}
</script>

<template>
  <ul class="menu q-pl-xs" v-if="menu.length > 0">

    <li v-for="item in items" :key="item.id">
      <sc-application-menu-item :item="item" :enabled="enabled" @selected="itemSelected(item)" />

      <div class="submenu" :class="{ collapsed: item.isCollapsed }" v-if="item.items">
        <ul class="menu">
          <li v-for="subitem in item.items" :key="subitem.id" class="subitem">
            <sc-application-menu-item :item="subitem" :enabled="enabled"
              @selected="itemSelected(subitem)">
            </sc-application-menu-item>
          </li>
        </ul>
      </div>

    </li>

    <!-- Hidden, AVATAR icon is now used as logout button -->
    <li class="logout" v-if="false">
      <sc-application-menu-item :item="logoutItem" @selected="logout()">
      </sc-application-menu-item>
    </li>
  </ul>
</template>

<style lang="scss" scoped>
ul.menu {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    border-left: solid transparent 2px;

    &:first-child {
      padding-top: 0;
    }

    &.subitem {
      padding-left: 20px;
    }

    &.logout {
      border-top: solid #263472 1px;
      margin-top: 8px;
      padding-top: 8px;
    }

    .submenu {
      max-height: 500px;
      overflow: hidden;
      transition: all 0.15s ease-in-out;
    }

    .submenu.collapsed {
      max-height: 0;
    }
  }
}

/* Layout adjustments for screen below HD resolution */
@media screen and (max-width: 1365px) {
  ul.menu {

    li {
      padding-right: 24px;
    }
  }
}
</style>
