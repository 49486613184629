import { createState } from './activity.state'

export const mutations = {
  /**
   * Registers user activity
   * @param {AuditItem} action User action to register
   */
  storeUserActivity (state, { action } = {}) {
    if (action) {
      state.items.push(action)
    }
  },

  /**
   * Clears user activity
   */
  clearUserActivity (state) {
    state.items = []
  },

  /**
   * Resets the state to original shape.
   * @description
   * Presence of this mutation is obligatory on stores,
   * to ensure purging of any user-bound data when
   * user logs in and out and in. Otherwise expect
   * unexpected side effects with data of the previously
   * logged in user appearing at places.
   */
  reset (state) {
    Object.assign(state, createState())
  }
}
