<script>
import { parseIntList } from '@stellacontrol/utilities'
import { AttenuationGroups, AttenuationGroupDescription, AttenuationGroupDetails, AttenuationGroupIndex } from '@stellacontrol/model'
import ParameterEditor from './parameter-editor'

export default {
  mixins: [
    ParameterEditor
  ],

  props: {
    // All bands available in the group
    bands: {
      required: true
    },
    // Edited band group
    group: {
      required: true
    }
  },

  data () {
    return {
      // Colors representing attenuation values
      colors: [
        'grey-2',
        'green-3',
        'green-4',
        'green-5',
        'green-6',
        'green-7',
        'green-7',
        'green-8',
        'green-8',
        'green-9',
        'green-9'
      ]
    }
  },

  computed: {
    // Details of the edited attenuation group
    attenuationGroup () {
      const { group } = this
      return {
        group,
        description: AttenuationGroupDescription[group],
        details: AttenuationGroupDetails[group],
        index: AttenuationGroupIndex[group]
      }
    },

    // Details of the group
    groupDetails () {
      return this.bands.map(band => band.label).join(', ')
    },

    reportedValue () {
      const { attenuationGroup, reported } = this
      const values = reported ? parseIntList(reported, 0) : []
      return values[attenuationGroup.index] || 0
    },

    customValue () {
      const { attenuationGroup, custom } = this
      const values = custom ? parseIntList(custom, 0) : []
      return values[attenuationGroup.index]
    },

    // Items for value selector
    items () {
      const { values, colors } = this
      return values.map((value, index) => ({
        label: `${value} dB`,
        value: value,
        icon: 'signal_cellular_alt',
        color: colors[index]
      }))
    }
  },

  methods: {
    setGroupAttenuation (value) {
      const { reported, custom, group } = this
      const currentValues = parseIntList(custom || reported, 0) || []
      const newValues = AttenuationGroups.map(g => group === g
        ? value || 0
        : currentValues[AttenuationGroupIndex[g]] || 0)
      this.change(newValues.join(','))
    }
  }
}
</script>

<template>
  <div class="parameter-editor" v-if="hasValue">

    <sc-parameter-value
      :label="`Attenuation of ${attenuationGroup.description} (${groupDetails})`"
      :reported="reportedValue"
      :custom="customValue"
      :unit="unit">
    </sc-parameter-value>

    <sc-parameter-selector
      :items="items"
      :label="`Attenuation of ${attenuationGroup.description} (${groupDetails})`"
      :allow-clear="false"
      @select="value => setGroupAttenuation(value)">
    </sc-parameter-selector>

  </div>
</template>
