import { Point } from '@stellacontrol/utilities'
import { PlanItemType } from './plan-item'
import { PlanLine } from './plan-line'
import { PlanLineStyle, PlanLineType, PlanArrowStyle } from '../styles'

/**
 * Ruler showing distance between points
 */
export class PlanRuler extends PlanLine {
  constructor (data = {}) {
    super(data)
    this.assign(data)
  }

  /**
   * Item type
   * @type {PlanItemType}
   */
  static get type () {
    return PlanItemType.Ruler
  }

  /**
   * Creates a new generic ruler
   * @param {Object} data Initial data
   * @returns {PlanRuler}
   */
  static create (data = {}) {
    const points = [
      new Point({ x: 0, y: 0 }),
      new Point({ x: 0, y: 0 })
    ]
    if (data.x && data.y) {
      points.every(p => p.moveBy(data))
    }
    return new PlanRuler({
      ...data,
      points
    })
  }

  /**
   * Item defaults
   */
  get defaults () {
    return {
      ...super.defaults,
      // Line style
      lineStyle: new PlanLineStyle({
        width: 2,
        color: 'red',
        type: PlanLineType.Dashed
      }),
      // Arrow style
      arrowStyle: new PlanArrowStyle({
        width: 6,
        height: 6,
        start: true,
        end: true
      }),
      alignThreshold: 0,
      maxPointCount: 2
    }
  }

  normalize () {
    super.normalize()
  }

  /**
   * Serializes the plan item to JSON
   * @returns {Object}
   */
  toJSON () {
    const result = super.toJSON()

    // Delete fixed data
    delete result.lineStyle
    delete result.arrowStyle
    delete result.alignThreshold
    delete result.maxPointCount

    return result
  }

  /**
   * Checks whether the ruler has both points
   * @type {Boolean}
   */
  get hasAllPoints () {
    const [start, end] = this.points
    return start && end && !start.sameAs(end)
  }

}
