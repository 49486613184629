<script>
import { FormValidationRules as rules } from '@stellacontrol/client-utilities'
import { DeviceBandDetails, DeviceBandIdentifier, DeviceBandIdentifiers, getDeviceFamily, getBandLabel } from '@stellacontrol/model'
import AlertConfigurationComponent from './alert-configuration-component'

export default {
  mixins: [
    AlertConfigurationComponent
  ],

  data () {
    return {
      rules,
      DeviceBandIdentifier
    }
  },

  computed: {
    bands () {
      const family = getDeviceFamily(this.device)
      return DeviceBandIdentifiers
        .map(identifier => DeviceBandDetails[identifier])
        .map(band => ({
          identifier: band.id,
          label: getBandLabel(band.id, family)
        }))
    },

    mean_dw () {
      return this.configuration.parameters.mean_dw
    },

    bandIsModified () {
      return identifier => this.isModified(
        configuration => configuration.parameters.mean_dw[identifier]
      )
    },

    bandIsNotDefault () {
      return identifier => this.isNotDefault(
        configuration => configuration.parameters.mean_dw[identifier],
        value => `Default threshold is ${value} dB.`
      )
    }
  },

  methods: {
    // Toggles the band, by setting its value to default or clearing to null.
    // Cleared bands will not be included in alert checks.
    toggleBand (band, value) {
      const { mean_dw } = this.configuration.parameters
      const { mean_dw: default_mean_dw } = this.defaultConfiguration.parameters
      mean_dw[band.identifier] = value ? default_mean_dw[band.identifier] : null
    }
  }
}
</script>

<template>
  <article>
    <section v-if="isEditable && advancedConfiguration" v-for="band in bands" :key="band.identifier"
      class="dense row items-center">
      <label class="q-pl-xl row items-center" :class="{ modified: bandIsModified(band.identifier) }">
        <q-checkbox :model-value="mean_dw[band.identifier] != null"
          @update:model-value="value => toggleBand(band, value)" size="34px" color="indigo-6"
          class="q-mr-sm" />
        <span>
          {{ band.label }}
        </span>
      </label>
      <div class="row items-center">
        <q-input v-model.number="mean_dw[band.identifier]" type="number" dense outlined square
          hide-bottom-space :disabled="mean_dw[band.identifier] == null"
          :readonly="mean_dw[band.identifier] == null" :min="mean_dw.min" :max="mean_dw.max"
          :rules="[rules.isNumber(mean_dw.min, mean_dw.max, `Allowed range: ${mean_dw.min}..${mean_dw.max}`)]"
          debounce="500" :style="{ 'max-width': '150px' }">
        </q-input>
      </div>
      <div class="text-grey-7 q-pl-md">
        {{ bandIsNotDefault(band.identifier) }}
      </div>
    </section>
  </article>
</template>

<style scoped lang="scss">
</style>