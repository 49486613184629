import { createState } from './organization-places.state'

export const mutations = {
  /**
   * Reset the state
   */
  reset (state) {
    Object.assign(state, createState())
  }
}
