import { Log } from '@stellacontrol/utilities'
import { EntityType } from '@stellacontrol/model'
import { PushClient } from './push-client'

/**
 * Push notification client for Devices API
 */
export class DevicesPushClient extends PushClient {
  constructor () {
    super()
    this.name = 'devices'
  }

  /**
   * Push services configuration
   * @type {Object}
   */
  configuration

  /**
   * Initializes the push client
   * @param {Object} configuration Push services configuration
   */
  initialize (configuration) {
    if (!configuration) throw new Error('Push services configuration is required')
    if (!configuration.endpoints) throw new Error('Push services endpoint configuration is required')
    if (!configuration.endpoints.devices) throw new Error('Devices Push Server endpoint is required')

    this.configuration = configuration
  }

  /**
   * Checks whether the push client has been initialized
   * @returns {Boolean}
   */
  get isInitialized () {
    return Boolean(this.configuration)
  }

  /**
   * Subscribes to device status updates
   * @param {Function<DeviceStatus>} onMessage Handler called when device status arrives
   * @param {Function<Error>} onError Optional custom handler for websocket errors
   * @param {Array[Device]} devices Devices whose status updates to receive
   * @param {Number} reconnectAttempts If greater than zero, the connection will be automatically restored when lost, until reconnection attempts run out
   * @param {Number} reconnectInterval Interval between reconnect attempts, in milliseconds
   * @returns {DevicesPushClient} Client instance if succesfully subscribed to device status updates
   */
  async subscribeToDeviceStatus ({ onMessage, onError, devices, reconnectAttempts = PushClient.RECONNECT_INFINITE, reconnectInterval = PushClient.RECONNECT_INTERVAL } = {}) {
    if (!this.isInitialized) throw new Error('Devices Push Client is not initialized')
    if (!onMessage) throw new Error('Device Status handler is required')
    if (!(devices?.length > 0)) return

    const url = `${this.configuration.endpoints.devices}/push/subscribe`
    const delay = 1000
    const message = {
      items: devices.map(({ serialNumber }) => ({
        dataType: EntityType.DeviceStatus,
        entityType: EntityType.Device,
        entityId: serialNumber
      }))
    }

    const subscribed = await this.subscribe({
      url,
      message,
      delay,
      onMessage,
      onError,
      authenticate: true,
      reconnectAttempts,
      reconnectInterval
    })

    if (subscribed) {
      Log.debug(`[${url}] Subscribed to device status`)
      return this
    }
  }

  /**
   * Subscribes to device data updates, such as device properties, flags etc.
   * @param {Function<DeviceStatus>} onMessage Handler called when device data arrives
   * @param {Function<Error>} onError Optional custom handler for websocket errors
   * @param {Array[Device]} devices Devices whose updates to receive
   * @param {Number} reconnectAttempts If greater than zero, the connection will be automatically restored when lost, until reconnection attempts run out
   * @param {Number} reconnectInterval Interval between reconnect attempts, in milliseconds
   * @returns {DevicesPushClient} Client instance if succesfully subscribed to device updates
   */
  async subscribeToDeviceUpdates ({ onMessage, onError, devices, reconnectAttempts = PushClient.RECONNECT_INFINITE, reconnectInterval = PushClient.RECONNECT_INTERVAL } = {}) {
    if (!this.isInitialized) throw new Error('Devices Push Client is not initialized')
    if (!onMessage) throw new Error('Device Update handler is required')
    if (!(devices?.length > 0)) return

    const url = `${this.configuration.endpoints.devices}/push/subscribe`
    const delay = 1000
    const message = {
      items: devices.map(({ serialNumber }) => ({
        dataType: EntityType.Device,
        entityType: EntityType.Device,
        entityId: serialNumber
      }))
    }

    const subscribed = await this.subscribe({
      url,
      message,
      delay,
      onMessage,
      onError,
      authenticate: true,
      reconnectAttempts,
      reconnectInterval
    })

    if (subscribed) {
      Log.debug(`[${url}] Subscribed to device updates`)
      return this
    }
  }
}
