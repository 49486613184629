<script>
import { mapActions } from 'vuex'
import { DialogMixin, FormMixin } from '@stellacontrol/client-utilities'
import { SimulatedDeviceProfiles } from '@stellacontrol/devices'

const dialog = 'simulated-device'

export default {
  mixins: [
    DialogMixin,
    FormMixin,
  ],

  data () {
    return {
      dialog
    }
  },

  computed: {
    // Simulated device profiles
    simulatedDeviceProfiles () {
      return SimulatedDeviceProfiles.filter(p => p.name !== 'default')
    },

    // Simulated device profiles
    selectedProfile () {
      return SimulatedDeviceProfiles.find(p => p.name === this.data?.device?.simulatedDeviceProfile)
    }
  },

  methods: {
    ...mapActions([
      'dialogOk',
      'dialogCancel',
      'getDeviceBySerialNumber'
    ]),

    // Validation rule which checks whether the specified organization name is already in use
    async serialNumberIsUnique (serialNumber) {
      const device = await this.getDeviceBySerialNumber({ serialNumber })
      return !device || `Device ${serialNumber} already exists`
    },

    /**
     * Validates and OKs the dialog
     */
    async ok () {
      if (await this.$refs.form.validate()) {
        this.dialogOk({ dialog })
      }
    },

    /**
     * Cancels the dialog
     */
    cancel () {
      this.dialogCancel({ dialog })
    }
  }
}
</script>

<template>
  <sc-dialog dialog="simulated-device" persistent v-if="isVisible">
    <template #default="{ data }">
      <q-form autofocus ref="form" class="simulated-device q-pa-md q-mt-md">
        <div class="text-h6 q-pb-md">
          Add new simulated device
        </div>

        <div class="q-mb-md">
          Add a <b>SIMULATED</b> device
          {{ data.place ? 'to ' + data.place.placeType : '' }}
          <b>{{ data.place ? data.place.name : '' }}</b>
          in <b>{{ data.organization.name }}</b>
        </div>

        <div class="column q-gutter-md">
          <q-input square outlined dense hide-bottom-space autofocus
            label="Serial number"
            v-model="data.device.serialNumber"
            debounce="500"
            maxlength="45"
            lazy-rules
            :rules="[
              rules.required('Unique serial number is required'),
              value => serialNumberIsUnique(value)
            ]">
          </q-input>

          <q-select
            square outlined dense hide-bottom-space
            bg-color="white"
            label="Simulation profile"
            v-model="data.device.simulatedDeviceProfile"
            :rules="[rules.required('Simulation profile is required')]"
            :options="simulatedDeviceProfiles"
            emit-value
            map-options
            option-value="name"
            option-label="description">

            <template v-slot:option="scope">
              <q-item clickable v-close-popup v-bind="scope.itemProps">
                <div class="row items-center no-wrap">
                  <q-icon class="q-mr-sm" :name="scope.opt.icon" :color="scope.opt.color" size="sm" />
                  <span class="no-wrap-text">
                    {{ scope.opt.description }}
                  </span>
                </div>
              </q-item>
            </template>

          </q-select>

          <div class="q-mt-md q-mr-lg q-ml-lg text-grey-9">
            {{ selectedProfile ? selectedProfile.details : 'The profile defines what kind of status data will be reported for this device' }}
          </div>

          <q-input square outlined dense
            type="textarea"
            clearable
            clear-icon="close"
            bg-color="white"
            label="Notes"
            v-model="data.device.notes" />

          <div class="row q-mb-lg q-gutter-md justify-end">
            <q-btn flat color="indigo-6" label="Close" @click="cancel()"></q-btn>
            <q-btn size="md" color="primary" label="Save" @click="ok()"></q-btn>
          </div>
        </div>
      </q-form>
    </template>
  </sc-dialog>
</template>

<style scoped lang="scss">
.simulated-device {
  min-width: 550px;
  max-width: 550px;
  min-height: 530px;
  max-height: 530px;
}
</style>