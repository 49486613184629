import { dispatch, dispatchSequential } from '@stellacontrol/client-utilities'
import { Entity, Announcement } from '@stellacontrol/model'

export async function resolveAnnouncements ({ to, from } = {}) {
  if (to && from) {
    const guardian = await dispatch('getCurrentGuardian')
    if (guardian) {

      const data = guardian.canUse('announcements-edit')
        ? await dispatch('getAnnouncements')
        : await dispatch('getMyAnnouncements')

      if (data) {
        await dispatch('setRouteData', { from, to, data })
        return data
      }
    }
  }
}

export async function resolveAnnouncement ({ to, from } = {}) {
  if (to && from) {

    await dispatchSequential(
      'requireOrganizationProfiles',
      'requireOrganizations'
    )

    const { id } = to.params
    const { copy } = to.query

    const isNew = id === Entity.ID_NEW
    const announcement = isNew
      ? new Announcement()
      : await dispatch('getAnnouncement', { id })

    const data = !isNew && copy
      ? announcement.copy()
      : announcement

    if (data) {
      await dispatch('setRouteData', { from, to, data })
      return data
    }
  }
}
