import { Entity } from '../../common/entity'
import { Feature } from '../feature/feature'

export class Permission extends Entity {
  constructor (data) {
    super()

    this.assign(data)

    if (this.canUse == null) this.canUse = false
    if (this.defaultValue == null) this.defaultValue = false
  }

  /**
   * Normalizes the data after assignment
   */
  normalize () {
    super.normalize()
    this.permissions = this.castArray(this.permissions, Permission)
    if (this.feature) {
      this.feature = this.cast(this.feature, Feature)
    } else if (this.featureName) {
      this.feature = new Feature({ name: this.featureName })
    }
  }

  /**
   * Returns core data required, useful for serializations
   */
  getCore () {
    const { id, featureName, canUse, context } = this
    return { id, featureName, canUse, context }
  }

  /**
   * Name of the granted feature
   * @type {String}
   */
  featureName

  /**
   * Feature details
   * @type {Feature}
   */
  get feature () {
    return this.__feature
  }

  /**
   * Sets the value of the granted feature
   * @param {Feature} value Value to set
   */
  set feature (value) {
    this.__feature = value
    if (value) {
      this.featureName = value.name
    }
  }

  /**
   * Principal owning the permission
   * @type {String}
   */
  principalId

  /**
   * Indicates whether principal can use the feature
   * @type {Boolean}
   */
  canUse

  /**
   * Indicates reason why permission cannot be used by the principal
   * @type {String}
   */
  reason

  /**
   * Indicates details for the reason why permission cannot be used by the principal
   * @type {String}
   */
  details

  /**
   * Indicates whether principal can use the feature
   * by virtue of having special status such as super administrator,
   * rather than by explicit grant of permission by higher autority
   * @type {Boolean}
   */
  implicit

  /**
   * Additional contextual data associated with the permission
   * @type {Object}
   */
  context

  /**
   * Default value for permission,
   * which can be specified on principals used
   * as source for other principals, i.e. organization profiles
   * @type {Boolean}
   */
  defaultValue

  /**
   * Returns true if access to the feature has been denied
   * @type {Boolean}
   */
  get cannotUse () {
    return !this.canUse
  }

  /**
   * Child permissions, which reflect the feature hierarchy
   * @type {Array[Permission]}
   */
  permissions

  /**
   * Returns true if permission contains child permissions
   * @type {Boolean}
   */
  get hasChildren () {
    return this.permissions && this.permissions.length > 0
  }

  /**
   * Returns child permissions matching the specified condition
   * @param {Function<Permission, Boolean>} predicate Predicate to check
   * @param {Boolean} includeSelf If true, self is returned too, if it matches the predicate
   * @returns {Array[Permission]}
   */
  findPermissions (predicate, includeSelf) {
    let permissions = includeSelf && predicate(this) ? [this] : []
    if (this.hasChildren) {
      for (const permission of this.permissions) {
        permissions = [...permissions, ...permission.findPermissions(predicate, true)]
      }
    }
    return permissions
  }

  /**
   * Returns true if some permissions match the specified condition
   * @param {Function<Permission, Boolean>} predicate Predicate to check
   * @param {Boolean} includeSelf If true, self is checked too if it matches the predicate
   * @type {Boolean}
   */
  somePermissions (predicate, includeSelf) {
    return this.findPermissions(predicate, includeSelf).length > 0
  }

  // Runtime properties ---------------------------------------------------------------
  /**
   * Indicates whether the feature is applicable in the current environment
   * @type {Boolean}
   */
  isApplicableInEnvironment

  /**
   * Index used to enforce proper sorting
   * @type {Number}
   */
  index

  /**
   * Indicates nesting level of a permission, useful for the UI
   * @type {Number}
   */
  level

  /**
   * Parent permission
   * @type {Permission}
   */
  parent

  /**
   * Indicates that any child permissions under this permission are collapsed in the tree
   * @type {Boolean}
   */
  isCollapsed

  /**
   * Indicates that permission is currently selected
   * @type {Boolean}
   */
  isSelected

  /**
   * Indicates whether the item matches the current filter
   * @type {Boolean}
   */
  matchesFilter

  /**
   * Searchable text representing the permission, used in filtering
   * @type {String}
   */
  searchText

  /**
   * Other permissions required by this permission to be grant-able
   * @type {Array[Permission]}
   */
  requiredPermissions

  /**
   * Description of the required permissions
   * @type {String}
   */
  requiredPermissionsText

  /**
   * If these features are available to the principal,
   * this feature will not be available to be granted
   * @type {Array[String]}
   */
  notApplicableWhen

  /**
   * If specified, only organizations of these levels can grant this permission
   * @type {Array[OrganizationLevel]}
   */
  grantedBy

  /**
   * String representation, handy for debuging
   * @type {String}
   */
  toString () {
    return this.feature ? `${this.feature.name}: ${this.canUse ? 'CAN USE' : 'CANNOT USE'}` : ''
  }

  /**
   * Overrides serialization to prevent serializing of certain
   * runtime-only properties
   * @returns {Object}
   */
  toJSON () {
    const result = {
      ...this
    }
    delete result.__canUse
    delete result.index
    delete result.level
    delete result.parent
    delete result.isCollapsed
    delete result.isSelected
    delete result.notApplicable
    delete result.matchesFilter
    delete result.requiredPermissions
    delete result.requiredPermissionsText
    delete result.isApplicableInEnvironment
    return result
  }

  /**
   * Clears filter previously applied to the permission
   */
  clearFilter () {
    this.matchesFilter = true
    if (this.hasChildren) {
      for (const item of this.permissions) {
        item.clearFilter()
      }
    }
  }
}
