/**
 * Installs Notifications UI dialogs into the application
 * @param application Vue application instance
 */
export function useNotificationsDialogs (application) {
  const plugin = {
    install (application) {
      const items = [
      ]

      for (const { tag, component } of items) {
        application.component(tag, component)
      }
    }
  }

  application.use(plugin)
}
