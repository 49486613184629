import { capitalize, formatDateTime, dateCompare, stringCompare, distinctValues, sortItemsWith } from '@stellacontrol/utilities'
import { getDeviceLabel, UploadDescription, DeviceFirmware, DeviceFirmwareGroupBy } from '@stellacontrol/model'
import { getJobSchedule } from '@stellacontrol/device-transmission'

export function createState (initialState = {}) {
  return {
    /**
     * Firmwares available to current organization
     */
    firmwares: [],

    /**
     * Firmware list columns
     */
    firmwareColumns: [
      { name: 'versionModel', label: 'Firmware Version', field: 'version', sortable: false, align: 'left', format: (version, item) => item.parentId ? item.label : item.version.toFullString() },
      { name: 'name', label: 'Name', field: 'name', sortable: false, align: 'left' },
      { name: 'created', label: 'Uploaded', field: 'createdAt', sortable: false, align: 'left', format: value => formatDateTime(value), sort: dateCompare },
      { name: 'isShared', label: 'Shared', field: 'hasOrganizations', sortable: false, align: 'left', format: hasOrganizations => hasOrganizations ? 'Shared' : 'Not shared' },
      { name: 'fileName', label: 'File Name', field: 'file', format: file => (file || {}).name, sortable: false, align: 'left' },
      { name: 'fileSize', label: 'File Size (B)', field: 'file', format: file => (file || {}).size, sortable: false, align: 'left' },
      { name: 'fileDate', label: 'File Time', field: 'file', format: file => file ? formatDateTime(file.date) : '', sortable: false, align: 'left' },
      { name: 'actions', label: 'Actions', align: 'right', sortable: false }
    ],

    /**
     * Upload jobs
     */
    jobs: [],

    /**
     * Number of days to show on jobs view
     */
    jobDays: null,

    /**
     * Firmware list columns
     */
    jobColumns: [
      { name: 'status', label: '', field: 'status', sortable: true, align: 'left' },
      { name: 'type', label: 'Upload Type', field: 'type', sortable: true, align: 'left', format: type => capitalize(UploadDescription[type]) },
      { name: 'description', label: 'Description', field: 'payload', sortable: true, align: 'left', format: payload => payload ? payload.description : '-' },
      { name: 'creator', label: 'Started By', field: 'creatorName', sortable: true, align: 'left' },
      { name: 'device', label: 'Device', field: 'device', sortable: true, align: 'left', format: device => device ? getDeviceLabel(device) : '-' },
      { name: 'currentVersion', label: 'Version', field: 'device', sortable: true, align: 'left', format: device => device ? device.firmwareVersionLong : '-' },
      { name: 'fileName', label: 'File name', field: 'payload', sortable: true, align: 'left', format: payload => (payload && payload.file) ? payload.file.name : '', desktopOnly: true },
      { name: 'defer', label: 'Schedule', field: 'defer', sortable: true, align: 'left', format: (_, job) => capitalize(getJobSchedule(job)), desktopOnly: true },
      { name: 'progress', label: 'Progress', field: 'progress', sortable: true, align: 'left', format: progress => `${progress || 0} %` },
      { name: 'retryAttempts', label: 'Attempts Left', field: 'retryAttempts', sortable: true, align: 'left', format: (retryAttempts, job) => (job.isFailed || job.isCompleted) ? '' : retryAttempts },
      { name: 'updatedAt', label: 'Last Update', field: 'updatedAt', sortable: true, align: 'left', format: value => formatDateTime(value), sort: (a, b) => dateCompare(a, b) },
      { name: 'actions', label: 'Actions', align: 'right', sortable: false }
    ],

    /**
     * Firmware list grouping mode
     */
    groupFirmwaresBy: DeviceFirmwareGroupBy.Version,

    /**
     * Device models where remote firmware updates can be executed
     */
    deviceModels: [],

    /**
     * List of models+FW versions which are not updateable
     */
    nonUpdateable: [],

    /**
     * Currently expanded firmware groups
     */
    expandedFirmwareGroups: {},

    // Properties of a currently running upload status poll clocks.
    // It's a per-view dictionary where each view adds its own
    // clock with its own configuration.
    polling: {},

    // Date and time of last check for status of upload jobs
    lastStatusCheck: null,

    ...initialState
  }
}

export const state = createState()

export const getters = {
  /**
   * Returns firmwares grouped by firmware version, for the UI
   * @param {Array[Firmware]} firmwares Firmwares to group
   * @returns {Array} Firmwares, grouped by version
   */
  firmwaresByVersion: (state, getters) => {
    const canManageFirmware = getters.guardian.canUse('can-manage-firmware')
    const activeFirmwares = state.firmwares.filter(firmware => canManageFirmware || firmware.isActive)
    activeFirmwares.sort((a, b) => {
      const result = -a.version.compareWith(b.version)
      return result === 0
        ? stringCompare(a.modelsString, b.modelsString)
        : result
    })

    const items = []
    let parent

    for (let i = 0; i < activeFirmwares.length; i++) {
      const firmware = activeFirmwares[i]
      const previousFirmware = activeFirmwares[i - 1]
      const sameVersion = previousFirmware && firmware.version.isSameAs(previousFirmware.version)
      if (!sameVersion) {
        parent = {
          id: `v-${firmware.version.toFullString()}`,
          label: firmware.version.toFullString(),
          version: firmware.version,
          isGroup: true
        }
        items.push(parent)
      }

      const item = new DeviceFirmware({
        ...firmware,
        label: firmware.modelsString,
        parentId: parent.id,
        isGroup: false,
      })
      items.push(item)
    }

    return items
  },


  /**
   * Returns firmwares grouped by device model, for the UI
   * @param {Array[Firmware]} firmwares Firmwares to group
   * @returns {Array} Firmwares, grouped by device model
   */
  firmwaresByDeviceModel: (state, getters) => {
    const canManageFirmware = getters.guardian.canUse('can-manage-firmware')
    const activeFirmwares = state.firmwares.filter(firmware => canManageFirmware || firmware.isActive)
    const uniqueModels = distinctValues(state.firmwares.flatMap(firmware => firmware.models))
    uniqueModels.sort()

    const items = []

    for (const model of uniqueModels) {
      const allowedFirmwares = activeFirmwares.filter(f => f.isAllowedForDevice(model))
      const modelFirmwares = sortItemsWith(allowedFirmwares, (a, b) => -a.version.compareWith(b.version))

      if (modelFirmwares.length > 0) {
        const parent = {
          id: `m-${model}`,
          label: model,
          model,
          isGroup: true
        }
        items.push(parent)

        for (const firmware of modelFirmwares) {
          const item = new DeviceFirmware({
            ...firmware,
            label: firmware.versionString,
            parentId: parent.id,
            isGroup: false
          })
          items.push(item)
        }
      }
    }

    return items
  }

}
