import { Secure } from '../../secure-component'

export const ContextEditor = {
  mixins: [
    Secure
  ],

  props: {
    // Editor name
    name: {
      required: true
    },

    // Editor title for the humans to read
    title: {
      required: true
    },

    // Principal whose permissions are being edited
    principal: {
      required: true
    },

    // All permissions of the principal
    permissions: {
      required: true
    },

    // Permission being edited
    permission: {
      required: true
    }
  }
}
