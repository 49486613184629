/**
 * Recursively extracts all views from the specified hierarchy
 * @param items Views list
 * @param hierarchy Whole view hierarchy
 */
export function getViews (items = [], hierarchy) {
  hierarchy = hierarchy || items
  let children = []

  for (const item of items) {
    item.path = findViewPath(item.name, hierarchy)
    if (item.views) {
      children = [...children, ...getViews(item.views, hierarchy)]
    }
  }

  // Enrich views with runtime properties and defaults
  const views = [...items, ...children].map(view => ({
    // View is hidden by default
    isVisible: false,
    // View is secure by default
    isSecure: true,
    // View does not require any special permissions by default
    permissions: [],
    // Route associated with the view
    route: null,
    // Route that has lead to the view
    from: null,
    // Other properties
    ...view,
    // View tabs, specified as:
    //  - array of tab names or
    //  - dictionary of tabs with required permissions
    tabs: view.tabs
      ? (Array.isArray(view.tabs)
        ? view.tabs.reduce((all, tab) => ({ ...all, [tab]: [] }), {})
        : view.tabs)
      : null,
    // Selected view tab
    tab: view.tabs
      ? (Array.isArray(view.tabs)
        ? view.tabs[0]
        : Object.keys(view.tabs)[0])
      : null
  }))

  return views
}

/**
 * Finds a path of the view in hierarchy
 * @param name Name of the view
 * @param hierarchy Hierarchy of views to traverse
 * @param path Path traversed so far
 * @returns Array of view names leading to the specified view
 */
function findViewPath (name, hierarchy, path = []) {
  for (const item of hierarchy || []) {
    if (item.name === name) {
      return [...path, item.name]
    } else if (item.views) {
      const found = findViewPath(name, item.views, [...path, item.name])
      if (found) {
        return found
      }
    }
  }
}
