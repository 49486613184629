import { DateRange } from '@stellacontrol/model'
import { createState } from './device-history-view.state'

export const mutations = {
  /**
   * Initializes the DEVICE HISTORY view
   * @param {DeviceHierarchy} hierarchy Hierarchy of organizations, places and devices available to the currently logged in organization and user
   * @param {Organization} organization Organization to view or organization associated with the entity
   * @param {OrganizationGuardian} organizationGuardian Organization's guardian
   * @param {Device} device Device to view
   * @param {Device} devicePart Multi-device part to view
   * @param {Object} tree Recent state of the items tree
   * @param {Object} filter Recent filter
   */
  initializeDeviceHistoryView (state, { hierarchy, organization, organizationGuardian, device, tree, filter } = {}) {
    state.hierarchy = hierarchy
    state.organization = organization
    state.organizationGuardian = organizationGuardian
    state.device = device
    state.tree.viewMode = tree.viewMode
    state.tree.expanded = tree.expanded
    state.tree.selected = device ? tree.selected : null
    state.tree.isMinimized = tree.isMinimized
    state.tree.isHidden = tree.isHidden
    state.tree.filter = tree.filter
    state.filter = {...state.filter, ...filter }

    // Make sure that period is not too wide
    if (state.filter.period.length > state.filter.maxDays) {
      state.filter.period = DateRange.today()
    }
  },

  /**
   * Stores updated data in the DEVICE HISTORY view
   * @param hierarchy Hierarchy of organizations, places and devices available to the currently logged in organization and user
   * @param organization Organization to view or organization associated with the entity
   */
  updateDeviceHistoryView (state, { hierarchy, organization } = {}) {
    if (hierarchy !== undefined) {
      state.hierarchy = hierarchy
    }
    if (organization !== undefined) {
      state.organization = organization
    }
  },

  /**
   * Stores currently visibility of the tree
   * @param isMinimized Visibility of the tree
   * @param filter Filter applied to the tree
   * @param viewMode Tree view mode
   */
  storeDeviceHistoryTreeState (state, { isMinimized, filter, viewMode } = {}) {
    if (isMinimized !== undefined) {
      state.tree.isMinimized = isMinimized
    }
    if (filter !== undefined) {
      state.tree.filter = filter
    }
    if (viewMode !== undefined) {
      state.tree.viewMode = viewMode
    }
  },

  /**
   * Stores currently expanded tree items
   * @param expanded List of identifiers of items currently expanded in the asset tree
   */
  storeExpandedDeviceHistoryItems (state, { expanded = [] } = {}) {
    state.tree.expanded = expanded
  },

  /**
   * Stores device history filter
   * @param {DateRange} period Filter period to store
   * @param {Array[String]} parameters Filter parameters to store
   * @param {Array[String]} extras Filter extras to store
   */
  storeDeviceHistoryFilter (state, { period, parameters, extras } = {}) {
    state.filter.period = period || state.filter.period
    state.filter.parameters = [...parameters || state.filter.parameters]
    state.filter.extras = [...extras || state.filter.extras]
  },

  reset (state) {
    Object.assign(state, createState())
  }
}
