<script>
import { reactive } from 'vue'

export default {
  props: {
    /**
     * Note to edit
     */
    note: {
      type: Object,
      required: true
    },

    /**
     * Note recipients
     */
    recipients: {
      type: Array,
      default () {
        return []
      }
    }
  },

  setup (props, { emit }) {
    // Component props
    let { note } = props

    const state = reactive({
      // Text of the edited note
      text: note?.text || '',
      // Recipient of the edited note
      recipientId: note?.recipientId
    })

    // Saves changes in the edited note
    function save () {
      note.recipientId = state.recipientId
      note.text = state.text
      emit('save', note)
    }

    // Cancels changes and signals to close the note editor
    function cancel () {
      emit('cancel')
    }

    // Returns a recipient of the specified note
    function noteRecipient () {
      return props.recipients.find(r => r.id === state.recipientId)
    }

    return {
      state,
      cancel,
      save,
      noteRecipient
    }
  }
}
</script>

<template>
  <div class="notes-container q-pb-sm" v-if="note">
    <q-list class="notes">
      <q-item class="q-mb-sm q-pa-md note" :class="{ editing: note.isEditing }">

        <q-item-section top>
          <q-item-label caption class="q-mb-xs">
            <div class="row">
              <span>
                {{ dateTimeString(note.createdAt) }}
              </span>
              <span class="q-ml-sm">
                {{ note.creator.fullName }}
              </span>
              <span class="q-ml-sm row items-center" v-if="noteRecipient()">
                <q-icon name="double_arrow" size="16px" color="grey-6" class="q-mr-sm"></q-icon>
                {{ noteRecipient().name }}
              </span>
              <q-space></q-space>
            </div>
          </q-item-label>

          <div>
            <q-input
              class="note-editor"
              ref="noteEditor"
              v-model="state.text"
              type="textarea"
              outlined
              bg-color="white"
              v-focus>
            </q-input>

            <sc-organization-selector class="q-mt-sm q-mb-sm"
              v-if="recipients.length > 0"
              :items="recipients"
              :dense="true"
              v-model="state.recipientId"
              label="Select note recipient">
            </sc-organization-selector>

            <div class="row justify-end q-pt-sm">
              <q-btn label="Save" class="primary q-mr-sm" unelevated dense @mousedown="save()"></q-btn>
              <q-btn label="Close" class="q-mr-sm" unelevated dense @mousedown="cancel()"></q-btn>
            </div>
          </div>

        </q-item-section>
      </q-item>
    </q-list>

  </div>
</template>

<style lang="scss" scoped>
.notes-container {
  flex: 1;

  .notes {
    .note {
      border: dotted #a7a7a7 1px;
      border-radius: 4px;
      position: relative;
    }
  }
}
</style>
