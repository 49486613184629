<script>
import { mapGetters, mapActions } from 'vuex'
import DeviceAction from './device-action.vue'
import { DeviceActionMixin } from './device-action-mixin'

export default {
  mixins: [
    DeviceActionMixin
  ],

  components: {
    'sc-device-action': DeviceAction
  },

  data () {
    return {
      // Date of decommissioning
      decommissionedOn: new Date(),
      // Additional notes
      notes: null
    }
  },

  computed: {
    ...mapGetters([
      'company'
    ])
  },

  methods: {
    ...mapActions([
      'decommissionDevices'
    ]),

    async decommission () {
      if (await this.validate()) {
        const { devices, decommissionedOn, notes } = this
        await this.decommissionDevices({ devices, decommissionedOn, notes })
        this.executed()
      }
    }
  }
}
</script>

<template>
  <sc-device-action
    :action="action"
    :devices="devices"
    :execute-label="`Decommission ${devicesLabel}`"
    @closing="closing"
    @close="close"
    @execute="decommission">

    <q-form ref="form" autofocus class="q-mt-md q-gutter-sm" @submit.prevent>

      <div>
        <label class="text-body2 text-grey-9">
          This will make {{ devicesLabel }} unavailable to current owner,
          distributor and other organizations with which {{ devicesLabel }} has been shared.
          {{ capitalize(devicesLabel) }} will be kept in the database for the record,
          but available only to {{ company.name }}.
        </label>
      </div>

      <sc-date-input
        class="q-mt-md"
        :dense="false"
        label="Decommissioned on"
        v-model="decommissionedOn"
        :rules="[
          rules.required('Date is required')
        ]">
      </sc-date-input>

      <q-input square outlined
        label="Notes"
        v-model="notes"
        type="textarea"
        autogrow
        >
      </q-input>

    </q-form>

  </sc-device-action>

</template>

<style lang="scss" scoped>
</style>
