<script>
import { AntennaType, AntennaName } from '@stellacontrol/planner'
import ItemPropertiesMixin from './item-properties.js'
import ItemProperties from './item-properties.vue'
import BackgroundProperties from './background-style-properties.vue'

export default {
  mixins: [
    ItemPropertiesMixin
  ],

  components: {
    'sc-item-properties': ItemProperties,
    'sc-background-properties': BackgroundProperties
  },

  data () {
    return {
      AntennaType
    }
  },

  computed: {
    // Available antenna types
    antennaTypes () {
      return Object
        .values(AntennaType)
        .map(value => ({ value, label: AntennaName[value] }))
    }
  }
}

</script>

<template>
  <sc-item-properties :renderer="renderer" :items="items" @action="executeAction" labelRadius="Size">
  </sc-item-properties>

  <main class="plan-item-properties">
    <section>
      <label>
        Beam
      </label>
      <q-input
        type="number" :min="0" :max="360" label="Degrees"
        dense outlined square
        debounce="500"
        :input-style="styles.number"
        :model-value="getValue('beam')"
        @update:model-value="value => setProperties({ beam: parseInt(value) })">
      </q-input>
    </section>
  </main>

</template>

<style lang="scss" scoped></style>
