<script>
import ItemPropertiesMixin from './item-properties.js'
import ItemProperties from './item-properties.vue'
import LineStyleProperties from './line-style-properties.vue'

export default {
  mixins: [
    ItemPropertiesMixin
  ],

  components: {
    'sc-item-properties': ItemProperties,
    'sc-line-style-properties': LineStyleProperties
  }
}

</script>

<template>
  <sc-item-properties :renderer="renderer" :items="items" @action="executeAction">
  </sc-item-properties>

  <sc-line-style-properties :renderer="renderer" :items="items" @action="executeAction" arrows>
  </sc-line-style-properties>
</template>

<style lang="scss" scoped>
</style>
