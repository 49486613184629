import devices from './devices'
import places from './places'
import installationsView from './installations-view'
import buildingDashboard from './building-dashboard'
import deviceDashboard from './device-dashboard'
import deviceStatus from './device-status'
import deviceSettings from './device-settings'
import deviceCommands from './device-commands'
import inventoryView from './inventory-view'
import devicePanel from './device-panel'
import deviceHistoryView from './device-history-view'
// TODO: REMOVE when old floor plans are deprecated
import floorPlans from './floor-plans'

export const AppletStores = {
  devices,
  places,
  installationsView,
  buildingDashboard,
  deviceDashboard,
  floorPlans,
  deviceStatus,
  deviceSettings,
  deviceCommands,
  inventoryView,
  devicePanel,
  deviceHistoryView
}
