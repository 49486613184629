import { state, getters } from './alerts-view.state'
import * as mutations from './alerts-view.mutations'
import * as actions from './alerts-view.actions'

export default {
  state,
  getters,
  mutations,
  actions
}
