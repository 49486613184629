import { capitalize, formatDate } from '@stellacontrol/utilities'
import { SortOrder, PlaceType, PremiumServiceStatus, getPlaceLabel, getPlaceIcon, DeviceConnectionStatus, DeviceConnectionStatusColor, DeviceConnectionStatusName } from '@stellacontrol/model'

// Inventory grid columns
export const InventoryGridColumns = {
  isSelected: {
    label: '',
    width: '32px',
    isVisible: true,
    cannotBeHidden: true,
    isExportable: false
  },

  tags: {
    label: 'Tags',
    width: '20px',
    isVisible: true,
    options: [],
    multiple: false,
    isExportable: false
  },

  isOnline: {
    label: 'Connection Status',
    icon: 'radio_button_unchecked',
    iconColor: 'gray-5',
    options: [
      {
        label: 'All',
        value: 'all'
      },
      ...Object.values(DeviceConnectionStatus)
        .filter(value => ![DeviceConnectionStatus.Error, DeviceConnectionStatus.Unknown, DeviceConnectionStatus.Heartbeat].includes(value))
        .map(value => ({
          label: DeviceConnectionStatusName[value],
          value,
          icon: 'brightness_1',
          iconColor: value === DeviceConnectionStatus.Unknown
            ? DeviceConnectionStatusColor[DeviceConnectionStatus.NeverConnected]
            : DeviceConnectionStatusColor[value]
        }))
    ],
    multiple: false,
    defaultFilter: 'all',
    width: '40px',
    isVisible: true,
    text: item => item ? '' : undefined
  },

  type: {
    label: 'Type',
    width: '40px',
    isVisible: true,
    isFixedWidth: true,
    Visible: true,
    filterType: 'text',
    text: item => item.device.acronym
  },

  serialNumber: {
    label: 'Serial number',
    width: '90px',
    isVisible: true,
    filterType: 'text',
    text: item => item.device.serialNumber
  },

  name: {
    label: 'Device name',
    width: '120px',
    isVisible: false,
    filterType: 'text',
    text: item => item.device.name
  },

  alerts: {
    label: 'Alerts',
    icon: 'notifications',
    iconColor: 'indigo-5',
    options: [
      { value: 'all', label: 'All' },
      { value: 'monitored', label: 'Monitored', icon: 'notifications', iconColor: 'green-5' },
      { value: 'not-monitored', label: 'Not monitored', icon: 'notifications_off', iconColor: 'grey-5' }
    ],
    defaultFilter: 'all',
    width: '24px',
    isVisible: true,
    text: item => item.isMonitored ? 'monitored' : 'not monitored'
  },

  firmwareVersion: {
    label: 'FW',
    width: '70px',
    isVisible: true,
    filterType: 'text',
    text: item => item.firmwareVersion
  },

  megaVersion: {
    label: 'MEGA',
    width: '65px',
    isVisible: false,
    filterType: 'text',
    text: item => item.device.megaVersion
  },

  hardwareVersion: {
    label: 'HW',
    width: '25px',
    isVisible: false,
    filterType: 'text',
    text: item => item.device.hardwareVersion
  },

  eepromVersion: {
    label: 'EEPROM',
    width: '50px',
    isVisible: false,
    filterType: 'text',
    text: item => item.device.eepromVersion
  },

  model: {
    label: 'Model',
    width: '120px',
    isVisible: true,
    filterType: 'text',
    text: item => item.model
  },

  manufacturedAt: {
    label: 'Manufactured',
    options: [],
    width: '70px',
    isVisible: false,
    filterType: 'text',
    text: item => formatDate(item.device.manufacturedAt)
  },

  ownership: {
    label: 'Ownership',
    options: [],
    isVisible: true,
    filterType: 'text',
    text: item => item.ownershipText
  },

  soldAt: {
    label: 'Sold',
    options: [],
    width: '70px',
    isVisible: true,
    filterType: 'text',
    text: item => {
      const date = item.soldAt
      return date ? formatDate(date) : undefined
    }
  },

  premiumServiceStatus: {
    label: 'Premium Service Status',
    defaultFilter: 'all',
    icon: 'paid',
    iconColor: 'indigo-5',
    options: [
      {
        label: 'All',
        value: 'all'
      },
      {
        value: PremiumServiceStatus.None,
        label: 'No Premium Services Assigned',
        icon: 'not_interested',
        iconColor: 'grey-5'
      },
      {
        value: PremiumServiceStatus.NotStarted,
        label: 'Premium Services Not Started',
        icon: 'pause_circle',
        iconColor: 'grey-5'
      },
      {
        value: PremiumServiceStatus.Inactive,
        label: 'Premium Services Not Yet Active',
        icon: 'play_circle',
        iconColor: 'grey-5'
      },
      {
        value: PremiumServiceStatus.Active,
        label: 'Premium Services Active',
        icon: 'play_circle',
        iconColor: 'green-6'
      },
      {
        value: PremiumServiceStatus.Expired,
        label: 'Premium Services Expired',
        icon: 'error',
        iconColor: 'orange-5'
      }
    ],
    width: '24px',
    isVisible: true,
    text: item => capitalize(item.device.premiumServiceStatus)
  },

  premiumService: {
    label: 'PS',
    width: '65px',
    isVisible: true,
    filterType: 'text',
    text: item => item.device.premiumServiceLabel
  },

  placeType: {
    isDisabled: true,
    label: 'Place Type',
    defaultFilter: 'all',
    icon: 'fmd_good',
    iconColor: 'indigo-5',
    options: [
      {
        label: 'All',
        value: 'all'
      },
      ...Object.values(PlaceType)
        .filter(value => value !== PlaceType.SharedPlace)
        .map(value => ({
          value,
          label: capitalize(getPlaceLabel(value)),
          icon: getPlaceIcon(value)
        }))
    ],
    width: '24px',
    isVisible: true,
    text: item => item.placeType
  },

  place: {
    label: 'Place Name',
    isVisible: true,
    width: '120px',
    filterType: 'text',
    text: item => item.place
  },

  location: {
    label: 'Location in the place',
    isVisible: true,
    width: '120px',
    filterType: 'text',
    text: item => item.device.customLocation || item.device.location
  },

  notes: {
    label: 'Notes',
    isVisible: true,
    filterType: 'text',
    text: item => item.recentNote
  }
}

// Creates a clone of default grid inventory columns
function getInventoryGridColumns () {
  const columns = {}
  for (const [name, column] of Object.entries(InventoryGridColumns)) {
    columns[name] = { ...column }
  }
  return columns
}

// Creates new clean state for the inventory view,
// preserving certain state between navigations
export function createState (previousState) {
  return {
    // Indicates that inventory view is currently reloading
    isReloading: false,

    // Inventory grid options
    gridOptions: {
      backgroundColor: '#ecebef',
      sortBy: previousState?.gridOptions?.sortBy || 'manufacturedAt',
      sortOrder: previousState?.gridOptions?.sortOrder || SortOrder.Descending,
      showDecommissionedDevices: false,
      showNonConnectedDevices: true,
      showDeviceParts: false,
      showSimulatedDevices: true,
      rowsPerPage: previousState?.gridOptions?.rowsPerPage || 50,
      rowsPerPageItems: [10, 25, 50, 100, 200, 500, 1000, 0]
    },

    // All grid columns
    defaultGridColumns: getInventoryGridColumns(),

    // Grid columns applicable to the current user
    gridColumns: previousState?.gridColumns || {},
    gridColumnsList: previousState?.gridColumnsList || [],

    // Grid filters dictionary, per column
    gridFilters: previousState?.gridFilters || {},

    // Available device tags, as dictionary and list
    deviceTags: {},
    deviceTagsList: [],

    // Inventory view items
    items: previousState?.items || []
  }
}

export const state = createState()

export const getters = {
}
