import { PlanAction, PlanActions } from './plan-action'

/**
 * Selects the specified items
 */
export class SelectItemsAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.SelectItems
  }

  /**
   * Action label
   * @type {String}
   */
  get label () {
    return 'Select items'
  }

  /**
   * Action icon
   * @type {String}
   */
  get icon () {
    return 'highlight_alt'
  }

  /**
   * Indicates that the action requires items to act on
   * @type {Boolean}
   */
  get requiresItems () {
    return true
  }

  /**
   * Indicates whether action requires refresh
   * @type {Boolean}
   */
  get requiresRefresh () {
    return true
  }

  /**
   * If true, the current selection will be preserved
   * after the action has been executed
   * @type {Boolean}
   */
  get preserveSelection () {
    return true
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   * @param {Array[PlanItem]} items Plan items to apply the action to
   */
  execute ({ renderer, items } = {}) {
    if (renderer && items) {
      if (items.length > 1) {
        renderer.selectItems({ items })
      } else {
        const item = items[0]
        const position = item.getCoordinates(renderer.isCrossSection)
        renderer.selectItem({ item, position })
      }
    }
  }
}
