<script>
import { mapActions, mapGetters } from 'vuex'
import { addSeconds, isSameDay, isTomorrow } from 'date-fns'
import { DeviceType, getDevicesLabel, getDeviceLabel, Version, versionCompare, HelpTopic, DocumentCategory, DocumentSubcategory } from '@stellacontrol/model'
import { formatTime, formatDateTime, distinctValues } from '@stellacontrol/utilities'
import { deviceUpdatedByUserRequest } from '@stellacontrol/device-transmission'
import { isDeviceCommandApplicable } from '@stellacontrol/mega'
import DeviceAction from './device-action.vue'
import { DeviceActionMixin } from './device-action-mixin'

export default {
  mixins: [
    DeviceActionMixin
  ],

  components: {
    'sc-device-action': DeviceAction
  },

  data () {
    return {
      // All available firmwares
      firmwares: [],
      // Firmware version to upload to devices
      firmwareVersion: null,
      // Time in hours to defer the upload
      deferBy: -1
    }
  },

  computed: {
    ...mapGetters([
      'isBusy'
    ]),

    // Options to select from, when scheduling firmware updates
    deferItems () {
      const { updatedByUserRequest } = this

      const items = [
        {
          value: -1,
          label: 'Whenever the device comes online'
        },
        updatedByUserRequest
          ? {
            value: -2,
            label: 'When user requests the update from the device'
          }
          : null
      ]

      if (!updatedByUserRequest) {
        items.push(
          { value: 3600, label: 'In one hour' },
          { value: 2 * 3600, label: 'In two hours' },
          { value: 3 * 3600, label: 'In three hours' },
          { value: 6 * 3600, label: 'In six hours' },
          { value: 12 * 3600, label: 'In twelve hours' },
          { value: 24 * 3600, label: 'Tomorrow' },
          { value: 48 * 3600, label: 'In two days' },
          { value: 72 * 3600, label: 'In three days' },
        )
      }

      return items.filter(i => i != null)
    },

    // True if current user is allowed to manage firmware repository
    canManageFirmware () {
      return this.canUse('can-manage-firmware')
    },

    // Returns all devices where there are uploads in progress or scheduled.
    scheduledUploads () {
      return this.devices.filter(d => d.updateStatus?.inProgress || d.updateStatus?.isScheduled)
    },

    // Indicates whether there any scheduled uploads on the selected devices.
    // In such case we won't allow another upload, until the pending upload
    // has completed, has failed or has been removed by the user.
    hasScheduledUploads () {
      return this.scheduledUploads.length > 0
    },

    // Devices which can be updated remotely
    updatableDevices () {
      const { devices } = this

      const updatableDevices = devices.filter(device =>
        device.isConnectedDevice &&
        !device.isMultiDevice &&
        isDeviceCommandApplicable('FIRMWARE-UPDATE', device))

      return updatableDevices
    },

    // True if any of the selected devices can be updated remotely
    hasUpdatableDevices () {
      return this.updatableDevices.length > 0
    },

    // Dictionary of current firmware versions of devices
    currentVersions () {
      const { devices, getDistinctValues } = this
      const versions = getDistinctValues('firmwareVersionLong')
      versions.sort()
      return versions.map(version => ({
        version,
        devices: devices.filter(device => device.firmwareVersionLong === version)
      }))
    },

    // Non-obsolete uploadable firmwares available to current organization.
    // Super-administrator can also upload obsolete firmwares.
    availableFirmwares () {
      const { isSuperAdministrator } = this
      return this.firmwares.filter(f =>
        (!f.isObsolete || isSuperAdministrator) && f.hasFile)
    },

    // Indicates whether there any firmwares available to the current organization
    // which can be uploaded to selected devices
    hasAvailableFirmwares () {
      return this.availableFirmwares.length > 0
    },

    // Subset of firmware versions which are present for all currently selected devices
    applicableFirmwareVersions () {
      const { updatableDevices, availableFirmwares, device } = this
      const models = distinctValues(updatableDevices.map(d => d.model))
      const firmwaresByVersion = availableFirmwares.reduce((all, firmware) => {
        const version = firmware.versionString
        all[version] = (all[version] || []).concat([firmware])
        return all
      }, {})
      const applicableVersions = Object
        .entries(firmwaresByVersion)
        // find firmwares applicable to the selected device model
        .filter(([version, firmwares]) => version && models.every(model => firmwares.some(f => f.isAllowedForDevice(model))))
        .map(([version]) => version)
        // remove firmwares the same as current version on device
        .filter(version => version !== device.firmwareVersionLong)
      applicableVersions.sort((a, b) => -versionCompare(a, b))
      return applicableVersions
    },

    // Options for select control
    firmwareVersionOptions () {
      const { applicableFirmwareVersions } = this
      return applicableFirmwareVersions.map(value => {
        const version = Version.parse(value)
        return {
          value: version.toFullString(),
          label: version.toFullString()
        }
      })
    },

    // Indicates whether there any active firmwares applicable to selected devices
    hasApplicableFirmwares () {
      return this.applicableFirmwareVersions.length > 0
    },

    // Indicates that device update requires explicit user request,
    // as opposed to devices which can be updated unattended
    updatedByUserRequest () {
      return this.devices?.some(d => deviceUpdatedByUserRequest(d))
    },

    // Checks if rollback to previous version is available for the selected devices
    canRollbackFirmware () {
      return false && this.hasUpdatableDevices &&
        this.updatableDevices.every(device => isDeviceCommandApplicable('FIRMWARE-ROLLBACK', device))
    },

    // Checks if upload can be scheduled, now that all selections were made
    canScheduleUpload () {
      return this.hasApplicableFirmwares &&
        this.firmwareVersion &&
        this.hasUpdatableDevices
    },

    // Calculates scheduled time of upload
    scheduleTime () {
      const { deferBy, device: { owner } } = this
      if (deferBy > 0) {
        const scheduleTime = addSeconds(new Date(), deferBy)
        const timezone = owner?.timezone ? `(${owner?.timezone})` : ''
        if (isSameDay(scheduleTime, new Date())) {
          return `today at ${formatTime(scheduleTime)} ${timezone}`
        } else if (isTomorrow(scheduleTime)) {
          return `tomorrow at ${formatTime(scheduleTime)} ${timezone}`
        } else {
          return `on ${formatDateTime(scheduleTime)} ${timezone}`
        }
      } else {
        return ''
      }
    },

    // Determines the document containing FW history appropriate for the viewed device
    firmwareHistoryDocument () {
      const { device } = this
      if (device.type === DeviceType.TestTool) return DocumentSubcategory.TestTool
      if (device.isLegacyFirmware) return DocumentSubcategory.Legacy
      return DocumentSubcategory.Current
    },

    // Checks whether the specified device can be updated
    canUpdateDevice () {
      return device => isDeviceCommandApplicable('FIRMWARE-UPDATE', device)
    }
  },

  methods: {
    ...mapActions([
      'busy',
      'done',
      'getDeviceFirmwares',
      'createFirmwareUploadJobs',
      'pingDevice',
      'getUploadJob',
      'removeUploadJob',
      'showDialog'
    ]),

    getDeviceLabel,
    getDevicesLabel,
    isDeviceCommandApplicable,
    deviceUpdatedByUserRequest,

    // Initializes the action
    async initialize () {
      await this.busy()
      this.firmwareVersion = null
      this.firmwares = await this.getDeviceFirmwares()
      this.firmwareVersion = (this.applicableFirmwareVersions || [])[0]
      await this.done()
    },

    // Schedules firmware update for the selected devices
    async execute () {
      if (await this.validate()) {
        const { updatableDevices: devices, firmwareVersion: version, deferBy: defer } = this
        await this.createFirmwareUploadJobs({ version, devices, defer })
        // In 30+ seconds send PING to the updated devices, so that they report their status
        // and can immediately pick up their pending FW updates
        setTimeout(() => {
          this.pingDevice({ devices })
        }, 40 * 1000)

        this.executed()
      }
    },

    // Cancels the specified upload job
    async cancelUploadJob (id) {
      const job = await this.getUploadJob({ id })
      if (job) {
        await this.busy()
        await this.removeUploadJob({ job, confirm: true })
        await this.initialize()
        await this.done()
      }
    },

    // Rolls back the firmware on selected devices
    // to previous version stored on devices
    async rollback () {
      this.executed({ action: this.action })
    },

    // Show firmware change log
    showFirmwareChangeLog () {
      this.showDialog({
        dialog: 'help',
        data: {
          topic: HelpTopic.FirmwareHistory,
          category: DocumentCategory.FirmwareHistory,
          subcategory: this.firmwareHistoryDocument,
          isCollapsible: false
        }
      })
    }
  },

  watch: {
    // When device selection changes, pick default firmware version
    devices () {
      this.firmwareVersion = (this.applicableFirmwareVersions || [])[0]
    }
  },

  async created () {
    this.initialize()
  }
}
</script>

<template>
  <sc-device-action :action="action" :devices="updatableDevices" execute-label="Update"
    reset-label="Rollback"
    :reset-tooltip="canRollbackFirmware ? 'Reverts the firmware back to previous version' : ''"
    :canExecute="canScheduleUpload" :can-reset="canRollbackFirmware" @closing="closing"
    @close="close" @execute="execute" @reset="rollback">

    <q-form ref="form" autofocus class="q-mt-md q-gutter-sm q-pr-md" @submit.prevent>
      <div>
        Current firmware on {{ selectionLabel }}:
      </div>

      <q-markup-table class="versions" flat bordered square separator="cell" wrap-cells>
        <tbody>
          <tr v-for="item in currentVersions" :key="item.version">
            <td>{{ item.version }}</td>
            <td>
              <div>
                <span v-if="item.devices.length === 1">
                  {{ getDeviceLabel(item.devices[0]) }}
                  [{{ item.devices[0].getModel(currentUser) }}]
                </span>
                <span v-else>
                  {{ getDevicesLabel(item.devices) }}
                </span>
              </div>
              <div v-if="item.devices.some(device => !canUpdateDevice(device))" class="text-orange-9">
                Remote updates not supported on this firmware
              </div>
              <sc-tooltip
                :text="item.devices.map(d => `${getDeviceLabel(d)} [${d.getModel(currentUser)}]`).join(', ')">
              </sc-tooltip>
            </td>
          </tr>
        </tbody>
      </q-markup-table>

      <div v-if="!isBusy && hasApplicableFirmwares && hasUpdatableDevices" class="q-mt-md">
        <div>
          Schedule the installation of the new firmware on
          {{ getDevicesLabel(updatableDevices) }}:
        </div>

        <q-select square outlined class="q-mt-sm" label="Firmware to install"
          v-model="firmwareVersion" :options="firmwareVersionOptions" emit-value map-options
          option-value="value" option-label="label">
        </q-select>

        <q-select square outlined class="q-mt-md" label="Run the update" v-model="deferBy"
          :options="deferItems" emit-value map-options option-value="value" option-label="label">
        </q-select>

        <div v-if="scheduleTime" class="text-body2 text-orange q-mt-sm">
          The update will be executed {{ scheduleTime }}
        </div>
      </div>

      <div v-if="!isBusy && hasScheduledUploads">
        <div class="orange-9">{{ count(scheduledUploads, 'scheduled update') }}:</div>

        <q-markup-table class="uploads q-mt-sm" flat bordered square separator="cell" wrap-cells>
          <tbody>
            <tr v-for="device in scheduledUploads" :key="device.id">
              <td>
                <q-icon :class="device.updateStatus.inProgress ? 'rotate' : ''"
                  :name="device.updateStatus.inProgress ? 'motion_photos_on' : 'update'" size="sm"
                  color="green-8">
                  <sc-tooltip v-if="device.updateStatus.inProgress">
                    The update is currently in progress
                  </sc-tooltip>
                  <sc-tooltip v-else-if="device.updateStatus.onUserRequest">
                    The update will be sent when user requests it from the device
                  </sc-tooltip>
                  <sc-tooltip v-else-if="device.updateStatus.whenConnected">
                    The update will be sent when device comes online
                  </sc-tooltip>
                </q-icon>
              </td>
              <td>
                <div class="row items-center">
                  <span>
                    Firmware v.{{ device.updateStatus.firmwareVersion }}
                  </span>
                  <q-icon name="chevron_right" size="xs" class="q-ml-sm q-mr-sm"></q-icon>
                  <span>{{ getDeviceLabel(device) }}</span>
                </div>
              </td>
              <td style="width: 48px; text-align: center;">
                <q-btn round flat dense unelevated icon="close" color="red"
                  @click="cancelUploadJob(device.updateStatus.jobId)">
                  <sc-tooltip>Cancel the update</sc-tooltip>
                </q-btn>
              </td>
            </tr>
          </tbody>
        </q-markup-table>

        <div class="text-orange-8 q-mt-sm">
          All pending updates will be cancelled when you schedule another update.
        </div>
      </div>

      <div v-else-if="hasAvailableFirmwares && !hasApplicableFirmwares && hasUpdatableDevices"
        class="text-orange-8">
        Cannot find firmware which could be applied to the current selection.
      </div>

    </q-form>

    <template #buttons>
      <q-btn unelevated dense label="Updates" no-caps icon="motion_photos_on"
        :to="{ name: 'device-updates', query: { tab: 'upload-jobs' } }">
        <sc-tooltip text="Go to firmware upload jobs"></sc-tooltip>
      </q-btn>
      <q-btn v-if="canManageFirmware" unelevated dense no-caps label="Firmwares" icon="memory"
        :to="{ name: 'device-updates', query: { tab: 'firmware' } }">
        <sc-tooltip text="Go to firmware repository"></sc-tooltip>
      </q-btn>
      <q-btn unelevated dense label="Change Log" no-caps icon="list"
        @click="showFirmwareChangeLog()">
        <sc-tooltip text="Show firmware change log to learn more about the recent firmware updates">
        </sc-tooltip>
      </q-btn>
    </template>
  </sc-device-action>
</template>

<style lang="scss" scoped>
.versions,
.uploads {
  th {
    text-align: left;
  }

  td:first-child {
    max-width: 80px;
    width: 80px;
  }
}
</style>
