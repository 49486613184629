import { dispatch, isRoute } from '@stellacontrol/client-utilities'

/**
 * Prepares the data required by premium-services-audit view
 */
export async function resolve ({ from }) {
  // Load current organization's pricelists and wallet,
  // if not already on the premium subscriptions page
  await dispatch('requireOrganizations')
  if (!isRoute(from, 'premium-services-audit')) {
    await dispatch('initializeServiceManagement')
  }

  return await dispatch('initializePremiumServicesAuditView')
}
