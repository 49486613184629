import { startOfDay } from 'date-fns'
import { Log } from '@stellacontrol/utilities'
import { Notification, Confirmation } from '@stellacontrol/client-utilities'
import { ServiceManagementAPI } from '@stellacontrol/client-api'
import { Pricelist, getDevicesDescription, PremiumServiceSubscription } from '@stellacontrol/model'

export const actions = {
  /**
   * Initialize the service-management applet
   */
  async initializeApplet () {
  },

  /**
   * Initializes service management applet
   * @param {Boolean} force If true, service management module is reinitialized
   * even if it was already done before
   */
  async initializeServiceManagement ({ commit, dispatch, getters, state }, { force } = {}) {
    if (state.isInitialized && !force) {
      return
    }

    const { organizationGuardian, pricelists } = getters
    const devices = await dispatch('requireDevices')

    try {
      // Check if organization is a bank, a reseller or a regular customer
      const isCustomer = organizationGuardian.mustUse('premium-services-buy')
      const isReseller = organizationGuardian.canUse('premium-services-sell')
      const isBank = organizationGuardian.isBank

      // If reseller with rights to sell premium features,
      // retrieve his customer hierarchy
      const customers = organizationGuardian.canUseAny(['premium-services-sell', 'child-organizations'])
        ? await dispatch('getServiceManagementCustomers')
        : null

      // Fetch organization's wallet and pricelists
      const wallet = await ServiceManagementAPI.getWallet()
      commit('initializeServiceManagement', { customers, isBank, isCustomer, isReseller, wallet, pricelists, devices })
      commit('updateGuardian', { wallet })

    } catch (error) {
      Log.error('Service Management applet cannot be initialized')
      Log.exception(error)
    }
  },

  /**
   * Retrieves hierarchy of customers for service management applet
   */
  async getServiceManagementCustomers ({ commit, getters }) {
    const customers = getters.organizationHierarchy
    commit('updateServiceManagement', { customers })
    return customers
  },

  /**
   * Retrieves the pricelists
   */
  async getPricelists ({ commit }) {
    const pricelists = await ServiceManagementAPI.getPricelists() || []
    commit('storePricelists', { pricelists })
    return pricelists
  },

  /**
   * Retrieves the pricelists if not yet done
   */
  requirePricelists ({ state, dispatch }) {
    return state.pricelists || dispatch('getPricelists')
  },

  /**
   * Returns all premium services
   */
  async getPremiumServices () {
    const premiumServices = await ServiceManagementAPI.getPremiumServices() || []
    return premiumServices
  },

  /**
   * Creates and returns a new pricelist
   * @param organization Organization to which the pricelist belongs.
   * If not specified, current organization is assumed.
   * @param isMain Mark the pricelist as main
   */
  async newPricelist ({ getters }, { organization, isMain } = {}) {
    organization = organization || getters.currentOrganization
    const pricelist = new Pricelist({
      organizationId: organization.id,
      isMain
    })
    return pricelist
  },

  /**
   * Saves a pricelist
   * @param silent If true, no notifications are displayed
   * @param pricelist Pricelist to save
   */
  async savePricelist ({ commit }, { pricelist, silent } = {}) {
    pricelist = await ServiceManagementAPI.savePricelist({ pricelist })
    if (pricelist) {
      commit('storePricelist', { pricelist })
      Notification.success({ message: `Pricelist ${pricelist.name} has been saved.`, silent })
    }
  },

  /**
   * Removes a pricelist
   * @param pricelist Pricelist to remove
   * @param confirm If true, no confirmation is asked
   */
  async removePricelist ({ commit }, { pricelist, confirm } = {}) {
    const yes = await Confirmation.ask({
      title: 'Delete pricelist?',
      message: `Do you want to permanently delete pricelist ${pricelist.name}?`,
      confirm
    })

    if (yes) {
      await ServiceManagementAPI.removePricelist({ pricelist })
      commit('removePricelist', { pricelist })
      Notification.success({ message: `Pricelist ${pricelist.name} has been removed.`, silent: !confirm })
    }
  },

  /**
   * Returns organization's wallet
   * @param {Organization} organization Organization. If not specified,
   * current organization's wallet will be retrieved
   * @returns {Promise<Wallet>}
   */
  async getWallet ({ getters }, { organization } = {}) {
    return ServiceManagementAPI.getWallet({
      organization: organization || getters.currentOrganization
    })
  },

  /**
   * Updates changes to my wallet
   * @param {Wallet} wallet Wallet of the current organization
   */
  updateMyWallet ({ commit }, { wallet } = {}) {
    commit('storeMyWallet', { wallet })
  },

  /**
   * Refreshes wallet of the current organization
   */
  async refreshMyWallet ({ commit, getters }) {
    const wallet = await ServiceManagementAPI.getWallet({
      organization: getters.currentOrganization
    })
    commit('storeMyWallet', { wallet })
    return wallet
  },

  /**
   * Returns the specified premium service
   * @param {String} id Identifier of a premium service to fetch
   * @returns {Promise<PremiumService>}
   */
  async getPremiumService ({ commit }, { id, store = true } = {}) {
    const premiumService = await ServiceManagementAPI.getPremiumService({ id })
    if (premiumService) {
      if (store) {
        commit('storePremiumService', { premiumService })
      }
      return premiumService
    }
  },

  /**
   * Assigns free-of-charge premium service to specified devices
   * @param {Array[Device]} devices Devices to assign the service to
   * @param {PremiumService} service Service to assign
   * @param {Boolean} silent If true, no UI messages are shown
   */
  async assignPremiumServiceToDevices ({ dispatch }, { devices, service, silent } = {}) {
    if (devices && devices.length > 0 && service) {

      const message = `Assigning premium service to ${getDevicesDescription(devices)}...`
      const successMessage = `Premium service has been assigned to ${getDevicesDescription(devices)}`
      await dispatch('busy', { message, silent })
      await ServiceManagementAPI.assignPremiumServiceToDevices({ devices, service })
      await dispatch('done', { message: successMessage, silent })
    }
  },

  /**
   * Sells premium service to specified customer
   * @param {organization} customer Customer to sell the service to
   * @param {Wallet} wallet Customer wallet
   * @param {PremiumService} service Service to sell
   * @param {Array[Device]} devices Devices to assign the service to
   * @param {Date} startsAt Start date for the subscription
   * @param {Boolean} silent If true, no UI messages are shown
   * @param {String} notes Additional notes
   */
  async sellPremiumService ({ commit, dispatch, getters }, { customer, wallet, devices, service, silent, startsAt, notes } = {}) {
    const { currentOrganization } = getters
    if (customer && wallet && service) {
      const isBuying = currentOrganization.id === customer.id
      const toDevices = devices && devices.length > 0
      const message = isBuying
        ? 'Activating premium service, please wait ...'
        : `Selling premium service to ${customer.name} ...`
      const successMessage = toDevices
        ? `Premium service has been activated on ${getDevicesDescription(devices)}`
        : 'Premium service has been activated'

      const subscription = new PremiumServiceSubscription({
        service,
        serviceId: service.id,
        price: service.price,
        devices: toDevices ? devices.map(d => d.serialNumber) : undefined,
        startsAt: startOfDay(startsAt, new Date()),
        duration: 1,
        walletId: wallet.id,
        notes
      })

      await dispatch('busy', { message, silent })
      try {
        const result = await ServiceManagementAPI.savePremiumServiceSubscription({ wallet, subscription })

        if (result?.transaction?.after) {
          commit('storeCustomerWallet', { customer, wallet: result?.transaction?.after })
          if (isBuying) {
            commit('storeMyWallet', { wallet: result?.transaction?.after })
          }
          await dispatch('done', { message: successMessage, silent })
        } else {
          await dispatch('done', { silent })
        }
      } catch (error) {
        Notification.error({ message: error.message })
        await dispatch('done', { silent })
      }

      // Refresh my wallet - could be, that my wallet was debited when
      // selling to customers who don't have tokens
      await dispatch('refreshMyWallet')
    }
  },

  /**
   * Clears free-of-charge premium services currently assigned to specified devices
   * @param {Array[Device]} devices Devices to clear the service from
   * @param {Boolean} silent If true, no UI messages are shown
   */
  async clearPremiumServicesOnDevices ({ commit, dispatch }, { devices, silent } = {}) {
    if (devices && devices.length > 0) {

      const message = `Removing premium service assigned to ${getDevicesDescription(devices)}...`
      const successMessage = `Premium service has been removed on ${getDevicesDescription(devices)}`
      await dispatch('busy', { message, silent })
      await ServiceManagementAPI.clearPremiumServicesOnDevices({ devices })
      await dispatch('done', { message: successMessage, silent })
      commit('clearPremiumServicesOnDevices', { devices })
    }
  },

  /**
   * Starts free-of-charge premium services on specified devices
   * @param {Array[Device]} devices Devices
   * @param {Date} startsAt Optional alternative date when subscriptions should start
   * @param {String} details Optional description of circumstances under which subscriptions are started, for audit purposes
   * @param {Boolean} silent If true, no UI messages are shown
   */
  async startDeviceSubscriptions ({ dispatch }, { devices, startsAt, details, silent } = {}) {
    if (devices && devices.length > 0) {
      const devicesLabel = `${devices.length === 1 ? 'device' : 'devices'} ${getDevicesDescription(devices)}`
      const message = `Activating premium service on ${devicesLabel}...`
      const successMessage = `Premium service has been activated on ${devicesLabel}`
      await dispatch('busy', { message, silent })
      const subscriptions = await ServiceManagementAPI.startDeviceSubscriptions({ devices, startsAt, details })
      await dispatch('done', { message: successMessage, silent })
      return subscriptions
    }
  },

  /**
   * Removes devices from specified premium subscription
   * @param {PremiumServiceSubscription} subscription Premium subscription
   * @param {Array[Devices]} devices Devices to remove from premium subscription
   * @param {Boolean} silent If true, no UI messages are shown
   * @returns {PremiumServiceSubscription} Modified subscription
   */
  async unsubscribeDevices ({ commit, dispatch }, { subscription, devices, silent } = {}) {
    if (subscription && devices && devices.length > 0) {
      const devicesLabel = `${devices.length === 1 ? 'device' : 'devices'} ${getDevicesDescription(devices)}`
      const message = `Stopping ${subscription.service.label} on ${devicesLabel} ...`
      const successMessage = `${subscription.service.label} has been stopped on ${devicesLabel}`

      await dispatch('busy', { message, silent })
      await ServiceManagementAPI.unsubscribeDevices({ subscription, devices })
      commit('unsubscribeDevices', { subscription, devices })
      await dispatch('done', { message: successMessage, silent })
    }
  }
}
