import { state, getters } from './list.state'
import { mutations } from './list.mutations'
import { actions } from './list.actions'

export default {
  state,
  getters,
  mutations,
  actions
}
