<script>
import ParameterEditor from './parameter-editor'

export default {
  mixins: [
    ParameterEditor
  ],

  computed: {
    items () {
      return this.values.map((value, index) => ({
        value,
        label: this.labels[index],
        icon: value ? 'directions_boat' : 'apartment'
      }))
    }
  }
}
</script>

<template>
  <div class="parameter-editor" v-if="hasValue">

    <sc-parameter-value
      label="Device Mode"
      :reported="reported"
      :custom="custom"
      :format="value => value == null ? '-' : (value ? 'Ship' : 'Land')"
    />

    <sc-parameter-selector
      :items="items"
      label="Device Mode"
      :allow-clear="isCustomized"
      @select="value => change(value)"
      @clear="clear()"
    />

  </div>
</template>
