import { stringCompare, numberCompare, groupItems } from '@stellacontrol/utilities'
import { OrganizationLevel } from './organization-level'
import { OrganizationProfile, User } from './organization-entities'
import { Permission } from '../security/permission'

/**
 * A tree representing organization hierarchy
 */
export class OrganizationHierarchy {
  constructor (data = {}) {
    Object.assign(this, data)

    if (data.hierarchyLevel === undefined) {
      this.hierarchyLevel = 0
    }

    // Normalize
    if (this.profile) {
      this.profile = new OrganizationProfile(this.profile)
    }
    if (this.permissions) {
      this.permissions = this.permissions.map(p => new Permission(p))
    }
    if (this.administrators) {
      this.administrators = this.administrators.map(administrator => new User(administrator))
    }
    if (this.organizations) {
      this.organizations = this.organizations.map(organization =>
        new OrganizationHierarchy({
          ...organization,
          parentOrganizationId: this.id,
          parentOrganization: this,
          hierarchyLevel: this.hierarchyLevel + 1
        }))
    }
  }

  /**
   * Level in the hierarchy
   * @type {Number}
   */
  hierarchyLevel

  /**
   * Organization identifier
   * @type {String}
   */
  id

  /**
   * Unique key, for lists etc
   */
  get key () {
    return `${this.id}-${this.hierarchyLevel}`
  }

  /**
   * Parent organization identifier
   * @type {String}
   */
  parentOrganizationId

  /**
   * Parent organization
   * @type {Organization}
   */
  parentOrganization

  /**
   * Organization name
   * @type {String}
   */
  name

  /**
   * Organization level
   * @type {OrganizationLevel}
   */
  level

  /**
   * Organization icon
   * @type {String}
   */
  icon

  /**
   * Organization profile identifier
   * @type {String}
   */
  profileId

  /**
   * Organization profile details
   * @type {OrganizationProfile}
   */
  profile

  /**
   * A collection of child organizations
   * @type {Array[Organization]}
   */
  organizations

  /**
   * Admin users of the organization
   * @type {Array[User]}
   */
  administrators

  /**
   * Organization details
   * @type {Organization}
   */
  details

  /**
   * Default admin user of the organization.
   * @type {User}
   */
  get administrator () {
    return (this.administrators || []).find(a => a.isEnabled)
  }

  /**
   * Identifier of the default admin user of the organization.
   * @type {String}
   */
  get administratorId () {
    return (this.administrator || {}).id
  }

  /**
   * Number of devices owned by the organization
   * @type {Number}
   */
  deviceCount

  /**
   * Organization permissions
   * @type {Array[Permission]}
   */
  permissions

  /**
   * Returns true if organization is allowed to use the specified feature
   * @param {String} name Feature name
   */
  canUse (name) {
    const permission = this.permissions?.find(p => p.featureName === name)
    return Boolean(permission?.canUse)
  }

  /**
   * Indicates that the organization has child organizations
   * @type {Boolean}
   */
  get hasChildOrganizations () {
    return (this.organizations || []).length > 0
  }

  /**
   * The number of child organizations
   * @type {Number}
   */
  get childOrganizationCount () {
    return (this.organizations || []).length
  }

  /**
   * Indicates that the organization has parent organization
   * @type {Boolean}
   */
  get hasParent () {
    return Boolean(this.parentOrganizationId)
  }

  /**
   * Indicates whether profile represents a super organization
   * @type {Boolean}
   */
  get isSuperOrganization () {
    return this.level === OrganizationLevel.SuperOrganization
  }

  /**
   * Indicates whether profile represents a reseller organization which can have child organizations
   * @type {Boolean}
   */
  get isResellerOrganization () {
    return this.level === OrganizationLevel.ResellerOrganization
  }

  /**
   * Indicates whether profile represents a regular organization
   * @type {Boolean}
   */
  get isRegularOrganization () {
    return this.level === OrganizationLevel.Organization
  }

  /**
   * Indicates whether profile represents a guest organization with very limited functionality
   * @type {Boolean}
   */
  get isGuestOrganization () {
    return this.level === OrganizationLevel.GuestOrganization
  }

  /**
   * Indicates whether profile represents a shipping company with no access to system or devices,
   * present only to capture the realities of shipping devices between organizations
   * @type {Boolean}
   */
  get isShippingCompany () {
    return this.level === OrganizationLevel.ShipppingCompany
  }

  /**
   * Organization wallet balance
   * @type {Number}
   */
  balance

  /**
   * Indicates that organization is a premium customer,
   * obliged to purchase subscriptions to use premium features
   * @type {Boolean}
   */
  get isPremiumCustomer () {
    return this.canUse('premium-services-buy')
  }

  /**
   * Indicates that organization has premium customers,
   * obliged to purchase subscriptions to use premium features
   * @type {Boolean}
   */
  get hasPremiumCustomers () {
    return this.organizations?.some(o => o.canUse('premium-services-buy'))
  }

  /**
   * Indicates that organization is a premium reseller,
   * able to sell subscriptions to his customers
   * @type {Boolean}
   */
  get isPremiumReseller () {
    return this.canUse('premium-services-sell')
  }

  /**
   * Indicates that organization is a token bank
   * @type {Boolean}
   */
  get isBank () {
    return this.level === OrganizationLevel.SuperOrganization || this.canUse('premium-services-bank')
  }

  /**
   * Traverses the hierarchy calling the specified callback on each item.
   * If callback returns something, traversing is stopped and the result is returned.
   * @param {Function<Organization, Number, Boolean>} callback Callback to call, can be an async function, receiving organization and hierarchy level.
   * If callback returns a truthy value, traversal is interrupted and the value is returned.
   * @param {Organization} root Root to start the search from. If not specified, we start with this hierarchy.
   * @param {Number} level Hierarchy level
   * @returns {Promise<Organization>}
   */
  async traverseAsync (callback, root, level = 0) {
    if (!callback) throw new Error('Callback is required')
    const organization = (root || this)
    if (await callback(organization, level)) {
      return organization
    } else {
      for (const child of organization.organizations || []) {
        const result = await this.traverseAsync(callback, child, level + 1)
        if (result) {
          return result
        }
      }
    }
  }

  /**
   * Traverses the hierarchy calling the specified callback on each item.
   * If callback returns something, traversing is stopped and the result is returned.
   * @param {Function<Organization>} callback Callback to call, can be an async function, receiving organization and hierarchy level
   * If callback returns a truthy value, traversal is interrupted and the value is returned.
   * @param {Organization} root Root to start the search from. If not specified, the entire hierarchy will be traversed.
   * @param {Number} level Hierarchy level
   * @returns {Organization}
   */
  traverse (callback, root, level = 0) {
    if (!callback) throw new Error('Callback is required')
    const organization = (root || this)
    if (callback(organization, level)) {
      return organization
    } else {
      for (const child of organization.organizations || []) {
        const result = this.traverse(callback, child, level + 1)
        if (result) {
          return result
        }
      }
    }
  }

  /**
   * Finds an organization in the hierarchy
   * @param {String} id Organization identifier
   * @returns {Organization}
   */
  find (id) {
    if (id != null) {
      return this.findBy(organization => organization.id === id)
    }
  }

  /**
   * Finds an organization in the hierarchy using the specified predicate
   * @param {Function<Organization>} predicate Predicate to check
   * @param {Organization} root Root to start the search from. If not specified, the entire hierarchy will be traversed.
   * @returns {Organization}
   */
  findBy (predicate, root) {
    if (!predicate) throw new Error('Predicate is required')
    const organization = (root || this)
    if (predicate(organization)) {
      return organization
    } else {
      for (const child of organization.organizations || []) {
        const found = this.findBy(predicate, child)
        if (found) {
          return found
        }
      }
    }
  }

  /**
   * Finds an entity in the hierarchy matching the specified predicate
   * @param {Function<Organization|Place|Device, Boolean>} predicate Predicate to determine the entity to find
   * @param {Organization|Place|Device} root Entity to start search from. If not specified, the entire hierarchy will be traversed.
   * @returns {Organization|Place|Device}
   */
  findEntity (predicate, root) {
    let entity
    root = root || this

    if (root) {
      if (predicate(root)) {
        entity = root

      } else {
        const children = [
          ...root.organizations || [],
          ...root.places || [],
          ...root.devices || []
        ]

        for (const child of children) {
          entity = this.findEntity(predicate, child)
          if (entity) {
            break
          }
        }
      }
    }

    return entity
  }

  /**
   * Finds a parent organization of the specified organization.
   * If predicate is specified, search upwards continuies until the predicate matches.
   * @param {String} id Organization identifier
   * @param {Function<Organization>} predicate Predicate to check
   * @returns {Organization}
   */
  findParentOf (id, predicate) {
    let parent = (this.find(id) || {}).parentOrganization
    if (predicate) {
      while (parent) {
        if (predicate(parent)) {
          return parent
        } else {
          parent = parent.parentOrganization
        }
      }
    } else {
      return parent
    }
  }

  /**
   * Finds a reseller organization for the specified organization.
   * If no reseller found, super organization will be seen as reseller.
   * @param {String} id Organization identifier
   * @returns {Organization}
   */
  findResellerOf (id) {
    return this.findParentOf(id, parent =>
      parent.level === OrganizationLevel.ResellerOrganization || parent.level === OrganizationLevel.SuperOrganization)
  }

  /**
   * Returns the entire chain of parents above the specified organization
   * @param {String} id Organization identifier
   * @returns {Array[Organization]}
   */
  getParentsOf (id) {
    const result = []

    let self = this.find(id)
    if (self) {
      let parent = this.find(self.parentOrganizationId)
      while (parent) {
        result.push(parent)
        parent = this.find(parent.parentOrganizationId)
      }
    }

    return result
  }

  /**
   * Returns the entire chain of children below the specified organization
   * @param {String} id Organization identifier
   * @returns {Array[Organization]}
   */
  getChildrenOf (id, result) {
    const organization = this.find(id)
    if (organization) {
      if (!result) result = []
      for (const child of organization.organizations || []) {
        result.push(child)
        this.getChildrenOf(child, result)
      }
    }
    return result
  }

  /**
   * Returns true if specified organization is a direct child of the specified organization
   * @param {Organization} parent Parent organization to check
   * @param {Organization} child Child organization to check
   * @returns {Boolean}
   */
  isChildOf ({ id: parentId }, { id: childId }) {
    const child = this.find(childId)
    const parent = this.find(parentId) || {}
    return child && child.parentOrganizationId === parent.id
  }

  /**
   * Returns true if organization is a direct parent of the specified organization
   * @param {Organization} parent Parent organization to check
   * @param {Organization} child Child organization to check
   * @returns {Boolean}
   */
  isParentOf ({ id: parentId }, { id: childId }) {
    const child = this.find(childId)
    const parent = this.find(parentId)
    return parent && child && parent.id === child.parentOrganizationId
  }

  /**
   * Returns true if organization is a child of the specified organization,
   * either directly or indirectly
   * @param {Organization} child Child organization to check
   * @param {Organization} parent Parent organization to check
   * @returns {Boolean}
   */
  isDescendantOf ({ id: childId }, { id: parentId }) {
    let child = this.find(childId) || {}
    const parent = this.find(parentId)
    while (parent && child.parentOrganization) {
      if (child.parentOrganizationId === parent.id) {
        return true
      } else {
        child = child.parentOrganization
      }
    }
  }

  /**
   * Returns true if organization is an ancestor of the specified organization,
   * either directly or indirectly
   * @param {Organization} parent Parent organization to check
   * @param {Organization} child Child organization to check
   * @returns {Boolean}
   */
  isAncestorOf ({ id: parentId }, { id: childId }) {
    return this.isDescendantOf({ id: childId }, { id: parentId })
  }

  /**
   * Finds an entity in device hierarchy, returns a path of entities leading to it
   * @param {String} id Entity identifier
   * @returns {Array[Organization|Place|Device]}
   */
  getEntityPath (id, path = [], root) {
    let result
    root = root || this

    if (root.id === id) {
      path.push(root)
      result = path
    }

    if (!result) {
      const children = [
        ...root.organizations || [],
        ...root.places || [],
        ...root.devices || []
      ]

      for (const child of children) {
        result = this.getEntityPath(id, [...path, root], child)
        if (result) break
      }
    }

    return result
  }

  /**
   * Finds all devices belonging under the specified entity in device hierarchy
   * @param {Organization|Place|Device} root Entity to start search from.
   * If not specified, the entire hierarchy will be traversed.
   * @returns {Array[Device]}
   */
  getDevicesAt (root, entities = []) {
    root = root || this

    if (root.serialNumber) {
      entities.push(root)

    } else {
      const children = [
        ...root.organizations || [],
        ...root.places || [],
        ...root.devices || []
      ]

      for (const child of children) {
        this.getDevicesAt(child, entities)
      }
    }

    return entities
  }

  /**
   * Adds the specified organization to hierarchy
   * @param {Organization} organization Organization to remove
   * @returns {OrganizationHierarchy} Added hierarchy element
   */
  add (organization) {
    if (organization) {
      const exists = this.find(organization.id)
      if (!exists) {
        const parent = this.find(organization.parentOrganizationId)
        if (parent) {
          const child = OrganizationHierarchy.fromOrganization(organization)
          child.hierarchyLevel = parent.hierarchyLevel + 1
          child.parentOrganization = parent
          parent.organizations = [...parent.organizations, child]
          return child
        }
      }
    }

  }

  /**
   * Removes the specified organization from hierarchy
   * @param {Organization} organization Organization to remove
   * @returns {OrganizationHierarchy} Modified hierarchy
   */
  remove (organization) {
    if (organization) {
      const parent = this.findParentOf(organization.id)
      if (parent) {
        parent.organizations = parent.organizations.filter(o => o.id !== organization.id)
      }
    }

    return this
  }

  /**
   * Re-creates hierarchy by assigning `parentOrganization` properties of children.
   * Useful after deserializing the instance from JSON.
   */
  recreateHierarchy () {
    for (const organization of this.organizations || []) {
      organization.parentOrganization = this
      organization.recreateHierarchy()
    }
  }

  /**
   * Creates a hierarchy from a flat list of hierarchy items
   * @param {Array{Organization}} items Organizations
   * @param {Organization} parent Hierarchy root
   * @returns {OrganizationHierarchy}
   */
  static fromList (root, items) {
    if (root && items) {
      // If root included in the items, replace as the one provided as parameter
      // is likely the right one to use
      const all = [root, ...items.filter(item => item.id !== root.id)]

      // Bucket items by parent, assign child organizations
      const dictionary = groupItems(all, item => item.parentOrganizationId)
      for (const item of all) {
        item.organizations = dictionary[item.id]
      }

      // Fix hierarchy level for all items
      root.traverse((item, hierarchyLevel) => {
        item.hierarchyLevel = hierarchyLevel
      })

      return root
    }
  }

  /**
   * Creates a hierarchy from a flat list of organizations
   * @param {Array[Organization]} organizations Organizations
   * @param {Organization} parentOrganization Hierarchy root
   * @param {Boolean} full If true, the hierarchy will be built from super-organization,
   * using the parent organizations available in `parentOrganization` parameter
   * @returns {OrganizationHierarchy}
   */
  static fromOrganizations (organizations, parentOrganization, full) {
    if (organizations && parentOrganization) {
      if (full) {
        organizations = [
          ...parentOrganization.parentOrganizations,
          ...organizations
        ]
      }
      const root = OrganizationHierarchy.fromOrganization(parentOrganization)
      const items = organizations
        .map(o => this.fromOrganization(o))
        .filter(i => i)
      const hierarchy = this.fromList(root, items)
      return hierarchy
    }
  }

  /**
   * Creates a hierarchy item from organization
   * @param {Organization} organization Organization
   * @param {Number} hierarchyLevel Hierarchy level
   * @returns {OrganizationHierarchy}
   */
  static fromOrganization (organization, hierarchyLevel = 0) {
    if (organization) {
      const { icon, profile } = organization
      if (profile) {
        return new OrganizationHierarchy({
          ...organization,
          details: organization,
          level: profile.level,
          icon: icon || profile.icon,
          hierarchyLevel
        })
      }
    }
  }

  /**
   * Returns organization hierarchy converted to a flat list,
   * sorted according to the hierarchy and rank
   * @param {Boolean} includeMe If true, hierarchy root is also added
   * @returns {Array[Organization]}
   */
  toList (includeMe = true) {
    const list = []

    const addItemAndChildren = (item, include) => {
      if (include) {
        list.push(item)
      }
      const children = [...item.organizations || []]
      children.sort((a, b) => {
        const result = -numberCompare(getItemRank(a), getItemRank(b))
        return result === 0 ? stringCompare(a.name, b.name) : result
      })
      for (const child of children) {
        addItemAndChildren(child, true)
      }
    }

    addItemAndChildren(this, includeMe)

    return list
  }

  /**
   * Overrides serialization to prevent circular JSON
   * with parentOrganization property
   */
  toJSON () {
    const result = { ...this, parentOrganization: undefined }
    return result
  }
}

/**
 * Returns a number representing hierarchy item rank
 * @param item Item to rank
 * @param currentOrganization Currently logged in organization, which if specified, has the highest rank
 */
function getItemRank (item, currentOrganization) {
  if (currentOrganization && item.id === currentOrganization.id) return 100
  if (item.level === OrganizationLevel.SuperOrganization) return 90
  if (item.level === OrganizationLevel.ResellerOrganization) return 80
  if (item.level === OrganizationLevel.RegularOrganization) return 50
  if (item.level === OrganizationLevel.ShippingCompany) return 40
  return 0
}
