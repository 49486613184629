<script>
import { mapActions, mapState } from 'vuex'
import { getDeviceLabel } from '@stellacontrol/model'
import { sameArrays } from '@stellacontrol/utilities'
import { ViewMixin, Viewport } from '@stellacontrol/client-utilities'
import { Secure } from '@stellacontrol/security-ui'
import DevicesTree from '../../components/device-tree/device-tree.vue'
import DeviceHistoryPanel from './device-history-panel.vue'
import { resolve } from './device-history.resolve'

const name = 'device-history'


export default {
  mixins: [
    ViewMixin,
    Secure
  ],

  components: {
    'sc-device-tree': DevicesTree,
    'sc-device-history': DeviceHistoryPanel
  },

  data () {
    return {
      name,
      // Indicates whether the component is still initializing
      isInitializing: true
    }
  },

  computed: {
    ...mapState({
      // Hierarchy of organizations, places and devices
      hierarchy: state => state.deviceHistoryView.hierarchy,
      // Currently viewed organization or organization of the viewed place/device
      viewedOrganization: state => state.deviceHistoryView.organization,
      // Currently viewed organization's guardian
      viewedOrganizationGuardian: state => state.deviceHistoryView.organizationGuardian,
      // Currently viewed device
      device: state => state.deviceHistoryView.device,
      // Device tree state
      treeState: state => state.deviceHistoryView.tree,
      // Selected period
      period: state => state.deviceHistoryView.filter.period,
      maxDays: state => state.deviceHistoryView.filter.maxDays,
      // Parameters to show
      parameters: state => state.deviceHistoryView.filter.parameters,
      // Extras to show, such as alerts, firmware updates etc.
      extras: state => state.deviceHistoryView.filter.extras
    }),

    // View title
    title () {
      const { device, getViewTitle } = this
      const title = getViewTitle(name)
      return device
        ? `${title}: ${getDeviceLabel(device)}`
        : title
    },

    // View breadcrumbs
    breadcrumbs () {
      const { device, viewedOrganization, getViewTitle } = this
      return [
        {
          name: 'home',
          title: getViewTitle('home')
        },
        {
          name: 'device-history',
          title: getViewTitle(name),
          route: 'device-history'
        },
        viewedOrganization && device
          ? {
            title: viewedOrganization.name
          }
          : null,
        device
          ? {
            title: getDeviceLabel(device)
          }
          : null
      ]
    },

    // Indicates whether the tree should be autocollapsed,
    // once a selection has been made.
    // This happens in mobile view.
    autoCollapseTree () {
      return Viewport.isSmallScreen
    },

    // Indicates whether asset tree is currently visible
    isTreeMinimized () {
      return this.treeState.isMinimized
    },

    // Indicates whether user can navigate to device dashboard
    canViewDashboard () {
      const { isInitializing, device, viewedOrganizationGuardian, currentOrganizationGuardian } = this
      return !isInitializing &&
        device &&
        viewedOrganizationGuardian?.canDeviceUse('live-status', device.serialNumber, currentOrganizationGuardian)
    },

    // Indicates whether user can navigate to inventory and see the selected device there
    canViewInventory () {
      return !this.isInitializing && this.device && this.canUse('inventory')
    },

    // Triggered when building the tree, checks if device
    // has premium subscription allowing to see its history
    isDeviceAllowed () {
      const { device, viewedOrganizationGuardian, currentOrganizationGuardian } = this
      const isDeviceAllowed = currentOrganizationGuardian.requiresPremiumSubscription('history-graph')
        ? viewedOrganizationGuardian.canDeviceUse('history-graph', device.serialNumber, currentOrganizationGuardian)
        : currentOrganizationGuardian.canUse('history-graph')
      return isDeviceAllowed
    },

    // Checks whether history panel is not visible
    // because the device does not qualify
    cantSeeHistory () {
      return !this.isInitializing && !this.isDeviceAllowed
    }
  },

  methods: {
    ...mapActions([
      'gotoRoute',
      'storeExpandedDeviceHistoryItems',
      'storeDeviceHistoryTreeState',
      'storeDeviceHistoryFilter'
    ]),

    getDeviceLabel,

    // Triggered when some nodes in the tree have been expanded or collapsed
    treeExpanded (expanded = []) {
      if (!sameArrays(expanded, this.treeState.expanded)) {
        this.storeExpandedDeviceHistoryItems({ expanded })
      }
    },

    // Triggered when devicehas been selected
    treeSelected (node) {
      if (node) {
        const { id, serialNumber } = node
        if (id !== this.device?.id && serialNumber) {
          const params = { device: serialNumber }
          this.gotoRoute({ name: 'device-history', params })
        }
      }
    },


    // Triggered when history panel has been initialized
    async historyInitialized () {
      this.isInitializing = false
    },

    // Triggered when parameters filter has changed in the history panel
    async filterChanged ({ period, parameters, extras } = {}) {
      if (!this.isInitializing) {
        await this.storeDeviceHistoryFilter({ period, parameters, extras })
      }
    },

    // Expands the asset tree
    showTree () {
      this.storeDeviceHistoryTreeState({ isMinimized: false })
    },

    // Collapses the asset tree
    hideTree () {
      this.storeDeviceHistoryTreeState({ isMinimized: true })
    },

    // Triggered when tree has been filtered
    treeFiltered (filter) {
      this.storeDeviceHistoryTreeState({ filter })
    },

    // Resets chart zoom
    resetZoom () {
      this.$refs.history?.resetZoom()
    }
  },

  // Reload data on navigation to another device
  async beforeRouteUpdate (to, from) {
    if (from.params.device !== to.params.device) {
      const result = await resolve({ from, to })
      this.$nextTick(() => {
        this.$refs.history?.populate()
      })
      if (result?.redirectTo) {
        return result.redirectTo
      }
    }
  },

  mounted () {
    this.$nextTick(() => {
      this.$refs.history?.populate()
    })
  }
}
</script>

<template>
  <sc-view :name="name" :title="title" :breadcrumbs="breadcrumbs">

    <template #toolbar>
      <div class="row q-gutter-xs">
        <q-btn label="Reset zoom" unelevated icon="zoom_out_map" :ripple="false"
          v-if="!isInitializing" @click="resetZoom()">
        </q-btn>
        <q-btn label="Open in Inventory" unelevated icon="list_alt" :ripple="false"
          v-if="canViewInventory"
          :to="{ name: 'inventory', query: { selection: device.serialNumber } }">
          <sc-tooltip>
            Open {{ getDeviceLabel(device) }} in the inventory
          </sc-tooltip>
        </q-btn>
        <q-btn v-if="canViewDashboard" label="Show Dashboard" unelevated icon="dashboard"
          :ripple="false"
          :to="{ name: 'device-dashboard', params: { serialNumber: device.serialNumber } }">
          <sc-tooltip>
            Show current status of {{ getDeviceLabel(device) }}
          </sc-tooltip>
        </q-btn>
      </div>
    </template>

    <main class="device-history">
      <section class="tree-container" :class="{ collapsed: isTreeMinimized }"
        v-if="!treeState.isHidden">
        <div v-if="treeState.isMinimized" class="expander row items-center q-pa-xs q-pt-sm">
          <q-btn no-caps no-wrap dense round unelevated :ripple="false" class="text-white q-mt-xs"
            icon="chevron_right" @click.stop="showTree()">
            <sc-tooltip text="Show devices"></sc-tooltip>
          </q-btn>
        </div>

        <sc-device-tree v-else ref="tree" :hierarchy="hierarchy" :allow-checking="false"
          :allowSelectingOrganizations="false" :allowSelectingPlaces="false"
          :initiallyMinimized="treeState.isMinimized" :initially-expanded="treeState.expanded"
          :initiallySelected="treeState.selected" :initial-filter="treeState.filter"
          :initialViewMode="treeState.viewMode" :auto-collapse="autoCollapseTree"
          @minimized="hideTree()" @expanded="expanded => treeExpanded(expanded)"
          @selected="node => treeSelected(node)" @filtered="filter => treeFiltered(filter)">
        </sc-device-tree>
      </section>

      <section class="history-container">
        <div v-if="cantSeeHistory" class="device-not-allowed column items-center justify-center">
          <div class="text-h6 text-grey-9">
            Device history is not available.
          </div>
          <div class="text-grey-7">
            Check whether there is a premium subscription activated on this device.
          </div>
        </div>

        <sc-device-history v-else ref="history" :device="device" :period="period" :maxDays="maxDays"
          max-height="750px" :parameters="parameters" :extras="extras"
          @initialized="historyInitialized" @filter="filterChanged">
        </sc-device-history>
      </section>
    </main>

  </sc-view>
</template>

<style lang='scss' scoped>
.device-history {
  flex: 1;
  display: flex;
  flex-direction: row;
  overflow: hidden;

  .tree-container {
    flex: 0;
    display: flex;
    flex-direction: column;
    border-right: solid #dddddd 1px;

    .expander {
      flex: 1;
      display: flex;
      flex-direction: column;
      background-color: #273163;

      button {
        background-color: transparent;
      }
    }
  }

  .history-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .device-not-allowed {
      flex: 1;
    }
  }
}
</style>
