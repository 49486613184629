<script>
/**
 * Simple wrapper around Quasar q-tab-panels,
 * with sanitized look and CSS styling, to make sure that tab panels
 * gracefully fill their parent container, with eventual overflow.
 */
export default {
  props: {
    // Selected tab passed as v-model
    modelValue: {
      default: ''
    }
  },

  emits: [
    'update:model-value'
  ],

  methods: {
    // Pass on the selected tab
    updateModelValue (value) {
      this.$emit('update:model-value', value)
    }
  }
}
</script>

<template>
  <div class="tabs">
    <q-tabs ref="tabs" v-bind="{ ...$props, ...$attrs }" align="left" inline-label no-caps class="bg-indigo-1 text-grey-9" :model-value="modelValue" @update:model-value="value => updateModelValue(value)">
      <slot>
      </slot>
    </q-tabs>
  </div>
</template>

<style lang='scss' scoped>
.tabs {
  flex: 0;
}
</style>
