<script>
import { mapState, mapGetters } from 'vuex'
import { DeviceConnectionStatus, DeviceConnectionStatusName } from '@stellacontrol/model'
import { Viewport, Animation } from '@stellacontrol/client-utilities'
import DeviceWidget from './device-widget'

const ICON_BUSY = 'change_circle'

export default {
  mixins: [
    DeviceWidget
  ],

  props: {
    // Indicates whether live status is allowed at all on this device
    isLiveStatusAllowed: {
      type: Boolean
    },
    // Name of the status watch process
    name: {
      type: String
    },
    // Icon size
    iconSize: {
      type: String
    }
  },

  data () {
    return {
      // Icon element representing device status
      iconElement: null
    }
  },

  computed: {
    ...mapState({
      // Status watcher clock ticks, used to enforce refreshing of labels such as status age
      ticks: state => state.deviceStatus.ticks
    }),

    ...mapGetters([
      'getStatusListener'
    ]),

    // Device status listener
    listener () {
      return this.getStatusListener(this.name)
    },

    isSmallScreen () {
      return Viewport.isSmallScreen
    },

    // Device connection status
    connectionStatus () {
      const { status } = this
      return (status ? status.connection.status : '') || DeviceConnectionStatus.NeverConnected
    },

    /**
     * Returns true if device is online in heartbeat mode
     */
    isDeviceCommunicating () {
      const { status } = this
      return status?.timings?.isHeartbeat || status?.timings?.isRealTime
    },

    // Indicates whether the device has ever connected to report its status
    hasConnected () {
      const { connectionStatus } = this
      return connectionStatus !== DeviceConnectionStatus.NeverConnected && connectionStatus !== DeviceConnectionStatus.Unknown
    },

    // Indicates a device in ship mode
    isShip () {
      return this.status?.identity?.isShip
    },

    // Indicates that listening to status updates now
    isListening () {
      return this.listener?.isListening
    },

    // Indicates that listening is now suspended
    isSuspended () {
      const { listener } = this
      return listener?.isSuspended
    },

    // Indicates that we're receiving real-time data
    isRealTime () {
      const { listener } = this
      return listener?.isListening
    },

    // Indicates device hasn't reported in a long time
    isLost () {
      return this.status?.connection?.isLost
    },

    // Age of the most recent status
    statusAge () {
      return this.ticks ? this.status?.timings?.statusAge : ''
    },

    // Device status age
    statusAgeString () {
      return this.ticks ? this.status?.timings?.statusAgeString : ''
    },

    // Status title
    title () {
      const { status, isLiveStatusAllowed } = this
      if (isLiveStatusAllowed) {
        return status
          ? status.isConnected ? DeviceConnectionStatusName[DeviceConnectionStatus.Online] : status.label
          : 'Retrieving status ...'
      } else {
        return 'Device status not available'
      }
    },

    // Detailed status description, such as message age
    description () {
      const { isListening, isLiveStatusAllowed, ticks, status } = this
      if (isLiveStatusAllowed && status && ticks >= 0) {
        const {
          timings: { isRealTime, isLost, statusAge, statusAgeString, isHeartbeat },
          connection: { isUnknown, hasNeverConnected }
        } = status
        if (isUnknown) {
          return 'Retrieving status ...'
        } else if (hasNeverConnected) {
          return 'Status unknown'
        } else if (isListening) {
          return statusAge < 3 ? 'Just connected' : statusAgeString
        } else if ((isRealTime || isHeartbeat) && (statusAge > 2)) {
          return statusAgeString
        } else if (isLost) {
          return statusAgeString
        } else {
          return ''
        }
      }
    },

    // Status icon color
    color () {
      const { status, isLiveStatusAllowed } = this
      if (status && isLiveStatusAllowed) {
        return status.connection.color || 'transparent'
      } else {
        return 'grey-7'
      }
    },

    // Icon representing the current status
    icon () {
      const { isLiveStatusAllowed, status } = this
      if (isLiveStatusAllowed) {
        const connectedIcon = 'offline_bolt'
        const disconnectedIcon = 'stop_circle'
        return status
          ? {
            [DeviceConnectionStatus.Online]: connectedIcon,
            [DeviceConnectionStatus.Heartbeat]: connectedIcon,
            [DeviceConnectionStatus.Lost]: disconnectedIcon,
            [DeviceConnectionStatus.NeverConnected]: 'do_not_disturb',
            [DeviceConnectionStatus.Unknown]: ICON_BUSY,
            [DeviceConnectionStatus.Error]: 'error',
          }[status.connection.status]
          : ICON_BUSY
      } else {
        return 'info'
      }
    },

    // CSS style of the icon
    iconStyle () {
      const { color } = this
      return { color }
    },

    // Icon rotation
    iconRotate () {
      const { icon } = this
      return icon === ICON_BUSY
    },

    adjustedIconSize () {
      return this.iconSize || (this.isSmallScreen
        ? '32px'
        : '60px')
    },

    items () {
      const { hasConnected, isListening, isLost, statusAge, ticks } = this
      if (ticks >= 0) {
        let statusText = ''

        if (hasConnected) {
          if (isListening) {
            if (!isLost && statusAge > 3) {
              statusText = 'Waiting for status ...'
            }
          }
        }

        const items = [
          { text: this.title, class: { 'label': true } },
          { text: this.description, class: { 'small': true } },
          { text: statusText, class: { 'small': true } },
        ]

        return items
      }
    }
  },

  methods: {
    // Triggered when widget has initialized
    initialized ({ iconElement } = {}) {
      this.iconElement = iconElement
    }
  },

  watch: {
    status (value, previous) {
      // Blink status icon gently on receiving new status, just skip the first one
      if (value != null && previous != null) {
        const { iconElement: element } = this
        if (element) {
          Animation.run({ element, cssClass: 'pulse', duration: 1000 })
        }
      }
    }
  }
}
</script>

<template>
  <sc-widget-text :iconSize="adjustedIconSize"
    :icon="icon" :iconStyle="iconStyle" :iconColor="null" :iconRotateReverse="iconRotate"
    :items="items" @initialized="initialized">
  </sc-widget-text>
</template>

<style lang="scss" scoped>
* {
  color: #dbf5e6;
}
</style>
