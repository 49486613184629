/**
 * Band attenuation groups
 */
export const AttenuationGroup = {
  LowerBands: 'lower',
  UpperBands: 'upper'
}

/**
 * Band attenuation groups indices
 */
export const AttenuationGroupIndex = {
  [AttenuationGroup.LowerBands]: 0,
  [AttenuationGroup.UpperBands]: 1
}

/**
 * List of band attenuation groupss
 */
export const AttenuationGroups = Object.values(AttenuationGroup)

/**
 * Descriptions of band attenuation groups
 */
export const AttenuationGroupDescription = {
  [AttenuationGroup.LowerBands]: 'Lower Bands',
  [AttenuationGroup.UpperBands]: 'Upper Bands'
}

/**
 * Detailed descriptions of band attenuation groups
 */
export const AttenuationGroupDetails = {
  [AttenuationGroup.LowerBands]: '700, 800, 900 MHz',
  [AttenuationGroup.UpperBands]: '1800, 2100, 2600 MHz'
}
