import { cleanup } from '@stellacontrol/utilities'
import { DeviceFirmware, UploadJob, UploadJobStatus } from '@stellacontrol/model'
import { APIClient } from './api-client'

/**
 * Device Transmission API client
 */
export class DeviceTransmissionAPIClient extends APIClient {
  /**
   * Returns API name served by this client
   */
  get name () {
    return 'Device Transmission'
  }

  /**
   * Retrieves the list of firmwares available to the specified organization.
   * If organization is not specified, firmwares available to the calling organization
   * will be returned instead.
   * @param {Organization} organization Organization whose firmwares to return
   * @param {Boolean} obsolete If true, also firmwares marked as obsolete are returned
   * @returns {Array[DeviceFirmware]} Device firmwares available to organization
   */
  async getDeviceFirmwares ({ organization, obsolete } = {}) {
    try {
      const url = organization
        ? this.endpoint('organization', organization.id, 'firmware')
        : this.endpoint('firmware')
      const params = {
        obsolete
      }
      const { firmwares } = await this.request({ url, params })
      return this.asDeviceFirmwares(firmwares)
    } catch (error) {
      this.handleError(error)
      return []
    }
  }

  /**
   * Retrieves the specified firmware.
   * @param {String} id Firmware identifier
   * @param {Boolean} withContent If true, binary firmware content is also returned
   * @returns {DeviceFirmware} Device firmware
   */
  async getDeviceFirmware ({ id, withContent } = {}) {
    try {
      const url = this.endpoint('firmware', id)
      const params = { content: withContent }
      const { firmware } = await this.request({ url, params })
      return this.asDeviceFirmware(firmware)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Returns URL for downloading firmware content.
   * @param {User} user User downloading the firmware
   * @param {DeviceFirmware} firmware Firmware to download
   * @returns {String} URL for downloading binary firmware content
   */
  getDeviceFirmwareUrl ({ user, firmware } = {}) {
    const url = this.endpoint('user', user.id, 'firmware', firmware.id, 'download')
    return url
  }

  /**
   * Checks if firmware with specified identifier or version already exists.
   * @param {String} id Firmware identifier
   * @param {String} model Firmware device model
   * @param {String} version Firmware version
   * @returns {DeviceFirmware} Device firmware identifier and version information, if firmware exists
   */
  async deviceFirmwareExists ({ id, model, version } = {}) {
    try {
      const url = id
        ? this.endpoint('firmware', id, 'exists')
        : this.endpoint('firmware', model, version, 'exists')
      const result = await this.request({ url })
      return (result.id && result.version) ? this.asDeviceFirmware(result) : undefined
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Saves the specified firmware
   * @param {DeviceFirmware} firmware Firmware to save
   * @returns {DeviceFirmware} Updated firmware
   */
  async saveDeviceFirmware ({ firmware } = {}) {
    try {
      const method = firmware.id ? 'put' : 'post'
      const url = this.endpoint('firmware', firmware.id)
      // Do not save firmware file content,
      // this is done separately with {@link saveDeviceFirmwareFile} method
      const data = {
        ...firmware,
        file: {
          ...firmware.file,
          content: undefined
        }
      }
      const { firmware: savedFirmware } = await this.request({ method, url, data })
      return this.asDeviceFirmware(savedFirmware)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Saves firmware file associated with the specified firmware
   * @param {DeviceFirmware} firmware Firmware
   * @param {File} file Firmware file to save
   * @returns {DeviceFirmware} Updated firmware
   */
  async saveDeviceFirmwareFile ({ firmware, file } = {}) {
    try {
      const method = 'put'
      const data = new FormData()
      data.append('name', file.name)
      data.append('date', file.lastModified)
      data.append('type', file.type)
      data.append('size', file.size)
      data.append('content', file)
      const url = this.endpoint('firmware', firmware.id, 'file')
      const { firmware: savedFirmware } = await this.request({ method, url, data })
      return this.asDeviceFirmware(savedFirmware)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Deletes the specified firmware
   * @param {DeviceFirmware} firmware Firmware to delete
   * @returns {DeviceFirmware} Deleted firmware
   */
  async removeDeviceFirmware ({ firmware } = {}) {
    try {
      const method = 'delete'
      const url = this.endpoint('firmware', firmware.id)
      const { firmware: deletedFirmware } = await this.request({ method, url })
      return this.asDeviceFirmware(deletedFirmware)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Activates / obsoletes the specified firmware
   * @param {DeviceFirmware} firmware Firmware to activate / obsolete
   * @param {Boolean} isObsolete Firmware status: active / obsolete
   * @returns {DeviceFirmware} Updated firmware
   */
  async setDeviceFirmwareStatus ({ firmware, isObsolete } = {}) {
    try {
      const method = 'put'
      const url = this.endpoint('firmware', firmware.id, 'status')
      const data = { isObsolete }
      const { firmware: updatedFirmware } = await this.request({ method, url, data })
      return this.asDeviceFirmware(updatedFirmware)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Grants access to firmware to the specified organizations
   * @param firmware Firmware to grant access to
   * @param organizations Organizations which should have access to the firmware
   */
  async grantAccessToDeviceFirmware ({ firmware, organizations }) {
    if (firmware && organizations && organizations.length > 0) {
      try {
        const method = 'put'
        const url = this.endpoint('firmware', firmware.id, 'grant')
        const data = { organizations: organizations.map(o => o.id) }
        const { granted } = await this.request({ method, url, data })
        return granted || []
      } catch (error) {
        this.handleError(error)
      }
    }
  }

  /**
   * Revokes access to firmware to the specified organizations
   * @param firmware Firmware to revoke access to
   * @param organizations Organizations whose access to the firmware is to be revoked
   */
  async revokeAccessToDeviceFirmware ({ firmware, organizations }) {
    if (firmware && organizations && organizations.length > 0) {
      try {
        const method = 'put'
        const url = this.endpoint('firmware', firmware.id, 'revoke')
        const data = { organizations: organizations.map(o => o.id) }
        const { revoked } = await this.request({ method, url, data })
        return revoked || []
      } catch (error) {
        this.handleError(error)
      }
    }
  }

  /**
   * Initiates upload of payload to one or more devices
   * @param {String} id Identifier of payload to upload
   * @param {UploadType} type Type of payload to upload
   * @param {Number} defer Time to defer the uploads by (in seconds)
   * @param {Array[Device]} devices Devices to upload the payload to
   * @returns {Array[UploadJob]} Initiated upload jobs
   */
  async createUploadJobs ({ id, type, defer, devices }) {
    try {
      const method = 'post'
      const url = this.endpoint('upload-job')
      const data = {
        id,
        type,
        defer,
        devices: devices.map(device => device.id)
      }
      const { jobs } = await this.request({ method, url, data })
      return this.asUploadJobs(jobs)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Retrieves upload jobs, optionally only those
   * belonging to a specified organization and/or
   * only those matching the specified status
   * @param {Organization} organization Organization to which the jobs belong
   * @param {Boolean} allOrganizations If true, jobs of all organizations are returned
   * @param {Device} device Device to which the jobs are related
   * @param {UploadType} type Upload job type
   * @param {UploadStatus} status Upload job status
   * @param {Date} since Ignore jobs older than the specified date, optional
   * @returns {Promise<Array[UploadJob]>} Upload jobs
   */
  async getUploadJobs ({ organization, allOrganizations, device, type, status, since }) {
    try {
      const method = 'get'
      const url = this.endpoint('upload-job')
      const params = cleanup({
        type,
        status,
        allOrganizations,
        organizationId: organization ? organization.id : undefined,
        serialNumber: device ? device.serialNumber : undefined,
        since
      })
      const { jobs, payloads } = await this.request({ method, url, params })
      return this.asUploadJobs(jobs, payloads)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Retrieves the specified upload job
   * @param {String} id Identifier of the job to retrieve
   * @param {Boolean} withPayloadContent If true, file data will be also fetched
   * @param {User} user User requesting the data
   * @returns {Promise<UploadJob>} Upload job
   */
  async getUploadJob ({ id, withPayloadContent }) {
    try {
      const method = 'get'
      const url = this.endpoint('upload-job', id)
      const params = { withPayloadContent }
      const { job } = await this.request({ method, url, params })
      return this.asUploadJob(job)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Retrieves status of all jobs
   * which are currently in progress
   * @param {DateTime} lastStatusCheck If specified, deep check for new jobs since the specified time will be performed
   * @returns {Array} Upload jobs's status and eventual newly added jobs since the specified time
   */
  async getUploadStatus ({ lastStatusCheck } = {}) {
    try {
      const method = 'get'
      const url = this.endpoint('upload-job', 'status')
      const params = { lastStatusCheck }
      const { states = [], jobs = [] } = await this.request({ method, url, params, progress: false })
      return {
        states: states.map(status => this.asUploadJobStatus(status)),
        jobs: jobs.map(job => this.asUploadJob(job))
      }
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Updates the status of the specified upload job
   * @param {UploadJob} job Job to update, with status details in it
   * @param {String} message Additional message to record, such as error or progress details
   * @param failed Indicates that job has failed at reaching the specified status
   * @returns {UploadJob} Updated job
   */
  async setUploadStatus ({ job: { id, status, progress, retryAttempts, retryInterval }, message, failed }) {
    try {
      const method = 'put'
      const data = { status, progress, retryAttempts, retryInterval, message, failed }
      const url = this.endpoint('upload-job', id)
      const { job } = await this.request({ method, url, data })
      return this.asUploadJob(job)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Resets the specified upload job back to `new` state
   * @param {UploadJob} job Job to reset
   * @param {String} reason Reason for reset
   * @returns {UploadJob} Updated job
   */
  async resetUploadJob ({ job: { id }, reason }) {
    try {
      const method = 'put'
      const url = this.endpoint('upload-job', id, 'reset')
      const data = { reason }
      const { job } = await this.request({ method, url, data })
      return this.asUploadJob(job)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Schedules a retry of the specified upload job
   * @param {UploadJob} job Job to retry
   * @param {Boolean} reset If true, attempts counter will be reset back to defaults
   * @param {String} reason Reason for retry
   * @returns {UploadJob} Updated job
   */
  async retryUploadJob ({ job: { id }, reset, reason }) {
    try {
      const method = 'put'
      const url = this.endpoint('upload-job', id, 'retry')
      const data = { reset, reason }
      const { job } = await this.request({ method, url, data })
      return this.asUploadJob(job)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Removes the specified upload job
   * @param {String} id Identifier of the job to remove
   * @returns {Promise<UploadJob>} Deleted job
  */
  async removeUploadJob ({ job: { id } }) {
    try {
      const method = 'delete'
      const url = this.endpoint('upload-job', id)
      const { job } = await this.request({ method, url })
      return this.asUploadJob(job)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Register job processing step
   * @param {UploadJob} job Job to update
   * @param {UploadStatus} status Status to register
   * @param {String} message Additional message to record, such as error or status details
   * @param {Boolean} failed Indicates that job has failed at reaching the specified status
   * @returns {Promise<UploadJob>} Deleted job
   */
  async saveUploadStep ({ job: { id }, status, message, failed }) {
    try {
      const method = 'put'
      const url = this.endpoint('upload-job', id, 'step')
      const data = { status, message, failed }
      const { job } = await this.request({ method, url, data })
      return this.asUploadJob(job)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Converts the specified data item
   * received from API to DeviceFirmware instance
   * @param item Data item
   * @returns Device firmware initialized with the content of the data item
   */
  asDeviceFirmware (item) {
    if (item) {
      const firmware = new DeviceFirmware(item)
      if (firmware.hasFileContent) {
        const decoded = window.atob(firmware.file.content)
        const bytes = new Uint8Array(decoded.length)
        firmware.file.content = bytes.map((b, i) => decoded.charCodeAt(i))
      }
      return firmware
    }
  }

  /**
   * Converts the specified data items
   * received from API to DeviceFirmware instances
   * @param items Data items
   * @returns DeviceFirmware instances initialized with the content of the data items
   */
  asDeviceFirmwares (items = []) {
    return items.map(item => new DeviceFirmware(item))
  }

  /**
   * Converts the specified data item
   * received from API to UploadJob instance
   * @param item Data item
   * @returns Upload job initialized with the content of the data item
   */
  asUploadJob (item) {
    if (item) {
      return new UploadJob(item)
    }
  }

  /**
   * Converts the specified data item
   * received from API to UploadJobStatus instance
   * @param item Data item
   * @returns Upload job initialized with the content of the data item
   */
  asUploadJobStatus (item) {
    if (item) {
      return new UploadJobStatus(item)
    }
  }

  /**
   * Converts the specified data items
   * received from API to UploadJob instances
   * @param {Array} items Data items
   * @param {Dictionary} payloads Optional dictionary of job payloads to match to the upload jobs
   * @returns UploadJob instances initialized with the content of the data items
   */
  asUploadJobs (items = [], payloads) {
    const jobs = items.map(item => new UploadJob(item))
    if (payloads) {
      for (const job of jobs) {
        const payload = payloads[job.payloadId]
        if (payload) {
          if (job.isFirmwareUpload) {
            job.payload = this.asDeviceFirmware(payload)
          }
        }
      }
    }
    return jobs
  }
}
