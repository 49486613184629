<script>
import { mapState } from 'vuex'
import { PlannerMode } from '@stellacontrol/planner'
import { executePlanAction, PlanActions } from '../../renderer'

export default {
  props: {
    // Plan renderer
    renderer: {
    },
    // Plan layout to edit
    layout: {
    },
    // Floor to show
    floor: {
    }
  },

  computed: {
    ...mapState({
      // Planner editing mode
      mode: state => state.planner.mode
    }),

    // Determines whether the planner works in readonly mode
    isReadOnly () {
      return this.mode === PlannerMode.ReadOnly
    },

    // Determines whether we're on the cross-section view
    isCrossSection () {
      return this.renderer && this.floor && this.floor.isCrossSection
    },

    // Determines whether the toolbar should be visible
    isToolbarVisible () {
      return this.renderer && this.floor && !this.isReadOnly
    }
  },

  methods: {
    // Executes an action
    async executeAction (details = {}) {
      const { renderer, floor } = this
      await executePlanAction({ renderer, floor, ...details })
    },

    // Removes the floor from the layout
    removeFloor () {
      this.executeAction({ action: PlanActions.RemoveFloor })
    },

    // Clears the floor content
    clearFloor () {
      this.executeAction({ action: PlanActions.ClearFloor })
    },

    // Downloads a screenshot of the floor/cross-section view
    async downloadFloorImage () {
      this.executeAction({ action: PlanActions.DownloadFloorImage })
    }
  }
}

</script>

<template>
  <div class="toolbar row items-center no-wrap q-pl-sm q-pr-sm" v-if="isToolbarVisible">
    <q-btn icon="cloud_download" :ripple="false" @click.stop="downloadFloorImage()" dense flat
      text-color="indigo-7">
      <sc-tooltip nowrap>
        Download the image of the
        {{ isCrossSection ? 'cross section' : 'floor' }}
      </sc-tooltip>
    </q-btn>
    <q-btn class="button" dense flat icon="close" text-color="red-9" @click.stop="clearFloor()">
      <sc-tooltip nowrap>
        Remove everything from the
        {{ isCrossSection ? 'plan' : 'floor' }}
      </sc-tooltip>
    </q-btn>
    <q-btn v-if="floor.canDelete" class="button" dense flat icon="delete" text-color="red-9"
      @click.stop="removeFloor()">
      <sc-tooltip nowrap>
        Remove the entire floor from the plan
      </sc-tooltip>
    </q-btn>
  </div>
</template>

<style lang="scss" scoped>
.toolbar {
  border-left: solid #7988e8 1px;
}
</style>
