<script>
import { mapState, mapActions } from 'vuex'

export default {
  props: {
    // Grid column
    column: {
      type: Object,
      required: true
    },
    // Default icon to show
    icon: {
      type: String
    },
    // Default icon style
    iconStyle: {
      type: Object,
      default: () => ({ 'margin-top': '8px' })
    },
    // Header tooltip
    tooltip: {
      type: String
    },
    // Icon color
    color: {
      type: String,
      default: 'indigo-5'
    }
  },

  computed: {
    ...mapState({
      gridFilters: state => state.inventoryView.gridFilters
    }),

    // Currently selected option
    selectedOption () {
      const { column } = this
      return column.options.find(option => option.value === this.gridFilters[column.name])
    },

    selectedIcon () {
      const { selectedOption, column, icon } = this
      return (selectedOption || {}).icon || column.icon || icon
    },

    selectedIconColor () {
      const { selectedOption, column, color } = this
      return (selectedOption || {}).iconColor || column.iconColor || color
    },

    selectedTooltip () {
      const { selectedOption, column, tooltip } = this
      return `${tooltip || column.label || ''}${selectedOption ? `: ${selectedOption.label}` : ''}`
    }
  },

  methods: {
    ...mapActions([
      'filterInventory'
    ])
  }
}
</script>

<template>
  <q-select
    dense
    borderless
    hide-dropdown-icon
    :model-value="gridFilters[column.name]"
    :multiple="column.multiple"
    :options="column.options"
    option-value="value"
    option-label="label"
    map-options
    emit-value
    @update:model-value="value => filterInventory({ column: column, value })"
  >
    <template v-slot:selected>
      <q-icon
        size="20px"
        :name="selectedIcon"
        :color="selectedIconColor"
        :style="{ ...iconStyle, color: selectedIconColor }">

        <sc-tooltip :text="selectedTooltip"></sc-tooltip>

      </q-icon>
    </template>
    <template v-slot:option="scope">
        <q-item clickable v-close-popup dense class="select-option items-center" v-bind="scope.itemProps">
          <q-icon
            :name="scope.opt.icon || column.icon || icon"
            :color="scope.opt.iconColor || column.iconColor || color"
            :style="{ color: scope.opt.iconColor || column.iconColor || color }"
            size="20px"></q-icon>
          <span class="q-ml-sm">
            {{scope.opt.label }}
          </span>
        </q-item>
    </template>
  </q-select>
</template>
