import { cleanup } from '@stellacontrol/utilities'
import { defaultListState } from './list.state'

export const mutations = {
  /**
   * Sets the state of a number of lists.
   * Items array contains entries with the following properties:
   * @param state List state
   * @param name List identifier
   * @param viewMode List view mode
   * @param sortBy List sort expression
   * @param sortDescending List sort direction
   * @param filter List filter expression
   */
  setListState (state, { items = [] } = {}) {
    for (const { name, viewMode, sortBy, sortDescending, filter } of items) {
      // Create list state if not present yet
      let listState = state.items.find(item => item.name === name)
      if (!listState) {
        listState = { ...defaultListState, name }
        state.items.push(listState)
      }

      // Cleanup the input to prevent overriding state properties with undefined values
      Object.assign(listState, cleanup({ viewMode, sortBy, sortDescending, filter }))
    }
  }
}
