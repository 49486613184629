import { mapState } from 'vuex'
import { Device, Organization } from '@stellacontrol/model'
import { Guardian } from '@stellacontrol/security'
import Widget from './widget.vue'
import TextWidget from './text.vue'
import IconWidget from './icon.vue'
import ParameterWidget from './parameter.vue'

export default {
  props: {
    // Device displayed in the widget
    device: {
      type: Device,
      required: true
    },
    // Viewed organization
    organization: {
      type: Organization
    },
    // Viewed organization's guardian
    organizationGuardian: {
      type: Guardian
    },
    // Pending premium service associated with device
    pendingPremiumService: {
      type: Object
    },
    // Indicates that device is editable, commands can be sent etc.
    isEditable: {
      type: Boolean,
      default: false
    },
    // Use to show/hide the actions menu
    hasActions: {
      type: Boolean,
      default: true
    }
  },

  components: {
    'sc-widget': Widget,
    'sc-widget-text': TextWidget,
    'sc-widget-icon': IconWidget,
    'sc-widget-parameter': ParameterWidget
  },

  computed: {
    ...mapState({
      // Status of all currently watched devices
      deviceStatus: state => state.deviceStatus.devices
    }),

    // Status of the viewed device
    status () {
      const { device, deviceStatus } = this
      return deviceStatus[device?.serialNumber]
    },

    // Indicates whether status of the selected device is now available
    hasStatus () {
      return Boolean(this.status?.connection)
    }
  }
}
