import { Assignable } from '../common/assignable'
import { FeatureFlag } from '../security/feature/feature-flag'

/**
 * Application feature flags.
 * Used to temporarily enable/disable access
 * to application features on global level,
 * for example during platform maintenance
 * or to configure application parameters.
 */
export class ApplicationFlags extends Assignable {
  constructor (data = {}) {
    super()
    this.assign(data)
    if (!this.environment) throw new Error('Application flags require `environment` property')
  }

  normalize () {
    super.normalize()
    this.items = this.castArray(this.items, FeatureFlag) || []
  }

  /**
   * Environment on which the flags are valid
   * @type {Environment}
   */
  environment

  /**
   * Feature flags controling status of application features
   * @type {Array[FeatureFlag]}
   */
  items

  /**
   * Returns true if there are no flags in {@link items} yet
   * @type {Boolean}
   */
  get isEmpty () {
    return this.items.length === 0
  }

  /**
   * Finds the specified flag
   * @param {String} name Flag name. If not specified, all flags are reset.
   * @returns {FeatureFlag} Feature flag with the specified name
   */
  find (name) {
    return this.items.find(item => item.name === name)
  }

  /**
   * Returns the value of the specified flag
   * @param {String} name Flag name
   * @returns {any} Flag value
   */
  get (name) {
    return this.find(name)?.value
  }

  /**
   * Returns true if flag matches the specified value
   * @param {String} name Flag name
   * @param {any} Flag Value to check
   * @returns {Boolean} True if value matches
   */
  is (name, value) {
    return this.get(name) === value
  }

  /**
   * Checks whether the specified flag is enabled
   * @param {String} name Flag name
   * @returns {Boolean} True if flag has not been disabled
   */
  isEnabled (name) {
    return !this.isDisabled(name)
  }

  /**
   * Checks whether the specified flag is disabled
   * @param {String} name Flag name
   * @returns {Boolean} True if flag has been disabled
   */
  isDisabled (name) {
    return Boolean(this.find(name)?.isDisabled)
  }

  /**
   * Sets flag value
   * @param {String} changedBy User which changed the value
   * @param {String} flag Flag name
   * @param {any} value Value to set
   * @param {String} type Value type: `string|boolean|number`. If not specified, the value is assigned as-is.
   * @returns {FeatureFlag} Changed feature flag
   */
  set (changedBy, name, value, type) {
    const flag = this.find(name)
    if (flag) {
      flag.set(changedBy, value, type)
      return flag
    } else {
      const flag = new FeatureFlag({ name, value, changedBy })
      this.items.push(flag)
      return flag
    }
  }

  /**
   * Enables the specified flag
   * @param {String} changedBy Identifier of user who changed the flag
   * @param {String} name Flag name
   */
  enable (changedBy, name) {
    this.set(changedBy, name, true)
  }

  /**
   * Disables the specified flag
   * @param {String} changedBy Identifier of user who changed the flag
   * @param {String} name Flag name
   */
  disable (changedBy, name) {
    this.set(changedBy, name, false)
  }

  /**
   * Removes all feature flags or the specified feature flag
   * @param {String} name Name of the flag to remove, optional. If not specified, all flags are removed.
   */
  delete (name) {
    if (name) {
      this.items = this.items.filter(item => item.name !== name)
    } else {
      this.items = []
    }
  }

  /**
   * Short-hand syntax for specific flags
   */
  /**
   * Checks if application is currently in maintenance mode
   * @returns {Boolean} True if application currently in maintenance mode
   */
  get inMaintenance () {
    return this.is('maintenance', true)
  }

  /**
   * Turn's application's maintenance mode on and off
   * @param {Boolean} value Maintenance mode status
   */
  set inMaintenance (value) {
    this.set('maintenance', Boolean(value))
  }
}
