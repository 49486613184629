<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}

</script>

<template>
  <div class="row">
    <div class="col-12 col-md-9 col-lg-6 q-pa-md q-gutter-sm">
      <sc-place-editor :place="data"></sc-place-editor>
    </div>
  </div>
</template>

<style lang='scss' scoped>
</style>
