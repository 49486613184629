import { Chart, registerables } from 'chart.js'
import { isWeekend } from 'date-fns'
import 'chartjs-adapter-date-fns'
import ZoomPlugin from 'chartjs-plugin-zoom'
import AnnotationPlugin from 'chartjs-plugin-annotation'
import { merge } from '@stellacontrol/utilities'

Chart.register(...registerables)
Chart.register(ZoomPlugin)
Chart.register(AnnotationPlugin)

// Default options for line charts
const DefaultOptions = {
  // Chart automatically adapts to the size of the container
  responsive: true,
  // Don't keep aspect ratio, so that the chart always remains sharp
  maintainAspectRatio: false,
  resizeDelay: 500,
  // No animations
  animation: false,
  // Layout:
  layout: {
    // A bit of padding inside the chart
    padding: 24
  },
  // Elements
  elements: {
    // Line charts
    line: {
      // Bezier line approximation level
      tension: 0.3,
      // Gaps in data should be rendered as continuous line
      spanGaps: true
    }
  },
  // Plugins
  plugins: {
    // Chart legend
    legend: {
      // Render on the right-top by default
      position: 'right',
      align: 'start'
    },
    // Chart datapoint labels
    datalabels: {
      // Show above the points
      align: 'top',
      offset: 4
    },
    zoom: {
      pan: {
        enabled: true,
        mode: 'x',
        modifierKey: 'ctrl'
      },

      zoom: {
        wheel: {
          enabled: true,
          speed: 0.5
        },
        pinch: {
          enabled: true
        },
        drag: {
          enabled: true
        },
        mode: 'x',
        scaleMode: 'x'
      }
    },
    transitions: {
      // Switch off animations in zoom plugin
      zoom: {
        animation: {
          duration: 0
        }
      }
    }
  }
}

/**
 * Chart.JS wrapper mixin
 */
export default {
  props: {
    // Period for X axis, if time series displayed
    period: {
    },

    // Custom css classed to add to the chart
    chartClass: {
    },

    // Chart value labels
    labels: {
      type: Array,
      default: () => []
    },

    // Chart data series
    series: {
      type: Array,
      default: () => []
    },

    // Chart annotations
    annotations: {
      type: Object,
      default: () => { }
    },

    // Additional chart plugins
    plugins: {
      type: Array,
      default: () => []
    },

    // Chart rendering options
    options: {
      type: Object,
      default: () => { }
    },

    // If true, zooming is enabled
    zoom: {
      type: Boolean,
      default: true
    },

    // If true, data decimation is enabled.
    // This works only when data does not require parsing!
    decimation: {
      type: Boolean,
      default: false
    },

    // Data decimation algorithm to use: min-max or lttb.
    decimationAlgorithm: {
      type: String,
      default: 'lttb'
    },

    // Custom tooltip callback.
    // Receives data point context on input, must return a dictionary of tooltip parts
    // as documented here: https://www.chartjs.org/docs/latest/configuration/tooltip.html#tooltip-callbacks
    // for example { title, label, footer }
    tooltips: {
    },

    // If specified, data labels are shown.
    // Data labels can also be a function formatting the input value.
    // This can be used to add measurement units etc.
    dataLabels: {
      default: true
    }
  },

  data () {
    return {
      // Default chart options
      DefaultOptions,
      // Chart instance
      chart: null,
      // Indicates that chart is resizing now
      isResizing: false,
      // Returns true if chart is zoomed or panned
      isZoomed: false
    }
  },

  computed: {
    // Chart type
    type () {
      throw new Error('Type must be specified in descendants')
    },

    // Indicates whether there is any data to be shown on the chart
    hasData () {
      return this.series?.some(s => s.hasData)
    },

    // Chart data
    chartData () {
      const { labels, series: datasets, options: customOptions, annotations, dataLabels, type } = this
      const options = merge(DefaultOptions, customOptions)

      // Hook up resize handler
      options.onResize = () => this.onResizeChart()

      // Add data labels plugin if data points should be rendered
      if (typeof dataLabels === 'function') {
        options.plugins.datalabels.formatter = (value, context) => dataLabels(value, context)
      }

      // Add chart annotations
      options.plugins.annotation = { annotations }

      // Override data point tooltip to only show value but with unit
      options.plugins.tooltip = {
        ...(options.plugins.tooltip || {}),

        callbacks: {
          title: context => this.getTooltipPart(context, 'title'),
          afterTitle: context => this.getTooltipPart(context, 'afterTitle'),
          label: context => this.getTooltipPart(context, 'label'),
          afterLabel: context => this.getTooltipPart(context, 'afterLabel'),
          footer: context => this.getTooltipPart(context, 'footer'),
          afterFooter: context => this.getTooltipPart(context, 'afterFooter')
        }
      }

      // Configure data decimation
      if (this.decimation) {
        options.plugins.decimation = {
          enabled: true,
          algorithm: this.decimationAlgorithm
        }
      }

      // Wire up X axis for time series if required
      const isTimeSeries = datasets.some(s => s.isTimeSeries)
      if (isTimeSeries) {
        if (!datasets.every(s => s.isTimeSeries)) {
          throw new Error('If time series used, all series must be marked as time series')
        }

        const { period } = this
        if (!period) {
          throw new Error('If time series used, period must be specified')
        }

        const unit = period.length <= 3
          ? 'minute'
          : (period.length <= 7
            ? 'hour' : 'day')

        const now = new Date()
        const min = period.from
        const max = period.to > now ? now : period.to

        options.scales = {
          ...(options.scales || {}),
          x: {
            type: 'time',
            min,
            max,
            ticks: {
              color: context => isWeekend(context.tick.value) ? 'blue' : 'black'
            },
            time: {
              unit,
              displayFormats: {
                minute: 'HH:mm',
                hour: 'dd MMM HH:mm',
                day: 'dd MMM'
              }
            }
          }
        }
      }

      if (this.zoom && options.plugins.zoom) {
        options.plugins.zoom.zoom.onZoom = this.onZoom
        options.plugins.zoom.pan.onPan = this.onPan
      } else {
        delete options.plugins.zoom
      }

      const data = {
        type,
        options,
        data: {
          labels,
          datasets
        }
      }

      return data
    }
  },

  methods: {
    // Chart HTML element
    getChartElement () {
      return this.$refs.chart
    },

    // Returns tooltip content for chart item
    getTooltipPart (context, part) {
      if (this.tooltips) {
        if (Array.isArray(context)) context = context[0]
        const tooltip = this.tooltips(context)
        return tooltip ? tooltip[part] : undefined
      }
    },

    // Triggered when chart is being resized
    onResizeChart () {
      this.$emit('resize', this)
    },

    // Forces chart update
    update (animate) {
      this.chart?.update(animate ? undefined : 'none')
    },

    // Resets chart zoom
    resetZoom () {
      this.chart?.resetZoom()
      this.$emit('resetZoom', this.chart)
    },

    // Triggered when chart is being panned
    onPan () {
      this.isZoomed = this.chart.isZoomedOrPanned()
      this.$emit('pan', this.chart)
    },

    // Triggered when chart is being zoomed
    onZoom () {
      this.isZoomed = this.chart?.isZoomedOrPanned()
      this.$emit('zoom', this.chart)
    }
  },

  emits: [
    'resize',
    'zoom',
    'pan',
    'resetZoom'
  ],

  mounted () {
    const { getChartElement, chartData } = this
    this.chart = new Chart(getChartElement(), chartData)
  }
}
