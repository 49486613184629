import { isEnum, assign, parseDate } from '@stellacontrol/utilities'
import { EntityType } from './entity-type'

/**
 * Note categories, indicating type of entity to which the note is attached
 */
export const NoteCategory = {
  General: 'general',
  Device: 'device',
  OrganizationProfile: 'organization-profile',
  Organization: 'organization',
  User: 'user',
  Place: 'place',
  Attachment: 'attachment'
}

/**
 * Note
 */
export class Note {
  constructor (data = {}) {
    assign(this,
      {
        ...data,
        createdAt: data.createdAt || new Date(),
        updatedAt: data.updatedAt || new Date(),
        category: data.category || NoteCategory.General
      },
      {
        createdAt: parseDate,
        updatedAt: parseDate
      })

    if (!isEnum(NoteCategory, this.category)) throw new Error(`Invalid note category ${this.category}`)
  }

  /**
   * Creates or updates note associated with the specified entity.
   * Useful for situations where entity has just a single note associated with it.
   * @param {Entity} entity
   * @returns {Note}
   */
  static for (entity) {
    if (entity) {
      return new Note({
        ...(entity.notes[0]),
        organizationId: entity.organizationId,
        entityId: entity.id,
        category: entity.getType()
      })
    }
  }

  /**
   * Creates or updates all notes associated with the specified entity.
   * Useful for situations where entity has more notes associated with it.
   * @param {Entity} entity
   * @returns {Array[Note]}
   */
  static all (entity) {
    if (entity) {
      return (entity.notes || []).map(note => new Note({
        ...note,
        organizationId: entity.organizationId,
        entityId: entity.id,
        category: entity.getType()
      }))
    }
  }

  /**
   * Database identifier
   * @type {String}
   */
  id

  /**
   * Creation time
   * @type {Date}
   */
  createdAt

  /**
   * Last modification time
   * @type {Date}
   */
  updatedAt

  /**
   * Creator id
   * @type {String}
   */
  createdBy

  /**
   * Creator details
   * @type {User}
   */
  creator

  /**
   * Updater id
   * @type {String}
   */
  updatedBy

  /**
   * Updater details
   * @type {User}
   */
  updater

  /**
   * Identifier of organization to which the note belongs
   * @type {String}
   */
  organizationId

  /**
   * Identifier of organization to which the note is addressed
   * @type {String}
   */
  recipientId

  /**
   * Indicates that note is/should be deleted
   * @type {Boolean}
   */
  isDeleted

  /**
   * Identifier of an entity to which the note is attached,
   * such as user etc. This is different than note
   * creator who is the user who created the note,
   * as specified in `createdBy` property of the `Entity` class.
   * @type {String}
   */
  entityId

  /**
   * Note category, typically an identifier of entity associated with note such as `device` or `user`
   * @type {NoteCategory}
   */
  category

  /**
   * Note text
   * @type {String}
   */
  text

  /**
   * Checks whether the note text is empty
   * @type {Boolean}
   */
  get isEmpty () {
    return (this.text?.trim() || '') == ''
  }

  /**
   * Indicates a private note
   * @type {Boolean}
   */
  isPrivate

  /**
   * Indicates whether the note is private
   * and is owned by the specified user
   * @param {User} user User who owns the note
   * @type {Boolean}
   */
  isOwnedBy ({ id }) {
    return this.createdBy === id
  }

  /**
   * Returns the entity type with which the note is associated
   * @returns {EntityType}
   */
  get entityType () {
    if (this.category === NoteCategory.General) {
      return
    } else if (this.category === NoteCategory.OrganizationProfile) {
      return EntityType.OrganizationProfile
    } else {
      return this.category
    }
  }

  /**
   * Adds a note to the specified list
   * @param {Array[Note]} notes Notes list
   * @param {Note} note Note to add
   * @param {User} user User adding the notes
   * @returns {Note} Added note
   */
  static add (notes = [], note, user) {
    if (!note) throw new Error('Note is required')
    if (!user) throw new Error('User is required')

    const newNote = new Note({
      entityId: note.entityId,
      category: note.category,
      createdBy: user.id,
      updatedBy: user.id,
      creator: user,
      updater: user,
      text: note.text.trim()
    })

    notes.push(newNote)
    return newNote
  }

  /**
   * Updates a note of an entity
   * @param {Entity} entity Entity whose note to update
   * @param {Note} note Note to update
   * @param {User} user User updating the note
   * @returns {Note} Updated note
   */
  static update (entity, note, user) {
    if (!entity) throw new Error('Entity is required')
    if (!note) throw new Error('Note is required')
    if (!user) throw new Error('User is required')

    if (!entity.notes) entity.notes = []
    let index = entity.notes.indexOf(note)
    if (index === -1 && note.id) {
      index = entity.notes.findIndex(n => n.id === note.id)
    }

    let updatedNote = note
    if (index === -1) {
      note.createdBy = user.id
      note.creator = user
      entity.notes.push(note)
    } else {
      updatedNote = entity.notes[index]
      updatedNote.text = note.text
      return updatedNote
    }

    updatedNote.updatedBy = user.id
    updatedNote.updater = user

    return updatedNote
  }

  /**
   * Removes a note of an entity
   * @param {Entity} entity Entity whose note to remove
   * @param {Note} note Note to remove
   * @returns {Array[Notes]} Modified list of notes
   */
  static remove (entity, note) {
    if (!entity) throw new Error('Entity is required')
    if (!entity.notes) return

    let index = entity.notes.indexOf(note)
    if (index === -1 && note.id) {
      index = entity.notes.findIndex(n => n.id === note.id)
    }
    if (index > -1) {
      entity.notes.splice(index, 1)
    }
    return entity.notes
  }
}
