import * as VueRouter from 'vue-router'
import { Log } from '@stellacontrol/utilities'
import { useRouterErrorHandler } from '../errors'

/**
 * Global router instance
 */
let router
let routerCreationAttempted = false

/**
 * Initializes UI router
 * @param application Vue application instance
 * @param routes Routes to add
 * @param views Views associated with routes
 */
export function useRouter (application, routes = [], views = []) {
  if (router || routerCreationAttempted) {
    return router
  }

  routerCreationAttempted = true
  try {
    // Create router
    router = VueRouter.createRouter({
      routes,
      history: VueRouter.createWebHashHistory()
    })

    useRouterErrorHandler(router)

  } catch (error) {
    Log.error('Cannot initialize router with the specified routes', routes)
    Log.exception(error)
    // We want the application to stop here, as errors when wiring up the router
    // can result in an endless cycle of router redirections and stack overflow.
    // eslint-disable-next-line no-debugger
    debugger
  }

  application.use(router)
  registerRoutes(router, routes, views)

  return { router, routes }
}

/**
 * Mounts new routes into existing router instance
 * @param router Router instance
 * @param routes Routes to mount
 * @param views Views associated with routes
 */
export function registerRoutes (router, routes, views) {
  if (router && routes && views) {
    // Store views in their routes, comes handy in route guards.
    // View is usually identified by route name,
    // but a different view can be associated with route,
    // using route.view property
    const newRoutes = []
    for (const route of routes) {
      const viewAlias = route.viewAlias
      const defaultViewName = (viewAlias || {})['default'] || route.view || route.name
      const view = views.find(v => v.name === defaultViewName)

      if (view) {
        const { isSecure, permissions = [] } = view
        route.meta = {
          ...route.meta,
          view,
          defaultViewName,
          viewAlias,
          isSecure,
          permissions
        }
      }

      // Register route
      router.addRoute(route)
      newRoutes.push(route)
    }
  }
}
