<script>
import { mapState, mapActions } from 'vuex'

export default {
  props: {
    column: {
      type: Object,
      required: true
    },

    enableTextFilter: {
      type: Boolean,
      default: true
    },

    clearable: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    ...mapState({
      gridFilters: state => state.inventoryView.gridFilters
    })
  },

  methods: {
    ...mapActions([
      'filterInventory'
    ]),

    // Filter function for manual filtering options
    // using an input
    selectFilter (column, value = '', update) {
      update(() => {
        if (this.enableTextFilter) {
          if (value === '') {
            column.options = column.allOptions
          } else {
            const text = value.toLowerCase()
            column.options = column.allOptions.filter(option => option.label.toLowerCase().includes(text))
          }
        }
      })
    }
  }
}
</script>

<template>
  <q-select
    dense
    hide-dropdown-icon
    bg-color="#e8e8e8"
    :clearable="clearable"
    clear-icon="close"
    :use-input="enableTextFilter"
    :fill-input="enableTextFilter"
    debounce="500"
    :model-value="gridFilters[column.name]"
    :label="column.label"
    :options="column.options"
    option-value="value"
    option-label="label"
    map-options
    emit-value
    v-bind="{ ...$props, ...$attrs }"
    @filter="(value, update) => selectFilter(column, value, update)"
    @update:model-value="value => filterInventory({ column: column, value })"
  >
    <template v-slot:no-option>
      <q-item>
        <q-item-section class="text-grey">
          No results
        </q-item-section>
      </q-item>
    </template>
    <template v-slot:option="scope">
      <q-item class="select-option items-center" clickable v-close-popup dense v-bind="scope.itemProps">
        <q-icon v-if="scope.opt.icon" :name="scope.opt.icon" :color="scope.opt.iconColor || 'indigo-5'" size="20px" class="q-mr-sm" />
        <span v-if="scope.opt.label">{{ scope.opt.label }}</span>
      </q-item>
    </template>

    <!--
      Pass all other custom slots from the parent
    -->
      <template v-for="(_, name) in $slots" v-slot:[name]="slotData">
        <slot :name="name" v-bind="slotData"></slot>
      </template>

  </q-select>
</template>

<style>
</style>