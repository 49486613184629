import ErrorView from '../views/error.vue'
import LoadingView from '../views/loading.vue'
import RedirectingView from '../views/redirecting.vue'
import ReloadingView from '../views/reloading.vue'
import EmptyView from '../views/empty.vue'
import NotAuthorizedView from '../views/not-authorized.vue'
import WIPView from '../views/wip.vue'

export const ClientRoutes = [
  {
    name: 'empty',
    path: '/empty',
    component: EmptyView,
    meta: {
      isSecure: false
    }
  },
  {
    name: 'loading',
    path: '/loading',
    component: LoadingView,
    meta: {
      isSecure: false
    }
  },
  {
    name: 'redirecting',
    path: '/redirecting',
    component: RedirectingView,
    meta: {
      isSecure: false
    }
  },
  {
    name: 'reloading',
    path: '/reloading',
    component: ReloadingView,
    meta: {
      isSecure: false
    }
  },
  {
    name: 'error',
    path: '/error',
    component: ErrorView,
    meta: {
      isSecure: false
    }
  },
  {
    name: 'wip',
    path: '/wip',
    component: WIPView,
    meta: {
      isSecure: false
    }
  },
  {
    name: 'not-authorized',
    path: '/not-authorized',
    component: NotAuthorizedView,
    meta: {
      isSecure: false
    }
  }
]

/**
 * Known route names
 */
export const ClientRoute = {
  Home: 'home',
  Login: 'login',
  Empty: 'empty',
  Loading: 'loading',
  Error: 'error',
  Help: 'help',
  WIP: 'wip',
  NotAuthorized: 'not-authorized'
}
