<script>
import { mapActions } from 'vuex'
import { sortItems } from '@stellacontrol/utilities'
import { DialogMixin } from '@stellacontrol/client-utilities'

const dialog = 'propagate-permission'

export default {
  mixins: [
    DialogMixin
  ],

  data () {
    return {
      dialog,
      // Organization profile
      principal: null,
      // Granted permission
      permission: null,
      // Profile organizations
      organizations: [],
      // Organizations permitted to use the feature
      permitted: []
    }
  },

  computed: {
    // Edited feature
    feature () {
      return this.permission?.feature
    },

    // Returns true if all organizations have been permitted
    allPermitted () {
      return this.permitted.length === this.organizations.length
    },

    // Indicates that the dialog has been populated
    isPopulated () {
      return Boolean(this.principal && this.permission)
    }
  },

  methods: {
    ...mapActions([
      'dialogOk',
      'dialogCancel',
      'getOrganizationProfileMembers'
    ]),

    // Populates the dialog
    async populate () {
      const { data: { principal, permission } } = this
      const organizations = await this.getOrganizationProfileMembers({ id: principal.id, withPermissions: true })
      this.principal = principal
      this.permission = permission
      this.organizations = sortItems(organizations, 'name')
      this.permitted = organizations.filter(o => o.canUse(permission.feature.name)).map(o => o.id)
    },

    // Returns true if specified organization is permitted to use the feature
    isPermitted (organization) {
      return this.permitted.includes(organization.id)
    },

    // Grants/denies permission to use the feature
    togglePermission (organization, value) {
      if (value) {
        if (!this.isPermitted(organization)) {
          this.permitted.push(organization.id)
        }
      } else {
        this.permitted = this.permitted.filter(id => organization.id !== id)
      }
    },

    // Grants/denies permission to all organizations
    toggleAll (status) {
      if (status) {
        this.permitted = this.organizations.map(o => o.id)
      } else {
        this.permitted = []
      }
    },

    // Validates and OKs the dialog
    async ok () {
      const { permitted, organizations, feature } = this
      const grant = organizations.filter(o => !o.canUse(feature.name) && permitted.includes(o.id)).map(o => o.id)
      const revoke = organizations.filter(o => o.canUse(feature.name) && !permitted.includes(o.id)).map(o => o.id)
      const data = { grant, revoke }
      this.dialogOk({ dialog, data })
    },

    // Cancels the dialog
    cancel () {
      this.dialogCancel({ dialog })
    }
  }
}

</script>

<template>
  <sc-dialog :dialog="dialog" @dialogShown="populate()">

    <q-form class="form" ref="form" v-if="isPopulated">
      <div class="content">

        <q-banner class="banner bg-indigo-6">
          <div class="row items-center">
            <span class="text-white title">
              Grant permission "{{ feature.description }}" to
            </span>
          </div>
        </q-banner>

        <div class="inside q-ma-md">
          <div class="organizations q-pt-sm q-pb-sm">
            <q-list dense>
              <q-item v-for="organization in organizations" :key="organization.id">
                <q-item-section side>
                  <q-checkbox dense :model-value="isPermitted(organization)"
                    :label="organization.name"
                    @update:model-value="value => togglePermission(organization, value)">
                  </q-checkbox>
                </q-item-section>
              </q-item>
            </q-list>
          </div>
          <div class="buttons row q-mt-md">
            <q-btn :label="allPermitted ? 'Deselect All' : 'Select All'" unelevated
              @click="toggleAll(!allPermitted)">
            </q-btn>
            <q-space>
            </q-space>
            <q-btn label="Grant" class="primary" unelevated @click="ok()">
            </q-btn>
            <q-btn label="Close" unelevated class="q-ml-md" @click="cancel()">
            </q-btn>
          </div>
        </div>
      </div>
    </q-form>

  </sc-dialog>
</template>

<style scoped lang="scss">
.form {
  width: 550px;
  height: 550px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .banner {
      flex: 0;
    }

    .inside {
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      .organizations {
        flex: 1;
        border: 1px solid #0000001f;
        overflow: auto;
      }

      .buttons {
        flex: 0;
      }
    }

    .title {
      font-size: 18px;
    }
  }
}
</style>