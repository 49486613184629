import PurchaseTokensDialog from './purchase-tokens-dialog.vue'
import SellTokensDialog from './sell-tokens-dialog.vue'
import PurchasePremiumServiceDialog from './purchase-premium-service-dialog.vue'

/**
 * Installs Service Management UI dialogs into the application
 * @param application Vue application instance
 */
export function useServiceManagementDialogs (application) {
  const plugin = {
    install (application) {
      const items = [
        {
          tag: 'sc-purchase-tokens-dialog',
          component: PurchaseTokensDialog
        },
        {
          tag: 'sc-sell-tokens-dialog',
          component: SellTokensDialog
        },
        {
          tag: 'sc-purchase-premium-service-dialog',
          component: PurchasePremiumServiceDialog
        }
      ]

      for (const { tag, component } of items) {
        application.component(tag, component)
      }
    }
  }

  application.use(plugin)
}
