<script>
import ParameterEditor from './parameter-editor'

export default {
  mixins: [
    ParameterEditor
  ],

  data () {
    return {
      // Edited LPAS level
      lpasLevel: 0
    }
  },

  computed: {
    // Items for value selector
    items () {
      const { mega: { values, unit, unitPlural } } = this

      if (values) {
        return values.map(value => ({
          label: `${value} ${value > 1 && unitPlural ? unitPlural : unit}`,
          value: value,
          icon: 'signal_cellular_alt'
        }))
      }
    }
  },

  methods: {
    valueFormat (value) {
      const { mega: { unit, unitPlural } } = this
      return value == null ? '-' : `${value} ${value > 1 && unitPlural ? unitPlural : unit}`
    },

    // Notifies about user's selection
    setLPASLevel (value) {
      this.change(parseInt(value))
    }
  },

  watch: {
    // Show current value of LPAS level from the device
    reported () {
      this.lpasLevel = parseInt(this.custom || this.reported)
    }
  }
}
</script>

<template>
  <div class="parameter-editor" v-if="hasValue">

    <sc-parameter-value
      label="LPAS"
      :reported="reported"
      :custom="custom"
      :format="valueFormat"
    />

    <sc-parameter-selector
      label="Low Power Automatic Shutdown should happen when signal goes below the level of"
      :allow-clear="isCustomized"
      @select="value => change(value)"
      @clear="clear()"
    >
      <template v-if="items">
        <q-item clickable v-close-popup
          v-for="(value) in values" :key="value"
          @click.stop="change(value)">
          <q-item-section side>
            <div class="leds q-pl-md">
              <div v-for="led in [1,2,3,4,5]" :key="`led-${led}`" class="led" :class="{ on: led <= value }">
              </div>
            </div>
          </q-item-section>
          <q-item-section>
            <q-item-label class="action-label q-pt-sm q-pb-sm">
              {{ value }} LED{{ value === 1 ? '' : 's' }}
            </q-item-label>
          </q-item-section>
        </q-item>
      </template>

      <template v-else>
        <div class="q-pt-md q-pl-lg q-pr-lg q-pb-md">
          <div>
            <q-input
              type="number"
              v-model="lpasLevel"
              :min="mega.min"
              :max="mega.max"
            >
              <template v-slot:prepend>
                <label class="text-body1">
                  {{ mega.unit }}
                </label>
              </template>
            </q-input>

            <div class="q-mt-sm text-grey-7">
              Allowed range: {{ mega.min }} {{ mega.unit }} to {{ mega.max }} {{ mega.unit }}
            </div>
          </div>

          <div class="row items-center q-pt-lg">
            <q-btn label="Clear" flat dense class="warning" padding="4px 16px" @click="clear()" v-close-popup />
            <q-space></q-space>
            <q-btn label="Change" flat dense class="success" padding="4px 16px" @click="setLPASLevel(lpasLevel)" v-close-popup />
          </div>
        </div>
      </template>

      <template #clear>
        <span />
      </template>
    </sc-parameter-selector>

  </div>

</template>

<style lang="scss" scoped>
.leds {
  display: flex;
  flex-direction: row;
  align-items: center;

  .led {
    width: 12px;
    height: 12px;
    margin-right: 4px;
    background-color: #d0d0d0;

    &.on {
      background-color: #4caf50;
    }
  }
}
</style>