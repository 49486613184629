<script>
import ItemProperties from './item-properties.vue'
import ItemPropertiesMixin from './item-properties.js'
import BackgroundProperties from './background-style-properties.vue'
import LineProperties from './line-style-properties.vue'

export default {
  mixins: [
    ItemPropertiesMixin
  ],

  components: {
    'sc-item-properties': ItemProperties,
    'sc-background-properties': BackgroundProperties,
    'sc-line-properties': LineProperties
  }
}

</script>

<template>
  <sc-item-properties :renderer="renderer" :items="items" @action="executeAction">
  </sc-item-properties>

  <main class="plan-item-properties">
    <!-- ellipse radius -->
    <section>
      <label>
        Radius
      </label>
      <q-input
        type="number" :min="1" :max="10000"
        dense outlined square label="Horizontal"
        debounce="500"
        :input-style="styles.number"
        :model-value="getValue('radius.x')"
        @update:model-value="value => setProperties({ 'radius.x': parseInt(value) })">
      </q-input>
      <q-input
        type="number" :min="1" :max="10000"
        dense outlined square label="Vertical"
        class="q-ml-xs"
        debounce="500"
        :input-style="styles.number"
        :model-value="getValue('radius.y')"
        @update:model-value="value => setProperties({ 'radius.y': parseInt(value) })">
      </q-input>
    </section>
  </main>

  <sc-background-properties :renderer="renderer" :items="items" @action="executeAction">
  </sc-background-properties>

  <sc-line-properties :renderer="renderer" :items="items" @action="executeAction"
    widthLabel="Border Width" colorLabel="Border Color" typeLabel="Border Type">
  </sc-line-properties>

</template>

<style lang="scss" scoped>
</style>
