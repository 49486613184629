import { resolve } from './alerts.resolve'
import AlertsView from './alerts.vue'

const name = 'alerts'

export const Routes = [
  {
    name,
    path: '/alerts',
    component: AlertsView,

    async beforeEnter (to, from, next) {
      if (await resolve({ to, from })) {
        next()
      }
    }
  }
]
