import { SecurityAPI } from '@stellacontrol/client-api'
import { dispatch } from '@stellacontrol/client-utilities'
import { SecurityRequestType } from '@stellacontrol/model'
import ResetPasswordView from './reset-password.vue'

const name = 'reset-password'

export const Routes = [
  {
    name,
    path: '/reset-password/:token',
    component: ResetPasswordView,

    async beforeEnter (to, from, next) {
      // Make sure there's no session before entering this view
      await dispatch('clearSession')

      const { params: { token } } = to
      const type = SecurityRequestType.NewPassword
      const data = await SecurityAPI.getSecurityRequest({ token, type })
      if (data) {
        await dispatch('setRouteData', { from, to, data })
        next()
      } else {
        next({ name: 'empty' })
      }
    }
  }
]
