/**
 * Represents an action in the action dropdown
 */
export class ActionItem {
  constructor (data = {}) {
    Object.assign(
      this,
      {
        ...data,
        isVisible: data.isVisible === undefined ? true : data.isVisible
      })
  }

  /**
   * Unique name of action in the menu
   */
  name

  /**
   * User-friendly action label.
   * It can also be a callback, receiving data associated with the action dropdown,
   * which returns a text to display.
   */
  label

  /**
   * Icon associated with the action
   */
  icon

  /**
   * Icon color
   */
  color

  /**
   * Indicates whether this is a default action in the set of actions
   */
  isDefault = false

  /**
   * Indicates whether the action should be visible.
   * This can be a boolean or a callback returning a boolean
   */
  isVisible = true

  /**
   * Indicates whether the action should be preceded by a separator
   */
  separator = false

  /**
   * Confirmation to ask, before the action is executed
   */
  confirmation

  /**
   * Handler to run when action is executed. Data associated with action dropdown is passed
   * as handler's argument - this is usually the data record associated with context actions.
   * Alternatively, the component will emit `action` event passing the action as argument
   */
  handler

  /**
   * Returns true if action can be executed.
   * This is checked by verifying {@link isVisible}
   * property or callback
   * @param data Context data passed to {@link isVisible} callback
   */
  canExecute (data) {
    const { isVisible } = this
    if (isVisible == null) {
      return true
    } else {
      return (typeof isVisible === 'function')
        ? isVisible(data)
        : Boolean(isVisible)
    }
  }
}
