<script>
import { mapGetters } from 'vuex'
import { getFullDurationString } from '@stellacontrol/utilities'
import { FormValidationRules as rules } from '@stellacontrol/client-utilities'
import AlertConfigurationComponent from './alert-configuration-component'

export default {
  mixins: [
    AlertConfigurationComponent
  ],

  data () {
    return {
      rules
    }
  },

  computed: {
    ...mapGetters([
      'getFeature'
    ]),

    alertType () {
      return this.configuration?.alertType
    },

    isDisabled () {
      return !this.configuration?.isEnabled
    },

    title () {
      const { configuration } = this
      const feature = this.getFeature(`alert-type-${configuration.alertType}`)
      if (feature) {
        return feature.details
      }
    },

    // Determines whether current user can mute alerts
    canMute () {
      return this.canEdit
    },

    // Logging frequency details
    frequencyIsModified () {
      return this.isModified('frequency')
    },

    frequencyIsNotDefault () {
      return this.isNotDefault('frequency', value => `Default frequency is ${this.duration(value)}.`)
    },

    // Frequencies to select from.
    // Certain frequencies are only allowed for debugging purposes
    // to super administrators
    frequencies () {
      const { configuration, isSuperAdministrator } = this
      const allowedFrequencies = configuration?.allowedFrequencies || []
      const frequencies = allowedFrequencies
        .map(({ duration, isReserved = false }) => ({
          value: duration,
          label: getFullDurationString(duration).replace('about', ''),
          isReserved
        }))
        .filter(f => f.isReserved ? isSuperAdministrator : true)

      return frequencies
    },

    // Determines whether alert frequency can be edited.
    // Normally this is only available for device-offline and few others.
    // We allow it for all alert types if super organization is editing.
    canEditFrequency () {
      return this.configuration.canRepeat &&
        (this.configuration.canEditFrequency || this.isSuperOrganization)
    }
  }
}
</script>

<template>
  <article>
    <section v-if="title" class="dense">
      <div class="column q-gutter-sm">
        <div class="title text-body2 text-weight-regular text-indigo-9">
          <slot name="title">
            {{ title }}
          </slot>
        </div>
        <div class="subtitle text-body2 text-weight-regular text-grey-8">
          <slot name="subtitle">
          </slot>
        </div>
      </div>
    </section>

    <section v-if="canMute">
      <div>
        <q-checkbox dense :model-value="isDisabled" label="Mute the alert"
          :class="{ modified: isDisabled }"
          @update:model-value="value => configuration.isEnabled = !value"
          :color="isDisabled ? 'gray-8' : 'green-7'">
        </q-checkbox>
      </div>
    </section>
    <section v-else-if="!configuration.isEnabled">
      <label class="text-orange-7">
        The alert is muted
      </label>
    </section>

    <section v-if="canEditFrequency && isEditable" class="vertical">
      <label :class="{ modified: frequencyIsModified }">
        Log recurring alert every
        <sc-hint size="20px">
          If the same alert keeps happening frequently,
          it will be only reported every {{ duration(configuration.frequency) }}.
          {{ frequencyIsNotDefault }}
        </sc-hint>
      </label>
      <div class="q-mt-sm">
        <q-option-group dense inline v-model="configuration.frequency" :options="frequencies"
          color="indigo-6">
        </q-option-group>
      </div>
    </section>

    <section v-if="canEditFrequency && !isEditable">
      <label :class="{ modified: frequencyIsModified }">
        Notify about recurring alert every
      </label>
      <sc-hint size="20px" class="q-ml-sm">
        If the same alert keeps happening frequently,
        it will be only reported every {{ duration(configuration.frequency) }}.
        {{ frequencyIsNotDefault }}
      </sc-hint>
      <div :class="{ disabled: isMuted }" class="q-ml-sm">
        {{ duration(configuration.frequency) }}
      </div>
    </section>

  </article>
</template>

<style scoped lang="scss"></style>