import { notNull } from '../compare'

/**
 * Size
 */
export class Size {
  constructor (data) {
    this.width = data?.width
    this.height = data?.height
  }

  /**
   * Creates size from another instance
   * @param {Size} size
   * @returns {Size}
   */
  static from (size) {
    return new Size(size)
  }

  /**
   * Creates size `(0,0)`
   * @returns {Size}
   */
  static get Zero () {
    return new Size({ width: 0, height: 0 })
  }

  /**
   * Creates a deep copy of the size
   * @type {Size}
   */
  copy () {
    return Size.from(this)
  }

  /**
   * Width
   * @type {Number}
   */
  width

  /**
   * Height
   * @type {Number}
   */
  height

  /**
   * Alternative name for {@link width}
   * @type {Number}
   */
  get x () {
    return this.width
  }
  set x (value) {
    this.width = value
  }

  /**
   * Alternative name for {@link height}
   * @type {Number}
   */
  get y () {
    return this.height
  }
  set y (value) {
    this.height = value
  }

  /**
   * String representation of the size
   * @returns {String}
   */
  toString () {
    const { width, height } = this
    return `(${[width, height].filter(c => c != null).join(',')})`
  }

  /**
   * Checks whether the size is the same as the specified one
   * @param {Size} size Size to compare to
   * @returns {Boolean}
   */
  sameAs (size) {
    if (size) {
      const { width, height } = size
      return this.width === width &&
        this.height === height
    }
  }

  /**
   * Sets the size from the specified instance
   * @param {Size|Point|Rectangle} size Data specified as {@link Size}, {@link Point} or {@link Rectangle}
   * @param {Size|Point|Rectangle} limits Optional upper limits on the size
   * @returns {Size} Modified instance
   */
  setSize (size, limits) {
    if (size) {
      const { x, y, width, height } = size
      this.width = width != null ? width : (x != null ? x : this.width)
      this.height = height != null ? height : (y != null ? y : this.height)
      if (limits) {
        const limitX = limits.x == null ? limits.width : limits.x
        const limitY = limits.y == null ? limits.height : limits.y
        this.width = limitX == null ? this.width : Math.min(limitX, this.width)
        this.height = limitY == null ? this.height : Math.min(limitY, this.height)
      }
    }
    return this
  }

  /**
   * Scales the size by the specified factor
   * @param {Point|Number} scale Scale factor on `x` and `y` axis, where `1` means no change
   * @param {Boolean} round If true, the returned size will be rounded up to nearest integers
   * @returns {Size} Modified instance
   */
  scale (scale, round = true) {
    const x = notNull(scale.width, scale.x, scale)
    const y = notNull(scale.height, scale.y, scale)
    this.width = (x != null && this.width != null) ? this.width * x : this.width
    this.height = (y != null && this.height != null) ? this.height * y : this.height
    return round ? this.round() : this
  }

  /**
   * Rounds the size upwards
   * @returns {Size} Modified instance
   */
  round () {
    this.width = this.width != null ? Math.round(this.width) : this.width
    this.height = this.height != null ? Math.round(this.height) : this.height
    return this
  }

  /**
   * Grows the size by the specified values
   * @param {Size|Point} value Values to grow by, specified as {@link Size} or {@link Point}
   * @returns {Size} Modified instance
   */
  growBy (value) {
    if (value) {
      const { x, y, width, height } = value

      if (width != null || height != null) {
        // Grow by size
        this.width = width == null ? this.width : this.width + width
        this.height = height == null ? this.height : this.height + height

      } else if (x != null || y != null) {
        // Grow by margins
        this.width = this.width + (x || 0)
        this.height = this.height + (y || 0)
      }
    }

    return this
  }


}

