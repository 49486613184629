import Widget from './widget.vue'
import Text from './text.vue'
import Icon from './icon.vue'
import Parameter from './parameter.vue'
import DeviceCard from './device-card.vue'
import DeviceInfo from './device-info.vue'
import DeviceStatus from './device-status.vue'
import DeviceAlerts from './device-alerts.vue'
import DeviceTemperature from './device-temperature.vue'
import DeviceBattery from './device-battery.vue'
import DeviceBands from './device-bands.vue'
import DeviceBandDetails from './device-band-details.vue'
import DeviceMega from './device-mega.vue'
import DeviceEvents from './device-events.vue'
import DeviceLeds from './device-leds.vue'
import DeviceSubscriptions from './device-subscriptions.vue'
import DeviceFirmwareUpdate from './device-firmware-update.vue'
import DeviceRegion from './device-region.vue'
import DeviceGraph from './device-graph.vue'
import DeviceCounters from './device-counters.vue'
import DevicePortSense from './device-portsense.vue'
import DeviceFlags from './device-flags.vue'
import DeviceNotes from './device-notes.vue'
import TTUsage from './device-tt-usage.vue'

export const DashboardWidgets = {
  'sc-widget': Widget,
  'sc-widget-text': Text,
  'sc-widget-icon': Icon,
  'sc-widget-parameter': Parameter,
  'sc-widget-device-card': DeviceCard,
  'sc-widget-device-info': DeviceInfo,
  'sc-widget-device-status': DeviceStatus,
  'sc-widget-device-alerts': DeviceAlerts,
  'sc-widget-device-temperature': DeviceTemperature,
  'sc-widget-device-battery': DeviceBattery,
  'sc-widget-device-bands': DeviceBands,
  'sc-widget-device-band-details': DeviceBandDetails,
  'sc-widget-device-mega': DeviceMega,
  'sc-widget-device-events': DeviceEvents,
  'sc-widget-device-leds': DeviceLeds,
  'sc-widget-device-subscriptions': DeviceSubscriptions,
  'sc-widget-firmware-update': DeviceFirmwareUpdate,
  'sc-widget-device-region': DeviceRegion,
  'sc-widget-device-graph': DeviceGraph,
  'sc-widget-device-counters': DeviceCounters,
  'sc-widget-device-portsense': DevicePortSense,
  'sc-widget-device-flags': DeviceFlags,
  'sc-widget-tt-usage': TTUsage,
  'sc-widget-device-notes': DeviceNotes
}
