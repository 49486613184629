<script>
import { mapState } from 'vuex'
import { Device, getBandLabel } from '@stellacontrol/model'
import { DeviceStatusConnection } from '@stellacontrol/devices'
import WidgetComponent from './widget.vue'

export default {
  props: {
    // Device to display
    device: {
      type: Device,
      required: true
    },

    // If `true`, we show compact view, with just a single row of leds representing the device
    // If `false`, we show rows of detailed LEDs for each band
    compact: {
      default: true
    },  },

  components: {
    'sc-widget': WidgetComponent
  },

  computed: {
    ...mapState({
      // Status watch clock ticks, used to enforce refreshing of labels such as status age
      ticks: state => state.deviceStatus.ticks,
      // Status of all currently watched devices
      deviceStatus: state => state.deviceStatus.devices
    }),

    // Status of the viewed device
    status () {
      const { device, deviceStatus } = this
      return deviceStatus[device?.serialNumber]
    },

    // Indicates that the device is a connected one.
    // For non-connected devices there's nothing to show really
    isConnectedDevice () {
      return this.device?.isConnectedDevice
    },

    // Device connection status
    connection () {
      return this.status?.connection || new DeviceStatusConnection()
    },

    // Device status timings
    timings () {
      return this.status?.timings || {}
    },

    // Device status age
    statusAge () {
      return this.ticks ? this.status?.timings?.statusAgeString : ''
    },

    // Device band details
    bands () {
      const { device: { family }, status } = this
      if (family && status?.bands) {
        const { identifiers: bandIdentifiers = [], details: bandDetails, status: bandStatus } = status.bands
        if (bandIdentifiers.length > 0) {
          return bandIdentifiers
            .map(id => {
              const name = getBandLabel(id, family)
              const { status } = bandStatus[id]
              const { description, details, color, borderColor, isAnimated } = bandDetails[id]
              return {
                id,
                name,
                status,
                description: description ? `${name}: ${description}` : '',
                details,
                color,
                borderColor,
                isAnimated
              }
            })
        }
      }
    },

    // CSS class representing a band LED
    ledClass () {
      return band => {
        const { id, status } = band
        return {
          ['band-' + id]: true,
          [status]: true
        }
      }
    },

    // CSS styles representing a band LED
    ledOuterStyle () {
      return band => {
        const { borderColor } = band
        return {
          'border': borderColor ? `solid ${borderColor} 1px` : 'none',
          'padding': borderColor ? '1px' : '0'
        }
      }
    },

    ledStyle () {
      return band => {
        const { color, borderColor } = band
        return {
          'background-color': color,
          'border': borderColor ? `solid ${borderColor} 1px` : 'none'
        }
      }
    }
  }
}
</script>

<template>
  <sc-widget v-bind="{...$props, ...$attrs}">
    <div class="device-leds">
      <div class="leds" v-if="isConnectedDevice && bands">
        <div
          v-for="band in bands"
          :key="band.id"
          class="led-outer"
          :style="ledOuterStyle(band)">

          <sc-tooltip :text="band.description"></sc-tooltip>

          <div
            class="led"
            :class="ledClass(band)"
            :style="ledStyle(band)">
          </div>
        </div>
      </div>

      <div class="status">
        <span v-if="isConnectedDevice && !connection.isUnknown"
          :class="{ [connection.status]: true }"
          :title="connection.error">
          {{ timings.isToday ? timeString(timings.receivedAt) : timings.statusAgeString }}
          <sc-tooltip
            v-if="connection.error"
            :text="connection.error"
            :content-class="{ 'bg-red-7': true, 'text-white': true, 'text-body2': true }">
          </sc-tooltip>
        </span>
      </div>
    </div>
  </sc-widget>
</template>

<style scoped lang="scss">
.device-leds {
  .leds {
    height: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .led-outer {
      height: 20px;
      width: 20px;
      margin-right: 8px;
      display: flex;
      flex-direction: row;
      align-items: center;

      &:last-child {
        margin-right: 0;
      }

      .led {
        height: 8px;
        width: 20px;
        border-radius: 1px;
        transition: all 0.1s;

        &:hover {
          height: 12px;
          border-radius: 1px;
        }

        &.blink {
          animation-name: blink;
          animation-duration: 0.4s;
          animation-iteration-count: infinite;
          animation-direction: alternate;
          background-color: #e20400;
        }
      }
    }
  }

  .status {
    padding-top: 4px;
    font-size: 11px;

    .non-connected {
      color: #020202;
    }

    .unknown {
      color: #da783f;
    }

    .never-connected {
      color: #020202;
    }

    .online {
      color: #2d7e1d;
    }

    .lost {
      color: #5c0000;
    }

    .error {
      color: #c30000;
    }
  }

  @keyframes blink {
    0% {
      opacity: 1
    }
    35% {
      opacity: 1
    }
    65% {
      opacity: 0.2
    }
    100% {
      opacity: 0.2
    }
  }

}

</style>