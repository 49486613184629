import { Assignable } from '../common/assignable'

/**
 * Portal website applicable to organization
 */
export class OrganizationSite extends Assignable {
  constructor (data) {
    super()
    this.assign(data)
  }

  /**
   * Site name
   * @type {String}
   */
  name

  /**
   * Site description
   * @type {String}
   */
  description

  /**
   * Indicates whether the site is a default one
   * @type {Boolean}
   */
  isDefault

  /**
   * Company identity behind the site
   */
  company

  /**
   * Site code
   * @type {String}
   */
  get code () {
    return this.company?.code
  }
}
