export const state = {
  /**
   * Features list
   */
  items: [],

  /**
   * Flat feature dictionary
   */
  feature: {}
}

export const getters = {
  /**
   * Features
   */
  features: state =>
    state.items,

  /**
   * Returns a feature with a given name
   */
  getFeature: state =>
    name => state.feature[name]
}
