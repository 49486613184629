import { parseDate } from '@stellacontrol/utilities'
import { Assignable } from '../common/assignable'

/**
 * Log files bundle
 */
export class LogBundle extends Assignable {
  constructor (data) {
    super()
    this.assign(data)
  }

  normalize () {
    super.normalize()
    this.isCompressed = Boolean(this?.isCompressed)
    this.createdAt = parseDate(this.createdAt)
    this.data = this.data ? Buffer.from(this.data) : undefined
    this.createdAt = this.createdAt || new Date()
  }

  /**
   * Log bundle identifier
   * @type {String}
   */
  id

  /**
   * URL used to download the bundle
   * @type {String}
   */
  url

  /**
   * Date and time when log entry was created
   * @type {Date}
   */
  createdAt

  /**
   * Log category
   * @type {LogCategory}
   */
  category

  /**
   * Entity identifier, such as device serial number,
   * to which the log entry relates
   * @type {String}
   */
  entity

  /**
   * Storage format
   * @type {LogFormat}
   */
  format

  /**
   * Indicates whether the stored data is compressed.
   * Requires that {@link format}) equals to {@link DeviceLogFormat.Binary}
   * @type {Boolean}
   */
  isCompressed

  /**
   * Size of the stored data
   * @type {Number}
   */
  size

  /**
   * Stored data
   * @type {Buffer}
   */
  data

  /**
   * Checks whether bundle has any data
   * @type {Boolean}
   */
  get hasData () {
    return this.data?.length > 0
  }

  /**
   * Clears the bundle data
   */
  clearData () {
    delete this.data
  }
}
