import { dispatch } from '@stellacontrol/client-utilities'
import FilesView from './files.vue'

const name = 'files'

export const Routes = [
  {
    name,
    path: '/files',
    component: FilesView,

    async beforeEnter (to, from, next) {
      await dispatch('requireOrganizations')
      await dispatch('requirePlaces')
      await dispatch('requireDevices')
      next()
    }
  }
]
