import { defaultDialogState, createState } from './dialog.state'
import { DialogResult } from '../../dialogs/dialog-result'

export const mutations = {
  /**
   * Opens the specified popup dialog
   * @param dialog Dialog identifier
   * @param data Dialog data
   * @param noClone If true, data is passed to the dialog in original shape, without creating a local clone
   */
  showDialog (state, { dialog, data, noClone } = {}) {
    if (!dialog) throw new Error('Dialog identifier required')

    // Find or create dialog state
    let dialogState = state.items.find(item => item.dialog === dialog)
    if (!dialogState) {
      dialogState = { ...defaultDialogState, dialog }
      state.items.push(dialogState)
    }

    // Set view data.
    // Store a clone, if clone() method is available
    // as we don't want views with forms to immediately modify the state.
    if (data) {
      if (typeof data.clone === 'function' && !noClone) {
        dialogState.data = data.clone()
      } else {
        dialogState.data = data
      }
    } else {
      dialogState.data = undefined
    }

    dialogState.isVisible = true
    dialogState.result = undefined
  },

  /**
   * Saves dialog data
   * @param dialog Dialog identifier
   * @param data Data to save
   */
  setDialogData (state, { dialog, data } = {}) {
    if (!dialog) throw new Error('Dialog identifier required')

    // Find or create dialog state
    let dialogState = state.items.find(item => item.dialog === dialog)
    if (!dialogState) {
      dialogState = { ...defaultDialogState, dialog }
      state.items.push(dialogState)
    }

    dialogState.data = data
  },

  /**
   * Hides the specified popup dialog
   * @param dialog Optional dialog identifier. If not specified, just any currently shown dialog will be hidden.
   * @param result Result to report, applicable only when dialog identifier is specified
   * @param data Data to report, applicable only when dialog identifier is specified
   */
  hideDialog (state, { dialog, result, data } = {}) {
    if (dialog) {
      let dialogState = state.items.find(item => item.dialog === dialog)
      if (!dialogState) {
        dialogState = { ...defaultDialogState }
        state.items.push(dialogState)
      }

      dialogState.isVisible = false
      dialogState.data = data || dialogState.data
      dialogState.result = result || DialogResult.Cancel
    } else {
      for (const dialogState of state.items) {
        dialogState.isVisible = false
        dialogState.result = DialogResult.Cancel
      }
    }
  },

  /**
   * Resets the state to original shape.
   * @description
   * Presence of this mutation is obligatory on stores,
   * to ensure purging of any user-bound data when
   * user logs in and out and in. Otherwise expect
   * unexpected side effects with data of the previously
   * logged in user appearing at places.
   */
  reset (state) {
    Object.assign(state, createState())
  }
}
