/**
 * ADDING NEW PRODUCT TYPE, STEPS:
 *
 * 1. Add to DeviceType enumeration
 * 2. Add human-friendly name to DeviceTypeName enumeration
 * 3. Add short acronym to DeviceAcronym enumeration
 * 4. If product is a connected device, add to ConnectedDeviceTypes list
 * 5. If product is a multi-unit, add to MultiDeviceTypes list
 * 6. If product is a multi-unit, add detailed definition of unit and its allowed parts in products.json
 * 7. If product is a multi-unit, add to MultiRegionDevices list if applicable
 * 8. If product is not updateable remotely, add its model identifier and eventual specific FW version
 *    to `entities.device.model.nonUpdateable` list in `configuration.default.js`
 *
 */

/**
 * Device types
 */
export const DeviceType = {
  /**
   * Repeater device (connected)
   */
  Repeater: 'repeater',
  /**
   * Line amplifier (connected)
   */
  LineAmp: 'lineamp',
  /**
   * Preamp device (connected)
   */
  Preamp: 'preamp',
  /**
   * Combiner device (connected)
   */
  Combiner: 'combiner',
  /**
   * Switch device (connected)
   */
  Switch: 'switch',
  /**
   * Test Tool
   */
  TestTool: 'tt',
  /**
   * Repeater device (non-connected)
   */
  RepeaterNC: 'repeater-nc',
  /**
   * Line amplifier (non-connected)
   */
  LineAmpNC: 'lineamp-nc',
  /**
   * Preamp device (non-connected)
   */
  PreampNC: 'preamp-nc',
  /**
   * Combiner device (non-connected)
   */
  CombinerNC: 'combiner-nc',
  /**
   * Dual Region Combiner
   */
  RouterAmp2X: 'router-amp-2x',
  /**
   * RouterAmp 2x DNL
   */
  RouterAmp2XDNL: 'router-amp-2x-dnl',
  /**
   * RouterAmp 4x
   */
  RouterAmp4X: 'router-amp-4x',
  /**
  * Dual Region Repeater (EMEA/US)
  */
  DualRegionRepeater: 'dr-repeater',
  /**
   * Dual LineAmp Device (EMEA/US)
   */
  DualRegionLineAmp: 'dr-lineamp',
  /**
  * Dual Region Repeater (EMEA only)
  */
  DualRegionRepeaterEU: 'dr-repeater-eu',
  /**
   * Dual LineAmp Device (EMEA only)
   */
  DualRegionLineAmpEU: 'dr-lineamp-eu',
  /**
   * Other device
   */
  Other: 'other'
}

/**
 * Human-friendly device type names
 */
export const DeviceTypeName = {
  [DeviceType.Repeater]: 'iRepeater',
  [DeviceType.LineAmp]: 'iLineamp',
  [DeviceType.Preamp]: 'iPreamp',
  [DeviceType.Combiner]: 'iCombiner',
  [DeviceType.Switch]: 'iSwitch',
  [DeviceType.RepeaterNC]: 'Repeater',
  [DeviceType.LineAmpNC]: 'Lineamp',
  [DeviceType.PreampNC]: 'Preamp',
  [DeviceType.CombinerNC]: 'Combiner',
  [DeviceType.RouterAmp2X]: 'RA2X WW',
  [DeviceType.RouterAmp2XDNL]: 'RA2X DNL',
  [DeviceType.RouterAmp4X]: 'RA4X EU',
  [DeviceType.DualRegionRepeater]: 'iRD WW',
  [DeviceType.DualRegionLineAmp]: 'iLD WW',
  [DeviceType.DualRegionRepeaterEU]: 'iRD EU',
  [DeviceType.DualRegionLineAmpEU]: 'iLD EU',
  [DeviceType.TestTool]: 'Test Tool',
  [DeviceType.Other]: 'Other'
}

/**
 * Device type acronyms
 */
export const DeviceAcronym = {
  [DeviceType.Repeater]: 'iR',
  [DeviceType.RepeaterNC]: 'R',
  [DeviceType.Combiner]: 'iC',
  [DeviceType.CombinerNC]: 'C',
  [DeviceType.LineAmp]: 'iL',
  [DeviceType.LineAmpNC]: 'L',
  [DeviceType.Preamp]: 'iPA',
  [DeviceType.PreampNC]: 'PA',
  [DeviceType.Switch]: 'iSW',
  [DeviceType.RouterAmp2X]: 'RA-2X-WW',
  [DeviceType.RouterAmp2XDNL]: 'RA-2X-DNL',
  [DeviceType.RouterAmp4X]: 'RA-4X-EU',
  [DeviceType.DualRegionRepeater]: 'iRD-WW',
  [DeviceType.DualRegionLineAmp]: 'iLD-WW',
  [DeviceType.DualRegionRepeaterEU]: 'iRD-EU',
  [DeviceType.DualRegionLineAmpEU]: 'iLD-EU',
  [DeviceType.TestTool]: 'TT',
  [DeviceType.Other]: 'O'
}

/**
 * Multi-device types
 */
export const MultiDeviceTypes = [
  DeviceType.RouterAmp2X,
  DeviceType.RouterAmp2XDNL,
  DeviceType.RouterAmp4X,
  DeviceType.DualRegionRepeater,
  DeviceType.DualRegionLineAmp,
  DeviceType.DualRegionRepeaterEU,
  DeviceType.DualRegionLineAmpEU
]

/**
 * Multi-region device types
 */
export const MultiRegionDeviceTypes = [
  DeviceType.DualRegionRepeater,
  DeviceType.DualRegionLineAmp
]

/**
 * Device types which are internet-connected,
 * as opposed to devices without remote control and monitoring functionality
 */
export const ConnectedDeviceTypes = [
  DeviceType.Repeater,
  DeviceType.Combiner,
  DeviceType.LineAmp,
  DeviceType.Preamp,
  DeviceType.Switch,
  DeviceType.RouterAmp2X,
  DeviceType.RouterAmp2XDNL,
  DeviceType.RouterAmp4X,
  DeviceType.DualRegionRepeater,
  DeviceType.DualRegionLineAmp,
  DeviceType.DualRegionRepeaterEU,
  DeviceType.DualRegionLineAmpEU,
  DeviceType.TestTool
]

/**
 * Device types which are not internet-connected
 */
export const NonConnectedDeviceTypes = Object.values(DeviceType).filter(t => !ConnectedDeviceTypes.includes(t))

/**
 * Returns true if specified device is a connected type
 * @param {DeviceType} type Device type
 * @returns {Boolean}
 */
export function isConnectedDevice (type) {
  return ConnectedDeviceTypes.includes(type)
}

/**
 * Returns true if specified device is a non-connected type
 * @param {DeviceType} type Device type
 * @returns {Boolean}
 */
export function isNonConnectedDevice (type) {
  return NonConnectedDeviceTypes.includes(type)
}

/**
 * Returns true if specified device is a multi-device type
 * @param {DeviceType} type Device type
 * @returns {Boolean}
 */
export function isMultiDevice (type) {
  return MultiDeviceTypes.includes(type)
}

/**
 * Returns true if specified device is a multi-region type
 * @param {DeviceType} type Device type
 * @returns {Boolean}
 */
export function isMultiRegionDevice (type) {
  return MultiRegionDeviceTypes.includes(type)
}

/**
 * Returns human-friendly device type name
 * @param {DeviceType} type Device type
 * @param {DeviceType} defaultValue Default value to return when type is not specified
 * @returns {String}
 */
export function getDeviceTypeName (type, defaultValue) {
  return DeviceTypeName[type] || defaultValue
}

/**
 * Deducts device type from product type
 * @param {String} productType Product type
 * @returns {DeviceType}
 */
export function productTypeToDeviceType (productType) {
  const code = (productType || '').toLowerCase()[0]
  return {
    'r': DeviceType.Repeater,
    'l': DeviceType.LineAmp,
    'p': DeviceType.PreAmp,
    'c': DeviceType.Combiner,
    's': DeviceType.Switch,
    't': DeviceType.TestTool
  }[code]
}