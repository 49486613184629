import { createState } from './users.state'

export const mutations = {
  /**
   * Stores users in the state
   * @param items Users to store
   */
  storeUsers (state, { items = [] } = {}) {
    state.items = items
  },

  /**
   * Stores an user in the state
   * @param user User to store
   */
  storeUser (state, { user } = {}) {
    if (user) {
      if (!state.items) {
        state.items = []
      }
      const { id } = user
      const index = id ? state.items.findIndex(i => i.id === id) : -1
      if (index === -1) {
        state.items.push(user)
      } else {
        state.items[index] = user
      }
      state.lastUpdated = user
    }
  },

  /**
   * Removes an user from the state
   * @param user User to remove
   */
  removeUser (state, { user = {} } = {}) {
    const { id } = user
    if (id) {
      const index = (state.items || []).findIndex(i => i.id === id)
      if (index !== -1) {
        state.items.splice(index, 1)
      }
    }
  },

  /**
   * Reset the state
   */
  reset (state) {
    Object.assign(state, createState())
  }
}
