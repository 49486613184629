import { PlanAction, PlanActions } from './plan-action'

/**
 * Displays help for the plan tool
 */
export class ShowHelpAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.ShowHelp
  }

  /**
  * Action label
  * @type {String}
  */
  get label () {
    return 'Show help'
  }

  /**
   * Action icon
   * @type {String}
   */
  get icon () {
    return 'help'
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   */
  execute ({ renderer } = {}) {
    renderer.showHelp()
  }
}
