/**
 * Standard plan layers
 */
export const PlanLayers = {
  Base: 'base',
  Radiation: 'radiation',
  Background: 'background',
  Selection: 'selection',
  Items: 'items'
}

/**
 * The order of plan layers
 */
export const PlanLayerOrder = {
  [PlanLayers.Base]: 0,
  [PlanLayers.Radiation]: 1,
  [PlanLayers.Background]: 2,
  [PlanLayers.Selection]: 3,
  [PlanLayers.Items]: 4
}

/**
 * Plan layer labels
 */
export const PlanLayerLabels = {
  [PlanLayers.Base]: 'Base',
  [PlanLayers.Radiation]: 'Radiation',
  [PlanLayers.Background]: 'Background',
  [PlanLayers.Selection]: 'Selection',
  [PlanLayers.Items]: 'Items'
}
