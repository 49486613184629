/**
 * MEGA parameter types
 */
export const MegaParameterType = {
  String: 'string',
  Number: 'number',
  Boolean: 'boolean',
  GeoPosition: 'geoposition',
  Date: 'date',
  Array: 'array'
}
