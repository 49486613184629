<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
    }
  },

  computed: {
    ...mapGetters([
    ]),

    // Instructions about creating sample data for organization
    createSampleData () {
      return this.data.createSampleData
    }
  },

  methods: {
    ...mapActions([
    ])
  }
}

</script>

<template>
  <div class="column">
    <div class="row items-center">
      <label class="text-grey-9">
        Use the options below to create sample place populated with simulated devices.
        This will allow the customer to try out the application, even before they
        have purchased any devices.
      </label>
    </div>
    <div class="row items-center q-mt-md">
      <q-checkbox v-model="createSampleData.createPlace" label="Place" size="sm" color="indigo-5" class="q-mr-md" />
      <q-input dense square outlined class="q-pl-md" input-style="background-color: white; padding-left: 8px; width: 208px"
        v-model="createSampleData.placeName"
        :disabled="!createSampleData.createPlace"
      />
    </div>
    <div class="row items-center q-mt-md">
      <q-checkbox v-model="createSampleData.createDevices" label="Simulated devices" size="sm" color="indigo-5" class="q-mr-md" />
      <q-input dense square outlined type="number" min="1" max="10" class="q-pl-md" input-style="background-color: white; padding-left: 8px; padding-right: 4px; width: 130px;"
        v-model="createSampleData.deviceCount"
        :disabled="!createSampleData.createDevices"
      />
    </div>
  </div>
</template>

<style lang='scss' scoped>
</style>
