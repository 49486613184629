import { Notify } from 'quasar'

/**
 * Notification types
 */
export const NotificationType = {
  Information: 'information',
  Success: 'success',
  Question: 'question',
  Warning: 'warning',
  Error: 'error',
  Progress: 'progress'
}

/**
 * Default notification timeout
 */
const defaultTimeout = {
  [NotificationType.Information]: 2000,
  [NotificationType.Success]: 1500,
  [NotificationType.Question]: 3000,
  [NotificationType.Warning]: 3000,
  [NotificationType.Error]: 3000,
  [NotificationType.Progress]: 0
}

/**
 * Action to show inside notification popup
 */
export class NotificationAction {
  constructor (data = {}) {
    Object.assign(this, data)
  }

  /**
   * Action label
   * @type {String}
   */
  label

  /**
   * Button icon
   * @type {String}
   */
  icon

  /**
   * Button color
   * @type {String}
   */
  color

  /**
   * Action handler
   * @type {Function}
   */
  handler
}

/**
 * Notification service for displaying popup notifications,
 * using Quasar Notify plugin.
 */
export class NotificationService {
  /**
   * Shows a notification
   * @param {NotificationType} type Notification type
   * @param {String} message Notification message
   * @param {String} details Notification details
   * @param {String} icon Custom icon. If not specified, notifications will display their default icons.
   * @param {String} color Custom color. If not specified, notifications will use their default colors.
   * @param {String} textColor Custom text color. If not specified, notifications will use their default colors.
   * @param {String} html If true, message can be HTML. Potentially unsafe!
   * @param {Boolean|String} group If true or group name, multiple identical notifications will be grouped
   * @param {Boolean} lineBreaks If true, \n characters are automatically converted into linebreaks
   * @param {Array[String]} classes Custom css classes to apply to the notification popup
   * @param {Number} timeout Time after which the notification is automatically closed
   * @param {Array[NotificationAction]} actions Actions to display in the notification.
   * Action can have following properties: label, icon, color, handler.
   * will be closed automatically, in milliseconds
   * @param {Boolean} silent If true, no notification will be shown.
   * Helps reduce code complexity in some scenarios by eliminating need for `if`
   */
  show ({
    type,
    message,
    details,
    icon: customIcon,
    color: customColor,
    textColor: customTextColor,
    html,
    group,
    lineBreaks = true,
    classes: customClasses,
    timeout,
    actions = [{ icon: 'close' }],
    silent
  }) {
    if (silent) {
      // Notification returns a function which allows to close it programmatically.
      // When silent, return a dummy to save on null checks.
      return () => { }
    }

    // Determine timeout depending on message type
    timeout = timeout == null ? defaultTimeout[type] : timeout


    const color = customColor || {
      [NotificationType.Information]: 'primary',
      [NotificationType.Success]: 'positive',
      [NotificationType.Question]: 'secondary',
      [NotificationType.Warning]: 'deep-orange-8',
      [NotificationType.Error]: 'negative',
      [NotificationType.Progress]: 'blue-grey-7'
    }[type]

    const icon = customIcon || {
      [NotificationType.Information]: 'info',
      [NotificationType.Success]: 'check_circle',
      [NotificationType.Question]: 'help',
      [NotificationType.Warning]: 'warning',
      [NotificationType.Error]: 'error',
      [NotificationType.Progress]: undefined
    }[type]

    actions = (actions || []).map(action => ({ ...action, color: 'white' }))
    const textColor = customTextColor || 'white'
    const classes = ['q-pa-md', ...(customClasses || [])].join(' ')

    message = (message || '').trim()
    if (message.includes('<script')) {
      throw new Error('Unsafe notification message')
    }

    if (message.includes('<') && message.includes('>')) {
      html = true
    }

    if (message.includes('\n') && lineBreaks) {
      html = true
      message = message.replace('\n', '<br>')
    }

    details = (details || '').trim()
    if (details.includes('<script')) {
      throw new Error('Unsafe notification message')
    }

    if (details.includes('<') && details.includes('>')) {
      html = true
    }

    if (details.includes('\n') && lineBreaks) {
      html = true
      details = details.replace('\n', '<br>')
    }

    return Notify.create({
      message,
      caption: details,
      html,
      color,
      textColor,
      classes,
      timeout,
      actions,
      icon,
      group
    })
  }

  /**
   * Shows an information
   * @param options Notification options or just a string message
   */
  info (options = {}) {
    if (typeof options === 'string') {
      return this.show({ type: NotificationType.Information, details: options, message: 'Information' })
    } else {
      return this.show({ type: NotificationType.Information, ...options })
    }
  }

  /**
   * Shows a success information
   * @param options Notification options or just a string message
   */
  success (options = {}) {
    if (typeof options === 'string') {
      return this.show({ type: NotificationType.Success, details: options, message: 'Success' })
    } else {
      return this.show({ type: NotificationType.Success, ...options })
    }
  }

  /**
   * Shows a warning information
   * @param options Notification options or just a string message
   */
  warning (options = {}) {
    if (typeof options === 'string') {
      return this.show({ type: NotificationType.Warning, details: options, message: 'Warning' })
    } else {
      return this.show({ type: NotificationType.Warning, ...options })
    }
  }

  /**
   * Shows an error
   * @param options Notification options or just a string message
   */
  error (options = {}) {
    if (typeof options === 'string') {
      return this.show({ type: NotificationType.Error, details: options, message: 'Error' })
    } else {
      return this.show({ type: NotificationType.Error, ...options })
    }
  }

  /**
   * Shows a question notification
   * @param options Notification options
   */
  question (options = {}) {
    if (!options.actions) {
      throw new Error('Question notification is supposed to provide some actions')
    }
    const questionOptions = {
      ...options,
      // Make the notification stay on screen by default, unless told otherwise
      // (which would be fun, user racing to answer the question ;-)
      timeout: options.timeout || 0
    }

    return this.show({ type: NotificationType.Question, ...questionOptions })
  }

  /**
   * Shows a progress notification
   * @param options Notification options
   */
  progress (options = {}) {
    const progressOptions = {
      ...options,
      timeout: options.timeout || 0,
      html: true,
      details: undefined,
      group: false,
      message: getProgressMessage(options.message, options.details),
      classes: ['notification', 'popup']
    }

    // Decorate notification handler with progress() function allowing caller to update progress message
    const notificationHandler = this.show({ type: NotificationType.Progress, ...progressOptions })
    notificationHandler.progress = ({ message, details, icon } = {}) => {
      if (!options.silent) {
        notificationHandler({
          message: getProgressMessage(message, details, icon),
          actions: [],
          html: true
        })
      }
    }

    return notificationHandler
  }
}

/**
 * Returns HTML markup of message displayed inside progress notification
 * @param {String} message Message to display
 * @param {String} details Details to display
 * @param {String} icon Icon to show
 * @returns HTML markup of message displayed inside progress notification
 */
function getProgressMessage (message, details, icon) {
  return `
    <div style="display: flex; flex-direction: row; align-items: center; min-width: 300px;">
      <i
        class="material-icons q-icon notranslate rotate-reverse-slow"
        style="font-size: 28px; margin-right: 12px;">
        ${icon || 'settings'}
      </i>
      <div>
        <div>
          ${message}
        </div>
        <div>
          ${details || ''}
        </div>
      </div>
    </div>
  `
}