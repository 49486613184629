/**
 * Global store instance
 */
let Store = null

/**
 * Returns the global state store.
 * Normally you shouldn't use it - it's only used internally
 * here, in global error handlers in /errors module.
 */
export function setStore (store) {
  Store = store
}

/**
 * Returns the global state store.
 * Normally you shouldn't use it - it's only used internally
 * here, in global error handlers in /errors module.
 */
export function getStore () {
  return Store
}

/**
 * Returns the global state.
 * Normally you shouldn't use it - it's only used internally
 * here, in global error handlers in /errors module.
 */
export function getState () {
  return Store ? Store.state : undefined
}
