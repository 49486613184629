<script>
import { mapActions, mapState } from 'vuex'
import { capitalize } from '@stellacontrol/utilities'
import { FormMixin } from '@stellacontrol/client-utilities'
import { AvailableProducts, isAvailableProductPart, isAllowedAsProductPart, canAddPartToDevice, getPossibleProductsForPart, Device, DeviceLinkType, getDeviceLabel } from '@stellacontrol/model'
import DeviceAction from './device-action.vue'
import { DeviceActionMixin } from './device-action-mixin'

export default {
  mixins: [
    DeviceActionMixin,
    FormMixin
  ],

  components: {
    'sc-device-action': DeviceAction
  },

  data () {
    return {
      // Details of the edited multi-device or master device of the edited part
      deviceDetails: null,
      // Selected multi-device
      multiDeviceId: null,
      // Selected product identifier
      productId: null,
      // Product serial number
      serialNumber: null,
      // Devices which are part of the multi-device
      availableParts: []
    }
  },

  computed: {
    ...mapState({
      allDevices: state => state.devices.devices || []
    }),

    // List of products to select from
    products () {
      return AvailableProducts
        .map(({ id, name, type, model }) => ({ id, name, type, model }))
    },

    // Currently selected product
    selectedProduct () {
      return AvailableProducts.find(p => p.id === this.productId)
    },

    // Returns current parts of the specified multi-device
    partsOf () {
      return device => this.allDevices.filter(d => d.partOf === device.id)
    },

    // Available multi-devices under the same owner,
    // which can have the selected device as their part
    multiDevices () {
      const { partsOf, device: { ownerId, type, model } = {} } = this
      return this.allDevices
        .filter(d => d.ownerId === ownerId && d.isMultiDevice && d.product && d.product.canBePart(type, model))
        .filter(d => canAddPartToDevice(d, type, model, partsOf(d)))
        .map(d => ({
          id: d.id,
          label: getDeviceLabel(d)
        }))
    },

    // List of products to which the selected boards can be added
    possibleProducts () {
      const { device: { type, model } = {} } = this
      return getPossibleProductsForPart(type, model)
    },

    // Indicates whether the selected device can be a part of any product
    canBePart () {
      return this.possibleProducts.length > 0
    },

    // Device parts selected for the multi-device
    selectedParts () {
      return this.availableParts.filter(part => part.isSelected)
    },

    // Returns true if there's only one device selected and it's a multi-device
    isEditingMultiDevice () {
      return this.isSingle && this.device.isMultiDevice
    },

    // Returns true if there's only one device selected and it's a potential part of a multi-device
    isAddingMultiDevicePart () {
      const { isSingle, device: { partOf, isMultiDevice, type, model } = {} } = this
      return isSingle && !isMultiDevice && !partOf && isAvailableProductPart(type, model)
    },

    // Returns true if editing a new multi-device
    isNewMultiDevice () {
      return this.isBatch && this.devices.every(d => !d.partOf && isAvailableProductPart(d.type, d.model))
    },

    // Label for EXECUTE button
    executeLabel () {
      return this.isNewMultiDevice
        ? 'Create'
        : (this.isAddingMultiDevicePart ? 'Add part' : 'Save')
    },

    // Returns firmware version string of a specified device.
    // Depending on permissions, this will show the build version or not.
    firmwareVersion () {
      return device => {
        const version = device.firmwareVersionLong
        return version ? `v.${version}` : ''
      }
    },

    // Checks whether the specified device is allowed as part of a multi-device.
    // If product type has been selected, it also checks whether the device
    // is allowed as part of the currently selected product
    isAllowedAsPart () {
      return device => {
        const { selectedProduct } = this
        let isAllowed = isAvailableProductPart(device.type, device.model)
        if (isAllowed && selectedProduct) {
          isAllowed = isAllowedAsProductPart(selectedProduct.id, device.type, device.model)
        }
        return isAllowed
      }
    },

    // Checks if there are any data validation errors
    validationError () {
      const { isEditingMultiDevice, isNewMultiDevice, isAddingMultiDevicePart, owner } = this

      if (isEditingMultiDevice || isNewMultiDevice) {
        const { serialNumber, selectedProduct, selectedParts } = this
        // Serial number is required
        if (!(serialNumber || '').trim()) return 'Serial number is required'
        // Product type is required
        if (!selectedProduct) return 'Product type is required'
        // If creating new multi device, all required parts must be selected
        if (isNewMultiDevice && selectedParts.length !== selectedProduct.parts.length) return 'All required parts of the multi-device must be selected'
      }

      if (isAddingMultiDevicePart) {
        const { multiDevices, deviceDetails: multiDevice, selectedProduct, device: { type, model } = {} } = this
        if (multiDevices.length === 0) return `There aren't any multi-devices in ${owner?.name || ''} stock, to which this board could be added.`
        if (!multiDevice) return true
        if (!selectedProduct) return 'Unknown multi-device unit'
        if (!isAllowedAsProductPart(selectedProduct.id, type, model)) 'This board type is not allowed on the selected multi-device unit'
        if (!canAddPartToDevice(multiDevice, type, model, multiDevice.parts)) return 'This board type is already present on the selected multi-device unit'
      }
    },

    // Returns true if changes can be saved
    canSave () {
      const { validationError, isEditingMultiDevice, isNewMultiDevice, isAddingMultiDevicePart } = this
      return !validationError && (isEditingMultiDevice || isNewMultiDevice || isAddingMultiDevicePart)
    }
  },

  methods: {
    ...mapActions([
      'getDevice',
      'getDeviceBySerialNumber',
      'saveDevice',
      'splitMultiDevice',
      'addPartToMultiDevice'
    ]),

    capitalize,
    getDeviceLabel,

    // Populates the view
    async populate () {
      try {
        this.isLoading = true
        this.deviceDetails = null

        if (this.isEditingMultiDevice) {
          // When editing a multi-device, show its parts
          this.deviceDetails = await this.getDevice({ id: this.device.id, withDetails: true })
          this.productId = this.deviceDetails.product?.id
          this.serialNumber = this.deviceDetails.serialNumber

        } else if (this.isAddingMultiDevicePart) {
          // When adding part to multi-device, load its details
          this.productId = null
          if (this.multiDeviceId) {
            if (this.multiDevices.find(d => d.id === this.multiDeviceId)) {
              this.deviceDetails = await this.getDevice({ id: this.multiDeviceId, withDetails: true })
              this.productId = this.deviceDetails?.product?.id
            } else {
              this.multiDeviceId = null
            }
          }
        }

        await this.populateParts()

      } finally {
        this.isLoading = false
      }
    },

    // Populates the multi-device details,
    // if adding a part to multi-device
    async populateMultiDevice () {
      try {
        this.isLoading = true
        this.productId = null
        this.deviceDetails = null

        if (this.isAddingMultiDevicePart && this.multiDeviceId) {
          this.deviceDetails = await this.getDevice({ id: this.multiDeviceId, withDetails: true })
          this.productId = this.deviceDetails?.product?.id
        }

        await this.populateParts()

      } finally {
        this.isLoading = false
      }
    },

    // Populates the list of parts of the selected multi-device,
    // or list of parts which can be selected as parts of the newly created multi-device
    async populateParts () {
      this.availableParts = []
      const { devices, deviceDetails } = this

      const parts = deviceDetails
        ? deviceDetails?.parts || []
        : (devices || []).filter(device => this.isAllowedAsPart(device))

      this.availableParts = parts.map(device => ({
        id: device.id,
        serialNumber: device.serialNumber,
        label: [
          `${device.serialNumber}`,
          `${device.getModel(this.currentUser)}`,
          `${this.firmwareVersion(device)}`]
          .filter(s => s).join(', '),
        isSelected: true
      }))
    },

    // Saves the changes
    async save () {
      if (await this.validate()) {
        try {
          this.isLoading = true
          const { isEditingMultiDevice, isAddingMultiDevicePart, serialNumber, selectedParts, selectedProduct: { type, model, resellerModel } } = this

          let device

          if (isAddingMultiDevicePart) {
            // Add part to the specified multi-device
            await this.addPartToMultiDevice({
              device: this.deviceDetails,
              part: this.device
            })

          } else {
            if (isEditingMultiDevice) {
              // Edit existing multi-device
              device = this.device
            } else {
              // Create new multi-device
              device = new Device()
              device.linkWith(this.owner, DeviceLinkType.Owner, this.currentUser)
            }
            device.serialNumber = serialNumber
            device.type = type
            device.model = model
            device.resellerModel = resellerModel
            device.parts = selectedParts

            await this.saveDevice({ device })
          }
        } finally {
          this.isLoading = false
        }

        this.executed()
      }
    },

    // Selects/unselects a part of the multi-device
    togglePart (id, isSelected) {
      const part = this.availableParts.find(p => p.id === id)
      if (part) {
        part.isSelected = isSelected
      }
    },

    // Validation rule which checks whether the specified serial number is already in use
    async serialNumberIsUnique (serialNumber) {
      const device = await this.getDeviceBySerialNumber({ serialNumber })
      return !device || device.id === this.device.id || `Device ${serialNumber} already exists`
    }
  },

  watch: {
    // Refresh on device selection change
    async devices () {
      await this.populate()
    },

    // Refresh available product parts on product selection
    async productId () {
      await this.populateParts()
    },

    // Retrieve details of the selected multi-device
    // if adding part to it
    async multiDeviceId () {
      await this.populateMultiDevice()
    }
  },

  async created () {
    await this.populate()
  }
}
</script>

<template>
  <sc-device-action :action="action" :devices="devices" :execute-label="executeLabel" @close="close"
    @execute="save" :canExecute="canSave" :isLoading="isLoading">

    <section>
      <ul class="requirements q-mt-md" v-if="canBePart">
        <li>Main unit and all boards must be owned by the same customer</li>
        <li>Board must not be already added to another multi-unit</li>
        <li>
          The board can be added only to the following multi-unit types:
          <ul class="possible-products">
            <li v-for="product of possibleProducts">
              {{ product.name }}
            </li>
          </ul>
        </li>
      </ul>
    </section>

    <!-- Add part to existing multi-device -->
    <q-form ref="form" autofocus class="q-mt-md q-gutter-sm" v-if="isAddingMultiDevicePart"
      @submit.prevent>
      <div v-if="multiDevices.length > 0">
        <div class="q-mb-sm">
          <label class="text-body2 text-grey-9">
            Add {{ devicesLabel }} board to the selected multi-device:
          </label>
        </div>
        <q-select square outlined clearable class="q-mb-md" v-model="multiDeviceId"
          :options="multiDevices" emit-value map-options option-value="id" option-label="label">
        </q-select>
      </div>

      <div class="q-mb-sm" v-if="selectedProduct">
        <label class="text-body2 text-grey-9">
          {{ selectedProduct.description }}
        </label>
        <div class="row items-center q-mt-sm" v-for="part in selectedProduct.parts">
          <q-icon name="memory" size="sm" color="grey-5" class="q-ml-md q-mr-sm"></q-icon>
          <span>{{ part.model }} {{ part.name }}</span>
        </div>
      </div>

      <div class="q-mb-sm q-mt-sm" v-if="deviceDetails && deviceDetails.parts">
        <label class="text-body2 text-grey-9">
          {{ deviceDetails.parts.length > 0
            ? 'Assigned parts:'
            : 'The device has no parts assigned yet' }}
        </label>
        <div class="row items-center q-mt-sm" v-for="part in deviceDetails.parts">
          <q-icon name="memory" size="sm" color="green-5" class="q-ml-md q-mr-sm"></q-icon>
          <span>
            <router-link class="item-link"
              :to="{ name: 'inventory', query: { selection: part.serialNumber } }">
              {{ getDeviceLabel(part) }}
            </router-link>
          </span>
        </div>
      </div>

      <div class="border q-pa-md text-orange-8" v-if="validationError && validationError !== true">
        <div>
          {{ validationError }}
        </div>
      </div>

    </q-form>

    <!-- Edit new or existing multi-device -->
    <q-form ref="form" autofocus class="q-mt-md q-gutter-sm" @submit.prevent
      v-else-if="isEditingMultiDevice || isNewMultiDevice">
      <div class="q-mb-sm">
        <label class="text-body2 text-grey-9">
          Product Type:
        </label>
      </div>

      <q-select square outlined clearable class="q-mb-md" v-model="productId" :options="products"
        emit-value map-options option-value="id" option-label="name">
      </q-select>

      <div class="q-pa-sm q-mb-md border" v-if="selectedProduct">
        <label class="text-body2 text-grey-9">
          {{ selectedProduct.description }}
        </label>
        <div class="row items-center q-mt-sm" v-for="part in selectedProduct.parts">
          <q-icon name="memory" size="sm" color="grey-5" class="q-ml-md q-mr-sm"></q-icon>
          <span>{{ part.model }} {{ part.name }}</span>
        </div>
      </div>

      <div class="q-mb-sm">
        <label class="text-body2 text-grey-9">
          Serial Number:
        </label>
      </div>

      <q-input square outlined clearable class="q-mb-md" v-model="serialNumber" hide-bottom-space
        debounce="1000" :rules="[
          rules.required('Unique name or serial number is required'),
          value => serialNumberIsUnique(value)
        ]">
      </q-input>

      <div class="q-mb-sm">
        <label class="text-body2 text-grey-9">
          Product Parts:
        </label>
      </div>

      <div class="border q-pa-sm">
        <div v-for="part in availableParts" v-if="availableParts.length > 0" class="row items-center">
          <q-checkbox size="sm" color="indigo-5" :model-value="part.isSelected" :label="part.label"
            @update:model-value="value => togglePart(part.id, value)">
            <sc-tooltip :text="`Uncheck to remove ${part.serialNumber} from the device`"></sc-tooltip>
          </q-checkbox>

          <router-link class="item-link" @click.stop
            :to="{ name: 'inventory', query: { selection: part.serialNumber } }">
            <q-icon name="chevron_right" size="sm" color="indigo-6" class="q-ml-sm"></q-icon>
            <sc-tooltip text="Select the board in the inventory"></sc-tooltip>
          </router-link>

        </div>
        <div v-else-if="selectedProduct && isNewMultiDevice" class="text-orange-8">
          You cannot create a {{ selectedProduct.name }} from these devices.
          None of them is included in the product specification.
        </div>
        <div v-else-if="availableParts.length === 0" class="text-orange-8">
          This multi-device has no parts assigned.
        </div>
      </div>

    </q-form>

    <!-- The selected device(s) cannot be made into a multi-device board, nor they are multi-devices themselves -->
    <div v-else class="q-pa-md text-orange-8">
      <span v-if="device.partOf">
        The board is already a part of a multi-device.
      </span>
      <span v-else>
        The board is not allowed as part of a any multi-device unit.
      </span>
    </div>

  </sc-device-action>
</template>

<style lang="scss" scoped>
.border {
  border: solid #0000003d 1px;
}

.requirements,
.possible-products {
  padding-left: 18px;
}
</style>
