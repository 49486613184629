import PremiumServiceSelector from './premium-service-selector.vue'

/**
 * Installs Servic eManagement UI components into the application
 * @param application Vue application instance
 */
export function useServiceManagementComponents (application) {
  const plugin = {
    install (application) {
      const items = [
        {
          tag: 'sc-premium-service-selector', component: PremiumServiceSelector
        }
      ]

      for (const { tag, component } of items) {
        application.component(tag, component)
      }
    }
  }

  application.use(plugin)
}
