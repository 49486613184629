import { createState } from './premium-services-audit-view.state'
import { startOfDay, endOfDay, subDays } from 'date-fns'

export const mutations = {
  /**
   * Stores audit items in the state
   * @param {Array[AuditItem]} items Audit items to store
   */
  storePremiumServicesAudit (state, { items = []} = {}) {
    state.items = items
  },

  /**
   * Filters the displayed audit items
   * @param {Array[AuditSubject]} subjects Optional list of audit subjects to return
   * @param {Date} olderThan If specified, only events before the specified date and time will be retrieved
   * @param {Date} newerThan If specified, only events after the specified date and time will be retrieved
   * @param {Number} rowsPerPage If specified, sets maximal rows displayed per page
   */
  filterPremiumServicesAudit (state, { subjects, olderThan, newerThan, rowsPerPage } = {}) {
    state.filter.subjects = subjects || state.filter.subjects
    state.filter.rowsPerPage = rowsPerPage || state.filter.rowsPerPage

    // Time range sanity checks
    state.filter.olderThan = endOfDay(olderThan || state.filter.olderThan || new Date())
    state.filter.newerThan = startOfDay(newerThan || state.filter.newerThan || subDays(state.filter.olderThan, 7))
    if (state.filter.olderThan < state.filter.newerThan) {
      state.filter.olderThan = endOfDay(state.filter.newerThan)
    } else if (state.filter.newerThan > state.filter.olderThan) {
      state.filter.newerThan = startOfDay(state.filter.olderThan)
    }
  },

  /**
   * Resets the store to defaults
   */
  reset (state) {
    Object.assign(state, createState())
  },

  /**
   * Resets the store after navigations.
   * Retains some parts of the state such as last-used filter.
   */
  resetPremiumServicesAuditView (state) {
    Object.assign(state, createState({
      filter: state.filter
    }))
  }
}
