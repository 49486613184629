<script>
import DeviceWidget from './device-widget'

export default {
  mixins: [
    DeviceWidget
  ],

  computed: {
    batteryInUse () {
      const { status } = this
      return status?.health?.batteryInUse
    },

    // Items to display
    items () {
      const { batteryInUse } = this
      if (batteryInUse) {
        const items = [
          { text: 'Mains power lost on the device', class: { 'label': true } }
        ]
        return items
      }
      return []
    },
  }
}
</script>

<template>
  <sc-widget-text
    v-if="batteryInUse"
    icon="battery_alert" iconColor="orange-7" iconSize="60px" :items="items">
  </sc-widget-text>
</template>
