/**
 * Announcement delivery channels
 */
export const AnnouncementChannel = {
  Popup: 'popup',
  Email: 'email',
  SMS: 'SMS',
  Chat: 'chat'
}

/**
 * Announcement channel descriptions
 */
export const AnnouncementChannels = {
  [AnnouncementChannel.Popup]: {
    name: 'Popup',
    description: 'Message displayed in the platform user interface',
    icon: 'computer'
  },
  [AnnouncementChannel.Email]: {
    name: 'Email',
    description: 'Message delivered by e-mail',
    icon: 'mail'
  },
  [AnnouncementChannel.SMS]: {
    name: 'SMS Message',
    description: 'Message delivered by SMS message',
    icon: 'phone_android'
  },
  [AnnouncementChannel.Chat]: {
    name: 'Chat Message',
    description: 'Message delivered to via instant chat application',
    icon: 'chat'
  }
}
