<script>
import { mapActions } from 'vuex'
import { CellMixin } from './cell-mixin'

export default {
  mixins: [
    CellMixin
  ],

  props: {
    item: {
      type: Object,
      required: true,
    }
  },

  methods: {
    ...mapActions([
      'setDeviceName'
    ])
  }
}
</script>

<template>
  <div :style="columnStyle" class="text clickable clip">
    <span class="cursor-pointer">
      {{ item.device.name || '-' }}
    </span>
    <q-popup-edit :class="{ 'bg-grey-1': true }" square buttons
      label-set="Save"
      v-slot="scope"
      v-model="item.device.name"
      :cover="false"
      :title="`Enter custom name of ${item.device.acronym} ${item.device.serialNumber}`"
      @save="name => setDeviceName({ device: item.device, name })">
         <q-input autofocus max-length="255"
          label="Device name"
          v-model="scope.value"
          @keyup.enter="scope.set">
         </q-input>
    </q-popup-edit>
  </div>
</template>

<style>
</style>