import { Log } from '@stellacontrol/utilities'
import { EntityType } from '@stellacontrol/model'
import { PushClient } from './push-client'

/**
 * Push notification client for Common API
 */
export class CommonPushClient extends PushClient {
  constructor () {
    super()
    this.name = 'common'
  }

  /**
   * Push services configuration
   * @type {Object}
   */
  configuration

  /**
   * Initializes the push client
   * @param {Object} configuration Push services configuration
   */
  initialize (configuration) {
    if (!configuration) throw new Error('Push services configuration is required')
    if (!configuration.endpoints) throw new Error('Push services endpoint configuration is required')
    if (!configuration.endpoints.common) throw new Error('Common Push Server endpoint is required')

    this.configuration = configuration
  }

  /**
   * Checks whether the push client has been initialized
   * @returns {Boolean}
   */
  get isInitialized () {
    return Boolean(this.configuration)
  }

  /**
   * Subscribes to application flags updates
   * @param {Function<ApplicationFlags>} onMessage Handler called when application flags arrive
   * @param {Number} reconnectAttempts If greater than zero, the connection will be automatically restored when lost, until reconnection attempts run out
   * @param {Number} reconnectInterval Interval between reconnect attempts, in milliseconds
   * @returns {CommonPushClient} Client instance if succesfully subscribed to application flags updates
   */
  async subscribeToApplicationFlags ({ onMessage, reconnectAttempts = PushClient.RECONNECT_INFINITE, reconnectInterval = PushClient.RECONNECT_INTERVAL } = {}) {
    if (!this.isInitialized) throw new Error('Common Push Client is not initialized')
    if (!onMessage) throw new Error('Application Flags handler is required')

    const url = `${this.configuration.endpoints.common}/push/subscribe`
    const delay = 1000
    const message = {
      items: [{
        dataType: EntityType.ApplicationFlags,
        entityType: EntityType.ApplicationFlags
      }]
    }

    const subscribed = await this.subscribe({
      url,
      message,
      delay,
      onMessage,
      authenticate: true,
      reconnectAttempts,
      reconnectInterval
    })

    if (subscribed) {
      Log.debug(`[${url}] Subscribed to application updates`)
      return this
    }
  }
}
