<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    // Identifier of the dialog
    dialog: {
      type: String,
      required: true
    },
    // Custom CSS classes to add to the dialog
    cssClass: Object,
    // If set to true, dialog will not be dismissible with ESC
    persistent: Boolean,
    // If set to true, dialog border will not be rounded
    square: Boolean
  },

  computed: {
    ...mapGetters([
      'isDialogVisible',
      'getDialogData'
    ]),

    isVisible () {
      return this.isDialogVisible(this.dialog)
    },

    data () {
      return this.getDialogData(this.dialog)
    }
  },

  watch: {
    isVisible (newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.dialogShown()
        } else {
          this.dialogHidden()
        }
      }
    }
  },

  methods: {
    ...mapActions([
      'dialogCancel'
    ]),

    dialogClose () {
      const { dialog } = this
      this.dialogCancel({ dialog })
    },

    dialogShown () {
      this.$emit('dialogShown')
    },

    dialogHidden () {
      this.$emit('dialogHidden')
    }
  }
}

</script>

<template>
  <q-dialog ref="dialog" class="dialog" :class="cssClass" v-if="isDialogVisible(dialog)"
    v-bind="{ ...$props, ...$attrs }" :model-value="true" :persistent="persistent" no-shake
    @update:model-value="() => dialogClose()" transition-show="none" transition-hide="none">

    <slot name="header">
    </slot>

    <q-card class="dialog-content" :square="square">
      <slot v-bind:data="data">
      </slot>
    </q-card>

    <slot name="footer">
    </slot>

  </q-dialog>
</template>

<style lang="scss" scoped>
.dialog-content {
  display: flex;
  max-width: none;
}
</style>

<style lang="scss">
.dialog-right-bottom .q-dialog__inner {
  position: absolute;
  top: unset !important;
  left: unset !important;
  right: 8px !important;
  bottom: 0 !important;
}

.dialog-left-bottom .q-dialog__inner {
  position: absolute;
  top: unset !important;
  right: unset !important;
  left: 8px !important;
  bottom: 0 !important;
}

.dialog-right-top .q-dialog__inner {
  position: absolute;
  bottom: unset !important;
  left: unset !important;
  right: 8px !important;
  top: 8px !important;
}

.dialog-left-top .q-dialog__inner {
  position: absolute;
  bottom: unset !important;
  right: unset !important;
  left: 8px !important;
  top: 8px !important;
}
</style>