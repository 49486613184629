import { assign } from '@stellacontrol/utilities'

/**
 * Application feature, which can be used or executed by the user
 */
export class Feature {
  constructor (data = {}) {
    assign(this, {
      isEnabled: true,
      isSecure: true,
      ...data
    })
  }

  /**
   * Unique name
   * @type {String}
   */
  name

  /**
   * Short label
   * @type {String}
   */
  label

  /**
   * User-friendly feature description
   * @type {String}
   */
  description

  /**
  /**
   * Principal-dependent feature descriptions
   * Sometimes a feature has a slightly different description depending on the context
   * in which it is granted, for example different for organization than for user.
   * @type {Dictionary<PrincipalType, String>}
   */
  descriptions

  /**
   * Further details and explanations
   * @type {String}
   */
  details

  /**
   * Environment on which the feature should be available
   * @type {Environment}
   */
  environment

  /**
   * Default access to the feature. If true, the feature is always allowed, otherwise it requires explicit permission
   * @type {Boolean}
   */
  isSecure

  /**
   * Used to globally enable or disable the feature for all organizations and users.
   * In code rather use `isDisabled` as it looks up the hierarchy whether
   * parents are enabled as well.
   * @type {Boolean}
   */
  isEnabled

  /**
   * Indicates that this is a premium feature.
   * Customers who run under subscription plans not only need a permission for this feature,
   * but also have to pay for it, using tokens.
   * @type {Boolean}
   */
  isPremium

  /**
   * Indicates that this feature, when granted to principal,
   * is a requirement rather than permission.
   * With permission granted, a principal is normally able to decide
   * whether to pass on this permission to his child organizations.
   * But with requirement granted, principal must not be able
   * to decide on his own, whether the requirement should apply to his
   * child organization. This is rather controlled by child's profile defaults.
   * @type {Boolean}
   */
  isRequirement

  /**
   * Recursively checks whether the feature is disabled -
   * either directly or by one of its parents
   * @type {Boolean}
   */
  get isDisabled () {
    return !this.isEnabled || (this.parent ? this.parent.isDisabled : false)
  }

  /**
   * List of principal types to which this feature applies.
   * Sometimes a feature only makes sense in context of a specific user, in which case
   * we'd specify `"subject": ["user"]` in feature definition.
   * When editing permissions of other principal type, such feature will not be visible.
   * @type {Array[PrincipalType]}
   */
  subject

  /**
  * Indicates the required principal level for this feature,
  * such as 'user', 'guest', 'administrator', 'super-organization' etc.
  * Only principals at the specified level can be granted access to the feature.
  * For other principals the feature will not be visible in the permission editor.
  * @type {OrganizationLevel|UserLevel}
  */
  level

  /**
   * List of features which must have been granted to the principal,
   * to avail of this feature as well
   * @type {Array[String]}
   */
  requires

  /**
   * If these features have been granted to the principal,
   * this feature will not be available (the opposite of {@link requires}).
   * Additionally, the feature won't be visible in the editor.
   * @type {Array[String]}
   */
  notApplicableWhen

  /**
   * If specified, only organizations of these levels can grant this permission.
   * If editor doesn't have the required level, the permission will be visible,
   * but not available for editing. If you need to hide it altogether,
   * use {@link visibleFor} instead
   * @type {Array[OrganizationLevel]}
   */
  grantedBy

  /**
   * If specified, only organizations of these levels can see this permission.
   * If editor doesn't have the required level, the permission will be hidden,
   * If you need to show it and only block from editing, use {@link grantedBy} instead
   * use {@link visibleFor} instead
   * @type {Array[OrganizationLevel]}
   */
  visibleFor

  /**
   * Child features
   * @type {Array[Feature]}
   */
  features

  /**
   * Returns true if feature has child features
   * @type {Boolean}
   */
  get hasChildren () {
    const { length } = this.features || []
    return length > 0
  }

  // RUNTIME PROPERTIES --------------------------------------------------

  /**
   * Parent feature
   * @type {Feature}
   */
  parent

  /**
   * Indicates that the feature is applicable under the current security context
   * @type {Boolean}
   */
  isApplicable

  /**
   * Overrides serialization to prevent serializing of certain
   * runtime-only properties
   * @returns {Object}
   */
  toJSON () {
    const result = { ...this }
    delete result.parent
    delete result.isApplicable
    return result
  }

  /**
   * Creates a deep clone of the feature tree
   * @param parent Parent feature
   */
  clone (parent) {
    const data = { ...this, parent, isApplicable: undefined }
    const feature = new Feature(data)
    if (this.hasChildren) {
      feature.features = this.features.map(f => f.clone(feature))
    }
    return feature
  }

  /**
   * Returns a flat list of feature and its children
   */
  flat () {
    if (this.hasChildren) {
      return [this, ...this.features.flatMap(feature => feature.flat())]
    } else {
      return [this]
    }
  }

  /**
   * Traverses the feature tree
   * running the specified handler on each feature.
   * If handler returns truthy value on a node,
   * traverse is stopped and the value is returned.
   * @param {Function} handler Handler to run on each feature
   * @returns {any} Result returned by the handler on any of the traversed features
   */
  traverse (handler) {
    let result = handler(this)
    if (!result) {
      if (this.hasChildren) {
        for (const child of this.features) {
          result = child.traverse(handler)
          if (result) {
            break
          }
        }
      }
    }
    return result
  }

  /**
   * Finds a feature with the specified name
   * @param {String} name Feature name
   * @returns {Feature}
   */
  find (name) {
    return this.traverse(feature =>
      (feature.name === name ? feature : undefined))
  }
}
