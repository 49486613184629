import { state, getters } from './planner.state'
import * as mutations from './planner.mutations'
import * as actions from './planner.actions'

export default {
  state,
  getters,
  mutations,
  actions
}
