<script>
import { mapState } from 'vuex'
import { PlanPalette, PlanLayers, PlannerMode } from '@stellacontrol/planner'

export default {
  components: {
  },

  props: {
    // Plan renderer
    renderer: {
      required: true
    },
    // Indicates whether adding items to the plan is now possible
    enabled: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      // Palette configuration
      palette: PlanPalette
    }
  },

  computed: {
    ...mapState({
      // Planner editing mode
      mode: state => state.planner.mode
    }),

    // Determines whether the planner allows full feature set
    isAdvancedMode () {
      return this.mode === PlannerMode.Advanced
    },

    // Item categories to show in the toolbox
    categories () {
      const { palette, isAdvancedMode } = this
      return palette
        .categories
        .filter(c => c.isAdvancedMode ? isAdvancedMode : true)
        .filter(c => this.categoryItems(c).length > 0)
    },

    // Items available in the specified category
    categoryItems () {
      const { renderer } = this
      return category => category.items.filter(i => i.isEnabled && (!renderer.isCrossSection || i.isCrossSection))
    },

    // Currently active item
    selectedItem () {
      return this.renderer.itemToAdd
    },

    // Checks if the specified item is currently selected
    isSelected () {
      return item => this.selectedItem?.id === item.id
    }
  },

  emits: [
    'action',
    'start-adding-item',
    'stop-adding-item'
  ],

  methods: {
    // Passes the action requested in the properties
    executeAction (details) {
      this.$emit('action', details)
    },

    // Toggles the specified item
    select (item) {
      const { selectedItem } = this
      if (selectedItem === item) {
        this.$emit('stop-adding-item')
      } else {
        this.$emit('start-adding-item', { item, layer: PlanLayers.Items })
      }
    }
  }
}

</script>

<template>
  <div class="plan-toolbox q-mb-sm">
    <template v-for="category in categories">
      <header class="text-bold">
        {{ category.label }}
      </header>
      <div class="row q-pl-xs q-pr-xs q-pb-xs q-gutter-xs">
        <q-btn v-for="item in categoryItems(category)" dense unelevated stack no-caps
          :disable="!enabled" size="11px" :ripple="false" :flat="!isSelected(item)" class="button"
          :class="{ selected: isSelected(item) }" :label="item.label" :icon="item.icon || palette.icons[item.type]"
          @click="select(item)">
        </q-btn>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.plan-toolbox {
  display: flex;
  flex-direction: column;
  user-select: none;

  header {
    padding: 10px 10px 5px 10px;
    font-size: 13px;
  }

  .button {
    color: #0f0f0f;
    background-color: #e8e8e8;
    min-width: 53px;

    &.selected {
      color: white;
      background-color: #3949ab;
    }
  }
}
</style>
