<script>
import { mapGetters } from 'vuex'
import { sortItems } from '@stellacontrol/utilities'
import { getOrganizationIcon, getOrganizationColor } from '@stellacontrol/model'
import { Secure } from '@stellacontrol/security-ui'

export default {
  mixins: [
    Secure
  ],

  props: {
    // Currently selected premium service
    service: {
      required: true
    },
    // List of premium services
    items: {
      default: []
    }
  },

  computed: {
    ...mapGetters([
      'organizations'
    ]),

    // Premium services grouped per reseller, super organization first
    resellers () {
      const { organizations, currentOrganization } = this

      const resellerServices = reseller => sortItems(
        this.items.filter(i => (reseller.isSuperOrganization && !i.resellerId) || reseller.id === i.resellerId),
        item => item.name
      )

      const resellers = sortItems(organizations
        .filter(reseller => !reseller.isSuperOrganization && this.items.some(item => item.resellerId === reseller.id))
        .map(reseller => ({ reseller, services: resellerServices(reseller) })),
      reseller => reseller.name)

      const serviceGroups = [
        { reseller: currentOrganization, services: resellerServices(currentOrganization) },
        ...resellers
      ]

      return serviceGroups
    },

    // Returns true if specified premium service is currently selected
    isSelected () {
      return s => {
        const { service } = this
        return service && s && service.id === s.id
      }
    },

    // CSS class for list item
    itemClass () {
      return service => ({
        'service': true,
        'is-default': service.isDefault,
        'is-bundle': service.isBundle,
        'selected': this.isSelected(service)
      })
    }
  },

  emits: [
    'selected'
  ],

  methods: {
    getOrganizationIcon,
    getOrganizationColor,

    // Selects the specified premium service
    select (service) {
      this.$emit('selected', service)
    },

    // Returns icon representing a premium service
    getServiceIcon (service) {
      return service.isBundle ? 'pie_chart' : 'circle'
    },

    // Returns color representing a premium service
    getServiceColor (service) {
      return service.getColor()
    }
  },

  mounted () {
  }
}

</script>

<template>
  <q-list>
    <template v-for="{ reseller, services } in resellers">
      <q-separator v-if="!reseller.isSuperOrganization"></q-separator>

      <q-item>
        <q-item-section avatar>
          <q-icon size="22px" :name="getOrganizationIcon(reseller)"
            :color="getOrganizationColor(reseller)"></q-icon>
        </q-item-section>
        <q-item-section>
          <q-item-label>
            {{ reseller.name }}
          </q-item-label>
        </q-item-section>
      </q-item>

      <q-item clickable v-close-popup v-for="service in services" :key="service.id"
        :active="isSelected(service)" :class="itemClass(service)" active-class="bg-indigo-1"
        @click="select(service)">
        <q-item-section avatar>
          <q-icon :name="getServiceIcon(service)" :color="getServiceColor(service)" size="lg"
            class="q-ml-md">
          </q-icon>
        </q-item-section>

        <q-item-section>
          <q-item-label class="q-mb-xs">
            <span>
              {{ service.code || service.name }}
            </span>
          </q-item-label>
          <q-item-label caption class="q-mb-xs">
            {{ service.code ? service.name : service.description }}
          </q-item-label>
        </q-item-section>

        <q-item-section side>
          <q-item-label caption>
            {{ capitalize(service.priceDescription) }}
          </q-item-label>
          <q-item-label caption>
            <span class="text-orange-9">
              {{ service.isPrivate ? 'Private service' : '' }}
            </span>
          </q-item-label>
        </q-item-section>
      </q-item>

    </template>

  </q-list>
</template>

<style lang='scss' scoped>
.service {
  &.is-default {
    span {
      font-weight: bold;
    }
  }

  &.is-bundle {}
}
</style>
