import { Guardian } from './guardian'
import { Pricelist } from '@stellacontrol/model'

/**
 * Features service, used to determine subset of features
 * applicable under a specific principal
 * @param {Feature} features Feature tree
 * @param {Principal} principal Principal in whose context the permissions will be resolved.
 * @param {Environment} environment Execution environment (DEV, PROD etc)
 * @description It is required that the principal is fully populated and it contains
 * details of its owner principals - organization, profile etc., all with
 * their respective permissions ready for inspection.
 */
export class Features {
  constructor (features, principal, environment) {
    if (!features) throw new Error('Features are required')
    if (!principal) throw new Error('Principal is required')
    if (!environment) throw new Error('Environment is required')

    this.principal = principal
    this.name = `${principal.type} features`.toUpperCase()
    this.environment = environment
    this.root = features.clone()
    this.dictionary = {}
    this.premiumFeatures = []
    this.features = []

    if (this.principal.hasParentPrincipals) {
      this.parentGuardians = this.principal.parentPrincipals.map(p => new Guardian({
        features: this.root,
        principal: p,
        pricelist: new Pricelist(),
        environment: this.environment
      }))
    }

    this.apply()
  }

  /**
   * Unique name of the service
   * @type {String}
   */
  name

  /**
   * Current environment
   * @type {Environment}
   */
  environment

  /**
  * Principal in whose context the feature tree will be resolved
  * @type {Principal}
  */
  principal

  /**
   * Parent guardians used to filter out features that were
   * denied in the parent principals, such as organization profile.
   * @type {Array[Guardian]}
   */
  parentGuardians

  /**
   * Feature tree root
   * @type {Feature}
   */
  root

  /**
   * List of all features, for iterations
   * @type {Array[Feature]}
   */
  features

  /**
   * Feature dictionary, for quick lookups
   * @type {Dictionary<string, Feature>}
   */
  dictionary

  /**
   * List of applicable features, for iterations
   * @type {Array[Feature]}
   */
  applicableFeatures

  /**
   * Applicable features dictionary, for quick lookups
   * @type {Dictionary<string, Feature>}
   */
  applicableFeaturesDictionary

  /**
   * List of premium features
   * @type {Array[Feature]}
   */
  premiumFeatures

  /**
   * Returns feature with the specified name
   * @param {String} name Feature name
   * @returns {Feature}
   */
  getFeature (name) {
    if (!name) throw new Error('Feature name is required')
    return this.dictionary[name]
  }

  /**
   * Checks if the specified feature is applicable
   * @param {Feature|String} name Feature instance or name
   * @returns {Boolean}
   */
  isApplicable (feature) {
    if (!feature) throw new Error('Feature is required')
    const name = typeof feature === 'string' ? feature : feature.name
    return Boolean(this.applicableFeaturesDictionary[name])
  }

  /**
   * Checks if the specified feature is a premium feature
   * @param {Feature|String} name Feature instance or name
   * @returns {Boolean}
   */
  isPremiumFeature (feature) {
    if (!feature) throw new Error('Feature is required')
    const name = typeof feature === 'string' ? feature : feature.name
    return this.premiumFeatures.some(f => f.name === name)
  }

  /**
   * Applies restrictions to the feature tree.
   * Features which aren't applicable under the principal
   * will be marked as not applicable
   */
  apply () {
    // Traverse feature tree, tag not applicable features
    // and create a dictionary for quick lookups
    this.dictionary = createFeatureDictionary(
      this.root,
      undefined,
      (feature, parent) => this.isApplicableFilter(feature, parent, this.principal, this.parentGuardians)
    )

    // Select premium features
    this.premiumFeatures = Object.values(this.dictionary).filter(feature => feature.isPremium)

    // Enumerable list of features
    this.features = Object.values(this.dictionary)

    // Enumerable list of applicable features
    this.applicableFeatures = this.features
      .filter(f => f.isApplicable)

    // Applicable features dictionary for quick lookups
    this.applicableFeaturesDictionary = this.applicableFeatures
      .reduce((all, feature) => ({ ...all, [feature.name]: feature }), {})
  }

  /**
   * Checks whether the feature is applicable
   * @param {Feature} feature Feature to check
   * @param {Feature} parentFeature Parent feature
   * @param {Principal} principal Principal in whose context we check the applicability of the feature
   * @param {Array[Guardian]} parentGuardians Parent principals
   * @returns {Boolean}
   */
  isApplicableFilter (feature, parentFeature, principal, parentGuardians) {
    // Feature must not be disabled globally,
    // neither directly nor by one of its parents
    let isApplicable = feature.isEnabled
    if (isApplicable && parentFeature) {
      isApplicable = isApplicable && parentFeature.isEnabled
    }

    // If feature has no subjects assigned explicitly, inherit from parent
    if (!feature.subject && parentFeature) {
      feature.subject = parentFeature.subject
    }

    // If feature is only allowed to specific principal types,
    // check if principal matches the feature subject
    if (isApplicable && feature.subject) {
      isApplicable = this.principalHasRequiredType(principal, feature.subject)
    }

    // If super organization, no other checks apply
    if (isApplicable && !principal.isSuperOrganization) {
      // If feature requires specific principal level,
      // check if principal has that level
      if (feature.level) {
        isApplicable = this.principalHasRequiredLevel(principal, feature.level)
      }

      // Check whether the specified feature is permitted in the parent principal.
      // If subject specified, skip this check for parent guardians whose
      // principal type does not match the subject
      if (isApplicable && parentGuardians) {
        const guardiansToCheck = parentGuardians.filter(g =>
          feature.subject
            ? this.principalHasRequiredType(g.principal, feature.subject)
            : true)
        isApplicable = guardiansToCheck.length === 0 || guardiansToCheck.every(g => g.canUse(feature.name))
      }
    }

    return isApplicable
  }

  /**
   * Checks whether given principal's level matches the level required by the feature
   * @param {Principal} principal Principal
   * @param {Dictionary<PrincipalType, OrganizationLevel>} featureLevels Feature levels per principal type
   * @returns {Boolean}
   */
  principalHasRequiredLevel (principal, featureLevels) {
    const { type: principalType, level: principalLevel } = principal
    const requiredLevel = featureLevels[principalType]
    return !requiredLevel || requiredLevel.includes(principalLevel)
  }

  /**
   * Checks whether the specified feature is applicable to the principal type
   * @param {String} principal Principal
   * @param {Array[PrincipalType]} subject Feature subjects
   * @returns {Boolean}
   */
  principalHasRequiredType (principal, subject) {
    return subject.includes(principal.type)
  }

  /**
   * Checks whether the feature or any of its features
   * is explicitly marked as applicable for the specified principal type
   * @param {Feature} feature Feature to check
   * @param {Principal} principal Principal to check
   * @returns {Boolean}
   */
  featureHasSubject (feature, principal) {
    let result = false
    if (feature.subject && feature.subject.includes(principal.type)) {
      result = true
    } else {
      for (const child of feature.features || []) {
        result = this.featureHasSubject(child, principal)
        if (result) {
          break
        }
      }
    }
    return result
  }
}

/**
 * Creates a dictionary of all features from a feature tree
 * @param {Feature} feature Root feature
 * @param {Feature} parent Feature parent
 * @param {Boolean} isApplicableFilter Filter function checking whether the feature is applicable
 * @returns {Dictionary<string, Feature>}
 */
function createFeatureDictionary (feature, parent, isApplicableFilter) {
  const dictionary = {
    [feature.name]: feature
  }

  // Feature is not applicable if parent was found to be not applicable,
  // otherwise explicitly check the conditions using the filter function
  feature.parent = parent
  feature.isApplicable = (parent ? parent.isApplicable : true) && isApplicableFilter(feature, parent)

  // Traverse the features
  if (feature.features) {
    const features = feature.features.reduce((all, child) =>
      Object.assign(all, createFeatureDictionary(child, feature, isApplicableFilter)), {})
    Object.assign(dictionary, features)
  }

  return dictionary
}
