import { assign, parseDate } from '@stellacontrol/utilities'

export class DeviceVersion {
  constructor (data = {}) {
    assign(this, data, { createdAt: parseDate })
  }

  /**
   * Record identifier
   * @type {String}
   */
  id

  /**
   * Device identifier
   * @type {String}
   */
  deviceId

  /**
   * Date and time when version change was registered
   * @type {Date}
   */
  createdAt

  /**
   * Firmware version
   * @type {String}
   */
  firmwareVersion

  /**
   * Long firmware version
   * @type {String}
   */
  firmwareVersionLong

  /**
   * Hardware version
   * @type {String}
   */
  hardwareVersion

  /**
   * EEPROM version
   * @type {String}
   */
  eepromVersion

  /**
   * MEGA version
   * @type {String}
   */
  megaVersion

  /**
   * Indicates whether this is the most current version record
   * @type {Boolean}
   */
  isCurrent

  /**
   * Other details
   * @type {String}
   */
  details

  /**
   * Returns string representation of all versions in the record
   * @param {Object} prefix Prefixes to add to version numbers
   * @param {String} separator Separator to join version parts
   * @returns {String}
   */
  getVersionString ({ prefix = { mega: '', firmware: 'FW: ', hardware: 'HW: ', eeprom: 'EEPROM: ' }, separator = ' | ' } = {}) {
    const { megaVersion, firmwareVersion, firmwareVersionLong, hardwareVersion, eepromVersion } = this
    const versions = [
      megaVersion ? `${prefix.mega}${megaVersion.toUpperCase()}` : '',
      firmwareVersion ? `${prefix.firmware}${firmwareVersionLong || firmwareVersion}` : '',
      hardwareVersion ? `${prefix.hardware}${hardwareVersion}` : '',
      eepromVersion ? `${prefix.eeprom}${eepromVersion}` : ''
    ].filter(v => v)
    return versions.join(separator)
  }
}
