import { htmlize } from '@stellacontrol/utilities'
import { ServiceManagementAPI } from '@stellacontrol/client-api'
import { isRouteLeft, ListViewMode } from '@stellacontrol/client-utilities'
import { AuditSubject } from '@stellacontrol/model'
import { AppletRoute } from '../../router'

export const actions = {
  /**
   * Initializes the premium services audit view
   */
  async initializePremiumServicesAuditView ({ dispatch }) {
    await dispatch('initializeList', [
      { name: 'premium-services-audit', viewMode: ListViewMode.Dense, sortBy: 'time', sortDescending: true }
    ])
    await dispatch('getPremiumServicesAudit')
    return true
  },

  /**
   * Retrieves premium services audit
   * @param {Organization} organization Organization whose audit trail to return
   * @param {Boolean} includeChildOrganizations If true, also audit trail
   * of all child organizations will be retrieved
   * @returns {Array[AuditItem]} List of audit items
   */
  async getPremiumServicesAudit ({ state, commit, dispatch, getters }, { organization, includeChildOrganizations = true } = {}) {
    await dispatch('loading')

    // Only super organization can request audit of any other organization.
    // Only super organization can request audit trail of events related to editing the pricelists.
    const { currentOrganization } = getters
    const { newerThan, olderThan } = state.filter
    const subjects = state.filter.subjects.filter(subject => subject === AuditSubject.PremiumService ? currentOrganization.isBank : true)
    const items = subjects.length === 0
      ? []
      : await ServiceManagementAPI.getAudit({
        organization: currentOrganization.isSuperOrganization ? organization : undefined,
        includeChildOrganizations,
        newerThan,
        olderThan,
        subjects
      })

    // Enrich items
    for (const item of items) {
      // Item tooltip
      if (item.details) {
        item.tooltip = item.changes
          ? htmlize(`${item.details}\n\n${item.changes}`)
          : htmlize(item.details)
      }
    }

    await dispatch('done')
    commit('storePremiumServicesAudit', { items })

    return items
  },


  /**
   * Filters the displayed audit items
   * @param {Date} olderThan If specified, only events before the specified date and time will be retrieved
   * @param {Date} newerThan If specified, only events after the specified date and time will be retrieved
   * @param {Array[AuditSubject]} subjects Optional list of audit subjects to return
   * @param {Number} rowsPerPage If specified, sets maximal rows displayed per page
   * @param {Boolean} reload If true, data is reloaded to apply the filter immediately
   */
  filterPremiumServicesAudit ({ commit, dispatch }, { olderThan, newerThan, subjects, rowsPerPage, reload = true } = {}) {
    commit('filterPremiumServicesAudit', { olderThan, newerThan, subjects, rowsPerPage })
    if (reload) {
      return dispatch('getPremiumServicesAudit')
    }
  },

  /**
   * Reset state if view closed
   */
  async navigationEnded ({ commit }, { from, to } = {}) {
    if (isRouteLeft(AppletRoute.PremiumServicesAudit, from, to)) {
      commit('resetPremiumServicesAuditView')
    }
  }
}
