import { Assignable } from '../common/assignable'
import { sortItems } from '@stellacontrol/utilities'

/**
 * Alert statistics parts
 */
export const AlertStatisticsPart = {
  Alerts: 'alerts',
  Devices: 'devices',
  PendingNotifications: 'pending-notifications',
  SentNotifications: 'sent-notifications',
  FailedNotifications: 'failed-notifications',
  ConfigurationChanges: 'configuration-changes',
  All: 'all'
}

/**
 * Alert statistics parts name
 */
export const AlertStatisticsPartName = {
  [AlertStatisticsPart.Alerts]: 'Alerts',
  [AlertStatisticsPart.Devices]: 'Devices which triggered alerts',
  [AlertStatisticsPart.PendingNotifications]: 'Pending notifications',
  [AlertStatisticsPart.SentNotifications]: 'Sent notifications',
  [AlertStatisticsPart.FailedNotifications]: 'Failed notifications',
  [AlertStatisticsPart.ConfigurationChanges]: 'Configuration changes',
  [AlertStatisticsPart.All]: 'All'
}

/**
 * Alert statistics properties holding the specified parts
 */
export const AlertStatisticsPartKey = {
  [AlertStatisticsPart.Alerts]: 'alerts',
  [AlertStatisticsPart.Devices]: 'devices',
  [AlertStatisticsPart.PendingNotifications]: 'pendingNotifications',
  [AlertStatisticsPart.SentNotifications]: 'sentNotifications',
  [AlertStatisticsPart.FailedNotifications]: 'failedNotifications',
  [AlertStatisticsPart.ConfigurationChanges]: 'configurationChanges'
}

/**
 * Alert statistics
 */
export class AlertStatistics extends Assignable {
  constructor (data = {}) {
    super()
    this.assign({ ...data })
  }

  /**
   * Organization
   * @type {Organization}
   */
  organization

  /**
   * Period start
   * @type {Date}
   */
  from

  /**
   * Period end
   * @type {Date}
   */
  until

  /**
   * Counters
   * @type {Object}
   */
  counters = {
    /**
     * Number of alerts triggered during the period
     * @type {Number}
     */
    alerts: 0,

    /**
     * Number of devices which triggered alerts
     * @type {Number}
     */
    devices: 0,

    /**
     * Numbers of pending notifications
     * @type {Number}
     */
    pendingNotifications: 0,

    /**
     * Numbers of sent notifications
     * @type {Number}
     */
    sentNotifications: 0,

    /**
     * Numbers of failed notifications
     * @type {Number}
     */
    failedNotifications: 0,

    /**
     * Numbers of alert configuration changes
     * @type {Number}
     */
    configurationChanges: 0
  }

  /**
   * Details.
   * Items in details arrays can be hidden from sight by setting
   * their `isPermitted` property to `false`
   * @type {Object}
   */
  details = {
    /**
     * Alerts triggered during the period
     * @type {Array[AlertOccurrence]}
     */
    alerts: [],

    /**
     * Devices which triggered alerts
     * @type {Array[Device]}
     */
    devices: [],

    /**
     * Pending notifications
     * @type {Array[Notification]}
     */
    pendingNotifications: [],

    /**
     * Sent notifications
     * @type {Array[Notification]}
     */
    sentNotifications: [],

    /**
     * Failed notifications
     * @type {Array[Notification]}
     */
    failedNotifications: [],

    /**
     * Alert configuration changes
     * @type {AuditItem}
     */
    configurationChanges: []
  }

  /**
   * Merges a list of alert statistics into one record
   * @param {Array[AlertStatistics]} items Items to merge
   * @returns {AlertStatistics} Merged statistics
   */
  static merge (items = []) {
    const statistics = new AlertStatistics()

    for (const item of items) {
      // Merge counters
      for (const key of Object.keys(statistics.counters)) {
        statistics.counters[key] = (statistics.counters[key] || 0) + (item.counters[key] || 0)
      }
      // Merge details
      for (const key of Object.keys(statistics.details)) {
        statistics.details[key] = [...(statistics.details[key] || []), ...(item.details[key] || [])]
      }
      // Re-sort details
      statistics.details.alerts = sortItems(statistics.details.alerts, 'time', true)
      statistics.details.devices = sortItems(statistics.details.devices, 'serialNumber')
      statistics.details.pendingNotifications = sortItems(statistics.details.pendingNotifications, 'created', true)
      statistics.details.sentNotifications = sortItems(statistics.details.sentNotifications, 'sent', true)
      statistics.details.failedNotifications = sortItems(statistics.details.failedNotifications, 'failed', true)
      statistics.details.configurationChanges = sortItems(statistics.details.configurationChanges, 'created', true)
    }
    return statistics
  }

  /**
   * Returns information about the data which has been protected from viewing
   * because the user does not have permissions / premium subscriptions to see them
   * @param {AlertStatisticsPart} part Statistics part to check
   * @returns {Object} If any data has been protected, returns the object
   * containing the information about the total record count and
   * the count of hidden records: `{ part, totalCount, hiddenCount, visibleCount }`
   */
  getProtectedData (part) {
    if (part) {
      const totalCount = this.counters[AlertStatisticsPartKey[part]]
      const details = this.details[AlertStatisticsPartKey[part]]
      const hiddenDetails = details.filter(item => item.isPermitted === false)
      const hiddenCount = hiddenDetails.length
      const visibleCount = totalCount - hiddenCount
      return hiddenCount > 0
        ? { part, totalCount, hiddenCount, visibleCount }
        : undefined
    }
  }
}
