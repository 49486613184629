/**
 * Device connection quality
 */
export const DeviceConnectionQuality = {
  // Nothing is known yet, the data about device status has not yet been requested from the system
  Unknown: 'unknown',
  // Good connection quality, device heartbeat is received frequently enough for its current sampling mode
  Good: 'good',
  // Bad connection quality, device heartbeat hasn't been received in a time longer than acceptable
  Bad: 'bad'
}

/**
 * Colors representing connection quality
 */
export const DeviceConnectionQualityColor = {
  [DeviceConnectionQuality.Unknown]: 'transparent',
  [DeviceConnectionQuality.Good]: '#2ecd6f',
  [DeviceConnectionQuality.Bad]: '#e20400'
}
