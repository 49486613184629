import { Log, isEnum, delay } from '@stellacontrol/utilities'
import { DeviceRegion, DeviceRegionControl } from '@stellacontrol/model'
import { FastSamplingSpeed } from '../model/fast-sampling-speed'
import { DeviceCommandClient } from './device-command-client'
import { DeviceStatusClient } from './device-status-client'
import { DeviceSettingsClient } from './device-settings-client'

/**
 * Process steps
 */
const RegionSwitchStep = {
  FastSampling: 'fast-sampling',
  QueryStatus: 'query-status',
  ClearSettings: 'clear-settings',
  RegionOff: 'region-off',
  ConfirmRegionOff: 'confirm-region-off',
  RegionChange: 'region-change',
  ConfirmRegionChange: 'confirm-region-change',
  Completed: 'completed'
}

/**
 * Service implementing workflow for switching REGION setting,
 * simple settings update.
 */
export class DeviceRegionSwitcher {
  /**
   * Creates a series of promises which need to be executed in a sequence in order to change region of the specified device.
   * The following scenario is executed:
   * 1. Any previous custom values for region-related settings are cleared
   * 2. `_rf_region` value is set to the new region, `_rf_region_control` is set to `manual`.
   * 3. Device is switched to fast mode to receive quick confirmation of the applied changes.
   * @param {Device} device Device to modify
   * @param {DeviceRegion} region Region to set
   */
  getSteps (device, region) {
    const commandService = new DeviceCommandClient()
    const settingsService = new DeviceSettingsClient()

    // Switch to fast sampling mode
    const stepFastSampling = () => new Promise(resolve => {
      commandService
        .switchToFastMode(device, { speed: FastSamplingSpeed.OneSecond, duration: 60 })
        .then(() => resolve({ device, step: RegionSwitchStep.FastSampling }))
        .catch(error => resolve({
          device,
          step: RegionSwitchStep.FastSampling,
          error: createError('Error turning on fast sampling mode', error)
        }))
    })

    // Clear previous customizations of RF-related values
    const stepClearSettings = () => new Promise(resolve => {
      settingsService
        .clearSettings(device, ['_rf_region', '_rf_region_control'])
        .then(() => resolve({ device, step: RegionSwitchStep.ClearSettings }))
        .then(() => delay(3000))
        .catch(error => resolve({
          device,
          step: RegionSwitchStep.ClearSettings,
          error: createError('Error clearing device settings', error)
        }))
    })

    // Region CHANGE
    const stepRegionChange = () => new Promise(resolve => {
      settingsService
        .updateSettings(device, { '_rf_region': region, '_rf_region_control': DeviceRegionControl.Manual })
        .then(() => delay(3000))
        .then(() => resolve({ device, step: RegionSwitchStep.RegionChange }))
        .catch(error => resolve({
          device,
          step: RegionSwitchStep.RegionChange,
          error: createError('Error changing device region', error)
        }))
    })

    const stepCompleted = () => new Promise((resolve) => {
      resolve({ device, step: RegionSwitchStep.Completed, region })
    })

    return [
      { step: RegionSwitchStep.ClearSettings, handler: stepClearSettings },
      { step: RegionSwitchStep.RegionChange, handler: stepRegionChange },
      { step: RegionSwitchStep.FastSampling, handler: stepFastSampling },
      { step: RegionSwitchStep.Completed, handler: stepCompleted }
    ].filter(step => step.handler != null)
  }

  /**
   * Changes region of the device.
   * To guarantee proper working of the device, the following scenario is executed:
   * 1. Device is switched to fast mode for a few seconds.
   * 2. Fresh device status is retrieved.
   * 3. If region is already the required one, exit.
   * 4. Device is switched to fast mode for one minute and will be kept in fast mode until the scenario finishes.
   * 5. `_rf_region` value is set to `OFF`.
   * 6. Waiting and retrieving status until it is confirmed that the region has been switched off, with a timeout of one minute.
   * 5. `_rf_region` value is set to the new region.
   * 6. Waiting and retrieving status until it is confirmed that the region has been changed, with a timeout of one minute.
   * 7. Done.
   * @param {Device} device Device to modify
   * @param {DeviceRegion} region Region to set
   * @param {Function<Device, RegionSwitchStep>} onStepCompleted Callback to call on each completed step.
   * Can be used to coordinate changing region of multiple devices at once.
   * @param {Function<Device, RegionSwitchStep, Error>} onStepFailed Callback to call when a step fails.
   * Can be used to coordinate changing region of multiple devices at once.
   */
  async setRegion (device, region, onStepCompleted, onStepFailed) {
    if (!device) throw new Error('Device is required')
    if (!region) throw new Error('Region is required')
    if (!isEnum(DeviceRegion, region)) throw new Error(`Invalid region [${region}]`)
    if (device.isNonConnectedDevice) {
      return { device, error: new Error('Cannot change region of a non-connected device') }
    }

    const steps = this.getSteps(device, region)

    let result

    for (const { step, handler } of steps) {
      try {
        result = await handler()
        if (result.error) {
          onStepFailed({ devices: [device], results: [result], region, step, error: result.error })
          break
        } else {
          onStepCompleted({ devices: [device], results: [result], step, region })
        }
      } catch (error) {
        result = error
        onStepFailed({ devices: [device], results: [], region, step, error })
      }
    }

    return result
  }

  /**
   * Changes region of the specified devices
   * @param {Array[Device]} devices Devices to modify
   * @param {DeviceRegion} region Region to set
   * @param {Function<Device, RegionSwitchStep>} onStepCompleted Callback to call on each completed step.
   * Can be used to coordinate changing region of multiple devices at once.
   * @param {Function<Device, RegionSwitchStep, Error>} onStepFailed Callback to call when a step fails.
   * Can be used to coordinate changing region of multiple devices at once.
   */
  async setRegionMany (devices = [], region, onStepCompleted, onStepFailed) {
    if (!devices) throw new Error('Devices are required')
    if (!region) throw new Error('Region is required')
    if (!isEnum(DeviceRegion, region)) throw new Error(`Invalid region [${region}]`)

    if (devices.length === 0) return []

    if (devices.length === 1) {
      const result = await this.setRegion(devices[0], region, onStepCompleted, onStepFailed)
      return [result]
    }

    // Create a bundle of devices, with steps under each device, required to change that device's region
    const completedDevices = {}
    const batch = devices.map(device => ({
      device,
      deviceSteps: this.getSteps(device, region),
      isCompleted: false
    }))

    // Runs in parallel a list of functions executing the specified step on all devices.
    // Ignore devices which due to previous actions have already completed with success.
    // For example, some devices might have already been at the right region.
    const runStep = async (step) => {
      const devices = batch
        .map(({ device }) => device)
        .filter(device => !completedDevices[device.serialNumber])

      const steps = batch
        .filter(({ device }) => !completedDevices[device.serialNumber])
        .map(({ deviceSteps }) => deviceSteps.find(s => s.step === step))
        .map(deviceStep => deviceStep
          ? new Promise(resolve => deviceStep.handler().then(result => resolve(result)))
          : undefined)
        .filter(s => s)

      let results = []
      let succeeded = []
      let failed = []
      try {
        results = await Promise.all(steps)
        succeeded = results.filter(r => !r.error)
        failed = results.filter(r => r.error)
      } catch (error) {
        Log.exception(error)
      }

      for (const { device, isCompleted } of succeeded) {
        completedDevices[device.serialNumber] = isCompleted
      }

      if (failed.length === 0) {
        onStepCompleted({ devices, step, results })
      } else {
        const errors = failed
          .map(item => `${item.device.serialNumber}: ${item.error.message}`)
          .join('\n')
        onStepFailed({ devices, step, results, error: new Error(errors) })
      }

      return { devices, succeeded, failed }
    }

    // Checks if step has succeeded or failed
    const stepSucceeded = (results) => results && results.failed.length === 0

    // Sequence to execute
    const sequence = [
      RegionSwitchStep.ClearSettings,
      RegionSwitchStep.RegionChange,
      RegionSwitchStep.FastSampling,
      RegionSwitchStep.Completed
    ]

    // Run the sequence
    let results
    for (const step of sequence) {
      results = await runStep(step)
      if (!stepSucceeded(results)) {
        break
      }
    }

    return [
      ...results.succeeded,
      ...results.failed
    ]
  }
}

/**
 * Service implementing workflow for switching REGION setting
 * for batches of devices using strict controls to achieve maximum
 * consistency of the result of region switch.
 * This is useful when ship enters different geographical location,
 * and frequencies need to be changed accordingly.
 */
export class DeviceRegionSwitcherStrict {
  /**
   * Creates a series of promises which need to be executed in a sequence in order to change region of the specified device.
   * To guarantee proper working of the device, the following scenario is executed:
   * 1. Device is switched to fast mode for a few seconds.
   * 2. Fresh device status is retrieved.
   * 3. If region is already the required one, exit.
   * 4. Device is switched to fast mode for one minute and will be kept in fast mode until the scenario finishes.
   * 5. `_rf_region` value is set to `OFF`.
   * 6. Waiting and retrieving status until it is confirmed that the region has been switched off, with a timeout of one minute.
   * 5. `_rf_region` value is set to the new region.
   * 6. Waiting and retrieving status until it is confirmed that the region has been changed, with a timeout of one minute.
   * 7. Done.
   * @param {Device} device Device to modify
   * @param {DeviceRegion} region Region to set
   */
  getSteps (device, region) {
    const commandService = new DeviceCommandClient()
    const statusService = new DeviceStatusClient()
    const settingsService = new DeviceSettingsClient()

    // Predicate functions
    const isRegionManualControl = (status) => status?.mega['_rf_region_control'] === DeviceRegionControl.Manual
    const isRegionOff = (status) => status?.mega['_rf_region'] === DeviceRegion.Off
    const isRegionChanged = (status) => status?.mega['_rf_region'] === region

    // Switch to fast sampling mode
    const stepFastSampling = () => new Promise(resolve => {
      commandService
        .switchToFastMode(device, { speed: FastSamplingSpeed.OneSecond, duration: 90 })
        .then(() => resolve({ device, step: RegionSwitchStep.FastSampling }))
        .catch(error => resolve({
          device,
          step: RegionSwitchStep.FastSampling,
          error: createError('Error turning on fast sampling mode', error)
        }))
    })

    const stepFastSamplingShort = () => new Promise(resolve => {
      commandService
        .switchToFastMode(device, { speed: FastSamplingSpeed.OneSecond, duration: 30 })
        .then(() => resolve({ device, step: RegionSwitchStep.FastSampling }))
        .catch(error => resolve({
          device,
          step: RegionSwitchStep.FastSampling,
          error: createError('Error turning on fast sampling mode', error)
        }))
    })

    // Query current status
    const stepQueryStatus = () => new Promise(resolve => {
      statusService.getDeviceStatus([device], { isFastSampling: true })
        .then((results) => {
          const currentStatus = results[0]
          if (currentStatus) {
            if (currentStatus.mega['_rf_region'] === region) {
              device.region = region
              resolve({ device, step: RegionSwitchStep.Completed, region })
            } else {
              resolve({ device, step: RegionSwitchStep.QueryStatus })
            }
          } else {
            resolve({ device, step: RegionSwitchStep.QueryStatus, error: new Error('Status could not be retrieved') })
          }
        })
        .catch(error => resolve({
          device,
          step: RegionSwitchStep.QueryStatus,
          error: createError('Error retrieving recent status', error)
        }))
    })

    // Clear any pending changes
    const stepClearSettings = () => new Promise(resolve => {
      settingsService
        .clearSettings(device, ['_rf_region'])
        .then(() => settingsService.clearSettings(device, ['_rf_region_control']))
        .then(() => resolve({ device, step: RegionSwitchStep.ClearSettings }))
        .then(() => delay(3000))
        .catch(error => resolve({
          device,
          step: RegionSwitchStep.ClearSettings,
          error: createError('Error clearing device settings', error)
        }))
    })

    // Switch to manual region control and switch region off
    const stepRegionOff = () => new Promise(resolve => {
      statusService.waitForLiveStatus(device)
        .then(status => {
          if (!isRegionManualControl(status)) {
            settingsService
              .updateSettings(device, { _rf_region_control: DeviceRegionControl.Manual })
              .then(() => delay(3000))
          }
          return status
        })
        .then(status => {
          if (!isRegionOff(status)) {
            settingsService
              .updateSettings(device, { _rf_region: DeviceRegion.Off })
              .then(() => delay(3000))
          }
        })
        .then(() => statusService.waitForLiveStatus(device, { predicate: status => isRegionManualControl(status) && isRegionOff(status) }))
        .then(status => {
          if (!isRegionManualControl(status)) {
            settingsService
              .updateSettings(device, { _rf_region_control: DeviceRegionControl.Manual })
              .then(() => delay(3000))
          }
        })
        .then(status => {
          if (!isRegionOff(status)) {
            settingsService
              .updateSettings(device, { _rf_region: DeviceRegion.Off })
              .then(() => delay(3000))
          }
        })
        .then(() => resolve({ device, step: RegionSwitchStep.RegionOff }))
        .catch(error => resolve({
          device,
          step: RegionSwitchStep.RegionOff,
          errorerror: createError('Error turning device region off', error)
        }))
    })

    // Confirm Region Manual Control and region OFF
    const stepConfirmRegionOff = () => new Promise(resolve => {
      statusService.waitForLiveStatus(
        device,
        {
          timeout: 15000,
          predicate: status => isRegionOff(status),
          message: `[${device.serialNumber}] Region OFF confirmed`
        })
        .then(status => {
          if (isRegionOff(status)) {
            resolve({ device, step: RegionSwitchStep.ConfirmRegionOff })
          } else {
            settingsService
              .clearSettings(device, ['_rf_region', '_rf_region_control'])
              .then(() => resolve({
                device,
                step: RegionSwitchStep.ConfirmRegionOff,
                error: createError(`Device region could not be switched off. Reported values: _RF_REGION=${status.reported['_rf_region']} _RF_REGION_CONTROL=${status?.reported['_rf_region_control']}`)
              }))
              .catch(error => resolve({
                device,
                step: RegionSwitchStep.ConfirmRegionOff,
                error: createError('Error clearing device settings after failed region off', error)
              }))
          }
        })
        .catch(error => resolve({
          device,
          step: RegionSwitchStep.ConfirmRegionOff,
          errorerror: createError('Error confirming device region off', error)
        }))
    })

    // Region CHANGE
    let stepRegionChange
    let stepConfirmRegionChange
    if (region !== DeviceRegion.Off) {
      stepRegionChange = () => new Promise(resolve => {
        settingsService
          .updateSettings(device, { _rf_region: region })
          .then(() => delay(3000))
          .then(() => statusService.waitForLiveStatus(device, { predicate: status => isRegionChanged(status) }))
          .then(status => {
            if (!isRegionChanged(status)) {
              settingsService
                .updateSettings(device, { _rf_region: region })
                .then(() => delay(3000))
            }
          })
          .then(() => resolve({ device, step: RegionSwitchStep.RegionChange }))
          .catch(error => resolve({
            device,
            step: RegionSwitchStep.RegionChange,
            error: createError('Error changing device region', error)
          }))
      })

      // Confirm Region CHANGE
      stepConfirmRegionChange = () => new Promise(resolve => {
        statusService.waitForLiveStatus(
          device,
          {
            timeout: 15000,
            predicate: status => isRegionChanged(status),
            message: `[${device.serialNumber}] Region change confirmed`
          })
          .then(status => {
            if (isRegionChanged(status)) {
              resolve({ device, step: RegionSwitchStep.ConfirmRegionChange })
            } else {
              settingsService
                .clearSettings(device, ['_rf_region', '_rf_region_control'])
                .then(() => resolve({
                  device,
                  step: RegionSwitchStep.ConfirmRegionChange,
                  error: createError(`Device region could not be set to [${region}]. Reported values: _RF_REGION=${status.reported['_rf_region']} _RF_REGION_CONTROL=${status?.reported['_rf_region_control']}`)
                }))
                .catch(error => resolve({
                  device,
                  step: RegionSwitchStep.ConfirmRegionChange,
                  error: createError('Error clearing device settings after failed region change', error)
                }))
            }
          })
          .catch(error => resolve({
            device,
            step: RegionSwitchStep.ConfirmRegionChange,
            error: createError('Error confirming region change', error)
          }))
      })
    }

    const stepCompleted = () => new Promise((resolve) => {
      resolve({ device, step: RegionSwitchStep.Completed, region })
    })

    return [
      { step: RegionSwitchStep.FastSampling, handler: stepFastSampling },
      { step: RegionSwitchStep.QueryStatus, handler: stepQueryStatus },
      { step: RegionSwitchStep.ClearSettings, handler: stepClearSettings },
      { step: RegionSwitchStep.RegionOff, handler: stepRegionOff },
      { step: RegionSwitchStep.ConfirmRegionOff, handler: stepConfirmRegionOff },
      { step: RegionSwitchStep.RegionChange, handler: stepRegionChange },
      { step: RegionSwitchStep.ConfirmRegionChange, handler: stepConfirmRegionChange },
      { step: RegionSwitchStep.FastSampling, handler: stepFastSamplingShort },
      { step: RegionSwitchStep.Completed, handler: stepCompleted }
    ].filter(step => step.handler != null)
  }

  /**
   * Changes region of the device.
   * To guarantee proper working of the device, the following scenario is executed:
   * 1. Device is switched to fast mode for a few seconds.
   * 2. Fresh device status is retrieved.
   * 3. If region is already the required one, exit.
   * 4. Device is switched to fast mode for one minute and will be kept in fast mode until the scenario finishes.
   * 5. `_rf_region` value is set to `OFF`.
   * 6. Waiting and retrieving status until it is confirmed that the region has been switched off, with a timeout of one minute.
   * 5. `_rf_region` value is set to the new region.
   * 6. Waiting and retrieving status until it is confirmed that the region has been changed, with a timeout of one minute.
   * 7. Done.
   * @param {Device} device Device to modify
   * @param {DeviceRegion} region Region to set
   * @param {Function<Device, RegionSwitchStep>} onStepCompleted Callback to call on each completed step.
   * Can be used to coordinate changing region of multiple devices at once.
   * @param {Function<Device, RegionSwitchStep, Error>} onStepFailed Callback to call when a step fails.
   * Can be used to coordinate changing region of multiple devices at once.
   */
  async setRegion (device, region, onStepCompleted, onStepFailed) {
    if (!device) throw new Error('Device is required')
    if (!region) throw new Error('Region is required')
    if (!isEnum(DeviceRegion, region)) throw new Error(`Invalid region [${region}]`)
    if (device.isNonConnectedDevice) {
      return { device, error: new Error('Cannot change region of a non-connected device') }
    }

    const steps = this.getSteps(device, region)

    let result

    for (const { step, handler } of steps) {
      try {
        result = await handler()
        if (result.error) {
          onStepFailed({ devices: [device], results: [result], region, step, error: result.error })
          break
        } else {
          onStepCompleted({ devices: [device], results: [result], step, region })
        }
      } catch (error) {
        result = error
        onStepFailed({ devices: [device], results: [], region, step, error })
      }
    }

    return result
  }

  /**
   * Changes region of the specified devices
   * @param {Array[Device]} devices Devices to modify
   * @param {DeviceRegion} region Region to set
   * @param {Function<Device, RegionSwitchStep>} onStepCompleted Callback to call on each completed step.
   * Can be used to coordinate changing region of multiple devices at once.
   * @param {Function<Device, RegionSwitchStep, Error>} onStepFailed Callback to call when a step fails.
   * Can be used to coordinate changing region of multiple devices at once.
   */
  async setRegionMany (devices = [], region, onStepCompleted, onStepFailed) {
    if (!devices) throw new Error('Devices are required')
    if (!region) throw new Error('Region is required')
    if (!isEnum(DeviceRegion, region)) throw new Error(`Invalid region [${region}]`)

    if (devices.length === 0) return []

    if (devices.length === 1) {
      const result = await this.setRegion(devices[0], region, onStepCompleted, onStepFailed)
      return [result]
    }

    // Create a bundle of devices, with steps under each device, required to change that device's region
    const completedDevices = {}
    const batch = devices.map(device => ({
      device,
      deviceSteps: this.getSteps(device, region),
      isCompleted: false
    }))

    // Runs in parallel a list of functions executing the specified step on all devices.
    // Ignore devices which due to previous actions have already completed with success.
    // For example, some devices might have already been at the right region.
    const runStep = async (step) => {
      const devices = batch
        .map(({ device }) => device)
        .filter(device => !completedDevices[device.serialNumber])

      const steps = batch
        .filter(({ device }) => !completedDevices[device.serialNumber])
        .map(({ deviceSteps }) => deviceSteps.find(s => s.step === step))
        .map(deviceStep => deviceStep
          ? new Promise(resolve => deviceStep.handler().then(result => resolve(result)))
          : undefined)
        .filter(s => s)

      let results = []
      let succeeded = []
      let failed = []
      try {
        results = await Promise.all(steps)
        succeeded = results.filter(r => !r.error)
        failed = results.filter(r => r.error)
      } catch (error) {
        Log.exception(error)
      }

      for (const { device, isCompleted } of succeeded) {
        completedDevices[device.serialNumber] = isCompleted
      }

      if (failed.length === 0) {
        onStepCompleted({ devices, step, results })
      } else {
        const errors = failed
          .map(item => `${item.device.serialNumber}: ${item.error.message}`)
          .join('\n')
        onStepFailed({ devices, step, results, error: new Error(errors) })
      }

      return { devices, succeeded, failed }
    }

    // Checks if step has succeeded or failed
    const stepSucceeded = (results) => results && results.failed.length === 0

    // Sequence to execute
    const sequence = [
      RegionSwitchStep.FastSampling,
      RegionSwitchStep.QueryStatus,
      RegionSwitchStep.ClearSettings,
      RegionSwitchStep.RegionOff,
      RegionSwitchStep.ConfirmRegionOff,
      RegionSwitchStep.RegionChange,
      RegionSwitchStep.ConfirmRegionChange,
      RegionSwitchStep.FastSampling,
      RegionSwitchStep.Completed
    ]

    // Run the sequence
    let results
    for (const step of sequence) {
      results = await runStep(step)
      if (!stepSucceeded(results)) {
        break
      }
    }

    return [
      ...results.succeeded,
      ...results.failed
    ]
  }
}

/**
 * Creates error result
 * @param {String} message Message to return
 * @param {Object} exception Caught exception
 * @returns {Object} Error result
 */
function createError (message, exception) {
  const error = new Error(`${message}\n${exception ? exception.message : ''}`)
  return error
}