<script>
import { mapActions, mapState } from 'vuex'
import { FormMixin } from '@stellacontrol/client-utilities'
import { Secure } from '@stellacontrol/security-ui'

export default {
  mixins: [
    FormMixin,
    Secure
  ],

  props: [
    'data'
  ],

  computed: {
    ...mapState({
      placeDevicesColumns: state => state.places.placeDevicesColumns
    }),

    devices () {
      return this.data.devices || []
    },

    hasDevices () {
      return this.devices.length > 0
    }
  },

  methods: {
    ...mapActions([
      'gotoDeviceDashboard',
      'addNonConnectedDevice',
      'addSimulatedDevice'
    ]),

    async executeAction (device, action) {
      switch (action.name) {
        case 'dashboard':
          await this.gotoDeviceDashboard({ device })
          break
        default:
          throw new Error(`Unhandled action ${action.name}`)
      }
    },

    // Adds new non-connected device to the place
    async addNonConnectedDevice () {
      const { data: place, data: { organization } } = this
      const device = await this.addNonConnectedDevice({ place, organization })
      if (device) {
        if (!place.devices) {
          place.devices = []
        }
        place.devices.push(device)
      }
    },

    // Adds new simulated device to the place
    async addSimulatedDeviceToPlace () {
      const { data: place, data: { organization } } = this
      const device = await this.addSimulatedDevice({ place, organization })
      if (device) {
        if (!place.devices) {
          place.devices = []
        }
        place.devices.push(device)
      }
    }
  }
}

</script>

<template>
  <div class="container">
    <div class="header" v-if="canUseAny(['add-non-connected-devices', 'add-simulated-devices'])">
      <div class="row items-center">
      <q-btn no-caps no-wrap rounded dense unelevated :ripple="false" icon="add">
        <q-popup-edit square :style="{ padding: 0 }" :cover="false" :model-value="data">
          <q-list dense>
            <q-item clickable v-close-popup @click="addNonConnectedDevice()" v-if="canUse('add-non-connected-devices')">
              <div class="row items-center q-pa-sm">
                <q-icon name="router" size="sm" color="grey-5" class="q-mr-md" />
                <span>Add non-connected device</span>
              </div>
            </q-item>
            <q-item clickable v-close-popup @click="addSimulatedDeviceToPlace()" v-if="canUse('add-simulated-devices')">
              <div class="row items-center q-pa-sm">
                <q-icon name="router" size="sm" color="orange-7" class="q-mr-md" />
                <span>Add simulated device</span>
              </div>
            </q-item>
          </q-list>
        </q-popup-edit>
      </q-btn>

        <q-btn label="Add Non-Connected Device" icon="add" unelevated
          @click="addDevice()"/>
        <q-space></q-space>
      </div>
    </div>

    <div class="table q-mt-sm" v-if="hasDevices">
      <sc-list
        v-if="hasDevices"
        name="place-devices"
        row-key="id"
        :columns="placeDevicesColumns"
        :items="devices"
        :actions="[ { name: 'dashboard', label: 'Dashboard', isDefault: true, icon: 'dashboard' } ]" @action="executeAction">

        <template v-slot:body-cell-serialNumber="props">
          <q-td :props="props">
            <router-link class="item-link"
              v-if="canUse('device-dashboard')"
              :to="{ name: 'device-dashboard', params: { serialNumber: props.row.serialNumber } }">
              {{ props.row.serialNumber }}
            </router-link>
            <span v-else>
              {{ props.row.serialNumber }}
            </span>
          </q-td>
        </template>

      </sc-list>
    </div>

    <sc-non-connected-device-dialog />
    <sc-simulated-device-dialog />

  </div>
</template>

<style lang='scss' scoped>
.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    flex: 0;
  }

  .table {
    flex: 1;
    overflow: auto;
    border-top: solid silver 1px;
  }
}
</style>
