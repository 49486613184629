<script>
import ParameterEditor from './parameter-editor'

export default {
  mixins: [
    ParameterEditor
  ],

  data () {
    return {
      icons: [
        'do_not_disturb',
        'public',
        'public'
      ],
      iconColors: [
        'grey-7',
        'blue-8',
        'green-8'
      ]
    }
  },

  computed: {
    // Items for value selector
    items () {
      const { values } = this
      return values.map((value, index) => ({
        label: this.labels[index],
        value: this.values[index],
        icon: this.icons[index],
        color: this.iconColors[index]
      }))
    }
  }
}
</script>

<template>
  <div class="parameter-editor" v-if="hasValue">

    <sc-parameter-value
      :label="mega.label"
      :reported="reported"
      :custom="custom"
      :format="value => labelOf(value)"
    />

    <sc-parameter-selector
      :items="items"
      :label="mega.label"
      :allow-clear="isCustomized"
      @select="value => change(value)"
      @clear="clear()"
    />

  </div>
</template>
