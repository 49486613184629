import { DialogResult } from '../../dialogs/dialog-result'

/**
 * Dialog state
 */
export function createState () {
  return {
    /**
     * List of dialogs. For each dialog the following data is kept:
     * { id, data, result }
     * `id` is dialog identifier,
     * `data` is data used to feed the dialog
     * `result` indicates closing result of the dialog
     */
    items: []
  }
}

export const state = createState()

export const defaultDialogState = {
  /**
   * Identifier of a dialog
  */
  dialog: undefined,
  /**
   * Indicates whether the dialog is now visible
   */
  isVisible: false,
  /**
   * Data edited in the dialog
   */
  data: undefined,
  /**
   * Dialog result
   */
  result: undefined
}

export const getters = {
  /**
   * Returns details of the specified dialog
   */
  getDialog: (state) =>
    dialog =>
      state.items.find(item => item.dialog === dialog) || defaultDialogState,

  /**
   * Returns details of the currently displayed dialog
   */
  getCurrentDialog: (state, getters) =>
    dialog =>
      getters.getDialog(dialog).dialog,

  /**
   * Returns identifier of the currently displayed dialog
   */
  getCurrentDialogId: (state, getters) =>
    dialog =>
      getters.getDialog(dialog).dialog,

  /**
   * Returns data for the specified dialog
   */
  getDialogData: (state, getters) =>
    dialog =>
      getters.getDialog(dialog).data,

  /**
   * Returns true if specified dialog is visible
   */
  isDialogVisible: (state, getters) =>
    dialog =>
      getters.getDialog(dialog).isVisible,

  /**
   * Returns true if specified dialog is hidden
   */
  isDialogHidden: (state, getters) =>
    dialog =>
      !getters.getDialog(dialog).isVisible,

  /**
   * Returns the result of the specified dialog
   */
  getDialogResult: (state, getters) =>
    dialog =>
      getters.getDialog(dialog).result,

  /**
   * Returns true if the specified dialog has been OK-ed
   */
  isDialogOk: (state, getters) =>
    dialog =>
      getters.getDialog(dialog).result === DialogResult.Ok,

  /**
   * Returns true if the specified dialog has been cancelled
   */
  isDialogCancelled: (state, getters) =>
    dialog =>
      getters.getDialog(dialog).result === DialogResult.Cancel
}
