import { differenceInSeconds, differenceInMilliseconds, isToday } from 'date-fns'
import { getConciseDurationString, safeParseInt } from '@stellacontrol/utilities'
import { DeviceConnectionStatus, DeviceConnectionAge, DeviceConnectionStatusName } from '@stellacontrol/model'

/**
 * Various time-related properties of the device status
 */
export class DeviceStatusTimings {
  /**
   * Initializes the instance
   * @param {String} serialNumber Device serial number
   * @param {Dictionary<String, any>} mega Live device data retrieved from Shadow API
   * @param data Initial values of the properties
   */
  constructor (serialNumber, mega, data = {}) {
    Object.assign(this, data)
    this.serialNumber = serialNumber
    this.parse(mega)
  }

  /**
   * Creates an instance populated with status timings data,
   * for example from deserialized {@link DeviceStatusTimings} instance
   * @param {Object} data
   * @returns {DeviceStatusTimings}
   */
  static from (data = {}) {
    const status = new DeviceStatusTimings(undefined, undefined, data)
    return status
  }

  /**
   * Device serial number
   * @type {String}
   */
  serialNumber

  /**
   * Time when status has been received from the device
   * @type {Date}
   */
  receivedAt

  /**
   * {@link receivedAt} as human-readable time string
   * including milliseconds
   * @type {String}
   */
  get receivedAtString () {
    return this.receivedAt?.toISOString().substring(11, 23)
  }

  /**
   * Delay between the time when message
   * was received and now, in milliseconds
   * @type {Number}
   */
  get delay () {
    return differenceInMilliseconds(new Date(), this.receivedAt)
  }

  /**
   * Human-friendly label representing the connection status
   * based on time of the recent status
   * @type {String}
   */
  get label () {
    if (this.isRealTime) return DeviceConnectionStatusName[DeviceConnectionStatus.Online]
    if (this.isHeartbeat) return DeviceConnectionStatusName[DeviceConnectionStatus.Heartbeat]
    if (this.isLost) return DeviceConnectionStatusName[DeviceConnectionStatus.Lost]
    return DeviceConnectionStatusName[DeviceConnectionStatus.NeverConnected]
  }

  /**
   * Indicates whether the status has been received today
   */
  get isToday () {
    return this.receivedAt && isToday(this.receivedAt)
  }

  /**
   * If true, the status is real-time (<=60s)
   * @type {Boolean}
   */
  get isRealTime () {
    const { statusAge } = this
    return statusAge <= DeviceConnectionAge[DeviceConnectionStatus.Online]
  }

  /**
   * If true, the status is reliably new, otherwise it's regarded as stale and device as lost.
   * If no message for more than 90 minutes, device is deemed as lost.
   * @type {Boolean}
   */
  get isHeartbeat () {
    const { statusAge } = this
    return statusAge > DeviceConnectionAge[DeviceConnectionStatus.Online] && statusAge <= DeviceConnectionAge[DeviceConnectionStatus.Heartbeat]
  }

  /**
   * If true, the status is old enough to treat device as lost.
   * @type {Boolean}
   */
  get isLost () {
    const { statusAge } = this
    return statusAge > DeviceConnectionAge[DeviceConnectionStatus.Heartbeat]
  }

  /**
   * Device uptime in seconds
   * @type {Number}
   */
  uptime

  /**
   * Device uptime as duration string
   * @type {String}
   */
  get uptimeString () {
    return this.uptime == null ? '-' : `${this.uptime}s`
  }

  /**
   * Age of the message, in seconds
   * @type {Number}
   */
  get statusAge () {
    const { receivedAt } = this
    if (receivedAt) {
      const now = new Date()
      return differenceInSeconds(now, receivedAt)
    }
  }

  /**
   * Human-friendly description of status age
   * @type {String}
   */
  get statusAgeString () {
    const { statusAge } = this
    if (statusAge <= 2) {
      return 'just now'
    } else if (statusAge > 0) {
      return getConciseDurationString(statusAge, { suffix: 'ago' })
    } else {
      return 'unknown'
    }
  }

  /**
   * Parses raw MEGA record received from the device
   * @param mega MEGA record
   */
  parse (mega = {}) {
    const megaVersion = mega['@version']
    if (megaVersion) {
      this.receivedAt = mega.extra?.timestamp ? new Date(mega.extra?.timestamp) : new Date()
      this.timestamp = this.receivedAt.getTime()
      this.uptime = safeParseInt(mega.uptime)
    }
  }

  /**
   * Updates the specified device with the parsed status
   * @param device Device to update with this status
   */
  update (device) {
    if (device) {
      // lint-disable-line no-empty
    }
  }
}
