import { getDurationString } from '@stellacontrol/utilities'
import { jobRequiresUserRequest } from './job-requires-user-request'

/**
 * Description of job schedule, indicating when the job will start
 * or maybe that it's already finished (completed or failed)
 * @param {UploadJob} job Upload job
 * @returns {String}
 */
export function getJobSchedule (job) {
  if (job) {
    const { defer, uploadIn, isRetry, isNew, whenConnected, isCompleted, isFailed } = job
    if (defer > 0 && uploadIn > 0) {
      return `in ${getDurationString(uploadIn, { seconds: false })}`
    } else if (isNew || isRetry) {
      return jobRequiresUserRequest(job)
        ? 'on user request'
        : whenConnected ? 'when connected' : 'immediately'
    } else if (isCompleted) {
      return 'completed'
    } else if (isFailed) {
      return 'failed'
    } else {
      return ''
    }
  }
}
