import { SecurityAPI } from '@stellacontrol/client-api'
import { dispatch } from '@stellacontrol/client-utilities'
import { SecurityRequestType } from '@stellacontrol/model'
import ActivateAccountView from './activate-account.vue'

const name = 'activate-account'

export const Routes = [
  {
    name,
    path: '/activate-account/:token',
    component: ActivateAccountView,

    async beforeEnter (to, from, next) {
      // Make sure there's no session before entering this view
      await dispatch('clearSession')

      // Fetch the security request specified in the route
      const { params: { token } } = to
      const type = SecurityRequestType.ActivateAccount
      const data = await SecurityAPI.getSecurityRequest({ token, type })
      if (data) {
        await dispatch('setRouteData', { from, to, data })
        next()
      } else {
        next({ name: 'empty' })
      }
    }
  }
]
