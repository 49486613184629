/**
 * Combiner bypass modes
 */
export const BypassMode = {
  /**
   * Bypass mode is off
   */
  Off: 0,
  /**
   * Bypass mode is on
   */
  On: 1,
  /**
   * Bypass mode will be turned on by device, if MIMO_DL_LEVEL exceeds level specified in _BYPASS_LEVEL setting
   */
  Auto: 2
}
