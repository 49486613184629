<script>
import { PlanPalette } from '@stellacontrol/planner'
import { PlanActions } from '../../../renderer/actions'
import LayerProperties from './layer-properties'

export default {
  mixins: [
    LayerProperties
  ],

  data () {
    return {
      // Palette configuration
      palette: PlanPalette,
      // Item over which the mouse moves
      focusedItem: null
    }
  },

  computed: {
    // Returns item class
    getItemClass () {
      return item => {
        const selectedItems = this.selectedItems || []
        const selected = selectedItems.some(i => i.id === item.id)
        const over = !selected && item.id == this.focusedItem?.id
        return { selected, over }
      }
    }
  },

  methods: {
    // Signals removal of the item
    removeItem (item) {
      this.$emit('action', { action: PlanActions.RemoveItems, items: [item] })
    },

    // Signals selection of the item
    selectItem (item) {
      this.$emit('action', { action: PlanActions.SelectItems, items: [item] })
    }
  }
}
</script>

<template>
  <main class="items" v-if="hasItems">
    <div v-for="item of items" @mouseover="focusedItem = item" @mouseleave="focusedItem = null"
      @click="selectItem(item)" class="item" :class="getItemClass(item)">
      <q-icon :name="palette.icons[item.type]" color="grey-7" size="sm" class="q-ml-xs q-mr-sm"></q-icon>
      <span class="label">
        {{ item.name === item.label ? '' : item.name }} {{ item.label || '' }}
      </span>
      <q-btn size="xs" round flat unelevated dense v-if="item.id == focusedItem?.id" icon="close" text-color="red-8"
        @click.stop="removeItem(item)">
      </q-btn>
    </div>
  </main>
  <main class="items" v-else>
    The plan is empty.
  </main>
</template>

<style lang="scss" scoped>
main.items {
  padding: 10px;
  max-height: 300px;
  overflow: auto;
  user-select: none;

  .item {
    padding: 5px 10px 5px 10px;
    font-size: 13px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    &.over {
      background-color: #eceff1;
    }

    &.selected {
      background-color: #c5cae9;
    }

    .label {
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

  }
}
</style>
