<script>
import { mapState, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapState({
      isLoadingView: state => state.inventoryView.isReloading
    }),

    ...mapGetters([
      'isLoggedIn',
      'isNavigating'
    ]),

    isVisible () {
      return this.isLoggedIn && (this.isNavigating || this.isLoadingView)
    }
  }
}

</script>

<template>
  <div class="navigation-indicator">
    <i class="material-icons q-icon notranslate" v-if="isVisible">
      change_circle
    </i>
    <sc-tooltip anchor="top left" text="Loading, please wait ..." v-if="isVisible" />
  </div>
</template>

<style scoped lang="scss">

.navigation-indicator {
  i {
    font-size: 32px;
    color: #d5dbfa;
    animation-name: rotate;
    animation-duration: 2.0s;
    animation-iteration-count: infinite;
    animation-direction: reverse;
    animation-timing-function: linear;
  }
}

</style>