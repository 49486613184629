import { state, getters } from './devices.state'
import { mutations } from './devices.mutations'
import { actions } from './devices.actions'

export default {
  state,
  getters,
  mutations,
  actions
}
