/**
 * View state
 */
export const state = {
  /**
   * All views
   */
  all: [],
  /**
   * View dictionary
   */
  view: null,
  /**
   * Navigation history
   */
  history: []
}

export const getters = {
  /**
   * Returns all views
   */
  views: state =>
    state.all,

  /**
   * Returns the specified view
   */
  getView: state =>
    name =>
      state.all.find(view => view.name === name),

  /**
   * Returns the specified view title
   */
  getViewTitle: state =>
    name =>
      state.all.find(view => view.name === name).title,

  /**
   * Returns tab currently selected on the specified view
   */
  getViewTab: state =>
    name =>
      state.all.find(view => view.name === name).tab,

  /**
   * Returns default view tab, the one listed as first in view tabs
   */
  getDefaultViewTab: state =>
    name => {
      const view = state.all.find(view => view.name === name)
      if (view && view.tabs) {
        return Object.keys(view.tabs)[0]
      }
    },

  /**
   * Returns permissions required by the specified view
   * @param name View name
   */
  getViewPermissions: (state, getters) =>
    name =>
      (getters.getView(name) || {}).permissions || [],

  /**
   * Currently displayed view
   */
  currentView: (state) =>
    state.all.find(view => view.isVisible)
}
