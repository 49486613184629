<script>
import '@stellacontrol/common-ui'
import { mapActions, mapGetters } from 'vuex'
import { Note, NoteCategory, getDevicesDescription, sortOrganizations, OrganizationSortOrder } from '@stellacontrol/model'
import { Secure } from '@stellacontrol/security-ui'

export default {
  mixins: [
    Secure
  ],

  props: {
    // Edited devices
    devices: {
      type: Array,
      required: true
    },

    // If true, notes are shown
    // in read-only mode regardless of
    // who is watching and with what permissions
    readOnly: {
      type: Boolean
    },

    // If true, notes are displayed in a popup which has impact on the layout
    popup: {
      type: Boolean
    },

    // If true, a new note will be created automatically
    // when notes editor is opened
    createNewNote: {
      type: Boolean
    }
  },

  data () {
    return {
      // New note to add
      newNote: null
    }
  },

  computed: {
    ...mapGetters([
      'organizations'
    ]),

    // The first of the edited devices
    device () {
      return this.devices[0]
    },

    // Indicates that we're looking at notes of multiple devices
    isBatch () {
      return this.devices.length > 1
    },

    // All organizations to which notes can be addressed.
    // We include:
    // - child organizations
    // We ignore:
    // - guest organizations
    recipients () {
      const { organizations, currentOrganization } = this
      return sortOrganizations(organizations, OrganizationSortOrder.Rank, { currentOrganization })
        .filter(o => o).filter(o => o.id !== currentOrganization.id && !o.isGuestOrganization)
    }
  },

  methods: {
    ...mapActions([
      'saveDeviceNote',
      'removeDeviceNote'
    ]),

    getDevicesDescription,

    async updateNote ({ note }) {
      const { device } = this
      await this.saveDeviceNote({ device, note })
    },

    async removeNote ({ note }) {
      const { device } = this
      await this.removeDeviceNote({ device, note })
    },

    cancel () {
      this.$emit('cancel')
    },

    // If multiple devices are selected, we only allow adding new notes
    createBatchNote () {
      const { currentUser } = this
      return new Note({
        organizationId: currentUser.organizationId,
        createdBy: currentUser.id,
        updatedBy: currentUser.id,
        creator: currentUser,
        updater: currentUser,
        category: NoteCategory.Device,
        text: ''
      })
    },

    // Saves the note under multiple devices
    async saveBatchNote (note) {
      for (const device of this.devices) {
        await this.saveDeviceNote({ device, note })
      }
      this.cancel()
    }
  },

  created () {
    if (this.isBatch) {
      this.newNote = this.createBatchNote()
    }
  }
}
</script>

<template>
  <div>
    <div v-if="isBatch" class="add-note column">
      <span class="q-mb-sm">
        Add note to {{ getDevicesDescription(devices) }}:
      </span>
      <sc-note :note="newNote" :recipients="recipients" @save="saveBatchNote" @cancel="cancel">
      </sc-note>
    </div>

    <div class="notes" v-else>
      <sc-notes :entity="device" :notes="device.notes" :recipients="recipients"
        :title="`${device.acronym} ${device.serialNumber}`"
        :popup="popup"
        :creator="currentUser"
        :edit-mode="readOnly ? 'none' : (isSuperAdministrator ? 'all' : 'own')"
        :create-new-note="createNewNote" category="device" @update="updateNote" @remove="removeNote"
        @cancel="cancel">
      </sc-notes>
    </div>
  </div>
</template>

<style lang="scss" scoped>
</style>