import pkg from '../package.json'
import { Views as views } from './views'
import { Routes as routes } from './router'
import { Store as stores } from './store'
import { runApplication } from './application'
import { useCommonApplet } from '@stellacontrol/common-ui'
import { useSecurityApplet } from '@stellacontrol/security-ui'
import { useDevicesApplet } from '@stellacontrol/devices-ui'
import { useAlertsApplet } from '@stellacontrol/alerts-ui'
import { useNotificationsApplet } from '@stellacontrol/notifications-ui'
import { useServiceManagementApplet } from '@stellacontrol/service-management-ui'
import { useDeviceTransmissionApplet } from '@stellacontrol/device-transmission-ui'
import { usePlannerApplet } from '@stellacontrol/planner-ui'
import { useCharts } from '@stellacontrol/charts'

runApplication({
  pkg,
  routes,
  views,
  stores,
  use: {
    useCommonApplet,
    useSecurityApplet,
    useDevicesApplet,
    useAlertsApplet,
    useNotificationsApplet,
    useServiceManagementApplet,
    useDeviceTransmissionApplet,
    usePlannerApplet,
    useCharts
  }
})
