import { camelize } from '@stellacontrol/utilities'

export const mutations = {
  /**
   * Initializes application views
   * @param state View state
   * @param views View list
   */
  initializeViews (state, { views }) {
    state.all = [...state.all, ...(views || [])]
    state.view = getViewDictionary(state.all)
  },

  /**
   * Navigates to the specified view
   * @param state View state
   * @param view View name
   * @param title Optional view title
   * @param route Route that has led to the view
   * @param from Route from which we've arrived to the view
   */
  showView (state, { view: { name } = {}, title, route, from } = {}) {
    if (!name) throw new Error('View is required')
    const view = state.all.find(item => item.name === name)
    const currentView = state.all.find(item => item.isVisible)

    if (!view) return

    if (currentView) {
      // Hide the current view
      currentView.isVisible = false
    }

    // Mark the new view as visible
    view.isVisible = true

    // Store the route associated with the view and route that has lead to the view.
    // Will be used for breadcrumbs.
    view.route = route
    view.from = from

    // Set custom title
    if (title) {
      view.defaultTitle = view.title
      view.title = title
    } else {
      // otherwise revert to default title
      if (view.defaultTitle) {
        view.title = view.defaultTitle
        view.defaultTitle = undefined
      }
    }
  },

  /**
   * Indicates that user has left the specified view
   * @param state View state
   * @param view View name
   * @param title Optional view title
   */
  leaveView (state, { view: { name } = {}, route } = {}) {
    if (!name) throw new Error('View is required')
    const view = state.all.find(item => item.name === name)
    if (view) {
      view.route = route
      view.isVisible = false
    } else {
      throw new Error(`Invalid view ${name}`)
    }
  },

  /**
   * Changes view busy state
   * @param view View to mark as busy or not busy
   * @param isBusy Busy state
   */
  viewBusy (state, { view: { name } = {}, isBusy }) {
    if (!name) throw new Error('View name is required')
    const view = state.all.find(item => item.name === name)
    if (!view) throw new Error(`Invalid view ${name}`)

    view.isBusy = isBusy
  },

  /**
 * Selects the specified tab on the view
 * @param {View} view View to mark as not busy
 * @param {String} name Alternative way to specify view, by name
 * @param {String} tab Tab to activate
 */
  selectViewTab (state, { view: { name }, tab }) {
    if (!name) throw new Error('View name is required')
    const view = state.all.find(item => item.name === name)
    if (!view) throw new Error(`Invalid view ${name}`)

    if (view.tabs) {
      if (!view.tabs[tab]) throw new Error(`View [${view.name}]: tab [${tab}] is not defined`)
    }

    view.tab = tab
  }
}

/**
 * Returns a dictionary of views, with view name as key
 * @param items List of views
 */
function getViewDictionary (items = []) {
  return items.reduce((all, view) => {
    all[camelize(view.name)] = view
    return all
  }, {})
}
