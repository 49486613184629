import { ListViewMode } from '../../components'

/**
 * States of various lists in the application.
 */
export const state = {
  // List of list states
  items: []
}

/**
 * Default list state
 */
export const defaultListState = {
  /**
   * List name
   */
  name: null,
  /**
   * List view mode
   */
  viewMode: ListViewMode.Normal,
  /**
   * List sort expression
   */
  sortBy: null,
  /**
   * List sort direction
   */
  sortDescending: false,
  /**
   * List filter expression
   */
  filter: null
}

export const getters = {
  /**
   * Returns the state of the specified list
   */
  list: (state) => (name) => state.items.find(item => item.name === name) || defaultListState,

  /**
   * Returns view mode of the specified list
   */
  listViewMode: (state, getters) => (name) => getters.list(name).viewMode,
  isNormalListView: (state, getters) => (name) => getters.listViewMode(name) === ListViewMode.Normal,
  isDenseListView: (state, getters) => (name) => getters.listViewMode(name) === ListViewMode.Dense,
  isCardView: (state, getters) => (name) => getters.listViewMode(name) === ListViewMode.Cards,

  /**
   * Returns sort expression of the specified list
   */
  listSortBy: (state, getters) => (name) => getters.list(name).sortBy,

  /**
   * Returns sort direction of the specified list
   */
  listSortDescending: (state, getters) => (name) => getters.list(name).sortDescending,

  /**
   * Returns filter expression the specified list
   */
  listFilter: (state, getters) => (name) => getters.list(name).filter
}
