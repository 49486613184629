import { DeviceHierarchy } from '@stellacontrol/model'

export function createState () {
  return {
    // Hierarchy of organizations, places and devices
    // available to the currently logged in organization and user
    hierarchy: null,

    // All devices available to the current organization and user,
    // including those of the child organizations
    devices: null,
    // Indicates whether all devices have been retrieved and are up to date
    hasDevices: false,
    // Indicates that next request for devices should refresh any cached data
    refresh: false,

    // Devices directly owned by the current organization
    ownDevices: null,
    // Indicates whether own devices have been retrieved and are up to date
    hasOwnDevices: false,

    // Devices shared with the current organization
    sharedDevices: null,
    // Indicates whether shared devices have been retrieved and are up to date
    hasSharedDevices: false,

    // Recently created devices
    recentlyAddedDevices: [],

    // Dictionary of all known device types, models, hardwares and firmwares
    deviceTypes: {
      types: [],
      models: [],
      hardwares: [],
      firmwares: []
    }
  }
}

export const state = createState()

export const getters = {
  /**
   * All devices available to the current user and organization,
   * either directly owned or shared with it
   */
  devices: state => state.devices || [],

  /**
   * Devices hierarchy, by organizations and places
   */
  devicesHierarchy: (state, getters) => {
    const { currentOrganization: organization, organizations, devices, places } = getters
    const hierarchy = DeviceHierarchy.from({ organization, organizations, places, devices })
    return hierarchy
  },

  /**
   * All devices directly owned by the current organization
   */
  ownDevices: state => state.ownDevices || [],

  /**
   * All devices shared with the current organization
   */
  sharedDevices: state => state.sharedDevices || [],

  /**
   * All recently created devices
   */
  recentlyAddedDevices: state => state.recentlyAddedDevices || [],

  /**
   * Returns device with the specified identifier
   */
  getDevice: state =>
    id =>
      state.devices.find(device => device.id === id),

  /**
   * Returns device with the specified serial number
   */
  getDeviceBySerialNumber: state =>
    serialNumber =>
      state.devices.find(device => device.serialNumber === serialNumber),

  /**
   * Checks if device has been added recently
   */
  isRecentlyAddedDevice: state =>
    ({ serialNumber }) => state.recentlyAddedDevices.find(device => device.serialNumber === serialNumber)
}
