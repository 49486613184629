import { createState } from './device-dashboard.state'

export const mutations = {
  /**
   * Initializes the Device Dashboard view
   * @param {Organization} organization Organization to which the viewed device belongs
   * @param {OrganizationGuardian} organizationGuardian Organization's guardian
   * @param {Place} place Place to which the viewed device belongs
   * @param {Device} device Device to view
   * @param {Device} devicePart Multi-device part to view
   * @param {PremiumService} pendingPremiumService Pending premium service associated with the device
   */
  initializeDeviceDashboard (state, { organization, organizationGuardian, place, device, devicePart, pendingPremiumService } = {}) {
    state.organization = organization
    state.organizationGuardian = organizationGuardian
    state.place = place
    state.device = device
    state.devicePart = devicePart
    state.pendingPremiumService = pendingPremiumService
  },

  reset (state) {
    Object.assign(state, createState())
  }
}
