import { DateRange } from '@stellacontrol/model'
import { DeviceAuditEventType } from '@stellacontrol/devices'

export function createState () {
  return {
    /**
     * Hierarchy of organizations, places and devices
     * available to the currently logged in organization and user
     */
    hierarchy: null,
    /**
     * Currently viewed organization
     */
    organization: null,
    /**
     * Organization's guardian
     */
    organizationGuardian: null,
    /**
    * Currently viewed device
    */
    device: null,
    /**
   * Current state of the tree - minimized or visible, filter etc
   */
    tree: {
      /**
       * View mode: full tree / buildings list
       */
      viewMode: 'tree',
      /**
       * Tree is now minimized
       */
      isMinimized: true,
      /**
       * Tree is not displayed at all
       */
      isHidden: true,
      /**
       * Tree filter
       */
      filter: null,
      /**
       * Currently expanded items in the tree
       */
      expanded: [],
      /**
       * Currently selected item in the tree
       */
      selected: null
    },
    /**
     * History view filter
     */
    filter: {
      // Selected period
      period: DateRange.today(),
      // Maximal period length, in days
      maxDays: 61,
      // Parameters to show
      parameters: ['temperature'],
      // Extras to show, such as alerts, firmware updates etc.
      extras: [
        DeviceAuditEventType.Alert,
        DeviceAuditEventType.SettingsChanged,
        DeviceAuditEventType.CommandSent,
        DeviceAuditEventType.FirmwareUpdated
      ]
    }
  }
}

export const state = createState()

export const getters = {
}
