/**
 * Device message content
 */
export const MessageContent = {
  // Message contains changed values only
  Delta: 0,
  // Message contains all values
  Full: 1,
  // Message contains changed values only, sent in fast-sampling mode
  FastSamplingDelta: 2
}
