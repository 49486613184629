<script>
import { mapState, mapActions } from 'vuex'
import { isPlaceLockedFor } from '@stellacontrol/model'
import { ViewMixin, ListAction, ActionItem } from '@stellacontrol/client-utilities'
import { Secure } from '@stellacontrol/security-ui'

const name = 'places'

export default {
  mixins: [
    ViewMixin,
    Secure
  ],

  data () {
    return {
      name,
      isPlaceLockedFor
    }
  },

  computed: {
    ...mapState({
      places: state => state.places.items || [],
      placeColumns: state => state.places.placesColumns || [],
    }),

    hasPlaces () {
      return this.places.length > 0
    },

    placeActions () {
      return [
        new ActionItem({
          ...ListAction.Edit,
          isVisible: place => !isPlaceLockedFor(place, this.currentUser)
        }),
        new ActionItem({
          ...ListAction.Delete,
          isVisible: place => !isPlaceLockedFor(place, this.currentUser)
        })
      ]
    }
  },

  methods: {
    ...mapActions([
      'getPlaces',
      'createPlace',
      'editPlace',
      'removePlace'
    ]),

    executeAction (place, action) {
      switch (action.name) {
        case 'edit':
          return this.editPlace({ place })
        case 'delete':
          return this.removePlace({ place, confirm: true })
        default:
          throw new Error(`Unhandled action ${action.name}`)
      }
    }
  }
}

</script>

<template>
  <sc-view :name="name">

    <template #toolbar>
      <q-btn flat icon="add" label="Add Building" color="indigo-6" @click="createPlace()"></q-btn>
    </template>

    <div class="q-ma-md" v-if="!hasPlaces">
      <div class="q-mb-md">
        You can add here places where devices will be installed, such as buildings, ships or vehicles.
      </div>
      <q-btn color="deep-purple-8" icon="add" label="Add Building" @click="createPlace()"></q-btn>
    </div>

    <template #header>
      <sc-list-filter v-if="hasPlaces" :name="name"></sc-list-filter>
    </template>

    <sc-list
      v-if="hasPlaces"
      :name="name"
      :columns="placeColumns"
      :items="places"
      row-key="id"
      :actions="placeActions"
      @action="executeAction">

      <template v-slot:body-cell-name="props">
        <q-td :props="props">
          <span v-if="isPlaceLockedFor(props.row, currentUser)">
            {{ props.row.name }}
          </span>
          <router-link v-else class="item-link" :to="{ name: 'place', params: { id: props.row.id } }">
            {{ props.row.name }}
          </router-link>
        </q-td>
      </template>

    </sc-list>

  </sc-view>
</template>

<style lang='scss' scoped>
</style>
