import { parseEnum } from '@stellacontrol/utilities'
import { PlanRectangle } from './plan-rectangle'
import { PlanItemType } from './plan-item'
import { PlanPortType } from './plan-port'
import { PlanBackgroundStyle, PlanTextStyle } from '../styles'

/**
 * Splitter types
 */
export const SplitterType = {
  SplitterTwoWay: 'splitter-two-way',
  SplitterThreeWay: 'splitter-three-way',
  SplitterFourWay: 'splitter-four-way',
  TapperTwoWay: 'tapper-two-way'
}

/**
 * Splitter names
 */
export const SplitterName = {
  [SplitterType.SplitterTwoWay]: 'Two-Way Splitter',
  [SplitterType.SplitterThreeWay]: 'Three-Way Splitter',
  [SplitterType.SplitterFourWay]: 'Four-Way Splitter',
  [SplitterType.TapperTwoWay]: 'Two-Way Tapper'
}

/**
 * Splitter parameters per type
 */
export const SplitterParameters = {
  [SplitterType.SplitterTwoWay]: { outPorts: 2, gain: -3.5 },
  [SplitterType.SplitterThreeWay]: { outPorts: 3, gain: -5.0 },
  [SplitterType.SplitterFourWay]: { outPorts: 4, gain: -7.5 },
  [SplitterType.TapperTwoWay]: { outPorts: 2, gain: -6.0 },
}

/**
 * Splitter-specific defaults
 */
const SplitterDefaults = {
  [SplitterType.SplitterTwoWay]: {
    width: 80,
    height: 40,
    backgroundStyle: new PlanBackgroundStyle({ color: 'yellow' }),
  },
  [SplitterType.SplitterThreeWay]: {
    width: 80,
    height: 40,
    backgroundStyle: new PlanBackgroundStyle({ color: 'yellow' })
  },
  [SplitterType.SplitterFourWay]: {
    width: 80,
    height: 40,
    backgroundStyle: new PlanBackgroundStyle({ color: 'yellow' })
  },
  [SplitterType.TapperTwoWay]: {
    width: 80,
    height: 40,
    backgroundStyle: new PlanBackgroundStyle({ color: 'yellow' })
  },
}

/**
 * Splitter/Tapper
 */
export class PlanSplitter extends PlanRectangle {
  constructor (data = {}) {
    super(data)
    this.assign(data)
  }

  __splitterType

  /**
   * Item type
   * @type {PlanItemType}
   */
  static get type () {
    return PlanItemType.Splitter
  }

  /**
   * Creates a splitter
   * @param {Object} data Item data
   * @returns {PlanSplitter}
   */
  static createSplitter (data = {}) {
    return new PlanSplitter({
      ...data,
      splitterType: data.splitterType || SplitterType.SplitterTwoWay
    })
  }

  /**
   * Object defaults
   */
  get defaults () {
    const { splitterType } = this
    const splitterDefaults = SplitterDefaults[splitterType]


    const defaults = {
      ...super.defaults,
      ...splitterDefaults,
      textStyle: PlanTextStyle.Small
    }

    return defaults
  }

  normalize () {
    super.normalize()
    this.splitterType = parseEnum(SplitterType, this.splitterType, SplitterType.SplitterTwoWay)
    this.createPorts()
  }

  /**
   * Serializes the plan item to JSON
   * @returns {Object}
   */
  toJSON () {
    const result = super.toJSON()

    // Remove runtime data
    result.splitterType = result.__splitterType
    delete result.__splitterType

    // Remove fixed styles
    delete result.backgroundStyle
    delete result.lineStyle

    return result
  }

  /**
   * Human-friendly representation of the item, useful for the UI
   * @param {Boolean} details If true, detailed description is returned
   * @returns {String}
  */
  // eslint-disable-next-line no-unused-vars
  toHumanString (details) {
    return SplitterName[this.splitterType]
  }

  /**
   * Splitter parameters
   * @type {Object}
   */
  get parameters () {
    return SplitterParameters[this.splitterType]
  }

  /**
   * If true, the label remains horizontal regardless of rotation of the item
   */
  get isLabelHorizontal () {
    return true
  }

  /**
   * Splitter type
   * @type {SplitterType}
   */
  get splitterType () {
    return this.__splitterType
  }
  set splitterType (value) {
    if (value != null && this.__splitterType !== value) {
      if (this.__splitterType != null) {
        // If splitter type was changed, refresh the ports and reset sizes to defaults
        this.__splitterType = value
        this.refresh()
      } else {
        this.__splitterType = value
      }
    }
  }

  /**
   * Automatically generated label
   * @type {String}
   */
  get autoLabel () {
    return `${SplitterName[this.splitterType]}`
  }

  /**
   * Creates splitter ports
   */
  createPorts () {
    this.clearPorts()
    const { splitterType } = this
    if (splitterType) {
      this.addPort(PlanPortType.In, PlanPortType.In)
      const length = SplitterParameters[splitterType].outPorts
      Array
        .from({ length })
        .every((_, index) => this.addPort((index + 1).toString(), PlanPortType.Out))
    }
  }

  /**
   * Refreshes the splitter after changing type -
   * creates splitter ports, updates sizes etc.
   */
  refresh () {
    const { splitterType } = this
    const splitterDefaults = SplitterDefaults[splitterType]
    Object.assign(this, splitterDefaults)
    this.createPorts()
  }

  /**
   * Determine signal gain/loss at the item
   * @param {PlanLayout} layout Plan layout
   * @returns {Number}
   */
  getGain (layout) {
    const { parameters } = this
    if (layout && parameters) {
      return parameters.gain
    }
    return 0
  }
}
