import { assign } from '@stellacontrol/utilities'
import LanguageList from './languages.json'

/**
 * List of codes of languages supported by the platform
 */
export const SupportedLanguages = ['en', 'fr', 'es', 'it']

/**
 * Language
 */
export class Language {
  constructor (data = {}) {
    assign(
      this,
      {
        ...data
      })
  }

  /**
   * ISO 639-2 language code
   * @type {String}
   */
  code

  /**
   * Language variant
   * @type {String}
   */
  variant

  /**
   * Language key, combination of code and eventual variant
   * @type {String}
   */
  get key () {
    const { code, variant } = this
    if (code) {
      return variant
        ? `${code}-${variant}`
        : code
    }
  }

  /**
   * Language names, per language code(+variant)
   * @type {Dictionary<String, String>}
   */
  names

  /**
   * Default English name of the language
   * @type {String}
   */
  get name () {
    const { names } = this
    if (names) {
      return names['en'] || names[0]
    }
  }

  /**
   * If false, the language is no longer active, eg. extinct
   * @type {Boolean}
   */
  isActive

  /**
   * True indicates that the language is supported by the platform
   * @type {Boolean}
   */
  get isSupported () {
    return isSupportedLanguage(this.code)
  }
}

/**
 * List of languages
 */
export const Languages = {
  /**
   * All languages
   */
  All: LanguageList.items.map(item => new Language(item)),
  /**
   * Languages officially supported by the platform
   */
  Supported: SupportedLanguages
    .map(code => LanguageList.items.find(item => item.code === code))
    .map(item => new Language(item))
}


/**
 * Gets language data by code
 * @param {String} code ISO 639-2 language code
 * @returns {Language} language data
 */
export function getLanguage (code) {
  if (code) {
    if (code.length === 2) {
      return Languages.All.find(language => language.code === code)
    }
  }
}

/**
 * Checks whether language is valid
 * @param {String} code ISO 639-2 language code
 * @returns {Boolean} True if language is valid
 */
export function isValidLanguage (code) {
  const language = getLanguage(code)
  return Boolean(language)
}

/**
 * Checks whether language is supported by the platform
 * @param {String} code ISO 639-2 language code
 * @returns {Boolean} True if language is supported by the platform
 */
export function isSupportedLanguage (code) {
  const language = getLanguage(code)
  return language && Languages.Supported.some(c => c.code === language.code)
}
