<script>
import { safeParseInt, safeParseFloat, clip } from '@stellacontrol/utilities'
import { Attachment } from '@stellacontrol/model'
import { CableLengthMode } from '@stellacontrol/planner'
import { PlanActions } from '../../../renderer/actions'
import LayerProperties from './layer-properties'

export default {
  mixins: [
    LayerProperties
  ],

  data () {
    return {
      // Selected files, array of `File` objects
      files: null,

      CableLengthMode
    }
  },

  computed: {
    // Indicates that we're editing the cross-section view
    isCrossSection () {
      return this.renderer?.isCrossSection
    },

    // Indicates that we're editing the floor plan
    isFloor () {
      return this.renderer?.isFloor
    },

    // Image label
    imageLabel () {
      const text = this.background.image?.name || '···'
      return clip(text, 10)
    },

    // Style for the floor name input
    floorInputStyle () {
      return this.floor?.canDelete
        ? {
          'width': '86px',
          'max-width': '86px'
        }
        : {
          'width': '130px',
          'max-width': '130px'
        }
    },

    // Determines whether transforming plan images is allowed
    allowImageTransform () {
      return this.isAdvancedMode && this.isFloor && this.background.hasImage
    },

    // Indicates whether cable lengths are visible at all
    cableLengthsVisible () {
      return this.layout.cableLengths !== CableLengthMode.Off
    }
  },

  methods: {
    safeParseInt,
    safeParseFloat,

    // Deletes the floor
    deleteFloor () {
      const { floor } = this
      this.$emit('action', { action: PlanActions.RemoveFloor, floor })
    },

    // Toggles floor connectors visibility
    toggleFloorConnectors (isVisible) {
      this.$emit('action', { action: PlanActions.ToggleFloorConnectors, isVisible })
    },

    // Toggles grid visibility
    toggleGrid (isVisible) {
      this.$emit('action', { action: PlanActions.ToggleGrid, isVisible })
    },

    // Sets grid size
    setGridSize (size) {
      this.$emit('action', { action: PlanActions.SetGridSize, size })
    },

    // Toggles background image with the plan
    toggleBackgroundImage (isVisible) {
      this.$emit('action', { action: PlanActions.ToggleBackgroundImage, isVisible })
    },

    // Opens file picker
    selectBackgroundImage () {
      this.$refs.imagePicker.pickFiles()
    },

    // Clears the background image
    clearBackgroundImage () {
      this.$emit('action', { action: PlanActions.ClearBackgroundImage })
    },

    // Sets the background image
    async setBackgroundImage (file) {
      file = file ? await Attachment.fromFile(file) : null
      this.$emit('action', { action: PlanActions.SetBackgroundImage, file })
    },

    // Sets the background image position
    setBackgroundImagePosition (imagePosition) {
      this.$emit('action', { action: PlanActions.SetBackgroundImage, imagePosition })
    },

    // Sets the background image scale
    setBackgroundImageScale (imageScale) {
      this.$emit('action', { action: PlanActions.SetBackgroundImage, imageScale })
    },

    // Rotates the image by increments of 90
    rotateImage () {
      let imageRotation = this.background.imageRotation + 90
      if (imageRotation % 90 !== 0 || imageRotation > 270) {
        imageRotation = 0
      }
      this.$emit('action', { action: PlanActions.SetBackgroundImage, imageRotation })
    },

    // Changes the floor dimensions
    async setFloorSize (size) {
      this.$emit('action', { action: PlanActions.SetFloorSize, size })
    },

    // Changes scale of plan elements
    async setEquipmentScale (scale) {
      this.$emit('action', { action: PlanActions.SetEquipmentScale, scale })
    },

    // Changes scale of the floor map
    async setMapScale (scale) {
      const { floor } = this
      this.$emit('action', { action: PlanActions.SetMapScale, floor, scale })
    },

    // Starts/stops interactively setting the map scale
    async drawMapScale () {
      this.$emit('action', { action: PlanActions.DrawMapScale })
    },

    // Changes the margin around the floor image
    async setFloorMargin (margin) {
      this.$emit('action', { action: PlanActions.SetFloorMargin, margin })
    },

    // Sets the floor label
    setFloorLabel (label) {
      const { floor } = this
      this.$emit('action', { action: PlanActions.SetFloorLabel, floor, label })
    },

    // Toggles cable lengths visibility
    toggleCableLengths (mode) {
      if (mode === false) {
        this.$emit('action', { action: PlanActions.ToggleCableLengths, mode: CableLengthMode.Off })
      } else {
        this.$emit('action', { action: PlanActions.ToggleCableLengths, mode: CableLengthMode.Actual })
      }
    },

    // Toggles equipment tag visibility
    toggleTags (isVisible) {
      this.$emit('action', { action: PlanActions.ToggleTags, isVisible })
    },

    // Toggles risers visibility
    toggleRisers (isVisible) {
      this.$emit('action', { action: PlanActions.ToggleRisers, isVisible })
    },
  }
}
</script>

<template>
  <main>
    <div class="column">
      <section>
        <div class="property row items-center q-mt-sm" v-if="isFloor">
          <label>
            Floor name
          </label>
          <q-input type="text" dense outlined square debounce="500" :input-style="floor.canDelete ? { 'width' : '133px'} : styles.text"
            :model-value="floor.label" @update:model-value="label => setFloorLabel(label)">
          </q-input>
          <q-btn class="q-ml-xs button" unelevated no-wrap outline square icon="close" color="red-6"
            @click="deleteFloor()" v-if="floor.canDelete">
            <sc-tooltip text="Remove the floor" nowrap></sc-tooltip>
          </q-btn>
        </div>

        <div class="property row items-center q-mt-sm" v-if="isFloor">
          <label>
            Image File
          </label>
          <q-btn class="button file-button" :class="{ 'has-image': background.hasImage }" unelevated
            no-caps no-wrap outline square color="grey-7"
            :label="background.hasImage ? imageLabel : 'Select image'"
            @click="selectBackgroundImage()">
          </q-btn>
          <q-btn class="q-ml-xs button" unelevated no-wrap outline square icon="close" color="red-6"
            @click="clearBackgroundImage()" v-if="background.hasImage">
            <sc-tooltip text="Remove the image" nowrap></sc-tooltip>
          </q-btn>
          <q-file ref="imagePicker" class="file-picker" accept=".jpg,.png" v-model="files" clearable
            square outlined dense @update:model-value="files => setBackgroundImage(files)">
          </q-file>
        </div>

        <div class="property row items-center q-mt-sm"
          v-if="isFloor && background.hasImage">
          <label>
            Image Size
          </label>
          <q-input dense outlined square disabled :input-style="styles.text.medium"
            :model-value="`${floor.dimensions.width} x ${floor.dimensions.height}`">
          </q-input>
          <q-btn class="q-ml-xs button" unelevated no-wrap outline square icon="rotate_right"
            color="grey-7" @click="rotateImage()">
            <sc-tooltip text="Rotate the image" nowrap></sc-tooltip>
          </q-btn>
        </div>

        <div class="property row items-center q-mt-sm" v-if="isAdvancedMode">
          <label>
            Margins
          </label>
          <q-input type="number" :min="0" :max="1000" dense outlined square label="Left"
            debounce="500" :input-style="styles.number" :model-value="floor.margin.left"
            @update:model-value="value => setFloorMargin({ left: safeParseInt(value, 0) })">
          </q-input>
          <q-input type="number" :min="0" :max="1000" dense outlined square label="Right"
            class="q-ml-xs" debounce="500" :input-style="styles.number"
            :model-value="floor.margin.right"
            @update:model-value="value => setFloorMargin({ right: safeParseInt(value, 0) })">
          </q-input>
        </div>
        <div class="property row items-center q-mt-sm" v-if="isAdvancedMode">
          <label>
          </label>
          <q-input type="number" :min="0" :max="1000" dense outlined square label="Top"
            debounce="500" :input-style="styles.number" :model-value="floor.margin.top"
            @update:model-value="value => setFloorMargin({ top: safeParseInt(value, 0) })">
          </q-input>
          <q-input type="number" :min="0" :max="1000" dense outlined square label="Bottom"
            class="q-ml-xs" debounce="500" :input-style="styles.number"
            :model-value="floor.margin.bottom"
            @update:model-value="value => setFloorMargin({ bottom: safeParseInt(value, 0) })">
          </q-input>
        </div>

        <div class="property row items-center q-mt-sm" v-if="false">
          <label>
            Scale
          </label>
          <q-input type="number" :min="0.1" :max="10" :step="0.1" dense outlined square
            debounce="500" label="Scale" :input-style="styles.number"
            :model-value="background.imageScale"
            @update:model-value="value => setBackgroundImageScale(safeParseFloat(value, 1))">
          </q-input>
        </div>
      </section>

      <section class="q-mt-md">
        <div class="text-bold q-mb-xs">
          Scale
        </div>
        <div class="property row items-center q-mt-sm">
          <label>
            Equipment
          </label>
          <q-input type="number" :min="0.1" :max="10" :step="0.1" dense outlined square
            debounce="500" label="Scale" :input-style="styles.number"
            :model-value="layout.scale.value"
            @update:model-value="value => setEquipmentScale(safeParseFloat(value, 0))">
          </q-input>
        </div>
        <div class="property row items-center q-mt-sm" v-if="isFloor">
          <label>
            Map
          </label>
          <q-input type="number" :min="1" :max="100000" :step="1" dense outlined square
            debounce="500" label="px / m" :input-style="styles.number" :model-value="floor.mapScale"
            @update:model-value="value => setMapScale(safeParseInt(value, 0))">
          </q-input>
          <q-btn class="button q-ml-xs" :class="{ selected: renderer.isDrawingMapScale }" unelevated
            no-caps no-wrap square icon="open_in_full" :outline="!renderer.isDrawingMapScale"
            color="grey-7" :ripple="false" @click="drawMapScale()">
            <sc-tooltip :text="renderer.isDrawingMapScale
          ? 'Draw a line to indicate one meter distance'
          : 'Click to draw the map scale'">
            </sc-tooltip>
          </q-btn>
        </div>
      </section>

      <section class="q-mt-md" v-if="isAdvancedMode">
        <div class="text-bold q-mb-xs">
          Show
        </div>
        <div class="property row items-center q-mb-sm">
          <label class="wider">
            <q-checkbox dense :model-value="layout.showTags" label="Equipment annotations"
              @update:model-value="value => toggleTags(value)">
            </q-checkbox>
          </label>
        </div>
        <div class="property row items-center q-mb-sm">
          <label class="wider">
            <q-checkbox dense :model-value="layout.showRisers" label="Risers and riser cables"
              @update:model-value="value => toggleRisers(value)">
            </q-checkbox>
          </label>
        </div>
        <div class="property row items-center q-mb-sm">
          <label class="wider row items-center">
            <q-checkbox dense :model-value="cableLengthsVisible" label="Cable Lengths"
              @update:model-value="value => toggleCableLengths(value)">
            </q-checkbox>
          </label>
        </div>
        <div class="property row items-center" v-if="isFloor">
          <label class="wider">
            <q-checkbox dense :model-value="layout.showGrid" label="Plan Grid"
              @update:model-value="value => toggleGrid(value)">
            </q-checkbox>
          </label>
          <q-input v-if="layout.showGrid" type="number" :min="5" :max="100" dense outlined square debounce="500"
            label="Grid size" :input-style="{ 'max-width': '70px' }" :model-value="layout.gridSize"
            @update:model-value="value => setGridSize(value)">
          </q-input>
        </div>
      </section>

    </div>
  </main>
</template>

<style lang="scss" scoped>
main {
  padding: 10px 10px 20px 10px;

  .property>label:not(.q-field) {
    min-width: 93px;
    margin-right: 8px;

    &.wider {
      min-width: 125px;
    }
  }

  .button {
    height: 40px;

    &.file-button {
      width: 202px;

      &.has-image {
        width: 158px;
      }
    }

    &.selected {
      color: white;
      background-color: #3949ab;
    }
  }

  .file-picker {
    display: none;
  }
}
</style>
