<script>
export default {
  props: {
    size: {
      type: String,
      default: '28px'
    },

    color: {
      type: String,
      default: '#7986cb'
    },

    icon: {
      type: String,
      default: 'change_circle'
    }
  },

  computed: {
    style () {
      return {
        'font-size': this.size,
        'color': this.color
      }
    }
  }
}
</script>

<template>
  <i v-bind="{...$props, ...$attrs}"
     class="progress-icon material-icons q-icon notranslate"
     :style="style">
     {{ icon }}
  </i>

</template>

<style scoped lang="scss">
.progress-icon {
  animation: rotate 2.0s infinite linear reverse;
}
</style>