import PremiumSubscribersView from './premium-subscribers.vue'
import { resolve } from './premium-subscribers.resolve'

export const Routes = [
  {
    name: 'premium-subscribers',
    path: '/premium-services/wallets',
    component: PremiumSubscribersView,

    async beforeEnter (to, from, next) {
      resolve({ to, from })
      next()
    }
  }
]
