import { Entity } from '../common/entity'
import { User } from '../organization'
import { Place } from '../device'
import { Organization } from '../organization'

/**
 * Plan
 */
export class Plan extends Entity {
  constructor (data = {}) {
    super()

    this.assign({
      ...data,
      version: data.version || 1
    })
  }

  /**
   * Creates a new empty plan associated with the specified organization and place
   * @param {String} name Plan name
   * @param {String} description Plan description
   * @param {Organization} organization Plan owner
   * @param {Place} place Place to which the plan belongs
   * @returns {Plan}
   */
  static createEmpty ({ name, description, organization, place } = {}) {
    return new Plan({
      name,
      description,
      organization,
      organizationId: organization ? organization.id : undefined,
      place,
      placeId: place ? place.id : undefined
    })
  }

  /**
   * Creates a new plan using the other plan's layout
   * @param {String} name Plan name
   * @param {String} description Plan description
   * @param {Organization} organization Plan owner
   * @param {Place} place Place to which the plan belongs
   * @param {Plan} plan Plan to borrow the layout from
   * @returns {Plan}
   */
  static createFrom ({ name, description, organization, place, plan } = {}) {
    return new Plan({
      name,
      description,
      organization,
      organizationId: organization ? organization.id : undefined,
      place,
      placeId: place ? place.id : undefined,
      layout: plan.layout,
      version: 1
    })
  }

  /**
   * Normalizes the data after assignment
   */
  normalize () {
    super.normalize()
    if (this.creator) {
      this.creator = this.cast(this.creator, User)
    }
    if (this.updater) {
      this.updater = this.cast(this.updater, User)
    }
    if (this.deleter) {
      this.deleter = this.cast(this.deleter, User)
    }
    if (this.place) {
      this.place = this.cast(this.place, Place)
    }
    if (this.organization) {
      this.organization = this.cast(this.organization, Organization)
    }
  }

  /**
   * Detailed description
   * @type {String}
   */
  description

  /**
   * Identifier of organization which owns the plan
   * @type {String}
   */
  organizationId

  /**
   * Organization which owns the plan
   * @type {Organization}
   */
  organization

  /**
   * Identifier of a place associated with the plan
   * @type {String}
   */
  placeId

  /**
   * Place associated with the plan
   * @type {Plan}
   */
  place

  /**
   * Plan layout, as created with the StellaPlanner tool
   * @type {PlanLayout}
   */
  layout

  /**
   * Plan version, used for optimistic locking
   * when plan is edited simultaneously by more people
   * @type {Number}
   */
  version

  /**
   * Checks whether the plan has a background image
   * @type {Boolean}
   */
  get hasImage () {
    return this.layout.background.image != null
  }

  /**
   * URL of the plan image
   * @type {String}
  */
  get imageUrl () {
    return this.layout.background.image?.reference

  }

  // Removes runtime data on serialization
  toJSON () {
    const result = {
      ...this
    }
    return result
  }

  /**
   * Used to purge images associated with plan floors, before saving the plan.
   * Background images are to be stored externally in file storage, not inside the plan layout.
   */
  purgeFloorImages () {
    const { layout } = this
    if (layout?.background?.image) {
      layout.background.image.content = null
    }
  }
}
