<script>
import { mapActions } from 'vuex'
import { Attachment } from '@stellacontrol/model'
import FileNotes from './file-notes.vue'

export default {
  props: {
    file: {
      type: Attachment
    },

    bordered: {
      type: Boolean,
      default: false
    },

    rounded: {
      type: Boolean,
      default: false
    },

    canSelect: {
      type: Boolean,
      default: false
    },

    canDelete: {
      type: Boolean,
      default: false
    },

    canAnnotate: {
      type: Boolean,
      default: false
    },

    canPreview: {
      type: Boolean,
      default: false
    },

    canPrint: {
      type: Boolean,
      default: false
    },

    canDownload: {
      type: Boolean,
      default: false
    }
  },

  components: {
    'sc-file-notes': FileNotes
  },

  data () {
    return {
      hover: false
    }
  },

  computed: {
    // Indicates whether the file can be viewed
    canPreviewFile () {
      return this.canPreview && this.file?.canPreview || this.file?.canPreviewInFrame
    }
  },

  emits: [
    'select',
    'download',
    'print',
    'preview',
    'remove'
  ],

  methods: {
    ...mapActions([
      'showDialog'
    ]),

    select () {
      this.$emit('select', this.file)
    },

    download () {
      this.$emit('download', this.file)
    },

    print () {
      this.$emit('print', this.file)
    },

    preview () {
      if (this.canPreviewFile) {
        this.$emit('preview', this.file)
      }
    },

    remove () {
      this.$emit('remove', this.file)
    },

    // Returns a text with file notes
    getNotes () {
      const { file } = this
      if (!file.hasNotes) return
      return file
        .notes
        .map(note => note.text)
        .join('<br><br>')
    },

    // Edits the file notes
    async editNotes () {
      const { file: attachment } = this
      await this.showDialog({
        dialog: 'attachment-notes',
        data: { attachment }
      })
    }
  }
}
</script>

<template>
  <main :class="{ hover, bordered, rounded, selected: file.isSelected }" @mouseenter="hover = true"
    @mouseleave="hover = false"
    @click="preview">

    <span>
      <q-icon :name="file.icon" size="sm" color="indigo-6">
      </q-icon>
    </span>
    <span class="name">
      {{ file.name }}
    </span>
    <span class="entity" v-if="file.dataType">
      {{ file.dataTypeLabel }}
    </span>
    <span class="entity" v-if="file.entity">
      {{ file.entityName }}
    </span>
    <q-space></q-space>
    <span class="date">
      {{ datetime(file.createdAt) }}
    </span>

    <span class="buttons" v-if="hover">
      <q-btn round dense flat icon="print" color="purple-6" v-if="canPrint && file.canPrint" size="sm"
        @click.stop="print">
        <sc-tooltip>
          Prints the report and downloads as ZIP archive
        </sc-tooltip>
      </q-btn>
      <q-btn round dense flat icon="download" color="purple-6" v-if="canDownload && file.canDownload" size="sm"
        @click.stop="download">
        <sc-tooltip>
          Downloads the file data
        </sc-tooltip>
      </q-btn>
      <q-btn round dense flat icon="search" color="purple-6" v-if="canPreviewFile" @click.stop="preview" size="sm">
        <sc-tooltip>
          Opens preview of the file
        </sc-tooltip>
      </q-btn>
      <q-btn round dense flat icon="delete" color="red-9" v-if="canDelete && file.canDelete" @click.stop="remove" size="sm">
        <sc-tooltip>
          Deletes the file
        </sc-tooltip>
      </q-btn>
    </span>

    <span class="selector" v-if="canSelect">
      <div class="checkbox" v-if="hover">
        <q-checkbox dense size="md" @click.stop="select()" :model-value="file.isSelected">
        </q-checkbox>
      </div>
      <div class="indicator" v-if="!hover">
        <q-icon name="check" size="sm" color="purple-6" v-if="file.isSelected" @click.stop="select">
        </q-icon>
      </div>
    </span>

    <span class="note" v-if="canAnnotate">
      <div class="indicator">
        <q-icon name="text_snippet" size="sm" color="indigo-5" v-if="file.hasNotes || hover"
          @click.stop="editNotes()">
          <sc-tooltip :text="getNotes()">
          </sc-tooltip>
        </q-icon>
      </div>
    </span>

  </main>
</template>

<style scoped lang="scss">
main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100px;
  min-width: 140px;
  max-width: 140px;
  padding: 10px 5px 10px 5px;
  background-color: #fafafa;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  gap: 4px;
  position: relative;

  &.bordered {
    border: solid #e0e0e0 1px;
  }

  &.rounded {
    border-radius: 4px;
  }

  &.selected {
    background-color: #d9d9d9;
  }

  span {
    display: block;
    width: 100%;
    font-size: 12px;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    text-align: center;
  }

  .date {
    color: #444;
  }

  .buttons {
    position: absolute;
    bottom: 0;
    height: 36px;
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-color: #dbe8f4;

    .button {
      cursor: pointer;
      opacity: 0.85;
    }

    .button:hover {
      opacity: 1;
    }
  }

  .selector {
    position: absolute;
    top: 6px;
    left: 4px;
    width: 28px;
    height: 28px;
  }

  .note {
    position: absolute;
    top: 11px;
    right: 8px;
    width: 28px;
    height: 28px;
  }

  &:hover {
    background-color: #dbe8f4;

    .buttons {
      display: flex;
      flex-direction: row;
      gap: 8px;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>