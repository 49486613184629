import { parseDate, Period, getPeriod } from '@stellacontrol/utilities'
import { Entity } from '../common/entity'
import { formatDuration, isLeapYear } from 'date-fns'

/**
 * Represents a premium service to which a customer can subscribe,
 * composed of one or more premium application features
 */
export class PremiumService extends Entity {
  constructor (data = {}) {
    super()

    this.assign(
      {
        ...data,
        createdAt: data.createdAt || new Date(),
        features: data.features || [],
        price: data.price == null ? 1 : data.price,
        period: data.period == null ? 1 : data.period,
        periodUnit: data.periodUnit == null ? Period.Day : data.periodUnit,
        isPerDevice: data.isPerDevice == null ? true : data.isPerDevice,
        isEnabled: data.isEnabled == null ? true : Boolean(data.isEnabled),
        isPrivate: data.isPrivate == null ? false : Boolean(data.isPrivate),
        isDefault: data.isDefault == null ? false : Boolean(data.isDefault)
      },
      {
        createdAt: parseDate,
        isPerDevice: Boolean,
        isEnabled: Boolean,
        isPrivate: Boolean,
        isDefault: Boolean
      }
    )

    if (!PremiumService.allowedPeriodUnits.includes(this.periodUnit)) {
      throw new Error(`Period unit [${this.periodUnit}] is not supported`)
    }
  }

  /**
   * Allowed period units
   * @type {Array[Period]}
   */
  static allowedPeriodUnits = [Period.Year, Period.Month, Period.Day]

  __period

  /**
   * Identifier of the pricelist to which the premium service belongs
   * @type {String}
   */
  pricelistId

  /**
   * Identifier of the organization to which the pricelist belongs
   * @type {String}
   */
  organizationId

  /**
   * Premium service product code
   * @type {String}
   */
  code

  /**
   * Premium service name
   * @type {String}
   */
  name

  /**
   * Premium service label including name and code
   */
  get label () {
    const { code, name } = this
    return code ? `${code}${name ? ' / ' : ''}${name || ''}` : (name || '')
  }

  /**
   * Premium service description
   * @type {String}
   */
  description

  /**
   * Custom color representing the premium service, used for coloring icons etc.
   * @type {String}
   */
  color

  /**
   * Determines service color, either custom specified above or one of the default colors
   */
  getColor () {
    const { color, isDefault, isPrivate } = this
    return color || (isDefault ? 'green-7' : (isPrivate ? 'orange-5' : 'indigo-5'))
  }

  /**
   * Application features included in the premium service
   * @type {Array[String]}
   */
  features

  /**
   * The first of application features in the premium service
   * @type {String}
   */
  get feature () {
    return this.features[0]
  }

  /**
   * Indicates that this is a default service, suggested automatically
   * whenever selling devices to new owners etc.
   */
  isDefault

  /**
   * Returns true if premium service represents a bundle of application features
   * @type {Boolean}
   */
  get isBundle () {
    return this.features.length > 1
  }

  /**
   * Returns true if specified feature is included in the premium service
   * @param name Feature name
   */
  hasFeature (name) {
    return (this.features || []).includes(name)
  }

  /**
   * Adds the specified feature to premium service
   * @param name Feature name
   */
  addFeature (name) {
    if (!this.hasFeature(name)) {
      this.features = [...(this.features || []), name]
    }
  }

  /**
   * Removes the specified feature from premium service
   * @param name Feature name
   */
  removeFeature (name) {
    const index = (this.features || []).indexOf(name)
    if (index > -1) {
      this.features.splice(index, 1)
    }
  }

  /**
   * Price of the premium service, in tokens
   * @type {Number}
   */
  price

  /**
   * Indicates a minimal period for which the service is purchased.
   * This can be further extended by multiplies of that period,
   * when customer subscribes to the service.
   * If not specified, it's a non-expiring service, enabled by one-off payment
   * @type {Number}
   * @description Special logic is required for premium services with unit of one day
   * and duration of one year, during the leap years!
   */
  get period () {
    if (this.periodUnit === Period.Day && (this.__period === 365 || this.__period === 366)) {
      return isLeapYear(new Date()) ? 366 : 365
    }
    return this.__period
  }

  set period (value) {
    if (this.periodUnit === Period.Day && (value === 365 || value === 366)) {
      this.__period = isLeapYear(new Date()) ? 366 : 365
    } else {
      this.__period = value
    }
  }

  /**
   * Indicates a time unit for the {@link period} property.
   * @type {Period}
   */
  periodUnit

  /**
   * Returns true if service is billed per day
   * @type {Boolean}
   */
  get isPerDay () {
    return this.periodUnit === Period.Day
  }

  /**
   * Returns true if service is billed per month
   * @type {Boolean}
   */
  get isPerMonth () {
    return this.periodUnit === Period.Month
  }

  /**
   * Returns true if service is billed per year
   * @type {Boolean}
   */
  get isPerYear () {
    return this.periodUnit === Period.Year
  }

  /**
   * Indicates that this premium service is charged per device
   * @type {Boolean}
   */
  isPerDevice

  /**
   * Indicates that this premium service is only visible to the creator
   * of the pricelist. Useful for things such as free offerings,
   * which should be strictly controlled by the bank organization.
   * @type {Boolean}
   */
  isPrivate

  /**
   * Indicates that the premium service is public
   * and can be sold by my resellers
   * @type {Boolean}
   */
  get isPublic () {
    return !this.isPrivate
  }

  /**
   * Number of premium subscriptions associated with the service
   * @type {Number}
   */
  subscriptionCount

  /**
   * Indicates that this premium service has been used
   * to create some subscriptions
   * @type {Boolean}
   */
  get hasSubscriptions () {
    return this.subscriptionCount > 0
  }

  /**
   * Returns true if price is charged periodically
   * @type {Boolean}
   */
  get isChargedPeriodically () {
    return Boolean(this.period && this.periodUnit)
  }

  /**
   * Returns true if service never expires
   * and does not require periodic renewals
   * @type {Boolean}
   */
  get neverExpires () {
    return !(this.period && this.periodUnit)
  }

  /**
   * Returns true if service is free of charge
   * @type {Boolean}
   */
  get isFree () {
    return this.price === 0
  }

  /**
   * Returns true if service is paid
   * @type {Boolean}
   */
  get isPaid () {
    return this.price > 0
  }

  // Checks whether subscription for this service can be extended.
  // This is when service is charged per device and/or per period.
  get subscriptionCanBeExtended () {
    return this.isPerDevice || this.isChargedPeriodically
  }

  /**
   * Human-friendly description of the single period of subscription
   */
  get periodDescription () {
    return this.getPeriodDescription(this.period, this.periodUnit)
  }

  /**
   * Human-friendly description of service subscription period
   * @param duration Duration of subscription
   * @param unit Duration time unit
   */
  getPeriodDescription (duration, unit) {
    if (!unit) throw new Error('Time unit is required')
    if (duration == null) return ''
    if (duration === 0) {
      return 'never expires'
    }

    switch (unit) {
      case Period.Year:
        return formatDuration({ years: duration })
      case Period.Month:
        return formatDuration({ months: duration })
      case Period.Day:
        return formatDuration({ days: duration })
      default:
        throw new Error(`Time unit [${unit}] is not supported`)
    }
  }

  /**
   * Human-friendly price label, including 'tokens' suffix with proper plurality
   * @type {String}
   */
  get priceLabel () {
    const { price } = this
    return price === 0 ? 'free of charge' : `${price} token${price === 1 ? '' : 's'}`
  }

  /**
   * Human-friendly description of the price, including period and per-device modifier
   * @type {String}
   */
  get priceDescription () {
    const { neverExpires, isPerDevice, priceLabel, periodDescription } = this

    let description = []
    if (!neverExpires) {
      description.push(periodDescription)
    }
    description.push(priceLabel)
    if (isPerDevice) {
      description.push('per device')
    }

    return description
      .filter(p => p)
      .join(' / ')
  }

  /**
   * Calculates service duration in service periods, for the specified date range
   * @param {Date} startDate Period start
   * @param {Date} endDate Period end
   */
  getDuration (startDate, endDate) {
    const { period, periodUnit, isChargedPeriodically } = this
    if (isChargedPeriodically) {
      const duration = getPeriod(startDate, endDate, periodUnit)
      return Math.ceil(duration / period)
    } else {
      return 0
    }
  }

  /**
   * Identifier of reseller to whom this premium service belongs
   * @type {String}
   */
  resellerId

  toJSON () {
    const result = {
      ...this,
      period: this.period
    }
    delete result.__period
    return result
  }
}
