import { Color } from '@stellacontrol/utilities'

/**
 * Filter for making all pixels matching the specified color transparent
 * @param {Array[String|Object]} colors Color of the pixels to process, specified as hex colors or `{ r, g, b}` tuples
 * @param {Number} tolerance Color tolerance
 * @returns {Function} Filter function
 */
export function TransparencyFilter ({ colors = [], tolerance = 10 } = {}) {
  return (image) => {
    if (colors.length === 0) return

    const count = image.data.length
    const rgbColors = colors.map(c => Color.toRGB(c)).filter(c => c)
    const { data } = image
    const colorMatches = (value, color) => value >= color - tolerance && value <= color + tolerance

    for (let i = 0; i < count - 4; i += 4) {
      for (const color of rgbColors) {
        const matches = colorMatches(data[i], color.r) &&
        colorMatches(data[i + 1], color.g) &&
        colorMatches(data[i + 2], color.b)
        if (matches) {
          image.data[i + 3] = 0
        }
      }
    }
  }
}
