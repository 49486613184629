import { safeParseInt } from '@stellacontrol/utilities'
import { dispatch, getters } from '@stellacontrol/client-utilities'

/**
 * Prepares data for device-updates route
 */
export async function resolveDeviceFirmwares () {
  await dispatch('requireOrganizations')
  await dispatch('getDeviceTypes')

  const isSuperAdministrator = getters('isSuperAdministrator')
  const firmwares = await dispatch('getDeviceFirmwares')
  const jobs = await dispatch('getUploadJobs', { allOrganizations: isSuperAdministrator })
  return { firmwares, jobs }
}

/**
 * Prepares data for device-updates route, jobs tab
 */
export async function resolveJobUploads ({ to }) {
  const isSuperAdministrator = getters('isSuperAdministrator')
  const jobDays = safeParseInt(to.query.days, 0)
  await dispatch('storeJobDays', { jobDays })
  await dispatch('getUploadJobs', { allOrganizations: isSuperAdministrator })
}

/**
 * Prepares data for firmware route
 */
export async function resolveDeviceFirmware ({ from, to }) {
  await dispatch('requireOrganizations')
  await dispatch('getDeviceTypes')

  const { params: { id } } = to
  let isNew = id === 'new'
  const data = isNew
    ? await dispatch('newDeviceFirmware')
    : await dispatch('getDeviceFirmware', { id })

  if (data) {
    const title = isNew ? 'New Device Firmware' : `Device Firmware: ${data.versionString}`
    await dispatch('setRouteData', { from, to, data, title })
    return data
  }
}
