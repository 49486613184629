import { isEnum, stringCompare } from '@stellacontrol/utilities'
import { EntityType } from '@stellacontrol/model'
import { CommonAPI } from '@stellacontrol/client-api'

export const actions = {
  /**
   * Starts the search for entities
   * @param {String} condition Condition to search for.
   * It may be prefixed with entity type, for example `user tomasz` or `device 69IK8829`.
   * If not, the search is done across variety of entity types: devices, places, users, organizations etc.
   */
  async startEntitySearch ({ commit, dispatch }, { condition } = {}) {
    condition = (condition || '').trim().toLowerCase()
    if (condition.length < 3) return

    commit('startEntitySearch', { condition })
    const types = []
    const parts = condition.split(' ').filter(part => part.trim())
    if (parts.length > 1 && isEnum(EntityType, parts[0])) {
      types.push(parts[0].trim())
      condition = parts.slice(1).join(' ')
    } else {
      types.push(EntityType.Device, EntityType.Organization, EntityType.Place, EntityType.User)
    }

    const organizations = await dispatch('requireOrganizations')
    const devices = await dispatch('requireDevices')

    const matches = (condition, ...texts) => {
      return texts.some(text => (text || '').trim().toLowerCase().includes(condition))
    }

    for (const type of types) {
      let items

      // Devices can be taken from cached data
      if (type === EntityType.Device) {
        items = devices.filter(device => matches(condition, device.serialNumber, device.name, device.acronym))
        if (items.length > 0) {
          dispatch('getLiveStatus', { devices: items })
        }
      } else if (type === EntityType.Organization && organizations.length > 1) {
        // Organizations can be taken from cached data
        items = organizations.filter(organization => matches(condition, organization.name))
      } else {
        // Other data is fetched from the server
        items = await CommonAPI.find({ type, condition })
      }

      // Store in search results
      commit('storeEntitySearchResults', { items })

      // If exact device match, just stop the search
      if (items?.length === 1 && stringCompare(items[0].serialNumber, condition, false) === 0) {
        break
      }
    }

    await dispatch('stopEntitySearch', { condition })
  },

  /**
   * Clears the last search results
   */
  async clearEntitySearch ({ commit }) {
    commit('clearEntitySearch')
  },

  /**
   * Stops the running search, leaving intact the results collected so far
   */
  async stopEntitySearch ({ commit }) {
    commit('stopEntitySearch')
  }
}
