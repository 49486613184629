<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { wait } from '@stellacontrol/utilities'
import { DialogMixin, Notification } from '@stellacontrol/client-utilities'
import { Secure } from '@stellacontrol/security-ui'
import { DeviceConnectionStatus, DeviceConnectionStatusColor, DeviceConnectionStatusBackgroundColor } from '@stellacontrol/model'
import DeviceConfigurationSettings from './tabs/device-configuration-settings.vue'
import DeviceConfigurationShadow from './tabs/device-configuration-shadow.vue'

const dialog = 'device-configuration'

export default {
  mixins: [
    DialogMixin,
    Secure
  ],

  components: {
    'sc-device-configuration-settings': DeviceConfigurationSettings,
    'sc-device-configuration-shadow': DeviceConfigurationShadow
  },

  data () {
    return {
      dialog,
      showLoading: false,
      // Device to configure
      device: null,
      // Show warning about device not being live
      showOfflineWarning: false,
      // Settings tab to show
      tab: 'settings'
    }
  },

  computed: {
    ...mapState({
      deviceStatus: state => state.deviceStatus
    }),

    ...mapGetters([
      'isSmallScreen'
    ]),

    // Current device status
    status () {
      const { device, deviceStatus } = this
      return device ? deviceStatus.devices[device.serialNumber] : undefined
    },

    // Device connection status
    connectionStatus () {
      const { status } = this
      return status?.connection?.status
    },

    hasConnected () {
      const { connectionStatus } = this
      return connectionStatus !== DeviceConnectionStatus.NeverConnected && connectionStatus !== DeviceConnectionStatus.Unknown
    },

    // Device connection timings
    timings () {
      const { status } = this
      return status?.timings
    },

    isRealTime () {
      return this.timings?.isRealTime
    },

    isHeartbeat () {
      return this.timings?.isHeartbeat
    },

    // Banner style, indicating device online status
    bannerStyle () {
      const { hasConnected, isRealTime, isHeartbeat, connectionStatus } = this
      let backgroundColor
      let textColor

      if (hasConnected && (isRealTime || isHeartbeat)) {
        backgroundColor = isRealTime ? '#3f51b5' : DeviceConnectionStatusColor[connectionStatus]
        textColor = 'black'
      } else {
        backgroundColor = '#999999'
        textColor = 'white'
      }

      return {
        'background-color': backgroundColor,
        'color': textColor
      }
    },

    // Status banner style, indicating device online status
    statusBannerStyle () {
      const { hasConnected, isRealTime, isHeartbeat, connectionStatus } = this
      let backgroundColor
      let textColor = 'black'
      if (hasConnected && (isRealTime || isHeartbeat)) {
        backgroundColor = DeviceConnectionStatusBackgroundColor[connectionStatus]
      } else {
        backgroundColor = DeviceConnectionStatusColor[DeviceConnectionStatus.NeverConnected]
      }

      return {
        'background-color': backgroundColor,
        'color': textColor,
        'border-bottom': 'solid #c0c0c0 1px'
      }
    },

    // Indicates whether the current user can see device shadow
    canSeeShadow () {
      return this.isSuperAdministrator
    },

    // Indicates whether there's more than one tab visible, so tabs control is needed
    hasTabs () {
      return this.canSeeShadow
    }
  },

  watch: {
    isRealTime () {
      this.showOfflineWarning = this.isRealTime === false && !this.showLoading
    }
  },

  methods: {
    ...mapActions([
      'dialogOk',
      'dialogCancel',
      'getDevice',
      'getDeviceBySerialNumber',
      'getLiveStatus',
      'watchDeviceStatus',
      'unwatchDeviceStatus'
    ]),

    // Starts watching device status updates
    async watchStatus () {
      const { device } = this
      if (device) {
        // Start watching device status
        await this.watchDeviceStatus({
          name: dialog,
          devices: [device],
          fastSampling: true
        })
      }
    },

    // Stops watching device status updates
    async unwatchStatus () {
      this.unwatchDeviceStatus({ name: dialog })
    },

    // Loads data and current status of the specified device
    async loadDevice ({ id, serialNumber }) {
      if (!(id || serialNumber)) throw new Error('Device identifier or serial number is required')

      // Fetch device
      this.device = undefined
      this.showLoading = true
      await wait(1000)

      const device = id
        ? await this.getDevice({ id })
        : await this.getDeviceBySerialNumber({ serialNumber })

      if (device) {
        // Fetch last known device status
        await this.getLiveStatus({ device })

        this.device = device
      } else {
        Notification.warning({ message: `Device ${id} ${serialNumber} not found` })
        this.cancel()
        return
      }

      // Start watching device status
      await this.watchStatus()

      this.showLoading = false
    },

    // When dialog is shown, load the device specified in the initial data
    // and start receiving status
    dialogShown () {
      const { id, serialNumber } = this.data || {}
      this.tab = 'settings'
      this.loadDevice({ id, serialNumber })
    },

    // When dialog is hidden, stop all polling for data
    dialogHidden () {
      this.unwatchStatus()
    },

    // Validates and OKs the dialog
    ok () {
      this.dialogOk({ dialog })
    },

    // Cancels the dialog
    cancel () {
      this.dialogCancel({ dialog })
    }
  },

  // Stop polling device status when component is destroyed
  async beforeUnmount () {
    this.unwatchStatus()
  }
}
</script>

<template>
  <sc-dialog persistent seamless position="right" :dialog="dialog" :maximized="isSmallScreen"
    :cssClass="{ 'dialog-right-bottom': true }" @dialogShown="dialogShown()"
    @dialogHidden="dialogHidden()">

    <q-form class="form" :class="{ 'never-connected': !hasConnected }" ref="form">

      <q-inner-loading :showing="showLoading">
        <span class="text-black title q-mb-md">
          Loading configuration of {{ data.serialNumber }} ...
        </span>
        <q-spinner-gears size="48px" color="grey-8"></q-spinner-gears>
      </q-inner-loading>

      <div v-if="!showLoading && device" class="content">
        <q-banner class="banner" :style="bannerStyle">
          <div class="row items-center">
            <span class="text-white title">
              Device Configuration: {{ device.acronym }} {{ device.serialNumber }}
            </span>
            <q-space></q-space>
            <q-btn label="Close" unelevated class="primary" @click="ok()"></q-btn>
          </div>
        </q-banner>

        <div dense class="banner q-pa-md" :style="statusBannerStyle" v-if="showOfflineWarning">
          <span v-if="hasConnected">
            The device is not communicating at the moment.
            Configuration changes will be saved but
            will be applied later, when the device comes back online.
          </span>
          <span v-else>
            Device status is unknown.
          </span>
        </div>

        <!-- Only show tabs if there are more than one visible -->
        <sc-tabs v-if="hasTabs && hasConnected" v-model="tab" align="left" inline-label no-caps
          class="bg-indigo-1 text-grey-9">
          <q-tab name="settings" icon="settings" label="Settings" :ripple="false"></q-tab>
          <q-tab v-if="canSeeShadow" name="shadow" icon="subject" label="Shadow" :ripple="false">
          </q-tab>
        </sc-tabs>

        <sc-tab-panels v-if="hasTabs && hasConnected" v-model="tab" keep-alive>
          <q-tab-panel name="settings">
            <sc-device-configuration-settings :device="device" :status="status">
            </sc-device-configuration-settings>
          </q-tab-panel>
          <q-tab-panel name="shadow">
            <sc-device-configuration-shadow :device="device" :status="status">
            </sc-device-configuration-shadow>
          </q-tab-panel>
        </sc-tab-panels>

        <div class="container-no-tabs" v-if="!hasTabs && hasConnected">
          <sc-device-configuration-settings :device="device" :status="status">
          </sc-device-configuration-settings>
        </div>

        <!-- NEVER-CONNECTED VIEW -->
        <div class="page never-connected q-pa-md" v-if="!hasConnected">
          <div class="row items-start no-wrap q-pa-lg">
            <q-icon name="wifi_off" color="red-8" class="q-mr-md" size="md"></q-icon>
            <span class="text-black title q-mb-md">
              Device has never connected, so it cannot be configured.
              Please connect the device to network and wait until the device
              reports the status, then try again.
            </span>
          </div>
        </div>

      </div>

    </q-form>

  </sc-dialog>
</template>

<style scoped lang="scss">
.form {
  width: 1060px;
  min-height: 770px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .banner {
      flex: 0;
    }

    .container-no-tabs {
      flex: 1;
      overflow: auto;
    }

    :deep(.q-tab-panel) {
      padding: 8px 0 4px 0;
    }

    &.never-connected {
      width: 500px;
    }

    .title {
      font-size: 18px;
    }

    .page {
      min-height: 660px;

      &.never-connected {
        min-height: 200px;
      }
    }
  }
}

/* Layout adjustments for screen below HD resolution */
@media screen and (max-width: 1365px) {
  .q-dialog.fullscreen {
    top: 40px;
  }

  .form {
    width: 100%;
    min-height: 100%;

    &.never-connected {
      width: 100%;
    }
  }
}
</style>
