<script>
import ItemPropertiesMixin from './item-properties.js'
import ItemProperties from './item-properties.vue'
import BackgroundProperties from './background-style-properties.vue'
import LineProperties from './line-style-properties.vue'

export default {
  mixins: [
    ItemPropertiesMixin
  ],

  components: {
    'sc-item-properties': ItemProperties,
    'sc-background-properties': BackgroundProperties,
    'sc-line-properties': LineProperties
  }
}

</script>

<template>
  <sc-item-properties :renderer="renderer" :items="items" @action="executeAction">
  </sc-item-properties>

  <sc-background-properties :renderer="renderer" :items="items" @action="executeAction">
  </sc-background-properties>

  <sc-line-properties :renderer="renderer" :items="items" @action="executeAction"
    widthLabel="Border Width" colorLabel="Border Color" typeLabel="Border Type">
  </sc-line-properties>
</template>

<style lang="scss" scoped>
</style>
