<script>
import { mapGetters } from 'vuex'
import { FormMixin } from '@stellacontrol/client-utilities'
import { OrganizationLevel, OrganizationIcons, CustomOrganizationIcons, getOrganizationLevelName, getOrganizationIcon, getOrganizationColor } from '@stellacontrol/model'
import { Secure } from '../../components'

export default {
  mixins: [
    FormMixin,
    Secure
  ],

  props: {
    data: {
      type: Object,
      required: true
    },

    permissions: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      notes: ''
    }
  },

  computed: {
    ...mapGetters([
      'termsAndConditions',
      'organizationProfiles',
      'organizations'
    ]),

    // Organization levels to choose from
    levels () {
      const { isSuperOrganization } = this
      const levels = [
        this.level(OrganizationLevel.Organization),
        this.level(OrganizationLevel.GuestOrganization),
        this.level(OrganizationLevel.ResellerOrganization),
        this.level(OrganizationLevel.SuperOrganization, { disable: this.superOrganizationExists })
      ]
      return levels
        .filter(l => l)
        .filter(l => isSuperOrganization ? true : l.value !== OrganizationLevel.SuperOrganization)
    },

    // Organizations which can own profiles
    profileOwners () {
      return this.organizations
        .filter(o => o.isSuperOrganization || o.isResellerOrganization)
    },

    // Custom icons
    icons () {
      return CustomOrganizationIcons.map(icon => ({ label: icon, value: icon }))
    },

    // Indicates whether there exists a super-organization profile
    superOrganizationExists () {
      return this.organizationProfiles.some(p => p.isSuperOrganization)
    },

    // Currently selected level
    profileLevel () {
      return this.data.level
    },

    // Prevent from downgrading super organization to a lower level
    canChangeLevel () {
      return !this.data.isSuperOrganization
    },

    // Prevent others from changing profile owner.
    // Only super-organization has these powers.
    canEditOwner () {
      return this.isSuperOrganization
    },

    // Returns true if editing terms and conditions associated with the profile
    // is available for the current user.
    canEditTermsAndConditions () {
      return (this.termsAndConditions.items || []).length > 0
    }
  },

  methods: {
    getOrganizationIcon,
    getOrganizationColor,

    // Returns an option for level select input
    level (value, options) {
      return { value, label: getOrganizationLevelName({ level: value }), ...options }
    },

    // Profile level has changed.
    levelChanged (level) {
      // Reinitialize the permissions, because
      // some features might have just become available or unavailable.
      this.data.level = level
      this.permissions.initialize()
    },

    // Triggered when profile owner has changed.
    // If set back to super organization, just clean it.
    ownerChanged (value) {
      this.data.ownerId = value === this.currentOrganization.id
        ? undefined
        : value
    }
  },

  watch: {
    data () {
      const { data, termsAndConditions } = this

      // Check if terms and conditions are still there to use,
      // clear if no longer required or no longer available for current organization
      if (termsAndConditions.requiresApproval) {
        if (!termsAndConditions.items.find(terms => terms.id === data.termsAndConditionsId)) {
          data.termsAndConditionsId = undefined
        }
      } else {
        data.termsAndConditionsId = undefined
      }
    },

    // Profile level has changed.
    profileLevel (newValue, oldValue) {
      // Pick default icon applicable for the new level,
      // unless the user has already customized the icon
      if (this.data.icon === OrganizationIcons[oldValue]) {
        this.data.icon = OrganizationIcons[newValue]
      }
    }
  }
}

</script>

<template>
  <q-form class="form" ref="form" autofocus>
      <div class="properties q-pa-md">
        <q-input v-model="data.name" dense square outlined
          maxlength="255"
          lazy-rules
          :rules="[
            rules.required('Profile name is required')
          ]">
          <template v-slot:before> <label>Profile Name</label> </template>
          <template v-slot:after> <sc-hint-placeholder /> </template>
        </q-input>

        <q-input v-model="data.description" dense square outlined maxlength="255">
          <template v-slot:before> <label>Description</label> </template>
          <template v-slot:after> <sc-hint-placeholder /> </template>
        </q-input>

        <q-select class="q-mt-lg" dense square outlined hide-bottom-space
          v-model="data.level"
          :options="levels"
          emit-value
          lazy-rules
          map-options
          :disable="!canChangeLevel"
          :rules="[
            rules.required('Organization level is required')
          ]"
          @update:model-value="value => levelChanged(value)">
          <template v-slot:before>
            <label>Organization Level</label>
          </template>
          <template v-slot:option="scope">
            <q-item v-bind="scope.itemProps">
              <q-item-section side>
                <q-icon :name="getOrganizationIcon({ level: scope.opt.value })" :color="getOrganizationColor({ level: scope.opt.value })" size="sm" />
              </q-item-section>
              <q-item-section>
                <q-item-label v-html="scope.opt.label" />
              </q-item-section>
            </q-item>
          </template>
          <template v-slot:after>
            <sc-hint width="450px" text="Level determines which permissions can be granted to the profile. For example reseller organization can have more permissions than regular customer." />
          </template>
        </q-select>

        <sc-organization-selector class="q-mt-lg" dense square outlined bg-color="white"
          v-if="canEditOwner"
          :model-value="data.ownerId || currentOrganization.id"
          :items="profileOwners"
          @update:model-value="value => ownerChanged(value)">
          <template v-slot:before>
            <label>Profile Owner</label>
          </template>
          <template v-slot:after>
            <sc-hint text="Profile Owner is able to edit profile permissions or delete the profile." />
          </template>
        </sc-organization-selector>

        <div class="row q-mt-lg" v-if="canUse('custom-organization-icons')">
          <q-select dense square outlined hide-bottom-space hide-dropdown-icon
            popup-content-class="profile-icon-picker"
            v-model="data.icon"
            emit-value
            map-options
            :options="icons">
            <template v-slot:before>
              <label>Organization icon</label>
            </template>
            <template v-slot:after>
              <sc-hint width="450px" text="Icon representing organizations assigned to this profile." />
            </template>
            <template v-slot:option="scope">
              <q-item v-bind="scope.itemProps">
                <q-item-section>
                  <q-icon :name="scope.opt.value" :color="getOrganizationColor(data)" size="sm" class="q-pa-xs" />
                </q-item-section>
              </q-item>
            </template>
            <template v-slot:selected>
              <div class="q-pl-sm q-pr-sm">
                <q-icon :name="data.icon" :color="getOrganizationColor(data)" size="sm" />
              </div>
            </template>
          </q-select>
        </div>

        <q-select class="q-mt-lg" dense square outlined hide-bottom-space
          v-if="canEditTermsAndConditions"
          v-model="data.termsAndConditionsId"
          :options="termsAndConditions.items"
          emit-value
          lazy-rules
          map-options
          option-value="id"
          option-label="name"
          >
          <template v-slot:before>
            <label>Terms and Conditions</label>
          </template>
          <template v-slot:after>
            <sc-hint width="450px" text="Terms and Conditions which must be accepted when users log in for the first time." />
          </template>
        </q-select>

        <q-field class="q-mt-md" dense borderless>
          <template v-slot:before> <label>Is Default?</label> </template>
           <q-toggle v-model="data.isDefault" color="green" />
          <template v-slot:after>
            <sc-hint width="450px" text="Default profile will be automatically selected, when adding a new organization." />
          </template>
        </q-field>

        <q-field dense borderless v-if="!data.isNew">
          <template v-slot:before> <label>Created</label> </template>
          <div class="row items-center">
            {{ data.createdText }}
          </div>
        </q-field>

        <q-field dense borderless v-if="!data.isNew">
          <template v-slot:before> <label>Updated</label> </template>
          <div class="row items-center">
             {{ data.updatedText }}
          </div>
        </q-field>
      </div>

      <div class="notes q-pa-md">
        <q-input v-model="data.note" type="textarea" dense square outlined class="note" label="Notes and remarks">
        </q-input>
      </div>
  </q-form>
</template>

<style lang='scss'>
.profile-icon-picker {
  .q-virtual-scroll__content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 416px;
    padding-bottom: 16px;
    padding-top: 16px;
    padding-left: 16px;
  }
}
</style>

<style lang='scss' scoped>
.form {
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: stretch;

  label {
    font-size: 14px;
    min-width: 150px;
  }

  .properties {
    flex: 1;
    flex-basis: 450px;
  }

  .notes {
    flex: 1;
    flex-basis: 450px;
    display: flex;
    flex-direction: column;

    .note {
      height: 100%;
    }

    :deep(.q-textarea) {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    :deep(.q-field__control) {
      height: 100%;
      flex: 1;
    }
  }
}
</style>
