<script>
import { mapActions } from 'vuex'
import { CellMixin } from './cell-mixin'
import { Secure } from '@stellacontrol/security-ui'

export default {
  mixins: [
    CellMixin,
    Secure
  ],

  props: {
    item: {
      type: Object,
      required: true,
    }
  },

  computed: {
    canEditLocation () {
      return this.canUse('set-device-place')
    }
  },

  methods: {
    ...mapActions([
      'setDeviceCustomLocation'
    ])
  }
}
</script>

<template>
  <div :style="columnStyle" class="text clip" :class="{ clickable: canEditLocation }">
    <span>
      {{ item.device.customLocation || item.device.location || '-' }}
    </span>
    <q-popup-edit :class="{ 'bg-grey-1': true }" square buttons
      label-set="Save"
      v-if="canEditLocation"
      v-slot="scope"
      v-model="item.device.customLocation"
      :cover="false"
      :title="`Enter current location of ${item.device.acronym} ${item.device.serialNumber}`"
      @save="location => setDeviceCustomLocation({ device: item.device, location })">
        <q-input
          autofocus max-length="255"
          v-model="scope.value"
          :label="item.device.location ? `Reported by device: ${item.device.location}` : 'Device location'"
          @keyup.enter="scope.set">
        </q-input>
    </q-popup-edit>
  </div>
</template>

<style>
</style>