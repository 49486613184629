import { mapState, mapActions } from 'vuex'

/**
 * Shared code for Device Panel tabs
 */
export const DevicePanelTabMixin = {
  computed: {
    ...mapState({
      owner: state => state.devicePanel.owner,
      ownerGuardian: state => state.devicePanel.ownerGuardian,
      ownerWallet: state => state.devicePanel.ownerWallet,
      items: state => state.devicePanel.items,
      devices: state => state.devicePanel.devices
    }),

    // Indicates that we're processing a single device
    isSingle () {
      return this.devices.length === 1
    },

    // Indicates that we're processing a batch of devices
    isBatch () {
      return this.devices.length > 1
    },

    // First of the processed devices
    device () {
      return this.devices[0]
    },

    // First of the processed items
    item () {
      return this.items[0]
    },

    // Devices pronoun, singular or plural depending on current selection
    devicesPronoun () {
      return this.devices.length === 1 ? 'it' : 'they'
    },

    // Devices label, singular or plural depending on current selection
    devicesLabel () {
      return this.devices.length === 1 ? 'the device' : 'devices'
    },

    // Devices text, a comma-separated list of serial numbers
    // of the selected devices
    devicesText () {
      return this.devices.map(d => d.serialNumber).join(', ')
    },

    // Indicates that device has an owner selected
    hasOwner () {
      return Boolean(this.owner)
    },

    // Indicates that selected devices have different owners
    haveDifferentOwners () {
      return this.getValues(device => device.owner ? device.owner.id : undefined).length > 1
    },

    // Indicates that selected devices have the same owner
    haveSameOwner () {
      return this.owner && this.getValues(device => device.owner ? device.owner.id : undefined).length === 1
    },

    // Returns true if devices are all decommissioned
    isDecommissioned () {
      return this.devices.every(device => device.isDecommissioned)
    }
  },

  methods: {
    ...mapActions([
      'getGuardian',
      'getWallet'
    ]),

    // Gets all values of a specified property from the edited devices
    getValues (property) {
      return Array.from(new Set(this.devices.map(device =>
        typeof property === 'function' ? property(device) : device[property]
      )))
    },

    // Gets a specified property of the edited devices.
    // If they have differing values for the property,
    // the specified default value is returned.
    getValue (property, defaultValue) {
      const values = this.getValues(property)
      return values.length === 1 ? values[0] : defaultValue
    }
  }
}
