<script>
import { PlanActions, executePlanAction } from '../../renderer/actions'

export default {
  props: {
    // Plan renderer
    renderer: {
    }
  },

  computed: {
    floor () {
      return this.renderer?.floor
    },

    zoom () {
      this.floor?.zoom.ratio
    },

    zoomLabel () {
      return `${this.floor?.zoom.ratio}%`
    }
  },

  methods: {
    // Zooms the plan in or out
    async setZoom (out) {
      const { renderer } = this
      const delta = out ? -0.1 : 0.1
      await executePlanAction({ renderer, action: PlanActions.SetFloorZoom, delta })
    },

    // Resets the scale to 1:1
    async resetZoom () {
      const { renderer } = this
      await executePlanAction({ renderer, action: PlanActions.SetFloorZoom, zoom: 1 })
    },

    // Sets the zoom to ensure that the plan is visible in its entirety
    async zoomToFit () {
      const { renderer } = this
      await executePlanAction({ renderer, action: PlanActions.SetFloorZoom, fit: true })
    }
  }
}
</script>

<template>
  <div class="zoom row items-center" v-if="renderer">
    <q-btn icon="remove_circle_outline" flat unelevated dense round size="sm"
      color="grey-1" @click="setZoom(true)">
    </q-btn>
    <q-btn flat unelevated dense size="md" class="button-reset" :label="zoomLabel" color="grey-1"
      @click="resetZoom()">
      <sc-tooltip v-if="zoom!=100" text="Reset to 100%"></sc-tooltip>
    </q-btn>
    <q-btn icon="add_circle_outline" flat unelevated dense round size="sm" color="grey-1"
      @click="setZoom(false)">
    </q-btn>
    <q-btn icon="fit_screen" flat unelevated dense round size="sm" color="grey-1 q-ml-xs"
      @click="zoomToFit()">
      <sc-tooltip text="Zoom to fit the screen"></sc-tooltip>
    </q-btn>
  </div>
</template>

<style lang="scss" scoped>
.zoom {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;

  .button-reset {
    width: 50px;
  }
}
</style>
