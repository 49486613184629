<script>
import { mapState, mapActions } from 'vuex'
export default {
  computed: {
    ...mapState({
      application: state => state.client.application,
      applicationUpdate: state => state.application.applicationUpdate
    })
  },

  methods: {
    ...mapActions([
      'reload'
    ]),

    apply () {
      this.reload({ instant: true })
    }
  }
}
</script>

<template>
  <main class="indicator" v-if="applicationUpdate.updatedTo">
    <q-btn size="md" square class="button-update pulse bg-orange-8 text-white" @click="apply()" no-caps>
      <div class="row items-center no-wrap q-pa-md q-pl-md q-pr-md">
        <span>
          The platform has been updated.<br>
          Click here to reload.
        </span>
      </div>
    </q-btn>
  </main>
</template>

<style scoped lang="scss">
.indicator {
  background-color: #182354;

  .button-update {
    width: 260px;
  }
}

/* Layout adjustments for small screens */
@media screen and (max-width: 1024px) {
  .indicator {
    background-color: transparent;

    .button-update {
      width: 100%;
    }
  }
}
</style>