import { Routes as deviceUpdateRoutes } from '../views/device-updates'

/**
 * Routes defined in this applet
 */
export const AppletRoutes = [
  ...deviceUpdateRoutes
]

/**
 * Enumeration of route names in this applet
 */
export const AppletRoute = {
  DeviceUpdates: 'device-updates',
  DeviceFirmware: 'firmware'
}
