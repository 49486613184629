<script>
import { PlanLayers } from '@stellacontrol/planner'
import BackgroundLayer from './layer-properties/background-layer-properties.vue'
import CrossSectionLayer from './layer-properties/cross-section-properties.vue'
import RadiationLayer from './layer-properties/radiation-layer-properties.vue'
import ItemsLayer from './layer-properties/items-layer-properties.vue'

export default {
  components: {
    'sc-background-layer-properties': BackgroundLayer,
    'sc-cross-section-properties': CrossSectionLayer,
    'sc-radiation-layer-properties': RadiationLayer,
    'sc-items-layer-properties': ItemsLayer
  },

  props: {
    // Plan renderer
    renderer: {
    },

    // Identifier of a layer whose properties to edit
    id: {
    },

    // List of currently selected items
    selectedItems: {
      default: () => []
    },

    // Indicates whether the component is now enabled for editing
    enabled: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    // Layer to edit
    layer () {
      const layer = this.renderer.getLayer(this.id)
      return layer?.item
    },

    // Properties component to load
    propertiesComponent () {
      const { layer, renderer: { isCrossSection } } = this
      const components = {
        [PlanLayers.Background]: 'sc-background-layer-properties',
        [PlanLayers.Radiation]: 'sc-radiation-layer-properties',
        [PlanLayers.Items]: isCrossSection ? 'sc-cross-section-properties' : 'sc-items-layer-properties'
      }
      return components[layer.id]
    }
  },

  emits: [
    'action'
  ],

  methods: {
    // Passes the action requested in the properties
    executeAction (details) {
      this.$emit('action', details)
    }
  }
}

</script>

<template>
  <template v-if="propertiesComponent">
    <component :is="propertiesComponent" :renderer="renderer" :layer="layer" :enabled="enabled"
      :selectedItems="selectedItems" @action="executeAction">
    </component>
  </template>
</template>

<style lang="scss" scoped></style>
