import { Assignable } from '../../common/assignable'
import { parseDate } from '@stellacontrol/utilities'

/**
 * Feature flag, indicating whether an application feature is enabled or disabled
 * or storing a custom value for application feature
 */
export class FeatureFlag extends Assignable {
  constructor (data = {}) {
    super()
    this.assign(data)
  }

  normalize () {
    super.normalize()
    this.changedAt = parseDate(this.changedAt || new Date())
  }

  /**
   * Date and time when feature has been toggled
   * @type {Date}
   */
  changedAt

  /**
   * Identifier of user who changed the flags
   * @type {String}
   */
  changedBy

  /**
   * Feature name, referring to an application {@link Feature}
   * @type {String}
   */
  name

  /**
   * Feature flag value
   * @type {any}
   */
  value

  /**
   * Indicates that feature has the specified value
   * @param {any} value Value to check
   * @type {Boolean}
   */
  hasValue (value) {
    return this.value === value
  }

  /**
   * Sets flag value
   * @param {String} changedBy User which changed the value
   * @param {any} value Value to set
   * @param {String} type Value type: `string|boolean|number`. If not specified, the value is assigned as-is.
   * @returns {any} Value assigned according to the specified type
   */
  set (changedBy, value, type) {
    if (!changedBy) throw new Error('User changing the feature flag is required')

    if (value == null || !type) {
      this.value = value
    } else {
      const s = value.toString().trim()
      switch (type) {
        case 'string':
          this.value = s
          break
        case 'boolean':
          this.value = s === 'true' || s === '1'
          break
        case 'number':
          this.value = parseInt(s)
          break
        default:
          throw new Error(`Unsupported flag value type [${type}]`)
      }
    }

    this.changedBy = changedBy
    this.changedAt = new Date()

    return this.value
  }

  /**
   * Indicates that feature is enabled
   * @type {Boolean}
   */
  get isEnabled () {
    return this.value === true
  }

  /**
   * Indicates that feature is not enabled
   * @type {Boolean}
   */
  get isDisabled () {
    return !this.isEnabled
  }
}
