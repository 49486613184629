/**
 * Supported protocols of the device messages
 */
export const MessageProtocol = {
  // JSON message
  JSON: 'json',
  // Protobuf message
  ProtoBuf: 'protobuf',
  // Raw binary file
  Binary: 'binary'
}
