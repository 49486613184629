import { isEnum } from '@stellacontrol/utilities'
import { Entity } from '../../common/entity'
import { SecurityRequestType } from './security-request-type'

/**
 * Security request
 */
export class SecurityRequest extends Entity {
  constructor (data) {
    super()
    this.assign(data)
    if (!isEnum(SecurityRequestType, this.type)) throw new Error(`Invalid request type ${this.type}`)
    if (this.user) {
      this.userId = this.user.id
      this.userName = this.user.name
    }
    if (data?.neverExpires) {
      this.expiresAfter = Number.MAX_SAFE_INTEGER
    }
  }

  /**
   * Request token
   * @type {String}
   */
  token

  /**
   * Request type
   * @type {SecurityRequestType}
   */
  type

  /**
   * Request subtype
   * @type {String}
   */
  subtype

  /**
   * User identifier
   * @type {String}
   */
  userId

  /**
   * User name
   * @type {String}
   */
  userName

  /**
   * User
   * @type {User}
   */
  user

  /**
   * Validity of the request in minutes
   * @type {Number}
   */
  expiresAfter

  /**
   * Checks whether the request never expires
   * @type {Boolean}
   */
  get neverExpires () {
    return this.expiresAfter === Number.MAX_SAFE_INTEGER
  }

  /**
   * Indicates that request has been used
   * @type {Date}
   */
  usedAt

  /**
   * Time left to expire, in minutes
   * @type {Number}
   */
  get timeToExpire () {
    if (this.hasExpired) {
      return 0
    } else {
      const expiration = new Date(this.createdAt.getTime() + this.expiresAfter * 60000)
      return Math.floor((expiration - (new Date())) / 60000)
    }
  }

  /**
   * Returns true if request has expired
   * @type {Boolean}
   */
  get hasExpired () {
    if (this.hasBeenUsed) {
      return true
    } else {
      const now = new Date()
      const expiration = new Date(this.createdAt.getTime() + this.expiresAfter * 60000)
      return now >= expiration
    }
  }

  /**
   * Returns true if request has been used
   * @type {Boolean}
   */
  get hasBeenUsed () {
    return Boolean(this.usedAt)
  }

  /**
   * Returns true if request is still valid to use
   * @type {Boolean}
   */
  get canUse () {
    return !(this.hasExpired || this.hasBeenUsed)
  }
}
