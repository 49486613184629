/**
 * Possible device upload types
 */
export const UploadType = {
  BootLogo: 'bootlogo',
  Firmware: 'firmware'
}

/**
 * Job type descriptions
 */
export const UploadDescription = {
  [UploadType.BootLogo]: 'boot logo',
  [UploadType.Firmware]: 'firmware'
}

/**
 * Icon representing job type
 */
export const UploadIcon = {
  [UploadType.BootLogo]: 'image',
  [UploadType.Firmware]: 'memory'
}

/**
 * Determines upload type appropriate for delivering
 * the specified file
 * @param name File name
 */
export function getUploadType (name) {
  if (name) {
    if (name.endsWith('.firmware')) return UploadType.Firmware
    if (name.endsWith('.bootlogo')) return UploadType.BootLogo
  }
}

/**
 * Determines whether the specified file is a firmware file
 * @param name File name
 */
export function isDeviceFirmwareFile (name) {
  return getUploadType(name) === UploadType.Firmware
}

/**
 * Determines whether the specified file is a boot logo file
 * @param name File name
 */
export function isDeviceBootLogoFile (name) {
  return getUploadType(name) === UploadType.BootLogo
}
