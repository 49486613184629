<script>
import { mapState, mapActions } from 'vuex'
import { groupItems } from '@stellacontrol/utilities'
import { DialogMixin } from '@stellacontrol/client-utilities'
import { PlannerMode } from '@stellacontrol/planner'

const dialog = 'plan-item-details'

export default {
  mixins: [
    DialogMixin
  ],

  data () {
    return {
      // Displayed plan item
      item: null,
      // Plan item details
      details: null,
      // Dialog identifier
      dialog
    }
  },

  computed: {
    ...mapState({
      // Planner editing mode
      mode: state => state.planner.mode
    }),

    // Determines whether the planner allows full feature set
    isAdvancedMode () {
      return this.mode === PlannerMode.Advanced
    },

    // Checks whether there are any item details to display
    hasDetails () {
      return this.item != null && this.details?.length > 0
    },

    // Detail groups
    groups () {
      const details = this.details.filter(d => this.isAdvancedMode || !d.isAdvancedMode)
      const groups = groupItems(details, item => item.category, 'Details')
      return Object
        .entries(groups)
        .map(([title, items]) => ({ title, items }))
    },

    // Indicates that there's only one detail group to show
    oneGroup () {
      return this.groups?.length === 1
    }
  },

  methods: {
    ...mapActions([
      'dialogOk',
      'dialogCancel'
    ]),

    // Called when dialog is shown
    dialogShown () {
      // Pass on initial data
      this.item = this.data.item
      this.details = this.data.details
    },

    // Closes the dialog
    close () {
      this.dialogCancel({ dialog })
    }
  }
}
</script>

<template>
  <sc-dialog :dialog="dialog" @dialogShown="dialogShown()">
    <q-form ref="form" class="form" v-if="hasDetails">
      <q-banner class="bg-indigo-6">
        <span class="text-white title">
          {{ item.autoLabel }} {{ item.tag || '' }}{{ item.tagIndex || '' }}
        </span>
      </q-banner>

      <main class="q-pa-md">
        <template v-for="group of groups">
          <header v-if="!oneGroup" class="q-pl-xs q-pt-xs q-pb-sm">
            {{ group.title }}
          </header>

          <section class="column q-mt-sm">
            <article v-for="item of group.items" class="row items-center">
              <span>
                {{ item.label }}
              </span>
              <q-space></q-space>
              <span>
                {{ item.value }} {{ item.unit }}
              </span>
            </article>
          </section>
        </template>

        <footer class="buttons row q-mt-lg justify-center">
          <q-btn label="Close" unelevated class="q-mr-md" @click="close()"></q-btn>
        </footer>

      </main>

    </q-form>
  </sc-dialog>
</template>

<style scoped lang="scss">
.form {
  min-width: 290px;
  max-width: 550px;

  .title {
    font-size: 18px;
  }

  main {
    header {
      font-weight: bold;
      border-bottom: solid silver 1px;

      &:not(:first-child) {
        margin-top: 10px;
      }
    }

    section {
      article {
        background-color: transparent;
        cursor: pointer;
        padding: 2px 2px 2px 4px;

        &:hover {
          background-color: #fafafa;
        }
      }
    }
  }
}
</style>
