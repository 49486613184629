import UsersView from './users.vue'
import UserView from './user.vue'
import { resolveUser, resolveUsers } from './user.resolve'

export const Routes = [
  {
    name: 'users',
    path: '/administration/users',
    component: UsersView,

    async beforeEnter (to, from, next) {
      if (await resolveUsers()) {
        next()
      }
    }
  },
  {
    name: 'user',
    path: '/administration/user/:id',
    component: UserView,
    viewAlias: {
      'default': 'user',
      'organization': 'organization-user'
    },

    async beforeEnter (to, from, next) {
      if (await resolveUser({ to, from })) {
        next()
      }
    }
  }
]
