<script>
import { FormValidationRules as rules } from '@stellacontrol/client-utilities'
import AlertConfigurationComponent from './alert-configuration-component'

export default {
  mixins: [
    AlertConfigurationComponent
  ],

  data () {
    return {
      rules
    }
  },

  computed: {
    trendMin () {
      return this.configuration.trendMin
    },

    trendMax () {
      return this.configuration.trendMax
    },

    trendIsModified () {
      return this.isModified('trend')
    },

    trendIsNotDefault () {
      return this.isNotDefault('trend', value => `Default period is ${this.duration(value)}.`)
    }
  }
}
</script>

<template>
  <article v-if="isEditable && advancedConfiguration">
    <section>
        <label :class="{ modified: trendIsModified }">
          Time window
          <sc-hint :text="`To detect alert, recent ${duration(configuration.trend)} of data will be analyzed. ${trendIsNotDefault}`" size="20px"></sc-hint>
        </label>
        <div>
          <sc-duration-input v-model="configuration.trend" :min="trendMin" :max="trendMax">
          </sc-duration-input>
        </div>
    </section>
  </article>
</template>

<style scoped lang="scss">

</style>