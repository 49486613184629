import { state, getters } from './floor-plans.state'
import { mutations } from './floor-plans.mutations'
import { actions } from './floor-plans.actions'

export default {
  state,
  getters,
  mutations,
  actions
}
