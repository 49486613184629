/**
 * Wallet transaction types
 */
export const WalletTransactionType = {
  /**
   * Tokens left the wallet
   */
  Debit: 'debit',
  /**
   * Tokens entered the wallet
   */
  Credit: 'credit'
}
