<script>
import ItemPropertiesMixin from './item-properties.js'
import ItemProperties from './item-properties.vue'
import BackgroundProperties from './background-style-properties.vue'

export default {
  mixins: [
    ItemPropertiesMixin
  ],

  components: {
    'sc-item-properties': ItemProperties,
    'sc-background-properties': BackgroundProperties
  },

  data () {
    return {
    }
  },

  computed: {
    // Port counts available for the device
    defaults () {
      return this.item?.defaults || {}
    },

    // Port counts available for the device
    portCounts () {
      return this.defaults?.portCounts || []
    }
  }
}

</script>

<template>
  <main class="plan-item-properties" v-if="item">
    <section>
      <label>
        Serial Number
      </label>
      <q-input
        dense outlined square
        type="text"
        debounce="500"
        :input-style="styles.text"
        :model-value="getValue('device.serialNumber', '')"
        @update:model-value="value => setProperties({ 'device.serialNumber': value })">
      </q-input>
    </section>
    <section v-if="portCounts.length > 1">
      <label>
        Port Count
      </label>
      <q-select outlined square dense emit-value map-options :options="portCounts"
        :style="styles.select.normal" :model-value="getValue('device.portCount', defaults.portCount)"
        @update:model-value="value => setProperties({ 'device.portCount': parseInt(value) })">
      </q-select>
    </section>
  </main>

  <sc-item-properties :renderer="renderer" :items="items" :label="false" @action="executeAction">
  </sc-item-properties>

  <sc-background-properties :renderer="renderer" :items="items" @action="executeAction" v-if="isAdvancedMode">
  </sc-background-properties>
</template>

<style lang="scss" scoped>
</style>
