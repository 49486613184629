import { PremiumService } from '@stellacontrol/model'
import { sortItemsBy } from '@stellacontrol/utilities'
import { Confirmation, Notification, isRouteLeft } from '@stellacontrol/client-utilities'
import { ServiceManagementAPI } from '@stellacontrol/client-api'
import { AppletRoute } from '../../router'

export const actions = {
  /**
   * Reset state if view closed
   */
  async navigationEnded ({ commit }, { from, to } = {}) {
    if (isRouteLeft(AppletRoute.PremiumServices, from, to) || isRouteLeft(AppletRoute.PremiumService, from, to)) {
      commit('resetPremiumServicesView')
    }
  },

  /**
   * Returns premium services associated with default pricelist
   * @param {Boolean} paid If true, only paid services are included in the result
   * @param {Boolean} free If true, only free services are included in the result
   * @param {Boolean} perDevice If true, only per-device services are included in the result
   */
  async getPremiumServices ({ dispatch }, { paid, free, perDevice } = {}) {
    const pricelists = await dispatch('requirePricelists')

    let services = []
    const pricelist = pricelists.find(p => p.isMain)
    if (pricelist) {
      if (paid) {
        services = pricelist.paidServices
      } else if (free) {
        services = pricelist.freeServices
      } else {
        services = pricelist.services
      }
      if (perDevice) {
        services = services.filter(s => s.isPerDevice)
      }
    }

    return sortItemsBy(services, 'label')
  },

  /**
   * Shows list of premium services
   */
  async gotoPremiumServices ({ dispatch }) {
    await dispatch('gotoRoute', { name: AppletRoute.PremiumServices })
  },

  /**
   * Creates a new premium service in the currently selected pricelist
   */
  async newPremiumService ({ getters }) {
    const { pricelist } = getters
    if (pricelist) {
      const premiumService = new PremiumService({
        name: 'New premium service',
        price: 1,
        perod: 1,
        pricelistId: pricelist.id
      })
      return premiumService
    }
  },

  /**
   * Starts editing the specified premium service in the currently selected pricelist
   * @param {String} id Identifier of premium service to edit. If not specified,
   * new premium service will be edited instead
   */
  async editPremiumService ({ getters, dispatch }, { id = 'new' } = {}) {
    if (getters.isCurrentRoute(AppletRoute.PremiumServices)) {
      await dispatch('updateRoute', { query: { service: id } })
    } else {
      await dispatch('gotoRoute', { name: AppletRoute.PremiumService, query: { service: id } })
    }
  },

  /**
   * Starts editing the specified premium service in the currently selected pricelist
   */
  async stopEditingPremiumService ({ dispatch, getters }) {
    if (getters.isCurrentRoute(AppletRoute.PremiumServices)) {
      await dispatch('updateRoute', { query: { service: undefined } })
    }
  },

  /**
   * Saves a premium service in the currently edited pricelist
   * @param premiumService Premium service to save
   * @param silent If true, no notifications are displayed
   */
  async savePremiumService ({ commit, dispatch, getters }, { premiumService, silent } = {}) {
    const { pricelist } = getters
    premiumService = await ServiceManagementAPI.savePremiumService({ premiumService })
    if (premiumService && pricelist) {
      commit('storePremiumService', { premiumService, pricelist })
      Notification.success({ message: `Service ${premiumService.name} has been saved.`, silent })
      await dispatch('stopEditingPremiumService')
    }
  },

  /**
   * Removes a premium service from the currently edited pricelist
   * @param premiumService Premium service to remove
   * @param confirm If true, no confirmation is asked
   */
  async removePremiumService ({ commit, dispatch, getters }, { premiumService, confirm } = {}) {
    const { pricelist } = getters
    const yes = await Confirmation.ask({
      title: 'Remove service from pricelist?',
      message: `Do you want to remove service ${premiumService.name} from the pricelist?`,
      confirm
    })

    if (yes) {
      await ServiceManagementAPI.removePremiumService({ premiumService, pricelist })
      commit('removePremiumService', { premiumService })
      await dispatch('stopEditingPremiumService')
      Notification.success({ message: `Service ${premiumService.name} has been removed.`, silent: !confirm })
    }
  }
}
