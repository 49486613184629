<script>

/**
 * Loading... component
 */
export default {
  props: [
    'title'
  ],

  data () {
    return {
      defaultTitle: 'Loading, please wait ...'
    }
  }
}

</script>

<template>
  <div class="loading">
    <q-inner-loading :showing="true">
      <q-spinner-gears size="64px" color="grey-7" />
    </q-inner-loading>
    <label class="text-h5 text-weight-light text-black">
      {{ title || defaultTitle }}
    </label>
  </div>
</template>

<style lang="scss" scoped>
.loading {
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;

  label {
    margin-top: 120px;
  }
}
</style>
