import { replaceAt } from './string'

/**
 * HTML-izes text by replacing linebreaks with <br>
 * @param {String} text Input text
 * @returns {String} HTML-ized text
 */
export function htmlize (text) {
  if (text == null) {
    return text
  }
  return text
    .toString()
    .replace(/\n/gm, '<br>')
}

/**
 * De-HTML-izes text by replacing tags such as <br> with linebreaks, etc.
 * @param {String} text Input text
 * @returns {String} De-HTML-ized text
 */
export function dehtmlize (text) {
  if (text == null) {
    return text
  }
  return text
    .toString()
    .replace(/<br>/gm, '\n')
    .replace(/<p>/gm, '\n')
    .replace(/<\/p>/gm, '\n')
    .replace(/<div>/gm, '\n')
    .replace(/<\/div>/gm, '\n')
    .replace(/<code>/gm, '\n')
    .replace(/<\/code>/gm, '\n')
    .replace(/<pre>/gm, '\n')
    .replace(/<\/pre>/gm, '\n')
    .replace(/<h[1-9]>/gm, '\n')
    .replace(/<\/h[1-9]>/gm, '\n')
    .replace(/\n\n/gm, '\n')
    .replace(/^[\r\n]+|\.|[\r\n]+$/gm, '')
}

/**
 * Extracts the entire specified HTML tag
 * @param {String} text Input text
 * @param {String} tag HTML tag name
 * @returns {String} Tag text
 */
export function extractTag (text, tag) {
  if (text == null) {
    return text
  }
  if (tag == null) {
    return
  }

  const expression = `<${tag}[\\s\\S]*?>([\\s\\S]*?)<\\/${tag}>`
  const regex = new RegExp(expression, 'mi')
  const results = text.toString().match(regex) || []
  return results ? results[0] : undefined
}

/**
 * Extracts all instances of the specified HTML tag
 * @param {String} text Input text
 * @param {String} tag HTML tag name
 * @returns {Array[String]} List of all instances of the tag
 */
export function extractTags (text, tag) {
  if (text == null) {
    return text
  }
  if (tag == null) {
    return
  }

  const expression = `<${tag}[\\s\\S]*?>([\\s\\S]*?)<\\/${tag}>`
  const regex = new RegExp(expression, 'gmi')
  const results = Array.from(text.toString().matchAll(regex) || [])
  return results.map(result => result[0])
}

/**
 * Extracts the content of the specified HTML tag
 * @param {String} text Input text
 * @param {String} tag HTML tag name
 * @returns {String} Inner content of the tag
 */
export function extractTagContent (text, tag) {
  if (text == null) {
    return text
  }
  if (tag == null) {
    return
  }
  const expression = `<${tag}[\\s\\S]*?>([\\s\\S]*?)<\\/${tag}>`
  const regex = new RegExp(expression, 'mi')
  const results = text.toString().match(regex) || []
  return results ? results[1] : undefined
}

/**
 * Extracts the content of all instances of the specified HTML tag
 * @param {String} text Input text
 * @param {String} tag HTML tag name
 * @returns {Array[String]} List of inner content of all instances of the tag
 */
export function extractTagsContents (text, tag) {
  if (text == null) {
    return text
  }
  if (tag == null) {
    return
  }
  const expression = `<${tag}[\\s\\S]*?>([\\s\\S]*?)<\\/${tag}>`
  const regex = new RegExp(expression, 'gmi')
  const results = Array.from(text.toString().matchAll(regex) || [])
  return results.map(result => result[1])
}

/**
 * Extracts attributes of a specified meta tag
 * @param {String} text Input text containing meta tags
 * @returns {Dictionary<String, String>} Dictionary of meta values found in the text
 */
export function extractMeta (text) {
  if (text == null) {
    return text
  }

  const regex = new RegExp('<meta *(.+?) *>', 'gmi')
  const matches = text
    .toString()
    .matchAll(regex)

  if (matches) {
    return Array.from(matches)
      .flatMap(match => match[1].split(' '))
      .map(item => item.split('='))
      .filter(field => field[0])
      .map(([key, value]) => ({ key, value: value.substring(1, value.length - 1) }))
      .reduce((all, { key, value }) => ({ ...all, [key]: value }), {})
  }
}

/**
 * Replaces the entire specified HTML tag with text
 * @param {String} text Input text
 * @param {String} tag HTML tag name
 * @param {String|Function} replaceWith Replacement text for tag content or
 * function receiving outer tag markup, inner tag content and tag number,
 * then returning a replacement text for it
 * @param {Boolean} all If true, replaces all occurrences of the tag
 * @returns {String} Modified text
 */
export function replaceTag (text, tag, replaceWith, all = true) {
  if (text == null || replaceWith == null) {
    return text
  }
  if (tag == null) {
    return
  }

  const expression = `<${tag}[\\s\\S]*?>([\\s\\S]*?)<\\/${tag}>`
  const regex = new RegExp(expression, all ? 'gmi' : 'mi')
  const results = text.toString().matchAll(regex) || []

  let output = text
  let shift = 0
  let number = 0

  for (const result of results) {
    const tagOuter = result[0]
    const tagInner = result[1]
    const tagIndex = result.index
    const replaceText = typeof replaceWith === 'function'
      ? replaceWith(tagOuter, tagInner, number) || ''
      : replaceWith.toString()
    output = replaceAt(output, tagIndex + shift, replaceText, tagOuter.length)
    shift = shift + (replaceText.length - tagOuter.length)
    number++
  }

  return output
}

/**
 * Replaces the content of specified HTML tag with text
 * @param {String} text Input text
 * @param {String} tag HTML tag name
 * @param {String|Function} replaceWith Replacement text for tag content or
 * function receiving inner tag content and tag number, then returning a replacement text for it
 * @param {Boolean} all If true, replaces all occurrences of the tag
 * @returns {String} Modified text
 */
export function replaceTagContent (text, tag, replaceWith, all = true) {
  if (text == null || replaceWith == null) {
    return text
  }
  if (tag == null) {
    return
  }

  const expression = `(<${tag}[^>]*>)([\\s\\S]*?)(<\\/${tag}>)`
  const regex = new RegExp(expression, all ? 'gmi' : 'mi')
  const results = text.toString().matchAll(regex) || []
  if (results.length === 0) {
    return text
  }

  let number = 0
  let shift = 0
  let output = text
  for (const result of results) {
    const open = result[1]
    const content = result[2]
    const index = result.index
    const replaceText = typeof replaceWith === 'function'
      ? replaceWith(content, number) || ''
      : replaceWith.toString()
    output = replaceAt(output, index + shift + open.length, replaceText, content.length)
    shift = shift + (replaceText.length - content.length)
    number++
  }

  return output
}

