import { parseDate, getAge, getAgeString, sameArrays } from '@stellacontrol/utilities'
import { isBandAlert } from './alert-type'
import { Assignable } from '../common/assignable'
import { AlertDescription } from './alert-description'

/**
 * An occurrence of an alert on a device
 */
export class AlertOccurrence extends Assignable {
  constructor (data = {}) {
    super()
    this.assign(
      {
        ...data,
        description: AlertDescription[data.alertType],
        createdAt: data.createdAt || new Date(),
        time: data.time || new Date()
      },
      {
        time: parseDate,
        createdAt: parseDate
      }
    )
  }

  /**
   * Identifier of the environment on which the action has been recorded
   * @type {Environment}
   */
  environment

  /**
  * Identifier of user account which registered the alert
  * @type {String}
  */
  createdBy

  /**
  * Date and time when alert has been registered.
  * @type {Date}
  */
  createdAt

  /**
 * Date and time when alert has occurred
 * @type {Date}
 */
  time

  /**
   * Device which triggered the alert
   * @type {Device}
   */
  device

  /**
   * Alert type
   * @type {AlertType}
   */
  alertType

  /**
   * Human-friendly alert description
   * @type {String}
   */
  description

  /**
   * Detailed alert message
   * @type {String}
   */
  message

  /**
   * Identifiers of bands on which the alert has been triggered
   * @type {Array[BandIdentifier]}
   */
  bands

  /**
   * Configuration of the alert, at the moment when it occurred
   * @type {Object}
   */
  configuration

  /**
   * Alert age, in seconds
  * @type {Number}
  */
  get age () {
    const { time } = this
    if (time) {
      return getAge(time)
    }
  }

  /**
   * User-friendly representation of alert {@link age}
   * @type {String}
   */
  get ageString () {
    const { time } = this
    if (time) {
      return getAgeString(time, new Date(), { seconds: this.age < 60 })
    }
  }

  /**
   * Checks whether this alert occurrence is the same
   * as the specified one.
   * @param {AlertOccurrence} alert Alert occurrence to compare with
   * @returns {Boolean}
   * @description We check here the alert type, device
   * and, if band alert, whether both alerts were raised on the same bands
   */
  sameAs (alert) {
    if (!alert) return false
    const { alertType, device } = this
    if (alert.alertType !== alertType) return false
    if (alert.device?.serialNumber !== device?.serialNumber) return false
    if (isBandAlert(alertType)) {
      return sameArrays(this.bands || [], alert.bands || [])
    } else {
      return true
    }
  }
}