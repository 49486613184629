/* eslint-disable no-console */
import { Log, wait } from '@stellacontrol/utilities'
import { getStore, getState } from '../store/get-store'
import { printError } from './error-printer'
import { isNonCriticalNavigationFailure, getNavigationFailure } from '../router/navigation-failure'

/**
 * Global error handler for client-side
 */
async function clientErrorHandler (error) {
  try {
    // Skip router navigation errors as they're pure annoyance and
    // there's no use for them at all
    if (routerErrorHandler(error)) {
      return
    }

    // Pretty-print the error
    error = await printError(error)

    const { client } = getState()
    const { getters: { isLoggedIn, isError }, dispatch } = getStore()

    // If error while session has expired, just go back to login page
    if (!isLoggedIn && !isError) {
      await dispatch('gotoLogin')
      return
    }

    // Otherwise navigate to user-friendly error page,
    // unless already in faulted state.
    if (client && !isError) {
      const message = typeof error === 'string' ? error : ''
      // Close any pending progress notification
      if (client.busy.now) {
        await dispatch('done')
        await wait(1500)
      }
      // Show error page.
      // For network errors show more benign network error popup.
      if (error.isNetworkError) {
        await dispatch('showNetworkErrorPopup', { error })
      } else {
        await dispatch('fail', { message, error })
      }
    }

  } catch (error) {
    console.log('Now that\'s unheard of, an error within the error handler !!!')
    console.error(error.toString())
  }

  return false
}

/**
 * Interceptor for Vue Router errors.
 * Filters out router navigation errors which can be safely ignored
 * @returns True if error has been handled and there's no need to bother the user
 */
function routerErrorHandler (error) {
  if (isNonCriticalNavigationFailure(error)) {
    const { name, from, to, type } = getNavigationFailure(error) || {}
    if (from && to) {
      Log.warn(`Navigation ${name}`, `${from.path} => ${to.path}`)
      Log.warn(`Navigation ${name || 'redirected'}`, `${from.path} => ${to.path} ${type ? `[${type}]` : ''}`)
    } else {
      Log.warn(error.message)
    }
    return true
  }
}

/**
 * Initializes error handling across the application
 * @param application Vue application instance
 */
export function useErrorHandler (application) {
  /**
   * Handler for errors within Vue components
   */
  application.config.errorHandler = (error, vm, info) => {
    Log.error(`Unexpected exception in VUE component ${info ? ('(' + info + ')') : ''}`, vm)
    error.vm = vm
    error.info = info
    clientErrorHandler(error)
  }

  /**
   * Handler for warnings within Vue components
   */
  application.config.warnHandler = (msg, vm, trace) => {
    Log.error('Warning in VUE component', vm)
    if (msg) {
      Log.error(msg)
    }
    if (trace) {
      Log.error(trace)
    }
  }

  /**
   * Handler for errors outside Vue components
   */
  window.onerror = (msg, url, line, col, error) => clientErrorHandler(error)

  /**
   * Handler for unhandled promise rejections
   */
  window.addEventListener('unhandledrejection', (event) => clientErrorHandler(event.reason))
}

/**
 * Initializes error handling during routing
 * @param router Vue router instance
 */
export function useRouterErrorHandler (router) {
  if (router) {
    router.onError(error => {
      clientErrorHandler(error)
    })
  }
}
