<script>
import { Storage } from '@stellacontrol/client-utilities'
import { PlanLayers } from '@stellacontrol/planner'
import Section from './section.vue'
import Toolbox from './section-toolbox.vue'
import ItemProperties from './section-item-properties.vue'
import LayerProperties from './section-layer-properties.vue'
import { PlanActions } from '../../renderer/actions/plan-action'
import Zoom from './zoom.vue'
import Tools from './tools.vue'

const KEY_EXPANDED_SECTIONS = 'plan.palette.sections.expandedSections'
const KEY_VISIBLE_LAYERS = 'plan.palette.sections.visibleLayers'

export default {
  props: {
    // Plan renderer
    renderer: {
      required: true
    }
  },

  components: {
    'scp-section': Section,
    'scp-toolbox': Toolbox,
    'scp-layer-properties': LayerProperties,
    'scp-item-properties': ItemProperties,
    'scp-plan-zoom': Zoom,
    'scp-plan-tools': Tools,
  },

  data () {
    return {
      PlanLayers,
      PlanActions,

      // Indicates whether the toolbox is expanded
      isExpanded: true,

      // Items currently selected in the renderer
      selectedItems: null,

      // Equipment hierarchy
      equipmentHierarchy: null,

      // Layer visibility
      visibleLayers: {},

      // Section status
      expandedSections: {
        radiation: false,
        background: false,
        items: false,
        toolbox: true,
        properties: false
      },

      // Indicates whether to automatically collapse other sections when a section is expanded
      autoCollapseSections: true
    }
  },

  computed: {
    // Plan layout
    layout () {
      return this.renderer?.layout
    },

    // Plan layers to show
    layers () {
      const { renderer } = this
      if (renderer) {
        return renderer.layers.filter(layer => !layer.isInternal)
      } else {
        return []
      }
    },

    // Indicates that we're editing the cross-section view
    isCrossSection () {
      return this.renderer?.isCrossSection
    },

    // Indicates that we're editing the floor plan
    isFloor () {
      return this.renderer?.isFloor
    },

    // Identifiers of the selected items
    selectedIdentifiers () {
      return (this.selectedItems || []).map(i => i.id)
    },

    // Indicates whether any items are currently selected
    hasSelectedItems () {
      return this.selectedItems?.length > 0
    },

    // Indicates whether property editor for selected items should be visible
    canEditItemProperties () {
      return this.hasSelectedItems &&
        this.visibleLayers[PlanLayers.Items] &&
        this.selectedItems.every(item => item.canEditProperties)
    },

    // Key in window session under which expanded toolbox sections are stored
    sectionsKey () {
      return `${KEY_EXPANDED_SECTIONS}.${this.renderer.isCrossSection ? 'cross-section' : 'floor'}`
    },

    // Key in window session under which visible layers are stored
    layersKey () {
      return `${KEY_VISIBLE_LAYERS}.${this.renderer.isCrossSection ? 'cross-section' : 'floor'}`
    }
  },

  emits: [
    'start-adding-item',
    'stop-adding-item',
    'action'
  ],

  methods: {
    // Populates the component
    populate () {
      const { renderer, sectionsKey, layersKey } = this

      // Restore section status
      this.expandedSections = Storage.get(sectionsKey, this.expandedSections)

      // Restore layers visibility
      this.visibleLayers = this.layers.reduce((all, l) => ({ ...all, [l.id]: true }), {})
      const savedLayers = Storage.get(layersKey, this.visibleLayers)
      for (const key of Object.keys(this.visibleLayers)) {
        const isLayerVisible = Boolean(savedLayers[key])
        this.visibleLayers[key] = isLayerVisible
        renderer.toggleLayer(key, isLayerVisible)
      }

      // Keep an eye on selections on the plan
      renderer.selection.addEventListener('select', (e) => {
        const { items } = e.detail
        this.selectedItems = [...items]
      })

      renderer.selection.addEventListener('deselect', () => {
        this.selectedItems = []
      })

      // Keep an eye on changes to equipment hierarchy, re-assign item tags
      this.equipmentHierarchy = renderer.equipmentHierarchy
      renderer.events.addEventListener('hierarchy-changed', ({ detail: { hierarchy } }) => {
        this.equipmentHierarchy = hierarchy
      })
    },

    // Signals that the user wants to start adding an item to the plan
    startAddingItem ({ item, layer }) {
      this.$emit('start-adding-item', { item, layer })
    },

    // Signals that the user wants to stop adding an item to the plan
    stopAddingItem () {
      this.$emit('stop-adding-item')
    },

    // Toggles the section
    toggleSection (name, state) {
      if (name) {
        const { expandedSections, autoCollapseSections } = this
        const isExpanded = state == null ? !this.expandedSections[name] : Boolean(state)
        if (isExpanded && autoCollapseSections) {
          for (const key in expandedSections) {
            expandedSections[key] = false
          }
        }
        this.expandedSections[name] = isExpanded
      }
    },

    // Toggles the layer visibility
    toggleLayer (id) {
      if (id) {
        this.renderer.toggleLayer(id)
        const layer = this.layers.find(l => l.id === id)
        this.visibleLayers[id] = layer.isVisible
      }
    },

    // Passes the action requested in the properties
    executeAction (details) {
      this.$emit('action', details)
    },
  },

  watch: {
    // Store section status in session storage
    expandedSections: {
      handler () {
        const { sectionsKey } = this
        Storage.put(sectionsKey, this.expandedSections)
      },
      deep: true
    },

    // Store layer visibility in session storage
    visibleLayers: {
      handler () {
        const { layersKey } = this
        Storage.put(layersKey, this.visibleLayers)
      },
      deep: true
    },

    // When selection changes, expand properties for the selected items
    selectedIdentifiers () {
      if (this.hasSelectedItems) {
        this.toggleSection('properties', true)
      }
    }
  },

  created () {
    this.populate()
  }
}

</script>

<template>
  <div class="palette shadow-2">
    <header class="palette-header bg-black row items-center q-pl-xs q-pr-sm q-pt-sm q-pb-sm"
      @dblclick="isExpanded = !isExpanded">
      <q-icon size="28px" class="icon" :name="isExpanded ? 'expand_more' : 'chevron_right'"
        color="grey-2" @click="isExpanded = !isExpanded">
      </q-icon>
      <span class="text text-bold text-grey-2 q-ml-xs">
        Toolbox
      </span>
      <q-space></q-space>
      <div class="tools q-mr-sm">
        <scp-plan-tools :renderer="renderer">
        </scp-plan-tools>
      </div>
      <div class="zoom">
        <scp-plan-zoom :renderer="renderer">
        </scp-plan-zoom>
      </div>
    </header>

    <template v-if="isExpanded">
      <scp-section section="background" title="Plan" icon="home_work" :sections="expandedSections"
        v-if="isFloor" :layer="PlanLayers.Background" :layers="visibleLayers" :renderer="renderer"
        @toggle-section="section => toggleSection(section)"
        @toggle-layer="layer => toggleLayer(layer)" @action="executeAction">
        <scp-layer-properties :renderer="renderer" :id="PlanLayers.Background"
          :enabled="visibleLayers[PlanLayers.Background]"
          @start-adding-item="({ item, layer }) => startAddingItem({ item, layer })"
          @stop-adding-item="stopAddingItem()" @action="executeAction">
        </scp-layer-properties>
      </scp-section>

      <scp-section section="cross-section" title="Plan" icon="home_work"
        :sections="expandedSections" v-if="isCrossSection" :layer="PlanLayers.Items"
        :layers="visibleLayers" :renderer="renderer" @toggle-section="section => toggleSection(section)"
        @toggle-layer="layer => toggleLayer(layer)" @action="executeAction">
        <scp-layer-properties :renderer="renderer" :id="PlanLayers.Items"
          :enabled="visibleLayers[PlanLayers.Items]"
          @start-adding-item="({ item, layer }) => startAddingItem({ item, layer })"
          @stop-adding-item="stopAddingItem()" @action="executeAction">
        </scp-layer-properties>
      </scp-section>

      <scp-section section="radiation" title="Radiation" icon="podcasts" v-if="isFloor"
        :sections="expandedSections" :layer="PlanLayers.Radiation" :layers="visibleLayers" :renderer="renderer"
        @toggle-section="section => toggleSection(section)"
        @toggle-layer="layer => toggleLayer(layer)" @action="executeAction">
        <scp-layer-properties :renderer="renderer" :id="PlanLayers.Radiation"
          :enabled="visibleLayers[PlanLayers.Radiation]"
          @start-adding-item="({ item, layer }) => startAddingItem({ item, layer })"
          @stop-adding-item="stopAddingItem()" @action="executeAction">
        </scp-layer-properties>
      </scp-section>

      <scp-section section="toolbox" title="Add Items" icon="add" :sections="expandedSections"
        :isSectionExpanded="expandedSections.layers" hides-layer="items" :layers="visibleLayers" :renderer="renderer"
        @toggle-section="section => toggleSection(section)"
        @toggle-layer="layer => toggleLayer(layer)" @action="executeAction">
        <scp-toolbox :renderer="renderer" :enabled="visibleLayers[PlanLayers.Items]"
          @start-adding-item="({ item, layer }) => startAddingItem({ item, layer })"
          @stop-adding-item="stopAddingItem()" @action="executeAction">
        </scp-toolbox>
      </scp-section>

      <scp-section section="properties" title="Item Properties" icon="tune"
        :action="PlanActions.RemoveItems" v-if="canEditItemProperties" :sections="expandedSections"
        :isSectionExpanded="expandedSections.layers" :renderer="renderer" :items="selectedItems"
        @toggle-section="section => toggleSection(section)" @action="executeAction">
        <scp-item-properties :renderer="renderer" :items="selectedItems" @action="executeAction">
        </scp-item-properties>
      </scp-section>

    </template>
  </div>
</template>

<style lang="scss" scoped>
.palette {
  background-color: #f8f8f8;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: relative;
  border-radius: 5px;
  border: solid #5a5a5a 1px;

  .palette-header {
    border-bottom: solid #393939 1px;

    .icon {
      cursor: pointer;
    }
  }
}
</style>
