import { createState } from './floor-plans.state'

export const mutations = {
  /**
   * Stores floor plans in the state
   * @param items Floor plans to store
   */
  storeFloorPlans (state, { items = [] } = {}) {
    state.items = items
  },

  /**
   * Stores a floor plan in the state
   * @param floorPlan Floor plan to store
   */
  storeFloorPlan (state, { floorPlan } = {}) {
    if (floorPlan) {
      const index = state.items.findIndex(item => item.id === floorPlan.id)
      if (index === -1) {
        state.items.push(floorPlan)
      } else {
        state.items[index] = floorPlan
      }
    }
  },

  /**
   * Removes a floor plan from the state
   * @param floorPlan FloorPlan to remove
   */
  removeFloorPlan (state, { floorPlan } = {}) {
    if (floorPlan) {
      const index = state.items.findIndex(item => item.id === floorPlan.id)
      if (index > -1) {
        state.items.splice(index, 1)
      }
    }
  },

  /**
   * Toggles visibility of floor plans of the specified organization
   * @param id Identifier of an organization
   */
  toggleOrganizationFloorPlans (state, { id } = {}) {
    const index = state.expandedOrganizations.indexOf(id)
    if (index > -1) {
      state.expandedOrganizations.splice(index)
    } else {
      state.expandedOrganizations.push(id)
    }
  },

  // Toggles visibility of floor plans of all organizations
  toggleAllFloorPlans (state, { isExpanded, organizations } = {}) {
    const someExpanded = isExpanded === false || state.expandedOrganizations.length > 0
    state.expandedOrganizations = someExpanded
      ? []
      : organizations.map(o => o.id)
  },

  /**
   * Reset the state
   */
  reset (state) {
    Object.assign(state, createState())
  }
}
