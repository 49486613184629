import { Log } from '@stellacontrol/utilities'
import { API } from './api'
import { APIDebugger } from './api-client/api-debugger'

/**
 * Initializes the APIs with the specified application configuration.
 * @param configuration Application configuration
 * @param apis Identifiers of APIs to initialize. If not specified, all APIs will be initialized.
 * @param client Identifier of a client which will be using the APIs
 * @param key Access key used to authorize the client
 * @description
 * Configuration of a specific API is assumed to be in the configuration.services[name].api node.
 * API Client must be one of listed in configuration.clients.
 */
export function initializeAPI (configuration, { apis = [], client = '', key = '' } = {}) {
  if (!configuration) throw new Error('Application configuration is required')

  const apiClientsToInitialize = Object
    .entries(API)
    .filter(([name]) => apis.length === 0 || apis.includes(name))
    .map(([name, apiClient]) => ({ name, apiClient }))

  try {
    const { logging, service } = configuration
    if (logging?.api) {
      APIDebugger.initialize(logging.api)
      if (APIDebugger.isEnabled && APIDebugger.isServiceAllowed(service)) {
        Log.value('API Debugger is enabled')
        if (APIDebugger.loggers.services.length > 0) {
          Log.value('  - Allowed Services', `${APIDebugger.loggers.services.join(', ')}`)
        }
        Log.value('  - Logged Subjects', APIDebugger.toString())
        if (APIDebugger.loggers.request.file) {
          Log.value('  - Request Log', `${APIDebugger.loggers.request.file} [${APIDebugger.loggers.request.fileFormat}]`)
        }
        if (APIDebugger.loggers.response.file) {
          Log.value('  - Response Log', `${APIDebugger.loggers.response.file} [${APIDebugger.loggers.response.fileFormat}]`)
        }
        if (APIDebugger.loggers.error.file) {
          Log.value('  - Error Log', `${APIDebugger.loggers.error.file} [${APIDebugger.loggers.error.fileFormat}]`)
        }
      }
    }

    const initializedAPIClients = []

    for (const { name, apiClient } of apiClientsToInitialize) {
      // Get configuration of the api.
      // It might be hidden inside 'api' property, depending on who's calling
      const serviceConfiguration = configuration.services[apiClient.configurationName || name]
      if (!serviceConfiguration) throw new Error(`${name} API is not configured in services configuration`)
      const apiConfiguration = serviceConfiguration.api || serviceConfiguration
      // Get error handling configuratior for API requests
      const errorHandlingConfiguration = configuration.client.errors.api

      // Initialize the API client
      apiClient.initialize({
        // Process using the client
        service: configuration.service,
        // Use the client identifier and key provided in configuration,
        // or the one provided explicitly
        api: {
          ...apiConfiguration,
          client: apiConfiguration.client || client,
          key: apiConfiguration.key || key,
          errors: errorHandlingConfiguration
        }
      })

      // Accumulate
      initializedAPIClients.push(apiClient)
    }

    return initializedAPIClients

  } catch (error) {
    Log.fail(`Could not initialize API: ${apiClientsToInitialize.map(c => c.name).join(', ')}`)
    Log.exception(error)
    throw error
  }
}
