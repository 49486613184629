<script>
import { mapActions, mapGetters } from 'vuex'
import { capitalize } from '@stellacontrol/utilities'
import { getPlaceLabel } from '@stellacontrol/model'
import { ViewMixin, TabsMixin, EditorMixin } from '@stellacontrol/client-utilities'
import { Secure } from '@stellacontrol/security-ui'
import PlaceGeneral from './place-general.vue'
import PlaceDevices from './place-devices.vue'
import PlaceFloorPlans from './place-floor-plans.vue'
import PlaceAttachments from './place-attachments.vue'

const name = 'place'

/**
 * Place editor
 */
export default {
  mixins: [
    ViewMixin,
    TabsMixin,
    EditorMixin,
    Secure
  ],

  components: {
    'sc-place-general': PlaceGeneral,
    'sc-place-devices': PlaceDevices,
    'sc-place-floor-plans': PlaceFloorPlans,
    'sc-place-attachments': PlaceAttachments
  },

  data () {
    return {
      name,
      tab: 'general',
      initialOrganizationId: null
    }
  },

  computed: {
    ...mapGetters([
      'organizations'
    ]),

    forms () {
      return [
        'place'
      ]
    },

    // Organization to which the place belongs
    placeOrganization () {
      return this.organizations.find(o => o.id === this.data.organizationId) || this.currentOrganization
    },

    // Indicates that we're adding a place in another organization, not ours
    inAnotherOrganization () {
      return this.data.organizationId !== this.currentOrganization.id
    },

    // Indicates that we're moving the place to another organization
    movingToAnotherOrganization () {
      return !this.data.isNew && this.data.organizationId !== this.initialOrganizationId
    },

    // Custom view title
    title () {
      const { data, inAnotherOrganization } = this
      const { placeType, name, isNew, organization } = data
      return isNew
        ? `New ${getPlaceLabel(placeType, 'place')} ${inAnotherOrganization ? 'in ' + organization.name : ''}`
        : `${capitalize(getPlaceLabel(placeType, 'place'))} ${name}`
    }
  },

  methods: {
    ...mapActions([
      'savePlace',
      'goBack',
      'gotoRoute'
    ]),

    getPlaceLabel,

    // Saves the place and navigates back to where the user came from
    async save(goBack) {
      if (await this.validate()) {
        const { data: place, movingToAnotherOrganization } = this
        await this.savePlace({ place, movingToAnotherOrganization })
        if (goBack) {
          await this.goBack()
        }
        return true
      } else {
        this.tab = 'general'
      }
    },

    async cancel() {
      await this.goBack()
    }
  },

  created () {
    this.initialOrganizationId = this.data.organizationId
  }
}

</script>

<template>
  <sc-view :name="name" :title="title">

    <template #toolbar>
      <div class="row q-gutter-sm">
        <q-btn label="Save" class="primary" unelevated @click="save(true)"></q-btn>
        <q-btn label="Close" unelevated @click="cancel()"></q-btn>
      </div>
    </template>

    <q-banner v-if="inAnotherOrganization && !movingToAnotherOrganization" class="bg-orange-5">
      Warning! You are {{ data.isNew ? 'adding' : 'editing'}} a {{ getPlaceLabel(data.placeType) }} in another organization: <b>{{ placeOrganization.name }}</b>
    </q-banner>

    <q-banner v-if="movingToAnotherOrganization" class="bg-orange-5">
      Warning! You are moving the place to another organization: <b>{{ placeOrganization.name }}</b>
    </q-banner>

    <sc-tabs v-if="!data.isNew" v-model="tab">
      <q-tab name="general" icon="create" label="Details" :ripple="false"></q-tab>
      <q-tab name="devices" v-if="!data.isNew" icon="router" label="Devices" :ripple="false"></q-tab>
      <q-tab name="floor-plans" v-if="!data.isNew && data.canHaveFloorPlans" icon="layers" label="Floor Plans" :ripple="false"></q-tab>
      <q-tab name="attachments" v-if="!data.isNew" icon="attach_file" label="Attachments" :ripple="false"></q-tab>
    </sc-tabs>

    <sc-tab-panels v-model="tab">
      <q-tab-panel name="general">
        <sc-place-general :data="data" ref="general"></sc-place-general>
      </q-tab-panel>
      <q-tab-panel name="devices" v-if="!data.isNew">
        <sc-place-devices :data="data" ref="devices"></sc-place-devices>
      </q-tab-panel>
      <q-tab-panel name="floor-plans" v-if="!data.isNew && data.canHaveFloorPlans">
        <sc-place-floor-plans :data="data" ref="floor-plans"></sc-place-floor-plans>
      </q-tab-panel>
      <q-tab-panel name="attachments" v-if="!data.isNew">
        <sc-place-attachments :data="data" ref="attachments"></sc-place-attachments>
      </q-tab-panel>
    </sc-tab-panels>

  </sc-view>
</template>

<style lang='scss' scoped>
</style>
