<script>
import { mapActions } from 'vuex'
import { FormMixin } from '@stellacontrol/client-utilities'
import { Secure } from '../../components'

export default {
  mixins: [
    FormMixin,
    Secure
  ],

  props: {
    data: {
      type: Object,
      required: true
    }
  },

  methods: {
    ...mapActions([
    ])
  }
}

</script>

<template>
  <div>Activity ... </div>
</template>

<style lang='scss' scoped>
</style>
