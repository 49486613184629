<script>
import { mapActions } from 'vuex'
import { getDeviceLabel, flagsToAlertTypes } from '@stellacontrol/model'
import { Secure } from '@stellacontrol/security-ui'
import { CellMixin } from './cell-mixin'

export default {
  mixins: [
    CellMixin,
    Secure
  ],

  props: {
    item: {
      type: Object,
      required: true,
    }
  },

  computed: {
    device () {
      return this.item.device
    },

    // If viewing organization requires premium subscriptions,
    // returns `true` when device doesn't have active premium subscription for `alerts` feature
    noActiveSubscription () {
      const { currentOrganizationGuardian } = this
      if (currentOrganizationGuardian.requiresPremiumSubscription('alerts')) {
        return !this.device?.canUse('alerts')
      } else {
        return false
      }
    },

    // Indication of alert types which were muted for the device
    alertsMuted () {
      return flagsToAlertTypes(this.device?.snapshot?.alertsMuted) || []
    },

    // Indication of alert types which were customized for the device
    alertsCustomized () {
      return flagsToAlertTypes(this.device?.snapshot?.alertsCustomized) || []
    },

    // Icon showing the status of alert monitoring for the device
    icon () {
      if (this.noActiveSubscription) return 'notifications_off'
      if (this.alertsMuted.length > 0) return 'notifications_off'
      return 'notifications'
    },

    iconColor () {
      if (this.noActiveSubscription) return 'grey-4'
      if (this.alertsCustomized.length > 0) return 'orange-5'
      return 'green-7'
    },

    // Indicates whether device owner has  permission to use alerts
    // and device is a monitored device
    canConfigureAlerts () {
      const { device, canUse } = this
      return device.canTriggerAlerts &&
        canUse('alerts-configure')
    }
  },

  methods: {
    ...mapActions([
      'selectInventory',
      'showActionPanel'
    ]),

    getDeviceLabel,

    // Opens device panel with ALERTS tab open
    async openDeviceConfiguration () {
      const { noActiveSubscription, canConfigureAlerts, device } = this
      if (!noActiveSubscription && canConfigureAlerts) {
        await this.selectInventory({ selection: [device.serialNumber] })
        await this.showActionPanel({ tab: 'alerts' })
      }
    }
  }
}
</script>

<template>
  <div :style="columnStyle" v-if="device.canTriggerAlerts">
    <q-icon :class="{ clickable: canConfigureAlerts }" :name="icon" :color="iconColor" size="20px"
      @click="openDeviceConfiguration()">
      <sc-tooltip v-if="noActiveSubscription" nowrap>
        This device does not have ALERTS subscription
      </sc-tooltip>
      <sc-tooltip v-else nowrap>
        <span v-if="canConfigureAlerts">
          Click to configure alerts for {{ getDeviceLabel(device) }}.
        </span>
        <span v-else>
          Alerts are monitored on this device.
        </span>
        <template v-if="alertsCustomized.length > 0">
          <br>
          Some alerts are customized.
        </template>
        <template v-if="alertsMuted.length > 0">
          <br>
          Some alerts are muted.
        </template>
      </sc-tooltip>
    </q-icon>
  </div>
</template>

<style scoped lang="scss">
.clickable {
  cursor: pointer;
}
</style>