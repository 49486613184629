<script>
import { mapActions } from 'vuex'
import { sortItems, countString } from '@stellacontrol/utilities'
import { FormValidationRules as rules } from '@stellacontrol/client-utilities'
import { getDeviceBands, DeviceAlertStatistics } from '@stellacontrol/model'
import AlertConfigurationComponent from './alert-configuration-component'

export default {
  mixins: [
    AlertConfigurationComponent
  ],

  data () {
    return {
      rules,
      // Band power statistics, used to determine the alert
      bandPowerStatistics: null
    }
  },

  computed: {
    powerReduction () {
      return this.configuration.parameters.powerReduction
    },

    powerReductionIsModified () {
      return this.isModified(
        configuration => configuration.parameters.powerReduction
      )
    },

    powerReductionIsNotDefault () {
      return this.isNotDefault(
        configuration => configuration.parameters.powerReduction,
        value => `Default power reduction is ${value} dB.`
      )
    },

    // Power reduction levels to select from
    powerReductionLevels () {
      const items = sortItems(this.configuration?.parameters?.powerReductionValues || [])
      return items.map(value => ({
        value,
        label: `${value} dB`
      })) || []
    },

    // Shows whether detailed band power statistics should be visible
    showStatisticsDetails () {
      return this.isSuperAdministrator
    },

    // Device bands
    deviceBands () {
      const { device } = this
      const bands = getDeviceBands(device)
      return bands || []
    },

    // The amount of data points still missing, in order to start monitoring
    // the alert for this device
    missingDataPoints () {
      const { bandPowerStatistics, configuration } = this
      if (bandPowerStatistics && configuration) {
        const required = configuration.parameters.statistics.count * 2
        const collected = bandPowerStatistics.count + bandPowerStatistics.values?.length
        return Math.max(0, required - collected)
      }
    },

    // The approximate amount of days or hours required before all required data points
    // have been collected
    missingDataPointsTime () {
      const { missingDataPoints } = this
      if (missingDataPoints != null) {
        const hours = Math.round(missingDataPoints / 6)
        const days = Math.round(hours / 8)
        return days < 1 ? countString(hours, 'hour') : countString(days, 'day')
      }
    }
  },

  methods: {
    ...mapActions([
      'getDeviceAlertStatistics',
      'deleteDeviceAlertStatistics'
    ]),

    async populate () {
      const { device } = this
      if (device) {
        this.bandPowerStatistics = await this.getDeviceAlertStatistics({ device, name: DeviceAlertStatistics.BandPower })
      } else {
        this.bandPowerStatistics = null
      }
    },

    async resetBandPowerStatistics () {
      const { device } = this
      if (device) {
        await this.deleteDeviceAlertStatistics({ device, name: DeviceAlertStatistics.BandPower })
        this.bandPowerStatistics = null
      }
    }
  },

  watch: {
    async device () {
      await this.populate()
    }
  },

  async created () {
    await this.populate()
  }
}
</script>

<template>
  <article>
    <section v-if="powerReductionLevels.length > 1" :class="{ vertical: isEditable }">
      <label :class="{ modified: powerReductionIsModified }">
        Power reduction level
        <sc-hint size="20px">
          The alert will be triggered if power on any band drops by the specified value or more.
        </sc-hint>
      </label>
      <div v-if="isEditable" class="q-mt-sm">
        <q-option-group dense inline v-model="configuration.parameters.powerReduction"
          :options="powerReductionLevels" color="indigo-6">
        </q-option-group>
      </div>
      <div v-else>
        {{ configuration.parameters.powerReduction }} dB
      </div>
    </section>

    <section>
      <div v-if="bandPowerStatistics?.isReady()" class="">
        The alert is now being monitored.
      </div>
      <div v-else class="text-orange-8">
        <span>
          The alert will not be monitored until
          we have collected enough data.
        </span>
        <span v-if="showStatisticsDetails && missingDataPoints != null">
          Additional {{ missingDataPoints }} data points are needed.
        </span>
        <span v-if="missingDataPoints != null">
          This will take at least {{ missingDataPointsTime }}.
        </span>
      </div>
    </section>

    <section>
      <div v-if="isEditable && showStatisticsDetails && bandPowerStatistics" class="">
        <div class="q-mb-sm">
          Band power statistics for the device:
        </div>
        <div class="row items-start">
          <q-markup-table flat dense bordered separator="cell" class="power-statistics">
            <thead>
              <tr>
                <th>
                  Value
                </th>
                <th>
                  Records
                </th>
                <th v-for="band in deviceBands">
                  {{ band.parameters.label }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Normal</td>
                <td>
                  {{ bandPowerStatistics.count }}
                </td>
                <td v-for="band in deviceBands">
                  <span v-if="bandPowerStatistics.normal[band.id] != null">
                    {{ Math.round(bandPowerStatistics.normal[band.id] || 0) }}
                  </span>
                  <span v-else>
                    -
                  </span>
                </td>
              </tr>
              <tr>
                <td>Recent</td>
                <td>
                  {{ bandPowerStatistics.values?.length || 0 }}
                </td>
                <td v-for="band in deviceBands">
                  <span v-if="bandPowerStatistics.normal[band.id] != null">
                    {{ Math.round(bandPowerStatistics.getAverageValue(band.id) || 0) }}
                  </span>
                  <span v-else>
                    -
                  </span>
                </td>
              </tr>
            </tbody>
          </q-markup-table>

          <q-space>
          </q-space>

          <div class="q-ml-md">
            <q-btn unelevated dense label="Reset" class="warning" @click="resetBandPowerStatistics()">
            </q-btn>
          </div>
        </div>
      </div>
    </section>
  </article>
</template>

<style scoped lang="scss">
.power-statistics {
  th {
    background-color: #f0f0f0;
  }

  th,
  td {
    text-align: right;
    min-width: 50px;

    &:first-child {
      text-align: left;
    }
  }
}
</style>