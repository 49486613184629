<script>
import { BypassMode } from '@stellacontrol/model'
import ParameterEditor from './parameter-editor'

const BypassModeColor = {
  [BypassMode.Off]: 'grey-5',
  [BypassMode.On]: 'green-5',
  [BypassMode.Auto]: 'purple-5'
}

export default {
  mixins: [
    ParameterEditor
  ],

  computed: {
    items () {
      return this.values.map((value, index) => ({
        value,
        label: this.labels[index],
        icon: 'fiber_manual_record',
        color: BypassModeColor[value]
      }))
    }
  }
}
</script>

<template>
  <div class="parameter-editor" v-if="hasValue">

    <sc-parameter-value
      label="Bypass Mode"
      :reported="reported"
      :custom="custom"
      :format="value => value == null ? '-' : (value ? 'On' : 'Off')">
    </sc-parameter-value>

    <sc-parameter-selector
      :items="items"
      label="Bypass Mode"
      :allow-clear="isCustomized"
      @select="value => change(value)"
      @clear="clear()">
    </sc-parameter-selector>

  </div>
</template>
