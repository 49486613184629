import { notNull } from '@stellacontrol/utilities'

/**
 * Item on the toolbar palette
 */
export class PlanPaletteItem {
  constructor (data) {
    Object.assign(this, data)
    this.id = PlanPaletteItem.__id++
    this.isEnabled = notNull(this.isEnabled, true)
  }

  /**
   * Identifier counter
   * @type {Number}
   */
  static __id = 1

  /**
   * Runtime identifier
   * @type {Number}
   */
  id

  /**
   * Set to false to temporarily hide the item from the palette
   * @type {Boolean}
   */
  isEnabled

  /**
   * Identifier of a layer to which to add the item
   * @type {String}
   */
  layer

  /**
   * Type of the created item
   * @type {PlanItemType}
   */
  type

  /**
   * Icon representing the item in the toolbox
   * @type {String}
   */
  icon

  /**
   * Label of the item in the toolbox
   * @type {String}
   */
  label

  /**
   * If true, the user will have to click multiple points
   * in order to finish adding the item
   * @type {Boolean}
   */
  hasPoints

  /**
   * Factory function creating an instance of {@link PlanItem}
   * when item is being added to the plan
   */
  factory
}
