<script>
import { getDurationString } from '@stellacontrol/utilities'
import ParameterEditor from './parameter-editor'

export default {
  mixins: [
    ParameterEditor
  ],

  data () {
    return {
      // Colors representing Check Bands values
      colors: ['green-9', 'green-7', 'green-6', 'green-5', 'green-4', 'green-3']
    }
  },

  computed: {
    // Items for value selector
    items () {
      const { values, colors } = this
      return values.map((value, index) => ({
        label: getDurationString(value * 60),
        value: value,
        icon: 'schedule',
        color: colors[index]
      }))
    }
  },

  methods: {
    getDurationString
  }
}
</script>

<template>
  <div class="parameter-editor" v-if="hasValue">

    <sc-parameter-value
      label="Check Device Bands Every"
      :reported="reported"
      :custom="custom"
      :format="value => getDurationString(value * 60)"
    />

    <sc-parameter-selector
      :items="items"
      label="Check Device Bands Every"
      :allow-clear="isCustomized"
      @select="value => change(value)"
      @clear="clear()"
    />

  </div>
</template>
