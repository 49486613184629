import { formatDate } from '@stellacontrol/utilities'

/**
 * Exports serial numbers to a text document
 * @param method Export method: text|csv
 * @param serialNumbers Serial numbers to export
 * @param device Device details, with type, model etc. properties
 */
export function exportSerialNumbers (method = 'text', serialNumbers = [], device = {}) {
  switch (method) {
    case 'list':
      return exportSerialNumbersToList(serialNumbers, device)
    case 'text':
      return exportSerialNumbersToText(serialNumbers, device)
    case 'csv':
      return exportSerialNumbersToCSV(serialNumbers, device)
    default:
      throw new Error(`Unknown export method ${method}`)
  }
}

/**
 * Exports serial numbers to a text document
 * @param devices Devices to export
 */
function exportSerialNumbersToText (devices) {
  if (!devices || devices.length === 0) {
    return
  }

  const device = devices[0]
  const bands = device.bands || []
  const header = `Device: ${(device.type || '?')[0].toUpperCase()}${(bands.length || '?')}-${device.model || '?'}\n\n` +
    `# Total ${devices.length} serial${devices.length === 1 ? '' : 's'}\n`

  let serials = ''
  let serialsInRow = 0

  for (const { serialNumber } of devices) {
    if (serialsInRow > 0) {
      serials = serials + ' + '
    }
    serials = serials + serialNumber
    serialsInRow++
    if (serialsInRow === 5) {
      serialsInRow = 0
      serials = serials + '\n'
    }
  }

  return header + serials
}

/**
 * Exports serial numbers to a simple list
 * @param devices Devices to export
 */
function exportSerialNumbersToList (devices = []) {
  if (devices.length === 0) {
    return
  }

  return devices.map(d => d.serialNumber).join('\n')
}

/**
 * Exports serial numbers to a text document
 * @param devices Devices to export
 */
export function exportSerialNumbersToCSV (devices = []) {
  if (devices.length === 0) {
    return
  }

  const lines = [
    'Serial;Type;Model;Bands;Port Count;Manufactured;Owner;'
  ]

  for (const device of devices) {
    const { serialNumber, type, model, bands, portCount, manufacturedAt, owner } = device
    const line = `${serialNumber};${type};${model};${bands};${portCount};${formatDate(manufacturedAt)};${owner ? owner.name : '-'}`
    lines.push(line)
  }

  return lines.join('\n')
}
