export function createState () {
  return {
    /**
     * Indicates that service management has been initialized
     */
    isInitialized: false,
    /**
     * Customer hierarchy of the current organization
     */
    customers: null,
    /**
     * Dictionary of devices per owner
     */
    devices: {},
    /**
     * All available pricelists
     */
    pricelists: [],
    /**
     * Indicates that current organization is a bank,
     * with ability to freely create and distribute tokens to its customers
     */
    isBank: false,
    /**
     * Indicates that current organization is a customer
     * able to subscribe to premium services
     */
    isCustomer: false,
    /**
     * Indicates that current organization is a reseller
     * able to sell premium service subscription to its customers
     */
    isReseller: false,
    /**
     * Wallet of the currently logged in organization
     */
    wallet: null
  }
}

export const state = createState()

export const getters = {
  // All pricelists
  pricelists: state => state.pricelists || [],
  // Current pricelist
  pricelist: state => (state.pricelists || []).find(item => item.isMain),
  // All available premium services
  premiumServices: (state, getters) => getters.pricelists?.flatMap(pricelist => pricelist.services || []),
  // Dictionary of premium services, by identifier
  premiumServicesDictionary: (state, getters) =>
    getters.premiumServices.reduce((all, ps) => ({ ...all, [ps.id]: ps }), []),
  // Details of the specified premium service
  getPremiumService: state =>
    id => state.pricelist?.services?.find(s => s.id === id),
  // Current organization's wallet
  myWallet: state => state.wallet
}
