import { safeParseInt } from '@stellacontrol/utilities'
import { Assignable } from '@stellacontrol/model'

/**
 * Properties of a point
 */
export class PlanPointStyle extends Assignable {
  constructor (data = {}) {
    super(data)
    this.assign(data)
  }

  /**
   * Object defaults
   */
  get defaults () {
    return {
      radius: 0,
      color: null,
      hoverColor: null,
      borderWidth: 0,
      borderColor: null
    }
  }

  normalize () {
    super.normalize()
    const { defaults } = this
    this.radius = safeParseInt(this.radius, defaults.radius)
    this.color = this.color || defaults.color
    this.hoverColor = this.hoverColor || defaults.hoverColor
    this.borderWidth = Math.max(0, safeParseInt(this.borderWidth, defaults.borderWidth))
    this.borderColor = this.borderColor || defaults.borderColor
  }

  /**
   * Checks if style is the same as the specified one
   * @param {PlanPointStyle} style
   * @returns {Boolean}
   */
  sameAs (style) {
    if (style) {
      return this.radius === style.radius &&
        this.color === style.color &&
        this.hoverColor === style.hoverColor &&
        this.borderWidth === style.borderWidth &&
        this.borderColor === style.borderColor
    }
  }

  /**
   * Point radius
   * @type {Number}
   */
  radius

  /**
   * Point border width
   * @type {Number}
   */
  borderWidth

  /**
   * Point border color
   * @type {String}
   */
  borderColor

  /**
   * Point fill color
   * @type {String}
   */
  color

  /**
   * Point fill color on hover
   * @type {String}
   */
  hoverColor

  /**
   * Returns true if style is not defined
   * @type {Boolean}
   */
  get isEmpty () {
    return !(this.radius && this.color)
  }
}
