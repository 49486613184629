<script>
import { mapState, mapActions } from 'vuex'
import { distinctValues } from '@stellacontrol/utilities'
import { getDevicesDescription } from '@stellacontrol/model'
import { getMegaParameter } from '@stellacontrol/mega'
import DeviceAction from './device-action.vue'
import { DeviceActionMixin } from './device-action-mixin'

export default {
  mixins: [
    DeviceActionMixin
  ],

  components: {
    'sc-device-action': DeviceAction
  },

  data () {
    return {
      // PIN number to assign
      pin: '',
      // Lock screen behaviour
      lcdMode: null,
      // Lock screen brightness
      lcdBrightness: ''
    }
  },

  computed: {
    ...mapState({
      // Recent status of devices
      deviceStatus: state => state.deviceStatus.devices
    }),

    // List of updatable devices
    updatableDevices () {
      return this.devices.filter(d => d.isConnectedDevice && !d.isSimulatedDevice)
    },

    // Returns PIN length to apply.
    // If devices with mixed firmware versions are being set up,
    // they have to agree on PIN length
    pinLength () {
      const { devices } = this
      const lengths = devices.map(device => (getMegaParameter('_pin', device) || {}).length)
      const distinct = new Set(lengths)
      return distinct.size === 1 ? lengths[0] : undefined
    },

    // Returns LCD brighness parameters
    lcdBrightnessParameters () {
      return getMegaParameter('_lcd_dim', this.device)
    },

    // Returns LCD mode parameters
    lcdModeParameters () {
      return getMegaParameter('_lcd_lock', this.device)
    },

    // Available LCD modes
    lcdModes () {
      return this.lcdModeParameters.values.map((value, index) => ({
        value,
        label: this.lcdModeParameters.labels[index]
      }))
    },

    // Returns true if it is possible to assign PIN
    // to the currently selected devices in one go
    canAssignPin () {
      return this.canUse('device-management-pin') && this.pinLength > 0
    },

    // Returns true if it is possible to configure the LCD screen
    // to the currently selected devices in one go
    canConfigureLCD () {
      return this.canUse('device-configuration-lcd')
    },

    // Returns true if it is possible to configure the LCD screen mode
    canConfigureLCDMode () {
      return false
    }
  },

  watch: {
    // Refresh on device selection change
    devices () {
      this.populate()
    }
  },

  methods: {
    ...mapActions([
      'getLiveStatus',
      'lockDevices'
    ]),

    getDevicesDescription,

    // Retrieves current settings of the selected devices
    async populate () {
      const { updatableDevices: devices, deviceStatus } = this
      await this.getLiveStatus({ devices })
      this.isLoading = true
      try {
        // Display current value, unless different per each device
        const values = this
          .devices
          .map(d => deviceStatus[d.serialNumber]?.mega)
          .filter(m => m)
        const lcdMode = distinctValues(values, '_lcd_lock')
        const lcdBrightness = distinctValues(values, '_lcd_dim')
        if (lcdMode?.length === 1) {
          this.lcdMode = lcdMode[0]
        }
        if (lcdBrightness?.length === 1) {
          this.lcdBrightness = (lcdBrightness[0] || '').toString()
        }

      } finally {
        this.isLoading = false
      }
    },

    async configure () {
      if (await this.validate()) {
        const { updatableDevices: devices, pin, lcdMode, lcdBrightness } = this
        await this.lockDevices({
          devices,
          pin: pin.trim(),
          lcdMode: lcdMode >= 0 ? lcdMode : undefined,
          lcdBrightness: lcdBrightness.toString().trim() ? parseInt(lcdBrightness) : undefined
        })
        this.executed({ refresh: false })
      }
    }
  },

  async created () {
    await this.populate()
  }
}
</script>

<template>
  <sc-device-action :isLoading="isLoading" :action="action" :devices="devices" execute-label="Assign"
    @closing="closing" @close="close" @execute="configure">

    <q-form ref="form" autofocus class="q-mt-md q-gutter-sm" @submit.prevent>
      <div v-if="canAssignPin">
        <div>
          <label class="text-body2 text-grey-9">
            Assign new PIN on {{ getDevicesDescription(devices) }}. Leave empty to keep the PIN
            unchanged.
            {{ capitalize(selectionLabel) }} {{ devicesVerb('require') }} PIN with {{ pinLength }}
            digits:
          </label>
        </div>

        <q-input square outlined label="PIN" v-model="pin" clearable counter hide-bottom-space
          :maxlength="pinLength" :rules="[
            rules.hasExactLength(pinLength, null, true) || 'PIN length is invalid',
            rules.hasOnlyDigits('Only digits are allowed')
          ]" lazy-rules>
        </q-input>
      </div>
      <div v-else>
        PIN cannot be assigned to the selected devices all at once.
        They seem to have varying PIN requirements.
        Try selecting devices with similar firmware versions.
      </div>

      <div v-if="canConfigureLCDMode" class="q-mt-md">
        <div>
          <label class="text-body2 text-grey-9">
            LCD Screen Mode:
          </label>
        </div>
        <q-select square outlined clearable class="q-mt-sm" :options="lcdModes" v-model="lcdMode"
          emit-value map-options option-value="value" option-label="label">
        </q-select>
      </div>

      <div v-if="canConfigureLCD" class="q-mt-md">
        <div>
          <label class="text-body2 text-grey-9">
            LCD Screen Brightness ({{ lcdBrightnessParameters.min }} - {{ lcdBrightnessParameters.max
            }}):
          </label>
        </div>
        <q-input square outlined clearable type="number" :min="lcdBrightnessParameters.min"
          :max="lcdBrightnessParameters.max" v-model="lcdBrightness">
        </q-input>

      </div>
    </q-form>

  </sc-device-action>
</template>

<style lang="scss" scoped></style>
