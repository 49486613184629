/**
 * Announcement priorities
 */
export const AnnouncementPriority = {
  Normal: 0,
  Elevated: 5,
  Urgent: 10
}

/**
 * Announcement priority details
 */
export const AnnouncementPriorities = {
  [AnnouncementPriority.Normal]: {
    name: 'Normal'
  },
  [AnnouncementPriority.Elevated]: {
    name: 'Elevated'
  },
  [AnnouncementPriority.Urgent]: {
    name: 'Urgent'
  }
}
