import { getStore } from '../get-store'
/**
 * Dispatches specified actions sequentially one after another.
 * @param actions Actions to dispatch, list of action names or { name, parameters } entities if action requires parameters
 * @returns Results of the dispatched actions - either a single result
 * if one action has been dispatched, or array of all results
 */
export async function dispatchSequential (...actions) {
  const store = getStore()
  let results = []
  for (const action of actions) {
    const actionName = (typeof action === 'string') ? action : action.name
    const actionParameters = (typeof action === 'string') ? undefined : action.parameters
    const result = await store.dispatch(actionName, actionParameters)
    results.push(result)
  }
  return actions.length === 1 ? results[0] : results
}
