import BugReportView from './bug-report.vue'
import { resolve } from './bug-report.resolve'

const name = 'bug-report'

export const Routes = [
  {
    name,
    path: '/bug-report/:id',
    component: BugReportView,

    async beforeEnter (to, from, next) {
      const data = await resolve(to, from)
      if (data) {
        next()
      }
    }
  }
]
