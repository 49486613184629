/**
 * Margin
 */
export class Margin {
  constructor (data) {
    const { top, right, bottom, left } = data || {}
    this.top = top == null ? 0 : top
    this.right = right == null ? 0 : right
    this.bottom = bottom == null ? 0 : bottom
    this.left = left == null ? 0 : left
  }

  /**
   * Creates margin with dimensions of another margin
   * @param {Margin} margin
   * @returns {Margin}
   */
  static from (margin) {
    return new Margin(margin)
  }

  /**
   * Creates zero-size margin
   * @returns {Margin}
   */
  static Zero () {
    return new Margin({
      top: 0,
      right: 0,
      bottom: 0,
      left: 0
    })
  }

  /**
   * Creates a deep copy of the margin
   * @type {Margin}
   */
  copy () {
    return Margin.from(this)
  }

  /**
   * Top margin
   * @type {Number}
  */
  top

  /**
   * Right margin
   * @type {Number}
   */
  right

  /**
   * Bottom margin
   * @type {Number}
   */
  bottom

  /**
   * Left margin
   * @type {Number}
   */
  left

  /**
   * Total margin on X axis
   * @type {Number}
   */
  get x () {
    return (this.left || 0) + (this.right || 0)
  }

  /**
   * Total margin on Y axis
   * @type {Number}
   */
  get y () {
    return (this.top || 0) + (this.bottom || 0)
  }

  /**
   * Returns left-top margin
   * @type {Margin}
   */
  get leftTop () {
    return Margin.from({
      left: this.left,
      top: this.top
    })
  }

  /**
   * Returns right-bottom margin
   * @type {Margin}
   */
  get rightBottom () {
    return Margin.from({
      right: this.right,
      bottom: this.bottom
    })
  }

  /**
 * Serializes the item to JSON
 * @returns {Object}
 */
  toJSON () {
    const result = { ...this }
    if (result.top == null) delete result.top
    if (result.right == null) delete result.right
    if (result.bottom == null) delete result.bottom
    if (result.left == null) delete result.left
    return result
  }

  /**
   * String representation of the margin
   * @returns {String}
   */
  toString () {
    const { top, right, bottom, left } = this
    return `(${[top, right, bottom, left].filter(c => c != null).join(',')})`
  }

  /**
   * Determines whether at least one edge of the margin is specified
   * @type {Boolean}
   */
  get isDefined () {
    return this.top != null || this.right != null || this.bottom != null || this.left != null
  }

  /**
   * Sets margin values
   * @param {Margin} values Values to assign, can be partial
   * @returns {Margin} Modified instance
   */
  setMargin (values) {
    const { top, right, bottom, left } = values || {}
    this.top = top == null ? this.top : top
    this.right = right == null ? this.right : right
    this.bottom = bottom == null ? this.bottom : bottom
    this.left = left == null ? this.left : left
  }
}
