import { Point, safeParseInt, merge } from '@stellacontrol/utilities'
import { PlanItem, PlanItemType } from './plan-item'
import { PlanLineStyle, PlanBackgroundStyle } from '../styles'

/**
 * Polygon
 */
export class PlanPolygon extends PlanItem {
  constructor (data = {}) {
    super(data)
    this.assign(data)
  }

  /**
   * Item type
   * @type {PlanItemType}
   */
  static get type () {
    return PlanItemType.Polygon
  }

  /**
   * Creates a new generic polygon
   * @param {Object} data Initial data
   * @returns {PlanPolygon}
   */
  static create (data = {}) {
    const points = [
      new Point({ x: 0, y: 0 }),
      new Point({ x: 0, y: 0 })
    ]
    if (data.x && data.y) {
      points.every(p => p.moveBy(data))
    }
    return new PlanPolygon({
      ...data,
      points,
      lineStyle: merge({ width: 0 }, data.lineStyle),
      backgroundStyle: merge({ color: 'blue', opacity: 0.5 }, data.backgroundStyle)
    })
  }

  /**
   * Item defaults
   */
  get defaults () {
    return {
      ...super.defaults,
      // Polygons don't have a position, they're defined by their points
      x: undefined,
      y: undefined,
      z: undefined,
      // If specified, polygon points are aligned if moved by margin smaller than the specified here
      alignThreshold: 4
    }
  }

  normalize () {
    super.normalize()
    const { defaults } = this
    this.points = this.castArray(this.points, Point, [])
    this.alignThreshold = safeParseInt(this.alignThreshold, defaults.alignThreshold)

    // When drawing building walls, use emphasized colors
    if (this.isWall || this.isYard) {
      this.lineStyleInProgress = new PlanLineStyle({ color: 'red', width: 6 })
      this.backgroundStyleInProgress = new PlanBackgroundStyle({ color: 'orange', opacity: 0.6 })
    }
  }

  /**
   * Removes any duplicate {@link points}
   */
  normalizePoints () {
    const points = this.points
      .filter((p, i, points) => i === 0 || !p.sameAs(points[i - 1]))
    this.points = points
  }

  /**
   * Serializes the plan item to JSON
   * @returns {Object}
   */
  toJSON () {
    const result = super.toJSON()
    delete result.x
    delete result.y
    delete result.z
    return result
  }

  /**
   * Polygon points
   * @type {Array[Point]}
   */
  points

  /**
   * Returns points making up the shape,
   * @param {Boolean} isCrossSection Indicates that we're operating on the cross-section
   * @returns {Array[Point]}
   */
  // eslint-disable-next-line no-unused-vars
  getPoints (isCrossSection) {
    return this.points || []
  }

  /**
   * Returns the first point of the polygon
   * @returns {Point}
   */
  getFirstPoint () {
    return this.points[0]
  }

  /**
   * If true, line points are aligned if moved by margin smaller than the specified here
   * @type {Number}
   */
  alignThreshold

  /**
  * Called when polygon has been moved to the specified position.
  * Translate the polygon points accordingly.
  * @param {Point} position Position to which the item was moved
  * @param {Boolean} isCrossSection If true, the coordinates on cross section have been changed,
  * otherwise just the ordinary coordinates on the floor
  * @param {Point} delta Position change. If not specified, we will use the first point
  * of the polygon to calculate the delta required to move all other points
  * @returns {PlanItem}
  */
  moveTo (position, isCrossSection, delta) {
    super.moveTo(position, isCrossSection, delta)
    if (position && delta) {
      const { x, y } = delta.round()
      for (const point of this.points) {
        point.moveBy({ x, y })
      }
    }
    return this
  }

  /**
   * Moves the item by the specified delta.
   * @param {Point} delta Delta to move the item by
   * @returns {PlanItem}
   */
  moveBy (delta) {
    super.moveBy(delta)
    if (delta) {
      for (const point of this.points) {
        point.moveBy(delta)
      }
    }
    return this
  }

  /**
   * Adds a point to the polygon.
   * @param {Point} point Point to add
   * @param {Number} index Index at which to add the point
   * @param {Point} align If true, points are aligned to each other to ensure proper straight angles when movements are minuscule
   * @param {Boolean} isCrossSection Indicates that we're operating on the cross-section
   * @returns {Array[Point]} Item points
   */
  // eslint-disable-next-line no-unused-vars
  addPoint (point, index, align, isCrossSection) {
    const { points, alignThreshold } = this
    if (index == null) {
      index = points.length - 1
    }
    if (index >= 0 && index <= points.length) {
      // Align the point, if position on X and/or Y axis is only slightly different,
      // to ensure that we have nice straight lines
      if (align) {
        const previous = this.getPreviousPoint(points, index, true)
        const next = this.getNextPoint(points, index, true)
        this.alignPoint(previous, point, next, alignThreshold)
      }
      // Insert the point at the specified index
      this.points = [...points.slice(0, index), point, ...points.slice(index)]
    }
    return this.points
  }

  /**
   * Removes a point from the polygon.
   * @param {Number} index Index at which to remove the point
   * @returns {Array[Point]} Item points
   */
  removePoint (index) {
    const { points } = this
    if (index >= 0 && index < points.length) {
      this.points = [...points.slice(0, index), ...points.slice(index + 1)]
    }
    this.normalizePoints()
    return this.points
  }

  /**
   * Moves line point to the specified new coordinates
   * @param {Number} index Point index
   * @param {Point} position Position to which the point was moved
   * @param {Point} align If true, points are aligned to each other
   * to ensure proper straight angles when movements are minuscule
   */
  movePoint (index, position, align) {
    const { points, alignThreshold } = this
    const point = points[index]
    const previous = this.getPreviousPoint(points, index, true)
    const next = this.getNextPoint(points, index, true)
    if (point) {
      const { x, y } = position.round()
      point.x = x
      point.y = y
      if (align) {
        this.alignPoint(previous, point, next, alignThreshold)
      }
      return this.getPoint(index)
    }
  }

  /**
   * Returns the index of the last point of the item
   * @returns {Number}
   */
  get lastPointIndex () {
    return this.points.length - 1
  }
}
