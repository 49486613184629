import { mapGetters } from 'vuex'

/**
 * Vue dialog mixin
 */
export const DialogMixin = {
  computed: {
    ...mapGetters([
      'getDialogData',
      'isDialogVisible'
    ]),

    /**
     * Data passed into the dialog
     */
    data () {
      return this.getDialogData(this.dialog)
    },

    /**
     * Returns true if dialog is visible
     */
    isVisible () {
      return this.isDialogVisible(this.dialog)
    },

    /**
     * Returns true if dialog is not visible
     */
    isHidden () {
      return !this.isVisible
    }
  },

  created () {
    if (!this.dialog) {
      throw new Error('Dialog component must have `dialog` data property which contains the unique identifier of the dialog')
    }
  }
}
