import { PlanEvent } from './plan-event'

/**
 * Component emitting plan events
 */
export class PlanEventEmitter extends EventTarget {
  /**
   * Event handlers bound to the event emitter
   * @type {Array[Function]}
   */
  __eventHandlers = []

  /**
   * Dispatches the specified event with the given details
   * @param {String} event Event name
   * @param {Object} data Data to send with the event
   */
  notifyEvent (event, data = {}) {
    if (event) {
      const detail = {
        event,
        shape: this,
        ...data
      }
      const e = new CustomEvent(event, { detail })
      this.dispatchEvent(e)
    }
  }


  /**
   * Wrapper around `addEventHandler`, takes note of attached event to allow easy removal of them
   * using `unbindEventHandlers`
   * @param {String} event Event to bind to
   * @param {Function} handled Event handler
   * @param {Object} options Event handler options
   */
  addEventListener (event, handler, options) {
    // Wrap the handler to prevent execution if event is already marked as handled
    const internalHandler = (e) => PlanEvent.isEventHandled(e) ? undefined : handler(e)
    super.addEventListener(
      event,
      internalHandler,
      options
    )
    this.__eventHandlers.push({ event, handler: internalHandler, options })
  }

  /**
   * Removes all event listeners
   */
  removeEventListeners () {
    const { __eventHandlers } = this
    this.__eventHandlers = []
    for (const { event, handler, options } of __eventHandlers) {
      this.removeEventListener(event, handler, options)
    }
  }

  /**
   * Unsubscribe event handlers when destroyed
   */
  destroy () {
    this.removeEventListeners()
  }
}