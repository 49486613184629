import { PlanAction, PlanActions } from './plan-action'

/**
 * Resizes items
 */
export class ResizeItemsAction extends PlanAction {
  /**
   * Action name
   * @type {String}
   */
  static get action () {
    return PlanActions.ResizeItems
  }

  /**
   * Indicates that the action requires items to act on
   * @type {Boolean}
   */
  get requiresItems () {
    return true
  }

  /**
   * Indicates whether action requires refresh
   * @type {Boolean}
   */
  get requiresRefresh () {
    return true
  }

  /**
   * If true, the current selection will be preserved
   * after the action has been executed
   * @type {Boolean}
   */
  get preserveSelection () {
    return true
  }

  /**
   * Executes the action
   * @param {PlanRenderer} renderer Plan renderer
   * @param {Array[PlanItem]} items Plan items to apply the action to
   * @param {Number} x Item resize on X axis
   * @param {Number} y Item resize on Y axis
   * @param {Boolean} snapToGrid If true, the new size should be snapped to grid lines
   */
  execute ({ renderer, items, x = 0, y = 0 } = {}) {
    if (renderer && items) {
      for (const item of items) {
        let width = x
        let height = y

        // Resize by multiplies of the grid size, if movement by more than one pixel
        const gridSize = renderer.layout.gridSize
        if (Math.abs(width) > 1) {
          width = Math.ceil(width / gridSize) * gridSize
        }
        if (Math.abs(height) > 1) {
          height = Math.ceil(height / gridSize) * gridSize
        }

        // Resize the item
        item.resizeBy(width, height)
        renderer.changed({ action: this })
      }
    }
  }
}
