<script>
import { mapActions, mapGetters } from 'vuex'
import { DocumentType } from '@stellacontrol/model'
import HelpMixin from './help-mixin'

export default {
  mixins: [
    HelpMixin
  ],

  data () {
    return {
      terms: null,
      text: null,
      DocumentType
    }
  },

  computed: {
    ...mapGetters([
      'myTermsAndConditions'
    ])
  },

  methods: {
    ...mapActions([
      'getTermsAndConditions'
    ])
  },

  async created () {
    const { id } = this.myTermsAndConditions || {}
    const { text } = await this.getTermsAndConditions({ id }) || {}
    if (text) {
      this.text = text
    } else {
      this.text = 'Could not load Terms and Conditions. Please contact administrator.'
    }
  }
}

</script>

<template>
  <main v-if="text">
    <sc-document-viewer
      :content="text"
      :type="DocumentType.Markdown"
      :isCollapsible="isCollapsible"
      :isCollapsed="isCollapsed">
    </sc-document-viewer>
  </main>
</template>

<style scoped lang="scss">
main {
  flex: 1;
  overflow: auto;
}
</style>
