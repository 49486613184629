import { getMegaParameter } from '@stellacontrol/mega'
import ParameterValue from './parameter-value.vue'
import ParameterSelector from './parameter-selector.vue'

// Parameter editor mixin
export default {
  components: {
    'sc-parameter-value': ParameterValue,
    'sc-parameter-selector': ParameterSelector
  },

  props: {
    // Device
    device: {
      required: true
    },
    // Device status
    status: {
    },
    // Parameter key in MEGA message
    parameter: {
      required: true
    },
    // Show unit along the current value
    showUnit: {
      default: false
    }
  },

  computed: {
    // MEGA definition of the parameter
    mega () {
      return getMegaParameter(this.parameter, this.device, this.status)
    },

    // Allowed parameter values
    values () {
      return this.mega.values
    },

    // Parameter values which user can select from
    selectableValues () {
      return this.mega.selectableValues || this.mega.values
    },

    // Allowed parameter values, translated to device-specific units
    deviceValues () {
      return this.mega.deviceValues
    },

    // Parameter unit
    unit () {
      return this.mega.unit
    },

    // Parameter labels
    labels () {
      return this.mega.labels
    },

    // Label of a specified value of the parameter
    labelOf () {
      return value => {
        const { values = [], labels = [] } = this
        const index = values.findIndex(v => v == value)
        const label = labels[index] || (value == null ? '-' : value.toString())
        return label
      }
    },

    // Reported value of the parameter
    reported () {
      const { status, parameter } = this
      if (status && parameter) {
        return status.getReported(parameter)
      }
    },

    // Custom value of the parameter
    custom () {
      const { status, parameter } = this
      if (status && parameter) {
        return status.getCustom(parameter)
      }
    },

    // Indicates whether parameter has a value
    hasValue () {
      return this.reported != null || this.custom != null
    },

    // Indicates whether parameter value has been changed
    isCustomized () {
      return this.custom != null
    },

    // Indicates whether parameter value has been changed
    // but not yet picked up by the device
    isChanging () {
      const { custom, reported } = this
      return custom != null && custom != reported
    },

    // Minimal value of a numeric parameter
    min () {
      return this.mega.min
    },

    // Maximal value of a numeric parameter
    max () {
      return this.mega.max
    }
  },

  emits: [
    'change',
    'clear'
  ],

  methods: {
    // Requests a change of the parameter value
    change (value) {
      const { parameter } = this
      this.$emit('change', { parameter, value })
    },

    // Requests clearing of the custom parameter value
    clear () {
      const { parameter } = this
      this.$emit('clear', { parameter })
    }
  }
}
