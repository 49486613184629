import { DeviceModels, ObsoleteDeviceModels, NonUpdateableDeviceModels } from '@stellacontrol/model'

/**
 * Application state mutations
 */
export const mutations = {
  /**
   * Initializes the application
   */
  initializeClient (_, { configuration } = {}) {
    if (configuration) {
      // Initialize device models from configuration
      DeviceModels.push(...configuration.entities.device.model.all)
      ObsoleteDeviceModels.push(...configuration.entities.device.model.obsolete)
      NonUpdateableDeviceModels.push(...configuration.entities.device.model.nonUpdateable)
    }
  },

  /**
   * Called after the initial application data has been loaded.
   * Used to massage and post-process the data, for example to create hierarchies,
   * recreate relationships between objects etc.
   */
  dataInitialized () {
  },

  /**
   * Starts polling for application updates,
   * telling user to refresh when update has been deployed
   * @param clock Timer watching for the updates
   */
  watchApplicationUpdates (state, { clock } = {}) {
    state.applicationUpdate.clock = clock
    state.applicationUpdate.updatedTo = null
  },

  /**
   * Starts polling for application updates,
   * telling user to refresh when update has been deployed
   */
  unwatchApplicationUpdates (state) {
    state.applicationUpdate.clock = null
    state.applicationUpdate.updatedTo = null
  },

  /**
   * Notifies that the application has been updated
   * @param version Version to which the application has been updated
   */
  applicationUpdated (state, { version }) {
    state.applicationUpdate.updatedTo = version
  },

  /**
   * Notifies that the application flags have been changed
   * @param {ApplicationFlags} flags New application flags
   */
  applicationFlagsChanged (state, { flags }) {
    state.flags = flags
  }
}
