import { parseDate } from '@stellacontrol/utilities'
import { Assignable } from '../common/assignable'

/**
 * Defines a link between attachment and a principal, allowing the principal
 * to view the attachment etc.
 * @description This relationship class is used to establish a relationship
 * between {@link Attachment} and other entities. The {@link Attachment}
 * already has `entityId` property linking it to a specific entity,
 * but we might also want to make it accessible to other entities while retaining
 * the original attachment owner.
 */
export class AttachmentLink extends Assignable {
  constructor (data = {}) {
    super()
    this.assign(data, {
      createdAt: parseDate,
      allowEdit: Boolean,
      allowDelete: Boolean
    })
  }

  /**
   * Normalizes the data after assignment
   */
  normalize () {
    super.normalize()
  }

  /**
   * Creation time
   * @type {Date}
   */
  createdAt

  /**
   * Identifier of a user who created the link
   * @type {String}
   */
  createdBy

  /**
   * Attachment identifier
   * @type {String}
   */
  attachmentId

  /**
   * Identifier of a principal linked to attachment, such as place, organization etc.
   * @type {String}
   */
  principalId

  /**
   * Principal linked to attachment, such as place, organization etc.
   * @type {Principal}
   */
  principal

  /**
   * Organization to which the {@link Principal} belongs
   * @type {Organization}
   */
  organization

  /**
   * If true, the principal can not only view the attachment but also modify it
   * @type {Boolean}
   */
  allowEdit

  /**
   * If true, the principal can not only view the attachment but also delete it
   * @type {Boolean}
   */
  allowDelete

  /**
   * Remove runtime properties before serialization
   * @returns {Object}
   */
  toJSON () {
    const result = { ...this }
    return result
  }
}
