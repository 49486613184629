import { Assignable } from '../common/assignable'

/**
 * Describes status of a notification, from perspective of its recipient.
 * Single notification
 */
export class NotificationStatus extends Assignable {
  constructor (data = {}) {
    super()

    this.assign({
      ...data,
    })
  }

  /**
   * Notification identifier
   * @type {String}
   */
  notificationId

  /**
   * Recipient identifier
   * @type {String}
   */
  recipientId

  /**
   * Bundle identifier
   * @type {String}
   */
  bundleId

  /**
   * Date and time when notification has been created
   * @type {Date}
   */
  createdAt

  /**
   * Date and time when notification has been sent
   * @type {Date}
   */
  sentAt

  /**
   * Details of where notification was sent to.
   * One recipient might still represent a batch of e-mail addresses,
   * which would be visible here
   * @type {String}
   */
  sentTo

  /**
   * Date and time when notification delivery has failed
   * @type {Date}
   */
  failedAt

  /**
   * Date and time when notification has been acknowledged by the recipient
   * @type {Date}
   */
  acknowledgedAt

  /**
   * Identifier of a user who acknowledged the notification
   * @type {String}
   */
  acknowledgedBy
}
