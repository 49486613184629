import { DeviceAlertStatistics } from './device-alert-statistics'

/**
 * Statistics of band usage values kept for each device,
 * used as baseline for detecting sustained continuous uplink alert
 */
export class BandUsageStatistics extends DeviceAlertStatistics {
  constructor (data = {}) {
    super(data)
    this.assign(data)
    this.parameter = 'sliding'
  }
}
