export const actions = {
  /**
   * Registers user activity
   * @param {AuditItem} action User action to register
   */
  async logUserActivity ({ commit }, { action } = {}) {
    if (!action) throw new Error('Action is required')
    commit('storeUserActivity', { action })
  },

  /**
   * Clears user activity
   */
  async clearUserActivity ({ commit }) {
    commit('clearUserActivity')
  }
}
