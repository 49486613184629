<script>
import { CellMixin } from './cell-mixin'

export default {
  mixins: [
    CellMixin
  ],

  props: {
    text: {
      required: true,
    },

    emptyText: {
      default: '-',
    }
  }
}
</script>

<template>
  <div :style="columnStyle" class="text clip">
    <span v-bind="{...$props, ...$attrs}">
      {{ text || emptyText }}
    </span>
  </div>
</template>

<style>
</style>