<script>
import { mapActions, mapGetters } from 'vuex'
import { Notification } from '@stellacontrol/client-utilities'

export default {
  data () {
    return {
      terms: null,
      text: null
    }
  },

  computed: {
    ...mapGetters([
      'myTermsAndConditions',
      'termsAndConditionsAccepted',
      'application'
    ])
  },

  methods: {
    ...mapActions([
      'getTermsAndConditions',
      'approveTermsAndConditions',
      'gotoHome',
      'collapseSidebar',
      'expandSidebar'
    ]),

    clear () {
      this.text = null
      this.terms = null
    },

    async approve () {
      const { terms } = this
      if (terms) {
        this.clear()
        await this.expandSidebar()
        await this.approveTermsAndConditions({ terms })
        await this.gotoHome()

        Notification.success({
          message: 'Thank you!',
          icon: 'sentiment_satisfied_alt',
          details: `Welcome to ${this.application.name}`
        })
      }
    }
  },

  async created () {
    const { id } = this.myTermsAndConditions || {}
    const { terms, html } = await this.getTermsAndConditions({ id }) || {}
    if (html) {
      this.terms = terms
      this.text = html
      this.collapseSidebar()
    } else {
      this.text = 'Could not load Terms and Conditions. Please contact administrator.'
    }
  }
}

</script>

<template>
  <main v-if="text" class="q-pt-md">
    <article class="terms-and-conditions markdown q-pa-md q-ma-md" v-html="text">
    </article>

    <footer class="q-pl-md q-pr-md q-pt-lg q-pb-xl column items-center text-indigo-8 text-subtitle2" v-if="text">
      <q-btn
        class="success"
        no-caps
        size="md"
        icon="check"
        label="I have read and agree to Terms and Conditions"
        @click="approve()"
      />
    </footer>
  </main>
</template>

<style lang="scss" scoped>
main {
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  article.terms-and-conditions {
    border: solid silver 1px;
    box-shadow: 0 0 4px #c0c0c0;
    max-width: 1200px;
  }

}
</style>
