import { mapActions, mapGetters } from 'vuex'

/**
 * Tabs mixin
 */
export const TabsMixin = {
  computed: {
    ...mapGetters([
      'getView',
      'getViewTab'
    ]),

    // Indicates that tab mixin has been introduced
    hasTabMixin () {
      if (!this.hasViewMixin) {
        throw new Error('Tab mixin requires view mixin applied to the component')
      }
      if (!this.name) {
        throw new Error('Tab mixin requires view name available on the component')
      }
      return true
    },

    /**
     * Tab currently selected on the view
     */
    viewTab () {
      const { hasTabMixin, getView, name } = this
      const view = getView(name)
      if (view && hasTabMixin) {
        return view.tab
      }
    },

    /**
     * All available tabs
     */
    viewTabs () {
      const { hasTabMixin, getView, name } = this
      const view = getView(name)
      if (view && hasTabMixin) {
        return view.tabs ? Object.keys(view.tabs) : []
      }
    },

    /**
     * Returns true if specified tab is currently selected
     */
    isViewTab () {
      return name => this.viewTab === name
    }
  },

  methods: {
    ...mapActions([
      'selectViewTab'
    ]),

    // Determines whether the specified tab can be selected.
    // Override in components, to provide for tabs which are conditionally shown.
    isTabVisible (tab) {
      return Boolean(tab)
    },

    // Selects the specified tab on the view
    async selectTab (tab) {
      if (tab) {
        const { isTabVisible, selectViewTab, name, getView } = this
        const view = getView(name)
        if (view && isTabVisible(tab)) {
          await selectViewTab({ view, tab })
        }
      }
    },

    // Selects the default tab
    async selectDefaultTab () {
      const { viewTabs, selectTab } = this
      await selectTab(viewTabs[0])
    },

    // Selects the default tab, if current tab on the view
    // is not visible for some reason
    async ensureTab () {
      const { viewTab, isTabVisible, selectDefaultTab } = this
      const isCurrentTabAllowed = viewTab && isTabVisible(viewTab)
      if (!isCurrentTabAllowed) {
        await selectDefaultTab()
      }
    }
  }
}
