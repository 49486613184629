<script>
import ItemProperties from './item-properties.vue'
import ItemPropertiesMixin from './item-properties.js'

export default {
  mixins: [
    ItemPropertiesMixin
  ],

  components: {
    'sc-item-properties': ItemProperties
  }
}

</script>

<template>
  <sc-item-properties :renderer="renderer" :items="items" @action="executeAction">
  </sc-item-properties>
</template>

<style lang="scss" scoped>
</style>
