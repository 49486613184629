import items from '../../assets/menu.json'

/**
 * Application main menu
 */
export function createState () {
  return {
    // All menu items
    items,

    // Menu with items applicable to the current user
    menu: []
  }
}

export const state = createState()

export const getters = {
  /**
   * menu
   */
  menu: state =>
    state.menu,

  /**
   * Returns a menu item with the specified name
   */
  getMenuItem: state =>
    name => findMenuItem(state.menu, item => item.name === name),

  /**
   * Returns a menu item associated with the specified view
   */
  getMenuItemByView: state =>
    view => findMenuItem(state.menu, item => item.view === view),

  /**
   * Determines menu item title
   */
  menuItemTitle: () =>
    item => item.title || '<Not Specified>'
}

/**
 * Recursively traverses the menu and calls the specified handler on every item
 * If handler returns true, traversing will be stopped and the
 * function will return the item on which processing has stopped
 * @param items Menu items
 * @param handler Menu item handler
 */
export function traverseMenu (items, handler) {
  if (items) {
    let found
    if (items) {
      for (const item of items) {
        const result = handler(item)
        if (result) {
          found = item
        } else {
          found = traverseMenu(item.items, handler)
        }
        if (found) {
          break
        }
      }
    }
    return found
  }
}

/**
 * Finds a menu item by specified predicate
 * @param menu Menu with items
 * @param predicate Menu item predicate
 */
export function findMenuItem (menu, predicate) {
  return traverseMenu(menu, item => predicate(item))
}
