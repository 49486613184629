import { Log } from '@stellacontrol/utilities'
import { useRouter, ClientRoutes as clientRoutes } from './router'
import { useStore } from './store'
import { useFilters } from './filters'
import { useDirectives } from './directives'
import { useComponents } from './components'
import { useViews } from './views'
import { useDialogs } from './dialogs'
import { registerStoreModules } from './store'

/**
 * Wires up all custom components, views and other plugins
 * into the specified Vue application
 * @param pkg Package metadata
 * @param application Vue application instance
 * @param stores State stores
 * @param routes UI routes
 * @param views UI views
 * @param use Dictionary of plugins to install with Vue,
 * used to register applet-specific components and services.
 * These can be either objects or functions:
 * - if object, it's assumed to have `install` function as required by `Vue.use()`
 * - if function, it is called with Vue application instance as argument
 * @param {Boolean} isApplet Indicates that we're running single applet instead of the entire application
 */
export async function useClient ({ pkg, application, stores, routes: applicationRoutes, views: applicationViews, use, isApplet }) {
  if (!window.Quasar) throw new Error('Quasar is not available')
  if (!pkg) throw new Error('Package metadata is required')
  if (!application) throw new Error('Vue application instance is required')
  if (!stores) throw new Error('Application state stores are required')
  if (!applicationRoutes) throw new Error('Application routes are required')
  if (!applicationViews) throw new Error('Application views are required')

  application.use(window.Quasar)
  useFilters(application)
  useDirectives(application)
  useComponents(application)
  useDialogs(application)
  useViews(application)

  const { store } = useStore(application, stores)

  const views = [...applicationViews]
  const routes = [...applicationRoutes, ...clientRoutes]

  for (const [name, plugin] of Object.entries(use || {})) {
    if (typeof plugin === 'function') {
      // Applet plugin
      const {
        name: pluginName,
        version: pluginVersion,
        routes: pluginRoutes = [],
        views: pluginViews = [],
        stores: pluginStores = {}
      } = await plugin(application, null, store) || {}

      // Aggregate routes introduced by plugin, avoid duplicates
      for (const route of pluginRoutes) {
        if (!routes.find(r => r.name === route.name)) {
          routes.push(route)
        }
      }

      // Aggregate views introduced by plugin, avoid duplicates
      for (const view of pluginViews) {
        if (!views.find(v => v.name === view.name)) {
          views.push(view)
        }
      }

      registerStoreModules(pluginStores, store)
      Log.bullet(`Plugin ${pluginName || name}${pluginVersion ? ` v.${pluginVersion}` : ''} loaded`)

    } else if (typeof plugin.install === 'function') {
      // Generic Vue plugin
      application.use(plugin)
      Log.bullet(`Plugin ${name} loaded`)

    } else {
      // Unknown thing, throw
      throw new Error(`Vue plugin [${name}] does not implement install() method`, plugin)
    }
  }

  const { router } = useRouter(application, routes, views)
  await store.dispatch('setApplication', { ...pkg, isApplet })
  await store.dispatch('initializeRouter', { router, routes })
  await store.dispatch('initializeViews', { views })

  return { application, router, store }
}
