import { Assignable } from '../common/assignable'
import { Wallet } from './wallet'
import { WalletTransactionType } from './wallet-transaction-type'
import { User } from '../organization/organization-entities'

/**
 * Represents a wallet transaction
 */
export class WalletTransaction extends Assignable {
  constructor (data = {}) {
    super()
    this.assign({ ...data })
  }

  normalize () {
    super.normalize()
    this.user = this.cast(this.user, User)
    this.before = this.cast(this.before, Wallet)
    this.after = this.cast(this.after, Wallet)
    if (this.before) {
      this.before.organization = undefined
    }
    if (this.after) {
      this.after.organization = undefined
    }
  }

  /**
   * User which performed the transaction
   * @type {User}
   */
  user

  /**
   * Wallet before the transaction
   * @type {Wallet}
   */
  before

  /**
   * Wallet after the transaction
   * @type {Wallet}
   */
  after

  /**
   * Transaction type
   * @type {WalletTransactionType}
   */
  type

  /**
   * Transaction amount
   * @type {Number}
   */
  amount

  /**
   * Transaction message
   * @type {String}
   */
  message

  /**
   * Creates a new credit transaction to the specified amount
   * @param {Wallet} wallet Wallet
   * @param {Number} amount Amount to credit
   * @param {String} message Additional message
   * @returns {WalletTransaction}
   */
  static Credit (wallet, amount, message) {
    if (!wallet) throw new Error('Wallet is required')
    if (!(amount >= 0)) throw new Error('Amount is required')

    return new WalletTransaction({
      type: WalletTransactionType.Credit,
      amount,
      message,
      before: wallet,
      after: new Wallet({
        ...wallet,
        balance: wallet.balance + amount
      })
    })
  }

  /**
   * Creates a new debit transaction to the specified amount
   * @param {Wallet} wallet Wallet
   * @param {Number} amount Amount to debit
   * @param {String} message Additional message
   * @returns {WalletTransaction}
   */
  static Debit (wallet, amount, message) {
    if (!wallet) throw new Error('Wallet is required')
    if (!(amount >= 0)) throw new Error('Amount is required')
    if (wallet.balance < amount) throw new Error('Cannot create debit transaction, insufficient balance')

    return new WalletTransaction({
      type: WalletTransactionType.Debit,
      amount,
      message,
      before: wallet,
      after: new Wallet({
        ...wallet,
        balance: wallet.balance - amount
      })
    })
  }
}
