<script>
/**
 * Wrapper around Quasar q-tab-panels,
 * with improved handling of keep-alive behaviour.
 *
 * Default component will keep tab panels alive indefinitely.
 * They will continue being updated, even if view where they're present
 * has been left, and even when user logs out.
 * This brings performance and memory use penalty.
 * It can also lead to mysterious exceptions, when data is no longer valid after log out.
 *
 * The improved component resets the list of cached children on creation / destruction,
 * so that the cache is only valid while parent component is there.
 * This happens simply by virtue of passing tabs with data bindings.
 * The initial list is empty, and it only gets populated when parent loads the tabs.
 * This effectively resets the cache.
 *
 * Additionally, the component provides common sanitized CSS styling,
 * ensuring that tab panels gracefully fill their parent container,
 * with eventual overflow.
 */
export default {
  props: {
    // Selected tab passed as v-model
    modelValue: {
      default: ''
    },

    // List of valid tab names
    tabs: {
      default: []
    },

    // Keeps tab panels alive between navigations
    keepAlive: {
      default: true
    }
  }
}
</script>

<template>
  <div class="tab-panels">
    <q-tab-panels v-bind="{ ...$props, ...$attrs }"
      :model-value="modelValue"
      :keep-alive="keepAlive"
      :keep-alive-include="tabs">
      <slot>
      </slot>
    </q-tab-panels>
  </div>
</template>

<style lang='scss' scoped>
.tab-panels {
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;

  .q-tab-panels {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .q-tab-panels :deep(.q-panel) {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .q-tab-panels :deep(.q-tab-panel) {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}
</style>
