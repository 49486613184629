<script>
export default {
  props: {
    /**
     * Tooltip text
     */
    text: {
      type: String
    },
    /**
     * CSS class applied to tooltip container
     */
    tooltipClass: {
      type: Object
    },
    /**
     * CSS class applied to tooltip content
     */
    contentClass: {
      type: Object
    },
    /**
     * If true, text won't wrap
     */
    nowrap: {
      type: Boolean
    },
    /**
     * Maximal width of the tooltip,
     * causing text to wrap
     */
    maxWidth: {
      type: String,
      default: '350px'
    },
    /**
     * Maximal height of the tooltip,
     * causing text to scroll
     */
    maxHeight: {
      type: String,
      default: '350px'
    }
  },

  computed: {
    // Indicates whether tooltip has assigned text to display,
    // either as `text` property or inside default slot
    hasText () {
      const { text, $slots: { default: slot } = {} } = this
      if (text) return true
      if (slot) {
        const el = slot()
        return el && el[0] && el[0].children && (el[0].children[0] || '').length > 0
      }
      return false
    },

    // CSS classes of the tooltip container
    tooltipContainerClass () {
      const { tooltipClass = { }, nowrap } = this
      const result = {
        nowrap,
        ...tooltipClass
      }
      return result
    },

    // CSS classes of the tooltip content
    tooltipContentClass () {
      const { contentClass = { }, nowrap } = this
      const result = {
        'bg-grey-8': true,
        'text-white': true,
        'text-body2': true,
        nowrap,
        ...contentClass
      }
      return result
    }
  },

  methods: {
    show () {
      this.$refs.tooltip.show()
    },

    hide () {
      this.$refs.tooltip.hide()
    },

    onshow () {
      this.$emit('show')
    },

    onhide () {
      this.$emit('hide')
    },

    beforeShow () {
      this.$emit('before-show')
    },

    beforeHide () {
      this.$emit('before-hide')
    }
  }
}
</script>

<template>
  <q-tooltip ref="tooltip" v-if="hasText" v-bind="{ ...$props, ...$attrs }" transition-show="fade"
    transition-hide="fade" :max-width="maxWidth" :max-height="maxHeight"
    :class="tooltipContainerClass" :contentClass="tooltipContentClass" @before-show="beforeShow()"
    @before-hide="beforeHide()" @show="onshow()" @hide="onhide()">
    <slot>
      <div class="tooltip" :class="{ nowrap }" v-html="text"></div>
    </slot>
  </q-tooltip>
</template>

<style lang="scss">
.q-tooltip {
  font-size: 14px !important;
  &.nowrap {
    white-space: nowrap;
    flex-wrap: nowrap;
    width: auto !important;
  }

  ul,
  ol {
    margin: 4px 4px 4px 12px;
    padding: 0;
  }

  li {
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.tooltip {
  &.nowrap {
    white-space: nowrap;
    flex-wrap: nowrap;
    width: auto !important;
  }
}

/*
  On screens below HD resolution tooltips are shown on top of the screen
  and are more pronounced for better readability
 */
@media screen and (max-width: 1365px) {
  .q-tooltip {
    position: fixed !important;
    z-index: 99999 !important;
    top: 8px !important;
    left: 8px !important;
    right: 8px !important;
    max-width: 100% !important;
    box-shadow: 0 4px 12px #1a237e !important;
    background-color: #3f51b5 !important;
    color: white !important;
    font-size: 16px !important;
    padding: 16px !important;
  }
}
</style>