/**
 * Help topics
 */
export const HelpTopic = {
  Videos: 'videos',
  FAQ: 'faq',
  Pricing: 'pricing',
  TermsAndConditions: 'terms-and-conditions',
  FirmwareHistory: 'firmware-history'
}

/**
 * Help topic descriptions
 */
export const HelpTopicDescription = {
  [HelpTopic.Videos]: 'Help Videos',
  [HelpTopic.FAQ]: 'Frequently Asked Questions',
  [HelpTopic.Pricing]: 'Service Pricing',
  [HelpTopic.TermsAndConditions]: 'Terms and Conditions',
  [HelpTopic.FirmwareHistory]: 'Firmware Change Log'
}

/**
 * Checks whether the specified help topic is known
 * @param {String} topic
 * @returns {Boolean}
 */
export function isKnownHelpTopic (topic) {
  return Object.values(HelpTopic).includes(topic)
}
