import { getPlaceIcon, PlaceIcons } from '@stellacontrol/model'
import { formatDate } from '@stellacontrol/utilities'

/**
 * Organization places columns
 */
const organizationPlacesColumns = [
  { name: 'icon', label: 'Type', field: 'placeType', format: value => ({ icon: getPlaceIcon(value, 'place') }), sortable: true, align: 'left' },
  { name: 'name', label: 'Name', field: 'name', sortable: true, align: 'left' },
  { name: 'deviceCount', label: 'Devices', field: 'deviceCount', format: value => value || '0', sortable: true, align: 'left', style: 'width: 120px' },
  { name: 'city', label: 'City', field: 'city', sortable: true, align: 'left', style: 'width: 150px' },
  { name: 'address', label: 'Address', field: 'address', sortable: true, align: 'left' },
  { name: 'created', label: 'Created On', field: 'createdAt', sortable: true, align: 'left', format: value => formatDate(value), style: 'width: 120px', desktopOnly: true },
  { name: 'updated', label: 'Updated On', field: 'updatedAt', sortable: true, align: 'left', format: value => formatDate(value), style: 'width: 120px', desktopOnly: true },
  { name: 'actions', label: 'Actions', align: 'right', sortable: false }
]

/**
 * Place-related list columns
 */
const placeDevicesColumns = [
  { name: 'serialNumber', label: 'Serial Number', field: 'serialNumber', sortable: true, align: 'left', style: 'width: 150px' },
  { name: 'acronym', label: 'Type', field: 'acronym', sortable: true, align: 'left', style: 'width: 100px' },
  { name: 'status', label: 'Status', field: 'status', sortable: true, align: 'left', format: () => '-' },
  { name: 'location', label: 'Device Location', field: 'location', sortable: true, align: 'left', format: value => value || '-' },
  { name: 'customLocation', label: 'Custom Location', field: 'customLocation', sortable: true, align: 'left', format: value => value || '-' },
  { name: 'model', label: 'Model', field: 'model', sortable: true, align: 'left' },
  { name: 'firmwareVersion', label: 'Firmware', field: 'firmwareVersion', sortable: true, align: 'left' }
]

export function createState () {
  return {
    /**
     * Organization places columns
     */
    organizationPlacesColumns,
    /**
     * Place devices columns
     */
    placeDevicesColumns,
    /**
     * Organization place icons, representing its type
     */
    placeIcons: PlaceIcons
  }
}

export const state = createState()

export const getters = {
  /**
   * Organization places list columns
   */
  organizationPlacesColumns: state =>
    state.organizationPlacesColumns,

  /**
   * Place devices list columns
   */
  placeDevicesColumns: state =>
    state.placeDevicesColumns
}

