<script>
import { mapState, mapActions } from 'vuex'

export default {
  computed: {
    ...mapState({
      announcements: state => state.announcements.newAnnouncements
    }),

    // Unacknowledged announcements
    newAnnouncements () {
      return this.announcements?.filter(a => !a.isAcknowledged) || []
    },

    // Indicates whether there are any unacknowledged announcements
    hasNewAnnouncements () {
      return this.newAnnouncements.length > 0
    }
  },

  methods: {
    ...mapActions([
      'hideAnnouncementPopup'
    ])
  }
}
</script>

<template>
  <main>
    <sc-announcement-list
      :announcements="newAnnouncements"
      :allow-acknowledge="true"
      :dense="true"
      :dark="true">
    </sc-announcement-list>
  </main>
</template>

<style lang='scss' scoped>
main {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
</style>
