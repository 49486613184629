import BuildingDashboardView from './building-dashboard.vue'
import { dispatch } from '@stellacontrol/client-utilities'
import { resolve } from './building-dashboard.resolve'

export const Routes = [
  // Building view
  {
    name: 'building-dashboard',
    path: '/organization/:organizationId/building/:id/dashboard',
    component: BuildingDashboardView,

    async beforeEnter (to, from, next) {
      const { redirectTo } = await resolve({ from, to }) || {}
      if (redirectTo) {
        next(redirectTo)
      } else {
        dispatch('storeBuildingsRoute', { route: to })
        next()
      }
    }
  }
]
