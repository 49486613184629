<script>
import { getDurationString } from '@stellacontrol/utilities'
import ParameterEditor from './parameter-editor'

export default {
  mixins: [
    ParameterEditor
  ],

  data () {
    return {
    }
  },

  computed: {
    // Items for value selector
    items () {
      const { values } = this
      return values.map((value, index) => ({
        label: this.labels[index],
        value
      }))
    }
  },

  methods: {
    getDurationString
  }
}
</script>

<template>
  <div class="parameter-editor">

    <sc-parameter-value
      label="Unattended Scan Interval"
      :reported="reported"
      :custom="custom"
      :format="value => value === 0 ? 'Off' : (getDurationString(value * 600) || 'Unknown')">
    </sc-parameter-value>

    <sc-parameter-selector
      :items="items"
      label="Unattended Scan Interval"
      :allow-clear="isCustomized"
      @select="value => change(value)"
      @clear="clear()">
    </sc-parameter-selector>

  </div>
</template>
