import { Log } from '@stellacontrol/utilities'

/**
 * Client state mutations
 */
export const mutations = {
  /**
   * Initializes the client with the specified context
   * @param state Current state
   * @param router Vue router instance
   */
  initializeRouter (state, { router }) {
    state.router = router
    state.routes = router.getRoutes()
  },

  /**
   * Stores the previous route in case
   * it's needed
   */
  storePreviousRoute (state, { route } = {}) {
    state.previousRoute = route
  },

  /**
   * Indicates that route navigation has started
   * @param from Start route
   * @param to Target route
   * @param message Optional message to log
   */
  navigationStarted (state, { from, to, message } = {}) {
    state.isNavigating = true
    state.from = from
    state.to = to
    state.isNavigatingToDifferentRoute = to.name !== from.name
    state.navigationStartedAt = new Date()
    state.navigationFinishedAt = null
    if (message) {
      Log.debug('Navigation started', message)
    }
  },

  /**
   * Indicates that route navigation has completed
   * @param from Start route
   * @param to Target route
   * @param message Optional message to log
   */
  navigationEnded (state, { from, to, message } = {}) {
    state.isNavigating = false
    state.isNavigatingToDifferentRoute = false
    state.from = from
    state.to = to
    state.navigationFinishedAt = new Date()
    if (message) {
      Log.debug('Navigation ended', message)
    }
  },

  /**
   * Indicates that ongoing route navigation has been cancelled,
   * usually due to error
   */
  navigationCancelled (state) {
    state.isNavigating = false
    state.isNavigatingToDifferentRoute = false
    state.navigationFinishedAt = new Date()
  },

  /**
   * Assigns data to the route.
   * @param from Source route
   * @param to Target route
   * @param title Application title associated with the route. Used to differentiate window title per route.
   * @param view View associated with the route
   * @param data Data to store on the route
   */
  setRouteData (state, { from, to, title, view, data }) {
    if (from && to) {
      const { name } = to
      state.routeData[name] = {
        name,
        title,
        view,
        from,
        to,
        data
      }
    }
  }
}
