<script>
import { mapActions } from 'vuex'
import { FormMixin } from '@stellacontrol/client-utilities'
import { Countries, Languages } from '@stellacontrol/model'
import { Secure } from '../../components'

export default {
  mixins: [
    FormMixin,
    Secure
  ],

  data () {
    return {
      // Countries to select from
      countries: [],
      // Languages to select from
      languages: [],
      // Default values for preferences
      preferences: {
      },
      // Device name preferences to choose from
      deviceNamePreferences: [
        { value: false, label: 'Device Serial Number' },
        { value: true, label: 'Device Name if available, otherwise Serial Number' }
      ]
    }
  },

  computed: {
    // Current organization's country code
    countryCode () {
      const { currentOrganization } = this
      return currentOrganization.countryCode
    },

    // Current organization's country
    country () {
      const { currentOrganization } = this
      return Countries.All.find(c => c.code === currentOrganization.countryCode)
    },

    // Current organization's language
    languageCode () {
      const { currentOrganization } = this
      return currentOrganization.languageCode
    },

    // Current organization's timezone
    timezone () {
      const { currentOrganization } = this
      return currentOrganization.timezone
    },

    // Checks whether more timezones are available to select from
    hasMultipleTimezones () {
      const { country } = this
      return country?.timezones?.length > 1
    },

    // Start pages allowed to the current organization
    allowedStartPages () {
      const { startPages, canUseAll } = this
      return startPages.filter(page => canUseAll(page.permissions))
    }
  },

  methods: {
    ...mapActions([
      'getOrganizationPreferences',
      'storeOrganizationPreference',
      'setLocale'
    ]),

    // Filter function for filtering options in the country selector
    filterCountries (value = '', update) {
      update(() => {
        if (value === '') {
          this.countries = Countries.All
        } else {
          const text = value.toLowerCase()
          this.countries = Countries.All.filter(o => o.name.toLowerCase().includes(text))
        }
      })
    },

    // Filter function for filtering options in the language selector
    filterLanguages (value = '', update) {
      update(() => {
        if (value === '') {
          this.languages = Languages.Supported
        } else {
          const text = value.toLowerCase()
          this.languages = Languages.Supported.filter(o => o.name.toLowerCase().includes(text))
        }
      })
    },

    async populate () {
      // Countries and languages
      this.countries = Countries.All
      this.languages = Languages.Supported

      // Fetch current preferences, assign defaults for missing preferences
      const currentPreferences = await this.getOrganizationPreferences()
      const defaultPreferences = {
        ...this.preferences
      }

      for (const key of Object.keys(this.preferences)) {
        const currentValue = currentPreferences[key]
        this.preferences[key] = currentValue == null
          ? defaultPreferences[key]
          : currentValue
      }

      // If country is selected but timezone is not specified yet,
      // assume default timezone
      if (this.country && !this.currentOrganization.timezone) {
        this.setLocale({
          organization: this.currentOrganization,
          timezone: this.country.getDefaultTimezone()
        })
      }
    },

    // Saves the specified organization preference
    async save (name, value) {
      this.preferences[name] = value
      await this.storeOrganizationPreference({ name, value })
    },

    // Saves changes to organization's locale
    async saveLocale ({ countryCode, languageCode, timezone } = {}) {
      const { currentOrganization: organization } = this
      if (countryCode || languageCode || timezone) {
        // Reset timezone if country changed
        const country = this.countries.find(c => c.code === countryCode)
        if (country) {
          if (!country.isValidTimezone(organization.timezone)) {
            timezone = country.getDefaultTimezone()
          }
        }

        await this.setLocale({
          organization,
          countryCode,
          languageCode,
          timezone
        })
      }
    }
  },

  async created () {
    await this.populate()
  }
}

</script>

<template>
  <main class="preferences">
    <section>
      <q-banner class="bg-indigo-1">
        <q-icon name="web" color="indigo-9" size="28px" class="q-mr-sm" />
        <span class="text-subtitle1">
          General Preferences
        </span>
      </q-banner>

      <div class="q-pl-md q-pt-md row ui-preferences">
        <table class="col-6">
          <tr>
            <td class="label">
              <label>Your Country</label>
            </td>
            <td>
              <q-select square dense outlined :model-value="countryCode" :options="countries"
                @update:model-value="value => saveLocale({ countryCode: value })" emit-value
                map-options option-value="code" option-label="name" use-input debounce="500"
                fill-input hide-selected @filter="filterCountries">
              </q-select>
            </td>
          </tr>
          <tr>
            <td class="label">
              <label>Preferred Language</label>
            </td>
            <td>
              <q-select square dense outlined :model-value="languageCode" :options="languages"
                @update:model-value="value => saveLocale({ languageCode: value })" emit-value
                map-options option-value="code" option-label="name" use-input debounce="500"
                fill-input hide-selected @filter="filterLanguages">
              </q-select>
            </td>
          </tr>
          <tr>
            <td class="label">
              <label>Timezone</label>
            </td>
            <td>
              <q-select :readonly="!hasMultipleTimezones" square dense outlined v-model="timezone"
                :options="country?.timezones"
                @update:model-value="value => saveLocale({ timezone: value })">
              </q-select>
            </td>
          </tr>
        </table>
      </div>

    </section>
  </main>
</template>

<style lang='scss'>
.preferences {
  max-width: 1200px;

  >section {
    margin-bottom: 24px;
  }

  .ui-preferences {
    table {
      td.label {
        width: 180px;
        max-width: 180px;
      }
    }
  }
}
</style>
