import { traverseMenu, findMenuItem } from './menu.state'

export const mutations = {
  /**
   * Initializes the menu with items applicable to the current user
   */
  initializeMenu (state, { menu }) {
    state.menu = menu || []
    let menuItemId = 1
    traverseMenu(state.menu, item => {
      item.id = menuItemId++
      item.isSelected = false
    })
  },

  /**
   * Clears the menu
   */
  clearMenu (state) {
    state.menu = []
  },

  /**
   * Marks the specified menu item as selected
   * @param state UI state
   * @param id Identifier of a menu item to select
   */
  selectMenuItem (state, { item: { id } = {} } = {}) {
    const { menu } = state

    // Unselect other items
    traverseMenu(menu, item => {
      item.isSelected = false
    })

    // Select the specified item
    const item = findMenuItem(menu, item => item.id === id)
    if (item) {
      item.isSelected = true

      // Expand the parent if collapsed
      traverseMenu(menu, parent => {
        if ((parent.items || []).find(i => i.id === id)) {
          if (parent.isCollapsed) {
            parent.isCollapsed = false
          }
          return true
        }
      })
    }
  },

  /**
   * Toggles the specified menu item
   * @param state UI state
   * @param id Identifier of a menu item to toggle
   */
  toggleMenuItem (state, { item: { id } }) {
    const { menu } = state
    const item = findMenuItem(menu, item => item.id === id)
    if (item) {
      item.isCollapsed = !item.isCollapsed
    }
  }
}
