<script>
import { mapActions, mapGetters } from 'vuex'
import { DeviceLinkType } from '@stellacontrol/model'
import DeviceAction from './device-action.vue'
import { DeviceActionMixin } from './device-action-mixin'

export default {
  mixins: [
    DeviceActionMixin
  ],

  components: {
    'sc-device-action': DeviceAction
  },

  data () {
    return {
      // Organization to share with
      organizationId: null,
      // Additional notes
      notes: null,
      // List of delegates to select from
      delegates: []
    }
  },

  computed: {
    ...mapGetters([
      'organizationHierarchy'
    ]),

    // Parents of the selected device's owner
    parentOrganizations () {
      const { owner, organizationHierarchy } = this
      return owner ? organizationHierarchy.getParentsOf(owner.id) : []
    },

    // All organizations which the devices can be shared with.
    // We ignore:
    // - super-organizations as they can already access anything
    // - organizations which own the devices
    // - parent organizations of the owner, as they can already see the device
    // - organizations with which the devices are already shared
    allDelegates () {
      const { devices, parentOrganizations } = this
      return this.organizations.filter(o => {
        if (o.isSuperOrganization) return false
        if (devices.every(device => device.getLinkWith(o, DeviceLinkType.Delegate))) return false
        if (devices.every(device => device.getLinkWith(o, DeviceLinkType.Owner))) return false
        if (parentOrganizations.find(p => p.id === o.id)) return false
        return true
      })
    },

    // Selected organization
    organization () {
      return this.allDelegates.find(o => o.id === this.organizationId)
    }
  },

  methods: {
    ...mapActions([
      'linkDevices'
    ]),

    async share () {
      if (await this.validate()) {
        const { devices, organization, notes } = this
        await this.linkDevices({ devices, organization, notes })
        this.executed()
      }
    },

    // Shares the devices with current organization if allowed
    selectCurrentOrganization () {
      const { devices, allDelegates, currentOrganization } = this
      const alreadyShared = devices.every(device => device.getLinkWith(currentOrganization, DeviceLinkType.Delegate))
      if (allDelegates.find(o => o.id === currentOrganization.id) && !alreadyShared) {
        this.organizationId = currentOrganization.id
      }
    }
  },

  created () {
    this.delegates = this.allDelegates
    this.selectCurrentOrganization()
  }
}
</script>

<template>
  <sc-device-action
    :action="action"
    :devices="devices"
    execute-label="Share"
    @closing="closing"
    @close="close"
    @execute="share">

    <q-form ref="form" autofocus class="q-mt-md q-gutter-sm" @submit.prevent>
      <div>
        <label class="text-body2 text-grey-9">
          The selected organization will be able to control {{ devicesLabel }},
          even if it does not own {{ devicesLabel }}. Use with care!
        </label>
        <br>
        <label class="text-body2 text-grey-9">
          To see who can already control {{ devicesLabel }}, see <b>ACCESS</b> tab above.
        </label>
      </div>

      <sc-organization-selector class="q-mt-md"
        v-model="organizationId"
        :label="`Select a company to share ${devicesLabel} with`"
        :items="delegates"
        :rules="[
          rules.required('Organization is required')
        ]">
      </sc-organization-selector>

      <q-input square outlined
        label="Notes"
        v-model="notes"
        type="textarea"
        autogrow
        >
      </q-input>

    </q-form>

  </sc-device-action>

</template>

<style lang="scss" scoped>
</style>
