<script>
import { mapState, mapActions } from 'vuex'
import { countString } from '@stellacontrol/utilities'
import { ViewMixin, ListAction, ActionItem } from '@stellacontrol/client-utilities'
import { AnnouncementCategories, AnnouncementChannels } from '@stellacontrol/model'
import { Secure } from '@stellacontrol/security-ui'

const name = 'announcements'

export default {
  mixins: [
    ViewMixin,
    Secure
  ],

  data () {
    return {
      name,
      AnnouncementCategories,
      AnnouncementChannels
    }
  },

  computed: {
    ...mapState({
      announcements: state => state.announcements.announcements,
      myAnnouncements: state => state.announcements.myAnnouncements || [],
      columns: state => state.announcements.announcementsAdministrationColumns || [],
      columnClass: state => state.announcements.announcementColumnClass || [],
    }),

    // Returns true if current user is allowed to edit the announcements
    canEditAnnouncements () {
      return this.canUse('announcements-edit')
    },

    // Indicates whether there are any announcements to show
    hasAnnouncements () {
      return this.canEditAnnouncements
        ? this.announcements.length > 0
        : this.myAnnouncements.length > 0
    },

    // Recipients text in recipient column
    recipientsLabel () {
      return announcement => announcement.hasRecipients
        ? countString(announcement.recipients, 'recipient')
        : 'No recipients'
    },

    // Recipients tooltip in recipient column
    recipientsTooltip () {
      return announcement => announcement.hasRecipients
        ? announcement.recipients
          .slice(0, 10)
          .map(recipient => recipient.label)
          .join('<br>')
          .concat(announcement.recipientCount > 10 ? `<br>... and ${announcement.recipientCount - 10} more` : '')
        : null
    },

    // Grid actions
    announcementActions () {
      if (this.canEditAnnouncements) {
        return [
          new ActionItem({
            ...ListAction.Edit
          }),
          new ActionItem({
            name: 'publish',
            label: announcement => announcement.publishedAt ? 'Publish again' : 'Publish',
            icon: 'double_arrow',
            color: 'green-6',
            isVisible: announcement => announcement.canPublish
          }),
          {
            name: 'copy',
            icon: 'content_copy',
            label: 'Copy',
            color: 'green-9'
          },
          new ActionItem({
            ...ListAction.Delete,
            separator: true,

          })
        ]
      }
    }
  },

  methods: {
    ...mapActions([
      'createAnnouncement',
      'editAnnouncement',
      'publishAnnouncement',
      'deleteAnnouncement',
      'copyAnnouncement'
    ]),

    // Executes the grid action
    async executeAction (announcement, action) {
      switch (action.name) {
        case 'edit':
          await this.editAnnouncement({ announcement })
          break

        case 'delete':
          await this.deleteAnnouncement({ announcement, confirm: true })
          break

        case 'publish':
          await this.publishAnnouncement({ announcement, confirm: true })
          break

        case 'copy':
          await this.copyAnnouncement({ announcement })
          break

        default:
          throw new Error(`Unhandled action ${action.name}`)
      }
    }
  },

  created () {
  }
}
</script>

<template>
  <sc-view :name="name">

    <template #toolbar>
      <q-btn flat unelevated icon="add" label="Add Announcement"
        class="button-announcement-add primary" v-if="canEditAnnouncements"
        @click="createAnnouncement()"></q-btn>
    </template>

    <template #header>
      <sc-list-filter v-if="hasAnnouncements && canEditAnnouncements" :name="name"></sc-list-filter>
    </template>

    <div class="q-ma-md" v-if="!hasAnnouncements">
      <div class="q-mb-md" v-if="canEditAnnouncements">
        Here you can create and publish announcements to customers.
      </div>
      <div class="q-mb-md" v-else>
        There are no announcements yet.
      </div>
    </div>

    <sc-list v-else-if="canEditAnnouncements" :name="name" :columns="columns" :items="announcements"
      row-key="id" :actions="announcementActions" @action="executeAction"
      :cellClassMethod="columnClass">

      <template v-slot:body-cell-category="props">
        <q-td :props="props" style="overflow: hidden; text-overflow: ellipsis;">
          <q-icon :name="props.row.icon" :color="props.row.isNoLongerApplicableOn() ? 'grey-5' : props.row.color" size="sm">
            <sc-tooltip :text="props.row.categoryDescription"></sc-tooltip>
          </q-icon>
        </q-td>
      </template>

      <template v-slot:body-cell-title="props">
        <q-td :props="props" style="overflow: hidden; text-overflow: ellipsis;">
          <div class="announcement-title">
            <router-link class="item-link"
              :to="{ name: 'announcement', params: { id: props.row.id } }">
              {{ props.row.title }}
            </router-link>
          </div>
        </q-td>
      </template>

      <template v-slot:body-cell-createdAt="props">
        <q-td :props="props">
          {{ props.value }}
        </q-td>
      </template>

      <template v-slot:body-cell-channels="props">
        <q-td :props="props" style="overflow: hidden; text-overflow: ellipsis;">
          <q-icon v-for="channel in props.row.channels || []"
            :name="AnnouncementChannels[channel].icon" size="sm" class="q-ml-xs"
            :color="props.row.isNoLongerApplicableOn() ? 'grey-5' : 'indigo-5'">
          </q-icon>
        </q-td>
      </template>

      <template v-slot:body-cell-recipients="props">
        <q-td :props="props">
          <span>
            {{ recipientsLabel(props.row) }}
            <sc-tooltip v-if="props.row.hasRecipients" :text="recipientsTooltip(props.row)">
            </sc-tooltip>
          </span>
        </q-td>
      </template>

      <template v-slot:body-cell-publishedAt="props">
        <q-td :props="props">
          {{ props.value }}
        </q-td>
      </template>

      <template v-slot:body-cell-shown="props">
        <q-td :props="props">
          {{ props.value }}
        </q-td>
      </template>

    </sc-list>

    <div class="announcements" v-else>
      <sc-announcement-list :announcements="myAnnouncements" :allow-acknowledge="true"
        :separator="true" :showDate="true">
      </sc-announcement-list>
    </div>

  </sc-view>
</template>

<style lang="scss" scoped>
.announcements {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin: 20px;
  border: solid #0000001f 1px;
}

.announcement-title {
  max-width: 600px;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>
