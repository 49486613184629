/**
 * Determine if running in NodeJS
 */
export const isNodeJS = (typeof process !== 'undefined') && (process?.release?.name === 'node')

/**
 * Determine if running in the browser
 */
export const isBrowser = (typeof document !== 'undefined')

/**
 * Determine if running in the mobile application (React Native)
 */
export const isSmallScreen = (typeof navigator !== 'undefined' && navigator['product'] === 'ReactNative')
