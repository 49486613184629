import { createState } from './announcements.state'

export const mutations = {
  /**
   * Stores all announcements available to the current user
   * @param {Array[Announcement]} announcements Announcements to store
   */
  storeAnnouncements (state, { announcements = [] } = {}) {
    state.announcements = announcements
  },

  /**
   * Stores new announcements to show
   * @param {Array[Announcement]} announcements Announcements to show when user logs in
   */
  storeNewAnnouncements (state, { announcements = [] } = {}) {
    state.newAnnouncements = announcements
  },

  /**
   * Stores all announcements available to the current user
   * @param {Array[Announcement]} announcements All announcements available to the current user
   */
  storeMyAnnouncements (state, { announcements = [] } = {}) {
    state.myAnnouncements = announcements
  },

  /**
   * Stores details of the specified announcement
   * @param {Announcement} announcement Announcement to store
   */
  storeAnnouncement (state, { announcement } = {}) {
    if (announcement) {
      const { announcements } = state
      const index = announcements.findIndex(a => a.id === announcement.id)

      if (index === -1) {
        announcements.push(announcement)
      } else {
        announcements[index] = announcement
      }
    }
  },


  /**
   * Removes the specified announcement
   * @param {Announcement} announcement Announcement to remove
   */
  removeAnnouncement (state, { announcement: { id } = {} } = {}) {
    if (id) {
      const { announcements } = state
      const index = announcements.findIndex(a => a.id === id)

      if (index > -1) {
        announcements.splice(index, 1)
      }
    }
  },

  /**
   * Marks the announcement as acknowledged
   * @param {AnnouncementStatus} status Announcement status
   */
  acknowledgeAnnouncement (state, { status } = {}) {
    if (status && status.isAcknowledged) {
      const lists = [state.announcements, state.newAnnouncements, state.myAnnouncements]
      for (const list of lists) {
        const index = list.findIndex(a => a.id === status.id)
        if (index > -1) {
          list[index].acknowledgedAt = status.createdAt
          list[index].acknowledgedBy = status.userId
        }
        if (list === state.newAnnouncements && index > -1) {
          state.newAnnouncements = state.newAnnouncements.filter(a => a.id !== status.id)
        }
      }
    }

    // Hide the announcements popup if all dismissed
    const hasNewAnnouncements = state.newAnnouncements?.find(a => !a.isAcknowledged)
    if (state.isAnnouncementPopupVisible) {
      state.isAnnouncementPopupVisible = hasNewAnnouncements
    }
  },

  /**
   * Shows the popup with new announcements
   */
  showAnnouncementsPopup (state) {
    const hasNewAnnouncements = state.newAnnouncements?.find(a => !a.isAcknowledged)
    state.isAnnouncementPopupVisible = hasNewAnnouncements
  },

  /**
   * Hides the popup with new announcements
   */
  hideAnnouncementsPopup (state) {
    state.isAnnouncementPopupVisible = false
  },

  /**
   * Resets the state to original shape.
   * @description
   * Presence of this mutation is obligatory on stores,
   * to ensure purging of any user-bound data when
   * user logs in and out and in. Otherwise expect
   * unexpected side effects with data of the previously
   * logged in user appearing at places.
   */
  reset (state) {
    Object.assign(state, createState())
  }
}
