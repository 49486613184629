import OrganizationPlaceView from './organization-place.vue'
import { resolveOrganizationPlace } from './organization-place.resolve'

export const Routes = [
  {
    name: 'organization-place',
    path: '/administration/organization/:organizationId/place/:id',
    component: OrganizationPlaceView,

    async beforeEnter (to, from, next) {
      if (await resolveOrganizationPlace({ from, to })) {
        next()
      }
    }
  }
]
