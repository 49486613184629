import { DeviceStatus } from '@stellacontrol/devices'

export function createState (initialState = {}) {
  return {
    /**
     * Clock tick counter
     * @type {Number}
     */
    ticks: 0,

    /**
     * Currently running device status listeners.
     * @type {Dictionary<string, { clock: Clock, listener: PushListener}>} Dictionary of listeners each listening to device status updates of specific devices
     */
    statusListeners: {
    },

    /**
     * Currently running device update listeners.
     * @type {Dictionary<string, { Clock, listener: PushListener}>} Dictionary of listeners each listening to device data updates
     */
    deviceListeners: {
    },

    /**
     * Dictionary of retrieved device states
     * @type {Dictionary<string, DeviceStatus>}
     */
    devices: {
    },

    /**
     * Dictionary of received device alerts
     * @type {Dictionary<string, Array[AlertOccurrence]>}
     */
    alerts: {
    },

    ...initialState
  }
}

export const state = createState()

export const getters = {
  /**
   * Returns true if specified status listener is currently running
   * @param {String} name Listener name
   */
  isReceivingDeviceStatus: state =>
    name => {
      const { listener } = state.statusListeners[name] || {}
      if (listener) {
        return !listener.isSuspended
      }
    },

  /**
   * Returns the details of the specified status listener
   * @param {String} name Listener name
   */
  getStatusListener: state =>
    name => {
      const { listener, clock } = state.statusListeners[name] || {}
      if (listener) {
        return {
          listener,
          clock,

          get isOpen () {
            return listener.isOpen
          },

          get isSuspended () {
            return listener.isSuspended
          },

          get isListening () {
            return listener.isOpen && !listener.isSuspended
          },

          suspend: () => listener.suspend(),

          resume: () => listener.resume()
        }
      } else {
        return {}
      }
    },

  /**
   * Returns settings for the specified process,
   * a structure with { refreshInterval, fastSamplingSpeed, fastSamplingDuration }
   */
  getStatusWatchSettings: (state, getters) =>
    (name, profile = 'liveStatus') => {
      const view = name.startsWith('peek')
        ? getters.configuration.client.views['peek']
        : getters.configuration.client.views[name]
      if (!view) throw new Error(`Device status watch settings for view [${name}] not found in client.views configuration`)
      const settings = view[profile]
      if (!settings) throw new Error(`Device status watch profile [${profile}] for view [${name}] not found in client.views configuration`)
      return settings
    },

  /**
   * Returns status of the specified device,
   * including status of device parts for multi-board devices
   */
  getDeviceStatus: (state) =>
    (device) => {
      if (device) {
        let status = state.devices[device.serialNumber]
        if (!status && device.isMultiDevice) {
          status = new DeviceStatus(device.serialNumber, {})
          status.parts = device.parts.map(part => state.devices[part.serialNumber]).filter(ps => ps)
          state.devices[device.serialNumber] = status
        }
        return status
      }
    },

  /**
   * Returns the time of the most recent status of the specified device
   */
  getDeviceStatusTime: (state) =>
    (device) => state.devices[device?.serialNumber]?.timings?.receivedAt
}
