<script>
import { mapGetters, mapActions } from 'vuex'
import { capitalize, isValidUrl, formatDate } from '@stellacontrol/utilities'
import { ViewMixin, EditorMixin, FormMixin, Confirmation } from '@stellacontrol/client-utilities'
import { Secure } from '@stellacontrol/security-ui'
import { AnnouncementRecipient, AnnouncementCategories, AnnouncementPriority, AnnouncementPriorities, AnnouncementChannel, AnnouncementChannels, PrincipalType, PrincipalTypeDescription } from '@stellacontrol/model'
import { resolveAnnouncement } from './announcements.resolve'

const name = 'announcement'

export default {
  mixins: [
    ViewMixin,
    EditorMixin,
    FormMixin,
    Secure
  ],

  data () {
    return {
      name,
      AnnouncementCategories,
      AnnouncementChannel,
      PrincipalType,
      // Selected recipient type
      recipientType: null,
      // Currently selected organizations
      selectedOrganizations: [],
      // Currently selected organization profiles
      selectedOrganizationProfiles: []
    }
  },

  computed: {
    ...mapGetters([
      'organizationProfiles',
      'organizationHierarchy',
      'organizations'
    ]),

    // Announcement category details
    category () {
      return AnnouncementCategories[this.data?.category]
    },

    // Announcement categories
    categories () {
      return Object
        .entries(AnnouncementCategories)
        .map(([category, details]) => ({
          name: details.name,
          description: details.description,
          icon: details.icon,
          value: category
        }))
    },

    // Announcement priorities
    priorities () {
      return Object
        .values(AnnouncementPriority)
        .map(priority => ({
          label: AnnouncementPriorities[priority].name,
          value: priority
        }))
    },

    // Announcement channels
    channels () {
      return Object
        .entries(AnnouncementChannels)
        .map(([channel, details]) => ({
          label: details.name,
          description: details.description,
          icon: details.icon,
          value: channel
        }))
    },

    // Selected channels
    selectedChannels () {
      return this.data.channels
    },

    // Selected recipients
    selectedRecipients () {
      return this.data.recipients
    },

    // Recipient types
    recipientTypes () {
      return [
        { value: PrincipalType.OrganizationProfile, label: PrincipalTypeDescription[PrincipalType.OrganizationProfile] },
        { value: PrincipalType.Organization, label: PrincipalTypeDescription[PrincipalType.Organization] }
      ]
    },

    // Custom view title
    title () {
      const { data, category } = this
      return data.isNew
        ? 'New Announcement'
        : (category ? capitalize(category?.name) : 'Announcement')
    },

    // Custom view subtitle
    subtitle () {
      const { data } = this
      return data.title || '-'
    },

    // Text editor toolbar
    textEditorToolbar () {
      return [
        ['bold', 'italic', 'underline'],
        ['left', 'center', 'right', 'justify'],
        ['unordered', 'ordered', 'link']
      ]
    },

    // Text editor commands
    textEditorCommands () {
      return {
        link: {
          tip: 'Add Link',
          icon: 'link',
          label: 'Insert Link',
          handler: () => this.addLink()
        }
      }
    }
  },

  watch: {
    // When recipient type changes, restore previously made selection
    recipientType (value) {
      switch (value) {
        case PrincipalType.Organization:
          this.selectOrganizations(this.selectedOrganizations)
          break

        case PrincipalType.OrganizationProfile:
          this.selectOrganizationProfiles(this.selectedOrganizationProfiles)
          break
      }
    },

    // Selected recipients
    selectedRecipients () {
    },

    // When organization selection changed, update the recipients
    selectedOrganizations (value) {
      this.selectOrganizations(value)
    },

    // When organization profile selection changed, update the recipients
    selectedOrganizationProfiles (value) {
      this.selectOrganizationProfiles(value)
    }
  },

  methods: {
    ...mapActions([
      'saveAnnouncement',
      'publishAnnouncement',
      'editAnnouncement',
      'gotoAnnouncements'
    ]),

    // Populates the form on load
    populate () {
      switch (this.data.recipientType) {
        case PrincipalType.Organization:
          this.selectedOrganizations = this.data.recipientIdentifiers
          this.recipientType = PrincipalType.Organization
          break

        case PrincipalType.OrganizationProfile:
          this.selectedOrganizationProfiles = this.data.recipientIdentifiers
          this.recipientType = PrincipalType.OrganizationProfile
          break

        default:
          this.recipientType = PrincipalType.OrganizationProfile
      }
    },

    // Selects the specified organizations as announcement recipients
    selectOrganizations (identifiers) {
      this.data.recipients = identifiers
        .map(id => this.organizations.find(o => o.id === id))
        .filter(o => o)
        .map(o => AnnouncementRecipient.fromOrganization(o))
    },

    // Selects the specified organization profiles as announcement recipients
    selectOrganizationProfiles (identifiers) {
      this.data.recipients = identifiers
        .map(id => this.organizationProfiles.find(o => o.id === id))
        .filter(o => o)
        .map(o => AnnouncementRecipient.fromOrganizationProfile(o))
    },

    // Saves the announcement, optionally publishes it
    async save (publish) {
      if (await this.validate()) {
        // Confirm re-publishing
        const { data, data: { publishedAt } } = this
        publish = (publishedAt && publish)
          ? await Confirmation.ask({
            title: 'Publish again?',
            ok: 'Yes, save and publish',
            cancel: 'No, just save',
            message: `This announcement was already published on ${formatDate(publishedAt)}. Do you want to send it again to customers?`
          })
          : publish
        const announcement = await this.saveAnnouncement({ announcement: data, publish })
        if (announcement) {
          if (publish) {
            await this.gotoAnnouncements()
          } else {
            await this.editAnnouncement({ announcement })
          }
        }
      }
    },

    // Cancels editing
    async cancel () {
      await this.gotoAnnouncements()
    },

    // Inserts link
    async addLink () {
      const { textEditor } = this.$refs
      if (textEditor) {
        const url = await Confirmation.prompt({ title: 'URL to insert:', initial: 'https://' })
        if (isValidUrl(url)) {
          const link = document.createElement('a')
          link.setAttribute('href', url)
          link.innerHTML = url
          textEditor.runCmd('insertHTML', `<a href="${url}" target="_blank">${url}</a>`)
        }
      }
    }
  },

  // Reload data on navigation to another announcement
  async beforeRouteUpdate (to, from) {
    if (from.params.id !== to.params.id) {
      if (await resolveAnnouncement({ from, to })) {
        await this.populate()
      }
    }
  },

  created () {
    this.populate()
  }
}
</script>

<template>
  <sc-view :name="name" :title="title" :subtitle="subtitle">

    <template #toolbar>
      <div class="row no-wrap q-gutter-sm">
        <q-btn class="success" unelevated @click="save(true)"
          :label="`Save and ${data.publishedAt ? 'Publish Again' : 'Publish'}`"
          :disabled="!data.canPublish">
          <sc-tooltip v-if="!data.canPublish">
            You have to select recipients to be able to publish the announcement
          </sc-tooltip>
          <sc-tooltip v-else>
            The announcement will be saved and marked for delivery.
            <span v-if="data.hasChannel(AnnouncementChannel.Email)">
              E-mail message will be sent immediately.
            </span>
            <span
              v-if="data.hasChannel(AnnouncementChannel.Popup) && data.validFrom || data.validUntil">
              Popup announcements will appear only
              {{ data.validFrom ? ` from ${date(data.validFrom)}` : '' }}
              {{ data.validUntil ? ` until ${date(data.validUntil)}` : '' }}.
            </span>
          </sc-tooltip>
        </q-btn>
        <q-btn label="Save" class="primary" unelevated @click="save(false)">
          <sc-tooltip v-if="!data.publishedAt">
            The announcement will be saved but won't be published yet.
          </sc-tooltip>
        </q-btn>
        <q-btn label="Close" unelevated @click="cancel()"></q-btn>
      </div>
    </template>

    <q-form class="form" ref="form" autofocus>
      <div class="banner">
        <q-banner v-if="data.publishedAt" class="bg-green-4">
          The announcement has been published on {{ date(data.publishedAt) }}.
        </q-banner>
      </div>
      <div class="content">
        <div class="properties q-pa-md">
          <q-select dense square outlined hide-bottom-space v-model="data.category" emit-value
            map-options :options="categories">
            <template v-slot:before>
              <label>Category</label>
            </template>
            <template v-slot:option="scope">
              <q-item v-bind="scope.itemProps">
                <q-item-section side>
                  <q-icon :name="scope.opt.icon" color="indigo-5" size="sm" class="q-pa-xs">
                  </q-icon>
                </q-item-section>
                <q-item-section>
                  <q-item-label>
                    {{ scope.opt.name }}
                  </q-item-label>
                  <q-item-label caption>
                    {{ scope.opt.description }}
                  </q-item-label>
                </q-item-section>
              </q-item>
            </template>
            <template v-slot:selected>
              <div class="row items-center">
                <q-icon color="indigo-5" size="sm" :name="AnnouncementCategories[data.category].icon">
                </q-icon>
                <span class="q-ml-sm">
                  {{ AnnouncementCategories[data.category].name }}
                </span>
              </div>
            </template>
          </q-select>

          <q-input v-model="data.title" dense square outlined maxlength="255" lazy-rules
            class="q-mt-md" hide-bottom-space :rules="[rules.required('Title is required')]">
            <template v-slot:before>
              <label>Title</label>
            </template>
          </q-input>

          <q-field class="q-mt-md text-editor" borderless dense>
            <main>
              <q-editor ref="textEditor" v-model="data.text" min-height="5rem" width="100%"
                :toolbar="textEditorToolbar" :definitions="textEditorCommands">
              </q-editor>
            </main>
            <template v-slot:before>
              <label>Details</label>
            </template>
          </q-field>

          <q-select dense square outlined hide-bottom-space class="q-mt-md" v-model="data.priority"
            :options="priorities" option-value="value" option-label="label" map-options emit-value>
            <template v-slot:before>
              <label>Priority</label>
            </template>
            <template v-slot:after>
              <sc-hint>
                Use higher priority to bring the announcement
                to customers' attention.
                High priority announcements will be
                displayed on top of the list.
              </sc-hint>
            </template>
          </q-select>

          <sc-date-input bg-color="white" dense clearable class="q-mt-md" v-model="data.validFrom"
            labelBefore="Valid From" :defaultToday="false"
            :hint="`The announcement will not be delivered before ${data.validFrom ? date(data.validFrom) : 'this date'}`">
          </sc-date-input>

          <sc-date-input bg-color="white" dense clearable class="q-mt-md" v-model="data.validUntil"
            labelBefore="Valid Until" :defaultToday="false"
            :hint="`The announcement will not be delivered after ${data.validUntil ? date(data.validUntil) : 'this date'}`">
          </sc-date-input>

          <q-field class="q-mt-md" borderless dense>
            <q-option-group :options="channels" type="checkbox" v-model="data.channels">
            </q-option-group>
            <template v-slot:before>
              <label>Delivery</label>
            </template>
          </q-field>
        </div>

        <div class="recipients q-pa-md">
          <div class="recipient-type">
            <q-select dense square outlined hide-bottom-space v-model="recipientType"
              :options="recipientTypes" option-value="value" option-label="label" map-options
              emit-value>
              <template v-slot:before>
                <label>
                  {{ data.recipientCount < 2 ? 'Recipient' : `${data.recipientCount} Recipients` }}
                    </label>
              </template>
            </q-select>
          </div>

          <div class="recipient-selector q-mt-md" v-if="recipientType === PrincipalType.Organization">
            <sc-multi-organization-selector v-model="selectedOrganizations"
              :hierarchy="organizationHierarchy" :include-self="true" iconSelected="check_circle"
              tickStrategy="strict" iconSelectedColor="green-7">
            </sc-multi-organization-selector>
          </div>

          <div class="recipient-selector q-mt-md"
            v-if="recipientType === PrincipalType.OrganizationProfile">
            <sc-multi-organization-profile-selector v-model="selectedOrganizationProfiles"
              :organizationProfiles="organizationProfiles" :bordered="false" :separator="false"
              :dense="true" :showProfileOwner="true">
            </sc-multi-organization-profile-selector>
          </div>
        </div>

      </div>
    </q-form>

  </sc-view>
</template>

<style lang="scss" scoped>
label {
  font-size: 14px;
  min-width: 160px;
  color: #0000008a;
}

.form {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  :deep(label) {
    font-size: 14px;
    min-width: 160px;
    color: #0000008a;
  }

  .banner {
    flex: 0;
  }

  .content {
    flex: 1;
    display: flex;
    flex-direction: row;
    overflow: hidden;

    .properties {
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: auto;

      .text-editor {
        >main {
          flex: 1;
        }

        :deep(.q-editor__content) {
          color: initial !important;
        }
      }
    }

    .recipients {
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      .recipient-type {
        flex: 0;
      }

      .recipient-selector {
        flex: 1;
        overflow: hidden;
        border: solid #0000001f 1px;
        display: flex;
        flex-direction: column;
      }
    }
  }

}
</style>