import { Log } from '@stellacontrol/utilities'
import { dispatch } from '@stellacontrol/client-utilities'

export async function resolve ({ from, to }) {
  const isTreeMinimized = to.query.tree === 'minimized'
  const isTreeHidden = to.query.tree === 'hidden'

  // Fetch the hierarchy of organizations, places and devices
  // available to the currently logged in organization
  const currentOrganization = await dispatch('getCurrentOrganization')
  const currentOrganizationGuardian = await dispatch('getCurrentOrganizationGuardian')

  let hierarchy
  if (!isTreeHidden) {
    hierarchy = await dispatch('getDeviceHistoryViewHierarchy')
    if (!(currentOrganization && hierarchy)) {
      return {
        redirectTo: {
          name: 'home'
        }
      }
    }
    await dispatch('requireDevices')
  }

  // If device specified in the URL, store in preferences for next time.
  // If no device specified in the URL, try reverting to the last viewed device.
  let { device: deviceId } = to.params || {}
  if (!deviceId) {
    const device = await dispatch('getUserPreference', { name: 'device-history-filter-device' })
    if (device) {
      return {
        redirectTo: {
          name: to.name,
          params: { device }
        }
      }
    }
  }

  // Check if asset tree status is enforced
  let organization, organizationGuardian, device

  // If no success in determining what to load, redirect to blank history page
  if (deviceId) {
    // Fetch device data
    device = await dispatch('getDeviceBySerialNumber', { serialNumber: deviceId, withDetails: true }) ||
      await dispatch('getDevice', { id: deviceId, withDetails: true })

    if (device) {
      dispatch('storeDeviceHistoryFilter', { device })
    } else {
      dispatch('storeDeviceHistoryFilter', { device: { serialNumber: null } })
      Log.warn(`Unauthorized access to ${deviceId}] history`)
      return {
        redirectTo: {
          name: to.name
        }
      }
    }
  }

  // Fetch device data if device selected
  if (device?.ownerId) {
    organization = await dispatch('getOrganization', { id: device.ownerId })
    organizationGuardian = await dispatch('getGuardian', { principal: organization, force: true })
  } else {
    device = undefined
    organization = await dispatch('getCurrentOrganization')
    organizationGuardian = await dispatch('getCurrentOrganizationGuardian')
  }

  // When customer requires premium services, check if device
  // has active subscription
  if (device) {
    const canAccessHistory = currentOrganizationGuardian.requiresPremiumSubscription('history-graph')
      ? organizationGuardian.canDeviceUse('history-graph', device.serialNumber, currentOrganizationGuardian)
      : currentOrganizationGuardian.canUse('history-graph')
    if (!canAccessHistory) {
      await dispatch('storeUserPreference', { name: 'device-history-selected-item', value: null })
    }
  }

  await dispatch('setRouteData', { from, to })
  await dispatch('initializeDeviceHistoryView', { hierarchy, organization, organizationGuardian, device, isTreeMinimized, isTreeHidden })

  return {}
}
