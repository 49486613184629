<script>
import { mapActions } from 'vuex'
import { DialogMixin } from '../../dialogs/dialog-mixin'

const dialog = 'network-error-popup'

export default {
  mixins: [
    DialogMixin
  ],

  data () {
    return {
      // Dialog title
      title: 'Network Error',
      // OK button label
      okLabel: 'Reload',
      // Cancel button label
      cancelLabel: 'Close',
      // Error which triggered the popup
      error: null,
      // Dialog identifier
      dialog
    }
  },

  methods: {
    ...mapActions([
      'dialogOk',
      'dialogCancel',
      'reload'
    ]),

    // Called when dialog is shown
    dialogShown () {
      this.title = this.data.title || this.title
      this.okLabel = this.data.okLabel || this.okLabel
      this.cancelLabel = this.data.cancelLabel || this.cancelLabel
      this.error = this.data.error
    },

    // OKs the dialog, reloads the UI
    async ok () {
      this.reload({ instant: true })
      this.dialogOk({ dialog })
    },

    // Cancels the dialog
    cancel () {
      this.dialogCancel({ dialog })
    }
  }
}
</script>

<template>
  <sc-dialog :dialog="dialog" persistent @dialogShown="dialogShown()">
    <q-form ref="form" class="form">
      <div class="q-pa-lg">
        <div class="row text-h6">
          Network connection has failed
        </div>

        <div class="row text-body2 q-pt-lg q-pb-md q-pr-md items-start no-wrap">
          <q-icon name="cloud_off" size="xl" color="orange-7" class="q-mr-lg">
          </q-icon>

          <span style="text-align: justify;">
            The network request has timed out.
            The network connection has been lost or the platform is experiencing very high traffic.
            You can try reloading the page. We sincerely apologize for the inconvenience.
          </span>
        </div>

        <div class="row q-mt-lg">
          <q-space></q-space>
          <q-btn :label="cancelLabel" unelevated class="q-mr-md" @click="cancel()"></q-btn>
          <q-btn :label="okLabel" unelevated class="primary" @click="ok()"></q-btn>
        </div>
      </div>

    </q-form>
  </sc-dialog>
</template>

<style scoped lang="scss">
.form {
  max-width: 450px;
}
</style>
