import { Log } from '@stellacontrol/utilities'
import { DeviceAPI } from '@stellacontrol/client-api'

/**
 * Devices API wrapper for retrieving settings of devices.
 */
export class DeviceSettingsClient {
  /**
   * Updates settings of a single device
   * @param {Device} device Device to update
   * @param {Object} parameters Shadow parameters to update
   * @param {Number} retry Number of attempts to update the settings, if they're not reconciled at first attempt
   * @param {Number} retryInterval Interval between update attempts, in milliseconds
   * @returns {Promise<Object>} Update results
   */
  async updateSettings (device = {}, parameters = {}, retry = 1, retryInterval = 0) {
    let { id, serialNumber } = device
    if (!(id || serialNumber)) throw new Error('Device identifier or serial number is required')

    try {
      const { results } = await DeviceAPI.updateSettings({ devices: [{ id, serialNumber }], parameters, retry, retryInterval }) || {}
      return (results || [])[0]

    } catch (error) {
      Log.error(`Error updating settings of ${device.serialNumber}`)
      Log.exception(error)
    }
  }

  /**
   * Updates settings of multiple devices
   * @param {Array[Device]} device Devices to update
   * @param {Object} parameters Shadow parameters to update
   * @returns {Promise<Array[Object]} Update results
   */
  async updateSettingsMany (devices = [], parameters = {}) {
    if (!devices || !(devices.length > 0)) return
    devices.every(({ id, serialNumber }) => {
      if (!(id || serialNumber)) throw new Error('Device identifier or serial number is required')
    })

    try {
      const { results } = await DeviceAPI.updateSettings({
        devices: devices.map(({ id, serialNumber }) => ({ id, serialNumber })),
        parameters
      }) || {}
      return (results || [])[0]

    } catch (error) {
      Log.error('Error updating device settings')
      Log.exception(error)
    }
  }

  /**
   * Resets settings of a single device to their factory values
   * @param {Device} device Device to update
   * @param {Boolean} clearShadow If true, the reset settings are then cleared from the device shadow
   * @param {Object} parameters List of parameters to reset to factory values. If not specified, the default set of factory settings will be applied.
   * @returns {Promise<Object>} Reset results
   */
  async resetSettings (device = {}, clearShadow, parameters) {
    let { id, serialNumber } = device
    if (!(id || serialNumber)) throw new Error('Device identifier or serial number is required')

    try {
      const { results } = await DeviceAPI.resetSettings({
        devices: [{ id, serialNumber }],
        clearShadow,
        parameters
      }) || {}
      return (results || [])[0]

    } catch (error) {
      Log.error(`Error resetting settings of ${device.serialNumber}`)
      Log.exception(error)
      return []
    }
  }

  /**
   * Clears custom parameters of a single device from device shadow
   * @param {Device} device Device to clear
   * @param {Object} parameters Customized shadow parameters to clear.
   * If not specified, all customized parameters (the entire desired shadow) will be cleared.
   * @returns {Promise<Object>} Clear results
   */
  async clearSettings (device = {}, parameters) {
    let { id, serialNumber } = device
    if (!(id || serialNumber)) throw new Error('Device identifier or serial number is required')

    try {
      const { results } = await DeviceAPI.clearSettings({
        devices: [{ id, serialNumber }],
        parameters
      })
      return (results || [])[0]

    } catch (error) {
      Log.error(`Error clearing settings of ${device.serialNumber}`)
      Log.exception(error)
      return []
    }
  }

  /**
   * Makes sure that device settings are reconciled with the device shadow
   * @param {Device} device Device to reconcile
   * @returns {Promise<DeviceSettings>} Device settings after eventual reconciliation
   */
  async reconcileSettings (device = {}) {
    let { id, serialNumber } = device
    if (!(id || serialNumber)) throw new Error('Device identifier or serial number is required')

    try {
      const result = await DeviceAPI.reconcileSettings({ device })
      return result

    } catch (error) {
      Log.error(`Error reconciling settings of ${device.serialNumber}`)
      Log.exception(error)
    }
  }
}