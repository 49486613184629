import { dispatch } from '@stellacontrol/client-utilities'

/**
 * Prepares the data required by alerts view
 */
export async function resolve ({ from, to }) {
  if (from && to) {
    await dispatch('requireOrganizations')
    await dispatch('requireDevices')
    return true
  }
}
